import React, { useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Typography as MuiTypography,
  Skeleton,
  Divider,
} from "@mui/material";
import { spacing } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DashboardCard from "../../components/dashboard/DashboardCard";
import DashboardEMITable from "./DashboardEMITable";
import DashboardCompanyTable from "./DashboardCompanyTable";
import {
  useDashboardLazyQuery,
  useDashboardQuery,
} from "../../data-access/hooks/staff/dashboard-total-data.hooks";
import {
  addMonths,
  endOfDay,
  endOfMonth,
  startOfDay,
  startOfMonth,
  sub,
} from "date-fns";

//?------------------------------------------------------------------------------------------------------- */
const Typography = styled(MuiTypography)(spacing);

const MuiSelect = styled(Select)`
  width: 215px;
  padding: 6px;
  height: 40px;
  background: white;
`;
const MuiMenuItem = styled(MenuItem)`
  background: white;
  margin: 5px 10px;
  height: 34px;
  border-radius: 3px;
  &:hover {
    background-color: #1e88e533;
  }
  &.Mui-selected {
    background-color: #1e88e533;
  }
`;
//?------------------------------------------------------------------------------------------------------- */
const today = new Date();
//?------------------------------------------------------------------------------------------------------- */

const Default = () => {
  const [age, setAge] = useState(0);

  const [allTotalData, setAllTotalData] = useState<any>();

  //?------------------------------------------------------------------------------------------------------- */
  const [getDataBySelectValue, { loading: totalDataGetLoading }] =
    useDashboardLazyQuery({
      onCompleted: (item) => setAllTotalData(item),
      fetchPolicy: "no-cache",
    });
  const handleChange = (event: any) => {
    setAge(event.target.value);
    if (event.target.value === 0) {
      getDataBySelectValue({
        variables: {
          endDate: endOfDay(today).toISOString(),
          startDate: startOfMonth(today).toISOString(),
        },
      });
    } else if (event.target.value === 1) {
      getDataBySelectValue({
        variables: {
          endDate: endOfDay(today).toISOString(),
          startDate: startOfDay(
            sub(today, {
              months: 1,
            })
          ).toISOString(),
        },
      });
    } else if (event.target.value === 2) {
      getDataBySelectValue({
        variables: {
          startDate: sub(startOfMonth(today), {
            months: 3,
          }).toISOString(),
          endDate: endOfDay(
            endOfMonth(
              addMonths(
                sub(startOfMonth(today), {
                  months: 3,
                }),
                2
              )
            )
          ).toISOString(),
        },
      });
    } else if (event.target.value === 3) {
      getDataBySelectValue({
        variables: {
          startDate: sub(startOfMonth(today), {
            months: 6,
          }).toISOString(),
          endDate: endOfDay(
            endOfMonth(
              addMonths(
                sub(startOfMonth(today), {
                  months: 6,
                }),
                5
              )
            )
          ).toISOString(),
        },
      });
    } else if (event.target.value === 4) {
      getDataBySelectValue({
        variables: {
          startDate: startOfDay(
            sub(today, {
              years: 1,
            })
          ).toISOString(),
          endDate: endOfDay(today).toISOString(),
        },
      });
    }
  };

  //?------------------------------------------------------------------------------------------------------- */
  const dashboardDropdownData = [
    {
      text: "Current month",
      value: 0,
    },
    {
      text: "Last 30 days",
      value: 1,
    },
    {
      text: "90 days",
      value: 2,
    },
    {
      text: "6 months",
      value: 3,
    },
    {
      text: "One Year",
      value: 4,
    },
  ];
  //?------------------------------------------------------------------------------------------------------- */
  const { loading: getAllTotalDataLoading } = useDashboardQuery({
    variables: {
      endDate: endOfDay(today).toISOString(),
      startDate: startOfDay(startOfMonth(today)).toISOString(),
    },
    onCompleted: (item) => setAllTotalData(item),
  });

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
        </Grid>
        <Grid item xs />
        <Grid item display={"flex"} gap={"32px"}>
          <FormControl>
            <MuiSelect
              id="demo-simple-select"
              value={age}
              onChange={(event) => handleChange(event)}
              defaultValue={age}
            >
              {dashboardDropdownData.map((item, i) => (
                <MuiMenuItem key={i} value={item.value}>
                  {item.text}
                </MuiMenuItem>
              ))}
            </MuiSelect>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container mt="16px" mb="28px" width={"100%"}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid
        display={"flex"}
        gap={"32px"}
        margin={"22px 0 34px 0"}
        justifyContent={"space-between"}
        item
      >
        <DashboardCard
          totalValue={
            totalDataGetLoading || getAllTotalDataLoading ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: "12px", width: "50px" }}
              />
            ) : (
              allTotalData &&
              allTotalData["company_aggregate"]?.aggregate?.count
            )
          }
          description={
            totalDataGetLoading || getAllTotalDataLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "12px" }} />
            ) : (
              "Total Companies"
            )
          }
          image={"companies"}
        />
        <DashboardCard
          totalValue={
            totalDataGetLoading || getAllTotalDataLoading ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: "12px", width: "50px" }}
              />
            ) : (
              allTotalData && allTotalData["trade_aggregate"]?.aggregate?.count
            )
          }
          description={
            totalDataGetLoading || getAllTotalDataLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "12px" }} />
            ) : (
              "Total Trade"
            )
          }
          image={"trade"}
        />
        <DashboardCard
          totalValue={
            totalDataGetLoading || getAllTotalDataLoading ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: "12px", width: "50px" }}
              />
            ) : (
              `₹ ${
                (allTotalData &&
                  allTotalData[
                    "trade_aggregate"
                  ]?.aggregate?.sum?.payout_value?.toFixed(2)) ||
                "0"
              }`
            )
          }
          description={
            totalDataGetLoading || getAllTotalDataLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "12px" }} />
            ) : (
              "Total Loan Amount"
            )
          }
          image={"loan"}
        />
        <DashboardCard
          totalValue={
            totalDataGetLoading || getAllTotalDataLoading ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: "12px", width: "50px" }}
              />
            ) : (
              `₹ ${
                (
                  allTotalData &&
                  allTotalData["trade_aggregate"]?.aggregate?.sum
                    ?.fees_without_gst
                )?.toFixed(2) || "0"
              }`
            )
          }
          description={
            totalDataGetLoading || getAllTotalDataLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "12px" }} />
            ) : (
              "Total Revenue"
            )
          }
          image={"revenue"}
        />
        <DashboardCard
          totalValue={
            totalDataGetLoading || getAllTotalDataLoading ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: "12px", width: "50px" }}
              />
            ) : (
              allTotalData &&
              allTotalData["investor_aggregate"]?.aggregate?.count
            )
          }
          description={
            totalDataGetLoading || getAllTotalDataLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "12px" }} />
            ) : (
              "Number Of Investors"
            )
          }
          image={"investors"}
        />
      </Grid>
      <Grid item>
        <DashboardCompanyTable />
      </Grid>
      <br />
      <br />
      <Grid item>
        <DashboardEMITable />
      </Grid>
    </React.Fragment>
  );
};

export default Default;
