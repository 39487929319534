import { Grid, Typography } from "@mui/material";
import EmpImg from "../assets/svg/empty-image.svg";

interface EmptyDataInterface {
  children?: any;
  gridStyle?: any;
  emptyMessage?: any;
}

const EmptyData = ({
  children,
  gridStyle,
  emptyMessage,
}: EmptyDataInterface) => {
  return (
    <Grid
      container
      display={"flex"}
      flexDirection="column"
      alignItems={"center"}
      justifyContent={"center"}
      padding={10}
      style={gridStyle}
    >
      <img src={EmpImg} alt="img" />
      <Typography fontSize={"12px"} fontWeight={400} color="#A4A4A4">
        {emptyMessage ? emptyMessage : "No data added yet!"}
      </Typography>
      {children}
    </Grid>
  );
};
export default EmptyData;
