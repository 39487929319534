import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField as MuiTextField,
  Switch,
  Select as MuiSelect,
  MenuItem,
} from "@mui/material";
import { Paper as MuiPaper } from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { TableWrapper } from "../../../theme/customComponents";
import { format } from "date-fns";

//*-----------------------------------------------------------------------------------------------
const Paper = styled(MuiPaper)(spacing);
const TextField = styled(MuiTextField)`
  & .MuiInputBase-input {
    padding: 0 10px;
    height: 32px;
  }
`;
const Select = styled(MuiSelect)`
  height: 32px;
  width: 100%;
`;
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 35,
  height: 19.5,
  padding: 0,
  borderRadius: 50,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(15px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#fff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 15,
    height: 15,
    color: "#1E88E5",
    borderRadius: 10,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(255,255,255)",
    border: " solid 2px black",
    boxSizing: "border-box",
  },
}));

interface MandateConfigurationInterface {
  isEditing?: Boolean;
  mandateList?: any[];
  mandateBankList?: any[];
  mandateFrequency?: any[];
  formik?: any;
  mandateData?: any;
}

interface mandateDataInterface {
  mandate_id?: Number;
  mandate_account_id?: Number;
  mandate_frequency?: String;
  max_presentation_amount?: Number;
  mandate_type?: String;
  mandate_account_no?: String;
  mandate_bank_name?: String;
  mandate_no_of_months?: Number;
  umrn?: String;
}

const MandateConfiguration = ({
  isEditing,
  mandateList,
  formik,
  mandateBankList,
  mandateFrequency,
  mandateData,
}: MandateConfigurationInterface) => {
  const mandateFilterData: mandateDataInterface =
    mandateData?.mandate === null
      ? {
          mandate_id: mandateData?.mandate_id ?? "-",
          mandate_account_id: mandateData?.mandate_account_id ?? "-",
          mandate_account_no: mandateData?.mandate_account?.account_no ?? "-",
          mandate_bank_name:
            mandateData?.mandate_account?.ref_bank?.name ?? "-",
          mandate_frequency: mandateData?.mandate_frequency ?? "-",
          max_presentation_amount: mandateData?.max_presentation_amount ?? "-",
          mandate_type: mandateData?.mandate_type ?? "-",
          mandate_no_of_months: mandateData?.mandate_no_of_months ?? "-",
        }
      : {
          mandate_id: mandateData?.mandate?.id ?? "-",
          mandate_account_no: mandateData?.mandate?.account?.account_no ?? "-",
          mandate_bank_name:
            mandateData?.mandate?.account?.ref_bank?.name ?? "-",
          mandate_account_id: mandateData?.mandate?.account_id ?? "-",
          mandate_frequency: mandateData?.mandate?.frequency ?? "-",
          max_presentation_amount: mandateData?.mandate?.amount ?? "-",
          mandate_type: mandateData?.mandate?.type ?? "-",
          mandate_no_of_months: mandateData?.mandate?.months ?? "-",
          umrn: mandateData?.mandate?.umrn ?? "-",
        };

  return (
    <Grid item>
      <TableWrapper>
        <Paper>
          <Typography fontSize={18} fontWeight={600} p={3}>
            Mandate Details
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                {isEditing && mandateList?.length !== 0 && (
                  <TableCell>Reuse Mandate</TableCell>
                )}
                {isEditing && mandateList?.length !== 0 && (
                  <TableCell>Select Mandate</TableCell>
                )}
                <TableCell>Account </TableCell>
                {(mandateData?.mandate !== null &&
                  mandateData?.mandate !== undefined) ||
                  (formik.values.mandate_configuration.mandate_id !== 0 && (
                    <TableCell>UMRN</TableCell>
                  ))}
                <TableCell>Frequency</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Max Amount</TableCell>
                {!isEditing && <TableCell>Mandate Trigger</TableCell>}
                <TableCell>
                  {formik.values.mandate_configuration.reuse_mandate
                    ? "Start Date & End Date"
                    : "Mandate Month"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {isEditing && mandateList?.length !== 0 && (
                  <TableCell>
                    {isEditing ? (
                      <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>No</Grid>
                        <Grid item>
                          <AntSwitch
                            name="mandate_configuration.reuse_mandate"
                            value={
                              formik.values.mandate_configuration.reuse_mandate
                            }
                            onChange={(value: any) => {
                              formik.setFieldValue(
                                "mandate_configuration.reuse_mandate",
                                value?.target?.checked === true ? true : false
                              );
                              if (value?.target?.checked === false) {
                                formik.setFieldValue(
                                  "mandate_configuration.mandate_id",
                                  0
                                );
                              }
                            }}
                          />
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                )}
                {isEditing && mandateList?.length !== 0 && (
                  <TableCell>
                    {isEditing ? (
                      <Select
                        id="mandate_configuration.mandate_id"
                        name="mandate_configuration.mandate_id"
                        value={formik.values.mandate_configuration.mandate_id}
                        onChange={formik.handleChange}
                        defaultValue={0}
                        disabled={
                          !formik.values.mandate_configuration.reuse_mandate
                        }
                      >
                        <MenuItem value={0}>Select Mandate</MenuItem>
                        {mandateList?.map((item) => (
                          <MenuItem value={item?.id}>{`${item?.id}`}</MenuItem>
                        ))}
                      </Select>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                )}
                <TableCell>
                  {isEditing ? (
                    !formik.values.mandate_configuration.reuse_mandate ? (
                      <Select
                        id="mandate_configuration.mandate_account_id"
                        name="mandate_configuration.mandate_account_id"
                        value={
                          formik.values.mandate_configuration.mandate_account_id
                        }
                        onChange={formik.handleChange}
                      >
                        {mandateBankList?.map((item) => (
                          <MenuItem
                            value={item.id}
                          >{` ${item.account_no}:${item?.ref_bank?.name}`}</MenuItem>
                        ))}
                      </Select>
                    ) : formik.values.mandate_configuration.mandate_id === 0 ? (
                      "-"
                    ) : (
                      `${
                        mandateList?.find(
                          (item) =>
                            item?.id ===
                            formik.values.mandate_configuration.mandate_id
                        )?.account?.account_no
                      }:${
                        mandateList?.find(
                          (item) =>
                            item?.id ===
                            formik.values.mandate_configuration.mandate_id
                        )?.account?.ref_bank?.name
                      }`
                    )
                  ) : (
                    `${mandateFilterData?.mandate_account_no}:${mandateFilterData?.mandate_bank_name}`
                  )}
                </TableCell>
                {(mandateData?.mandate !== null &&
                  mandateData?.mandate !== undefined) ||
                  (formik.values.mandate_configuration.mandate_id !== 0 && (
                    <TableCell>
                      {
                        mandateList?.find(
                          (item) =>
                            item?.id ===
                            formik.values.mandate_configuration.mandate_id
                        )?.umrn
                      }
                    </TableCell>
                  ))}
                <TableCell>
                  {isEditing ? (
                    !formik.values.mandate_configuration.reuse_mandate ? (
                      <Select
                        id="mandate_configuration.mandate_frequency"
                        name="mandate_configuration.mandate_frequency"
                        value={
                          formik.values.mandate_configuration.mandate_frequency
                        }
                        onChange={formik.handleChange}
                      >
                        {mandateFrequency?.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    ) : formik.values.mandate_configuration.mandate_id === 0 ? (
                      "-"
                    ) : (
                      `${
                        mandateList?.find(
                          (item) =>
                            item?.id ===
                            formik.values.mandate_configuration.mandate_id
                        )?.frequency
                      }`
                    )
                  ) : (
                    mandateFilterData?.mandate_frequency
                  )}
                </TableCell>
                <TableCell>
                  {isEditing ? (
                    !formik.values.mandate_configuration.reuse_mandate ? (
                      <Select
                        id="mandate_configuration.mandate_type"
                        name="mandate_configuration.mandate_type"
                        value={formik.values.mandate_configuration.mandate_type}
                        onChange={formik.handleChange}
                      >
                        <MenuItem value={"PAPER"}>Physical</MenuItem>
                        <MenuItem value={"ELECTRONIC"}>Electronic</MenuItem>
                      </Select>
                    ) : formik.values.mandate_configuration.mandate_id === 0 ? (
                      "-"
                    ) : (
                      `${
                        mandateList?.find(
                          (item) =>
                            item?.id ===
                            formik.values.mandate_configuration.mandate_id
                        )?.type
                      }`
                    )
                  ) : (
                    mandateFilterData?.mandate_type
                  )}
                </TableCell>
                <TableCell>
                  {isEditing ? (
                    !formik.values.mandate_configuration.reuse_mandate ? (
                      <TextField
                        name="mandate_configuration.max_presentation_amount"
                        value={
                          formik.values.mandate_configuration
                            .max_presentation_amount
                        }
                        onChange={formik.handleChange}
                        id="mandate_configuration.max_presentation_amount"
                        placeholder="Enter Amount"
                        variant="outlined"
                      />
                    ) : formik.values.mandate_configuration.mandate_id === 0 ? (
                      "-"
                    ) : (
                      `₹ ${
                        mandateList?.find(
                          (item) =>
                            item?.id ===
                            formik.values.mandate_configuration.mandate_id
                        )?.max_presentation_amount
                      }`
                    )
                  ) : (
                    `₹ ${mandateFilterData?.max_presentation_amount}`
                  )}
                </TableCell>
                {!isEditing && <TableCell>{mandateData?.debit_mode}</TableCell>}

                <TableCell>
                  {isEditing ? (
                    !formik.values.mandate_configuration.reuse_mandate ? (
                      <TextField
                        name="mandate_configuration.mandate_no_of_months"
                        value={
                          formik.values.mandate_configuration
                            .mandate_no_of_months
                        }
                        onChange={formik.handleChange}
                        id="mandate_configuration.mandate_no_of_months"
                        placeholder="Enter term length"
                        variant="outlined"
                      />
                    ) : formik.values.mandate_configuration.mandate_id === 0 ? (
                      "-"
                    ) : (
                      `${
                        formik.values.mandate_configuration.mandate_id &&
                        format(
                          new Date(
                            mandateList?.find(
                              (item) =>
                                item?.id ===
                                formik.values.mandate_configuration.mandate_id
                            )?.start_date
                          ),
                          "dd/MM/yyyy"
                        )
                      } & ${
                        formik.values.mandate_configuration.mandate_id &&
                        format(
                          new Date(
                            mandateList?.find(
                              (item) =>
                                item?.id ===
                                formik.values.mandate_configuration.mandate_id
                            )?.end_date
                          ),
                          "dd/MM/yyyy"
                        )
                      }`
                    )
                  ) : (
                    mandateFilterData?.mandate_no_of_months
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </TableWrapper>
    </Grid>
  );
};

export default MandateConfiguration;
