import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useContext } from "react";

import {
  Ref_Subscription_Status_Enum,
  Ref_Trade_State_Enum,
} from "../data-access/types";
import {
  useAttachContractsMutation,
  useGetAllContractsByCompanyIdQuery,
  useGetAllSubscriptionByCompanyIdLazyQuery,
} from "../data-access/hooks/company/company-contract.hooks";
import _ from "lodash";
import { CompanyContext } from "../contexts/CompanyContext";

const useContractSelection = () => {
  const { id, trade_id } = useParams();

  const navigate = useNavigate();

  const { state }: any = useLocation();

  const [listOfAllTrades, setListOfAllTrades] = React.useState<any[]>([]);

  const [allSelectedContracts, setAllSelectedContracts] = React.useState<any[]>(
    []
  );

  const { setShowAlert } = useContext(CompanyContext);

  const [tradeSummaryData, setTradeSummaryData] = React.useState<any>();

  const [contractType, setContractType] = React.useState("Primary");

  const [paginationData, setPaginationData] = React.useState({
    offset: 0,
    limit: 50,
    totalcount: 0,
    currentPage: 1,
  });

  /**************************************************************************************************************************************** */
  const pageCount = (number: number) => {
    return Math.ceil(number / 10);
  };

  /**************************************************************************************************************************************** */
  const [getAllSubscription, { loading: allSubscription }] =
    useGetAllSubscriptionByCompanyIdLazyQuery({
      onCompleted: (item) => {
        const subscriptionData = item?.subscription.map((item) => {
          return {
            ...item,
            subscription_id: item.id,
          };
        });
        setAllSelectedContracts(subscriptionData);
      },
    });
  /**************************************************************************************************************************************** */
  const { loading, refetch } = useGetAllContractsByCompanyIdQuery({
    variables: {
      where: {
        company_id: {
          _eq: Number(id),
        },
        is_latest: {
          _eq: true,
        },
        eligibility_score: {
          _eq: contractType === "Primary" ? 2 : 1,
        },
        _not: {
          trade_subscriptions: {
            status: { _eq: Ref_Subscription_Status_Enum.Active },
            trade: {
              state: {
                _in: [
                  Ref_Trade_State_Enum.Active,
                  Ref_Trade_State_Enum.Initiated,
                ],
              },
              id: {
                _neq: Number(trade_id),
              },
            },
          },
        },
        status: {
          _eq: Ref_Subscription_Status_Enum.Active,
        },
        verified: {
          _eq: true,
        },
      },

      trade_where: {
        id: {
          _eq: Number(trade_id),
        },
      },

      selected_where: {
        company_id: {
          _eq: Number(id),
        },
        trade_id: {
          _eq: Number(trade_id),
        },
      },

      agg_where: {
        company_id: {
          _eq: Number(id),
        },
        eligibility_score: {
          _eq: contractType === "Primary" ? 2 : 1,
        },
        _not: {
          trade_subscriptions: {
            status: { _eq: Ref_Subscription_Status_Enum.Active },
            trade: {
              state: {
                _in: [
                  Ref_Trade_State_Enum.Active,
                  Ref_Trade_State_Enum.Initiated,
                ],
              },
              id: {
                _neq: Number(trade_id),
              },
            },
          },
        },
        status: {
          _eq: Ref_Subscription_Status_Enum.Active,
        },
        verified: {
          _eq: true,
        },
      },

      offset: paginationData.offset,
      limit: paginationData.limit,
    },
    onCompleted: (item) => {
      /**========================================================================================== */
      const subscriptionData = item?.subscription.map((item) => {
        return {
          ...item,
          subscription_id: item.id,
        };
      });

      setListOfAllTrades(subscriptionData);

      setTradeSummaryData(item.trade);

      /**========================================================================================== */
      if (
        allSelectedContracts?.length === 0 &&
        item?.trade_subscription?.length !== 0 &&
        item.trade[0].status !== "INITIATED"
      ) {
        setAllSelectedContracts(item?.trade_subscription);
      } else if (item.trade[0].status === "INITIATED") {
        getAllSubscription({
          variables: {
            where: {
              company_id: {
                _eq: Number(id),
              },
              is_latest: {
                _eq: true,
              },
              _not: {
                trade_subscriptions: {
                  status: { _eq: Ref_Subscription_Status_Enum.Active },
                  trade: {
                    state: {
                      _in: [
                        Ref_Trade_State_Enum.Active,
                        Ref_Trade_State_Enum.Initiated,
                      ],
                    },
                    id: {
                      _neq: Number(trade_id),
                    },
                  },
                },
              },
              status: {
                _eq: Ref_Subscription_Status_Enum.Active,
              },
              verified: {
                _eq: true,
              },
            },
          },
        });
      }

      /**========================================================================================== */
      setPaginationData({
        ...paginationData,
        totalcount: Number(item.subscription_aggregate.aggregate?.count),
      });
    },
    /**========================================================================================== */
    onError: (item) => {
      setShowAlert({
        show: true,
        message: item.message,
        type: "error",
      });
    },
  });

  /**************************************************************************************************************************************** */
  const [handleAttachContracts, { loading: AttachLoading }] =
    useAttachContractsMutation({
      onCompleted: (item) => {
        refetch();
        setShowAlert({
          show: true,
          message:
            "Contacts attached successfully. Please ask Company admin to sign mandate",
          type: "success",
        });
      },
      onError: (item) => {
        setShowAlert({
          show: true,
          message: item.message,
          type: "error",
        });
      },
    });

  /**************************************************************************************************************************************** */
  const handleContractsChange = (event: any, dataItem: any) => {
    if (event?.target?.checked) {
      setAllSelectedContracts([...allSelectedContracts, dataItem]);
    } else {
      const deleteId = allSelectedContracts.filter(
        (item) => item.subscription_id !== dataItem.subscription_id
      );
      setAllSelectedContracts(deleteId);
    }
  };

  /**************************************************************************************************************************************** */
  const isSelected = (id: number) =>
    allSelectedContracts?.findIndex(
      (item: any) => item.subscription_id === id
    ) !== -1;

  /**************************************************************************************************************************************** */
  const handleAllContractsChange = (event: any) => {
    if (event?.target?.checked) {
      const result = _.unionBy(
        listOfAllTrades,
        allSelectedContracts,
        "subscription_id"
      );
      setAllSelectedContracts(result);
    } else {
      const ids = new Set(listOfAllTrades.map(({ id }) => id));
      const selectedRows = allSelectedContracts.filter(
        ({ id }) => !ids.has(id)
      );
      setAllSelectedContracts(selectedRows);
    }
  };

  return {
    id,
    loading,
    handleAttachContracts,
    state,
    AttachLoading,
    setContractType,
    contractType,
    allSelectedContracts,
    handleAllContractsChange,
    listOfAllTrades,
    handleContractsChange,
    pageCount,
    paginationData,
    setPaginationData,
    tradeSummaryData,
    isSelected,
    trade_id,
    navigate,
    allSubscription,
  };
};

export default useContractSelection;
