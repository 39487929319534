import ReactQuill from "react-quill";
import CloseIcon from "../../../assets/svg/close-icon.svg";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useUpdateOpsNotesMutation } from "../../../data-access/hooks/company/company.hooks";
import { useState } from "react";

interface OpsModalInterface {
  setOpenSalesNoteModal: any;
  data: any;
  refetch: any;
}

const SalesNotesModal = ({
  setOpenSalesNoteModal,
  data,
  refetch,
}: OpsModalInterface) => {
  const [isRemarkEditing, setIsRemarkEditing] = useState(
    data?.sales_answers[0]?.notes === "" ? true : false
  );

  const [editorText, setEditorText] = useState(data?.sales_answers[0]?.notes);

  const handleEditorChange = (html: any) => {
    setEditorText(html);
  };

  const modules = {
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        { align: "center" },
        { align: "right" },
        { align: "justify" },
        { list: "bullet" },
        { list: "ordered" },
        { color: ["red", "blue"] },
      ],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const [updateOpsNotes, { loading: updateOpsNotesLoading }] =
    useUpdateOpsNotesMutation({
      onCompleted: () => {
        refetch();
        setOpenSalesNoteModal(false);
      },
    });

  return (
    <Grid
      container
      width={"50%"}
      padding={"20px"}
      style={{ background: "white" }}
    >
      <Grid
        item
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        marginBottom={"5px"}
      >
        <Typography fontWeight={800} fontSize={"18px"}>
          Sales Notes
        </Typography>
        <IconButton onClick={() => setOpenSalesNoteModal(false)}>
          <img width={"16px"} height={"16px"} alt="" src={CloseIcon} />
        </IconButton>
      </Grid>
      <Grid item width={"100%"}>
        {isRemarkEditing || data?.sales_answers[0]?.notes === "" ? (
          <ReactQuill
            onChange={handleEditorChange}
            value={editorText}
            defaultValue={data?.sales_answers[0]?.notes}
            modules={modules}
            formats={formats}
            className={"custom-quill"}
          />
        ) : (
          <Typography fontWeight={400} fontSize={"14px"}>
            <div
              dangerouslySetInnerHTML={{
                __html: data?.sales_answers[0]?.notes,
              }}
            />
          </Typography>
        )}
      </Grid>
      <Grid
        item
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"10px 0"}
      >
        <Button onClick={() => setOpenSalesNoteModal(false)} variant="outlined">
          Close
        </Button>
        {!isRemarkEditing ? (
          <Button variant="contained" onClick={() => setIsRemarkEditing(true)}>
            Edit
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() =>
              updateOpsNotes({
                variables: {
                  id: data?.sales_answers[0]?.id,
                  notes: `${editorText}`,
                },
              })
            }
          >
            {updateOpsNotesLoading ? <CircularProgress size={25} /> : "Save"}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default SalesNotesModal;
