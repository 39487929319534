import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";
import "./i18n";
import createTheme from "./theme";
import routes from "./routes";
import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import { AuthProvider } from "./contexts/JWTContext";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./data-access/apollo-client";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import { LicenseInfo } from "@mui/x-data-grid-pro";

// @ts-ignore
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
});

if (process.env.REACT_APP_MATERIAL_UI_KEY) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MATERIAL_UI_KEY);
}

function App() {
  const content = useRoutes(routes);
  const { theme } = useTheme();

  useEffect(() => {
    if (process.env.REACT_APP_GTM_ID) {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Bridgeup Ops Panel"
          defaultTitle="Bridgeup Ops Panel"
        />
        <Provider store={store}>
          <StylesProvider jss={jss}>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={createTheme(theme)}>
                <ThemeProvider theme={createTheme(theme)}>
                  <AuthProvider>{content}</AuthProvider>
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </StylesProvider>
        </Provider>
      </HelmetProvider>
    </ApolloProvider>
  );
}

export default App;
