import React, { useState } from "react";
import styled from "styled-components/macro";
import { Button, Grid, Typography as MuiTypography } from "@mui/material";
import { spacing } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { format } from "date-fns";
import { useGetScheduledEmiQuery } from "../../data-access/hooks/staff/schedule-emi.hooks";
import { Order_By } from "../../data-access/types";
import { TableWrapper } from "../../theme/customComponents";
import CommonLoader from "../../components/CommonLoader";
import TableStatus from "../../components/TableStatus";

//?------------------------------------------------------------------------------------------------------- */
const Typography = styled(MuiTypography)(spacing);

//?----------------------------------CURRENT MONTH FUNCTION------------------------------------------------ */
const allMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const DashboardEMITable = () => {
  const d = new Date();
  const currentMonth = allMonths[d.getMonth()];
  const [allEMIData, setAllEMIData] = useState([]);
  const today = new Date();
  const sevenDaysBefore = new Date(new Date().setDate(today.getDate() - 7));
  const thirtyDayFromSeven = new Date(new Date().setDate(today.getDate() + 30));

  //?----------------------------------ALL EMI's LIST-------------------------------------------------------- */
  const { loading: EMIListLoading } = useGetScheduledEmiQuery({
    variables: {
      limit: 20,
      offset: 0,
      order_by: [{ created_at: Order_By.Desc }],
      where: {
        scheduled_at: {
          _gte: format(sevenDaysBefore, "yyyy-MM-dd"),
          _lte: format(thirtyDayFromSeven, "yyyy-MM-dd"),
        },
      },
    },
    onCompleted: (item: any) => setAllEMIData(item.scheduled_emi),
  });

  return (
    <React.Fragment>
      <Paper>
        <Grid padding={"20px"} justifyContent="space-between" container>
          <Grid item>
            <Typography variant="h3">
              Upcoming EMIs for {currentMonth}
            </Typography>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button variant="primaryMedium">View all EMI's</Button>
          </Grid>
        </Grid>
        {EMIListLoading ? (
          <CommonLoader />
        ) : (
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Company Name</TableCell>
                  <TableCell>EMI No.</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Transction ID</TableCell>
                  <TableCell>Transction Date</TableCell>
                  {/* <TableCell>EMI Type</TableCell>
                  <TableCell>Investor Repayment</TableCell>
                  <TableCell>Mandate Trigger</TableCell> */}
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {allEMIData?.map(
                  (row: {
                    id: number;
                    trade_installment_seq: number;
                    name: string;
                    status: string;
                    created_at: string;
                    amount: number;
                    scheduled_at: number;
                    mobile_number: number;
                    fullname: string;
                    email: string;
                    trade: any;
                    company_users: any[];
                    transactions: any[];
                    estimated_arr: number;
                    active_gstin: string | number | null;
                  }) => {
                    const transctionData = format(
                      new Date(row.scheduled_at),
                      "dd/MM/yyyy / hh:mm aaaaa'm'"
                    );
                    return (
                      <TableRow key={row.id}>
                        <TableCell>{row?.trade?.company?.name}</TableCell>
                        <TableCell>{row.trade_installment_seq}</TableCell>
                        <TableCell>{row.amount}</TableCell>
                        <TableCell>{row.transactions[0]?.id || "-"}</TableCell>
                        <TableCell>{transctionData}</TableCell>
                        <TableCell>
                          {TableStatus({
                            stautsText: `${row?.status}`,
                          })}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableWrapper>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default DashboardEMITable;
