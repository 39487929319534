import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  CircularProgress,
  FormControl,
} from "@mui/material";
import { spacing } from "@mui/system";
import SimpleInput from "../input/SimpleInput";
import { resetPassword } from "../../services/auth";

const Alert = styled(MuiAlert)(spacing);

function ResetPassword() {
  const navigate = useNavigate();

  const [isloading, setIsLoading] = React.useState(false);

  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={async (values, { setStatus, setErrors }) => {
        setIsLoading(true);
        resetPassword(values.email)
          .then(() => {
            setIsLoading(false);
            navigate("/auth/sign-in");
          })
          .catch((item) => {
            setIsLoading(false);
            setStatus({ success: false });
            setErrors({ submit: item.response.data.message });
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <FormControl
            fullWidth
            style={{
              margin: "10px 0",
            }}
          >
            <SimpleInput
              type="email"
              name="email"
              inputlabel="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
              autoFocus
            />
          </FormControl>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isloading}
          >
            {isloading ? <CircularProgress size={18} /> : "Reset password"}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
