import {
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  CircularProgress,
  Button,
  Paper,
  Autocomplete,
  TextField,
  LinearProgress,
  TextFieldProps,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { TableWrapper } from "../../../theme/customComponents";
import { ReactComponent as DownloadIcon } from "../../../assets/Icon/Outline/download-icon.svg";
import { TableType } from "../../../data-access/types";
import { ReactComponent as ExclamationCircleIcon } from "../../../assets/Icon/Outline/exclamation-icon.svg";
import { format } from "date-fns";
import React from "react";
import { useGetCompanyActiveGstQuery } from "../../../data-access/hooks/company/deals-trade.hooks";
import { createStyles, makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { createFilterOptions } from "@mui/base";
import TableStatus from "../../../components/TableStatus";
import SimpleDatePicker from "../../../components/input/SimpleDatePicker";

interface FilterGSTInterface {
  gstinId: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    autocomplete: {},
    autocompletepaper: {
      boxShadow: "0 0 3px #00000044",
    },
  })
);

const GSTRegex = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/gm;

const DetailsContainer = ({
  allTradeData,
  downloadLoading,
  handleDownloadTable,
  downloadAPALoading,
  downloadUploadFile,
  isEditing,
  formik,
}: any) => {
  const { id: company_id } = useParams();

  const classes = useStyles();

  const [allActiveGST, setAllActiveGST] = React.useState<any[]>([]);

  /*************************************************************************************************** */
  const { loading: getAllGSTNumbersLoading } = useGetCompanyActiveGstQuery({
    onCompleted: (item) => {
      const activeDGSTata = item?.company?.map(
        (dataItem) =>
          dataItem?.gstin &&
          dataItem?.gstin?.filter(
            (gstData: any) => gstData.authStatus === "Active"
          )
      );
      const FilterData =
        activeDGSTata &&
        activeDGSTata[0]?.map((data: any) => {
          return {
            gstinId: data.gstinId,
          };
        });
      setAllActiveGST(FilterData || []);
    },
    variables: {
      where: {
        id: {
          _eq: Number(company_id),
        },
      },
    },
  });

  const filter = createFilterOptions<FilterGSTInterface>();

  return (
    <>
      {getAllGSTNumbersLoading && <LinearProgress />}
      <Grid item>
        <TableWrapper>
          <Paper>
            <Typography fontSize={18} fontWeight={600} p={3}>
              Trade Details
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Trade ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Offline Trade</TableCell>
                  <TableCell>Fees Without GST</TableCell>
                  <TableCell>Date of Disbursal</TableCell>
                  <TableCell align="right">Amortization Table</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{allTradeData?.id}</TableCell>
                  <TableCell>
                    {TableStatus({
                      stautsText: `${allTradeData?.status}`,
                    })}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {allTradeData?.state === "OFFLINE" ? "Yes" : "No"}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {`₹ ${allTradeData?.fees_without_gst}`}
                  </TableCell>
                  {isEditing && allTradeData?.status === "OFFLINE" ? (
                    <TableCell>
                      <SimpleDatePicker
                        name="trade_configuration.payout_on"
                        onChange={(value: any) =>
                          formik.setFieldValue(
                            "trade_configuration.payout_on",
                            value
                          )
                        }
                        value={formik.values.trade_configuration.payout_on}
                        renderInput={(
                          params: JSX.IntrinsicAttributes & TextFieldProps
                        ) => (
                          <TextField
                            {...params}
                            placeholder="Select Date of Disbursal"
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </TableCell>
                  ) : (
                    <TableCell>
                      {" "}
                      {allTradeData?.payout_on
                        ? format(
                            new Date(allTradeData?.payout_on),
                            "dd LLL yyyy"
                          )
                        : "-"}
                    </TableCell>
                  )}

                  <TableCell align="right">
                    {downloadLoading ? (
                      <CircularProgress size={18} />
                    ) : (
                      <Grid
                        item
                        display={"flex"}
                        flexDirection="row"
                        justifyContent={"flex-end"}
                      >
                        <Button
                          disabled={
                            downloadLoading ||
                            allTradeData?.status === "INITIATED"
                          }
                          onClick={() =>
                            handleDownloadTable({
                              type: TableType.Investor,
                              trade_id: allTradeData?.id,
                            })
                          }
                        >
                          <DownloadIcon width={18} height={18} />
                          &nbsp; Investors
                        </Button>
                        <Button
                          disabled={
                            downloadLoading ||
                            allTradeData?.status === "INITIATED"
                          }
                          onClick={() =>
                            handleDownloadTable({
                              type: TableType.Company,
                              trade_id: allTradeData?.id,
                            })
                          }
                        >
                          <DownloadIcon width={18} height={18} />
                          &nbsp; Company{" "}
                        </Button>
                      </Grid>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </TableWrapper>
      </Grid>
      <br />
      <br />
      <Grid item>
        <TableWrapper>
          <Paper>
            <Typography fontSize={18} fontWeight={600} p={3}>
              Customer Details
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Company ID</TableCell>
                  <TableCell>Company Name</TableCell>
                  {!isEditing && <TableCell>Loan Amount</TableCell>}
                  {!isEditing && <TableCell>Interest Rate</TableCell>}
                  {!isEditing && <TableCell>EMI Start Date</TableCell>}
                  <TableCell>GST No</TableCell>
                  <TableCell>Billing Address</TableCell>
                  <TableCell>Remark</TableCell>
                  <TableCell align="right">APA</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{allTradeData?.company?.id}</TableCell>
                  <TableCell>{allTradeData?.company?.name}</TableCell>
                  {!isEditing && (
                    <TableCell>
                      {`₹ ${allTradeData?.payout_value?.toFixed(2)}`}
                    </TableCell>
                  )}
                  {!isEditing && (
                    <TableCell>
                      {allTradeData?.roi ? `${allTradeData?.roi}%` : "-"}
                    </TableCell>
                  )}
                  {!isEditing && (
                    <TableCell>
                      {allTradeData?.emi_start_date
                        ? format(
                            new Date(allTradeData?.emi_start_date),
                            "dd LLL yyyy"
                          )
                        : "-"}
                    </TableCell>
                  )}
                  <TableCell>
                    {allTradeData?.status !== "OFFLINE" && isEditing ? (
                      <Autocomplete
                        classes={{
                          root: classes.autocomplete,
                          paper: classes.autocompletepaper,
                        }}
                        value={formik.values.trade_configuration.billing_gst}
                        onChange={(event, newValue) => {
                          if (typeof newValue === "string") {
                            formik.setFieldValue(
                              "trade_configuration.billing_gst",
                              //@ts-ignore
                              newValue?.gstinId
                            );
                          } else if (newValue && newValue.inputValue) {
                            formik.setFieldValue(
                              "trade_configuration.billing_gst",
                              //@ts-ignore
                              newValue?.gstinId
                            );
                          } else {
                            formik.setFieldValue(
                              "trade_configuration.billing_gst",
                              //@ts-ignore
                              newValue?.gstinId
                            );
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          const isExisting = options.some(
                            (option) => inputValue === option.gstinId
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              gstinId: `${inputValue}`,
                            });
                          }
                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={allActiveGST}
                        getOptionLabel={(option) => {
                          if (option && typeof option === "string") {
                            return option;
                          }
                          if (option && option.inputValue) {
                            return option.inputValue;
                          }
                          return option && option.gstinId;
                        }}
                        renderOption={(props, option) => (
                          <li {...props}>{option.gstinId}</li>
                        )}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={
                              formik.values.trade_configuration.billing_gst
                            }
                            error={
                              formik.values.trade_configuration.billing_gst &&
                              !GSTRegex.test(
                                formik.values.trade_configuration.billing_gst
                              )
                            }
                          />
                        )}
                      />
                    ) : (
                      allTradeData?.company?.billing_gst || "-"
                    )}
                  </TableCell>
                  <TableCell>
                    {allTradeData?.status !== "OFFLINE" && isEditing ? (
                      <TextField
                        name="trade_configuration.billing_address"
                        id="trade_configuration.billing_address"
                        placeholder="Enter Billing address"
                        variant="outlined"
                        value={
                          formik.values.trade_configuration.billing_address
                        }
                        onChange={formik.handleChange}
                        fullWidth
                      />
                    ) : (
                      allTradeData?.company.billing_address || "-"
                    )}
                  </TableCell>
                  <TableCell>
                    {allTradeData?.status !== "OFFLINE" && isEditing ? (
                      <TextField
                        name="trade_configuration.remarks"
                        id="trade_configuration.remarks"
                        placeholder="Enter Remarks"
                        variant="outlined"
                        value={formik.values.trade_configuration.remarks}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                    ) : (
                      allTradeData?.remarks || "-"
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {downloadAPALoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Tooltip
                        arrow
                        title={
                          allTradeData?.apa === null
                            ? "Sorry No APA uploaded"
                            : `Uploaded On : ${
                                allTradeData?.apa?.created_at &&
                                format(
                                  new Date(allTradeData?.apa?.created_at),
                                  "dd/MM/yyyy"
                                )
                              }`
                        }
                      >
                        <span>
                          <Button
                            onClick={() => {
                              downloadUploadFile({
                                variables: {
                                  file_registry_id:
                                    allTradeData?.apa?.signed_file_id,
                                },
                              });
                            }}
                            disabled={allTradeData?.apa === null}
                          >
                            View APA &nbsp;
                            <ExclamationCircleIcon
                              color={
                                allTradeData?.apa === null
                                  ? "rgba(0,0,0,0.26)"
                                  : "#1E88E5"
                              }
                              width="18px"
                              height="18px"
                            />
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </TableWrapper>
      </Grid>
      <br />
      <br />
    </>
  );
};

export default DetailsContainer;
