import React, { useContext } from "react";
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  Pagination,
  styled,
  IconButton,
  CircularProgress,
  Tooltip,
  Link,
  TablePagination,
  Menu,
  MenuItem,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { TableWrapper } from "../../theme/customComponents";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { IdPrefixFormat } from "../../data-access/formatter";
import {
  useGenerateAnnexureMutation,
  useGenerateMisReportMutation,
  useGetGlobalTradesQuery,
} from "../../data-access/hooks/company/deals-trade.hooks";
import {
  Annexure_Type,
  Mis_Report_Type,
  Order_By,
} from "../../data-access/types";
import { ReactComponent as DownloadIcon } from "../../assets/Icon/Outline/download-icon.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/Icon/Outline/chevron-down-icon.svg";
import CommonLoader from "../../components/CommonLoader";
import { CompanyContext } from "../../contexts/CompanyContext";
import TableStatus from "../../components/TableStatus";
import { NavLink } from "react-router-dom";
import SimpleInput from "../../components/input/SimpleInput";
import CustomMISModal from "./CustomMISModal";

const TableCell = styled(MuiTableCell)`
  padding: 2px 14px;
  font-size: 14px !important;
`;

const GlobalTrades = () => {
  const navigate = useNavigate();

  const [allTrades, setAllTrades] = React.useState<any[]>([]);

  const [searchText, setSearchText] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [showCustomModal, setShowCustomModal] = React.useState(false);

  const { setShowAlert } = useContext(CompanyContext);

  const [selectedTrade, setSelectedTrade] = React.useState<number>();

  const [paginationData, setPaginationData] = React.useState({
    offset: 0,
    limit: 50,
    totalcount: 0,
    currentPage: 1,
  });

  /**************************************************************************************************************************************** */

  const [handleDownloadMISReport] = useGenerateMisReportMutation({
    onCompleted: (data) => {
      const downloadUrl = data?.GenerateMISReport?.download_url;
      const link = document.createElement("a");
      //@ts-ignore
      link.href = downloadUrl;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      if (link && link.parentNode) {
        link.parentNode.removeChild(link);
      }
    },
    onError: (item) => {
      setShowAlert({
        show: true,
        message: item.message,
        type: "error",
      });
    },
  });

  /**************************************************************************************************************************************** */
  const { loading: tradeLoading } = useGetGlobalTradesQuery({
    variables: {
      limit: paginationData.limit,
      offset: paginationData.offset,
      order_by: {
        id: Order_By.Desc,
      },
      where: {
        _or: [
          {
            company: {
              name: {
                _ilike: `%${searchText}%`,
              },
            },
          },
          {
            id: {
              _eq: Number(searchText) || 0,
            },
          },
        ],
      },
    },

    onCompleted: (item) => {
      setPaginationData({
        ...paginationData,
        totalcount: Number(item.trade_aggregate.aggregate?.count),
      });
      setAllTrades(item.trade);
    },
    fetchPolicy: "no-cache",
  });

  //*************************************************************************************************** */
  const [handleAnnexure, { loading: APALoading }] = useGenerateAnnexureMutation(
    {
      onCompleted: (item) => {
        const downloadUrl = item?.GenerateAnnexure?.download_url;
        const link = document.createElement("a");
        link.href = downloadUrl ?? "";
        link?.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      },
      onError: (item) => {
        setShowAlert({
          show: true,
          type: "error",
          message: item?.message,
        });
      },
    }
  );

  /**************************************************************************************************************************************** */
  const pageCount = (number: number) => {
    return Math.ceil(number / paginationData.limit);
  };

  return (
    <>
      <Helmet title="Global Trade" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid
          item
          justifyContent="space-between"
          display={"flex"}
          alignItems="center"
          container
          width={"100%"}
        >
          <Grid item xs={2}>
            <Typography fontSize={"24px"} fontWeight={600}>
              Trades
            </Typography>
          </Grid>
          <Grid item xs={10} display={"flex"} alignItems="center" gap="8px">
            <SimpleInput
              style={{
                width: "22%",
                background: "#ffffff",
                marginLeft: "auto",
              }}
              placeholder="Search with company name or trade id"
              onChange={(event: any) => {
                setSearchText(event.target.value);
              }}
            />
            <Button
              variant="dropdownButton"
              onClick={(event: any) => {
                setAnchorEl(event.currentTarget);
              }}
              style={{
                whiteSpace: "nowrap",

                display: "flex",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              Download MIS
              <ChevronDownIcon
                style={{
                  minHeight: 20,
                  minWidth: 20,
                }}
                color="#1E88E5 "
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  handleDownloadMISReport({
                    variables: {
                      args: {
                        request_type: Mis_Report_Type.All,
                      },
                    },
                  });
                }}
              >
                All
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  handleDownloadMISReport({
                    variables: {
                      args: {
                        request_type: Mis_Report_Type.LastMonth,
                      },
                    },
                  });
                }}
              >
                Last Month
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setShowCustomModal(true);
                }}
              >
                Custom
              </MenuItem>
            </Menu>{" "}
          </Grid>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" style={{ margin: "16px 0 28px 0" }} />
      {tradeLoading ? (
        <CommonLoader />
      ) : (
        <Grid item>
          <Paper>
            <TableWrapper
              style={{
                maxHeight: "calc(100vh - 244px)",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "20%" }}>Trade ID</TableCell>
                    <TableCell style={{ width: "20%" }}>Company ID</TableCell>
                    <TableCell style={{ width: "20%" }}>Company Name</TableCell>
                    <TableCell style={{ width: "20%" }}>Tenure</TableCell>
                    <TableCell style={{ width: "20%" }}>Trade Amount</TableCell>
                    <TableCell style={{ width: "20%" }}>EMI Amount</TableCell>
                    <TableCell style={{ width: "20%" }}>Investors</TableCell>
                    <TableCell style={{ width: "20%" }}>Status</TableCell>
                    <TableCell style={{ width: "20%" }}>
                      EMI Start Date
                    </TableCell>
                    <TableCell style={{ width: "20%" }}>ROI</TableCell>
                    <TableCell style={{ width: "20%" }}>EMI Count</TableCell>
                    <TableCell style={{ width: "20%" }}>
                      BridgeUp fees
                    </TableCell>

                    <TableCell style={{ width: "20%" }}>Mandate ID</TableCell>
                    <TableCell style={{ width: "20%" }}>Generate APA</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allTrades.map((item, index) => (
                    <TableRow key={index} id-={`trade-${index}`}>
                      <TableCell style={{ width: "20%" }}>
                        {(
                          <Button
                            style={{
                              textDecoration: "underline",
                              color: "black",
                            }}
                            onClick={() =>
                              navigate(
                                `/company/${item?.company_id}/trade/${item?.id}`,
                                {
                                  state: {
                                    company: item?.company_id,
                                    dealId: item?.deal_id,
                                    data: item,
                                  },
                                }
                              )
                            }
                          >
                            {item?.id && IdPrefixFormat(item?.id, "T")}
                          </Button>
                        ) || "-"}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {(
                          <Button
                            style={{
                              textDecoration: "underline",
                              color: "black",
                            }}
                            onClick={() =>
                              navigate(`/company/${item?.company_id}/details`)
                            }
                          >
                            {item.company_id}
                          </Button>
                        ) || "-"}
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap", width: "20%" }}>
                        <Tooltip
                          title={item?.company.name ?? "-"}
                          arrow
                          placement="right"
                        >
                          {(
                            <Typography whiteSpace={"nowrap"}>
                              {item?.company.name === null
                                ? "-"
                                : `${item?.company.name?.substring(0, 8)}...`}
                            </Typography>
                          ) || "-"}
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {item.validity || "-"}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {item.emi_amount?.toFixed(2) || "-"}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {item.payout_value?.toFixed(2) || "-"}
                      </TableCell>
                      {}
                      <TableCell style={{ width: "20%" }}>
                        {item.investments.map((item: any, index: any) => (
                          <Link
                            key={index}
                            component={NavLink}
                            to={`/investor/${item.investor.id}/details`}
                            style={{
                              color: "#000000",
                              textDecoration: "underline",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Tooltip
                              title={item?.investor.name ?? "-"}
                              arrow
                              placement="right"
                            >
                              {(
                                <Typography whiteSpace={"nowrap"}>
                                  {item?.investor.name === null
                                    ? "-"
                                    : `${item?.investor.name?.substring(
                                        0,
                                        8
                                      )}...`}
                                </Typography>
                              ) || "-"}
                            </Tooltip>
                          </Link>
                        ))}
                      </TableCell>
                      <TableCell style={{ width: "20%", whiteSpace: "nowrap" }}>
                        <TableStatus
                          style={{
                            padding: "3px 6px",
                            fontSize: 12,
                            fontWeight: 600,
                            borderRadius: 4,
                          }}
                          stautsText={`${item?.status}`}
                        />
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {item?.emi_start_date
                          ? format(
                              Date.parse(item?.emi_start_date),
                              "dd LLL yyyy"
                            )
                          : "-"}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {item.roi || "-"}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {item.validity || "-"}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {item.fees?.toFixed(2) || "-"}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {item.mandate?.id || "-"}
                      </TableCell>
                      <TableCell style={{ width: "20%" }} align="right">
                        <Tooltip
                          title={
                            item.status !== "CONTRACT_SELECTED"
                              ? "The trade status should be Contract Selected "
                              : ""
                          }
                        >
                          <span>
                            <IconButton
                              disabled={item.status !== "CONTRACT_SELECTED"}
                              color="primary"
                              onClick={() => {
                                setSelectedTrade(item?.id);
                                handleAnnexure({
                                  variables: {
                                    trade_id: item?.id,
                                    annexure_type: "APA" as Annexure_Type,
                                  },
                                });
                              }}
                            >
                              {selectedTrade === item.id && APALoading ? (
                                <CircularProgress size={16} />
                              ) : (
                                <DownloadIcon
                                  color={
                                    item.status !== "CONTRACT_SELECTED"
                                      ? "#E0E0E0"
                                      : "#1E88E5"
                                  }
                                  width={16}
                                  height={16}
                                />
                              )}
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
            {paginationData?.totalcount > 10 && (
              <TablePagination
                colSpan={20}
                count={pageCount(paginationData?.totalcount ?? 0)}
                onPageChange={(event: any, value: any) => {
                  const newOffset =
                    paginationData?.limit * value - paginationData?.limit;
                  setPaginationData({
                    offset: newOffset,
                    limit: paginationData?.limit,
                    totalcount: paginationData?.totalcount,
                    currentPage: value,
                  });
                }}
                component="div"
                page={paginationData?.currentPage}
                rowsPerPage={paginationData.limit}
                rowsPerPageOptions={[10, 20, 50]}
                labelDisplayedRows={() => (
                  <div style={{ display: "none" }}></div>
                )}
                labelRowsPerPage={"Rows:"}
                onRowsPerPageChange={(event) => {
                  setPaginationData({
                    ...paginationData,
                    limit: Number(event.target.value),
                    offset: 0,
                    currentPage: 1,
                  });
                }}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                ActionsComponent={() => (
                  <Pagination
                    count={pageCount(paginationData?.totalcount ?? 0)}
                    variant={"outlined"}
                    page={paginationData?.currentPage}
                    onChange={(event: any, value: any) => {
                      const newOffset =
                        paginationData?.limit * value - paginationData?.limit;
                      setPaginationData({
                        offset: newOffset,
                        limit: paginationData?.limit,
                        totalcount: paginationData?.totalcount,
                        currentPage: value,
                      });
                    }}
                  />
                )}
              />
            )}
          </Paper>
        </Grid>
      )}

      <CustomMISModal open={showCustomModal} setOpen={setShowCustomModal} />
    </>
  );
};

export default GlobalTrades;
