import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DownloadCamReportMutationVariables = Types.Exact<{
  company_id: Types.Scalars['Int'];
}>;


export type DownloadCamReportMutation = { __typename?: 'mutation_root', runScoringEngine?: { __typename?: 'RunScoringEngineOutput', message: string } | null };

export type SendCompanyClientAgreementMutationVariables = Types.Exact<{
  company_id: Types.Scalars['Int'];
}>;


export type SendCompanyClientAgreementMutation = { __typename?: 'mutation_root', TriggerCompanyClientAgreement?: { __typename?: 'TriggerCompanyClientAgreementOutput', message: string } | null };

export type FetchHistoricalAccountingDataMutationVariables = Types.Exact<{
  company_id: Types.Scalars['Int'];
}>;


export type FetchHistoricalAccountingDataMutation = { __typename?: 'mutation_root', fetchHistoricalAccountingData?: { __typename?: 'FetchHistoricalAccountingDataOutput', message: string } | null };


export const DownloadCamReportDocument = gql`
    mutation downloadCamReport($company_id: Int!) {
  runScoringEngine(args: {company_id: $company_id}) {
    message
  }
}
    `;
export type DownloadCamReportMutationFn = Apollo.MutationFunction<DownloadCamReportMutation, DownloadCamReportMutationVariables>;

/**
 * __useDownloadCamReportMutation__
 *
 * To run a mutation, you first call `useDownloadCamReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadCamReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadCamReportMutation, { data, loading, error }] = useDownloadCamReportMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useDownloadCamReportMutation(baseOptions?: Apollo.MutationHookOptions<DownloadCamReportMutation, DownloadCamReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadCamReportMutation, DownloadCamReportMutationVariables>(DownloadCamReportDocument, options);
      }
export type DownloadCamReportMutationHookResult = ReturnType<typeof useDownloadCamReportMutation>;
export type DownloadCamReportMutationResult = Apollo.MutationResult<DownloadCamReportMutation>;
export type DownloadCamReportMutationOptions = Apollo.BaseMutationOptions<DownloadCamReportMutation, DownloadCamReportMutationVariables>;
export const SendCompanyClientAgreementDocument = gql`
    mutation sendCompanyClientAgreement($company_id: Int!) {
  TriggerCompanyClientAgreement(args: {company_id: $company_id}) {
    message
  }
}
    `;
export type SendCompanyClientAgreementMutationFn = Apollo.MutationFunction<SendCompanyClientAgreementMutation, SendCompanyClientAgreementMutationVariables>;

/**
 * __useSendCompanyClientAgreementMutation__
 *
 * To run a mutation, you first call `useSendCompanyClientAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCompanyClientAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCompanyClientAgreementMutation, { data, loading, error }] = useSendCompanyClientAgreementMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useSendCompanyClientAgreementMutation(baseOptions?: Apollo.MutationHookOptions<SendCompanyClientAgreementMutation, SendCompanyClientAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCompanyClientAgreementMutation, SendCompanyClientAgreementMutationVariables>(SendCompanyClientAgreementDocument, options);
      }
export type SendCompanyClientAgreementMutationHookResult = ReturnType<typeof useSendCompanyClientAgreementMutation>;
export type SendCompanyClientAgreementMutationResult = Apollo.MutationResult<SendCompanyClientAgreementMutation>;
export type SendCompanyClientAgreementMutationOptions = Apollo.BaseMutationOptions<SendCompanyClientAgreementMutation, SendCompanyClientAgreementMutationVariables>;
export const FetchHistoricalAccountingDataDocument = gql`
    mutation fetchHistoricalAccountingData($company_id: Int!) {
  fetchHistoricalAccountingData(args: {company_id: $company_id}) {
    message
  }
}
    `;
export type FetchHistoricalAccountingDataMutationFn = Apollo.MutationFunction<FetchHistoricalAccountingDataMutation, FetchHistoricalAccountingDataMutationVariables>;

/**
 * __useFetchHistoricalAccountingDataMutation__
 *
 * To run a mutation, you first call `useFetchHistoricalAccountingDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchHistoricalAccountingDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchHistoricalAccountingDataMutation, { data, loading, error }] = useFetchHistoricalAccountingDataMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useFetchHistoricalAccountingDataMutation(baseOptions?: Apollo.MutationHookOptions<FetchHistoricalAccountingDataMutation, FetchHistoricalAccountingDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FetchHistoricalAccountingDataMutation, FetchHistoricalAccountingDataMutationVariables>(FetchHistoricalAccountingDataDocument, options);
      }
export type FetchHistoricalAccountingDataMutationHookResult = ReturnType<typeof useFetchHistoricalAccountingDataMutation>;
export type FetchHistoricalAccountingDataMutationResult = Apollo.MutationResult<FetchHistoricalAccountingDataMutation>;
export type FetchHistoricalAccountingDataMutationOptions = Apollo.BaseMutationOptions<FetchHistoricalAccountingDataMutation, FetchHistoricalAccountingDataMutationVariables>;
export const namedOperations = {
  Mutation: {
    downloadCamReport: 'downloadCamReport',
    sendCompanyClientAgreement: 'sendCompanyClientAgreement',
    fetchHistoricalAccountingData: 'fetchHistoricalAccountingData'
  }
}