import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  Pagination,
  Paper,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { useParams } from "react-router-dom";
import CommonBreadCrumb from "../../components/CommonBreadCrumb";
import SimpleInput from "../../components/input/SimpleInput";
import { ReactComponent as SearchIcon } from "../../assets/Icon/Outline/search-icon.svg";
import { useState } from "react";
import { useGetAllTransactionsQuery } from "../../data-access/hooks/company/emi-calendar.hooks";
import CommonLoader from "../../components/CommonLoader";
import { format } from "date-fns";
import { IdPrefixFormat } from "../../data-access/formatter";
import { ReactComponent as ArrowRightIcon } from "../../assets/Icon/Outline/arrow-right-icon.svg";
import ManualPayment from "./ManualPayment";
import { Order_By } from "../../data-access/types";
import EmptyData from "../../components/EmptyData";
import TableStatus from "../../components/TableStatus";
import { TableWrapper } from "../../theme/customComponents";

interface TransactionListInterface {
  id?: number;
  amount?: number;
  trade_id: any;
  transaction_status: any;
  transaction_type: any;
  transaction_method: any;
  timestamp: any;
  trade_installment_seq: any;
  scheduled_emi_id: any;
  company_id: any;
}

const Transaction = () => {
  const { id } = useParams();

  const pageCount = (number: number) => {
    return Math.ceil(number / 10);
  };

  const [allTransactionData, setAllTransactionData] =
    useState<TransactionListInterface[]>();

  const [whereClause, setWhereClause] = useState<any>({
    trade: {
      company: {
        id: {
          _eq: Number(id),
        },
      },
    },
  });

  const [paginationData, setPaginationData] = useState({
    offset: 0,
    limit: 50,
    totalcount: 0,
    currentPage: 1,
  });

  const [searchText, setSearchText] = useState("");

  const [manualModal, setManualModal] = useState({
    show: false,
    data: {},
  });

  const { loading: getAllLoading, refetch: transactionRefetch } =
    useGetAllTransactionsQuery({
      variables: {
        where: whereClause,
        limit: paginationData.limit,
        offset: paginationData.offset,
        agg_where: {
          trade: {
            company: {
              id: {
                _eq: Number(id),
              },
            },
          },
        },
        order_by: {
          timestamp: Order_By.Desc,
        },
      },
      fetchPolicy: "no-cache",
      onCompleted: (transactionList) => {
        const data = transactionList?.transactions_view?.map((item) => {
          return {
            id: item?.id,
            amount: item?.amount,
            trade_id: item?.trade_id,
            transaction_status: item?.transaction_status,
            transaction_type: item?.transaction_type,
            transaction_method: item?.transaction_method,
            timestamp: item?.timestamp,
            scheduled_emi_id: item?.scheduled_emi_id,
            trade_installment_seq: item?.trade_installment_seq,
            company_id: item?.trade?.company?.id,
          };
        });
        setAllTransactionData(data);
        setPaginationData({
          ...paginationData,
          totalcount: Number(
            transactionList.transactions_view_aggregate.aggregate?.count
          ),
        });
      },
    });

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Grid item>
            <CommonBreadCrumb
              typographyText="Transactions"
              linksText={[{ link: "EMI Calender", to: "/emi" }]}
              showCompany
            />
          </Grid>
          <Grid item xs={4}>
            <SimpleInput
              fullWidth
              placeholder="Search using Trade ID"
              style={{
                background: "#ffffff",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    style={{ paddingLeft: "10px" }}
                    position="start"
                  >
                    <SearchIcon width="16px" height="16px" />
                  </InputAdornment>
                ),
              }}
              onChange={(event: React.FormEvent<HTMLInputElement>) => {
                setSearchText(event.currentTarget.value);
                if (event.currentTarget.value) {
                  setWhereClause({
                    trade: {
                      company: {
                        id: {
                          _eq: Number(id),
                        },
                      },
                    },
                    trade_id: {
                      _eq: Number(event.currentTarget.value),
                    },
                  });
                } else {
                  setWhereClause({
                    trade: {
                      company: {
                        id: {
                          _eq: Number(id),
                        },
                      },
                    },
                    trade_id: {},
                  });
                }
              }}
              value={searchText}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt="16px" mb="28px">
          <Divider />
        </Grid>
        {allTransactionData?.length === 0 ? (
          <Grid item xs={12}>
            <Paper>
              <EmptyData />
            </Paper>
          </Grid>
        ) : (
          <Grid item xs={12}>
            {getAllLoading ? (
              <CommonLoader />
            ) : (
              <TableWrapper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Trade ID</TableCell>
                      <TableCell>EMI Date</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Method</TableCell>
                      <TableCell>EMI Number</TableCell>
                      <TableCell>EMI Sequence</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allTransactionData?.map((dataItem, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {IdPrefixFormat(dataItem?.trade_id, "T")}
                        </TableCell>
                        <TableCell>
                          {format(new Date(dataItem?.timestamp), "dd LLL yyyy")}
                        </TableCell>
                        <TableCell>{dataItem?.transaction_type}</TableCell>
                        <TableCell>{dataItem?.amount?.toFixed(2)}</TableCell>
                        <TableCell>
                          {TableStatus({
                            stautsText: `${dataItem?.transaction_status}`,
                          })}
                        </TableCell>
                        <TableCell>{dataItem?.transaction_method}</TableCell>
                        <TableCell>{dataItem?.scheduled_emi_id}</TableCell>
                        <TableCell>{dataItem?.trade_installment_seq}</TableCell>
                        <TableCell>
                          {["FAILED_CREDIT", "FAILED_DEBIT"].includes(
                            dataItem?.transaction_status
                          ) && (
                            <Button
                              onClick={() => {
                                setManualModal({
                                  data: dataItem,
                                  show: true,
                                });
                              }}
                            >
                              Manual Payment&nbsp;{" "}
                              <ArrowRightIcon width={16} height={16} />
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableWrapper>
            )}
            {allTransactionData?.length !== 0 && (
              <Pagination
                count={
                  paginationData?.totalcount &&
                  pageCount(paginationData?.totalcount)
                }
                variant={"outlined"}
                page={paginationData?.currentPage}
                onChange={(event: any, value: any) => {
                  const newOffset = 10 * value - 10;
                  setPaginationData({
                    offset: newOffset,
                    limit: paginationData?.limit,
                    totalcount: paginationData?.totalcount,
                    currentPage: value,
                  });
                }}
              />
            )}
          </Grid>
        )}
      </Grid>

      {manualModal.show && (
        <ManualPayment
          setOpen={setManualModal}
          open={manualModal}
          data={manualModal.data as any}
          refetch={transactionRefetch}
        />
      )}
    </>
  );
};

export default Transaction;
