import {
  Divider as MuiDivider,
  Typography,
  Paper,
  Grid,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { ReactComponent as DownloadIcon } from "../../../assets/Icon/Outline/download-icon.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/Icon/Outline/document-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/Icon/Outline/trash-icon.svg";
import {
  useDownloadUploadedFileLazyQuery,
  useUploadSubscriptionDataMutation,
} from "../../../data-access/hooks/company/payment-partner.hooks";
import CommonBreadCrumb from "../../../components/CommonBreadCrumb";
import { CompanyContext } from "../../../contexts/CompanyContext";

const Divider = styled(MuiDivider)(spacing);

const AddPaymentData = () => {
  const { id } = useParams();

  const { setShowAlert } = useContext(CompanyContext);

  const navigate = useNavigate();

  const [secureResponse, setSecureResponse] = useState({
    response: "",
    selectedFile: {} as any,
  });

  const [uploadedNewFile, setUploadNewFile] = useState([]);

  const onDrop = useCallback((acceptedFiles: any) => {
    setUploadLoading(true);
    setUploadNewFile(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/csv": [".csv"],
    },
    multiple: false,
    onDrop,
    maxFiles: 1,
  });

  const [uploadLoading, setUploadLoading] = useState(false);

  const uploadFile = async (selectedFile: File) => {
    const formData = new FormData();

    formData.append("file", selectedFile, selectedFile.name);

    formData.append("category_id", "SUBSCRIPTION");

    formData.append("password", "");

    const token = localStorage.getItem("accessToken");

    const config: any = {
      method: "post",
      url: `${process.env.REACT_APP_ACTIONS_BASE_URL}secure-upload`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        "X-hasura-tenant-id": id,
      },
    };

    await axios(config)
      .then((response) => {
        setShowAlert({
          show: true,
          message: "The file is successfully uploaded",
          type: "success",
        });
        setSecureResponse({
          response: response?.data?.file_registry_id,
          selectedFile,
        });
        setUploadLoading(false);
      })
      .catch((error) => {
        setShowAlert({
          show: true,
          message: error.response.data.message,
          type: "error",
        });
        setUploadLoading(false);
      });
  };

  useEffect(() => {
    if (uploadedNewFile.length !== 0) {
      uploadFile(uploadedNewFile[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedNewFile]);

  const [uploadSelectedFile, { loading }] = useUploadSubscriptionDataMutation({
    onCompleted: (item: any) => navigate(`/company/${id}/payment-partner`),
    onError: (item: any) => console.log("item", item),
  });

  const [downloadUploadFile, { loading: downloadLoading }] =
    useDownloadUploadedFileLazyQuery({
      onCompleted: (item: any) => {
        const downloadUrl = item?.generateFileRegistryDownloadURL?.download_url;
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      },
      onError: (error) => {
        setShowAlert({
          show: true,
          message: error.message,
          type: "error",
        });
      },
    });

  return (
    <>
      <Helmet title="Company Dashboard" />
      <Grid container display={"flex"} flexDirection={"column"}>
        <Grid
          item
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <CommonBreadCrumb
              typographyText="  Payment Partner Data"
              linksText={[
                { link: "Company Queues", to: "/" },
                {
                  link: "Payment Partner",
                  to: `/company/${id}/payment-partner`,
                },
              ]}
              showCompany
            />
          </Grid>
        </Grid>
        <Divider mt="16px" mb="28px" />
        <Grid item>
          <Grid
            container
            item
            display={"flex"}
            flexDirection={"column"}
            width={"50%"}
            gap={"25px"}
          >
            <Grid item>
              <Typography fontWeight={600} fontSize={"18px"}>
                Payment Partner File
              </Typography>
            </Grid>
            <Paper>
              <Grid
                container
                padding={"20px"}
                display={"flex"}
                flexDirection={"column"}
                gap={"25px"}
                alignItems={"flex-start"}
              >
                <Grid
                  item
                  width="100%"
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  {uploadLoading ? (
                    <CircularProgress />
                  ) : (
                    <div
                      style={{
                        background: "#1E88E50A",
                        width: "100%",
                        border: "dashed 1px #1E88E5",
                        borderRadius: "11px",
                        height: "120px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div>
                        <div>
                          <Typography fontWeight={600} fontSize={"16px"}>
                            Drag and drop or
                            <span
                              style={{
                                color: "#1E88E5",
                              }}
                            >
                              &nbsp; Browse
                            </span>
                          </Typography>
                          <Typography
                            fontWeight={400}
                            fontSize={"14px"}
                            color={"#7D87A6"}
                          >
                            {"Supported file type  CSV"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}
                  {secureResponse.response !== "" && (
                    <Grid container display={"flex"} flexDirection={"column"}>
                      <Typography
                        fontWeight={600}
                        fontSize={"14px"}
                        margin={"10px 0"}
                      >
                        Your File
                      </Typography>
                      <Grid
                        item
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Grid item display={"flex"} alignItems={"center"}>
                          <DocumentIcon
                            width={16}
                            height={16}
                            color="#1E88E5"
                          />
                          <Typography
                            fontWeight={400}
                            fontSize={"14px"}
                            margin={"0 5px"}
                          >
                            {secureResponse?.selectedFile?.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          display={"flex"}
                          alignItems={"center"}
                          gap="4px"
                        >
                          {downloadLoading ? (
                            <CircularProgress size={16} />
                          ) : (
                            <IconButton
                              color="primary"
                              onClick={() =>
                                downloadUploadFile({
                                  variables: {
                                    //@ts-ignore
                                    file_registry_id: secureResponse?.response,
                                  },
                                })
                              }
                            >
                              <DownloadIcon
                                width={16}
                                height={16}
                                color="#1E88E5"
                              />
                            </IconButton>
                          )}
                          <IconButton
                            color="primary"
                            onClick={() =>
                              setSecureResponse({
                                response: "",
                                selectedFile: {},
                              })
                            }
                          >
                            <DeleteIcon
                              width={16}
                              height={16}
                              color="#FF6363"
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item width="100%">
                  <Button
                    onClick={() =>
                      uploadSelectedFile({
                        variables: {
                          file_id: parseInt(secureResponse?.response),
                        },
                      })
                    }
                    disabled={secureResponse?.response === "" || loading}
                    fullWidth
                    variant="primaryLarge"
                  >
                    {loading ? <CircularProgress size={18} /> : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPaymentData;
