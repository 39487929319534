import {
  Button,
  Divider,
  FormControl,
  Grid,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import SimpleInput from "../../../components/input/SimpleInput";
import {
  useCreateCompanySignatoryMutation,
  useUpdateCompanySignatoryByIdMutation,
} from "../../../data-access/hooks/company/company-details.hooks";
import SideModal from "../../../components/Modals/SideModal";
import { useState } from "react";

interface AuthorizedSignatoryModalInterface {
  setOpen?: any;
  companyId?: any;
  open?: any;
  signatoryFetch?: any;
  setUpdateData?: any;
  updateData?: any;
}

const SignatoryModal = (props: AuthorizedSignatoryModalInterface) => {
  const {
    setOpen,
    open,
    updateData,
    companyId,
    signatoryFetch,
    setUpdateData,
  } = props;

  const [showAlert, setShowAlert] = useState({
    show: false,
    type: "info" as any,
    message: "",
  });
  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Full name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Please entervalid mail id."),
    mobile_number: Yup.string()
      .required("Mobile Number is required")
      .length(10, "Mobile number should be 10 digits"),
    designation: Yup.string().required("Designation is required"),
  });

  /********************************************************************************************************************* */
  const formik = useFormik({
    initialValues: {
      full_name: updateData ? updateData?.full_name : "",
      email: updateData ? updateData?.email : "",
      mobile_number: updateData ? updateData?.mobile_number : "",
      designation: updateData ? updateData?.designation : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (updateData?.id) {
        handleUpdateSignatory({
          variables: {
            id: Number(updateData?.id),
            set: {
              full_name: values.full_name,
              email: values.email,
              mobile_number: `${values.mobile_number}`,
              designation: values.designation,
            },
          },
        });
      } else {
        handleNewSignatory({
          variables: {
            object: {
              full_name: values.full_name,
              email: values.email,
              mobile_number: `${values.mobile_number}`,
              company_id: Number(companyId),
              designation: values.designation,
            },
          },
        });
      }
    },
  });

  /********************************************************************************************************************* */
  const [handleNewSignatory, { loading: createLoading }] =
    useCreateCompanySignatoryMutation({
      onCompleted: () => {
        signatoryFetch();
        setOpen(false);
        setUpdateData({});
      },
      onError: (error) => {
        setShowAlert({
          show: true,
          type: "error",
          message: error.message,
        });
      },
    });

  /********************************************************************************************************************* */
  const [handleUpdateSignatory, { loading: updateLoading }] =
    useUpdateCompanySignatoryByIdMutation({
      onCompleted: () => {
        signatoryFetch();
        setOpen(false);
        setUpdateData({});
      },
      onError: (error) => {
        setShowAlert({
          show: true,
          type: "error",
          message: error.message,
        });
      },
    });

  return (
    <SideModal
      modalTitle={updateData?.id ? "Update Signatory" : "Add Signatory"}
      open={open}
      onClose={() => setOpen(false)}
      footer={
        <Button
          type="submit"
          form="signatory-form"
          fullWidth
          variant="primarySmall"
          disabled={createLoading || updateLoading}
        >
          {createLoading || updateLoading ? (
            <CircularProgress size={20} />
          ) : (
            "Submit"
          )}
        </Button>
      }
    >
      <Grid
        container
        style={{ background: "white" }}
        justifyContent="center"
        alignItems={"center"}
        display="flex"
        flexDirection={"column"}
      >
        {showAlert.show && (
          <Grid item my={2}>
            <Alert severity={showAlert.type}>{showAlert.message}</Alert>
          </Grid>
        )}
        <form
          onSubmit={formik.handleSubmit}
          id="signatory-form"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <Grid item>
            <FormControl fullWidth>
              <SimpleInput
                inputlabel="Full Name"
                name="full_name"
                placeholder="Enter full name"
                onChange={formik.handleChange}
                error={
                  formik.touched.full_name && Boolean(formik.errors.full_name)
                }
                value={formik.values.full_name}
                onBlur={formik.handleBlur}
                helperText={formik.touched.full_name && formik.errors.full_name}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <SimpleInput
                inputlabel="Email"
                placeholder="Enter email"
                name="email"
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <SimpleInput
                inputlabel="Mobile Number"
                placeholder="Enter mobile number"
                type={"number"}
                name="mobile_number"
                onChange={formik.handleChange}
                error={
                  formik.touched.mobile_number &&
                  Boolean(formik.errors.mobile_number)
                }
                value={formik.values.mobile_number}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.mobile_number && formik.errors.mobile_number
                }
                InputProps={{
                  startAdornment: (
                    <div style={{ display: "flex", marginLeft: "10px" }}>
                      +91
                      <Divider
                        orientation="vertical"
                        flexItem
                        style={{ marginLeft: "10px" }}
                      ></Divider>
                    </div>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <SimpleInput
                inputlabel="Designation"
                placeholder="Enter designation"
                name="designation"
                onChange={formik.handleChange}
                error={
                  formik.touched.designation &&
                  Boolean(formik.errors.designation)
                }
                value={formik.values.designation}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.designation && formik.errors.designation
                }
              />
            </FormControl>
          </Grid>
        </form>
      </Grid>
    </SideModal>
  );
};

export default SignatoryModal;
