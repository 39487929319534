import { createContext, ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCompanyDetailByIdLazyQuery } from "../data-access/hooks/company/company-details.hooks";
import { Alert, AlertColor, Snackbar } from "@mui/material";

const CompanyContext = createContext<any>(null);

function CompanyProvider({ children }: { children: ReactNode }) {
  const params = useParams();

  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
    type: "info" as AlertColor,
  });

  const [companyData, setCompanyData] = useState<any>();

  const [handleGetCompanyDetails, { loading: getDataLoading }] =
    useCompanyDetailByIdLazyQuery({
      onCompleted: (item: any) => {
        setCompanyData(item.company_by_pk);
      },
    });

  useEffect(() => {
    if (params?.id) {
      localStorage.setItem("tenantId", params?.id);
      handleGetCompanyDetails({
        variables: {
          //@ts-ignore
          id: params?.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleClose = () => {
    setShowAlert({
      show: false,
      message: "",
      type: "info",
    });
  };

  const values = {
    companyData,
    showAlert,
    setShowAlert,
    setCompanyData,
    getDataLoading,
  };
  return (
    <CompanyContext.Provider value={values}>
      {children}
      <Snackbar
        open={showAlert?.show}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={handleClose}
          severity={showAlert?.type as AlertColor}
          sx={{ width: "100%" }}
          variant="outlined"
        >
          {showAlert?.message}
        </Alert>
      </Snackbar>
    </CompanyContext.Provider>
  );
}

export { CompanyContext, CompanyProvider };
