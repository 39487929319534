import {
  Paper,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Modal,
  Grid,
  Button,
  Badge,
  CircularProgress,
  FormControl,
  TextField as MuiTextField,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import FileSolidIcon from "../../../assets/svg/file-solid-icon.svg";
import "react-quill/dist/quill.snow.css";
import {
  useGetCompanyQueueFullDetailsQuery,
  useUpdateOpsQuestionsDataMutation,
} from "../../../data-access/hooks/company/company.hooks";
import { useParams } from "react-router-dom";
import OpsRemarkModal from "./OpsRemarkModal";
import SalesNotesModal from "./SalesNotesModal";
import { createStyles, makeStyles } from "@mui/styles";
import { TableWrapper } from "../../../theme/customComponents";
import CommonBreadCrumb from "../../../components/CommonBreadCrumb";
import CommonLoader from "../../../components/CommonLoader";

const ITEM_HEIGHT = 48;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5,
      width: 250,
      padding: "20px",
    },
  },
};

const TextField = styled(MuiTextField)`
  & .MuiOutlinedInput-input {
    padding: 0 5px;
    height: 40px;
  }
`;
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#FF5106",
    color: "#FF5106",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}));
const CustomTooltip = styled.span`
  background-color: #eaeaea;
  color: #000;
  width: 100%;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    margin-left: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #eaeaea transparent transparent;
  }
`;
const useStyles = makeStyles(() =>
  createStyles({
    customSelect: {
      padding: "0 40px 0 10px",
      height: "40px !important",
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    tooltip: {
      background: "#fff",
      boxShadow: "0px 4px 12px rgba(192, 191, 191)",
      color: "#000",
      padding: "15px",
      borderRadius: "7px",
      width: "113px",
    },
    arrow: {
      fontSize: 16,
      width: 17,
      "&::before": {
        border: "1px solid #FFF",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 12px rgba(192, 191, 191)",
      },
    },
    table: {
      minWidth: 650,
    },
    sticky: {
      position: "sticky",
      left: 0,
      background: "white",
      boxShadow: "5px 2px 5px grey",
      borderRight: "2px solid black",
    },
  })
);

const CompanyDetail = () => {
  const classes = useStyles();
  const [openSalesNoteModal, setOpenSalesNoteModal] = useState(false);

  const [openOpsNotesModal, setOpenOpsNotesModal] = useState(false);

  const [showOpsRemark, setShowOpsRemark] = useState("");

  const [lifeQuestionAnswerData, setLifeQuestionAnswerData] = useState<any>();

  const params = useParams();

  const [isEditingData, setIsEditingData] = useState(false);

  const [allDataTogether, setAllDataTogether] = useState<any>([
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "What is your company type?",
      question_id: "PQL1",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",

      question: "What was your company’s previous years’ revenue?",
      question_id: "PQL2",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Are you currently EBITDA profitable?",
      question_id: "PQL3",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "What is your outstanding debt?",
      question_id: "PQL4",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Are you Venture Funded?",
      question_id: "PQL5",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "What is your Net Worth?",
      question_id: "PQL6",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Purpose of capital?",
      question_id: "PQL7",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "What is your ask amount?",
      question_id: "PQL8",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Company Description",
      question_id: "SQL1",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Duration of Business",
      question_id: "SQL2",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Industry",
      question_id: "SQL3",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "City of HQ",
      question_id: "SQL4",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Entity Type",
      question_id: "SQL5",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Type of Clients",
      question_id: "SQL6",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Annual Revenue",
      question_id: "SQL7",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "PAT(Profit After Tax)",
      question_id: "SQL8",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Recurring/Re-occurring revenue?",
      question_id: "SQL9",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",

      question: "Existing debt, if any then what irr and from whom",
      question_id: "SQL10",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Have they received Debt Financing?",
      question_id: "SQL11",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Type of Financing Required?",
      question_id: "SQL12",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Willing to escrow receivables?",
      question_id: "SQL13",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Reasons for Disqualification",
      question_id: "SQL14",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Reasons for Disqualification",
      question_id: "SQL15",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Reasons for Disqualification",
      question_id: "SQL16",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Data Stages",
      question_id: "SQL17",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Company Classification (Hubspot)",
      question_id: "SQL18",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Reasons for Cold Lead",
      question_id: "SQL19",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Notes",
      question_id: "SQL20",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "District",
      question_id: "SQL21",
    },
    {
      prequestionary: "",
      sales_ans: "",
      ops_ans: "",
      ops_remark: "",
      question: "Incorporation Date",
      question_id: "SQL22",
    },
  ]);

  //*-------------------------------------------------------------------------
  const onHandleNotesInputChange = (question_id: any, data: any) => {
    setLifeQuestionAnswerData({
      pql_answers: lifeQuestionAnswerData.pql_answers,
      sales_answers: [
        {
          id: lifeQuestionAnswerData?.sales_answers[0]?.id,
          category: lifeQuestionAnswerData?.sales_answers[0]?.category,
          answer: lifeQuestionAnswerData?.sales_answers[0]?.answer.map(
            (childAnswers: any) => {
              return {
                question_id: childAnswers.question_id,
                notes: childAnswers.notes,
                answer:
                  childAnswers?.question_id === question_id
                    ? data
                    : childAnswers?.answer,
              };
            }
          ),
        },
      ],
      ops_answers: [
        {
          id: lifeQuestionAnswerData?.ops_answers[0]?.id,
          category: lifeQuestionAnswerData?.ops_answers[0]?.category,
          answer: lifeQuestionAnswerData?.ops_answers[0]?.answer.map(
            (childAnswers: any) => {
              return {
                question_id: childAnswers?.question_id,
                answer: childAnswers?.answer,
                notes:
                  childAnswers?.question_id === question_id
                    ? data
                    : childAnswers?.notes,
              };
            }
          ),
        },
      ],
    });
  };

  //*-------------------------------------------------------------------------
  const onHandleInputChange = (question_id: any, data: any, type: string) => {
    if (type === "ops") {
      if (
        lifeQuestionAnswerData?.ops_answers[0]?.answer
          ?.map((item: any) => item?.question_id)
          ?.indexOf(question_id) === -1
      ) {
        lifeQuestionAnswerData?.ops_answers[0]?.answer.push({
          question_id: question_id,
          notes: "",
          answer: data,
        });
      } else {
        setLifeQuestionAnswerData({
          pql_answers: lifeQuestionAnswerData.pql_answers,
          sales_answers: lifeQuestionAnswerData.sales_answers,
          ops_answers: [
            {
              id: lifeQuestionAnswerData?.ops_answers[0]?.id,
              category: lifeQuestionAnswerData?.ops_answers[0]?.category,
              answer: lifeQuestionAnswerData?.ops_answers[0]?.answer.map(
                (childAnswers: any) => {
                  return {
                    question_id: childAnswers.question_id,
                    notes: childAnswers.notes,
                    answer:
                      childAnswers?.question_id === question_id
                        ? data
                        : childAnswers?.answer,
                  };
                }
              ),
            },
          ],
        });
      }
    } else {
      if (
        lifeQuestionAnswerData?.sales_answers[0]?.answer
          ?.map((item: any) => item?.question_id)
          ?.indexOf(question_id) === -1
      ) {
        lifeQuestionAnswerData?.sales_answers[0]?.answer.push({
          question_id: question_id,
          notes: "",
          answer: data,
        });
      } else {
        setLifeQuestionAnswerData({
          pql_answers: lifeQuestionAnswerData.pql_answers,
          sales_answers: [
            {
              id: lifeQuestionAnswerData?.sales_answers[0]?.id,
              category: lifeQuestionAnswerData?.sales_answers[0]?.category,
              answer:
                lifeQuestionAnswerData?.sales_answers[0]?.answer?.map(
                  (childAnswers: any) => {
                    return {
                      question_id: childAnswers.question_id,
                      notes: childAnswers.notes,
                      answer:
                        childAnswers?.question_id === question_id
                          ? data
                          : childAnswers?.answer,
                    };
                  }
                ) ?? [],
            },
          ],
          ops_answers: lifeQuestionAnswerData?.ops_answers,
        });
      }
    }
  };

  //*-------------------------------------------------------------------------
  const [updateConpanyViewDetails, { loading: updareViewDetailsLoading }] =
    useUpdateOpsQuestionsDataMutation({
      onCompleted: () => {
        getFullDetailsRefetch();
        setIsEditingData(false);
      },
    });

  const handleSaveQuestionChanges = () => {
    delete lifeQuestionAnswerData.ops_answers[0]["__typename"];
    delete lifeQuestionAnswerData.sales_answers[0]["__typename"];
    updateConpanyViewDetails({
      variables: {
        updates: [
          {
            where: {
              id: {
                _eq: lifeQuestionAnswerData.ops_answers[0]?.id,
              },
            },
            _set: lifeQuestionAnswerData.ops_answers[0] as any,
          },
          {
            where: {
              id: {
                _eq: lifeQuestionAnswerData.sales_answers[0]?.id,
              },
            },
            _set: lifeQuestionAnswerData.sales_answers[0] as any,
          },
        ],
      },
    });
  };
  //*-------------------------------------------------------------------------
  const returnField = (question_id: string, item: any, type = "ops") => {
    if (
      question_id === "PQL2" ||
      question_id === "PQL4" ||
      question_id === "PQL6" ||
      question_id === "PQL8"
    ) {
      return (
        <FormControl style={{ width: "100%" }}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder={"Please enter answer"}
            defaultValue={item[`${type}_ans`]}
            name={question_id}
            onChange={(event: any) =>
              onHandleInputChange(question_id, event.target.value, type)
            }
          />
        </FormControl>
      );
    } else if (question_id === "PQL1") {
      return (
        <FormControl fullWidth>
          <Select
            classes={{
              select: classes.customSelect,
            }}
            value={item[`${type}_answers`]}
            defaultValue={item[`${type}_ans`]}
            onChange={(event: any) =>
              onHandleInputChange(question_id, event.target.value, type)
            }
            input={<OutlinedInput />}
            MenuProps={MenuProps}
          >
            <MenuItem value={"saas"}>
              <ListItemText primary={"SAAS"} />
            </MenuItem>
            <MenuItem value={"services"}>
              <ListItemText primary={"Services"} />
            </MenuItem>
            <MenuItem value={"e_commerce"}>
              <ListItemText primary={"E-Commerce"} />
            </MenuItem>
            <MenuItem value={"ev"}>
              <ListItemText primary={"EV"} />
            </MenuItem>
            <MenuItem value={"others"}>
              <ListItemText primary={"Others"} />
            </MenuItem>
          </Select>
        </FormControl>
      );
    } else if (question_id === "PQL3" || question_id === "PQL5") {
      return (
        <FormControl fullWidth>
          <Select
            classes={{
              select: classes.customSelect,
            }}
            value={item[`${type}_answers`]}
            defaultValue={item[`${type}_ans`]}
            onChange={(event: any) =>
              onHandleInputChange(question_id, event.target.value, type)
            }
            input={<OutlinedInput />}
            MenuProps={MenuProps}
          >
            <MenuItem value={"yes"}>
              <ListItemText primary={"Yes"} />
            </MenuItem>
            <MenuItem value={"no"}>
              <ListItemText primary={"No"} />
            </MenuItem>
          </Select>
        </FormControl>
      );
    } else if (question_id === "PQL7") {
      return (
        <FormControl fullWidth>
          <Select
            classes={{
              select: classes.customSelect,
            }}
            value={item[`${type}_answers`]}
            defaultValue={item[`${type}_ans`]}
            onChange={(event: any) =>
              onHandleInputChange(question_id, event.target.value, type)
            }
            input={<OutlinedInput />}
            MenuProps={MenuProps}
          >
            <MenuItem value={"growth_capital"}>
              <ListItemText primary={"Growth Capital"} />
            </MenuItem>
            <MenuItem value={"working_capital"}>
              <ListItemText primary={"Working Capital"} />
            </MenuItem>
            <MenuItem value={"both"}>
              <ListItemText primary={"Both"} />
            </MenuItem>
          </Select>
        </FormControl>
      );
    } else {
      return (
        <FormControl style={{ width: "100%" }}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder={"Please enter answer"}
            defaultValue={item[`${type}_ans`]}
            name={question_id}
            onChange={(event: any) =>
              onHandleInputChange(question_id, event.target.value, type)
            }
          />
        </FormControl>
      );
    }
  };
  //*-------------------------------------------------------------------------
  const { loading: lifeCycleLoading, refetch: getFullDetailsRefetch } =
    useGetCompanyQueueFullDetailsQuery({
      variables: {
        //@ts-ignore
        company_id: params.id,
        where: {
          company_id: {
            //@ts-ignore
            _eq: params.id,
          },
          end_date: {
            _is_null: true,
          },
        },
      },
      onCompleted: (item) => {
        setLifeQuestionAnswerData(item);
        setAllDataTogether(
          allDataTogether?.map((data: any) => {
            return {
              prequestionary: item.pql_answers[0]?.answer.filter(
                (pqldata: any) => pqldata.question_id === data.question_id
              )[0]?.answer,
              sales_ans: item.sales_answers[0]?.answer.filter(
                (pqldata: any) => pqldata.question_id === data.question_id
              )[0]?.answer,
              ops_ans: item.ops_answers[0]?.answer.filter(
                (pqldata: any) => pqldata.question_id === data.question_id
              )[0]?.answer,
              ops_remark: item.ops_answers[0]?.answer.filter(
                (pqldata: any) => pqldata.question_id === data.question_id
              )[0]?.notes,
              question: data.question,
              question_id: data.question_id,
            };
          })
        );
      },
      fetchPolicy: "no-cache",
    });

  return (
    <React.Fragment>
      <Helmet title="Company Details" />
      {/** HEADER------------------------------------------------------------------------------------- */}
      <Grid
        container
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item>
          <CommonBreadCrumb
            typographyText="PQL Data"
            linksText={[{ link: "  Company Queues", to: "/company-queues" }]}
            showCompany
          />
        </Grid>
        <Grid item>
          {isEditingData ? (
            <>
              <Button
                variant="outlined"
                onClick={() => setIsEditingData(false)}
              >
                Cancel
              </Button>
              &nbsp; &nbsp;
              <Button variant="contained" onClick={handleSaveQuestionChanges}>
                {updareViewDetailsLoading ? (
                  <CircularProgress size={25} />
                ) : (
                  "Save"
                )}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              onClick={() => setIsEditingData(true)}
              disabled={
                (lifeQuestionAnswerData?.stage &&
                  lifeQuestionAnswerData?.stage[0]?.stage ===
                    "A1_REGISTERED") ||
                (lifeQuestionAnswerData?.stage &&
                  lifeQuestionAnswerData?.stage[0]?.stage === "B2_MQL") ||
                (lifeQuestionAnswerData?.stage &&
                  lifeQuestionAnswerData?.stage[0]?.stage === "C3_PQL")
              }
            >
              Edit
            </Button>
          )}
        </Grid>
      </Grid>
      <br />
      <br />
      <Paper>
        {lifeCycleLoading ? (
          <CommonLoader />
        ) : (
          <TableWrapper>
            <MuiTable>
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Questions</TableCell>
                  <TableCell>Prequalification</TableCell>
                  <TableCell>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      Sales answers
                      <IconButton onClick={() => setOpenSalesNoteModal(true)}>
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <img
                            width={"16px"}
                            height={"16px"}
                            alt=""
                            src={FileSolidIcon}
                          />
                        </StyledBadge>{" "}
                      </IconButton>
                    </span>
                  </TableCell>
                  <TableCell>Ops</TableCell>
                  <TableCell>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      Ops Remarks
                      <IconButton
                        onClick={() => {
                          setOpenOpsNotesModal(true);
                        }}
                      >
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <img
                            width={"16px"}
                            height={"16px"}
                            alt=""
                            src={FileSolidIcon}
                          />{" "}
                        </StyledBadge>
                      </IconButton>
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allDataTogether.map((item: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell width={"2%"}>{index + 1}</TableCell>
                      <TableCell width={"26%"}>
                        {item.question || "-"}{" "}
                      </TableCell>
                      <TableCell
                        width={"6%"}
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {item.prequestionary?.toLocaleString() || "-"}{" "}
                      </TableCell>
                      <TableCell
                        width={"6%"}
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {isEditingData
                          ? returnField(item.question_id, item, "sales")
                          : item.sales_ans || "-"}{" "}
                      </TableCell>
                      <TableCell
                        width={"20%"}
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {isEditingData
                          ? returnField(item.question_id, item)
                          : item.ops_ans || "-"}{" "}
                      </TableCell>

                      <TableCell width={"30%"}>
                        {isEditingData ? (
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              variant="outlined"
                              defaultValue={item.ops_remark}
                              placeholder={"Please enter remark"}
                              name={item.question_id}
                              onChange={(event: any) =>
                                onHandleNotesInputChange(
                                  item.question_id,
                                  event.target.value
                                )
                              }
                            />
                          </FormControl>
                        ) : item.ops_remark ? (
                          <span
                            style={{
                              position: "relative",
                              background: "#eaeaea",
                            }}
                          >
                            <CustomTooltip>
                              {item?.ops_remark?.length >= 90 &&
                              showOpsRemark !== item.question_id
                                ? `${item?.ops_remark.substr(0, 90)}...`
                                : item.ops_remark}
                              {item?.ops_remark?.length >= 90 && (
                                <Button
                                  onClick={() =>
                                    setShowOpsRemark(
                                      showOpsRemark === ""
                                        ? item.question_id
                                        : ""
                                    )
                                  }
                                  style={{ padding: "0" }}
                                >
                                  {showOpsRemark === "" ? "Read More" : "Hide"}
                                </Button>
                              )}
                            </CustomTooltip>
                          </span>
                        ) : (
                          "-"
                        )}{" "}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </MuiTable>
          </TableWrapper>
        )}
      </Paper>

      {/** OPS NOTES MODAL ------------------------------------------------------------------------------- */}
      <Modal
        open={openOpsNotesModal}
        onClose={() => setOpenOpsNotesModal(false)}
      >
        <Grid
          container
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <OpsRemarkModal
            data={lifeQuestionAnswerData}
            setOpenOpsNotesModal={setOpenOpsNotesModal}
            refetch={getFullDetailsRefetch}
          />
        </Grid>
      </Modal>
      {/** SALES ANSWER MODAL ------------------------------------------------------------------------------- */}
      <Modal
        open={openSalesNoteModal}
        onClose={() => setOpenSalesNoteModal(false)}
      >
        <Grid
          container
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <SalesNotesModal
            data={lifeQuestionAnswerData}
            setOpenSalesNoteModal={setOpenSalesNoteModal}
            refetch={getFullDetailsRefetch}
          />
        </Grid>
      </Modal>
    </React.Fragment>
  );
};

export default CompanyDetail;
