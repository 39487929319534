import {
  Divider as MuiDivider,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  Button,
  Grid,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../../assets/svg/download-icon.svg";
import React, { useState } from "react";
import styled from "styled-components";
import {
  useDownloadUploadedFileLazyQuery,
  useGetPaymentPartnerIntegrationsQuery,
} from "../../../data-access/hooks/company/payment-partner.hooks";
import EmptyData from "../../../components/EmptyData";
import { format } from "date-fns";
import { TableWrapper } from "../../../theme/customComponents";
import CommonBreadCrumb from "../../../components/CommonBreadCrumb";
import CommonLoader from "../../../components/CommonLoader";
import TableStatus from "../../../components/TableStatus";

const Divider = styled(MuiDivider)(spacing);

const PaymentPartner = () => {
  const params = useParams();

  const navigate = useNavigate();

  const [allPaymentData, setAllPaymentData] = useState([]);

  const [downloadUploadFile, { loading: downloadLoading }] =
    useDownloadUploadedFileLazyQuery({
      onCompleted: (item: any) => {
        const downloadUrl = item?.generateFileRegistryDownloadURL?.download_url;
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      },
    });

  const { loading: getAllLoading } = useGetPaymentPartnerIntegrationsQuery({
    variables: {
      where: {
        company_id: {
          //@ts-ignore
          _eq: params?.id,
        },
        category: {
          _eq: "SUBSCRIPTION",
        },
      },
      order_by: [
        {
          created_at: "desc" as any,
        },
      ],
    },
    fetchPolicy: "no-cache",
    onCompleted: (item: any) => {
      setAllPaymentData(item?.company_integration);
    },
  });

  return (
    <React.Fragment>
      <Helmet title="Company Dashboard" />
      <Grid container display={"flex"} flexDirection={"column"}>
        <Grid
          item
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <CommonBreadCrumb
              typographyText="Payment Partner"
              linksText={[{ link: "Company Queues", to: "/company-queues" }]}
              showCompany
            />
          </Grid>
          <Grid item display={"flex"} gap="10px">
            <Button
              download
              href="https://cdn.bridgeup.com/templates/subscriptions/2023-01-20/subscription_template.csv"
              variant="outlined"
            >
              Download Template
            </Button>
            <Button
              onClick={() => navigate(`/company/${params.id}/add-payment-data`)}
              variant="outlined"
            >
              Add Payment Partner Data
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider mt="16px" mb="28px" />
      <Grid item>
        <TableWrapper>
          {getAllLoading ? (
            <CommonLoader />
          ) : (
            <Paper>
              {allPaymentData?.length === 0 ? (
                <Grid item width={"100%"}>
                  <EmptyData />
                </Grid>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Payment Partner</TableCell>
                      <TableCell>File Name</TableCell>
                      <TableCell>Last Synced On</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Failure Reason</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allPaymentData?.map((item: any, index) => (
                      <TableRow key={index}>
                        <TableCell>{item?.source || "-"}</TableCell>
                        <TableCell
                          style={{
                            maxWidth: "350px",
                          }}
                        >
                          {item?.company_integration_files[0]?.file_registry
                            ?.name || "-"}
                        </TableCell>
                        <TableCell>
                          {item?.created_at
                            ? format(
                                new Date(`${item?.created_at}`),
                                "dd/MM/yyyy"
                              )
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {TableStatus({
                            stautsText: `${item?.status}`,
                          })}
                        </TableCell>
                        <TableCell>
                          {item?.failure_reason
                            ? item?.failure_reason?.message
                            : "-"}
                        </TableCell>
                        <TableCell
                          style={{
                            display: "flex",
                          }}
                        >
                          <IconButton
                            color="primary"
                            onClick={() =>
                              downloadUploadFile({
                                variables: {
                                  //@ts-ignore
                                  file_registry_id:
                                    item?.company_integration_files[0]
                                      ?.file_registry?.id,
                                },
                              })
                            }
                            style={{
                              margin: "5px",
                            }}
                          >
                            {downloadLoading ? (
                              <CircularProgress size={12} />
                            ) : (
                              <DownloadIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Paper>
          )}
        </TableWrapper>
      </Grid>
    </React.Fragment>
  );
};

export default PaymentPartner;
