import {
  Alert,
  AlertColor,
  Button,
  CircularProgress,
  Grid,
  TextField,
  TextFieldProps,
} from "@mui/material";
import React, { useState } from "react";
import SimpleDatePicker from "../../../components/input/SimpleDatePicker";
import SimpleFileInput from "../../../components/input/SimpleFileInput";
import { useFormik } from "formik";
import axios from "axios";
import { useUploadAgreementMutation } from "../../../data-access/hooks/company/nda-logs.hooks";
import { Agreement_Category } from "../../../data-access/types";
import { useParams } from "react-router";
import SideModal from "../../../components/Modals/SideModal";

interface UploadAgreementInterface {
  setOpen: Function;
  getInvestorRefetch: any;
  investorId?: number | null;
  processData?: any;
  setCurrentStep?: any;
  getAllInvestorRefetch?: any;
  setProcessData?: any;
  modalType?: any;
  open?: any;
  currentStep?: any;
}

const UploadInvestorAgreement = (props: UploadAgreementInterface) => {
  const {
    setOpen,
    processData,
    setCurrentStep,
    getAllInvestorRefetch,
    modalType,
    open,
    currentStep,
  } = props;

  const params = useParams();

  const [secureResponse, setSecureResponse] = useState({
    response: "",
    selectedFile: {} as any,
  });

  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  const [uploadLoading, setUploadLoading] = useState(false);

  const uploadFile = async (selectedFile: any) => {
    const formData = new FormData();
    formData?.append("file", selectedFile, selectedFile?.name);
    formData?.append("category_id", "TENANT_AGREEMENT");
    formData?.append("password", "");
    const token = localStorage.getItem("accessToken");
    const config: any = {
      method: "post",
      url: `${process.env.REACT_APP_ACTIONS_BASE_URL}secure-upload`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        "X-hasura-tenant-id": 662,
      },
    };
    await axios(config)
      .then((response) => {
        setShowAlert({
          show: true,
          message: "The file is successfully uploaded",
          type: "success",
        });
        setSecureResponse({
          response: response?.data?.file_registry_id,
          selectedFile,
        });
        setUploadLoading(false);
      })
      .catch((error) => {
        setShowAlert({
          show: true,
          message: error?.response?.data?.message,
          type: "error",
        });
        setUploadLoading(false);
      });
  };

  const [handleUploadAgreement, { loading: uploadAPILoading }] =
    useUploadAgreementMutation({
      onCompleted: () => {
        setOpen(false);
        getAllInvestorRefetch();
      },
      onError: (error) => {
        setShowAlert({
          show: true,
          message: error.message,
          type: "error",
        });
      },
    });

  const formik = useFormik({
    initialValues: {
      signed_at: new Date(),
    },
    onSubmit: (fieldValues) => {
      setShowAlert({
        show: false,
        message: "",
        type: "info",
      });
      handleUploadAgreement({
        variables: {
          args: {
            category_id: Agreement_Category.TenantAgreement,
            signed_at: `${fieldValues.signed_at}`,
            file_id: Number(secureResponse.response),
          },
        },
        context: {
          headers: {
            "X-hasura-proxy-tenant-type": "INVESTOR",
            "X-hasura-proxy-tenant-id":
              params.investor_id || processData?.investorData?.id,
            "X-hasura-tenant-id":
              params.investor_id || processData?.investorData?.id,
          },
        },
      });
    },
  });

  return (
    <SideModal
      open={open && currentStep === 3 ? true : false}
      onClose={() => setOpen(false)}
      modalTitle={"Upload Investor Agreement"}
      footer={
        <Grid
          xs={12}
          width={"100%"}
          item
          display={"flex"}
          justifyContent="center"
          gap="10px"
        >
          {!params.investor_id && modalType !== "standalone" && (
            <Button
              fullWidth
              onClick={() => {
                setCurrentStep(2);
              }}
              variant="secondarySmall"
            >
              Back
            </Button>
          )}
          <Button
            fullWidth
            disabled={uploadAPILoading}
            type="submit"
            variant="primarySmall"
            form="agreement-form"
          >
            {uploadAPILoading ? <CircularProgress size={20} /> : "Submit"}
          </Button>
        </Grid>
      }
    >
      <form id="agreement-form" onSubmit={formik.handleSubmit}>
        <Grid container xs={12} rowSpacing={2}>
          <Grid xs={12} my={2}>
            {showAlert.show && (
              <Alert
                severity={showAlert.type as AlertColor}
                sx={{ width: "100%" }}
                variant="outlined"
              >
                {showAlert.message}
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            <SimpleDatePicker
              inputlabel="Agreement Signed Date"
              required
              disableFuture
              value={formik.values.signed_at}
              name="signed_at"
              onChange={(value: any) => {
                formik.setFieldValue("signed_at", value);
              }}
              type="date"
              inputFormat="DD/MM/YYYY"
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps
              ) => (
                <TextField
                  style={{
                    margin: "4px 0 0 0",
                  }}
                  {...params}
                  placeholder="Select Scored on date"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <SimpleFileInput
              inputlabel="Agreement File(only pdf is allowed)"
              type="file"
              loading={uploadLoading}
              name="agreement"
              accept=".pdf"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUploadLoading(true);
                uploadFile(event?.target?.files && event?.target?.files[0]);
              }}
              required
            />
          </Grid>
        </Grid>
      </form>
    </SideModal>
  );
};

export default UploadInvestorAgreement;
