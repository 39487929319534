import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Button,
  Card,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useGetEmaiCalendarDataQuery } from "../../data-access/hooks/company/emi-calendar.hooks";
import { Divider, FullCalendarWrapper } from "./Calendar.styles";
import { format } from "date-fns";
import { ReactComponent as SwitchHorizontal } from "../../assets/Icon/Outline/switch-horizontal-icon.svg";
import { IdPrefixFormat } from "../../data-access/formatter";
import { useNavigate } from "react-router-dom";
import { calendarStatusColor } from "../../utils/common";

const today = new Date();

const CustomEventCell = (data: any) => {
  const cardData = data?.event?.extendedProps;
  return (
    <Grid
      container
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      margin={"2px"}
    >
      <Grid
        item
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={"4px"}
      >
        <span
          style={{
            color: calendarStatusColor(
              cardData?.external_status || cardData?.transactions_status
            ),
            fontSize: "16px",
          }}
        >
          ●
        </span>
        <Typography
          style={{
            textOverflow: "ellipsis",
            width: "100px",
            overflow: "hidden",
            fontSize: "14px",
            fontWeight: "500",
          }}
          color={"black"}
        >
          {cardData?.company_name}
        </Typography>
      </Grid>
    </Grid>
  );
};

const statusData = [
  {
    name: "Scheduled",
    color: "#1E88E5",
  },
  {
    name: "Cancled",
    color: "#A157FF",
  },
  {
    name: "Draft",
    color: "#C6C6C6",
  },
  {
    name: "Failed ",
    color: "#E90000",
  },
  {
    name: "In progress ",
    color: "#EAB224",
  },
  {
    name: "Completed ",
    color: "#57B867",
  },
];

function Calendar() {
  const navigate = useNavigate();

  const [selectedData, setSelectedData] = useState<any>();

  const [currentMonth, setCurrentMonth] = useState({
    start: "",
    end: "",
  });

  const [showAllInvestors, setShowAllInvestors] = useState(2);

  const [allEmiData, setAllEmiData] = useState<any>();

  const [rightPanelData, setRightPanelData] = useState<any>();

  const { loading: getEmiDataLoading } = useGetEmaiCalendarDataQuery({
    onCompleted: (data) => {
      const orderedItems = data?.scheduled_emi?.map((item) => {
        return {
          id: `${item?.id}`,
          start: `${format(Date.parse(item?.scheduled_at), "yyyy-MM-dd")}`,
          schedule_date: `${format(
            Date.parse(item?.scheduled_at),
            "yyyy-MM-dd"
          )}`,
          title: `${item?.trade?.company?.name}`,
          color: "#ffffff",
          emi_count: item?.trade_installment_seq,
          total_emi: item?.trade?.validity,
          company_name: item?.trade?.company?.name,
          company_id: item?.trade?.company?.id,
          trade_id: item?.trade?.id,
          escro: item?.trade?.collection_mode,
          external_status: item?.status,
          transactions_status: item?.transactions[0]?.transaction_status,
          transactions_id: item?.transactions[0]?.id,
          investors: item?.trade?.investments,
          emi_amount: item?.trade?.emi_amount,
          transactions: item?.transactions,
        };
      });
      setAllEmiData(orderedItems);
      setRightPanelData(
        orderedItems?.filter(
          (item: any) => item?.start === format(today, "yyyy-MM-dd")
        )
      );
    },
    variables: {
      where: {
        scheduled_at: {
          _gte: currentMonth.start,
          _lt: currentMonth.end,
        },
      },
    },
  });

  return (
    <React.Fragment>
      <Helmet title="Calendar" />
      <Typography variant="h3" gutterBottom display="inline">
        Calendar
      </Typography>
      <Divider mt="16px" mb="28px" />
      {getEmiDataLoading && <LinearProgress color="primary" />}
      <Grid container style={{ background: "white" }}>
        <Grid item xs={9}>
          <FullCalendarWrapper>
            <FullCalendar
              initialView="dayGridMonth"
              initialDate={today}
              plugins={[dayGridPlugin, interactionPlugin]}
              selectable={true}
              dateClick={(data) => {
                setRightPanelData(
                  allEmiData?.filter(
                    (item: any) => item?.schedule_date === data?.dateStr
                  )
                );
                setSelectedData(data);
              }}
              dayMaxEventRows={3}
              datesSet={(dateInfo) => {
                setCurrentMonth({
                  start: dateInfo?.startStr,
                  end: dateInfo?.endStr,
                });
              }}
              events={allEmiData && allEmiData}
              eventContent={CustomEventCell}
              headerToolbar={{
                left: "prev,next",
                center: "title",
                right: "dayGridDay,dayGridWeek,dayGridMonth",
              }}
            />
          </FullCalendarWrapper>
        </Grid>
        <Grid container item xs={3} display={"flex"} flexDirection={"column"}>
          <Grid
            item
            display={"grid"}
            gridTemplateColumns={"auto auto auto"}
            style={{ background: "white" }}
            height={"96px"}
            padding={"14px"}
          >
            {statusData.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <span style={{ color: `${item.color}`, fontSize: "16px" }}>
                  ●
                </span>
                <Typography fontWeight={500} fontSize={"14px"}>
                  {item.name}
                </Typography>
              </div>
            ))}
          </Grid>
          <Grid
            item
            style={{
              background: "white",
              border: "solid 1px #EDEDED",
              borderLeft: "none",
              borderRight: "none",
            }}
            padding={"14px"}
          >
            <Typography fontWeight={700} fontSize={"22px"}>
              {selectedData?.dateStr
                ? format(Date.parse(selectedData?.dateStr), "LLLL dd, yyyy")
                : format(today, "LLLL dd, yyyy")}
            </Typography>
          </Grid>
          <Grid item style={{ background: "white" }} padding={"14px"}>
            {rightPanelData?.map((item: any, index: number) => (
              <Card
                key={index}
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  padding: "14px",
                  borderBottom: "solid 1px #ededed",
                  marginBottom: "14px ",
                }}
              >
                <Grid
                  item
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent="space-between"
                >
                  <Typography
                    color={"black"}
                    fontWeight={700}
                    fontSize={"16px"}
                  >
                    <span
                      style={{
                        color: calendarStatusColor(
                          item?.external_status || item?.transactions_status
                        ),
                        fontSize: "16px",
                      }}
                    >
                      ●
                    </span>
                    &nbsp; {item?.company_name}
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      navigate(`/company/${item?.company_id}/transactions`);
                    }}
                  >
                    <SwitchHorizontal width={18} height={18} color="#0671E0" />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  container
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid item>
                    <Typography
                      fontWeight={600}
                      fontSize={"14px"}
                      color={"#AAA5B7"}
                    >
                      Trade Id
                    </Typography>
                    <Typography fontWeight={400} fontSize={"14px"}>
                      {item?.trade_id && IdPrefixFormat(item?.trade_id, "T")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      fontWeight={600}
                      fontSize={"14px"}
                      color={"#AAA5B7"}
                    >
                      Txn Id
                    </Typography>
                    <Typography fontWeight={400} fontSize={"14px"}>
                      {item?.transactions && item?.transactions.length !== 0
                        ? IdPrefixFormat(item?.transactions[0].id, "TXN", 5)
                        : IdPrefixFormat(item?.id, "S", 5)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography
                    fontWeight={600}
                    fontSize={"14px"}
                    color={"#AAA5B7"}
                  >
                    EMI Amount
                  </Typography>
                  <Typography fontWeight={600} fontSize={"14px"}>
                    {`₹ ${item?.emi_amount}`}
                    <span>{`(${item?.emi_count}/${item?.total_emi})`}</span>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontWeight={600}
                    fontSize={"14px"}
                    color={"#AAA5B7"}
                  >
                    Payment Mode
                  </Typography>
                  <Typography fontWeight={600} fontSize={"14px"}>
                    {item?.escro}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontWeight={600}
                    fontSize={"14px"}
                    color={"#AAA5B7"}
                  >
                    Investors
                  </Typography>
                  {item?.investors
                    ?.slice(0, showAllInvestors)
                    ?.map((item: any, index: number) => {
                      return (
                        <Typography
                          key={index}
                          padding={"4px 0"}
                          fontWeight={700}
                          fontSize={"14px"}
                        >
                          {`${item?.investor?.name} (${item?.withdrawal_mode})`}
                        </Typography>
                      );
                    })}
                  {item?.investors?.length > 2 && (
                    <Button
                      onClick={() =>
                        setShowAllInvestors(
                          showAllInvestors === 2 ? item?.investors?.length : 2
                        )
                      }
                      style={{
                        padding: "0",
                      }}
                    >
                      {showAllInvestors === 2 ? "Show More" : "Show Less"}
                    </Button>
                  )}
                </Grid>
              </Card>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Calendar;
