import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDocument_Checklist_CategoryQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Document_Checklist_Category_Bool_Exp>;
}>;


export type GetDocument_Checklist_CategoryQuery = { __typename?: 'query_root', document_checklist_category: Array<{ __typename?: 'document_checklist_category', id: number, is_applicable: boolean, ref_document_checklist_category: { __typename?: 'ref_document_checklist_category', id: number, is_applicable: boolean, name: string, sequence: number }, document_checklists: Array<{ __typename?: 'document_checklist', id: number, shared_by_company: boolean, status: Types.Ref_Document_Checklist_Status_Enum, remarks?: string | null, ref_document_checklist: { __typename?: 'ref_document_checklist', name: string, serial_number: string, subtext?: string | null, internal_tip?: string | null } }> }> };

export type GetDocument_Checklist_Category_By_IdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetDocument_Checklist_Category_By_IdQuery = { __typename?: 'query_root', document_checklist_category_by_pk?: { __typename?: 'document_checklist_category', id: number, is_applicable: boolean, ref_document_checklist_category: { __typename?: 'ref_document_checklist_category', id: number, is_applicable: boolean, name: string, sequence: number }, document_checklists: Array<{ __typename?: 'document_checklist', id: number, shared_by_company: boolean, status: Types.Ref_Document_Checklist_Status_Enum, remarks?: string | null, ref_document_checklist: { __typename?: 'ref_document_checklist', name: string, serial_number: string, subtext?: string | null, internal_tip?: string | null } }> } | null };

export type UpdateDocument_ChecklistMutationVariables = Types.Exact<{
  updates: Array<Types.Document_Checklist_Updates> | Types.Document_Checklist_Updates;
}>;


export type UpdateDocument_ChecklistMutation = { __typename?: 'mutation_root', update_document_checklist_many?: Array<{ __typename?: 'document_checklist_mutation_response', affected_rows: number } | null> | null };

export type Update_Document_Checklist_Category_ManyMutationVariables = Types.Exact<{
  updates: Array<Types.Document_Checklist_Category_Updates> | Types.Document_Checklist_Category_Updates;
}>;


export type Update_Document_Checklist_Category_ManyMutation = { __typename?: 'mutation_root', update_document_checklist_category_many?: Array<{ __typename?: 'document_checklist_category_mutation_response', affected_rows: number } | null> | null };

export type SendDocumentchecklistNotificationMutationVariables = Types.Exact<{
  args: Types.SendDocumentchecklistNotificationInput;
}>;


export type SendDocumentchecklistNotificationMutation = { __typename?: 'mutation_root', SendDocumentchecklistNotification?: { __typename?: 'SendDocumentchecklistNotificationOutput', message: string } | null };

export type AddDocumentChecklistMutationVariables = Types.Exact<{
  company_id: Types.Scalars['Int'];
  sales_email: Types.Scalars['String'];
}>;


export type AddDocumentChecklistMutation = { __typename?: 'mutation_root', AddDocumentchecklistForCompany?: { __typename?: 'AddDocumentchecklistForCompanyOutput', message: string } | null };

export type GetAllCompanyLogsByIdQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Company_Email_Log_Bool_Exp>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  company_id: Types.Scalars['Int'];
  order_by?: Types.InputMaybe<Array<Types.Company_Email_Log_Order_By> | Types.Company_Email_Log_Order_By>;
}>;


export type GetAllCompanyLogsByIdQuery = { __typename?: 'query_root', company_email_log: Array<{ __typename?: 'company_email_log', created_at: any, email_event: { __typename?: 'email_events', subject?: string | null, to: any, from: any, cc?: any | null, bcc?: any | null, status?: string | null, failure_reason?: string | null, type: Types.Ref_Email_Events_Type_Enum } }>, company_email_log_aggregate: { __typename?: 'company_email_log_aggregate', aggregate?: { __typename?: 'company_email_log_aggregate_fields', count: number } | null } };

export type ToggleDocumentChecklistMutationVariables = Types.Exact<{
  args?: Types.InputMaybe<Types.ToggleCompanyDocumentChecklistInput>;
}>;


export type ToggleDocumentChecklistMutation = { __typename?: 'mutation_root', ToggleCompanyDocumentChecklist?: { __typename?: 'ToggleCompanyDocumentChecklistOutput', message: string } | null };

export type GetDocumentChecklistStatusQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetDocumentChecklistStatusQuery = { __typename?: 'query_root', company_by_pk?: { __typename?: 'company', document_checklist_enabled: boolean } | null };

export type GetCompanySalesRmQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetCompanySalesRmQuery = { __typename?: 'query_root', company: Array<{ __typename?: 'company', sales_rm?: number | null }> };


export const GetDocument_Checklist_CategoryDocument = gql`
    query getDocument_checklist_category($where: document_checklist_category_bool_exp) {
  document_checklist_category(
    where: $where
    order_by: [{ref_document_checklist_category: {sequence: asc}}]
  ) {
    id
    is_applicable
    ref_document_checklist_category {
      id
      is_applicable
      name
      sequence
    }
    document_checklists(order_by: {ref_document_checklist: {serial_number: asc}}) {
      id
      shared_by_company
      status
      remarks
      ref_document_checklist {
        name
        serial_number
        subtext
        internal_tip
      }
    }
  }
}
    `;

/**
 * __useGetDocument_Checklist_CategoryQuery__
 *
 * To run a query within a React component, call `useGetDocument_Checklist_CategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocument_Checklist_CategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocument_Checklist_CategoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDocument_Checklist_CategoryQuery(baseOptions?: Apollo.QueryHookOptions<GetDocument_Checklist_CategoryQuery, GetDocument_Checklist_CategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocument_Checklist_CategoryQuery, GetDocument_Checklist_CategoryQueryVariables>(GetDocument_Checklist_CategoryDocument, options);
      }
export function useGetDocument_Checklist_CategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocument_Checklist_CategoryQuery, GetDocument_Checklist_CategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocument_Checklist_CategoryQuery, GetDocument_Checklist_CategoryQueryVariables>(GetDocument_Checklist_CategoryDocument, options);
        }
export type GetDocument_Checklist_CategoryQueryHookResult = ReturnType<typeof useGetDocument_Checklist_CategoryQuery>;
export type GetDocument_Checklist_CategoryLazyQueryHookResult = ReturnType<typeof useGetDocument_Checklist_CategoryLazyQuery>;
export type GetDocument_Checklist_CategoryQueryResult = Apollo.QueryResult<GetDocument_Checklist_CategoryQuery, GetDocument_Checklist_CategoryQueryVariables>;
export const GetDocument_Checklist_Category_By_IdDocument = gql`
    query getDocument_checklist_category_by_id($id: Int!) {
  document_checklist_category_by_pk(id: $id) {
    id
    is_applicable
    ref_document_checklist_category {
      id
      is_applicable
      name
      sequence
    }
    document_checklists(order_by: {ref_document_checklist: {serial_number: asc}}) {
      id
      shared_by_company
      status
      remarks
      ref_document_checklist {
        name
        serial_number
        subtext
        internal_tip
      }
    }
  }
}
    `;

/**
 * __useGetDocument_Checklist_Category_By_IdQuery__
 *
 * To run a query within a React component, call `useGetDocument_Checklist_Category_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocument_Checklist_Category_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocument_Checklist_Category_By_IdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocument_Checklist_Category_By_IdQuery(baseOptions: Apollo.QueryHookOptions<GetDocument_Checklist_Category_By_IdQuery, GetDocument_Checklist_Category_By_IdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocument_Checklist_Category_By_IdQuery, GetDocument_Checklist_Category_By_IdQueryVariables>(GetDocument_Checklist_Category_By_IdDocument, options);
      }
export function useGetDocument_Checklist_Category_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocument_Checklist_Category_By_IdQuery, GetDocument_Checklist_Category_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocument_Checklist_Category_By_IdQuery, GetDocument_Checklist_Category_By_IdQueryVariables>(GetDocument_Checklist_Category_By_IdDocument, options);
        }
export type GetDocument_Checklist_Category_By_IdQueryHookResult = ReturnType<typeof useGetDocument_Checklist_Category_By_IdQuery>;
export type GetDocument_Checklist_Category_By_IdLazyQueryHookResult = ReturnType<typeof useGetDocument_Checklist_Category_By_IdLazyQuery>;
export type GetDocument_Checklist_Category_By_IdQueryResult = Apollo.QueryResult<GetDocument_Checklist_Category_By_IdQuery, GetDocument_Checklist_Category_By_IdQueryVariables>;
export const UpdateDocument_ChecklistDocument = gql`
    mutation updateDocument_checklist($updates: [document_checklist_updates!]!) {
  update_document_checklist_many(updates: $updates) {
    affected_rows
  }
}
    `;
export type UpdateDocument_ChecklistMutationFn = Apollo.MutationFunction<UpdateDocument_ChecklistMutation, UpdateDocument_ChecklistMutationVariables>;

/**
 * __useUpdateDocument_ChecklistMutation__
 *
 * To run a mutation, you first call `useUpdateDocument_ChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocument_ChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentChecklistMutation, { data, loading, error }] = useUpdateDocument_ChecklistMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateDocument_ChecklistMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocument_ChecklistMutation, UpdateDocument_ChecklistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocument_ChecklistMutation, UpdateDocument_ChecklistMutationVariables>(UpdateDocument_ChecklistDocument, options);
      }
export type UpdateDocument_ChecklistMutationHookResult = ReturnType<typeof useUpdateDocument_ChecklistMutation>;
export type UpdateDocument_ChecklistMutationResult = Apollo.MutationResult<UpdateDocument_ChecklistMutation>;
export type UpdateDocument_ChecklistMutationOptions = Apollo.BaseMutationOptions<UpdateDocument_ChecklistMutation, UpdateDocument_ChecklistMutationVariables>;
export const Update_Document_Checklist_Category_ManyDocument = gql`
    mutation update_document_checklist_category_many($updates: [document_checklist_category_updates!]!) {
  update_document_checklist_category_many(updates: $updates) {
    affected_rows
  }
}
    `;
export type Update_Document_Checklist_Category_ManyMutationFn = Apollo.MutationFunction<Update_Document_Checklist_Category_ManyMutation, Update_Document_Checklist_Category_ManyMutationVariables>;

/**
 * __useUpdate_Document_Checklist_Category_ManyMutation__
 *
 * To run a mutation, you first call `useUpdate_Document_Checklist_Category_ManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Document_Checklist_Category_ManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentChecklistCategoryManyMutation, { data, loading, error }] = useUpdate_Document_Checklist_Category_ManyMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdate_Document_Checklist_Category_ManyMutation(baseOptions?: Apollo.MutationHookOptions<Update_Document_Checklist_Category_ManyMutation, Update_Document_Checklist_Category_ManyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Document_Checklist_Category_ManyMutation, Update_Document_Checklist_Category_ManyMutationVariables>(Update_Document_Checklist_Category_ManyDocument, options);
      }
export type Update_Document_Checklist_Category_ManyMutationHookResult = ReturnType<typeof useUpdate_Document_Checklist_Category_ManyMutation>;
export type Update_Document_Checklist_Category_ManyMutationResult = Apollo.MutationResult<Update_Document_Checklist_Category_ManyMutation>;
export type Update_Document_Checklist_Category_ManyMutationOptions = Apollo.BaseMutationOptions<Update_Document_Checklist_Category_ManyMutation, Update_Document_Checklist_Category_ManyMutationVariables>;
export const SendDocumentchecklistNotificationDocument = gql`
    mutation sendDocumentchecklistNotification($args: SendDocumentchecklistNotificationInput!) {
  SendDocumentchecklistNotification(args: $args) {
    message
  }
}
    `;
export type SendDocumentchecklistNotificationMutationFn = Apollo.MutationFunction<SendDocumentchecklistNotificationMutation, SendDocumentchecklistNotificationMutationVariables>;

/**
 * __useSendDocumentchecklistNotificationMutation__
 *
 * To run a mutation, you first call `useSendDocumentchecklistNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDocumentchecklistNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDocumentchecklistNotificationMutation, { data, loading, error }] = useSendDocumentchecklistNotificationMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useSendDocumentchecklistNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendDocumentchecklistNotificationMutation, SendDocumentchecklistNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendDocumentchecklistNotificationMutation, SendDocumentchecklistNotificationMutationVariables>(SendDocumentchecklistNotificationDocument, options);
      }
export type SendDocumentchecklistNotificationMutationHookResult = ReturnType<typeof useSendDocumentchecklistNotificationMutation>;
export type SendDocumentchecklistNotificationMutationResult = Apollo.MutationResult<SendDocumentchecklistNotificationMutation>;
export type SendDocumentchecklistNotificationMutationOptions = Apollo.BaseMutationOptions<SendDocumentchecklistNotificationMutation, SendDocumentchecklistNotificationMutationVariables>;
export const AddDocumentChecklistDocument = gql`
    mutation addDocumentChecklist($company_id: Int!, $sales_email: String!) {
  AddDocumentchecklistForCompany(
    args: {company_id: $company_id, sales_email: $sales_email}
  ) {
    message
  }
}
    `;
export type AddDocumentChecklistMutationFn = Apollo.MutationFunction<AddDocumentChecklistMutation, AddDocumentChecklistMutationVariables>;

/**
 * __useAddDocumentChecklistMutation__
 *
 * To run a mutation, you first call `useAddDocumentChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentChecklistMutation, { data, loading, error }] = useAddDocumentChecklistMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      sales_email: // value for 'sales_email'
 *   },
 * });
 */
export function useAddDocumentChecklistMutation(baseOptions?: Apollo.MutationHookOptions<AddDocumentChecklistMutation, AddDocumentChecklistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDocumentChecklistMutation, AddDocumentChecklistMutationVariables>(AddDocumentChecklistDocument, options);
      }
export type AddDocumentChecklistMutationHookResult = ReturnType<typeof useAddDocumentChecklistMutation>;
export type AddDocumentChecklistMutationResult = Apollo.MutationResult<AddDocumentChecklistMutation>;
export type AddDocumentChecklistMutationOptions = Apollo.BaseMutationOptions<AddDocumentChecklistMutation, AddDocumentChecklistMutationVariables>;
export const GetAllCompanyLogsByIdDocument = gql`
    query getAllCompanyLogsById($where: company_email_log_bool_exp, $offset: Int, $limit: Int, $company_id: Int!, $order_by: [company_email_log_order_by!]) {
  company_email_log(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    email_event {
      subject
      to
      from
      cc
      bcc
      status
      failure_reason
      type
    }
    created_at
  }
  company_email_log_aggregate(where: {company_id: {_eq: $company_id}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllCompanyLogsByIdQuery__
 *
 * To run a query within a React component, call `useGetAllCompanyLogsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompanyLogsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompanyLogsByIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      company_id: // value for 'company_id'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetAllCompanyLogsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAllCompanyLogsByIdQuery, GetAllCompanyLogsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCompanyLogsByIdQuery, GetAllCompanyLogsByIdQueryVariables>(GetAllCompanyLogsByIdDocument, options);
      }
export function useGetAllCompanyLogsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCompanyLogsByIdQuery, GetAllCompanyLogsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCompanyLogsByIdQuery, GetAllCompanyLogsByIdQueryVariables>(GetAllCompanyLogsByIdDocument, options);
        }
export type GetAllCompanyLogsByIdQueryHookResult = ReturnType<typeof useGetAllCompanyLogsByIdQuery>;
export type GetAllCompanyLogsByIdLazyQueryHookResult = ReturnType<typeof useGetAllCompanyLogsByIdLazyQuery>;
export type GetAllCompanyLogsByIdQueryResult = Apollo.QueryResult<GetAllCompanyLogsByIdQuery, GetAllCompanyLogsByIdQueryVariables>;
export const ToggleDocumentChecklistDocument = gql`
    mutation toggleDocumentChecklist($args: ToggleCompanyDocumentChecklistInput) {
  ToggleCompanyDocumentChecklist(args: $args) {
    message
  }
}
    `;
export type ToggleDocumentChecklistMutationFn = Apollo.MutationFunction<ToggleDocumentChecklistMutation, ToggleDocumentChecklistMutationVariables>;

/**
 * __useToggleDocumentChecklistMutation__
 *
 * To run a mutation, you first call `useToggleDocumentChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleDocumentChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleDocumentChecklistMutation, { data, loading, error }] = useToggleDocumentChecklistMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useToggleDocumentChecklistMutation(baseOptions?: Apollo.MutationHookOptions<ToggleDocumentChecklistMutation, ToggleDocumentChecklistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleDocumentChecklistMutation, ToggleDocumentChecklistMutationVariables>(ToggleDocumentChecklistDocument, options);
      }
export type ToggleDocumentChecklistMutationHookResult = ReturnType<typeof useToggleDocumentChecklistMutation>;
export type ToggleDocumentChecklistMutationResult = Apollo.MutationResult<ToggleDocumentChecklistMutation>;
export type ToggleDocumentChecklistMutationOptions = Apollo.BaseMutationOptions<ToggleDocumentChecklistMutation, ToggleDocumentChecklistMutationVariables>;
export const GetDocumentChecklistStatusDocument = gql`
    query getDocumentChecklistStatus($id: Int!) {
  company_by_pk(id: $id) {
    document_checklist_enabled
  }
}
    `;

/**
 * __useGetDocumentChecklistStatusQuery__
 *
 * To run a query within a React component, call `useGetDocumentChecklistStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentChecklistStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentChecklistStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentChecklistStatusQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentChecklistStatusQuery, GetDocumentChecklistStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentChecklistStatusQuery, GetDocumentChecklistStatusQueryVariables>(GetDocumentChecklistStatusDocument, options);
      }
export function useGetDocumentChecklistStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentChecklistStatusQuery, GetDocumentChecklistStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentChecklistStatusQuery, GetDocumentChecklistStatusQueryVariables>(GetDocumentChecklistStatusDocument, options);
        }
export type GetDocumentChecklistStatusQueryHookResult = ReturnType<typeof useGetDocumentChecklistStatusQuery>;
export type GetDocumentChecklistStatusLazyQueryHookResult = ReturnType<typeof useGetDocumentChecklistStatusLazyQuery>;
export type GetDocumentChecklistStatusQueryResult = Apollo.QueryResult<GetDocumentChecklistStatusQuery, GetDocumentChecklistStatusQueryVariables>;
export const GetCompanySalesRmDocument = gql`
    query getCompanySalesRM($id: Int!) {
  company(where: {id: {_eq: $id}}) {
    sales_rm
  }
}
    `;

/**
 * __useGetCompanySalesRmQuery__
 *
 * To run a query within a React component, call `useGetCompanySalesRmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySalesRmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySalesRmQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanySalesRmQuery(baseOptions: Apollo.QueryHookOptions<GetCompanySalesRmQuery, GetCompanySalesRmQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanySalesRmQuery, GetCompanySalesRmQueryVariables>(GetCompanySalesRmDocument, options);
      }
export function useGetCompanySalesRmLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanySalesRmQuery, GetCompanySalesRmQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanySalesRmQuery, GetCompanySalesRmQueryVariables>(GetCompanySalesRmDocument, options);
        }
export type GetCompanySalesRmQueryHookResult = ReturnType<typeof useGetCompanySalesRmQuery>;
export type GetCompanySalesRmLazyQueryHookResult = ReturnType<typeof useGetCompanySalesRmLazyQuery>;
export type GetCompanySalesRmQueryResult = Apollo.QueryResult<GetCompanySalesRmQuery, GetCompanySalesRmQueryVariables>;
export const namedOperations = {
  Query: {
    getDocument_checklist_category: 'getDocument_checklist_category',
    getDocument_checklist_category_by_id: 'getDocument_checklist_category_by_id',
    getAllCompanyLogsById: 'getAllCompanyLogsById',
    getDocumentChecklistStatus: 'getDocumentChecklistStatus',
    getCompanySalesRM: 'getCompanySalesRM'
  },
  Mutation: {
    updateDocument_checklist: 'updateDocument_checklist',
    update_document_checklist_category_many: 'update_document_checklist_category_many',
    sendDocumentchecklistNotification: 'sendDocumentchecklistNotification',
    addDocumentChecklist: 'addDocumentChecklist',
    toggleDocumentChecklist: 'toggleDocumentChecklist'
  }
}