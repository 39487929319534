import { GetAllTdsByTradeQuery } from "./../data-access/hooks/company/emi-calendar.hooks";
import { Order_By } from "./../data-access/types";
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useLocation, useParams } from "react-router-dom";
import React, { useContext } from "react";
import {
  useDownloadAmortizationTableMutation,
  useEditTradeMutation,
  useGenerateAnnexureMutation,
  useGetAllBankAccountsQuery,
  useGetGlobalTradesQuery,
  useGetMandateByTradeQuery,
  useInitiatePayoutRequestMutation,
} from "../data-access/hooks/company/deals-trade.hooks";
import { useDownloadUploadedFileLazyQuery } from "../data-access/hooks/company/payment-partner.hooks";
import { getChangedValues } from "../utils/common";
import { Mandate_Type, TableType } from "../data-access/types";
import { CompanyContext } from "../contexts/CompanyContext";
import {
  useGetAllTdsByTradeQuery,
  useGetVirtualAccountBalanceMutation,
} from "../data-access/hooks/company/emi-calendar.hooks";

interface downloadInterface {
  type: TableType;
  trade_id: number;
}

const useCompanyTrade = () => {
  const { id: company_id, trade_id } = useParams();

  const { state }: any = useLocation();

  const emiStaus = (text: string) => {
    if (text === "DRAFT" || text === "SCHEDULED") {
      return "#666666";
    } else if (
      text === "SUCCESS" ||
      text === "COMPLETED" ||
      text === "CREDIT_SUCCESS" ||
      text === "DEBIT_SUCCESS"
    ) {
      return "#57B867";
    } else if (
      text === "INITIATED" ||
      text === "CREDIT_INITIATED" ||
      text === "REATTEMPT" ||
      text === "DEBIT_INITIATED"
    ) {
      return "#DBA314";
    } else if (
      text === "FAILED_DEBIT" ||
      text === "FAILED_CREDIT" ||
      text === "FAILED"
    ) {
      return "#DD3838";
    }
  };

  const [openAPAModal, setOpenAPAModal] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const [availableBalance, setAvailableBalance] = React.useState<
    number | undefined
  >();
  const [isEditing, setIsEditing] = React.useState(false);

  const { setShowAlert } = useContext(CompanyContext);

  const [mandateBankList, setMandateBankList] = React.useState<any[]>([]);

  const [allTradeData, setAllTradeData] = React.useState<any>(state?.data);

  const [investorsDetails, setInvestorsDetails] = React.useState<any[]>(
    allTradeData?.investments
  );

  const mandateFrequency = [
    "AD HOC",
    "INTRA_DAY",
    "DAILY",
    "WEEKLY",
    "MONTHLY",
    "BIMONTHLY",
    "QUARTERLY",
    "YEARLY",
    "SEMIANNUALLY",
  ];

  const [mandateList, setMandateList] = React.useState<any[]>([]);

  const [allTdsData, setAllTdsData] = React.useState<GetAllTdsByTradeQuery>();

  /**************************************************************************************************************************************** */
  const { loading: getAllLoading, refetch: GetTDSRefetch } =
    useGetAllTdsByTradeQuery({
      variables: {
        where: {
          trade_id: {
            _eq: Number(trade_id),
          },
        },
      },
      onCompleted: (item) => {
        setAllTdsData(item);
      },
      fetchPolicy: "no-cache",
    });

  /*************************************************************************************************** */
  const { loading: mandateLoading } = useGetMandateByTradeQuery({
    variables: {
      where: {
        company_id: {
          _eq: Number(company_id),
        },
      },
    },
    onCompleted: (item) => setMandateList(item.mandate),
    onError: (item) => {
      setShowAlert({
        show: true,
        message: item.message,
        type: "error",
      });
    },
  });

  //*************************************************************************************************** */
  const { loading: bankAccountsLoading } = useGetAllBankAccountsQuery({
    variables: {
      company_id: Number(company_id),
    },
    onCompleted: (item) => {
      setMandateBankList(item.account);
    },
    onError: (item) => {
      setShowAlert({
        show: true,
        message: item.message,
        type: "error",
      });
    },
  });

  //*************************************************************************************************** */
  const { loading: tradeDataLoading, refetch: getAllDataRefetch } =
    useGetGlobalTradesQuery({
      variables: {
        where: {
          id: {
            _eq: Number(trade_id),
          },
          company_id: {
            _eq: Number(company_id),
          },
        },
        order_by: {
          id: Order_By.Desc,
        },
      },
      onCompleted: (item) => {
        setAllTradeData(item?.trade[0]);
        if (item?.trade[0]?.status !== "OFFLINE") {
          getAccountEscrowBalance({
            variables: {
              args: {
                account_id: Number(
                  item?.trade[0]?.investments[0]?.withhold_account_id
                ),
              },
            },
          });
        }
      },
      onError: (item) => {
        setShowAlert({
          show: true,
          message: item.message,
          type: "error",
        });
      },
      fetchPolicy: "no-cache",
    });

  //*************************************************************************************************** */
  const [downloadAmortizationTable, { loading: downloadLoading }] =
    useDownloadAmortizationTableMutation({
      onCompleted: (data) => {
        const downloadUrl = data?.DownloadAmortizationTable?.downloadUrl;
        const link = document.createElement("a");
        link.href = `${downloadUrl}`;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      },
      onError: (item) => {
        setShowAlert({
          show: true,
          message: item.message,
          type: "error",
        });
      },
    });

  //*************************************************************************************************** */
  const handleDownloadTable = ({ type, trade_id }: downloadInterface) => {
    downloadAmortizationTable({
      variables: {
        args: {
          table_type: type,
          trade_id: trade_id,
        },
      },
    });
  };

  //*************************************************************************************************** */
  const [handleInitiatePayoutRequest, { loading: initiatePayoutLoading }] =
    useInitiatePayoutRequestMutation({
      onError: (item) => {
        setShowAlert({
          show: true,
          message: item.message,
          type: "error",
        });
      },
      onCompleted: () => getAllDataRefetch(),
    });

  //*************************************************************************************************** */
  const [downloadUploadFile, { loading: downloadAPALoading }] =
    useDownloadUploadedFileLazyQuery({
      onCompleted: (item: any) => {
        const downloadUrl = item?.generateFileRegistryDownloadURL?.download_url;
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      },
      onError: (item) => {
        setShowAlert({
          show: true,
          message: item.message,
          type: "error",
        });
      },
    });

  //*************************************************************************************************** */
  const [handleUpdateTradeData, { loading: updateLoading }] =
    useEditTradeMutation({
      onCompleted: (item) => {
        getAllDataRefetch();
        setIsEditing(false);
      },
      onError: (item) => {
        setShowAlert({
          show: true,
          message: item.message,
          type: "error",
        });
      },
    });

  //*************************************************************************************************** */
  const handleWithdrawalChange = (event: any, index: number) => {
    const list = [...investorsDetails];
    if (event?.target?.checked) {
      list[index]["withdrawal_mode"] = "MANUAL";
    } else {
      list[index]["withdrawal_mode"] = "AUTO";
    }
    setInvestorsDetails(list);
  };

  //*************************************************************************************************** */
  const returnConvertedValues = (item: any) => {
    const objKeys = Object.keys(item);

    if (objKeys.includes("is_offline")) {
      return {
        ...item,
        is_offline: JSON.parse(item["is_offline"]),
      };
    } else {
      return item;
    }
  };

  //*************************************************************************************************** */

  const formik = useFormik({
    initialValues: {
      mandate_configuration: {
        mandate_id:
          allTradeData?.mandate === null ? 0 : allTradeData?.mandate?.id,
        mandate_account_id:
          allTradeData?.mandate === null
            ? allTradeData?.mandate_account_id
            : "",
        mandate_frequency:
          allTradeData?.mandate === null ? allTradeData?.mandate_frequency : "",
        mandate_no_of_months:
          allTradeData?.mandate === null
            ? allTradeData?.mandate_no_of_months
            : "",
        max_presentation_amount:
          allTradeData?.mandate === null
            ? allTradeData?.max_presentation_amount
            : "",
        mandate_type:
          allTradeData?.mandate === null ? allTradeData?.mandate_type : "",
        reuse_mandate: allTradeData?.mandate === null ? false : true,
      },
      trade_configuration: {
        is_offline: allTradeData?.state === "OFFLINE" ? true : false,
        payout_value: allTradeData?.payout_value,
        roi: allTradeData?.roi,
        fees_without_gst: allTradeData?.fees_without_gst,
        validity: allTradeData?.validity,
        emi_start_date: allTradeData?.emi_start_date,
        debit_mode: allTradeData?.debit_mode,
        collection_mode: allTradeData?.collection_mode,
        billing_gst: allTradeData?.company?.billing_gst,
        billing_address: allTradeData?.company?.billing_address,
        remarks: allTradeData?.company?.remarks,
        payout_on: allTradeData?.company?.payout_on,
      },
    },
    onSubmit: (values) => {
      const trade_configure_data = getChangedValues(
        values.trade_configuration,
        formik.initialValues.trade_configuration
      );
      const mandate_data =
        values.mandate_configuration.mandate_id === 0
          ? {
              mandate_id: null,
              mandate_account_id: Number(
                values.mandate_configuration.mandate_account_id
              ),
              mandate_frequency: values.mandate_configuration.mandate_frequency,
              mandate_no_of_months: Number(
                values.mandate_configuration.mandate_no_of_months
              ),
              max_presentation_amount: Number(
                values.mandate_configuration.max_presentation_amount
              ),
              mandate_type: values.mandate_configuration
                .mandate_type as Mandate_Type,
            }
          : { mandate_id: Number(values.mandate_configuration.mandate_id) };

      if (allTradeData?.status === "ACTIVE") {
        handleUpdateTradeData({
          variables: {
            args: {
              investments: investorsDetails?.map((item) => {
                return {
                  investment_id: item.id,
                  withdrawal_mode: item?.withdrawal_mode,
                };
              }),
              trade_id: allTradeData?.id,
            },
          },
        });
      } else if (allTradeData?.status === "OFFLINE") {
        handleUpdateTradeData({
          variables: {
            args: {
              trade_id: allTradeData?.id,
              trade_configuration: returnConvertedValues(trade_configure_data),
            },
          },
        });
      } else {
        handleUpdateTradeData({
          variables: {
            args: {
              trade_id: allTradeData?.id,
              investments: investorsDetails?.map((item) => {
                return {
                  investment_id: item.id,
                  offered_amount: Number(item.offered_amount),
                  invested_amount: Number(item.invested_amount),
                  emi_split_ratio: item.emi_split_ratio,
                  withdrawal_mode: item.withdrawal_mode,
                  fees_per_emi: Number(item.fees),
                };
              }),
              mandate_configuration: mandate_data,
              trade_configuration: returnConvertedValues(trade_configure_data),
            },
          },
        });
      }
    },
  });

  //*************************************************************************************************** */
  const [handleAnnexure, { loading: APALoading }] = useGenerateAnnexureMutation(
    {
      onCompleted: (item) => {
        const downloadUrl = item?.GenerateAnnexure?.download_url;
        const link = document.createElement("a");
        link.href = downloadUrl ?? "";
        link?.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
        setAnchorEl(null);
      },
      onError: (item) => {
        setShowAlert({
          show: true,
          message: item.message,
          type: "error",
        });
        setAnchorEl(null);
      },
    }
  );

  //*************************************************************************************************** */

  const [getAccountEscrowBalance] = useGetVirtualAccountBalanceMutation({
    onCompleted: (item) => {
      setAvailableBalance(item.GetVirtualAccountBalance?.balance);
    },
    onError: (item) => {
      setShowAlert({
        show: true,
        message: item.message,
        type: "error",
      });
    },
  });

  //*************************************************************************************************** */
  const handleInvestorChange = (e: any, index: number) => {
    const { value, name } = e?.target;
    const list = [...investorsDetails];
    //@ts-ignore
    list[index][name] = value;
    setInvestorsDetails(list);
  };

  return {
    emiStaus,
    openAPAModal,
    setOpenAPAModal,
    allTradeData,
    getAllDataRefetch,
    bankAccountsLoading,
    mandateLoading,
    formik,
    tradeDataLoading,
    isEditing,
    setIsEditing,
    handleInitiatePayoutRequest,
    initiatePayoutLoading,
    updateLoading,
    downloadLoading,
    downloadAPALoading,
    handleDownloadTable,
    downloadUploadFile,
    investorsDetails,
    handleInvestorChange,
    mandateList,
    mandateBankList,
    handleWithdrawalChange,
    anchorEl,
    setAnchorEl,
    open,
    handleAnnexure,
    APALoading,
    mandateFrequency,
    company_id,
    trade_id,
    availableBalance,
    allTdsData,
    getAllLoading,
    GetTDSRefetch,
  };
};

export default useCompanyTrade;
