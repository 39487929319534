/* eslint-disable react-hooks/exhaustive-deps */
import {
  Divider as MuiDivider,
  Typography,
  Paper,
  Grid,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { ReactComponent as DownloadIcon } from "../../../assets/Icon/Outline/download-icon.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/Icon/Outline/document-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/Icon/Outline/trash-icon.svg";

import CommonBreadCrumb from "../../../components/CommonBreadCrumb";
import {
  useDownloadAccountDataFileLazyQuery,
  useUploadAccountDataFileMutation,
} from "../../../data-access/hooks/company/account-data.hooks";
import { RequestType } from "../../../data-access/types";

const Divider = styled(MuiDivider)(spacing);

const AddAccountData = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [uploadedNewFile, setUploadNewFile] = useState<any[]>([]);

  const onDrop = useCallback(async (acceptedFiles: any) => {
    setUploadNewFile(acceptedFiles);
    setUploadLoading(true);

    const newFiles = await Promise.all(
      acceptedFiles.map((file: any) => uploadFile(file))
    );

    const consFiles = [...uploadedNewFile, ...newFiles];

    setUploadNewFile(consFiles);
    setUploadLoading(false);
    return;
  }, []);

  const deleteFile = async (id: number) => {
    const newFiles = uploadedNewFile.filter((f) => f.id !== id);
    setUploadNewFile(newFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    multiple: true,
    onDrop,
  });

  const [uploadLoading, setUploadLoading] = useState(false);

  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  const uploadFile = async (selectedFile: File) => {
    const formData = new FormData();
    formData.append("file", selectedFile, selectedFile.name);
    formData.append("category_id", "BANKING");
    formData.append("password", "");
    const token = localStorage.getItem("accessToken");
    const config: any = {
      method: "post",
      url: `${process.env.REACT_APP_ACTIONS_BASE_URL}secure-upload`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        "X-hasura-tenant-id": id,
      },
    };

    const response = await axios(config);
    setUploadLoading(false);
    const fileStatement: any = {
      name: selectedFile.name,
      file_registry_id: response.data.file_registry_id,
      id: response.data.file_registry_id,
    };
    return fileStatement;
  };

  const [uploadSelectedFile, { loading }] = useUploadAccountDataFileMutation({
    onCompleted: () => {
      navigate(`/company/${id}/account-data`);
    },
    onError: (item) => {
      setShowAlert({
        message: item.message,
        show: true,
        type: "error",
      });
    },
  });

  const [downloadUploadFile, { loading: downloadLoading }] =
    useDownloadAccountDataFileLazyQuery({
      onCompleted: (item) => {
        const downloadUrl =
          item.generateFileRegistryDownloadURL?.download_url || "";
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      },
    });

  return (
    <>
      <Helmet title="Accounting Data" />
      <Grid container display={"flex"} flexDirection={"column"}>
        <Grid
          item
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <CommonBreadCrumb
              typographyText="Add Accounting Data"
              linksText={[
                { link: "Company Queues", to: "/" },
                {
                  link: "Accounting Data",
                  to: `/company/${id}/account-data`,
                },
              ]}
              showCompany
            />
          </Grid>
        </Grid>
        <Divider mt="16px" mb="28px" />
        <Grid item>
          <Grid
            container
            item
            display={"flex"}
            flexDirection={"column"}
            width={"50%"}
            gap={"25px"}
          >
            <Grid item>
              <Typography fontWeight={600} fontSize={"18px"}>
                Accounting Data File
              </Typography>
            </Grid>
            <Paper>
              <Grid
                container
                padding={"20px"}
                display={"flex"}
                flexDirection={"column"}
                gap={"25px"}
                alignItems={"flex-start"}
              >
                <Grid
                  item
                  width="100%"
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  {uploadLoading ? (
                    <CircularProgress />
                  ) : (
                    <div
                      style={{
                        background: "#1E88E50A",
                        width: "100%",
                        border: "dashed 1px #1E88E5",
                        borderRadius: "11px",
                        height: "120px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div>
                        <div>
                          <Typography fontWeight={600} fontSize={"16px"}>
                            Drag and drop or
                            <span
                              style={{
                                color: "#1E88E5",
                              }}
                            >
                              &nbsp; Browse
                            </span>
                          </Typography>
                          <Typography
                            fontWeight={400}
                            fontSize={"14px"}
                            color={"#7D87A6"}
                          >
                            {"Supported file type  CSV"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}
                  {uploadedNewFile?.length !== 0 && (
                    <Grid container display={"flex"} flexDirection={"column"}>
                      <Typography
                        fontWeight={600}
                        fontSize={"14px"}
                        margin={"10px 0"}
                      >
                        Your File
                      </Typography>
                      {uploadedNewFile.map((dataItems) => (
                        <Grid
                          item
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Grid item display={"flex"} alignItems={"center"}>
                            <DocumentIcon
                              width={16}
                              height={16}
                              color="#1E88E5"
                            />
                            <Typography
                              fontWeight={400}
                              fontSize={"14px"}
                              margin={"0 5px"}
                            >
                              {dataItems?.name}
                            </Typography>
                          </Grid>
                          <Grid item display={"flex"} alignItems={"center"}>
                            {downloadLoading ? (
                              <CircularProgress size={16} />
                            ) : (
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  downloadUploadFile({
                                    variables: {
                                      args: {
                                        file_registry_id: dataItems.id,
                                      },
                                    },
                                  });
                                }}
                              >
                                <DownloadIcon
                                  width={16}
                                  height={16}
                                  color="#1E88E5"
                                />
                              </IconButton>
                            )}
                            <IconButton
                              color="primary"
                              onClick={() => deleteFile(dataItems.id)}
                            >
                              <DeleteIcon
                                width={16}
                                height={16}
                                color="#FF6363"
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
                <Grid item width="100%">
                  <Button
                    fullWidth
                    variant="secondarySmall"
                    onClick={() => {
                      uploadSelectedFile({
                        variables: {
                          args: {
                            file_ids: uploadedNewFile.map((item) => {
                              return item.file_registry_id;
                            }),
                            req_type: RequestType.Submit,
                          },
                        },
                      });
                    }}
                  >
                    {loading ? <CircularProgress size={18} /> : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showAlert.show}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() =>
            setShowAlert({
              ...showAlert,
              show: false,
            })
          }
          //@ts-ignore
          severity={showAlert.type}
          sx={{ width: "100%" }}
          variant="outlined"
        >
          {showAlert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddAccountData;
