import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllAgreementsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Company_Agreement_Logs_View_Bool_Exp>;
}>;


export type GetAllAgreementsQuery = { __typename?: 'query_root', company_agreement_logs_view: Array<{ __typename?: 'company_agreement_logs_view', status?: string | null, category_id?: string | null, signed_at?: any | null, failure_reason?: string | null, source?: string | null, source_id?: string | null, id?: number | null, signed_file?: { __typename?: 'file_registry', id: number, name: string } | null, draft_file?: { __typename?: 'file_registry', id: number, name: string } | null }> };

export type UploadAgreementMutationVariables = Types.Exact<{
  args: Types.UploadAgreementInput;
}>;


export type UploadAgreementMutation = { __typename?: 'mutation_root', UploadAgreement?: { __typename?: 'UploadAgreementOutput', message: string } | null };

export type TriggerCustomNdaMutationVariables = Types.Exact<{
  args: Types.TriggerCustomNdaOutputInput;
}>;


export type TriggerCustomNdaMutation = { __typename?: 'mutation_root', TriggerCustomNDA?: { __typename?: 'TriggerCustomNDAOutput', message: string } | null };

export type DownloadAgreementMutationVariables = Types.Exact<{
  args: Types.DownloadAgreementInput;
}>;


export type DownloadAgreementMutation = { __typename?: 'mutation_root', DownloadAgreement?: { __typename?: 'DownloadAgreementOutput', download_url: string } | null };


export const GetAllAgreementsDocument = gql`
    query getAllAgreements($where: company_agreement_logs_view_bool_exp) {
  company_agreement_logs_view(where: $where) {
    status
    category_id
    signed_at
    signed_file {
      id
      name
    }
    draft_file {
      id
      name
    }
    failure_reason
    source
    source_id
    id
  }
}
    `;

/**
 * __useGetAllAgreementsQuery__
 *
 * To run a query within a React component, call `useGetAllAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAgreementsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllAgreementsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAgreementsQuery, GetAllAgreementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAgreementsQuery, GetAllAgreementsQueryVariables>(GetAllAgreementsDocument, options);
      }
export function useGetAllAgreementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAgreementsQuery, GetAllAgreementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAgreementsQuery, GetAllAgreementsQueryVariables>(GetAllAgreementsDocument, options);
        }
export type GetAllAgreementsQueryHookResult = ReturnType<typeof useGetAllAgreementsQuery>;
export type GetAllAgreementsLazyQueryHookResult = ReturnType<typeof useGetAllAgreementsLazyQuery>;
export type GetAllAgreementsQueryResult = Apollo.QueryResult<GetAllAgreementsQuery, GetAllAgreementsQueryVariables>;
export const UploadAgreementDocument = gql`
    mutation uploadAgreement($args: UploadAgreementInput!) {
  UploadAgreement(args: $args) {
    message
  }
}
    `;
export type UploadAgreementMutationFn = Apollo.MutationFunction<UploadAgreementMutation, UploadAgreementMutationVariables>;

/**
 * __useUploadAgreementMutation__
 *
 * To run a mutation, you first call `useUploadAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAgreementMutation, { data, loading, error }] = useUploadAgreementMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUploadAgreementMutation(baseOptions?: Apollo.MutationHookOptions<UploadAgreementMutation, UploadAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadAgreementMutation, UploadAgreementMutationVariables>(UploadAgreementDocument, options);
      }
export type UploadAgreementMutationHookResult = ReturnType<typeof useUploadAgreementMutation>;
export type UploadAgreementMutationResult = Apollo.MutationResult<UploadAgreementMutation>;
export type UploadAgreementMutationOptions = Apollo.BaseMutationOptions<UploadAgreementMutation, UploadAgreementMutationVariables>;
export const TriggerCustomNdaDocument = gql`
    mutation triggerCustomNDA($args: TriggerCustomNDAOutputInput!) {
  TriggerCustomNDA(args: $args) {
    message
  }
}
    `;
export type TriggerCustomNdaMutationFn = Apollo.MutationFunction<TriggerCustomNdaMutation, TriggerCustomNdaMutationVariables>;

/**
 * __useTriggerCustomNdaMutation__
 *
 * To run a mutation, you first call `useTriggerCustomNdaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerCustomNdaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerCustomNdaMutation, { data, loading, error }] = useTriggerCustomNdaMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useTriggerCustomNdaMutation(baseOptions?: Apollo.MutationHookOptions<TriggerCustomNdaMutation, TriggerCustomNdaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerCustomNdaMutation, TriggerCustomNdaMutationVariables>(TriggerCustomNdaDocument, options);
      }
export type TriggerCustomNdaMutationHookResult = ReturnType<typeof useTriggerCustomNdaMutation>;
export type TriggerCustomNdaMutationResult = Apollo.MutationResult<TriggerCustomNdaMutation>;
export type TriggerCustomNdaMutationOptions = Apollo.BaseMutationOptions<TriggerCustomNdaMutation, TriggerCustomNdaMutationVariables>;
export const DownloadAgreementDocument = gql`
    mutation downloadAgreement($args: DownloadAgreementInput!) {
  DownloadAgreement(args: $args) {
    download_url
  }
}
    `;
export type DownloadAgreementMutationFn = Apollo.MutationFunction<DownloadAgreementMutation, DownloadAgreementMutationVariables>;

/**
 * __useDownloadAgreementMutation__
 *
 * To run a mutation, you first call `useDownloadAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadAgreementMutation, { data, loading, error }] = useDownloadAgreementMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDownloadAgreementMutation(baseOptions?: Apollo.MutationHookOptions<DownloadAgreementMutation, DownloadAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadAgreementMutation, DownloadAgreementMutationVariables>(DownloadAgreementDocument, options);
      }
export type DownloadAgreementMutationHookResult = ReturnType<typeof useDownloadAgreementMutation>;
export type DownloadAgreementMutationResult = Apollo.MutationResult<DownloadAgreementMutation>;
export type DownloadAgreementMutationOptions = Apollo.BaseMutationOptions<DownloadAgreementMutation, DownloadAgreementMutationVariables>;
export const namedOperations = {
  Query: {
    getAllAgreements: 'getAllAgreements'
  },
  Mutation: {
    uploadAgreement: 'uploadAgreement',
    triggerCustomNDA: 'triggerCustomNDA',
    downloadAgreement: 'downloadAgreement'
  }
}