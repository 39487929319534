import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import SimpleInput from "../../../components/input/SimpleInput";
import * as Yup from "yup";
import { ReactComponent as ExclamationCircleIcon } from "../../../assets/Icon/Outline/exclamation-icon.svg";
import { ReactComponent as XIcon } from "../../../assets/Icon/Outline/x-icon.svg";
import SimpleSelect from "../../../components/input/SimpleSelect";
import { useTriggerCustomNdaMutation } from "../../../data-access/hooks/company/nda-logs.hooks";
import ConfirmCustomNDA from "./ConfirmCustomNDA";

interface CustomNDAModalInterface {
  onClose?: any;
  allUsers?: any[];
  setAddModal?: any;
  companyDetailsData?: any;
}

const CustomNDA = ({
  onClose,
  setAddModal,
  allUsers,
  companyDetailsData,
}: CustomNDAModalInterface) => {
  const [currentStep, setCurrentStep] = useState(1);

  const [errorMessage, setErrorMessage] = useState("");

  /************************************************************************************************************************************* */
  const validationSchema = Yup.object().shape({
    authorized_signatory_id: Yup.string().required(
      "Add a signatory to proceed."
    ),
    attorney_name: Yup.string()
      .required("Name is required.")
      .matches(/^((?![0-9]).)*$/, "First name must only contain alphabets"),
    notice_address: Yup.string().required("Address is required."),
    notice_email: Yup.string()
      .required("Email is required.")
      .email("Please enter a valid email."),
    attorney_mobile: Yup.string()
      .required("Mobile number is required")
      .matches(/^\d{10}$/, "Please enter valid mobile number"),
  });

  /************************************************************************************************************************************* */
  const [handleCustomNDA, { loading: customLoading }] =
    useTriggerCustomNdaMutation({
      onCompleted: (item) => {
        onClose(false);
      },
      onError: (item) => {
        setErrorMessage(item.message);
      },
    });

  /************************************************************************************************************************************* */
  const formik = useFormik({
    initialValues: {
      authorized_signatory_id: null,
      attorney_mobile: "",
      attorney_name: "",
      notice_address: "",
      notice_email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (currentStep === 1) {
        setCurrentStep(2);
      } else if (currentStep === 2) {
        setCurrentStep(3);
      } else {
        handleCustomNDA({
          variables: {
            args: {
              authorized_signatory_id: Number(values?.authorized_signatory_id),
              attorney_mobile: values?.attorney_mobile,
              attorney_name: values?.attorney_name,
              notice_address: values?.notice_address,
              notice_email: values?.notice_email,
            },
          },
        });
      }
    },
  });

  return (
    <>
      <Grid
        container
        style={{ background: "white" }}
        width="25%"
        p={5}
        gap="16px"
      >
        {currentStep !== 3 && (
          <Grid
            item
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            width="100%"
          >
            <Typography
              style={{ display: "flex", flexDirection: "column" }}
              fontSize={"22px"}
              fontWeight={600}
            >
              Custom NDA Details
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#AAA5B7",
                }}
              >
                {currentStep === 1
                  ? "Step 1 of 2 : Signatory Details"
                  : "Step 2 of 2 : Address & Email for notices"}
              </span>
            </Typography>
            <IconButton style={{ padding: "0" }} onClick={() => onClose(false)}>
              <XIcon color="#000000" width="18px" height="18px" />
            </IconButton>
          </Grid>
        )}
        {currentStep !== 3 && (
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            style={{ width: "100%" }}
          ></Divider>
        )}

        <Grid
          item
          display="flex"
          width="100%"
          justifyContent={"center"}
          alignItems={"center"}
        >
          <form
            onSubmit={formik.handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              width: "100%",
            }}
          >
            {(errorMessage || allUsers?.length === 0) && (
              <Alert severity="error">
                {allUsers?.length === 0
                  ? "Add a signatory to proceed."
                  : errorMessage}
              </Alert>
            )}
            {/** DETAILS FORM  ****************************************************************************************** */}
            <Grid item>
              {currentStep === 1 && (
                <>
                  <FormControl fullWidth>
                    <SimpleInput
                      value={companyDetailsData?.name}
                      inputlabel={
                        <Grid container alignItems={"center"} gap={"2px"}>
                          <Typography>Company Name</Typography>
                          <Tooltip title="Company Name to be added in the NDA.">
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <ExclamationCircleIcon
                                width="16px"
                                height="16px"
                                color="#1E88E5"
                              />
                            </span>
                          </Tooltip>
                        </Grid>
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <SimpleSelect
                      formlabel={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span>Select Signatory</span>
                          <Button
                            onClick={() => {
                              setAddModal(true);
                            }}
                            style={{ textDecoration: "underline" }}
                          >
                            Add Signatory
                          </Button>
                        </div>
                      }
                      error={
                        formik.touched.authorized_signatory_id &&
                        Boolean(formik.errors.authorized_signatory_id)
                      }
                      helperText={
                        formik.touched.authorized_signatory_id &&
                        formik.errors.authorized_signatory_id
                      }
                      onChange={formik.handleChange}
                      value={formik.values.authorized_signatory_id}
                      name="authorized_signatory_id"
                      disabled={allUsers?.length === 0}
                    >
                      {allUsers?.map((item) => (
                        <MenuItem value={item?.id}>{item?.full_name}</MenuItem>
                      ))}
                    </SimpleSelect>
                  </FormControl>
                </>
              )}
              {currentStep === 2 && (
                <Grid item display={"flex"} gap={"10px"} flexDirection="column">
                  <FormControl fullWidth>
                    <SimpleInput
                      name="notice_address"
                      inputlabel={"Notice Address"}
                      placeholder="Enter Notice Address"
                      onChange={formik.handleChange}
                      value={formik.values.notice_address}
                      error={
                        formik.touched.notice_address &&
                        Boolean(formik.errors.notice_address)
                      }
                      helperText={
                        formik.touched.notice_address &&
                        formik.errors.notice_address
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <SimpleInput
                      name="notice_email"
                      inputlabel={"Notice Email"}
                      placeholder="Enter Notice Email"
                      onChange={formik.handleChange}
                      value={formik.values.notice_email}
                      error={
                        formik.touched.notice_email &&
                        Boolean(formik.errors.notice_email)
                      }
                      helperText={
                        formik.touched.notice_email &&
                        formik.errors.notice_email
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <SimpleInput
                      name="attorney_name"
                      inputlabel={"Attorney Name"}
                      placeholder="Enter ttorney Name"
                      onChange={formik.handleChange}
                      value={formik.values.attorney_name}
                      error={
                        formik.touched.attorney_name &&
                        Boolean(formik.errors.attorney_name)
                      }
                      helperText={
                        formik.touched.attorney_name &&
                        formik.errors.attorney_name
                      }
                      InputProps={{
                        startAdornment: <div>Mr./Mrs.</div>,
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <SimpleInput
                      name="attorney_mobile"
                      inputlabel={"Attorney Mobile"}
                      placeholder="Enter torney Mobile"
                      onChange={formik.handleChange}
                      value={formik.values.attorney_mobile}
                      error={
                        formik.touched.attorney_mobile &&
                        Boolean(formik.errors.attorney_mobile)
                      }
                      helperText={
                        formik.touched.attorney_mobile &&
                        formik.errors.attorney_mobile
                      }
                      InputProps={{
                        startAdornment: <div>+91</div>,
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
              {currentStep === 3 && (
                <ConfirmCustomNDA
                  data={{
                    email: formik.values.notice_email,
                    company_name: companyDetailsData.name,
                  }}
                />
              )}
            </Grid>
            {/** SHOW ONLY FORM  ****************************************************************************************** */}
            {formik.values.authorized_signatory_id && currentStep === 1 && (
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Grid item>
                  <FormControl fullWidth>
                    <SimpleInput
                      inputlabel="Full Name"
                      name="full_name"
                      value={
                        allUsers?.find(
                          (item) =>
                            item?.id === formik?.values?.authorized_signatory_id
                        ).full_name
                      }
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <SimpleInput
                      name="email"
                      inputlabel={
                        <Grid container alignItems={"center"} gap={"2px"}>
                          <Typography>Email-ID</Typography>
                          <Tooltip title="Recipient Email ID where the NDA will be sent.">
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <ExclamationCircleIcon
                                width="16px"
                                height="16px"
                              />
                            </span>
                          </Tooltip>
                        </Grid>
                      }
                      value={
                        allUsers?.find(
                          (item) =>
                            item?.id === formik?.values?.authorized_signatory_id
                        ).email
                      }
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <SimpleInput
                      inputlabel="Mobile Number"
                      name="mobile_number"
                      value={
                        allUsers?.find(
                          (item) =>
                            item?.id === formik?.values?.authorized_signatory_id
                        ).mobile_number
                      }
                      disabled
                      InputProps={{
                        startAdornment: (
                          <div style={{ display: "flex" }}>
                            +91
                            <Divider
                              orientation="vertical"
                              flexItem
                              style={{ marginLeft: "10px" }}
                            ></Divider>
                          </div>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <SimpleInput
                      inputlabel="Designation"
                      name="designation"
                      value={
                        allUsers?.find(
                          (item) =>
                            item?.id === formik?.values?.authorized_signatory_id
                        ).designation
                      }
                      disabled
                    />
                  </FormControl>
                </Grid>
              </form>
            )}
            <Grid item>
              {currentStep === 1 && (
                <Button
                  onClick={() => setCurrentStep(2)}
                  fullWidth
                  variant="primarySmall"
                  disabled={!formik.values.authorized_signatory_id}
                >
                  Proceed
                </Button>
              )}
              {currentStep === 2 && (
                <Grid item display={"flex"} gap="30px">
                  <Button
                    onClick={() => setCurrentStep(1)}
                    fullWidth
                    variant="secondarySmall"
                  >
                    Back
                  </Button>
                  <Button
                    disabled={customLoading}
                    type="submit"
                    fullWidth
                    variant="primarySmall"
                  >
                    {customLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      "Send NDA"
                    )}
                  </Button>
                </Grid>
              )}
              {currentStep === 3 && (
                <Grid item display={"flex"} gap="30px">
                  <Button
                    onClick={() => setCurrentStep(2)}
                    fullWidth
                    variant="secondarySmall"
                  >
                    Back
                  </Button>
                  <Button
                    disabled={customLoading}
                    type="submit"
                    fullWidth
                    variant="primarySmall"
                  >
                    {customLoading ? <CircularProgress size={20} /> : "Send "}
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomNDA;
