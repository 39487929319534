/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { verifyEmail } from "../../services/auth";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function VerifyEmail() {
  const navigate = useNavigate();
  const params = useParams();

  const [isVerifying, setVerifying] = useState(false);
  const [inValidLink, setInvalidLink] = useState(false);

  const [verified, setVerified] = useState(false);

  const verifyCode = async (code: string) => {
    setVerifying(true);
    try {
      const resp = await verifyEmail(code);
      setVerified(resp.data.success);
    } catch (error: any) {
      setInvalidLink(true);
    } finally {
      setVerifying(false);
    }
  };
  useEffect(() => {
    const code = params?.code;
    if (code) {
      verifyCode(code);
    } else {
      navigate("/auth/sign-in");
    }
  }, []);
  return (
    <Wrapper>
      <Helmet title="Verify Email" />
      {isVerifying && (
        <Typography component="h2" variant="h5" align="center" gutterBottom>
          Verifying Your Email...
        </Typography>
      )}

      {verified && (
        <>
          <Typography component="h2" variant="h5" align="center" gutterBottom>
            Email Verified
          </Typography>
          <Button href="/auth/sign-in">LOGIN</Button>
        </>
      )}

      {inValidLink && (
        <div className="small-container">
          <div className="verify-account mt-36">
            <Typography component="h2" variant="h5" align="center" gutterBottom>
              Invalid or Expired link
            </Typography>
          </div>
          <div>
            <Button href="/auth/sign-in">LOGIN</Button>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

export default VerifyEmail;
