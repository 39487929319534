import React from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import AddInvestor from "./AddInvestor";
import { FieldArray, Form, Formik } from "formik";
import AddUsers from "./AddUsers";
import * as Yup from "yup";
import AddInvestorBankAccount from "./AddInvestorBankAccount";
import UploadInvestorAgreement from "./UploadInvestorAgreement";
import {
  useAddInvestorsUsersMutation,
  useGetInvestorUsersLazyQuery,
  useUpdateInvestorUserByIdMutation,
} from "../../../data-access/hooks/company/investors.hooks";
import { Investor_Type } from "../../../data-access/types";
import SideModal from "../../../components/Modals/SideModal";

interface processDataInterface {
  investorData: any;
  userData: any;
  bankData: any;
  agreementData: any;
}

interface InvestorModalInterface {
  setOpen: Function;
  currentStep: number;
  setCurrentStep?: any;
  processData?: processDataInterface;
  setProcessData?: any;
  investorId?: number;
  editData?: any;
  setEditData?: any;
  modalType?: "standalone" | "dependent";
  getInvestorRefetch?: any;
  getAllInvestorRefetch?: any;
  getBankRefetch?: any;
  open?: any;
}

const InvestorModal = ({
  setOpen,
  currentStep,
  setCurrentStep,
  processData,
  setProcessData,
  modalType,
  investorId,
  editData,
  getInvestorRefetch,
  getBankRefetch,
  setEditData,
  open,
}: InvestorModalInterface) => {
  const updateUserData =
    modalType === "standalone" ? [editData] : processData?.userData;

  let arrayHelpersRef: any;

  let arraylength: any;

  const [expanded, setExpanded] = React.useState(0);

  const companyPanRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  const [addUserError, setAddUserError] = React.useState("");

  /**===================================================================================================================*/
  const validationSchema = Yup.object().shape({
    users: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string().required("Please enter First Name"),
        pan: Yup.string().matches(
          companyPanRegex,
          "Please enter a valid PAN number"
        ),
        last_name: Yup.string().required("Please enter Last Name"),
        email: Yup.string()
          .required("Please enter Email")
          .email("Please Enter a valid email"),
        mobile_number: Yup.string()
          .required("Please enter Mobile Number")
          .length(10, "Mobile number should be 10 digits"),
        designation: Yup.string().required("Please enter Designation"),
      })
    ),
  });

  /**===================================================================================================================*/
  const addNewUserForm = () => {
    arrayHelpersRef?.push({
      last_name: "",
      first_name: "",
      email: "",
      mobile_number: "",
      designation: "",
      pan: "",
    });
    setExpanded(arraylength);
  };

  /**===================================================================================================================*/
  const removeUserForm = (removeIndex: number) => {
    arrayHelpersRef.remove(removeIndex);
    setExpanded(removeIndex - 1);
  };

  /**===================================================================================================================*/
  const [handleUpdateUserData, { loading: updateUserLoading }] =
    useUpdateInvestorUserByIdMutation({
      onCompleted: (item) => {
        getInvestorRefetch();

        setAddUserError("");
        if (editData?.id) {
          getInvestorRefetch();
          setOpen(false);
          setEditData({});
        } else {
          setCurrentStep(2);
          getInvestorUsersById({
            variables: {
              where: {
                id: {
                  _in: processData?.userData?.map((itemData: any) => {
                    return itemData.id;
                  }),
                },
              },
            },
          });
        }
      },
      onError: (error) => {
        setAddUserError(error.message);
      },
    });

  /**===================================================================================================================*/
  const [handleAddUsers, { loading: addUserLoading }] =
    useAddInvestorsUsersMutation({
      onCompleted: (item) => {
        getInvestorRefetch();
        setAddUserError("");
        if (investorId) {
          getInvestorRefetch();
          setOpen(false);
        } else {
          setCurrentStep(2);
          getInvestorUsersById({
            variables: {
              where: {
                id: {
                  _in: item.AddInvestorUser?.investor_users,
                },
              },
            },
          });
        }
      },
      onError: (item) => {
        setAddUserError(item.message);
      },
    });

  /**===================================================================================================================*/
  const [getInvestorUsersById] = useGetInvestorUsersLazyQuery({
    onCompleted: (item) => {
      const data = item.investor_user.map((dataItems) => {
        if (dataItems?.pan === "") {
          return {
            last_name: dataItems.user.last_name,
            id: dataItems.id,
            first_name: dataItems.user.first_name,
            email: dataItems.user.email,
            mobile_number: dataItems.user.mobile_number,
            designation: dataItems.user.designation,
          };
        } else {
          return {
            last_name: dataItems.user.last_name,
            id: dataItems.id,
            first_name: dataItems.user.first_name,
            email: dataItems.user.email,
            mobile_number: dataItems.user.mobile_number,
            designation: dataItems.user.designation,
            pan: dataItems.pan,
          };
        }
      });
      setProcessData({
        ...processData,
        userData: data,
      });
    },
  });
  /**===================================================================================================================*/

  return (
    <>
      {/***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */}
      {currentStep === 0 && (
        <AddInvestor
          setCurrentStep={setCurrentStep}
          processData={processData}
          setProcessData={setProcessData}
          editData={editData}
          setOpen={setOpen}
          open={open}
          getInvestorRefetch={getInvestorRefetch}
          modalType={modalType}
          currentStep={currentStep}
        />
      )}
      {/***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */}
      {currentStep === 1 && (
        <SideModal
          open={currentStep === 1 && open ? true : false}
          onClose={() => setOpen(false)}
          modalTitle={
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
            >
              <Grid item>
                <Typography fontWeight={700} fontSize={20} lineHeight={1}>
                  {editData?.id || !!processData?.userData?.length
                    ? "Update User"
                    : "Add User"}
                </Typography>{" "}
              </Grid>
              <Grid item>
                <Button variant="secondarySmall" onClick={addNewUserForm}>
                  Add User
                </Button>
              </Grid>
            </Grid>
          }
          footer={
            <Grid
              xs={12}
              width={"100%"}
              item
              display={"flex"}
              justifyContent="center"
              gap="10px"
            >
              {modalType !== "standalone" && !editData && (
                <Button
                  onClick={() => setCurrentStep(0)}
                  type="submit"
                  fullWidth
                  variant="secondarySmall"
                  form="add-user-form"
                >
                  Back
                </Button>
              )}
              <Button
                disabled={addUserLoading || updateUserLoading}
                type="submit"
                fullWidth
                variant="primarySmall"
                form="add-user-form"
              >
                {addUserLoading || updateUserLoading ? (
                  <CircularProgress size={20} />
                ) : editData?.id ? (
                  "Submit"
                ) : (
                  "Next"
                )}
              </Button>
            </Grid>
          }
        >
          {Boolean(addUserError) && (
            <Alert
              onClose={() => {
                setAddUserError("");
              }}
              style={{ margin: "20px 0" }}
              severity="error"
            >
              {addUserError}
            </Alert>
          )}

          <Formik
            validationSchema={
              ![
                Investor_Type.Bank,
                Investor_Type.FamilyOffice,
                Investor_Type.Funds,
                Investor_Type.Nbfc,
              ].includes(processData?.investorData.type) && validationSchema
            }
            initialValues={{
              users:
                editData?.id || !!processData?.userData?.length
                  ? updateUserData
                  : [
                      {
                        last_name: "",
                        first_name: "",
                        email: "",
                        mobile_number: "",
                        designation: "",
                        pan: "",
                      },
                    ],
            }}
            onSubmit={(item) => {
              const responseObject = item.users.map((data: any) => {
                if (data.pan === "") {
                  return {
                    last_name: data.last_name,
                    first_name: data.first_name,
                    email: data.email,
                    mobile_number: `${data.mobile_number}`,
                    designation: data.designation,
                  };
                } else {
                  return {
                    last_name: data.last_name,
                    pan: data.pan,
                    first_name: data.first_name,
                    email: data.email,
                    mobile_number: `${data.mobile_number}`,
                    designation: data.designation,
                  };
                }
              });
              if (
                item.users[0].first_name === "" &&
                item.users[0].pan === "" &&
                item.users[0].last_name === "" &&
                item.users[0].email === "" &&
                item.users[0].mobile_number === "" &&
                item.users[0].designation === "" &&
                [
                  Investor_Type.Bank,
                  Investor_Type.FamilyOffice,
                  Investor_Type.Funds,
                  Investor_Type.Nbfc,
                ].includes(processData?.investorData.type)
              ) {
                setCurrentStep(2);
              } else if (editData?.id || !!processData?.userData?.length) {
                // eslint-disable-next-line array-callback-return
                item.users?.map((item: any) => {
                  if (item?.id) {
                    handleUpdateUserData({
                      variables: {
                        args: {
                          investor_user_id: item?.id,
                          first_name: item.first_name,
                          last_name: item.last_name,
                          pan: item.pan,
                          mobile_number: item.mobile_number,
                          designation: item.designation,
                        },
                      },
                    });
                  } else {
                    handleAddUsers({
                      variables: {
                        args: {
                          last_name: item.last_name,
                          pan: item.pan,
                          first_name: item.first_name,
                          email: item.email,
                          mobile_number: `${item.mobile_number}`,
                          designation: item.designation,
                        },
                      },
                      context: {
                        headers: {
                          "X-hasura-proxy-tenant-id":
                            processData?.investorData.id || investorId,
                          "X-hasura-tenant-id":
                            processData?.investorData.id || investorId,
                          "X-hasura-proxy-tenant-type": "INVESTOR",
                        },
                      },
                    });
                  }
                });
              } else {
                handleAddUsers({
                  variables: {
                    args: responseObject,
                  },
                  context: {
                    headers: {
                      "X-hasura-proxy-tenant-id":
                        processData?.investorData.id || investorId,
                      "X-hasura-tenant-id":
                        processData?.investorData.id || investorId,
                      "X-hasura-proxy-tenant-type": "INVESTOR",
                    },
                  },
                });
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              touched,
              errors,
            }) => (
              <Form id="add-user-form" onSubmit={handleSubmit}>
                <FieldArray
                  name="users"
                  render={(arrayHelper) => {
                    const userArray = values.users;

                    arraylength = userArray.length;

                    arrayHelpersRef = arrayHelper;

                    return (
                      <AddUsers
                        userArray={userArray}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        removeUserForm={removeUserForm}
                        editData={editData}
                      />
                    );
                  }}
                />
                <Grid
                  xs={12}
                  width={"100%"}
                  item
                  display={"flex"}
                  justifyContent="center"
                  gap="10px"
                  my={2}
                ></Grid>
              </Form>
            )}
          </Formik>
        </SideModal>
      )}
      {/**=======================================================================================================================*/}
      {currentStep === 2 && (
        <AddInvestorBankAccount
          editData={editData}
          setCurrentStep={setCurrentStep}
          processData={processData}
          setProcessData={setProcessData}
          setOpen={setOpen}
          investorId={investorId}
          getBankRefetch={getBankRefetch}
          setEditData={setEditData}
          modalType={modalType}
          currentStep={currentStep}
          open={open}
        />
      )}
      {/**=======================================================================================================================*/}
      {currentStep === 3 && (
        <UploadInvestorAgreement
          setOpen={setOpen}
          getInvestorRefetch={getInvestorRefetch}
          processData={processData}
          setProcessData={setProcessData}
          setCurrentStep={setCurrentStep}
          getAllInvestorRefetch={getInvestorRefetch}
          modalType={modalType}
          currentStep={currentStep}
          open={open}
        />
      )}
    </>
  );
};

export default InvestorModal;
