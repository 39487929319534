import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableWrapper } from "../../theme/customComponents";
import {
  Checkbox,
  Grid,
  Pagination,
  Typography,
  Button,
  Modal,
  TablePagination,
  IconButton,
  CircularProgress,
} from "@mui/material";
import styled from "@emotion/styled";
import EmptyData from "../../components/EmptyData";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorModal from "./ErrorModal";
import LinearProgress from "@mui/material/LinearProgress";
import { ReactComponent as EditIcon } from "../../assets/Icon/Outline/pencil-icon.svg";
import { ReactComponent as SuccessIcon } from "../../assets/Icon/Outline/check-icon.svg";
import { ReactComponent as CancelIcon } from "../../assets/Icon/Outline/x-icon.svg";
import SimpleInput from "../../components/input/SimpleInput";
import { useEditOfferMutation } from "../../data-access/hooks/company/deals-trade.hooks";

interface paginationDataInterface {
  offset: number;
  limit: number;
  totalcount: number;
  currentPage: number;
}

interface offerTableInterface {
  data?: any[];
  loading?: boolean;
  selectedDeal?: number;
  onSelect?: any;
  paginationData?: paginationDataInterface | any;
  setPaginationData?: any;
  getOffersById?: any;
  setShowAddModal?: any;
  dealId?: Number;
  selectedOffers: any;
  setSelectedOffers: any;
  offerRefetch: any;
}

const CustomCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0;
  }
`;

const OffersTable = ({
  data,
  loading,
  paginationData,
  setPaginationData,
  selectedDeal,
  getOffersById,
  setShowAddModal,
  dealId,
  selectedOffers,
  setSelectedOffers,
  offerRefetch,
}: offerTableInterface) => {
  const pageCount = (number: number) => {
    return Math.ceil(number / 10);
  };

  const navigate = useNavigate();

  const [showErrorModal, setShowErrorModal] = useState({
    show: false,
    type: "",
  });

  const [isEditing, setIsEditing] = useState(false);

  const [isEditingRow, setIsEditingRow] = useState("");

  const [changedRemark, setChangedRemark] = useState("");

  /**---------------------------------------------------------------------------------------------------------------------------------- */
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data?.map((n) => {
        return {
          id: n.id,
          investor_id: n.investor_id,
          interest_rate: n.roi,
          name: n.investor.name,
          amount: n.amount,
          remarks: n.remarks,
        };
      });
      setSelectedOffers(newSelected || []);
      return;
    }
    setSelectedOffers([]);
  };

  /**---------------------------------------------------------------------------------------------------------------------------------- */
  const handleSingleSelect = (
    id: number,
    investor_id: number,
    interest_rate: number,
    name: string,
    amount: any,
    event: React.ChangeEvent<HTMLInputElement>,
    remarks: string
  ) => {
    if (event.target.checked && id) {
      setSelectedOffers([
        ...selectedOffers,
        {
          id: id,
          investor_id: investor_id,
          interest_rate: interest_rate,
          name: name,
          amount: amount,
          remarks: remarks,
        },
      ]);
    } else {
      const deleteId = selectedOffers.filter((item: any) => item.id !== id);
      setSelectedOffers(deleteId);
    }
  };
  /**---------------------------------------------------------------------------------------------------------------------------------- */
  const isSelected = (name: number) =>
    selectedOffers?.findIndex((item: any) => item.id === name) !== -1;

  /**INVESTOR DUPLICATE---------------------------------------------------------------------------------------------------------------------------------- */
  const valueArr = selectedOffers.map((item: any) => {
    return item.investor_id;
  });
  const duplicateInvestors =
    valueArr.length >= 1 &&
    valueArr.some((item: any, idx: any) => {
      return valueArr.indexOf(item) !== idx;
    });
  /**INTEREST DUPLICATE---------------------------------------------------------------------------------------------------------------------------------- */
  const interestRateArr = selectedOffers.map((item: any) => {
    return item.interest_rate;
  });

  const matchedInterestRate =
    interestRateArr.length >= 2 &&
    interestRateArr.some((item: any, idx: any) => {
      return interestRateArr.indexOf(item) !== idx;
    });

  /**---------------------------------------------------------------------------------------------------------------------------------- */
  const handleInitiatOffer = () => {
    if (duplicateInvestors) {
      setShowErrorModal({
        show: true,
        type: "investor",
      });
    } else if (!matchedInterestRate && selectedOffers?.length >= 3) {
      setShowErrorModal({
        show: true,
        type: "interest_rate",
      });
    } else {
      navigate(`/company/${selectedDeal}/trade-configuration`, {
        state: {
          company: selectedDeal,
          dealId: dealId,
          selectedOffers: selectedOffers.filter((item: any) => item.id !== 0),
          remarks: selectedOffers.length === 1 ? selectedOffers[0].remarks : "",
        },
      });
    }
  };
  console.log("selectedOffers: ", selectedOffers);

  /**---------------------------------------------------------------------------------------------------------------------------------- */
  const [handleUpdateOffer, { loading: editLoading }] = useEditOfferMutation({
    onCompleted: () => {
      setIsEditing(false);
      setIsEditingRow("");
      offerRefetch();
    },
  });
  /**---------------------------------------------------------------------------------------------------------------------------------- */
  return (
    <>
      <Paper>
        <Grid
          container
          display={"flex"}
          justifyContent="space-between"
          width="100%"
          alignItems={"center"}
          padding={4}
        >
          <Typography fontSize={"18px"} fontWeight={600}>
            Offer Table
          </Typography>
          <Button
            disabled={selectedOffers.length === 0}
            variant="primarySmall"
            onClick={handleInitiatOffer}
          >
            Initiate Trade
          </Button>
        </Grid>
        <TableWrapper>
          {loading && <LinearProgress />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <CustomCheckbox onChange={handleSelectAllClick} />
                </TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Interest rate</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Remark</TableCell>
                <TableCell>Investor ID</TableCell>
                <TableCell>Investors Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {data?.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6}>
                    <EmptyData>
                      <Button
                        onClick={() => setShowAddModal(true)}
                        variant="primarySmall"
                      >
                        Add Bid/Offer
                      </Button>
                    </EmptyData>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data?.map((item: any, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <CustomCheckbox
                          onChange={(event) =>
                            handleSingleSelect(
                              item.id,
                              item.investor_id,
                              item.roi,
                              item.investor.name,
                              item.amount,
                              event,
                              item.remarks
                            )
                          }
                          checked={
                            isSelected(item.id) && selectedOffers !== undefined
                          }
                        />
                      </TableCell>
                      <TableCell>{item.id || "-"}</TableCell>
                      <TableCell>{`${item.roi}%` || "-"}</TableCell>
                      <TableCell>{item.amount || "-"}</TableCell>
                      <TableCell>
                        {isEditing && item.id === isEditingRow ? (
                          <SimpleInput
                            defaultValue={item.remarks}
                            onChange={(event: any) => {
                              setChangedRemark(event.target.value);
                            }}
                          />
                        ) : (
                          item.remarks || "-"
                        )}
                      </TableCell>
                      <TableCell>{item.investor_id || "-"}</TableCell>
                      <TableCell id="section-1">
                        {item.investor.name || "-"}
                      </TableCell>
                      <TableCell align="right">
                        {isEditing && item.id === isEditingRow ? (
                          <Grid
                            container
                            display={"flex"}
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                            gap={"6px"}
                          >
                            <IconButton
                              onClick={() => {
                                handleUpdateOffer({
                                  variables: {
                                    where: {
                                      id: {
                                        _eq: Number(isEditingRow),
                                      },
                                    },
                                    set: {
                                      remarks: `${changedRemark}`,
                                    },
                                  },
                                });
                              }}
                              style={{ border: "solid 1px #1e88e51f" }}
                            >
                              {editLoading ? (
                                <CircularProgress size={20} />
                              ) : (
                                <SuccessIcon
                                  width={20}
                                  height={20}
                                  color={"#1E88E5"}
                                />
                              )}
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setIsEditing(false);
                                setIsEditingRow("");
                              }}
                              style={{ border: "solid 1px #1e88e51f" }}
                            >
                              <CancelIcon
                                width={20}
                                height={20}
                                color={"#1E88E5"}
                              />
                            </IconButton>
                          </Grid>
                        ) : (
                          <IconButton
                            onClick={() => {
                              setIsEditing(true);
                              setIsEditingRow(item.id);
                            }}
                            style={{ border: "solid 1px #1e88e51f" }}
                          >
                            <EditIcon
                              width={20}
                              height={20}
                              color={"#1E88E5"}
                            />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableWrapper>
        {paginationData?.totalcount > 10 && (
          <TablePagination
            colSpan={20}
            count={pageCount(paginationData?.totalcount ?? 0)}
            onPageChange={(event: any, value: any) => {
              const newOffset =
                paginationData?.limit * value - paginationData?.limit;
              setPaginationData({
                offset: newOffset,
                limit: paginationData?.limit,
                totalcount: paginationData?.totalcount,
                currentPage: value,
              });
            }}
            component="div"
            page={paginationData?.currentPage}
            rowsPerPage={paginationData.limit}
            rowsPerPageOptions={[10, 20, 50]}
            labelDisplayedRows={() => <div style={{ display: "none" }}></div>}
            labelRowsPerPage={"Rows:"}
            onRowsPerPageChange={(event) => {
              setPaginationData({
                ...paginationData,
                limit: Number(event.target.value),
                offsets: 0,
                currentPage: 1,
              });
            }}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            ActionsComponent={() => (
              <Pagination
                count={pageCount(paginationData?.totalcount ?? 0)}
                variant={"outlined"}
                page={paginationData?.currentPage}
                onChange={(event: any, value: any) => {
                  const newOffset =
                    paginationData?.limit * value - paginationData?.limit;
                  setPaginationData({
                    offset: newOffset,
                    limit: paginationData?.limit,
                    totalcount: paginationData?.totalcount,
                    currentPage: value,
                  });
                }}
              />
            )}
          />
        )}
      </Paper>

      <Modal
        open={showErrorModal.show}
        onClose={() => setShowErrorModal({ show: false, type: "" })}
      >
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <ErrorModal
            type={showErrorModal.type}
            onClose={() => setShowErrorModal({ show: false, type: "" })}
          />
        </Grid>
      </Modal>
    </>
  );
};

export default OffersTable;
