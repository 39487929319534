import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { ReactComponent as SearchIcon } from "../../../assets/Icon/Outline/search-icon.svg";
import SimpleInput from "../../../components/input/SimpleInput";
import SimpleSelect from "../../../components/input/SimpleSelect";
import * as Yup from "yup";
import {
  useAddInvestorBankAccountMutation,
  useGetBankDetailsByIfscLazyQuery,
  useUpdateInvestorBankAccountMutation,
} from "../../../data-access/hooks/company/investors.hooks";
import { CommonOpsColors } from "../../../utils/common";
import { Investor_Type } from "../../../data-access/types";
import SideModal from "../../../components/Modals/SideModal";

const AddInvestorBankAccount = ({
  setCurrentStep,
  processData,
  setProcessData,
  editData,
  investorId,
  setOpen,
  getBankRefetch,
  setEditData,
  modalType,
  open,
  currentStep,
}: any) => {
  const [apiErrors, setApiErrors] = React.useState("");

  const updateData = {
    ifsc: editData?.ifsc || processData?.bankData?.ifsc_code,
    id: editData?.id || processData?.bankData?.id,
    account_name: editData?.investor_name || processData?.bankData?.name,
    account_no: editData?.account_no || processData?.bankData?.account_no,
    account_type: editData?.account_type || processData?.bankData?.account_type,
    is_primary: editData?.is_primary || processData?.bankData?.is_primary,
    multi_signatory:
      editData?.multi_signatories || processData?.bankData?.multi_signatory,
  };

  const [bankValues, setBankValues] = React.useState({
    bank_id: "",
    bank_code: "",
    bank_micr: "",
    bank_name: "",
  });

  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  const [handleGetBankDetails, { loading: ifscLoading }] =
    useGetBankDetailsByIfscLazyQuery({
      onCompleted: (item) => {
        setBankValues({
          bank_id: `${item.searchIFSC?.bank?.id}`,
          bank_code: `${item.searchIFSC?.bank_code}`,
          bank_micr: `${item.searchIFSC?.micr}`,
          bank_name: `${item.searchIFSC?.bank?.name}`,
        });
      },
      onError: (error) => {
        setApiErrors(error.message);
      },
    });
  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  const validationSchema = Yup.object().shape({
    account_name: Yup.string().required("Please enter account holder's name"),
    account_no: Yup.string().required("Please enter account number"),
    account_type: Yup.string().required("Please select account type"),
    bank_ifsc: Yup.string().required("Please enter IFSC"),
  });

  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  const [handleAddInvestorBank, { loading: addAccountLoading }] =
    useAddInvestorBankAccountMutation({
      onCompleted: (item) => {
        if (investorId) {
          getBankRefetch();
          setOpen(false);
        } else {
          setCurrentStep(3);
          setProcessData({
            ...processData,
            bankData: {
              id: item.AddPhysicalAccount?.id,
              account_no: `${formik.values?.account_no}`,
              ifsc_code: formik.values?.bank_ifsc,
              account_type: formik.values?.account_type,
              is_primary: formik.values?.is_primary,
              multi_signatory: formik.values?.multi_signatory,
              name: formik.values?.account_name,
            },
          });
        }
      },
      onError: (error) => {
        setApiErrors(error.message);
      },
    });

  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  const [handleUpdateAccount, { loading: updateAccountLoading }] =
    useUpdateInvestorBankAccountMutation({
      onCompleted: (item) => {
        if (editData?.id) {
          getBankRefetch();
          setOpen(false);
          setEditData({});
        } else {
          setCurrentStep(3);
          setProcessData({
            ...processData,
            bankData: {
              id: item.update_account_by_pk?.id,
              account_no: `${formik.values?.account_no}`,
              ifsc_code: formik.values?.bank_ifsc,
              account_type: formik.values?.account_type,
              is_primary: formik.values?.is_primary,
              multi_signatory: formik.values?.multi_signatory,
              name: formik.values?.account_name,
            },
          });
        }
      },
      onError: (error) => {
        setApiErrors(error.message);
      },
    });
  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  const formik = useFormik({
    initialValues: {
      bank_ifsc: updateData?.id ? updateData.ifsc : "",
      account_name: updateData?.id ? updateData.account_name : "",
      account_no: updateData?.id ? updateData.account_no : "",
      account_type: updateData?.id ? updateData.account_type : "",
      is_primary: updateData?.id ? updateData.is_primary : false,
      multi_signatory: updateData?.id ? updateData.multi_signatory : false,
    },
    validationSchema:
      ![
        Investor_Type.Bank,
        Investor_Type.FamilyOffice,
        Investor_Type.Funds,
        Investor_Type.Nbfc,
      ].includes(processData?.investorData.type) && validationSchema,
    onSubmit: (fieldValues) => {
      setApiErrors("");
      if (
        fieldValues?.account_no === "" &&
        fieldValues?.bank_ifsc === "" &&
        fieldValues?.account_type === "" &&
        fieldValues?.account_name === "" &&
        [
          Investor_Type.Bank,
          Investor_Type.FamilyOffice,
          Investor_Type.Funds,
          Investor_Type.Nbfc,
        ].includes(processData?.investorData.type)
      ) {
        setCurrentStep(3);
      } else if (updateData?.id) {
        handleUpdateAccount({
          variables: {
            pk_columns: { id: updateData?.id },
            set: {
              bank_id: Number(bankValues?.bank_id),
              account_no: `${fieldValues?.account_no}`,
              ifsc_code: fieldValues?.bank_ifsc,
              account_type: fieldValues?.account_type,
              is_primary: fieldValues?.is_primary,
              multi_signatory: fieldValues?.multi_signatory,
              name: fieldValues?.account_name,
            },
          },
        });
      } else {
        handleAddInvestorBank({
          context: {
            headers: {
              "X-hasura-proxy-tenant-id":
                investorId || processData?.investorData?.id,
              "X-hasura-tenant-id": investorId || processData?.investorData?.id,
              "X-hasura-proxy-tenant-type": "INVESTOR",
            },
          },
          variables: {
            args: {
              bank_id: Number(bankValues?.bank_id),
              account_no: `${fieldValues?.account_no}`,
              ifsc_code: fieldValues?.bank_ifsc,
              account_type: fieldValues?.account_type,
              is_primary: fieldValues?.is_primary,
              multi_signatory: fieldValues?.multi_signatory,
              account_holder_name: fieldValues?.account_name,
            },
          },
        });
      }
    },
  });

  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  useEffect(() => {
    if (editData?.ifsc || processData?.bankData?.ifsc_code) {
      handleGetBankDetails({
        variables: {
          ifsc: formik.values.bank_ifsc,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData?.ifsc, processData?.bankData?.ifsc_code]);

  return (
    <SideModal
      open={open && currentStep === 2 ? true : false}
      onClose={() => setOpen(false)}
      modalTitle={updateData?.id ? "Update Bank Account" : "Add Bank Account"}
      footer={
        <Grid
          xs={12}
          width={"100%"}
          item
          display={"flex"}
          justifyContent="center"
          gap="10px"
        >
          {modalType !== "standalone" && !editData?.id && (
            <Button
              onClick={() => {
                setCurrentStep(1);
              }}
              fullWidth
              variant="secondarySmall"
            >
              {updateData?.id ? "Submit" : "Back"}
            </Button>
          )}
          <Button
            disabled={addAccountLoading || updateAccountLoading}
            type="submit"
            fullWidth
            variant="primarySmall"
            form="add-bank-form"
          >
            {addAccountLoading || updateAccountLoading ? (
              <CircularProgress size={20} />
            ) : (
              "Next"
            )}
          </Button>
        </Grid>
      }
    >
      <form onSubmit={formik.handleSubmit} id="add-bank-form">
        {Boolean(apiErrors) && (
          <Alert style={{ margin: "10px 0" }} severity="error">
            {apiErrors}
          </Alert>
        )}
        <Grid container xs={12} spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <SimpleInput
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.account_no}
                name="account_no"
                error={
                  formik.touched.account_no && Boolean(formik.errors.account_no)
                }
                helperText={
                  formik.touched.account_no && formik.errors.account_no
                }
                id="account_no"
                placeholder="Enter the Account Number"
                inputlabel="Account No."
                type={"number"}
                requiredlabel
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <SimpleInput
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.account_name}
                name="account_name"
                error={
                  formik.touched.account_name &&
                  Boolean(formik.errors.account_name)
                }
                helperText={
                  formik.touched.account_name && formik.errors.account_name
                }
                id="account_name"
                placeholder="Enter Account Name"
                inputlabel="Enter Account Name"
                requiredlabel
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <SimpleInput
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bank_ifsc}
                name="bank_ifsc"
                error={
                  formik.touched.bank_ifsc && Boolean(formik.errors.bank_ifsc)
                }
                helperText={formik.touched.bank_ifsc && formik.errors.bank_ifsc}
                id="bank_ifsc"
                placeholder="Ex: EXYPS76507"
                inputlabel="IFSC"
                requiredlabel
                InputProps={{
                  endAdornment: (
                    <IconButton
                      color="primary"
                      style={{
                        margin: "2px",
                        padding: "6px",
                      }}
                      onClick={() => {
                        handleGetBankDetails({
                          variables: {
                            ifsc: formik.values.bank_ifsc,
                          },
                        });
                      }}
                    >
                      {ifscLoading ? (
                        <CircularProgress size={18} />
                      ) : (
                        <SearchIcon
                          width={18}
                          height={18}
                          color={CommonOpsColors.primary}
                        />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <SimpleSelect
                name="account_type"
                error={
                  formik.touched.account_type &&
                  Boolean(formik.errors.account_type)
                }
                id="account_type"
                requiredlabel
                displayEmpty
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.account_type}
                helperText={
                  formik.touched.account_type && formik.errors.account_type
                }
                formlabel="Type"
                renderValue={(values: any) => {
                  if (formik.values.account_type === "") {
                    return <Typography>Select</Typography>;
                  } else {
                    return values;
                  }
                }}
              >
                <MenuItem value={"CURRENT"}>Current</MenuItem>
                <MenuItem value={"SAVINGS"}>Saving</MenuItem>
              </SimpleSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <SimpleInput
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={bankValues.bank_name}
                name="bank_name"
                id="bank_name"
                inputlabel="Bank"
                requiredlabel
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <SimpleInput
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="bank_micr"
                id="bank_micr"
                inputlabel="MICR Code"
                requiredlabel
                disabled
                value={bankValues.bank_micr}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <SimpleInput
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="bank_id"
                id="bank_id"
                inputlabel="Bank Id"
                requiredlabel
                disabled
                value={bankValues.bank_id}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={9}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FormControl
              fullWidth
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Checkbox
                name="is_primary"
                onChange={(event: any) => {
                  formik.setFieldValue("is_primary", event?.target?.checked);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.is_primary}
                checked={formik.values.is_primary}
              />
              <Typography color={"#666666DE"}>Primary Account</Typography>
            </FormControl>
            <FormControl
              fullWidth
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Checkbox
                name="multi_signatory"
                onChange={(event: any) => {
                  formik.setFieldValue(
                    "multi_signatory",
                    event?.target?.checked
                  );
                }}
                onBlur={formik.handleBlur}
                value={formik.values.multi_signatory}
                checked={formik.values.multi_signatory}
              />
              <Typography color={"#666666DE"}>Multi Signatories</Typography>
            </FormControl>
          </Grid>
          <Grid
            xs={12}
            width={"100%"}
            item
            display={"flex"}
            justifyContent="center"
            gap="10px"
            my={2}
          ></Grid>
        </Grid>
      </form>
    </SideModal>
  );
};

export default AddInvestorBankAccount;
