/* eslint-disable array-callback-return */
import styled from "styled-components/macro";

import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Pagination,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { Divider as MuiDivider } from "@mui/material";
import React from "react";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { TableWrapper } from "../../../theme/customComponents";
import CommonBreadCrumb from "../../../components/CommonBreadCrumb";
import TradeSummary from "./TradeSummary";
import { ReactComponent as ArrowRightIcon } from "../../../assets/Icon/Outline/arrow-right-icon.svg";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import useContractSelection from "../../../hooks/useContractSelection";
import { IdPrefixFormat } from "../../../data-access/formatter";
import CommonLoader from "../../../components/CommonLoader";

const Divider = styled(MuiDivider)(spacing);

const CustomCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0;
  }
`;

const TradeConfiguration: React.FC = () => {
  const {
    loading,
    AttachLoading,
    setContractType,
    handleAttachContracts,
    contractType,
    allSelectedContracts,
    handleAllContractsChange,
    listOfAllTrades,
    handleContractsChange,
    pageCount,
    paginationData,
    setPaginationData,
    tradeSummaryData,
    isSelected,
    trade_id,
    id,
    allSubscription,
    navigate,
  } = useContractSelection();

  return (
    <React.Fragment>
      <Helmet title="Contract Selection" />
      <Grid
        item
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {/*---------------------------*COMMON BREAD CRUMBS--------------------------- */}
        <Grid item>
          <CommonBreadCrumb
            typographyText="Contract Selection"
            linksText={[
              { link: "Company Queues", to: "/company-queues" },
              {
                link: `Trade-Id : ${IdPrefixFormat(Number(trade_id), "T")}`,
                to: `/company/${id}/trade/${trade_id}`,
                data: { trade_id: Number(trade_id) },
              },
            ]}
            showCompany
          />
        </Grid>
        <Grid item display={"flex"} gap="6px">
          <Button
            onClick={() => navigate(`/company/${id}/payment-partner`)}
            variant="secondarySmall"
          >
            Upload Contract
          </Button>

          <Button
            variant="primarySmall"
            onClick={() =>
              handleAttachContracts({
                variables: {
                  args: {
                    pool_subscription_ids: allSelectedContracts
                      ?.filter(
                        (item) =>
                          item?.eligibility_score ||
                          item?.subscription?.eligibility_score === 1
                      )
                      ?.map((item) => {
                        return item?.subscription_id;
                      }),
                    primary_subscription_ids: allSelectedContracts
                      ?.filter(
                        (item) =>
                          item?.eligibility_score ||
                          item?.subscription?.eligibility_score === 2
                      )
                      ?.map((item) => {
                        return item?.subscription_id;
                      }),
                    trade_id: Number(trade_id),
                  },
                },
              })
            }
            disabled={AttachLoading}
          >
            {AttachLoading ? (
              <CircularProgress size={20} />
            ) : (
              <>
                Save & Proceed&nbsp;{" "}
                <ArrowRightIcon width="16px" height="16px" color="#fff" />
              </>
            )}
          </Button>
        </Grid>
      </Grid>
      {/*--------------------------------------------------------------------------- */}
      <Divider mt="16px" mb="28px" />
      <Grid container xs={12} spacing={5}>
        {loading || allSubscription ? (
          <Grid item xs={8}>
            <CommonLoader />
          </Grid>
        ) : (
          <Grid item xs={8}>
            <TableWrapper>
              <Grid
                container
                width={"100%"}
                style={{ background: "white" }}
                p={3}
                display="flex"
                flexDirection={"row"}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography fontSize={"18px"} fontWeight={600}>
                    Contract Selection
                  </Typography>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={400}
                    color="#7D87A6"
                  >
                    To initiate the trade, please select the contracts.
                  </Typography>
                </Grid>
                <Grid item display={"flex"} alignItems={"center"}>
                  <FormControl>
                    <RadioGroup
                      row
                      onChange={(event) => setContractType(event.target.value)}
                      value={contractType}
                    >
                      <FormControlLabel
                        value="Primary"
                        control={<Radio />}
                        label="Primary"
                      />
                      <FormControlLabel
                        value="Pool"
                        control={<Radio />}
                        label="Pool"
                      />
                    </RadioGroup>
                  </FormControl>
                  <CSVLink
                    style={{
                      fontFamily: "Manrope",
                      textDecoration: "none",
                      color: "#1E88E5",
                    }}
                    separator={","}
                    filename={`contracts-${new Date()}.csv`}
                    enclosingCharacter={""}
                    data={allSelectedContracts?.map((item, index) => {
                      return {
                        customer_identifier: index + 1,
                        customer_email: item?.customer_email,
                        customer_name: item?.customer_name,
                        term_start: item?.term_start,
                        term_end: item?.term_end,
                        status: item?.status,
                        billing_period: item?.billing_period,
                        billing_interval: item?.billing_interval,
                        amount_per_period: item?.amount_per_period,
                        currency: item?.currency,
                        customer_since_dt: item?.customer_created,
                        tax_inclusive: item?.tax_inclusive,
                        tax_rate: item?.tax_rate,
                        eligibility: item?.eligibility_score,
                      };
                    })}
                  >
                    Download Contracts
                  </CSVLink>
                </Grid>
              </Grid>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <CustomCheckbox onChange={handleAllContractsChange} />
                    </TableCell>
                    <TableCell>Customer Id</TableCell>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Term End</TableCell>
                    <TableCell>Billing Period</TableCell>
                    <TableCell>Billing Interval</TableCell>
                    <TableCell>Amount Per Period</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listOfAllTrades?.map((dataItem) => (
                    <TableRow>
                      <TableCell>
                        <CustomCheckbox
                          name={dataItem.id}
                          onChange={(event) => {
                            handleContractsChange(event, dataItem);
                          }}
                          checked={isSelected(dataItem.id)}
                        />
                      </TableCell>
                      <TableCell>{dataItem?.id}</TableCell>
                      <TableCell>{dataItem?.customer_name}</TableCell>
                      <TableCell>
                        {format(Date.parse(dataItem?.term_end), "dd/MM/yyyy")}
                      </TableCell>
                      <TableCell>{dataItem?.billing_period}</TableCell>
                      <TableCell>{dataItem?.billing_period_interval}</TableCell>
                      <TableCell>{dataItem?.amount_per_period}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
            <Pagination
              count={pageCount(paginationData?.totalcount)}
              variant={"outlined"}
              page={paginationData?.currentPage}
              onChange={(event: any, value: any) => {
                const newOffset = 10 * value - 10;
                setPaginationData({
                  offset: newOffset,
                  limit: paginationData?.limit,
                  totalcount: paginationData?.totalcount,
                  currentPage: value,
                });
              }}
            />
          </Grid>
        )}
        <Grid item xs={4}>
          <TradeSummary
            data={tradeSummaryData}
            selectedContracts={allSelectedContracts}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default TradeConfiguration;
