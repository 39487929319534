import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DownloadFilesZipMutationVariables = Types.Exact<{
  args: Types.DownloadFilesZipInput;
}>;


export type DownloadFilesZipMutation = { __typename?: 'mutation_root', DownloadFilesZip?: { __typename?: 'DownloadFilesZipOutput', downloadUrl: string } | null };

export type DownloadTdsZipMutationVariables = Types.Exact<{
  args: Types.DownloadZipInput;
}>;


export type DownloadTdsZipMutation = { __typename?: 'mutation_root', DownloadZip?: { __typename?: 'DownloadZipOutput', downloadUrl: string } | null };


export const DownloadFilesZipDocument = gql`
    mutation DownloadFilesZip($args: DownloadFilesZipInput!) {
  DownloadFilesZip(args: $args) {
    downloadUrl
  }
}
    `;
export type DownloadFilesZipMutationFn = Apollo.MutationFunction<DownloadFilesZipMutation, DownloadFilesZipMutationVariables>;

/**
 * __useDownloadFilesZipMutation__
 *
 * To run a mutation, you first call `useDownloadFilesZipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadFilesZipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadFilesZipMutation, { data, loading, error }] = useDownloadFilesZipMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDownloadFilesZipMutation(baseOptions?: Apollo.MutationHookOptions<DownloadFilesZipMutation, DownloadFilesZipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadFilesZipMutation, DownloadFilesZipMutationVariables>(DownloadFilesZipDocument, options);
      }
export type DownloadFilesZipMutationHookResult = ReturnType<typeof useDownloadFilesZipMutation>;
export type DownloadFilesZipMutationResult = Apollo.MutationResult<DownloadFilesZipMutation>;
export type DownloadFilesZipMutationOptions = Apollo.BaseMutationOptions<DownloadFilesZipMutation, DownloadFilesZipMutationVariables>;
export const DownloadTdsZipDocument = gql`
    mutation DownloadTDSZip($args: DownloadZipInput!) {
  DownloadZip(args: $args) {
    downloadUrl
  }
}
    `;
export type DownloadTdsZipMutationFn = Apollo.MutationFunction<DownloadTdsZipMutation, DownloadTdsZipMutationVariables>;

/**
 * __useDownloadTdsZipMutation__
 *
 * To run a mutation, you first call `useDownloadTdsZipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadTdsZipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadTdsZipMutation, { data, loading, error }] = useDownloadTdsZipMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDownloadTdsZipMutation(baseOptions?: Apollo.MutationHookOptions<DownloadTdsZipMutation, DownloadTdsZipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadTdsZipMutation, DownloadTdsZipMutationVariables>(DownloadTdsZipDocument, options);
      }
export type DownloadTdsZipMutationHookResult = ReturnType<typeof useDownloadTdsZipMutation>;
export type DownloadTdsZipMutationResult = Apollo.MutationResult<DownloadTdsZipMutation>;
export type DownloadTdsZipMutationOptions = Apollo.BaseMutationOptions<DownloadTdsZipMutation, DownloadTdsZipMutationVariables>;
export const namedOperations = {
  Mutation: {
    DownloadFilesZip: 'DownloadFilesZip',
    DownloadTDSZip: 'DownloadTDSZip'
  }
}