import styled from "styled-components/macro";
import {
  FormLabel as MuiFormLabel,
  Grid,
  TextField as MuiTextField,
} from "@mui/material";

const FormLabel = styled(MuiFormLabel)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-bottom: 4px;
`;
const TextField = styled(MuiTextField)`
  & input {
    height: 40px;
    padding: 0 12px;
  }
  & .MuiFormHelperText-root {
    margin-left: 0;
  }
  & .Mui-disabled {
    background: #e9e9e9;
    color: black;
    -webkit-text-fill-color: black;
  }
  & .MuiInputBase-root {
    padding: 0 12px 0 0;
  }
`;

const SimpleInput = (props: any) => {
  return (
    <Grid container xs={12}>
      <FormLabel>
        {props.inputlabel}
        {props.requiredlabel && <span style={{ color: "#E90000" }}>*</span>}
      </FormLabel>
      <TextField {...props} fullWidth />
    </Grid>
  );
};

export default SimpleInput;
