import { useFormik } from "formik";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
import {
  useDownloadCamReportMutation,
  useFetchHistoricalAccountingDataMutation,
} from "../data-access/hooks/company/cam-report.hooks";
import {
  useCompanyDetailByIdQuery,
  useUpdateCompanyAuthorizeSignatoriesMutation,
} from "../data-access/hooks/company/company-details.hooks";

const useCompanyDetails = () => {
  const [companyDetailsData, setCompanyDetailsData] = useState<any>();

  const { setShowAlert } = useContext(CompanyContext);

  const [openPanModal, setOpenPanModal] = useState(false);

  const [companyDetailsMCAProfileData, setCompanyDetailsMCAProfileData] =
    useState();

  const { id }: any = useParams();

  /*************************************************************************************************************/
  const { loading: getDataLoading, refetch: getCompanyRefetch } =
    useCompanyDetailByIdQuery({
      variables: {
        id: id,
      },
      onCompleted: (item) => {
        setCompanyDetailsData(item.company_by_pk);
        setCompanyDetailsMCAProfileData(item.company_by_pk?.mca_profile);
      },
    });

  /*************************************************************************************************************/
  const [updateSignatoryDetails, { loading: updateSignatoryDetailsLoading }] =
    useUpdateCompanyAuthorizeSignatoriesMutation({
      onCompleted: (data: any) => {
        setCompanyDetailsData(data.update_company.returning[0]);
      },
    });

  /*************************************************************************************************************/
  const formik = useFormik({
    initialValues: {
      full_name: companyDetailsData?.authorized_signatories?.full_name,
      email: companyDetailsData?.authorized_signatories?.email,
      mobile_no: companyDetailsData?.authorized_signatories?.mobile_no,
      designation: companyDetailsData?.authorized_signatories?.designation,
    },
    onSubmit: (item: any) => {
      updateSignatoryDetails({
        variables: {
          authSignatories: {
            full_name: item.full_name
              ? item.full_name
              : companyDetailsData?.authorized_signatories?.full_name,
            email: item.email
              ? item.email
              : companyDetailsData?.authorized_signatories?.email,
            mobile_no: item.mobile_no
              ? item.mobile_no
              : companyDetailsData?.authorized_signatories?.mobile_no,
            designation: item.designation
              ? item.designation
              : companyDetailsData?.authorized_signatories?.designation,
          },
          //@ts-ignore
          id: id,
        },
      });
    },
  });

  /*************************************************************************************************************/
  const mcaProfile = {
    cin:
      companyDetailsMCAProfileData &&
      (companyDetailsMCAProfileData["cin"] ||
        companyDetailsMCAProfileData["LLPIN"]),
    pan: companyDetailsMCAProfileData && companyDetailsMCAProfileData["pan"],
    email_id:
      companyDetailsMCAProfileData && companyDetailsMCAProfileData["Email_Id"],
    roc_code:
      companyDetailsMCAProfileData && companyDetailsMCAProfileData["ROC_Code"],
    company_name:
      companyDetailsMCAProfileData &&
      (companyDetailsMCAProfileData["Company_Name"] ||
        companyDetailsMCAProfileData["LLP_Name"]),
    company_status:
      companyDetailsMCAProfileData &&
      (companyDetailsMCAProfileData["Company_Status"] ||
        companyDetailsMCAProfileData["LLP_Status"]),
    class_of_company:
      companyDetailsMCAProfileData &&
      companyDetailsMCAProfileData["Class_of_Company"],
    company_category:
      companyDetailsMCAProfileData &&
      (companyDetailsMCAProfileData["Company_Category"] ||
        companyDetailsMCAProfileData["Description_of_main_division"]),
    date_of_last_AGM:
      companyDetailsMCAProfileData &&
      companyDetailsMCAProfileData["Date_of_last_AGM"],
    number_of_members:
      companyDetailsMCAProfileData &&
      companyDetailsMCAProfileData["Number_of_Members"],
    number_of_partners:
      companyDetailsMCAProfileData &&
      companyDetailsMCAProfileData["Number_of_Partners"],
    number_of_designated_partners:
      companyDetailsMCAProfileData &&
      companyDetailsMCAProfileData["Number_of_Designated_Partners"],
    registered_address:
      companyDetailsMCAProfileData &&
      companyDetailsMCAProfileData["Registered_Address"],
    company_subcategory:
      companyDetailsMCAProfileData &&
      companyDetailsMCAProfileData["Company_SubCategory"],
    paid_up_capital:
      companyDetailsMCAProfileData &&
      (companyDetailsMCAProfileData["Paid_up_Capital(Rs)"] ||
        companyDetailsMCAProfileData["Total_Obligation_of_Contribution"]),
    registration_number:
      companyDetailsMCAProfileData &&
      companyDetailsMCAProfileData["Registration_Number"],
    alternative_address:
      companyDetailsMCAProfileData &&
      companyDetailsMCAProfileData["alternative_address"],
    date_of_balance_sheet:
      companyDetailsMCAProfileData &&
      (companyDetailsMCAProfileData["Date_of_Balance_Sheet"] ||
        companyDetailsMCAProfileData["Annual_Return_filed"]),
    date_of_incorporation:
      companyDetailsMCAProfileData &&
      companyDetailsMCAProfileData["Date_of_Incorporation"],
    whether_listed_or_not:
      companyDetailsMCAProfileData &&
      companyDetailsMCAProfileData["Whether_Listed_or_not"],
    authorised_capital:
      companyDetailsMCAProfileData &&
      companyDetailsMCAProfileData["Authorised_Capital(Rs)"],
    suspended_at_stock_exchange:
      companyDetailsMCAProfileData &&
      companyDetailsMCAProfileData["Suspended_at_stock_exchange"],
    solvency_filed:
      companyDetailsMCAProfileData &&
      companyDetailsMCAProfileData["Solvency_filed"],
  };

  /*************************************************************************************************************/
  const loading = updateSignatoryDetailsLoading || getDataLoading;

  /*************************************************************************************************************/
  const [handleDownloadCamReport, { loading: downloadLoading }] =
    useDownloadCamReportMutation({
      onCompleted: () =>
        setShowAlert({
          type: "success",
          message: "CAM report is successfully sent to you email.",
          show: true,
        }),
      onError: () =>
        setShowAlert({
          type: "error",
          message: "Something went wrong please try again.",
          show: true,
        }),
    });

  /*************************************************************************************************************/
  const [getFinancialsData, { loading: financialsLoading }] =
    useFetchHistoricalAccountingDataMutation({
      onCompleted: () => {
        setShowAlert({
          type: "success",
          message: "Financial data is generated successfully.",
          show: true,
        });
      },
      onError: (item) => {
        setShowAlert({
          type: "error",
          message: item.message ?? "",
          show: true,
        });
      },
    });

  /*************************************************************************************************************/

  return {
    id,
    formik,
    mcaProfile,
    downloadLoading,
    loading,
    companyDetailsData,
    financialsLoading,
    getFinancialsData,
    handleDownloadCamReport,
    openPanModal,
    setOpenPanModal,
    getCompanyRefetch,
    companyDetailsMCAProfileData,
  };
};

export default useCompanyDetails;
