import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DashboardQueryVariables = Types.Exact<{
  startDate?: Types.InputMaybe<Types.Scalars['timestamptz']>;
  endDate?: Types.InputMaybe<Types.Scalars['timestamptz']>;
}>;


export type DashboardQuery = { __typename?: 'query_root', company_aggregate: { __typename?: 'company_aggregate', aggregate?: { __typename?: 'company_aggregate_fields', count: number } | null }, investor_aggregate: { __typename?: 'investor_aggregate', aggregate?: { __typename?: 'investor_aggregate_fields', count: number } | null }, trade_aggregate: { __typename?: 'trade_aggregate', aggregate?: { __typename?: 'trade_aggregate_fields', count: number, sum?: { __typename?: 'trade_sum_fields', payout_value?: any | null, fees_without_gst?: any | null } | null } | null } };


export const DashboardDocument = gql`
    query dashboard($startDate: timestamptz, $endDate: timestamptz) {
  company_aggregate(where: {created_at: {_gte: $startDate, _lte: $endDate}}) {
    aggregate {
      count
    }
  }
  investor_aggregate(where: {created_at: {_gte: $startDate, _lte: $endDate}}) {
    aggregate {
      count
    }
  }
  trade_aggregate(where: {created_at: {_gte: $startDate, _lte: $endDate}}) {
    aggregate {
      count
      sum {
        payout_value
        fees_without_gst
      }
    }
  }
}
    `;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useDashboardQuery(baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const namedOperations = {
  Query: {
    dashboard: 'dashboard'
  }
}