import {
  Button,
  Divider,
  Grid,
  Pagination,
  IconButton,
  Paper,
  Typography,
  TablePagination,
  TableCell as MuiTableCell,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import { TableWrapper } from "../../theme/customComponents";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as RigntArrowIcon } from "../../assets/Icon/Outline/arrow-right-icon.svg";
import useInvestor from "../../hooks/useInvestor";
import styled from "styled-components";
import InvestorModal from "./InvestorsModals/InvestorModal";
import { format } from "date-fns";
import CommonLoader from "../../components/CommonLoader";
import TableStatus from "../../components/TableStatus";

const TableCell = styled(MuiTableCell)`
  padding: 2px 14px;
  font-size: 14px !important;
`;

const GlobalInvestos = () => {
  const {
    allInvestors,
    setPaginationData,
    pageCount,
    paginationData,
    openInvestorModal,
    setOpenInvestorModal,
    currentStep,
    setCurrentStep,
    processData,
    setProcessData,
    navigate,
    getInvestorsLoading,
    getAllInvestorRefetch,
  } = useInvestor();

  return (
    <>
      <Grid item>
        <Typography fontSize={"24px"} fontWeight={600}>
          Investor Overview
        </Typography>
      </Grid>
      <Grid item width={"100%"} mt={"16px"} mb="28px">
        <Divider />
      </Grid>
      <Paper style={{ width: "100%" }}>
        <Grid
          width={"100%"}
          item
          display={"flex"}
          justifyContent="space-between"
          p={5}
          alignItems="center"
        >
          <Typography fontSize={"18px"} fontWeight={600}>
            Investor Listing
          </Typography>
          <Button
            onClick={() => {
              setCurrentStep(0);
              setOpenInvestorModal(true);
            }}
            variant="secondarySmall"
          >
            Add Investor
          </Button>
        </Grid>
        <Grid item>
          {getInvestorsLoading ? (
            <CommonLoader />
          ) : (
            <TableWrapper
              style={{
                maxHeight: "calc(100vh - 322px)",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Investor Name</TableCell>
                    <TableCell>Investor Type</TableCell>
                    <TableCell>Status </TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Investor PAN</TableCell>
                    <TableCell>Investor CIN</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allInvestors?.map((dataItems, index) => (
                    <TableRow key={index}>
                      <TableCell>{dataItems.id}</TableCell>
                      <TableCell>{dataItems.name}</TableCell>
                      <TableCell>{dataItems.type}</TableCell>
                      <TableCell>
                        <TableStatus
                          style={{
                            padding: "3px 6px",
                            fontSize: 12,
                            fontWeight: 600,
                            borderRadius: 4,
                          }}
                          stautsText={`${dataItems?.status}`}
                        />
                      </TableCell>
                      <TableCell>
                        {format(
                          new Date(`${dataItems?.created_at}`),
                          "dd LLL yyyy"
                        ) || "-"}
                      </TableCell>
                      <TableCell>{dataItems.pan || "-"}</TableCell>
                      <TableCell>{dataItems.cin || "-"}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => {
                            navigate(`/investor/${dataItems?.id}/details`);
                          }}
                          color="primary"
                        >
                          <RigntArrowIcon
                            color="#1E88E5"
                            width={16}
                            height={16}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
          )}
          {paginationData?.totalcount > 10 && (
            <TablePagination
              colSpan={20}
              count={pageCount(paginationData?.totalcount ?? 0)}
              onPageChange={(event: any, value: any) => {
                const newOffset =
                  paginationData?.limit * value - paginationData?.limit;
                setPaginationData({
                  offset: newOffset,
                  limit: paginationData?.limit,
                  totalcount: paginationData?.totalcount,
                  currentPage: value,
                });
              }}
              component="div"
              page={paginationData?.currentPage}
              rowsPerPage={paginationData.limit}
              rowsPerPageOptions={[10, 20, 50]}
              labelDisplayedRows={() => <div style={{ display: "none" }}></div>}
              labelRowsPerPage={"Rows:"}
              onRowsPerPageChange={(event) => {
                setPaginationData({
                  ...paginationData,
                  limit: Number(event.target.value),
                  offset: 0,
                  currentPage: 1,
                });
              }}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              ActionsComponent={() => (
                <Pagination
                  count={pageCount(paginationData?.totalcount ?? 0)}
                  variant={"outlined"}
                  page={paginationData?.currentPage}
                  onChange={(event: any, value: any) => {
                    const newOffset =
                      paginationData?.limit * value - paginationData?.limit;
                    setPaginationData({
                      offset: newOffset,
                      limit: paginationData?.limit,
                      totalcount: paginationData?.totalcount,
                      currentPage: value,
                    });
                  }}
                />
              )}
            />
          )}
        </Grid>
      </Paper>
      {openInvestorModal && (
        <InvestorModal
          setOpen={setOpenInvestorModal}
          open={openInvestorModal}
          currentStep={Number(currentStep)}
          setCurrentStep={setCurrentStep}
          processData={processData}
          setProcessData={setProcessData}
          getInvestorRefetch={getAllInvestorRefetch}
        />
      )}
    </>
  );
};

export default GlobalInvestos;
