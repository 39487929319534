import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import { format } from "date-fns/esm";
import React from "react";
import { useParams } from "react-router-dom";
import CommonBreadCrumb from "../../../components/CommonBreadCrumb";
import {
  useGetAllCountryQuery,
  useGetAllStateQuery,
  useGetInvestorBankAccountsQuery,
  useGetInvestorByIdQuery,
} from "../../../data-access/hooks/company/investors.hooks";
import { TableWrapper } from "../../../theme/customComponents";
import APASignatoryModal from "../InvestorsModals/APASignatoryModal";
import AccountTable from "./AccountTable";
import { ReactComponent as PlusIcon } from "../../../assets/Icon/Outline/plus-icon.svg";
import { ReactComponent as DownArrowIcon } from "../../../assets/Icon/Outline/chevron-down-icon.svg";
import { ReactComponent as PencilIcon } from "../../../assets/Icon/Outline/pencil-icon.svg";
import UserTable from "./UserTable";
import InvestorModal from "../InvestorsModals/InvestorModal";
import styled from "styled-components";
import { useDownloadAgreementMutation } from "../../../data-access/hooks/company/nda-logs.hooks";
import InvestmentsTable from "./InvestmentsTable";

interface InvestorDetailsInterface {
  name: string;
  address_line1: string;
  address_line2: string;
  cin: string;
  id: number;
  type: string;
  pan: string;
  total_committed_budget: any;
  country_id: number;
  agreement_file_id?: number;
  state_id: number;
  created_at: string;
  zipcode: number;
}
interface InvestorUsersInterface {
  id: number;
  user_id: number;
  pan: string;
  investor_id: number;
  last_name: string;
  first_name: string;
  email: string;
  mobile_number: string;
  designation: string;
}
interface InvestorBankInterface {
  account_no: number;
  account_type: string;
  ifsc: any;
  bank_id: number;
  bank_name: string;
  investor_name: string;
  investor_id: number;
  is_primary: boolean;
  id: number;
  multi_signatories: boolean;
}

const CustomeTableRow = styled(TableRow)`
  & :hover {
    background: white;
  }
`;

const InvestorDetails = () => {
  const { investor_id } = useParams();

  const [investorDetails, setInvestorDetails] =
    React.useState<InvestorDetailsInterface>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [editData, setEditData] = React.useState<any>();

  const [selectedInvestorId, setSelectedInvestorId] = React.useState<number>();

  const [addBankModal, setAddBankModal] = React.useState(false);

  const [addInvestorModal, setAddInvestorModal] = React.useState(false);

  const [uploadAgreementModal, setUploadAgreementModal] = React.useState(false);

  const open = Boolean(anchorEl);

  const [investorUsers, setInvestorUsers] = React.useState<
    InvestorUsersInterface[] | undefined | null
  >([]);

  const [investorBank, setInvestorBank] = React.useState<
    InvestorBankInterface[] | undefined | null
  >([]);

  const [apaSignatory, setAPASignatory] = React.useState(false);

  const { loading, refetch: getInvestorRefetch } = useGetInvestorByIdQuery({
    variables: {
      id: Number(investor_id),
    },
    onCompleted: (item) => {
      setInvestorDetails({
        name: `${item?.investor_by_pk?.name}`,
        address_line1: `${item?.investor_by_pk?.address_line1}`,
        address_line2: `${item?.investor_by_pk?.address_line2}`,
        cin: `${item?.investor_by_pk?.cin}`,
        id: Number(item?.investor_by_pk?.id),
        type: `${item?.investor_by_pk?.type}`,
        pan: `${item?.investor_by_pk?.pan}`,
        total_committed_budget: item?.investor_by_pk?.total_committed_budget,
        country_id: Number(item?.investor_by_pk?.country_id),
        state_id: Number(item?.investor_by_pk?.state_id),
        zipcode: Number(item?.investor_by_pk?.zipcode),
        created_at: `${item?.investor_by_pk?.created_at}`,
        agreement_file_id: item?.agreement_registry[0]?.id,
      });

      const userData = item.investor_by_pk?.investor_users.map((item) => {
        return {
          id: Number(item?.id),
          pan: `${item?.pan}`,
          investor_id: Number(item?.investor_id),
          last_name: `${item?.user?.last_name}`,
          first_name: `${item?.user?.first_name}`,
          email: `${item?.user?.email}`,
          mobile_number: `${item?.user?.mobile_number}`,
          designation: `${item?.user?.designation}`,
          user_id: item?.user?.id,
        };
      });
      setInvestorUsers(userData);
    },
    fetchPolicy: "no-cache",
  });

  const { loading: accountLoading, refetch: getBankRefetch } =
    useGetInvestorBankAccountsQuery({
      variables: {
        where: {
          investor: {
            id: {
              _eq: Number(investor_id),
            },
          },
        },
      },
      onCompleted: (item) => {
        const bankData = item.account.map((dataItem) => {
          return {
            account_no: Number(dataItem.account_no),
            account_type: `${dataItem.account_type}`,
            ifsc: dataItem.ifsc_code,
            bank_id: Number(dataItem.ref_bank.id),
            bank_name: `${dataItem.ref_bank.name}`,
            investor_name: `${dataItem.investor?.name}`,
            investor_id: Number(dataItem.investor?.id),
            is_primary: Boolean(dataItem.is_primary),
            multi_signatories: Boolean(dataItem.multi_signatory),
            id: Number(dataItem.id),
          };
        });
        setInvestorBank(bankData);
      },
      fetchPolicy: "no-cache",
    });

  const [handleDownloadAgreement] = useDownloadAgreementMutation({
    onCompleted: (data) => {
      const downloadUrl = data?.DownloadAgreement?.download_url;
      const link = document.createElement("a");
      link.href = `${downloadUrl}`;

      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      if (link && link.parentNode) {
        link.parentNode.removeChild(link);
      }
    },
  });

  const { data: countryData } = useGetAllCountryQuery();

  const { data: stateData } = useGetAllStateQuery();

  return (
    <>
      {loading ? (
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          height={"90vh"}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container xs={12}>
          <Grid item>
            <CommonBreadCrumb
              typographyText="Investor Details"
              linksText={[{ link: "Investors", to: "/investors" }]}
            />{" "}
          </Grid>
          <Grid item xs={12} mt="16px" mb="28px">
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Grid
                item
                p={4}
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Typography
                  fontSize={18}
                  fontWeight={600}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {investorDetails?.name}
                  <span
                    style={{
                      fontWeight: "500",
                      color: "#7D87A6",
                      fontSize: "14px",
                    }}
                  >
                    {`Investor Id : ${investorDetails?.id}`}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {`Created At : ${
                      investorDetails?.created_at
                        ? format(
                            new Date(`${investorDetails?.created_at}`),
                            "dd LLL, yyyy"
                          )
                        : "-"
                    }`}
                  </span>
                </Typography>
                <span
                  style={{
                    display: "flex",
                    gap: "18px",
                  }}
                >
                  <Button
                    onClick={() => setAPASignatory(true)}
                    variant="primarySmall"
                  >
                    APA Signatory Details
                  </Button>
                  <Button
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    variant="dropdownButton"
                  >
                    Investor Agreement&nbsp;
                    <DownArrowIcon width={18} height={18} color="#1E88E5" />
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                  >
                    <MenuItem
                      onClick={() => {
                        setUploadAgreementModal(true);
                        setSelectedInvestorId(investorDetails?.id);
                        setAnchorEl(null);
                      }}
                    >
                      Upload Agreement{" "}
                    </MenuItem>
                    <MenuItem
                      disabled={!investorDetails?.agreement_file_id}
                      onClick={() => {
                        setAnchorEl(null);
                        handleDownloadAgreement({
                          variables: {
                            args: {
                              agreement_registry_id: Number(
                                investorDetails?.agreement_file_id
                              ),
                            },
                          },
                        });
                      }}
                    >
                      Download Agreement
                    </MenuItem>
                  </Menu>
                </span>
              </Grid>
              <TableWrapper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Investor Pan</TableCell>
                      <TableCell>Investor Name</TableCell>
                      <TableCell>Investor Cin</TableCell>
                      <TableCell>Total committed Amount</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Address Line 1</TableCell>
                      <TableCell>Address Line 2</TableCell>
                      <TableCell>Country</TableCell>
                      <TableCell>State</TableCell>
                      <TableCell>ZipCode</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{investorDetails?.pan || "-"} </TableCell>
                      <TableCell>{investorDetails?.name || "-"} </TableCell>
                      <TableCell>{investorDetails?.cin || "-"} </TableCell>
                      <TableCell>
                        {investorDetails?.total_committed_budget || "-"}{" "}
                      </TableCell>
                      <TableCell>{investorDetails?.type || "-"}</TableCell>
                      <TableCell>
                        {investorDetails?.address_line1 || "-"}{" "}
                      </TableCell>
                      <TableCell>
                        {investorDetails?.address_line2 || "-"}{" "}
                      </TableCell>
                      <TableCell>
                        {countryData
                          ? countryData?.ref_country.find(
                              (item) => investorDetails?.country_id === item.id
                            )?.name
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {stateData
                          ? stateData?.ref_states.find(
                              (item) => investorDetails?.state_id === item.id
                            )?.name
                          : "-"}
                      </TableCell>
                      <TableCell>{investorDetails?.zipcode || "-"}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            setEditData(investorDetails);
                            setAddInvestorModal(true);
                          }}
                          color="primary"
                        >
                          <PencilIcon width={16} height={16} color="#1E88E5" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <CustomeTableRow>
                      <TableCell colSpan={11} style={{ padding: "0" }}>
                        <UserTable
                          investorUsers={investorUsers}
                          getInvestorRefetch={getInvestorRefetch}
                        />
                      </TableCell>
                    </CustomeTableRow>
                  </TableBody>
                </Table>
              </TableWrapper>
            </Paper>
          </Grid>
          <Grid
            item
            container
            my={6}
            xs={12}
            style={{
              padding: "16px 0 0 0",
              background: "white",
            }}
          >
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
              p={"0 16px 16px 16px"}
            >
              <Typography fontSize={18} fontWeight={600}>
                {`${investorDetails?.name}'s Bank Accounts`}
              </Typography>
              <Button
                onClick={() => {
                  setSelectedInvestorId(investorDetails?.id);
                  setAddBankModal(true);
                }}
                variant="primarySmall"
              >
                {" "}
                <PlusIcon width={16} height={16} />
                Add
              </Button>
            </Grid>
            <Grid item xs={12}>
              {investorBank?.length !== 0 && (
                <AccountTable
                  investorBank={investorBank}
                  loading={accountLoading}
                  setAddBankModal={setAddBankModal}
                  setEditData={setEditData}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            item
            container
            my={6}
            xs={12}
            style={{
              padding: "16px 0",
              background: "white",
            }}
          >
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
              p={"0 16px 16px 16px"}
            >
              <Typography fontSize={18} fontWeight={600}>
                Investments
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <InvestmentsTable />
            </Grid>
          </Grid>
        </Grid>
      )}

      {apaSignatory && (
        <APASignatoryModal
          setOpen={setAPASignatory}
          investor_id={Number(investor_id)}
          open={apaSignatory}
        />
      )}

      {addBankModal && (
        <InvestorModal
          setOpen={setAddBankModal}
          open={addBankModal}
          currentStep={2}
          modalType="standalone"
          investorId={Number(selectedInvestorId)}
          editData={editData}
          setEditData={setEditData}
          getInvestorRefetch={getInvestorRefetch}
          getBankRefetch={getBankRefetch}
        />
      )}

      {uploadAgreementModal && (
        <InvestorModal
          setOpen={setUploadAgreementModal}
          currentStep={3}
          modalType="standalone"
          open={uploadAgreementModal}
          investorId={Number(selectedInvestorId)}
          setEditData={setEditData}
          editData={editData}
          getInvestorRefetch={getInvestorRefetch}
          getBankRefetch={getBankRefetch}
        />
      )}

      {addInvestorModal && (
        <InvestorModal
          setOpen={setAddInvestorModal}
          currentStep={0}
          open={addInvestorModal}
          modalType="standalone"
          investorId={Number(selectedInvestorId)}
          setEditData={setEditData}
          editData={editData}
          getInvestorRefetch={getInvestorRefetch}
          getBankRefetch={getBankRefetch}
        />
      )}
    </>
  );
};

export default InvestorDetails;
