import {
  Button,
  ClickAwayListener,
  Typography,
  Tooltip,
  Fade,
  Grid,
  TextareaAutosize as MuiTextareaAutosize,
  IconButton as MuiIconButton,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import styled from "styled-components";
import { ReactComponent as PencilIcon } from "../../../assets/Icon/Outline/pencil-icon.svg";
import { ReactComponent as DotVerticalIcon } from "../../../assets/Icon/Outline/dots-vertical-icon.svg";
import { ReactComponent as XCircleIcon } from "../../../assets/Icon/Outline/x-circle-icon.svg";

//*-----------------------------------------------------------------------------------------------
const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      background: "#fff",
      boxShadow: "0px 4px 12px rgba(192, 191, 191)",
      color: "#000",
      padding: "18px 24px",
      borderRadius: "7px",
      width: "400px",
    },
    arrow: {
      fontSize: 16,
      width: 17,
      "&::before": {
        border: "1px solid #FFF",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 12px rgba(192, 191, 191)",
      },
    },
    menu: {
      boxShadow: "0px 4px 12px rgba(192, 191, 191)",
    },
    normalTooltip: {
      background: "#000",
    },
    normalArrow: {
      fontSize: 16,
      width: 17,
      "&::before": {
        border: "1px solid #000",
        backgroundColor: "#000",
      },
    },
  })
);
const TextareaAutosize = styled(MuiTextareaAutosize)`
  border: #dfdfdf solid 1px;
  resize: none;
  width: 100%;
  padding: 5px 10px;
  font-family: Manrope;
  color: #00000;
  font-famile: Manrope;
  color: #00000073;
  &:focus {
    outline: none;
  }
`;
const IconButton = styled(MuiIconButton)`
  &:hover {
    background: transparent;
  }
  padding: 0;
`;
const MenuIconButton = styled(MuiIconButton)`
  padding: 5px;
  height: 20px;
  border-radius: 0;
  &:hover {
    color: #fff;
    background: #1e88e5;
  }
`;
const TooltipButton = styled(MuiIconButton)`
  font-size: 14px;
  color: #fff;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  border-radius: 0;
  &:hover {
    background: grey;
  }
`;

const RemarksCell = ({
  row,
  handleSectionRemarkChange,
  setOnTextareaValueChange,
  onTextareaValueChange,
  setAllSectionData,
  allSectionData,
  setUpdateDocumentCheckListData,
  parent,
}: any) => {
  const [open, setOpen] = React.useState(false);
  const [isRemarkEditing, setIsRemarkEditing] = React.useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          onClose={handleTooltipClose}
          open={open}
          placement="bottom-end"
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
          }}
          title={
            <Grid
              container
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Grid
                padding={"4px 0"}
                item
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                flexDirection={"row"}
              >
                <Typography fontSize={"16px"} fontWeight={600}>
                  Remarks
                </Typography>
                <IconButton>
                  {row?.remarks && !isRemarkEditing ? (
                    <ClickAwayListener
                      onClickAway={() => setTooltipOpen(false)}
                    >
                      <Tooltip
                        onClose={() => setTooltipOpen(false)}
                        open={tooltipOpen}
                        arrow
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        classes={{
                          tooltip: classes.normalTooltip,
                          arrow: classes.normalArrow,
                        }}
                        title={
                          <Grid item display={"flex"} flexDirection={"column"}>
                            <TooltipButton
                              onClick={() => {
                                setTooltipOpen(false);
                                setIsRemarkEditing(true);
                              }}
                            >
                              Edit
                            </TooltipButton>
                            <TooltipButton
                              onClick={() => {
                                setOnTextareaValueChange("");
                                setTooltipOpen(false);
                                setOpen(false);
                                setAllSectionData(
                                  allSectionData?.map((item: any) => {
                                    return {
                                      is_applicable: item.is_applicable,
                                      id: item.id,
                                      ref_document_checklist_category:
                                        item.ref_document_checklist_category,
                                      document_checklists:
                                        item?.document_checklists.map(
                                          (child: any) =>
                                            child.id === row?.id
                                              ? {
                                                  ...child,
                                                  remarks: "",
                                                }
                                              : child
                                        ),
                                    };
                                  })
                                );
                                setUpdateDocumentCheckListData((prev: any) => [
                                  ...prev,
                                  {
                                    where: {
                                      id: {
                                        _eq: row?.id,
                                      },
                                    },
                                    _set: {
                                      remarks: "",
                                    },
                                  },
                                ]);
                                setOnTextareaValueChange("");
                              }}
                            >
                              Delete
                            </TooltipButton>
                          </Grid>
                        }
                      >
                        <MenuIconButton
                          id="basic-button"
                          onClick={() => setTooltipOpen(true)}
                        >
                          <DotVerticalIcon color="#000" />
                        </MenuIconButton>
                      </Tooltip>
                    </ClickAwayListener>
                  ) : (
                    <IconButton
                      className="close-button"
                      onClick={() => {
                        setIsRemarkEditing(false);
                        setOpen(false);
                      }}
                    >
                      <XCircleIcon color="#666666" width="20px" height="20px" />
                    </IconButton>
                  )}
                </IconButton>
              </Grid>
              <Grid item padding={"4px 0"}>
                {isRemarkEditing || row?.remarks === "" ? (
                  <TextareaAutosize
                    name={`remark`}
                    value={onTextareaValueChange ?? row?.remarks}
                    onChange={(e) => setOnTextareaValueChange(e.target.value)}
                    defaultValue={row?.remarks}
                    minRows={8}
                  />
                ) : (
                  <Typography
                    fontSize={"14px"}
                    color={"#00000073"}
                    fontFamily={"Manrope"}
                  >
                    {row?.remarks}
                  </Typography>
                )}
              </Grid>
              <Grid
                padding={"4px 0"}
                item
                justifyContent={"flex-end"}
                display={"flex"}
              >
                {(!row.remarks || isRemarkEditing) && (
                  <Button
                    onClick={() => {
                      setIsRemarkEditing(false);
                      setOpen(false);
                      handleSectionRemarkChange(row?.id);
                    }}
                    style={{ height: "30px" }}
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            </Grid>
          }
        >
          {!!row?.remarks ? (
            <Typography
              fontSize={"14px"}
              color={"#00000073"}
              fontFamily={"Manrope"}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>
                {`${row?.remarks?.substring(0, 100)} ${
                  row?.remarks.length > 100 ? "..." : ""
                }`}{" "}
                {row?.remarks.length > 100 ? (
                  <span
                    onClick={() => setOpen(true)}
                    style={{
                      alignItems: "center",
                      cursor:
                        !parent?.is_applicable && parent.id !== 1
                          ? "unset"
                          : "pointer",
                      color:
                        !parent?.is_applicable && parent.id !== 1
                          ? "#B4B4B4"
                          : "#1E88E5",
                      pointerEvents:
                        !parent?.is_applicable && parent.id !== 1
                          ? "none"
                          : "unset",
                    }}
                  >
                    Read more
                  </span>
                ) : (
                  ""
                )}
              </span>
              <Tooltip
                arrow
                PopperProps={{
                  disablePortal: true,
                }}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                onClose={handleTooltipClose}
                open={open}
                placement="bottom-end"
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.arrow,
                }}
                title={
                  <Grid
                    container
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <Grid
                      padding={"4px 0"}
                      item
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      flexDirection={"row"}
                    >
                      <Typography fontSize={"16px"} fontWeight={600}>
                        Remarks
                      </Typography>
                      <IconButton>
                        {row?.remarks && !isRemarkEditing ? (
                          <ClickAwayListener
                            onClickAway={() => setTooltipOpen(false)}
                          >
                            <Tooltip
                              onClose={() => setTooltipOpen(false)}
                              open={tooltipOpen}
                              arrow
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              classes={{
                                tooltip: classes.normalTooltip,
                                arrow: classes.normalArrow,
                              }}
                              title={
                                <Grid
                                  item
                                  display={"flex"}
                                  flexDirection={"column"}
                                >
                                  <TooltipButton
                                    onClick={() => {
                                      setTooltipOpen(false);
                                      setIsRemarkEditing(true);
                                    }}
                                  >
                                    Edit
                                  </TooltipButton>
                                  <TooltipButton
                                    onClick={() => {
                                      setOnTextareaValueChange("");
                                      setTooltipOpen(false);
                                      setOpen(false);
                                      setAllSectionData(
                                        allSectionData?.map((item: any) => {
                                          return {
                                            is_applicable: item.is_applicable,
                                            id: item.id,
                                            ref_document_checklist_category:
                                              item.ref_document_checklist_category,
                                            document_checklists:
                                              item?.document_checklists.map(
                                                (child: any) =>
                                                  child.id === row?.id
                                                    ? {
                                                        ...child,
                                                        remarks: "",
                                                      }
                                                    : child
                                              ),
                                          };
                                        })
                                      );
                                      setUpdateDocumentCheckListData(
                                        (prev: any) => [
                                          ...prev,
                                          {
                                            where: {
                                              id: {
                                                _eq: row?.id,
                                              },
                                            },
                                            _set: {
                                              remarks: "",
                                            },
                                          },
                                        ]
                                      );
                                      setOnTextareaValueChange("");
                                    }}
                                  >
                                    Delete
                                  </TooltipButton>
                                </Grid>
                              }
                            >
                              <MenuIconButton
                                id="basic-button"
                                onClick={() => setTooltipOpen(true)}
                              >
                                <DotVerticalIcon color="#000" />
                              </MenuIconButton>
                            </Tooltip>
                          </ClickAwayListener>
                        ) : (
                          <IconButton
                            className="close-button"
                            onClick={() => {
                              setIsRemarkEditing(false);
                              setOpen(false);
                            }}
                          >
                            <XCircleIcon
                              color="#666666"
                              width="20px"
                              height="20px"
                            />
                          </IconButton>
                        )}
                      </IconButton>
                    </Grid>
                    <Grid item padding={"4px 0"}>
                      {isRemarkEditing || row?.remarks === "" ? (
                        <TextareaAutosize
                          name={`remark`}
                          value={onTextareaValueChange ?? row?.remarks}
                          onChange={(e) =>
                            setOnTextareaValueChange(e.target.value)
                          }
                          defaultValue={row?.remarks}
                          minRows={8}
                        />
                      ) : (
                        <Typography
                          fontSize={"14px"}
                          color={"#00000073"}
                          fontFamily={"Manrope"}
                        >
                          {row?.remarks}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      padding={"4px 0"}
                      item
                      justifyContent={"flex-end"}
                      display={"flex"}
                    >
                      {(!row.remarks || isRemarkEditing) && (
                        <Button
                          onClick={() => {
                            setIsRemarkEditing(false);
                            setOpen(false);
                            handleSectionRemarkChange(row?.id);
                          }}
                          style={{ height: "30px" }}
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                }
              >
                <IconButton
                  onClick={() => {
                    setIsRemarkEditing(true);
                    setOpen(true);
                  }}
                  style={{
                    borderRadius: "50%",
                    border: "solid 1px #1E88E51F",
                    padding: "5px",
                  }}
                >
                  <PencilIcon width={16} height={16} color="#1E88E5" />
                </IconButton>
              </Tooltip>
            </Typography>
          ) : (
            <Button variant="linkButton" onClick={handleTooltipOpen}>
              Add Remark
            </Button>
          )}
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default RemarksCell;
