import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useContext } from "react";
import { useCreateCompanyMutation } from "../data-access/hooks/company/company.hooks";
import { CompanyContext } from "../contexts/CompanyContext";

const useCreateCompany = () => {
  const navigate = useNavigate();

  const { setShowAlert } = useContext(CompanyContext);

  const RegistrationSchema = yup.object().shape({
    first_name: yup
      .string()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed ")
      .required("First name is required"),
    last_name: yup
      .string()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed ")
      .required("Last  name is required"),
    company_email: yup
      .string()
      .required("Email is required")
      .email("Company Email invalid"),
    company_pan: yup
      .string()
      .required("Company Pan is required")
      .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/g, "Please enter valid company pan"),
    phone: yup
      .string()
      .required("Phone number is required")
      .matches(/^\d{10}$/, "Please enter valid mobile number"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      company_email: "",
      company_pan: "",
      phone: "",
    },
    validationSchema: RegistrationSchema,
    onSubmit: (item: any) => {
      handleCreateCompany({
        variables: {
          args: {
            email: item.company_email,
            first_name: item.first_name,
            last_name: item.last_name,
            mobile_number: JSON.stringify(item.phone),
            pan: item.company_pan,
          },
        },
      });
    },
  });

  const [handleCreateCompany, { loading: createLoading }] =
    useCreateCompanyMutation({
      onCompleted: (item) => {
        navigate(`/company/${item?.OpsCreateCompany?.company_id}/details`);
        setShowAlert({
          show: true,
          type: "success",
          message: item?.OpsCreateCompany?.message ?? "",
        });
      },
      onError: (item) => {
        setShowAlert({
          show: true,
          type: "error",
          message: item?.message ?? "",
        });
      },
    });

  return {
    createLoading,
    formik,
    navigate,
  };
};

export default useCreateCompany;
