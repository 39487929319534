import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllCollaboratorsByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetAllCollaboratorsByIdQuery = { __typename?: 'query_root', company_by_pk?: { __typename?: 'company', name?: string | null, company_users: Array<{ __typename?: 'company_user', id: number, user_type: Types.Ref_User_Type_Enum, user: { __typename?: 'user', first_name: string, last_name: string, mobile_number: string, email: string, id: number, status: string } }> } | null };

export type ResendCollaboratorMailMutationVariables = Types.Exact<{
  user_id: Types.Scalars['Int'];
}>;


export type ResendCollaboratorMailMutation = { __typename?: 'mutation_root', ResendMailToCollaborator?: { __typename?: 'ResendMailToCollaboratorOutput', message: string } | null };

export type DeleteCollaboratorMailMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type DeleteCollaboratorMailMutation = { __typename?: 'mutation_root', DeleteCollaborator?: { __typename?: 'DeleteCollaboratorOutput', message: string } | null };

export type AddNewCollaboratorMutationVariables = Types.Exact<{
  args: Types.AddCollaboratorInput;
}>;


export type AddNewCollaboratorMutation = { __typename?: 'mutation_root', AddCollaborator?: { __typename?: 'AddCollaboratorOutput', message: string, user_id: number } | null };

export type GetAllAlternateEmailQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Company_Bool_Exp>;
}>;


export type GetAllAlternateEmailQuery = { __typename?: 'query_root', company: Array<{ __typename?: 'company', alternate_emails?: string | null }> };

export type UpdateCompanyAlternateEmailMutationVariables = Types.Exact<{
  additional_emails: Types.Scalars['String'];
}>;


export type UpdateCompanyAlternateEmailMutation = { __typename?: 'mutation_root', CompanyAdditionalEmails?: { __typename?: 'CompanyAdditionalEmailsOutput', message: string } | null };


export const GetAllCollaboratorsByIdDocument = gql`
    query getAllCollaboratorsById($id: Int!) {
  company_by_pk(id: $id) {
    name
    company_users {
      id
      user_type
      user {
        first_name
        last_name
        mobile_number
        email
        id
        status
      }
    }
  }
}
    `;

/**
 * __useGetAllCollaboratorsByIdQuery__
 *
 * To run a query within a React component, call `useGetAllCollaboratorsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCollaboratorsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCollaboratorsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllCollaboratorsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAllCollaboratorsByIdQuery, GetAllCollaboratorsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCollaboratorsByIdQuery, GetAllCollaboratorsByIdQueryVariables>(GetAllCollaboratorsByIdDocument, options);
      }
export function useGetAllCollaboratorsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCollaboratorsByIdQuery, GetAllCollaboratorsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCollaboratorsByIdQuery, GetAllCollaboratorsByIdQueryVariables>(GetAllCollaboratorsByIdDocument, options);
        }
export type GetAllCollaboratorsByIdQueryHookResult = ReturnType<typeof useGetAllCollaboratorsByIdQuery>;
export type GetAllCollaboratorsByIdLazyQueryHookResult = ReturnType<typeof useGetAllCollaboratorsByIdLazyQuery>;
export type GetAllCollaboratorsByIdQueryResult = Apollo.QueryResult<GetAllCollaboratorsByIdQuery, GetAllCollaboratorsByIdQueryVariables>;
export const ResendCollaboratorMailDocument = gql`
    mutation resendCollaboratorMail($user_id: Int!) {
  ResendMailToCollaborator(args: {user_id: $user_id}) {
    message
  }
}
    `;
export type ResendCollaboratorMailMutationFn = Apollo.MutationFunction<ResendCollaboratorMailMutation, ResendCollaboratorMailMutationVariables>;

/**
 * __useResendCollaboratorMailMutation__
 *
 * To run a mutation, you first call `useResendCollaboratorMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendCollaboratorMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendCollaboratorMailMutation, { data, loading, error }] = useResendCollaboratorMailMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useResendCollaboratorMailMutation(baseOptions?: Apollo.MutationHookOptions<ResendCollaboratorMailMutation, ResendCollaboratorMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendCollaboratorMailMutation, ResendCollaboratorMailMutationVariables>(ResendCollaboratorMailDocument, options);
      }
export type ResendCollaboratorMailMutationHookResult = ReturnType<typeof useResendCollaboratorMailMutation>;
export type ResendCollaboratorMailMutationResult = Apollo.MutationResult<ResendCollaboratorMailMutation>;
export type ResendCollaboratorMailMutationOptions = Apollo.BaseMutationOptions<ResendCollaboratorMailMutation, ResendCollaboratorMailMutationVariables>;
export const DeleteCollaboratorMailDocument = gql`
    mutation deleteCollaboratorMail($id: Int!) {
  DeleteCollaborator(args: {id: $id}) {
    message
  }
}
    `;
export type DeleteCollaboratorMailMutationFn = Apollo.MutationFunction<DeleteCollaboratorMailMutation, DeleteCollaboratorMailMutationVariables>;

/**
 * __useDeleteCollaboratorMailMutation__
 *
 * To run a mutation, you first call `useDeleteCollaboratorMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollaboratorMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollaboratorMailMutation, { data, loading, error }] = useDeleteCollaboratorMailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCollaboratorMailMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCollaboratorMailMutation, DeleteCollaboratorMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCollaboratorMailMutation, DeleteCollaboratorMailMutationVariables>(DeleteCollaboratorMailDocument, options);
      }
export type DeleteCollaboratorMailMutationHookResult = ReturnType<typeof useDeleteCollaboratorMailMutation>;
export type DeleteCollaboratorMailMutationResult = Apollo.MutationResult<DeleteCollaboratorMailMutation>;
export type DeleteCollaboratorMailMutationOptions = Apollo.BaseMutationOptions<DeleteCollaboratorMailMutation, DeleteCollaboratorMailMutationVariables>;
export const AddNewCollaboratorDocument = gql`
    mutation addNewCollaborator($args: AddCollaboratorInput!) {
  AddCollaborator(args: $args) {
    message
    user_id
  }
}
    `;
export type AddNewCollaboratorMutationFn = Apollo.MutationFunction<AddNewCollaboratorMutation, AddNewCollaboratorMutationVariables>;

/**
 * __useAddNewCollaboratorMutation__
 *
 * To run a mutation, you first call `useAddNewCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewCollaboratorMutation, { data, loading, error }] = useAddNewCollaboratorMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useAddNewCollaboratorMutation(baseOptions?: Apollo.MutationHookOptions<AddNewCollaboratorMutation, AddNewCollaboratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewCollaboratorMutation, AddNewCollaboratorMutationVariables>(AddNewCollaboratorDocument, options);
      }
export type AddNewCollaboratorMutationHookResult = ReturnType<typeof useAddNewCollaboratorMutation>;
export type AddNewCollaboratorMutationResult = Apollo.MutationResult<AddNewCollaboratorMutation>;
export type AddNewCollaboratorMutationOptions = Apollo.BaseMutationOptions<AddNewCollaboratorMutation, AddNewCollaboratorMutationVariables>;
export const GetAllAlternateEmailDocument = gql`
    query getAllAlternateEmail($where: company_bool_exp) {
  company(where: $where) {
    alternate_emails
  }
}
    `;

/**
 * __useGetAllAlternateEmailQuery__
 *
 * To run a query within a React component, call `useGetAllAlternateEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAlternateEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAlternateEmailQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllAlternateEmailQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAlternateEmailQuery, GetAllAlternateEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAlternateEmailQuery, GetAllAlternateEmailQueryVariables>(GetAllAlternateEmailDocument, options);
      }
export function useGetAllAlternateEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAlternateEmailQuery, GetAllAlternateEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAlternateEmailQuery, GetAllAlternateEmailQueryVariables>(GetAllAlternateEmailDocument, options);
        }
export type GetAllAlternateEmailQueryHookResult = ReturnType<typeof useGetAllAlternateEmailQuery>;
export type GetAllAlternateEmailLazyQueryHookResult = ReturnType<typeof useGetAllAlternateEmailLazyQuery>;
export type GetAllAlternateEmailQueryResult = Apollo.QueryResult<GetAllAlternateEmailQuery, GetAllAlternateEmailQueryVariables>;
export const UpdateCompanyAlternateEmailDocument = gql`
    mutation updateCompanyAlternateEmail($additional_emails: String!) {
  CompanyAdditionalEmails(args: {additional_emails: $additional_emails}) {
    message
  }
}
    `;
export type UpdateCompanyAlternateEmailMutationFn = Apollo.MutationFunction<UpdateCompanyAlternateEmailMutation, UpdateCompanyAlternateEmailMutationVariables>;

/**
 * __useUpdateCompanyAlternateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyAlternateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyAlternateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyAlternateEmailMutation, { data, loading, error }] = useUpdateCompanyAlternateEmailMutation({
 *   variables: {
 *      additional_emails: // value for 'additional_emails'
 *   },
 * });
 */
export function useUpdateCompanyAlternateEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyAlternateEmailMutation, UpdateCompanyAlternateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyAlternateEmailMutation, UpdateCompanyAlternateEmailMutationVariables>(UpdateCompanyAlternateEmailDocument, options);
      }
export type UpdateCompanyAlternateEmailMutationHookResult = ReturnType<typeof useUpdateCompanyAlternateEmailMutation>;
export type UpdateCompanyAlternateEmailMutationResult = Apollo.MutationResult<UpdateCompanyAlternateEmailMutation>;
export type UpdateCompanyAlternateEmailMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyAlternateEmailMutation, UpdateCompanyAlternateEmailMutationVariables>;
export const namedOperations = {
  Query: {
    getAllCollaboratorsById: 'getAllCollaboratorsById',
    getAllAlternateEmail: 'getAllAlternateEmail'
  },
  Mutation: {
    resendCollaboratorMail: 'resendCollaboratorMail',
    deleteCollaboratorMail: 'deleteCollaboratorMail',
    addNewCollaborator: 'addNewCollaborator',
    updateCompanyAlternateEmail: 'updateCompanyAlternateEmail'
  }
}