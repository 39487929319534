import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadAccountDataFileMutationVariables = Types.Exact<{
  args: Types.AccountingExcelUploadInput;
}>;


export type UploadAccountDataFileMutation = { __typename?: 'mutation_root', accountingExcelUpload?: { __typename?: 'AccountingExcelUploadOutput', message: string, id: number } | null };

export type DownloadAccountDataFileQueryVariables = Types.Exact<{
  args: Types.GenerateFileRegistryDownloadUrlInput;
}>;


export type DownloadAccountDataFileQuery = { __typename?: 'query_root', generateFileRegistryDownloadURL?: { __typename?: 'GenerateFileRegistryDownloadURLOutput', download_url: string } | null };

export type GetAllAccountIntegerationDataQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Company_Integration_Bool_Exp>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetAllAccountIntegerationDataQuery = { __typename?: 'query_root', company_integration: Array<{ __typename?: 'company_integration', category: string, company_id: number, source: string, status: string, updated_at: any, failure_reason?: any | null, company_integration_files: Array<{ __typename?: 'company_integration_file', company_integration_id: number, file_registry: { __typename?: 'file_registry', category_id: Types.Ref_File_Category_Enum, name: string, id: number } }> }>, company_integration_aggregate: { __typename?: 'company_integration_aggregate', aggregate?: { __typename?: 'company_integration_aggregate_fields', count: number } | null } };


export const UploadAccountDataFileDocument = gql`
    mutation uploadAccountDataFile($args: AccountingExcelUploadInput!) {
  accountingExcelUpload(args: $args) {
    message
    id
  }
}
    `;
export type UploadAccountDataFileMutationFn = Apollo.MutationFunction<UploadAccountDataFileMutation, UploadAccountDataFileMutationVariables>;

/**
 * __useUploadAccountDataFileMutation__
 *
 * To run a mutation, you first call `useUploadAccountDataFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAccountDataFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAccountDataFileMutation, { data, loading, error }] = useUploadAccountDataFileMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUploadAccountDataFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadAccountDataFileMutation, UploadAccountDataFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadAccountDataFileMutation, UploadAccountDataFileMutationVariables>(UploadAccountDataFileDocument, options);
      }
export type UploadAccountDataFileMutationHookResult = ReturnType<typeof useUploadAccountDataFileMutation>;
export type UploadAccountDataFileMutationResult = Apollo.MutationResult<UploadAccountDataFileMutation>;
export type UploadAccountDataFileMutationOptions = Apollo.BaseMutationOptions<UploadAccountDataFileMutation, UploadAccountDataFileMutationVariables>;
export const DownloadAccountDataFileDocument = gql`
    query downloadAccountDataFile($args: GenerateFileRegistryDownloadURLInput!) {
  generateFileRegistryDownloadURL(args: $args) {
    download_url
  }
}
    `;

/**
 * __useDownloadAccountDataFileQuery__
 *
 * To run a query within a React component, call `useDownloadAccountDataFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadAccountDataFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadAccountDataFileQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDownloadAccountDataFileQuery(baseOptions: Apollo.QueryHookOptions<DownloadAccountDataFileQuery, DownloadAccountDataFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadAccountDataFileQuery, DownloadAccountDataFileQueryVariables>(DownloadAccountDataFileDocument, options);
      }
export function useDownloadAccountDataFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadAccountDataFileQuery, DownloadAccountDataFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadAccountDataFileQuery, DownloadAccountDataFileQueryVariables>(DownloadAccountDataFileDocument, options);
        }
export type DownloadAccountDataFileQueryHookResult = ReturnType<typeof useDownloadAccountDataFileQuery>;
export type DownloadAccountDataFileLazyQueryHookResult = ReturnType<typeof useDownloadAccountDataFileLazyQuery>;
export type DownloadAccountDataFileQueryResult = Apollo.QueryResult<DownloadAccountDataFileQuery, DownloadAccountDataFileQueryVariables>;
export const GetAllAccountIntegerationDataDocument = gql`
    query getAllAccountIntegerationData($where: company_integration_bool_exp, $offset: Int, $limit: Int) {
  company_integration(where: $where, offset: $offset, limit: $limit) {
    category
    company_id
    source
    status
    updated_at
    failure_reason
    company_integration_files {
      company_integration_id
      file_registry {
        category_id
        name
        id
      }
    }
  }
  company_integration_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllAccountIntegerationDataQuery__
 *
 * To run a query within a React component, call `useGetAllAccountIntegerationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccountIntegerationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccountIntegerationDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllAccountIntegerationDataQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAccountIntegerationDataQuery, GetAllAccountIntegerationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAccountIntegerationDataQuery, GetAllAccountIntegerationDataQueryVariables>(GetAllAccountIntegerationDataDocument, options);
      }
export function useGetAllAccountIntegerationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAccountIntegerationDataQuery, GetAllAccountIntegerationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAccountIntegerationDataQuery, GetAllAccountIntegerationDataQueryVariables>(GetAllAccountIntegerationDataDocument, options);
        }
export type GetAllAccountIntegerationDataQueryHookResult = ReturnType<typeof useGetAllAccountIntegerationDataQuery>;
export type GetAllAccountIntegerationDataLazyQueryHookResult = ReturnType<typeof useGetAllAccountIntegerationDataLazyQuery>;
export type GetAllAccountIntegerationDataQueryResult = Apollo.QueryResult<GetAllAccountIntegerationDataQuery, GetAllAccountIntegerationDataQueryVariables>;
export const namedOperations = {
  Query: {
    downloadAccountDataFile: 'downloadAccountDataFile',
    getAllAccountIntegerationData: 'getAllAccountIntegerationData'
  },
  Mutation: {
    uploadAccountDataFile: 'uploadAccountDataFile'
  }
}