import { Typography, Breadcrumbs as MuiBreadcrumbs, Link } from "@mui/material";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { CompanyContext } from "../contexts/CompanyContext";

const Breadcrumbs = styled(MuiBreadcrumbs)`
  margin: 5px 0;
  font-family: Manrope;
`;

interface BreadcrumbsProps {
  typographyText?: string;
  endLink?: string;
  linksText?: any[];
  showCompany?: boolean;
}

const CommonBreadCrumb = (props: BreadcrumbsProps) => {
  const { companyData } = useContext(CompanyContext);

  return (
    <>
      <Typography
        style={{ textTransform: "capitalize" }}
        fontSize={"24px"}
        fontWeight={600}
        display="inline"
      >
        {props.typographyText}
      </Typography>
      <Breadcrumbs>
        {props.linksText?.map(
          (data: { link: string; to: string; data: any }, index: number) => (
            <Link
              color={"#000000"}
              component={NavLink}
              to={data?.to}
              state={data?.data}
              key={index}
            >
              {data.link}
            </Link>
          )
        )}

        {(props.showCompany || props.endLink) && (
          <Typography color={"rgba(0, 0, 0, 0.45)"}>
            {props.endLink ? props.endLink : companyData?.name}
          </Typography>
        )}
      </Breadcrumbs>
    </>
  );
};

export default CommonBreadCrumb;
