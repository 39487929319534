import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserByIdQueryVariables = Types.Exact<{
  user_id: Types.Scalars['Int'];
}>;


export type GetUserByIdQuery = { __typename?: 'query_root', user_by_pk?: { __typename?: 'user', id: number, email: string, first_name: string, last_name: string } | null };

export type StaffUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StaffUsersQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', first_name: string, id: number, last_name: string, email: string, designation?: string | null }> };

export type UsersCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UsersCountQuery = { __typename?: 'query_root', user_aggregate: { __typename?: 'user_aggregate', aggregate?: { __typename?: 'user_aggregate_fields', count: number } | null } };

export type UpdateUserMutationVariables = Types.Exact<{
  whereID: Types.Scalars['Int'];
  set: Types.User_Set_Input;
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', update_user?: { __typename?: 'user_mutation_response', returning: Array<{ __typename?: 'user', id: number, first_name: string, last_name: string, email: string, designation?: string | null, mobile_number: string, country_code: string }> } | null };


export const GetUserByIdDocument = gql`
    query getUserByID($user_id: Int!) {
  user_by_pk(id: $user_id) {
    id
    email
    first_name
    last_name
  }
}
    `;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const StaffUsersDocument = gql`
    query staffUsers {
  user(
    where: {tenant_type: {_eq: "STAFF"}, deleted_at: {_is_null: true}}
    order_by: {created_at: desc}
  ) {
    first_name
    id
    last_name
    email
    designation
  }
}
    `;

/**
 * __useStaffUsersQuery__
 *
 * To run a query within a React component, call `useStaffUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffUsersQuery(baseOptions?: Apollo.QueryHookOptions<StaffUsersQuery, StaffUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffUsersQuery, StaffUsersQueryVariables>(StaffUsersDocument, options);
      }
export function useStaffUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffUsersQuery, StaffUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffUsersQuery, StaffUsersQueryVariables>(StaffUsersDocument, options);
        }
export type StaffUsersQueryHookResult = ReturnType<typeof useStaffUsersQuery>;
export type StaffUsersLazyQueryHookResult = ReturnType<typeof useStaffUsersLazyQuery>;
export type StaffUsersQueryResult = Apollo.QueryResult<StaffUsersQuery, StaffUsersQueryVariables>;
export const UsersCountDocument = gql`
    query usersCount {
  user_aggregate(
    where: {tenant_type: {_eq: "STAFF"}, deleted_at: {_is_null: true}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUsersCountQuery__
 *
 * To run a query within a React component, call `useUsersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersCountQuery(baseOptions?: Apollo.QueryHookOptions<UsersCountQuery, UsersCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersCountQuery, UsersCountQueryVariables>(UsersCountDocument, options);
      }
export function useUsersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersCountQuery, UsersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersCountQuery, UsersCountQueryVariables>(UsersCountDocument, options);
        }
export type UsersCountQueryHookResult = ReturnType<typeof useUsersCountQuery>;
export type UsersCountLazyQueryHookResult = ReturnType<typeof useUsersCountLazyQuery>;
export type UsersCountQueryResult = Apollo.QueryResult<UsersCountQuery, UsersCountQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($whereID: Int!, $set: user_set_input!) {
  update_user(where: {id: {_eq: $whereID}}, _set: $set) {
    returning {
      id
      first_name
      last_name
      email
      designation
      mobile_number
      country_code
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      whereID: // value for 'whereID'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const namedOperations = {
  Query: {
    getUserByID: 'getUserByID',
    staffUsers: 'staffUsers',
    usersCount: 'usersCount'
  },
  Mutation: {
    updateUser: 'updateUser'
  }
}