import styled from "styled-components/macro";
import { Box as MuiBox, Card as MuiCard } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";

/**!For now i have add the font size as per the what looks good will change once the figma is updated */
const Card = styled(MuiCard)`
  width: 340px;
  & .MuiCardHeader-title {
    color: black;
    font-weight: 800;
    font-size: 18px;
    white-space: nowrap;
  }
  & .MuiCardHeader-subheader {
    color: black;
    font-size: 12px;
    white-space: nowrap;
  }
`;
const Box = styled(MuiBox)`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #def0ff;
  border-radius: 7px;
`;

interface DashboardCardInterface {
  image: String;
  description: String | HTMLElement | any;
  totalValue: String | HTMLElement | any;
}

const DashboardCard = ({
  totalValue,
  description,
  image,
}: DashboardCardInterface) => {
  return (
    <Card>
      <CardHeader
        avatar={
          <Box>
            <img alt="" src={`/svg/${image && image}.svg`} height={"24px"} />
          </Box>
        }
        title={totalValue && totalValue}
        subheader={description && description}
      />
    </Card>
  );
};

export default DashboardCard;
