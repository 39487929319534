import styled from "styled-components/macro";

export const TableWrapper = styled.div`
  overflow-y: auto;
  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: #1e88e555;
  }
  ::-webkit-scrollbar-thumb {
    background: #1e88e5;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
  max-height: calc(100vh - 290px);
  & .MuiTableHead-root {
    position: sticky;
    top: 0;
    background: #ecf5ff;
    z-index: 99;
  }
`;
