import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetGlobalInvestorsQuery } from "../data-access/hooks/company/investors.hooks";
import { Order_By } from "../data-access/types";

interface GetAllInvestorInterface {
  name?: string;
  id?: number;
  created_at?: string;
  cin?: string;
  pan?: string;
  type?: string;
  status?: string;
}

const useInvestor = () => {
  const navigate = useNavigate();

  const [allInvestors, setAllInvestors] = React.useState<
    GetAllInvestorInterface[]
  >([]);

  const [currentStep, setCurrentStep] = React.useState(0);

  const [processData, setProcessData] = React.useState({
    investorData: {},
    userData: [],
    bankData: {},
    agreementData: {},
  });

  const [openInvestorModal, setOpenInvestorModal] = React.useState(false);

  const [paginationData, setPaginationData] = React.useState({
    offset: 0,
    limit: 50,
    totalcount: 0,
    currentPage: 1,
  });

  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  const { loading: getInvestorsLoading, refetch: getAllInvestorRefetch } =
    useGetGlobalInvestorsQuery({
      variables: {
        order_by: {
          id: Order_By.Desc,
        },
        limit: paginationData.limit,
        offset: paginationData.offset,
      },
      onCompleted: (item) => {
        const data = item.investor.map((data) => {
          return {
            name: `${data.name}`,
            id: Number(data.id),
            created_at: `${data.created_at}`,
            cin: `${data.cin}`,
            pan: `${data.pan}`,
            type: `${data.type}`,
            status: `${data.status}`,
          };
        });
        setPaginationData({
          ...paginationData,
          totalcount: Number(item.investor_aggregate.aggregate?.count),
        });
        setAllInvestors(data);
      },
    });

  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  const pageCount = (number: number) => {
    return Math.ceil(number / 10);
  };

  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  return {
    allInvestors,
    setAllInvestors,
    getInvestorsLoading,
    paginationData,
    setPaginationData,
    pageCount,
    openInvestorModal,
    setOpenInvestorModal,
    currentStep,
    setCurrentStep,
    processData,
    setProcessData,
    navigate,
    getAllInvestorRefetch,
  };
};

export default useInvestor;
