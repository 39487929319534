/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components/macro";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField as MuiTextField,
  Switch,
  TextFieldProps,
  CircularProgress,
  LinearProgress,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import { Paper as MuiPaper, Divider as MuiDivider } from "@mui/material";
import React, { useContext, useState } from "react";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useFormik } from "formik";
import { TableWrapper } from "../../../theme/customComponents";
import CommonBreadCrumb from "../../../components/CommonBreadCrumb";
import { ReactComponent as ArrowRightIcon } from "../../../assets/Icon/Outline/arrow-right-icon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useConfigureTradeMutation,
  useGetAllBankAccountsQuery,
  useGetCompanyActiveGstQuery,
  useGetMandateByTradeQuery,
} from "../../../data-access/hooks/company/deals-trade.hooks";
import {
  Mandate_Type,
  Debit_Mode,
  Collection_Mode,
  InitiateTradeInput,
} from "../../../data-access/types";
import SimpleDatePicker from "../../../components/input/SimpleDatePicker";
import { addMonths, format } from "date-fns";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { createStyles, makeStyles } from "@mui/styles";
import MandateConfiguration from "./MandateConfiguration";

//*-----------------------------------------------------------------------------------------------
const Paper = styled(MuiPaper)(spacing);
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)`
  & .MuiInputBase-input {
    padding: 0 10px;
    height: 32px;
  }
`;
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 35,
  height: 19.5,
  padding: 0,
  borderRadius: 50,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(15px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#fff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 15,
    height: 15,
    color: "#1E88E5",
    borderRadius: 10,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(255,255,255)",
    border: " solid 2px black",
    boxSizing: "border-box",
  },
}));
//*-----------------------------------------------------------------------------------------------

interface BidsInterface {
  id?: Number;
  interest_rate?: Number;
  investor_id?: Number;
  withdrawal_mode?: String;
  emi_split_ratio?: String;
  fees_per_emi?: String;
  invested_amount?: Number;
  amount?: Number;
}

interface FilterGSTInterface {
  gstinId: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    autocomplete: {
      "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
        padding: 0,
      },
      "& .MuiOutlinedInput-root": {
        padding: "0 10px",
      },
    },
    autocompletepaper: {
      boxShadow: "0 0 3px #00000044",
    },
  })
);

const TradeConfiguration: React.FC = () => {
  const { state }: any = useLocation();
  console.log("state: ", state);

  const classes = useStyles();

  const GSTRegex = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/gm;

  const { setShowAlert, companyData } = useContext(CompanyContext);

  const navigate = useNavigate();

  const { id: company_id } = useParams();

  const filter = createFilterOptions<FilterGSTInterface>();

  const [mandateList, setMandateList] = React.useState<any[]>([]);

  const mandateFrequency = [
    "AD HOC",
    "INTRA_DAY",
    "DAILY",
    "WEEKLY",
    "MONTHLY",
    "BIMONTHLY",
    "QUARTERLY",
    "YEARLY",
    "SEMIANNUALLY",
  ];

  const [mandateBankList, setMandateBankList] = React.useState<any[]>([]);

  const [allActiveGST, setAllActiveGST] = React.useState<any[]>([]);

  const [rows, setRows] = useState<BidsInterface[]>(state?.selectedOffers);

  /*************************************************************************************************** */
  const { loading: getAllGSTNumbersLoading } = useGetCompanyActiveGstQuery({
    onCompleted: (item) => {
      const activeDGSTata = item?.company?.map(
        (dataItem) =>
          dataItem?.gstin &&
          dataItem?.gstin?.filter(
            (gstData: any) => gstData.authStatus === "Active"
          )
      );
      const FilterData =
        activeDGSTata &&
        activeDGSTata[0]?.map((data: any) => {
          return {
            gstinId: data.gstinId,
          };
        });
      setAllActiveGST(FilterData || []);
    },
    variables: {
      where: {
        id: {
          _eq: Number(company_id),
        },
      },
    },
  });
  /*************************************************************************************************** */
  const formik = useFormik({
    initialValues: {
      mandate_configuration: {
        mandate_account_id: null,
        mandate_no_of_months: null,
        max_presentation_amount: "",
        mandate_type: "PAPER",
        mandate_frequency: "MONTHLY",
        mandate_id: 0,
        reuse_mandate: false,
      },
      trade_configuration: {
        roi: "",
        fees_without_gst: null,
        validity: 12,
        debit_mode: "" as Debit_Mode,
        collection_mode: "" as Collection_Mode,
        is_offline: false,
        payout_value: "",
        remarks: state?.remarks || "",
        emi_start_date: new Date(),
        payout_on: new Date(),
        billing_address: "",
        billing_gst: "",
      },
    },
    onSubmit: (item) => {
      if (item.trade_configuration.is_offline) {
        let initiateTradeArgs: InitiateTradeInput = {
          deal_id: state.dealId,
          bids: convertedInvestors as any,
          mandate_configuration: {},
          trade_configuration: {
            roi: Number(item?.trade_configuration.roi),
            fees_without_gst: item?.trade_configuration.fees_without_gst,
            validity: Number(item?.trade_configuration.validity),
            debit_mode: (item?.trade_configuration.debit_mode
              ? "MANUAL"
              : "AUTO") as Debit_Mode,
            collection_mode: (item?.trade_configuration.collection_mode
              ? "ESCROW"
              : "MANDATE") as Collection_Mode,
            is_offline: item?.trade_configuration.is_offline,
            payout_value: Number(item?.trade_configuration.payout_value),
            emi_start_date: item?.trade_configuration.emi_start_date,
            payout_on: item.trade_configuration.payout_on,
            remarks: item.trade_configuration.remarks,
          },
        };
        if (item.trade_configuration.billing_address) {
          if (initiateTradeArgs.trade_configuration) {
            initiateTradeArgs.trade_configuration.billing_address =
              item.trade_configuration.billing_address;
          }
        }

        if (item.trade_configuration.billing_gst) {
          if (initiateTradeArgs.trade_configuration) {
            initiateTradeArgs.trade_configuration.billing_gst =
              item.trade_configuration.billing_gst;
          }
        }

        handleInitiateConfigration({
          variables: {
            args: initiateTradeArgs,
          },
        });
      } else if (!item.mandate_configuration.mandate_id) {
        handleInitiateConfigration({
          variables: {
            args: {
              deal_id: state.dealId,
              bids: convertedInvestors as any,
              mandate_configuration: {
                mandate_account_id:
                  item.mandate_configuration.mandate_account_id,
                mandate_frequency: item.mandate_configuration.mandate_frequency,
                mandate_no_of_months: Number(
                  item.mandate_configuration.mandate_no_of_months
                ),
                mandate_type: item.mandate_configuration
                  .mandate_type as Mandate_Type,
                max_presentation_amount: Number(
                  item.mandate_configuration?.max_presentation_amount
                ),
              },
              trade_configuration: {
                roi: Number(item.trade_configuration.roi),
                fees_without_gst: item.trade_configuration.fees_without_gst,
                validity: Number(item.trade_configuration.validity),
                debit_mode: (item.trade_configuration.debit_mode
                  ? "MANUAL"
                  : "AUTO") as Debit_Mode,
                collection_mode: (item.trade_configuration.collection_mode
                  ? "ESCROW"
                  : "MANDATE") as Collection_Mode,
                is_offline: item.trade_configuration.is_offline ? true : false,
                payout_value: Number(item.trade_configuration.payout_value),
                emi_start_date: item.trade_configuration.emi_start_date,
                billing_gst: item.trade_configuration.billing_gst,
                payout_on: item.trade_configuration.payout_on,
                remarks: item.trade_configuration.remarks,
              },
            },
          },
        });
      } else {
        handleInitiateConfigration({
          variables: {
            args: {
              deal_id: state.dealId,
              bids: convertedInvestors as any,
              mandate_configuration: {
                mandate_id: item.mandate_configuration?.mandate_id,
              },
              trade_configuration: {
                roi: Number(item.trade_configuration.roi),
                fees_without_gst: item.trade_configuration.fees_without_gst,
                validity: Number(item.trade_configuration.validity),
                debit_mode: (item.trade_configuration.debit_mode
                  ? "MANUAL"
                  : "AUTO") as Debit_Mode,
                collection_mode: (item.trade_configuration.collection_mode
                  ? "ESCROW"
                  : "MANDATE") as Collection_Mode,
                is_offline: item.trade_configuration.is_offline ? true : false,
                payout_value: Number(item.trade_configuration.payout_value),
                emi_start_date: item.trade_configuration.emi_start_date,
                billing_gst: item.trade_configuration.billing_gst,
                payout_on: item.trade_configuration.payout_on,
                remarks: item.trade_configuration.remarks,
              },
            },
          },
        });
      }
    },
  });

  /*************************************************************************************************** */
  const getMandateMonth = format(
    addMonths(
      Number(formik.values.trade_configuration.emi_start_date),
      formik.values.trade_configuration.validity + 2
    ),
    "yyyy-MM-dd"
  );

  /*************************************************************************************************** */
  const handleInputChange = (e: any, index: any) => {
    const { value, name } = e?.target;
    const list = [...rows];
    //@ts-ignore
    list[index][name] = value;
    setRows(list);
  };

  /*************************************************************************************************** */
  const handleRepaymentInputChange = (e: any, index: any) => {
    const { checked } = e?.target;
    const list = [...rows];
    list[index]["withdrawal_mode"] = checked ? "MANUAL" : "AUTO";
    setRows(list);
  };

  /*************************************************************************************************** */
  const convertedInvestors = rows?.map((item) => {
    return {
      id: item?.id,
      amount: Number(item?.invested_amount),
      emi_split_ratio: Number(item?.emi_split_ratio),
      withdrawal_mode: item?.withdrawal_mode || "AUTO",
      fees_per_emi: Number(item?.fees_per_emi),
    };
  });

  /*************************************************************************************************** */

  const { loading: bankAccountsLoading } = useGetAllBankAccountsQuery({
    variables: {
      company_id: Number(company_id),
    },
    onCompleted: (item) => {
      setMandateBankList(item.account);
    },
  });

  /*************************************************************************************************** */
  const { loading: mandateLoading } = useGetMandateByTradeQuery({
    variables: {
      where: {
        company_id: {
          _eq: Number(company_id),
        },
        end_date: {
          _gte: getMandateMonth,
        },
      },
    },
    onCompleted: (item) => setMandateList(item.mandate),
  });

  /*************************************************************************************************** */
  const [handleInitiateConfigration, { loading: InitiateTradeLoading }] =
    useConfigureTradeMutation({
      onCompleted: (item) => {
        if (formik.values.trade_configuration.is_offline) {
          navigate(`/trades`, {
            state: { id: item?.initiateTrade?.id },
          });
        } else {
          navigate(
            `/company/${company_id}/trade/${item?.initiateTrade?.id}/contract-selection`,
            {
              state: { id: item?.initiateTrade?.id },
            }
          );
        }
      },
      onError: (item) => {
        setShowAlert({
          show: true,
          message: item?.message,
          type: "error",
        });
      },
    });

  React.useEffect(() => {
    if (formik?.values.trade_configuration.collection_mode) {
      formik.setFieldValue("trade_configuration.debit_mode", true);
    }
  }, [formik?.values.trade_configuration.collection_mode]);

  return (
    <React.Fragment>
      <Helmet title="Company Detail" />
      {(bankAccountsLoading || mandateLoading || getAllGSTNumbersLoading) && (
        <LinearProgress />
      )}
      <form onSubmit={formik.handleSubmit}>
        <Grid
          item
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {/*---------------------------*COMMON BREAD CRUMBS--------------------------- */}
          <Grid item>
            <CommonBreadCrumb
              typographyText="Trade Configuration"
              linksText={[{ link: "Deals", to: "/deals" }]}
              showCompany
            />
          </Grid>
          <Grid item gap={3} display={"flex"}>
            <Button
              disabled={InitiateTradeLoading}
              variant="primarySmall"
              type="submit"
            >
              {InitiateTradeLoading ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  Save & Proceed
                  <ArrowRightIcon width="16px" height="16px" color="#fff" />
                </>
              )}
            </Button>
          </Grid>
        </Grid>
        <Divider mt="16px" mb="28px" />
        <Grid item>
          {/*COMPANY DETAILS--------------------------------------------------------------------------- */}
          <Grid item>
            <Paper>
              <Grid padding={"20px"} justifyContent="space-between" container>
                <Grid item>
                  <Typography fontWeight={600} variant="h4">
                    Company Details
                  </Typography>
                </Grid>
              </Grid>
              <TableWrapper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Company ID</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>GST Number</TableCell>
                      <TableCell>Billing Address</TableCell>
                      <TableCell>Remarks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width={"20%"}>{companyData?.id}</TableCell>
                      <TableCell width={"20%"}>{companyData?.name}</TableCell>
                      <TableCell width={"20%"}>
                        <Autocomplete
                          classes={{
                            root: classes.autocomplete,
                            paper: classes.autocompletepaper,
                          }}
                          value={formik.values.trade_configuration.billing_gst}
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              formik.setFieldValue(
                                "trade_configuration.billing_gst",
                                //@ts-ignore
                                newValue?.gstinId
                              );
                            } else if (newValue && newValue.inputValue) {
                              formik.setFieldValue(
                                "trade_configuration.billing_gst",
                                //@ts-ignore
                                newValue?.gstinId
                              );
                            } else {
                              formik.setFieldValue(
                                "trade_configuration.billing_gst",
                                //@ts-ignore
                                newValue?.gstinId
                              );
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            const isExisting = options.some(
                              (option) => inputValue === option.gstinId
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                gstinId: `${inputValue}`,
                              });
                            }
                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          options={allActiveGST}
                          getOptionLabel={(option) => {
                            if (option && typeof option === "string") {
                              return option;
                            }
                            if (option && option.inputValue) {
                              return option.inputValue;
                            }
                            return option && option.gstinId;
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>{option.gstinId}</li>
                          )}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Enter Billing GST"
                              error={
                                Boolean(
                                  formik.values.trade_configuration.billing_gst
                                ) &&
                                !GSTRegex.test(
                                  formik.values.trade_configuration.billing_gst
                                )
                              }
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell width={"20%"}>
                        <TextField
                          name="trade_configuration.billing_address"
                          id="trade_configuration.billing_address"
                          placeholder="Enter Billing address"
                          variant="outlined"
                          value={
                            formik.values.trade_configuration.billing_address
                          }
                          onChange={formik.handleChange}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell width={"20%"}>
                        <TextField
                          name="trade_configuration.remarks"
                          id="trade_configuration.remarks"
                          placeholder="Enter Remarks"
                          variant="outlined"
                          value={formik.values.trade_configuration.remarks}
                          onChange={formik.handleChange}
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableWrapper>
            </Paper>
          </Grid>
          <br />
          <br />
          {/*TRADE CONFIGRATION--------------------------------------------------------------------------- */}
          <Grid item>
            <Paper>
              <Grid
                padding={"20px"}
                justifyContent="space-between"
                gap={1}
                container
              >
                <Grid item>
                  <Typography fontWeight={600} variant="h4">
                    Trade Configurations{" "}
                  </Typography>
                </Grid>
              </Grid>
              <TableWrapper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Offline Trade</TableCell>
                      {formik.values.trade_configuration.is_offline && (
                        <TableCell>Date of Disbursal</TableCell>
                      )}
                      <TableCell>EMI Start Date</TableCell>
                      <TableCell>Tenure</TableCell>
                      <TableCell>Interest rate</TableCell>
                      <TableCell>Loan Amount</TableCell>
                      <TableCell>Fees without GST</TableCell>
                      <TableCell>Mandate Trigger</TableCell>
                      <TableCell>Escrow Receivables</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>No</Grid>
                          <Grid item>
                            <AntSwitch
                              name="trade_configuration.is_offline"
                              value={
                                formik.values.trade_configuration.is_offline
                              }
                              onChange={(value: any) =>
                                formik.setFieldValue(
                                  "trade_configuration.is_offline",
                                  value?.target?.checked
                                )
                              }
                            />
                          </Grid>
                          <Grid item>Yes</Grid>
                        </Grid>
                      </TableCell>
                      {formik.values.trade_configuration.is_offline && (
                        <TableCell>
                          <SimpleDatePicker
                            name="trade_configuration.payout_on"
                            onChange={(value: any) =>
                              formik.setFieldValue(
                                "trade_configuration.payout_on",
                                value
                              )
                            }
                            value={formik.values.trade_configuration.payout_on}
                            renderInput={(
                              params: JSX.IntrinsicAttributes & TextFieldProps
                            ) => (
                              <TextField
                                {...params}
                                placeholder="Select Date of Disbursal"
                                fullWidth
                                variant="outlined"
                              />
                            )}
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        <SimpleDatePicker
                          name="trade_configuration.emi_start_date"
                          disablePast
                          onChange={(value: any) =>
                            formik.setFieldValue(
                              "trade_configuration.emi_start_date",
                              value
                            )
                          }
                          value={
                            formik.values.trade_configuration.emi_start_date
                          }
                          renderInput={(
                            params: JSX.IntrinsicAttributes & TextFieldProps
                          ) => (
                            <TextField
                              {...params}
                              placeholder="Select Scored on date"
                              fullWidth
                              variant="outlined"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          id="trade_configuration.validity"
                          name="trade_configuration.validity"
                          onChange={formik.handleChange}
                          value={formik.values.trade_configuration.validity}
                          placeholder="Enter Tenure"
                          variant="outlined"
                          type={"number"}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          name="trade_configuration.roi"
                          id="trade_configuration.roi"
                          placeholder="Enter interest rate"
                          variant="outlined"
                          type={"number"}
                          value={formik.values.trade_configuration.roi}
                          onChange={formik.handleChange}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          name="trade_configuration.payout_value"
                          id="trade_configuration.payout_value"
                          placeholder="Enter Fees"
                          type={"number"}
                          variant="outlined"
                          value={formik.values.trade_configuration.payout_value}
                          onChange={formik.handleChange}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          name="trade_configuration.fees_without_gst"
                          id="trade_configuration.fees_without_gst"
                          placeholder="Enter Fees"
                          type={"number"}
                          variant="outlined"
                          value={
                            formik.values.trade_configuration.fees_without_gst
                          }
                          onChange={formik.handleChange}
                        />
                      </TableCell>
                      <TableCell>
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>Auto</Grid>
                          <Grid item>
                            <AntSwitch
                              name="trade_configuration.debit_mode"
                              value={
                                formik.values.trade_configuration.debit_mode
                              }
                              checked={
                                formik.values.trade_configuration.debit_mode
                                  ? true
                                  : false
                              }
                              onChange={(value: any) =>
                                formik.setFieldValue(
                                  "trade_configuration.debit_mode",
                                  value?.target?.checked
                                )
                              }
                            />
                          </Grid>
                          <Grid item>Manual</Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Grid item>No</Grid>
                          <Grid item>
                            <AntSwitch
                              value={
                                formik.values.trade_configuration
                                  .collection_mode
                              }
                              onChange={(value: any) =>
                                formik.setFieldValue(
                                  "trade_configuration.collection_mode",
                                  value?.target?.checked
                                )
                              }
                              name="trade_configuration.collection_mode"
                            />
                          </Grid>
                          <Grid item>Yes</Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableWrapper>
            </Paper>
          </Grid>
          <br />
          <br />
          {/*INVESTOR DETAILS--------------------------------------------------------------------------- */}
          <Grid item>
            <Paper>
              <Grid
                padding={"20px"}
                justifyContent="space-between"
                gap={1}
                container
              >
                <Grid item>
                  <Typography fontWeight={600} variant="h4">
                    Investors Details
                  </Typography>
                </Grid>
              </Grid>
              <TableWrapper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Offered Amount</TableCell>
                      <TableCell>Invested Amount</TableCell>
                      <TableCell>Investor Fees/EMI</TableCell>
                      <TableCell>Split Ratio</TableCell>
                      <TableCell>Investor Repayment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map((row: any, i: Number) => (
                      <TableRow>
                        <TableCell width={"16.6%"}>
                          {row.name || row?.investor?.name}
                        </TableCell>
                        <TableCell width={"16.6%"}>
                          {row?.id || row?.investor?.id}
                        </TableCell>
                        <TableCell width={"16.6%"}>{row?.amount}</TableCell>
                        <TableCell width={"16.6%"}>
                          {" "}
                          <TextField
                            name="invested_amount"
                            id="invested_amount"
                            placeholder="Enter amount"
                            value={row.invested_amount}
                            onChange={(event) => handleInputChange(event, i)}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell width={"16.6%"}>
                          {" "}
                          <TextField
                            name="fees_per_emi"
                            id="fees_per_emi"
                            placeholder="Enter amount"
                            value={row.fees_per_emi}
                            onChange={(event) => handleInputChange(event, i)}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell width={"16.6%"}>
                          {" "}
                          <TextField
                            name="emi_split_ratio"
                            id="emi_split_ratio"
                            placeholder="Enter split ratio"
                            value={row.emi_split_ratio}
                            onChange={(event) => handleInputChange(event, i)}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>Auto</Grid>
                            <Grid item>
                              <AntSwitch
                                name="withdrawal_mode"
                                onChange={(event) =>
                                  handleRepaymentInputChange(event, i)
                                }
                              />
                            </Grid>
                            <Grid item>Manual</Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableWrapper>
            </Paper>
          </Grid>
          <br />
          <br />
          {/*MANDATE CONFIGRATION--------------------------------------------------------------------------- */}
          {!formik.values.trade_configuration.is_offline && (
            <MandateConfiguration
              formik={formik}
              isEditing={true}
              mandateList={mandateList}
              mandateBankList={mandateBankList}
              mandateFrequency={mandateFrequency}
            />
          )}
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default TradeConfiguration;
