import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEmaiCalendarDataQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Scheduled_Emi_Bool_Exp>;
}>;


export type GetEmaiCalendarDataQuery = { __typename?: 'query_root', scheduled_emi: Array<{ __typename?: 'scheduled_emi', id: number, status: Types.Ref_Transaction_Status_Enum, trade_installment_seq?: number | null, scheduled_at: any, total_amount?: any | null, trade: { __typename?: 'trade', emi_amount?: any | null, id: number, validity: number, collection_mode: Types.Ref_Collection_Mode_Enum, company: { __typename?: 'company', name?: string | null, id: number }, investments: Array<{ __typename?: 'investment', withdrawal_mode?: Types.Ref_Trade_Withdrawal_Mode_Enum | null, investor: { __typename?: 'investor', name: string } }> }, transactions: Array<{ __typename?: 'transaction', id: number, transaction_status: Types.Ref_Transaction_Status_Enum, transaction_type: Types.Ref_Transaction_Type_Enum }> }> };

export type GetAllTransactionsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Transactions_View_Bool_Exp>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  agg_where?: Types.InputMaybe<Types.Transactions_View_Bool_Exp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  order_by?: Types.InputMaybe<Array<Types.Transactions_View_Order_By> | Types.Transactions_View_Order_By>;
}>;


export type GetAllTransactionsQuery = { __typename?: 'query_root', transactions_view: Array<{ __typename?: 'transactions_view', id?: any | null, trade_id?: number | null, transaction_status?: string | null, transaction_type?: string | null, amount?: any | null, trade_installment_seq?: number | null, transaction_method?: string | null, timestamp?: any | null, scheduled_emi_id?: number | null, trade?: { __typename?: 'trade', company: { __typename?: 'company', id: number } } | null }>, transactions_view_aggregate: { __typename?: 'transactions_view_aggregate', aggregate?: { __typename?: 'transactions_view_aggregate_fields', count: number } | null } };

export type AddEmiTransactionMutationVariables = Types.Exact<{
  args: Types.AddEmiTransactionInput;
}>;


export type AddEmiTransactionMutation = { __typename?: 'mutation_root', addEMITransaction?: { __typename?: 'AddEMITransactionOutput', message: string } | null };

export type TransferTdstoCompanyMutationVariables = Types.Exact<{
  args: Types.TransferTdsToCompanyInput;
}>;


export type TransferTdstoCompanyMutation = { __typename?: 'mutation_root', TransferTDSToCompany?: { __typename?: 'TransferTDSToCompanyOutput', message: string } | null };

export type GetVirtualAccountBalanceMutationVariables = Types.Exact<{
  args: Types.GetVirtualAccountBalanceInput;
}>;


export type GetVirtualAccountBalanceMutation = { __typename?: 'mutation_root', GetVirtualAccountBalance?: { __typename?: 'GetVirtualAccountBalanceOutput', balance: number } | null };

export type GetAllTdsByTradeQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Tds_Bool_Exp>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetAllTdsByTradeQuery = { __typename?: 'query_root', tds: Array<{ __typename?: 'tds', amount: any, created_at: any, status: Types.Ref_Tds_Status_Enum, tds_files: any, id: number, transaction: { __typename?: 'transaction', counterparty_account: { __typename?: 'account', account_no: string, ref_bank: { __typename?: 'ref_bank', name: string } } } }> };

export type GetAllTransactionMethodsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllTransactionMethodsQuery = { __typename?: 'query_root', ref_transaction_method: Array<{ __typename?: 'ref_transaction_method', id: string, name: string }> };


export const GetEmaiCalendarDataDocument = gql`
    query getEmaiCalendarData($where: scheduled_emi_bool_exp) {
  scheduled_emi(where: $where) {
    id
    status
    trade_installment_seq
    trade {
      emi_amount
      id
      validity
      company {
        name
        id
      }
      collection_mode
      investments {
        withdrawal_mode
        investor {
          name
        }
      }
    }
    transactions {
      id
      transaction_status
      transaction_type
    }
    scheduled_at
    total_amount
  }
}
    `;

/**
 * __useGetEmaiCalendarDataQuery__
 *
 * To run a query within a React component, call `useGetEmaiCalendarDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmaiCalendarDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmaiCalendarDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetEmaiCalendarDataQuery(baseOptions?: Apollo.QueryHookOptions<GetEmaiCalendarDataQuery, GetEmaiCalendarDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmaiCalendarDataQuery, GetEmaiCalendarDataQueryVariables>(GetEmaiCalendarDataDocument, options);
      }
export function useGetEmaiCalendarDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmaiCalendarDataQuery, GetEmaiCalendarDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmaiCalendarDataQuery, GetEmaiCalendarDataQueryVariables>(GetEmaiCalendarDataDocument, options);
        }
export type GetEmaiCalendarDataQueryHookResult = ReturnType<typeof useGetEmaiCalendarDataQuery>;
export type GetEmaiCalendarDataLazyQueryHookResult = ReturnType<typeof useGetEmaiCalendarDataLazyQuery>;
export type GetEmaiCalendarDataQueryResult = Apollo.QueryResult<GetEmaiCalendarDataQuery, GetEmaiCalendarDataQueryVariables>;
export const GetAllTransactionsDocument = gql`
    query getAllTransactions($where: transactions_view_bool_exp, $offset: Int, $agg_where: transactions_view_bool_exp, $limit: Int, $order_by: [transactions_view_order_by!]) {
  transactions_view(
    where: $where
    offset: $offset
    limit: $limit
    order_by: $order_by
  ) {
    id
    trade {
      company {
        id
      }
    }
    trade_id
    transaction_status
    transaction_type
    amount
    trade_installment_seq
    transaction_method
    timestamp
    scheduled_emi_id
  }
  transactions_view_aggregate(where: $agg_where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllTransactionsQuery__
 *
 * To run a query within a React component, call `useGetAllTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTransactionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      agg_where: // value for 'agg_where'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetAllTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTransactionsQuery, GetAllTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTransactionsQuery, GetAllTransactionsQueryVariables>(GetAllTransactionsDocument, options);
      }
export function useGetAllTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTransactionsQuery, GetAllTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTransactionsQuery, GetAllTransactionsQueryVariables>(GetAllTransactionsDocument, options);
        }
export type GetAllTransactionsQueryHookResult = ReturnType<typeof useGetAllTransactionsQuery>;
export type GetAllTransactionsLazyQueryHookResult = ReturnType<typeof useGetAllTransactionsLazyQuery>;
export type GetAllTransactionsQueryResult = Apollo.QueryResult<GetAllTransactionsQuery, GetAllTransactionsQueryVariables>;
export const AddEmiTransactionDocument = gql`
    mutation AddEMITransaction($args: AddEMITransactionInput!) {
  addEMITransaction(args: $args) {
    message
  }
}
    `;
export type AddEmiTransactionMutationFn = Apollo.MutationFunction<AddEmiTransactionMutation, AddEmiTransactionMutationVariables>;

/**
 * __useAddEmiTransactionMutation__
 *
 * To run a mutation, you first call `useAddEmiTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmiTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmiTransactionMutation, { data, loading, error }] = useAddEmiTransactionMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useAddEmiTransactionMutation(baseOptions?: Apollo.MutationHookOptions<AddEmiTransactionMutation, AddEmiTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEmiTransactionMutation, AddEmiTransactionMutationVariables>(AddEmiTransactionDocument, options);
      }
export type AddEmiTransactionMutationHookResult = ReturnType<typeof useAddEmiTransactionMutation>;
export type AddEmiTransactionMutationResult = Apollo.MutationResult<AddEmiTransactionMutation>;
export type AddEmiTransactionMutationOptions = Apollo.BaseMutationOptions<AddEmiTransactionMutation, AddEmiTransactionMutationVariables>;
export const TransferTdstoCompanyDocument = gql`
    mutation transferTdstoCompany($args: TransferTDSToCompanyInput!) {
  TransferTDSToCompany(args: $args) {
    message
  }
}
    `;
export type TransferTdstoCompanyMutationFn = Apollo.MutationFunction<TransferTdstoCompanyMutation, TransferTdstoCompanyMutationVariables>;

/**
 * __useTransferTdstoCompanyMutation__
 *
 * To run a mutation, you first call `useTransferTdstoCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferTdstoCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferTdstoCompanyMutation, { data, loading, error }] = useTransferTdstoCompanyMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useTransferTdstoCompanyMutation(baseOptions?: Apollo.MutationHookOptions<TransferTdstoCompanyMutation, TransferTdstoCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferTdstoCompanyMutation, TransferTdstoCompanyMutationVariables>(TransferTdstoCompanyDocument, options);
      }
export type TransferTdstoCompanyMutationHookResult = ReturnType<typeof useTransferTdstoCompanyMutation>;
export type TransferTdstoCompanyMutationResult = Apollo.MutationResult<TransferTdstoCompanyMutation>;
export type TransferTdstoCompanyMutationOptions = Apollo.BaseMutationOptions<TransferTdstoCompanyMutation, TransferTdstoCompanyMutationVariables>;
export const GetVirtualAccountBalanceDocument = gql`
    mutation GetVirtualAccountBalance($args: GetVirtualAccountBalanceInput!) {
  GetVirtualAccountBalance(args: $args) {
    balance
  }
}
    `;
export type GetVirtualAccountBalanceMutationFn = Apollo.MutationFunction<GetVirtualAccountBalanceMutation, GetVirtualAccountBalanceMutationVariables>;

/**
 * __useGetVirtualAccountBalanceMutation__
 *
 * To run a mutation, you first call `useGetVirtualAccountBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetVirtualAccountBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getVirtualAccountBalanceMutation, { data, loading, error }] = useGetVirtualAccountBalanceMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGetVirtualAccountBalanceMutation(baseOptions?: Apollo.MutationHookOptions<GetVirtualAccountBalanceMutation, GetVirtualAccountBalanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetVirtualAccountBalanceMutation, GetVirtualAccountBalanceMutationVariables>(GetVirtualAccountBalanceDocument, options);
      }
export type GetVirtualAccountBalanceMutationHookResult = ReturnType<typeof useGetVirtualAccountBalanceMutation>;
export type GetVirtualAccountBalanceMutationResult = Apollo.MutationResult<GetVirtualAccountBalanceMutation>;
export type GetVirtualAccountBalanceMutationOptions = Apollo.BaseMutationOptions<GetVirtualAccountBalanceMutation, GetVirtualAccountBalanceMutationVariables>;
export const GetAllTdsByTradeDocument = gql`
    query getAllTDSByTrade($where: tds_bool_exp, $offset: Int, $limit: Int) {
  tds(where: $where, limit: $limit, offset: $offset) {
    amount
    created_at
    status
    tds_files
    id
    transaction {
      counterparty_account {
        ref_bank {
          name
        }
        account_no
      }
    }
  }
}
    `;

/**
 * __useGetAllTdsByTradeQuery__
 *
 * To run a query within a React component, call `useGetAllTdsByTradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTdsByTradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTdsByTradeQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllTdsByTradeQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTdsByTradeQuery, GetAllTdsByTradeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTdsByTradeQuery, GetAllTdsByTradeQueryVariables>(GetAllTdsByTradeDocument, options);
      }
export function useGetAllTdsByTradeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTdsByTradeQuery, GetAllTdsByTradeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTdsByTradeQuery, GetAllTdsByTradeQueryVariables>(GetAllTdsByTradeDocument, options);
        }
export type GetAllTdsByTradeQueryHookResult = ReturnType<typeof useGetAllTdsByTradeQuery>;
export type GetAllTdsByTradeLazyQueryHookResult = ReturnType<typeof useGetAllTdsByTradeLazyQuery>;
export type GetAllTdsByTradeQueryResult = Apollo.QueryResult<GetAllTdsByTradeQuery, GetAllTdsByTradeQueryVariables>;
export const GetAllTransactionMethodsDocument = gql`
    query getAllTransactionMethods {
  ref_transaction_method {
    id
    name
  }
}
    `;

/**
 * __useGetAllTransactionMethodsQuery__
 *
 * To run a query within a React component, call `useGetAllTransactionMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTransactionMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTransactionMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTransactionMethodsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTransactionMethodsQuery, GetAllTransactionMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTransactionMethodsQuery, GetAllTransactionMethodsQueryVariables>(GetAllTransactionMethodsDocument, options);
      }
export function useGetAllTransactionMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTransactionMethodsQuery, GetAllTransactionMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTransactionMethodsQuery, GetAllTransactionMethodsQueryVariables>(GetAllTransactionMethodsDocument, options);
        }
export type GetAllTransactionMethodsQueryHookResult = ReturnType<typeof useGetAllTransactionMethodsQuery>;
export type GetAllTransactionMethodsLazyQueryHookResult = ReturnType<typeof useGetAllTransactionMethodsLazyQuery>;
export type GetAllTransactionMethodsQueryResult = Apollo.QueryResult<GetAllTransactionMethodsQuery, GetAllTransactionMethodsQueryVariables>;
export const namedOperations = {
  Query: {
    getEmaiCalendarData: 'getEmaiCalendarData',
    getAllTransactions: 'getAllTransactions',
    getAllTDSByTrade: 'getAllTDSByTrade',
    getAllTransactionMethods: 'getAllTransactionMethods'
  },
  Mutation: {
    AddEMITransaction: 'AddEMITransaction',
    transferTdstoCompany: 'transferTdstoCompany',
    GetVirtualAccountBalance: 'GetVirtualAccountBalance'
  }
}