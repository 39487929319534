import {
  Button,
  Grid,
  IconButton,
  Typography,
  Divider,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputAdornment,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import { useContext, useState } from "react";
import ScoreForm from "./ScoreForm";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import SimpleInput from "../../../components/input/SimpleInput";
import {
  useAddNewCompanyScoreTimeSeriesMutation,
  useGetScoreFactorQuery,
} from "../../../data-access/hooks/company/score.hooks";
import { useParams } from "react-router-dom";
import { CompanyContext } from "../../../contexts/CompanyContext";
import SideModal from "../../../components/Modals/SideModal";

interface Props {
  open: boolean;
  setStepNumber: any;
  handleDialogClose: () => void;
  scoreFactorRefetch: () => void;
  stepNumber: number;
  scoreData: any;
  createdScoreId: any;
  setCreatedScoreId: any;
  setScoreData: any;
  editType?: any;
  setEditType?: any;
}

const AddScoreModal = (props: Props) => {
  const {
    open,
    handleDialogClose,
    scoreFactorRefetch,
    setStepNumber,
    stepNumber,
    scoreData,
    createdScoreId,
    setCreatedScoreId,
    setScoreData,
    editType,
    setEditType,
  } = props;

  const { id: company_id } = useParams();

  let arrayHelpersRef: any;

  let arraylength = 0;

  const [expanded, setExpanded] = useState<number>(0);

  const { setShowAlert } = useContext(CompanyContext);

  const [scoringFactors, setScoringFactors] = useState<any>();

  /*************************************************************************************************************/
  const addNewTradingFactorForm = () => {
    arrayHelpersRef.push({
      time_series: "",
      sequence: "",
      1: "",
      2: "",
      3: "",
      4: "",
      5: "",
      6: "",
      7: "",
      8: "",
      9: "",
      10: "",
      11: "",
      12: "",
    });
    setExpanded(arraylength);
  };

  /*************************************************************************************************************/
  const removeTradingFactorForm = (removeIndex: number) => {
    arrayHelpersRef.remove(removeIndex);
    setExpanded(removeIndex - 1);
  };

  /*************************************************************************************************************/
  const { loading: scoreFactorLoading } = useGetScoreFactorQuery({
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const x = data.score_factor.map((item) => {
        return {
          id: item.id,
          label: item.ref_score_factor.name,
          name: item.ref_score_factor.id,
          unit: item.unit,
        };
      });
      setScoringFactors(x);
    },
    onError: (item) => {
      setShowAlert({
        show: true,
        message: item?.message,
        type: "error",
      });
    },
  });

  /*************************************************************************************************************/
  const [handleAddNewTradeFactor, { loading: addFactorsLoading }] =
    useAddNewCompanyScoreTimeSeriesMutation({
      onCompleted: (item) => {
        scoreFactorRefetch();
        handleDialogClose();
      },
      onError: (error) => {
        setShowAlert({
          show: true,
          message: error.message,
          type: "error",
        });
      },
    });

  return (
    <>
      {stepNumber === 0 ? (
        <ScoreForm
          onSubmitSuccess={(id) => {
            setStepNumber(id);
          }}
          scoreData={scoreData}
          scoreFactorRefetch={scoreFactorRefetch}
          setCreatedScoreId={setCreatedScoreId}
          setScoreData={setScoreData}
          editType={editType}
          open={open}
          handleDialogClose={handleDialogClose}
        />
      ) : (
        <SideModal
          open={open}
          onClose={() => handleDialogClose()}
          footer={
            <Grid
              item
              xs={12}
              display="flex"
              alignItems={"center"}
              justifyContent="center"
              gap="10px"
            >
              <Button
                fullWidth
                variant="secondarySmall"
                onClick={() => {
                  setEditType(false);
                  setStepNumber(0);
                }}
              >
                Back
              </Button>
              <Button
                fullWidth
                disabled={addFactorsLoading}
                variant="primarySmall"
                type="submit"
                form="add-trade-factors-form"
              >
                {addFactorsLoading ? <CircularProgress size={20} /> : "Submit"}
              </Button>
            </Grid>
          }
          modalTitle={
            <Grid
              container
              display="flex"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                fontWeight={700}
                fontSize={20}
                lineHeight={1}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "2px",
                }}
              >
                Add Trade Factor
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#AAA5B7",
                    letterSpacing: "0.6px",
                  }}
                >
                  Step 2 of 2 : Trading Factors
                </span>
              </Typography>
              <Button
                variant="secondarySmall"
                onClick={addNewTradingFactorForm}
              >
                Add Time Series
              </Button>
            </Grid>
          }
        >
          <Formik
            initialValues={{
              factors: [
                {
                  time_series: "",
                  sequence: "",
                  1: "",
                  2: "",
                  3: "",
                  4: "",
                  5: "",
                  6: "",
                  7: "",
                  8: "",
                  9: "",
                  10: "",
                  11: "",
                  12: "",
                },
              ],
            }}
            onSubmit={(values) => {
              const data = values.factors.map((factorValue: any) => {
                return {
                  time_series: factorValue.time_series,
                  sequence: Number(factorValue.sequence),
                  company_id: Number(company_id),
                  company_score_id: scoreData?.score_id
                    ? Number(scoreData?.score_id)
                    : Number(createdScoreId),
                  company_score_factors: {
                    data: scoringFactors.map((item: any) => {
                      return {
                        score_factor_id: item.id,
                        company_id: Number(company_id),
                        company_score_id: scoreData?.score_id
                          ? Number(scoreData?.score_id)
                          : Number(createdScoreId),
                        //@ts-ignore
                        value: Number(factorValue[`${item.id}`]),
                      };
                    }),
                  },
                };
              });
              handleAddNewTradeFactor({
                variables: {
                  //@ts-ignore
                  objects: data,
                },
              });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form id="add-trade-factors-form" onSubmit={handleSubmit}>
                <FieldArray
                  name="factors"
                  render={(arrayHelpers) => {
                    arrayHelpersRef = arrayHelpers;
                    const factors = values.factors;
                    console.log(
                      "%c [ factors ]-295",
                      "font-size:13px; background:pink; color:#bf2c9f;",
                      factors
                    );
                    arraylength = factors.length;
                    return (
                      factors &&
                      factors.length > 0 &&
                      factors.map((item: any, index: any) => (
                        <Accordion
                          expanded={expanded === index}
                          onChange={() =>
                            setExpanded(expanded === index ? -1 : index)
                          }
                          key={index}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={"panel1a-content_" + index}
                            id={"panel1a-header" + index}
                          >
                            <Box
                              display={"flex"}
                              flex={1}
                              justifyContent="space-between"
                            >
                              <Typography fontWeight={600} variant="body2">
                                Time Series: {item.time_series}
                              </Typography>

                              {factors.length > 1 && (
                                <IconButton
                                  color="primary"
                                  onClick={() => removeTradingFactorForm(index)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              )}
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            {scoreFactorLoading ? (
                              <Grid container spacing={5}>
                                <Grid item xs={12}>
                                  <Skeleton />
                                </Grid>
                                <Grid item xs={12}>
                                  <Skeleton />
                                </Grid>{" "}
                                <Grid item xs={12}>
                                  <Skeleton />
                                </Grid>{" "}
                                <Grid item xs={12}>
                                  <Skeleton />
                                </Grid>{" "}
                                <Grid item xs={12}>
                                  <Skeleton />
                                </Grid>{" "}
                                <Grid item xs={12}>
                                  <Skeleton />
                                </Grid>{" "}
                                <Grid item xs={12}>
                                  <Skeleton />
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth variant="standard">
                                    <SimpleInput
                                      fullWidth
                                      requiredlabel
                                      id="time_series"
                                      name={`factors.${index}.time_series`}
                                      placeholder="Example: FY 2022"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      inputlabel="Time Series"
                                      value={values.factors[index].time_series}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth variant="standard">
                                    <SimpleInput
                                      id="sequence"
                                      fullWidth
                                      name={`factors.${index}.sequence`}
                                      placeholder="Enter The Sequence"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      inputlabel="Sequence"
                                      value={values.factors[index].sequence}
                                      type={"number"}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  <Divider
                                    variant="fullWidth"
                                    orientation="horizontal"
                                  ></Divider>
                                </Grid>
                                {!scoreFactorLoading &&
                                  scoringFactors?.map(
                                    (item: {
                                      id: number;
                                      label: string;
                                      unit: string;
                                      name: string;
                                    }) => (
                                      <Grid item xs={12} sm={6}>
                                        <FormControl
                                          fullWidth
                                          variant="standard"
                                        >
                                          <SimpleInput
                                            id={item.id}
                                            fullWidth
                                            name={`factors.${index}.${item.id}`}
                                            placeholder={`Enter the ${item.label}`}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            inputlabel={item.label}
                                            value={
                                              //@ts-ignore
                                              values.factors[index][
                                                `${item.id}`
                                              ]
                                            }
                                            InputProps={{
                                              endAdornment:
                                                (item.unit === "PERCENTAGE" && (
                                                  <InputAdornment position="end">
                                                    <Typography
                                                      color={"#000"}
                                                      fontSize="14px"
                                                      fontWeight={500}
                                                    >
                                                      %
                                                    </Typography>
                                                  </InputAdornment>
                                                )) ||
                                                ((item?.name ===
                                                  "CAPITAL_EFFICIENCY" ||
                                                  item?.name === "LTV_TO_CAC" ||
                                                  item?.name ===
                                                    "DEBT_TO_EQUITY_RATIO") && (
                                                  <InputAdornment position="end">
                                                    <Typography fontSize={30}>
                                                      ÷
                                                    </Typography>
                                                  </InputAdornment>
                                                )) ||
                                                (item.unit === "AMOUNT" && (
                                                  <InputAdornment position="end">
                                                    <Typography
                                                      color={"#000"}
                                                      fontSize="14px"
                                                      fontWeight={500}
                                                    >
                                                      ₹
                                                    </Typography>
                                                  </InputAdornment>
                                                )),
                                            }}
                                            type={"number"}
                                          />
                                        </FormControl>
                                      </Grid>
                                    )
                                  )}
                              </Grid>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      ))
                    );
                  }}
                />
              </Form>
            )}
          </Formik>
        </SideModal>
      )}
    </>
  );
};

export default AddScoreModal;
