import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useFormik } from "formik";
import SimpleInput from "../../../components/input/SimpleInput";
import SimpleDatePicker from "../../../components/input/SimpleDatePicker";
import SimpleFileInput from "../../../components/input/SimpleFileInput";
import { useContext, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import {
  useAddNewCompanyScoreMutation,
  useUpdateCompanyScoreMutation,
} from "../../../data-access/hooks/company/score.hooks";
import { getChangedValues } from "../../../utils/common";
import { CompanyContext } from "../../../contexts/CompanyContext";
import SideModal from "../../../components/Modals/SideModal";

interface ScoreFormProps {
  onSubmitSuccess: (id: number) => void;
  scoreFactorRefetch: () => void;
  setCreatedScoreId: any;
  editType: any;
  scoreData: any;
  handleDialogClose: any;
  setScoreData: any;
  open: boolean;
}

const ScoreForm = (props: ScoreFormProps) => {
  const {
    onSubmitSuccess,
    scoreFactorRefetch,
    scoreData,
    setCreatedScoreId,
    setScoreData,
    editType,
    handleDialogClose,
    open,
  } = props;

  const [uploadLoading, setUploadLoading] = useState(false);

  const { setShowAlert } = useContext(CompanyContext);

  const [secureResponse, setSecureResponse] = useState({
    response: "",
    selectedFile: {} as any,
  });

  const { id } = useParams();

  const uploadFile = async (selectedFile: any) => {
    const formData = new FormData();
    formData?.append("file", selectedFile, selectedFile?.name);
    formData?.append("category_id", "SCORING_ENGINE_REPORT");
    formData?.append("password", "");
    const token = localStorage.getItem("accessToken");
    const config: any = {
      method: "post",
      url: `${process.env.REACT_APP_ACTIONS_BASE_URL}secure-upload`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        "X-hasura-tenant-id": id,
      },
    };
    await axios(config)
      .then((response) => {
        setShowAlert({
          show: true,
          message: "The file is successfully uploaded",
          type: "success",
        });
        setSecureResponse({
          response: response?.data?.file_registry_id,
          selectedFile,
        });
        setUploadLoading(false);
      })
      .catch((error) => {
        setShowAlert({
          show: true,
          message: error?.response?.data?.message,
          type: "error",
        });
        setUploadLoading(false);
      });
  };

  const [handleAddNewScore, { loading: addScoreLoading }] =
    useAddNewCompanyScoreMutation({
      onCompleted: (item) => {
        scoreFactorRefetch();
        setScoreData({
          score_id: item.insert_company_score_one?.id,
          arr: item.insert_company_score_one?.arr,
          facility_limit: item.insert_company_score_one?.facility_limit,
          rating: item.insert_company_score_one?.rating,
          min_offer_amount: item.insert_company_score_one?.min_bid_price,
          financials_score: item.insert_company_score_one?.financials_score,
          business_growth_score:
            item.insert_company_score_one?.business_growth_score,
          capital_efficiency_score:
            item.insert_company_score_one?.capital_efficiency_score,
          customer_value_score:
            item.insert_company_score_one?.customer_value_score,
          management_score: item.insert_company_score_one?.management_score,
          max_recommended_bid:
            item.insert_company_score_one?.max_recommended_bid,
          min_recommended_bid:
            item.insert_company_score_one?.min_recommended_bid,
          scored_on: item.insert_company_score_one?.scored_on,
          report_file_id: item.insert_company_score_one?.report_file_id,
        });
        setCreatedScoreId(item.insert_company_score_one?.id);
        setShowAlert({
          show: true,
          message: `Score added successfully with ID:${item.insert_company_score_one?.id}`,
          type: "success",
        });
        onSubmitSuccess(1);
      },
      onError: (item) => {
        setShowAlert({
          show: true,
          message: item.message,
          type: "error",
        });
      },
    });

  const [handleUpdateScore] = useUpdateCompanyScoreMutation({
    onCompleted: (item) => {
      scoreFactorRefetch();
      setScoreData({
        score_id: item.update_company_score_by_pk?.id,
        arr: item.update_company_score_by_pk?.arr,
        facility_limit: item.update_company_score_by_pk?.facility_limit,
        rating: item.update_company_score_by_pk?.rating,
        min_offer_amount: item.update_company_score_by_pk?.min_bid_price,
        financials_score: item.update_company_score_by_pk?.financials_score,
        business_growth_score:
          item.update_company_score_by_pk?.business_growth_score,
        capital_efficiency_score:
          item.update_company_score_by_pk?.capital_efficiency_score,
        customer_value_score:
          item.update_company_score_by_pk?.customer_value_score,
        management_score: item.update_company_score_by_pk?.management_score,
        max_recommended_bid:
          item.update_company_score_by_pk?.max_recommended_bid,
        min_recommended_bid:
          item.update_company_score_by_pk?.min_recommended_bid,
        scored_on: item.update_company_score_by_pk?.scored_on,
        report_file_id: item.update_company_score_by_pk?.report_file_id,
      });
      setShowAlert({
        show: true,
        message: `Score updated successfully`,
        type: "success",
      });
      if (!editType) {
        onSubmitSuccess(1);
      } else {
        handleDialogClose();
      }
    },
    onError: (item) => {
      setShowAlert({
        show: true,
        message: item.message,
        type: "error",
      });
    },
  });

  const validationSchema = yup.object().shape({
    arr: yup.string().required("ARR is required"),
    facility_limit: yup
      .number()
      .required("Facility Limit is required")
      .min(1, "Should be more than 1 and less than 100")
      .max(100, "Should be more than 1 and less than 100"),
    rating: yup
      .number()
      .min(1, "Should be more than 1 and less than 10")
      .max(10, "Should be more than 1 and less than 10"),
    min_bid_price: yup
      .string()
      .required("Minimum Offer/Bid value is reqiured."),
    financials_score: yup
      .number()
      .min(1, "Should be more than 1 and less than 10")
      .max(10, "Should be more than 1 and less than 10"),
    business_growth_score: yup
      .number()
      .min(1, "Should be more than 1 and less than 10")
      .max(10, "Should be more than 1 and less than 10"),
    capital_efficiency_score: yup
      .number()
      .min(1, "Should be more than 1 and less than 10")
      .max(10, "Should be more than 1 and less than 10"),
    customer_value_score: yup
      .number()
      .min(1, "Should be more than 1 and less than 10")
      .max(10, "Should be more than 1 and less than 10"),
    management_score: yup
      .number()
      .min(1, "Should be more than 1 and less than 10")
      .max(10, "Should be more than 1 and less than 10"),
    max_recommended_bid: yup
      .number()
      .min(0.1, "Should be more than 0 and less than 1.")
      .max(1, "Should be more than 0 and less than 1."),
    min_recommended_bid: yup
      .number()
      .min(0.1, "Should be more than 0 and less than 1.")
      .max(1, "Should be more than 0 and less than 1."),
    scored_on: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      arr: scoreData?.score_id ? scoreData.arr : "",
      facility_limit: scoreData?.score_id ? scoreData.facility_limit : "",
      rating: scoreData?.score_id ? scoreData.rating : "",
      min_bid_price: scoreData?.score_id ? scoreData.min_offer_amount : "",
      financials_score: scoreData?.score_id ? scoreData.financials_score : "",
      business_growth_score: scoreData?.score_id
        ? scoreData.business_growth_score
        : "",
      capital_efficiency_score: scoreData?.score_id
        ? scoreData.capital_efficiency_score
        : "",
      customer_value_score: scoreData?.score_id
        ? scoreData.customer_value_score
        : "",
      management_score: scoreData?.score_id ? scoreData.management_score : "",
      max_recommended_bid: scoreData?.score_id
        ? scoreData.max_recommended_bid
        : "",
      min_recommended_bid: scoreData?.score_id
        ? scoreData.min_recommended_bid
        : "",
      scored_on: scoreData?.score_id ? scoreData.scored_on : new Date(),
      report_file_id: scoreData?.score_id
        ? scoreData.report_file_id
        : secureResponse.response !== ""
        ? secureResponse.response
        : undefined,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = getChangedValues(values, {
        arr: scoreData?.score_id ? scoreData.arr : "",
        facility_limit: scoreData?.score_id ? scoreData.facility_limit : "",
        rating: scoreData?.score_id ? scoreData.rating : "",
        min_bid_price: scoreData?.score_id ? scoreData.min_offer_amount : "",
        financials_score: scoreData?.score_id ? scoreData.financials_score : "",
        business_growth_score: scoreData?.score_id
          ? scoreData.business_growth_score
          : "",
        capital_efficiency_score: scoreData?.score_id
          ? scoreData.capital_efficiency_score
          : "",
        customer_value_score: scoreData?.score_id
          ? scoreData.customer_value_score
          : "",
        management_score: scoreData?.score_id ? scoreData.management_score : "",
        max_recommended_bid: scoreData?.score_id
          ? scoreData.max_recommended_bid
          : "",
        min_recommended_bid: scoreData?.score_id
          ? scoreData.min_recommended_bid
          : "",
        scored_on: scoreData?.score_id ? scoreData.scored_on : new Date(),
        report_file_id: scoreData?.score_id
          ? scoreData.report_file_id
          : secureResponse.response !== ""
          ? secureResponse.response
          : undefined,
      });

      if (scoreData && scoreData.score_id) {
        handleUpdateScore({
          variables: {
            score_id: scoreData.score_id,
            set: data,
          },
        });
      } else {
        handleAddNewScore({
          variables: {
            object: {
              company_id: Number(id),
              ...data,
            },
          },
        });
      }
    },
  });

  return (
    <SideModal
      open={open}
      onClose={() => handleDialogClose()}
      modalTitle={scoreData?.score_id ? "Update Score Data" : "Add New Score"}
      footer={
        <Button
          type="submit"
          variant="contained"
          size="medium"
          fullWidth
          disabled={
            uploadLoading ||
            !formik.values.arr ||
            !formik.values.facility_limit ||
            !formik.values.min_bid_price
          }
          form="add-score-form"
        >
          {addScoreLoading ? (
            <CircularProgress size={20} />
          ) : scoreData?.score_id ? (
            "Update"
          ) : (
            "Next"
          )}
        </Button>
      }
      modalSubTitle="Step 1 of 2 :Score and parameters"
    >
      <form
        id="add-score-form"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
      >
        <Grid container spacing={5} sx={{ mt: 0 }}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <SimpleInput
                helperText={
                  formik.touched.arr &&
                  Boolean(formik.errors.arr) &&
                  formik.errors.arr
                }
                error={formik.touched.arr && Boolean(formik.errors.arr)}
                fullWidth
                requiredlabel
                inputlabel="ARR"
                id="arr"
                name="arr"
                value={formik.values.arr}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter the ARR"
                type="number"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <SimpleInput
                helperText={
                  formik.touched.facility_limit &&
                  Boolean(formik.errors.facility_limit) &&
                  formik.errors.facility_limit
                }
                error={
                  formik.touched.facility_limit &&
                  Boolean(formik.errors.facility_limit)
                }
                fullWidth
                requiredlabel
                inputlabel="  Facility Limit"
                id="facility_limit"
                name="facility_limit"
                value={formik.values.facility_limit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                placeholder="Enter the Facility Limit"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <SimpleInput
                helperText={
                  formik.touched.rating &&
                  Boolean(formik.errors.rating) &&
                  formik.errors.rating
                }
                error={formik.touched.rating && Boolean(formik.errors.rating)}
                fullWidth
                requiredlabel
                inputlabel="Bridge up score"
                id="rating"
                name="rating"
                value={formik.values.rating}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                placeholder="Enter the Bridge up score"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <SimpleInput
                helperText={
                  formik.touched.min_bid_price &&
                  Boolean(formik.errors.min_bid_price) &&
                  formik.errors.min_bid_price
                }
                error={
                  formik.touched.min_bid_price &&
                  Boolean(formik.errors.min_bid_price)
                }
                fullWidth
                requiredlabel
                inputlabel="Min Offer Value"
                id="min_bid_price"
                name="min_bid_price"
                value={formik.values.min_bid_price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                placeholder="Enter Min Offer Value"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <SimpleInput
                helperText={
                  formik.touched.financials_score &&
                  Boolean(formik.errors.financials_score) &&
                  formik.errors.financials_score
                }
                error={
                  formik.touched.financials_score &&
                  Boolean(formik.errors.financials_score)
                }
                fullWidth
                inputlabel="Financial Score"
                id="financials_score"
                name="financials_score"
                value={formik.values.financials_score}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                placeholder="Enter Financial Score"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <SimpleInput
                helperText={
                  formik.touched.business_growth_score &&
                  Boolean(formik.errors.business_growth_score) &&
                  formik.errors.business_growth_score
                }
                error={
                  formik.touched.business_growth_score &&
                  Boolean(formik.errors.business_growth_score)
                }
                fullWidth
                inputlabel="Business Growth Score"
                id="business_growth_score"
                name="business_growth_score"
                value={formik.values.business_growth_score}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                placeholder="Enter Business Growth Score"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <SimpleInput
                helperText={
                  formik.touched.capital_efficiency_score &&
                  Boolean(formik.errors.capital_efficiency_score) &&
                  formik.errors.capital_efficiency_score
                }
                error={
                  formik.touched.capital_efficiency_score &&
                  Boolean(formik.errors.capital_efficiency_score)
                }
                fullWidth
                inputlabel="Capital Efficiency Score"
                id="capital_efficiency_score"
                name="capital_efficiency_score"
                value={formik.values.capital_efficiency_score}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                placeholder="Enter Capital Efficiency Score"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <SimpleInput
                helperText={
                  formik.touched.customer_value_score &&
                  Boolean(formik.errors.customer_value_score) &&
                  formik.errors.customer_value_score
                }
                error={
                  formik.touched.customer_value_score &&
                  Boolean(formik.errors.customer_value_score)
                }
                fullWidth
                inputlabel="Customer Value Score"
                id="customer_value_score"
                name="customer_value_score"
                value={formik.values.customer_value_score}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                placeholder="Enter Customer Value Score"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <SimpleInput
                helperText={
                  formik.touched.management_score &&
                  Boolean(formik.errors.management_score) &&
                  formik.errors.management_score
                }
                error={
                  formik.touched.management_score &&
                  Boolean(formik.errors.management_score)
                }
                fullWidth
                inputlabel="Management Score"
                id="management_score"
                name="management_score"
                value={formik.values.management_score}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                placeholder="Enter Management Score"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <SimpleInput
                helperText={
                  formik.touched.max_recommended_bid &&
                  Boolean(formik.errors.max_recommended_bid) &&
                  formik.errors.max_recommended_bid
                }
                error={
                  formik.touched.max_recommended_bid &&
                  Boolean(formik.errors.max_recommended_bid)
                }
                fullWidth
                inputlabel="Max Recommended Offer/Bid"
                id="max_recommended_bid"
                name="max_recommended_bid"
                value={formik.values.max_recommended_bid}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                placeholder="Enter Max Recommended Offer/Bid"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <SimpleInput
                helperText={
                  formik.touched.min_recommended_bid &&
                  Boolean(formik.errors.min_recommended_bid) &&
                  formik.errors.min_recommended_bid
                }
                error={
                  formik.touched.min_recommended_bid &&
                  Boolean(formik.errors.min_recommended_bid)
                }
                fullWidth
                inputlabel="Min Recommended Offer/Bid"
                id="min_recommended_bid"
                name="min_recommended_bid"
                value={formik.values.min_recommended_bid}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                placeholder="Enter Min Recommended Offer/Bid"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <SimpleDatePicker
                disableFuture
                inputlabel="Scored on"
                value={formik.values.scored_on}
                name="scored_on"
                onChange={(value: any) => {
                  formik.setFieldValue("scored_on", value, true);
                }}
                type="date"
                inputFormat="DD/MM/YYYY"
                renderInput={(
                  params: JSX.IntrinsicAttributes & TextFieldProps
                ) => (
                  <TextField
                    {...params}
                    placeholder="Select Scored on date"
                    fullWidth
                    value={formik.values.scored_on}
                    variant="outlined"
                  />
                )}
                helperText={
                  formik.touched.scored_on &&
                  Boolean(formik.errors.scored_on) &&
                  formik.errors.scored_on
                }
                error={
                  formik.touched.scored_on && Boolean(formik.errors.scored_on)
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SimpleFileInput
              loading={uploadLoading}
              type="file"
              name="cam_report"
              inputlabel="Upload final CAM Report"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUploadLoading(true);
                uploadFile(event?.target?.files && event?.target?.files[0]);
              }}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <SimpleInput
                helperText={
                  formik.touched.report_file_id &&
                  Boolean(formik.errors.report_file_id) &&
                  formik.errors.report_file_id
                }
                error={
                  formik.touched.report_file_id &&
                  Boolean(formik.errors.report_file_id)
                }
                fullWidth
                disabled
                inputlabel="  Report Id"
                id="report_file_id"
                name="report_file_id"
                value={
                  secureResponse.response !== ""
                    ? secureResponse.response
                    : undefined
                }
                defaultValue={
                  scoreData?.score_id
                    ? scoreData.report_file_id
                    : secureResponse.response !== ""
                    ? secureResponse.response
                    : undefined
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter Report Id"
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </SideModal>
  );
};

export default ScoreForm;
