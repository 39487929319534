import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import SimpleInput from "../../../components/input/SimpleInput";
import { ReactComponent as XIcon } from "../../../assets/Icon/Outline/x-icon.svg";
import * as Yup from "yup";
import { useVerifyPanLazyQuery } from "../../../data-access/hooks/company/company-details.hooks";

const CompanyPan = ({
  setOpen,
  getCompanyRefetch,
  companyDetailsData,
}: any) => {
  const companyPanRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  const formik = useFormik({
    initialValues: {
      pan: companyDetailsData ? companyDetailsData?.pan : "",
    },
    onSubmit: (items) => {
      handleVerifyPan({
        variables: {
          args: {
            pan: items.pan,
          },
        },
      });
    },
    validationSchema: Yup.object().shape({
      pan: Yup.string()
        .required("Pan is required")
        .matches(companyPanRegex, "Please enter a valid PAN number"),
    }),
  });

  const [handleVerifyPan, { loading, error }] = useVerifyPanLazyQuery({
    onCompleted: () => {
      setOpen(false);
      getCompanyRefetch();
    },
    onError: (item) => console.log("item.message", item.message),
  });

  return (
    <Grid
      container
      width={"20%"}
      style={{ background: "white" }}
      justifyContent="center"
      alignItems={"center"}
      display="flex"
      flexDirection={"column"}
    >
      <Grid
        container
        item
        display={"flex"}
        flexDirection="column"
        gap="10px"
        p={5}
      >
        <Grid
          item
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography
            fontSize={18}
            fontWeight={600}
            style={{ display: "flex", flexDirection: "column" }}
          >
            Company Pan
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <XIcon color="#666666" height="16px" width="16px" />
          </IconButton>
        </Grid>
        <Divider orientation="horizontal" variant="fullWidth" />
        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {((formik.touched.pan && Boolean(formik.errors.pan)) ||
            Boolean(error?.message)) && (
            <Alert severity="error">
              {error?.message || formik.errors.pan}
            </Alert>
          )}
          <Grid item my={2}>
            <FormControl fullWidth>
              <SimpleInput
                name="pan"
                id="pan"
                inputlabel="Enter Pan"
                onChange={formik.handleChange}
                value={formik.values.pan}
                error={formik.touched.pan && Boolean(formik.errors.pan)}
              />
            </FormControl>
          </Grid>

          <Grid item>
            <Button
              type="submit"
              fullWidth
              variant="primarySmall"
              disabled={loading}
            >
              {loading ? <CircularProgress size={20} /> : "Update"}
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default CompanyPan;
