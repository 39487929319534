import {
  Button,
  Divider,
  FormControl,
  Grid,
  Typography,
  IconButton,
  MenuItem,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import { useParams } from "react-router";
import SimpleInput from "../../../components/input/SimpleInput";
import SimpleSelect from "../../../components/input/SimpleSelect";
import { ReactComponent as XIcon } from "../../../assets/Icon/Outline/x-icon.svg";
import { useSendSignatoryAgreementMutation } from "../../../data-access/hooks/company/company-details.hooks";
import * as Yup from "yup";

interface SelectSignatoryModalInterface {
  setOpen?: any;
  allUsers?: any[];
  setOpenAddSignatoryModal?: any;
}

const SelectSignatory = (props: SelectSignatoryModalInterface) => {
  const { setOpen, allUsers, setOpenAddSignatoryModal } = props;

  const { id: company_id } = useParams();

  /************************************************************************************************************************************* */
  const validationSchema = Yup.object().shape({
    select_signatory: Yup.string().required("Add a signatory to proceed."),
  });
  /************************************************************************************************************************************* */
  const [handleSendAgreement, { loading: sendLoading, error: apiErrors }] =
    useSendSignatoryAgreementMutation({
      onCompleted: () => {
        setOpen(false);
      },
      onError: (item) => console.error("item", item.message),
    });

  /************************************************************************************************************************************* */
  const formik = useFormik({
    initialValues: {
      select_signatory: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSendAgreement({
        variables: {
          company_id: Number(company_id),
          authorized_signatory_id: Number(values.select_signatory),
        },
      });
    },
  });

  return (
    <Grid
      container
      width={"20%"}
      style={{ background: "white" }}
      justifyContent="center"
      alignItems={"center"}
      display="flex"
      flexDirection={"column"}
    >
      <Grid
        container
        item
        display={"flex"}
        flexDirection="column"
        gap="10px"
        p={5}
      >
        <Grid
          item
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography
            fontSize={18}
            fontWeight={600}
            style={{ display: "flex", flexDirection: "column" }}
          >
            Select Signatory
            <span
              style={{ fontSize: "12px", fontWeight: "600", color: "#b8b8b8" }}
            >
              The agreement will be sent to selected signatory
            </span>
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <XIcon color="#666666" height="16px" width="16px" />
          </IconButton>
        </Grid>
        <Divider orientation="horizontal" variant="fullWidth" />

        {((formik.touched.select_signatory &&
          Boolean(formik.errors.select_signatory)) ||
          Boolean(apiErrors?.message)) && (
          <Alert severity="error">
            {formik.errors.select_signatory || apiErrors?.message}
          </Alert>
        )}

        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Grid item>
            <FormControl fullWidth>
              <SimpleSelect
                formlabel={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>Select Signatory</span>
                    <Button
                      onClick={() => {
                        setOpenAddSignatoryModal(true);
                        setOpen(false);
                      }}
                      style={{ textDecoration: "underline" }}
                    >
                      Add Signatory
                    </Button>
                  </div>
                }
                onChange={formik.handleChange}
                name="select_signatory"
              >
                {allUsers?.map((item, index) => (
                  <MenuItem key={index} value={item?.id}>
                    {item?.full_name}
                  </MenuItem>
                ))}
              </SimpleSelect>
            </FormControl>
          </Grid>
          {formik.values.select_signatory && (
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Grid item>
                <FormControl fullWidth>
                  <SimpleInput
                    inputlabel="Full Name"
                    name="full_name"
                    value={
                      allUsers?.find(
                        (item) => item?.id === formik?.values?.select_signatory
                      ).full_name
                    }
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <SimpleInput
                    inputlabel="Email"
                    name="email"
                    value={
                      allUsers?.find(
                        (item) => item?.id === formik?.values?.select_signatory
                      ).email
                    }
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <SimpleInput
                    inputlabel="Mobile Number"
                    name="mobile_number"
                    value={
                      allUsers?.find(
                        (item) => item?.id === formik?.values?.select_signatory
                      ).mobile_number
                    }
                    disabled
                    InputProps={{
                      startAdornment: (
                        <div style={{ display: "flex" }}>
                          +91
                          <Divider
                            orientation="vertical"
                            flexItem
                            style={{ marginLeft: "10px" }}
                          ></Divider>
                        </div>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <SimpleInput
                    inputlabel="Designation"
                    name="designation"
                    value={
                      allUsers?.find(
                        (item) => item?.id === formik?.values?.select_signatory
                      ).designation
                    }
                    disabled
                  />
                </FormControl>
              </Grid>
            </form>
          )}
          <Grid item>
            <Button
              type="submit"
              fullWidth
              variant="primarySmall"
              disabled={sendLoading}
            >
              {sendLoading ? <CircularProgress size={20} /> : "Continue"}
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default SelectSignatory;
