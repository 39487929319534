import {
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableBody,
  Modal,
  Divider,
} from "@mui/material";
import CommonLoader from "../../../components/CommonLoader";
import { TableWrapper } from "../../../theme/customComponents";
import CompanyAgreementLogs from "../CompanyAgreement/CompanyAgreementList";
import CompanyTeam from "../CompanyTeam";
import AuthorizedSignatoryDetails from "../CompanySignatory/AuthorizedSignatoryDetails";
import useUserDetails from "../../../hooks/useUserDetails";
import UploadAgreement from "../CompanySignatory/UploadAgreement";
import SelectSignatory from "../CompanySignatory/SelectSignatory";
import CommonBreadCrumb from "../../../components/CommonBreadCrumb";

const UserDetails = () => {
  const {
    id,
    open,
    anchorEl,
    loading,
    companyDetailsData,
    setAnchorEl,
    handleAgreementClick,
    setOpenUploadModal,
    openAddSignatoryModal,
    setOpenAddSignatoryModal,
    signatoryList,
    getSignatoryLoading,
    signatoryFetch,
    setSelectSignatoryModal,
    openUploadModal,
    selectSignatoryModal,
  } = useUserDetails();

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <CommonBreadCrumb
            typographyText="User Details"
            linksText={[{ link: "Company Queues", to: "/company-queues" }]}
            showCompany
          />
        </Grid>
        <Grid item xs={12} mt="16px" mb="28px">
          <Divider />
        </Grid>
        <Grid item xs={12} my={5}>
          <Paper>
            <Grid padding={"20px"} justifyContent="space-between" container>
              <Grid item>
                <Typography fontWeight={600} variant="h4">
                  User Details
                </Typography>
              </Grid>
            </Grid>
            {loading ? (
              <CommonLoader loadingCount={[1]} />
            ) : (
              <TableWrapper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width={"33.33%"}>Full Name</TableCell>
                      <TableCell width={"33.33%"}>Email-Id</TableCell>
                      <TableCell width={"33.33%"}>Phone Number</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companyDetailsData?.company_users
                      ?.filter((item: any) => item?.user_type === "ADMIN")
                      ?.map((userData: any, index: number) => {
                        return (
                          <TableRow key={index}>
                            <TableCell width={"33.33%"}>
                              {`${userData?.user?.first_name} ${userData?.user?.last_name}` ||
                                "-"}
                            </TableCell>
                            <TableCell width={"33.33%"}>
                              {`${userData?.user?.email}` || "-"}
                            </TableCell>
                            <TableCell width={"33.33%"}>
                              {`${userData?.user?.mobile_number}` || "-"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableWrapper>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} my={5}>
          <CompanyAgreementLogs
            setAddModal={setOpenAddSignatoryModal}
            allUsers={signatoryList}
            companyDetailsData={companyDetailsData}
            open={open}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            handleAgreementClick={handleAgreementClick}
            setSelectSignatoryModal={setSelectSignatoryModal}
            setOpenUploadModal={setOpenUploadModal}
          />
        </Grid>
        {/**-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */}
        <Grid item xs={12} my={5}>
          <CompanyTeam />
        </Grid>
        {/**-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */}
        <Grid item xs={12} my={5}>
          <AuthorizedSignatoryDetails
            open={openAddSignatoryModal}
            setOpen={setOpenAddSignatoryModal}
            data={signatoryList}
            loading={getSignatoryLoading}
            companyId={Number(id)}
            signatoryFetch={signatoryFetch}
          />
        </Grid>
      </Grid>
      <Modal open={openUploadModal}>
        <Grid
          container
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <UploadAgreement onClose={setOpenUploadModal} />
        </Grid>
      </Modal>

      <Modal open={selectSignatoryModal}>
        <Grid
          container
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <SelectSignatory
            allUsers={signatoryList}
            setOpen={setSelectSignatoryModal}
            setOpenAddSignatoryModal={setOpenAddSignatoryModal}
          />
        </Grid>
      </Modal>
    </>
  );
};

export default UserDetails;
