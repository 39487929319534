import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { ReactComponent as ChevronDownIcon } from "../../../assets/Icon/Outline/chevron-down-icon.svg";

const TradeSummary = ({ data, selectedContracts }: any) => {
  const Tenure = data && data[0]?.validity;

  const BidPrice = data && data[0]?.bid_price;

  const poolAmount = selectedContracts
    ?.filter(
      (item: any) =>
        item?.subscription?.eligibility_score === 1 ||
        item?.eligibility_score === 1
    )
    ?.map((item: any) => {
      return item?.amount_per_month * BidPrice * Tenure;
    });

  const primaryAmount = selectedContracts
    ?.filter(
      (item: any) =>
        item?.subscription?.eligibility_score === 2 ||
        item?.eligibility_score === 2
    )
    ?.map((item: any) => {
      return item?.amount_per_month * BidPrice * Tenure;
    });

  function add(accumulator: any, a: any) {
    return accumulator + a;
  }

  return (
    <Grid container width={"100%"} display="flex">
      <Grid item width={"100%"} p={4} style={{ background: "white" }}>
        <Typography fontSize={"18px"} fontWeight={700} paddingBottom={3}>
          Trade Summary
        </Typography>
        <Divider variant="fullWidth" orientation="horizontal"></Divider>
        <Typography
          fontSize={"14px"}
          color="#666666"
          fontWeight={500}
          py={3}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Selected Contracts
          <span
            style={{
              fontSize: "14px",
              color: "#000",
            }}
          >
            {selectedContracts?.length}
          </span>
        </Typography>
        <Typography
          fontSize={"14px"}
          color="#666666"
          fontWeight={500}
          py={3}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Offer Price
          <span
            style={{
              fontSize: "14px",
              color: "#000",
            }}
          >{`₹${data && data[0]?.bid_price.toFixed(2)}`}</span>
        </Typography>
        <Typography
          fontSize={"14px"}
          color="#666666"
          fontWeight={500}
          py={3}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Total Offer Amount
          <span
            style={{
              fontSize: "14px",
              color: "#000",
            }}
          >{`₹${data && data[0]?.payout_value.toFixed(2)}`}</span>
        </Typography>
        <Typography
          fontSize={"14px"}
          color="#666666"
          fontWeight={500}
          py={3}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Primary contract value
          <span
            style={{
              fontSize: "14px",
              color: "#000",
            }}
          >{`₹${primaryAmount?.reduce(add, 0).toFixed(2)}`}</span>
        </Typography>
        <Typography
          fontSize={"14px"}
          color="#666666"
          fontWeight={500}
          py={3}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Pool contract value
          <span
            style={{
              fontSize: "14px",
              color: "#000",
            }}
          >{`₹${poolAmount?.reduce(add, 0).toFixed(2)}`}</span>
        </Typography>
        <Divider variant="fullWidth" orientation="horizontal"></Divider>
        <Typography
          fontSize={"14px"}
          color="#666666"
          fontWeight={500}
          py={3}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Platform Fee{" "}
          <span
            style={{
              fontSize: "14px",
              color: "#000",
            }}
          >{`₹${data && data[0]?.fees.toFixed(2)}`}</span>
        </Typography>
        <Divider variant="fullWidth" orientation="horizontal"></Divider>
        <Typography
          fontSize={"14px"}
          fontWeight={700}
          py={3}
          paddingBottom={0}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Total Payout{" "}
          <span
            style={{
              fontSize: "14px",
              color: "#000",
            }}
          >{`₹${
            data && (data[0]?.payout_value - data[0]?.fees).toFixed(2)
          }`}</span>
        </Typography>
      </Grid>
      <Grid item width={"100%"} style={{ background: "white" }} p={2} my={3}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ChevronDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={"18px"} fontWeight={700}>
              Offer Details
            </Typography>
          </AccordionSummary>
          <Divider variant="fullWidth" orientation="horizontal"></Divider>
          {data &&
            data[0]?.investments?.map((item: any) => (
              <AccordionDetails>
                <Typography
                  fontSize={"14px"}
                  color="#666666"
                  fontWeight={500}
                  py={3}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Investor Name:
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {item?.investor?.name}
                  </span>
                </Typography>
                <Typography
                  fontSize={"14px"}
                  color="#666666"
                  fontWeight={500}
                  py={3}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Interest Rate:
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {item?.investor?.actual_irr || "-"}
                  </span>
                </Typography>
                <Typography
                  fontSize={"14px"}
                  color="#666666"
                  fontWeight={500}
                  py={3}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Max investment Amount:
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {item?.invested_amount}
                  </span>
                </Typography>
              </AccordionDetails>
            ))}
        </Accordion>
      </Grid>
      <Grid item width={"100%"} style={{ background: "white" }}></Grid>
    </Grid>
  );
};

export default TradeSummary;
