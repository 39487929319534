import { useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import { AuthContext } from "../../contexts/JWTContext";
import { useUpdateCompanyRmMutation } from "../hooks/company/company.hooks";
import {
  useAddDocumentChecklistMutation,
  useGetCompanySalesRmQuery,
  useGetDocumentChecklistStatusQuery,
  useGetDocument_Checklist_CategoryQuery,
  useSendDocumentchecklistNotificationMutation,
  useToggleDocumentChecklistMutation,
  useUpdateDocument_ChecklistMutation,
  useUpdate_Document_Checklist_Category_ManyMutation,
} from "../hooks/company/document-checklist.hooks";

interface Section {
  id: number;
  document_checklists: SectionDocument[];
  is_applicable: boolean;
  ref_document_checklist_category: RefDocumentChecklistCategory;
}
interface RefDocumentChecklistCategory {
  id: number;
  is_applicable: boolean;
  name: string;
  sequence: number;
}
interface SectionDocument {
  remarks: string | any;
  shared_by_company: string;
  id: number;
  status: string;
  ref_document_checklist: RestData;
}
interface RestData {
  internal_tip: String;
  name: String;
  serial_number: String;
  subtext: String;
}

const useCompanyDocumentChecklist = () => {
  const checkListRef = useRef<any>();

  const { companyData } = useContext(CompanyContext);
  const context = useContext(AuthContext);

  console.log("companyData: ", companyData);

  const [allSectionData, setAllSectionData] = useState<Section[]>();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);

  const [onTextareaValueChange, setOnTextareaValueChange] = useState("");

  const { setShowAlert } = useContext(CompanyContext);

  const params = useParams();

  //*DROPDOWN STATUS DATA-----------------------------------------------------------------------------------------
  const statusDropdownData = [
    {
      text: "Completed",
      value: "COMPLETED",
    },
    {
      text: "Incomplete",
      value: "INCOMPLETE",
    },
    {
      text: "Not Received",
      value: "NOT_RECEIVED",
    },
    {
      text: "Not Applicable",
      value: "NOT_APPLICABLE",
    },
  ];

  //*GET SALESRM-----------------------------------------------------------------------------------------
  const { data: salesRM } = useGetCompanySalesRmQuery({
    variables: {
      id: Number(params?.id),
    },
  });
  console.log("salesRM: ", salesRM);

  //*GET CHECKLISTDATA-----------------------------------------------------------------------------------------
  const {
    loading: getAllDataLoading,
    data: initialSectionData,
    refetch: getAllDocumnetDataRefetch,
  } = useGetDocument_Checklist_CategoryQuery({
    variables: {
      where: {
        company_id: {
          _eq: Number(params.id),
        },
      },
    },
    onCompleted: (allData: any) =>
      setAllSectionData(allData.document_checklist_category),
  });

  //* PAUSE DOCUMENT CHECKLIST----------------------------------------------------------------------------------------

  const [handlePauseDocumentChecklist, { loading: statusUpdateLoading }] =
    useToggleDocumentChecklistMutation({
      onCompleted: (response) => {
        getStatusRefetch();
        setShowAlert({
          show: true,
          message: `${response.ToggleCompanyDocumentChecklist?.message}`,
          type: "success",
        });
      },
      onError: (error) => {
        setShowAlert({
          show: true,
          message: error.message,
          type: "error",
        });
      },
    });

  //* CHILD CATEGORY FUNCTION-----------------------------------------------------------------------------------------
  const onHandleSelectChange = ({ e, id }: any) => {
    setAllSectionData(
      allSectionData?.map((item: any) => {
        return {
          is_applicable: item.is_applicable,
          id: item.id,
          ref_document_checklist_category: item.ref_document_checklist_category,
          document_checklists: item?.document_checklists.map((child: any) =>
            child.id === id ? { ...child, status: e } : child
          ),
        };
      })
    );

    setUpdateDocumentCheckListData((prev: any) => [
      ...prev,
      {
        where: {
          id: {
            _eq: id,
          },
        },
        _set: {
          status: e,
        },
      },
    ]);
  };

  //*API INTEGRATION-----------------------------------------------------------------------------------------
  const handleSectionRemarkChange = (id: any) => {
    setAllSectionData(
      allSectionData?.map((item: any) => {
        return {
          is_applicable: item.is_applicable,
          id: item.id,
          ref_document_checklist_category: item.ref_document_checklist_category,
          document_checklists: item?.document_checklists.map((child: any) =>
            child.id === id
              ? { ...child, remarks: onTextareaValueChange }
              : child
          ),
        };
      })
    );
    setUpdateDocumentCheckListData((prev: any) => [
      ...prev,
      {
        where: {
          id: {
            _eq: id,
          },
        },
        _set: {
          remarks: onTextareaValueChange,
        },
      },
    ]);
    setShowTooltip(false);
    setOnTextareaValueChange("");
  };

  //*CATEGORY CHANGE FUNCTION--------------------------------------------------------------------------------
  const handleSectionApplicable = (id: any, value: any) => {
    setAllSectionData(
      allSectionData?.map((item: any) => {
        return {
          ref_document_checklist_category: item.ref_document_checklist_category,
          document_checklists: item.document_checklists,
          is_applicable:
            item.id === id ? JSON.parse(value) : item.is_applicable,
          id: item.id,
        };
      })
    );

    setUpdateDocumentCheckLisCategorytData((prev: any) => [
      ...prev,
      {
        where: {
          id: {
            _eq: id,
          },
        },
        _set: {
          is_applicable: JSON.parse(value),
        },
      },
    ]);
  };
  const [updateDocumentCheckListData, setUpdateDocumentCheckListData] =
    useState<any[]>([
      {
        where: {
          id: {
            _eq: "",
          },
        },
        _set: {
          status: "",
          remarks: "",
        },
      },
    ]);
  const [
    updateDocumentCheckLisCategorytData,
    setUpdateDocumentCheckLisCategorytData,
  ] = useState<any[]>([
    {
      where: {
        id: {
          _eq: "",
        },
      },
      _set: {
        is_applicable: false,
      },
    },
  ]);

  //*UPDATE QUERY--------------------------------------------------------------------------------------------
  const [updateDocumentChecklistCategory, { loading: categoryLoading }] =
    useUpdate_Document_Checklist_Category_ManyMutation({
      onCompleted: () => {
        getAllDocumnetDataRefetch();
      },
    });
  const [updateDocumentChecklistData] = useUpdateDocument_ChecklistMutation({
    onCompleted: () => {
      getAllDocumnetDataRefetch();
    },
  });

  //*FINAL SUBMIT BUTTON ------------------------------------------------------------------------------------
  const handleSaveDocumentChecklist = () => {
    updateDocumentChecklistCategory({
      variables: {
        updates: updateDocumentCheckLisCategorytData.filter(
          (item) => item.where.id._eq !== ""
        ),
      },
    });
    updateDocumentChecklistData({
      variables: {
        updates: updateDocumentCheckListData.filter(
          (item) => item.where.id._eq !== ""
        ),
      },
    });
    updateCompanyRM({
      variables: {
        id: Number(params.id),
        ops_rm: Number(context?.user?.id),
        sales_rm: Number(salesRM?.company[0].sales_rm),
      },
    });
  };

  //*REMINDER API--------------------------------------------------------------------------------------------
  const [notifyIncomepleteDocumentChecklist, { loading: NotifyUserLoading }] =
    useSendDocumentchecklistNotificationMutation({
      onCompleted: () => {
        setShowConfirmationModal(false);
        setShowAlert({
          message: "Reminder successfully sent",
          show: true,
          type: "success",
        });
      },
      onError: (item) => {
        setShowAlert({
          message: item.message,
          show: true,
          type: "error",
        });
      },
    });

  //*--------------------------------------------------------------------------------------------------------
  const [handleAddDocumentChecklist, { loading: addLoading }] =
    useAddDocumentChecklistMutation({
      onCompleted: (item) => {
        getAllDocumnetDataRefetch();
      },
    });
  //*--------------------------------------------------------------------------------------------------------
  const { refetch: getStatusRefetch, data: documentChecklistStatus } =
    useGetDocumentChecklistStatusQuery({
      variables: {
        id: Number(params.id),
      },
    });

  /** STAGE SALES AND OPS RM UPDATE--------------------------------------------------------------------------- */
  const [updateCompanyRM] = useUpdateCompanyRmMutation({
    onCompleted: () => {
      console.log("SUCCESSFULLY UPDATED");
    },
  });
  //*--------------------------------------------------------------------------------------------------------
  const loading = getAllDataLoading;

  return {
    setShowTooltip,
    onHandleSelectChange,
    handleSectionApplicable,
    setShowConfirmationModal,
    setOnTextareaValueChange,
    handleSectionRemarkChange,
    handleSaveDocumentChecklist,
    showTooltip,
    checkListRef,
    allSectionData,
    statusDropdownData,
    showConfirmationModal,
    onTextareaValueChange,
    categoryLoading,
    loading,
    notifyIncomepleteDocumentChecklist,
    NotifyUserLoading,
    setAllSectionData,
    setUpdateDocumentCheckListData,
    initialSectionData,
    params,
    handleAddDocumentChecklist,
    addLoading,
    handlePauseDocumentChecklist,
    statusUpdateLoading,
    getStatusRefetch,
    documentChecklistStatus,
  };
};

export default useCompanyDocumentChecklist;
