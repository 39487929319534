import { Divider, Drawer, Grid, IconButton, Typography } from "@mui/material";
import { ReactComponent as XIcon } from "../../assets/Icon/Outline/x-icon.svg";
import styled from "styled-components";

const ChildGrid = styled(Grid)`
  height: -webkit-fill-available;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #1e88e555;
  }
  ::-webkit-scrollbar-thumb {
    background: #1e88e5;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
interface SideModalProps {
  open: boolean;
  modalWidth?: string;
  modalTitle?: string | any;
  modalSubTitle?: string;
  onClose: any;
  children?: any;
  footer?: any;
}

const SideModal = ({
  open,
  onClose,
  modalWidth,
  modalTitle,
  children,
  footer,
  modalSubTitle,
}: SideModalProps) => {
  const isTitleString = typeof modalTitle;

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: modalWidth ? modalWidth : "30%",
        },
      }}
    >
      <Grid
        item
        justifyContent={"space-between"}
        display="flex"
        alignItems={"center"}
        p={"30px 30px 0 30px"}
      >
        {isTitleString === "string" ? (
          <Typography
            fontWeight={700}
            fontSize={20}
            lineHeight={1}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            {modalTitle || "Title"}
            {modalSubTitle && (
              <span
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: "#AAA5B7",
                  letterSpacing: "0.6px",
                }}
              >
                {" "}
                {modalSubTitle || "hello"}
              </span>
            )}
          </Typography>
        ) : (
          modalTitle
        )}
        <IconButton onClick={onClose} style={{ padding: "6px" }}>
          <XIcon color="#000000" />
        </IconButton>
      </Grid>
      <Grid item width={"100%"} m={"28px 0px 20px 0px"}>
        <Divider />
      </Grid>
      <ChildGrid item width={"100%"} p={"0 30px"}>
        {children}
      </ChildGrid>
      <Grid
        item
        width={"100%"}
        p={"16px 30px"}
        style={{
          boxShadow: "0px -2px 7px 0px #e9e9e9",
        }}
      >
        {footer}
      </Grid>
    </Drawer>
  );
};

export default SideModal;
