import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGlobalInvestorsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Investor_Bool_Exp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  order_by?: Types.InputMaybe<Array<Types.Investor_Order_By> | Types.Investor_Order_By>;
}>;


export type GetGlobalInvestorsQuery = { __typename?: 'query_root', investor: Array<{ __typename?: 'investor', name: string, id: number, created_at: any, cin?: string | null, pan?: string | null, type?: Types.Ref_Investor_Type_Enum | null, status: string }>, investor_aggregate: { __typename?: 'investor_aggregate', aggregate?: { __typename?: 'investor_aggregate_fields', count: number } | null } };

export type GetAllCountryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllCountryQuery = { __typename?: 'query_root', ref_country: Array<{ __typename?: 'ref_country', id: number, name: string }> };

export type GetAllStateQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Ref_States_Bool_Exp>;
}>;


export type GetAllStateQuery = { __typename?: 'query_root', ref_states: Array<{ __typename?: 'ref_states', id: number, name: string, country_id: number }> };

export type AddInvestorDetailsMutationVariables = Types.Exact<{
  args: Types.OpsCreateInvestorInput;
}>;


export type AddInvestorDetailsMutation = { __typename?: 'mutation_root', OpsCreateInvestor?: { __typename?: 'OpsCreateInvestorOutput', investor_id: number, message: string } | null };

export type AddInvestorsUsersMutationVariables = Types.Exact<{
  args: Array<Types.AddInvestorUserInput> | Types.AddInvestorUserInput;
}>;


export type AddInvestorsUsersMutation = { __typename?: 'mutation_root', AddInvestorUser?: { __typename?: 'AddInvestorUserOutput', investor_users?: Array<number> | null, message: string } | null };

export type GetInvestorByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetInvestorByIdQuery = { __typename?: 'query_root', investor_by_pk?: { __typename?: 'investor', name: string, address_line1?: string | null, address_line2?: string | null, cin?: string | null, id: number, agreement_file_id?: number | null, type?: Types.Ref_Investor_Type_Enum | null, pan?: string | null, total_committed_budget?: any | null, country_id?: number | null, state_id?: number | null, zipcode?: number | null, created_at: any, investor_users: Array<{ __typename?: 'investor_user', id: number, pan?: string | null, investor_id: number, user: { __typename?: 'user', last_name: string, id: number, first_name: string, email: string, mobile_number: string, designation?: string | null } }> } | null, agreement_registry: Array<{ __typename?: 'agreement_registry', id: number }> };

export type GetInvestorBankAccountsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Account_Bool_Exp>;
}>;


export type GetInvestorBankAccountsQuery = { __typename?: 'query_root', account: Array<{ __typename?: 'account', account_no: string, id: number, account_type?: Types.Ref_Account_Type_Enum | null, bank_id: number, is_primary: boolean, ifsc_code?: string | null, multi_signatory: boolean, ref_bank: { __typename?: 'ref_bank', id: number, bank_code: string, name: string }, investor?: { __typename?: 'investor', id: number, name: string } | null }> };

export type GetBankDetailsByIfscQueryVariables = Types.Exact<{
  ifsc: Types.Scalars['String'];
}>;


export type GetBankDetailsByIfscQuery = { __typename?: 'query_root', searchIFSC?: { __typename?: 'searchIFSCOutput', bank_code: string, ifsc: string, micr?: string | null, bank?: { __typename?: 'ref_bank', id: number, bank_code: string, name: string } | null } | null };

export type AddInvestorSignatoryMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  set?: Types.InputMaybe<Types.Investor_Set_Input>;
}>;


export type AddInvestorSignatoryMutation = { __typename?: 'mutation_root', update_investor_by_pk?: { __typename?: 'investor', id: number } | null };

export type UpdateInvestorMutationVariables = Types.Exact<{
  pk_columns: Types.Investor_Pk_Columns_Input;
  set?: Types.InputMaybe<Types.Investor_Set_Input>;
}>;


export type UpdateInvestorMutation = { __typename?: 'mutation_root', update_investor_by_pk?: { __typename?: 'investor', id: number, name: string } | null };

export type UpdateInvestorUserByIdMutationVariables = Types.Exact<{
  args: Types.UpdateInvestorUserInput;
}>;


export type UpdateInvestorUserByIdMutation = { __typename?: 'mutation_root', UpdateInvestorUser?: { __typename?: 'UpdateInvestorUserOutput', message: string, investor_user_id: number } | null };

export type AddInvestorBankAccountMutationVariables = Types.Exact<{
  args: Types.AddPhysicalAccountInput;
}>;


export type AddInvestorBankAccountMutation = { __typename?: 'mutation_root', AddPhysicalAccount?: { __typename?: 'AddPhysicalAccountOutput', message: string, id: number } | null };

export type UpdateInvestorBankAccountMutationVariables = Types.Exact<{
  pk_columns: Types.Account_Pk_Columns_Input;
  set?: Types.InputMaybe<Types.Account_Set_Input>;
}>;


export type UpdateInvestorBankAccountMutation = { __typename?: 'mutation_root', update_account_by_pk?: { __typename?: 'account', id: number } | null };

export type DownloadInvestorAgreementMutationVariables = Types.Exact<{
  agreement_registry_id: Types.Scalars['Int'];
}>;


export type DownloadInvestorAgreementMutation = { __typename?: 'mutation_root', DownloadAgreement?: { __typename?: 'DownloadAgreementOutput', download_url: string } | null };

export type GetInvestorUsersQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Investor_User_Bool_Exp>;
}>;


export type GetInvestorUsersQuery = { __typename?: 'query_root', investor_user: Array<{ __typename?: 'investor_user', pan?: string | null, id: number, user: { __typename?: 'user', first_name: string, last_name: string, designation?: string | null, mobile_number: string, email: string } }> };

export type GetAllInvestmentsByInvestorQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Investment_Bool_Exp>;
}>;


export type GetAllInvestmentsByInvestorQuery = { __typename?: 'query_root', investment: Array<{ __typename?: 'investment', trade_id: number, invested_amount?: any | null, withdrawal_mode?: Types.Ref_Trade_Withdrawal_Mode_Enum | null, trade: { __typename?: 'trade', status: Types.Ref_Trade_Status_Enum, company: { __typename?: 'company', name?: string | null, id: number } } }> };


export const GetGlobalInvestorsDocument = gql`
    query getGlobalInvestors($where: investor_bool_exp, $limit: Int, $offset: Int, $order_by: [investor_order_by!]) {
  investor(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
    name
    id
    created_at
    cin
    pan
    type
    status
  }
  investor_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetGlobalInvestorsQuery__
 *
 * To run a query within a React component, call `useGetGlobalInvestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalInvestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalInvestorsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetGlobalInvestorsQuery(baseOptions?: Apollo.QueryHookOptions<GetGlobalInvestorsQuery, GetGlobalInvestorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGlobalInvestorsQuery, GetGlobalInvestorsQueryVariables>(GetGlobalInvestorsDocument, options);
      }
export function useGetGlobalInvestorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalInvestorsQuery, GetGlobalInvestorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGlobalInvestorsQuery, GetGlobalInvestorsQueryVariables>(GetGlobalInvestorsDocument, options);
        }
export type GetGlobalInvestorsQueryHookResult = ReturnType<typeof useGetGlobalInvestorsQuery>;
export type GetGlobalInvestorsLazyQueryHookResult = ReturnType<typeof useGetGlobalInvestorsLazyQuery>;
export type GetGlobalInvestorsQueryResult = Apollo.QueryResult<GetGlobalInvestorsQuery, GetGlobalInvestorsQueryVariables>;
export const GetAllCountryDocument = gql`
    query getAllCountry {
  ref_country {
    id
    name
  }
}
    `;

/**
 * __useGetAllCountryQuery__
 *
 * To run a query within a React component, call `useGetAllCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCountryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCountryQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCountryQuery, GetAllCountryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCountryQuery, GetAllCountryQueryVariables>(GetAllCountryDocument, options);
      }
export function useGetAllCountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCountryQuery, GetAllCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCountryQuery, GetAllCountryQueryVariables>(GetAllCountryDocument, options);
        }
export type GetAllCountryQueryHookResult = ReturnType<typeof useGetAllCountryQuery>;
export type GetAllCountryLazyQueryHookResult = ReturnType<typeof useGetAllCountryLazyQuery>;
export type GetAllCountryQueryResult = Apollo.QueryResult<GetAllCountryQuery, GetAllCountryQueryVariables>;
export const GetAllStateDocument = gql`
    query getAllState($where: ref_states_bool_exp) {
  ref_states(where: $where) {
    id
    name
    country_id
  }
}
    `;

/**
 * __useGetAllStateQuery__
 *
 * To run a query within a React component, call `useGetAllStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllStateQuery(baseOptions?: Apollo.QueryHookOptions<GetAllStateQuery, GetAllStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllStateQuery, GetAllStateQueryVariables>(GetAllStateDocument, options);
      }
export function useGetAllStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStateQuery, GetAllStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllStateQuery, GetAllStateQueryVariables>(GetAllStateDocument, options);
        }
export type GetAllStateQueryHookResult = ReturnType<typeof useGetAllStateQuery>;
export type GetAllStateLazyQueryHookResult = ReturnType<typeof useGetAllStateLazyQuery>;
export type GetAllStateQueryResult = Apollo.QueryResult<GetAllStateQuery, GetAllStateQueryVariables>;
export const AddInvestorDetailsDocument = gql`
    mutation addInvestorDetails($args: OpsCreateInvestorInput!) {
  OpsCreateInvestor(args: $args) {
    investor_id
    message
  }
}
    `;
export type AddInvestorDetailsMutationFn = Apollo.MutationFunction<AddInvestorDetailsMutation, AddInvestorDetailsMutationVariables>;

/**
 * __useAddInvestorDetailsMutation__
 *
 * To run a mutation, you first call `useAddInvestorDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvestorDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvestorDetailsMutation, { data, loading, error }] = useAddInvestorDetailsMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useAddInvestorDetailsMutation(baseOptions?: Apollo.MutationHookOptions<AddInvestorDetailsMutation, AddInvestorDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInvestorDetailsMutation, AddInvestorDetailsMutationVariables>(AddInvestorDetailsDocument, options);
      }
export type AddInvestorDetailsMutationHookResult = ReturnType<typeof useAddInvestorDetailsMutation>;
export type AddInvestorDetailsMutationResult = Apollo.MutationResult<AddInvestorDetailsMutation>;
export type AddInvestorDetailsMutationOptions = Apollo.BaseMutationOptions<AddInvestorDetailsMutation, AddInvestorDetailsMutationVariables>;
export const AddInvestorsUsersDocument = gql`
    mutation addInvestorsUsers($args: [AddInvestorUserInput!]!) {
  AddInvestorUser(args: $args) {
    investor_users
    message
  }
}
    `;
export type AddInvestorsUsersMutationFn = Apollo.MutationFunction<AddInvestorsUsersMutation, AddInvestorsUsersMutationVariables>;

/**
 * __useAddInvestorsUsersMutation__
 *
 * To run a mutation, you first call `useAddInvestorsUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvestorsUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvestorsUsersMutation, { data, loading, error }] = useAddInvestorsUsersMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useAddInvestorsUsersMutation(baseOptions?: Apollo.MutationHookOptions<AddInvestorsUsersMutation, AddInvestorsUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInvestorsUsersMutation, AddInvestorsUsersMutationVariables>(AddInvestorsUsersDocument, options);
      }
export type AddInvestorsUsersMutationHookResult = ReturnType<typeof useAddInvestorsUsersMutation>;
export type AddInvestorsUsersMutationResult = Apollo.MutationResult<AddInvestorsUsersMutation>;
export type AddInvestorsUsersMutationOptions = Apollo.BaseMutationOptions<AddInvestorsUsersMutation, AddInvestorsUsersMutationVariables>;
export const GetInvestorByIdDocument = gql`
    query getInvestorById($id: Int!) {
  investor_by_pk(id: $id) {
    name
    address_line1
    address_line2
    cin
    id
    agreement_file_id
    type
    pan
    total_committed_budget
    country_id
    state_id
    zipcode
    created_at
    investor_users {
      id
      pan
      investor_id
      user {
        last_name
        id
        first_name
        email
        mobile_number
        designation
      }
    }
  }
  agreement_registry(
    where: {category_id: {_eq: TENANT_AGREEMENT}, agreement_tenants: {tenant_id: {_eq: $id}, tenant_type: {_eq: "INVESTOR"}}}
    order_by: [{created_at: desc}]
    limit: 1
  ) {
    id
  }
}
    `;

/**
 * __useGetInvestorByIdQuery__
 *
 * To run a query within a React component, call `useGetInvestorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvestorByIdQuery(baseOptions: Apollo.QueryHookOptions<GetInvestorByIdQuery, GetInvestorByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvestorByIdQuery, GetInvestorByIdQueryVariables>(GetInvestorByIdDocument, options);
      }
export function useGetInvestorByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvestorByIdQuery, GetInvestorByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvestorByIdQuery, GetInvestorByIdQueryVariables>(GetInvestorByIdDocument, options);
        }
export type GetInvestorByIdQueryHookResult = ReturnType<typeof useGetInvestorByIdQuery>;
export type GetInvestorByIdLazyQueryHookResult = ReturnType<typeof useGetInvestorByIdLazyQuery>;
export type GetInvestorByIdQueryResult = Apollo.QueryResult<GetInvestorByIdQuery, GetInvestorByIdQueryVariables>;
export const GetInvestorBankAccountsDocument = gql`
    query getInvestorBankAccounts($where: account_bool_exp) {
  account(where: $where) {
    account_no
    id
    account_type
    bank_id
    is_primary
    ifsc_code
    multi_signatory
    ref_bank {
      id
      bank_code
      name
    }
    investor {
      id
      name
    }
  }
}
    `;

/**
 * __useGetInvestorBankAccountsQuery__
 *
 * To run a query within a React component, call `useGetInvestorBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorBankAccountsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetInvestorBankAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetInvestorBankAccountsQuery, GetInvestorBankAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvestorBankAccountsQuery, GetInvestorBankAccountsQueryVariables>(GetInvestorBankAccountsDocument, options);
      }
export function useGetInvestorBankAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvestorBankAccountsQuery, GetInvestorBankAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvestorBankAccountsQuery, GetInvestorBankAccountsQueryVariables>(GetInvestorBankAccountsDocument, options);
        }
export type GetInvestorBankAccountsQueryHookResult = ReturnType<typeof useGetInvestorBankAccountsQuery>;
export type GetInvestorBankAccountsLazyQueryHookResult = ReturnType<typeof useGetInvestorBankAccountsLazyQuery>;
export type GetInvestorBankAccountsQueryResult = Apollo.QueryResult<GetInvestorBankAccountsQuery, GetInvestorBankAccountsQueryVariables>;
export const GetBankDetailsByIfscDocument = gql`
    query getBankDetailsByIFSC($ifsc: String!) {
  searchIFSC(args: {ifsc: $ifsc}) {
    bank {
      id
      bank_code
      name
    }
    bank_code
    ifsc
    micr
  }
}
    `;

/**
 * __useGetBankDetailsByIfscQuery__
 *
 * To run a query within a React component, call `useGetBankDetailsByIfscQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankDetailsByIfscQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankDetailsByIfscQuery({
 *   variables: {
 *      ifsc: // value for 'ifsc'
 *   },
 * });
 */
export function useGetBankDetailsByIfscQuery(baseOptions: Apollo.QueryHookOptions<GetBankDetailsByIfscQuery, GetBankDetailsByIfscQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBankDetailsByIfscQuery, GetBankDetailsByIfscQueryVariables>(GetBankDetailsByIfscDocument, options);
      }
export function useGetBankDetailsByIfscLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBankDetailsByIfscQuery, GetBankDetailsByIfscQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBankDetailsByIfscQuery, GetBankDetailsByIfscQueryVariables>(GetBankDetailsByIfscDocument, options);
        }
export type GetBankDetailsByIfscQueryHookResult = ReturnType<typeof useGetBankDetailsByIfscQuery>;
export type GetBankDetailsByIfscLazyQueryHookResult = ReturnType<typeof useGetBankDetailsByIfscLazyQuery>;
export type GetBankDetailsByIfscQueryResult = Apollo.QueryResult<GetBankDetailsByIfscQuery, GetBankDetailsByIfscQueryVariables>;
export const AddInvestorSignatoryDocument = gql`
    mutation addInvestorSignatory($id: Int!, $set: investor_set_input) {
  update_investor_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
  }
}
    `;
export type AddInvestorSignatoryMutationFn = Apollo.MutationFunction<AddInvestorSignatoryMutation, AddInvestorSignatoryMutationVariables>;

/**
 * __useAddInvestorSignatoryMutation__
 *
 * To run a mutation, you first call `useAddInvestorSignatoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvestorSignatoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvestorSignatoryMutation, { data, loading, error }] = useAddInvestorSignatoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useAddInvestorSignatoryMutation(baseOptions?: Apollo.MutationHookOptions<AddInvestorSignatoryMutation, AddInvestorSignatoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInvestorSignatoryMutation, AddInvestorSignatoryMutationVariables>(AddInvestorSignatoryDocument, options);
      }
export type AddInvestorSignatoryMutationHookResult = ReturnType<typeof useAddInvestorSignatoryMutation>;
export type AddInvestorSignatoryMutationResult = Apollo.MutationResult<AddInvestorSignatoryMutation>;
export type AddInvestorSignatoryMutationOptions = Apollo.BaseMutationOptions<AddInvestorSignatoryMutation, AddInvestorSignatoryMutationVariables>;
export const UpdateInvestorDocument = gql`
    mutation updateInvestor($pk_columns: investor_pk_columns_input!, $set: investor_set_input) {
  update_investor_by_pk(_set: $set, pk_columns: $pk_columns) {
    id
    name
  }
}
    `;
export type UpdateInvestorMutationFn = Apollo.MutationFunction<UpdateInvestorMutation, UpdateInvestorMutationVariables>;

/**
 * __useUpdateInvestorMutation__
 *
 * To run a mutation, you first call `useUpdateInvestorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvestorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvestorMutation, { data, loading, error }] = useUpdateInvestorMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateInvestorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvestorMutation, UpdateInvestorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvestorMutation, UpdateInvestorMutationVariables>(UpdateInvestorDocument, options);
      }
export type UpdateInvestorMutationHookResult = ReturnType<typeof useUpdateInvestorMutation>;
export type UpdateInvestorMutationResult = Apollo.MutationResult<UpdateInvestorMutation>;
export type UpdateInvestorMutationOptions = Apollo.BaseMutationOptions<UpdateInvestorMutation, UpdateInvestorMutationVariables>;
export const UpdateInvestorUserByIdDocument = gql`
    mutation updateInvestorUserByID($args: UpdateInvestorUserInput!) {
  UpdateInvestorUser(args: $args) {
    message
    investor_user_id
  }
}
    `;
export type UpdateInvestorUserByIdMutationFn = Apollo.MutationFunction<UpdateInvestorUserByIdMutation, UpdateInvestorUserByIdMutationVariables>;

/**
 * __useUpdateInvestorUserByIdMutation__
 *
 * To run a mutation, you first call `useUpdateInvestorUserByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvestorUserByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvestorUserByIdMutation, { data, loading, error }] = useUpdateInvestorUserByIdMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUpdateInvestorUserByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvestorUserByIdMutation, UpdateInvestorUserByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvestorUserByIdMutation, UpdateInvestorUserByIdMutationVariables>(UpdateInvestorUserByIdDocument, options);
      }
export type UpdateInvestorUserByIdMutationHookResult = ReturnType<typeof useUpdateInvestorUserByIdMutation>;
export type UpdateInvestorUserByIdMutationResult = Apollo.MutationResult<UpdateInvestorUserByIdMutation>;
export type UpdateInvestorUserByIdMutationOptions = Apollo.BaseMutationOptions<UpdateInvestorUserByIdMutation, UpdateInvestorUserByIdMutationVariables>;
export const AddInvestorBankAccountDocument = gql`
    mutation addInvestorBankAccount($args: AddPhysicalAccountInput!) {
  AddPhysicalAccount(args: $args) {
    message
    id
  }
}
    `;
export type AddInvestorBankAccountMutationFn = Apollo.MutationFunction<AddInvestorBankAccountMutation, AddInvestorBankAccountMutationVariables>;

/**
 * __useAddInvestorBankAccountMutation__
 *
 * To run a mutation, you first call `useAddInvestorBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvestorBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvestorBankAccountMutation, { data, loading, error }] = useAddInvestorBankAccountMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useAddInvestorBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<AddInvestorBankAccountMutation, AddInvestorBankAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInvestorBankAccountMutation, AddInvestorBankAccountMutationVariables>(AddInvestorBankAccountDocument, options);
      }
export type AddInvestorBankAccountMutationHookResult = ReturnType<typeof useAddInvestorBankAccountMutation>;
export type AddInvestorBankAccountMutationResult = Apollo.MutationResult<AddInvestorBankAccountMutation>;
export type AddInvestorBankAccountMutationOptions = Apollo.BaseMutationOptions<AddInvestorBankAccountMutation, AddInvestorBankAccountMutationVariables>;
export const UpdateInvestorBankAccountDocument = gql`
    mutation updateInvestorBankAccount($pk_columns: account_pk_columns_input!, $set: account_set_input) {
  update_account_by_pk(pk_columns: $pk_columns, _set: $set) {
    id
  }
}
    `;
export type UpdateInvestorBankAccountMutationFn = Apollo.MutationFunction<UpdateInvestorBankAccountMutation, UpdateInvestorBankAccountMutationVariables>;

/**
 * __useUpdateInvestorBankAccountMutation__
 *
 * To run a mutation, you first call `useUpdateInvestorBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvestorBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvestorBankAccountMutation, { data, loading, error }] = useUpdateInvestorBankAccountMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateInvestorBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvestorBankAccountMutation, UpdateInvestorBankAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvestorBankAccountMutation, UpdateInvestorBankAccountMutationVariables>(UpdateInvestorBankAccountDocument, options);
      }
export type UpdateInvestorBankAccountMutationHookResult = ReturnType<typeof useUpdateInvestorBankAccountMutation>;
export type UpdateInvestorBankAccountMutationResult = Apollo.MutationResult<UpdateInvestorBankAccountMutation>;
export type UpdateInvestorBankAccountMutationOptions = Apollo.BaseMutationOptions<UpdateInvestorBankAccountMutation, UpdateInvestorBankAccountMutationVariables>;
export const DownloadInvestorAgreementDocument = gql`
    mutation downloadInvestorAgreement($agreement_registry_id: Int!) {
  DownloadAgreement(args: {agreement_registry_id: $agreement_registry_id}) {
    download_url
  }
}
    `;
export type DownloadInvestorAgreementMutationFn = Apollo.MutationFunction<DownloadInvestorAgreementMutation, DownloadInvestorAgreementMutationVariables>;

/**
 * __useDownloadInvestorAgreementMutation__
 *
 * To run a mutation, you first call `useDownloadInvestorAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadInvestorAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadInvestorAgreementMutation, { data, loading, error }] = useDownloadInvestorAgreementMutation({
 *   variables: {
 *      agreement_registry_id: // value for 'agreement_registry_id'
 *   },
 * });
 */
export function useDownloadInvestorAgreementMutation(baseOptions?: Apollo.MutationHookOptions<DownloadInvestorAgreementMutation, DownloadInvestorAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadInvestorAgreementMutation, DownloadInvestorAgreementMutationVariables>(DownloadInvestorAgreementDocument, options);
      }
export type DownloadInvestorAgreementMutationHookResult = ReturnType<typeof useDownloadInvestorAgreementMutation>;
export type DownloadInvestorAgreementMutationResult = Apollo.MutationResult<DownloadInvestorAgreementMutation>;
export type DownloadInvestorAgreementMutationOptions = Apollo.BaseMutationOptions<DownloadInvestorAgreementMutation, DownloadInvestorAgreementMutationVariables>;
export const GetInvestorUsersDocument = gql`
    query getInvestorUsers($where: investor_user_bool_exp) {
  investor_user(where: $where) {
    pan
    id
    user {
      first_name
      last_name
      designation
      mobile_number
      email
    }
  }
}
    `;

/**
 * __useGetInvestorUsersQuery__
 *
 * To run a query within a React component, call `useGetInvestorUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetInvestorUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetInvestorUsersQuery, GetInvestorUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvestorUsersQuery, GetInvestorUsersQueryVariables>(GetInvestorUsersDocument, options);
      }
export function useGetInvestorUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvestorUsersQuery, GetInvestorUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvestorUsersQuery, GetInvestorUsersQueryVariables>(GetInvestorUsersDocument, options);
        }
export type GetInvestorUsersQueryHookResult = ReturnType<typeof useGetInvestorUsersQuery>;
export type GetInvestorUsersLazyQueryHookResult = ReturnType<typeof useGetInvestorUsersLazyQuery>;
export type GetInvestorUsersQueryResult = Apollo.QueryResult<GetInvestorUsersQuery, GetInvestorUsersQueryVariables>;
export const GetAllInvestmentsByInvestorDocument = gql`
    query getAllInvestmentsByInvestor($where: investment_bool_exp) {
  investment(where: $where) {
    trade_id
    invested_amount
    withdrawal_mode
    trade {
      status
      company {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useGetAllInvestmentsByInvestorQuery__
 *
 * To run a query within a React component, call `useGetAllInvestmentsByInvestorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInvestmentsByInvestorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInvestmentsByInvestorQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllInvestmentsByInvestorQuery(baseOptions?: Apollo.QueryHookOptions<GetAllInvestmentsByInvestorQuery, GetAllInvestmentsByInvestorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInvestmentsByInvestorQuery, GetAllInvestmentsByInvestorQueryVariables>(GetAllInvestmentsByInvestorDocument, options);
      }
export function useGetAllInvestmentsByInvestorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInvestmentsByInvestorQuery, GetAllInvestmentsByInvestorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInvestmentsByInvestorQuery, GetAllInvestmentsByInvestorQueryVariables>(GetAllInvestmentsByInvestorDocument, options);
        }
export type GetAllInvestmentsByInvestorQueryHookResult = ReturnType<typeof useGetAllInvestmentsByInvestorQuery>;
export type GetAllInvestmentsByInvestorLazyQueryHookResult = ReturnType<typeof useGetAllInvestmentsByInvestorLazyQuery>;
export type GetAllInvestmentsByInvestorQueryResult = Apollo.QueryResult<GetAllInvestmentsByInvestorQuery, GetAllInvestmentsByInvestorQueryVariables>;
export const namedOperations = {
  Query: {
    getGlobalInvestors: 'getGlobalInvestors',
    getAllCountry: 'getAllCountry',
    getAllState: 'getAllState',
    getInvestorById: 'getInvestorById',
    getInvestorBankAccounts: 'getInvestorBankAccounts',
    getBankDetailsByIFSC: 'getBankDetailsByIFSC',
    getInvestorUsers: 'getInvestorUsers',
    getAllInvestmentsByInvestor: 'getAllInvestmentsByInvestor'
  },
  Mutation: {
    addInvestorDetails: 'addInvestorDetails',
    addInvestorsUsers: 'addInvestorsUsers',
    addInvestorSignatory: 'addInvestorSignatory',
    updateInvestor: 'updateInvestor',
    updateInvestorUserByID: 'updateInvestorUserByID',
    addInvestorBankAccount: 'addInvestorBankAccount',
    updateInvestorBankAccount: 'updateInvestorBankAccount',
    downloadInvestorAgreement: 'downloadInvestorAgreement'
  }
}