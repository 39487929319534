import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAccountByCompanyIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetAccountByCompanyIdQuery = { __typename?: 'query_root', account: Array<{ __typename?: 'account', tenant_type: string, tenant_id: number }> };

export type UploadSubscriptionDataMutationVariables = Types.Exact<{
  file_id: Types.Scalars['Int'];
}>;


export type UploadSubscriptionDataMutation = { __typename?: 'mutation_root', UploadSubscriptionData?: { __typename?: 'UploadSubscriptionDataOutput', id: number, message: string } | null };

export type GetPaymentPartnerIntegrationsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Company_Integration_Bool_Exp>;
  order_by?: Types.InputMaybe<Array<Types.Company_Integration_Order_By> | Types.Company_Integration_Order_By>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetPaymentPartnerIntegrationsQuery = { __typename?: 'query_root', company_integration: Array<{ __typename?: 'company_integration', source: string, category: string, created_at: any, integration_auth_id?: number | null, status: string, failure_reason?: any | null, company: { __typename?: 'company', name?: string | null }, company_integration_files: Array<{ __typename?: 'company_integration_file', status: string, file_registry: { __typename?: 'file_registry', id: number, name: string } }> }> };

export type DownloadUploadedFileQueryVariables = Types.Exact<{
  file_registry_id: Types.Scalars['Int'];
}>;


export type DownloadUploadedFileQuery = { __typename?: 'query_root', generateFileRegistryDownloadURL?: { __typename?: 'GenerateFileRegistryDownloadURLOutput', download_url: string } | null };


export const GetAccountByCompanyIdDocument = gql`
    query getAccountByCompanyId($id: Int!) {
  account(where: {company: {id: {_eq: $id}}}) {
    tenant_type
    tenant_id
  }
}
    `;

/**
 * __useGetAccountByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetAccountByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountByCompanyIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountByCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<GetAccountByCompanyIdQuery, GetAccountByCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountByCompanyIdQuery, GetAccountByCompanyIdQueryVariables>(GetAccountByCompanyIdDocument, options);
      }
export function useGetAccountByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountByCompanyIdQuery, GetAccountByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountByCompanyIdQuery, GetAccountByCompanyIdQueryVariables>(GetAccountByCompanyIdDocument, options);
        }
export type GetAccountByCompanyIdQueryHookResult = ReturnType<typeof useGetAccountByCompanyIdQuery>;
export type GetAccountByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetAccountByCompanyIdLazyQuery>;
export type GetAccountByCompanyIdQueryResult = Apollo.QueryResult<GetAccountByCompanyIdQuery, GetAccountByCompanyIdQueryVariables>;
export const UploadSubscriptionDataDocument = gql`
    mutation uploadSubscriptionData($file_id: Int!) {
  UploadSubscriptionData(args: {file_id: $file_id}) {
    id
    message
  }
}
    `;
export type UploadSubscriptionDataMutationFn = Apollo.MutationFunction<UploadSubscriptionDataMutation, UploadSubscriptionDataMutationVariables>;

/**
 * __useUploadSubscriptionDataMutation__
 *
 * To run a mutation, you first call `useUploadSubscriptionDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadSubscriptionDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadSubscriptionDataMutation, { data, loading, error }] = useUploadSubscriptionDataMutation({
 *   variables: {
 *      file_id: // value for 'file_id'
 *   },
 * });
 */
export function useUploadSubscriptionDataMutation(baseOptions?: Apollo.MutationHookOptions<UploadSubscriptionDataMutation, UploadSubscriptionDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadSubscriptionDataMutation, UploadSubscriptionDataMutationVariables>(UploadSubscriptionDataDocument, options);
      }
export type UploadSubscriptionDataMutationHookResult = ReturnType<typeof useUploadSubscriptionDataMutation>;
export type UploadSubscriptionDataMutationResult = Apollo.MutationResult<UploadSubscriptionDataMutation>;
export type UploadSubscriptionDataMutationOptions = Apollo.BaseMutationOptions<UploadSubscriptionDataMutation, UploadSubscriptionDataMutationVariables>;
export const GetPaymentPartnerIntegrationsDocument = gql`
    query getPaymentPartnerIntegrations($where: company_integration_bool_exp, $order_by: [company_integration_order_by!], $offset: Int, $limit: Int) {
  company_integration(
    where: $where
    order_by: $order_by
    limit: $limit
    offset: $offset
  ) {
    source
    category
    created_at
    integration_auth_id
    status
    company {
      name
    }
    failure_reason
    company_integration_files {
      status
      file_registry {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetPaymentPartnerIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetPaymentPartnerIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentPartnerIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentPartnerIntegrationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetPaymentPartnerIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentPartnerIntegrationsQuery, GetPaymentPartnerIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentPartnerIntegrationsQuery, GetPaymentPartnerIntegrationsQueryVariables>(GetPaymentPartnerIntegrationsDocument, options);
      }
export function useGetPaymentPartnerIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentPartnerIntegrationsQuery, GetPaymentPartnerIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentPartnerIntegrationsQuery, GetPaymentPartnerIntegrationsQueryVariables>(GetPaymentPartnerIntegrationsDocument, options);
        }
export type GetPaymentPartnerIntegrationsQueryHookResult = ReturnType<typeof useGetPaymentPartnerIntegrationsQuery>;
export type GetPaymentPartnerIntegrationsLazyQueryHookResult = ReturnType<typeof useGetPaymentPartnerIntegrationsLazyQuery>;
export type GetPaymentPartnerIntegrationsQueryResult = Apollo.QueryResult<GetPaymentPartnerIntegrationsQuery, GetPaymentPartnerIntegrationsQueryVariables>;
export const DownloadUploadedFileDocument = gql`
    query downloadUploadedFile($file_registry_id: Int!) {
  generateFileRegistryDownloadURL(args: {file_registry_id: $file_registry_id}) {
    download_url
  }
}
    `;

/**
 * __useDownloadUploadedFileQuery__
 *
 * To run a query within a React component, call `useDownloadUploadedFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadUploadedFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadUploadedFileQuery({
 *   variables: {
 *      file_registry_id: // value for 'file_registry_id'
 *   },
 * });
 */
export function useDownloadUploadedFileQuery(baseOptions: Apollo.QueryHookOptions<DownloadUploadedFileQuery, DownloadUploadedFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadUploadedFileQuery, DownloadUploadedFileQueryVariables>(DownloadUploadedFileDocument, options);
      }
export function useDownloadUploadedFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadUploadedFileQuery, DownloadUploadedFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadUploadedFileQuery, DownloadUploadedFileQueryVariables>(DownloadUploadedFileDocument, options);
        }
export type DownloadUploadedFileQueryHookResult = ReturnType<typeof useDownloadUploadedFileQuery>;
export type DownloadUploadedFileLazyQueryHookResult = ReturnType<typeof useDownloadUploadedFileLazyQuery>;
export type DownloadUploadedFileQueryResult = Apollo.QueryResult<DownloadUploadedFileQuery, DownloadUploadedFileQueryVariables>;
export const namedOperations = {
  Query: {
    getAccountByCompanyId: 'getAccountByCompanyId',
    getPaymentPartnerIntegrations: 'getPaymentPartnerIntegrations',
    downloadUploadedFile: 'downloadUploadedFile'
  },
  Mutation: {
    uploadSubscriptionData: 'uploadSubscriptionData'
  }
}