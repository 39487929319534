import {
  Button,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { format } from "date-fns";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import {
  useDownloadAgreementMutation,
  useGetAllAgreementsQuery,
} from "../../../data-access/hooks/company/nda-logs.hooks";
import CustomNDA from "./CustomNDA";
import { ReactComponent as ChevronDownIcon } from "../../../assets/Icon/Outline/chevron-down-icon.svg";
import UploadNDA from "./UploadNDA";
import { agreementModeStatus } from "../../../utils/common";
import CommonLoader from "../../../components/CommonLoader";
import { CompanyContext } from "../../../contexts/CompanyContext";
import TableStatus from "../../../components/TableStatus";

const CompanyAgreementLogs = ({
  setAddModal,
  allUsers,
  companyDetailsData,
  open,
  anchorEl,
  setAnchorEl,
  handleAgreementClick,
  setSelectSignatoryModal,
  setOpenUploadModal,
}: any) => {
  const { id: company_id } = useParams();

  const { setShowAlert } = useContext(CompanyContext);

  const [getAllNda, setGetAllNda] = React.useState<any[]>([]);

  const [showUploadModal, setShowUploadModal] = React.useState(false);

  const [selectedItem, setSelectedItem] = React.useState("");

  const [ndaAnchorEl, setNdaAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const showNda = Boolean(ndaAnchorEl);

  const [downloadNDALogs, { loading: downloadlLoading }] =
    useDownloadAgreementMutation({
      onCompleted: (item) => {
        const downloadUrl = item?.DownloadAgreement?.download_url;
        const link = document.createElement("a");
        link.href = `${downloadUrl}`;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
        setShowAlert({
          type: "success",
          message: "File Downloaded Successfully",
          show: true,
        });
      },
      onError: (item) => {
        setShowAlert({
          type: "error",
          message: item?.message,
          show: true,
        });
      },
    });

  const [showCustomUploadModal, setShowCustomUploadModal] =
    React.useState(false);

  const { loading, refetch: NDAListRefetch } = useGetAllAgreementsQuery({
    variables: {
      where: {
        company_id: {
          _eq: Number(company_id),
        },
      },
    },
    onCompleted: (item) => {
      const data = item.company_agreement_logs_view.map((data) => {
        return {
          id: data.id,
          signed_at: data.signed_at,
          category_id: data.category_id,
          status: data.status,
          name: data.signed_file
            ? data.signed_file?.name
            : data.draft_file?.name,
          signed_file_id: data.signed_file
            ? data.signed_file?.id
            : data.draft_file?.id,
        };
      });
      setGetAllNda(data);
    },
  });

  return (
    <>
      <Grid container display={"flex"} flexDirection="column">
        <Grid
          item
          style={{
            background: "#fff",
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Typography fontWeight={600} fontSize="18px">
              Agreement Details
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button
              variant="dropdownButton"
              onClick={handleAgreementClick}
              style={{
                padding: "6px 10px",
                display: "flex",
                alignItems: "center",
                gap: "6px",
              }}
            >
              BU Agreement{" "}
              <ChevronDownIcon width={18} height={18} color="#1E88E5 " />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  setSelectSignatoryModal(true);
                  setAnchorEl(null);
                }}
              >
                Send Agreement
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setOpenUploadModal(true);
                }}
              >
                Upload BU Agreement{" "}
              </MenuItem>
            </Menu>{" "}
            <Menu
              anchorEl={ndaAnchorEl}
              open={showNda}
              onClose={() => setNdaAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  setNdaAnchorEl(null);
                  setShowUploadModal(true);
                }}
              >
                Upload NDA
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setNdaAnchorEl(null);
                  setShowCustomUploadModal(true);
                }}
              >
                Send Custom NDA
              </MenuItem>
            </Menu>{" "}
          </Grid>
        </Grid>
        {loading ? (
          <CommonLoader />
        ) : (
          <Grid item>
            {getAllNda?.length !== 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>File</TableCell>
                    <TableCell>Signed Status</TableCell>
                    <TableCell>Updated On</TableCell>
                    <TableCell>Mode</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getAllNda?.map((item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{item.name || "-"}</TableCell>
                      <TableCell width={"20%"}>
                        <Typography>
                          {TableStatus({
                            stautsText: `${item?.status}`,
                          })}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {format(new Date(item.signed_at), "dd/MM/yyyy")}
                      </TableCell>
                      <TableCell>
                        {agreementModeStatus(item.category_id || "")?.text}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="linkButton"
                          onClick={() => {
                            setSelectedItem(item?.id);
                            downloadNDALogs({
                              variables: {
                                args: {
                                  agreement_registry_id: item?.id,
                                },
                              },
                            });
                          }}
                        >
                          {downloadlLoading && selectedItem === item?.id ? (
                            <CircularProgress size={20} />
                          ) : (
                            "Download"
                          )}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
        )}
      </Grid>
      <Modal open={showUploadModal} onClose={() => setShowUploadModal(false)}>
        <Grid
          container
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <UploadNDA
            onClose={setShowUploadModal}
            NDAListRefetch={NDAListRefetch}
          />
        </Grid>
      </Modal>

      <Modal
        open={showCustomUploadModal}
        onClose={() => setShowCustomUploadModal(false)}
      >
        <Grid
          container
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <CustomNDA
            onClose={setShowCustomUploadModal}
            setAddModal={setAddModal}
            allUsers={allUsers}
            companyDetailsData={companyDetailsData}
          />
        </Grid>
      </Modal>
    </>
  );
};

export default CompanyAgreementLogs;
