interface StatusProps {
  stautsText: string;
  style?: any;
}

const TableStatus = ({ stautsText, style }: StatusProps) => {
  const colors = {
    green: "#57B867",
    red: "#E90000",
    purple: "#A157FF",
    blue: "#1E88E5",
    yellow: "#EAB224",
    grey: "#4F566B",
  };

  const statusFunction = (text: string) => {
    switch (text) {
      case "COMPLETED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "CREDIT_ACCEPTED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "MANDATE_SIGNED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "APA_COMPANY_SIGNED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "APA_INVESTOR_SIGNED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "SALES_QUALIFIED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "TRANSFORMATION_COMPLETE":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "SYNC_COMPLETED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "VALIDATION_COMPLETE":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "APPROVED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "CONNECTED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "DEBIT_SUCCESS":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "VERIFIED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "MANDATE_SUCCESS":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "INELIGIBLEFAILED_CREDIT":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "SIGNED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.green,
        };
      case "FAILED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.red,
        };
      case "UNVERIFIED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.red,
        };
      case "PAYOUT_DISBURSED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.red,
        };
      case "DELINQUENT":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.red,
        };
      case "CREDIT_REJECTED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.red,
        };
      case "CUSTOMER_DENY":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.red,
        };
      case "EXPIRED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.red,
        };
      case "INCOMPLETE":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.red,
        };
      case "NOT_RECEIVED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.red,
        };
      case "BLOCKED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.red,
        };
      case "DISCONNECTED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.red,
        };
      case "FAILED_DEBIT":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.red,
        };
      case "IN_ACTIVE":
      case "ANALYSING":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.purple,
        };
      case "CANCELLED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.purple,
        };
      case "INELIGIBLE":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.purple,
        };
      case "CREATED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.purple,
        };
      case "ACTIVE":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.blue,
        };
      case "DEFAULT":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.blue,
        };
      case "CUSTOMER_ACCEPT":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.blue,
        };
      case "BIDDING":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.blue,
        };
      case "DOCUMENT_COLLECTION":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.blue,
        };
      case "SCHEDULED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.blue,
        };
      case "SYNCING":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.blue,
        };
      case "SYSTEM_UPLOADED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.blue,
        };
      case "PAUSED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.blue,
        };
      case "UPLOADED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.blue,
        };
      case "INITIATED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "CONTRACT_SELECTED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "CREDIT_HOLD":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "PENDING":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "AGREEMENT_IN_PROGRESS":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "CREDIT_WIP":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "CLOSED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "VERIFYING":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "DEBIT_INITIATED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "PENDING_APPROVAL":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "VALIDATING":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "TRANSFORMING":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "CREDIT_SUCCESS":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "IN_QUEUE":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "PROCESSING":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "PARTIAL_SIGNED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.yellow,
        };
      case "NOT_INTERESTED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.grey,
        };
      case "MANDATE_REGISTERED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.grey,
        };
      case "APA_GENERATED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.grey,
        };
      case "DRAFT":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.grey,
        };
      case "CHURNED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.grey,
        };
      case "OFFLINE":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.grey,
        };
      case "REGISTERED":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.grey,
        };
      case "REATTEMPT":
        return {
          text: text.toLowerCase().replace("_", " "),
          color: colors.grey,
        };
      default:
        return {
          text: "-",
          color: "",
        };
    }
  };

  return (
    <div
      style={{
        padding: "6px 12px",
        background: `${statusFunction(stautsText)?.color}24`,
        color: statusFunction(stautsText)?.color,
        border: statusFunction(stautsText).text === "-" ? "none" : "solid 1px",
        borderColor: statusFunction(stautsText)?.color,
        borderRadius: "6px",
        fontSize: "14px",
        fontWeight: "600",
        textAlign: "center",
        textTransform: "capitalize",
        width: "fit-content",
        ...style,
      }}
    >
      {statusFunction(stautsText)?.text ?? "-"}
    </div>
  );
};

export default TableStatus;
