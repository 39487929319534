import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import axios from "./axios";

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   expiredTimer = window.setTimeout(() => {
//   }, timeLeft);
// };

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.clear();
    delete axios.defaults.headers.common.Authorization;
  }
};

const getSession = () => {
  return localStorage.getItem("accessToken");
};

const setRefreshToken = (refreshToken: string | null) => {
  if (refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
  }
};

const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};
export {
  verify,
  sign,
  isValidToken,
  getSession,
  setSession,
  setRefreshToken,
  getRefreshToken,
};
