/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components/macro";
import { Grid, Paper } from "@mui/material";
import {
  Divider as MuiDivider,
  Button,
  Table,
  TableBody,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import React from "react";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import CommonBreadCrumb from "../../../components/CommonBreadCrumb";
import AddScoreModal from "./AddScoreModal";
import { TableWrapper } from "../../../theme/customComponents";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { ReactComponent as PencilAltIcon } from "../../../assets/Icon/Outline/pencil-alt-icon.svg";
import { format } from "date-fns";
import EditTradeFactor from "./EditTradeFactors";
import useScoringEngine from "../../../hooks/useScoringEngine";
import EmptyData from "../../../components/EmptyData";

export interface TableDataInterface {
  score_id: number;
  company_id: number;
  min_offer_amount: number;
  rating: number;
  facility_limit: number;
  arr: number;
  financial_score: number;
  business_growth_score: number;
  capital_efficiency_score: number;
  customer_value_score: number;
  management_score: number;
  scored_on: number;
  factors: FactorsInterface[];
}

interface FactorsInterface {
  time_series: string;
  sequence: number;
  score_factors: any[];
  score_factor_title: any[];
}

const Divider = styled(MuiDivider)(spacing);

const CustomTableRow = styled(TableRow)`
  background: #f8f8f8 !important;
`;

const CustomTableHead = styled(TableHead)`
  background: #f8f8f8 !important;
  padding: 3px 18px;
`;
const CustomTableCell = styled(TableCell)`
  background: #f8f8f8 !important;
  border: none;
  padding: 5px 18px;
  text-transform: capitalize;
`;

const CompanyScore: React.FC = () => {
  const {
    openScoreModal,
    setOpenScoreModal,
    openFactorModal,
    setOpenFactorModal,
    tableData,
    stepNumber,
    setStepNumber,
    editScoreData,
    setEditScoreData,
    createdScoreId,
    setCreatedScoreId,
    editType,
    setEditType,
    handleDownloadCamReport,
    downloadLoading,
    scoreFactorsLoading,
    scoreFactorRefetch,
    handleInitailDeal,
    initaiteLoading,
  } = useScoringEngine();

  return (
    <React.Fragment>
      <Helmet title="Company Detail" />
      <Grid
        item
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {/*---------------------------*COMMON BREAD CRUMBS--------------------------- */}
        <Grid item>
          <CommonBreadCrumb
            typographyText="Scoring Engine"
            linksText={[{ link: "  Company Queues", to: "/company-queues" }]}
            showCompany
          />
        </Grid>
        <Grid item display={"flex"} alignItems={"center"} gap={"8px"}>
          <Button
            variant="primaryMedium"
            onClick={() => {
              setStepNumber(0);
              setOpenScoreModal(true);
            }}
          >
            Add Score
          </Button>
          <Button
            onClick={() =>
              handleInitailDeal({
                variables: {
                  company_score_id: Number(tableData && tableData[0].score_id),
                },
              })
            }
            disabled={tableData?.length === 0 || initaiteLoading}
            variant="secondarySmall"
          >
            {initaiteLoading ? (
              <CircularProgress size={20} />
            ) : (
              "Initiate Deals"
            )}
          </Button>
        </Grid>
        {/*--------------------------------------------------------------------------- */}
      </Grid>

      <Divider mt="16px" mb="28px" />

      {scoreFactorsLoading ? (
        <Grid
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"100%"}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid item>
          {tableData?.length === 0 ? (
            <Paper>
              <EmptyData />
            </Paper>
          ) : (
            tableData?.map((item, index) => (
              <Grid container my={5} key={index}>
                <Grid item width={"100%"}>
                  <Typography
                    fontWeight={600}
                    fontSize={"18px"}
                    style={{
                      background: "#fff",
                      paddingTop: "16px",
                      paddingLeft: "16px",
                    }}
                  >{`Score ID: #${item.score_id}`}</Typography>
                  <Typography
                    fontWeight={500}
                    fontSize={"14px"}
                    style={{
                      background: "#fff",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                    }}
                  >{`Scored as of 
              ${format(Date.parse(`${item.scored_on}`), "dd LLL yyyy")}
            `}</Typography>
                </Grid>
                <Grid item width={"100%"}>
                  <TableWrapper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Final Cam Report</TableCell>
                          <TableCell>Score ID</TableCell>
                          <TableCell>Company ID</TableCell>
                          <TableCell>Minimum Offer/Bid</TableCell>
                          <TableCell>Rating</TableCell>
                          <TableCell>Facility Limit </TableCell>
                          <TableCell>ARR</TableCell>
                          <TableCell>Financials Score</TableCell>
                          <TableCell>Business Growth Score</TableCell>
                          <TableCell>Capital Efficiency Score</TableCell>
                          <TableCell>Customer Value Score</TableCell>
                          <TableCell>Management Score</TableCell>
                          <TableCell>Scored On</TableCell>
                          <TableCell
                            style={{
                              position: "sticky",
                              right: "0",
                              background: "#ECF5FF",
                            }}
                          >
                            Edit Score
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <>
                          <TableRow>
                            <TableCell>
                              <Button
                                onClick={() =>
                                  handleDownloadCamReport({
                                    variables: {
                                      file_registry_id: Number(
                                        item?.report_file_id
                                      ),
                                    },
                                  })
                                }
                                variant="tertiarySmall"
                              >
                                {downloadLoading ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  "Download"
                                )}
                              </Button>
                            </TableCell>
                            <TableCell>{item.score_id || "-"}</TableCell>
                            <TableCell>{item.company_id || "-"}</TableCell>
                            <TableCell>
                              {item.min_offer_amount || "-"}
                            </TableCell>
                            <TableCell>{item.rating || "-"}</TableCell>
                            <TableCell>{item.facility_limit || "-"}</TableCell>
                            <TableCell>{item.arr || "-"}</TableCell>
                            <TableCell>{item.financial_score || "-"}</TableCell>
                            <TableCell>
                              {item.business_growth_score || "-"}
                            </TableCell>
                            <TableCell>
                              {item.capital_efficiency_score || "-"}
                            </TableCell>
                            <TableCell>
                              {item.customer_value_score || "-"}
                            </TableCell>
                            <TableCell>
                              {item.management_score || "-"}
                            </TableCell>
                            <TableCell>
                              {format(
                                Date.parse(`${item.scored_on}`),
                                "dd/MM/yyyy"
                              ) || "-"}
                            </TableCell>
                            <TableCell
                              style={{
                                position: "sticky",
                                background: "#fff",
                                right: "0",
                              }}
                            >
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  setStepNumber(0);
                                  setOpenScoreModal(true);
                                  setEditScoreData(item);
                                  setEditType(true);
                                }}
                              >
                                <PencilAltIcon
                                  color="#1E88E5"
                                  height="16px"
                                  width="16px"
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <TableCell
                            colSpan={14}
                            style={{ background: "white" }}
                          >
                            <Grid
                              item
                              display={"flex"}
                              justifyContent="space-between"
                              alignItems={"center"}
                              width={"100%"}
                            >
                              <Typography
                                fontSize={"16px"}
                                fontWeight={600}
                                style={{
                                  position: "sticky",
                                  left: "16px",
                                }}
                              >
                                Trading Factors
                              </Typography>
                              <Button
                                variant="secondarySmall"
                                style={{
                                  position: "sticky",
                                  right: "16px",
                                }}
                                onClick={() => {
                                  setStepNumber(1);
                                  setOpenScoreModal(true);
                                  setEditScoreData(item);
                                }}
                              >
                                Add Trading Factor
                              </Button>
                            </Grid>
                            {item?.factors?.map(
                              (score_factor_item, factorIndex) => (
                                <Table
                                  style={{
                                    margin: "10px 0",
                                    background: "white",
                                  }}
                                  key={factorIndex}
                                >
                                  <CustomTableHead>
                                    <CustomTableRow>
                                      <CustomTableCell>
                                        Time Stamp
                                      </CustomTableCell>
                                      <CustomTableCell>
                                        Sequence
                                      </CustomTableCell>
                                      {score_factor_item?.score_factor_title?.map(
                                        (title, index) => {
                                          const fieldTitle = title?.factor
                                            ?.toLowerCase()
                                            .replace("_", " ");
                                          return (
                                            <CustomTableCell key={index}>
                                              {fieldTitle}
                                            </CustomTableCell>
                                          );
                                        }
                                      )}
                                      <CustomTableCell
                                        style={{
                                          position: "sticky",
                                          right: "0",
                                        }}
                                      >
                                        Edit Factor{" "}
                                      </CustomTableCell>
                                    </CustomTableRow>
                                  </CustomTableHead>
                                  <TableBody>
                                    <CustomTableRow>
                                      <CustomTableCell>
                                        {score_factor_item?.time_series}
                                      </CustomTableCell>
                                      <CustomTableCell>
                                        {score_factor_item?.sequence}
                                      </CustomTableCell>
                                      <CustomTableCell>
                                        {score_factor_item?.score_factors[0]
                                          ?.value || "-"}
                                      </CustomTableCell>
                                      <CustomTableCell>
                                        {score_factor_item?.score_factors[1]
                                          ?.value || "-"}
                                      </CustomTableCell>
                                      <CustomTableCell>
                                        {score_factor_item?.score_factors[2]
                                          ?.value || "-"}
                                      </CustomTableCell>
                                      <CustomTableCell>
                                        {score_factor_item?.score_factors[3]
                                          ?.value || "-"}
                                      </CustomTableCell>
                                      <CustomTableCell>
                                        {score_factor_item?.score_factors[4]
                                          ?.value || "-"}
                                      </CustomTableCell>
                                      <CustomTableCell>
                                        {score_factor_item?.score_factors[5]
                                          ?.value || "-"}
                                      </CustomTableCell>
                                      <CustomTableCell>
                                        {score_factor_item?.score_factors[6]
                                          ?.value || "-"}
                                      </CustomTableCell>
                                      <CustomTableCell>
                                        {score_factor_item?.score_factors[7]
                                          ?.value || "-"}
                                      </CustomTableCell>
                                      <CustomTableCell>
                                        {score_factor_item?.score_factors[8]
                                          ?.value || "-"}
                                      </CustomTableCell>
                                      <CustomTableCell
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {score_factor_item?.score_factors[9]
                                          ?.value || "-"}
                                      </CustomTableCell>
                                      <CustomTableCell>
                                        {score_factor_item?.score_factors[10]
                                          ?.value || "-"}
                                      </CustomTableCell>
                                      <CustomTableCell>
                                        {score_factor_item?.score_factors[11]
                                          ?.value || "-"}
                                      </CustomTableCell>
                                      <CustomTableCell
                                        style={{
                                          position: "sticky",
                                          right: "0",
                                        }}
                                      >
                                        <IconButton
                                          color="primary"
                                          onClick={() => {
                                            setOpenFactorModal(true);
                                            setEditScoreData(
                                              item?.factors[factorIndex]
                                            );
                                          }}
                                        >
                                          <PencilAltIcon
                                            color="#1E88E5"
                                            height="16px"
                                            width="16px"
                                          />
                                        </IconButton>
                                      </CustomTableCell>
                                    </CustomTableRow>
                                  </TableBody>
                                </Table>
                              )
                            )}
                          </TableCell>
                        </>
                      </TableBody>
                    </Table>
                  </TableWrapper>
                </Grid>
              </Grid>
            ))
          )}
        </Grid>
      )}

      {openFactorModal && (
        <EditTradeFactor
          open={openFactorModal}
          handleDialogClose={() => {
            setOpenFactorModal(false);
            setEditScoreData(undefined);
          }}
          scoreFactorRefetch={scoreFactorRefetch}
          stepNumber={stepNumber}
          scoreData={editScoreData}
          setStepNumber={setStepNumber}
          setCreatedScoreId={setCreatedScoreId}
        />
      )}
      {openScoreModal && (
        <AddScoreModal
          open={openScoreModal}
          handleDialogClose={() => {
            setOpenScoreModal(false);
            setEditScoreData(undefined);
          }}
          scoreFactorRefetch={scoreFactorRefetch}
          stepNumber={stepNumber}
          scoreData={editScoreData}
          setScoreData={setEditScoreData}
          setStepNumber={setStepNumber}
          createdScoreId={createdScoreId}
          setCreatedScoreId={setCreatedScoreId}
          editType={editType}
          setEditType={setEditType}
        />
      )}
    </React.Fragment>
  );
};

export default CompanyScore;
