import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AttachContractsMutationVariables = Types.Exact<{
  args: Types.AttachContractsToTradeInput;
}>;


export type AttachContractsMutation = { __typename?: 'mutation_root', attachContractsToTrade?: { __typename?: 'attachContractsToTradeOutput', message: string } | null };

export type GetAllContractsByCompanyIdQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Subscription_Bool_Exp>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  order_by?: Types.InputMaybe<Array<Types.Subscription_Order_By> | Types.Subscription_Order_By>;
  selected_where?: Types.InputMaybe<Types.Trade_Subscription_Bool_Exp>;
  trade_where?: Types.InputMaybe<Types.Trade_Bool_Exp>;
  agg_where?: Types.InputMaybe<Types.Subscription_Bool_Exp>;
}>;


export type GetAllContractsByCompanyIdQuery = { __typename?: 'query_root', subscription: Array<{ __typename?: 'subscription', term_end?: any | null, amount_per_period: any, customer_name?: string | null, customer_created: any, id: number, company_id: number, eligibility_score?: number | null, customer_identifier: string, customer_email: string, term_start: any, status: Types.Ref_Subscription_Status_Enum, billing_period: string, billing_period_interval: number, currency: string, tax_inclusive: boolean, tax_rate?: any | null, amount_per_month: any }>, trade_subscription: Array<{ __typename?: 'trade_subscription', id: number, amount_per_month: any, subscription_id: number, company_id: number, subscription: { __typename?: 'subscription', eligibility_score?: number | null }, trade: { __typename?: 'trade', validity: number, bid_price: any } }>, trade: Array<{ __typename?: 'trade', bid_price: any, payout_value?: any | null, fees?: any | null, status: Types.Ref_Trade_Status_Enum, available_investment_amount?: any | null, validity: number, investments: Array<{ __typename?: 'investment', actual_irr?: any | null, invested_amount?: any | null, investor: { __typename?: 'investor', name: string, id: number, max_amount_per_trade?: number | null, max_amount_per_company?: number | null } }> }>, subscription_aggregate: { __typename?: 'subscription_aggregate', aggregate?: { __typename?: 'subscription_aggregate_fields', count: number } | null } };

export type GetAllSubscriptionByCompanyIdQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Subscription_Bool_Exp>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  order_by?: Types.InputMaybe<Array<Types.Subscription_Order_By> | Types.Subscription_Order_By>;
  selected_where?: Types.InputMaybe<Types.Trade_Subscription_Bool_Exp>;
  trade_where?: Types.InputMaybe<Types.Trade_Bool_Exp>;
  agg_where?: Types.InputMaybe<Types.Subscription_Bool_Exp>;
}>;


export type GetAllSubscriptionByCompanyIdQuery = { __typename?: 'query_root', subscription: Array<{ __typename?: 'subscription', term_end?: any | null, amount_per_period: any, customer_name?: string | null, customer_created: any, id: number, company_id: number, eligibility_score?: number | null, customer_identifier: string, customer_email: string, term_start: any, status: Types.Ref_Subscription_Status_Enum, billing_period: string, billing_period_interval: number, currency: string, tax_inclusive: boolean, tax_rate?: any | null, amount_per_month: any }> };


export const AttachContractsDocument = gql`
    mutation attachContracts($args: attachContractsToTradeInput!) {
  attachContractsToTrade(args: $args) {
    message
  }
}
    `;
export type AttachContractsMutationFn = Apollo.MutationFunction<AttachContractsMutation, AttachContractsMutationVariables>;

/**
 * __useAttachContractsMutation__
 *
 * To run a mutation, you first call `useAttachContractsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachContractsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachContractsMutation, { data, loading, error }] = useAttachContractsMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useAttachContractsMutation(baseOptions?: Apollo.MutationHookOptions<AttachContractsMutation, AttachContractsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttachContractsMutation, AttachContractsMutationVariables>(AttachContractsDocument, options);
      }
export type AttachContractsMutationHookResult = ReturnType<typeof useAttachContractsMutation>;
export type AttachContractsMutationResult = Apollo.MutationResult<AttachContractsMutation>;
export type AttachContractsMutationOptions = Apollo.BaseMutationOptions<AttachContractsMutation, AttachContractsMutationVariables>;
export const GetAllContractsByCompanyIdDocument = gql`
    query getAllContractsByCompanyId($where: subscription_bool_exp, $offset: Int, $limit: Int, $order_by: [subscription_order_by!], $selected_where: trade_subscription_bool_exp, $trade_where: trade_bool_exp, $agg_where: subscription_bool_exp) {
  subscription(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
    term_end
    amount_per_period
    customer_name
    customer_created
    id
    company_id
    eligibility_score
    customer_identifier
    customer_email
    term_start
    status
    billing_period
    billing_period_interval
    currency
    tax_inclusive
    tax_rate
    amount_per_month
    eligibility_score
  }
  trade_subscription(where: $selected_where, order_by: {subscription_id: desc}) {
    id
    amount_per_month
    subscription_id
    company_id
    subscription {
      eligibility_score
    }
    trade {
      validity
      bid_price
    }
  }
  trade(where: $trade_where) {
    bid_price
    payout_value
    fees
    status
    available_investment_amount
    validity
    investments {
      actual_irr
      invested_amount
      investor {
        name
        id
        max_amount_per_trade
        max_amount_per_company
      }
    }
  }
  subscription_aggregate(where: $agg_where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllContractsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetAllContractsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllContractsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllContractsByCompanyIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *      selected_where: // value for 'selected_where'
 *      trade_where: // value for 'trade_where'
 *      agg_where: // value for 'agg_where'
 *   },
 * });
 */
export function useGetAllContractsByCompanyIdQuery(baseOptions?: Apollo.QueryHookOptions<GetAllContractsByCompanyIdQuery, GetAllContractsByCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllContractsByCompanyIdQuery, GetAllContractsByCompanyIdQueryVariables>(GetAllContractsByCompanyIdDocument, options);
      }
export function useGetAllContractsByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllContractsByCompanyIdQuery, GetAllContractsByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllContractsByCompanyIdQuery, GetAllContractsByCompanyIdQueryVariables>(GetAllContractsByCompanyIdDocument, options);
        }
export type GetAllContractsByCompanyIdQueryHookResult = ReturnType<typeof useGetAllContractsByCompanyIdQuery>;
export type GetAllContractsByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetAllContractsByCompanyIdLazyQuery>;
export type GetAllContractsByCompanyIdQueryResult = Apollo.QueryResult<GetAllContractsByCompanyIdQuery, GetAllContractsByCompanyIdQueryVariables>;
export const GetAllSubscriptionByCompanyIdDocument = gql`
    query getAllSubscriptionByCompanyId($where: subscription_bool_exp, $offset: Int, $limit: Int, $order_by: [subscription_order_by!], $selected_where: trade_subscription_bool_exp, $trade_where: trade_bool_exp, $agg_where: subscription_bool_exp) {
  subscription(where: $where) {
    term_end
    amount_per_period
    customer_name
    customer_created
    id
    company_id
    eligibility_score
    customer_identifier
    customer_email
    term_start
    status
    billing_period
    billing_period_interval
    currency
    tax_inclusive
    tax_rate
    amount_per_month
    eligibility_score
  }
}
    `;

/**
 * __useGetAllSubscriptionByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetAllSubscriptionByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSubscriptionByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSubscriptionByCompanyIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *      selected_where: // value for 'selected_where'
 *      trade_where: // value for 'trade_where'
 *      agg_where: // value for 'agg_where'
 *   },
 * });
 */
export function useGetAllSubscriptionByCompanyIdQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSubscriptionByCompanyIdQuery, GetAllSubscriptionByCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSubscriptionByCompanyIdQuery, GetAllSubscriptionByCompanyIdQueryVariables>(GetAllSubscriptionByCompanyIdDocument, options);
      }
export function useGetAllSubscriptionByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSubscriptionByCompanyIdQuery, GetAllSubscriptionByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSubscriptionByCompanyIdQuery, GetAllSubscriptionByCompanyIdQueryVariables>(GetAllSubscriptionByCompanyIdDocument, options);
        }
export type GetAllSubscriptionByCompanyIdQueryHookResult = ReturnType<typeof useGetAllSubscriptionByCompanyIdQuery>;
export type GetAllSubscriptionByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetAllSubscriptionByCompanyIdLazyQuery>;
export type GetAllSubscriptionByCompanyIdQueryResult = Apollo.QueryResult<GetAllSubscriptionByCompanyIdQuery, GetAllSubscriptionByCompanyIdQueryVariables>;
export const namedOperations = {
  Query: {
    getAllContractsByCompanyId: 'getAllContractsByCompanyId',
    getAllSubscriptionByCompanyId: 'getAllSubscriptionByCompanyId'
  },
  Mutation: {
    attachContracts: 'attachContracts'
  }
}