import React from "react";
import {
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { ReactComponent as PencilIcon } from "../../../assets/Icon/Outline/pencil-icon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/Icon/Outline/plus-icon.svg";
import InvestorModal from "../InvestorsModals/InvestorModal";
import { useParams } from "react-router-dom";

interface InvestorUsersInterface {
  id: number;
  pan: string;
  investor_id: number;
  last_name: string;
  first_name: string;
  email: string;
  mobile_number: string;
  designation: string;
  user_id: number;
}

const UserTable = ({ investorUsers, getInvestorRefetch }: any) => {
  const { investor_id } = useParams();

  const [addUserModal, setAddUserModal] = React.useState(false);

  const [editData, setEditData] = React.useState<any>();

  return (
    <>
      <Grid container xs={12}>
        <Grid
          item
          xs={12}
          p={"16px"}
          display="flex"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography fontWeight={600} fontSize="16px">
            Added User
          </Typography>
          <Button
            onClick={() => {
              setEditData({});
              setAddUserModal(true);
            }}
            variant="secondarySmall"
          >
            {" "}
            <PlusIcon width={14} height={14} /> Add user
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Table>
            {investorUsers?.map(
              (item: InvestorUsersInterface, index: number) => (
                <TableHead key={index}>
                  <TableRow
                    style={{
                      background: "#F8F8F8",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "0 16px 16px 16px",
                    }}
                  >
                    <TableCell
                      style={{
                        border: "none",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span>User First Name</span>
                      <span style={{ fontWeight: 400 }}>
                        {item.first_name || "-"}
                      </span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span>User Last Name</span>
                      <span style={{ fontWeight: 400 }}>
                        {item.last_name || "-"}
                      </span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span>User PAN</span>
                      <span style={{ fontWeight: 400 }}>{item.pan || "-"}</span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span>User Email</span>
                      <span style={{ fontWeight: 400 }}>
                        {item.email || "-"}
                      </span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span>Mobile</span>
                      <span style={{ fontWeight: 400 }}>
                        {item.mobile_number || "-"}
                      </span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span>User Designation</span>
                      <span style={{ fontWeight: 400 }}>
                        {item.designation || "-"}
                      </span>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span></span>
                      <span>
                        <IconButton
                          onClick={() => {
                            setEditData(item);
                            setAddUserModal(true);
                          }}
                          color="primary"
                        >
                          <PencilIcon width={16} height={16} color="#1E88E5" />
                        </IconButton>
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
              )
            )}
          </Table>
        </Grid>
      </Grid>
      {addUserModal && (
        <InvestorModal
          setOpen={setAddUserModal}
          currentStep={1}
          open={addUserModal}
          modalType="standalone"
          setEditData={setEditData}
          editData={editData}
          investorId={Number(investor_id)}
          getInvestorRefetch={getInvestorRefetch}
        />
      )}
    </>
  );
};

export default UserTable;
