import { ReactComponent as Logo } from "../../vendor/logo.svg";
import styled from "styled-components/macro";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Button,
  CircularProgress,
  Typography,
  Grid,
  FormControl,
  Alert as MuiAlert,
} from "@mui/material";
import SimpleInput from "../../components/input/SimpleInput";
import { setPassword } from "../../services/auth";
import { spacing } from "@mui/system";
import { useState } from "react";

const Alert = styled(MuiAlert)(spacing);

const MuiButton = styled(Button)`
  &:disabled {
    background: #dae9fa;
  }
`;
const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 128px;
  height: 64px;
  margin-bottom: 32px;
`;

const ForgotPassword = () => {
  const { token } = useParams();

  const navigate = useNavigate();

  const [errorMatch, setErrorMatch] = useState("");

  const [isSubmited, setSubmitted] = useState(false);

  return (
    <>
      <Brand />
      <Grid
        container
        width={"100%"}
        style={{ background: "white" }}
        padding={10}
      >
        <Grid item width={"100%"}>
          <Typography fontSize={"22px"} fontWeight={700}>
            Set Password
          </Typography>
          <Formik
            initialValues={{
              password: "",
              confirm_password: "",
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .required("Password is required")
                .matches(
                  /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
                  "Please enter at least 8 characters, 1 Uppercase, 1 Lowercase, 1 Number & 1 special character. "
                ),
              confirm_password: Yup.string().required(
                "Confirm Password is required"
              ),
            })}
            onSubmit={async (values) => {
              setSubmitted(true);
              if (values.confirm_password === values.password) {
                //@ts-ignore
                setPassword(values.password, token)
                  .then(() => {
                    navigate("/auth/sign-in");
                    setSubmitted(false);
                  })
                  .catch(() => {
                    setErrorMatch(
                      "Something went wrong. Please try it again later."
                    );
                    setSubmitted(false);
                  });
              } else {
                setErrorMatch("Password doesn't match");
                setSubmitted(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <>
                <Alert mt={2} mb={3} severity="warning">
                  Please use a strong password so that your account stays secure
                </Alert>
                {errorMatch !== "" && (
                  <Alert mt={2} mb={3} severity="error">
                    {errorMatch}
                  </Alert>
                )}
                <form
                  noValidate
                  onSubmit={handleSubmit}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <FormControl fullWidth>
                    <SimpleInput
                      inputlabel="Password"
                      name="password"
                      type="password"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.password}
                      helperText={touched.password && errors.password}
                      error={touched.password && Boolean(errors.password)}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <SimpleInput
                      inputlabel="Confirm New Password"
                      name="confirm_password"
                      type="password"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.confirm_password}
                      error={
                        touched.confirm_password &&
                        Boolean(errors.confirm_password)
                      }
                      helperText={
                        touched.confirm_password && errors.confirm_password
                      }
                    />
                  </FormControl>
                  <MuiButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmited}
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    {isSubmited ? (
                      <CircularProgress size={25} />
                    ) : (
                      "Set Password"
                    )}
                  </MuiButton>
                </form>
              </>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotPassword;
