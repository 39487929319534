import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  Paper,
  Grid,
  Button,
  Checkbox,
} from "@mui/material";
import { TableWrapper } from "../../../theme/customComponents";
import { format } from "date-fns";
import InititateTdsModal from "./InititateTdsModal";
import TableStatus from "../../../components/TableStatus";

const EmiSummary = ({
  allTradeData,
  emiStaus,
  availableBalance,
  GetTDSRefetch,
}: any) => {
  const [openInitiatTdsModal, setOpenInitiatTdsModal] = useState(false);

  const [selectedTDS, setSelectedTDS] = useState<any[]>([]);

  /**************************************************************************************************************************************** */
  function handleSelectedTDS(event: any, item: any) {
    if (event.target.checked) {
      setSelectedTDS([...selectedTDS, item]);
    } else {
      const filterData = selectedTDS?.filter(
        (dataItem: any) => item.id !== dataItem.id
      );
      setSelectedTDS(filterData);
    }
  }

  /**************************************************************************************************************************************** */
  const isSelected = (id: number) =>
    selectedTDS?.length !== 0 &&
    selectedTDS?.findIndex((item: any) => item.id === id) !== -1;
  const markedTds = (item: any) => {
    return item?.emi_tds[0]?.tds_id ? true : false;
  };
  return (
    <>
      <TableWrapper>
        <Paper>
          <Grid
            container
            display="flex"
            alignItems={"center"}
            justifyContent="space-between"
            p={3}
          >
            <Typography fontSize={18} fontWeight={600}>
              EMI Summary
            </Typography>
            <Button
              onClick={() => {
                setOpenInitiatTdsModal(true);
              }}
              variant="primarySmall"
              disabled={selectedTDS?.length === 0}
            >
              Initiate TDS Settlement
            </Button>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectedTDS(
                          allTradeData?.scheduled_emis?.filter(
                            (data: any) =>
                              data?.status?.toLowerCase() === "completed"
                          )
                        );
                      } else {
                        setSelectedTDS([]);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>EMI ID</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Transaction Date</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Penal Interest</TableCell>
                <TableCell>Bounce Charges</TableCell>
                <TableCell>TDS Amount</TableCell>
                <TableCell>TDS ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allTradeData?.scheduled_emis?.map((item: any) => (
                <TableRow>
                  <TableCell>
                    {item?.status?.toLowerCase() === "completed" && (
                      <Checkbox
                        disabled={markedTds(item)}
                        checked={isSelected(item.id)}
                        onChange={(event) => {
                          handleSelectedTDS(event, item);
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell>{item?.id ?? "-"}</TableCell>
                  <TableCell>{item?.amount ?? "-"}</TableCell>
                  <TableCell>
                    {TableStatus({
                      stautsText: `${item?.status}`,
                    })}
                  </TableCell>
                  <TableCell>
                    {format(Date.parse(item?.scheduled_at), "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell>{item?.transactions[0]?.id ?? "-"}</TableCell>
                  <TableCell>
                    {item?.penal_interest.toFixed(2) ?? "-"}
                  </TableCell>
                  <TableCell>{item?.bounce_charges ?? "-"}</TableCell>
                  <TableCell>{`₹ ${item?.tds}` ?? "-"}</TableCell>
                  <TableCell>{item?.emi_tds[0]?.tds_id ?? "-"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </TableWrapper>
      {openInitiatTdsModal && (
        <InititateTdsModal
          setOpen={setOpenInitiatTdsModal}
          selectedTDS={selectedTDS}
          availableBalance={availableBalance}
          open={openInitiatTdsModal}
          GetTDSRefetch={GetTDSRefetch}
        />
      )}
    </>
  );
};

export default EmiSummary;
