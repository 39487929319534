import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SimpleInput from "../../../components/input/SimpleInput";
import { ReactComponent as DeleteIcon } from "../../../assets/Icon/Outline/trash-icon.svg";
import { Box } from "@mui/system";

const AddUsers = ({
  userArray,
  expanded,
  setExpanded,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  removeUserForm,
  editData,
}: any) => {
  return (
    userArray &&
    userArray?.length !== 0 &&
    userArray?.map((item: any, index: number) => (
      <Accordion
        expanded={expanded === index}
        onChange={() => setExpanded(expanded === index ? -1 : index)}
        key={index}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={"panel1a-content_" + index}
          id={"header" + index}
        >
          <Box display={"flex"} flex={1} justifyContent="space-between">
            <Typography fontWeight={600} variant="body2">
              Added User {index + 1}:{" "}
              {values?.users[index]?.first_name +
                " " +
                values?.users[index]?.last_name}
            </Typography>

            {userArray.length > 1 && (
              <IconButton onClick={() => removeUserForm(index)}>
                <DeleteIcon color={"#DD3838"} width={20} height={20} />
              </IconButton>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <SimpleInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.users[index].first_name}
                  name={`users.${index}.first_name`}
                  id="first_name"
                  placeholder="Enter First Name"
                  inputlabel="First Name"
                  requiredlabel
                  error={
                    touched &&
                    touched?.users &&
                    touched?.users[index] &&
                    touched?.users[index].first_name &&
                    Boolean(
                      errors &&
                        errors?.users &&
                        errors?.users[index] &&
                        errors?.users[index].first_name
                    )
                  }
                  helperText={
                    touched &&
                    touched?.users &&
                    touched?.users[index] &&
                    touched?.users[index].first_name &&
                    errors &&
                    errors?.users &&
                    errors?.users[index] &&
                    errors?.users[index].first_name
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <SimpleInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.users[index].last_name}
                  name={`users.${index}.last_name`}
                  id="last_name"
                  placeholder="Enter the Last Name"
                  inputlabel="Last Name"
                  error={
                    touched &&
                    touched?.users &&
                    touched?.users[index] &&
                    touched?.users[index].last_name &&
                    Boolean(
                      errors &&
                        errors?.users &&
                        errors?.users[index] &&
                        errors?.users[index].last_name
                    )
                  }
                  helperText={
                    touched &&
                    touched?.users &&
                    touched?.users[index] &&
                    touched?.users[index].last_name &&
                    errors &&
                    errors?.users &&
                    errors?.users[index] &&
                    errors?.users[index].last_name
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <SimpleInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.users[index].pan}
                  name={`users.${index}.pan`}
                  id="pan"
                  placeholder="Enter User Pan"
                  inputlabel="User Pan"
                  error={
                    touched &&
                    touched?.users &&
                    touched?.users[index] &&
                    touched?.users[index].pan &&
                    Boolean(
                      errors &&
                        errors?.users &&
                        errors?.users[index] &&
                        errors?.users[index].pan
                    )
                  }
                  helperText={
                    touched &&
                    touched?.users &&
                    touched?.users[index] &&
                    touched?.users[index].pan &&
                    errors &&
                    errors?.users &&
                    errors?.users[index] &&
                    errors?.users[index].pan
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <SimpleInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.users[index].email}
                  name={`users.${index}.email`}
                  id="email"
                  disabled={editData?.id ? true : false}
                  placeholder="Enter Email"
                  inputlabel="User Email"
                  requiredlabel
                  error={
                    touched &&
                    touched?.users &&
                    touched?.users[index] &&
                    touched?.users[index].email &&
                    Boolean(
                      errors &&
                        errors?.users &&
                        errors?.users[index] &&
                        errors?.users[index].email
                    )
                  }
                  helperText={
                    touched &&
                    touched?.users &&
                    touched?.users[index] &&
                    touched?.users[index].email &&
                    errors &&
                    errors?.users &&
                    errors?.users[index] &&
                    errors?.users[index].email
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <SimpleInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.users[index].mobile_number}
                  name={`users.${index}.mobile_number`}
                  id="mobile_number"
                  placeholder="Enter Mobile Number"
                  inputlabel="Mobile"
                  requiredlabel
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <Typography
                        fontWeight={500}
                        style={{ marginLeft: "6px" }}
                      >
                        +91
                      </Typography>
                    ),
                  }}
                  error={
                    touched &&
                    touched?.users &&
                    touched?.users[index] &&
                    touched?.users[index].mobile_number &&
                    Boolean(
                      errors &&
                        errors?.users &&
                        errors?.users[index] &&
                        errors?.users[index].mobile_number
                    )
                  }
                  helperText={
                    touched &&
                    touched?.users &&
                    touched?.users[index] &&
                    touched?.users[index].mobile_number &&
                    errors &&
                    errors?.users &&
                    errors?.users[index] &&
                    errors?.users[index].mobile_number
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <SimpleInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.users[index].designation}
                  name={`users.${index}.designation`}
                  id="designation"
                  placeholder="Enter User Designation*"
                  inputlabel="User Designation"
                  requiredlabel
                  error={
                    touched &&
                    touched?.users &&
                    touched?.users[index] &&
                    touched?.users[index].designation &&
                    Boolean(
                      errors &&
                        errors?.users &&
                        errors?.users[index] &&
                        errors?.users[index].designation
                    )
                  }
                  helperText={
                    touched &&
                    touched?.users &&
                    touched?.users[index] &&
                    touched?.users[index].designation &&
                    errors &&
                    errors?.users &&
                    errors?.users[index] &&
                    errors?.users[index].designation
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    ))
  );
};

export default AddUsers;
