import {
  Divider,
  Grid,
  Typography,
  TextField as MuiTextField,
  InputAdornment,
  Button,
  CircularProgress,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useContext, useState } from "react";
import {
  useEndCompanyOfferMutation,
  useGetAllDealsQuery,
  useGetAllOffersLazyQuery,
} from "../../data-access/hooks/company/deals-trade.hooks";
import DealsTable from "./DealsTable";
import styled from "@emotion/styled";
import AddOfferModal from "./AddOfferModal";
import OffersTable from "./OffersTable";
import { Order_By } from "../../data-access/types";
import { ReactComponent as SearchIcon } from "../../assets/Icon/Outline/search-icon.svg";
import { CompanyContext } from "../../contexts/CompanyContext";

const SearchTextField = styled(MuiTextField)`
  width: 400px;
  background: white;
  & .MuiOutlinedInput-input {
    background: white;
    height: 40px;
    padding: 0 10px 0 0;
  }
`;

interface SelectedOffersDataInterface {
  id: Number;
  investor_id: Number;
  interest_rate: Number;
  name: String;
  amount: any;
}

const GlobalDeals = () => {
  const [alldealsData, setAllDealsData] = useState<any>();

  const [selected, setSelected] = useState<number>();

  const [dealId, setDealId] = useState<number>();

  const [showAddModal, setShowAddModal] = useState(false);

  const [paginationData, setPaginationData] = useState({
    offset: 0,
    limit: 50,
    totalcount: 0,
    currentPage: 1,
  });

  const { setShowAlert } = useContext(CompanyContext);

  const [offerPaginationData, setOfferPaginationData] = useState({
    offset: 0,
    limit: 50,
    totalcount: 0,
    currentPage: 1,
  });

  const [selectedOffers, setSelectedOffers] = useState<
    SelectedOffersDataInterface[]
  >([]);

  const [whereClause, setWhereClause] = useState<any>();

  const [allOfferData, setAllOfferData] = useState<any>();

  /**---------------------------------------------------------------------------------------- */
  const [getOffersById, { loading: offersLoading, refetch: offerRefetch }] =
    useGetAllOffersLazyQuery({
      onCompleted: (item) => {
        setOfferPaginationData({
          ...offerPaginationData,
          totalcount: Number(item.bid_aggregate.aggregate?.count),
        });
        setAllOfferData(item.bid);
        const element = document.getElementById("section-1");
        if (element && !offersLoading) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      },
      fetchPolicy: "no-cache",
    });

  /**---------------------------------------------------------------------------------------- */
  const [handleEndOffer, { loading: endOfferLoading }] =
    useEndCompanyOfferMutation({
      onCompleted: () => {
        setShowAlert({
          show: true,
          message: "Offer successfully ended",
          type: "success",
        });
        window.location.reload();
      },
      onError: (error) => {
        setShowAlert({
          show: true,
          message: error.message,
          type: "error",
        });
      },
    });
  /**---------------------------------------------------------------------------------------- */
  const { loading: GetDealsLoading } = useGetAllDealsQuery({
    variables: {
      limit: paginationData.limit,
      offset: paginationData.offset,
      where: whereClause,
      order_by: {
        bid_closure: Order_By.Desc,
      },
    },
    onCompleted: (item) => {
      const data = item.deal.map((response) => {
        return {
          deal_id: response.id,
          score_id: response.company_score.id,
          company_name: response.company.name,
          company_id: response.company.id,
          status: response.status,
          created_at: response.created_at,
          deal_expiry: response.deal_expiry,
          offer_closure: response.bid_closure,
          arr: response.company_score.arr,
          facility_limit: response.company_score.facility_limit,
          min_bid_price: response.company_score.min_bid_price,
          max_recommended_bid: response.company_score.max_recommended_bid,
          min_recommended_bid: response.company_score.min_recommended_bid,
          business_growth_score: response.company_score.business_growth_score,
          customer_retention: response.company_score.customer_retention,
          customer_value_score: response.company_score.customer_value_score,
          financials_score: response.company_score.financials_score,
          gross_margin: response.company_score.gross_margin,
          management_score: response.company_score.management_score,
          payback_period: response.company_score.payback_period,
          revenue_retention: response.company_score.revenue_retention,
          estimated_runway: response.company_score.estimated_runway,
          company_score_id: response.company_score.id,
          ltv_cac: response.company_score.ltv_cac,
          capital_efficiency_score:
            response.company_score.capital_efficiency_score,
          scoring_version: response.company_score.version,
          total_offers: response.bids_aggregate.aggregate?.count,
          top_offer: response.bids_aggregate.aggregate?.max?.price,
        };
      });
      setAllDealsData(data);
      setPaginationData({
        ...paginationData,
        totalcount: Number(item.deal_aggregate.aggregate?.count),
      });
    },
    fetchPolicy: "no-cache",
  });

  /**---------------------------------------------------------------------------------------- */

  const today = new Date();

  return (
    <>
      <Helmet title="Dashboard" />
      <Grid
        justifyContent="space-between"
        display={"flex"}
        alignItems={"center"}
        container
      >
        <Grid item xs={6}>
          <Typography fontSize={"24px"} fontWeight={600}>
            Deals
          </Typography>
        </Grid>
        <Grid
          container
          xs={6}
          item
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"10px"}
        >
          <SearchTextField
            placeholder="Search with Company Name or ID"
            variant="outlined"
            fullWidth
            type={"search"}
            onChange={(event) => {
              if (event.target.value) {
                setWhereClause({
                  _or: [
                    {
                      company: {
                        id: {
                          _eq: parseInt(event.target.value) || 0,
                        },
                      },
                    },
                    {
                      company: {
                        name: {
                          _ilike: `%${event.target.value}%`,
                        },
                      },
                    },
                  ],
                });
              } else {
                setWhereClause({});
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon width="16px" height="16px" color="#666666" />
                </InputAdornment>
              ),
            }}
          />
          <Button
            disabled={!selected}
            onClick={() => setShowAddModal(true)}
            variant="primaryMedium"
          >
            Add Bid/Offer
          </Button>
          <Button
            onClick={() =>
              handleEndOffer({
                variables: {
                  //@ts-ignore
                  deal_id: dealId,
                  bid_closure: today,
                },
              })
            }
            disabled={!selected || endOfferLoading}
            variant="primaryMedium"
          >
            {endOfferLoading ? <CircularProgress size={20} /> : "Close Deal"}
          </Button>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" style={{ margin: "16px 0 28px 0" }} />

      {/**---------------------------------------------------------------------------------------- */}
      <DealsTable
        data={alldealsData}
        loading={GetDealsLoading}
        selectedRow={dealId}
        onSelect={setSelected}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
        getOffersById={getOffersById}
        setDealId={setDealId}
        offerPaginationData={offerPaginationData}
      />
      <br />
      {/**---------------------------------------------------------------------------------------- */}
      {selected && allOfferData && !offersLoading && (
        <OffersTable
          data={allOfferData}
          loading={offersLoading}
          selectedDeal={selected}
          paginationData={offerPaginationData}
          getOffersById={getOffersById}
          setPaginationData={setOfferPaginationData}
          setShowAddModal={setShowAddModal}
          dealId={dealId}
          selectedOffers={selectedOffers}
          setSelectedOffers={setSelectedOffers}
          offerRefetch={offerRefetch}
        />
      )}

      {/**---------------------------------------------------------------------------------------- */}
      <AddOfferModal
        onClose={setShowAddModal}
        dealId={dealId}
        open={showAddModal}
        offerRefetch={offerRefetch}
      />
    </>
  );
};

export default GlobalDeals;
