import styled from "styled-components/macro";
import { CircularProgress, Typography } from "@mui/material";
import { CommonOpsColors } from "../utils/common";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  min-height: calc(100vh - 16px);
  gap: 10px;
`;

function Loader() {
  return (
    <Root>
      <CircularProgress color="primary" />
      <Typography
        fontWeight={700}
        fontSize={16}
        color={CommonOpsColors.primary}
      >
        Hold on we are fetching data that you are looking for.
      </Typography>
    </Root>
  );
}

export default Loader;
