import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonBreadCrumb from "../../../components/CommonBreadCrumb";
import CommonLoader from "../../../components/CommonLoader";
import EmptyData from "../../../components/EmptyData";
import { useGetAllAccountIntegerationDataQuery } from "../../../data-access/hooks/company/account-data.hooks";
import AccountingDataTable from "./AccountingDataTable";

const AccountingData = () => {
  const { id: company_id } = useParams();

  const navigate = useNavigate();

  const [paginationData, setPaginationData] = useState({
    offset: 0,
    limit: 50,
    totalcount: 0,
    currentPage: 1,
  });

  const [allAccountData, setAllAccountData] = useState<any[]>([]);

  const { loading: getAllLoading, refetch: getAllRefetch } =
    useGetAllAccountIntegerationDataQuery({
      variables: {
        where: {
          company_id: {
            _eq: Number(company_id),
          },
          category: {
            _eq: "ACCOUNTING",
          },
        },
        offset: paginationData.offset,
        limit: paginationData.limit,
      },
      onCompleted: (item) => {
        setAllAccountData(item.company_integration);
        setPaginationData({
          ...paginationData,
          totalcount: Number(
            item.company_integration_aggregate.aggregate?.count
          ),
        });
      },
      fetchPolicy: "no-cache",
    });

  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <CommonBreadCrumb
          typographyText="Accounting Data"
          linksText={[{ link: "  Company Queues", to: "/company-queues" }]}
          showCompany
        />
      </Grid>
      <Grid item xs={12} mt="16px" mb="28px">
        <Divider />
      </Grid>
      <Paper style={{ width: "100%" }}>
        <Grid
          item
          xs={12}
          style={{ background: "white" }}
          display="flex"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography p={5} fontWeight={600} fontSize={"18px"}>
            Integration History
          </Typography>
          <Button
            style={{ margin: "20px" }}
            variant="secondarySmall"
            onClick={() => navigate(`/company/${company_id}/add-account-data`)}
          >
            Add Accounting Data
          </Button>
        </Grid>
        {getAllLoading ? (
          <CommonLoader />
        ) : (
          <Grid item xs={12}>
            {allAccountData?.length !== 0 ? (
              <AccountingDataTable
                allAccountData={allAccountData}
                paginationData={paginationData}
                setPaginationData={setPaginationData}
                getAllRefetch={getAllRefetch}
              />
            ) : (
              <EmptyData>
                <Button
                  onClick={() =>
                    navigate(`/company/${company_id}/add-account-data`, {
                      state: getAllRefetch,
                    })
                  }
                >
                  Accounting Data
                </Button>
              </EmptyData>
            )}
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

export default AccountingData;
