import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllDashboardCompaniesQueryVariables = Types.Exact<{
  where: Types.Application_Lifecycle_Stage_Bool_Exp;
}>;


export type GetAllDashboardCompaniesQuery = { __typename?: 'query_root', application_lifecycle_stage: Array<{ __typename?: 'application_lifecycle_stage', stage: Types.Ref_Application_Lifecycle_Stage_Enum, company?: { __typename?: 'company', created_at: any, estimated_arr?: any | null, domain: string, status: Types.Ref_Company_Status_Enum, name?: string | null, id: number, company_users: Array<{ __typename?: 'company_user', user: { __typename?: 'user', first_name: string, last_name: string, id: number, mobile_number: string } }> } | null }> };

export type GetLifecycleQuestionResultQueryVariables = Types.Exact<{
  where: Types.Lifecycle_Question_Result_Bool_Exp;
}>;


export type GetLifecycleQuestionResultQuery = { __typename?: 'query_root', lifecycle_question_result: Array<{ __typename?: 'lifecycle_question_result', answer: any, category: Types.Ref_Lifecycle_Question_Category_Enum, id: number, notes?: string | null }> };

export type ApplicationLifecycleStageQueryVariables = Types.Exact<{
  where: Types.Application_Lifecycle_Stage_Bool_Exp;
  order_by?: Types.InputMaybe<Array<Types.Application_Lifecycle_Stage_Order_By> | Types.Application_Lifecycle_Stage_Order_By>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ApplicationLifecycleStageQuery = { __typename?: 'query_root', application_lifecycle_stage: Array<{ __typename?: 'application_lifecycle_stage', reject_reason?: string | null, id: number, investor_type?: Types.Ref_Investor_Type_Enum | null, remarks?: string | null, age?: number | null, offer_limit?: number | null, offer_limit_remarks?: string | null, has_financial_integration?: boolean | null, priority?: number | null, likeliness?: Types.Ref_Company_Likeliness_Enum | null, stage: Types.Ref_Application_Lifecycle_Stage_Enum, created_at: any, lifecycle_question_result_id?: number | null, company?: { __typename?: 'company', name?: string | null, id: number, sales_user?: { __typename?: 'user', first_name: string, last_name: string, id: number } | null, ops_user?: { __typename?: 'user', first_name: string, last_name: string, id: number } | null } | null, lifecycle_question_result?: { __typename?: 'lifecycle_question_result', answer: any, category: Types.Ref_Lifecycle_Question_Category_Enum, id: number } | null }> };

export type ApplicationLifecycleStageCountQueryVariables = Types.Exact<{
  where: Types.Application_Lifecycle_Stage_Bool_Exp;
}>;


export type ApplicationLifecycleStageCountQuery = { __typename?: 'query_root', application_lifecycle_stage_aggregate: { __typename?: 'application_lifecycle_stage_aggregate', aggregate?: { __typename?: 'application_lifecycle_stage_aggregate_fields', count: number } | null } };

export type UpdateLifecycleQuestionResultByPkMutationVariables = Types.Exact<{
  pk_columns: Types.Lifecycle_Question_Result_Pk_Columns_Input;
}>;


export type UpdateLifecycleQuestionResultByPkMutation = { __typename?: 'mutation_root', update_lifecycle_question_result_by_pk?: { __typename?: 'lifecycle_question_result', notes?: string | null } | null };

export type ExportCompanyQueuesMutationVariables = Types.Exact<{
  args: Types.ExportCompanyQueuesInput;
}>;


export type ExportCompanyQueuesMutation = { __typename?: 'mutation_root', ExportCompanyQueues?: { __typename?: 'ExportCompanyQueuesOutput', message: string } | null };

export type GetCompanyQueueFullDetailsQueryVariables = Types.Exact<{
  company_id: Types.Scalars['Int'];
  where: Types.Application_Lifecycle_Stage_Bool_Exp;
}>;


export type GetCompanyQueueFullDetailsQuery = { __typename?: 'query_root', pql_answers: Array<{ __typename?: 'lifecycle_question_result', id: number, category: Types.Ref_Lifecycle_Question_Category_Enum, answer: any, notes?: string | null }>, sales_answers: Array<{ __typename?: 'lifecycle_question_result', id: number, category: Types.Ref_Lifecycle_Question_Category_Enum, notes?: string | null, answer: any }>, ops_answers: Array<{ __typename?: 'lifecycle_question_result', id: number, category: Types.Ref_Lifecycle_Question_Category_Enum, notes?: string | null, answer: any }>, stage: Array<{ __typename?: 'application_lifecycle_stage', stage: Types.Ref_Application_Lifecycle_Stage_Enum }> };

export type MoveCompanyInQueueMutationVariables = Types.Exact<{
  moveTo: Types.MoveToStages;
}>;


export type MoveCompanyInQueueMutation = { __typename?: 'mutation_root', MoveCompanyInQueue?: { __typename?: 'MoveCompanyInQueueOutput', message: string } | null };

export type UpdateOpsQuestionsDataMutationVariables = Types.Exact<{
  updates: Array<Types.Lifecycle_Question_Result_Updates> | Types.Lifecycle_Question_Result_Updates;
}>;


export type UpdateOpsQuestionsDataMutation = { __typename?: 'mutation_root', update_ops_and_sales_answers?: Array<{ __typename?: 'lifecycle_question_result_mutation_response', affected_rows: number } | null> | null };

export type UpdateOpsNotesMutationVariables = Types.Exact<{
  notes: Types.Scalars['String'];
  id: Types.Scalars['Int'];
}>;


export type UpdateOpsNotesMutation = { __typename?: 'mutation_root', update_ops?: { __typename?: 'lifecycle_question_result', id: number } | null };

export type UpdateCompanyQueueDataMutationVariables = Types.Exact<{
  updateProperties: Types.Application_Lifecycle_Stage_Set_Input;
  id: Types.Scalars['Int'];
}>;


export type UpdateCompanyQueueDataMutation = { __typename?: 'mutation_root', update_application_lifecycle_stage_by_pk?: { __typename?: 'application_lifecycle_stage', id: number } | null };

export type GetAllStaffUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllStaffUsersQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', id: number, first_name: string, last_name: string }> };

export type UpdateCompanyRmMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  ops_rm?: Types.InputMaybe<Types.Scalars['Int']>;
  sales_rm?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type UpdateCompanyRmMutation = { __typename?: 'mutation_root', update_company_by_pk?: { __typename?: 'company', id: number } | null };

export type CreateCompanyMutationVariables = Types.Exact<{
  args: Types.OpsCreateCompanyInput;
}>;


export type CreateCompanyMutation = { __typename?: 'mutation_root', OpsCreateCompany?: { __typename?: 'OpsCreateCompanyOutput', message: string, company_id: number } | null };


export const GetAllDashboardCompaniesDocument = gql`
    query getAllDashboardCompanies($where: application_lifecycle_stage_bool_exp!) {
  application_lifecycle_stage(
    where: $where
    limit: 5
    order_by: [{created_at: desc}]
  ) {
    stage
    company {
      created_at
      estimated_arr
      domain
      status
      name
      id
      company_users {
        user {
          first_name
          last_name
          id
          mobile_number
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllDashboardCompaniesQuery__
 *
 * To run a query within a React component, call `useGetAllDashboardCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDashboardCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDashboardCompaniesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllDashboardCompaniesQuery(baseOptions: Apollo.QueryHookOptions<GetAllDashboardCompaniesQuery, GetAllDashboardCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDashboardCompaniesQuery, GetAllDashboardCompaniesQueryVariables>(GetAllDashboardCompaniesDocument, options);
      }
export function useGetAllDashboardCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDashboardCompaniesQuery, GetAllDashboardCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDashboardCompaniesQuery, GetAllDashboardCompaniesQueryVariables>(GetAllDashboardCompaniesDocument, options);
        }
export type GetAllDashboardCompaniesQueryHookResult = ReturnType<typeof useGetAllDashboardCompaniesQuery>;
export type GetAllDashboardCompaniesLazyQueryHookResult = ReturnType<typeof useGetAllDashboardCompaniesLazyQuery>;
export type GetAllDashboardCompaniesQueryResult = Apollo.QueryResult<GetAllDashboardCompaniesQuery, GetAllDashboardCompaniesQueryVariables>;
export const GetLifecycleQuestionResultDocument = gql`
    query getLifecycleQuestionResult($where: lifecycle_question_result_bool_exp!) {
  lifecycle_question_result(where: $where) {
    answer
    category
    id
    notes
  }
}
    `;

/**
 * __useGetLifecycleQuestionResultQuery__
 *
 * To run a query within a React component, call `useGetLifecycleQuestionResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLifecycleQuestionResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLifecycleQuestionResultQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLifecycleQuestionResultQuery(baseOptions: Apollo.QueryHookOptions<GetLifecycleQuestionResultQuery, GetLifecycleQuestionResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLifecycleQuestionResultQuery, GetLifecycleQuestionResultQueryVariables>(GetLifecycleQuestionResultDocument, options);
      }
export function useGetLifecycleQuestionResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLifecycleQuestionResultQuery, GetLifecycleQuestionResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLifecycleQuestionResultQuery, GetLifecycleQuestionResultQueryVariables>(GetLifecycleQuestionResultDocument, options);
        }
export type GetLifecycleQuestionResultQueryHookResult = ReturnType<typeof useGetLifecycleQuestionResultQuery>;
export type GetLifecycleQuestionResultLazyQueryHookResult = ReturnType<typeof useGetLifecycleQuestionResultLazyQuery>;
export type GetLifecycleQuestionResultQueryResult = Apollo.QueryResult<GetLifecycleQuestionResultQuery, GetLifecycleQuestionResultQueryVariables>;
export const ApplicationLifecycleStageDocument = gql`
    query applicationLifecycleStage($where: application_lifecycle_stage_bool_exp!, $order_by: [application_lifecycle_stage_order_by!], $offset: Int, $limit: Int) {
  application_lifecycle_stage(
    where: $where
    order_by: $order_by
    offset: $offset
    limit: $limit
  ) {
    reject_reason
    id
    investor_type
    remarks
    age
    offer_limit
    offer_limit_remarks
    has_financial_integration
    priority
    likeliness
    stage
    created_at
    company {
      name
      id
      sales_user {
        first_name
        last_name
        id
      }
      ops_user {
        first_name
        last_name
        id
      }
    }
    lifecycle_question_result_id
    lifecycle_question_result {
      answer
      category
      id
    }
  }
}
    `;

/**
 * __useApplicationLifecycleStageQuery__
 *
 * To run a query within a React component, call `useApplicationLifecycleStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationLifecycleStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationLifecycleStageQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useApplicationLifecycleStageQuery(baseOptions: Apollo.QueryHookOptions<ApplicationLifecycleStageQuery, ApplicationLifecycleStageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationLifecycleStageQuery, ApplicationLifecycleStageQueryVariables>(ApplicationLifecycleStageDocument, options);
      }
export function useApplicationLifecycleStageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationLifecycleStageQuery, ApplicationLifecycleStageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationLifecycleStageQuery, ApplicationLifecycleStageQueryVariables>(ApplicationLifecycleStageDocument, options);
        }
export type ApplicationLifecycleStageQueryHookResult = ReturnType<typeof useApplicationLifecycleStageQuery>;
export type ApplicationLifecycleStageLazyQueryHookResult = ReturnType<typeof useApplicationLifecycleStageLazyQuery>;
export type ApplicationLifecycleStageQueryResult = Apollo.QueryResult<ApplicationLifecycleStageQuery, ApplicationLifecycleStageQueryVariables>;
export const ApplicationLifecycleStageCountDocument = gql`
    query applicationLifecycleStageCount($where: application_lifecycle_stage_bool_exp!) {
  application_lifecycle_stage_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useApplicationLifecycleStageCountQuery__
 *
 * To run a query within a React component, call `useApplicationLifecycleStageCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationLifecycleStageCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationLifecycleStageCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useApplicationLifecycleStageCountQuery(baseOptions: Apollo.QueryHookOptions<ApplicationLifecycleStageCountQuery, ApplicationLifecycleStageCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationLifecycleStageCountQuery, ApplicationLifecycleStageCountQueryVariables>(ApplicationLifecycleStageCountDocument, options);
      }
export function useApplicationLifecycleStageCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationLifecycleStageCountQuery, ApplicationLifecycleStageCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationLifecycleStageCountQuery, ApplicationLifecycleStageCountQueryVariables>(ApplicationLifecycleStageCountDocument, options);
        }
export type ApplicationLifecycleStageCountQueryHookResult = ReturnType<typeof useApplicationLifecycleStageCountQuery>;
export type ApplicationLifecycleStageCountLazyQueryHookResult = ReturnType<typeof useApplicationLifecycleStageCountLazyQuery>;
export type ApplicationLifecycleStageCountQueryResult = Apollo.QueryResult<ApplicationLifecycleStageCountQuery, ApplicationLifecycleStageCountQueryVariables>;
export const UpdateLifecycleQuestionResultByPkDocument = gql`
    mutation updateLifecycleQuestionResultByPk($pk_columns: lifecycle_question_result_pk_columns_input!) {
  update_lifecycle_question_result_by_pk(pk_columns: $pk_columns) {
    notes
  }
}
    `;
export type UpdateLifecycleQuestionResultByPkMutationFn = Apollo.MutationFunction<UpdateLifecycleQuestionResultByPkMutation, UpdateLifecycleQuestionResultByPkMutationVariables>;

/**
 * __useUpdateLifecycleQuestionResultByPkMutation__
 *
 * To run a mutation, you first call `useUpdateLifecycleQuestionResultByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLifecycleQuestionResultByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLifecycleQuestionResultByPkMutation, { data, loading, error }] = useUpdateLifecycleQuestionResultByPkMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *   },
 * });
 */
export function useUpdateLifecycleQuestionResultByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLifecycleQuestionResultByPkMutation, UpdateLifecycleQuestionResultByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLifecycleQuestionResultByPkMutation, UpdateLifecycleQuestionResultByPkMutationVariables>(UpdateLifecycleQuestionResultByPkDocument, options);
      }
export type UpdateLifecycleQuestionResultByPkMutationHookResult = ReturnType<typeof useUpdateLifecycleQuestionResultByPkMutation>;
export type UpdateLifecycleQuestionResultByPkMutationResult = Apollo.MutationResult<UpdateLifecycleQuestionResultByPkMutation>;
export type UpdateLifecycleQuestionResultByPkMutationOptions = Apollo.BaseMutationOptions<UpdateLifecycleQuestionResultByPkMutation, UpdateLifecycleQuestionResultByPkMutationVariables>;
export const ExportCompanyQueuesDocument = gql`
    mutation exportCompanyQueues($args: ExportCompanyQueuesInput!) {
  ExportCompanyQueues(args: $args) {
    message
  }
}
    `;
export type ExportCompanyQueuesMutationFn = Apollo.MutationFunction<ExportCompanyQueuesMutation, ExportCompanyQueuesMutationVariables>;

/**
 * __useExportCompanyQueuesMutation__
 *
 * To run a mutation, you first call `useExportCompanyQueuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportCompanyQueuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportCompanyQueuesMutation, { data, loading, error }] = useExportCompanyQueuesMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useExportCompanyQueuesMutation(baseOptions?: Apollo.MutationHookOptions<ExportCompanyQueuesMutation, ExportCompanyQueuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportCompanyQueuesMutation, ExportCompanyQueuesMutationVariables>(ExportCompanyQueuesDocument, options);
      }
export type ExportCompanyQueuesMutationHookResult = ReturnType<typeof useExportCompanyQueuesMutation>;
export type ExportCompanyQueuesMutationResult = Apollo.MutationResult<ExportCompanyQueuesMutation>;
export type ExportCompanyQueuesMutationOptions = Apollo.BaseMutationOptions<ExportCompanyQueuesMutation, ExportCompanyQueuesMutationVariables>;
export const GetCompanyQueueFullDetailsDocument = gql`
    query GetCompanyQueueFullDetails($company_id: Int!, $where: application_lifecycle_stage_bool_exp!) {
  pql_answers: lifecycle_question_result(
    where: {category: {_eq: USER}, company_id: {_eq: $company_id}}
    order_by: [{created_at: desc}]
    limit: 1
  ) {
    id
    category
    answer
    notes
  }
  sales_answers: lifecycle_question_result(
    where: {category: {_eq: SALES}, company_id: {_eq: $company_id}}
    order_by: [{created_at: desc}]
    limit: 1
  ) {
    id
    category
    notes
    answer
  }
  ops_answers: lifecycle_question_result(
    where: {category: {_eq: OPS}, company_id: {_eq: $company_id}}
    order_by: [{created_at: desc}]
    limit: 1
  ) {
    id
    category
    notes
    answer
  }
  stage: application_lifecycle_stage(where: $where) {
    stage
  }
}
    `;

/**
 * __useGetCompanyQueueFullDetailsQuery__
 *
 * To run a query within a React component, call `useGetCompanyQueueFullDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQueueFullDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQueueFullDetailsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCompanyQueueFullDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyQueueFullDetailsQuery, GetCompanyQueueFullDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyQueueFullDetailsQuery, GetCompanyQueueFullDetailsQueryVariables>(GetCompanyQueueFullDetailsDocument, options);
      }
export function useGetCompanyQueueFullDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQueueFullDetailsQuery, GetCompanyQueueFullDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyQueueFullDetailsQuery, GetCompanyQueueFullDetailsQueryVariables>(GetCompanyQueueFullDetailsDocument, options);
        }
export type GetCompanyQueueFullDetailsQueryHookResult = ReturnType<typeof useGetCompanyQueueFullDetailsQuery>;
export type GetCompanyQueueFullDetailsLazyQueryHookResult = ReturnType<typeof useGetCompanyQueueFullDetailsLazyQuery>;
export type GetCompanyQueueFullDetailsQueryResult = Apollo.QueryResult<GetCompanyQueueFullDetailsQuery, GetCompanyQueueFullDetailsQueryVariables>;
export const MoveCompanyInQueueDocument = gql`
    mutation moveCompanyInQueue($moveTo: moveToStages!) {
  MoveCompanyInQueue(args: {moveTo: $moveTo}) {
    message
  }
}
    `;
export type MoveCompanyInQueueMutationFn = Apollo.MutationFunction<MoveCompanyInQueueMutation, MoveCompanyInQueueMutationVariables>;

/**
 * __useMoveCompanyInQueueMutation__
 *
 * To run a mutation, you first call `useMoveCompanyInQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveCompanyInQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveCompanyInQueueMutation, { data, loading, error }] = useMoveCompanyInQueueMutation({
 *   variables: {
 *      moveTo: // value for 'moveTo'
 *   },
 * });
 */
export function useMoveCompanyInQueueMutation(baseOptions?: Apollo.MutationHookOptions<MoveCompanyInQueueMutation, MoveCompanyInQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveCompanyInQueueMutation, MoveCompanyInQueueMutationVariables>(MoveCompanyInQueueDocument, options);
      }
export type MoveCompanyInQueueMutationHookResult = ReturnType<typeof useMoveCompanyInQueueMutation>;
export type MoveCompanyInQueueMutationResult = Apollo.MutationResult<MoveCompanyInQueueMutation>;
export type MoveCompanyInQueueMutationOptions = Apollo.BaseMutationOptions<MoveCompanyInQueueMutation, MoveCompanyInQueueMutationVariables>;
export const UpdateOpsQuestionsDataDocument = gql`
    mutation updateOpsQuestionsData($updates: [lifecycle_question_result_updates!]!) {
  update_ops_and_sales_answers: update_lifecycle_question_result_many(
    updates: $updates
  ) {
    affected_rows
  }
}
    `;
export type UpdateOpsQuestionsDataMutationFn = Apollo.MutationFunction<UpdateOpsQuestionsDataMutation, UpdateOpsQuestionsDataMutationVariables>;

/**
 * __useUpdateOpsQuestionsDataMutation__
 *
 * To run a mutation, you first call `useUpdateOpsQuestionsDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpsQuestionsDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpsQuestionsDataMutation, { data, loading, error }] = useUpdateOpsQuestionsDataMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateOpsQuestionsDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOpsQuestionsDataMutation, UpdateOpsQuestionsDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOpsQuestionsDataMutation, UpdateOpsQuestionsDataMutationVariables>(UpdateOpsQuestionsDataDocument, options);
      }
export type UpdateOpsQuestionsDataMutationHookResult = ReturnType<typeof useUpdateOpsQuestionsDataMutation>;
export type UpdateOpsQuestionsDataMutationResult = Apollo.MutationResult<UpdateOpsQuestionsDataMutation>;
export type UpdateOpsQuestionsDataMutationOptions = Apollo.BaseMutationOptions<UpdateOpsQuestionsDataMutation, UpdateOpsQuestionsDataMutationVariables>;
export const UpdateOpsNotesDocument = gql`
    mutation updateOpsNotes($notes: String!, $id: Int!) {
  update_ops: update_lifecycle_question_result_by_pk(
    pk_columns: {id: $id}
    _set: {notes: $notes}
  ) {
    id
  }
}
    `;
export type UpdateOpsNotesMutationFn = Apollo.MutationFunction<UpdateOpsNotesMutation, UpdateOpsNotesMutationVariables>;

/**
 * __useUpdateOpsNotesMutation__
 *
 * To run a mutation, you first call `useUpdateOpsNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpsNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpsNotesMutation, { data, loading, error }] = useUpdateOpsNotesMutation({
 *   variables: {
 *      notes: // value for 'notes'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateOpsNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOpsNotesMutation, UpdateOpsNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOpsNotesMutation, UpdateOpsNotesMutationVariables>(UpdateOpsNotesDocument, options);
      }
export type UpdateOpsNotesMutationHookResult = ReturnType<typeof useUpdateOpsNotesMutation>;
export type UpdateOpsNotesMutationResult = Apollo.MutationResult<UpdateOpsNotesMutation>;
export type UpdateOpsNotesMutationOptions = Apollo.BaseMutationOptions<UpdateOpsNotesMutation, UpdateOpsNotesMutationVariables>;
export const UpdateCompanyQueueDataDocument = gql`
    mutation updateCompanyQueueData($updateProperties: application_lifecycle_stage_set_input!, $id: Int!) {
  update_application_lifecycle_stage_by_pk(
    pk_columns: {id: $id}
    _set: $updateProperties
  ) {
    id
  }
}
    `;
export type UpdateCompanyQueueDataMutationFn = Apollo.MutationFunction<UpdateCompanyQueueDataMutation, UpdateCompanyQueueDataMutationVariables>;

/**
 * __useUpdateCompanyQueueDataMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyQueueDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyQueueDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyQueueDataMutation, { data, loading, error }] = useUpdateCompanyQueueDataMutation({
 *   variables: {
 *      updateProperties: // value for 'updateProperties'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCompanyQueueDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyQueueDataMutation, UpdateCompanyQueueDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyQueueDataMutation, UpdateCompanyQueueDataMutationVariables>(UpdateCompanyQueueDataDocument, options);
      }
export type UpdateCompanyQueueDataMutationHookResult = ReturnType<typeof useUpdateCompanyQueueDataMutation>;
export type UpdateCompanyQueueDataMutationResult = Apollo.MutationResult<UpdateCompanyQueueDataMutation>;
export type UpdateCompanyQueueDataMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyQueueDataMutation, UpdateCompanyQueueDataMutationVariables>;
export const GetAllStaffUsersDocument = gql`
    query getAllStaffUsers {
  user(where: {tenant_type: {_eq: STAFF}, deleted_at: {_is_null: true}}) {
    id
    first_name
    last_name
  }
}
    `;

/**
 * __useGetAllStaffUsersQuery__
 *
 * To run a query within a React component, call `useGetAllStaffUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStaffUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStaffUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllStaffUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllStaffUsersQuery, GetAllStaffUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllStaffUsersQuery, GetAllStaffUsersQueryVariables>(GetAllStaffUsersDocument, options);
      }
export function useGetAllStaffUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStaffUsersQuery, GetAllStaffUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllStaffUsersQuery, GetAllStaffUsersQueryVariables>(GetAllStaffUsersDocument, options);
        }
export type GetAllStaffUsersQueryHookResult = ReturnType<typeof useGetAllStaffUsersQuery>;
export type GetAllStaffUsersLazyQueryHookResult = ReturnType<typeof useGetAllStaffUsersLazyQuery>;
export type GetAllStaffUsersQueryResult = Apollo.QueryResult<GetAllStaffUsersQuery, GetAllStaffUsersQueryVariables>;
export const UpdateCompanyRmDocument = gql`
    mutation updateCompanyRM($id: Int!, $ops_rm: Int, $sales_rm: Int) {
  update_company_by_pk(
    pk_columns: {id: $id}
    _set: {ops_rm: $ops_rm, sales_rm: $sales_rm}
  ) {
    id
  }
}
    `;
export type UpdateCompanyRmMutationFn = Apollo.MutationFunction<UpdateCompanyRmMutation, UpdateCompanyRmMutationVariables>;

/**
 * __useUpdateCompanyRmMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyRmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyRmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyRmMutation, { data, loading, error }] = useUpdateCompanyRmMutation({
 *   variables: {
 *      id: // value for 'id'
 *      ops_rm: // value for 'ops_rm'
 *      sales_rm: // value for 'sales_rm'
 *   },
 * });
 */
export function useUpdateCompanyRmMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyRmMutation, UpdateCompanyRmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyRmMutation, UpdateCompanyRmMutationVariables>(UpdateCompanyRmDocument, options);
      }
export type UpdateCompanyRmMutationHookResult = ReturnType<typeof useUpdateCompanyRmMutation>;
export type UpdateCompanyRmMutationResult = Apollo.MutationResult<UpdateCompanyRmMutation>;
export type UpdateCompanyRmMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyRmMutation, UpdateCompanyRmMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($args: OpsCreateCompanyInput!) {
  OpsCreateCompany(args: $args) {
    message
    company_id
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const namedOperations = {
  Query: {
    getAllDashboardCompanies: 'getAllDashboardCompanies',
    getLifecycleQuestionResult: 'getLifecycleQuestionResult',
    applicationLifecycleStage: 'applicationLifecycleStage',
    applicationLifecycleStageCount: 'applicationLifecycleStageCount',
    GetCompanyQueueFullDetails: 'GetCompanyQueueFullDetails',
    getAllStaffUsers: 'getAllStaffUsers'
  },
  Mutation: {
    updateLifecycleQuestionResultByPk: 'updateLifecycleQuestionResultByPk',
    exportCompanyQueues: 'exportCompanyQueues',
    moveCompanyInQueue: 'moveCompanyInQueue',
    updateOpsQuestionsData: 'updateOpsQuestionsData',
    updateOpsNotes: 'updateOpsNotes',
    updateCompanyQueueData: 'updateCompanyQueueData',
    updateCompanyRM: 'updateCompanyRM',
    createCompany: 'createCompany'
  }
}