import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from "react";

import { JWTContextType, ActionMap, AuthState, AuthUser } from "../types/auth";

import { isValidToken, setSession } from "../utils/jwt";
import jwt_decode from "jwt-decode";
import { useGetUserByIdLazyQuery } from "../data-access/hooks/staff/user.hooks";
import {
  signIn as SignIn,
  signOut as SignOut,
  resetPassword as ResetPassword,
} from "../services/auth";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

let decoded: any = {};

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_IN]: {
    user: AuthUser;
  };
  [SIGN_OUT]: undefined;
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        isInitialized: true,
        user: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const [userData, setUserData] = useState<any>();

  const [getGetUserByIdLazyQuery] = useGetUserByIdLazyQuery({
    onCompleted: (item: any) => setUserData(item),
  });

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          decoded = jwt_decode(accessToken);
          const r = await getGetUserByIdLazyQuery({
            variables: {
              user_id: decoded["x-hasura-user-id"],
            },
          });
          const userData = r.data?.user_by_pk;
          if (userData) {
            const user = {
              id: userData.id,
              email: userData.email,
              full_name: userData.first_name + " " + userData.last_name,
            };
            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: true,
                user: user,
              },
            });
          }
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (email: string, password: string) => {
    const userId = await SignIn(email, password);
    const r = await getGetUserByIdLazyQuery({
      variables: {
        user_id: userId,
      },
    });
    const userData = r.data?.user_by_pk;
    if (userData) {
      const user = {
        id: userData.id,
        email: userData.email,
        full_name: userData.first_name + " " + userData.last_name,
      };
      dispatch({
        type: SIGN_IN,
        payload: {
          user,
        },
      });
    }
  };

  const signOut = async () => {
    await SignOut();
    dispatch({ type: SIGN_OUT });
  };

  const resetPassword = async (email: string) => {
    await ResetPassword(email);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        resetPassword,
        userData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
