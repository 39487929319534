import React, { useContext } from "react";
import styled from "styled-components/macro";
import { Button, Grid, Typography as MuiTypography } from "@mui/material";
import { spacing } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CompanyContext } from "../../contexts/CompanyContext";
import useDashboardData from "../../data-access/api/dashboard-data-api";
import { TableWrapper } from "../../theme/customComponents";
import CommonLoader from "../../components/CommonLoader";
import TableStatus from "../../components/TableStatus";

//?------------------------------------------------------------------------------------------------------- */
const Typography = styled(MuiTypography)(spacing);

//?------------------------------------------------------------------------------------------------------- */

const DashboardCompanyTable = () => {
  const { allCompanyListData, CompanyListLoaing, navigate } =
    useDashboardData();

  const { setCompanyData } = useContext(CompanyContext);

  return (
    <React.Fragment>
      <Grid item>
        <Paper>
          <Grid padding={"20px"} justifyContent="space-between" container>
            <Grid item>
              <Typography variant="h3">Recent Companies</Typography>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Button
                variant="primaryMedium"
                onClick={() => navigate("/company-queues")}
              >
                View all Company
              </Button>
            </Grid>
          </Grid>
          {CompanyListLoaing ? (
            <CommonLoader />
          ) : (
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Company ID</TableCell>
                    <TableCell>Company Name</TableCell>
                    <TableCell>Contact Name</TableCell>
                    <TableCell>Contact Number</TableCell>
                    <TableCell>Estimated ARR</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allCompanyListData?.map((row: any) => {
                    const userData = row?.company?.company_users[0]?.user;
                    return (
                      <TableRow
                        onClick={() => {
                          navigate(`/company/${row.company.id}/details`);
                          setCompanyData(row?.company);
                        }}
                        key={row?.company.id}
                      >
                        <TableCell>{row?.company?.id}</TableCell>
                        <TableCell>{row?.company?.name}</TableCell>
                        <TableCell>
                          {userData?.first_name + " " + userData?.last_name}
                        </TableCell>
                        <TableCell>{userData?.mobile_number}</TableCell>
                        <TableCell>
                          {row?.company?.estimated_arr?.toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {" "}
                          {TableStatus({
                            stautsText: `${row?.company?.status}`,
                          })}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableWrapper>
          )}
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default DashboardCompanyTable;
