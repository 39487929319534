import React from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableWrapper } from "../../../theme/customComponents";
import { ReactComponent as PencilIcon } from "../../../assets/Icon/Outline/pencil-icon.svg";
import EmptyData from "../../../components/EmptyData";
import CommonLoader from "../../../components/CommonLoader";

interface AccountTableInterface {
  investorBank?: InvestorBankInterface[] | undefined | null;
  loading?: boolean;
  setAddBankModal: Function;
  setEditData?: any;
}

interface InvestorBankInterface {
  account_no: number;
  account_type: string;
  ifsc: string;
  bank_id: number;
  bank_name: string;
  investor_name: string;
  investor_id: number;
  is_primary: boolean;
  multi_signatories: boolean;
}

const AccountTable = ({
  investorBank,
  loading,
  setAddBankModal,
  setEditData,
}: AccountTableInterface) => {
  return investorBank?.length === 0 ? (
    <Grid container>
      <EmptyData />
    </Grid>
  ) : (
    <TableWrapper>
      {loading ? (
        <CommonLoader />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Account No.</TableCell>
              <TableCell>Account Name</TableCell>
              <TableCell>Bank Name</TableCell>
              <TableCell>Is Primary</TableCell>
              <TableCell>Account Type</TableCell>
              <TableCell>IFSC</TableCell>
              <TableCell>Bank ID </TableCell>
              <TableCell>Multi Signatories</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {investorBank?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.account_no}</TableCell>
                <TableCell>{item.investor_name}</TableCell>
                <TableCell>{item.bank_name}</TableCell>
                <TableCell>{item.is_primary ? "True" : "False"}</TableCell>
                <TableCell>{item.account_type}</TableCell>
                <TableCell>{item.ifsc}</TableCell>
                <TableCell>{item.bank_id}</TableCell>
                <TableCell>
                  {item.multi_signatories ? "True" : "False"}
                </TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setEditData(item);
                      setAddBankModal(true);
                    }}
                    style={{ border: "solid 1px #1E88E533" }}
                  >
                    <PencilIcon width={16} height={16} color="#1E88E5" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableWrapper>
  );
};

export default AccountTable;
