import { RouteObject } from "react-router-dom";
import async from "./components/Async";
import AuthLayout from "./layouts/Auth";
import AuthGuard from "./components/guards/AuthGuard";
import GuestGuard from "./components/guards/GuestGuard";
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import Dashboard from "./pages/dashboard";
import ModuleLayout from "./layouts/ModuleLayout";
import CompanyDetails from "./pages/company/CompanyDetails";
import CompanyDocumentChecklist from "./pages/company/CompanyDocumentChecklist";
import CreateCompany from "./pages/dashboard/CreateCompany";
import PaymentPartner from "./pages/company/PaymentPartner";
import AddPaymentData from "./pages/company/PaymentPartner/AddPaymentData";
import EMICalendar from "./pages/EMICalendar";
import CompanyDetail from "./pages/company/CompanyDetails/CompanyDetail";
import Deals from "./pages/company/DealsTrade/Deals";
import ForgotPassword from "./pages/auth/ForgotPassword";
import CompanyScore from "./pages/company/ScoringEngine/Index";
import GlobalDeals from "./pages/Deals";
import TradeConfiguration from "./pages/company/CompanyTrade/TradeConfiguration";
import ContractSelection from "./pages/company/CompanyContract";
import GlobalTrades from "./pages/Trades";
import CompanyTrade from "./pages/company/CompanyTrade";
import VerifyEmail from "./pages/auth/VerifyEmail";
import CompanyEmailLogs from "./pages/company/CompanyEmailLogs";
import AccountingData from "./pages/company/AccountingData/Index";
import AddAccountData from "./pages/company/AccountingData/AddAccountData";
import Transaction from "./pages/EMICalendar/Transaction";
import GlobalInvestos from "./pages/Investors";
import InvestorDetails from "./pages/Investors/InvestorDetails/InvestorDetails";
import CompanyGST from "./pages/company/CompanyGST/CompanyGST";
import UserDetails from "./pages/company/UserDetails";

const Layout = async(() => import("./layouts/Layout"));
const Company = async(() => import("./pages/company"));
const CompanyOverview = async(
  () => import("./pages/company/CompanyOverview/index")
);
const BankingData = async(() => import("./pages/company/BankingData/index"));
const AddBankAccount = async(
  () => import("./pages/company/BankingData/addBankAccount")
);
const BankAccountDetails = async(
  () => import("./pages/company/BankingData/bankAccountDetails")
);

const routes: RouteObject[] = [
  {
    path: "auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "verify-email/:code",
        element: <VerifyEmail />,
      },
      {
        path: "set-password/:token",
        element: <ForgotPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        element: <Company />,
        index: true,
      },
      {
        path: "trades",
        element: <GlobalTrades />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "deals",
        element: <GlobalDeals />,
      },
      {
        path: "investors",
        element: <GlobalInvestos />,
      },
      {
        path: "investor/:investor_id/details",
        element: <InvestorDetails />,
      },
      {
        path: "emi",
        element: <EMICalendar />,
      },
      {
        path: "create-company",
        element: <CreateCompany />,
      },
      {
        path: ":id/company-detail",
        element: <CompanyDetail />,
      },
      {
        path: "company-queues",
        children: [
          {
            element: <Company />,
            index: true,
          },
        ],
      },
      {
        path: "company",
        element: (
          <AuthGuard>
            <ModuleLayout path="company" />
          </AuthGuard>
        ),
        children: [
          {
            element: <CompanyOverview />,
            path: ":id/overview",
            index: true,
          },
          {
            element: <CompanyEmailLogs />,
            path: ":id/checklist-logs",
            index: true,
          },
          {
            path: ":id/trade/:trade_id",
            element: <CompanyTrade />,
          },
          {
            path: ":id/overview",
            element: <CompanyOverview />,
          },
          {
            path: ":id/gst",
            element: <CompanyGST />,
          },
          {
            path: ":id/details",
            element: <CompanyDetails />,
          },
          {
            path: ":id/user-details",
            element: <UserDetails />,
          },
          {
            path: ":id/scoring-engine",
            element: <CompanyScore />,
          },
          {
            path: ":id/banking-data",
            element: <BankingData />,
          },
          {
            path: ":id/payment-partner",
            element: <PaymentPartner />,
          },
          {
            path: ":id/add-payment-data",
            element: <AddPaymentData />,
          },
          {
            path: ":id/document-checklist",
            element: <CompanyDocumentChecklist />,
          },
          {
            path: ":id/account-data",
            element: <AccountingData />,
          },
          {
            path: ":id/add-account-data",
            element: <AddAccountData />,
          },
          {
            path: ":id/deals-trades",
            element: <Deals />,
          },
          {
            path: ":id/trade-configuration",
            element: <TradeConfiguration />,
          },
          {
            path: ":id/trade/:trade_id/contract-selection",
            element: <ContractSelection />,
          },

          {
            path: ":id/add-bank",
            element: <AddBankAccount />,
          },
          {
            path: ":id/banking-data/:accountId",
            element: <BankAccountDetails />,
          },
          {
            path: ":id/banking-data/:accountId/integration-history/:history_id",
            element: <BankAccountDetails />,
          },
          {
            path: ":id/banking-data/integration-history/:history_id",
            element: <BankAccountDetails />,
          },
          {
            path: ":id/transactions",
            element: <Transaction />,
          },
        ],
      },
    ],
  },

  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
