import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompanyDetailByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type CompanyDetailByIdQuery = { __typename?: 'query_root', company_by_pk?: { __typename?: 'company', cin?: string | null, id: number, domain: string, estimated_arr?: any | null, name?: string | null, incorporation_date?: any | null, active_gstin?: string | null, mca_profile?: any | null, mca_signatories?: any | null, authorized_signatories?: any | null, pan?: string | null, document_checklist_enabled: boolean, company_users: Array<{ __typename?: 'company_user', user_type: Types.Ref_User_Type_Enum, user: { __typename?: 'user', id: number, email: string, last_name: string, first_name: string, mobile_number: string } }> } | null };

export type UpdateCompanyAuthorizeSignatoriesMutationVariables = Types.Exact<{
  authSignatories?: Types.InputMaybe<Types.Scalars['jsonb']>;
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type UpdateCompanyAuthorizeSignatoriesMutation = { __typename?: 'mutation_root', update_company?: { __typename?: 'company_mutation_response', returning: Array<{ __typename?: 'company', cin?: string | null, id: number, domain: string, estimated_arr?: any | null, name?: string | null, incorporation_date?: any | null, active_gstin?: string | null, mca_profile?: any | null, mca_signatories?: any | null, authorized_signatories?: any | null }> } | null };

export type GetSignatoryByCompanyIdQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Company_Authorized_Signatory_Bool_Exp>;
}>;


export type GetSignatoryByCompanyIdQuery = { __typename?: 'query_root', company_authorized_signatory: Array<{ __typename?: 'company_authorized_signatory', full_name?: string | null, id: number, pincode?: string | null, designation?: string | null, email?: string | null, mobile_number?: string | null }> };

export type UpdateCompanySignatoryByIdMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  set?: Types.InputMaybe<Types.Company_Authorized_Signatory_Set_Input>;
}>;


export type UpdateCompanySignatoryByIdMutation = { __typename?: 'mutation_root', update_company_authorized_signatory_by_pk?: { __typename?: 'company_authorized_signatory', company_id: number, id: number } | null };

export type CreateCompanySignatoryMutationVariables = Types.Exact<{
  object: Types.Company_Authorized_Signatory_Insert_Input;
}>;


export type CreateCompanySignatoryMutation = { __typename?: 'mutation_root', insert_company_authorized_signatory_one?: { __typename?: 'company_authorized_signatory', id: number } | null };

export type SendSignatoryAgreementMutationVariables = Types.Exact<{
  authorized_signatory_id: Types.Scalars['Int'];
  company_id: Types.Scalars['Int'];
}>;


export type SendSignatoryAgreementMutation = { __typename?: 'mutation_root', TriggerCompanyClientAgreement?: { __typename?: 'TriggerCompanyClientAgreementOutput', message: string } | null };

export type VerifyPanQueryVariables = Types.Exact<{
  args: Types.VerifyPanInput;
}>;


export type VerifyPanQuery = { __typename?: 'query_root', verifyPan?: { __typename?: 'VerifyPanOutput', gstin?: string | null, message: string, name_on_pan?: string | null, verified: boolean, registered_name?: string | null } | null };


export const CompanyDetailByIdDocument = gql`
    query companyDetailByID($id: Int!) {
  company_by_pk(id: $id) {
    cin
    id
    domain
    estimated_arr
    name
    incorporation_date
    active_gstin
    mca_profile
    mca_signatories
    authorized_signatories
    pan
    document_checklist_enabled
    company_users {
      user_type
      user {
        id
        email
        last_name
        first_name
        mobile_number
      }
    }
  }
}
    `;

/**
 * __useCompanyDetailByIdQuery__
 *
 * To run a query within a React component, call `useCompanyDetailByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDetailByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDetailByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyDetailByIdQuery(baseOptions: Apollo.QueryHookOptions<CompanyDetailByIdQuery, CompanyDetailByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyDetailByIdQuery, CompanyDetailByIdQueryVariables>(CompanyDetailByIdDocument, options);
      }
export function useCompanyDetailByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyDetailByIdQuery, CompanyDetailByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyDetailByIdQuery, CompanyDetailByIdQueryVariables>(CompanyDetailByIdDocument, options);
        }
export type CompanyDetailByIdQueryHookResult = ReturnType<typeof useCompanyDetailByIdQuery>;
export type CompanyDetailByIdLazyQueryHookResult = ReturnType<typeof useCompanyDetailByIdLazyQuery>;
export type CompanyDetailByIdQueryResult = Apollo.QueryResult<CompanyDetailByIdQuery, CompanyDetailByIdQueryVariables>;
export const UpdateCompanyAuthorizeSignatoriesDocument = gql`
    mutation updateCompanyAuthorizeSignatories($authSignatories: jsonb, $id: Int) {
  update_company(
    _set: {authorized_signatories: $authSignatories}
    where: {id: {_eq: $id}}
  ) {
    returning {
      cin
      id
      domain
      estimated_arr
      name
      incorporation_date
      active_gstin
      mca_profile
      mca_signatories
      authorized_signatories
    }
  }
}
    `;
export type UpdateCompanyAuthorizeSignatoriesMutationFn = Apollo.MutationFunction<UpdateCompanyAuthorizeSignatoriesMutation, UpdateCompanyAuthorizeSignatoriesMutationVariables>;

/**
 * __useUpdateCompanyAuthorizeSignatoriesMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyAuthorizeSignatoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyAuthorizeSignatoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyAuthorizeSignatoriesMutation, { data, loading, error }] = useUpdateCompanyAuthorizeSignatoriesMutation({
 *   variables: {
 *      authSignatories: // value for 'authSignatories'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCompanyAuthorizeSignatoriesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyAuthorizeSignatoriesMutation, UpdateCompanyAuthorizeSignatoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyAuthorizeSignatoriesMutation, UpdateCompanyAuthorizeSignatoriesMutationVariables>(UpdateCompanyAuthorizeSignatoriesDocument, options);
      }
export type UpdateCompanyAuthorizeSignatoriesMutationHookResult = ReturnType<typeof useUpdateCompanyAuthorizeSignatoriesMutation>;
export type UpdateCompanyAuthorizeSignatoriesMutationResult = Apollo.MutationResult<UpdateCompanyAuthorizeSignatoriesMutation>;
export type UpdateCompanyAuthorizeSignatoriesMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyAuthorizeSignatoriesMutation, UpdateCompanyAuthorizeSignatoriesMutationVariables>;
export const GetSignatoryByCompanyIdDocument = gql`
    query getSignatoryByCompanyId($where: company_authorized_signatory_bool_exp) {
  company_authorized_signatory(where: $where) {
    full_name
    id
    pincode
    designation
    email
    mobile_number
  }
}
    `;

/**
 * __useGetSignatoryByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetSignatoryByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignatoryByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignatoryByCompanyIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSignatoryByCompanyIdQuery(baseOptions?: Apollo.QueryHookOptions<GetSignatoryByCompanyIdQuery, GetSignatoryByCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignatoryByCompanyIdQuery, GetSignatoryByCompanyIdQueryVariables>(GetSignatoryByCompanyIdDocument, options);
      }
export function useGetSignatoryByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignatoryByCompanyIdQuery, GetSignatoryByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignatoryByCompanyIdQuery, GetSignatoryByCompanyIdQueryVariables>(GetSignatoryByCompanyIdDocument, options);
        }
export type GetSignatoryByCompanyIdQueryHookResult = ReturnType<typeof useGetSignatoryByCompanyIdQuery>;
export type GetSignatoryByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetSignatoryByCompanyIdLazyQuery>;
export type GetSignatoryByCompanyIdQueryResult = Apollo.QueryResult<GetSignatoryByCompanyIdQuery, GetSignatoryByCompanyIdQueryVariables>;
export const UpdateCompanySignatoryByIdDocument = gql`
    mutation updateCompanySignatoryById($id: Int!, $set: company_authorized_signatory_set_input) {
  update_company_authorized_signatory_by_pk(pk_columns: {id: $id}, _set: $set) {
    company_id
    id
  }
}
    `;
export type UpdateCompanySignatoryByIdMutationFn = Apollo.MutationFunction<UpdateCompanySignatoryByIdMutation, UpdateCompanySignatoryByIdMutationVariables>;

/**
 * __useUpdateCompanySignatoryByIdMutation__
 *
 * To run a mutation, you first call `useUpdateCompanySignatoryByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanySignatoryByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanySignatoryByIdMutation, { data, loading, error }] = useUpdateCompanySignatoryByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateCompanySignatoryByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanySignatoryByIdMutation, UpdateCompanySignatoryByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanySignatoryByIdMutation, UpdateCompanySignatoryByIdMutationVariables>(UpdateCompanySignatoryByIdDocument, options);
      }
export type UpdateCompanySignatoryByIdMutationHookResult = ReturnType<typeof useUpdateCompanySignatoryByIdMutation>;
export type UpdateCompanySignatoryByIdMutationResult = Apollo.MutationResult<UpdateCompanySignatoryByIdMutation>;
export type UpdateCompanySignatoryByIdMutationOptions = Apollo.BaseMutationOptions<UpdateCompanySignatoryByIdMutation, UpdateCompanySignatoryByIdMutationVariables>;
export const CreateCompanySignatoryDocument = gql`
    mutation createCompanySignatory($object: company_authorized_signatory_insert_input!) {
  insert_company_authorized_signatory_one(object: $object) {
    id
  }
}
    `;
export type CreateCompanySignatoryMutationFn = Apollo.MutationFunction<CreateCompanySignatoryMutation, CreateCompanySignatoryMutationVariables>;

/**
 * __useCreateCompanySignatoryMutation__
 *
 * To run a mutation, you first call `useCreateCompanySignatoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanySignatoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanySignatoryMutation, { data, loading, error }] = useCreateCompanySignatoryMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateCompanySignatoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanySignatoryMutation, CreateCompanySignatoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanySignatoryMutation, CreateCompanySignatoryMutationVariables>(CreateCompanySignatoryDocument, options);
      }
export type CreateCompanySignatoryMutationHookResult = ReturnType<typeof useCreateCompanySignatoryMutation>;
export type CreateCompanySignatoryMutationResult = Apollo.MutationResult<CreateCompanySignatoryMutation>;
export type CreateCompanySignatoryMutationOptions = Apollo.BaseMutationOptions<CreateCompanySignatoryMutation, CreateCompanySignatoryMutationVariables>;
export const SendSignatoryAgreementDocument = gql`
    mutation sendSignatoryAgreement($authorized_signatory_id: Int!, $company_id: Int!) {
  TriggerCompanyClientAgreement(
    args: {authorized_signatory_id: $authorized_signatory_id, company_id: $company_id}
  ) {
    message
  }
}
    `;
export type SendSignatoryAgreementMutationFn = Apollo.MutationFunction<SendSignatoryAgreementMutation, SendSignatoryAgreementMutationVariables>;

/**
 * __useSendSignatoryAgreementMutation__
 *
 * To run a mutation, you first call `useSendSignatoryAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSignatoryAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSignatoryAgreementMutation, { data, loading, error }] = useSendSignatoryAgreementMutation({
 *   variables: {
 *      authorized_signatory_id: // value for 'authorized_signatory_id'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useSendSignatoryAgreementMutation(baseOptions?: Apollo.MutationHookOptions<SendSignatoryAgreementMutation, SendSignatoryAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSignatoryAgreementMutation, SendSignatoryAgreementMutationVariables>(SendSignatoryAgreementDocument, options);
      }
export type SendSignatoryAgreementMutationHookResult = ReturnType<typeof useSendSignatoryAgreementMutation>;
export type SendSignatoryAgreementMutationResult = Apollo.MutationResult<SendSignatoryAgreementMutation>;
export type SendSignatoryAgreementMutationOptions = Apollo.BaseMutationOptions<SendSignatoryAgreementMutation, SendSignatoryAgreementMutationVariables>;
export const VerifyPanDocument = gql`
    query verifyPan($args: VerifyPanInput!) {
  verifyPan(args: $args) {
    gstin
    message
    name_on_pan
    verified
    registered_name
  }
}
    `;

/**
 * __useVerifyPanQuery__
 *
 * To run a query within a React component, call `useVerifyPanQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyPanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyPanQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useVerifyPanQuery(baseOptions: Apollo.QueryHookOptions<VerifyPanQuery, VerifyPanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyPanQuery, VerifyPanQueryVariables>(VerifyPanDocument, options);
      }
export function useVerifyPanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyPanQuery, VerifyPanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyPanQuery, VerifyPanQueryVariables>(VerifyPanDocument, options);
        }
export type VerifyPanQueryHookResult = ReturnType<typeof useVerifyPanQuery>;
export type VerifyPanLazyQueryHookResult = ReturnType<typeof useVerifyPanLazyQuery>;
export type VerifyPanQueryResult = Apollo.QueryResult<VerifyPanQuery, VerifyPanQueryVariables>;
export const namedOperations = {
  Query: {
    companyDetailByID: 'companyDetailByID',
    getSignatoryByCompanyId: 'getSignatoryByCompanyId',
    verifyPan: 'verifyPan'
  },
  Mutation: {
    updateCompanyAuthorizeSignatories: 'updateCompanyAuthorizeSignatories',
    updateCompanySignatoryById: 'updateCompanySignatoryById',
    createCompanySignatory: 'createCompanySignatory',
    sendSignatoryAgreement: 'sendSignatoryAgreement'
  }
}