/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  AlertColor,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ReactComponent as TrashIcon } from "../../../assets/Icon/Outline/trash-icon.svg";
import { ReactComponent as LibraryIcon } from "../../../assets/Icon/Outline/library-icon.svg";
import { useCallback, useState } from "react";
import { TableWrapper } from "../../../theme/customComponents";
import _ from "lodash";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTransferTdstoCompanyMutation } from "../../../data-access/hooks/company/emi-calendar.hooks";
import styled from "styled-components";
import SideModal from "../../../components/Modals/SideModal";

interface InitiateTDSProps {
  setOpen: Function;
  selectedTDS: any[];
  availableBalance: any;
  GetTDSRefetch: any;
  open: boolean;
}

interface FileStatement {
  name: string;
  file_registry_id: number;
}
const TableHead = styled(MuiTableHead)`
  & .MuiTableRow-head {
    background: #d9d9d938;
  }
  & .MuiTableCell-head {
    border-bottom: none;
  }
`;
const InititateTdsModal = ({
  setOpen,
  selectedTDS,
  availableBalance,
  GetTDSRefetch,
  open,
}: InitiateTDSProps) => {
  const { id, trade_id } = useParams();

  const [uploadLoading, setUploadLoading] = useState(false);

  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
    type: "info" as AlertColor,
  });

  const [uploadedNewFile, setUploadNewFile] = useState<any[]>([]);

  const onDrop = useCallback(async (acceptedFiles: any) => {
    setShowAlert({
      show: false,
      message: "",
      type: "info" as AlertColor,
    });
    setUploadNewFile(acceptedFiles);
    setUploadLoading(true);
    try {
      const resp = (await Promise.allSettled(
        acceptedFiles.map((file: any) => uploadFile(file))
      )) as unknown as PromiseSettledResult<FileStatement>[];

      const newFiles = resp
        .map((v) => (v.status === "fulfilled" ? v.value : undefined))
        .filter((v) => v !== undefined);

      const consFiles = [...uploadedNewFile, ...newFiles];

      setUploadNewFile(consFiles);
    } catch (err) {
      console.log("fil upload failed");
    }
    setUploadLoading(false);
    return;
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    multiple: true,
    onDrop,
  });

  /**==================================================================================================================== */
  const deleteFile = async (id: number) => {
    const newFiles = uploadedNewFile.filter((f) => f.file_registry_id !== id);
    setUploadNewFile(newFiles);
  };

  /**==================================================================================================================== */
  const [handleTransferTds, { loading: transferLoading }] =
    useTransferTdstoCompanyMutation({
      onCompleted: () => {
        setOpen(false);
        GetTDSRefetch();
      },
      onError: (error) => {
        setShowAlert({
          show: true,
          message: error.message,
          type: "error" as AlertColor,
        });
      },
    });
  /**==================================================================================================================== */
  const uploadFile = async (selectedFile: File) => {
    const formData = new FormData();

    formData.append("file", selectedFile, selectedFile.name);

    formData.append("category_id", "TDS");

    const token = localStorage.getItem("accessToken");

    const config: any = {
      method: "post",
      url: `${process.env.REACT_APP_ACTIONS_BASE_URL}secure-upload`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        "X-hasura-proxy-tenant-type": "COMPANY",
        "X-hasura-tenant-id": id,
      },
    };

    try {
      const resp = await axios(config);

      const fileStatement: FileStatement = {
        name: selectedFile.name,
        file_registry_id: resp.data.file_registry_id,
      };

      return fileStatement;
    } catch (err: any) {
      setShowAlert({
        show: true,
        message: `${selectedFile.name} upload failed: ${err?.response?.data?.message}`,
        type: "error",
      });
      throw err;
    }
  };

  return (
    <SideModal
      open={open}
      onClose={() => setOpen(false)}
      modalTitle={"Initiate TDS: Transaction Details"}
      footer={
        <Button
          onClick={() => {
            handleTransferTds({
              variables: {
                args: {
                  tds_files: uploadedNewFile.map((item: any) => {
                    return item.file_registry_id;
                  }),
                  scheduled_emi_ids: selectedTDS.map((item: any) => {
                    return item.id;
                  }),
                  trade_id: Number(trade_id),
                },
              },
            });
          }}
          disabled={transferLoading}
          fullWidth
          variant="primarySmall"
        >
          {transferLoading ? (
            <CircularProgress size={20} />
          ) : (
            "Confirm And proceed"
          )}
        </Button>
      }
    >
      <Grid
        container
        style={{
          background: "#fff",
        }}
        display="flex"
        justifyContent={"center"}
        alignItems="center"
      >
        {showAlert.show && (
          <Grid item xs={12} my={2}>
            <Alert
              severity={showAlert.type}
              onClose={() => {
                setShowAlert({
                  show: false,
                  message: "",
                  type: "error",
                });
              }}
            >
              {showAlert.message}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>EMI No.</TableCell>
                  <TableCell>EMI ID</TableCell>
                  <TableCell align="right">TDS Due</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedTDS?.map((item, index) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item?.id}</TableCell>
                    <TableCell align="right">₹ {item.tds}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography fontWeight={700}>Total</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontWeight={700}>
                      ₹&nbsp;
                      {_.sum(
                        selectedTDS.map((item) => {
                          return item.tds;
                        })
                      ).toFixed(2)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableWrapper>
        </Grid>
        <Grid item xs={12} my={4}>
          <Card>
            <CardContent
              style={{
                background: "#F8F8F8",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <LibraryIcon color="#1E88E5" />
              <Typography fontWeight={400} color="#666666">
                Available balance (escrow Account)
              </Typography>
              <Typography fontWeight={700}>₹ {availableBalance}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={600} fontSize={18} my={2}>
            Upload TDS Certificate
          </Typography>
          <div
            style={{
              background: "#1E88E50A",
              width: "100%",
              border: "dashed 1px #1E88E5",
              borderRadius: "11px",
              height: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            {...getRootProps()}
          >
            {uploadLoading ? (
              <CircularProgress />
            ) : (
              <>
                <input {...getInputProps()} />
                <div>
                  <div>
                    <Typography fontWeight={600} fontSize={"16px"}>
                      Drag and drop or
                      <span
                        style={{
                          color: "#1E88E5",
                        }}
                      >
                        &nbsp; Browse
                      </span>
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize={"14px"}
                      color={"#7D87A6"}
                    >
                      {"Supported file type PDF"}
                    </Typography>
                  </div>
                </div>
              </>
            )}
          </div>
          {uploadedNewFile?.length !== 0 && (
            <Grid item xs={12} my={3}>
              <Typography fontWeight={600} fontSize={18}>
                Your Files
              </Typography>

              {uploadedNewFile.map((item) => (
                <Grid
                  item
                  display={"flex"}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Typography>{item.name}</Typography>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      deleteFile(item.file_registry_id);
                    }}
                  >
                    <TrashIcon color="#DD3838" width={18} height={18} />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    </SideModal>
  );
};

export default InititateTdsModal;
