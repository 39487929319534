export const getDomain = (text: string) => {
  return text?.split("@")[1];
};

export const getChangedValues = (values: any, initialValues: any) => {
  return Object.entries(values).reduce((acc: any, [key, value]: any) => {
    const hasChanged = initialValues[key] !== value;

    if (hasChanged) {
      acc[key] = value;
    }

    return acc;
  }, {});
};

//*************************************************************************************************** */

export const calendarStatusColor = (type: string) => {
  switch (type) {
    case "COMPLETED":
      return "#57B867";
    case "INITIATED":
      return "#EAB224";
    case "DEBIT_INITIATED":
      return "#EAB224";
    case "FAILED_DEBIT":
      return "#E90000";
    case "FAILED_CREDIT":
      return "#E90000";
    case "SCHEDULED":
      return "#1E88E5";
    case "REATTEMPT":
      return "#EAB224";
    case "DEBIT_SUCCESS":
      return "#57B867";
    case "CREDIT_SUCCESS":
      return "#57B867";
    case "DRAFT":
      return "#707070";
    case "CANCELLED":
      return "#A157FF";
    default:
      break;
  }
};

//*************************************************************************************************** */
//============================================================================= */
export const dealStatusColor = (type: string) => {
  switch (type?.toLowerCase()) {
    case "expired":
      return { color: "#EB4848", text: "Expired" };
    case "closed":
      return { color: "#DDA516", text: "Closed" };
    case "bidding":
      return { color: "#1E88E5", text: "Open" };
    default:
      break;
  }
};

//============================================================================= */
export const investorStatusColor = (type: string) => {
  switch (type?.toLowerCase()) {
    case "active":
      return { color: "#1E88E5", text: "Active" };
    default:
      break;
  }
};

//============================================================================= */
export const tradeStatus = (text: string) => {
  if (text === "MANDATE_REGISTERED") {
    return "Mandate Registered";
  } else if (text === "MANDATE_SIGNED") {
    return "Mandate Signed";
  } else if (text === "APA_COMPANY_SIGNED") {
    return "APA Company Signed";
  } else if (text === "APA_INVESTOR_SIGNED") {
    return "APA Investor Signed";
  } else if (text === "PAYOUT_DISBURSED") {
    return "Payout Disbursed";
  } else if (text === "ACTIVE") {
    return "Active";
  } else if (text === "COMPLETED") {
    return "Completed";
  } else if (text === "DEFAULT") {
    return "Default";
  } else if (text === "FAILED") {
    return "Failed";
  } else if (text === "CONTRACT_SELECTED") {
    return "Contract Selected";
  } else if (text === "MANDATE_SUCCESS") {
    return "Mandate Success";
  } else if (text === "MANDATE_FAILED") {
    return "Mandate Failed";
  } else if (text === "APA_GENERATED") {
    return "APA Generated";
  } else if (text === "MANDATE_IN_REVIEW") {
    return "Mandate in Review";
  } else if (text === "APA_IN_REVIEW") {
    return "APA in Review";
  } else if (text === "OFFLINE") {
    return "Offline";
  } else if (text === "INITIATED") {
    return "Initiated";
  } else if (text === "PAYOUT_INITIATED") {
    return "Payout Initiated";
  } else if (text === "PAYOUT_DEBITED") {
    return "Payout Debited";
  }
};

//============================================================================= */
export const getTradeStatusColor = (type: string) => {
  if (
    type === "ACTIVE" ||
    type === "MANDATE_SIGNED" ||
    type === "APA_COMPANY_SIGNED" ||
    type === "APA_INVESTOR_SIGNED" ||
    type === "MANDATE_SUCCESS" ||
    type === "PAYOUT_DEBITED" ||
    type === "COMPLETED"
  ) {
    return "#57B867";
  } else if (
    type === "INITIATED" ||
    type === "APA_IN_REVIEW" ||
    type === "MANDATE_IN_REVIEW" ||
    type === "PENDING" ||
    type === "PAYOUT_INITIATED" ||
    type === "CONTRACT_SELECTED"
  ) {
    return "#DBA314";
  } else if (type === "DEFAULT") {
    return "#1E88E5";
  } else if (
    type === "FAILED" ||
    type === "PAYOUT_DISBURSED" ||
    type === "MANDATE_FAILED"
  ) {
    return "#E90000";
  } else if (
    type === "APA_GENERATED" ||
    type === "MANDATE_REGISTERED" ||
    type === "OFFLINE"
  ) {
    return "#4F566B";
  }
};

//============================================================================= */
export const agreementStatusCode = (type: string) => {
  switch (type) {
    case "DRAFT":
      return {
        text: "Draft",
        color: "#1E88E5",
      };
    case "INITIATED":
      return {
        text: "Initiated",
        color: "#DBA314",
      };
    case "PARTIAL_SIGNED":
      return {
        text: "Partial Signed",
        color: "#57B867",
      };
    case "FAILED":
      return {
        text: "Failed",
        color: "#E90000",
      };
    case "CANCELLED":
      return {
        text: "Cancelled",
        color: "#E90000",
      };
    case "SIGNED":
      return {
        text: "Signed",
        color: "#57B867",
      };
    default:
      break;
  }
};

//============================================================================= */
export const agreementModeStatus = (mode: string) => {
  switch (mode) {
    case "TENANT_AGREEMENT":
      return {
        text: "Tenant Aggreement",
        color: "#1E88E5",
      };
    case "NDA_ONLINE":
      return {
        text: "NDA Online",
        color: "#1E88E5",
      };
    case "NDA_CLICKWRAP":
      return {
        text: "NDA ClickWrap",
        color: "#1E88E5",
      };
    case "NDA_MANUAL":
      return {
        text: "NDA Manual",
        color: "#1E88E5",
      };
    case "APA":
      return {
        text: "APA Aggreement",
        color: "#1E88E5",
      };
    default:
      break;
  }
};

//============================================================================= */
export const CommonOpsColors = {
  primary: "#1E88E5",
  error: "#DD3838",
  success: "#57B867",
  warning: "#DBA314",
};
