import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import styled from "styled-components/macro";
import { FormLabel as MuiFormLabel } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";

const FormLabel = styled(MuiFormLabel)`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  margin-bottom: 4px;
`;
const DatePicker = styled(MuiDatePicker)`
  & input {
    height: 40px;
    padding: 0 8px;
  }
  & .MuiFormHelperText-root {
    margin-left: 0;
  }
  & .MuiSvgIcon-root {
    color: #1e88e5;
  }
`;

const SimpleDatePicker = (props: any) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {props.inputlabel && (
          <FormLabel>
            {props.inputlabel}
            {props.required && <span style={{ color: "#E90000" }}>*</span>}
          </FormLabel>
        )}
        <DatePicker {...props} style={{ marginTop: "5px" }} />
      </LocalizationProvider>
    </>
  );
};

export default SimpleDatePicker;
