import React from "react";
import {
  Alert,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { ReactComponent as XIcon } from "../../../assets/Icon/Outline/x-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/Icon/Outline/trash-icon.svg";
import SimpleInput from "../../../components/input/SimpleInput";
import { useUpdateCompanyAlternateEmailMutation } from "../../../data-access/hooks/company/team-collaboration.hooks";
import * as yup from "yup";
import { useFormik } from "formik";

interface AdditionalEmailInterface {
  setOpen: Function;
  allAlternateEmails: string;
  refetch?: any;
  company_id?: any;
}

const AdditionalsEmail = ({
  setOpen,
  allAlternateEmails,
  refetch,
  company_id,
}: AdditionalEmailInterface) => {
  const validationSchema = yup.object().shape({
    additional_emails: yup
      .string()
      .required("Email is required")
      .email("Enter a valid email address"),
  });
  const stringArray = !allAlternateEmails ? [] : allAlternateEmails.split(",");

  const [errorMessage, setErrorMessage] = React.useState("");

  const [handleAddString, { loading }] = useUpdateCompanyAlternateEmailMutation(
    {
      onCompleted: () => {
        refetch();
        formik.resetForm({
          values: {
            additional_emails: " ",
          },
          errors: {
            additional_emails: " ",
          },
        });
        setErrorMessage("");
      },
      onError: (item) => {
        setErrorMessage(item.message);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      additional_emails: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (
        values.additional_emails &&
        allAlternateEmails.includes(values.additional_emails)
      ) {
        setErrorMessage(
          "Same email already exist please enter different email."
        );
      } else {
        const UpdatedMails = [...stringArray, values.additional_emails].join(
          ","
        );
        handleAddString({
          variables: {
            additional_emails: `${UpdatedMails}`,
          },
        });
      }
    },
  });

  function deleteEmail(dataItem: string) {
    const arr = [...stringArray];
    const newarr = arr.filter((item) => item !== dataItem);
    return newarr.join(",");
  }

  return (
    <Grid
      container
      width={"30%"}
      style={{ background: "white" }}
      justifyContent="center"
      alignItems={"center"}
      display="flex"
      flexDirection={"column"}
    >
      <Grid
        container
        item
        display={"flex"}
        flexDirection="column"
        gap="16px"
        p={5}
      >
        <Grid
          item
          display={"flex"}
          justifyContent="space-between"
          alignItems={"flex-start"}
        >
          <Typography
            width={"70%"}
            fontSize={18}
            fontWeight={600}
            display="flex"
            flexDirection={"column"}
          >
            Add Alternate Emails
            <span
              style={{
                fontWeight: "500",
                fontSize: "14px",
                color: "#666666",
              }}
            >
              Added alternate email IDs will be added as recipients to all
              system generated emails.
            </span>
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <XIcon color="#666666" height="168x" width="18px" />
          </IconButton>
        </Grid>
        <Divider orientation="horizontal" variant="fullWidth" />
        {Boolean(errorMessage) && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        <form onSubmit={formik.handleSubmit}>
          <Grid container xs={12} rowGap={8}>
            <Grid item xs={12}>
              <SimpleInput
                fullWidth
                inputlabel="Email-ID"
                required
                placeholder="Enter email"
                name="additional_emails"
                error={
                  formik.touched.additional_emails &&
                  Boolean(formik.errors.additional_emails)
                }
                helperText={
                  formik.touched.additional_emails &&
                  formik.errors.additional_emails
                }
                onChange={(event: any) => {
                  formik.setFieldValue(
                    "additional_emails",
                    event?.target?.value
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={loading}
                type="submit"
                variant="primarySmall"
                fullWidth
              >
                {"Add"}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Divider orientation="horizontal" variant="fullWidth" />
        {stringArray?.length !== 0 && (
          <Grid container direction={"column"}>
            <Grid item>
              <Typography fontWeight={600} fontSize={"16px"}>
                Added emails
              </Typography>
            </Grid>
            {stringArray?.map((item) => {
              return (
                <Grid
                  item
                  direction={"row"}
                  justifyContent="space-between"
                  display={"flex"}
                  alignItems="center"
                >
                  <Typography>{item}</Typography>
                  <IconButton>
                    <DeleteIcon
                      onClick={() =>
                        handleAddString({
                          variables: {
                            additional_emails: `${deleteEmail(item)}`,
                          },
                        })
                      }
                      color="#DD3838"
                      width={20}
                      height={20}
                    />
                  </IconButton>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AdditionalsEmail;
