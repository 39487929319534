import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  Pagination,
  TableRow,
  Grid,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetAllInvestmentsByInvestorQuery } from "../../../data-access/hooks/company/investments.hooks";
import { TableWrapper } from "../../../theme/customComponents";
import { useState } from "react";
import TableStatus from "../../../components/TableStatus";
import CommonLoader from "../../../components/CommonLoader";
import EmptyData from "../../../components/EmptyData";

interface GetAllInvestmentsByInvestorQueryVariables {
  trade_id: any;
  invested_amount: any;
  withdrawal_mode: any;
  status: any;
  borrower_name: any;
  borrower_id: any;
}

const InvestmentsTable = () => {
  const { investor_id } = useParams();

  const [allInvestmentData, setAllInvestmentData] = useState<
    GetAllInvestmentsByInvestorQueryVariables[]
  >([]);

  const [paginationData, setPaginationData] = useState({
    offset: 0,
    limit: 50,
    totalcount: 0,
    currentPage: 1,
  });

  const pageCount = (number: number) => {
    return Math.ceil(number / 10);
  };

  const { loading } = useGetAllInvestmentsByInvestorQuery({
    variables: {
      where: {
        investor_id: {
          _eq: Number(investor_id),
        },
      },
      limit: paginationData.limit,
      offset: paginationData.offset,
    },
    onCompleted: (item) => {
      const data = item.investment.map((dataItem) => {
        return {
          trade_id: dataItem.trade_id,
          invested_amount: dataItem.invested_amount,
          withdrawal_mode: dataItem.withdrawal_mode,
          status: dataItem.trade.status,
          borrower_name: dataItem.trade.company.name,
          borrower_id: dataItem.trade.company.id,
        };
      });
      setAllInvestmentData(data);
      setPaginationData({
        ...paginationData,
        totalcount: Number(item.investment_aggregate.aggregate?.count),
      });
    },
  });
  return allInvestmentData?.length === 0 ? (
    <Grid container>
      <EmptyData />
    </Grid>
  ) : (
    <>
      <TableWrapper>
        {loading ? (
          <CommonLoader />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Company Name</TableCell>
                <TableCell>Trade ID</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Invested Amount</TableCell>
                <TableCell>Investor Repayment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allInvestmentData?.map((dataItem, index) => (
                <TableRow key={index}>
                  <TableCell>{dataItem.borrower_name}</TableCell>
                  <TableCell>{dataItem.trade_id}</TableCell>
                  <TableCell>
                    {TableStatus({
                      stautsText: dataItem.status,
                    })}
                  </TableCell>
                  <TableCell>{dataItem.invested_amount}</TableCell>
                  <TableCell>{dataItem.withdrawal_mode}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableWrapper>
      {paginationData?.totalcount !== 0 && (
        <Pagination
          count={pageCount(paginationData?.totalcount)}
          variant={"outlined"}
          page={paginationData?.currentPage}
          onChange={(event: any, value: any) => {
            const newOffset = 10 * value - 10;
            setPaginationData({
              offset: newOffset,
              limit: paginationData?.limit,
              totalcount: paginationData?.totalcount,
              currentPage: value,
            });
          }}
        />
      )}
    </>
  );
};

export default InvestmentsTable;
