import { green, grey, common } from "@mui/material/colors";
import { THEMES } from "../constants";

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#4782da",
  600: "#407ad6",
  700: "#376fd0",
  800: "#2f65cb",
  900: "#2052c2 ",
};
const ColorSecondary = {
  light: "#E8E8E9",
  primary: "#191B1F",
  dark: "#131417",
};
const ColorSuccess = {
  light: "#EEF8F0",
  primary: "#EEF8F0",
  dark: "#418A4D",
};
const ColorWarning = {
  light: "#E9F3FC",
  primary: "#FBBF24",
  dark: "#BC8F1B",
};
const ColorError = {
  light: "#FCEBEB",
  primary: "#DD3838",
  dark: "#DD3838",
};
const ColorBlue = {
  light: "#E9F3FC",
  primary: "#1E88E5",
  dark: "#1766AC",
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    success: {
      main: ColorSuccess.primary,
      contrastText: "#FFF",
    },
    primary: {
      contrastText: "#FFF",
      main: ColorBlue.primary,
    },
    warning: {
      contrastText: "#FFF",
      main: ColorWarning?.primary,
    },
    error: {
      contrastText: "#FFF",
      main: ColorError?.primary,
    },
    secondary: {
      contrastText: "#000",
      main: ColorSecondary.primary,
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: "#1B2430",
    header: {
      color: grey[200],
      background: "#1B2430",
      brand: {
        color: common.white,
      },
    },
    footer: {
      color: grey[200],
      background: "#1E2A38",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: customBlue[500],
    },
  },
};

const variants: Array<VariantType> = [defaultVariant];

export default variants;

export type VariantType = {
  name: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
    success: MainContrastTextType;
    warning: MainContrastTextType;
    error: MainContrastTextType;
  };
  header: ColorBgType & {
    search: {
      color: string;
    };
    indicator: {
      background: string;
    };
  };
  footer: ColorBgType;
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string;
      };
    };
    footer: ColorBgType & {
      online: {
        background: string;
      };
    };
    badge: ColorBgType;
  };
};

type MainContrastTextType = {
  main: string;
  contrastText: string;
};

type ColorBgType = {
  color: string;
  background: string;
};
