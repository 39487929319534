import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField as MuiTextField,
  Typography,
  FormControl,
  FormLabel,
  IconButton,
} from "@mui/material";
import { ReactComponent as ArrowLeftIcon } from "../../assets/Icon/Outline/arrow-left-icon.svg";
import styled from "styled-components";
import useCreateCompany from "../../hooks/useCreateCompany";

const TextField = styled(MuiTextField)`
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & .MuiFormHelperText-root {
    color: red;
    margin: 0;
  }
  & .MuiOutlinedInput-input {
    height: 40px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
  }
`;

const CreateCompany = () => {
  const { createLoading, formik, navigate } = useCreateCompany();

  return (
    <>
      <IconButton onClick={() => navigate("/company-queues")}>
        <ArrowLeftIcon width={16} height={16} color="#000" />
      </IconButton>
      <Grid
        container
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
      >
        <form onSubmit={formik.handleSubmit}>
          <Paper>
            <Grid item padding={"30px"}>
              <Grid item>
                <Typography fontSize={"18px"} fontWeight={700}>
                  Enter the following details to create a company!{" "}
                </Typography>
              </Grid>
              <Grid
                item
                display={"flex"}
                flexDirection={"column"}
                gap={"22px"}
                padding={"30px 0"}
              >
                <FormControl>
                  <FormLabel
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    First Name
                  </FormLabel>
                  <TextField
                    id="first_name"
                    variant="outlined"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    name="first_name"
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                    error={
                      formik.touched.first_name &&
                      Boolean(formik.errors.first_name)
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    Last Name
                  </FormLabel>
                  <TextField
                    id="last_name"
                    variant="outlined"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    name="last_name"
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    Mobile Number
                  </FormLabel>
                  <TextField
                    id="phone"
                    variant="outlined"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    name="phone"
                    type="number"
                    helperText={formik.touched.phone && formik.errors.phone}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    Company Email
                  </FormLabel>
                  <TextField
                    id="company_email"
                    name="company_email"
                    variant="outlined"
                    value={formik.values.company_email}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.company_email &&
                      formik.errors.company_email
                    }
                    error={
                      formik.touched.company_email &&
                      Boolean(formik.errors.company_email)
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    Company PAN number{" "}
                  </FormLabel>
                  <TextField
                    id="company_pan"
                    variant="outlined"
                    value={formik.values.company_pan}
                    onChange={formik.handleChange}
                    name="company_pan"
                    helperText={
                      formik.touched.company_pan && formik.errors.company_pan
                    }
                    error={
                      formik.touched.company_pan &&
                      Boolean(formik.errors.company_pan)
                    }
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Button
                  disabled={createLoading}
                  type="submit"
                  variant="contained"
                >
                  {createLoading ? <CircularProgress size={18} /> : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Grid>
    </>
  );
};

export default CreateCompany;
