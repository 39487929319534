import axios from "axios";
import jwt_decode from "jwt-decode";

import { getSession, setRefreshToken, setSession } from "../../utils/jwt";

const token = localStorage.getItem("accessToken");
/********************************************************************************************************************* */
const signIn = async (email: string, password: string) => {
  const user = {
    email,
    password,
  };
  const baseUrl = process.env.REACT_APP_AUTH_BASE_URL;
  const response = await axios.post(baseUrl + "signIn", user, {
    headers: {
      "X-Hasura-Tenant-Type": "STAFF",
    },
  });
  const { access_token, refresh_token } = response.data;

  setSession(access_token);
  setRefreshToken(refresh_token);

  const decoded: any = jwt_decode(access_token);
  return decoded["x-hasura-user-id"];
};

/********************************************************************************************************************* */
const signOut = async () => {
  const baseUrl = process.env.REACT_APP_AUTH_BASE_URL;
  const token = getSession();
  if (token) {
    await axios.post(
      baseUrl + "signOut",
      {},
      {
        headers: {
          authorization: token ? `Bearer ${token}` : "",
          "X-Hasura-Tenant-Type": "STAFF",
        },
      }
    );
  }
  setSession(null);
};

/********************************************************************************************************************* */
const resetPassword = async (email: string) => {
  const baseUrl = process.env.REACT_APP_AUTH_BASE_URL;
  return await axios.post(
    baseUrl + "forgotPassword",
    { email },
    {
      headers: {
        "X-Hasura-Tenant-Type": "STAFF",
      },
    }
  );
};

/********************************************************************************************************************* */
const setPassword = async (newPassword: string, code: string) => {
  const baseUrl = process.env.REACT_APP_AUTH_BASE_URL;
  return await axios.post(
    baseUrl + "confirmForgotPassword",
    {
      newPassword: newPassword,
      code: code,
    },
    {
      headers: {
        "X-Hasura-Tenant-Type": "STAFF",
      },
    }
  );
};

/********************************************************************************************************************* */
const changePassword = async (newPassword: string, oldPassword: string) => {
  const baseUrl = process.env.REACT_APP_AUTH_BASE_URL;
  await axios.post(
    baseUrl + "resetPassword",
    {
      newPassword: newPassword,
      oldPassword: oldPassword,
    },
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        "X-Hasura-Tenant-Type": "STAFF",
      },
    }
  );
};

/********************************************************************************************************************* */
const refreshToken = async () => {
  const baseUrl = process.env.REACT_APP_AUTH_BASE_URL;

  const refreshToken = localStorage.getItem("refreshToken");
  const token = localStorage.getItem("accessToken");

  if (refreshToken) {
    const response = await axios.post(
      baseUrl + "refreshToken",
      { refreshToken },
      {
        headers: {
          authorization: token ? `Bearer ${token}` : "",
          "X-Hasura-Tenant-Type": "STAFF",
        },
      }
    );
    const { access_token, refresh_token } = response.data;

    if (response) {
      localStorage.setItem("accessToken", access_token);
      localStorage.setItem("refreshToken", refresh_token);
    }

    localStorage.setItem(
      "expires_in",
      Date.now() + response.data.expires_in * 1000 + ""
    );
    const decoded: any = jwt_decode(access_token);
    return decoded["x-hasura-user-id"];
  } else {
    throw new Error("Invalid refresh token");
  }
};

const verifyEmail = async (code: string) => {
  const baseUrl = process.env.REACT_APP_AUTH_BASE_URL;
  return await axios.post(
    baseUrl + "verifyEmail",
    { code },
    {
      headers: {
        "X-Hasura-Tenant-Type": "STAFF",
      },
    }
  );
};

export {
  signIn,
  signOut,
  resetPassword,
  refreshToken,
  setPassword,
  changePassword,
  verifyEmail,
};
