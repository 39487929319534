import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import CommonLoader from "../../../components/CommonLoader";
import TableStatus from "../../../components/TableStatus";
import { useDownloadTdsZipMutation } from "../../../data-access/hooks/company/download-zip-files.hooks";
import { GetAllTdsByTradeQuery } from "../../../data-access/hooks/company/emi-calendar.hooks";

interface TDSTransferProps {
  allTdsData: GetAllTdsByTradeQuery;
  getAllLoading: boolean;
}
const TDSTransaction = ({ allTdsData, getAllLoading }: TDSTransferProps) => {
  const [selectedTDS, setSelectedTDS] = useState<number[]>([]);

  /**************************************************************************************************************************************** */
  function handleSelectedTDS(event: any, id: number) {
    if (event.target.checked) {
      setSelectedTDS([...selectedTDS, id]);
    } else {
      const filterData = selectedTDS?.filter(
        (dataItem: any) => id !== dataItem
      );
      setSelectedTDS(filterData);
    }
  }

  /**************************************************************************************************************************************** */
  const [handleDowloadTds, { loading: tdsDownloadLoading }] =
    useDownloadTdsZipMutation({
      onCompleted: (dataItem) => {
        const url = dataItem.DownloadZip?.downloadUrl;
        const link = document.createElement("a");
        link.href = url ?? "";
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      },
    });

  /**************************************************************************************************************************************** */
  const isSelected = (id: number) =>
    selectedTDS?.length !== 0 &&
    selectedTDS?.findIndex((item: any) => item === id) !== -1;

  return (
    <Paper>
      <Grid container>
        <Grid
          item
          xs={12}
          p={4}
          display="flex"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography fontWeight={600} fontSize={18}>
            TDS Transaction Details{" "}
          </Typography>
          <Button
            disabled={tdsDownloadLoading}
            onClick={() => {
              handleDowloadTds({
                variables: {
                  args: {
                    file_ids: selectedTDS,
                  },
                },
              });
            }}
            variant="primarySmall"
          >
            {tdsDownloadLoading ? (
              <CircularProgress size={20} />
            ) : (
              "Download Certificate"
            )}
          </Button>
        </Grid>
        {getAllLoading ? (
          <CommonLoader loadingCount={[1, 2, 3, 4, 5]} />
        ) : (
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      onChange={(event) => {
                        if (event.target.checked) {
                          const ids: number[] | any = allTdsData?.tds.map(
                            (item) => {
                              return item.tds_files?.file_ids[0];
                            }
                          );
                          setSelectedTDS(ids);
                        } else {
                          setSelectedTDS([]);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>TDS ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Amount Paid</TableCell>
                  <TableCell>Settlement Account</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allTdsData?.tds?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox
                        checked={isSelected(item.tds_files?.file_ids[0])}
                        onChange={(event) => {
                          handleSelectedTDS(event, item.tds_files?.file_ids[0]);
                        }}
                      />
                    </TableCell>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>
                      {item.created_at &&
                        format(new Date(item.created_at), "dd LLL yyyy")}
                    </TableCell>
                    <TableCell>
                      {TableStatus({
                        stautsText: `${item?.status}`,
                      })}
                    </TableCell>
                    <TableCell>₹ {item.amount}</TableCell>
                    <TableCell>
                      <Typography>
                        {item.transaction.counterparty_account.ref_bank.name}
                      </Typography>
                      <Typography>
                        {item.transaction.counterparty_account.account_no}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default TDSTransaction;
