import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import SimpleInput from "../../../components/input/SimpleInput";
import SimpleSelect from "../../../components/input/SimpleSelect";
import {
  useAddInvestorDetailsMutation,
  useGetAllCountryQuery,
  useGetAllStateQuery,
  useUpdateInvestorMutation,
} from "../../../data-access/hooks/company/investors.hooks";
import { Investor_Type } from "../../../data-access/types";
import * as Yup from "yup";
import { getChangedValues } from "../../../utils/common";
import SideModal from "../../../components/Modals/SideModal";

interface GetAllCountryInterface {
  name?: string;
  id?: number;
}

interface GetAllStateInterface {
  name?: string;
  id?: number;
  country_id?: number;
}

const companyPanRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

const AddInvestor = ({
  setCurrentStep,
  processData,
  setProcessData,
  editData,
  setOpen,
  getInvestorRefetch,
  open,
  currentStep,
}: any) => {
  const editFields = {
    name: editData?.name || processData?.investorData?.name,
    country_id: editData?.country_id || processData?.investorData?.country_id,
    pan: editData?.pan || processData?.investorData?.pan,
    type: editData?.type || processData?.investorData?.type,
    address_line1:
      editData?.address_line1 || processData?.investorData?.address_line1,
    address_line2:
      editData?.address_line2 || processData?.investorData?.address_line2,
    address_line3:
      editData?.address_line3 || processData?.investorData?.address_line3,
    total_committed_budget:
      editData?.total_committed_budget ||
      processData?.investorData?.total_committed_budget,
    zipcode: editData?.zipcode || processData?.investorData?.zipcode,
    state_id: editData?.state_id || processData?.investorData?.state_id,
    cin: editData?.cin || processData?.investorData?.cin,
    id: editData?.id || processData?.investorData?.id,
  };

  const [apiErrors, setApiErrors] = React.useState("");

  const [allCountry, getAllCountry] =
    React.useState<GetAllCountryInterface[]>();

  const [allStates, getAllStates] = React.useState<GetAllStateInterface[]>();

  const investorType = [
    "NBFC",
    "HNI",
    "BANK",
    "RETAIL_INVESTOR",
    "FAMILY_OFFICE",
    "FUNDS",
    "CORPORATE",
  ];
  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter Investor Name"),
    country_id: Yup.string().required("Please select a company"),
    pan: Yup.string()
      .required("Please enter Investor Pan")
      .matches(companyPanRegex, "Please enter a valid PAN number"),
    type: Yup.string().required("Please select Investor Type"),
    address_line1: Yup.string().required("Please enter Investor address"),
    zipcode: Yup.string().required("Please enter Zipcode"),
    state_id: Yup.string().required("Please select State"),
  });

  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  const [handleAddInvestor, { loading: addInvestorLoading }] =
    useAddInvestorDetailsMutation({
      onCompleted: (item) => {
        setApiErrors("");
        getInvestorRefetch();
        setCurrentStep(1);
        setProcessData({
          ...processData,
          investorData: {
            id: item.OpsCreateInvestor?.investor_id,
            name: formik.values.name,
            country_id: formik.values.country_id,
            pan: formik.values.pan,
            type: formik.values.type as Investor_Type,
            address_line1: formik.values.address_line1,
            address_line2: formik.values.address_line2,
            address_line3: formik.values.address_line3,
            total_committed_budget: formik.values.total_committed_budget,
            zipcode: Number(formik.values.zipcode),
            state_id: formik.values.state_id,
            cin: formik.values.cin,
            status: "ACTIVE",
          },
        });
      },
      onError: (item) => {
        setApiErrors(item.message);
      },
    });

  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  const [handleUpdateInvestor, { loading: updateInvestorLoading }] =
    useUpdateInvestorMutation({
      onCompleted: (item) => {
        setApiErrors("");
        getInvestorRefetch();
        if (processData?.investorData.id) {
          setCurrentStep(1);
          setProcessData({
            ...processData,
            investorData: {
              id: item.update_investor_by_pk?.id,
              name: item.update_investor_by_pk?.name,
              country_id: formik.values.country_id,
              pan: formik.values.pan,
              type: formik.values.type as Investor_Type,
              address_line1: formik.values.address_line1,
              address_line2: formik.values.address_line2,
              address_line3: formik.values.address_line3,
              total_committed_budget: formik.values.total_committed_budget,
              zipcode: Number(formik.values.zipcode),
              state_id: formik.values.state_id,
              cin: formik.values.cin,
              status: "ACTIVE",
            },
          });
        } else {
          setOpen(false);
          getInvestorRefetch();
        }
      },
      onError: (item) => {
        setApiErrors(item.message);
      },
    });

  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  const formik = useFormik({
    initialValues: {
      name: editFields.id ? editFields?.name : "",
      country_id: editFields.id ? editFields?.country_id : 101,
      pan: editFields.id ? editFields?.pan : "",
      type: editFields.id ? editFields?.type : "",
      address_line1: editFields.id ? editFields?.address_line1 : "",
      address_line2: editFields.id ? editFields?.address_line2 : "",
      address_line3: editFields.id ? editFields?.address_line3 : "",
      total_committed_budget: editFields.id
        ? editFields?.total_committed_budget
        : null,
      zipcode: editFields.id ? editFields?.zipcode : "",
      state_id: editFields.id ? editFields?.state_id : 0,
      cin: editFields.id ? editFields?.cin : "",
    },
    validationSchema: validationSchema,
    onSubmit: (fieldValues) => {
      setApiErrors("");
      const data = getChangedValues(formik.values, editFields);
      if (editFields?.id) {
        handleUpdateInvestor({
          variables: {
            pk_columns: {
              id: editFields.id,
            },
            set: data,
          },
        });
      } else {
        handleAddInvestor({
          variables: {
            args: {
              name: fieldValues.name,
              country_id: fieldValues.country_id,
              pan: fieldValues.pan,
              investor_type: fieldValues.type as Investor_Type,
              address_line_1: fieldValues.address_line1,
              address_line_2: fieldValues.address_line2,
              address_line_3: fieldValues.address_line3,
              total_committed_budget: fieldValues.total_committed_budget,
              zipcode: Number(fieldValues.zipcode),
              state_id: fieldValues.state_id,
              cin: fieldValues.cin,
              status: "ACTIVE",
            },
          },
        });
      }
    },
  });

  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  const { loading: countryLoading } = useGetAllCountryQuery({
    onCompleted: (item) => {
      getAllCountry(item?.ref_country);
    },
  });

  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  const { loading: stateLoading } = useGetAllStateQuery({
    onCompleted: (item) => {
      getAllStates(item?.ref_states);
    },
    variables: {
      where: {
        country_id: { _eq: formik.values.country_id },
      },
    },
  });

  return (
    <SideModal
      open={open && currentStep === 0 ? true : false}
      onClose={() => setOpen(false)}
      modalTitle={editFields?.id ? "Update Investor" : "Add Investor"}
      footer={
        <Button
          disabled={addInvestorLoading || updateInvestorLoading}
          type="submit"
          fullWidth
          variant="primarySmall"
          form="add-investor-form"
        >
          {addInvestorLoading || updateInvestorLoading ? (
            <CircularProgress size={20} />
          ) : editData?.id ? (
            "Submit"
          ) : (
            "Next"
          )}
        </Button>
      }
    >
      <form onSubmit={formik.handleSubmit} id="add-investor-form">
        {Boolean(apiErrors) && (
          <Alert style={{ margin: "10px 0" }} severity="error">
            {apiErrors}
          </Alert>
        )}
        {stateLoading || countryLoading ? (
          <Grid
            container
            justifyContent={"center"}
            height={"50vh"}
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <SimpleSelect
                  name="type"
                  error={formik.touched.type && Boolean(formik.errors.type)}
                  id="type"
                  requiredlabel
                  displayEmpty
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.type}
                  helperText={formik.touched.type && formik.errors.type}
                  formlabel="Type"
                  renderValue={(values: any) => {
                    if (formik.values.type === "") {
                      return <Typography>Select</Typography>;
                    } else {
                      return values;
                    }
                  }}
                >
                  {investorType.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </SimpleSelect>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <SimpleInput
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  name="name"
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  id="name"
                  placeholder="Enter the investment firm name"
                  inputlabel="Investor Name"
                  requiredlabel
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <SimpleInput
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pan}
                  name="pan"
                  error={formik.touched.pan && Boolean(formik.errors.pan)}
                  helperText={formik.touched.pan && formik.errors.pan}
                  id="pan"
                  placeholder="Ex: EXYPS76507"
                  inputlabel="Investor Pan"
                  requiredlabel
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <SimpleInput
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cin}
                  name="cin"
                  error={formik.touched.cin && Boolean(formik.errors.cin)}
                  helperText={formik.touched.cin && formik.errors.cin}
                  id="cin"
                  placeholder="Enter CIN"
                  inputlabel="Investor CIN (optional)"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <SimpleInput
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.total_committed_budget}
                  name="total_committed_budget"
                  error={
                    formik.touched.total_committed_budget &&
                    Boolean(formik.errors.total_committed_budget)
                  }
                  helperText={
                    formik.touched.total_committed_budget &&
                    formik.errors.total_committed_budget
                  }
                  id="total_committed_budget"
                  placeholder="Enter Amount"
                  inputlabel="Total Committed Amount"
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <SimpleInput
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address_line1}
                  name="address_line1"
                  error={
                    formik.touched.address_line1 &&
                    Boolean(formik.errors.address_line1)
                  }
                  helperText={
                    formik.touched.address_line1 && formik.errors.address_line1
                  }
                  id="address_line1"
                  placeholder="Enter Address"
                  inputlabel="Address Line 1"
                  requiredlabel
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <SimpleInput
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address_line2}
                  name="address_line2"
                  error={
                    formik.touched.address_line2 &&
                    Boolean(formik.errors.address_line2)
                  }
                  helperText={
                    formik.touched.address_line2 && formik.errors.address_line2
                  }
                  id="address_line2"
                  placeholder="Enter Address"
                  inputlabel="Address Line 2"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <SimpleInput
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address_line3}
                  name="address_line3"
                  error={
                    formik.touched.address_line3 &&
                    Boolean(formik.errors.address_line3)
                  }
                  helperText={
                    formik.touched.address_line3 && formik.errors.address_line3
                  }
                  id="address_line3"
                  placeholder="Enter Address"
                  inputlabel="Address Line 3"
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <SimpleSelect
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.country_id}
                  name="country_id"
                  error={
                    formik.touched.country_id &&
                    Boolean(formik.errors.country_id)
                  }
                  helperText={
                    formik.touched.country_id && formik.errors.country_id
                  }
                  id="country_id"
                  formlabel="Country"
                  requiredlabel
                >
                  {allCountry?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </SimpleSelect>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <SimpleSelect
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.state_id}
                  name="state_id"
                  error={
                    formik.touched.state_id && Boolean(formik.errors.state_id)
                  }
                  helperText={formik.touched.state_id && formik.errors.state_id}
                  displayEmpty
                  requiredlabel
                  id="state_id"
                  placeholder="Please enter"
                  formlabel="State"
                  renderValue={(values: any) => {
                    if (formik.values.state_id === 0) {
                      return <Typography>Select</Typography>;
                    } else {
                      return allStates?.find(
                        (item) => item.id === formik.values.state_id
                      )?.name;
                    }
                  }}
                >
                  {allStates?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </SimpleSelect>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <SimpleInput
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.zipcode}
                  name="zipcode"
                  error={
                    formik.touched.zipcode && Boolean(formik.errors.zipcode)
                  }
                  helperText={formik.touched.zipcode && formik.errors.zipcode}
                  id="zipcode"
                  placeholder="Enter Zip Code"
                  inputlabel="Zip Code"
                  requiredlabel
                  type="number"
                ></SimpleInput>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </form>
    </SideModal>
  );
};

export default AddInvestor;
