import { useNavigate, useParams } from "react-router-dom";
import React, { useContext } from "react";
import { useInitiateDealMutation } from "../data-access/hooks/company/deals-trade.hooks";
import {
  useDownloadScoreReportLazyQuery,
  useGetScoreAndFactorQuery,
} from "../data-access/hooks/company/score.hooks";
import { CompanyContext } from "../contexts/CompanyContext";

export interface TableDataInterface {
  score_id: number;
  company_id: number;
  min_offer_amount: number;
  rating: number;
  facility_limit: number;
  arr: number;
  financial_score: number;
  business_growth_score: number;
  capital_efficiency_score: number;
  customer_value_score: number;
  management_score: number;
  scored_on: number;
  report_file_id?: number | null | undefined;
  factors: FactorsInterface[];
}

interface FactorsInterface {
  time_series: string;
  sequence: number;
  score_factors: any[];
  score_factor_title: any[];
}

const useScoringEngine = () => {
  const { id } = useParams();
  const [openScoreModal, setOpenScoreModal] = React.useState(false);

  const [openFactorModal, setOpenFactorModal] = React.useState(false);

  const [tableData, setTableData] = React.useState<TableDataInterface[]>();

  const [stepNumber, setStepNumber] = React.useState(0);

  const [editScoreData, setEditScoreData] = React.useState<any>();

  const [createdScoreId, setCreatedScoreId] = React.useState(null);

  const [editType, setEditType] = React.useState(false);

  const navigate = useNavigate();

  const { setShowAlert } = useContext(CompanyContext);

  /*************************************************************************************************************/
  const [handleDownloadCamReport, { loading: downloadLoading }] =
    useDownloadScoreReportLazyQuery({
      onCompleted: (data) => {
        const downloadUrl = data?.generateFileRegistryDownloadURL?.download_url;
        const link = document.createElement("a");
        link.href = `${downloadUrl}`;

        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      },
      onError: () =>
        setShowAlert({
          type: "error",
          message: "Something went wrong please try again.",
          show: true,
        }),
    });

  /*************************************************************************************************************/
  const [handleInitailDeal, { loading: initaiteLoading }] =
    useInitiateDealMutation({
      onCompleted: (item) => navigate("/deals"),
      onError: (item) =>
        setShowAlert({
          type: "error",
          message: item?.message,
          show: true,
        }),
    });
  /*************************************************************************************************************/
  const { loading: scoreFactorsLoading, refetch: scoreFactorRefetch } =
    useGetScoreAndFactorQuery({
      fetchPolicy: "no-cache",
      variables: {
        //@ts-ignore
        companyId: id,
      },
      onCompleted: (item) => {
        const scoreData = item.company_score.map((score) => {
          return {
            score_id: score.id,
            company_id: score.company_id,
            min_offer_amount: score.min_bid_price,
            rating: score.rating,
            facility_limit: score.facility_limit,
            arr: score.arr,
            financial_score: score.financials_score,
            business_growth_score: score.business_growth_score,
            capital_efficiency_score: score.capital_efficiency_score,
            customer_value_score: score.customer_value_score,
            management_score: score.management_score,
            min_recommended_bid: score.min_recommended_bid,
            max_recommended_bid: score.max_recommended_bid,
            report_file_id: score.report_file_id,
            scored_on: score.scored_on,
            factors: score.company_score_time_series.map((time_item) => {
              return {
                score_factor_id: time_item.id,
                time_series: time_item.time_series,
                sequence: time_item.sequence,
                score_factors: time_item.company_score_factors,
                score_factor_title: item.score_factor,
              };
            }),
          };
        });
        setTableData(scoreData);
      },
      onError: (item) => {
        setShowAlert({
          show: true,
          message: item?.message,
          type: "error",
        });
      },
    });

  return {
    id,
    openScoreModal,
    setOpenScoreModal,
    openFactorModal,
    setOpenFactorModal,
    tableData,
    stepNumber,
    setStepNumber,
    editScoreData,
    setEditScoreData,
    createdScoreId,
    setCreatedScoreId,
    editType,
    setEditType,
    navigate,
    handleDownloadCamReport,
    downloadLoading,
    scoreFactorsLoading,
    scoreFactorRefetch,
    handleInitailDeal,
    initaiteLoading,
  };
};

export default useScoringEngine;
