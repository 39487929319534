import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import {
  Button,
  TableCell as MuiTableCell,
  TablePagination,
  Tooltip,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableWrapper } from "../../theme/customComponents";
import { format } from "date-fns";
import { Checkbox, Pagination, Typography } from "@mui/material";
import styled from "@emotion/styled";
import CommonLoader from "../../components/CommonLoader";
import TableStatus from "../../components/TableStatus";
import { useNavigate } from "react-router-dom";

interface allDealsInterface {
  deal_id: any;
  score_id: any;
  company_name: any;
  company_id: any;
  status: any;
  created_at: any;
  deal_expiry: any;
  offer_closure: any;
  arr: any;
  facility_limit: any;
  min_bid_price: any;
  max_recommended_bid: any;
  min_recommended_bid: any;
  business_growth_score: any;
  customer_retention: any;
  customer_value_score: any;
  financials_score: any;
  gross_margin: any;
  management_score: any;
  payback_period: any;
  revenue_retention: any;
  estimated_runway: any;
  company_score_id: any;
  ltv_cac: any;
  capital_efficiency_score: any;
  scoring_version: any;
  top_offer: any;
  total_offers: any;
}
interface paginationDataInterface {
  offset: number;
  limit: number;
  totalcount: number;
  currentPage: number;
}
interface dealsTableInterface {
  data?: any[];
  loading?: boolean;
  selectedRow?: number;
  onSelect?: any;
  paginationData: paginationDataInterface;
  setPaginationData?: any;
  getOffersById?: any;
  offerPaginationData?: any;
  setDealId?: any;
}

const CustomCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding: 8px 14px;
  font-size: 14px !important;
`;

const DealsTable = ({
  data,
  loading,
  selectedRow,
  onSelect,
  paginationData,
  setPaginationData,
  getOffersById,
  offerPaginationData,
  setDealId,
}: dealsTableInterface) => {
  const pageCount = (number: number) => {
    return Math.ceil(number / paginationData.limit);
  };

  const navigate = useNavigate();

  return (
    <Paper>
      <Typography padding={4} fontSize={"18px"} fontWeight={600}>
        Deal
      </Typography>
      <TableWrapper
        style={{
          maxHeight: "calc(100vh - 322px)",
        }}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Company ID</TableCell>
                <TableCell>Deal ID</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Deal Expiry</TableCell>
                <TableCell>Offer Closure</TableCell>
                <TableCell>ARR</TableCell>
                <TableCell>Facility Limit</TableCell>
                <TableCell>Total offers</TableCell>
                <TableCell>Top Offer</TableCell>
                <TableCell>Min Offer Price</TableCell>
                <TableCell>Min Recommended Offer</TableCell>
                <TableCell>Max Recommended Offer</TableCell>
                <TableCell>Business Growth Score</TableCell>
                <TableCell>Capital Efficiency Score</TableCell>
                <TableCell>Customer Retention</TableCell>
                <TableCell>Customer Value Score</TableCell>
                <TableCell>Financials Score</TableCell>
                <TableCell>Gross Margin</TableCell>
                <TableCell>Management Score</TableCell>
                <TableCell>Payback Period</TableCell>
                <TableCell>Revenue Retention</TableCell>
                <TableCell>Estimated Runway</TableCell>
                <TableCell>Company Score ID</TableCell>
                <TableCell>LTV_CAC</TableCell>
                <TableCell>Scoring Version</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item: allDealsInterface, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <CustomCheckbox
                        onChange={() => {
                          onSelect(item.company_id);
                          setDealId(item.deal_id);
                          getOffersById({
                            variables: {
                              deal_id: item.deal_id,
                              limit: offerPaginationData.limit,
                              offset: offerPaginationData.offset,
                            },
                          });
                        }}
                        checked={item.deal_id === selectedRow}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="linkButton"
                        style={{
                          color: "#000000",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          navigate(`/company/${item.company_id}/details`);
                        }}
                      >
                        {item.company_id}
                      </Button>
                    </TableCell>
                    <TableCell>{item.deal_id || "-"}</TableCell>
                    <TableCell>
                      <Tooltip
                        title={item?.company_name ?? "-"}
                        arrow
                        placement="right"
                      >
                        {(
                          <Typography whiteSpace={"nowrap"}>
                            {item?.company_name === null
                              ? "-"
                              : `${item?.company_name?.substring(0, 8)}...`}
                          </Typography>
                        ) || "-"}
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <TableStatus
                        style={{
                          padding: "3px 6px",
                          fontSize: 12,
                          fontWeight: 600,
                          borderRadius: 4,
                        }}
                        stautsText={`${item?.status}`}
                      />
                    </TableCell>
                    <TableCell>
                      {(
                        <Typography whiteSpace={"nowrap"}>
                          {format(Date.parse(item.created_at), "dd LLL yyyy")}
                        </Typography>
                      ) || "-"}
                    </TableCell>
                    <TableCell>
                      {(
                        <Typography whiteSpace={"nowrap"}>
                          {format(Date.parse(item.deal_expiry), "dd LLL yyyy")}
                        </Typography>
                      ) || "-"}
                    </TableCell>
                    <TableCell>
                      {(
                        <Typography whiteSpace={"nowrap"}>
                          {format(
                            Date.parse(item.offer_closure),
                            "dd LLL yyyy"
                          )}
                        </Typography>
                      ) || "-"}
                    </TableCell>
                    <TableCell>{item.arr || "-"}</TableCell>
                    <TableCell>{item.facility_limit || "-"}</TableCell>
                    <TableCell>{item.total_offers || "-"}</TableCell>
                    <TableCell>{item.top_offer?.toFixed(3) || "-"}</TableCell>
                    <TableCell>{item.min_bid_price || "-"}</TableCell>
                    <TableCell>{item.min_recommended_bid || "-"}</TableCell>
                    <TableCell>{item.max_recommended_bid || "-"}</TableCell>
                    <TableCell>{item.business_growth_score || "-"}</TableCell>
                    <TableCell>
                      {item.capital_efficiency_score || "-"}
                    </TableCell>
                    <TableCell>{item.customer_retention || "-"}</TableCell>
                    <TableCell>{item.customer_value_score || "-"}</TableCell>
                    <TableCell>{item.financials_score || "-"}</TableCell>
                    <TableCell>{item.gross_margin || "-"}</TableCell>
                    <TableCell>{item.management_score || "-"}</TableCell>
                    <TableCell>{item.payback_period || "-"}</TableCell>
                    <TableCell>{item.revenue_retention || "-"}</TableCell>
                    <TableCell>{item.estimated_runway || "-"}</TableCell>
                    <TableCell>{item.company_score_id || "-"}</TableCell>
                    <TableCell>{item.ltv_cac || "-"}</TableCell>
                    <TableCell>{item.scoring_version || "-"}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableWrapper>
      {paginationData?.totalcount > 10 && (
        <TablePagination
          colSpan={20}
          count={pageCount(paginationData?.totalcount ?? 0)}
          onPageChange={(event: any, value: any) => {
            const newOffset =
              paginationData?.limit * value - paginationData?.limit;
            setPaginationData({
              offset: newOffset,
              limit: paginationData?.limit,
              totalcount: paginationData?.totalcount,
              currentPage: value,
            });
          }}
          component="div"
          page={paginationData?.currentPage}
          rowsPerPage={paginationData.limit}
          rowsPerPageOptions={[10, 20, 50]}
          labelDisplayedRows={() => <div style={{ display: "none" }}></div>}
          labelRowsPerPage={"Rows:"}
          onRowsPerPageChange={(event) => {
            setPaginationData({
              ...paginationData,
              limit: Number(event.target.value),
              offsets: 0,
              currentPage: 1,
            });
          }}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          ActionsComponent={() => (
            <Pagination
              count={pageCount(paginationData?.totalcount ?? 0)}
              variant={"outlined"}
              page={paginationData?.currentPage}
              onChange={(event: any, value: any) => {
                const newOffset =
                  paginationData?.limit * value - paginationData?.limit;
                setPaginationData({
                  offset: newOffset,
                  limit: paginationData?.limit,
                  totalcount: paginationData?.totalcount,
                  currentPage: value,
                });
              }}
            />
          )}
        />
      )}
    </Paper>
  );
};

export default DealsTable;
