import {
  AllDealsByIdQuery,
  AllTradeByIdQuery,
  useAllTradeByIdQuery,
  useDownloadAmortizationTableMutation,
} from "./../hooks/company/deals-trade.hooks";
import { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAllDealsByIdQuery } from "../hooks/company/deals-trade.hooks";
import { CompanyContext } from "../../contexts/CompanyContext";
import { TableType } from "../types";

interface downloadInterface {
  type: TableType;
  trade_id: number;
}

const useDealsTrade = () => {
  const navigate = useNavigate();

  const params = useParams();

  const [alldealsData, setAllDealData] = useState<AllDealsByIdQuery>();

  const [allTradeData, setAllTradeData] = useState<AllTradeByIdQuery>();

  const { companyData } = useContext(CompanyContext);

  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  //*************************************************************************************************** */
  const { loading: getDealDataLoading } = useAllDealsByIdQuery({
    variables: {
      //@ts-ignore
      company_id: parseInt(params?.id),
    },
    onCompleted: (item) => setAllDealData(item),
  });

  //*************************************************************************************************** */
  const { loading: getTradeDataLoading } = useAllTradeByIdQuery({
    variables: {
      //@ts-ignore
      company_id: parseInt(params?.id),
    },
    onCompleted: (item) => setAllTradeData(item),
  });

  //*************************************************************************************************** */
  const [downloadAmortizationTable, { loading: downloadLoading }] =
    useDownloadAmortizationTableMutation({
      onCompleted: (data) => {
        const downloadUrl = data?.DownloadAmortizationTable?.downloadUrl;
        const link = document.createElement("a");
        //@ts-ignore
        link.href = downloadUrl;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      },
    });

  const handleDownloadTable = ({ type, trade_id }: downloadInterface) => {
    downloadAmortizationTable({
      variables: {
        args: {
          table_type: type,
          trade_id: trade_id,
        },
      },
    });
  };
  //*************************************************************************************************** */
  const loading = getDealDataLoading || getTradeDataLoading;

  return {
    navigate,
    params,
    loading,
    alldealsData,
    allTradeData,
    companyData,
    handleDownloadTable,
    TableType,
    downloadLoading,
    showAlert,
    setShowAlert,
  };
};

export default useDealsTrade;
