import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const EscorwAccount = ({ allTradeData, availableBalance }: any) => {
  return (
    <Paper>
      <Grid container>
        <Grid item xs={12} p={4}>
          <Typography fontWeight={600} fontSize={18}>
            Current available balance (Escrow Account)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Account Name</TableCell>
                <TableCell>Account Number</TableCell>
                <TableCell>Available Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {allTradeData?.investments[0].withhold_account?.name}
                </TableCell>
                <TableCell>
                  {allTradeData?.investments[0].withhold_account?.account_no}
                </TableCell>
                <TableCell>{`₹ ${availableBalance}`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EscorwAccount;
