import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
  CircularProgress,
  Modal,
  Divider,
  Paper,
} from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { TableWrapper } from "../../../theme/customComponents";
import CommonBreadCrumb from "../../../components/CommonBreadCrumb";
import useCompanyDetails from "../../../hooks/useCompanyDetails";
import CompanyPan from "./CompanyPanModal";
import CommonLoader from "../../../components/CommonLoader";
import TableStatus from "../../../components/TableStatus";

const CompanyDetails: React.FC = () => {
  const {
    id,
    formik,
    mcaProfile,
    downloadLoading,
    loading,
    companyDetailsData,
    financialsLoading,
    getFinancialsData,
    handleDownloadCamReport,
    openPanModal,
    setOpenPanModal,
    getCompanyRefetch,
    companyDetailsMCAProfileData,
  } = useCompanyDetails();

  const isLLPCompany =
    companyDetailsMCAProfileData &&
    Object.keys(companyDetailsMCAProfileData).includes("LLPIN");

  return (
    <>
      <Helmet title="Company Detail" />
      <Grid
        item
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {/*---------------------------COMMON BREAD CRUMBS--------------------------- */}
        <Grid item>
          <CommonBreadCrumb
            typographyText="Company Details"
            linksText={[{ link: "  Company Queues", to: "/company-queues" }]}
            showCompany
          />
        </Grid>
        <Grid item display="flex" gap="6px">
          <Button
            disabled={downloadLoading}
            onClick={() =>
              handleDownloadCamReport({
                variables: {
                  //@ts-ignore
                  company_id: Number(id),
                },
              })
            }
            variant="secondaryMedium"
          >
            {downloadLoading ? (
              <CircularProgress size={20} />
            ) : (
              "Generate Cam Report"
            )}
          </Button>
          <Button
            disabled={financialsLoading}
            onClick={() =>
              getFinancialsData({
                variables: {
                  //@ts-ignore
                  company_id: Number(id),
                },
              })
            }
            variant="secondaryMedium"
          >
            {financialsLoading ? (
              <CircularProgress size={20} />
            ) : (
              "Get Financials"
            )}
          </Button>
          <Button onClick={() => setOpenPanModal(true)} variant="primaryMedium">
            Update Company Pan
          </Button>
        </Grid>
      </Grid>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{
          margin: "16px 0 28px 0",
        }}
      />
      <form onSubmit={formik.handleSubmit}>
        <Grid item>
          {/**-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */}
          <Grid item>
            <Paper>
              <Grid padding={"20px"} justifyContent="space-between" container>
                <Grid item>
                  <Typography fontWeight={600} variant="h4">
                    Company Details
                  </Typography>
                </Grid>
              </Grid>
              {loading ? (
                <CommonLoader loadingCount={[1]} />
              ) : (
                <TableWrapper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell>CIN</TableCell>
                        <TableCell>GST</TableCell>
                        <TableCell>Pan</TableCell>
                        <TableCell>Incorporation Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{companyDetailsData?.name || "-"}</TableCell>
                        <TableCell>{companyDetailsData?.cin || "-"}</TableCell>
                        <TableCell>
                          {companyDetailsData?.active_gstin || "-"}
                        </TableCell>
                        <TableCell>{companyDetailsData?.pan || "-"}</TableCell>
                        <TableCell>
                          {companyDetailsData?.incorporation_date || "-"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableWrapper>
              )}
            </Paper>
          </Grid>
          <br />
          <br />
          {/**-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */}
          <Grid item>
            <Paper>
              <Grid padding={"20px"} justifyContent="space-between" container>
                <Grid item>
                  <Typography fontWeight={600} variant="h4">
                    MCA Profile
                  </Typography>
                </Grid>
              </Grid>
              <TableWrapper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>CIN</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Company Status</TableCell>
                      <TableCell>ROC Code</TableCell>
                      <TableCell>Registration Number</TableCell>
                      <TableCell>Company Category</TableCell>
                      <TableCell>Company SubCategory</TableCell>
                      <TableCell>Class of Company</TableCell>
                      <TableCell>Date of Incorporation</TableCell>
                      <TableCell>Authorised Capital (Rs)</TableCell>
                      <TableCell>Paid up capital (Rs)</TableCell>
                      <TableCell>Date of Last Annual General Meeting</TableCell>
                      <TableCell>Listing Status</TableCell>
                      <TableCell>Total Members</TableCell>
                      <TableCell>Date of Last Balance Sheet</TableCell>
                      <TableCell>Email Id</TableCell>
                      <TableCell>Registered Address</TableCell>
                      <TableCell>Alternative Address</TableCell>
                      <TableCell>Suspended at Stock Exchange</TableCell>
                      {isLLPCompany && <TableCell>Solvency Filed</TableCell>}
                      {isLLPCompany && (
                        <TableCell>Total Designated Partners</TableCell>
                      )}
                      {isLLPCompany && <TableCell>Total Partners</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{ verticalAlign: "top", whiteSpace: "nowrap" }}
                      >
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.cin || "-"
                        )}
                      </TableCell>
                      <TableCell
                        style={{ verticalAlign: "top", whiteSpace: "nowrap" }}
                      >
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.company_name || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          TableStatus({
                            stautsText: `${mcaProfile?.company_status}`,
                          })
                        )}
                      </TableCell>
                      <TableCell
                        style={{ verticalAlign: "top", whiteSpace: "nowrap" }}
                      >
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.roc_code || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.registration_number || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.company_category || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.company_subcategory || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.class_of_company || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.date_of_incorporation || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.authorised_capital || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.paid_up_capital || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.date_of_last_AGM || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.whether_listed_or_not || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.number_of_members || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.date_of_balance_sheet || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.email_id || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.registered_address || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.alternative_address || "-"
                        )}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          mcaProfile.suspended_at_stock_exchange || "-"
                        )}
                      </TableCell>
                      {isLLPCompany && (
                        <TableCell style={{ verticalAlign: "top" }}>
                          {loading ? (
                            <Skeleton variant="text" />
                          ) : (
                            mcaProfile.solvency_filed || "-"
                          )}
                        </TableCell>
                      )}
                      {isLLPCompany && (
                        <TableCell style={{ verticalAlign: "top" }}>
                          {loading ? (
                            <Skeleton variant="text" />
                          ) : (
                            mcaProfile.number_of_designated_partners || "-"
                          )}
                        </TableCell>
                      )}
                      {isLLPCompany && (
                        <TableCell style={{ verticalAlign: "top" }}>
                          {loading ? (
                            <Skeleton variant="text" />
                          ) : (
                            mcaProfile.number_of_partners || "-"
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableWrapper>
            </Paper>
          </Grid>
          <br />
          <br />
          {/**-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */}
          <Grid item>
            <Paper>
              <Grid padding={"20px"} justifyContent="space-between" container>
                <Grid item>
                  <Typography fontWeight={600} variant="h4">
                    MCA Directors Signatory Details{" "}
                  </Typography>
                </Grid>
              </Grid>
              <TableWrapper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Address</TableCell>
                      <TableCell>Full Name</TableCell>
                      <TableCell>Designation</TableCell>
                      <TableCell>DIN/DPIN/PAN</TableCell>
                      <TableCell>dsc expiry date </TableCell>
                      <TableCell>Date Of Appointment</TableCell>
                      <TableCell>Wheather Dsc Registered </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companyDetailsData?.mca_signatories?.map(
                      (item: any, index: number) => {
                        const mcaSignatories = {
                          address: item["address"],
                          full_name: item["full_name"],
                          designation: item["designation"],
                          din_dpin_pan: item["DIN/DPIN/PAN"],
                          dsc_expiry_date: item["dsc_expiry_date"],
                          date_of_appointment: item["date_of_appointment"],
                          wheather_dsc_registered:
                            item["wheather_dsc_registered"],
                        };
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {loading ? (
                                <Skeleton variant="text" />
                              ) : (
                                mcaSignatories?.address || "-"
                              )}
                            </TableCell>
                            <TableCell>
                              {loading ? (
                                <Skeleton variant="text" />
                              ) : (
                                mcaSignatories?.full_name || "-"
                              )}
                            </TableCell>
                            <TableCell>
                              {loading ? (
                                <Skeleton variant="text" />
                              ) : (
                                mcaSignatories?.designation || "-"
                              )}
                            </TableCell>
                            <TableCell>
                              {loading ? (
                                <Skeleton variant="text" />
                              ) : (
                                mcaSignatories?.din_dpin_pan || "-"
                              )}
                            </TableCell>
                            <TableCell>
                              {loading ? (
                                <Skeleton variant="text" />
                              ) : (
                                mcaSignatories?.dsc_expiry_date || "-"
                              )}
                            </TableCell>
                            <TableCell>
                              {loading ? (
                                <Skeleton variant="text" />
                              ) : (
                                mcaSignatories?.date_of_appointment || "-"
                              )}
                            </TableCell>
                            <TableCell>
                              {loading ? (
                                <Skeleton variant="text" />
                              ) : (
                                mcaSignatories?.wheather_dsc_registered || "-"
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableWrapper>
            </Paper>
          </Grid>
          <br />
          <br />
        </Grid>
      </form>

      <Modal open={openPanModal} onClose={() => setOpenPanModal(false)}>
        <Grid
          container
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <CompanyPan
            setOpen={setOpenPanModal}
            getCompanyRefetch={getCompanyRefetch}
            companyDetailsData={companyDetailsData}
          />
        </Grid>
      </Modal>
    </>
  );
};

export default CompanyDetails;
