import { FormLabel, OutlinedInput, Select, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ReactComponent as ChevronDownIcon } from "../../assets/Icon/Outline/chevron-down-icon.svg";

const useStyles = makeStyles(() =>
  createStyles({
    customSelect: {
      padding: "0 40px 0 10px",
      height: "40px !important",
      display: "flex",
      alignItems: "center",
    },
  })
);

const SimpleSelect = (props: any) => {
  const classes = useStyles();

  return (
    <>
      <FormLabel
        style={{ fontSize: "14px", fontWeight: "500", marginBottom: "4px  " }}
      >
        {props.formlabel}
        {props.requiredlabel && <span style={{ color: "#E90000" }}>*</span>}
      </FormLabel>
      <Select
        {...props}
        IconComponent={() => (
          <span style={{ marginRight: "5px", display: "flex" }}>
            <ChevronDownIcon width="20px" height="20px" color="#1E88E5" />
          </span>
        )}
        classes={{
          select: classes.customSelect,
        }}
        input={<OutlinedInput />}
      >
        {props.children}
      </Select>
      <Typography style={{ marginTop: "3px", color: "#DD3838" }}>
        {props.helperText}
      </Typography>
    </>
  );
};

export default SimpleSelect;
