import React, { useContext } from "react";
import SideModal from "../../components/Modals/SideModal";
import { Button, CircularProgress, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  DateRange,
  DateRangePicker,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs, { Dayjs } from "dayjs";
import SimpleInput from "../../components/input/SimpleInput";
import { useGenerateMisReportMutation } from "../../data-access/hooks/company/deals-trade.hooks";
import { Mis_Report_Type } from "../../data-access/types";
import { CompanyContext } from "../../contexts/CompanyContext";
import { format } from "date-fns";

const CustomMISModal = ({ open, setOpen }: any) => {
  const { setShowAlert } = useContext(CompanyContext);
  const todayDate = new Date();

  const [value, setValue] = React.useState<DateRange<Dayjs>>([
    dayjs(todayDate),
    dayjs(todayDate.setDate(todayDate.getDate() + 1)),
  ]);

  const [handleDownloadMISReport, { loading }] = useGenerateMisReportMutation({
    onCompleted: (data) => {
      setOpen(false);
      const downloadUrl = data?.GenerateMISReport?.download_url;
      const link = document.createElement("a");
      //@ts-ignore
      link.href = downloadUrl;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      if (link && link.parentNode) {
        link.parentNode.removeChild(link);
      }
    },
    onError: (item) => {
      setShowAlert({
        show: true,
        message: item.message,
        type: "error",
      });
    },
  });

  return (
    <SideModal
      open={open}
      onClose={() => setOpen(false)}
      modalWidth="40%"
      modalTitle={"Custom Date Selection"}
      footer={
        <Button
          disabled={
            (value[0] && format(new Date(`${value[0]}`), "dd/MM/yyyy")) ===
            (value[1] && format(new Date(`${value[1]}`), "dd/MM/yyyy"))
          }
          onClick={() => {
            handleDownloadMISReport({
              variables: {
                args: {
                  request_type: Mis_Report_Type.Custom,
                  end_date: value[1],
                  start_date: value[0],
                },
              },
            });
          }}
          fullWidth
          variant="primarySmall"
          form="mis-form"
        >
          {loading ? <CircularProgress size={20} /> : "Apply"}
        </Button>
      }
    >
      <form id="mis-form">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            value={value}
            onChange={(newValue) => setValue(newValue)}
            renderInput={(startProps: any, endProps: any) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <SimpleInput
                    {...startProps}
                    autoComplete={"off"}
                    name="start_date"
                    inputlabel="Start"
                    label=""
                    error={
                      (value[0] &&
                        format(new Date(`${value[0]}`), "dd/MM/yyyy")) ===
                      (value[1] &&
                        format(new Date(`${value[1]}`), "dd/MM/yyyy"))
                    }
                  />
                  <SimpleInput
                    {...endProps}
                    autoComplete={"off"}
                    name="end_date"
                    inputlabel="End"
                    label=""
                    error={
                      (value[0] &&
                        format(new Date(`${value[0]}`), "dd/MM/yyyy")) ===
                      (value[1] &&
                        format(new Date(`${value[1]}`), "dd/MM/yyyy"))
                    }
                  />
                </div>
              );
            }}
          />
          {(value[0] && format(new Date(`${value[0]}`), "dd/MM/yyyy")) ===
            (value[1] && format(new Date(`${value[1]}`), "dd/MM/yyyy")) && (
            <Typography color={"#FF6363"}>
              Start date and end date cannot be same.
            </Typography>
          )}
        </LocalizationProvider>
      </form>
    </SideModal>
  );
};

export default CustomMISModal;
