import React, { useState } from "react";
import {
  Card,
  CardContent as MuiCardContent,
  CardHeader,
  Divider,
  Grid,
  Skeleton,
  Typography,
  Paper,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import CommonBreadCrumb from "../../../components/CommonBreadCrumb";
import { useGetAllGstReturnQuery } from "../../../data-access/hooks/company/company-gst.hooks";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { format } from "date-fns";
import _ from "lodash";
import EmptyData from "../../../components/EmptyData";

interface GstMetaDataInterface {
  __typename?: "company_gst_return";
  company_id: number;
  gstin: string;
  financial_year: string;
  dof: any;
  is_delay: boolean;
  status: string;
  tax_period: string;
  valid: string;
  rtntype: string;
  ret_prd: number;
  mof: string;
  is_active?: boolean | null;
  id: number;
  arn: string;
}

const CustomGstDiv = styled.div`
  height: 74px;
  min-width: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  flex-direction: column;
  white-space: nowrap;
  margin: 2px;
`;
const CardContent = styled(MuiCardContent)`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 2px;
  }
  ::-webkit-scrollbar-track {
    background: #1e88e555;
  }
  ::-webkit-scrollbar-thumb {
    background: #1e88e5;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const CompanyGST = () => {
  const { id: company_id } = useParams();

  const [allGstData, setAllGstData] = useState<GstMetaDataInterface[]>([]);

  const convertedData =
    allGstData &&
    _(allGstData)
      .groupBy((x) => x.gstin)
      .map((value, key) => ({ gst_number: key, gst_data: value }))
      .value();

  const returnGSTMonthColors = (type: string) => {
    switch (type.toLowerCase()) {
      case "filed":
        return "#30A986";
      case "not filed":
        return "#DC6766";
      case "na":
        return "#DDDDDD";
      case "to be filed":
        return "#EC9D08";
      default:
        break;
    }
  };

  const { loading: gstLoading } = useGetAllGstReturnQuery({
    variables: {
      company_id: Number(company_id),
    },
    onCompleted: (data) => {
      setAllGstData(data.company_gst_return);
    },
  });

  return (
    <Grid container>
      <Helmet title="Company GST" />
      <Grid item xs={12}>
        <CommonBreadCrumb
          typographyText="GST"
          linksText={[{ link: "Company Queues", to: "/company-queues" }]}
          showCompany
        />
      </Grid>
      <Grid item xs={12} mt="16px" mb="28px">
        <Divider />
      </Grid>
      {gstLoading ? (
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <span>
                  <Skeleton variant="text" width={"20%"} />
                  <Skeleton variant="text" width={"30%"} />
                </span>
              }
            ></CardHeader>
            <CardContent
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto auto auto auto auto auto",
                gap: "10px",
              }}
            >
              <Skeleton variant="rounded" height={"116px"} />
              <Skeleton variant="rounded" height={"116px"} />
              <Skeleton variant="rounded" height={"116px"} />
              <Skeleton variant="rounded" height={"116px"} />
              <Skeleton variant="rounded" height={"116px"} />
              <Skeleton variant="rounded" height={"116px"} />
              <Skeleton variant="rounded" height={"116px"} />
              <Skeleton variant="rounded" height={"116px"} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              title={
                <span>
                  <Skeleton variant="text" width={"20%"} />
                  <Skeleton variant="text" width={"30%"} />
                </span>
              }
            ></CardHeader>
            <CardContent
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto auto auto auto auto auto",
                gap: "10px",
              }}
            >
              <Skeleton variant="rounded" height={"116px"} />
              <Skeleton variant="rounded" height={"116px"} />
              <Skeleton variant="rounded" height={"116px"} />
              <Skeleton variant="rounded" height={"116px"} />
              <Skeleton variant="rounded" height={"116px"} />
              <Skeleton variant="rounded" height={"116px"} />
              <Skeleton variant="rounded" height={"116px"} />
              <Skeleton variant="rounded" height={"116px"} />
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <Grid item xs={12}>
          {convertedData?.length === 0 ? (
            <Paper>
              <EmptyData />
            </Paper>
          ) : (
            convertedData?.map((item, index) => {
              return (
                <Card key={index}>
                  <CardHeader
                    title={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography fontWeight={600} fontSize={18}>
                          GST No: {item.gst_number || "-"}
                        </Typography>
                        <Typography
                          style={{
                            background: item.gst_data.find(
                              (data) => data.gstin === item.gst_number
                            )?.is_active
                              ? "#57B86733"
                              : "#DD383833",
                            border: item.gst_data.find(
                              (data) => data.gstin === item.gst_number
                            )?.is_active
                              ? "#57B867 solid 1px"
                              : "#DD3838 solid 1px",
                            color: item.gst_data.find(
                              (data) => data.gstin === item.gst_number
                            )?.is_active
                              ? "#57B867"
                              : "#DD3838",
                            padding: "4px 8px",
                            fontWeight: 600,
                            fontSize: 14,
                          }}
                        >
                          {item.gst_data.find(
                            (data) => data.gstin === item.gst_number
                          )?.is_active
                            ? "Active"
                            : "In-Active"}
                        </Typography>
                      </span>
                    }
                  ></CardHeader>
                  <CardContent>
                    <span
                      style={{
                        display: "flex",
                      }}
                    >
                      <CustomGstDiv
                        key={index}
                        style={{ background: "#2C4E86", fontWeight: "600" }}
                      >
                        GSTR-1 /IFF
                      </CustomGstDiv>
                      {item.gst_data
                        .filter((inner_item) => inner_item.rtntype === "GSTR1")
                        .map((data_item, index) => (
                          <CustomGstDiv
                            key={index}
                            style={{
                              background: returnGSTMonthColors(
                                data_item.status
                              ),
                            }}
                          >
                            <span>
                              {format(new Date(data_item.dof), "LLL - yyyy")}
                            </span>
                            <span style={{ fontSize: 12, fontWeight: 600 }}>
                              {data_item.status}
                            </span>
                          </CustomGstDiv>
                        ))}
                    </span>
                    <span
                      style={{
                        display: "flex",
                      }}
                    >
                      <CustomGstDiv
                        key={index}
                        style={{ background: "#2C4E86", fontWeight: "600" }}
                      >
                        GSTR-3B
                      </CustomGstDiv>
                      {item.gst_data
                        .filter((inner_item) => inner_item.rtntype === "GSTR3B")
                        .map((data_item, index) => (
                          <CustomGstDiv
                            key={index}
                            style={{
                              background: returnGSTMonthColors(
                                data_item.status
                              ),
                            }}
                          >
                            <span>
                              {format(new Date(data_item.dof), "LLL - yyyy")}
                            </span>
                            <span style={{ fontSize: 12, fontWeight: 600 }}>
                              {data_item.status}
                            </span>
                          </CustomGstDiv>
                        ))}
                    </span>
                  </CardContent>
                </Card>
              );
            })
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default CompanyGST;
