export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: any;
  float8: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

export enum Agreement_Category {
  Apa = 'APA',
  Nda = 'NDA',
  TenantAgreement = 'TENANT_AGREEMENT'
}

export enum Annexure_Type {
  Apa = 'APA',
  Rta = 'RTA'
}

export type AccountingExcelUploadInput = {
  file_ids?: InputMaybe<Array<Scalars['Int']>>;
  integration_id?: InputMaybe<Scalars['Int']>;
  req_type?: InputMaybe<RequestType>;
};

export type AccountingExcelUploadOutput = {
  __typename?: 'AccountingExcelUploadOutput';
  id: Scalars['Int'];
  message: Scalars['String'];
};

export type AccountingSyncInput = {
  source: Scalars['String'];
};

export type AccountingSyncOutput = {
  __typename?: 'AccountingSyncOutput';
  message: Scalars['String'];
};

export type AddCollaboratorInput = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  mobile_number: Scalars['String'];
  user_type: UserType;
};

export type AddCollaboratorOutput = {
  __typename?: 'AddCollaboratorOutput';
  message: Scalars['String'];
  user_id: Scalars['Int'];
};

export type AddDocumentchecklistForCompanyInput = {
  company_id: Scalars['Int'];
  sales_email: Scalars['String'];
};

export type AddDocumentchecklistForCompanyOutput = {
  __typename?: 'AddDocumentchecklistForCompanyOutput';
  message: Scalars['String'];
};

export type AddEmiTransactionInput = {
  amount: Scalars['Float'];
  bounce_charges: Scalars['Float'];
  interest_charges: Scalars['Float'];
  remarks?: InputMaybe<Scalars['String']>;
  scheduled_emi_id: Scalars['Int'];
  timestamp: Scalars['timestamp'];
  trade_id: Scalars['Int'];
  trade_installment_seq: Scalars['Int'];
  transaction_method: Scalars['String'];
  transaction_method_id: Scalars['String'];
};

export type AddEmiTransactionOutput = {
  __typename?: 'AddEMITransactionOutput';
  message: Scalars['String'];
};

export type AddInvestorUserInput = {
  designation: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  mobile_number: Scalars['String'];
  pan?: InputMaybe<Scalars['String']>;
};

export type AddInvestorUserOutput = {
  __typename?: 'AddInvestorUserOutput';
  investor_users?: Maybe<Array<Scalars['Int']>>;
  message: Scalars['String'];
};

export type AddPayoutTransactionInput = {
  amount: Scalars['Float'];
  remarks: Scalars['String'];
  timestamp: Scalars['timestamp'];
  trade_id: Scalars['Int'];
  transaction_method: Scalars['String'];
  transaction_method_id: Scalars['String'];
  transaction_status: Scalars['String'];
};

export type AddPayoutTransactionOutput = {
  __typename?: 'AddPayoutTransactionOutput';
  message: Scalars['String'];
};

export type AddPhysicalAccountInput = {
  account_holder_name: Scalars['String'];
  account_no: Scalars['String'];
  account_type: Scalars['String'];
  bank_id: Scalars['Int'];
  ifsc_code: Scalars['String'];
  is_primary: Scalars['Boolean'];
  multi_signatory?: InputMaybe<Scalars['Boolean']>;
};

export type AddPhysicalAccountOutput = {
  __typename?: 'AddPhysicalAccountOutput';
  id: Scalars['Int'];
  message: Scalars['String'];
};

export type AddVirtualAccountInput = {
  tenant_id: Scalars['Int'];
};

export type AddVirtualAccountOutput = {
  __typename?: 'AddVirtualAccountOutput';
  id: Scalars['Int'];
  message: Scalars['String'];
};

export type BankStatementsUploadInput = {
  account_id?: InputMaybe<Scalars['Int']>;
  account_type: Scalars['String'];
  bank_id?: InputMaybe<Scalars['Int']>;
  drawing_power_fixed_amount?: InputMaybe<Scalars['Float']>;
  end_date?: InputMaybe<Scalars['String']>;
  file_registry_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  integration_id?: InputMaybe<Scalars['Int']>;
  is_primary: Scalars['Boolean'];
  is_sanction_limit_fixed?: InputMaybe<Scalars['Boolean']>;
  req_type?: InputMaybe<RequestType>;
  sanction_limit_fixed_amount?: InputMaybe<Scalars['Float']>;
  sequence?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['String']>;
};

export type BankStatementsUploadOutput = {
  __typename?: 'BankStatementsUploadOutput';
  id: Scalars['Int'];
  message: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export enum Collection_Mode {
  Escrow = 'ESCROW',
  Mandate = 'MANDATE'
}

export type CheckColdCompaniesOutput = {
  __typename?: 'CheckColdCompaniesOutput';
  message: Scalars['String'];
};

export type CloseBiddingOutput = {
  __typename?: 'CloseBiddingOutput';
  message: Scalars['String'];
};

export type CompanyAdditionalEmailsInput = {
  additional_emails: Scalars['String'];
};

export type CompanyAdditionalEmailsOutput = {
  __typename?: 'CompanyAdditionalEmailsOutput';
  message: Scalars['String'];
};

export type CompanyIntegrationStatusInput = {
  integration_method: Scalars['String'];
  integration_status: Scalars['String'];
  integration_type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

export type CreateDealInput = {
  company_score_id: Scalars['Int'];
};

export type CreateDealOutput = {
  __typename?: 'CreateDealOutput';
  deal_id: Scalars['Int'];
};

export enum Debit_Mode {
  Auto = 'AUTO',
  Manual = 'MANUAL'
}

export enum DeletableModel {
  CompanyIntegration = 'Company_Integration',
  IntegrationAuth = 'Integration_Auth'
}

export type DeleteCollaboratorInput = {
  id: Scalars['Int'];
};

export type DeleteCollaboratorOutput = {
  __typename?: 'DeleteCollaboratorOutput';
  message: Scalars['String'];
};

export type DeleteInput = {
  modelName: DeletableModel;
  where: Scalars['jsonb'];
};

export type DeleteOutput = {
  __typename?: 'DeleteOutput';
  message: Scalars['String'];
};

export type DeletePhysicalAccountInput = {
  account_id: Scalars['Int'];
};

export type DeletePhysicalAccountOutput = {
  __typename?: 'DeletePhysicalAccountOutput';
  message: Scalars['String'];
};

export type DownloadAgreementInput = {
  agreement_registry_id: Scalars['Int'];
};

export type DownloadAgreementOutput = {
  __typename?: 'DownloadAgreementOutput';
  download_url: Scalars['String'];
};

export type DownloadAmortizationTableInput = {
  table_type: TableType;
  trade_id: Scalars['Int'];
};

export type DownloadAmortizationTableOutput = {
  __typename?: 'DownloadAmortizationTableOutput';
  downloadUrl: Scalars['String'];
};

export type DownloadCiFileInput = {
  id: Scalars['Int'];
};

export type DownloadCiFileOutput = {
  __typename?: 'DownloadCiFileOutput';
  url: Scalars['String'];
};

export type DownloadFilesZipInput = {
  company_integration_id: Scalars['Int'];
  fileType: TargetFilesType;
};

export type DownloadFilesZipOutput = {
  __typename?: 'DownloadFilesZipOutput';
  downloadUrl: Scalars['String'];
};

export type DownloadPrefilledNachMandateInput = {
  trade_id: Scalars['Int'];
};

export type DownloadPrefilledNachMandateOutput = {
  __typename?: 'DownloadPrefilledNachMandateOutput';
  url: Scalars['String'];
};

export type DownloadZipInput = {
  file_ids: Array<Scalars['Int']>;
};

export type DownloadZipOutput = {
  __typename?: 'DownloadZipOutput';
  downloadUrl: Scalars['String'];
};

export type EditTradeConfiguration = {
  billing_address?: InputMaybe<Scalars['String']>;
  billing_gst?: InputMaybe<Scalars['String']>;
  collection_mode?: InputMaybe<Collection_Mode>;
  debit_mode?: InputMaybe<Debit_Mode>;
  emi_start_date?: InputMaybe<Scalars['timestamp']>;
  fees_without_gst?: InputMaybe<Scalars['Float']>;
  is_offline?: InputMaybe<Scalars['Boolean']>;
  payout_on?: InputMaybe<Scalars['timestamp']>;
  payout_value?: InputMaybe<Scalars['Float']>;
  remarks?: InputMaybe<Scalars['String']>;
  roi?: InputMaybe<Scalars['Float']>;
  validity?: InputMaybe<Scalars['Int']>;
};

export type EditTradeInput = {
  investments?: InputMaybe<Array<EditTradeInvestment>>;
  mandate_configuration?: InputMaybe<EditTradeMandateConfiguration>;
  trade_configuration?: InputMaybe<EditTradeConfiguration>;
  trade_id: Scalars['Int'];
};

export type EditTradeInvestment = {
  emi_split_ratio?: InputMaybe<Scalars['Float']>;
  fees_per_emi?: InputMaybe<Scalars['Float']>;
  invested_amount?: InputMaybe<Scalars['Float']>;
  investment_id: Scalars['Int'];
  offered_amount?: InputMaybe<Scalars['Float']>;
  withdrawal_mode?: InputMaybe<Withdrawal_Mode>;
};

export type EditTradeMandateConfiguration = {
  mandate_account_id?: InputMaybe<Scalars['Int']>;
  mandate_frequency?: InputMaybe<Scalars['String']>;
  mandate_id?: InputMaybe<Scalars['Int']>;
  mandate_no_of_months?: InputMaybe<Scalars['Int']>;
  mandate_type?: InputMaybe<Mandate_Type>;
  max_presentation_amount?: InputMaybe<Scalars['Float']>;
};

export type EditTradeOutput = {
  __typename?: 'EditTradeOutput';
  message: Scalars['String'];
};

export type ExchangeSaveTokenInput = {
  params: Scalars['jsonb'];
  source: Scalars['String'];
};

export type ExchangeSaveTokenOutput = {
  __typename?: 'ExchangeSaveTokenOutput';
  id: Scalars['Int'];
  message: Scalars['String'];
  metadata?: Maybe<Scalars['jsonb']>;
};

export type ExportCompanyQueuesInput = {
  end_date?: InputMaybe<Scalars['timestamp']>;
  exportView: ExportStage;
  request_type: Queue_Export_Type;
  start_date?: InputMaybe<Scalars['timestamp']>;
};

export type ExportCompanyQueuesOutput = {
  __typename?: 'ExportCompanyQueuesOutput';
  message: Scalars['String'];
};

export type FetchCrimeDetailsInput = {
  company_id: Scalars['Int'];
};

export type FetchCrimeDetailsOutput = {
  __typename?: 'FetchCrimeDetailsOutput';
  message: Scalars['String'];
};

export type FetchEpfoDetailInput = {
  company_id: Scalars['Int'];
};

export type FetchEpfoDetailOutput = {
  __typename?: 'FetchEPFODetailOutput';
  message: Scalars['String'];
};

export type FetchGstReturnFilingInput = {
  company_id: Scalars['Int'];
};

export type FetchGstReturnFilingOutput = {
  __typename?: 'FetchGSTReturnFilingOutput';
  message: Scalars['String'];
};

export type FetchHistoricalAccountingDataInput = {
  company_id: Scalars['Int'];
};

export type FetchHistoricalAccountingDataOutput = {
  __typename?: 'FetchHistoricalAccountingDataOutput';
  message: Scalars['String'];
};

export type FetchMcaProfileInput = {
  company_id: Scalars['Int'];
};

export type FetchMcaProfileOutput = {
  __typename?: 'FetchMCAProfileOutput';
  message: Scalars['String'];
};

export type GenerateApaInput = {
  signing_inputs: SigningInputs;
  trade_id: Scalars['Int'];
};

export type GenerateApaOutput = {
  __typename?: 'GenerateAPAOutput';
  file_registry_id: Scalars['Int'];
  signing_link: Scalars['String'];
  url: Scalars['String'];
};

export type GenerateAnnexureInput = {
  annexure_type: Annexure_Type;
  trade_id: Scalars['Int'];
};

export type GenerateAnnexureOutput = {
  __typename?: 'GenerateAnnexureOutput';
  download_url: Scalars['String'];
};

export type GenerateFileRegistryDownloadUrlInput = {
  file_registry_id: Scalars['Int'];
};

export type GenerateFileRegistryDownloadUrlOutput = {
  __typename?: 'GenerateFileRegistryDownloadURLOutput';
  download_url: Scalars['String'];
};

export type GenerateFileRegistryUploadUrlInput = {
  category_id: Scalars['String'];
  content_type?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};

export type GenerateFileRegistryUploadUrlOutput = {
  __typename?: 'GenerateFileRegistryUploadURLOutput';
  file_registry?: Maybe<File_Registry>;
  file_registry_id: Scalars['Int'];
  upload_url: Scalars['String'];
};

export type GenerateMisReportInput = {
  end_date?: InputMaybe<Scalars['timestamp']>;
  request_type: Mis_Report_Type;
  start_date?: InputMaybe<Scalars['timestamp']>;
};

export type GenerateMisReportOutput = {
  __typename?: 'GenerateMISReportOutput';
  download_url: Scalars['String'];
};

export type GenerateTradeEmiScheduleInput = {
  emi_start_date: Scalars['String'];
  trade_id: Scalars['Int'];
};

export type GenerateTradeEmiScheduleOutput = {
  __typename?: 'GenerateTradeEMIScheduleOutput';
  message: Scalars['String'];
};

export type GetNachMandatePropertiesInput = {
  multi_signatory?: InputMaybe<Scalars['Boolean']>;
  trade_id: Scalars['Int'];
};

export type GetNachMandatePropertiesOutput = {
  __typename?: 'GetNachMandatePropertiesOutput';
  emi_amount: Scalars['Float'];
  final_collection_date: Scalars['String'];
  first_collection_date: Scalars['String'];
  frequency: Scalars['String'];
  type: Scalars['String'];
};

export type GetVirtualAccountBalanceInput = {
  account_id: Scalars['Int'];
};

export type GetVirtualAccountBalanceOutput = {
  __typename?: 'GetVirtualAccountBalanceOutput';
  balance: Scalars['Float'];
};

export enum Investor_Type {
  Bank = 'BANK',
  Corporate = 'CORPORATE',
  FamilyOffice = 'FAMILY_OFFICE',
  Funds = 'FUNDS',
  Hni = 'HNI',
  Nbfc = 'NBFC',
  RetailInvestor = 'RETAIL_INVESTOR'
}

export type InitiatePayoutRequestInput = {
  trade_id: Scalars['Int'];
};

export type InitiatePayoutRequestOutput = {
  __typename?: 'InitiatePayoutRequestOutput';
  message: Scalars['String'];
};

export type InitiateTradeBid = {
  amount: Scalars['Float'];
  emi_split_ratio: Scalars['Float'];
  fees_per_emi?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  withdrawal_mode: Withdrawal_Mode;
};

export type InitiateTradeConfiguration = {
  billing_address?: InputMaybe<Scalars['String']>;
  billing_gst?: InputMaybe<Scalars['String']>;
  collection_mode?: InputMaybe<Collection_Mode>;
  debit_mode?: InputMaybe<Debit_Mode>;
  emi_start_date: Scalars['timestamp'];
  fees_without_gst?: InputMaybe<Scalars['Float']>;
  is_offline?: InputMaybe<Scalars['Boolean']>;
  payout_on?: InputMaybe<Scalars['timestamp']>;
  payout_value: Scalars['Float'];
  remarks?: InputMaybe<Scalars['String']>;
  roi: Scalars['Float'];
  validity: Scalars['Int'];
};

export type InitiateTradeInput = {
  bids?: InputMaybe<Array<InitiateTradeBid>>;
  deal_id: Scalars['Int'];
  mandate_configuration?: InputMaybe<InitiateTradeMandateConfiguration>;
  trade_configuration?: InputMaybe<InitiateTradeConfiguration>;
};

export type InitiateTradeMandateConfiguration = {
  mandate_account_id?: InputMaybe<Scalars['Int']>;
  mandate_frequency?: InputMaybe<Scalars['String']>;
  mandate_id?: InputMaybe<Scalars['Int']>;
  mandate_no_of_months?: InputMaybe<Scalars['Int']>;
  mandate_type?: InputMaybe<Mandate_Type>;
  max_presentation_amount?: InputMaybe<Scalars['Float']>;
};

export type InitiateTradeOutput = {
  __typename?: 'InitiateTradeOutput';
  code: Scalars['String'];
  id: Scalars['Int'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntegrationsApproveInput = {
  id: Scalars['Int'];
};

export type IntegrationsApproveOutput = {
  __typename?: 'IntegrationsApproveOutput';
  message: Scalars['String'];
};

export type IntegrationsUploadInput = {
  company_integration_id: Scalars['Int'];
  file_id: Scalars['Int'];
};

export type IntegrationsUploadOutput = {
  __typename?: 'IntegrationsUploadOutput';
  message: Scalars['String'];
};

export type ListIntegrationsOutput = {
  __typename?: 'ListIntegrationsOutput';
  auth_protocol: Scalars['String'];
  category: Scalars['String'];
  /** An array relationship */
  company_integrations: Array<Company_Integration>;
  /** An aggregate relationship */
  company_integrations_aggregate: Company_Integration_Aggregate;
  /** An array relationship */
  integrations: Array<Integration_Auth>;
  /** An aggregate relationship */
  integrations_aggregate: Integration_Auth_Aggregate;
  is_active: Scalars['Boolean'];
  logo_url?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};


export type ListIntegrationsOutputCompany_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_Order_By>>;
  where?: InputMaybe<Company_Integration_Bool_Exp>;
};


export type ListIntegrationsOutputCompany_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_Order_By>>;
  where?: InputMaybe<Company_Integration_Bool_Exp>;
};


export type ListIntegrationsOutputIntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Integration_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Auth_Order_By>>;
  where?: InputMaybe<Integration_Auth_Bool_Exp>;
};


export type ListIntegrationsOutputIntegrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Auth_Order_By>>;
  where?: InputMaybe<Integration_Auth_Bool_Exp>;
};

export enum Mandate_Type {
  Electronic = 'ELECTRONIC',
  Paper = 'PAPER'
}

export enum Mis_Report_Type {
  All = 'ALL',
  Custom = 'CUSTOM',
  LastMonth = 'LAST_MONTH'
}

export type MoveCompanyInQueueInput = {
  moveTo: MoveToStages;
};

export type MoveCompanyInQueueOutput = {
  __typename?: 'MoveCompanyInQueueOutput';
  message: Scalars['String'];
};

export type NetBankingPullInput = {
  account_type: Scalars['String'];
  bank_id: Scalars['Int'];
  is_primary: Scalars['Boolean'];
  sequence: Scalars['Int'];
};

export type NetBankingPullOutput = {
  __typename?: 'NetBankingPullOutput';
  bankId: Scalars['Int'];
  id: Scalars['Int'];
  token: Scalars['String'];
};

export type NotifyAndTriggerEscrowReceivablesOutput = {
  __typename?: 'NotifyAndTriggerEscrowReceivablesOutput';
  message: Scalars['String'];
};

export type NotifyCustomersUpcomingEmiOutput = {
  __typename?: 'NotifyCustomersUpcomingEMIOutput';
  message: Scalars['String'];
};

export type NotifyIncompleteDocumentChecklistOutput = {
  __typename?: 'NotifyIncompleteDocumentChecklistOutput';
  message: Scalars['String'];
};

export type NotifyNewDealsOutput = {
  __typename?: 'NotifyNewDealsOutput';
  message: Scalars['String'];
};

export type OpsAccountSetupNotifyInput = {
  company_id: Scalars['Int'];
  current_integration_input: CompanyIntegrationStatusInput;
};

export type OpsAccountSetupNotifyOutput = {
  __typename?: 'OpsAccountSetupNotifyOutput';
  message: Scalars['String'];
};

export type OpsCreateCompanyInput = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  mobile_number: Scalars['String'];
  pan: Scalars['String'];
};

export type OpsCreateCompanyOutput = {
  __typename?: 'OpsCreateCompanyOutput';
  company_id: Scalars['Int'];
  message: Scalars['String'];
};

export type OpsCreateInvestorInput = {
  address_line_1: Scalars['String'];
  address_line_2?: InputMaybe<Scalars['String']>;
  address_line_3?: InputMaybe<Scalars['String']>;
  cin?: InputMaybe<Scalars['String']>;
  country_id: Scalars['Int'];
  investor_type: Investor_Type;
  name: Scalars['String'];
  pan: Scalars['String'];
  state_id: Scalars['Int'];
  status: Scalars['String'];
  total_committed_budget?: InputMaybe<Scalars['Float']>;
  zipcode: Scalars['Int'];
};

export type OpsCreateInvestorOutput = {
  __typename?: 'OpsCreateInvestorOutput';
  investor_id: Scalars['Int'];
  message: Scalars['String'];
};

export type PlaceBidInput = {
  deal_id: Scalars['Int'];
  max_investment_amount?: InputMaybe<Scalars['Float']>;
  remarks?: InputMaybe<Scalars['String']>;
  roi: Scalars['Float'];
  validity?: InputMaybe<Scalars['Int']>;
};

export type PlaceBidOutput = {
  __typename?: 'PlaceBidOutput';
  bid_id: Scalars['Int'];
};

export type PresentEscrowEmiManualInput = {
  bounce_charges?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  penal_interest?: InputMaybe<Scalars['Float']>;
  scheduled_at: Scalars['String'];
};

export type PresentMandateDigioOutput = {
  __typename?: 'PresentMandateDigioOutput';
  message: Scalars['String'];
};

export type PresentMandateManualInput = {
  bounce_charges?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  penal_interest?: InputMaybe<Scalars['Float']>;
  scheduled_at: Scalars['String'];
};

export type PresentMandateManualOutput = {
  __typename?: 'PresentMandateManualOutput';
  message: Scalars['String'];
};

export type PreviewApaInput = {
  signing_inputs: SigningInputs;
  trade_id: Scalars['Int'];
};

export type PreviewApaOutput = {
  __typename?: 'PreviewAPAOutput';
  file_registry_id: Scalars['Int'];
  url: Scalars['String'];
};

export enum Queue_Export_Type {
  All = 'ALL',
  Custom = 'CUSTOM',
  LastMonth = 'LAST_MONTH'
}

export type QuestionInput = {
  answer: Scalars['String'];
  question_id: Scalars['String'];
};

export enum QuestionnaireSubmitType {
  Ops = 'OPS',
  Sales = 'SALES',
  User = 'USER'
}

export type RefreshTokensInput = {
  source: Scalars['String'];
};

export type RefreshTokensOutput = {
  __typename?: 'RefreshTokensOutput';
  message: Scalars['String'];
};

export type RegisterNachMandateInput = {
  account_id: Scalars['Int'];
  customer_bank_acct_number?: InputMaybe<Scalars['String']>;
  customer_bank_ifsc?: InputMaybe<Scalars['String']>;
  multi_signatory: Scalars['Boolean'];
  trade_id: Scalars['Int'];
};

export type RegisterNachMandateOutput = {
  __typename?: 'RegisterNachMandateOutput';
  message: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type RemindInvestorsOutput = {
  __typename?: 'RemindInvestorsOutput';
  message: Scalars['String'];
};

export enum RequestType {
  Draft = 'DRAFT',
  Submit = 'SUBMIT'
}

export type ResendMailToCollaboratorInput = {
  user_id: Scalars['Int'];
};

export type ResendMailToCollaboratorOutput = {
  __typename?: 'ResendMailToCollaboratorOutput';
  message: Scalars['String'];
};

export type ResyncHubspotOutput = {
  __typename?: 'ResyncHubspotOutput';
  message: Scalars['String'];
};

export type RunScoringEngineInput = {
  company_id: Scalars['Int'];
};

export type RunScoringEngineOutput = {
  __typename?: 'RunScoringEngineOutput';
  company_score_id?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type SendBankLinkagesStatusNotificationOutput = {
  __typename?: 'SendBankLinkagesStatusNotificationOutput';
  message: Scalars['String'];
};

export type SendDocumentchecklistNotificationInput = {
  company_id: Scalars['Int'];
};

export type SendDocumentchecklistNotificationOutput = {
  __typename?: 'SendDocumentchecklistNotificationOutput';
  message: Scalars['String'];
};

export type SigningInputs = {
  c_auth_addr: Scalars['String'];
  c_auth_id: Scalars['String'];
  c_signer_designation: Scalars['String'];
  c_signer_name: Scalars['String'];
  c_signer_phone: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type SubmitPreQualificationQuestionnaireInput = {
  response: Array<QuestionInput>;
  submitType: QuestionnaireSubmitType;
};

export type SubmitPreQualificationQuestionnaireOutput = {
  __typename?: 'SubmitPreQualificationQuestionnaireOutput';
  message: Scalars['String'];
};

export type SubmitSignedNachMandateInput = {
  trade_id: Scalars['Int'];
};

export type SubmitSignedNachMandateOutput = {
  __typename?: 'SubmitSignedNachMandateOutput';
  message: Scalars['String'];
};

export type SubscriptionCsvUploadInput = {
  file_id: Scalars['Int'];
  integration_id?: InputMaybe<Scalars['Int']>;
  req_type?: InputMaybe<RequestType>;
};

export type SubscriptionCsvUploadOutput = {
  __typename?: 'SubscriptionCSVUploadOutput';
  id: Scalars['Int'];
  message: Scalars['String'];
};

export type SubscriptionSyncInput = {
  source: Scalars['String'];
};

export type SubscriptionSyncOutput = {
  __typename?: 'SubscriptionSyncOutput';
  message: Scalars['String'];
};

export type SyncCompanyEmailLogsOutput = {
  __typename?: 'SyncCompanyEmailLogsOutput';
  corrupted?: Maybe<Array<Maybe<Scalars['Int']>>>;
  message: Scalars['String'];
};

export enum TableType {
  Company = 'COMPANY',
  Investor = 'INVESTOR'
}

export enum TargetFilesType {
  Reports = 'REPORTS',
  UploadedFiles = 'UPLOADED_FILES'
}

export type ToggleCompanyDocumentChecklistInput = {
  company_id: Scalars['Int'];
};

export type ToggleCompanyDocumentChecklistOutput = {
  __typename?: 'ToggleCompanyDocumentChecklistOutput';
  message: Scalars['String'];
};

export type TradeInvestments = {
  emi_split_ratio: Scalars['Float'];
  invested_amount: Scalars['Float'];
  investment_id: Scalars['Int'];
};

export type TransferTdsToCompanyInput = {
  remarks?: InputMaybe<Scalars['String']>;
  scheduled_emi_ids: Array<Scalars['Int']>;
  tds_files: Array<Scalars['Int']>;
  trade_id: Scalars['Int'];
};

export type TransferTdsToCompanyOutput = {
  __typename?: 'TransferTDSToCompanyOutput';
  message: Scalars['String'];
};

export type TriggerCompanyClientAgreementInput = {
  authorized_signatory_id: Scalars['Int'];
  company_id: Scalars['Int'];
};

export type TriggerCompanyClientAgreementOutput = {
  __typename?: 'TriggerCompanyClientAgreementOutput';
  message: Scalars['String'];
};

export type TriggerCustomNdaOutput = {
  __typename?: 'TriggerCustomNDAOutput';
  message: Scalars['String'];
};

export type TriggerCustomNdaOutputInput = {
  attorney_mobile: Scalars['String'];
  attorney_name: Scalars['String'];
  authorized_signatory_id: Scalars['Int'];
  notice_address: Scalars['String'];
  notice_email: Scalars['String'];
};

export type UpdateCompanyStatusInput = {
  company_id: Scalars['Int'];
  status: Scalars['String'];
};

export type UpdateCompanyStatusOutput = {
  __typename?: 'UpdateCompanyStatusOutput';
  message: Scalars['String'];
};

export type UpdateInvestorUserInput = {
  designation?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  investor_user_id: Scalars['Int'];
  last_name?: InputMaybe<Scalars['String']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  pan?: InputMaybe<Scalars['String']>;
};

export type UpdateInvestorUserOutput = {
  __typename?: 'UpdateInvestorUserOutput';
  investor_user_id: Scalars['Int'];
  message: Scalars['String'];
};

export type UpdateNetbankingLinkageInput = {
  integration_id: Scalars['Int'];
  providerAccountId: Scalars['String'];
  requestId: Scalars['String'];
};

export type UpdateNetbankingLinkageOutput = {
  __typename?: 'UpdateNetbankingLinkageOutput';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type UpdateTradeAmountsInput = {
  emi_amount: Scalars['Float'];
  fees_without_gst: Scalars['Float'];
  investments: Array<TradeInvestments>;
  payout_value: Scalars['Float'];
  roi: Scalars['Float'];
  trade_id: Scalars['Int'];
};

export type UpdateTradeAmountsOutput = {
  __typename?: 'UpdateTradeAmountsOutput';
  message: Scalars['String'];
};

export type UploadAgreementInput = {
  category_id: Agreement_Category;
  file_id: Scalars['Int'];
  metadata?: InputMaybe<UploadAgreementMetaData>;
  signed_at: Scalars['String'];
};

export type UploadAgreementMetaData = {
  source_id: Scalars['Int'];
};

export type UploadAgreementOutput = {
  __typename?: 'UploadAgreementOutput';
  message: Scalars['String'];
};

export type UploadSignedNachMandateInput = {
  trade_id: Scalars['Int'];
};

export type UploadSignedNachMandateOutput = {
  __typename?: 'UploadSignedNachMandateOutput';
  file_registry_id: Scalars['Int'];
  url: Scalars['String'];
};

export type UploadSubscriptionDataInput = {
  file_id: Scalars['Int'];
  integration_id?: InputMaybe<Scalars['Int']>;
};

export type UploadSubscriptionDataOutput = {
  __typename?: 'UploadSubscriptionDataOutput';
  id: Scalars['Int'];
  message: Scalars['String'];
};

export enum UserType {
  Admin = 'ADMIN',
  Director = 'DIRECTOR',
  Member = 'MEMBER'
}

export type VerifiedSubscriptionCsvUploadInput = {
  company_integration_id: Scalars['Int'];
  file_id: Scalars['Int'];
};

export type VerifiedSubscriptionCsvUploadOutput = {
  __typename?: 'VerifiedSubscriptionCSVUploadOutput';
  message: Scalars['String'];
};

export type VerifyAndUpdateAutoRejectInput = {
  company_id: Scalars['Int'];
};

export type VerifyAndUpdateAutoRejectOutput = {
  __typename?: 'VerifyAndUpdateAutoRejectOutput';
  auto_reject: Scalars['Boolean'];
  message: Scalars['String'];
};

export type VerifyPanInput = {
  pan: Scalars['String'];
  sync_name?: InputMaybe<Scalars['Boolean']>;
};

export type VerifyPanOutput = {
  __typename?: 'VerifyPanOutput';
  gstin?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  name_on_pan?: Maybe<Scalars['String']>;
  registered_name?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};

export enum Withdrawal_Mode {
  Auto = 'AUTO',
  Manual = 'MANUAL'
}

/** columns and relationships of "account" */
export type Account = {
  __typename?: 'account';
  /** An object relationship */
  account_balance?: Maybe<Account_Balance>;
  account_no: Scalars['String'];
  account_type?: Maybe<Ref_Account_Type_Enum>;
  bank_id: Scalars['Int'];
  /** An object relationship */
  company?: Maybe<Company>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  ifsc_code?: Maybe<Scalars['String']>;
  /** An array relationship */
  integrations: Array<Integration_Auth>;
  /** An aggregate relationship */
  integrations_aggregate: Integration_Auth_Aggregate;
  /** An object relationship */
  investor?: Maybe<Investor>;
  is_primary: Scalars['Boolean'];
  metadata?: Maybe<Scalars['jsonb']>;
  micr_code?: Maybe<Scalars['Int']>;
  multi_signatory: Scalars['Boolean'];
  name: Scalars['String'];
  /** An object relationship */
  ref_bank: Ref_Bank;
  status: Ref_Account_Status_Enum;
  tenant_id: Scalars['Int'];
  tenant_type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "account" */
export type AccountIntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Integration_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Auth_Order_By>>;
  where?: InputMaybe<Integration_Auth_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountIntegrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Auth_Order_By>>;
  where?: InputMaybe<Integration_Auth_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type AccountBalanceEmailAlertOutput = {
  __typename?: 'accountBalanceEmailAlertOutput';
  message: Scalars['String'];
};

/** aggregated selection of "account" */
export type Account_Aggregate = {
  __typename?: 'account_aggregate';
  aggregate?: Maybe<Account_Aggregate_Fields>;
  nodes: Array<Account>;
};

export type Account_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Account_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Account_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Account_Aggregate_Bool_Exp_Count>;
};

export type Account_Aggregate_Bool_Exp_Bool_And = {
  arguments: Account_Select_Column_Account_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Account_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Account_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Account_Select_Column_Account_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Account_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Account_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Account_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Account_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "account" */
export type Account_Aggregate_Fields = {
  __typename?: 'account_aggregate_fields';
  avg?: Maybe<Account_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Max_Fields>;
  min?: Maybe<Account_Min_Fields>;
  stddev?: Maybe<Account_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Sum_Fields>;
  var_pop?: Maybe<Account_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Var_Samp_Fields>;
  variance?: Maybe<Account_Variance_Fields>;
};


/** aggregate fields of "account" */
export type Account_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Account_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account" */
export type Account_Aggregate_Order_By = {
  avg?: InputMaybe<Account_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Account_Max_Order_By>;
  min?: InputMaybe<Account_Min_Order_By>;
  stddev?: InputMaybe<Account_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Account_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Account_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Account_Sum_Order_By>;
  var_pop?: InputMaybe<Account_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Account_Var_Samp_Order_By>;
  variance?: InputMaybe<Account_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Account_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "account" */
export type Account_Arr_Rel_Insert_Input = {
  data: Array<Account_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_On_Conflict>;
};

/** aggregate avg on columns */
export type Account_Avg_Fields = {
  __typename?: 'account_avg_fields';
  bank_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  micr_code?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account" */
export type Account_Avg_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  micr_code?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "account_balance" */
export type Account_Balance = {
  __typename?: 'account_balance';
  /** An object relationship */
  account: Account;
  account_id: Scalars['Int'];
  amount: Scalars['numeric'];
  as_of: Scalars['timestamptz'];
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by: Scalars['Int'];
  currency: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "account_balance" */
export type Account_Balance_Aggregate = {
  __typename?: 'account_balance_aggregate';
  aggregate?: Maybe<Account_Balance_Aggregate_Fields>;
  nodes: Array<Account_Balance>;
};

/** aggregate fields of "account_balance" */
export type Account_Balance_Aggregate_Fields = {
  __typename?: 'account_balance_aggregate_fields';
  avg?: Maybe<Account_Balance_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Balance_Max_Fields>;
  min?: Maybe<Account_Balance_Min_Fields>;
  stddev?: Maybe<Account_Balance_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Balance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Balance_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Balance_Sum_Fields>;
  var_pop?: Maybe<Account_Balance_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Balance_Var_Samp_Fields>;
  variance?: Maybe<Account_Balance_Variance_Fields>;
};


/** aggregate fields of "account_balance" */
export type Account_Balance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Account_Balance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Account_Balance_Avg_Fields = {
  __typename?: 'account_balance_avg_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "account_balance". All fields are combined with a logical 'AND'. */
export type Account_Balance_Bool_Exp = {
  _and?: InputMaybe<Array<Account_Balance_Bool_Exp>>;
  _not?: InputMaybe<Account_Balance_Bool_Exp>;
  _or?: InputMaybe<Array<Account_Balance_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_id?: InputMaybe<Int_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  as_of?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_balance" */
export enum Account_Balance_Constraint {
  /** unique or primary key constraint on columns "account_id" */
  AccountBalanceAccountIdKey = 'account_balance_account_id_key',
  /** unique or primary key constraint on columns "account_id" */
  AccountBalancePkey = 'account_balance_pkey'
}

/** input type for incrementing numeric columns in table "account_balance" */
export type Account_Balance_Inc_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "account_balance" */
export type Account_Balance_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  as_of?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Account_Balance_Max_Fields = {
  __typename?: 'account_balance_max_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  as_of?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Account_Balance_Min_Fields = {
  __typename?: 'account_balance_min_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  as_of?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "account_balance" */
export type Account_Balance_Mutation_Response = {
  __typename?: 'account_balance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Balance>;
};

/** input type for inserting object relation for remote table "account_balance" */
export type Account_Balance_Obj_Rel_Insert_Input = {
  data: Account_Balance_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_Balance_On_Conflict>;
};

/** on_conflict condition type for table "account_balance" */
export type Account_Balance_On_Conflict = {
  constraint: Account_Balance_Constraint;
  update_columns?: Array<Account_Balance_Update_Column>;
  where?: InputMaybe<Account_Balance_Bool_Exp>;
};

/** Ordering options when selecting data from "account_balance". */
export type Account_Balance_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  as_of?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: account_balance */
export type Account_Balance_Pk_Columns_Input = {
  account_id: Scalars['Int'];
};

/** select columns of table "account_balance" */
export enum Account_Balance_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  AsOf = 'as_of',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Currency = 'currency',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "account_balance" */
export type Account_Balance_Set_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  as_of?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Account_Balance_Stddev_Fields = {
  __typename?: 'account_balance_stddev_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Account_Balance_Stddev_Pop_Fields = {
  __typename?: 'account_balance_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Account_Balance_Stddev_Samp_Fields = {
  __typename?: 'account_balance_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "account_balance" */
export type Account_Balance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Balance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Balance_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  as_of?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Account_Balance_Sum_Fields = {
  __typename?: 'account_balance_sum_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "account_balance" */
export enum Account_Balance_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  AsOf = 'as_of',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Currency = 'currency',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Account_Balance_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Account_Balance_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Account_Balance_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Balance_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Account_Balance_Var_Pop_Fields = {
  __typename?: 'account_balance_var_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Account_Balance_Var_Samp_Fields = {
  __typename?: 'account_balance_var_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Account_Balance_Variance_Fields = {
  __typename?: 'account_balance_variance_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "account". All fields are combined with a logical 'AND'. */
export type Account_Bool_Exp = {
  _and?: InputMaybe<Array<Account_Bool_Exp>>;
  _not?: InputMaybe<Account_Bool_Exp>;
  _or?: InputMaybe<Array<Account_Bool_Exp>>;
  account_balance?: InputMaybe<Account_Balance_Bool_Exp>;
  account_no?: InputMaybe<String_Comparison_Exp>;
  account_type?: InputMaybe<Ref_Account_Type_Enum_Comparison_Exp>;
  bank_id?: InputMaybe<Int_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ifsc_code?: InputMaybe<String_Comparison_Exp>;
  integrations?: InputMaybe<Integration_Auth_Bool_Exp>;
  integrations_aggregate?: InputMaybe<Integration_Auth_Aggregate_Bool_Exp>;
  investor?: InputMaybe<Investor_Bool_Exp>;
  is_primary?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  micr_code?: InputMaybe<Int_Comparison_Exp>;
  multi_signatory?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ref_bank?: InputMaybe<Ref_Bank_Bool_Exp>;
  status?: InputMaybe<Ref_Account_Status_Enum_Comparison_Exp>;
  tenant_id?: InputMaybe<Int_Comparison_Exp>;
  tenant_type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "account" */
export enum Account_Constraint {
  /** unique or primary key constraint on columns "account_no", "tenant_id", "bank_id", "tenant_type" */
  AccountAccountNoBankIdTenantIdTenantTypeKey = 'account_account_no_bank_id_tenant_id_tenant_type_key',
  /** unique or primary key constraint on columns "id" */
  AccountPkey = 'account_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Account_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Account_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Account_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "account" */
export type Account_Inc_Input = {
  bank_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  micr_code?: InputMaybe<Scalars['Int']>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "account" */
export type Account_Insert_Input = {
  account_balance?: InputMaybe<Account_Balance_Obj_Rel_Insert_Input>;
  account_no?: InputMaybe<Scalars['String']>;
  account_type?: InputMaybe<Ref_Account_Type_Enum>;
  bank_id?: InputMaybe<Scalars['Int']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ifsc_code?: InputMaybe<Scalars['String']>;
  integrations?: InputMaybe<Integration_Auth_Arr_Rel_Insert_Input>;
  investor?: InputMaybe<Investor_Obj_Rel_Insert_Input>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  micr_code?: InputMaybe<Scalars['Int']>;
  multi_signatory?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ref_bank?: InputMaybe<Ref_Bank_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Ref_Account_Status_Enum>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  tenant_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Account_Max_Fields = {
  __typename?: 'account_max_fields';
  account_no?: Maybe<Scalars['String']>;
  bank_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ifsc_code?: Maybe<Scalars['String']>;
  micr_code?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['Int']>;
  tenant_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "account" */
export type Account_Max_Order_By = {
  account_no?: InputMaybe<Order_By>;
  bank_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ifsc_code?: InputMaybe<Order_By>;
  micr_code?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  tenant_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Min_Fields = {
  __typename?: 'account_min_fields';
  account_no?: Maybe<Scalars['String']>;
  bank_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ifsc_code?: Maybe<Scalars['String']>;
  micr_code?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['Int']>;
  tenant_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "account" */
export type Account_Min_Order_By = {
  account_no?: InputMaybe<Order_By>;
  bank_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ifsc_code?: InputMaybe<Order_By>;
  micr_code?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  tenant_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "account" */
export type Account_Mutation_Response = {
  __typename?: 'account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account>;
};

/** input type for inserting object relation for remote table "account" */
export type Account_Obj_Rel_Insert_Input = {
  data: Account_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_On_Conflict>;
};

/** on_conflict condition type for table "account" */
export type Account_On_Conflict = {
  constraint: Account_Constraint;
  update_columns?: Array<Account_Update_Column>;
  where?: InputMaybe<Account_Bool_Exp>;
};

/** Ordering options when selecting data from "account". */
export type Account_Order_By = {
  account_balance?: InputMaybe<Account_Balance_Order_By>;
  account_no?: InputMaybe<Order_By>;
  account_type?: InputMaybe<Order_By>;
  bank_id?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ifsc_code?: InputMaybe<Order_By>;
  integrations_aggregate?: InputMaybe<Integration_Auth_Aggregate_Order_By>;
  investor?: InputMaybe<Investor_Order_By>;
  is_primary?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  micr_code?: InputMaybe<Order_By>;
  multi_signatory?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ref_bank?: InputMaybe<Ref_Bank_Order_By>;
  status?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  tenant_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: account */
export type Account_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Account_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "account" */
export enum Account_Select_Column {
  /** column name */
  AccountNo = 'account_no',
  /** column name */
  AccountType = 'account_type',
  /** column name */
  BankId = 'bank_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  IfscCode = 'ifsc_code',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MicrCode = 'micr_code',
  /** column name */
  MultiSignatory = 'multi_signatory',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "account_aggregate_bool_exp_bool_and_arguments_columns" columns of table "account" */
export enum Account_Select_Column_Account_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  MultiSignatory = 'multi_signatory'
}

/** select "account_aggregate_bool_exp_bool_or_arguments_columns" columns of table "account" */
export enum Account_Select_Column_Account_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  MultiSignatory = 'multi_signatory'
}

/** input type for updating data in table "account" */
export type Account_Set_Input = {
  account_no?: InputMaybe<Scalars['String']>;
  account_type?: InputMaybe<Ref_Account_Type_Enum>;
  bank_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ifsc_code?: InputMaybe<Scalars['String']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  micr_code?: InputMaybe<Scalars['Int']>;
  multi_signatory?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ref_Account_Status_Enum>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  tenant_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Account_Stddev_Fields = {
  __typename?: 'account_stddev_fields';
  bank_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  micr_code?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account" */
export type Account_Stddev_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  micr_code?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Account_Stddev_Pop_Fields = {
  __typename?: 'account_stddev_pop_fields';
  bank_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  micr_code?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account" */
export type Account_Stddev_Pop_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  micr_code?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Account_Stddev_Samp_Fields = {
  __typename?: 'account_stddev_samp_fields';
  bank_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  micr_code?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account" */
export type Account_Stddev_Samp_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  micr_code?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "account" */
export type Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Stream_Cursor_Value_Input = {
  account_no?: InputMaybe<Scalars['String']>;
  account_type?: InputMaybe<Ref_Account_Type_Enum>;
  bank_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ifsc_code?: InputMaybe<Scalars['String']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  micr_code?: InputMaybe<Scalars['Int']>;
  multi_signatory?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ref_Account_Status_Enum>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  tenant_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Account_Sum_Fields = {
  __typename?: 'account_sum_fields';
  bank_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  micr_code?: Maybe<Scalars['Int']>;
  tenant_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "account" */
export type Account_Sum_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  micr_code?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "account" */
export enum Account_Update_Column {
  /** column name */
  AccountNo = 'account_no',
  /** column name */
  AccountType = 'account_type',
  /** column name */
  BankId = 'bank_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  IfscCode = 'ifsc_code',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MicrCode = 'micr_code',
  /** column name */
  MultiSignatory = 'multi_signatory',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Account_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Account_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Account_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Account_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Account_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Account_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Account_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Account_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Account_Var_Pop_Fields = {
  __typename?: 'account_var_pop_fields';
  bank_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  micr_code?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account" */
export type Account_Var_Pop_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  micr_code?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Account_Var_Samp_Fields = {
  __typename?: 'account_var_samp_fields';
  bank_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  micr_code?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account" */
export type Account_Var_Samp_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  micr_code?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Account_Variance_Fields = {
  __typename?: 'account_variance_fields';
  bank_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  micr_code?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account" */
export type Account_Variance_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  micr_code?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "agreement_registry" */
export type Agreement_Registry = {
  __typename?: 'agreement_registry';
  /** An object relationship */
  agreement_category?: Maybe<Ref_Agreement_Category>;
  /** An object relationship */
  agreement_status?: Maybe<Ref_Agreement_Status>;
  /** An array relationship */
  agreement_tenants: Array<Agreement_Tenant>;
  /** An aggregate relationship */
  agreement_tenants_aggregate: Agreement_Tenant_Aggregate;
  category_id: Ref_Agreement_Category_Enum;
  created_at: Scalars['timestamp'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamp']>;
  deleted_by?: Maybe<Scalars['Int']>;
  /** An object relationship */
  draft_file?: Maybe<File_Registry>;
  draft_file_id?: Maybe<Scalars['Int']>;
  failure_reason?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  metadata?: Maybe<Scalars['jsonb']>;
  signed_at?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  signed_file?: Maybe<File_Registry>;
  signed_file_id?: Maybe<Scalars['Int']>;
  signers?: Maybe<Scalars['jsonb']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  status: Ref_Agreement_Status_Enum;
  updated_at: Scalars['timestamp'];
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "agreement_registry" */
export type Agreement_RegistryAgreement_TenantsArgs = {
  distinct_on?: InputMaybe<Array<Agreement_Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Agreement_Tenant_Order_By>>;
  where?: InputMaybe<Agreement_Tenant_Bool_Exp>;
};


/** columns and relationships of "agreement_registry" */
export type Agreement_RegistryAgreement_Tenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agreement_Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Agreement_Tenant_Order_By>>;
  where?: InputMaybe<Agreement_Tenant_Bool_Exp>;
};


/** columns and relationships of "agreement_registry" */
export type Agreement_RegistryMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "agreement_registry" */
export type Agreement_RegistrySignersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "agreement_registry" */
export type Agreement_Registry_Aggregate = {
  __typename?: 'agreement_registry_aggregate';
  aggregate?: Maybe<Agreement_Registry_Aggregate_Fields>;
  nodes: Array<Agreement_Registry>;
};

/** aggregate fields of "agreement_registry" */
export type Agreement_Registry_Aggregate_Fields = {
  __typename?: 'agreement_registry_aggregate_fields';
  avg?: Maybe<Agreement_Registry_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Agreement_Registry_Max_Fields>;
  min?: Maybe<Agreement_Registry_Min_Fields>;
  stddev?: Maybe<Agreement_Registry_Stddev_Fields>;
  stddev_pop?: Maybe<Agreement_Registry_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Agreement_Registry_Stddev_Samp_Fields>;
  sum?: Maybe<Agreement_Registry_Sum_Fields>;
  var_pop?: Maybe<Agreement_Registry_Var_Pop_Fields>;
  var_samp?: Maybe<Agreement_Registry_Var_Samp_Fields>;
  variance?: Maybe<Agreement_Registry_Variance_Fields>;
};


/** aggregate fields of "agreement_registry" */
export type Agreement_Registry_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Agreement_Registry_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Agreement_Registry_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
  signers?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Agreement_Registry_Avg_Fields = {
  __typename?: 'agreement_registry_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  draft_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "agreement_registry". All fields are combined with a logical 'AND'. */
export type Agreement_Registry_Bool_Exp = {
  _and?: InputMaybe<Array<Agreement_Registry_Bool_Exp>>;
  _not?: InputMaybe<Agreement_Registry_Bool_Exp>;
  _or?: InputMaybe<Array<Agreement_Registry_Bool_Exp>>;
  agreement_category?: InputMaybe<Ref_Agreement_Category_Bool_Exp>;
  agreement_status?: InputMaybe<Ref_Agreement_Status_Bool_Exp>;
  agreement_tenants?: InputMaybe<Agreement_Tenant_Bool_Exp>;
  agreement_tenants_aggregate?: InputMaybe<Agreement_Tenant_Aggregate_Bool_Exp>;
  category_id?: InputMaybe<Ref_Agreement_Category_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  draft_file?: InputMaybe<File_Registry_Bool_Exp>;
  draft_file_id?: InputMaybe<Int_Comparison_Exp>;
  failure_reason?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  signed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  signed_file?: InputMaybe<File_Registry_Bool_Exp>;
  signed_file_id?: InputMaybe<Int_Comparison_Exp>;
  signers?: InputMaybe<Jsonb_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  source_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Ref_Agreement_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "agreement_registry" */
export enum Agreement_Registry_Constraint {
  /** unique or primary key constraint on columns "id" */
  AgreementRegistryPkey = 'agreement_registry_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Agreement_Registry_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
  signers?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Agreement_Registry_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
  signers?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Agreement_Registry_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
  signers?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "agreement_registry" */
export type Agreement_Registry_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  draft_file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  signed_file_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "agreement_registry" */
export type Agreement_Registry_Insert_Input = {
  agreement_category?: InputMaybe<Ref_Agreement_Category_Obj_Rel_Insert_Input>;
  agreement_status?: InputMaybe<Ref_Agreement_Status_Obj_Rel_Insert_Input>;
  agreement_tenants?: InputMaybe<Agreement_Tenant_Arr_Rel_Insert_Input>;
  category_id?: InputMaybe<Ref_Agreement_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  draft_file?: InputMaybe<File_Registry_Obj_Rel_Insert_Input>;
  draft_file_id?: InputMaybe<Scalars['Int']>;
  failure_reason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  signed_at?: InputMaybe<Scalars['timestamp']>;
  signed_file?: InputMaybe<File_Registry_Obj_Rel_Insert_Input>;
  signed_file_id?: InputMaybe<Scalars['Int']>;
  signers?: InputMaybe<Scalars['jsonb']>;
  source?: InputMaybe<Scalars['String']>;
  source_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ref_Agreement_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Agreement_Registry_Max_Fields = {
  __typename?: 'agreement_registry_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  deleted_by?: Maybe<Scalars['Int']>;
  draft_file_id?: Maybe<Scalars['Int']>;
  failure_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  signed_at?: Maybe<Scalars['timestamp']>;
  signed_file_id?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Agreement_Registry_Min_Fields = {
  __typename?: 'agreement_registry_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  deleted_by?: Maybe<Scalars['Int']>;
  draft_file_id?: Maybe<Scalars['Int']>;
  failure_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  signed_at?: Maybe<Scalars['timestamp']>;
  signed_file_id?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "agreement_registry" */
export type Agreement_Registry_Mutation_Response = {
  __typename?: 'agreement_registry_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Agreement_Registry>;
};

/** input type for inserting object relation for remote table "agreement_registry" */
export type Agreement_Registry_Obj_Rel_Insert_Input = {
  data: Agreement_Registry_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Agreement_Registry_On_Conflict>;
};

/** on_conflict condition type for table "agreement_registry" */
export type Agreement_Registry_On_Conflict = {
  constraint: Agreement_Registry_Constraint;
  update_columns?: Array<Agreement_Registry_Update_Column>;
  where?: InputMaybe<Agreement_Registry_Bool_Exp>;
};

/** Ordering options when selecting data from "agreement_registry". */
export type Agreement_Registry_Order_By = {
  agreement_category?: InputMaybe<Ref_Agreement_Category_Order_By>;
  agreement_status?: InputMaybe<Ref_Agreement_Status_Order_By>;
  agreement_tenants_aggregate?: InputMaybe<Agreement_Tenant_Aggregate_Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  draft_file?: InputMaybe<File_Registry_Order_By>;
  draft_file_id?: InputMaybe<Order_By>;
  failure_reason?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  signed_at?: InputMaybe<Order_By>;
  signed_file?: InputMaybe<File_Registry_Order_By>;
  signed_file_id?: InputMaybe<Order_By>;
  signers?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: agreement_registry */
export type Agreement_Registry_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Agreement_Registry_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
  signers?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "agreement_registry" */
export enum Agreement_Registry_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  DraftFileId = 'draft_file_id',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  SignedAt = 'signed_at',
  /** column name */
  SignedFileId = 'signed_file_id',
  /** column name */
  Signers = 'signers',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "agreement_registry" */
export type Agreement_Registry_Set_Input = {
  category_id?: InputMaybe<Ref_Agreement_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  draft_file_id?: InputMaybe<Scalars['Int']>;
  failure_reason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  signed_at?: InputMaybe<Scalars['timestamp']>;
  signed_file_id?: InputMaybe<Scalars['Int']>;
  signers?: InputMaybe<Scalars['jsonb']>;
  source?: InputMaybe<Scalars['String']>;
  source_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ref_Agreement_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Agreement_Registry_Stddev_Fields = {
  __typename?: 'agreement_registry_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  draft_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Agreement_Registry_Stddev_Pop_Fields = {
  __typename?: 'agreement_registry_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  draft_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Agreement_Registry_Stddev_Samp_Fields = {
  __typename?: 'agreement_registry_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  draft_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "agreement_registry" */
export type Agreement_Registry_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Agreement_Registry_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Agreement_Registry_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Ref_Agreement_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  draft_file_id?: InputMaybe<Scalars['Int']>;
  failure_reason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  signed_at?: InputMaybe<Scalars['timestamp']>;
  signed_file_id?: InputMaybe<Scalars['Int']>;
  signers?: InputMaybe<Scalars['jsonb']>;
  source?: InputMaybe<Scalars['String']>;
  source_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ref_Agreement_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Agreement_Registry_Sum_Fields = {
  __typename?: 'agreement_registry_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  draft_file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  signed_file_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "agreement_registry" */
export enum Agreement_Registry_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  DraftFileId = 'draft_file_id',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  SignedAt = 'signed_at',
  /** column name */
  SignedFileId = 'signed_file_id',
  /** column name */
  Signers = 'signers',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Agreement_Registry_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Agreement_Registry_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Agreement_Registry_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Agreement_Registry_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Agreement_Registry_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Agreement_Registry_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Agreement_Registry_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Agreement_Registry_Set_Input>;
  /** filter the rows which have to be updated */
  where: Agreement_Registry_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Agreement_Registry_Var_Pop_Fields = {
  __typename?: 'agreement_registry_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  draft_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Agreement_Registry_Var_Samp_Fields = {
  __typename?: 'agreement_registry_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  draft_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Agreement_Registry_Variance_Fields = {
  __typename?: 'agreement_registry_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  draft_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "agreement_tenant" */
export type Agreement_Tenant = {
  __typename?: 'agreement_tenant';
  agreement_registry_id: Scalars['Int'];
  created_at: Scalars['timestamp'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamp']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  tenant_id: Scalars['Int'];
  tenant_type: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamp']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "agreement_tenant" */
export type Agreement_Tenant_Aggregate = {
  __typename?: 'agreement_tenant_aggregate';
  aggregate?: Maybe<Agreement_Tenant_Aggregate_Fields>;
  nodes: Array<Agreement_Tenant>;
};

export type Agreement_Tenant_Aggregate_Bool_Exp = {
  count?: InputMaybe<Agreement_Tenant_Aggregate_Bool_Exp_Count>;
};

export type Agreement_Tenant_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Agreement_Tenant_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Agreement_Tenant_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "agreement_tenant" */
export type Agreement_Tenant_Aggregate_Fields = {
  __typename?: 'agreement_tenant_aggregate_fields';
  avg?: Maybe<Agreement_Tenant_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Agreement_Tenant_Max_Fields>;
  min?: Maybe<Agreement_Tenant_Min_Fields>;
  stddev?: Maybe<Agreement_Tenant_Stddev_Fields>;
  stddev_pop?: Maybe<Agreement_Tenant_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Agreement_Tenant_Stddev_Samp_Fields>;
  sum?: Maybe<Agreement_Tenant_Sum_Fields>;
  var_pop?: Maybe<Agreement_Tenant_Var_Pop_Fields>;
  var_samp?: Maybe<Agreement_Tenant_Var_Samp_Fields>;
  variance?: Maybe<Agreement_Tenant_Variance_Fields>;
};


/** aggregate fields of "agreement_tenant" */
export type Agreement_Tenant_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Agreement_Tenant_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "agreement_tenant" */
export type Agreement_Tenant_Aggregate_Order_By = {
  avg?: InputMaybe<Agreement_Tenant_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Agreement_Tenant_Max_Order_By>;
  min?: InputMaybe<Agreement_Tenant_Min_Order_By>;
  stddev?: InputMaybe<Agreement_Tenant_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Agreement_Tenant_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Agreement_Tenant_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Agreement_Tenant_Sum_Order_By>;
  var_pop?: InputMaybe<Agreement_Tenant_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Agreement_Tenant_Var_Samp_Order_By>;
  variance?: InputMaybe<Agreement_Tenant_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "agreement_tenant" */
export type Agreement_Tenant_Arr_Rel_Insert_Input = {
  data: Array<Agreement_Tenant_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Agreement_Tenant_On_Conflict>;
};

/** aggregate avg on columns */
export type Agreement_Tenant_Avg_Fields = {
  __typename?: 'agreement_tenant_avg_fields';
  agreement_registry_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "agreement_tenant" */
export type Agreement_Tenant_Avg_Order_By = {
  agreement_registry_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "agreement_tenant". All fields are combined with a logical 'AND'. */
export type Agreement_Tenant_Bool_Exp = {
  _and?: InputMaybe<Array<Agreement_Tenant_Bool_Exp>>;
  _not?: InputMaybe<Agreement_Tenant_Bool_Exp>;
  _or?: InputMaybe<Array<Agreement_Tenant_Bool_Exp>>;
  agreement_registry_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tenant_id?: InputMaybe<Int_Comparison_Exp>;
  tenant_type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "agreement_tenant" */
export enum Agreement_Tenant_Constraint {
  /** unique or primary key constraint on columns "id" */
  AgreementTenantPkey = 'agreement_tenant_pkey'
}

/** input type for incrementing numeric columns in table "agreement_tenant" */
export type Agreement_Tenant_Inc_Input = {
  agreement_registry_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "agreement_tenant" */
export type Agreement_Tenant_Insert_Input = {
  agreement_registry_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  tenant_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Agreement_Tenant_Max_Fields = {
  __typename?: 'agreement_tenant_max_fields';
  agreement_registry_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tenant_id?: Maybe<Scalars['Int']>;
  tenant_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "agreement_tenant" */
export type Agreement_Tenant_Max_Order_By = {
  agreement_registry_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  tenant_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Agreement_Tenant_Min_Fields = {
  __typename?: 'agreement_tenant_min_fields';
  agreement_registry_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tenant_id?: Maybe<Scalars['Int']>;
  tenant_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "agreement_tenant" */
export type Agreement_Tenant_Min_Order_By = {
  agreement_registry_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  tenant_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "agreement_tenant" */
export type Agreement_Tenant_Mutation_Response = {
  __typename?: 'agreement_tenant_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Agreement_Tenant>;
};

/** on_conflict condition type for table "agreement_tenant" */
export type Agreement_Tenant_On_Conflict = {
  constraint: Agreement_Tenant_Constraint;
  update_columns?: Array<Agreement_Tenant_Update_Column>;
  where?: InputMaybe<Agreement_Tenant_Bool_Exp>;
};

/** Ordering options when selecting data from "agreement_tenant". */
export type Agreement_Tenant_Order_By = {
  agreement_registry_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  tenant_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: agreement_tenant */
export type Agreement_Tenant_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "agreement_tenant" */
export enum Agreement_Tenant_Select_Column {
  /** column name */
  AgreementRegistryId = 'agreement_registry_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "agreement_tenant" */
export type Agreement_Tenant_Set_Input = {
  agreement_registry_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  tenant_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Agreement_Tenant_Stddev_Fields = {
  __typename?: 'agreement_tenant_stddev_fields';
  agreement_registry_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "agreement_tenant" */
export type Agreement_Tenant_Stddev_Order_By = {
  agreement_registry_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Agreement_Tenant_Stddev_Pop_Fields = {
  __typename?: 'agreement_tenant_stddev_pop_fields';
  agreement_registry_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "agreement_tenant" */
export type Agreement_Tenant_Stddev_Pop_Order_By = {
  agreement_registry_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Agreement_Tenant_Stddev_Samp_Fields = {
  __typename?: 'agreement_tenant_stddev_samp_fields';
  agreement_registry_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "agreement_tenant" */
export type Agreement_Tenant_Stddev_Samp_Order_By = {
  agreement_registry_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "agreement_tenant" */
export type Agreement_Tenant_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Agreement_Tenant_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Agreement_Tenant_Stream_Cursor_Value_Input = {
  agreement_registry_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  tenant_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Agreement_Tenant_Sum_Fields = {
  __typename?: 'agreement_tenant_sum_fields';
  agreement_registry_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tenant_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "agreement_tenant" */
export type Agreement_Tenant_Sum_Order_By = {
  agreement_registry_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "agreement_tenant" */
export enum Agreement_Tenant_Update_Column {
  /** column name */
  AgreementRegistryId = 'agreement_registry_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Agreement_Tenant_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Agreement_Tenant_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Agreement_Tenant_Set_Input>;
  /** filter the rows which have to be updated */
  where: Agreement_Tenant_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Agreement_Tenant_Var_Pop_Fields = {
  __typename?: 'agreement_tenant_var_pop_fields';
  agreement_registry_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "agreement_tenant" */
export type Agreement_Tenant_Var_Pop_Order_By = {
  agreement_registry_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Agreement_Tenant_Var_Samp_Fields = {
  __typename?: 'agreement_tenant_var_samp_fields';
  agreement_registry_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "agreement_tenant" */
export type Agreement_Tenant_Var_Samp_Order_By = {
  agreement_registry_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Agreement_Tenant_Variance_Fields = {
  __typename?: 'agreement_tenant_variance_fields';
  agreement_registry_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "agreement_tenant" */
export type Agreement_Tenant_Variance_Order_By = {
  agreement_registry_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "application_lifecycle_stage" */
export type Application_Lifecycle_Stage = {
  __typename?: 'application_lifecycle_stage';
  /** A computed field, executes function "get_lifecycle_stage_age" */
  age?: Maybe<Scalars['Int']>;
  /** An object relationship */
  company?: Maybe<Company>;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  has_financial_integration?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  investor_type?: Maybe<Ref_Investor_Type_Enum>;
  /** An object relationship */
  lifecycle_question_result?: Maybe<Lifecycle_Question_Result>;
  lifecycle_question_result_id?: Maybe<Scalars['Int']>;
  likeliness?: Maybe<Ref_Company_Likeliness_Enum>;
  offer_limit?: Maybe<Scalars['Int']>;
  offer_limit_remarks?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  /** An object relationship */
  ref_application_lifecycle_stage: Ref_Application_Lifecycle_Stage;
  /** An object relationship */
  ref_investor_type?: Maybe<Ref_Investor_Type>;
  reject_reason?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  stage: Ref_Application_Lifecycle_Stage_Enum;
  start_date: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};

/** aggregated selection of "application_lifecycle_stage" */
export type Application_Lifecycle_Stage_Aggregate = {
  __typename?: 'application_lifecycle_stage_aggregate';
  aggregate?: Maybe<Application_Lifecycle_Stage_Aggregate_Fields>;
  nodes: Array<Application_Lifecycle_Stage>;
};

/** aggregate fields of "application_lifecycle_stage" */
export type Application_Lifecycle_Stage_Aggregate_Fields = {
  __typename?: 'application_lifecycle_stage_aggregate_fields';
  avg?: Maybe<Application_Lifecycle_Stage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Application_Lifecycle_Stage_Max_Fields>;
  min?: Maybe<Application_Lifecycle_Stage_Min_Fields>;
  stddev?: Maybe<Application_Lifecycle_Stage_Stddev_Fields>;
  stddev_pop?: Maybe<Application_Lifecycle_Stage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Application_Lifecycle_Stage_Stddev_Samp_Fields>;
  sum?: Maybe<Application_Lifecycle_Stage_Sum_Fields>;
  var_pop?: Maybe<Application_Lifecycle_Stage_Var_Pop_Fields>;
  var_samp?: Maybe<Application_Lifecycle_Stage_Var_Samp_Fields>;
  variance?: Maybe<Application_Lifecycle_Stage_Variance_Fields>;
};


/** aggregate fields of "application_lifecycle_stage" */
export type Application_Lifecycle_Stage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Application_Lifecycle_Stage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Application_Lifecycle_Stage_Avg_Fields = {
  __typename?: 'application_lifecycle_stage_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifecycle_question_result_id?: Maybe<Scalars['Float']>;
  offer_limit?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "application_lifecycle_stage". All fields are combined with a logical 'AND'. */
export type Application_Lifecycle_Stage_Bool_Exp = {
  _and?: InputMaybe<Array<Application_Lifecycle_Stage_Bool_Exp>>;
  _not?: InputMaybe<Application_Lifecycle_Stage_Bool_Exp>;
  _or?: InputMaybe<Array<Application_Lifecycle_Stage_Bool_Exp>>;
  age?: InputMaybe<Int_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  end_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  has_financial_integration?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  investor_type?: InputMaybe<Ref_Investor_Type_Enum_Comparison_Exp>;
  lifecycle_question_result?: InputMaybe<Lifecycle_Question_Result_Bool_Exp>;
  lifecycle_question_result_id?: InputMaybe<Int_Comparison_Exp>;
  likeliness?: InputMaybe<Ref_Company_Likeliness_Enum_Comparison_Exp>;
  offer_limit?: InputMaybe<Int_Comparison_Exp>;
  offer_limit_remarks?: InputMaybe<String_Comparison_Exp>;
  priority?: InputMaybe<Int_Comparison_Exp>;
  ref_application_lifecycle_stage?: InputMaybe<Ref_Application_Lifecycle_Stage_Bool_Exp>;
  ref_investor_type?: InputMaybe<Ref_Investor_Type_Bool_Exp>;
  reject_reason?: InputMaybe<String_Comparison_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
  stage?: InputMaybe<Ref_Application_Lifecycle_Stage_Enum_Comparison_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "application_lifecycle_stage" */
export enum Application_Lifecycle_Stage_Constraint {
  /** unique or primary key constraint on columns "id" */
  ApplicationLifecycleStagePkey = 'application_lifecycle_stage_pkey'
}

/** input type for incrementing numeric columns in table "application_lifecycle_stage" */
export type Application_Lifecycle_Stage_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lifecycle_question_result_id?: InputMaybe<Scalars['Int']>;
  offer_limit?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "application_lifecycle_stage" */
export type Application_Lifecycle_Stage_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  has_financial_integration?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_type?: InputMaybe<Ref_Investor_Type_Enum>;
  lifecycle_question_result?: InputMaybe<Lifecycle_Question_Result_Obj_Rel_Insert_Input>;
  lifecycle_question_result_id?: InputMaybe<Scalars['Int']>;
  likeliness?: InputMaybe<Ref_Company_Likeliness_Enum>;
  offer_limit?: InputMaybe<Scalars['Int']>;
  offer_limit_remarks?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  ref_application_lifecycle_stage?: InputMaybe<Ref_Application_Lifecycle_Stage_Obj_Rel_Insert_Input>;
  ref_investor_type?: InputMaybe<Ref_Investor_Type_Obj_Rel_Insert_Input>;
  reject_reason?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Ref_Application_Lifecycle_Stage_Enum>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Application_Lifecycle_Stage_Max_Fields = {
  __typename?: 'application_lifecycle_stage_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  lifecycle_question_result_id?: Maybe<Scalars['Int']>;
  offer_limit?: Maybe<Scalars['Int']>;
  offer_limit_remarks?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  reject_reason?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Application_Lifecycle_Stage_Min_Fields = {
  __typename?: 'application_lifecycle_stage_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  lifecycle_question_result_id?: Maybe<Scalars['Int']>;
  offer_limit?: Maybe<Scalars['Int']>;
  offer_limit_remarks?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  reject_reason?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "application_lifecycle_stage" */
export type Application_Lifecycle_Stage_Mutation_Response = {
  __typename?: 'application_lifecycle_stage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Application_Lifecycle_Stage>;
};

/** on_conflict condition type for table "application_lifecycle_stage" */
export type Application_Lifecycle_Stage_On_Conflict = {
  constraint: Application_Lifecycle_Stage_Constraint;
  update_columns?: Array<Application_Lifecycle_Stage_Update_Column>;
  where?: InputMaybe<Application_Lifecycle_Stage_Bool_Exp>;
};

/** Ordering options when selecting data from "application_lifecycle_stage". */
export type Application_Lifecycle_Stage_Order_By = {
  age?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  has_financial_integration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_type?: InputMaybe<Order_By>;
  lifecycle_question_result?: InputMaybe<Lifecycle_Question_Result_Order_By>;
  lifecycle_question_result_id?: InputMaybe<Order_By>;
  likeliness?: InputMaybe<Order_By>;
  offer_limit?: InputMaybe<Order_By>;
  offer_limit_remarks?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  ref_application_lifecycle_stage?: InputMaybe<Ref_Application_Lifecycle_Stage_Order_By>;
  ref_investor_type?: InputMaybe<Ref_Investor_Type_Order_By>;
  reject_reason?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: application_lifecycle_stage */
export type Application_Lifecycle_Stage_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "application_lifecycle_stage" */
export enum Application_Lifecycle_Stage_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  HasFinancialIntegration = 'has_financial_integration',
  /** column name */
  Id = 'id',
  /** column name */
  InvestorType = 'investor_type',
  /** column name */
  LifecycleQuestionResultId = 'lifecycle_question_result_id',
  /** column name */
  Likeliness = 'likeliness',
  /** column name */
  OfferLimit = 'offer_limit',
  /** column name */
  OfferLimitRemarks = 'offer_limit_remarks',
  /** column name */
  Priority = 'priority',
  /** column name */
  RejectReason = 'reject_reason',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  Stage = 'stage',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "application_lifecycle_stage" */
export type Application_Lifecycle_Stage_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  has_financial_integration?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_type?: InputMaybe<Ref_Investor_Type_Enum>;
  lifecycle_question_result_id?: InputMaybe<Scalars['Int']>;
  likeliness?: InputMaybe<Ref_Company_Likeliness_Enum>;
  offer_limit?: InputMaybe<Scalars['Int']>;
  offer_limit_remarks?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  reject_reason?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Ref_Application_Lifecycle_Stage_Enum>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Application_Lifecycle_Stage_Stddev_Fields = {
  __typename?: 'application_lifecycle_stage_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifecycle_question_result_id?: Maybe<Scalars['Float']>;
  offer_limit?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Application_Lifecycle_Stage_Stddev_Pop_Fields = {
  __typename?: 'application_lifecycle_stage_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifecycle_question_result_id?: Maybe<Scalars['Float']>;
  offer_limit?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Application_Lifecycle_Stage_Stddev_Samp_Fields = {
  __typename?: 'application_lifecycle_stage_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifecycle_question_result_id?: Maybe<Scalars['Float']>;
  offer_limit?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "application_lifecycle_stage" */
export type Application_Lifecycle_Stage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Application_Lifecycle_Stage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Application_Lifecycle_Stage_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  has_financial_integration?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_type?: InputMaybe<Ref_Investor_Type_Enum>;
  lifecycle_question_result_id?: InputMaybe<Scalars['Int']>;
  likeliness?: InputMaybe<Ref_Company_Likeliness_Enum>;
  offer_limit?: InputMaybe<Scalars['Int']>;
  offer_limit_remarks?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  reject_reason?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Ref_Application_Lifecycle_Stage_Enum>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Application_Lifecycle_Stage_Sum_Fields = {
  __typename?: 'application_lifecycle_stage_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lifecycle_question_result_id?: Maybe<Scalars['Int']>;
  offer_limit?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "application_lifecycle_stage" */
export enum Application_Lifecycle_Stage_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  HasFinancialIntegration = 'has_financial_integration',
  /** column name */
  Id = 'id',
  /** column name */
  InvestorType = 'investor_type',
  /** column name */
  LifecycleQuestionResultId = 'lifecycle_question_result_id',
  /** column name */
  Likeliness = 'likeliness',
  /** column name */
  OfferLimit = 'offer_limit',
  /** column name */
  OfferLimitRemarks = 'offer_limit_remarks',
  /** column name */
  Priority = 'priority',
  /** column name */
  RejectReason = 'reject_reason',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  Stage = 'stage',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Application_Lifecycle_Stage_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Application_Lifecycle_Stage_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Application_Lifecycle_Stage_Set_Input>;
  /** filter the rows which have to be updated */
  where: Application_Lifecycle_Stage_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Application_Lifecycle_Stage_Var_Pop_Fields = {
  __typename?: 'application_lifecycle_stage_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifecycle_question_result_id?: Maybe<Scalars['Float']>;
  offer_limit?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Application_Lifecycle_Stage_Var_Samp_Fields = {
  __typename?: 'application_lifecycle_stage_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifecycle_question_result_id?: Maybe<Scalars['Float']>;
  offer_limit?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Application_Lifecycle_Stage_Variance_Fields = {
  __typename?: 'application_lifecycle_stage_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifecycle_question_result_id?: Maybe<Scalars['Float']>;
  offer_limit?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

export type AttachContractsToTradeInput = {
  pool_subscription_ids: Array<Scalars['Int']>;
  primary_subscription_ids: Array<Scalars['Int']>;
  trade_id: Scalars['Int'];
};

export type AttachContractsToTradeOutput = {
  __typename?: 'attachContractsToTradeOutput';
  message: Scalars['String'];
};

/** columns and relationships of "banking_partner_integration" */
export type Banking_Partner_Integration = {
  __typename?: 'banking_partner_integration';
  account_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  bank?: Maybe<Ref_Bank>;
  bank_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_primary?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
};

/** aggregated selection of "banking_partner_integration" */
export type Banking_Partner_Integration_Aggregate = {
  __typename?: 'banking_partner_integration_aggregate';
  aggregate?: Maybe<Banking_Partner_Integration_Aggregate_Fields>;
  nodes: Array<Banking_Partner_Integration>;
};

/** aggregate fields of "banking_partner_integration" */
export type Banking_Partner_Integration_Aggregate_Fields = {
  __typename?: 'banking_partner_integration_aggregate_fields';
  avg?: Maybe<Banking_Partner_Integration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Banking_Partner_Integration_Max_Fields>;
  min?: Maybe<Banking_Partner_Integration_Min_Fields>;
  stddev?: Maybe<Banking_Partner_Integration_Stddev_Fields>;
  stddev_pop?: Maybe<Banking_Partner_Integration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Banking_Partner_Integration_Stddev_Samp_Fields>;
  sum?: Maybe<Banking_Partner_Integration_Sum_Fields>;
  var_pop?: Maybe<Banking_Partner_Integration_Var_Pop_Fields>;
  var_samp?: Maybe<Banking_Partner_Integration_Var_Samp_Fields>;
  variance?: Maybe<Banking_Partner_Integration_Variance_Fields>;
};


/** aggregate fields of "banking_partner_integration" */
export type Banking_Partner_Integration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Banking_Partner_Integration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Banking_Partner_Integration_Avg_Fields = {
  __typename?: 'banking_partner_integration_avg_fields';
  bank_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "banking_partner_integration". All fields are combined with a logical 'AND'. */
export type Banking_Partner_Integration_Bool_Exp = {
  _and?: InputMaybe<Array<Banking_Partner_Integration_Bool_Exp>>;
  _not?: InputMaybe<Banking_Partner_Integration_Bool_Exp>;
  _or?: InputMaybe<Array<Banking_Partner_Integration_Bool_Exp>>;
  account_type?: InputMaybe<String_Comparison_Exp>;
  bank?: InputMaybe<Ref_Bank_Bool_Exp>;
  bank_id?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_primary?: InputMaybe<Boolean_Comparison_Exp>;
  sequence?: InputMaybe<Int_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "banking_partner_integration" */
export type Banking_Partner_Integration_Inc_Input = {
  bank_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "banking_partner_integration" */
export type Banking_Partner_Integration_Insert_Input = {
  account_type?: InputMaybe<Scalars['String']>;
  bank?: InputMaybe<Ref_Bank_Obj_Rel_Insert_Input>;
  bank_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  sequence?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Banking_Partner_Integration_Max_Fields = {
  __typename?: 'banking_partner_integration_max_fields';
  account_type?: Maybe<Scalars['String']>;
  bank_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Banking_Partner_Integration_Min_Fields = {
  __typename?: 'banking_partner_integration_min_fields';
  account_type?: Maybe<Scalars['String']>;
  bank_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "banking_partner_integration" */
export type Banking_Partner_Integration_Mutation_Response = {
  __typename?: 'banking_partner_integration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Banking_Partner_Integration>;
};

/** input type for inserting object relation for remote table "banking_partner_integration" */
export type Banking_Partner_Integration_Obj_Rel_Insert_Input = {
  data: Banking_Partner_Integration_Insert_Input;
};

/** Ordering options when selecting data from "banking_partner_integration". */
export type Banking_Partner_Integration_Order_By = {
  account_type?: InputMaybe<Order_By>;
  bank?: InputMaybe<Ref_Bank_Order_By>;
  bank_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_primary?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
};

/** select columns of table "banking_partner_integration" */
export enum Banking_Partner_Integration_Select_Column {
  /** column name */
  AccountType = 'account_type',
  /** column name */
  BankId = 'bank_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Source = 'source'
}

/** input type for updating data in table "banking_partner_integration" */
export type Banking_Partner_Integration_Set_Input = {
  account_type?: InputMaybe<Scalars['String']>;
  bank_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  sequence?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Banking_Partner_Integration_Stddev_Fields = {
  __typename?: 'banking_partner_integration_stddev_fields';
  bank_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Banking_Partner_Integration_Stddev_Pop_Fields = {
  __typename?: 'banking_partner_integration_stddev_pop_fields';
  bank_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Banking_Partner_Integration_Stddev_Samp_Fields = {
  __typename?: 'banking_partner_integration_stddev_samp_fields';
  bank_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "banking_partner_integration" */
export type Banking_Partner_Integration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Banking_Partner_Integration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Banking_Partner_Integration_Stream_Cursor_Value_Input = {
  account_type?: InputMaybe<Scalars['String']>;
  bank_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  sequence?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Banking_Partner_Integration_Sum_Fields = {
  __typename?: 'banking_partner_integration_sum_fields';
  bank_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
};

export type Banking_Partner_Integration_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Banking_Partner_Integration_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Banking_Partner_Integration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Banking_Partner_Integration_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Banking_Partner_Integration_Var_Pop_Fields = {
  __typename?: 'banking_partner_integration_var_pop_fields';
  bank_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Banking_Partner_Integration_Var_Samp_Fields = {
  __typename?: 'banking_partner_integration_var_samp_fields';
  bank_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Banking_Partner_Integration_Variance_Fields = {
  __typename?: 'banking_partner_integration_variance_fields';
  bank_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "bid" */
export type Bid = {
  __typename?: 'bid';
  amount: Scalars['numeric'];
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  /** An object relationship */
  deal: Deal;
  deal_id: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  estimated_irr?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  /** An array relationship */
  investments: Array<Investment>;
  /** An aggregate relationship */
  investments_aggregate: Investment_Aggregate;
  /** An object relationship */
  investor: Investor;
  investor_id: Scalars['Int'];
  price: Scalars['float8'];
  /** An object relationship */
  ref_bid?: Maybe<Ref_Bid>;
  remarks?: Maybe<Scalars['String']>;
  roi?: Maybe<Scalars['float8']>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
  validity?: Maybe<Scalars['Int']>;
  your_bid?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "bid" */
export type BidInvestmentsArgs = {
  distinct_on?: InputMaybe<Array<Investment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Order_By>>;
  where?: InputMaybe<Investment_Bool_Exp>;
};


/** columns and relationships of "bid" */
export type BidInvestments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Order_By>>;
  where?: InputMaybe<Investment_Bool_Exp>;
};

/** aggregated selection of "bid" */
export type Bid_Aggregate = {
  __typename?: 'bid_aggregate';
  aggregate?: Maybe<Bid_Aggregate_Fields>;
  nodes: Array<Bid>;
};

export type Bid_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Bid_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Bid_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Bid_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Bid_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Bid_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Bid_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Bid_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Bid_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Bid_Aggregate_Bool_Exp_Var_Samp>;
};

export type Bid_Aggregate_Bool_Exp_Avg = {
  arguments: Bid_Select_Column_Bid_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bid_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Bid_Aggregate_Bool_Exp_Corr = {
  arguments: Bid_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bid_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Bid_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Bid_Select_Column_Bid_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Bid_Select_Column_Bid_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Bid_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Bid_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bid_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Bid_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Bid_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bid_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Bid_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Bid_Select_Column_Bid_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Bid_Select_Column_Bid_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Bid_Aggregate_Bool_Exp_Max = {
  arguments: Bid_Select_Column_Bid_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bid_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Bid_Aggregate_Bool_Exp_Min = {
  arguments: Bid_Select_Column_Bid_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bid_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Bid_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Bid_Select_Column_Bid_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bid_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Bid_Aggregate_Bool_Exp_Sum = {
  arguments: Bid_Select_Column_Bid_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bid_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Bid_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Bid_Select_Column_Bid_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bid_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "bid" */
export type Bid_Aggregate_Fields = {
  __typename?: 'bid_aggregate_fields';
  avg?: Maybe<Bid_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bid_Max_Fields>;
  min?: Maybe<Bid_Min_Fields>;
  stddev?: Maybe<Bid_Stddev_Fields>;
  stddev_pop?: Maybe<Bid_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bid_Stddev_Samp_Fields>;
  sum?: Maybe<Bid_Sum_Fields>;
  var_pop?: Maybe<Bid_Var_Pop_Fields>;
  var_samp?: Maybe<Bid_Var_Samp_Fields>;
  variance?: Maybe<Bid_Variance_Fields>;
};


/** aggregate fields of "bid" */
export type Bid_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bid_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bid" */
export type Bid_Aggregate_Order_By = {
  avg?: InputMaybe<Bid_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bid_Max_Order_By>;
  min?: InputMaybe<Bid_Min_Order_By>;
  stddev?: InputMaybe<Bid_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bid_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bid_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bid_Sum_Order_By>;
  var_pop?: InputMaybe<Bid_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bid_Var_Samp_Order_By>;
  variance?: InputMaybe<Bid_Variance_Order_By>;
};

export type Bid_Amount_Subscription_Args = {
  bid_price?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting array relation for remote table "bid" */
export type Bid_Arr_Rel_Insert_Input = {
  data: Array<Bid_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Bid_On_Conflict>;
};

/** aggregate avg on columns */
export type Bid_Avg_Fields = {
  __typename?: 'bid_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  estimated_irr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  validity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bid" */
export type Bid_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  estimated_irr?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bid". All fields are combined with a logical 'AND'. */
export type Bid_Bool_Exp = {
  _and?: InputMaybe<Array<Bid_Bool_Exp>>;
  _not?: InputMaybe<Bid_Bool_Exp>;
  _or?: InputMaybe<Array<Bid_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deal?: InputMaybe<Deal_Bool_Exp>;
  deal_id?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  estimated_irr?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  investments?: InputMaybe<Investment_Bool_Exp>;
  investments_aggregate?: InputMaybe<Investment_Aggregate_Bool_Exp>;
  investor?: InputMaybe<Investor_Bool_Exp>;
  investor_id?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  ref_bid?: InputMaybe<Ref_Bid_Bool_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
  roi?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  validity?: InputMaybe<Int_Comparison_Exp>;
  your_bid?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "bid" */
export enum Bid_Constraint {
  /** unique or primary key constraint on columns "id" */
  BidPkey = 'bid_pkey'
}

/** input type for incrementing numeric columns in table "bid" */
export type Bid_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deal_id?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  estimated_irr?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['float8']>;
  roi?: InputMaybe<Scalars['float8']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  validity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bid" */
export type Bid_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deal?: InputMaybe<Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  estimated_irr?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  investments?: InputMaybe<Investment_Arr_Rel_Insert_Input>;
  investor?: InputMaybe<Investor_Obj_Rel_Insert_Input>;
  investor_id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['float8']>;
  ref_bid?: InputMaybe<Ref_Bid_Obj_Rel_Insert_Input>;
  remarks?: InputMaybe<Scalars['String']>;
  roi?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  validity?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Bid_Max_Fields = {
  __typename?: 'bid_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  estimated_irr?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  investor_id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  remarks?: Maybe<Scalars['String']>;
  roi?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  validity?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "bid" */
export type Bid_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  estimated_irr?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bid_Min_Fields = {
  __typename?: 'bid_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  estimated_irr?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  investor_id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  remarks?: Maybe<Scalars['String']>;
  roi?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  validity?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "bid" */
export type Bid_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  estimated_irr?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bid" */
export type Bid_Mutation_Response = {
  __typename?: 'bid_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bid>;
};

/** input type for inserting object relation for remote table "bid" */
export type Bid_Obj_Rel_Insert_Input = {
  data: Bid_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Bid_On_Conflict>;
};

/** on_conflict condition type for table "bid" */
export type Bid_On_Conflict = {
  constraint: Bid_Constraint;
  update_columns?: Array<Bid_Update_Column>;
  where?: InputMaybe<Bid_Bool_Exp>;
};

/** Ordering options when selecting data from "bid". */
export type Bid_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal?: InputMaybe<Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  estimated_irr?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investments_aggregate?: InputMaybe<Investment_Aggregate_Order_By>;
  investor?: InputMaybe<Investor_Order_By>;
  investor_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  ref_bid?: InputMaybe<Ref_Bid_Order_By>;
  remarks?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
  your_bid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bid */
export type Bid_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "bid" */
export enum Bid_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EstimatedIrr = 'estimated_irr',
  /** column name */
  Id = 'id',
  /** column name */
  InvestorId = 'investor_id',
  /** column name */
  Price = 'price',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  Roi = 'roi',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Validity = 'validity'
}

/** select "bid_aggregate_bool_exp_avg_arguments_columns" columns of table "bid" */
export enum Bid_Select_Column_Bid_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  EstimatedIrr = 'estimated_irr',
  /** column name */
  Price = 'price',
  /** column name */
  Roi = 'roi'
}

/** select "bid_aggregate_bool_exp_corr_arguments_columns" columns of table "bid" */
export enum Bid_Select_Column_Bid_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  EstimatedIrr = 'estimated_irr',
  /** column name */
  Price = 'price',
  /** column name */
  Roi = 'roi'
}

/** select "bid_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "bid" */
export enum Bid_Select_Column_Bid_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  EstimatedIrr = 'estimated_irr',
  /** column name */
  Price = 'price',
  /** column name */
  Roi = 'roi'
}

/** select "bid_aggregate_bool_exp_max_arguments_columns" columns of table "bid" */
export enum Bid_Select_Column_Bid_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  EstimatedIrr = 'estimated_irr',
  /** column name */
  Price = 'price',
  /** column name */
  Roi = 'roi'
}

/** select "bid_aggregate_bool_exp_min_arguments_columns" columns of table "bid" */
export enum Bid_Select_Column_Bid_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  EstimatedIrr = 'estimated_irr',
  /** column name */
  Price = 'price',
  /** column name */
  Roi = 'roi'
}

/** select "bid_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "bid" */
export enum Bid_Select_Column_Bid_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  EstimatedIrr = 'estimated_irr',
  /** column name */
  Price = 'price',
  /** column name */
  Roi = 'roi'
}

/** select "bid_aggregate_bool_exp_sum_arguments_columns" columns of table "bid" */
export enum Bid_Select_Column_Bid_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  EstimatedIrr = 'estimated_irr',
  /** column name */
  Price = 'price',
  /** column name */
  Roi = 'roi'
}

/** select "bid_aggregate_bool_exp_var_samp_arguments_columns" columns of table "bid" */
export enum Bid_Select_Column_Bid_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  EstimatedIrr = 'estimated_irr',
  /** column name */
  Price = 'price',
  /** column name */
  Roi = 'roi'
}

/** input type for updating data in table "bid" */
export type Bid_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deal_id?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  estimated_irr?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['float8']>;
  remarks?: InputMaybe<Scalars['String']>;
  roi?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  validity?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Bid_Stddev_Fields = {
  __typename?: 'bid_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  estimated_irr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  validity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bid" */
export type Bid_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  estimated_irr?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bid_Stddev_Pop_Fields = {
  __typename?: 'bid_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  estimated_irr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  validity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "bid" */
export type Bid_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  estimated_irr?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bid_Stddev_Samp_Fields = {
  __typename?: 'bid_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  estimated_irr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  validity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "bid" */
export type Bid_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  estimated_irr?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "bid" */
export type Bid_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bid_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bid_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deal_id?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  estimated_irr?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['float8']>;
  remarks?: InputMaybe<Scalars['String']>;
  roi?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  validity?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Bid_Sum_Fields = {
  __typename?: 'bid_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  estimated_irr?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  investor_id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  roi?: Maybe<Scalars['float8']>;
  updated_by?: Maybe<Scalars['Int']>;
  validity?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "bid" */
export type Bid_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  estimated_irr?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** update columns of table "bid" */
export enum Bid_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EstimatedIrr = 'estimated_irr',
  /** column name */
  Id = 'id',
  /** column name */
  InvestorId = 'investor_id',
  /** column name */
  Price = 'price',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  Roi = 'roi',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Validity = 'validity'
}

export type Bid_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bid_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bid_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bid_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bid_Var_Pop_Fields = {
  __typename?: 'bid_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  estimated_irr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  validity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "bid" */
export type Bid_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  estimated_irr?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bid_Var_Samp_Fields = {
  __typename?: 'bid_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  estimated_irr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  validity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "bid" */
export type Bid_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  estimated_irr?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Bid_Variance_Fields = {
  __typename?: 'bid_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  estimated_irr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  validity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bid" */
export type Bid_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  estimated_irr?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** columns and relationships of "blacklisted_tokens" */
export type Blacklisted_Tokens = {
  __typename?: 'blacklisted_tokens';
  token: Scalars['String'];
};

/** aggregated selection of "blacklisted_tokens" */
export type Blacklisted_Tokens_Aggregate = {
  __typename?: 'blacklisted_tokens_aggregate';
  aggregate?: Maybe<Blacklisted_Tokens_Aggregate_Fields>;
  nodes: Array<Blacklisted_Tokens>;
};

/** aggregate fields of "blacklisted_tokens" */
export type Blacklisted_Tokens_Aggregate_Fields = {
  __typename?: 'blacklisted_tokens_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Blacklisted_Tokens_Max_Fields>;
  min?: Maybe<Blacklisted_Tokens_Min_Fields>;
};


/** aggregate fields of "blacklisted_tokens" */
export type Blacklisted_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Blacklisted_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "blacklisted_tokens". All fields are combined with a logical 'AND'. */
export type Blacklisted_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Blacklisted_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Blacklisted_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Blacklisted_Tokens_Bool_Exp>>;
  token?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "blacklisted_tokens" */
export enum Blacklisted_Tokens_Constraint {
  /** unique or primary key constraint on columns "token" */
  BlacklistedTokensPkey = 'blacklisted_tokens_pkey'
}

/** input type for inserting data into table "blacklisted_tokens" */
export type Blacklisted_Tokens_Insert_Input = {
  token?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Blacklisted_Tokens_Max_Fields = {
  __typename?: 'blacklisted_tokens_max_fields';
  token?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Blacklisted_Tokens_Min_Fields = {
  __typename?: 'blacklisted_tokens_min_fields';
  token?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "blacklisted_tokens" */
export type Blacklisted_Tokens_Mutation_Response = {
  __typename?: 'blacklisted_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Blacklisted_Tokens>;
};

/** on_conflict condition type for table "blacklisted_tokens" */
export type Blacklisted_Tokens_On_Conflict = {
  constraint: Blacklisted_Tokens_Constraint;
  update_columns?: Array<Blacklisted_Tokens_Update_Column>;
  where?: InputMaybe<Blacklisted_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "blacklisted_tokens". */
export type Blacklisted_Tokens_Order_By = {
  token?: InputMaybe<Order_By>;
};

/** primary key columns input for table: blacklisted_tokens */
export type Blacklisted_Tokens_Pk_Columns_Input = {
  token: Scalars['String'];
};

/** select columns of table "blacklisted_tokens" */
export enum Blacklisted_Tokens_Select_Column {
  /** column name */
  Token = 'token'
}

/** input type for updating data in table "blacklisted_tokens" */
export type Blacklisted_Tokens_Set_Input = {
  token?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "blacklisted_tokens" */
export type Blacklisted_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Blacklisted_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Blacklisted_Tokens_Stream_Cursor_Value_Input = {
  token?: InputMaybe<Scalars['String']>;
};

/** update columns of table "blacklisted_tokens" */
export enum Blacklisted_Tokens_Update_Column {
  /** column name */
  Token = 'token'
}

export type Blacklisted_Tokens_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Blacklisted_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Blacklisted_Tokens_Bool_Exp;
};

/** columns and relationships of "company" */
export type Company = {
  __typename?: 'company';
  /** An array relationship */
  accounts: Array<Account>;
  /** An aggregate relationship */
  accounts_aggregate: Account_Aggregate;
  active_gstin?: Maybe<Scalars['String']>;
  address_line_1?: Maybe<Scalars['String']>;
  agreement_signed_date?: Maybe<Scalars['timestamptz']>;
  alternate_emails?: Maybe<Scalars['String']>;
  application_lifecycle_stage?: Maybe<Ref_Application_Lifecycle_Stage_Enum>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['Int']>;
  authorized_signatories?: Maybe<Scalars['jsonb']>;
  auto_rejected?: Maybe<Scalars['Boolean']>;
  billing_address?: Maybe<Scalars['String']>;
  billing_gst?: Maybe<Scalars['String']>;
  /** An object relationship */
  ccs_agreement_file?: Maybe<Agreement_Registry>;
  ccs_agreement_id?: Maybe<Scalars['Int']>;
  cin?: Maybe<Scalars['String']>;
  /** An array relationship */
  company_integrations: Array<Company_Integration>;
  /** An aggregate relationship */
  company_integrations_aggregate: Company_Integration_Aggregate;
  /** An array relationship */
  company_scores: Array<Company_Score>;
  /** An aggregate relationship */
  company_scores_aggregate: Company_Score_Aggregate;
  company_sector?: Maybe<Scalars['String']>;
  company_type?: Maybe<Scalars['String']>;
  /** An array relationship */
  company_users: Array<Company_User>;
  /** An aggregate relationship */
  company_users_aggregate: Company_User_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  crm_company_id?: Maybe<Scalars['String']>;
  crm_contact_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  /** An array relationship */
  document_checklist_categories: Array<Document_Checklist_Category>;
  /** An aggregate relationship */
  document_checklist_categories_aggregate: Document_Checklist_Category_Aggregate;
  document_checklist_enabled: Scalars['Boolean'];
  domain: Scalars['String'];
  estimated_arr?: Maybe<Scalars['numeric']>;
  gstin?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  incorporation_date?: Maybe<Scalars['date']>;
  industry?: Maybe<Scalars['String']>;
  /** An array relationship */
  integration_auths: Array<Integration_Auth>;
  /** An aggregate relationship */
  integration_auths_aggregate: Integration_Auth_Aggregate;
  /** An array relationship */
  lead_status: Array<Company_Lead_Status>;
  /** An aggregate relationship */
  lead_status_aggregate: Company_Lead_Status_Aggregate;
  /** An array relationship */
  mandates: Array<Mandate>;
  /** An aggregate relationship */
  mandates_aggregate: Mandate_Aggregate;
  marquee_customers?: Maybe<Scalars['String']>;
  marquee_investors?: Maybe<Scalars['jsonb']>;
  mca_profile?: Maybe<Scalars['jsonb']>;
  mca_signatories?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  min_tradable_value?: Maybe<Scalars['float8']>;
  name?: Maybe<Scalars['String']>;
  ops_rm?: Maybe<Scalars['Int']>;
  /** An object relationship */
  ops_user?: Maybe<User>;
  pan?: Maybe<Scalars['String']>;
  /** An object relationship */
  ref_company_type?: Maybe<Ref_Company_Type>;
  /** An object relationship */
  ref_industry?: Maybe<Ref_Industry>;
  reject_reason?: Maybe<Scalars['String']>;
  rejected_at?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "get_remaining_tradable_value" */
  remaining_tradable_value?: Maybe<Scalars['float8']>;
  sales_rm?: Maybe<Scalars['Int']>;
  /** An object relationship */
  sales_user?: Maybe<User>;
  shareholder_info?: Maybe<Scalars['jsonb']>;
  shareholder_info_date?: Maybe<Scalars['timestamp']>;
  state_id?: Maybe<Scalars['Int']>;
  status: Ref_Company_Status_Enum;
  tenant_type?: Maybe<Ref_Tenant_Type_Enum>;
  total_customers?: Maybe<Scalars['Int']>;
  total_funding?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  trades: Array<Trade>;
  /** An aggregate relationship */
  trades_aggregate: Trade_Aggregate;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
  version: Scalars['Int'];
  website?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};


/** columns and relationships of "company" */
export type CompanyAccountsArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyAuthorized_SignatoriesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "company" */
export type CompanyCompany_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_Order_By>>;
  where?: InputMaybe<Company_Integration_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyCompany_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_Order_By>>;
  where?: InputMaybe<Company_Integration_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyCompany_ScoresArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Order_By>>;
  where?: InputMaybe<Company_Score_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyCompany_Scores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Order_By>>;
  where?: InputMaybe<Company_Score_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyCompany_UsersArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyCompany_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyDealsArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyDeals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyDocument_Checklist_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Document_Checklist_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Checklist_Category_Order_By>>;
  where?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyDocument_Checklist_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Checklist_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Checklist_Category_Order_By>>;
  where?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyGstinArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "company" */
export type CompanyIntegration_AuthsArgs = {
  distinct_on?: InputMaybe<Array<Integration_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Auth_Order_By>>;
  where?: InputMaybe<Integration_Auth_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyIntegration_Auths_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Auth_Order_By>>;
  where?: InputMaybe<Integration_Auth_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyLead_StatusArgs = {
  distinct_on?: InputMaybe<Array<Company_Lead_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Lead_Status_Order_By>>;
  where?: InputMaybe<Company_Lead_Status_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyLead_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Lead_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Lead_Status_Order_By>>;
  where?: InputMaybe<Company_Lead_Status_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyMandatesArgs = {
  distinct_on?: InputMaybe<Array<Mandate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mandate_Order_By>>;
  where?: InputMaybe<Mandate_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyMandates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mandate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mandate_Order_By>>;
  where?: InputMaybe<Mandate_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyMarquee_InvestorsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "company" */
export type CompanyMca_ProfileArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "company" */
export type CompanyMca_SignatoriesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "company" */
export type CompanyMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "company" */
export type CompanyShareholder_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "company" */
export type CompanyTradesArgs = {
  distinct_on?: InputMaybe<Array<Trade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Order_By>>;
  where?: InputMaybe<Trade_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyTrades_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Order_By>>;
  where?: InputMaybe<Trade_Bool_Exp>;
};

/** aggregated selection of "company" */
export type Company_Aggregate = {
  __typename?: 'company_aggregate';
  aggregate?: Maybe<Company_Aggregate_Fields>;
  nodes: Array<Company>;
};

/** aggregate fields of "company" */
export type Company_Aggregate_Fields = {
  __typename?: 'company_aggregate_fields';
  avg?: Maybe<Company_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Max_Fields>;
  min?: Maybe<Company_Min_Fields>;
  stddev?: Maybe<Company_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Sum_Fields>;
  var_pop?: Maybe<Company_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Var_Samp_Fields>;
  variance?: Maybe<Company_Variance_Fields>;
};


/** aggregate fields of "company" */
export type Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "company_agreement_logs_view" */
export type Company_Agreement_Logs_View = {
  __typename?: 'company_agreement_logs_view';
  category_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  deleted_by?: Maybe<Scalars['Int']>;
  /** An object relationship */
  draft_file?: Maybe<File_Registry>;
  draft_file_id?: Maybe<Scalars['Int']>;
  failure_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['jsonb']>;
  signed_at?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  signed_file?: Maybe<File_Registry>;
  signed_file_id?: Maybe<Scalars['Int']>;
  signers?: Maybe<Scalars['jsonb']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "company_agreement_logs_view" */
export type Company_Agreement_Logs_ViewMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "company_agreement_logs_view" */
export type Company_Agreement_Logs_ViewSignersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "company_agreement_logs_view" */
export type Company_Agreement_Logs_View_Aggregate = {
  __typename?: 'company_agreement_logs_view_aggregate';
  aggregate?: Maybe<Company_Agreement_Logs_View_Aggregate_Fields>;
  nodes: Array<Company_Agreement_Logs_View>;
};

/** aggregate fields of "company_agreement_logs_view" */
export type Company_Agreement_Logs_View_Aggregate_Fields = {
  __typename?: 'company_agreement_logs_view_aggregate_fields';
  avg?: Maybe<Company_Agreement_Logs_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Agreement_Logs_View_Max_Fields>;
  min?: Maybe<Company_Agreement_Logs_View_Min_Fields>;
  stddev?: Maybe<Company_Agreement_Logs_View_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Agreement_Logs_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Agreement_Logs_View_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Agreement_Logs_View_Sum_Fields>;
  var_pop?: Maybe<Company_Agreement_Logs_View_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Agreement_Logs_View_Var_Samp_Fields>;
  variance?: Maybe<Company_Agreement_Logs_View_Variance_Fields>;
};


/** aggregate fields of "company_agreement_logs_view" */
export type Company_Agreement_Logs_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Agreement_Logs_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Company_Agreement_Logs_View_Avg_Fields = {
  __typename?: 'company_agreement_logs_view_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  draft_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company_agreement_logs_view". All fields are combined with a logical 'AND'. */
export type Company_Agreement_Logs_View_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Agreement_Logs_View_Bool_Exp>>;
  _not?: InputMaybe<Company_Agreement_Logs_View_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Agreement_Logs_View_Bool_Exp>>;
  category_id?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  draft_file?: InputMaybe<File_Registry_Bool_Exp>;
  draft_file_id?: InputMaybe<Int_Comparison_Exp>;
  failure_reason?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  signed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  signed_file?: InputMaybe<File_Registry_Bool_Exp>;
  signed_file_id?: InputMaybe<Int_Comparison_Exp>;
  signers?: InputMaybe<Jsonb_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  source_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Company_Agreement_Logs_View_Max_Fields = {
  __typename?: 'company_agreement_logs_view_max_fields';
  category_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  deleted_by?: Maybe<Scalars['Int']>;
  draft_file_id?: Maybe<Scalars['Int']>;
  failure_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  signed_at?: Maybe<Scalars['timestamp']>;
  signed_file_id?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Company_Agreement_Logs_View_Min_Fields = {
  __typename?: 'company_agreement_logs_view_min_fields';
  category_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  deleted_by?: Maybe<Scalars['Int']>;
  draft_file_id?: Maybe<Scalars['Int']>;
  failure_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  signed_at?: Maybe<Scalars['timestamp']>;
  signed_file_id?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "company_agreement_logs_view". */
export type Company_Agreement_Logs_View_Order_By = {
  category_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  draft_file?: InputMaybe<File_Registry_Order_By>;
  draft_file_id?: InputMaybe<Order_By>;
  failure_reason?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  signed_at?: InputMaybe<Order_By>;
  signed_file?: InputMaybe<File_Registry_Order_By>;
  signed_file_id?: InputMaybe<Order_By>;
  signers?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** select columns of table "company_agreement_logs_view" */
export enum Company_Agreement_Logs_View_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  DraftFileId = 'draft_file_id',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  SignedAt = 'signed_at',
  /** column name */
  SignedFileId = 'signed_file_id',
  /** column name */
  Signers = 'signers',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate stddev on columns */
export type Company_Agreement_Logs_View_Stddev_Fields = {
  __typename?: 'company_agreement_logs_view_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  draft_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Agreement_Logs_View_Stddev_Pop_Fields = {
  __typename?: 'company_agreement_logs_view_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  draft_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Agreement_Logs_View_Stddev_Samp_Fields = {
  __typename?: 'company_agreement_logs_view_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  draft_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "company_agreement_logs_view" */
export type Company_Agreement_Logs_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Agreement_Logs_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Agreement_Logs_View_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  draft_file_id?: InputMaybe<Scalars['Int']>;
  failure_reason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  signed_at?: InputMaybe<Scalars['timestamp']>;
  signed_file_id?: InputMaybe<Scalars['Int']>;
  signers?: InputMaybe<Scalars['jsonb']>;
  source?: InputMaybe<Scalars['String']>;
  source_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Company_Agreement_Logs_View_Sum_Fields = {
  __typename?: 'company_agreement_logs_view_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  draft_file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  signed_file_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Company_Agreement_Logs_View_Var_Pop_Fields = {
  __typename?: 'company_agreement_logs_view_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  draft_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Agreement_Logs_View_Var_Samp_Fields = {
  __typename?: 'company_agreement_logs_view_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  draft_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Agreement_Logs_View_Variance_Fields = {
  __typename?: 'company_agreement_logs_view_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  draft_file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Company_Append_Input = {
  authorized_signatories?: InputMaybe<Scalars['jsonb']>;
  gstin?: InputMaybe<Scalars['jsonb']>;
  marquee_investors?: InputMaybe<Scalars['jsonb']>;
  mca_profile?: InputMaybe<Scalars['jsonb']>;
  mca_signatories?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  shareholder_info?: InputMaybe<Scalars['jsonb']>;
};

/** contains all litigation details of the company */
export type Company_Application_Crime_Details = {
  __typename?: 'company_application_crime_details';
  /** An object relationship */
  company: Company;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  crime_details: Scalars['jsonb'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by: Scalars['Int'];
};


/** contains all litigation details of the company */
export type Company_Application_Crime_DetailsCrime_DetailsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "company_application_crime_details" */
export type Company_Application_Crime_Details_Aggregate = {
  __typename?: 'company_application_crime_details_aggregate';
  aggregate?: Maybe<Company_Application_Crime_Details_Aggregate_Fields>;
  nodes: Array<Company_Application_Crime_Details>;
};

/** aggregate fields of "company_application_crime_details" */
export type Company_Application_Crime_Details_Aggregate_Fields = {
  __typename?: 'company_application_crime_details_aggregate_fields';
  avg?: Maybe<Company_Application_Crime_Details_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Application_Crime_Details_Max_Fields>;
  min?: Maybe<Company_Application_Crime_Details_Min_Fields>;
  stddev?: Maybe<Company_Application_Crime_Details_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Application_Crime_Details_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Application_Crime_Details_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Application_Crime_Details_Sum_Fields>;
  var_pop?: Maybe<Company_Application_Crime_Details_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Application_Crime_Details_Var_Samp_Fields>;
  variance?: Maybe<Company_Application_Crime_Details_Variance_Fields>;
};


/** aggregate fields of "company_application_crime_details" */
export type Company_Application_Crime_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Application_Crime_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Company_Application_Crime_Details_Append_Input = {
  crime_details?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Company_Application_Crime_Details_Avg_Fields = {
  __typename?: 'company_application_crime_details_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company_application_crime_details". All fields are combined with a logical 'AND'. */
export type Company_Application_Crime_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Application_Crime_Details_Bool_Exp>>;
  _not?: InputMaybe<Company_Application_Crime_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Application_Crime_Details_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  crime_details?: InputMaybe<Jsonb_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_application_crime_details" */
export enum Company_Application_Crime_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyApplicationCrimeDetailsPkey = 'company_application_crime_details_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Company_Application_Crime_Details_Delete_At_Path_Input = {
  crime_details?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Company_Application_Crime_Details_Delete_Elem_Input = {
  crime_details?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Company_Application_Crime_Details_Delete_Key_Input = {
  crime_details?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "company_application_crime_details" */
export type Company_Application_Crime_Details_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_application_crime_details" */
export type Company_Application_Crime_Details_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  crime_details?: InputMaybe<Scalars['jsonb']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Company_Application_Crime_Details_Max_Fields = {
  __typename?: 'company_application_crime_details_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Company_Application_Crime_Details_Min_Fields = {
  __typename?: 'company_application_crime_details_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "company_application_crime_details" */
export type Company_Application_Crime_Details_Mutation_Response = {
  __typename?: 'company_application_crime_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Application_Crime_Details>;
};

/** on_conflict condition type for table "company_application_crime_details" */
export type Company_Application_Crime_Details_On_Conflict = {
  constraint: Company_Application_Crime_Details_Constraint;
  update_columns?: Array<Company_Application_Crime_Details_Update_Column>;
  where?: InputMaybe<Company_Application_Crime_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "company_application_crime_details". */
export type Company_Application_Crime_Details_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  crime_details?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_application_crime_details */
export type Company_Application_Crime_Details_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Company_Application_Crime_Details_Prepend_Input = {
  crime_details?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "company_application_crime_details" */
export enum Company_Application_Crime_Details_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CrimeDetails = 'crime_details',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "company_application_crime_details" */
export type Company_Application_Crime_Details_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  crime_details?: InputMaybe<Scalars['jsonb']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Company_Application_Crime_Details_Stddev_Fields = {
  __typename?: 'company_application_crime_details_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Application_Crime_Details_Stddev_Pop_Fields = {
  __typename?: 'company_application_crime_details_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Application_Crime_Details_Stddev_Samp_Fields = {
  __typename?: 'company_application_crime_details_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "company_application_crime_details" */
export type Company_Application_Crime_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Application_Crime_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Application_Crime_Details_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  crime_details?: InputMaybe<Scalars['jsonb']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Company_Application_Crime_Details_Sum_Fields = {
  __typename?: 'company_application_crime_details_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "company_application_crime_details" */
export enum Company_Application_Crime_Details_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CrimeDetails = 'crime_details',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Company_Application_Crime_Details_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Company_Application_Crime_Details_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Company_Application_Crime_Details_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Company_Application_Crime_Details_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Company_Application_Crime_Details_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Application_Crime_Details_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Company_Application_Crime_Details_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Application_Crime_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Application_Crime_Details_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Application_Crime_Details_Var_Pop_Fields = {
  __typename?: 'company_application_crime_details_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Application_Crime_Details_Var_Samp_Fields = {
  __typename?: 'company_application_crime_details_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Application_Crime_Details_Variance_Fields = {
  __typename?: 'company_application_crime_details_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "company_application_epfo" */
export type Company_Application_Epfo = {
  __typename?: 'company_application_epfo';
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  epfo_details: Scalars['jsonb'];
  id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "company_application_epfo" */
export type Company_Application_EpfoEpfo_DetailsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "company_application_epfo" */
export type Company_Application_Epfo_Aggregate = {
  __typename?: 'company_application_epfo_aggregate';
  aggregate?: Maybe<Company_Application_Epfo_Aggregate_Fields>;
  nodes: Array<Company_Application_Epfo>;
};

/** aggregate fields of "company_application_epfo" */
export type Company_Application_Epfo_Aggregate_Fields = {
  __typename?: 'company_application_epfo_aggregate_fields';
  avg?: Maybe<Company_Application_Epfo_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Application_Epfo_Max_Fields>;
  min?: Maybe<Company_Application_Epfo_Min_Fields>;
  stddev?: Maybe<Company_Application_Epfo_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Application_Epfo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Application_Epfo_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Application_Epfo_Sum_Fields>;
  var_pop?: Maybe<Company_Application_Epfo_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Application_Epfo_Var_Samp_Fields>;
  variance?: Maybe<Company_Application_Epfo_Variance_Fields>;
};


/** aggregate fields of "company_application_epfo" */
export type Company_Application_Epfo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Application_Epfo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Company_Application_Epfo_Append_Input = {
  epfo_details?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Company_Application_Epfo_Avg_Fields = {
  __typename?: 'company_application_epfo_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company_application_epfo". All fields are combined with a logical 'AND'. */
export type Company_Application_Epfo_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Application_Epfo_Bool_Exp>>;
  _not?: InputMaybe<Company_Application_Epfo_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Application_Epfo_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  epfo_details?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_application_epfo" */
export enum Company_Application_Epfo_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyApplicationEpfoPkey = 'company_application_epfo_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Company_Application_Epfo_Delete_At_Path_Input = {
  epfo_details?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Company_Application_Epfo_Delete_Elem_Input = {
  epfo_details?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Company_Application_Epfo_Delete_Key_Input = {
  epfo_details?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "company_application_epfo" */
export type Company_Application_Epfo_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_application_epfo" */
export type Company_Application_Epfo_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  epfo_details?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Company_Application_Epfo_Max_Fields = {
  __typename?: 'company_application_epfo_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Company_Application_Epfo_Min_Fields = {
  __typename?: 'company_application_epfo_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "company_application_epfo" */
export type Company_Application_Epfo_Mutation_Response = {
  __typename?: 'company_application_epfo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Application_Epfo>;
};

/** on_conflict condition type for table "company_application_epfo" */
export type Company_Application_Epfo_On_Conflict = {
  constraint: Company_Application_Epfo_Constraint;
  update_columns?: Array<Company_Application_Epfo_Update_Column>;
  where?: InputMaybe<Company_Application_Epfo_Bool_Exp>;
};

/** Ordering options when selecting data from "company_application_epfo". */
export type Company_Application_Epfo_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  epfo_details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_application_epfo */
export type Company_Application_Epfo_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Company_Application_Epfo_Prepend_Input = {
  epfo_details?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "company_application_epfo" */
export enum Company_Application_Epfo_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EpfoDetails = 'epfo_details',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "company_application_epfo" */
export type Company_Application_Epfo_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  epfo_details?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Company_Application_Epfo_Stddev_Fields = {
  __typename?: 'company_application_epfo_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Application_Epfo_Stddev_Pop_Fields = {
  __typename?: 'company_application_epfo_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Application_Epfo_Stddev_Samp_Fields = {
  __typename?: 'company_application_epfo_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "company_application_epfo" */
export type Company_Application_Epfo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Application_Epfo_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Application_Epfo_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  epfo_details?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Company_Application_Epfo_Sum_Fields = {
  __typename?: 'company_application_epfo_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "company_application_epfo" */
export enum Company_Application_Epfo_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EpfoDetails = 'epfo_details',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Company_Application_Epfo_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Company_Application_Epfo_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Company_Application_Epfo_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Company_Application_Epfo_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Company_Application_Epfo_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Application_Epfo_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Company_Application_Epfo_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Application_Epfo_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Application_Epfo_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Application_Epfo_Var_Pop_Fields = {
  __typename?: 'company_application_epfo_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Application_Epfo_Var_Samp_Fields = {
  __typename?: 'company_application_epfo_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Application_Epfo_Variance_Fields = {
  __typename?: 'company_application_epfo_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "company_authorized_signatory" */
export type Company_Authorized_Signatory = {
  __typename?: 'company_authorized_signatory';
  address?: Maybe<Scalars['String']>;
  /** An object relationship */
  company: Company;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  designation?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  mobile_number?: Maybe<Scalars['String']>;
  pincode?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "company_authorized_signatory" */
export type Company_Authorized_Signatory_Aggregate = {
  __typename?: 'company_authorized_signatory_aggregate';
  aggregate?: Maybe<Company_Authorized_Signatory_Aggregate_Fields>;
  nodes: Array<Company_Authorized_Signatory>;
};

/** aggregate fields of "company_authorized_signatory" */
export type Company_Authorized_Signatory_Aggregate_Fields = {
  __typename?: 'company_authorized_signatory_aggregate_fields';
  avg?: Maybe<Company_Authorized_Signatory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Authorized_Signatory_Max_Fields>;
  min?: Maybe<Company_Authorized_Signatory_Min_Fields>;
  stddev?: Maybe<Company_Authorized_Signatory_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Authorized_Signatory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Authorized_Signatory_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Authorized_Signatory_Sum_Fields>;
  var_pop?: Maybe<Company_Authorized_Signatory_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Authorized_Signatory_Var_Samp_Fields>;
  variance?: Maybe<Company_Authorized_Signatory_Variance_Fields>;
};


/** aggregate fields of "company_authorized_signatory" */
export type Company_Authorized_Signatory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Authorized_Signatory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Company_Authorized_Signatory_Avg_Fields = {
  __typename?: 'company_authorized_signatory_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company_authorized_signatory". All fields are combined with a logical 'AND'. */
export type Company_Authorized_Signatory_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Authorized_Signatory_Bool_Exp>>;
  _not?: InputMaybe<Company_Authorized_Signatory_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Authorized_Signatory_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  designation?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  mobile_number?: InputMaybe<String_Comparison_Exp>;
  pincode?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_authorized_signatory" */
export enum Company_Authorized_Signatory_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyAuthorizedSignatoryPkey = 'company_authorized_signatory_pkey'
}

/** input type for incrementing numeric columns in table "company_authorized_signatory" */
export type Company_Authorized_Signatory_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_authorized_signatory" */
export type Company_Authorized_Signatory_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  designation?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  pincode?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Company_Authorized_Signatory_Max_Fields = {
  __typename?: 'company_authorized_signatory_max_fields';
  address?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  designation?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mobile_number?: Maybe<Scalars['String']>;
  pincode?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Company_Authorized_Signatory_Min_Fields = {
  __typename?: 'company_authorized_signatory_min_fields';
  address?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  designation?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mobile_number?: Maybe<Scalars['String']>;
  pincode?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "company_authorized_signatory" */
export type Company_Authorized_Signatory_Mutation_Response = {
  __typename?: 'company_authorized_signatory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Authorized_Signatory>;
};

/** on_conflict condition type for table "company_authorized_signatory" */
export type Company_Authorized_Signatory_On_Conflict = {
  constraint: Company_Authorized_Signatory_Constraint;
  update_columns?: Array<Company_Authorized_Signatory_Update_Column>;
  where?: InputMaybe<Company_Authorized_Signatory_Bool_Exp>;
};

/** Ordering options when selecting data from "company_authorized_signatory". */
export type Company_Authorized_Signatory_Order_By = {
  address?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  designation?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mobile_number?: InputMaybe<Order_By>;
  pincode?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_authorized_signatory */
export type Company_Authorized_Signatory_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "company_authorized_signatory" */
export enum Company_Authorized_Signatory_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Designation = 'designation',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  Pincode = 'pincode',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "company_authorized_signatory" */
export type Company_Authorized_Signatory_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  designation?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  pincode?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Company_Authorized_Signatory_Stddev_Fields = {
  __typename?: 'company_authorized_signatory_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Authorized_Signatory_Stddev_Pop_Fields = {
  __typename?: 'company_authorized_signatory_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Authorized_Signatory_Stddev_Samp_Fields = {
  __typename?: 'company_authorized_signatory_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "company_authorized_signatory" */
export type Company_Authorized_Signatory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Authorized_Signatory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Authorized_Signatory_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  designation?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  pincode?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Company_Authorized_Signatory_Sum_Fields = {
  __typename?: 'company_authorized_signatory_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "company_authorized_signatory" */
export enum Company_Authorized_Signatory_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Designation = 'designation',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  Pincode = 'pincode',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Company_Authorized_Signatory_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Authorized_Signatory_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Authorized_Signatory_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Authorized_Signatory_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Authorized_Signatory_Var_Pop_Fields = {
  __typename?: 'company_authorized_signatory_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Authorized_Signatory_Var_Samp_Fields = {
  __typename?: 'company_authorized_signatory_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Authorized_Signatory_Variance_Fields = {
  __typename?: 'company_authorized_signatory_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate avg on columns */
export type Company_Avg_Fields = {
  __typename?: 'company_avg_fields';
  approved_by?: Maybe<Scalars['Float']>;
  ccs_agreement_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  estimated_arr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min_tradable_value?: Maybe<Scalars['Float']>;
  ops_rm?: Maybe<Scalars['Float']>;
  sales_rm?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
  total_customers?: Maybe<Scalars['Float']>;
  total_funding?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company". All fields are combined with a logical 'AND'. */
export type Company_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Bool_Exp>>;
  _not?: InputMaybe<Company_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Bool_Exp>>;
  accounts?: InputMaybe<Account_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Account_Aggregate_Bool_Exp>;
  active_gstin?: InputMaybe<String_Comparison_Exp>;
  address_line_1?: InputMaybe<String_Comparison_Exp>;
  agreement_signed_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  alternate_emails?: InputMaybe<String_Comparison_Exp>;
  application_lifecycle_stage?: InputMaybe<Ref_Application_Lifecycle_Stage_Enum_Comparison_Exp>;
  approved_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  approved_by?: InputMaybe<Int_Comparison_Exp>;
  authorized_signatories?: InputMaybe<Jsonb_Comparison_Exp>;
  auto_rejected?: InputMaybe<Boolean_Comparison_Exp>;
  billing_address?: InputMaybe<String_Comparison_Exp>;
  billing_gst?: InputMaybe<String_Comparison_Exp>;
  ccs_agreement_file?: InputMaybe<Agreement_Registry_Bool_Exp>;
  ccs_agreement_id?: InputMaybe<Int_Comparison_Exp>;
  cin?: InputMaybe<String_Comparison_Exp>;
  company_integrations?: InputMaybe<Company_Integration_Bool_Exp>;
  company_integrations_aggregate?: InputMaybe<Company_Integration_Aggregate_Bool_Exp>;
  company_scores?: InputMaybe<Company_Score_Bool_Exp>;
  company_scores_aggregate?: InputMaybe<Company_Score_Aggregate_Bool_Exp>;
  company_sector?: InputMaybe<String_Comparison_Exp>;
  company_type?: InputMaybe<String_Comparison_Exp>;
  company_users?: InputMaybe<Company_User_Bool_Exp>;
  company_users_aggregate?: InputMaybe<Company_User_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  crm_company_id?: InputMaybe<String_Comparison_Exp>;
  crm_contact_id?: InputMaybe<String_Comparison_Exp>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  district?: InputMaybe<String_Comparison_Exp>;
  document_checklist_categories?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
  document_checklist_categories_aggregate?: InputMaybe<Document_Checklist_Category_Aggregate_Bool_Exp>;
  document_checklist_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  estimated_arr?: InputMaybe<Numeric_Comparison_Exp>;
  gstin?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  incorporation_date?: InputMaybe<Date_Comparison_Exp>;
  industry?: InputMaybe<String_Comparison_Exp>;
  integration_auths?: InputMaybe<Integration_Auth_Bool_Exp>;
  integration_auths_aggregate?: InputMaybe<Integration_Auth_Aggregate_Bool_Exp>;
  lead_status?: InputMaybe<Company_Lead_Status_Bool_Exp>;
  lead_status_aggregate?: InputMaybe<Company_Lead_Status_Aggregate_Bool_Exp>;
  mandates?: InputMaybe<Mandate_Bool_Exp>;
  mandates_aggregate?: InputMaybe<Mandate_Aggregate_Bool_Exp>;
  marquee_customers?: InputMaybe<String_Comparison_Exp>;
  marquee_investors?: InputMaybe<Jsonb_Comparison_Exp>;
  mca_profile?: InputMaybe<Jsonb_Comparison_Exp>;
  mca_signatories?: InputMaybe<Jsonb_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  min_tradable_value?: InputMaybe<Float8_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ops_rm?: InputMaybe<Int_Comparison_Exp>;
  ops_user?: InputMaybe<User_Bool_Exp>;
  pan?: InputMaybe<String_Comparison_Exp>;
  ref_company_type?: InputMaybe<Ref_Company_Type_Bool_Exp>;
  ref_industry?: InputMaybe<Ref_Industry_Bool_Exp>;
  reject_reason?: InputMaybe<String_Comparison_Exp>;
  rejected_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  remaining_tradable_value?: InputMaybe<Float8_Comparison_Exp>;
  sales_rm?: InputMaybe<Int_Comparison_Exp>;
  sales_user?: InputMaybe<User_Bool_Exp>;
  shareholder_info?: InputMaybe<Jsonb_Comparison_Exp>;
  shareholder_info_date?: InputMaybe<Timestamp_Comparison_Exp>;
  state_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Ref_Company_Status_Enum_Comparison_Exp>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum_Comparison_Exp>;
  total_customers?: InputMaybe<Int_Comparison_Exp>;
  total_funding?: InputMaybe<Numeric_Comparison_Exp>;
  trades?: InputMaybe<Trade_Bool_Exp>;
  trades_aggregate?: InputMaybe<Trade_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
  website?: InputMaybe<String_Comparison_Exp>;
  zip_code?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "company_cin" */
export type Company_Cin = {
  __typename?: 'company_cin';
  cin: Scalars['String'];
  company_name: Scalars['String'];
};

/** aggregated selection of "company_cin" */
export type Company_Cin_Aggregate = {
  __typename?: 'company_cin_aggregate';
  aggregate?: Maybe<Company_Cin_Aggregate_Fields>;
  nodes: Array<Company_Cin>;
};

/** aggregate fields of "company_cin" */
export type Company_Cin_Aggregate_Fields = {
  __typename?: 'company_cin_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Company_Cin_Max_Fields>;
  min?: Maybe<Company_Cin_Min_Fields>;
};


/** aggregate fields of "company_cin" */
export type Company_Cin_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Cin_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "company_cin". All fields are combined with a logical 'AND'. */
export type Company_Cin_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Cin_Bool_Exp>>;
  _not?: InputMaybe<Company_Cin_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Cin_Bool_Exp>>;
  cin?: InputMaybe<String_Comparison_Exp>;
  company_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_cin" */
export enum Company_Cin_Constraint {
  /** unique or primary key constraint on columns "cin" */
  CompanyCinPkey = 'company_cin_pkey'
}

/** input type for inserting data into table "company_cin" */
export type Company_Cin_Insert_Input = {
  cin?: InputMaybe<Scalars['String']>;
  company_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Company_Cin_Max_Fields = {
  __typename?: 'company_cin_max_fields';
  cin?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Company_Cin_Min_Fields = {
  __typename?: 'company_cin_min_fields';
  cin?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "company_cin" */
export type Company_Cin_Mutation_Response = {
  __typename?: 'company_cin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Cin>;
};

/** on_conflict condition type for table "company_cin" */
export type Company_Cin_On_Conflict = {
  constraint: Company_Cin_Constraint;
  update_columns?: Array<Company_Cin_Update_Column>;
  where?: InputMaybe<Company_Cin_Bool_Exp>;
};

/** Ordering options when selecting data from "company_cin". */
export type Company_Cin_Order_By = {
  cin?: InputMaybe<Order_By>;
  company_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_cin */
export type Company_Cin_Pk_Columns_Input = {
  cin: Scalars['String'];
};

/** select columns of table "company_cin" */
export enum Company_Cin_Select_Column {
  /** column name */
  Cin = 'cin',
  /** column name */
  CompanyName = 'company_name'
}

/** input type for updating data in table "company_cin" */
export type Company_Cin_Set_Input = {
  cin?: InputMaybe<Scalars['String']>;
  company_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "company_cin" */
export type Company_Cin_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Cin_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Cin_Stream_Cursor_Value_Input = {
  cin?: InputMaybe<Scalars['String']>;
  company_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "company_cin" */
export enum Company_Cin_Update_Column {
  /** column name */
  Cin = 'cin',
  /** column name */
  CompanyName = 'company_name'
}

export type Company_Cin_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Cin_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Cin_Bool_Exp;
};

/** unique or primary key constraints on table "company" */
export enum Company_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyPkey = 'company_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Company_Delete_At_Path_Input = {
  authorized_signatories?: InputMaybe<Array<Scalars['String']>>;
  gstin?: InputMaybe<Array<Scalars['String']>>;
  marquee_investors?: InputMaybe<Array<Scalars['String']>>;
  mca_profile?: InputMaybe<Array<Scalars['String']>>;
  mca_signatories?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
  shareholder_info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Company_Delete_Elem_Input = {
  authorized_signatories?: InputMaybe<Scalars['Int']>;
  gstin?: InputMaybe<Scalars['Int']>;
  marquee_investors?: InputMaybe<Scalars['Int']>;
  mca_profile?: InputMaybe<Scalars['Int']>;
  mca_signatories?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
  shareholder_info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Company_Delete_Key_Input = {
  authorized_signatories?: InputMaybe<Scalars['String']>;
  gstin?: InputMaybe<Scalars['String']>;
  marquee_investors?: InputMaybe<Scalars['String']>;
  mca_profile?: InputMaybe<Scalars['String']>;
  mca_signatories?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  shareholder_info?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "company_email_log" */
export type Company_Email_Log = {
  __typename?: 'company_email_log';
  /** An object relationship */
  company: Company;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  /** An object relationship */
  email_event: Email_Events;
  email_event_id: Scalars['Int'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "company_email_log" */
export type Company_Email_Log_Aggregate = {
  __typename?: 'company_email_log_aggregate';
  aggregate?: Maybe<Company_Email_Log_Aggregate_Fields>;
  nodes: Array<Company_Email_Log>;
};

export type Company_Email_Log_Aggregate_Bool_Exp = {
  count?: InputMaybe<Company_Email_Log_Aggregate_Bool_Exp_Count>;
};

export type Company_Email_Log_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Company_Email_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Email_Log_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "company_email_log" */
export type Company_Email_Log_Aggregate_Fields = {
  __typename?: 'company_email_log_aggregate_fields';
  avg?: Maybe<Company_Email_Log_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Email_Log_Max_Fields>;
  min?: Maybe<Company_Email_Log_Min_Fields>;
  stddev?: Maybe<Company_Email_Log_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Email_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Email_Log_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Email_Log_Sum_Fields>;
  var_pop?: Maybe<Company_Email_Log_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Email_Log_Var_Samp_Fields>;
  variance?: Maybe<Company_Email_Log_Variance_Fields>;
};


/** aggregate fields of "company_email_log" */
export type Company_Email_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Email_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_email_log" */
export type Company_Email_Log_Aggregate_Order_By = {
  avg?: InputMaybe<Company_Email_Log_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Email_Log_Max_Order_By>;
  min?: InputMaybe<Company_Email_Log_Min_Order_By>;
  stddev?: InputMaybe<Company_Email_Log_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Company_Email_Log_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Company_Email_Log_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Company_Email_Log_Sum_Order_By>;
  var_pop?: InputMaybe<Company_Email_Log_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Company_Email_Log_Var_Samp_Order_By>;
  variance?: InputMaybe<Company_Email_Log_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "company_email_log" */
export type Company_Email_Log_Arr_Rel_Insert_Input = {
  data: Array<Company_Email_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Email_Log_On_Conflict>;
};

/** aggregate avg on columns */
export type Company_Email_Log_Avg_Fields = {
  __typename?: 'company_email_log_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  email_event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "company_email_log" */
export type Company_Email_Log_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  email_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "company_email_log". All fields are combined with a logical 'AND'. */
export type Company_Email_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Email_Log_Bool_Exp>>;
  _not?: InputMaybe<Company_Email_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Email_Log_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  email_event?: InputMaybe<Email_Events_Bool_Exp>;
  email_event_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_email_log" */
export enum Company_Email_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyEmailLogPkey = 'company_email_log_pkey'
}

/** input type for incrementing numeric columns in table "company_email_log" */
export type Company_Email_Log_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  email_event_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_email_log" */
export type Company_Email_Log_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  email_event?: InputMaybe<Email_Events_Obj_Rel_Insert_Input>;
  email_event_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Company_Email_Log_Max_Fields = {
  __typename?: 'company_email_log_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  email_event_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "company_email_log" */
export type Company_Email_Log_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  email_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Email_Log_Min_Fields = {
  __typename?: 'company_email_log_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  email_event_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "company_email_log" */
export type Company_Email_Log_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  email_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_email_log" */
export type Company_Email_Log_Mutation_Response = {
  __typename?: 'company_email_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Email_Log>;
};

/** on_conflict condition type for table "company_email_log" */
export type Company_Email_Log_On_Conflict = {
  constraint: Company_Email_Log_Constraint;
  update_columns?: Array<Company_Email_Log_Update_Column>;
  where?: InputMaybe<Company_Email_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "company_email_log". */
export type Company_Email_Log_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  email_event?: InputMaybe<Email_Events_Order_By>;
  email_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_email_log */
export type Company_Email_Log_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "company_email_log" */
export enum Company_Email_Log_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EmailEventId = 'email_event_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "company_email_log" */
export type Company_Email_Log_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  email_event_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Company_Email_Log_Stddev_Fields = {
  __typename?: 'company_email_log_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  email_event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "company_email_log" */
export type Company_Email_Log_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  email_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Company_Email_Log_Stddev_Pop_Fields = {
  __typename?: 'company_email_log_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  email_event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "company_email_log" */
export type Company_Email_Log_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  email_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Company_Email_Log_Stddev_Samp_Fields = {
  __typename?: 'company_email_log_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  email_event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "company_email_log" */
export type Company_Email_Log_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  email_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "company_email_log" */
export type Company_Email_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Email_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Email_Log_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  email_event_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Company_Email_Log_Sum_Fields = {
  __typename?: 'company_email_log_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  email_event_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "company_email_log" */
export type Company_Email_Log_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  email_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "company_email_log" */
export enum Company_Email_Log_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EmailEventId = 'email_event_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Company_Email_Log_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Email_Log_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Email_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Email_Log_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Email_Log_Var_Pop_Fields = {
  __typename?: 'company_email_log_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  email_event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "company_email_log" */
export type Company_Email_Log_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  email_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Company_Email_Log_Var_Samp_Fields = {
  __typename?: 'company_email_log_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  email_event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "company_email_log" */
export type Company_Email_Log_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  email_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Company_Email_Log_Variance_Fields = {
  __typename?: 'company_email_log_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  email_event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "company_email_log" */
export type Company_Email_Log_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  email_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "company_gst_return" */
export type Company_Gst_Return = {
  __typename?: 'company_gst_return';
  arn: Scalars['String'];
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  dof: Scalars['date'];
  dof_timestamp: Scalars['timestamptz'];
  financial_year: Scalars['String'];
  gstin: Scalars['String'];
  id: Scalars['Int'];
  is_active?: Maybe<Scalars['Boolean']>;
  is_delay: Scalars['Boolean'];
  mof: Scalars['String'];
  raw_gst_return: Scalars['jsonb'];
  ret_prd: Scalars['Int'];
  rtntype: Scalars['String'];
  status: Scalars['String'];
  tax_period: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
  valid: Scalars['String'];
};


/** columns and relationships of "company_gst_return" */
export type Company_Gst_ReturnRaw_Gst_ReturnArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "company_gst_return" */
export type Company_Gst_Return_Aggregate = {
  __typename?: 'company_gst_return_aggregate';
  aggregate?: Maybe<Company_Gst_Return_Aggregate_Fields>;
  nodes: Array<Company_Gst_Return>;
};

/** aggregate fields of "company_gst_return" */
export type Company_Gst_Return_Aggregate_Fields = {
  __typename?: 'company_gst_return_aggregate_fields';
  avg?: Maybe<Company_Gst_Return_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Gst_Return_Max_Fields>;
  min?: Maybe<Company_Gst_Return_Min_Fields>;
  stddev?: Maybe<Company_Gst_Return_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Gst_Return_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Gst_Return_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Gst_Return_Sum_Fields>;
  var_pop?: Maybe<Company_Gst_Return_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Gst_Return_Var_Samp_Fields>;
  variance?: Maybe<Company_Gst_Return_Variance_Fields>;
};


/** aggregate fields of "company_gst_return" */
export type Company_Gst_Return_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Gst_Return_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Company_Gst_Return_Append_Input = {
  raw_gst_return?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Company_Gst_Return_Avg_Fields = {
  __typename?: 'company_gst_return_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ret_prd?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company_gst_return". All fields are combined with a logical 'AND'. */
export type Company_Gst_Return_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Gst_Return_Bool_Exp>>;
  _not?: InputMaybe<Company_Gst_Return_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Gst_Return_Bool_Exp>>;
  arn?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  dof?: InputMaybe<Date_Comparison_Exp>;
  dof_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  financial_year?: InputMaybe<String_Comparison_Exp>;
  gstin?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_delay?: InputMaybe<Boolean_Comparison_Exp>;
  mof?: InputMaybe<String_Comparison_Exp>;
  raw_gst_return?: InputMaybe<Jsonb_Comparison_Exp>;
  ret_prd?: InputMaybe<Int_Comparison_Exp>;
  rtntype?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tax_period?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  valid?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_gst_return" */
export enum Company_Gst_Return_Constraint {
  /** unique or primary key constraint on columns "arn" */
  CompanyGstReturnArnKey = 'company_gst_return_arn_key',
  /** unique or primary key constraint on columns "id" */
  CompanyGstReturnPkey = 'company_gst_return_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Company_Gst_Return_Delete_At_Path_Input = {
  raw_gst_return?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Company_Gst_Return_Delete_Elem_Input = {
  raw_gst_return?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Company_Gst_Return_Delete_Key_Input = {
  raw_gst_return?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "company_gst_return" */
export type Company_Gst_Return_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ret_prd?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_gst_return" */
export type Company_Gst_Return_Insert_Input = {
  arn?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  dof?: InputMaybe<Scalars['date']>;
  dof_timestamp?: InputMaybe<Scalars['timestamptz']>;
  financial_year?: InputMaybe<Scalars['String']>;
  gstin?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_delay?: InputMaybe<Scalars['Boolean']>;
  mof?: InputMaybe<Scalars['String']>;
  raw_gst_return?: InputMaybe<Scalars['jsonb']>;
  ret_prd?: InputMaybe<Scalars['Int']>;
  rtntype?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tax_period?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  valid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Company_Gst_Return_Max_Fields = {
  __typename?: 'company_gst_return_max_fields';
  arn?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  dof?: Maybe<Scalars['date']>;
  dof_timestamp?: Maybe<Scalars['timestamptz']>;
  financial_year?: Maybe<Scalars['String']>;
  gstin?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mof?: Maybe<Scalars['String']>;
  ret_prd?: Maybe<Scalars['Int']>;
  rtntype?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tax_period?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  valid?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Company_Gst_Return_Min_Fields = {
  __typename?: 'company_gst_return_min_fields';
  arn?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  dof?: Maybe<Scalars['date']>;
  dof_timestamp?: Maybe<Scalars['timestamptz']>;
  financial_year?: Maybe<Scalars['String']>;
  gstin?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mof?: Maybe<Scalars['String']>;
  ret_prd?: Maybe<Scalars['Int']>;
  rtntype?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tax_period?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  valid?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "company_gst_return" */
export type Company_Gst_Return_Mutation_Response = {
  __typename?: 'company_gst_return_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Gst_Return>;
};

/** on_conflict condition type for table "company_gst_return" */
export type Company_Gst_Return_On_Conflict = {
  constraint: Company_Gst_Return_Constraint;
  update_columns?: Array<Company_Gst_Return_Update_Column>;
  where?: InputMaybe<Company_Gst_Return_Bool_Exp>;
};

/** Ordering options when selecting data from "company_gst_return". */
export type Company_Gst_Return_Order_By = {
  arn?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  dof?: InputMaybe<Order_By>;
  dof_timestamp?: InputMaybe<Order_By>;
  financial_year?: InputMaybe<Order_By>;
  gstin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_delay?: InputMaybe<Order_By>;
  mof?: InputMaybe<Order_By>;
  raw_gst_return?: InputMaybe<Order_By>;
  ret_prd?: InputMaybe<Order_By>;
  rtntype?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tax_period?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  valid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_gst_return */
export type Company_Gst_Return_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Company_Gst_Return_Prepend_Input = {
  raw_gst_return?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "company_gst_return" */
export enum Company_Gst_Return_Select_Column {
  /** column name */
  Arn = 'arn',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Dof = 'dof',
  /** column name */
  DofTimestamp = 'dof_timestamp',
  /** column name */
  FinancialYear = 'financial_year',
  /** column name */
  Gstin = 'gstin',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDelay = 'is_delay',
  /** column name */
  Mof = 'mof',
  /** column name */
  RawGstReturn = 'raw_gst_return',
  /** column name */
  RetPrd = 'ret_prd',
  /** column name */
  Rtntype = 'rtntype',
  /** column name */
  Status = 'status',
  /** column name */
  TaxPeriod = 'tax_period',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Valid = 'valid'
}

/** input type for updating data in table "company_gst_return" */
export type Company_Gst_Return_Set_Input = {
  arn?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  dof?: InputMaybe<Scalars['date']>;
  dof_timestamp?: InputMaybe<Scalars['timestamptz']>;
  financial_year?: InputMaybe<Scalars['String']>;
  gstin?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_delay?: InputMaybe<Scalars['Boolean']>;
  mof?: InputMaybe<Scalars['String']>;
  raw_gst_return?: InputMaybe<Scalars['jsonb']>;
  ret_prd?: InputMaybe<Scalars['Int']>;
  rtntype?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tax_period?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  valid?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Company_Gst_Return_Stddev_Fields = {
  __typename?: 'company_gst_return_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ret_prd?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Gst_Return_Stddev_Pop_Fields = {
  __typename?: 'company_gst_return_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ret_prd?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Gst_Return_Stddev_Samp_Fields = {
  __typename?: 'company_gst_return_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ret_prd?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "company_gst_return" */
export type Company_Gst_Return_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Gst_Return_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Gst_Return_Stream_Cursor_Value_Input = {
  arn?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  dof?: InputMaybe<Scalars['date']>;
  dof_timestamp?: InputMaybe<Scalars['timestamptz']>;
  financial_year?: InputMaybe<Scalars['String']>;
  gstin?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_delay?: InputMaybe<Scalars['Boolean']>;
  mof?: InputMaybe<Scalars['String']>;
  raw_gst_return?: InputMaybe<Scalars['jsonb']>;
  ret_prd?: InputMaybe<Scalars['Int']>;
  rtntype?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tax_period?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  valid?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Company_Gst_Return_Sum_Fields = {
  __typename?: 'company_gst_return_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ret_prd?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "company_gst_return" */
export enum Company_Gst_Return_Update_Column {
  /** column name */
  Arn = 'arn',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Dof = 'dof',
  /** column name */
  DofTimestamp = 'dof_timestamp',
  /** column name */
  FinancialYear = 'financial_year',
  /** column name */
  Gstin = 'gstin',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDelay = 'is_delay',
  /** column name */
  Mof = 'mof',
  /** column name */
  RawGstReturn = 'raw_gst_return',
  /** column name */
  RetPrd = 'ret_prd',
  /** column name */
  Rtntype = 'rtntype',
  /** column name */
  Status = 'status',
  /** column name */
  TaxPeriod = 'tax_period',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Valid = 'valid'
}

export type Company_Gst_Return_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Company_Gst_Return_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Company_Gst_Return_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Company_Gst_Return_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Company_Gst_Return_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Gst_Return_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Company_Gst_Return_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Gst_Return_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Gst_Return_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Gst_Return_Var_Pop_Fields = {
  __typename?: 'company_gst_return_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ret_prd?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Gst_Return_Var_Samp_Fields = {
  __typename?: 'company_gst_return_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ret_prd?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Gst_Return_Variance_Fields = {
  __typename?: 'company_gst_return_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ret_prd?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "company" */
export type Company_Inc_Input = {
  approved_by?: InputMaybe<Scalars['Int']>;
  ccs_agreement_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  estimated_arr?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  min_tradable_value?: InputMaybe<Scalars['float8']>;
  ops_rm?: InputMaybe<Scalars['Int']>;
  sales_rm?: InputMaybe<Scalars['Int']>;
  state_id?: InputMaybe<Scalars['Int']>;
  total_customers?: InputMaybe<Scalars['Int']>;
  total_funding?: InputMaybe<Scalars['numeric']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company" */
export type Company_Insert_Input = {
  accounts?: InputMaybe<Account_Arr_Rel_Insert_Input>;
  active_gstin?: InputMaybe<Scalars['String']>;
  address_line_1?: InputMaybe<Scalars['String']>;
  agreement_signed_date?: InputMaybe<Scalars['timestamptz']>;
  alternate_emails?: InputMaybe<Scalars['String']>;
  application_lifecycle_stage?: InputMaybe<Ref_Application_Lifecycle_Stage_Enum>;
  approved_at?: InputMaybe<Scalars['timestamptz']>;
  approved_by?: InputMaybe<Scalars['Int']>;
  authorized_signatories?: InputMaybe<Scalars['jsonb']>;
  auto_rejected?: InputMaybe<Scalars['Boolean']>;
  billing_address?: InputMaybe<Scalars['String']>;
  billing_gst?: InputMaybe<Scalars['String']>;
  ccs_agreement_file?: InputMaybe<Agreement_Registry_Obj_Rel_Insert_Input>;
  ccs_agreement_id?: InputMaybe<Scalars['Int']>;
  cin?: InputMaybe<Scalars['String']>;
  company_integrations?: InputMaybe<Company_Integration_Arr_Rel_Insert_Input>;
  company_scores?: InputMaybe<Company_Score_Arr_Rel_Insert_Input>;
  company_sector?: InputMaybe<Scalars['String']>;
  company_type?: InputMaybe<Scalars['String']>;
  company_users?: InputMaybe<Company_User_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  crm_company_id?: InputMaybe<Scalars['String']>;
  crm_contact_id?: InputMaybe<Scalars['String']>;
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  document_checklist_categories?: InputMaybe<Document_Checklist_Category_Arr_Rel_Insert_Input>;
  document_checklist_enabled?: InputMaybe<Scalars['Boolean']>;
  domain?: InputMaybe<Scalars['String']>;
  estimated_arr?: InputMaybe<Scalars['numeric']>;
  gstin?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  incorporation_date?: InputMaybe<Scalars['date']>;
  industry?: InputMaybe<Scalars['String']>;
  integration_auths?: InputMaybe<Integration_Auth_Arr_Rel_Insert_Input>;
  lead_status?: InputMaybe<Company_Lead_Status_Arr_Rel_Insert_Input>;
  mandates?: InputMaybe<Mandate_Arr_Rel_Insert_Input>;
  marquee_customers?: InputMaybe<Scalars['String']>;
  marquee_investors?: InputMaybe<Scalars['jsonb']>;
  mca_profile?: InputMaybe<Scalars['jsonb']>;
  mca_signatories?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  min_tradable_value?: InputMaybe<Scalars['float8']>;
  name?: InputMaybe<Scalars['String']>;
  ops_rm?: InputMaybe<Scalars['Int']>;
  ops_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  pan?: InputMaybe<Scalars['String']>;
  ref_company_type?: InputMaybe<Ref_Company_Type_Obj_Rel_Insert_Input>;
  ref_industry?: InputMaybe<Ref_Industry_Obj_Rel_Insert_Input>;
  reject_reason?: InputMaybe<Scalars['String']>;
  rejected_at?: InputMaybe<Scalars['timestamptz']>;
  sales_rm?: InputMaybe<Scalars['Int']>;
  sales_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  shareholder_info?: InputMaybe<Scalars['jsonb']>;
  shareholder_info_date?: InputMaybe<Scalars['timestamp']>;
  state_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Ref_Company_Status_Enum>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum>;
  total_customers?: InputMaybe<Scalars['Int']>;
  total_funding?: InputMaybe<Scalars['numeric']>;
  trades?: InputMaybe<Trade_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['Int']>;
  website?: InputMaybe<Scalars['String']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "company_integration" */
export type Company_Integration = {
  __typename?: 'company_integration';
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['Int']>;
  category: Scalars['String'];
  /** An object relationship */
  company: Company;
  company_id: Scalars['Int'];
  /** An array relationship */
  company_integration_files: Array<Company_Integration_File>;
  /** An aggregate relationship */
  company_integration_files_aggregate: Company_Integration_File_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  failure_reason?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  /** An object relationship */
  integration_auth?: Maybe<Integration_Auth>;
  integration_auth_id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['jsonb']>;
  progress: Scalars['float8'];
  request_id?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  start_time?: Maybe<Scalars['timestamptz']>;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "company_integration" */
export type Company_IntegrationCompany_Integration_FilesArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_File_Order_By>>;
  where?: InputMaybe<Company_Integration_File_Bool_Exp>;
};


/** columns and relationships of "company_integration" */
export type Company_IntegrationCompany_Integration_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_File_Order_By>>;
  where?: InputMaybe<Company_Integration_File_Bool_Exp>;
};


/** columns and relationships of "company_integration" */
export type Company_IntegrationFailure_ReasonArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "company_integration" */
export type Company_IntegrationMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "company_integration" */
export type Company_Integration_Aggregate = {
  __typename?: 'company_integration_aggregate';
  aggregate?: Maybe<Company_Integration_Aggregate_Fields>;
  nodes: Array<Company_Integration>;
};

export type Company_Integration_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Company_Integration_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Company_Integration_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Company_Integration_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Company_Integration_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Company_Integration_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Company_Integration_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Company_Integration_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Company_Integration_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Company_Integration_Aggregate_Bool_Exp_Var_Samp>;
};

export type Company_Integration_Aggregate_Bool_Exp_Avg = {
  arguments: Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Integration_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Company_Integration_Aggregate_Bool_Exp_Corr = {
  arguments: Company_Integration_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Integration_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Company_Integration_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Company_Integration_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Company_Integration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Integration_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Company_Integration_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Company_Integration_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Integration_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Company_Integration_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Company_Integration_Aggregate_Bool_Exp_Max = {
  arguments: Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Integration_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Company_Integration_Aggregate_Bool_Exp_Min = {
  arguments: Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Integration_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Company_Integration_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Integration_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Company_Integration_Aggregate_Bool_Exp_Sum = {
  arguments: Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Integration_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Company_Integration_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Integration_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "company_integration" */
export type Company_Integration_Aggregate_Fields = {
  __typename?: 'company_integration_aggregate_fields';
  avg?: Maybe<Company_Integration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Integration_Max_Fields>;
  min?: Maybe<Company_Integration_Min_Fields>;
  stddev?: Maybe<Company_Integration_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Integration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Integration_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Integration_Sum_Fields>;
  var_pop?: Maybe<Company_Integration_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Integration_Var_Samp_Fields>;
  variance?: Maybe<Company_Integration_Variance_Fields>;
};


/** aggregate fields of "company_integration" */
export type Company_Integration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Integration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_integration" */
export type Company_Integration_Aggregate_Order_By = {
  avg?: InputMaybe<Company_Integration_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Integration_Max_Order_By>;
  min?: InputMaybe<Company_Integration_Min_Order_By>;
  stddev?: InputMaybe<Company_Integration_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Company_Integration_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Company_Integration_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Company_Integration_Sum_Order_By>;
  var_pop?: InputMaybe<Company_Integration_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Company_Integration_Var_Samp_Order_By>;
  variance?: InputMaybe<Company_Integration_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Company_Integration_Append_Input = {
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "company_integration" */
export type Company_Integration_Arr_Rel_Insert_Input = {
  data: Array<Company_Integration_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Integration_On_Conflict>;
};

/** aggregate avg on columns */
export type Company_Integration_Avg_Fields = {
  __typename?: 'company_integration_avg_fields';
  approved_by?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  integration_auth_id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "company_integration" */
export type Company_Integration_Avg_Order_By = {
  approved_by?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_auth_id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "company_integration". All fields are combined with a logical 'AND'. */
export type Company_Integration_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Integration_Bool_Exp>>;
  _not?: InputMaybe<Company_Integration_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Integration_Bool_Exp>>;
  approved_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  approved_by?: InputMaybe<Int_Comparison_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  company_integration_files?: InputMaybe<Company_Integration_File_Bool_Exp>;
  company_integration_files_aggregate?: InputMaybe<Company_Integration_File_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  end_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  failure_reason?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  integration_auth?: InputMaybe<Integration_Auth_Bool_Exp>;
  integration_auth_id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  progress?: InputMaybe<Float8_Comparison_Exp>;
  request_id?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  start_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_integration" */
export enum Company_Integration_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyIntegrationPkey = 'company_integration_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Company_Integration_Delete_At_Path_Input = {
  failure_reason?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Company_Integration_Delete_Elem_Input = {
  failure_reason?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Company_Integration_Delete_Key_Input = {
  failure_reason?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "company_integration_file" */
export type Company_Integration_File = {
  __typename?: 'company_integration_file';
  /** An object relationship */
  company_integration: Company_Integration;
  company_integration_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  failure_reason?: Maybe<Scalars['jsonb']>;
  file_id: Scalars['Int'];
  /** An object relationship */
  file_registry: File_Registry;
  id: Scalars['Int'];
  status: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "company_integration_file" */
export type Company_Integration_FileFailure_ReasonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "company_integration_file" */
export type Company_Integration_File_Aggregate = {
  __typename?: 'company_integration_file_aggregate';
  aggregate?: Maybe<Company_Integration_File_Aggregate_Fields>;
  nodes: Array<Company_Integration_File>;
};

export type Company_Integration_File_Aggregate_Bool_Exp = {
  count?: InputMaybe<Company_Integration_File_Aggregate_Bool_Exp_Count>;
};

export type Company_Integration_File_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Company_Integration_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Integration_File_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "company_integration_file" */
export type Company_Integration_File_Aggregate_Fields = {
  __typename?: 'company_integration_file_aggregate_fields';
  avg?: Maybe<Company_Integration_File_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Integration_File_Max_Fields>;
  min?: Maybe<Company_Integration_File_Min_Fields>;
  stddev?: Maybe<Company_Integration_File_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Integration_File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Integration_File_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Integration_File_Sum_Fields>;
  var_pop?: Maybe<Company_Integration_File_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Integration_File_Var_Samp_Fields>;
  variance?: Maybe<Company_Integration_File_Variance_Fields>;
};


/** aggregate fields of "company_integration_file" */
export type Company_Integration_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Integration_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_integration_file" */
export type Company_Integration_File_Aggregate_Order_By = {
  avg?: InputMaybe<Company_Integration_File_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Integration_File_Max_Order_By>;
  min?: InputMaybe<Company_Integration_File_Min_Order_By>;
  stddev?: InputMaybe<Company_Integration_File_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Company_Integration_File_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Company_Integration_File_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Company_Integration_File_Sum_Order_By>;
  var_pop?: InputMaybe<Company_Integration_File_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Company_Integration_File_Var_Samp_Order_By>;
  variance?: InputMaybe<Company_Integration_File_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Company_Integration_File_Append_Input = {
  failure_reason?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "company_integration_file" */
export type Company_Integration_File_Arr_Rel_Insert_Input = {
  data: Array<Company_Integration_File_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Integration_File_On_Conflict>;
};

/** aggregate avg on columns */
export type Company_Integration_File_Avg_Fields = {
  __typename?: 'company_integration_file_avg_fields';
  company_integration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "company_integration_file" */
export type Company_Integration_File_Avg_Order_By = {
  company_integration_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "company_integration_file". All fields are combined with a logical 'AND'. */
export type Company_Integration_File_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Integration_File_Bool_Exp>>;
  _not?: InputMaybe<Company_Integration_File_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Integration_File_Bool_Exp>>;
  company_integration?: InputMaybe<Company_Integration_Bool_Exp>;
  company_integration_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  failure_reason?: InputMaybe<Jsonb_Comparison_Exp>;
  file_id?: InputMaybe<Int_Comparison_Exp>;
  file_registry?: InputMaybe<File_Registry_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_integration_file" */
export enum Company_Integration_File_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyIntegrationFilePkey = 'company_integration_file_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Company_Integration_File_Delete_At_Path_Input = {
  failure_reason?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Company_Integration_File_Delete_Elem_Input = {
  failure_reason?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Company_Integration_File_Delete_Key_Input = {
  failure_reason?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "company_integration_file" */
export type Company_Integration_File_Inc_Input = {
  company_integration_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_integration_file" */
export type Company_Integration_File_Insert_Input = {
  company_integration?: InputMaybe<Company_Integration_Obj_Rel_Insert_Input>;
  company_integration_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  file_id?: InputMaybe<Scalars['Int']>;
  file_registry?: InputMaybe<File_Registry_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Company_Integration_File_Max_Fields = {
  __typename?: 'company_integration_file_max_fields';
  company_integration_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "company_integration_file" */
export type Company_Integration_File_Max_Order_By = {
  company_integration_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Integration_File_Min_Fields = {
  __typename?: 'company_integration_file_min_fields';
  company_integration_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "company_integration_file" */
export type Company_Integration_File_Min_Order_By = {
  company_integration_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_integration_file" */
export type Company_Integration_File_Mutation_Response = {
  __typename?: 'company_integration_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Integration_File>;
};

/** on_conflict condition type for table "company_integration_file" */
export type Company_Integration_File_On_Conflict = {
  constraint: Company_Integration_File_Constraint;
  update_columns?: Array<Company_Integration_File_Update_Column>;
  where?: InputMaybe<Company_Integration_File_Bool_Exp>;
};

/** Ordering options when selecting data from "company_integration_file". */
export type Company_Integration_File_Order_By = {
  company_integration?: InputMaybe<Company_Integration_Order_By>;
  company_integration_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  failure_reason?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  file_registry?: InputMaybe<File_Registry_Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_integration_file */
export type Company_Integration_File_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Company_Integration_File_Prepend_Input = {
  failure_reason?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "company_integration_file" */
export enum Company_Integration_File_Select_Column {
  /** column name */
  CompanyIntegrationId = 'company_integration_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "company_integration_file" */
export type Company_Integration_File_Set_Input = {
  company_integration_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Company_Integration_File_Stddev_Fields = {
  __typename?: 'company_integration_file_stddev_fields';
  company_integration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "company_integration_file" */
export type Company_Integration_File_Stddev_Order_By = {
  company_integration_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Company_Integration_File_Stddev_Pop_Fields = {
  __typename?: 'company_integration_file_stddev_pop_fields';
  company_integration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "company_integration_file" */
export type Company_Integration_File_Stddev_Pop_Order_By = {
  company_integration_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Company_Integration_File_Stddev_Samp_Fields = {
  __typename?: 'company_integration_file_stddev_samp_fields';
  company_integration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "company_integration_file" */
export type Company_Integration_File_Stddev_Samp_Order_By = {
  company_integration_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "company_integration_file" */
export type Company_Integration_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Integration_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Integration_File_Stream_Cursor_Value_Input = {
  company_integration_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Company_Integration_File_Sum_Fields = {
  __typename?: 'company_integration_file_sum_fields';
  company_integration_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "company_integration_file" */
export type Company_Integration_File_Sum_Order_By = {
  company_integration_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "company_integration_file" */
export enum Company_Integration_File_Update_Column {
  /** column name */
  CompanyIntegrationId = 'company_integration_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Company_Integration_File_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Company_Integration_File_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Company_Integration_File_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Company_Integration_File_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Company_Integration_File_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Integration_File_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Company_Integration_File_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Integration_File_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Integration_File_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Integration_File_Var_Pop_Fields = {
  __typename?: 'company_integration_file_var_pop_fields';
  company_integration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "company_integration_file" */
export type Company_Integration_File_Var_Pop_Order_By = {
  company_integration_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Company_Integration_File_Var_Samp_Fields = {
  __typename?: 'company_integration_file_var_samp_fields';
  company_integration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "company_integration_file" */
export type Company_Integration_File_Var_Samp_Order_By = {
  company_integration_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Company_Integration_File_Variance_Fields = {
  __typename?: 'company_integration_file_variance_fields';
  company_integration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "company_integration_file" */
export type Company_Integration_File_Variance_Order_By = {
  company_integration_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "company_integration" */
export type Company_Integration_Inc_Input = {
  approved_by?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  integration_auth_id?: InputMaybe<Scalars['Int']>;
  progress?: InputMaybe<Scalars['float8']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_integration" */
export type Company_Integration_Insert_Input = {
  approved_at?: InputMaybe<Scalars['timestamptz']>;
  approved_by?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_integration_files?: InputMaybe<Company_Integration_File_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  integration_auth?: InputMaybe<Integration_Auth_Obj_Rel_Insert_Input>;
  integration_auth_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  progress?: InputMaybe<Scalars['float8']>;
  request_id?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  start_time?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Company_Integration_Max_Fields = {
  __typename?: 'company_integration_max_fields';
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  integration_auth_id?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['float8']>;
  request_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "company_integration" */
export type Company_Integration_Max_Order_By = {
  approved_at?: InputMaybe<Order_By>;
  approved_by?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_auth_id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Integration_Min_Fields = {
  __typename?: 'company_integration_min_fields';
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  integration_auth_id?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['float8']>;
  request_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "company_integration" */
export type Company_Integration_Min_Order_By = {
  approved_at?: InputMaybe<Order_By>;
  approved_by?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_auth_id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_integration" */
export type Company_Integration_Mutation_Response = {
  __typename?: 'company_integration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Integration>;
};

/** input type for inserting object relation for remote table "company_integration" */
export type Company_Integration_Obj_Rel_Insert_Input = {
  data: Company_Integration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Integration_On_Conflict>;
};

/** on_conflict condition type for table "company_integration" */
export type Company_Integration_On_Conflict = {
  constraint: Company_Integration_Constraint;
  update_columns?: Array<Company_Integration_Update_Column>;
  where?: InputMaybe<Company_Integration_Bool_Exp>;
};

/** Ordering options when selecting data from "company_integration". */
export type Company_Integration_Order_By = {
  approved_at?: InputMaybe<Order_By>;
  approved_by?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_integration_files_aggregate?: InputMaybe<Company_Integration_File_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  failure_reason?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_auth?: InputMaybe<Integration_Auth_Order_By>;
  integration_auth_id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_integration */
export type Company_Integration_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Company_Integration_Prepend_Input = {
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "company_integration" */
export enum Company_Integration_Select_Column {
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  Category = 'category',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationAuthId = 'integration_auth_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Progress = 'progress',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  Source = 'source',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "company_integration_aggregate_bool_exp_avg_arguments_columns" columns of table "company_integration" */
export enum Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Progress = 'progress'
}

/** select "company_integration_aggregate_bool_exp_corr_arguments_columns" columns of table "company_integration" */
export enum Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Progress = 'progress'
}

/** select "company_integration_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "company_integration" */
export enum Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Progress = 'progress'
}

/** select "company_integration_aggregate_bool_exp_max_arguments_columns" columns of table "company_integration" */
export enum Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Progress = 'progress'
}

/** select "company_integration_aggregate_bool_exp_min_arguments_columns" columns of table "company_integration" */
export enum Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Progress = 'progress'
}

/** select "company_integration_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "company_integration" */
export enum Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Progress = 'progress'
}

/** select "company_integration_aggregate_bool_exp_sum_arguments_columns" columns of table "company_integration" */
export enum Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Progress = 'progress'
}

/** select "company_integration_aggregate_bool_exp_var_samp_arguments_columns" columns of table "company_integration" */
export enum Company_Integration_Select_Column_Company_Integration_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Progress = 'progress'
}

/** input type for updating data in table "company_integration" */
export type Company_Integration_Set_Input = {
  approved_at?: InputMaybe<Scalars['timestamptz']>;
  approved_by?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  integration_auth_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  progress?: InputMaybe<Scalars['float8']>;
  request_id?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  start_time?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Company_Integration_Stddev_Fields = {
  __typename?: 'company_integration_stddev_fields';
  approved_by?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  integration_auth_id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "company_integration" */
export type Company_Integration_Stddev_Order_By = {
  approved_by?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_auth_id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Company_Integration_Stddev_Pop_Fields = {
  __typename?: 'company_integration_stddev_pop_fields';
  approved_by?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  integration_auth_id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "company_integration" */
export type Company_Integration_Stddev_Pop_Order_By = {
  approved_by?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_auth_id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Company_Integration_Stddev_Samp_Fields = {
  __typename?: 'company_integration_stddev_samp_fields';
  approved_by?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  integration_auth_id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "company_integration" */
export type Company_Integration_Stddev_Samp_Order_By = {
  approved_by?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_auth_id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "company_integration" */
export type Company_Integration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Integration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Integration_Stream_Cursor_Value_Input = {
  approved_at?: InputMaybe<Scalars['timestamptz']>;
  approved_by?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  integration_auth_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  progress?: InputMaybe<Scalars['float8']>;
  request_id?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  start_time?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Company_Integration_Sum_Fields = {
  __typename?: 'company_integration_sum_fields';
  approved_by?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  integration_auth_id?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['float8']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "company_integration" */
export type Company_Integration_Sum_Order_By = {
  approved_by?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_auth_id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "company_integration" */
export enum Company_Integration_Update_Column {
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  Category = 'category',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationAuthId = 'integration_auth_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Progress = 'progress',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  Source = 'source',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Company_Integration_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Company_Integration_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Company_Integration_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Company_Integration_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Company_Integration_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Integration_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Company_Integration_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Integration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Integration_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Integration_Var_Pop_Fields = {
  __typename?: 'company_integration_var_pop_fields';
  approved_by?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  integration_auth_id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "company_integration" */
export type Company_Integration_Var_Pop_Order_By = {
  approved_by?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_auth_id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Company_Integration_Var_Samp_Fields = {
  __typename?: 'company_integration_var_samp_fields';
  approved_by?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  integration_auth_id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "company_integration" */
export type Company_Integration_Var_Samp_Order_By = {
  approved_by?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_auth_id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Company_Integration_Variance_Fields = {
  __typename?: 'company_integration_variance_fields';
  approved_by?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  integration_auth_id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "company_integration" */
export type Company_Integration_Variance_Order_By = {
  approved_by?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_auth_id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "company_lead_status" */
export type Company_Lead_Status = {
  __typename?: 'company_lead_status';
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  status: Ref_Company_Lead_Status_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "company_lead_status" */
export type Company_Lead_Status_Aggregate = {
  __typename?: 'company_lead_status_aggregate';
  aggregate?: Maybe<Company_Lead_Status_Aggregate_Fields>;
  nodes: Array<Company_Lead_Status>;
};

export type Company_Lead_Status_Aggregate_Bool_Exp = {
  count?: InputMaybe<Company_Lead_Status_Aggregate_Bool_Exp_Count>;
};

export type Company_Lead_Status_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Company_Lead_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Lead_Status_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "company_lead_status" */
export type Company_Lead_Status_Aggregate_Fields = {
  __typename?: 'company_lead_status_aggregate_fields';
  avg?: Maybe<Company_Lead_Status_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Lead_Status_Max_Fields>;
  min?: Maybe<Company_Lead_Status_Min_Fields>;
  stddev?: Maybe<Company_Lead_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Lead_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Lead_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Lead_Status_Sum_Fields>;
  var_pop?: Maybe<Company_Lead_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Lead_Status_Var_Samp_Fields>;
  variance?: Maybe<Company_Lead_Status_Variance_Fields>;
};


/** aggregate fields of "company_lead_status" */
export type Company_Lead_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Lead_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_lead_status" */
export type Company_Lead_Status_Aggregate_Order_By = {
  avg?: InputMaybe<Company_Lead_Status_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Lead_Status_Max_Order_By>;
  min?: InputMaybe<Company_Lead_Status_Min_Order_By>;
  stddev?: InputMaybe<Company_Lead_Status_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Company_Lead_Status_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Company_Lead_Status_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Company_Lead_Status_Sum_Order_By>;
  var_pop?: InputMaybe<Company_Lead_Status_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Company_Lead_Status_Var_Samp_Order_By>;
  variance?: InputMaybe<Company_Lead_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "company_lead_status" */
export type Company_Lead_Status_Arr_Rel_Insert_Input = {
  data: Array<Company_Lead_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Lead_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Company_Lead_Status_Avg_Fields = {
  __typename?: 'company_lead_status_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "company_lead_status" */
export type Company_Lead_Status_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "company_lead_status". All fields are combined with a logical 'AND'. */
export type Company_Lead_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Lead_Status_Bool_Exp>>;
  _not?: InputMaybe<Company_Lead_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Lead_Status_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Ref_Company_Lead_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_lead_status" */
export enum Company_Lead_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyLeadStatusPkey = 'company_lead_status_pkey'
}

/** input type for incrementing numeric columns in table "company_lead_status" */
export type Company_Lead_Status_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_lead_status" */
export type Company_Lead_Status_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Ref_Company_Lead_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Company_Lead_Status_Max_Fields = {
  __typename?: 'company_lead_status_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "company_lead_status" */
export type Company_Lead_Status_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Lead_Status_Min_Fields = {
  __typename?: 'company_lead_status_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "company_lead_status" */
export type Company_Lead_Status_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_lead_status" */
export type Company_Lead_Status_Mutation_Response = {
  __typename?: 'company_lead_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Lead_Status>;
};

/** on_conflict condition type for table "company_lead_status" */
export type Company_Lead_Status_On_Conflict = {
  constraint: Company_Lead_Status_Constraint;
  update_columns?: Array<Company_Lead_Status_Update_Column>;
  where?: InputMaybe<Company_Lead_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "company_lead_status". */
export type Company_Lead_Status_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_lead_status */
export type Company_Lead_Status_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "company_lead_status" */
export enum Company_Lead_Status_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "company_lead_status" */
export type Company_Lead_Status_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Ref_Company_Lead_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Company_Lead_Status_Stddev_Fields = {
  __typename?: 'company_lead_status_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "company_lead_status" */
export type Company_Lead_Status_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Company_Lead_Status_Stddev_Pop_Fields = {
  __typename?: 'company_lead_status_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "company_lead_status" */
export type Company_Lead_Status_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Company_Lead_Status_Stddev_Samp_Fields = {
  __typename?: 'company_lead_status_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "company_lead_status" */
export type Company_Lead_Status_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "company_lead_status" */
export type Company_Lead_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Lead_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Lead_Status_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Ref_Company_Lead_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Company_Lead_Status_Sum_Fields = {
  __typename?: 'company_lead_status_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "company_lead_status" */
export type Company_Lead_Status_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "company_lead_status" */
export enum Company_Lead_Status_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Company_Lead_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Lead_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Lead_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Lead_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Lead_Status_Var_Pop_Fields = {
  __typename?: 'company_lead_status_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "company_lead_status" */
export type Company_Lead_Status_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Company_Lead_Status_Var_Samp_Fields = {
  __typename?: 'company_lead_status_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "company_lead_status" */
export type Company_Lead_Status_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Company_Lead_Status_Variance_Fields = {
  __typename?: 'company_lead_status_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "company_lead_status" */
export type Company_Lead_Status_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Company_Max_Fields = {
  __typename?: 'company_max_fields';
  active_gstin?: Maybe<Scalars['String']>;
  address_line_1?: Maybe<Scalars['String']>;
  agreement_signed_date?: Maybe<Scalars['timestamptz']>;
  alternate_emails?: Maybe<Scalars['String']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['Int']>;
  billing_address?: Maybe<Scalars['String']>;
  billing_gst?: Maybe<Scalars['String']>;
  ccs_agreement_id?: Maybe<Scalars['Int']>;
  cin?: Maybe<Scalars['String']>;
  company_sector?: Maybe<Scalars['String']>;
  company_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  crm_company_id?: Maybe<Scalars['String']>;
  crm_contact_id?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  estimated_arr?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  incorporation_date?: Maybe<Scalars['date']>;
  industry?: Maybe<Scalars['String']>;
  marquee_customers?: Maybe<Scalars['String']>;
  min_tradable_value?: Maybe<Scalars['float8']>;
  name?: Maybe<Scalars['String']>;
  ops_rm?: Maybe<Scalars['Int']>;
  pan?: Maybe<Scalars['String']>;
  reject_reason?: Maybe<Scalars['String']>;
  rejected_at?: Maybe<Scalars['timestamptz']>;
  sales_rm?: Maybe<Scalars['Int']>;
  shareholder_info_date?: Maybe<Scalars['timestamp']>;
  state_id?: Maybe<Scalars['Int']>;
  total_customers?: Maybe<Scalars['Int']>;
  total_funding?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Company_Min_Fields = {
  __typename?: 'company_min_fields';
  active_gstin?: Maybe<Scalars['String']>;
  address_line_1?: Maybe<Scalars['String']>;
  agreement_signed_date?: Maybe<Scalars['timestamptz']>;
  alternate_emails?: Maybe<Scalars['String']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['Int']>;
  billing_address?: Maybe<Scalars['String']>;
  billing_gst?: Maybe<Scalars['String']>;
  ccs_agreement_id?: Maybe<Scalars['Int']>;
  cin?: Maybe<Scalars['String']>;
  company_sector?: Maybe<Scalars['String']>;
  company_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  crm_company_id?: Maybe<Scalars['String']>;
  crm_contact_id?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  estimated_arr?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  incorporation_date?: Maybe<Scalars['date']>;
  industry?: Maybe<Scalars['String']>;
  marquee_customers?: Maybe<Scalars['String']>;
  min_tradable_value?: Maybe<Scalars['float8']>;
  name?: Maybe<Scalars['String']>;
  ops_rm?: Maybe<Scalars['Int']>;
  pan?: Maybe<Scalars['String']>;
  reject_reason?: Maybe<Scalars['String']>;
  rejected_at?: Maybe<Scalars['timestamptz']>;
  sales_rm?: Maybe<Scalars['Int']>;
  shareholder_info_date?: Maybe<Scalars['timestamp']>;
  state_id?: Maybe<Scalars['Int']>;
  total_customers?: Maybe<Scalars['Int']>;
  total_funding?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "company" */
export type Company_Mutation_Response = {
  __typename?: 'company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company>;
};

/** input type for inserting object relation for remote table "company" */
export type Company_Obj_Rel_Insert_Input = {
  data: Company_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

/** on_conflict condition type for table "company" */
export type Company_On_Conflict = {
  constraint: Company_Constraint;
  update_columns?: Array<Company_Update_Column>;
  where?: InputMaybe<Company_Bool_Exp>;
};

/** Ordering options when selecting data from "company". */
export type Company_Order_By = {
  accounts_aggregate?: InputMaybe<Account_Aggregate_Order_By>;
  active_gstin?: InputMaybe<Order_By>;
  address_line_1?: InputMaybe<Order_By>;
  agreement_signed_date?: InputMaybe<Order_By>;
  alternate_emails?: InputMaybe<Order_By>;
  application_lifecycle_stage?: InputMaybe<Order_By>;
  approved_at?: InputMaybe<Order_By>;
  approved_by?: InputMaybe<Order_By>;
  authorized_signatories?: InputMaybe<Order_By>;
  auto_rejected?: InputMaybe<Order_By>;
  billing_address?: InputMaybe<Order_By>;
  billing_gst?: InputMaybe<Order_By>;
  ccs_agreement_file?: InputMaybe<Agreement_Registry_Order_By>;
  ccs_agreement_id?: InputMaybe<Order_By>;
  cin?: InputMaybe<Order_By>;
  company_integrations_aggregate?: InputMaybe<Company_Integration_Aggregate_Order_By>;
  company_scores_aggregate?: InputMaybe<Company_Score_Aggregate_Order_By>;
  company_sector?: InputMaybe<Order_By>;
  company_type?: InputMaybe<Order_By>;
  company_users_aggregate?: InputMaybe<Company_User_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  crm_company_id?: InputMaybe<Order_By>;
  crm_contact_id?: InputMaybe<Order_By>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  district?: InputMaybe<Order_By>;
  document_checklist_categories_aggregate?: InputMaybe<Document_Checklist_Category_Aggregate_Order_By>;
  document_checklist_enabled?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  estimated_arr?: InputMaybe<Order_By>;
  gstin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incorporation_date?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  integration_auths_aggregate?: InputMaybe<Integration_Auth_Aggregate_Order_By>;
  lead_status_aggregate?: InputMaybe<Company_Lead_Status_Aggregate_Order_By>;
  mandates_aggregate?: InputMaybe<Mandate_Aggregate_Order_By>;
  marquee_customers?: InputMaybe<Order_By>;
  marquee_investors?: InputMaybe<Order_By>;
  mca_profile?: InputMaybe<Order_By>;
  mca_signatories?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  min_tradable_value?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ops_rm?: InputMaybe<Order_By>;
  ops_user?: InputMaybe<User_Order_By>;
  pan?: InputMaybe<Order_By>;
  ref_company_type?: InputMaybe<Ref_Company_Type_Order_By>;
  ref_industry?: InputMaybe<Ref_Industry_Order_By>;
  reject_reason?: InputMaybe<Order_By>;
  rejected_at?: InputMaybe<Order_By>;
  remaining_tradable_value?: InputMaybe<Order_By>;
  sales_rm?: InputMaybe<Order_By>;
  sales_user?: InputMaybe<User_Order_By>;
  shareholder_info?: InputMaybe<Order_By>;
  shareholder_info_date?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tenant_type?: InputMaybe<Order_By>;
  total_customers?: InputMaybe<Order_By>;
  total_funding?: InputMaybe<Order_By>;
  trades_aggregate?: InputMaybe<Trade_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
  zip_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company */
export type Company_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Company_Prepend_Input = {
  authorized_signatories?: InputMaybe<Scalars['jsonb']>;
  gstin?: InputMaybe<Scalars['jsonb']>;
  marquee_investors?: InputMaybe<Scalars['jsonb']>;
  mca_profile?: InputMaybe<Scalars['jsonb']>;
  mca_signatories?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  shareholder_info?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "company_reports" */
export type Company_Reports = {
  __typename?: 'company_reports';
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  report_event_id: Scalars['Int'];
};

/** aggregated selection of "company_reports" */
export type Company_Reports_Aggregate = {
  __typename?: 'company_reports_aggregate';
  aggregate?: Maybe<Company_Reports_Aggregate_Fields>;
  nodes: Array<Company_Reports>;
};

/** aggregate fields of "company_reports" */
export type Company_Reports_Aggregate_Fields = {
  __typename?: 'company_reports_aggregate_fields';
  avg?: Maybe<Company_Reports_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Reports_Max_Fields>;
  min?: Maybe<Company_Reports_Min_Fields>;
  stddev?: Maybe<Company_Reports_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Reports_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Reports_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Reports_Sum_Fields>;
  var_pop?: Maybe<Company_Reports_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Reports_Var_Samp_Fields>;
  variance?: Maybe<Company_Reports_Variance_Fields>;
};


/** aggregate fields of "company_reports" */
export type Company_Reports_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Reports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Company_Reports_Avg_Fields = {
  __typename?: 'company_reports_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  report_event_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company_reports". All fields are combined with a logical 'AND'. */
export type Company_Reports_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Reports_Bool_Exp>>;
  _not?: InputMaybe<Company_Reports_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Reports_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  report_event_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_reports" */
export enum Company_Reports_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyReportsPkey = 'company_reports_pkey'
}

/** input type for incrementing numeric columns in table "company_reports" */
export type Company_Reports_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  report_event_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_reports" */
export type Company_Reports_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  report_event_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Company_Reports_Max_Fields = {
  __typename?: 'company_reports_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  report_event_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Company_Reports_Min_Fields = {
  __typename?: 'company_reports_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  report_event_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "company_reports" */
export type Company_Reports_Mutation_Response = {
  __typename?: 'company_reports_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Reports>;
};

/** on_conflict condition type for table "company_reports" */
export type Company_Reports_On_Conflict = {
  constraint: Company_Reports_Constraint;
  update_columns?: Array<Company_Reports_Update_Column>;
  where?: InputMaybe<Company_Reports_Bool_Exp>;
};

/** Ordering options when selecting data from "company_reports". */
export type Company_Reports_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  report_event_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_reports */
export type Company_Reports_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "company_reports" */
export enum Company_Reports_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportEventId = 'report_event_id'
}

/** input type for updating data in table "company_reports" */
export type Company_Reports_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  report_event_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Company_Reports_Stddev_Fields = {
  __typename?: 'company_reports_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  report_event_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Reports_Stddev_Pop_Fields = {
  __typename?: 'company_reports_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  report_event_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Reports_Stddev_Samp_Fields = {
  __typename?: 'company_reports_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  report_event_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "company_reports" */
export type Company_Reports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Reports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Reports_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  report_event_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Company_Reports_Sum_Fields = {
  __typename?: 'company_reports_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  report_event_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "company_reports" */
export enum Company_Reports_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportEventId = 'report_event_id'
}

export type Company_Reports_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Reports_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Reports_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Reports_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Reports_Var_Pop_Fields = {
  __typename?: 'company_reports_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  report_event_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Reports_Var_Samp_Fields = {
  __typename?: 'company_reports_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  report_event_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Reports_Variance_Fields = {
  __typename?: 'company_reports_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  report_event_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "company_score" */
export type Company_Score = {
  __typename?: 'company_score';
  arpa?: Maybe<Scalars['float8']>;
  arr?: Maybe<Scalars['float8']>;
  business_growth_score?: Maybe<Scalars['float8']>;
  capital_efficiency?: Maybe<Scalars['numeric']>;
  capital_efficiency_score?: Maybe<Scalars['float8']>;
  churn?: Maybe<Scalars['float8']>;
  /** An object relationship */
  company: Company;
  company_id: Scalars['Int'];
  /** An array relationship */
  company_score_time_series: Array<Company_Score_Time_Series>;
  /** An aggregate relationship */
  company_score_time_series_aggregate: Company_Score_Time_Series_Aggregate;
  compliance_score?: Maybe<Scalars['float8']>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  credit_assessment_report_file_id?: Maybe<Scalars['Int']>;
  customer_retention?: Maybe<Scalars['float8']>;
  customer_value_score?: Maybe<Scalars['float8']>;
  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  dtoe_ratio?: Maybe<Scalars['float8']>;
  estimated_runway?: Maybe<Scalars['Int']>;
  facility_limit?: Maybe<Scalars['float8']>;
  /** An array relationship */
  factors: Array<Company_Score_Factor>;
  /** An aggregate relationship */
  factors_aggregate: Company_Score_Factor_Aggregate;
  financials_score?: Maybe<Scalars['float8']>;
  gross_margin?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  ltv?: Maybe<Scalars['float8']>;
  ltv_cac?: Maybe<Scalars['float8']>;
  management_score?: Maybe<Scalars['float8']>;
  max_recommended_bid?: Maybe<Scalars['float8']>;
  min_bid_price?: Maybe<Scalars['float8']>;
  min_recommended_bid?: Maybe<Scalars['float8']>;
  mrr_growth?: Maybe<Scalars['float8']>;
  net_cash_flow?: Maybe<Scalars['float8']>;
  net_profit?: Maybe<Scalars['float8']>;
  payback_period?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['float8']>;
  report_file_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  report_file_registry?: Maybe<File_Registry>;
  revenue_retention?: Maybe<Scalars['float8']>;
  scored_on: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['String']>;
};


/** columns and relationships of "company_score" */
export type Company_ScoreCompany_Score_Time_SeriesArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Time_Series_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Time_Series_Order_By>>;
  where?: InputMaybe<Company_Score_Time_Series_Bool_Exp>;
};


/** columns and relationships of "company_score" */
export type Company_ScoreCompany_Score_Time_Series_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Time_Series_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Time_Series_Order_By>>;
  where?: InputMaybe<Company_Score_Time_Series_Bool_Exp>;
};


/** columns and relationships of "company_score" */
export type Company_ScoreDealsArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** columns and relationships of "company_score" */
export type Company_ScoreDeals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** columns and relationships of "company_score" */
export type Company_ScoreFactorsArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Factor_Order_By>>;
  where?: InputMaybe<Company_Score_Factor_Bool_Exp>;
};


/** columns and relationships of "company_score" */
export type Company_ScoreFactors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Factor_Order_By>>;
  where?: InputMaybe<Company_Score_Factor_Bool_Exp>;
};

/** aggregated selection of "company_score" */
export type Company_Score_Aggregate = {
  __typename?: 'company_score_aggregate';
  aggregate?: Maybe<Company_Score_Aggregate_Fields>;
  nodes: Array<Company_Score>;
};

export type Company_Score_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Company_Score_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Company_Score_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Company_Score_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Company_Score_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Company_Score_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Company_Score_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Company_Score_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Company_Score_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Company_Score_Aggregate_Bool_Exp_Var_Samp>;
};

export type Company_Score_Aggregate_Bool_Exp_Avg = {
  arguments: Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Score_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Company_Score_Aggregate_Bool_Exp_Corr = {
  arguments: Company_Score_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Score_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Company_Score_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Company_Score_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Company_Score_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Score_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Company_Score_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Company_Score_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Score_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Company_Score_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Company_Score_Aggregate_Bool_Exp_Max = {
  arguments: Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Score_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Company_Score_Aggregate_Bool_Exp_Min = {
  arguments: Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Score_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Company_Score_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Score_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Company_Score_Aggregate_Bool_Exp_Sum = {
  arguments: Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Score_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Company_Score_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Score_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "company_score" */
export type Company_Score_Aggregate_Fields = {
  __typename?: 'company_score_aggregate_fields';
  avg?: Maybe<Company_Score_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Score_Max_Fields>;
  min?: Maybe<Company_Score_Min_Fields>;
  stddev?: Maybe<Company_Score_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Score_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Score_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Score_Sum_Fields>;
  var_pop?: Maybe<Company_Score_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Score_Var_Samp_Fields>;
  variance?: Maybe<Company_Score_Variance_Fields>;
};


/** aggregate fields of "company_score" */
export type Company_Score_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Score_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_score" */
export type Company_Score_Aggregate_Order_By = {
  avg?: InputMaybe<Company_Score_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Score_Max_Order_By>;
  min?: InputMaybe<Company_Score_Min_Order_By>;
  stddev?: InputMaybe<Company_Score_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Company_Score_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Company_Score_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Company_Score_Sum_Order_By>;
  var_pop?: InputMaybe<Company_Score_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Company_Score_Var_Samp_Order_By>;
  variance?: InputMaybe<Company_Score_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "company_score" */
export type Company_Score_Arr_Rel_Insert_Input = {
  data: Array<Company_Score_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Score_On_Conflict>;
};

/** aggregate avg on columns */
export type Company_Score_Avg_Fields = {
  __typename?: 'company_score_avg_fields';
  arpa?: Maybe<Scalars['Float']>;
  arr?: Maybe<Scalars['Float']>;
  business_growth_score?: Maybe<Scalars['Float']>;
  capital_efficiency?: Maybe<Scalars['Float']>;
  capital_efficiency_score?: Maybe<Scalars['Float']>;
  churn?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  compliance_score?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  credit_assessment_report_file_id?: Maybe<Scalars['Float']>;
  customer_retention?: Maybe<Scalars['Float']>;
  customer_value_score?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  dtoe_ratio?: Maybe<Scalars['Float']>;
  estimated_runway?: Maybe<Scalars['Float']>;
  facility_limit?: Maybe<Scalars['Float']>;
  financials_score?: Maybe<Scalars['Float']>;
  gross_margin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ltv?: Maybe<Scalars['Float']>;
  ltv_cac?: Maybe<Scalars['Float']>;
  management_score?: Maybe<Scalars['Float']>;
  max_recommended_bid?: Maybe<Scalars['Float']>;
  min_bid_price?: Maybe<Scalars['Float']>;
  min_recommended_bid?: Maybe<Scalars['Float']>;
  mrr_growth?: Maybe<Scalars['Float']>;
  net_cash_flow?: Maybe<Scalars['Float']>;
  net_profit?: Maybe<Scalars['Float']>;
  payback_period?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  report_file_id?: Maybe<Scalars['Float']>;
  revenue_retention?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "company_score" */
export type Company_Score_Avg_Order_By = {
  arpa?: InputMaybe<Order_By>;
  arr?: InputMaybe<Order_By>;
  business_growth_score?: InputMaybe<Order_By>;
  capital_efficiency?: InputMaybe<Order_By>;
  capital_efficiency_score?: InputMaybe<Order_By>;
  churn?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  compliance_score?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_assessment_report_file_id?: InputMaybe<Order_By>;
  customer_retention?: InputMaybe<Order_By>;
  customer_value_score?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  dtoe_ratio?: InputMaybe<Order_By>;
  estimated_runway?: InputMaybe<Order_By>;
  facility_limit?: InputMaybe<Order_By>;
  financials_score?: InputMaybe<Order_By>;
  gross_margin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ltv?: InputMaybe<Order_By>;
  ltv_cac?: InputMaybe<Order_By>;
  management_score?: InputMaybe<Order_By>;
  max_recommended_bid?: InputMaybe<Order_By>;
  min_bid_price?: InputMaybe<Order_By>;
  min_recommended_bid?: InputMaybe<Order_By>;
  mrr_growth?: InputMaybe<Order_By>;
  net_cash_flow?: InputMaybe<Order_By>;
  net_profit?: InputMaybe<Order_By>;
  payback_period?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  report_file_id?: InputMaybe<Order_By>;
  revenue_retention?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "company_score". All fields are combined with a logical 'AND'. */
export type Company_Score_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Score_Bool_Exp>>;
  _not?: InputMaybe<Company_Score_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Score_Bool_Exp>>;
  arpa?: InputMaybe<Float8_Comparison_Exp>;
  arr?: InputMaybe<Float8_Comparison_Exp>;
  business_growth_score?: InputMaybe<Float8_Comparison_Exp>;
  capital_efficiency?: InputMaybe<Numeric_Comparison_Exp>;
  capital_efficiency_score?: InputMaybe<Float8_Comparison_Exp>;
  churn?: InputMaybe<Float8_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  company_score_time_series?: InputMaybe<Company_Score_Time_Series_Bool_Exp>;
  company_score_time_series_aggregate?: InputMaybe<Company_Score_Time_Series_Aggregate_Bool_Exp>;
  compliance_score?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  credit_assessment_report_file_id?: InputMaybe<Int_Comparison_Exp>;
  customer_retention?: InputMaybe<Float8_Comparison_Exp>;
  customer_value_score?: InputMaybe<Float8_Comparison_Exp>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  dtoe_ratio?: InputMaybe<Float8_Comparison_Exp>;
  estimated_runway?: InputMaybe<Int_Comparison_Exp>;
  facility_limit?: InputMaybe<Float8_Comparison_Exp>;
  factors?: InputMaybe<Company_Score_Factor_Bool_Exp>;
  factors_aggregate?: InputMaybe<Company_Score_Factor_Aggregate_Bool_Exp>;
  financials_score?: InputMaybe<Float8_Comparison_Exp>;
  gross_margin?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ltv?: InputMaybe<Float8_Comparison_Exp>;
  ltv_cac?: InputMaybe<Float8_Comparison_Exp>;
  management_score?: InputMaybe<Float8_Comparison_Exp>;
  max_recommended_bid?: InputMaybe<Float8_Comparison_Exp>;
  min_bid_price?: InputMaybe<Float8_Comparison_Exp>;
  min_recommended_bid?: InputMaybe<Float8_Comparison_Exp>;
  mrr_growth?: InputMaybe<Float8_Comparison_Exp>;
  net_cash_flow?: InputMaybe<Float8_Comparison_Exp>;
  net_profit?: InputMaybe<Float8_Comparison_Exp>;
  payback_period?: InputMaybe<Int_Comparison_Exp>;
  rating?: InputMaybe<Float8_Comparison_Exp>;
  report_file_id?: InputMaybe<Int_Comparison_Exp>;
  report_file_registry?: InputMaybe<File_Registry_Bool_Exp>;
  revenue_retention?: InputMaybe<Float8_Comparison_Exp>;
  scored_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_score" */
export enum Company_Score_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyScorePkey = 'company_score_pkey'
}

/** columns and relationships of "company_score_factor" */
export type Company_Score_Factor = {
  __typename?: 'company_score_factor';
  /** An array relationship */
  all_factors: Array<Company_Score_Factor>;
  /** An aggregate relationship */
  all_factors_aggregate: Company_Score_Factor_Aggregate;
  benchmark?: Maybe<Scalars['numeric']>;
  benchmark_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  company?: Maybe<Company>;
  company_id: Scalars['Int'];
  /** An object relationship */
  company_score?: Maybe<Company_Score>;
  company_score_id: Scalars['Int'];
  /** An object relationship */
  company_score_time_series: Company_Score_Time_Series;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  score_factor: Score_Factor;
  score_factor_id: Scalars['Int'];
  time_series_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  value: Scalars['numeric'];
};


/** columns and relationships of "company_score_factor" */
export type Company_Score_FactorAll_FactorsArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Factor_Order_By>>;
  where?: InputMaybe<Company_Score_Factor_Bool_Exp>;
};


/** columns and relationships of "company_score_factor" */
export type Company_Score_FactorAll_Factors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Factor_Order_By>>;
  where?: InputMaybe<Company_Score_Factor_Bool_Exp>;
};

/** aggregated selection of "company_score_factor" */
export type Company_Score_Factor_Aggregate = {
  __typename?: 'company_score_factor_aggregate';
  aggregate?: Maybe<Company_Score_Factor_Aggregate_Fields>;
  nodes: Array<Company_Score_Factor>;
};

export type Company_Score_Factor_Aggregate_Bool_Exp = {
  count?: InputMaybe<Company_Score_Factor_Aggregate_Bool_Exp_Count>;
};

export type Company_Score_Factor_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Company_Score_Factor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Score_Factor_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "company_score_factor" */
export type Company_Score_Factor_Aggregate_Fields = {
  __typename?: 'company_score_factor_aggregate_fields';
  avg?: Maybe<Company_Score_Factor_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Score_Factor_Max_Fields>;
  min?: Maybe<Company_Score_Factor_Min_Fields>;
  stddev?: Maybe<Company_Score_Factor_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Score_Factor_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Score_Factor_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Score_Factor_Sum_Fields>;
  var_pop?: Maybe<Company_Score_Factor_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Score_Factor_Var_Samp_Fields>;
  variance?: Maybe<Company_Score_Factor_Variance_Fields>;
};


/** aggregate fields of "company_score_factor" */
export type Company_Score_Factor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Score_Factor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_score_factor" */
export type Company_Score_Factor_Aggregate_Order_By = {
  avg?: InputMaybe<Company_Score_Factor_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Score_Factor_Max_Order_By>;
  min?: InputMaybe<Company_Score_Factor_Min_Order_By>;
  stddev?: InputMaybe<Company_Score_Factor_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Company_Score_Factor_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Company_Score_Factor_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Company_Score_Factor_Sum_Order_By>;
  var_pop?: InputMaybe<Company_Score_Factor_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Company_Score_Factor_Var_Samp_Order_By>;
  variance?: InputMaybe<Company_Score_Factor_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "company_score_factor" */
export type Company_Score_Factor_Arr_Rel_Insert_Input = {
  data: Array<Company_Score_Factor_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Score_Factor_On_Conflict>;
};

/** aggregate avg on columns */
export type Company_Score_Factor_Avg_Fields = {
  __typename?: 'company_score_factor_avg_fields';
  benchmark?: Maybe<Scalars['Float']>;
  benchmark_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score_factor_id?: Maybe<Scalars['Float']>;
  time_series_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "company_score_factor" */
export type Company_Score_Factor_Avg_Order_By = {
  benchmark?: InputMaybe<Order_By>;
  benchmark_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score_factor_id?: InputMaybe<Order_By>;
  time_series_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "company_score_factor". All fields are combined with a logical 'AND'. */
export type Company_Score_Factor_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Score_Factor_Bool_Exp>>;
  _not?: InputMaybe<Company_Score_Factor_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Score_Factor_Bool_Exp>>;
  all_factors?: InputMaybe<Company_Score_Factor_Bool_Exp>;
  all_factors_aggregate?: InputMaybe<Company_Score_Factor_Aggregate_Bool_Exp>;
  benchmark?: InputMaybe<Numeric_Comparison_Exp>;
  benchmark_id?: InputMaybe<Int_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  company_score?: InputMaybe<Company_Score_Bool_Exp>;
  company_score_id?: InputMaybe<Int_Comparison_Exp>;
  company_score_time_series?: InputMaybe<Company_Score_Time_Series_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  score_factor?: InputMaybe<Score_Factor_Bool_Exp>;
  score_factor_id?: InputMaybe<Int_Comparison_Exp>;
  time_series_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_score_factor" */
export enum Company_Score_Factor_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyScoreFactorPkey = 'company_score_factor_pkey',
  /** unique or primary key constraint on columns "score_factor_id", "time_series_id" */
  CompanyScoreFactorTimeSeriesIdScoreFactorIdKey = 'company_score_factor_time_series_id_score_factor_id_key'
}

/** input type for incrementing numeric columns in table "company_score_factor" */
export type Company_Score_Factor_Inc_Input = {
  benchmark?: InputMaybe<Scalars['numeric']>;
  benchmark_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_score_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  score_factor_id?: InputMaybe<Scalars['Int']>;
  time_series_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "company_score_factor" */
export type Company_Score_Factor_Insert_Input = {
  all_factors?: InputMaybe<Company_Score_Factor_Arr_Rel_Insert_Input>;
  benchmark?: InputMaybe<Scalars['numeric']>;
  benchmark_id?: InputMaybe<Scalars['Int']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_score?: InputMaybe<Company_Score_Obj_Rel_Insert_Input>;
  company_score_id?: InputMaybe<Scalars['Int']>;
  company_score_time_series?: InputMaybe<Company_Score_Time_Series_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  score_factor?: InputMaybe<Score_Factor_Obj_Rel_Insert_Input>;
  score_factor_id?: InputMaybe<Scalars['Int']>;
  time_series_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Company_Score_Factor_Max_Fields = {
  __typename?: 'company_score_factor_max_fields';
  benchmark?: Maybe<Scalars['numeric']>;
  benchmark_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  company_score_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  score_factor_id?: Maybe<Scalars['Int']>;
  time_series_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "company_score_factor" */
export type Company_Score_Factor_Max_Order_By = {
  benchmark?: InputMaybe<Order_By>;
  benchmark_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score_factor_id?: InputMaybe<Order_By>;
  time_series_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Score_Factor_Min_Fields = {
  __typename?: 'company_score_factor_min_fields';
  benchmark?: Maybe<Scalars['numeric']>;
  benchmark_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  company_score_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  score_factor_id?: Maybe<Scalars['Int']>;
  time_series_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "company_score_factor" */
export type Company_Score_Factor_Min_Order_By = {
  benchmark?: InputMaybe<Order_By>;
  benchmark_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score_factor_id?: InputMaybe<Order_By>;
  time_series_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_score_factor" */
export type Company_Score_Factor_Mutation_Response = {
  __typename?: 'company_score_factor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Score_Factor>;
};

/** on_conflict condition type for table "company_score_factor" */
export type Company_Score_Factor_On_Conflict = {
  constraint: Company_Score_Factor_Constraint;
  update_columns?: Array<Company_Score_Factor_Update_Column>;
  where?: InputMaybe<Company_Score_Factor_Bool_Exp>;
};

/** Ordering options when selecting data from "company_score_factor". */
export type Company_Score_Factor_Order_By = {
  all_factors_aggregate?: InputMaybe<Company_Score_Factor_Aggregate_Order_By>;
  benchmark?: InputMaybe<Order_By>;
  benchmark_id?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_score?: InputMaybe<Company_Score_Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  company_score_time_series?: InputMaybe<Company_Score_Time_Series_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score_factor?: InputMaybe<Score_Factor_Order_By>;
  score_factor_id?: InputMaybe<Order_By>;
  time_series_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_score_factor */
export type Company_Score_Factor_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "company_score_factor" */
export enum Company_Score_Factor_Select_Column {
  /** column name */
  Benchmark = 'benchmark',
  /** column name */
  BenchmarkId = 'benchmark_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyScoreId = 'company_score_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  ScoreFactorId = 'score_factor_id',
  /** column name */
  TimeSeriesId = 'time_series_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "company_score_factor" */
export type Company_Score_Factor_Set_Input = {
  benchmark?: InputMaybe<Scalars['numeric']>;
  benchmark_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_score_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  score_factor_id?: InputMaybe<Scalars['Int']>;
  time_series_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Company_Score_Factor_Stddev_Fields = {
  __typename?: 'company_score_factor_stddev_fields';
  benchmark?: Maybe<Scalars['Float']>;
  benchmark_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score_factor_id?: Maybe<Scalars['Float']>;
  time_series_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "company_score_factor" */
export type Company_Score_Factor_Stddev_Order_By = {
  benchmark?: InputMaybe<Order_By>;
  benchmark_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score_factor_id?: InputMaybe<Order_By>;
  time_series_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Company_Score_Factor_Stddev_Pop_Fields = {
  __typename?: 'company_score_factor_stddev_pop_fields';
  benchmark?: Maybe<Scalars['Float']>;
  benchmark_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score_factor_id?: Maybe<Scalars['Float']>;
  time_series_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "company_score_factor" */
export type Company_Score_Factor_Stddev_Pop_Order_By = {
  benchmark?: InputMaybe<Order_By>;
  benchmark_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score_factor_id?: InputMaybe<Order_By>;
  time_series_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Company_Score_Factor_Stddev_Samp_Fields = {
  __typename?: 'company_score_factor_stddev_samp_fields';
  benchmark?: Maybe<Scalars['Float']>;
  benchmark_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score_factor_id?: Maybe<Scalars['Float']>;
  time_series_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "company_score_factor" */
export type Company_Score_Factor_Stddev_Samp_Order_By = {
  benchmark?: InputMaybe<Order_By>;
  benchmark_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score_factor_id?: InputMaybe<Order_By>;
  time_series_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "company_score_factor" */
export type Company_Score_Factor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Score_Factor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Score_Factor_Stream_Cursor_Value_Input = {
  benchmark?: InputMaybe<Scalars['numeric']>;
  benchmark_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_score_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  score_factor_id?: InputMaybe<Scalars['Int']>;
  time_series_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Company_Score_Factor_Sum_Fields = {
  __typename?: 'company_score_factor_sum_fields';
  benchmark?: Maybe<Scalars['numeric']>;
  benchmark_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  company_score_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  score_factor_id?: Maybe<Scalars['Int']>;
  time_series_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "company_score_factor" */
export type Company_Score_Factor_Sum_Order_By = {
  benchmark?: InputMaybe<Order_By>;
  benchmark_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score_factor_id?: InputMaybe<Order_By>;
  time_series_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "company_score_factor" */
export enum Company_Score_Factor_Update_Column {
  /** column name */
  Benchmark = 'benchmark',
  /** column name */
  BenchmarkId = 'benchmark_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyScoreId = 'company_score_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  ScoreFactorId = 'score_factor_id',
  /** column name */
  TimeSeriesId = 'time_series_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type Company_Score_Factor_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Score_Factor_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Score_Factor_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Score_Factor_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Score_Factor_Var_Pop_Fields = {
  __typename?: 'company_score_factor_var_pop_fields';
  benchmark?: Maybe<Scalars['Float']>;
  benchmark_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score_factor_id?: Maybe<Scalars['Float']>;
  time_series_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "company_score_factor" */
export type Company_Score_Factor_Var_Pop_Order_By = {
  benchmark?: InputMaybe<Order_By>;
  benchmark_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score_factor_id?: InputMaybe<Order_By>;
  time_series_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Company_Score_Factor_Var_Samp_Fields = {
  __typename?: 'company_score_factor_var_samp_fields';
  benchmark?: Maybe<Scalars['Float']>;
  benchmark_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score_factor_id?: Maybe<Scalars['Float']>;
  time_series_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "company_score_factor" */
export type Company_Score_Factor_Var_Samp_Order_By = {
  benchmark?: InputMaybe<Order_By>;
  benchmark_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score_factor_id?: InputMaybe<Order_By>;
  time_series_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Company_Score_Factor_Variance_Fields = {
  __typename?: 'company_score_factor_variance_fields';
  benchmark?: Maybe<Scalars['Float']>;
  benchmark_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score_factor_id?: Maybe<Scalars['Float']>;
  time_series_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "company_score_factor" */
export type Company_Score_Factor_Variance_Order_By = {
  benchmark?: InputMaybe<Order_By>;
  benchmark_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score_factor_id?: InputMaybe<Order_By>;
  time_series_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "company_score" */
export type Company_Score_Inc_Input = {
  arpa?: InputMaybe<Scalars['float8']>;
  arr?: InputMaybe<Scalars['float8']>;
  business_growth_score?: InputMaybe<Scalars['float8']>;
  capital_efficiency?: InputMaybe<Scalars['numeric']>;
  capital_efficiency_score?: InputMaybe<Scalars['float8']>;
  churn?: InputMaybe<Scalars['float8']>;
  company_id?: InputMaybe<Scalars['Int']>;
  compliance_score?: InputMaybe<Scalars['float8']>;
  created_by?: InputMaybe<Scalars['Int']>;
  credit_assessment_report_file_id?: InputMaybe<Scalars['Int']>;
  customer_retention?: InputMaybe<Scalars['float8']>;
  customer_value_score?: InputMaybe<Scalars['float8']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  dtoe_ratio?: InputMaybe<Scalars['float8']>;
  estimated_runway?: InputMaybe<Scalars['Int']>;
  facility_limit?: InputMaybe<Scalars['float8']>;
  financials_score?: InputMaybe<Scalars['float8']>;
  gross_margin?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  ltv?: InputMaybe<Scalars['float8']>;
  ltv_cac?: InputMaybe<Scalars['float8']>;
  management_score?: InputMaybe<Scalars['float8']>;
  max_recommended_bid?: InputMaybe<Scalars['float8']>;
  min_bid_price?: InputMaybe<Scalars['float8']>;
  min_recommended_bid?: InputMaybe<Scalars['float8']>;
  mrr_growth?: InputMaybe<Scalars['float8']>;
  net_cash_flow?: InputMaybe<Scalars['float8']>;
  net_profit?: InputMaybe<Scalars['float8']>;
  payback_period?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['float8']>;
  report_file_id?: InputMaybe<Scalars['Int']>;
  revenue_retention?: InputMaybe<Scalars['float8']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_score" */
export type Company_Score_Insert_Input = {
  arpa?: InputMaybe<Scalars['float8']>;
  arr?: InputMaybe<Scalars['float8']>;
  business_growth_score?: InputMaybe<Scalars['float8']>;
  capital_efficiency?: InputMaybe<Scalars['numeric']>;
  capital_efficiency_score?: InputMaybe<Scalars['float8']>;
  churn?: InputMaybe<Scalars['float8']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_score_time_series?: InputMaybe<Company_Score_Time_Series_Arr_Rel_Insert_Input>;
  compliance_score?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  credit_assessment_report_file_id?: InputMaybe<Scalars['Int']>;
  customer_retention?: InputMaybe<Scalars['float8']>;
  customer_value_score?: InputMaybe<Scalars['float8']>;
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  dtoe_ratio?: InputMaybe<Scalars['float8']>;
  estimated_runway?: InputMaybe<Scalars['Int']>;
  facility_limit?: InputMaybe<Scalars['float8']>;
  factors?: InputMaybe<Company_Score_Factor_Arr_Rel_Insert_Input>;
  financials_score?: InputMaybe<Scalars['float8']>;
  gross_margin?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  ltv?: InputMaybe<Scalars['float8']>;
  ltv_cac?: InputMaybe<Scalars['float8']>;
  management_score?: InputMaybe<Scalars['float8']>;
  max_recommended_bid?: InputMaybe<Scalars['float8']>;
  min_bid_price?: InputMaybe<Scalars['float8']>;
  min_recommended_bid?: InputMaybe<Scalars['float8']>;
  mrr_growth?: InputMaybe<Scalars['float8']>;
  net_cash_flow?: InputMaybe<Scalars['float8']>;
  net_profit?: InputMaybe<Scalars['float8']>;
  payback_period?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['float8']>;
  report_file_id?: InputMaybe<Scalars['Int']>;
  report_file_registry?: InputMaybe<File_Registry_Obj_Rel_Insert_Input>;
  revenue_retention?: InputMaybe<Scalars['float8']>;
  scored_on?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Company_Score_Max_Fields = {
  __typename?: 'company_score_max_fields';
  arpa?: Maybe<Scalars['float8']>;
  arr?: Maybe<Scalars['float8']>;
  business_growth_score?: Maybe<Scalars['float8']>;
  capital_efficiency?: Maybe<Scalars['numeric']>;
  capital_efficiency_score?: Maybe<Scalars['float8']>;
  churn?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  compliance_score?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  credit_assessment_report_file_id?: Maybe<Scalars['Int']>;
  customer_retention?: Maybe<Scalars['float8']>;
  customer_value_score?: Maybe<Scalars['float8']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  dtoe_ratio?: Maybe<Scalars['float8']>;
  estimated_runway?: Maybe<Scalars['Int']>;
  facility_limit?: Maybe<Scalars['float8']>;
  financials_score?: Maybe<Scalars['float8']>;
  gross_margin?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  ltv?: Maybe<Scalars['float8']>;
  ltv_cac?: Maybe<Scalars['float8']>;
  management_score?: Maybe<Scalars['float8']>;
  max_recommended_bid?: Maybe<Scalars['float8']>;
  min_bid_price?: Maybe<Scalars['float8']>;
  min_recommended_bid?: Maybe<Scalars['float8']>;
  mrr_growth?: Maybe<Scalars['float8']>;
  net_cash_flow?: Maybe<Scalars['float8']>;
  net_profit?: Maybe<Scalars['float8']>;
  payback_period?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['float8']>;
  report_file_id?: Maybe<Scalars['Int']>;
  revenue_retention?: Maybe<Scalars['float8']>;
  scored_on?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "company_score" */
export type Company_Score_Max_Order_By = {
  arpa?: InputMaybe<Order_By>;
  arr?: InputMaybe<Order_By>;
  business_growth_score?: InputMaybe<Order_By>;
  capital_efficiency?: InputMaybe<Order_By>;
  capital_efficiency_score?: InputMaybe<Order_By>;
  churn?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  compliance_score?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_assessment_report_file_id?: InputMaybe<Order_By>;
  customer_retention?: InputMaybe<Order_By>;
  customer_value_score?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  dtoe_ratio?: InputMaybe<Order_By>;
  estimated_runway?: InputMaybe<Order_By>;
  facility_limit?: InputMaybe<Order_By>;
  financials_score?: InputMaybe<Order_By>;
  gross_margin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ltv?: InputMaybe<Order_By>;
  ltv_cac?: InputMaybe<Order_By>;
  management_score?: InputMaybe<Order_By>;
  max_recommended_bid?: InputMaybe<Order_By>;
  min_bid_price?: InputMaybe<Order_By>;
  min_recommended_bid?: InputMaybe<Order_By>;
  mrr_growth?: InputMaybe<Order_By>;
  net_cash_flow?: InputMaybe<Order_By>;
  net_profit?: InputMaybe<Order_By>;
  payback_period?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  report_file_id?: InputMaybe<Order_By>;
  revenue_retention?: InputMaybe<Order_By>;
  scored_on?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Score_Min_Fields = {
  __typename?: 'company_score_min_fields';
  arpa?: Maybe<Scalars['float8']>;
  arr?: Maybe<Scalars['float8']>;
  business_growth_score?: Maybe<Scalars['float8']>;
  capital_efficiency?: Maybe<Scalars['numeric']>;
  capital_efficiency_score?: Maybe<Scalars['float8']>;
  churn?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  compliance_score?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  credit_assessment_report_file_id?: Maybe<Scalars['Int']>;
  customer_retention?: Maybe<Scalars['float8']>;
  customer_value_score?: Maybe<Scalars['float8']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  dtoe_ratio?: Maybe<Scalars['float8']>;
  estimated_runway?: Maybe<Scalars['Int']>;
  facility_limit?: Maybe<Scalars['float8']>;
  financials_score?: Maybe<Scalars['float8']>;
  gross_margin?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  ltv?: Maybe<Scalars['float8']>;
  ltv_cac?: Maybe<Scalars['float8']>;
  management_score?: Maybe<Scalars['float8']>;
  max_recommended_bid?: Maybe<Scalars['float8']>;
  min_bid_price?: Maybe<Scalars['float8']>;
  min_recommended_bid?: Maybe<Scalars['float8']>;
  mrr_growth?: Maybe<Scalars['float8']>;
  net_cash_flow?: Maybe<Scalars['float8']>;
  net_profit?: Maybe<Scalars['float8']>;
  payback_period?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['float8']>;
  report_file_id?: Maybe<Scalars['Int']>;
  revenue_retention?: Maybe<Scalars['float8']>;
  scored_on?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "company_score" */
export type Company_Score_Min_Order_By = {
  arpa?: InputMaybe<Order_By>;
  arr?: InputMaybe<Order_By>;
  business_growth_score?: InputMaybe<Order_By>;
  capital_efficiency?: InputMaybe<Order_By>;
  capital_efficiency_score?: InputMaybe<Order_By>;
  churn?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  compliance_score?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_assessment_report_file_id?: InputMaybe<Order_By>;
  customer_retention?: InputMaybe<Order_By>;
  customer_value_score?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  dtoe_ratio?: InputMaybe<Order_By>;
  estimated_runway?: InputMaybe<Order_By>;
  facility_limit?: InputMaybe<Order_By>;
  financials_score?: InputMaybe<Order_By>;
  gross_margin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ltv?: InputMaybe<Order_By>;
  ltv_cac?: InputMaybe<Order_By>;
  management_score?: InputMaybe<Order_By>;
  max_recommended_bid?: InputMaybe<Order_By>;
  min_bid_price?: InputMaybe<Order_By>;
  min_recommended_bid?: InputMaybe<Order_By>;
  mrr_growth?: InputMaybe<Order_By>;
  net_cash_flow?: InputMaybe<Order_By>;
  net_profit?: InputMaybe<Order_By>;
  payback_period?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  report_file_id?: InputMaybe<Order_By>;
  revenue_retention?: InputMaybe<Order_By>;
  scored_on?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_score" */
export type Company_Score_Mutation_Response = {
  __typename?: 'company_score_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Score>;
};

/** input type for inserting object relation for remote table "company_score" */
export type Company_Score_Obj_Rel_Insert_Input = {
  data: Company_Score_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Score_On_Conflict>;
};

/** on_conflict condition type for table "company_score" */
export type Company_Score_On_Conflict = {
  constraint: Company_Score_Constraint;
  update_columns?: Array<Company_Score_Update_Column>;
  where?: InputMaybe<Company_Score_Bool_Exp>;
};

/** Ordering options when selecting data from "company_score". */
export type Company_Score_Order_By = {
  arpa?: InputMaybe<Order_By>;
  arr?: InputMaybe<Order_By>;
  business_growth_score?: InputMaybe<Order_By>;
  capital_efficiency?: InputMaybe<Order_By>;
  capital_efficiency_score?: InputMaybe<Order_By>;
  churn?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_score_time_series_aggregate?: InputMaybe<Company_Score_Time_Series_Aggregate_Order_By>;
  compliance_score?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_assessment_report_file_id?: InputMaybe<Order_By>;
  customer_retention?: InputMaybe<Order_By>;
  customer_value_score?: InputMaybe<Order_By>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  dtoe_ratio?: InputMaybe<Order_By>;
  estimated_runway?: InputMaybe<Order_By>;
  facility_limit?: InputMaybe<Order_By>;
  factors_aggregate?: InputMaybe<Company_Score_Factor_Aggregate_Order_By>;
  financials_score?: InputMaybe<Order_By>;
  gross_margin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ltv?: InputMaybe<Order_By>;
  ltv_cac?: InputMaybe<Order_By>;
  management_score?: InputMaybe<Order_By>;
  max_recommended_bid?: InputMaybe<Order_By>;
  min_bid_price?: InputMaybe<Order_By>;
  min_recommended_bid?: InputMaybe<Order_By>;
  mrr_growth?: InputMaybe<Order_By>;
  net_cash_flow?: InputMaybe<Order_By>;
  net_profit?: InputMaybe<Order_By>;
  payback_period?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  report_file_id?: InputMaybe<Order_By>;
  report_file_registry?: InputMaybe<File_Registry_Order_By>;
  revenue_retention?: InputMaybe<Order_By>;
  scored_on?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_score */
export type Company_Score_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "company_score" */
export enum Company_Score_Select_Column {
  /** column name */
  Arpa = 'arpa',
  /** column name */
  Arr = 'arr',
  /** column name */
  BusinessGrowthScore = 'business_growth_score',
  /** column name */
  CapitalEfficiency = 'capital_efficiency',
  /** column name */
  CapitalEfficiencyScore = 'capital_efficiency_score',
  /** column name */
  Churn = 'churn',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ComplianceScore = 'compliance_score',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditAssessmentReportFileId = 'credit_assessment_report_file_id',
  /** column name */
  CustomerRetention = 'customer_retention',
  /** column name */
  CustomerValueScore = 'customer_value_score',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  DtoeRatio = 'dtoe_ratio',
  /** column name */
  EstimatedRunway = 'estimated_runway',
  /** column name */
  FacilityLimit = 'facility_limit',
  /** column name */
  FinancialsScore = 'financials_score',
  /** column name */
  GrossMargin = 'gross_margin',
  /** column name */
  Id = 'id',
  /** column name */
  Ltv = 'ltv',
  /** column name */
  LtvCac = 'ltv_cac',
  /** column name */
  ManagementScore = 'management_score',
  /** column name */
  MaxRecommendedBid = 'max_recommended_bid',
  /** column name */
  MinBidPrice = 'min_bid_price',
  /** column name */
  MinRecommendedBid = 'min_recommended_bid',
  /** column name */
  MrrGrowth = 'mrr_growth',
  /** column name */
  NetCashFlow = 'net_cash_flow',
  /** column name */
  NetProfit = 'net_profit',
  /** column name */
  PaybackPeriod = 'payback_period',
  /** column name */
  Rating = 'rating',
  /** column name */
  ReportFileId = 'report_file_id',
  /** column name */
  RevenueRetention = 'revenue_retention',
  /** column name */
  ScoredOn = 'scored_on',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Version = 'version'
}

/** select "company_score_aggregate_bool_exp_avg_arguments_columns" columns of table "company_score" */
export enum Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Arpa = 'arpa',
  /** column name */
  Arr = 'arr',
  /** column name */
  BusinessGrowthScore = 'business_growth_score',
  /** column name */
  CapitalEfficiencyScore = 'capital_efficiency_score',
  /** column name */
  Churn = 'churn',
  /** column name */
  ComplianceScore = 'compliance_score',
  /** column name */
  CustomerRetention = 'customer_retention',
  /** column name */
  CustomerValueScore = 'customer_value_score',
  /** column name */
  DtoeRatio = 'dtoe_ratio',
  /** column name */
  FacilityLimit = 'facility_limit',
  /** column name */
  FinancialsScore = 'financials_score',
  /** column name */
  GrossMargin = 'gross_margin',
  /** column name */
  Ltv = 'ltv',
  /** column name */
  LtvCac = 'ltv_cac',
  /** column name */
  ManagementScore = 'management_score',
  /** column name */
  MaxRecommendedBid = 'max_recommended_bid',
  /** column name */
  MinBidPrice = 'min_bid_price',
  /** column name */
  MinRecommendedBid = 'min_recommended_bid',
  /** column name */
  MrrGrowth = 'mrr_growth',
  /** column name */
  NetCashFlow = 'net_cash_flow',
  /** column name */
  NetProfit = 'net_profit',
  /** column name */
  Rating = 'rating',
  /** column name */
  RevenueRetention = 'revenue_retention'
}

/** select "company_score_aggregate_bool_exp_corr_arguments_columns" columns of table "company_score" */
export enum Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Arpa = 'arpa',
  /** column name */
  Arr = 'arr',
  /** column name */
  BusinessGrowthScore = 'business_growth_score',
  /** column name */
  CapitalEfficiencyScore = 'capital_efficiency_score',
  /** column name */
  Churn = 'churn',
  /** column name */
  ComplianceScore = 'compliance_score',
  /** column name */
  CustomerRetention = 'customer_retention',
  /** column name */
  CustomerValueScore = 'customer_value_score',
  /** column name */
  DtoeRatio = 'dtoe_ratio',
  /** column name */
  FacilityLimit = 'facility_limit',
  /** column name */
  FinancialsScore = 'financials_score',
  /** column name */
  GrossMargin = 'gross_margin',
  /** column name */
  Ltv = 'ltv',
  /** column name */
  LtvCac = 'ltv_cac',
  /** column name */
  ManagementScore = 'management_score',
  /** column name */
  MaxRecommendedBid = 'max_recommended_bid',
  /** column name */
  MinBidPrice = 'min_bid_price',
  /** column name */
  MinRecommendedBid = 'min_recommended_bid',
  /** column name */
  MrrGrowth = 'mrr_growth',
  /** column name */
  NetCashFlow = 'net_cash_flow',
  /** column name */
  NetProfit = 'net_profit',
  /** column name */
  Rating = 'rating',
  /** column name */
  RevenueRetention = 'revenue_retention'
}

/** select "company_score_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "company_score" */
export enum Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Arpa = 'arpa',
  /** column name */
  Arr = 'arr',
  /** column name */
  BusinessGrowthScore = 'business_growth_score',
  /** column name */
  CapitalEfficiencyScore = 'capital_efficiency_score',
  /** column name */
  Churn = 'churn',
  /** column name */
  ComplianceScore = 'compliance_score',
  /** column name */
  CustomerRetention = 'customer_retention',
  /** column name */
  CustomerValueScore = 'customer_value_score',
  /** column name */
  DtoeRatio = 'dtoe_ratio',
  /** column name */
  FacilityLimit = 'facility_limit',
  /** column name */
  FinancialsScore = 'financials_score',
  /** column name */
  GrossMargin = 'gross_margin',
  /** column name */
  Ltv = 'ltv',
  /** column name */
  LtvCac = 'ltv_cac',
  /** column name */
  ManagementScore = 'management_score',
  /** column name */
  MaxRecommendedBid = 'max_recommended_bid',
  /** column name */
  MinBidPrice = 'min_bid_price',
  /** column name */
  MinRecommendedBid = 'min_recommended_bid',
  /** column name */
  MrrGrowth = 'mrr_growth',
  /** column name */
  NetCashFlow = 'net_cash_flow',
  /** column name */
  NetProfit = 'net_profit',
  /** column name */
  Rating = 'rating',
  /** column name */
  RevenueRetention = 'revenue_retention'
}

/** select "company_score_aggregate_bool_exp_max_arguments_columns" columns of table "company_score" */
export enum Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Arpa = 'arpa',
  /** column name */
  Arr = 'arr',
  /** column name */
  BusinessGrowthScore = 'business_growth_score',
  /** column name */
  CapitalEfficiencyScore = 'capital_efficiency_score',
  /** column name */
  Churn = 'churn',
  /** column name */
  ComplianceScore = 'compliance_score',
  /** column name */
  CustomerRetention = 'customer_retention',
  /** column name */
  CustomerValueScore = 'customer_value_score',
  /** column name */
  DtoeRatio = 'dtoe_ratio',
  /** column name */
  FacilityLimit = 'facility_limit',
  /** column name */
  FinancialsScore = 'financials_score',
  /** column name */
  GrossMargin = 'gross_margin',
  /** column name */
  Ltv = 'ltv',
  /** column name */
  LtvCac = 'ltv_cac',
  /** column name */
  ManagementScore = 'management_score',
  /** column name */
  MaxRecommendedBid = 'max_recommended_bid',
  /** column name */
  MinBidPrice = 'min_bid_price',
  /** column name */
  MinRecommendedBid = 'min_recommended_bid',
  /** column name */
  MrrGrowth = 'mrr_growth',
  /** column name */
  NetCashFlow = 'net_cash_flow',
  /** column name */
  NetProfit = 'net_profit',
  /** column name */
  Rating = 'rating',
  /** column name */
  RevenueRetention = 'revenue_retention'
}

/** select "company_score_aggregate_bool_exp_min_arguments_columns" columns of table "company_score" */
export enum Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Arpa = 'arpa',
  /** column name */
  Arr = 'arr',
  /** column name */
  BusinessGrowthScore = 'business_growth_score',
  /** column name */
  CapitalEfficiencyScore = 'capital_efficiency_score',
  /** column name */
  Churn = 'churn',
  /** column name */
  ComplianceScore = 'compliance_score',
  /** column name */
  CustomerRetention = 'customer_retention',
  /** column name */
  CustomerValueScore = 'customer_value_score',
  /** column name */
  DtoeRatio = 'dtoe_ratio',
  /** column name */
  FacilityLimit = 'facility_limit',
  /** column name */
  FinancialsScore = 'financials_score',
  /** column name */
  GrossMargin = 'gross_margin',
  /** column name */
  Ltv = 'ltv',
  /** column name */
  LtvCac = 'ltv_cac',
  /** column name */
  ManagementScore = 'management_score',
  /** column name */
  MaxRecommendedBid = 'max_recommended_bid',
  /** column name */
  MinBidPrice = 'min_bid_price',
  /** column name */
  MinRecommendedBid = 'min_recommended_bid',
  /** column name */
  MrrGrowth = 'mrr_growth',
  /** column name */
  NetCashFlow = 'net_cash_flow',
  /** column name */
  NetProfit = 'net_profit',
  /** column name */
  Rating = 'rating',
  /** column name */
  RevenueRetention = 'revenue_retention'
}

/** select "company_score_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "company_score" */
export enum Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Arpa = 'arpa',
  /** column name */
  Arr = 'arr',
  /** column name */
  BusinessGrowthScore = 'business_growth_score',
  /** column name */
  CapitalEfficiencyScore = 'capital_efficiency_score',
  /** column name */
  Churn = 'churn',
  /** column name */
  ComplianceScore = 'compliance_score',
  /** column name */
  CustomerRetention = 'customer_retention',
  /** column name */
  CustomerValueScore = 'customer_value_score',
  /** column name */
  DtoeRatio = 'dtoe_ratio',
  /** column name */
  FacilityLimit = 'facility_limit',
  /** column name */
  FinancialsScore = 'financials_score',
  /** column name */
  GrossMargin = 'gross_margin',
  /** column name */
  Ltv = 'ltv',
  /** column name */
  LtvCac = 'ltv_cac',
  /** column name */
  ManagementScore = 'management_score',
  /** column name */
  MaxRecommendedBid = 'max_recommended_bid',
  /** column name */
  MinBidPrice = 'min_bid_price',
  /** column name */
  MinRecommendedBid = 'min_recommended_bid',
  /** column name */
  MrrGrowth = 'mrr_growth',
  /** column name */
  NetCashFlow = 'net_cash_flow',
  /** column name */
  NetProfit = 'net_profit',
  /** column name */
  Rating = 'rating',
  /** column name */
  RevenueRetention = 'revenue_retention'
}

/** select "company_score_aggregate_bool_exp_sum_arguments_columns" columns of table "company_score" */
export enum Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Arpa = 'arpa',
  /** column name */
  Arr = 'arr',
  /** column name */
  BusinessGrowthScore = 'business_growth_score',
  /** column name */
  CapitalEfficiencyScore = 'capital_efficiency_score',
  /** column name */
  Churn = 'churn',
  /** column name */
  ComplianceScore = 'compliance_score',
  /** column name */
  CustomerRetention = 'customer_retention',
  /** column name */
  CustomerValueScore = 'customer_value_score',
  /** column name */
  DtoeRatio = 'dtoe_ratio',
  /** column name */
  FacilityLimit = 'facility_limit',
  /** column name */
  FinancialsScore = 'financials_score',
  /** column name */
  GrossMargin = 'gross_margin',
  /** column name */
  Ltv = 'ltv',
  /** column name */
  LtvCac = 'ltv_cac',
  /** column name */
  ManagementScore = 'management_score',
  /** column name */
  MaxRecommendedBid = 'max_recommended_bid',
  /** column name */
  MinBidPrice = 'min_bid_price',
  /** column name */
  MinRecommendedBid = 'min_recommended_bid',
  /** column name */
  MrrGrowth = 'mrr_growth',
  /** column name */
  NetCashFlow = 'net_cash_flow',
  /** column name */
  NetProfit = 'net_profit',
  /** column name */
  Rating = 'rating',
  /** column name */
  RevenueRetention = 'revenue_retention'
}

/** select "company_score_aggregate_bool_exp_var_samp_arguments_columns" columns of table "company_score" */
export enum Company_Score_Select_Column_Company_Score_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Arpa = 'arpa',
  /** column name */
  Arr = 'arr',
  /** column name */
  BusinessGrowthScore = 'business_growth_score',
  /** column name */
  CapitalEfficiencyScore = 'capital_efficiency_score',
  /** column name */
  Churn = 'churn',
  /** column name */
  ComplianceScore = 'compliance_score',
  /** column name */
  CustomerRetention = 'customer_retention',
  /** column name */
  CustomerValueScore = 'customer_value_score',
  /** column name */
  DtoeRatio = 'dtoe_ratio',
  /** column name */
  FacilityLimit = 'facility_limit',
  /** column name */
  FinancialsScore = 'financials_score',
  /** column name */
  GrossMargin = 'gross_margin',
  /** column name */
  Ltv = 'ltv',
  /** column name */
  LtvCac = 'ltv_cac',
  /** column name */
  ManagementScore = 'management_score',
  /** column name */
  MaxRecommendedBid = 'max_recommended_bid',
  /** column name */
  MinBidPrice = 'min_bid_price',
  /** column name */
  MinRecommendedBid = 'min_recommended_bid',
  /** column name */
  MrrGrowth = 'mrr_growth',
  /** column name */
  NetCashFlow = 'net_cash_flow',
  /** column name */
  NetProfit = 'net_profit',
  /** column name */
  Rating = 'rating',
  /** column name */
  RevenueRetention = 'revenue_retention'
}

/** input type for updating data in table "company_score" */
export type Company_Score_Set_Input = {
  arpa?: InputMaybe<Scalars['float8']>;
  arr?: InputMaybe<Scalars['float8']>;
  business_growth_score?: InputMaybe<Scalars['float8']>;
  capital_efficiency?: InputMaybe<Scalars['numeric']>;
  capital_efficiency_score?: InputMaybe<Scalars['float8']>;
  churn?: InputMaybe<Scalars['float8']>;
  company_id?: InputMaybe<Scalars['Int']>;
  compliance_score?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  credit_assessment_report_file_id?: InputMaybe<Scalars['Int']>;
  customer_retention?: InputMaybe<Scalars['float8']>;
  customer_value_score?: InputMaybe<Scalars['float8']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  dtoe_ratio?: InputMaybe<Scalars['float8']>;
  estimated_runway?: InputMaybe<Scalars['Int']>;
  facility_limit?: InputMaybe<Scalars['float8']>;
  financials_score?: InputMaybe<Scalars['float8']>;
  gross_margin?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  ltv?: InputMaybe<Scalars['float8']>;
  ltv_cac?: InputMaybe<Scalars['float8']>;
  management_score?: InputMaybe<Scalars['float8']>;
  max_recommended_bid?: InputMaybe<Scalars['float8']>;
  min_bid_price?: InputMaybe<Scalars['float8']>;
  min_recommended_bid?: InputMaybe<Scalars['float8']>;
  mrr_growth?: InputMaybe<Scalars['float8']>;
  net_cash_flow?: InputMaybe<Scalars['float8']>;
  net_profit?: InputMaybe<Scalars['float8']>;
  payback_period?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['float8']>;
  report_file_id?: InputMaybe<Scalars['Int']>;
  revenue_retention?: InputMaybe<Scalars['float8']>;
  scored_on?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Company_Score_Stddev_Fields = {
  __typename?: 'company_score_stddev_fields';
  arpa?: Maybe<Scalars['Float']>;
  arr?: Maybe<Scalars['Float']>;
  business_growth_score?: Maybe<Scalars['Float']>;
  capital_efficiency?: Maybe<Scalars['Float']>;
  capital_efficiency_score?: Maybe<Scalars['Float']>;
  churn?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  compliance_score?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  credit_assessment_report_file_id?: Maybe<Scalars['Float']>;
  customer_retention?: Maybe<Scalars['Float']>;
  customer_value_score?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  dtoe_ratio?: Maybe<Scalars['Float']>;
  estimated_runway?: Maybe<Scalars['Float']>;
  facility_limit?: Maybe<Scalars['Float']>;
  financials_score?: Maybe<Scalars['Float']>;
  gross_margin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ltv?: Maybe<Scalars['Float']>;
  ltv_cac?: Maybe<Scalars['Float']>;
  management_score?: Maybe<Scalars['Float']>;
  max_recommended_bid?: Maybe<Scalars['Float']>;
  min_bid_price?: Maybe<Scalars['Float']>;
  min_recommended_bid?: Maybe<Scalars['Float']>;
  mrr_growth?: Maybe<Scalars['Float']>;
  net_cash_flow?: Maybe<Scalars['Float']>;
  net_profit?: Maybe<Scalars['Float']>;
  payback_period?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  report_file_id?: Maybe<Scalars['Float']>;
  revenue_retention?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "company_score" */
export type Company_Score_Stddev_Order_By = {
  arpa?: InputMaybe<Order_By>;
  arr?: InputMaybe<Order_By>;
  business_growth_score?: InputMaybe<Order_By>;
  capital_efficiency?: InputMaybe<Order_By>;
  capital_efficiency_score?: InputMaybe<Order_By>;
  churn?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  compliance_score?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_assessment_report_file_id?: InputMaybe<Order_By>;
  customer_retention?: InputMaybe<Order_By>;
  customer_value_score?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  dtoe_ratio?: InputMaybe<Order_By>;
  estimated_runway?: InputMaybe<Order_By>;
  facility_limit?: InputMaybe<Order_By>;
  financials_score?: InputMaybe<Order_By>;
  gross_margin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ltv?: InputMaybe<Order_By>;
  ltv_cac?: InputMaybe<Order_By>;
  management_score?: InputMaybe<Order_By>;
  max_recommended_bid?: InputMaybe<Order_By>;
  min_bid_price?: InputMaybe<Order_By>;
  min_recommended_bid?: InputMaybe<Order_By>;
  mrr_growth?: InputMaybe<Order_By>;
  net_cash_flow?: InputMaybe<Order_By>;
  net_profit?: InputMaybe<Order_By>;
  payback_period?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  report_file_id?: InputMaybe<Order_By>;
  revenue_retention?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Company_Score_Stddev_Pop_Fields = {
  __typename?: 'company_score_stddev_pop_fields';
  arpa?: Maybe<Scalars['Float']>;
  arr?: Maybe<Scalars['Float']>;
  business_growth_score?: Maybe<Scalars['Float']>;
  capital_efficiency?: Maybe<Scalars['Float']>;
  capital_efficiency_score?: Maybe<Scalars['Float']>;
  churn?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  compliance_score?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  credit_assessment_report_file_id?: Maybe<Scalars['Float']>;
  customer_retention?: Maybe<Scalars['Float']>;
  customer_value_score?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  dtoe_ratio?: Maybe<Scalars['Float']>;
  estimated_runway?: Maybe<Scalars['Float']>;
  facility_limit?: Maybe<Scalars['Float']>;
  financials_score?: Maybe<Scalars['Float']>;
  gross_margin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ltv?: Maybe<Scalars['Float']>;
  ltv_cac?: Maybe<Scalars['Float']>;
  management_score?: Maybe<Scalars['Float']>;
  max_recommended_bid?: Maybe<Scalars['Float']>;
  min_bid_price?: Maybe<Scalars['Float']>;
  min_recommended_bid?: Maybe<Scalars['Float']>;
  mrr_growth?: Maybe<Scalars['Float']>;
  net_cash_flow?: Maybe<Scalars['Float']>;
  net_profit?: Maybe<Scalars['Float']>;
  payback_period?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  report_file_id?: Maybe<Scalars['Float']>;
  revenue_retention?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "company_score" */
export type Company_Score_Stddev_Pop_Order_By = {
  arpa?: InputMaybe<Order_By>;
  arr?: InputMaybe<Order_By>;
  business_growth_score?: InputMaybe<Order_By>;
  capital_efficiency?: InputMaybe<Order_By>;
  capital_efficiency_score?: InputMaybe<Order_By>;
  churn?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  compliance_score?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_assessment_report_file_id?: InputMaybe<Order_By>;
  customer_retention?: InputMaybe<Order_By>;
  customer_value_score?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  dtoe_ratio?: InputMaybe<Order_By>;
  estimated_runway?: InputMaybe<Order_By>;
  facility_limit?: InputMaybe<Order_By>;
  financials_score?: InputMaybe<Order_By>;
  gross_margin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ltv?: InputMaybe<Order_By>;
  ltv_cac?: InputMaybe<Order_By>;
  management_score?: InputMaybe<Order_By>;
  max_recommended_bid?: InputMaybe<Order_By>;
  min_bid_price?: InputMaybe<Order_By>;
  min_recommended_bid?: InputMaybe<Order_By>;
  mrr_growth?: InputMaybe<Order_By>;
  net_cash_flow?: InputMaybe<Order_By>;
  net_profit?: InputMaybe<Order_By>;
  payback_period?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  report_file_id?: InputMaybe<Order_By>;
  revenue_retention?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Company_Score_Stddev_Samp_Fields = {
  __typename?: 'company_score_stddev_samp_fields';
  arpa?: Maybe<Scalars['Float']>;
  arr?: Maybe<Scalars['Float']>;
  business_growth_score?: Maybe<Scalars['Float']>;
  capital_efficiency?: Maybe<Scalars['Float']>;
  capital_efficiency_score?: Maybe<Scalars['Float']>;
  churn?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  compliance_score?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  credit_assessment_report_file_id?: Maybe<Scalars['Float']>;
  customer_retention?: Maybe<Scalars['Float']>;
  customer_value_score?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  dtoe_ratio?: Maybe<Scalars['Float']>;
  estimated_runway?: Maybe<Scalars['Float']>;
  facility_limit?: Maybe<Scalars['Float']>;
  financials_score?: Maybe<Scalars['Float']>;
  gross_margin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ltv?: Maybe<Scalars['Float']>;
  ltv_cac?: Maybe<Scalars['Float']>;
  management_score?: Maybe<Scalars['Float']>;
  max_recommended_bid?: Maybe<Scalars['Float']>;
  min_bid_price?: Maybe<Scalars['Float']>;
  min_recommended_bid?: Maybe<Scalars['Float']>;
  mrr_growth?: Maybe<Scalars['Float']>;
  net_cash_flow?: Maybe<Scalars['Float']>;
  net_profit?: Maybe<Scalars['Float']>;
  payback_period?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  report_file_id?: Maybe<Scalars['Float']>;
  revenue_retention?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "company_score" */
export type Company_Score_Stddev_Samp_Order_By = {
  arpa?: InputMaybe<Order_By>;
  arr?: InputMaybe<Order_By>;
  business_growth_score?: InputMaybe<Order_By>;
  capital_efficiency?: InputMaybe<Order_By>;
  capital_efficiency_score?: InputMaybe<Order_By>;
  churn?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  compliance_score?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_assessment_report_file_id?: InputMaybe<Order_By>;
  customer_retention?: InputMaybe<Order_By>;
  customer_value_score?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  dtoe_ratio?: InputMaybe<Order_By>;
  estimated_runway?: InputMaybe<Order_By>;
  facility_limit?: InputMaybe<Order_By>;
  financials_score?: InputMaybe<Order_By>;
  gross_margin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ltv?: InputMaybe<Order_By>;
  ltv_cac?: InputMaybe<Order_By>;
  management_score?: InputMaybe<Order_By>;
  max_recommended_bid?: InputMaybe<Order_By>;
  min_bid_price?: InputMaybe<Order_By>;
  min_recommended_bid?: InputMaybe<Order_By>;
  mrr_growth?: InputMaybe<Order_By>;
  net_cash_flow?: InputMaybe<Order_By>;
  net_profit?: InputMaybe<Order_By>;
  payback_period?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  report_file_id?: InputMaybe<Order_By>;
  revenue_retention?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "company_score" */
export type Company_Score_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Score_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Score_Stream_Cursor_Value_Input = {
  arpa?: InputMaybe<Scalars['float8']>;
  arr?: InputMaybe<Scalars['float8']>;
  business_growth_score?: InputMaybe<Scalars['float8']>;
  capital_efficiency?: InputMaybe<Scalars['numeric']>;
  capital_efficiency_score?: InputMaybe<Scalars['float8']>;
  churn?: InputMaybe<Scalars['float8']>;
  company_id?: InputMaybe<Scalars['Int']>;
  compliance_score?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  credit_assessment_report_file_id?: InputMaybe<Scalars['Int']>;
  customer_retention?: InputMaybe<Scalars['float8']>;
  customer_value_score?: InputMaybe<Scalars['float8']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  dtoe_ratio?: InputMaybe<Scalars['float8']>;
  estimated_runway?: InputMaybe<Scalars['Int']>;
  facility_limit?: InputMaybe<Scalars['float8']>;
  financials_score?: InputMaybe<Scalars['float8']>;
  gross_margin?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  ltv?: InputMaybe<Scalars['float8']>;
  ltv_cac?: InputMaybe<Scalars['float8']>;
  management_score?: InputMaybe<Scalars['float8']>;
  max_recommended_bid?: InputMaybe<Scalars['float8']>;
  min_bid_price?: InputMaybe<Scalars['float8']>;
  min_recommended_bid?: InputMaybe<Scalars['float8']>;
  mrr_growth?: InputMaybe<Scalars['float8']>;
  net_cash_flow?: InputMaybe<Scalars['float8']>;
  net_profit?: InputMaybe<Scalars['float8']>;
  payback_period?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['float8']>;
  report_file_id?: InputMaybe<Scalars['Int']>;
  revenue_retention?: InputMaybe<Scalars['float8']>;
  scored_on?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Company_Score_Sum_Fields = {
  __typename?: 'company_score_sum_fields';
  arpa?: Maybe<Scalars['float8']>;
  arr?: Maybe<Scalars['float8']>;
  business_growth_score?: Maybe<Scalars['float8']>;
  capital_efficiency?: Maybe<Scalars['numeric']>;
  capital_efficiency_score?: Maybe<Scalars['float8']>;
  churn?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  compliance_score?: Maybe<Scalars['float8']>;
  created_by?: Maybe<Scalars['Int']>;
  credit_assessment_report_file_id?: Maybe<Scalars['Int']>;
  customer_retention?: Maybe<Scalars['float8']>;
  customer_value_score?: Maybe<Scalars['float8']>;
  deleted_by?: Maybe<Scalars['Int']>;
  dtoe_ratio?: Maybe<Scalars['float8']>;
  estimated_runway?: Maybe<Scalars['Int']>;
  facility_limit?: Maybe<Scalars['float8']>;
  financials_score?: Maybe<Scalars['float8']>;
  gross_margin?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  ltv?: Maybe<Scalars['float8']>;
  ltv_cac?: Maybe<Scalars['float8']>;
  management_score?: Maybe<Scalars['float8']>;
  max_recommended_bid?: Maybe<Scalars['float8']>;
  min_bid_price?: Maybe<Scalars['float8']>;
  min_recommended_bid?: Maybe<Scalars['float8']>;
  mrr_growth?: Maybe<Scalars['float8']>;
  net_cash_flow?: Maybe<Scalars['float8']>;
  net_profit?: Maybe<Scalars['float8']>;
  payback_period?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['float8']>;
  report_file_id?: Maybe<Scalars['Int']>;
  revenue_retention?: Maybe<Scalars['float8']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "company_score" */
export type Company_Score_Sum_Order_By = {
  arpa?: InputMaybe<Order_By>;
  arr?: InputMaybe<Order_By>;
  business_growth_score?: InputMaybe<Order_By>;
  capital_efficiency?: InputMaybe<Order_By>;
  capital_efficiency_score?: InputMaybe<Order_By>;
  churn?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  compliance_score?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_assessment_report_file_id?: InputMaybe<Order_By>;
  customer_retention?: InputMaybe<Order_By>;
  customer_value_score?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  dtoe_ratio?: InputMaybe<Order_By>;
  estimated_runway?: InputMaybe<Order_By>;
  facility_limit?: InputMaybe<Order_By>;
  financials_score?: InputMaybe<Order_By>;
  gross_margin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ltv?: InputMaybe<Order_By>;
  ltv_cac?: InputMaybe<Order_By>;
  management_score?: InputMaybe<Order_By>;
  max_recommended_bid?: InputMaybe<Order_By>;
  min_bid_price?: InputMaybe<Order_By>;
  min_recommended_bid?: InputMaybe<Order_By>;
  mrr_growth?: InputMaybe<Order_By>;
  net_cash_flow?: InputMaybe<Order_By>;
  net_profit?: InputMaybe<Order_By>;
  payback_period?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  report_file_id?: InputMaybe<Order_By>;
  revenue_retention?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "company_score_time_series" */
export type Company_Score_Time_Series = {
  __typename?: 'company_score_time_series';
  company_id: Scalars['Int'];
  /** An object relationship */
  company_score: Company_Score;
  /** An array relationship */
  company_score_factors: Array<Company_Score_Factor>;
  /** An aggregate relationship */
  company_score_factors_aggregate: Company_Score_Factor_Aggregate;
  company_score_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  sequence: Scalars['Int'];
  time_series: Scalars['String'];
  update_by?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "company_score_time_series" */
export type Company_Score_Time_SeriesCompany_Score_FactorsArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Factor_Order_By>>;
  where?: InputMaybe<Company_Score_Factor_Bool_Exp>;
};


/** columns and relationships of "company_score_time_series" */
export type Company_Score_Time_SeriesCompany_Score_Factors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Factor_Order_By>>;
  where?: InputMaybe<Company_Score_Factor_Bool_Exp>;
};

/** aggregated selection of "company_score_time_series" */
export type Company_Score_Time_Series_Aggregate = {
  __typename?: 'company_score_time_series_aggregate';
  aggregate?: Maybe<Company_Score_Time_Series_Aggregate_Fields>;
  nodes: Array<Company_Score_Time_Series>;
};

export type Company_Score_Time_Series_Aggregate_Bool_Exp = {
  count?: InputMaybe<Company_Score_Time_Series_Aggregate_Bool_Exp_Count>;
};

export type Company_Score_Time_Series_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Company_Score_Time_Series_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Score_Time_Series_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "company_score_time_series" */
export type Company_Score_Time_Series_Aggregate_Fields = {
  __typename?: 'company_score_time_series_aggregate_fields';
  avg?: Maybe<Company_Score_Time_Series_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Score_Time_Series_Max_Fields>;
  min?: Maybe<Company_Score_Time_Series_Min_Fields>;
  stddev?: Maybe<Company_Score_Time_Series_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Score_Time_Series_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Score_Time_Series_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Score_Time_Series_Sum_Fields>;
  var_pop?: Maybe<Company_Score_Time_Series_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Score_Time_Series_Var_Samp_Fields>;
  variance?: Maybe<Company_Score_Time_Series_Variance_Fields>;
};


/** aggregate fields of "company_score_time_series" */
export type Company_Score_Time_Series_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Score_Time_Series_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_score_time_series" */
export type Company_Score_Time_Series_Aggregate_Order_By = {
  avg?: InputMaybe<Company_Score_Time_Series_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Score_Time_Series_Max_Order_By>;
  min?: InputMaybe<Company_Score_Time_Series_Min_Order_By>;
  stddev?: InputMaybe<Company_Score_Time_Series_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Company_Score_Time_Series_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Company_Score_Time_Series_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Company_Score_Time_Series_Sum_Order_By>;
  var_pop?: InputMaybe<Company_Score_Time_Series_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Company_Score_Time_Series_Var_Samp_Order_By>;
  variance?: InputMaybe<Company_Score_Time_Series_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "company_score_time_series" */
export type Company_Score_Time_Series_Arr_Rel_Insert_Input = {
  data: Array<Company_Score_Time_Series_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Score_Time_Series_On_Conflict>;
};

/** aggregate avg on columns */
export type Company_Score_Time_Series_Avg_Fields = {
  __typename?: 'company_score_time_series_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  update_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "company_score_time_series" */
export type Company_Score_Time_Series_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  update_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "company_score_time_series". All fields are combined with a logical 'AND'. */
export type Company_Score_Time_Series_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Score_Time_Series_Bool_Exp>>;
  _not?: InputMaybe<Company_Score_Time_Series_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Score_Time_Series_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  company_score?: InputMaybe<Company_Score_Bool_Exp>;
  company_score_factors?: InputMaybe<Company_Score_Factor_Bool_Exp>;
  company_score_factors_aggregate?: InputMaybe<Company_Score_Factor_Aggregate_Bool_Exp>;
  company_score_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  sequence?: InputMaybe<Int_Comparison_Exp>;
  time_series?: InputMaybe<String_Comparison_Exp>;
  update_by?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_score_time_series" */
export enum Company_Score_Time_Series_Constraint {
  /** unique or primary key constraint on columns "company_score_id", "time_series" */
  CompanyScoreTimeSeriesCompanyScoreIdTimeSeriesKey = 'company_score_time_series_company_score_id_time_series_key',
  /** unique or primary key constraint on columns "id" */
  CompanyScoreTimeSeriesPkey = 'company_score_time_series_pkey'
}

/** input type for incrementing numeric columns in table "company_score_time_series" */
export type Company_Score_Time_Series_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  company_score_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  update_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_score_time_series" */
export type Company_Score_Time_Series_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  company_score?: InputMaybe<Company_Score_Obj_Rel_Insert_Input>;
  company_score_factors?: InputMaybe<Company_Score_Factor_Arr_Rel_Insert_Input>;
  company_score_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  time_series?: InputMaybe<Scalars['String']>;
  update_by?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Company_Score_Time_Series_Max_Fields = {
  __typename?: 'company_score_time_series_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  company_score_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  time_series?: Maybe<Scalars['String']>;
  update_by?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "company_score_time_series" */
export type Company_Score_Time_Series_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  time_series?: InputMaybe<Order_By>;
  update_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Score_Time_Series_Min_Fields = {
  __typename?: 'company_score_time_series_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  company_score_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  time_series?: Maybe<Scalars['String']>;
  update_by?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "company_score_time_series" */
export type Company_Score_Time_Series_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  time_series?: InputMaybe<Order_By>;
  update_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_score_time_series" */
export type Company_Score_Time_Series_Mutation_Response = {
  __typename?: 'company_score_time_series_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Score_Time_Series>;
};

/** input type for inserting object relation for remote table "company_score_time_series" */
export type Company_Score_Time_Series_Obj_Rel_Insert_Input = {
  data: Company_Score_Time_Series_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Score_Time_Series_On_Conflict>;
};

/** on_conflict condition type for table "company_score_time_series" */
export type Company_Score_Time_Series_On_Conflict = {
  constraint: Company_Score_Time_Series_Constraint;
  update_columns?: Array<Company_Score_Time_Series_Update_Column>;
  where?: InputMaybe<Company_Score_Time_Series_Bool_Exp>;
};

/** Ordering options when selecting data from "company_score_time_series". */
export type Company_Score_Time_Series_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score?: InputMaybe<Company_Score_Order_By>;
  company_score_factors_aggregate?: InputMaybe<Company_Score_Factor_Aggregate_Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  time_series?: InputMaybe<Order_By>;
  update_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_score_time_series */
export type Company_Score_Time_Series_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "company_score_time_series" */
export enum Company_Score_Time_Series_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyScoreId = 'company_score_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  TimeSeries = 'time_series',
  /** column name */
  UpdateBy = 'update_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "company_score_time_series" */
export type Company_Score_Time_Series_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  company_score_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  time_series?: InputMaybe<Scalars['String']>;
  update_by?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Company_Score_Time_Series_Stddev_Fields = {
  __typename?: 'company_score_time_series_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  update_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "company_score_time_series" */
export type Company_Score_Time_Series_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  update_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Company_Score_Time_Series_Stddev_Pop_Fields = {
  __typename?: 'company_score_time_series_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  update_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "company_score_time_series" */
export type Company_Score_Time_Series_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  update_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Company_Score_Time_Series_Stddev_Samp_Fields = {
  __typename?: 'company_score_time_series_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  update_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "company_score_time_series" */
export type Company_Score_Time_Series_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  update_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "company_score_time_series" */
export type Company_Score_Time_Series_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Score_Time_Series_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Score_Time_Series_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  company_score_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  time_series?: InputMaybe<Scalars['String']>;
  update_by?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Company_Score_Time_Series_Sum_Fields = {
  __typename?: 'company_score_time_series_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  company_score_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  update_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "company_score_time_series" */
export type Company_Score_Time_Series_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  update_by?: InputMaybe<Order_By>;
};

/** update columns of table "company_score_time_series" */
export enum Company_Score_Time_Series_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyScoreId = 'company_score_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  TimeSeries = 'time_series',
  /** column name */
  UpdateBy = 'update_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Company_Score_Time_Series_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Score_Time_Series_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Score_Time_Series_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Score_Time_Series_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Score_Time_Series_Var_Pop_Fields = {
  __typename?: 'company_score_time_series_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  update_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "company_score_time_series" */
export type Company_Score_Time_Series_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  update_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Company_Score_Time_Series_Var_Samp_Fields = {
  __typename?: 'company_score_time_series_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  update_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "company_score_time_series" */
export type Company_Score_Time_Series_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  update_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Company_Score_Time_Series_Variance_Fields = {
  __typename?: 'company_score_time_series_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  update_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "company_score_time_series" */
export type Company_Score_Time_Series_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  update_by?: InputMaybe<Order_By>;
};

/** update columns of table "company_score" */
export enum Company_Score_Update_Column {
  /** column name */
  Arpa = 'arpa',
  /** column name */
  Arr = 'arr',
  /** column name */
  BusinessGrowthScore = 'business_growth_score',
  /** column name */
  CapitalEfficiency = 'capital_efficiency',
  /** column name */
  CapitalEfficiencyScore = 'capital_efficiency_score',
  /** column name */
  Churn = 'churn',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ComplianceScore = 'compliance_score',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditAssessmentReportFileId = 'credit_assessment_report_file_id',
  /** column name */
  CustomerRetention = 'customer_retention',
  /** column name */
  CustomerValueScore = 'customer_value_score',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  DtoeRatio = 'dtoe_ratio',
  /** column name */
  EstimatedRunway = 'estimated_runway',
  /** column name */
  FacilityLimit = 'facility_limit',
  /** column name */
  FinancialsScore = 'financials_score',
  /** column name */
  GrossMargin = 'gross_margin',
  /** column name */
  Id = 'id',
  /** column name */
  Ltv = 'ltv',
  /** column name */
  LtvCac = 'ltv_cac',
  /** column name */
  ManagementScore = 'management_score',
  /** column name */
  MaxRecommendedBid = 'max_recommended_bid',
  /** column name */
  MinBidPrice = 'min_bid_price',
  /** column name */
  MinRecommendedBid = 'min_recommended_bid',
  /** column name */
  MrrGrowth = 'mrr_growth',
  /** column name */
  NetCashFlow = 'net_cash_flow',
  /** column name */
  NetProfit = 'net_profit',
  /** column name */
  PaybackPeriod = 'payback_period',
  /** column name */
  Rating = 'rating',
  /** column name */
  ReportFileId = 'report_file_id',
  /** column name */
  RevenueRetention = 'revenue_retention',
  /** column name */
  ScoredOn = 'scored_on',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Version = 'version'
}

export type Company_Score_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Score_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Score_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Score_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Score_Var_Pop_Fields = {
  __typename?: 'company_score_var_pop_fields';
  arpa?: Maybe<Scalars['Float']>;
  arr?: Maybe<Scalars['Float']>;
  business_growth_score?: Maybe<Scalars['Float']>;
  capital_efficiency?: Maybe<Scalars['Float']>;
  capital_efficiency_score?: Maybe<Scalars['Float']>;
  churn?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  compliance_score?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  credit_assessment_report_file_id?: Maybe<Scalars['Float']>;
  customer_retention?: Maybe<Scalars['Float']>;
  customer_value_score?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  dtoe_ratio?: Maybe<Scalars['Float']>;
  estimated_runway?: Maybe<Scalars['Float']>;
  facility_limit?: Maybe<Scalars['Float']>;
  financials_score?: Maybe<Scalars['Float']>;
  gross_margin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ltv?: Maybe<Scalars['Float']>;
  ltv_cac?: Maybe<Scalars['Float']>;
  management_score?: Maybe<Scalars['Float']>;
  max_recommended_bid?: Maybe<Scalars['Float']>;
  min_bid_price?: Maybe<Scalars['Float']>;
  min_recommended_bid?: Maybe<Scalars['Float']>;
  mrr_growth?: Maybe<Scalars['Float']>;
  net_cash_flow?: Maybe<Scalars['Float']>;
  net_profit?: Maybe<Scalars['Float']>;
  payback_period?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  report_file_id?: Maybe<Scalars['Float']>;
  revenue_retention?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "company_score" */
export type Company_Score_Var_Pop_Order_By = {
  arpa?: InputMaybe<Order_By>;
  arr?: InputMaybe<Order_By>;
  business_growth_score?: InputMaybe<Order_By>;
  capital_efficiency?: InputMaybe<Order_By>;
  capital_efficiency_score?: InputMaybe<Order_By>;
  churn?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  compliance_score?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_assessment_report_file_id?: InputMaybe<Order_By>;
  customer_retention?: InputMaybe<Order_By>;
  customer_value_score?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  dtoe_ratio?: InputMaybe<Order_By>;
  estimated_runway?: InputMaybe<Order_By>;
  facility_limit?: InputMaybe<Order_By>;
  financials_score?: InputMaybe<Order_By>;
  gross_margin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ltv?: InputMaybe<Order_By>;
  ltv_cac?: InputMaybe<Order_By>;
  management_score?: InputMaybe<Order_By>;
  max_recommended_bid?: InputMaybe<Order_By>;
  min_bid_price?: InputMaybe<Order_By>;
  min_recommended_bid?: InputMaybe<Order_By>;
  mrr_growth?: InputMaybe<Order_By>;
  net_cash_flow?: InputMaybe<Order_By>;
  net_profit?: InputMaybe<Order_By>;
  payback_period?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  report_file_id?: InputMaybe<Order_By>;
  revenue_retention?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Company_Score_Var_Samp_Fields = {
  __typename?: 'company_score_var_samp_fields';
  arpa?: Maybe<Scalars['Float']>;
  arr?: Maybe<Scalars['Float']>;
  business_growth_score?: Maybe<Scalars['Float']>;
  capital_efficiency?: Maybe<Scalars['Float']>;
  capital_efficiency_score?: Maybe<Scalars['Float']>;
  churn?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  compliance_score?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  credit_assessment_report_file_id?: Maybe<Scalars['Float']>;
  customer_retention?: Maybe<Scalars['Float']>;
  customer_value_score?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  dtoe_ratio?: Maybe<Scalars['Float']>;
  estimated_runway?: Maybe<Scalars['Float']>;
  facility_limit?: Maybe<Scalars['Float']>;
  financials_score?: Maybe<Scalars['Float']>;
  gross_margin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ltv?: Maybe<Scalars['Float']>;
  ltv_cac?: Maybe<Scalars['Float']>;
  management_score?: Maybe<Scalars['Float']>;
  max_recommended_bid?: Maybe<Scalars['Float']>;
  min_bid_price?: Maybe<Scalars['Float']>;
  min_recommended_bid?: Maybe<Scalars['Float']>;
  mrr_growth?: Maybe<Scalars['Float']>;
  net_cash_flow?: Maybe<Scalars['Float']>;
  net_profit?: Maybe<Scalars['Float']>;
  payback_period?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  report_file_id?: Maybe<Scalars['Float']>;
  revenue_retention?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "company_score" */
export type Company_Score_Var_Samp_Order_By = {
  arpa?: InputMaybe<Order_By>;
  arr?: InputMaybe<Order_By>;
  business_growth_score?: InputMaybe<Order_By>;
  capital_efficiency?: InputMaybe<Order_By>;
  capital_efficiency_score?: InputMaybe<Order_By>;
  churn?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  compliance_score?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_assessment_report_file_id?: InputMaybe<Order_By>;
  customer_retention?: InputMaybe<Order_By>;
  customer_value_score?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  dtoe_ratio?: InputMaybe<Order_By>;
  estimated_runway?: InputMaybe<Order_By>;
  facility_limit?: InputMaybe<Order_By>;
  financials_score?: InputMaybe<Order_By>;
  gross_margin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ltv?: InputMaybe<Order_By>;
  ltv_cac?: InputMaybe<Order_By>;
  management_score?: InputMaybe<Order_By>;
  max_recommended_bid?: InputMaybe<Order_By>;
  min_bid_price?: InputMaybe<Order_By>;
  min_recommended_bid?: InputMaybe<Order_By>;
  mrr_growth?: InputMaybe<Order_By>;
  net_cash_flow?: InputMaybe<Order_By>;
  net_profit?: InputMaybe<Order_By>;
  payback_period?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  report_file_id?: InputMaybe<Order_By>;
  revenue_retention?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Company_Score_Variance_Fields = {
  __typename?: 'company_score_variance_fields';
  arpa?: Maybe<Scalars['Float']>;
  arr?: Maybe<Scalars['Float']>;
  business_growth_score?: Maybe<Scalars['Float']>;
  capital_efficiency?: Maybe<Scalars['Float']>;
  capital_efficiency_score?: Maybe<Scalars['Float']>;
  churn?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  compliance_score?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  credit_assessment_report_file_id?: Maybe<Scalars['Float']>;
  customer_retention?: Maybe<Scalars['Float']>;
  customer_value_score?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  dtoe_ratio?: Maybe<Scalars['Float']>;
  estimated_runway?: Maybe<Scalars['Float']>;
  facility_limit?: Maybe<Scalars['Float']>;
  financials_score?: Maybe<Scalars['Float']>;
  gross_margin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ltv?: Maybe<Scalars['Float']>;
  ltv_cac?: Maybe<Scalars['Float']>;
  management_score?: Maybe<Scalars['Float']>;
  max_recommended_bid?: Maybe<Scalars['Float']>;
  min_bid_price?: Maybe<Scalars['Float']>;
  min_recommended_bid?: Maybe<Scalars['Float']>;
  mrr_growth?: Maybe<Scalars['Float']>;
  net_cash_flow?: Maybe<Scalars['Float']>;
  net_profit?: Maybe<Scalars['Float']>;
  payback_period?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  report_file_id?: Maybe<Scalars['Float']>;
  revenue_retention?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "company_score" */
export type Company_Score_Variance_Order_By = {
  arpa?: InputMaybe<Order_By>;
  arr?: InputMaybe<Order_By>;
  business_growth_score?: InputMaybe<Order_By>;
  capital_efficiency?: InputMaybe<Order_By>;
  capital_efficiency_score?: InputMaybe<Order_By>;
  churn?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  compliance_score?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_assessment_report_file_id?: InputMaybe<Order_By>;
  customer_retention?: InputMaybe<Order_By>;
  customer_value_score?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  dtoe_ratio?: InputMaybe<Order_By>;
  estimated_runway?: InputMaybe<Order_By>;
  facility_limit?: InputMaybe<Order_By>;
  financials_score?: InputMaybe<Order_By>;
  gross_margin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ltv?: InputMaybe<Order_By>;
  ltv_cac?: InputMaybe<Order_By>;
  management_score?: InputMaybe<Order_By>;
  max_recommended_bid?: InputMaybe<Order_By>;
  min_bid_price?: InputMaybe<Order_By>;
  min_recommended_bid?: InputMaybe<Order_By>;
  mrr_growth?: InputMaybe<Order_By>;
  net_cash_flow?: InputMaybe<Order_By>;
  net_profit?: InputMaybe<Order_By>;
  payback_period?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  report_file_id?: InputMaybe<Order_By>;
  revenue_retention?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** select columns of table "company" */
export enum Company_Select_Column {
  /** column name */
  ActiveGstin = 'active_gstin',
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AgreementSignedDate = 'agreement_signed_date',
  /** column name */
  AlternateEmails = 'alternate_emails',
  /** column name */
  ApplicationLifecycleStage = 'application_lifecycle_stage',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  AuthorizedSignatories = 'authorized_signatories',
  /** column name */
  AutoRejected = 'auto_rejected',
  /** column name */
  BillingAddress = 'billing_address',
  /** column name */
  BillingGst = 'billing_gst',
  /** column name */
  CcsAgreementId = 'ccs_agreement_id',
  /** column name */
  Cin = 'cin',
  /** column name */
  CompanySector = 'company_sector',
  /** column name */
  CompanyType = 'company_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CrmCompanyId = 'crm_company_id',
  /** column name */
  CrmContactId = 'crm_contact_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Description = 'description',
  /** column name */
  District = 'district',
  /** column name */
  DocumentChecklistEnabled = 'document_checklist_enabled',
  /** column name */
  Domain = 'domain',
  /** column name */
  EstimatedArr = 'estimated_arr',
  /** column name */
  Gstin = 'gstin',
  /** column name */
  Id = 'id',
  /** column name */
  IncorporationDate = 'incorporation_date',
  /** column name */
  Industry = 'industry',
  /** column name */
  MarqueeCustomers = 'marquee_customers',
  /** column name */
  MarqueeInvestors = 'marquee_investors',
  /** column name */
  McaProfile = 'mca_profile',
  /** column name */
  McaSignatories = 'mca_signatories',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MinTradableValue = 'min_tradable_value',
  /** column name */
  Name = 'name',
  /** column name */
  OpsRm = 'ops_rm',
  /** column name */
  Pan = 'pan',
  /** column name */
  RejectReason = 'reject_reason',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  SalesRm = 'sales_rm',
  /** column name */
  ShareholderInfo = 'shareholder_info',
  /** column name */
  ShareholderInfoDate = 'shareholder_info_date',
  /** column name */
  StateId = 'state_id',
  /** column name */
  Status = 'status',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  TotalCustomers = 'total_customers',
  /** column name */
  TotalFunding = 'total_funding',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Version = 'version',
  /** column name */
  Website = 'website',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "company" */
export type Company_Set_Input = {
  active_gstin?: InputMaybe<Scalars['String']>;
  address_line_1?: InputMaybe<Scalars['String']>;
  agreement_signed_date?: InputMaybe<Scalars['timestamptz']>;
  alternate_emails?: InputMaybe<Scalars['String']>;
  application_lifecycle_stage?: InputMaybe<Ref_Application_Lifecycle_Stage_Enum>;
  approved_at?: InputMaybe<Scalars['timestamptz']>;
  approved_by?: InputMaybe<Scalars['Int']>;
  authorized_signatories?: InputMaybe<Scalars['jsonb']>;
  auto_rejected?: InputMaybe<Scalars['Boolean']>;
  billing_address?: InputMaybe<Scalars['String']>;
  billing_gst?: InputMaybe<Scalars['String']>;
  ccs_agreement_id?: InputMaybe<Scalars['Int']>;
  cin?: InputMaybe<Scalars['String']>;
  company_sector?: InputMaybe<Scalars['String']>;
  company_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  crm_company_id?: InputMaybe<Scalars['String']>;
  crm_contact_id?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  document_checklist_enabled?: InputMaybe<Scalars['Boolean']>;
  domain?: InputMaybe<Scalars['String']>;
  estimated_arr?: InputMaybe<Scalars['numeric']>;
  gstin?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  incorporation_date?: InputMaybe<Scalars['date']>;
  industry?: InputMaybe<Scalars['String']>;
  marquee_customers?: InputMaybe<Scalars['String']>;
  marquee_investors?: InputMaybe<Scalars['jsonb']>;
  mca_profile?: InputMaybe<Scalars['jsonb']>;
  mca_signatories?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  min_tradable_value?: InputMaybe<Scalars['float8']>;
  name?: InputMaybe<Scalars['String']>;
  ops_rm?: InputMaybe<Scalars['Int']>;
  pan?: InputMaybe<Scalars['String']>;
  reject_reason?: InputMaybe<Scalars['String']>;
  rejected_at?: InputMaybe<Scalars['timestamptz']>;
  sales_rm?: InputMaybe<Scalars['Int']>;
  shareholder_info?: InputMaybe<Scalars['jsonb']>;
  shareholder_info_date?: InputMaybe<Scalars['timestamp']>;
  state_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Ref_Company_Status_Enum>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum>;
  total_customers?: InputMaybe<Scalars['Int']>;
  total_funding?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['Int']>;
  website?: InputMaybe<Scalars['String']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "company_statement" */
export type Company_Statement = {
  __typename?: 'company_statement';
  amount: Scalars['float8'];
  balance: Scalars['float8'];
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  metadata?: Maybe<Scalars['jsonb']>;
  timestamp: Scalars['timestamptz'];
  transaction_status: Ref_Transaction_Status_Enum;
  transaction_type: Ref_Transaction_Type_Enum;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "company_statement" */
export type Company_StatementMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "company_statement" */
export type Company_Statement_Aggregate = {
  __typename?: 'company_statement_aggregate';
  aggregate?: Maybe<Company_Statement_Aggregate_Fields>;
  nodes: Array<Company_Statement>;
};

/** aggregate fields of "company_statement" */
export type Company_Statement_Aggregate_Fields = {
  __typename?: 'company_statement_aggregate_fields';
  avg?: Maybe<Company_Statement_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Statement_Max_Fields>;
  min?: Maybe<Company_Statement_Min_Fields>;
  stddev?: Maybe<Company_Statement_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Statement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Statement_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Statement_Sum_Fields>;
  var_pop?: Maybe<Company_Statement_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Statement_Var_Samp_Fields>;
  variance?: Maybe<Company_Statement_Variance_Fields>;
};


/** aggregate fields of "company_statement" */
export type Company_Statement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Statement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Company_Statement_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Company_Statement_Avg_Fields = {
  __typename?: 'company_statement_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company_statement". All fields are combined with a logical 'AND'. */
export type Company_Statement_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Statement_Bool_Exp>>;
  _not?: InputMaybe<Company_Statement_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Statement_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  balance?: InputMaybe<Float8_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum_Comparison_Exp>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_statement" */
export enum Company_Statement_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyStatementPkey = 'company_statement_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Company_Statement_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Company_Statement_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Company_Statement_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "company_statement" */
export type Company_Statement_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  balance?: InputMaybe<Scalars['float8']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_statement" */
export type Company_Statement_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  balance?: InputMaybe<Scalars['float8']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Company_Statement_Max_Fields = {
  __typename?: 'company_statement_max_fields';
  amount?: Maybe<Scalars['float8']>;
  balance?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Company_Statement_Min_Fields = {
  __typename?: 'company_statement_min_fields';
  amount?: Maybe<Scalars['float8']>;
  balance?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "company_statement" */
export type Company_Statement_Mutation_Response = {
  __typename?: 'company_statement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Statement>;
};

/** on_conflict condition type for table "company_statement" */
export type Company_Statement_On_Conflict = {
  constraint: Company_Statement_Constraint;
  update_columns?: Array<Company_Statement_Update_Column>;
  where?: InputMaybe<Company_Statement_Bool_Exp>;
};

/** Ordering options when selecting data from "company_statement". */
export type Company_Statement_Order_By = {
  amount?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  transaction_status?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_statement */
export type Company_Statement_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Company_Statement_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "company_statement" */
export enum Company_Statement_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Balance = 'balance',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TransactionStatus = 'transaction_status',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "company_statement" */
export type Company_Statement_Set_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  balance?: InputMaybe<Scalars['float8']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Company_Statement_Stddev_Fields = {
  __typename?: 'company_statement_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Statement_Stddev_Pop_Fields = {
  __typename?: 'company_statement_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Statement_Stddev_Samp_Fields = {
  __typename?: 'company_statement_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "company_statement" */
export type Company_Statement_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Statement_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Statement_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  balance?: InputMaybe<Scalars['float8']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Company_Statement_Sum_Fields = {
  __typename?: 'company_statement_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  balance?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "company_statement" */
export enum Company_Statement_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Balance = 'balance',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TransactionStatus = 'transaction_status',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Company_Statement_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Company_Statement_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Company_Statement_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Company_Statement_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Company_Statement_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Statement_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Company_Statement_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Statement_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Statement_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Statement_Var_Pop_Fields = {
  __typename?: 'company_statement_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Statement_Var_Samp_Fields = {
  __typename?: 'company_statement_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Statement_Variance_Fields = {
  __typename?: 'company_statement_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Company_Stddev_Fields = {
  __typename?: 'company_stddev_fields';
  approved_by?: Maybe<Scalars['Float']>;
  ccs_agreement_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  estimated_arr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min_tradable_value?: Maybe<Scalars['Float']>;
  ops_rm?: Maybe<Scalars['Float']>;
  sales_rm?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
  total_customers?: Maybe<Scalars['Float']>;
  total_funding?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Stddev_Pop_Fields = {
  __typename?: 'company_stddev_pop_fields';
  approved_by?: Maybe<Scalars['Float']>;
  ccs_agreement_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  estimated_arr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min_tradable_value?: Maybe<Scalars['Float']>;
  ops_rm?: Maybe<Scalars['Float']>;
  sales_rm?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
  total_customers?: Maybe<Scalars['Float']>;
  total_funding?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Stddev_Samp_Fields = {
  __typename?: 'company_stddev_samp_fields';
  approved_by?: Maybe<Scalars['Float']>;
  ccs_agreement_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  estimated_arr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min_tradable_value?: Maybe<Scalars['Float']>;
  ops_rm?: Maybe<Scalars['Float']>;
  sales_rm?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
  total_customers?: Maybe<Scalars['Float']>;
  total_funding?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "company" */
export type Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Stream_Cursor_Value_Input = {
  active_gstin?: InputMaybe<Scalars['String']>;
  address_line_1?: InputMaybe<Scalars['String']>;
  agreement_signed_date?: InputMaybe<Scalars['timestamptz']>;
  alternate_emails?: InputMaybe<Scalars['String']>;
  application_lifecycle_stage?: InputMaybe<Ref_Application_Lifecycle_Stage_Enum>;
  approved_at?: InputMaybe<Scalars['timestamptz']>;
  approved_by?: InputMaybe<Scalars['Int']>;
  authorized_signatories?: InputMaybe<Scalars['jsonb']>;
  auto_rejected?: InputMaybe<Scalars['Boolean']>;
  billing_address?: InputMaybe<Scalars['String']>;
  billing_gst?: InputMaybe<Scalars['String']>;
  ccs_agreement_id?: InputMaybe<Scalars['Int']>;
  cin?: InputMaybe<Scalars['String']>;
  company_sector?: InputMaybe<Scalars['String']>;
  company_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  crm_company_id?: InputMaybe<Scalars['String']>;
  crm_contact_id?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  document_checklist_enabled?: InputMaybe<Scalars['Boolean']>;
  domain?: InputMaybe<Scalars['String']>;
  estimated_arr?: InputMaybe<Scalars['numeric']>;
  gstin?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  incorporation_date?: InputMaybe<Scalars['date']>;
  industry?: InputMaybe<Scalars['String']>;
  marquee_customers?: InputMaybe<Scalars['String']>;
  marquee_investors?: InputMaybe<Scalars['jsonb']>;
  mca_profile?: InputMaybe<Scalars['jsonb']>;
  mca_signatories?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  min_tradable_value?: InputMaybe<Scalars['float8']>;
  name?: InputMaybe<Scalars['String']>;
  ops_rm?: InputMaybe<Scalars['Int']>;
  pan?: InputMaybe<Scalars['String']>;
  reject_reason?: InputMaybe<Scalars['String']>;
  rejected_at?: InputMaybe<Scalars['timestamptz']>;
  sales_rm?: InputMaybe<Scalars['Int']>;
  shareholder_info?: InputMaybe<Scalars['jsonb']>;
  shareholder_info_date?: InputMaybe<Scalars['timestamp']>;
  state_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Ref_Company_Status_Enum>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum>;
  total_customers?: InputMaybe<Scalars['Int']>;
  total_funding?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['Int']>;
  website?: InputMaybe<Scalars['String']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Company_Sum_Fields = {
  __typename?: 'company_sum_fields';
  approved_by?: Maybe<Scalars['Int']>;
  ccs_agreement_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  estimated_arr?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  min_tradable_value?: Maybe<Scalars['float8']>;
  ops_rm?: Maybe<Scalars['Int']>;
  sales_rm?: Maybe<Scalars['Int']>;
  state_id?: Maybe<Scalars['Int']>;
  total_customers?: Maybe<Scalars['Int']>;
  total_funding?: Maybe<Scalars['numeric']>;
  updated_by?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "company_tan" */
export type Company_Tan = {
  __typename?: 'company_tan';
  challan_no: Scalars['String'];
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  financial_year: Scalars['String'];
  id: Scalars['Int'];
  tan: Scalars['String'];
  transaction: Scalars['jsonb'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "company_tan" */
export type Company_TanTransactionArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "company_tan" */
export type Company_Tan_Aggregate = {
  __typename?: 'company_tan_aggregate';
  aggregate?: Maybe<Company_Tan_Aggregate_Fields>;
  nodes: Array<Company_Tan>;
};

/** aggregate fields of "company_tan" */
export type Company_Tan_Aggregate_Fields = {
  __typename?: 'company_tan_aggregate_fields';
  avg?: Maybe<Company_Tan_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Tan_Max_Fields>;
  min?: Maybe<Company_Tan_Min_Fields>;
  stddev?: Maybe<Company_Tan_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Tan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Tan_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Tan_Sum_Fields>;
  var_pop?: Maybe<Company_Tan_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Tan_Var_Samp_Fields>;
  variance?: Maybe<Company_Tan_Variance_Fields>;
};


/** aggregate fields of "company_tan" */
export type Company_Tan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Tan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Company_Tan_Append_Input = {
  transaction?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Company_Tan_Avg_Fields = {
  __typename?: 'company_tan_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company_tan". All fields are combined with a logical 'AND'. */
export type Company_Tan_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Tan_Bool_Exp>>;
  _not?: InputMaybe<Company_Tan_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Tan_Bool_Exp>>;
  challan_no?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  financial_year?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tan?: InputMaybe<String_Comparison_Exp>;
  transaction?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_tan" */
export enum Company_Tan_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyTanPkey = 'company_tan_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Company_Tan_Delete_At_Path_Input = {
  transaction?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Company_Tan_Delete_Elem_Input = {
  transaction?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Company_Tan_Delete_Key_Input = {
  transaction?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "company_tan" */
export type Company_Tan_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_tan" */
export type Company_Tan_Insert_Input = {
  challan_no?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  financial_year?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  tan?: InputMaybe<Scalars['String']>;
  transaction?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Company_Tan_Max_Fields = {
  __typename?: 'company_tan_max_fields';
  challan_no?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  financial_year?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  tan?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Company_Tan_Min_Fields = {
  __typename?: 'company_tan_min_fields';
  challan_no?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  financial_year?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  tan?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "company_tan" */
export type Company_Tan_Mutation_Response = {
  __typename?: 'company_tan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Tan>;
};

/** on_conflict condition type for table "company_tan" */
export type Company_Tan_On_Conflict = {
  constraint: Company_Tan_Constraint;
  update_columns?: Array<Company_Tan_Update_Column>;
  where?: InputMaybe<Company_Tan_Bool_Exp>;
};

/** Ordering options when selecting data from "company_tan". */
export type Company_Tan_Order_By = {
  challan_no?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  financial_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tan?: InputMaybe<Order_By>;
  transaction?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_tan */
export type Company_Tan_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Company_Tan_Prepend_Input = {
  transaction?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "company_tan" */
export enum Company_Tan_Select_Column {
  /** column name */
  ChallanNo = 'challan_no',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  FinancialYear = 'financial_year',
  /** column name */
  Id = 'id',
  /** column name */
  Tan = 'tan',
  /** column name */
  Transaction = 'transaction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "company_tan" */
export type Company_Tan_Set_Input = {
  challan_no?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  financial_year?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  tan?: InputMaybe<Scalars['String']>;
  transaction?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Company_Tan_Stddev_Fields = {
  __typename?: 'company_tan_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Tan_Stddev_Pop_Fields = {
  __typename?: 'company_tan_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Tan_Stddev_Samp_Fields = {
  __typename?: 'company_tan_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "company_tan" */
export type Company_Tan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Tan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Tan_Stream_Cursor_Value_Input = {
  challan_no?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  financial_year?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  tan?: InputMaybe<Scalars['String']>;
  transaction?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Company_Tan_Sum_Fields = {
  __typename?: 'company_tan_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "company_tan" */
export enum Company_Tan_Update_Column {
  /** column name */
  ChallanNo = 'challan_no',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  FinancialYear = 'financial_year',
  /** column name */
  Id = 'id',
  /** column name */
  Tan = 'tan',
  /** column name */
  Transaction = 'transaction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Company_Tan_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Company_Tan_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Company_Tan_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Company_Tan_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Company_Tan_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Tan_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Company_Tan_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Tan_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Tan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Tan_Var_Pop_Fields = {
  __typename?: 'company_tan_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Tan_Var_Samp_Fields = {
  __typename?: 'company_tan_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Tan_Variance_Fields = {
  __typename?: 'company_tan_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "company_type_factor_benchmark" */
export type Company_Type_Factor_Benchmark = {
  __typename?: 'company_type_factor_benchmark';
  company_type: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  /** An array relationship */
  factor_benchmark_ranges: Array<Factor_Benchmark_Range>;
  /** An aggregate relationship */
  factor_benchmark_ranges_aggregate: Factor_Benchmark_Range_Aggregate;
  factor_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  score_factor: Score_Factor;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  value: Scalars['numeric'];
};


/** columns and relationships of "company_type_factor_benchmark" */
export type Company_Type_Factor_BenchmarkFactor_Benchmark_RangesArgs = {
  distinct_on?: InputMaybe<Array<Factor_Benchmark_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Factor_Benchmark_Range_Order_By>>;
  where?: InputMaybe<Factor_Benchmark_Range_Bool_Exp>;
};


/** columns and relationships of "company_type_factor_benchmark" */
export type Company_Type_Factor_BenchmarkFactor_Benchmark_Ranges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Factor_Benchmark_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Factor_Benchmark_Range_Order_By>>;
  where?: InputMaybe<Factor_Benchmark_Range_Bool_Exp>;
};

/** aggregated selection of "company_type_factor_benchmark" */
export type Company_Type_Factor_Benchmark_Aggregate = {
  __typename?: 'company_type_factor_benchmark_aggregate';
  aggregate?: Maybe<Company_Type_Factor_Benchmark_Aggregate_Fields>;
  nodes: Array<Company_Type_Factor_Benchmark>;
};

/** aggregate fields of "company_type_factor_benchmark" */
export type Company_Type_Factor_Benchmark_Aggregate_Fields = {
  __typename?: 'company_type_factor_benchmark_aggregate_fields';
  avg?: Maybe<Company_Type_Factor_Benchmark_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Type_Factor_Benchmark_Max_Fields>;
  min?: Maybe<Company_Type_Factor_Benchmark_Min_Fields>;
  stddev?: Maybe<Company_Type_Factor_Benchmark_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Type_Factor_Benchmark_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Type_Factor_Benchmark_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Type_Factor_Benchmark_Sum_Fields>;
  var_pop?: Maybe<Company_Type_Factor_Benchmark_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Type_Factor_Benchmark_Var_Samp_Fields>;
  variance?: Maybe<Company_Type_Factor_Benchmark_Variance_Fields>;
};


/** aggregate fields of "company_type_factor_benchmark" */
export type Company_Type_Factor_Benchmark_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Type_Factor_Benchmark_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Company_Type_Factor_Benchmark_Avg_Fields = {
  __typename?: 'company_type_factor_benchmark_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  factor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company_type_factor_benchmark". All fields are combined with a logical 'AND'. */
export type Company_Type_Factor_Benchmark_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Type_Factor_Benchmark_Bool_Exp>>;
  _not?: InputMaybe<Company_Type_Factor_Benchmark_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Type_Factor_Benchmark_Bool_Exp>>;
  company_type?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  factor_benchmark_ranges?: InputMaybe<Factor_Benchmark_Range_Bool_Exp>;
  factor_benchmark_ranges_aggregate?: InputMaybe<Factor_Benchmark_Range_Aggregate_Bool_Exp>;
  factor_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  score_factor?: InputMaybe<Score_Factor_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_type_factor_benchmark" */
export enum Company_Type_Factor_Benchmark_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyTypeFactorBenchmarkPkey = 'company_type_factor_benchmark_pkey'
}

/** input type for incrementing numeric columns in table "company_type_factor_benchmark" */
export type Company_Type_Factor_Benchmark_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  factor_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "company_type_factor_benchmark" */
export type Company_Type_Factor_Benchmark_Insert_Input = {
  company_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  factor_benchmark_ranges?: InputMaybe<Factor_Benchmark_Range_Arr_Rel_Insert_Input>;
  factor_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  score_factor?: InputMaybe<Score_Factor_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Company_Type_Factor_Benchmark_Max_Fields = {
  __typename?: 'company_type_factor_benchmark_max_fields';
  company_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  factor_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Company_Type_Factor_Benchmark_Min_Fields = {
  __typename?: 'company_type_factor_benchmark_min_fields';
  company_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  factor_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "company_type_factor_benchmark" */
export type Company_Type_Factor_Benchmark_Mutation_Response = {
  __typename?: 'company_type_factor_benchmark_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Type_Factor_Benchmark>;
};

/** on_conflict condition type for table "company_type_factor_benchmark" */
export type Company_Type_Factor_Benchmark_On_Conflict = {
  constraint: Company_Type_Factor_Benchmark_Constraint;
  update_columns?: Array<Company_Type_Factor_Benchmark_Update_Column>;
  where?: InputMaybe<Company_Type_Factor_Benchmark_Bool_Exp>;
};

/** Ordering options when selecting data from "company_type_factor_benchmark". */
export type Company_Type_Factor_Benchmark_Order_By = {
  company_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  factor_benchmark_ranges_aggregate?: InputMaybe<Factor_Benchmark_Range_Aggregate_Order_By>;
  factor_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score_factor?: InputMaybe<Score_Factor_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_type_factor_benchmark */
export type Company_Type_Factor_Benchmark_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "company_type_factor_benchmark" */
export enum Company_Type_Factor_Benchmark_Select_Column {
  /** column name */
  CompanyType = 'company_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  FactorId = 'factor_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "company_type_factor_benchmark" */
export type Company_Type_Factor_Benchmark_Set_Input = {
  company_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  factor_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Company_Type_Factor_Benchmark_Stddev_Fields = {
  __typename?: 'company_type_factor_benchmark_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  factor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Type_Factor_Benchmark_Stddev_Pop_Fields = {
  __typename?: 'company_type_factor_benchmark_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  factor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Type_Factor_Benchmark_Stddev_Samp_Fields = {
  __typename?: 'company_type_factor_benchmark_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  factor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "company_type_factor_benchmark" */
export type Company_Type_Factor_Benchmark_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Type_Factor_Benchmark_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Type_Factor_Benchmark_Stream_Cursor_Value_Input = {
  company_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  factor_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Company_Type_Factor_Benchmark_Sum_Fields = {
  __typename?: 'company_type_factor_benchmark_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  factor_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['numeric']>;
};

/** update columns of table "company_type_factor_benchmark" */
export enum Company_Type_Factor_Benchmark_Update_Column {
  /** column name */
  CompanyType = 'company_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  FactorId = 'factor_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type Company_Type_Factor_Benchmark_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Type_Factor_Benchmark_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Type_Factor_Benchmark_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Type_Factor_Benchmark_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Type_Factor_Benchmark_Var_Pop_Fields = {
  __typename?: 'company_type_factor_benchmark_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  factor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Type_Factor_Benchmark_Var_Samp_Fields = {
  __typename?: 'company_type_factor_benchmark_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  factor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Type_Factor_Benchmark_Variance_Fields = {
  __typename?: 'company_type_factor_benchmark_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  factor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** update columns of table "company" */
export enum Company_Update_Column {
  /** column name */
  ActiveGstin = 'active_gstin',
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AgreementSignedDate = 'agreement_signed_date',
  /** column name */
  AlternateEmails = 'alternate_emails',
  /** column name */
  ApplicationLifecycleStage = 'application_lifecycle_stage',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  AuthorizedSignatories = 'authorized_signatories',
  /** column name */
  AutoRejected = 'auto_rejected',
  /** column name */
  BillingAddress = 'billing_address',
  /** column name */
  BillingGst = 'billing_gst',
  /** column name */
  CcsAgreementId = 'ccs_agreement_id',
  /** column name */
  Cin = 'cin',
  /** column name */
  CompanySector = 'company_sector',
  /** column name */
  CompanyType = 'company_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CrmCompanyId = 'crm_company_id',
  /** column name */
  CrmContactId = 'crm_contact_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Description = 'description',
  /** column name */
  District = 'district',
  /** column name */
  DocumentChecklistEnabled = 'document_checklist_enabled',
  /** column name */
  Domain = 'domain',
  /** column name */
  EstimatedArr = 'estimated_arr',
  /** column name */
  Gstin = 'gstin',
  /** column name */
  Id = 'id',
  /** column name */
  IncorporationDate = 'incorporation_date',
  /** column name */
  Industry = 'industry',
  /** column name */
  MarqueeCustomers = 'marquee_customers',
  /** column name */
  MarqueeInvestors = 'marquee_investors',
  /** column name */
  McaProfile = 'mca_profile',
  /** column name */
  McaSignatories = 'mca_signatories',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MinTradableValue = 'min_tradable_value',
  /** column name */
  Name = 'name',
  /** column name */
  OpsRm = 'ops_rm',
  /** column name */
  Pan = 'pan',
  /** column name */
  RejectReason = 'reject_reason',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  SalesRm = 'sales_rm',
  /** column name */
  ShareholderInfo = 'shareholder_info',
  /** column name */
  ShareholderInfoDate = 'shareholder_info_date',
  /** column name */
  StateId = 'state_id',
  /** column name */
  Status = 'status',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  TotalCustomers = 'total_customers',
  /** column name */
  TotalFunding = 'total_funding',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Version = 'version',
  /** column name */
  Website = 'website',
  /** column name */
  ZipCode = 'zip_code'
}

export type Company_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Company_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Company_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Company_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Company_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Company_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Bool_Exp;
};

/** columns and relationships of "company_user" */
export type Company_User = {
  __typename?: 'company_user';
  /** An object relationship */
  company: Company;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  pan?: Maybe<Scalars['String']>;
  privacy_policy_version: Scalars['Int'];
  /** An object relationship */
  ref_user_type: Ref_User_Type;
  terms_conditions_version: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
  /** An object relationship */
  user: User;
  user_id: Scalars['Int'];
  user_type: Ref_User_Type_Enum;
};

/** aggregated selection of "company_user" */
export type Company_User_Aggregate = {
  __typename?: 'company_user_aggregate';
  aggregate?: Maybe<Company_User_Aggregate_Fields>;
  nodes: Array<Company_User>;
};

export type Company_User_Aggregate_Bool_Exp = {
  count?: InputMaybe<Company_User_Aggregate_Bool_Exp_Count>;
};

export type Company_User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Company_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "company_user" */
export type Company_User_Aggregate_Fields = {
  __typename?: 'company_user_aggregate_fields';
  avg?: Maybe<Company_User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_User_Max_Fields>;
  min?: Maybe<Company_User_Min_Fields>;
  stddev?: Maybe<Company_User_Stddev_Fields>;
  stddev_pop?: Maybe<Company_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_User_Stddev_Samp_Fields>;
  sum?: Maybe<Company_User_Sum_Fields>;
  var_pop?: Maybe<Company_User_Var_Pop_Fields>;
  var_samp?: Maybe<Company_User_Var_Samp_Fields>;
  variance?: Maybe<Company_User_Variance_Fields>;
};


/** aggregate fields of "company_user" */
export type Company_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_user" */
export type Company_User_Aggregate_Order_By = {
  avg?: InputMaybe<Company_User_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_User_Max_Order_By>;
  min?: InputMaybe<Company_User_Min_Order_By>;
  stddev?: InputMaybe<Company_User_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Company_User_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Company_User_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Company_User_Sum_Order_By>;
  var_pop?: InputMaybe<Company_User_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Company_User_Var_Samp_Order_By>;
  variance?: InputMaybe<Company_User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "company_user" */
export type Company_User_Arr_Rel_Insert_Input = {
  data: Array<Company_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_User_On_Conflict>;
};

/** aggregate avg on columns */
export type Company_User_Avg_Fields = {
  __typename?: 'company_user_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  privacy_policy_version?: Maybe<Scalars['Float']>;
  terms_conditions_version?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "company_user" */
export type Company_User_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  privacy_policy_version?: InputMaybe<Order_By>;
  terms_conditions_version?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "company_user". All fields are combined with a logical 'AND'. */
export type Company_User_Bool_Exp = {
  _and?: InputMaybe<Array<Company_User_Bool_Exp>>;
  _not?: InputMaybe<Company_User_Bool_Exp>;
  _or?: InputMaybe<Array<Company_User_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  pan?: InputMaybe<String_Comparison_Exp>;
  privacy_policy_version?: InputMaybe<Int_Comparison_Exp>;
  ref_user_type?: InputMaybe<Ref_User_Type_Bool_Exp>;
  terms_conditions_version?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
  user_type?: InputMaybe<Ref_User_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_user" */
export enum Company_User_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyUserPkey = 'company_user_pkey',
  /** unique or primary key constraint on columns "user_id" */
  CompanyUserUserIdIdx = 'company_user_user_id_idx'
}

/** input type for incrementing numeric columns in table "company_user" */
export type Company_User_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  privacy_policy_version?: InputMaybe<Scalars['Int']>;
  terms_conditions_version?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_user" */
export type Company_User_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  pan?: InputMaybe<Scalars['String']>;
  privacy_policy_version?: InputMaybe<Scalars['Int']>;
  ref_user_type?: InputMaybe<Ref_User_Type_Obj_Rel_Insert_Input>;
  terms_conditions_version?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']>;
  user_type?: InputMaybe<Ref_User_Type_Enum>;
};

/** aggregate max on columns */
export type Company_User_Max_Fields = {
  __typename?: 'company_user_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pan?: Maybe<Scalars['String']>;
  privacy_policy_version?: Maybe<Scalars['Int']>;
  terms_conditions_version?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "company_user" */
export type Company_User_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pan?: InputMaybe<Order_By>;
  privacy_policy_version?: InputMaybe<Order_By>;
  terms_conditions_version?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_User_Min_Fields = {
  __typename?: 'company_user_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pan?: Maybe<Scalars['String']>;
  privacy_policy_version?: Maybe<Scalars['Int']>;
  terms_conditions_version?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "company_user" */
export type Company_User_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pan?: InputMaybe<Order_By>;
  privacy_policy_version?: InputMaybe<Order_By>;
  terms_conditions_version?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_user" */
export type Company_User_Mutation_Response = {
  __typename?: 'company_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_User>;
};

/** on_conflict condition type for table "company_user" */
export type Company_User_On_Conflict = {
  constraint: Company_User_Constraint;
  update_columns?: Array<Company_User_Update_Column>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};

/** Ordering options when selecting data from "company_user". */
export type Company_User_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pan?: InputMaybe<Order_By>;
  privacy_policy_version?: InputMaybe<Order_By>;
  ref_user_type?: InputMaybe<Ref_User_Type_Order_By>;
  terms_conditions_version?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_user */
export type Company_User_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "company_user" */
export enum Company_User_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Pan = 'pan',
  /** column name */
  PrivacyPolicyVersion = 'privacy_policy_version',
  /** column name */
  TermsConditionsVersion = 'terms_conditions_version',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserType = 'user_type'
}

/** input type for updating data in table "company_user" */
export type Company_User_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  pan?: InputMaybe<Scalars['String']>;
  privacy_policy_version?: InputMaybe<Scalars['Int']>;
  terms_conditions_version?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
  user_type?: InputMaybe<Ref_User_Type_Enum>;
};

/** aggregate stddev on columns */
export type Company_User_Stddev_Fields = {
  __typename?: 'company_user_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  privacy_policy_version?: Maybe<Scalars['Float']>;
  terms_conditions_version?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "company_user" */
export type Company_User_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  privacy_policy_version?: InputMaybe<Order_By>;
  terms_conditions_version?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Company_User_Stddev_Pop_Fields = {
  __typename?: 'company_user_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  privacy_policy_version?: Maybe<Scalars['Float']>;
  terms_conditions_version?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "company_user" */
export type Company_User_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  privacy_policy_version?: InputMaybe<Order_By>;
  terms_conditions_version?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Company_User_Stddev_Samp_Fields = {
  __typename?: 'company_user_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  privacy_policy_version?: Maybe<Scalars['Float']>;
  terms_conditions_version?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "company_user" */
export type Company_User_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  privacy_policy_version?: InputMaybe<Order_By>;
  terms_conditions_version?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "company_user" */
export type Company_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_User_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  pan?: InputMaybe<Scalars['String']>;
  privacy_policy_version?: InputMaybe<Scalars['Int']>;
  terms_conditions_version?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
  user_type?: InputMaybe<Ref_User_Type_Enum>;
};

/** aggregate sum on columns */
export type Company_User_Sum_Fields = {
  __typename?: 'company_user_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  privacy_policy_version?: Maybe<Scalars['Int']>;
  terms_conditions_version?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "company_user" */
export type Company_User_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  privacy_policy_version?: InputMaybe<Order_By>;
  terms_conditions_version?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "company_user" */
export enum Company_User_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Pan = 'pan',
  /** column name */
  PrivacyPolicyVersion = 'privacy_policy_version',
  /** column name */
  TermsConditionsVersion = 'terms_conditions_version',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserType = 'user_type'
}

export type Company_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_User_Var_Pop_Fields = {
  __typename?: 'company_user_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  privacy_policy_version?: Maybe<Scalars['Float']>;
  terms_conditions_version?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "company_user" */
export type Company_User_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  privacy_policy_version?: InputMaybe<Order_By>;
  terms_conditions_version?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Company_User_Var_Samp_Fields = {
  __typename?: 'company_user_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  privacy_policy_version?: Maybe<Scalars['Float']>;
  terms_conditions_version?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "company_user" */
export type Company_User_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  privacy_policy_version?: InputMaybe<Order_By>;
  terms_conditions_version?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Company_User_Variance_Fields = {
  __typename?: 'company_user_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  privacy_policy_version?: Maybe<Scalars['Float']>;
  terms_conditions_version?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "company_user" */
export type Company_User_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  privacy_policy_version?: InputMaybe<Order_By>;
  terms_conditions_version?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Company_Var_Pop_Fields = {
  __typename?: 'company_var_pop_fields';
  approved_by?: Maybe<Scalars['Float']>;
  ccs_agreement_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  estimated_arr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min_tradable_value?: Maybe<Scalars['Float']>;
  ops_rm?: Maybe<Scalars['Float']>;
  sales_rm?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
  total_customers?: Maybe<Scalars['Float']>;
  total_funding?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Var_Samp_Fields = {
  __typename?: 'company_var_samp_fields';
  approved_by?: Maybe<Scalars['Float']>;
  ccs_agreement_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  estimated_arr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min_tradable_value?: Maybe<Scalars['Float']>;
  ops_rm?: Maybe<Scalars['Float']>;
  sales_rm?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
  total_customers?: Maybe<Scalars['Float']>;
  total_funding?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Variance_Fields = {
  __typename?: 'company_variance_fields';
  approved_by?: Maybe<Scalars['Float']>;
  ccs_agreement_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  estimated_arr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min_tradable_value?: Maybe<Scalars['Float']>;
  ops_rm?: Maybe<Scalars['Float']>;
  sales_rm?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
  total_customers?: Maybe<Scalars['Float']>;
  total_funding?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "deal" */
export type Deal = {
  __typename?: 'deal';
  bid_closure: Scalars['timestamptz'];
  /** An array relationship */
  bids: Array<Bid>;
  /** An aggregate relationship */
  bids_aggregate: Bid_Aggregate;
  /** An object relationship */
  company: Company;
  company_id: Scalars['Int'];
  /** An object relationship */
  company_score: Company_Score;
  company_score_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deal_expiry: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "highest_bid" */
  highest_bid?: Maybe<Array<Bid>>;
  id: Scalars['Int'];
  /** A computed field, executes function "deal_status" */
  status?: Maybe<Scalars['String']>;
  /** An array relationship */
  trades: Array<Trade>;
  /** An aggregate relationship */
  trades_aggregate: Trade_Aggregate;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
  your_bids?: Maybe<Array<Bid>>;
};


/** columns and relationships of "deal" */
export type DealBidsArgs = {
  distinct_on?: InputMaybe<Array<Bid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bid_Order_By>>;
  where?: InputMaybe<Bid_Bool_Exp>;
};


/** columns and relationships of "deal" */
export type DealBids_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bid_Order_By>>;
  where?: InputMaybe<Bid_Bool_Exp>;
};


/** columns and relationships of "deal" */
export type DealHighest_BidArgs = {
  distinct_on?: InputMaybe<Array<Bid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bid_Order_By>>;
  where?: InputMaybe<Bid_Bool_Exp>;
};


/** columns and relationships of "deal" */
export type DealTradesArgs = {
  distinct_on?: InputMaybe<Array<Trade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Order_By>>;
  where?: InputMaybe<Trade_Bool_Exp>;
};


/** columns and relationships of "deal" */
export type DealTrades_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Order_By>>;
  where?: InputMaybe<Trade_Bool_Exp>;
};


/** columns and relationships of "deal" */
export type DealYour_BidsArgs = {
  distinct_on?: InputMaybe<Array<Bid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bid_Order_By>>;
  where?: InputMaybe<Bid_Bool_Exp>;
};

/** aggregated selection of "deal" */
export type Deal_Aggregate = {
  __typename?: 'deal_aggregate';
  aggregate?: Maybe<Deal_Aggregate_Fields>;
  nodes: Array<Deal>;
};

export type Deal_Aggregate_Bool_Exp = {
  count?: InputMaybe<Deal_Aggregate_Bool_Exp_Count>;
};

export type Deal_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Deal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Deal_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "deal" */
export type Deal_Aggregate_Fields = {
  __typename?: 'deal_aggregate_fields';
  avg?: Maybe<Deal_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Deal_Max_Fields>;
  min?: Maybe<Deal_Min_Fields>;
  stddev?: Maybe<Deal_Stddev_Fields>;
  stddev_pop?: Maybe<Deal_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deal_Stddev_Samp_Fields>;
  sum?: Maybe<Deal_Sum_Fields>;
  var_pop?: Maybe<Deal_Var_Pop_Fields>;
  var_samp?: Maybe<Deal_Var_Samp_Fields>;
  variance?: Maybe<Deal_Variance_Fields>;
};


/** aggregate fields of "deal" */
export type Deal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deal" */
export type Deal_Aggregate_Order_By = {
  avg?: InputMaybe<Deal_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Deal_Max_Order_By>;
  min?: InputMaybe<Deal_Min_Order_By>;
  stddev?: InputMaybe<Deal_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Deal_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Deal_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Deal_Sum_Order_By>;
  var_pop?: InputMaybe<Deal_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Deal_Var_Samp_Order_By>;
  variance?: InputMaybe<Deal_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "deal" */
export type Deal_Arr_Rel_Insert_Input = {
  data: Array<Deal_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_On_Conflict>;
};

/** aggregate avg on columns */
export type Deal_Avg_Fields = {
  __typename?: 'deal_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "deal" */
export type Deal_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "deal". All fields are combined with a logical 'AND'. */
export type Deal_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Bool_Exp>>;
  _not?: InputMaybe<Deal_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Bool_Exp>>;
  bid_closure?: InputMaybe<Timestamptz_Comparison_Exp>;
  bids?: InputMaybe<Bid_Bool_Exp>;
  bids_aggregate?: InputMaybe<Bid_Aggregate_Bool_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  company_score?: InputMaybe<Company_Score_Bool_Exp>;
  company_score_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deal_expiry?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  highest_bid?: InputMaybe<Bid_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  trades?: InputMaybe<Trade_Bool_Exp>;
  trades_aggregate?: InputMaybe<Trade_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  your_bids?: InputMaybe<Bid_Bool_Exp>;
};

/** unique or primary key constraints on table "deal" */
export enum Deal_Constraint {
  /** unique or primary key constraint on columns "company_score_id" */
  DealCompanyScoreIdIdx = 'deal_company_score_id_idx',
  /** unique or primary key constraint on columns "id" */
  DealPkey = 'deal_pkey'
}

/** input type for incrementing numeric columns in table "deal" */
export type Deal_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  company_score_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "deal" */
export type Deal_Insert_Input = {
  bid_closure?: InputMaybe<Scalars['timestamptz']>;
  bids?: InputMaybe<Bid_Arr_Rel_Insert_Input>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_score?: InputMaybe<Company_Score_Obj_Rel_Insert_Input>;
  company_score_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deal_expiry?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  trades?: InputMaybe<Trade_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Deal_Max_Fields = {
  __typename?: 'deal_max_fields';
  bid_closure?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  company_score_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deal_expiry?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "deal" */
export type Deal_Max_Order_By = {
  bid_closure?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_expiry?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Deal_Min_Fields = {
  __typename?: 'deal_min_fields';
  bid_closure?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  company_score_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deal_expiry?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "deal" */
export type Deal_Min_Order_By = {
  bid_closure?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_expiry?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "deal" */
export type Deal_Mutation_Response = {
  __typename?: 'deal_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal>;
};

/** input type for inserting object relation for remote table "deal" */
export type Deal_Obj_Rel_Insert_Input = {
  data: Deal_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_On_Conflict>;
};

/** on_conflict condition type for table "deal" */
export type Deal_On_Conflict = {
  constraint: Deal_Constraint;
  update_columns?: Array<Deal_Update_Column>;
  where?: InputMaybe<Deal_Bool_Exp>;
};

/** Ordering options when selecting data from "deal". */
export type Deal_Order_By = {
  bid_closure?: InputMaybe<Order_By>;
  bids_aggregate?: InputMaybe<Bid_Aggregate_Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_score?: InputMaybe<Company_Score_Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_expiry?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  highest_bid_aggregate?: InputMaybe<Bid_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  trades_aggregate?: InputMaybe<Trade_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  your_bids_aggregate?: InputMaybe<Bid_Aggregate_Order_By>;
};

/** primary key columns input for table: deal */
export type Deal_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "deal" */
export enum Deal_Select_Column {
  /** column name */
  BidClosure = 'bid_closure',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyScoreId = 'company_score_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DealExpiry = 'deal_expiry',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "deal" */
export type Deal_Set_Input = {
  bid_closure?: InputMaybe<Scalars['timestamptz']>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_score_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deal_expiry?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Deal_Stddev_Fields = {
  __typename?: 'deal_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "deal" */
export type Deal_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Deal_Stddev_Pop_Fields = {
  __typename?: 'deal_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "deal" */
export type Deal_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Deal_Stddev_Samp_Fields = {
  __typename?: 'deal_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "deal" */
export type Deal_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "deal" */
export type Deal_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deal_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Stream_Cursor_Value_Input = {
  bid_closure?: InputMaybe<Scalars['timestamptz']>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_score_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deal_expiry?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Deal_Sum_Fields = {
  __typename?: 'deal_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  company_score_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "deal" */
export type Deal_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "deal" */
export enum Deal_Update_Column {
  /** column name */
  BidClosure = 'bid_closure',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyScoreId = 'company_score_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DealExpiry = 'deal_expiry',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Deal_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Deal_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Deal_Var_Pop_Fields = {
  __typename?: 'deal_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "deal" */
export type Deal_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Deal_Var_Samp_Fields = {
  __typename?: 'deal_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "deal" */
export type Deal_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Deal_Variance_Fields = {
  __typename?: 'deal_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  company_score_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "deal" */
export type Deal_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  company_score_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "document_checklist" */
export type Document_Checklist = {
  __typename?: 'document_checklist';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  document_checklist_category_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  ref_document_checklist: Ref_Document_Checklist;
  ref_document_checklist_id: Scalars['Int'];
  /** An object relationship */
  ref_document_checklist_status: Ref_Document_Checklist_Status;
  remarks?: Maybe<Scalars['String']>;
  shared_by_company: Scalars['Boolean'];
  status: Ref_Document_Checklist_Status_Enum;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "document_checklist" */
export type Document_Checklist_Aggregate = {
  __typename?: 'document_checklist_aggregate';
  aggregate?: Maybe<Document_Checklist_Aggregate_Fields>;
  nodes: Array<Document_Checklist>;
};

export type Document_Checklist_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Document_Checklist_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Document_Checklist_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Document_Checklist_Aggregate_Bool_Exp_Count>;
};

export type Document_Checklist_Aggregate_Bool_Exp_Bool_And = {
  arguments: Document_Checklist_Select_Column_Document_Checklist_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Document_Checklist_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Document_Checklist_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Document_Checklist_Select_Column_Document_Checklist_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Document_Checklist_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Document_Checklist_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Document_Checklist_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Document_Checklist_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "document_checklist" */
export type Document_Checklist_Aggregate_Fields = {
  __typename?: 'document_checklist_aggregate_fields';
  avg?: Maybe<Document_Checklist_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Document_Checklist_Max_Fields>;
  min?: Maybe<Document_Checklist_Min_Fields>;
  stddev?: Maybe<Document_Checklist_Stddev_Fields>;
  stddev_pop?: Maybe<Document_Checklist_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Document_Checklist_Stddev_Samp_Fields>;
  sum?: Maybe<Document_Checklist_Sum_Fields>;
  var_pop?: Maybe<Document_Checklist_Var_Pop_Fields>;
  var_samp?: Maybe<Document_Checklist_Var_Samp_Fields>;
  variance?: Maybe<Document_Checklist_Variance_Fields>;
};


/** aggregate fields of "document_checklist" */
export type Document_Checklist_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Checklist_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "document_checklist" */
export type Document_Checklist_Aggregate_Order_By = {
  avg?: InputMaybe<Document_Checklist_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Document_Checklist_Max_Order_By>;
  min?: InputMaybe<Document_Checklist_Min_Order_By>;
  stddev?: InputMaybe<Document_Checklist_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Document_Checklist_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Document_Checklist_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Document_Checklist_Sum_Order_By>;
  var_pop?: InputMaybe<Document_Checklist_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Document_Checklist_Var_Samp_Order_By>;
  variance?: InputMaybe<Document_Checklist_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "document_checklist" */
export type Document_Checklist_Arr_Rel_Insert_Input = {
  data: Array<Document_Checklist_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_Checklist_On_Conflict>;
};

/** aggregate avg on columns */
export type Document_Checklist_Avg_Fields = {
  __typename?: 'document_checklist_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  document_checklist_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "document_checklist" */
export type Document_Checklist_Avg_Order_By = {
  created_by?: InputMaybe<Order_By>;
  document_checklist_category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "document_checklist". All fields are combined with a logical 'AND'. */
export type Document_Checklist_Bool_Exp = {
  _and?: InputMaybe<Array<Document_Checklist_Bool_Exp>>;
  _not?: InputMaybe<Document_Checklist_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Checklist_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  document_checklist_category_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ref_document_checklist?: InputMaybe<Ref_Document_Checklist_Bool_Exp>;
  ref_document_checklist_id?: InputMaybe<Int_Comparison_Exp>;
  ref_document_checklist_status?: InputMaybe<Ref_Document_Checklist_Status_Bool_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
  shared_by_company?: InputMaybe<Boolean_Comparison_Exp>;
  status?: InputMaybe<Ref_Document_Checklist_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "document_checklist_category" */
export type Document_Checklist_Category = {
  __typename?: 'document_checklist_category';
  /** An object relationship */
  company: Company;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  /** An array relationship */
  document_checklists: Array<Document_Checklist>;
  /** An aggregate relationship */
  document_checklists_aggregate: Document_Checklist_Aggregate;
  id: Scalars['Int'];
  is_applicable: Scalars['Boolean'];
  /** An object relationship */
  ref_document_checklist_category: Ref_Document_Checklist_Category;
  ref_document_checklist_category_id: Scalars['Int'];
  sequence: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "document_checklist_category" */
export type Document_Checklist_CategoryDocument_ChecklistsArgs = {
  distinct_on?: InputMaybe<Array<Document_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Checklist_Order_By>>;
  where?: InputMaybe<Document_Checklist_Bool_Exp>;
};


/** columns and relationships of "document_checklist_category" */
export type Document_Checklist_CategoryDocument_Checklists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Checklist_Order_By>>;
  where?: InputMaybe<Document_Checklist_Bool_Exp>;
};

/** aggregated selection of "document_checklist_category" */
export type Document_Checklist_Category_Aggregate = {
  __typename?: 'document_checklist_category_aggregate';
  aggregate?: Maybe<Document_Checklist_Category_Aggregate_Fields>;
  nodes: Array<Document_Checklist_Category>;
};

export type Document_Checklist_Category_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Document_Checklist_Category_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Document_Checklist_Category_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Document_Checklist_Category_Aggregate_Bool_Exp_Count>;
};

export type Document_Checklist_Category_Aggregate_Bool_Exp_Bool_And = {
  arguments: Document_Checklist_Category_Select_Column_Document_Checklist_Category_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Document_Checklist_Category_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Document_Checklist_Category_Select_Column_Document_Checklist_Category_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Document_Checklist_Category_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Document_Checklist_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "document_checklist_category" */
export type Document_Checklist_Category_Aggregate_Fields = {
  __typename?: 'document_checklist_category_aggregate_fields';
  avg?: Maybe<Document_Checklist_Category_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Document_Checklist_Category_Max_Fields>;
  min?: Maybe<Document_Checklist_Category_Min_Fields>;
  stddev?: Maybe<Document_Checklist_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Document_Checklist_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Document_Checklist_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Document_Checklist_Category_Sum_Fields>;
  var_pop?: Maybe<Document_Checklist_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Document_Checklist_Category_Var_Samp_Fields>;
  variance?: Maybe<Document_Checklist_Category_Variance_Fields>;
};


/** aggregate fields of "document_checklist_category" */
export type Document_Checklist_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Checklist_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "document_checklist_category" */
export type Document_Checklist_Category_Aggregate_Order_By = {
  avg?: InputMaybe<Document_Checklist_Category_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Document_Checklist_Category_Max_Order_By>;
  min?: InputMaybe<Document_Checklist_Category_Min_Order_By>;
  stddev?: InputMaybe<Document_Checklist_Category_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Document_Checklist_Category_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Document_Checklist_Category_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Document_Checklist_Category_Sum_Order_By>;
  var_pop?: InputMaybe<Document_Checklist_Category_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Document_Checklist_Category_Var_Samp_Order_By>;
  variance?: InputMaybe<Document_Checklist_Category_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "document_checklist_category" */
export type Document_Checklist_Category_Arr_Rel_Insert_Input = {
  data: Array<Document_Checklist_Category_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_Checklist_Category_On_Conflict>;
};

/** aggregate avg on columns */
export type Document_Checklist_Category_Avg_Fields = {
  __typename?: 'document_checklist_category_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "document_checklist_category" */
export type Document_Checklist_Category_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "document_checklist_category". All fields are combined with a logical 'AND'. */
export type Document_Checklist_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Document_Checklist_Category_Bool_Exp>>;
  _not?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Checklist_Category_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  document_checklists?: InputMaybe<Document_Checklist_Bool_Exp>;
  document_checklists_aggregate?: InputMaybe<Document_Checklist_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_applicable?: InputMaybe<Boolean_Comparison_Exp>;
  ref_document_checklist_category?: InputMaybe<Ref_Document_Checklist_Category_Bool_Exp>;
  ref_document_checklist_category_id?: InputMaybe<Int_Comparison_Exp>;
  sequence?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "document_checklist_category" */
export enum Document_Checklist_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentChecklistCategoryPkey = 'document_checklist_category_pkey'
}

/** input type for incrementing numeric columns in table "document_checklist_category" */
export type Document_Checklist_Category_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ref_document_checklist_category_id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "document_checklist_category" */
export type Document_Checklist_Category_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  document_checklists?: InputMaybe<Document_Checklist_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  is_applicable?: InputMaybe<Scalars['Boolean']>;
  ref_document_checklist_category?: InputMaybe<Ref_Document_Checklist_Category_Obj_Rel_Insert_Input>;
  ref_document_checklist_category_id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Document_Checklist_Category_Max_Fields = {
  __typename?: 'document_checklist_category_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "document_checklist_category" */
export type Document_Checklist_Category_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Document_Checklist_Category_Min_Fields = {
  __typename?: 'document_checklist_category_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "document_checklist_category" */
export type Document_Checklist_Category_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "document_checklist_category" */
export type Document_Checklist_Category_Mutation_Response = {
  __typename?: 'document_checklist_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Document_Checklist_Category>;
};

/** on_conflict condition type for table "document_checklist_category" */
export type Document_Checklist_Category_On_Conflict = {
  constraint: Document_Checklist_Category_Constraint;
  update_columns?: Array<Document_Checklist_Category_Update_Column>;
  where?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "document_checklist_category". */
export type Document_Checklist_Category_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  document_checklists_aggregate?: InputMaybe<Document_Checklist_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_applicable?: InputMaybe<Order_By>;
  ref_document_checklist_category?: InputMaybe<Ref_Document_Checklist_Category_Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: document_checklist_category */
export type Document_Checklist_Category_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "document_checklist_category" */
export enum Document_Checklist_Category_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsApplicable = 'is_applicable',
  /** column name */
  RefDocumentChecklistCategoryId = 'ref_document_checklist_category_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "document_checklist_category_aggregate_bool_exp_bool_and_arguments_columns" columns of table "document_checklist_category" */
export enum Document_Checklist_Category_Select_Column_Document_Checklist_Category_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsApplicable = 'is_applicable'
}

/** select "document_checklist_category_aggregate_bool_exp_bool_or_arguments_columns" columns of table "document_checklist_category" */
export enum Document_Checklist_Category_Select_Column_Document_Checklist_Category_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsApplicable = 'is_applicable'
}

/** input type for updating data in table "document_checklist_category" */
export type Document_Checklist_Category_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_applicable?: InputMaybe<Scalars['Boolean']>;
  ref_document_checklist_category_id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Document_Checklist_Category_Stddev_Fields = {
  __typename?: 'document_checklist_category_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "document_checklist_category" */
export type Document_Checklist_Category_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Document_Checklist_Category_Stddev_Pop_Fields = {
  __typename?: 'document_checklist_category_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "document_checklist_category" */
export type Document_Checklist_Category_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Document_Checklist_Category_Stddev_Samp_Fields = {
  __typename?: 'document_checklist_category_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "document_checklist_category" */
export type Document_Checklist_Category_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "document_checklist_category" */
export type Document_Checklist_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Checklist_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Checklist_Category_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_applicable?: InputMaybe<Scalars['Boolean']>;
  ref_document_checklist_category_id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Document_Checklist_Category_Sum_Fields = {
  __typename?: 'document_checklist_category_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "document_checklist_category" */
export type Document_Checklist_Category_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "document_checklist_category" */
export enum Document_Checklist_Category_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsApplicable = 'is_applicable',
  /** column name */
  RefDocumentChecklistCategoryId = 'ref_document_checklist_category_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Document_Checklist_Category_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Document_Checklist_Category_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Checklist_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Document_Checklist_Category_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Document_Checklist_Category_Var_Pop_Fields = {
  __typename?: 'document_checklist_category_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "document_checklist_category" */
export type Document_Checklist_Category_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Document_Checklist_Category_Var_Samp_Fields = {
  __typename?: 'document_checklist_category_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "document_checklist_category" */
export type Document_Checklist_Category_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Document_Checklist_Category_Variance_Fields = {
  __typename?: 'document_checklist_category_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "document_checklist_category" */
export type Document_Checklist_Category_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "document_checklist" */
export enum Document_Checklist_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentChecklistPkey = 'document_checklist_pkey'
}

/** input type for incrementing numeric columns in table "document_checklist" */
export type Document_Checklist_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  document_checklist_category_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ref_document_checklist_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "document_checklist" */
export type Document_Checklist_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  document_checklist_category_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ref_document_checklist?: InputMaybe<Ref_Document_Checklist_Obj_Rel_Insert_Input>;
  ref_document_checklist_id?: InputMaybe<Scalars['Int']>;
  ref_document_checklist_status?: InputMaybe<Ref_Document_Checklist_Status_Obj_Rel_Insert_Input>;
  remarks?: InputMaybe<Scalars['String']>;
  shared_by_company?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Ref_Document_Checklist_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Document_Checklist_Max_Fields = {
  __typename?: 'document_checklist_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  document_checklist_category_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ref_document_checklist_id?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "document_checklist" */
export type Document_Checklist_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  document_checklist_category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_id?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Document_Checklist_Min_Fields = {
  __typename?: 'document_checklist_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  document_checklist_category_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ref_document_checklist_id?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "document_checklist" */
export type Document_Checklist_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  document_checklist_category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_id?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "document_checklist" */
export type Document_Checklist_Mutation_Response = {
  __typename?: 'document_checklist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Document_Checklist>;
};

/** on_conflict condition type for table "document_checklist" */
export type Document_Checklist_On_Conflict = {
  constraint: Document_Checklist_Constraint;
  update_columns?: Array<Document_Checklist_Update_Column>;
  where?: InputMaybe<Document_Checklist_Bool_Exp>;
};

/** Ordering options when selecting data from "document_checklist". */
export type Document_Checklist_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  document_checklist_category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist?: InputMaybe<Ref_Document_Checklist_Order_By>;
  ref_document_checklist_id?: InputMaybe<Order_By>;
  ref_document_checklist_status?: InputMaybe<Ref_Document_Checklist_Status_Order_By>;
  remarks?: InputMaybe<Order_By>;
  shared_by_company?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: document_checklist */
export type Document_Checklist_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "document_checklist" */
export enum Document_Checklist_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DocumentChecklistCategoryId = 'document_checklist_category_id',
  /** column name */
  Id = 'id',
  /** column name */
  RefDocumentChecklistId = 'ref_document_checklist_id',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  SharedByCompany = 'shared_by_company',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "document_checklist_aggregate_bool_exp_bool_and_arguments_columns" columns of table "document_checklist" */
export enum Document_Checklist_Select_Column_Document_Checklist_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  SharedByCompany = 'shared_by_company'
}

/** select "document_checklist_aggregate_bool_exp_bool_or_arguments_columns" columns of table "document_checklist" */
export enum Document_Checklist_Select_Column_Document_Checklist_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  SharedByCompany = 'shared_by_company'
}

/** input type for updating data in table "document_checklist" */
export type Document_Checklist_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  document_checklist_category_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ref_document_checklist_id?: InputMaybe<Scalars['Int']>;
  remarks?: InputMaybe<Scalars['String']>;
  shared_by_company?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Ref_Document_Checklist_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Document_Checklist_Stddev_Fields = {
  __typename?: 'document_checklist_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  document_checklist_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "document_checklist" */
export type Document_Checklist_Stddev_Order_By = {
  created_by?: InputMaybe<Order_By>;
  document_checklist_category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Document_Checklist_Stddev_Pop_Fields = {
  __typename?: 'document_checklist_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  document_checklist_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "document_checklist" */
export type Document_Checklist_Stddev_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  document_checklist_category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Document_Checklist_Stddev_Samp_Fields = {
  __typename?: 'document_checklist_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  document_checklist_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "document_checklist" */
export type Document_Checklist_Stddev_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  document_checklist_category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "document_checklist" */
export type Document_Checklist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Checklist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Checklist_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  document_checklist_category_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ref_document_checklist_id?: InputMaybe<Scalars['Int']>;
  remarks?: InputMaybe<Scalars['String']>;
  shared_by_company?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Ref_Document_Checklist_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Document_Checklist_Sum_Fields = {
  __typename?: 'document_checklist_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  document_checklist_category_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ref_document_checklist_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "document_checklist" */
export type Document_Checklist_Sum_Order_By = {
  created_by?: InputMaybe<Order_By>;
  document_checklist_category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "document_checklist" */
export enum Document_Checklist_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DocumentChecklistCategoryId = 'document_checklist_category_id',
  /** column name */
  Id = 'id',
  /** column name */
  RefDocumentChecklistId = 'ref_document_checklist_id',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  SharedByCompany = 'shared_by_company',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Document_Checklist_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Document_Checklist_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Checklist_Set_Input>;
  /** filter the rows which have to be updated */
  where: Document_Checklist_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Document_Checklist_Var_Pop_Fields = {
  __typename?: 'document_checklist_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  document_checklist_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "document_checklist" */
export type Document_Checklist_Var_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  document_checklist_category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Document_Checklist_Var_Samp_Fields = {
  __typename?: 'document_checklist_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  document_checklist_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "document_checklist" */
export type Document_Checklist_Var_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  document_checklist_category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Document_Checklist_Variance_Fields = {
  __typename?: 'document_checklist_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  document_checklist_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "document_checklist" */
export type Document_Checklist_Variance_Order_By = {
  created_by?: InputMaybe<Order_By>;
  document_checklist_category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_document_checklist_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "email_events" */
export type Email_Events = {
  __typename?: 'email_events';
  attachments?: Maybe<Scalars['jsonb']>;
  bcc?: Maybe<Scalars['jsonb']>;
  cc?: Maybe<Scalars['jsonb']>;
  code: Scalars['String'];
  /** An array relationship */
  company_email_logs: Array<Company_Email_Log>;
  /** An aggregate relationship */
  company_email_logs_aggregate: Company_Email_Log_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  failure_reason?: Maybe<Scalars['String']>;
  from: Scalars['jsonb'];
  id: Scalars['Int'];
  metadata?: Maybe<Scalars['jsonb']>;
  reply_to?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  to: Scalars['jsonb'];
  type: Ref_Email_Events_Type_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  variables?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "email_events" */
export type Email_EventsAttachmentsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "email_events" */
export type Email_EventsBccArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "email_events" */
export type Email_EventsCcArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "email_events" */
export type Email_EventsCompany_Email_LogsArgs = {
  distinct_on?: InputMaybe<Array<Company_Email_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Email_Log_Order_By>>;
  where?: InputMaybe<Company_Email_Log_Bool_Exp>;
};


/** columns and relationships of "email_events" */
export type Email_EventsCompany_Email_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Email_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Email_Log_Order_By>>;
  where?: InputMaybe<Company_Email_Log_Bool_Exp>;
};


/** columns and relationships of "email_events" */
export type Email_EventsFromArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "email_events" */
export type Email_EventsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "email_events" */
export type Email_EventsReply_ToArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "email_events" */
export type Email_EventsToArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "email_events" */
export type Email_EventsVariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "email_events" */
export type Email_Events_Aggregate = {
  __typename?: 'email_events_aggregate';
  aggregate?: Maybe<Email_Events_Aggregate_Fields>;
  nodes: Array<Email_Events>;
};

/** aggregate fields of "email_events" */
export type Email_Events_Aggregate_Fields = {
  __typename?: 'email_events_aggregate_fields';
  avg?: Maybe<Email_Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Email_Events_Max_Fields>;
  min?: Maybe<Email_Events_Min_Fields>;
  stddev?: Maybe<Email_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Email_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Email_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Email_Events_Sum_Fields>;
  var_pop?: Maybe<Email_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Email_Events_Var_Samp_Fields>;
  variance?: Maybe<Email_Events_Variance_Fields>;
};


/** aggregate fields of "email_events" */
export type Email_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Email_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Email_Events_Append_Input = {
  attachments?: InputMaybe<Scalars['jsonb']>;
  bcc?: InputMaybe<Scalars['jsonb']>;
  cc?: InputMaybe<Scalars['jsonb']>;
  from?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  reply_to?: InputMaybe<Scalars['jsonb']>;
  to?: InputMaybe<Scalars['jsonb']>;
  variables?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Email_Events_Avg_Fields = {
  __typename?: 'email_events_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "email_events". All fields are combined with a logical 'AND'. */
export type Email_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Email_Events_Bool_Exp>>;
  _not?: InputMaybe<Email_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Email_Events_Bool_Exp>>;
  attachments?: InputMaybe<Jsonb_Comparison_Exp>;
  bcc?: InputMaybe<Jsonb_Comparison_Exp>;
  cc?: InputMaybe<Jsonb_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  company_email_logs?: InputMaybe<Company_Email_Log_Bool_Exp>;
  company_email_logs_aggregate?: InputMaybe<Company_Email_Log_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  failure_reason?: InputMaybe<String_Comparison_Exp>;
  from?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  reply_to?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  to?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<Ref_Email_Events_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  variables?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_events" */
export enum Email_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailEventsPkey = 'email_events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Email_Events_Delete_At_Path_Input = {
  attachments?: InputMaybe<Array<Scalars['String']>>;
  bcc?: InputMaybe<Array<Scalars['String']>>;
  cc?: InputMaybe<Array<Scalars['String']>>;
  from?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
  reply_to?: InputMaybe<Array<Scalars['String']>>;
  to?: InputMaybe<Array<Scalars['String']>>;
  variables?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Email_Events_Delete_Elem_Input = {
  attachments?: InputMaybe<Scalars['Int']>;
  bcc?: InputMaybe<Scalars['Int']>;
  cc?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
  reply_to?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['Int']>;
  variables?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Email_Events_Delete_Key_Input = {
  attachments?: InputMaybe<Scalars['String']>;
  bcc?: InputMaybe<Scalars['String']>;
  cc?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  reply_to?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
  variables?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "email_events" */
export type Email_Events_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "email_events" */
export type Email_Events_Insert_Input = {
  attachments?: InputMaybe<Scalars['jsonb']>;
  bcc?: InputMaybe<Scalars['jsonb']>;
  cc?: InputMaybe<Scalars['jsonb']>;
  code?: InputMaybe<Scalars['String']>;
  company_email_logs?: InputMaybe<Company_Email_Log_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  failure_reason?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  reply_to?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Ref_Email_Events_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  variables?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Email_Events_Max_Fields = {
  __typename?: 'email_events_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  failure_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Email_Events_Min_Fields = {
  __typename?: 'email_events_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  failure_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "email_events" */
export type Email_Events_Mutation_Response = {
  __typename?: 'email_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Events>;
};

/** input type for inserting object relation for remote table "email_events" */
export type Email_Events_Obj_Rel_Insert_Input = {
  data: Email_Events_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Email_Events_On_Conflict>;
};

/** on_conflict condition type for table "email_events" */
export type Email_Events_On_Conflict = {
  constraint: Email_Events_Constraint;
  update_columns?: Array<Email_Events_Update_Column>;
  where?: InputMaybe<Email_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "email_events". */
export type Email_Events_Order_By = {
  attachments?: InputMaybe<Order_By>;
  bcc?: InputMaybe<Order_By>;
  cc?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  company_email_logs_aggregate?: InputMaybe<Company_Email_Log_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  failure_reason?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  reply_to?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  variables?: InputMaybe<Order_By>;
};

/** primary key columns input for table: email_events */
export type Email_Events_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Email_Events_Prepend_Input = {
  attachments?: InputMaybe<Scalars['jsonb']>;
  bcc?: InputMaybe<Scalars['jsonb']>;
  cc?: InputMaybe<Scalars['jsonb']>;
  from?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  reply_to?: InputMaybe<Scalars['jsonb']>;
  to?: InputMaybe<Scalars['jsonb']>;
  variables?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "email_events" */
export enum Email_Events_Select_Column {
  /** column name */
  Attachments = 'attachments',
  /** column name */
  Bcc = 'bcc',
  /** column name */
  Cc = 'cc',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ReplyTo = 'reply_to',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  To = 'to',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Variables = 'variables'
}

/** input type for updating data in table "email_events" */
export type Email_Events_Set_Input = {
  attachments?: InputMaybe<Scalars['jsonb']>;
  bcc?: InputMaybe<Scalars['jsonb']>;
  cc?: InputMaybe<Scalars['jsonb']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  failure_reason?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  reply_to?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Ref_Email_Events_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  variables?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Email_Events_Stddev_Fields = {
  __typename?: 'email_events_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Email_Events_Stddev_Pop_Fields = {
  __typename?: 'email_events_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Email_Events_Stddev_Samp_Fields = {
  __typename?: 'email_events_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "email_events" */
export type Email_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Email_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Email_Events_Stream_Cursor_Value_Input = {
  attachments?: InputMaybe<Scalars['jsonb']>;
  bcc?: InputMaybe<Scalars['jsonb']>;
  cc?: InputMaybe<Scalars['jsonb']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  failure_reason?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  reply_to?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Ref_Email_Events_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  variables?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type Email_Events_Sum_Fields = {
  __typename?: 'email_events_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "email_events" */
export enum Email_Events_Update_Column {
  /** column name */
  Attachments = 'attachments',
  /** column name */
  Bcc = 'bcc',
  /** column name */
  Cc = 'cc',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ReplyTo = 'reply_to',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  To = 'to',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Variables = 'variables'
}

export type Email_Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Email_Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Email_Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Email_Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Email_Events_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Email_Events_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Email_Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Email_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Email_Events_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Email_Events_Var_Pop_Fields = {
  __typename?: 'email_events_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Email_Events_Var_Samp_Fields = {
  __typename?: 'email_events_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Email_Events_Variance_Fields = {
  __typename?: 'email_events_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Pivot Table between scheduled_emi and tds */
export type Emi_Tds = {
  __typename?: 'emi_tds';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  emi_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  scheduled_emi: Scheduled_Emi;
  /** An object relationship */
  tds: Tds;
  tds_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "emi_tds" */
export type Emi_Tds_Aggregate = {
  __typename?: 'emi_tds_aggregate';
  aggregate?: Maybe<Emi_Tds_Aggregate_Fields>;
  nodes: Array<Emi_Tds>;
};

export type Emi_Tds_Aggregate_Bool_Exp = {
  count?: InputMaybe<Emi_Tds_Aggregate_Bool_Exp_Count>;
};

export type Emi_Tds_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Emi_Tds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Emi_Tds_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "emi_tds" */
export type Emi_Tds_Aggregate_Fields = {
  __typename?: 'emi_tds_aggregate_fields';
  avg?: Maybe<Emi_Tds_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Emi_Tds_Max_Fields>;
  min?: Maybe<Emi_Tds_Min_Fields>;
  stddev?: Maybe<Emi_Tds_Stddev_Fields>;
  stddev_pop?: Maybe<Emi_Tds_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Emi_Tds_Stddev_Samp_Fields>;
  sum?: Maybe<Emi_Tds_Sum_Fields>;
  var_pop?: Maybe<Emi_Tds_Var_Pop_Fields>;
  var_samp?: Maybe<Emi_Tds_Var_Samp_Fields>;
  variance?: Maybe<Emi_Tds_Variance_Fields>;
};


/** aggregate fields of "emi_tds" */
export type Emi_Tds_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Emi_Tds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "emi_tds" */
export type Emi_Tds_Aggregate_Order_By = {
  avg?: InputMaybe<Emi_Tds_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Emi_Tds_Max_Order_By>;
  min?: InputMaybe<Emi_Tds_Min_Order_By>;
  stddev?: InputMaybe<Emi_Tds_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Emi_Tds_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Emi_Tds_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Emi_Tds_Sum_Order_By>;
  var_pop?: InputMaybe<Emi_Tds_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Emi_Tds_Var_Samp_Order_By>;
  variance?: InputMaybe<Emi_Tds_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "emi_tds" */
export type Emi_Tds_Arr_Rel_Insert_Input = {
  data: Array<Emi_Tds_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Emi_Tds_On_Conflict>;
};

/** aggregate avg on columns */
export type Emi_Tds_Avg_Fields = {
  __typename?: 'emi_tds_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tds_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "emi_tds" */
export type Emi_Tds_Avg_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tds_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "emi_tds". All fields are combined with a logical 'AND'. */
export type Emi_Tds_Bool_Exp = {
  _and?: InputMaybe<Array<Emi_Tds_Bool_Exp>>;
  _not?: InputMaybe<Emi_Tds_Bool_Exp>;
  _or?: InputMaybe<Array<Emi_Tds_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  emi_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  scheduled_emi?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  tds?: InputMaybe<Tds_Bool_Exp>;
  tds_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "emi_tds" */
export enum Emi_Tds_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmiTdsPkey = 'emi_tds_pkey'
}

/** input type for incrementing numeric columns in table "emi_tds" */
export type Emi_Tds_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  emi_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  tds_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "emi_tds" */
export type Emi_Tds_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  emi_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  scheduled_emi?: InputMaybe<Scheduled_Emi_Obj_Rel_Insert_Input>;
  tds?: InputMaybe<Tds_Obj_Rel_Insert_Input>;
  tds_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Emi_Tds_Max_Fields = {
  __typename?: 'emi_tds_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  emi_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tds_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "emi_tds" */
export type Emi_Tds_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tds_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Emi_Tds_Min_Fields = {
  __typename?: 'emi_tds_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  emi_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tds_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "emi_tds" */
export type Emi_Tds_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tds_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "emi_tds" */
export type Emi_Tds_Mutation_Response = {
  __typename?: 'emi_tds_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Emi_Tds>;
};

/** on_conflict condition type for table "emi_tds" */
export type Emi_Tds_On_Conflict = {
  constraint: Emi_Tds_Constraint;
  update_columns?: Array<Emi_Tds_Update_Column>;
  where?: InputMaybe<Emi_Tds_Bool_Exp>;
};

/** Ordering options when selecting data from "emi_tds". */
export type Emi_Tds_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  scheduled_emi?: InputMaybe<Scheduled_Emi_Order_By>;
  tds?: InputMaybe<Tds_Order_By>;
  tds_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: emi_tds */
export type Emi_Tds_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "emi_tds" */
export enum Emi_Tds_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EmiId = 'emi_id',
  /** column name */
  Id = 'id',
  /** column name */
  TdsId = 'tds_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "emi_tds" */
export type Emi_Tds_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  emi_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  tds_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Emi_Tds_Stddev_Fields = {
  __typename?: 'emi_tds_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tds_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "emi_tds" */
export type Emi_Tds_Stddev_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tds_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Emi_Tds_Stddev_Pop_Fields = {
  __typename?: 'emi_tds_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tds_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "emi_tds" */
export type Emi_Tds_Stddev_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tds_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Emi_Tds_Stddev_Samp_Fields = {
  __typename?: 'emi_tds_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tds_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "emi_tds" */
export type Emi_Tds_Stddev_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tds_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "emi_tds" */
export type Emi_Tds_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Emi_Tds_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Emi_Tds_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  emi_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  tds_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Emi_Tds_Sum_Fields = {
  __typename?: 'emi_tds_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  emi_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tds_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "emi_tds" */
export type Emi_Tds_Sum_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tds_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "emi_tds" */
export enum Emi_Tds_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EmiId = 'emi_id',
  /** column name */
  Id = 'id',
  /** column name */
  TdsId = 'tds_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Emi_Tds_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Emi_Tds_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Emi_Tds_Set_Input>;
  /** filter the rows which have to be updated */
  where: Emi_Tds_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Emi_Tds_Var_Pop_Fields = {
  __typename?: 'emi_tds_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tds_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "emi_tds" */
export type Emi_Tds_Var_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tds_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Emi_Tds_Var_Samp_Fields = {
  __typename?: 'emi_tds_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tds_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "emi_tds" */
export type Emi_Tds_Var_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tds_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Emi_Tds_Variance_Fields = {
  __typename?: 'emi_tds_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tds_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "emi_tds" */
export type Emi_Tds_Variance_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tds_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

export enum ExportStage {
  All = 'ALL',
  Cold = 'COLD',
  Investor = 'INVESTOR',
  Rejected = 'REJECTED',
  Sql = 'SQL',
  Trade = 'TRADE'
}

/** columns and relationships of "factor_benchmark_range" */
export type Factor_Benchmark_Range = {
  __typename?: 'factor_benchmark_range';
  benchmark_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  max: Scalars['numeric'];
  min: Scalars['numeric'];
  rating: Scalars['numeric'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "factor_benchmark_range" */
export type Factor_Benchmark_Range_Aggregate = {
  __typename?: 'factor_benchmark_range_aggregate';
  aggregate?: Maybe<Factor_Benchmark_Range_Aggregate_Fields>;
  nodes: Array<Factor_Benchmark_Range>;
};

export type Factor_Benchmark_Range_Aggregate_Bool_Exp = {
  count?: InputMaybe<Factor_Benchmark_Range_Aggregate_Bool_Exp_Count>;
};

export type Factor_Benchmark_Range_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Factor_Benchmark_Range_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Factor_Benchmark_Range_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "factor_benchmark_range" */
export type Factor_Benchmark_Range_Aggregate_Fields = {
  __typename?: 'factor_benchmark_range_aggregate_fields';
  avg?: Maybe<Factor_Benchmark_Range_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Factor_Benchmark_Range_Max_Fields>;
  min?: Maybe<Factor_Benchmark_Range_Min_Fields>;
  stddev?: Maybe<Factor_Benchmark_Range_Stddev_Fields>;
  stddev_pop?: Maybe<Factor_Benchmark_Range_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Factor_Benchmark_Range_Stddev_Samp_Fields>;
  sum?: Maybe<Factor_Benchmark_Range_Sum_Fields>;
  var_pop?: Maybe<Factor_Benchmark_Range_Var_Pop_Fields>;
  var_samp?: Maybe<Factor_Benchmark_Range_Var_Samp_Fields>;
  variance?: Maybe<Factor_Benchmark_Range_Variance_Fields>;
};


/** aggregate fields of "factor_benchmark_range" */
export type Factor_Benchmark_Range_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Factor_Benchmark_Range_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Aggregate_Order_By = {
  avg?: InputMaybe<Factor_Benchmark_Range_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Factor_Benchmark_Range_Max_Order_By>;
  min?: InputMaybe<Factor_Benchmark_Range_Min_Order_By>;
  stddev?: InputMaybe<Factor_Benchmark_Range_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Factor_Benchmark_Range_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Factor_Benchmark_Range_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Factor_Benchmark_Range_Sum_Order_By>;
  var_pop?: InputMaybe<Factor_Benchmark_Range_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Factor_Benchmark_Range_Var_Samp_Order_By>;
  variance?: InputMaybe<Factor_Benchmark_Range_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Arr_Rel_Insert_Input = {
  data: Array<Factor_Benchmark_Range_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Factor_Benchmark_Range_On_Conflict>;
};

/** aggregate avg on columns */
export type Factor_Benchmark_Range_Avg_Fields = {
  __typename?: 'factor_benchmark_range_avg_fields';
  benchmark_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Avg_Order_By = {
  benchmark_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "factor_benchmark_range". All fields are combined with a logical 'AND'. */
export type Factor_Benchmark_Range_Bool_Exp = {
  _and?: InputMaybe<Array<Factor_Benchmark_Range_Bool_Exp>>;
  _not?: InputMaybe<Factor_Benchmark_Range_Bool_Exp>;
  _or?: InputMaybe<Array<Factor_Benchmark_Range_Bool_Exp>>;
  benchmark_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  max?: InputMaybe<Numeric_Comparison_Exp>;
  min?: InputMaybe<Numeric_Comparison_Exp>;
  rating?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "factor_benchmark_range" */
export enum Factor_Benchmark_Range_Constraint {
  /** unique or primary key constraint on columns "id" */
  FactorBenchmarkRangePkey = 'factor_benchmark_range_pkey'
}

/** input type for incrementing numeric columns in table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Inc_Input = {
  benchmark_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['numeric']>;
  min?: InputMaybe<Scalars['numeric']>;
  rating?: InputMaybe<Scalars['numeric']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Insert_Input = {
  benchmark_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['numeric']>;
  min?: InputMaybe<Scalars['numeric']>;
  rating?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Factor_Benchmark_Range_Max_Fields = {
  __typename?: 'factor_benchmark_range_max_fields';
  benchmark_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  rating?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Max_Order_By = {
  benchmark_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Factor_Benchmark_Range_Min_Fields = {
  __typename?: 'factor_benchmark_range_min_fields';
  benchmark_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  rating?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Min_Order_By = {
  benchmark_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Mutation_Response = {
  __typename?: 'factor_benchmark_range_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Factor_Benchmark_Range>;
};

/** on_conflict condition type for table "factor_benchmark_range" */
export type Factor_Benchmark_Range_On_Conflict = {
  constraint: Factor_Benchmark_Range_Constraint;
  update_columns?: Array<Factor_Benchmark_Range_Update_Column>;
  where?: InputMaybe<Factor_Benchmark_Range_Bool_Exp>;
};

/** Ordering options when selecting data from "factor_benchmark_range". */
export type Factor_Benchmark_Range_Order_By = {
  benchmark_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: factor_benchmark_range */
export type Factor_Benchmark_Range_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "factor_benchmark_range" */
export enum Factor_Benchmark_Range_Select_Column {
  /** column name */
  BenchmarkId = 'benchmark_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Max = 'max',
  /** column name */
  Min = 'min',
  /** column name */
  Rating = 'rating',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Set_Input = {
  benchmark_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['numeric']>;
  min?: InputMaybe<Scalars['numeric']>;
  rating?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Factor_Benchmark_Range_Stddev_Fields = {
  __typename?: 'factor_benchmark_range_stddev_fields';
  benchmark_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Stddev_Order_By = {
  benchmark_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Factor_Benchmark_Range_Stddev_Pop_Fields = {
  __typename?: 'factor_benchmark_range_stddev_pop_fields';
  benchmark_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Stddev_Pop_Order_By = {
  benchmark_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Factor_Benchmark_Range_Stddev_Samp_Fields = {
  __typename?: 'factor_benchmark_range_stddev_samp_fields';
  benchmark_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Stddev_Samp_Order_By = {
  benchmark_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Factor_Benchmark_Range_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Factor_Benchmark_Range_Stream_Cursor_Value_Input = {
  benchmark_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['numeric']>;
  min?: InputMaybe<Scalars['numeric']>;
  rating?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Factor_Benchmark_Range_Sum_Fields = {
  __typename?: 'factor_benchmark_range_sum_fields';
  benchmark_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['numeric']>;
  min?: Maybe<Scalars['numeric']>;
  rating?: Maybe<Scalars['numeric']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Sum_Order_By = {
  benchmark_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "factor_benchmark_range" */
export enum Factor_Benchmark_Range_Update_Column {
  /** column name */
  BenchmarkId = 'benchmark_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Max = 'max',
  /** column name */
  Min = 'min',
  /** column name */
  Rating = 'rating',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Factor_Benchmark_Range_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Factor_Benchmark_Range_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Factor_Benchmark_Range_Set_Input>;
  /** filter the rows which have to be updated */
  where: Factor_Benchmark_Range_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Factor_Benchmark_Range_Var_Pop_Fields = {
  __typename?: 'factor_benchmark_range_var_pop_fields';
  benchmark_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Var_Pop_Order_By = {
  benchmark_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Factor_Benchmark_Range_Var_Samp_Fields = {
  __typename?: 'factor_benchmark_range_var_samp_fields';
  benchmark_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Var_Samp_Order_By = {
  benchmark_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Factor_Benchmark_Range_Variance_Fields = {
  __typename?: 'factor_benchmark_range_variance_fields';
  benchmark_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "factor_benchmark_range" */
export type Factor_Benchmark_Range_Variance_Order_By = {
  benchmark_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

export type FetchTanTransactionInput = {
  company_id: Scalars['Int'];
};

export type FetchTanTransactionOutput = {
  __typename?: 'fetchTANTransactionOutput';
  message: Scalars['String'];
};

export type FileRegistryUpdatePasswordsInput = {
  file_registry_id: Scalars['Int'];
  password: Scalars['String'];
};

export type FileRegistryUpdatePasswordsOutput = {
  __typename?: 'fileRegistryUpdatePasswordsOutput';
  message: Scalars['String'];
};

/** columns and relationships of "file_registry" */
export type File_Registry = {
  __typename?: 'file_registry';
  category_id: Ref_File_Category_Enum;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  expiry_date?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  location: Scalars['String'];
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  tenant_id: Scalars['Int'];
  tenant_type: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "file_registry" */
export type File_Registry_Aggregate = {
  __typename?: 'file_registry_aggregate';
  aggregate?: Maybe<File_Registry_Aggregate_Fields>;
  nodes: Array<File_Registry>;
};

/** aggregate fields of "file_registry" */
export type File_Registry_Aggregate_Fields = {
  __typename?: 'file_registry_aggregate_fields';
  avg?: Maybe<File_Registry_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<File_Registry_Max_Fields>;
  min?: Maybe<File_Registry_Min_Fields>;
  stddev?: Maybe<File_Registry_Stddev_Fields>;
  stddev_pop?: Maybe<File_Registry_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<File_Registry_Stddev_Samp_Fields>;
  sum?: Maybe<File_Registry_Sum_Fields>;
  var_pop?: Maybe<File_Registry_Var_Pop_Fields>;
  var_samp?: Maybe<File_Registry_Var_Samp_Fields>;
  variance?: Maybe<File_Registry_Variance_Fields>;
};


/** aggregate fields of "file_registry" */
export type File_Registry_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Registry_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type File_Registry_Avg_Fields = {
  __typename?: 'file_registry_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "file_registry". All fields are combined with a logical 'AND'. */
export type File_Registry_Bool_Exp = {
  _and?: InputMaybe<Array<File_Registry_Bool_Exp>>;
  _not?: InputMaybe<File_Registry_Bool_Exp>;
  _or?: InputMaybe<Array<File_Registry_Bool_Exp>>;
  category_id?: InputMaybe<Ref_File_Category_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  expiry_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  tenant_id?: InputMaybe<Int_Comparison_Exp>;
  tenant_type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "file_registry" */
export enum File_Registry_Constraint {
  /** unique or primary key constraint on columns "id" */
  FileRegistryPkey = 'file_registry_pkey'
}

/** input type for incrementing numeric columns in table "file_registry" */
export type File_Registry_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "file_registry" */
export type File_Registry_Insert_Input = {
  category_id?: InputMaybe<Ref_File_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  expiry_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  tenant_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type File_Registry_Max_Fields = {
  __typename?: 'file_registry_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  expiry_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['Int']>;
  tenant_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type File_Registry_Min_Fields = {
  __typename?: 'file_registry_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  expiry_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['Int']>;
  tenant_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "file_registry" */
export type File_Registry_Mutation_Response = {
  __typename?: 'file_registry_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<File_Registry>;
};

/** input type for inserting object relation for remote table "file_registry" */
export type File_Registry_Obj_Rel_Insert_Input = {
  data: File_Registry_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<File_Registry_On_Conflict>;
};

/** on_conflict condition type for table "file_registry" */
export type File_Registry_On_Conflict = {
  constraint: File_Registry_Constraint;
  update_columns?: Array<File_Registry_Update_Column>;
  where?: InputMaybe<File_Registry_Bool_Exp>;
};

/** Ordering options when selecting data from "file_registry". */
export type File_Registry_Order_By = {
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  expiry_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  tenant_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: file_registry */
export type File_Registry_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "file_registry" */
export enum File_Registry_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  ExpiryDate = 'expiry_date',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "file_registry" */
export type File_Registry_Set_Input = {
  category_id?: InputMaybe<Ref_File_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  expiry_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  tenant_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type File_Registry_Stddev_Fields = {
  __typename?: 'file_registry_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type File_Registry_Stddev_Pop_Fields = {
  __typename?: 'file_registry_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type File_Registry_Stddev_Samp_Fields = {
  __typename?: 'file_registry_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "file_registry" */
export type File_Registry_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Registry_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type File_Registry_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Ref_File_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  expiry_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  tenant_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type File_Registry_Sum_Fields = {
  __typename?: 'file_registry_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tenant_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "file_registry" */
export enum File_Registry_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  ExpiryDate = 'expiry_date',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type File_Registry_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<File_Registry_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<File_Registry_Set_Input>;
  /** filter the rows which have to be updated */
  where: File_Registry_Bool_Exp;
};

/** aggregate var_pop on columns */
export type File_Registry_Var_Pop_Fields = {
  __typename?: 'file_registry_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type File_Registry_Var_Samp_Fields = {
  __typename?: 'file_registry_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type File_Registry_Variance_Fields = {
  __typename?: 'file_registry_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type GenerateDailyCustomerReportOutput = {
  __typename?: 'generateDailyCustomerReportOutput';
  message: Scalars['String'];
};

/** columns and relationships of "integration_auth" */
export type Integration_Auth = {
  __typename?: 'integration_auth';
  access_token?: Maybe<Scalars['String']>;
  /** An object relationship */
  bank_account?: Maybe<Account>;
  /** An object relationship */
  banking_partner?: Maybe<Banking_Partner_Integration>;
  /** An object relationship */
  company: Company;
  company_id: Scalars['Int'];
  /** An array relationship */
  company_integrations: Array<Company_Integration>;
  /** An aggregate relationship */
  company_integrations_aggregate: Company_Integration_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  expiry?: Maybe<Scalars['float8']>;
  generated_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  last_used?: Maybe<Scalars['timestamptz']>;
  metadata?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  ref_document_checklist_integration?: Maybe<Ref_Document_Checklist_Integration>;
  refresh_token?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  source_account_id?: Maybe<Scalars['String']>;
  source_account_int_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "integration_auth" */
export type Integration_AuthCompany_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_Order_By>>;
  where?: InputMaybe<Company_Integration_Bool_Exp>;
};


/** columns and relationships of "integration_auth" */
export type Integration_AuthCompany_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_Order_By>>;
  where?: InputMaybe<Company_Integration_Bool_Exp>;
};


/** columns and relationships of "integration_auth" */
export type Integration_AuthMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "integration_auth" */
export type Integration_Auth_Aggregate = {
  __typename?: 'integration_auth_aggregate';
  aggregate?: Maybe<Integration_Auth_Aggregate_Fields>;
  nodes: Array<Integration_Auth>;
};

export type Integration_Auth_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Integration_Auth_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Integration_Auth_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Integration_Auth_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Integration_Auth_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Integration_Auth_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Integration_Auth_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Integration_Auth_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Integration_Auth_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Integration_Auth_Aggregate_Bool_Exp_Var_Samp>;
};

export type Integration_Auth_Aggregate_Bool_Exp_Avg = {
  arguments: Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Integration_Auth_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Integration_Auth_Aggregate_Bool_Exp_Corr = {
  arguments: Integration_Auth_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Integration_Auth_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Integration_Auth_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Integration_Auth_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Integration_Auth_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Integration_Auth_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Integration_Auth_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Integration_Auth_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Integration_Auth_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Integration_Auth_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Integration_Auth_Aggregate_Bool_Exp_Max = {
  arguments: Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Integration_Auth_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Integration_Auth_Aggregate_Bool_Exp_Min = {
  arguments: Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Integration_Auth_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Integration_Auth_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Integration_Auth_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Integration_Auth_Aggregate_Bool_Exp_Sum = {
  arguments: Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Integration_Auth_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Integration_Auth_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Integration_Auth_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "integration_auth" */
export type Integration_Auth_Aggregate_Fields = {
  __typename?: 'integration_auth_aggregate_fields';
  avg?: Maybe<Integration_Auth_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Integration_Auth_Max_Fields>;
  min?: Maybe<Integration_Auth_Min_Fields>;
  stddev?: Maybe<Integration_Auth_Stddev_Fields>;
  stddev_pop?: Maybe<Integration_Auth_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Integration_Auth_Stddev_Samp_Fields>;
  sum?: Maybe<Integration_Auth_Sum_Fields>;
  var_pop?: Maybe<Integration_Auth_Var_Pop_Fields>;
  var_samp?: Maybe<Integration_Auth_Var_Samp_Fields>;
  variance?: Maybe<Integration_Auth_Variance_Fields>;
};


/** aggregate fields of "integration_auth" */
export type Integration_Auth_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Integration_Auth_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "integration_auth" */
export type Integration_Auth_Aggregate_Order_By = {
  avg?: InputMaybe<Integration_Auth_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Integration_Auth_Max_Order_By>;
  min?: InputMaybe<Integration_Auth_Min_Order_By>;
  stddev?: InputMaybe<Integration_Auth_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Integration_Auth_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Integration_Auth_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Integration_Auth_Sum_Order_By>;
  var_pop?: InputMaybe<Integration_Auth_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Integration_Auth_Var_Samp_Order_By>;
  variance?: InputMaybe<Integration_Auth_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Integration_Auth_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "integration_auth" */
export type Integration_Auth_Arr_Rel_Insert_Input = {
  data: Array<Integration_Auth_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Integration_Auth_On_Conflict>;
};

/** aggregate avg on columns */
export type Integration_Auth_Avg_Fields = {
  __typename?: 'integration_auth_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  expiry?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_account_int_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "integration_auth" */
export type Integration_Auth_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  expiry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_account_int_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "integration_auth". All fields are combined with a logical 'AND'. */
export type Integration_Auth_Bool_Exp = {
  _and?: InputMaybe<Array<Integration_Auth_Bool_Exp>>;
  _not?: InputMaybe<Integration_Auth_Bool_Exp>;
  _or?: InputMaybe<Array<Integration_Auth_Bool_Exp>>;
  access_token?: InputMaybe<String_Comparison_Exp>;
  bank_account?: InputMaybe<Account_Bool_Exp>;
  banking_partner?: InputMaybe<Banking_Partner_Integration_Bool_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  company_integrations?: InputMaybe<Company_Integration_Bool_Exp>;
  company_integrations_aggregate?: InputMaybe<Company_Integration_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  expiry?: InputMaybe<Float8_Comparison_Exp>;
  generated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_used?: InputMaybe<Timestamptz_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  ref_document_checklist_integration?: InputMaybe<Ref_Document_Checklist_Integration_Bool_Exp>;
  refresh_token?: InputMaybe<String_Comparison_Exp>;
  scope?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  source_account_id?: InputMaybe<String_Comparison_Exp>;
  source_account_int_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_auth" */
export enum Integration_Auth_Constraint {
  /** unique or primary key constraint on columns "id" */
  IntegrationAuthPkey = 'integration_auth_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Integration_Auth_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Integration_Auth_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Integration_Auth_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "integration_auth" */
export type Integration_Auth_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  expiry?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "integration_auth" */
export type Integration_Auth_Insert_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  bank_account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  banking_partner?: InputMaybe<Banking_Partner_Integration_Obj_Rel_Insert_Input>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_integrations?: InputMaybe<Company_Integration_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  expiry?: InputMaybe<Scalars['float8']>;
  generated_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  last_used?: InputMaybe<Scalars['timestamptz']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  ref_document_checklist_integration?: InputMaybe<Ref_Document_Checklist_Integration_Obj_Rel_Insert_Input>;
  refresh_token?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  source_account_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Integration_Auth_Max_Fields = {
  __typename?: 'integration_auth_max_fields';
  access_token?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  expiry?: Maybe<Scalars['float8']>;
  generated_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  last_used?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_account_id?: Maybe<Scalars['String']>;
  source_account_int_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "integration_auth" */
export type Integration_Auth_Max_Order_By = {
  access_token?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  expiry?: InputMaybe<Order_By>;
  generated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_used?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  source_account_id?: InputMaybe<Order_By>;
  source_account_int_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Integration_Auth_Min_Fields = {
  __typename?: 'integration_auth_min_fields';
  access_token?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  expiry?: Maybe<Scalars['float8']>;
  generated_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  last_used?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_account_id?: Maybe<Scalars['String']>;
  source_account_int_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "integration_auth" */
export type Integration_Auth_Min_Order_By = {
  access_token?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  expiry?: InputMaybe<Order_By>;
  generated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_used?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  source_account_id?: InputMaybe<Order_By>;
  source_account_int_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "integration_auth" */
export type Integration_Auth_Mutation_Response = {
  __typename?: 'integration_auth_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Auth>;
};

/** input type for inserting object relation for remote table "integration_auth" */
export type Integration_Auth_Obj_Rel_Insert_Input = {
  data: Integration_Auth_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Integration_Auth_On_Conflict>;
};

/** on_conflict condition type for table "integration_auth" */
export type Integration_Auth_On_Conflict = {
  constraint: Integration_Auth_Constraint;
  update_columns?: Array<Integration_Auth_Update_Column>;
  where?: InputMaybe<Integration_Auth_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_auth". */
export type Integration_Auth_Order_By = {
  access_token?: InputMaybe<Order_By>;
  bank_account?: InputMaybe<Account_Order_By>;
  banking_partner?: InputMaybe<Banking_Partner_Integration_Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_integrations_aggregate?: InputMaybe<Company_Integration_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  expiry?: InputMaybe<Order_By>;
  generated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_used?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  ref_document_checklist_integration?: InputMaybe<Ref_Document_Checklist_Integration_Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  source_account_id?: InputMaybe<Order_By>;
  source_account_int_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: integration_auth */
export type Integration_Auth_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Integration_Auth_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "integration_auth" */
export enum Integration_Auth_Select_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Expiry = 'expiry',
  /** column name */
  GeneratedAt = 'generated_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastUsed = 'last_used',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  Scope = 'scope',
  /** column name */
  Source = 'source',
  /** column name */
  SourceAccountId = 'source_account_id',
  /** column name */
  SourceAccountIntId = 'source_account_int_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "integration_auth_aggregate_bool_exp_avg_arguments_columns" columns of table "integration_auth" */
export enum Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Expiry = 'expiry'
}

/** select "integration_auth_aggregate_bool_exp_corr_arguments_columns" columns of table "integration_auth" */
export enum Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Expiry = 'expiry'
}

/** select "integration_auth_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "integration_auth" */
export enum Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Expiry = 'expiry'
}

/** select "integration_auth_aggregate_bool_exp_max_arguments_columns" columns of table "integration_auth" */
export enum Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Expiry = 'expiry'
}

/** select "integration_auth_aggregate_bool_exp_min_arguments_columns" columns of table "integration_auth" */
export enum Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Expiry = 'expiry'
}

/** select "integration_auth_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "integration_auth" */
export enum Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Expiry = 'expiry'
}

/** select "integration_auth_aggregate_bool_exp_sum_arguments_columns" columns of table "integration_auth" */
export enum Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Expiry = 'expiry'
}

/** select "integration_auth_aggregate_bool_exp_var_samp_arguments_columns" columns of table "integration_auth" */
export enum Integration_Auth_Select_Column_Integration_Auth_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Expiry = 'expiry'
}

/** input type for updating data in table "integration_auth" */
export type Integration_Auth_Set_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  expiry?: InputMaybe<Scalars['float8']>;
  generated_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  last_used?: InputMaybe<Scalars['timestamptz']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  source_account_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Integration_Auth_Stddev_Fields = {
  __typename?: 'integration_auth_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  expiry?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_account_int_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "integration_auth" */
export type Integration_Auth_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  expiry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_account_int_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Integration_Auth_Stddev_Pop_Fields = {
  __typename?: 'integration_auth_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  expiry?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_account_int_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "integration_auth" */
export type Integration_Auth_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  expiry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_account_int_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Integration_Auth_Stddev_Samp_Fields = {
  __typename?: 'integration_auth_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  expiry?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_account_int_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "integration_auth" */
export type Integration_Auth_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  expiry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_account_int_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "integration_auth" */
export type Integration_Auth_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Auth_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Auth_Stream_Cursor_Value_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  expiry?: InputMaybe<Scalars['float8']>;
  generated_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  last_used?: InputMaybe<Scalars['timestamptz']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  source_account_id?: InputMaybe<Scalars['String']>;
  source_account_int_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Integration_Auth_Sum_Fields = {
  __typename?: 'integration_auth_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  expiry?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  source_account_int_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "integration_auth" */
export type Integration_Auth_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  expiry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_account_int_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "integration_auth" */
export enum Integration_Auth_Update_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Expiry = 'expiry',
  /** column name */
  GeneratedAt = 'generated_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastUsed = 'last_used',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  Scope = 'scope',
  /** column name */
  Source = 'source',
  /** column name */
  SourceAccountId = 'source_account_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Integration_Auth_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Integration_Auth_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Integration_Auth_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Integration_Auth_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Integration_Auth_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Integration_Auth_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Integration_Auth_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Integration_Auth_Set_Input>;
  /** filter the rows which have to be updated */
  where: Integration_Auth_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Integration_Auth_Var_Pop_Fields = {
  __typename?: 'integration_auth_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  expiry?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_account_int_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "integration_auth" */
export type Integration_Auth_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  expiry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_account_int_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Integration_Auth_Var_Samp_Fields = {
  __typename?: 'integration_auth_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  expiry?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_account_int_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "integration_auth" */
export type Integration_Auth_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  expiry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_account_int_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Integration_Auth_Variance_Fields = {
  __typename?: 'integration_auth_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  expiry?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_account_int_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "integration_auth" */
export type Integration_Auth_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  expiry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_account_int_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "internal_statement" */
export type Internal_Statement = {
  __typename?: 'internal_statement';
  amount: Scalars['float8'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  metadata?: Maybe<Scalars['jsonb']>;
  tenant_id: Scalars['Int'];
  tenant_type: Ref_Tenant_Type_Enum;
  timestamp: Scalars['timestamptz'];
  transaction_status: Ref_Transaction_Status_Enum;
  transaction_type: Ref_Transaction_Type_Enum;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "internal_statement" */
export type Internal_StatementMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "internal_statement" */
export type Internal_Statement_Aggregate = {
  __typename?: 'internal_statement_aggregate';
  aggregate?: Maybe<Internal_Statement_Aggregate_Fields>;
  nodes: Array<Internal_Statement>;
};

/** aggregate fields of "internal_statement" */
export type Internal_Statement_Aggregate_Fields = {
  __typename?: 'internal_statement_aggregate_fields';
  avg?: Maybe<Internal_Statement_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Internal_Statement_Max_Fields>;
  min?: Maybe<Internal_Statement_Min_Fields>;
  stddev?: Maybe<Internal_Statement_Stddev_Fields>;
  stddev_pop?: Maybe<Internal_Statement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Internal_Statement_Stddev_Samp_Fields>;
  sum?: Maybe<Internal_Statement_Sum_Fields>;
  var_pop?: Maybe<Internal_Statement_Var_Pop_Fields>;
  var_samp?: Maybe<Internal_Statement_Var_Samp_Fields>;
  variance?: Maybe<Internal_Statement_Variance_Fields>;
};


/** aggregate fields of "internal_statement" */
export type Internal_Statement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Internal_Statement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Internal_Statement_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Internal_Statement_Avg_Fields = {
  __typename?: 'internal_statement_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "internal_statement". All fields are combined with a logical 'AND'. */
export type Internal_Statement_Bool_Exp = {
  _and?: InputMaybe<Array<Internal_Statement_Bool_Exp>>;
  _not?: InputMaybe<Internal_Statement_Bool_Exp>;
  _or?: InputMaybe<Array<Internal_Statement_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  tenant_id?: InputMaybe<Int_Comparison_Exp>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum_Comparison_Exp>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "internal_statement" */
export enum Internal_Statement_Constraint {
  /** unique or primary key constraint on columns "id" */
  InternalStatementPkey = 'internal_statement_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Internal_Statement_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Internal_Statement_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Internal_Statement_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "internal_statement" */
export type Internal_Statement_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "internal_statement" */
export type Internal_Statement_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Internal_Statement_Max_Fields = {
  __typename?: 'internal_statement_max_fields';
  amount?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tenant_id?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Internal_Statement_Min_Fields = {
  __typename?: 'internal_statement_min_fields';
  amount?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tenant_id?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "internal_statement" */
export type Internal_Statement_Mutation_Response = {
  __typename?: 'internal_statement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Internal_Statement>;
};

/** on_conflict condition type for table "internal_statement" */
export type Internal_Statement_On_Conflict = {
  constraint: Internal_Statement_Constraint;
  update_columns?: Array<Internal_Statement_Update_Column>;
  where?: InputMaybe<Internal_Statement_Bool_Exp>;
};

/** Ordering options when selecting data from "internal_statement". */
export type Internal_Statement_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  tenant_type?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  transaction_status?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: internal_statement */
export type Internal_Statement_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Internal_Statement_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "internal_statement" */
export enum Internal_Statement_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TransactionStatus = 'transaction_status',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "internal_statement" */
export type Internal_Statement_Set_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Internal_Statement_Stddev_Fields = {
  __typename?: 'internal_statement_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Internal_Statement_Stddev_Pop_Fields = {
  __typename?: 'internal_statement_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Internal_Statement_Stddev_Samp_Fields = {
  __typename?: 'internal_statement_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "internal_statement" */
export type Internal_Statement_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Internal_Statement_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Internal_Statement_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  tenant_id?: InputMaybe<Scalars['Int']>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Internal_Statement_Sum_Fields = {
  __typename?: 'internal_statement_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tenant_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "internal_statement" */
export enum Internal_Statement_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TransactionStatus = 'transaction_status',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Internal_Statement_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Internal_Statement_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Internal_Statement_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Internal_Statement_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Internal_Statement_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Internal_Statement_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Internal_Statement_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Internal_Statement_Set_Input>;
  /** filter the rows which have to be updated */
  where: Internal_Statement_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Internal_Statement_Var_Pop_Fields = {
  __typename?: 'internal_statement_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Internal_Statement_Var_Samp_Fields = {
  __typename?: 'internal_statement_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Internal_Statement_Variance_Fields = {
  __typename?: 'internal_statement_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "investment" */
export type Investment = {
  __typename?: 'investment';
  actual_irr?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  amortization: Array<Investment_Amortization>;
  /** An aggregate relationship */
  amortization_aggregate: Investment_Amortization_Aggregate;
  amortization_table?: Maybe<Scalars['Int']>;
  /** An object relationship */
  apa?: Maybe<Agreement_Registry>;
  apa_agreement_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  bid: Bid;
  bid_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  emi_split_ratio?: Maybe<Scalars['numeric']>;
  fees?: Maybe<Scalars['numeric']>;
  id: Scalars['Int'];
  invested_amount?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  investor: Investor;
  /** An object relationship */
  investor_amortization_table_file?: Maybe<File_Registry>;
  investor_id: Scalars['Int'];
  offered_amount: Scalars['numeric'];
  /** An object relationship */
  ref_trade_withdrawal_mode?: Maybe<Ref_Trade_Withdrawal_Mode>;
  return_amount?: Maybe<Scalars['numeric']>;
  signing_inputs?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  trade: Trade;
  trade_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  withdrawal_mode?: Maybe<Ref_Trade_Withdrawal_Mode_Enum>;
  /** An object relationship */
  withhold_account?: Maybe<Account>;
  withhold_account_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "investment" */
export type InvestmentAmortizationArgs = {
  distinct_on?: InputMaybe<Array<Investment_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Amortization_Order_By>>;
  where?: InputMaybe<Investment_Amortization_Bool_Exp>;
};


/** columns and relationships of "investment" */
export type InvestmentAmortization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investment_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Amortization_Order_By>>;
  where?: InputMaybe<Investment_Amortization_Bool_Exp>;
};


/** columns and relationships of "investment" */
export type InvestmentSigning_InputsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "investment" */
export type Investment_Aggregate = {
  __typename?: 'investment_aggregate';
  aggregate?: Maybe<Investment_Aggregate_Fields>;
  nodes: Array<Investment>;
};

export type Investment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Investment_Aggregate_Bool_Exp_Count>;
};

export type Investment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Investment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Investment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "investment" */
export type Investment_Aggregate_Fields = {
  __typename?: 'investment_aggregate_fields';
  avg?: Maybe<Investment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Investment_Max_Fields>;
  min?: Maybe<Investment_Min_Fields>;
  stddev?: Maybe<Investment_Stddev_Fields>;
  stddev_pop?: Maybe<Investment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Investment_Stddev_Samp_Fields>;
  sum?: Maybe<Investment_Sum_Fields>;
  var_pop?: Maybe<Investment_Var_Pop_Fields>;
  var_samp?: Maybe<Investment_Var_Samp_Fields>;
  variance?: Maybe<Investment_Variance_Fields>;
};


/** aggregate fields of "investment" */
export type Investment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Investment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "investment" */
export type Investment_Aggregate_Order_By = {
  avg?: InputMaybe<Investment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Investment_Max_Order_By>;
  min?: InputMaybe<Investment_Min_Order_By>;
  stddev?: InputMaybe<Investment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Investment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Investment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Investment_Sum_Order_By>;
  var_pop?: InputMaybe<Investment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Investment_Var_Samp_Order_By>;
  variance?: InputMaybe<Investment_Variance_Order_By>;
};

/** columns and relationships of "investment_amortization" */
export type Investment_Amortization = {
  __typename?: 'investment_amortization';
  amount?: Maybe<Scalars['numeric']>;
  capital?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  interest?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  investment?: Maybe<Investment>;
  investment_id?: Maybe<Scalars['Int']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  scheduled_emi?: Maybe<Scheduled_Emi>;
  status?: Maybe<Scalars['String']>;
  tds?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  trade?: Maybe<Trade>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "investment_amortization" */
export type Investment_Amortization_Aggregate = {
  __typename?: 'investment_amortization_aggregate';
  aggregate?: Maybe<Investment_Amortization_Aggregate_Fields>;
  nodes: Array<Investment_Amortization>;
};

export type Investment_Amortization_Aggregate_Bool_Exp = {
  count?: InputMaybe<Investment_Amortization_Aggregate_Bool_Exp_Count>;
};

export type Investment_Amortization_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Investment_Amortization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Investment_Amortization_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "investment_amortization" */
export type Investment_Amortization_Aggregate_Fields = {
  __typename?: 'investment_amortization_aggregate_fields';
  avg?: Maybe<Investment_Amortization_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Investment_Amortization_Max_Fields>;
  min?: Maybe<Investment_Amortization_Min_Fields>;
  stddev?: Maybe<Investment_Amortization_Stddev_Fields>;
  stddev_pop?: Maybe<Investment_Amortization_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Investment_Amortization_Stddev_Samp_Fields>;
  sum?: Maybe<Investment_Amortization_Sum_Fields>;
  var_pop?: Maybe<Investment_Amortization_Var_Pop_Fields>;
  var_samp?: Maybe<Investment_Amortization_Var_Samp_Fields>;
  variance?: Maybe<Investment_Amortization_Variance_Fields>;
};


/** aggregate fields of "investment_amortization" */
export type Investment_Amortization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Investment_Amortization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "investment_amortization" */
export type Investment_Amortization_Aggregate_Order_By = {
  avg?: InputMaybe<Investment_Amortization_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Investment_Amortization_Max_Order_By>;
  min?: InputMaybe<Investment_Amortization_Min_Order_By>;
  stddev?: InputMaybe<Investment_Amortization_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Investment_Amortization_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Investment_Amortization_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Investment_Amortization_Sum_Order_By>;
  var_pop?: InputMaybe<Investment_Amortization_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Investment_Amortization_Var_Samp_Order_By>;
  variance?: InputMaybe<Investment_Amortization_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "investment_amortization" */
export type Investment_Amortization_Arr_Rel_Insert_Input = {
  data: Array<Investment_Amortization_Insert_Input>;
};

/** aggregate avg on columns */
export type Investment_Amortization_Avg_Fields = {
  __typename?: 'investment_amortization_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "investment_amortization" */
export type Investment_Amortization_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "investment_amortization". All fields are combined with a logical 'AND'. */
export type Investment_Amortization_Bool_Exp = {
  _and?: InputMaybe<Array<Investment_Amortization_Bool_Exp>>;
  _not?: InputMaybe<Investment_Amortization_Bool_Exp>;
  _or?: InputMaybe<Array<Investment_Amortization_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  capital?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interest?: InputMaybe<Numeric_Comparison_Exp>;
  investment?: InputMaybe<Investment_Bool_Exp>;
  investment_id?: InputMaybe<Int_Comparison_Exp>;
  scheduled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  scheduled_emi?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tds?: InputMaybe<Numeric_Comparison_Exp>;
  trade?: InputMaybe<Trade_Bool_Exp>;
  trade_id?: InputMaybe<Int_Comparison_Exp>;
  trade_installment_seq?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "investment_amortization" */
export type Investment_Amortization_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  capital?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  interest?: InputMaybe<Scalars['numeric']>;
  investment?: InputMaybe<Investment_Obj_Rel_Insert_Input>;
  investment_id?: InputMaybe<Scalars['Int']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  scheduled_emi?: InputMaybe<Scheduled_Emi_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  tds?: InputMaybe<Scalars['numeric']>;
  trade?: InputMaybe<Trade_Obj_Rel_Insert_Input>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Investment_Amortization_Max_Fields = {
  __typename?: 'investment_amortization_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  capital?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  interest?: Maybe<Scalars['numeric']>;
  investment_id?: Maybe<Scalars['Int']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  tds?: Maybe<Scalars['numeric']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "investment_amortization" */
export type Investment_Amortization_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Investment_Amortization_Min_Fields = {
  __typename?: 'investment_amortization_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  capital?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  interest?: Maybe<Scalars['numeric']>;
  investment_id?: Maybe<Scalars['Int']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  tds?: Maybe<Scalars['numeric']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "investment_amortization" */
export type Investment_Amortization_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "investment_amortization". */
export type Investment_Amortization_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  investment?: InputMaybe<Investment_Order_By>;
  investment_id?: InputMaybe<Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  scheduled_emi?: InputMaybe<Scheduled_Emi_Order_By>;
  status?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade?: InputMaybe<Trade_Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** select columns of table "investment_amortization" */
export enum Investment_Amortization_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Capital = 'capital',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Interest = 'interest',
  /** column name */
  InvestmentId = 'investment_id',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  Status = 'status',
  /** column name */
  Tds = 'tds',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  TradeInstallmentSeq = 'trade_installment_seq'
}

/** aggregate stddev on columns */
export type Investment_Amortization_Stddev_Fields = {
  __typename?: 'investment_amortization_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "investment_amortization" */
export type Investment_Amortization_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Investment_Amortization_Stddev_Pop_Fields = {
  __typename?: 'investment_amortization_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "investment_amortization" */
export type Investment_Amortization_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Investment_Amortization_Stddev_Samp_Fields = {
  __typename?: 'investment_amortization_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "investment_amortization" */
export type Investment_Amortization_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "investment_amortization" */
export type Investment_Amortization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Investment_Amortization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Investment_Amortization_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  capital?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  interest?: InputMaybe<Scalars['numeric']>;
  investment_id?: InputMaybe<Scalars['Int']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  tds?: InputMaybe<Scalars['numeric']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Investment_Amortization_Sum_Fields = {
  __typename?: 'investment_amortization_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  capital?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  interest?: Maybe<Scalars['numeric']>;
  investment_id?: Maybe<Scalars['Int']>;
  tds?: Maybe<Scalars['numeric']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "investment_amortization" */
export type Investment_Amortization_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Investment_Amortization_Var_Pop_Fields = {
  __typename?: 'investment_amortization_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "investment_amortization" */
export type Investment_Amortization_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Investment_Amortization_Var_Samp_Fields = {
  __typename?: 'investment_amortization_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "investment_amortization" */
export type Investment_Amortization_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Investment_Amortization_Variance_Fields = {
  __typename?: 'investment_amortization_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "investment_amortization" */
export type Investment_Amortization_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Investment_Append_Input = {
  signing_inputs?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "investment" */
export type Investment_Arr_Rel_Insert_Input = {
  data: Array<Investment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Investment_On_Conflict>;
};

/** aggregate avg on columns */
export type Investment_Avg_Fields = {
  __typename?: 'investment_avg_fields';
  actual_irr?: Maybe<Scalars['Float']>;
  amortization_table?: Maybe<Scalars['Float']>;
  apa_agreement_id?: Maybe<Scalars['Float']>;
  bid_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_split_ratio?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invested_amount?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  offered_amount?: Maybe<Scalars['Float']>;
  return_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  withhold_account_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "investment" */
export type Investment_Avg_Order_By = {
  actual_irr?: InputMaybe<Order_By>;
  amortization_table?: InputMaybe<Order_By>;
  apa_agreement_id?: InputMaybe<Order_By>;
  bid_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_split_ratio?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  offered_amount?: InputMaybe<Order_By>;
  return_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  withhold_account_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "investment". All fields are combined with a logical 'AND'. */
export type Investment_Bool_Exp = {
  _and?: InputMaybe<Array<Investment_Bool_Exp>>;
  _not?: InputMaybe<Investment_Bool_Exp>;
  _or?: InputMaybe<Array<Investment_Bool_Exp>>;
  actual_irr?: InputMaybe<Numeric_Comparison_Exp>;
  amortization?: InputMaybe<Investment_Amortization_Bool_Exp>;
  amortization_aggregate?: InputMaybe<Investment_Amortization_Aggregate_Bool_Exp>;
  amortization_table?: InputMaybe<Int_Comparison_Exp>;
  apa?: InputMaybe<Agreement_Registry_Bool_Exp>;
  apa_agreement_id?: InputMaybe<Int_Comparison_Exp>;
  bid?: InputMaybe<Bid_Bool_Exp>;
  bid_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  emi_split_ratio?: InputMaybe<Numeric_Comparison_Exp>;
  fees?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invested_amount?: InputMaybe<Numeric_Comparison_Exp>;
  investor?: InputMaybe<Investor_Bool_Exp>;
  investor_amortization_table_file?: InputMaybe<File_Registry_Bool_Exp>;
  investor_id?: InputMaybe<Int_Comparison_Exp>;
  offered_amount?: InputMaybe<Numeric_Comparison_Exp>;
  ref_trade_withdrawal_mode?: InputMaybe<Ref_Trade_Withdrawal_Mode_Bool_Exp>;
  return_amount?: InputMaybe<Numeric_Comparison_Exp>;
  signing_inputs?: InputMaybe<Jsonb_Comparison_Exp>;
  trade?: InputMaybe<Trade_Bool_Exp>;
  trade_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  withdrawal_mode?: InputMaybe<Ref_Trade_Withdrawal_Mode_Enum_Comparison_Exp>;
  withhold_account?: InputMaybe<Account_Bool_Exp>;
  withhold_account_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "investment" */
export enum Investment_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvestmentPkey = 'investment_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Investment_Delete_At_Path_Input = {
  signing_inputs?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Investment_Delete_Elem_Input = {
  signing_inputs?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Investment_Delete_Key_Input = {
  signing_inputs?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "investment" */
export type Investment_Inc_Input = {
  actual_irr?: InputMaybe<Scalars['numeric']>;
  amortization_table?: InputMaybe<Scalars['Int']>;
  apa_agreement_id?: InputMaybe<Scalars['Int']>;
  bid_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  emi_split_ratio?: InputMaybe<Scalars['numeric']>;
  fees?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  invested_amount?: InputMaybe<Scalars['numeric']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  offered_amount?: InputMaybe<Scalars['numeric']>;
  return_amount?: InputMaybe<Scalars['numeric']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  withhold_account_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "investment" */
export type Investment_Insert_Input = {
  actual_irr?: InputMaybe<Scalars['numeric']>;
  amortization?: InputMaybe<Investment_Amortization_Arr_Rel_Insert_Input>;
  amortization_table?: InputMaybe<Scalars['Int']>;
  apa?: InputMaybe<Agreement_Registry_Obj_Rel_Insert_Input>;
  apa_agreement_id?: InputMaybe<Scalars['Int']>;
  bid?: InputMaybe<Bid_Obj_Rel_Insert_Input>;
  bid_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  emi_split_ratio?: InputMaybe<Scalars['numeric']>;
  fees?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  invested_amount?: InputMaybe<Scalars['numeric']>;
  investor?: InputMaybe<Investor_Obj_Rel_Insert_Input>;
  investor_amortization_table_file?: InputMaybe<File_Registry_Obj_Rel_Insert_Input>;
  investor_id?: InputMaybe<Scalars['Int']>;
  offered_amount?: InputMaybe<Scalars['numeric']>;
  ref_trade_withdrawal_mode?: InputMaybe<Ref_Trade_Withdrawal_Mode_Obj_Rel_Insert_Input>;
  return_amount?: InputMaybe<Scalars['numeric']>;
  signing_inputs?: InputMaybe<Scalars['jsonb']>;
  trade?: InputMaybe<Trade_Obj_Rel_Insert_Input>;
  trade_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  withdrawal_mode?: InputMaybe<Ref_Trade_Withdrawal_Mode_Enum>;
  withhold_account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  withhold_account_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Investment_Max_Fields = {
  __typename?: 'investment_max_fields';
  actual_irr?: Maybe<Scalars['numeric']>;
  amortization_table?: Maybe<Scalars['Int']>;
  apa_agreement_id?: Maybe<Scalars['Int']>;
  bid_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  emi_split_ratio?: Maybe<Scalars['numeric']>;
  fees?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  invested_amount?: Maybe<Scalars['numeric']>;
  investor_id?: Maybe<Scalars['Int']>;
  offered_amount?: Maybe<Scalars['numeric']>;
  return_amount?: Maybe<Scalars['numeric']>;
  trade_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  withhold_account_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "investment" */
export type Investment_Max_Order_By = {
  actual_irr?: InputMaybe<Order_By>;
  amortization_table?: InputMaybe<Order_By>;
  apa_agreement_id?: InputMaybe<Order_By>;
  bid_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_split_ratio?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  offered_amount?: InputMaybe<Order_By>;
  return_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  withhold_account_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Investment_Min_Fields = {
  __typename?: 'investment_min_fields';
  actual_irr?: Maybe<Scalars['numeric']>;
  amortization_table?: Maybe<Scalars['Int']>;
  apa_agreement_id?: Maybe<Scalars['Int']>;
  bid_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  emi_split_ratio?: Maybe<Scalars['numeric']>;
  fees?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  invested_amount?: Maybe<Scalars['numeric']>;
  investor_id?: Maybe<Scalars['Int']>;
  offered_amount?: Maybe<Scalars['numeric']>;
  return_amount?: Maybe<Scalars['numeric']>;
  trade_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  withhold_account_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "investment" */
export type Investment_Min_Order_By = {
  actual_irr?: InputMaybe<Order_By>;
  amortization_table?: InputMaybe<Order_By>;
  apa_agreement_id?: InputMaybe<Order_By>;
  bid_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_split_ratio?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  offered_amount?: InputMaybe<Order_By>;
  return_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  withhold_account_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "investment" */
export type Investment_Mutation_Response = {
  __typename?: 'investment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Investment>;
};

/** input type for inserting object relation for remote table "investment" */
export type Investment_Obj_Rel_Insert_Input = {
  data: Investment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Investment_On_Conflict>;
};

/** on_conflict condition type for table "investment" */
export type Investment_On_Conflict = {
  constraint: Investment_Constraint;
  update_columns?: Array<Investment_Update_Column>;
  where?: InputMaybe<Investment_Bool_Exp>;
};

/** Ordering options when selecting data from "investment". */
export type Investment_Order_By = {
  actual_irr?: InputMaybe<Order_By>;
  amortization_aggregate?: InputMaybe<Investment_Amortization_Aggregate_Order_By>;
  amortization_table?: InputMaybe<Order_By>;
  apa?: InputMaybe<Agreement_Registry_Order_By>;
  apa_agreement_id?: InputMaybe<Order_By>;
  bid?: InputMaybe<Bid_Order_By>;
  bid_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_split_ratio?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  investor?: InputMaybe<Investor_Order_By>;
  investor_amortization_table_file?: InputMaybe<File_Registry_Order_By>;
  investor_id?: InputMaybe<Order_By>;
  offered_amount?: InputMaybe<Order_By>;
  ref_trade_withdrawal_mode?: InputMaybe<Ref_Trade_Withdrawal_Mode_Order_By>;
  return_amount?: InputMaybe<Order_By>;
  signing_inputs?: InputMaybe<Order_By>;
  trade?: InputMaybe<Trade_Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  withdrawal_mode?: InputMaybe<Order_By>;
  withhold_account?: InputMaybe<Account_Order_By>;
  withhold_account_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: investment */
export type Investment_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Investment_Prepend_Input = {
  signing_inputs?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "investment" */
export enum Investment_Select_Column {
  /** column name */
  ActualIrr = 'actual_irr',
  /** column name */
  AmortizationTable = 'amortization_table',
  /** column name */
  ApaAgreementId = 'apa_agreement_id',
  /** column name */
  BidId = 'bid_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EmiSplitRatio = 'emi_split_ratio',
  /** column name */
  Fees = 'fees',
  /** column name */
  Id = 'id',
  /** column name */
  InvestedAmount = 'invested_amount',
  /** column name */
  InvestorId = 'investor_id',
  /** column name */
  OfferedAmount = 'offered_amount',
  /** column name */
  ReturnAmount = 'return_amount',
  /** column name */
  SigningInputs = 'signing_inputs',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  WithdrawalMode = 'withdrawal_mode',
  /** column name */
  WithholdAccountId = 'withhold_account_id'
}

/** input type for updating data in table "investment" */
export type Investment_Set_Input = {
  actual_irr?: InputMaybe<Scalars['numeric']>;
  amortization_table?: InputMaybe<Scalars['Int']>;
  apa_agreement_id?: InputMaybe<Scalars['Int']>;
  bid_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  emi_split_ratio?: InputMaybe<Scalars['numeric']>;
  fees?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  invested_amount?: InputMaybe<Scalars['numeric']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  offered_amount?: InputMaybe<Scalars['numeric']>;
  return_amount?: InputMaybe<Scalars['numeric']>;
  signing_inputs?: InputMaybe<Scalars['jsonb']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  withdrawal_mode?: InputMaybe<Ref_Trade_Withdrawal_Mode_Enum>;
  withhold_account_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Investment_Stddev_Fields = {
  __typename?: 'investment_stddev_fields';
  actual_irr?: Maybe<Scalars['Float']>;
  amortization_table?: Maybe<Scalars['Float']>;
  apa_agreement_id?: Maybe<Scalars['Float']>;
  bid_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_split_ratio?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invested_amount?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  offered_amount?: Maybe<Scalars['Float']>;
  return_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  withhold_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "investment" */
export type Investment_Stddev_Order_By = {
  actual_irr?: InputMaybe<Order_By>;
  amortization_table?: InputMaybe<Order_By>;
  apa_agreement_id?: InputMaybe<Order_By>;
  bid_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_split_ratio?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  offered_amount?: InputMaybe<Order_By>;
  return_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  withhold_account_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Investment_Stddev_Pop_Fields = {
  __typename?: 'investment_stddev_pop_fields';
  actual_irr?: Maybe<Scalars['Float']>;
  amortization_table?: Maybe<Scalars['Float']>;
  apa_agreement_id?: Maybe<Scalars['Float']>;
  bid_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_split_ratio?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invested_amount?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  offered_amount?: Maybe<Scalars['Float']>;
  return_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  withhold_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "investment" */
export type Investment_Stddev_Pop_Order_By = {
  actual_irr?: InputMaybe<Order_By>;
  amortization_table?: InputMaybe<Order_By>;
  apa_agreement_id?: InputMaybe<Order_By>;
  bid_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_split_ratio?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  offered_amount?: InputMaybe<Order_By>;
  return_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  withhold_account_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Investment_Stddev_Samp_Fields = {
  __typename?: 'investment_stddev_samp_fields';
  actual_irr?: Maybe<Scalars['Float']>;
  amortization_table?: Maybe<Scalars['Float']>;
  apa_agreement_id?: Maybe<Scalars['Float']>;
  bid_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_split_ratio?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invested_amount?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  offered_amount?: Maybe<Scalars['Float']>;
  return_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  withhold_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "investment" */
export type Investment_Stddev_Samp_Order_By = {
  actual_irr?: InputMaybe<Order_By>;
  amortization_table?: InputMaybe<Order_By>;
  apa_agreement_id?: InputMaybe<Order_By>;
  bid_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_split_ratio?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  offered_amount?: InputMaybe<Order_By>;
  return_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  withhold_account_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "investment" */
export type Investment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Investment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Investment_Stream_Cursor_Value_Input = {
  actual_irr?: InputMaybe<Scalars['numeric']>;
  amortization_table?: InputMaybe<Scalars['Int']>;
  apa_agreement_id?: InputMaybe<Scalars['Int']>;
  bid_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  emi_split_ratio?: InputMaybe<Scalars['numeric']>;
  fees?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  invested_amount?: InputMaybe<Scalars['numeric']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  offered_amount?: InputMaybe<Scalars['numeric']>;
  return_amount?: InputMaybe<Scalars['numeric']>;
  signing_inputs?: InputMaybe<Scalars['jsonb']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  withdrawal_mode?: InputMaybe<Ref_Trade_Withdrawal_Mode_Enum>;
  withhold_account_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Investment_Sum_Fields = {
  __typename?: 'investment_sum_fields';
  actual_irr?: Maybe<Scalars['numeric']>;
  amortization_table?: Maybe<Scalars['Int']>;
  apa_agreement_id?: Maybe<Scalars['Int']>;
  bid_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  emi_split_ratio?: Maybe<Scalars['numeric']>;
  fees?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  invested_amount?: Maybe<Scalars['numeric']>;
  investor_id?: Maybe<Scalars['Int']>;
  offered_amount?: Maybe<Scalars['numeric']>;
  return_amount?: Maybe<Scalars['numeric']>;
  trade_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
  withhold_account_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "investment" */
export type Investment_Sum_Order_By = {
  actual_irr?: InputMaybe<Order_By>;
  amortization_table?: InputMaybe<Order_By>;
  apa_agreement_id?: InputMaybe<Order_By>;
  bid_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_split_ratio?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  offered_amount?: InputMaybe<Order_By>;
  return_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  withhold_account_id?: InputMaybe<Order_By>;
};

/** update columns of table "investment" */
export enum Investment_Update_Column {
  /** column name */
  ActualIrr = 'actual_irr',
  /** column name */
  AmortizationTable = 'amortization_table',
  /** column name */
  ApaAgreementId = 'apa_agreement_id',
  /** column name */
  BidId = 'bid_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EmiSplitRatio = 'emi_split_ratio',
  /** column name */
  Fees = 'fees',
  /** column name */
  Id = 'id',
  /** column name */
  InvestedAmount = 'invested_amount',
  /** column name */
  InvestorId = 'investor_id',
  /** column name */
  OfferedAmount = 'offered_amount',
  /** column name */
  ReturnAmount = 'return_amount',
  /** column name */
  SigningInputs = 'signing_inputs',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  WithdrawalMode = 'withdrawal_mode',
  /** column name */
  WithholdAccountId = 'withhold_account_id'
}

export type Investment_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Investment_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Investment_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Investment_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Investment_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Investment_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Investment_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Investment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Investment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Investment_Var_Pop_Fields = {
  __typename?: 'investment_var_pop_fields';
  actual_irr?: Maybe<Scalars['Float']>;
  amortization_table?: Maybe<Scalars['Float']>;
  apa_agreement_id?: Maybe<Scalars['Float']>;
  bid_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_split_ratio?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invested_amount?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  offered_amount?: Maybe<Scalars['Float']>;
  return_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  withhold_account_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "investment" */
export type Investment_Var_Pop_Order_By = {
  actual_irr?: InputMaybe<Order_By>;
  amortization_table?: InputMaybe<Order_By>;
  apa_agreement_id?: InputMaybe<Order_By>;
  bid_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_split_ratio?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  offered_amount?: InputMaybe<Order_By>;
  return_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  withhold_account_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Investment_Var_Samp_Fields = {
  __typename?: 'investment_var_samp_fields';
  actual_irr?: Maybe<Scalars['Float']>;
  amortization_table?: Maybe<Scalars['Float']>;
  apa_agreement_id?: Maybe<Scalars['Float']>;
  bid_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_split_ratio?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invested_amount?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  offered_amount?: Maybe<Scalars['Float']>;
  return_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  withhold_account_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "investment" */
export type Investment_Var_Samp_Order_By = {
  actual_irr?: InputMaybe<Order_By>;
  amortization_table?: InputMaybe<Order_By>;
  apa_agreement_id?: InputMaybe<Order_By>;
  bid_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_split_ratio?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  offered_amount?: InputMaybe<Order_By>;
  return_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  withhold_account_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Investment_Variance_Fields = {
  __typename?: 'investment_variance_fields';
  actual_irr?: Maybe<Scalars['Float']>;
  amortization_table?: Maybe<Scalars['Float']>;
  apa_agreement_id?: Maybe<Scalars['Float']>;
  bid_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_split_ratio?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invested_amount?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  offered_amount?: Maybe<Scalars['Float']>;
  return_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  withhold_account_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "investment" */
export type Investment_Variance_Order_By = {
  actual_irr?: InputMaybe<Order_By>;
  amortization_table?: InputMaybe<Order_By>;
  apa_agreement_id?: InputMaybe<Order_By>;
  bid_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_split_ratio?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  offered_amount?: InputMaybe<Order_By>;
  return_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  withhold_account_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "investor" */
export type Investor = {
  __typename?: 'investor';
  /** An array relationship */
  accounts: Array<Account>;
  /** An aggregate relationship */
  accounts_aggregate: Account_Aggregate;
  address_line1?: Maybe<Scalars['String']>;
  address_line2?: Maybe<Scalars['String']>;
  address_line3?: Maybe<Scalars['String']>;
  agreement_file_id?: Maybe<Scalars['Int']>;
  agreement_signed_date?: Maybe<Scalars['timestamptz']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['Int']>;
  budget_committed_from?: Maybe<Scalars['timestamptz']>;
  cin?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  gstin?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  investments: Array<Investment>;
  /** An aggregate relationship */
  investments_aggregate: Investment_Aggregate;
  /** An array relationship */
  investor_users: Array<Investor_User>;
  /** An aggregate relationship */
  investor_users_aggregate: Investor_User_Aggregate;
  max_amount_per_company?: Maybe<Scalars['Int']>;
  max_amount_per_trade?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  pan?: Maybe<Scalars['String']>;
  /** An object relationship */
  ref_country?: Maybe<Ref_Country>;
  /** An object relationship */
  ref_investor_type?: Maybe<Ref_Investor_Type>;
  /** An object relationship */
  ref_state?: Maybe<Ref_States>;
  signer_details?: Maybe<Scalars['jsonb']>;
  state_id?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  tenant_type?: Maybe<Ref_Tenant_Type_Enum>;
  total_committed_budget?: Maybe<Scalars['float8']>;
  type?: Maybe<Ref_Investor_Type_Enum>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
  zipcode?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "investor" */
export type InvestorAccountsArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


/** columns and relationships of "investor" */
export type InvestorAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


/** columns and relationships of "investor" */
export type InvestorInvestmentsArgs = {
  distinct_on?: InputMaybe<Array<Investment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Order_By>>;
  where?: InputMaybe<Investment_Bool_Exp>;
};


/** columns and relationships of "investor" */
export type InvestorInvestments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Order_By>>;
  where?: InputMaybe<Investment_Bool_Exp>;
};


/** columns and relationships of "investor" */
export type InvestorInvestor_UsersArgs = {
  distinct_on?: InputMaybe<Array<Investor_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_User_Order_By>>;
  where?: InputMaybe<Investor_User_Bool_Exp>;
};


/** columns and relationships of "investor" */
export type InvestorInvestor_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investor_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_User_Order_By>>;
  where?: InputMaybe<Investor_User_Bool_Exp>;
};


/** columns and relationships of "investor" */
export type InvestorSigner_DetailsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "investor" */
export type Investor_Aggregate = {
  __typename?: 'investor_aggregate';
  aggregate?: Maybe<Investor_Aggregate_Fields>;
  nodes: Array<Investor>;
};

/** aggregate fields of "investor" */
export type Investor_Aggregate_Fields = {
  __typename?: 'investor_aggregate_fields';
  avg?: Maybe<Investor_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Investor_Max_Fields>;
  min?: Maybe<Investor_Min_Fields>;
  stddev?: Maybe<Investor_Stddev_Fields>;
  stddev_pop?: Maybe<Investor_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Investor_Stddev_Samp_Fields>;
  sum?: Maybe<Investor_Sum_Fields>;
  var_pop?: Maybe<Investor_Var_Pop_Fields>;
  var_samp?: Maybe<Investor_Var_Samp_Fields>;
  variance?: Maybe<Investor_Variance_Fields>;
};


/** aggregate fields of "investor" */
export type Investor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Investor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Investor_Append_Input = {
  signer_details?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Investor_Avg_Fields = {
  __typename?: 'investor_avg_fields';
  agreement_file_id?: Maybe<Scalars['Float']>;
  approved_by?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_amount_per_company?: Maybe<Scalars['Float']>;
  max_amount_per_trade?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
  total_committed_budget?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  zipcode?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "investor". All fields are combined with a logical 'AND'. */
export type Investor_Bool_Exp = {
  _and?: InputMaybe<Array<Investor_Bool_Exp>>;
  _not?: InputMaybe<Investor_Bool_Exp>;
  _or?: InputMaybe<Array<Investor_Bool_Exp>>;
  accounts?: InputMaybe<Account_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Account_Aggregate_Bool_Exp>;
  address_line1?: InputMaybe<String_Comparison_Exp>;
  address_line2?: InputMaybe<String_Comparison_Exp>;
  address_line3?: InputMaybe<String_Comparison_Exp>;
  agreement_file_id?: InputMaybe<Int_Comparison_Exp>;
  agreement_signed_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  approved_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  approved_by?: InputMaybe<Int_Comparison_Exp>;
  budget_committed_from?: InputMaybe<Timestamptz_Comparison_Exp>;
  cin?: InputMaybe<String_Comparison_Exp>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  gstin?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  investments?: InputMaybe<Investment_Bool_Exp>;
  investments_aggregate?: InputMaybe<Investment_Aggregate_Bool_Exp>;
  investor_users?: InputMaybe<Investor_User_Bool_Exp>;
  investor_users_aggregate?: InputMaybe<Investor_User_Aggregate_Bool_Exp>;
  max_amount_per_company?: InputMaybe<Int_Comparison_Exp>;
  max_amount_per_trade?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pan?: InputMaybe<String_Comparison_Exp>;
  ref_country?: InputMaybe<Ref_Country_Bool_Exp>;
  ref_investor_type?: InputMaybe<Ref_Investor_Type_Bool_Exp>;
  ref_state?: InputMaybe<Ref_States_Bool_Exp>;
  signer_details?: InputMaybe<Jsonb_Comparison_Exp>;
  state_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum_Comparison_Exp>;
  total_committed_budget?: InputMaybe<Float8_Comparison_Exp>;
  type?: InputMaybe<Ref_Investor_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  zipcode?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "investor" */
export enum Investor_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvestorPkey = 'investor_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Investor_Delete_At_Path_Input = {
  signer_details?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Investor_Delete_Elem_Input = {
  signer_details?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Investor_Delete_Key_Input = {
  signer_details?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "investor" */
export type Investor_Inc_Input = {
  agreement_file_id?: InputMaybe<Scalars['Int']>;
  approved_by?: InputMaybe<Scalars['Int']>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  max_amount_per_company?: InputMaybe<Scalars['Int']>;
  max_amount_per_trade?: InputMaybe<Scalars['Int']>;
  state_id?: InputMaybe<Scalars['Int']>;
  total_committed_budget?: InputMaybe<Scalars['float8']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  zipcode?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "investor" */
export type Investor_Insert_Input = {
  accounts?: InputMaybe<Account_Arr_Rel_Insert_Input>;
  address_line1?: InputMaybe<Scalars['String']>;
  address_line2?: InputMaybe<Scalars['String']>;
  address_line3?: InputMaybe<Scalars['String']>;
  agreement_file_id?: InputMaybe<Scalars['Int']>;
  agreement_signed_date?: InputMaybe<Scalars['timestamptz']>;
  approved_at?: InputMaybe<Scalars['timestamptz']>;
  approved_by?: InputMaybe<Scalars['Int']>;
  budget_committed_from?: InputMaybe<Scalars['timestamptz']>;
  cin?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  domain?: InputMaybe<Scalars['String']>;
  gstin?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  investments?: InputMaybe<Investment_Arr_Rel_Insert_Input>;
  investor_users?: InputMaybe<Investor_User_Arr_Rel_Insert_Input>;
  max_amount_per_company?: InputMaybe<Scalars['Int']>;
  max_amount_per_trade?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  pan?: InputMaybe<Scalars['String']>;
  ref_country?: InputMaybe<Ref_Country_Obj_Rel_Insert_Input>;
  ref_investor_type?: InputMaybe<Ref_Investor_Type_Obj_Rel_Insert_Input>;
  ref_state?: InputMaybe<Ref_States_Obj_Rel_Insert_Input>;
  signer_details?: InputMaybe<Scalars['jsonb']>;
  state_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum>;
  total_committed_budget?: InputMaybe<Scalars['float8']>;
  type?: InputMaybe<Ref_Investor_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  zipcode?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Investor_Max_Fields = {
  __typename?: 'investor_max_fields';
  address_line1?: Maybe<Scalars['String']>;
  address_line2?: Maybe<Scalars['String']>;
  address_line3?: Maybe<Scalars['String']>;
  agreement_file_id?: Maybe<Scalars['Int']>;
  agreement_signed_date?: Maybe<Scalars['timestamptz']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['Int']>;
  budget_committed_from?: Maybe<Scalars['timestamptz']>;
  cin?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  gstin?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  max_amount_per_company?: Maybe<Scalars['Int']>;
  max_amount_per_trade?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pan?: Maybe<Scalars['String']>;
  state_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  total_committed_budget?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  zipcode?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Investor_Min_Fields = {
  __typename?: 'investor_min_fields';
  address_line1?: Maybe<Scalars['String']>;
  address_line2?: Maybe<Scalars['String']>;
  address_line3?: Maybe<Scalars['String']>;
  agreement_file_id?: Maybe<Scalars['Int']>;
  agreement_signed_date?: Maybe<Scalars['timestamptz']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['Int']>;
  budget_committed_from?: Maybe<Scalars['timestamptz']>;
  cin?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  gstin?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  max_amount_per_company?: Maybe<Scalars['Int']>;
  max_amount_per_trade?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pan?: Maybe<Scalars['String']>;
  state_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  total_committed_budget?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  zipcode?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "investor" */
export type Investor_Mutation_Response = {
  __typename?: 'investor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Investor>;
};

/** input type for inserting object relation for remote table "investor" */
export type Investor_Obj_Rel_Insert_Input = {
  data: Investor_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Investor_On_Conflict>;
};

/** on_conflict condition type for table "investor" */
export type Investor_On_Conflict = {
  constraint: Investor_Constraint;
  update_columns?: Array<Investor_Update_Column>;
  where?: InputMaybe<Investor_Bool_Exp>;
};

/** Ordering options when selecting data from "investor". */
export type Investor_Order_By = {
  accounts_aggregate?: InputMaybe<Account_Aggregate_Order_By>;
  address_line1?: InputMaybe<Order_By>;
  address_line2?: InputMaybe<Order_By>;
  address_line3?: InputMaybe<Order_By>;
  agreement_file_id?: InputMaybe<Order_By>;
  agreement_signed_date?: InputMaybe<Order_By>;
  approved_at?: InputMaybe<Order_By>;
  approved_by?: InputMaybe<Order_By>;
  budget_committed_from?: InputMaybe<Order_By>;
  cin?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  gstin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investments_aggregate?: InputMaybe<Investment_Aggregate_Order_By>;
  investor_users_aggregate?: InputMaybe<Investor_User_Aggregate_Order_By>;
  max_amount_per_company?: InputMaybe<Order_By>;
  max_amount_per_trade?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pan?: InputMaybe<Order_By>;
  ref_country?: InputMaybe<Ref_Country_Order_By>;
  ref_investor_type?: InputMaybe<Ref_Investor_Type_Order_By>;
  ref_state?: InputMaybe<Ref_States_Order_By>;
  signer_details?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tenant_type?: InputMaybe<Order_By>;
  total_committed_budget?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: investor */
export type Investor_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** columns and relationships of "investor_preference" */
export type Investor_Preference = {
  __typename?: 'investor_preference';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  investor_id: Scalars['Int'];
  preferences?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "investor_preference" */
export type Investor_PreferencePreferencesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "investor_preference" */
export type Investor_Preference_Aggregate = {
  __typename?: 'investor_preference_aggregate';
  aggregate?: Maybe<Investor_Preference_Aggregate_Fields>;
  nodes: Array<Investor_Preference>;
};

/** aggregate fields of "investor_preference" */
export type Investor_Preference_Aggregate_Fields = {
  __typename?: 'investor_preference_aggregate_fields';
  avg?: Maybe<Investor_Preference_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Investor_Preference_Max_Fields>;
  min?: Maybe<Investor_Preference_Min_Fields>;
  stddev?: Maybe<Investor_Preference_Stddev_Fields>;
  stddev_pop?: Maybe<Investor_Preference_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Investor_Preference_Stddev_Samp_Fields>;
  sum?: Maybe<Investor_Preference_Sum_Fields>;
  var_pop?: Maybe<Investor_Preference_Var_Pop_Fields>;
  var_samp?: Maybe<Investor_Preference_Var_Samp_Fields>;
  variance?: Maybe<Investor_Preference_Variance_Fields>;
};


/** aggregate fields of "investor_preference" */
export type Investor_Preference_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Investor_Preference_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Investor_Preference_Append_Input = {
  preferences?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Investor_Preference_Avg_Fields = {
  __typename?: 'investor_preference_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "investor_preference". All fields are combined with a logical 'AND'. */
export type Investor_Preference_Bool_Exp = {
  _and?: InputMaybe<Array<Investor_Preference_Bool_Exp>>;
  _not?: InputMaybe<Investor_Preference_Bool_Exp>;
  _or?: InputMaybe<Array<Investor_Preference_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  investor_id?: InputMaybe<Int_Comparison_Exp>;
  preferences?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "investor_preference" */
export enum Investor_Preference_Constraint {
  /** unique or primary key constraint on columns "investor_id" */
  InvestorPreferenceInvestorIdIdx = 'investor_preference_investor_id_idx',
  /** unique or primary key constraint on columns "id" */
  InvestorPreferencePkey = 'investor_preference_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Investor_Preference_Delete_At_Path_Input = {
  preferences?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Investor_Preference_Delete_Elem_Input = {
  preferences?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Investor_Preference_Delete_Key_Input = {
  preferences?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "investor_preference" */
export type Investor_Preference_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "investor_preference" */
export type Investor_Preference_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  preferences?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Investor_Preference_Max_Fields = {
  __typename?: 'investor_preference_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  investor_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Investor_Preference_Min_Fields = {
  __typename?: 'investor_preference_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  investor_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "investor_preference" */
export type Investor_Preference_Mutation_Response = {
  __typename?: 'investor_preference_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Investor_Preference>;
};

/** on_conflict condition type for table "investor_preference" */
export type Investor_Preference_On_Conflict = {
  constraint: Investor_Preference_Constraint;
  update_columns?: Array<Investor_Preference_Update_Column>;
  where?: InputMaybe<Investor_Preference_Bool_Exp>;
};

/** Ordering options when selecting data from "investor_preference". */
export type Investor_Preference_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  preferences?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: investor_preference */
export type Investor_Preference_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Investor_Preference_Prepend_Input = {
  preferences?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "investor_preference" */
export enum Investor_Preference_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  InvestorId = 'investor_id',
  /** column name */
  Preferences = 'preferences',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "investor_preference" */
export type Investor_Preference_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  preferences?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Investor_Preference_Stddev_Fields = {
  __typename?: 'investor_preference_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Investor_Preference_Stddev_Pop_Fields = {
  __typename?: 'investor_preference_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Investor_Preference_Stddev_Samp_Fields = {
  __typename?: 'investor_preference_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "investor_preference" */
export type Investor_Preference_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Investor_Preference_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Investor_Preference_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  preferences?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Investor_Preference_Sum_Fields = {
  __typename?: 'investor_preference_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  investor_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "investor_preference" */
export enum Investor_Preference_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  InvestorId = 'investor_id',
  /** column name */
  Preferences = 'preferences',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Investor_Preference_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Investor_Preference_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Investor_Preference_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Investor_Preference_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Investor_Preference_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Investor_Preference_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Investor_Preference_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Investor_Preference_Set_Input>;
  /** filter the rows which have to be updated */
  where: Investor_Preference_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Investor_Preference_Var_Pop_Fields = {
  __typename?: 'investor_preference_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Investor_Preference_Var_Samp_Fields = {
  __typename?: 'investor_preference_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Investor_Preference_Variance_Fields = {
  __typename?: 'investor_preference_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Investor_Prepend_Input = {
  signer_details?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "investor" */
export enum Investor_Select_Column {
  /** column name */
  AddressLine1 = 'address_line1',
  /** column name */
  AddressLine2 = 'address_line2',
  /** column name */
  AddressLine3 = 'address_line3',
  /** column name */
  AgreementFileId = 'agreement_file_id',
  /** column name */
  AgreementSignedDate = 'agreement_signed_date',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  BudgetCommittedFrom = 'budget_committed_from',
  /** column name */
  Cin = 'cin',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Domain = 'domain',
  /** column name */
  Gstin = 'gstin',
  /** column name */
  Id = 'id',
  /** column name */
  MaxAmountPerCompany = 'max_amount_per_company',
  /** column name */
  MaxAmountPerTrade = 'max_amount_per_trade',
  /** column name */
  Name = 'name',
  /** column name */
  Pan = 'pan',
  /** column name */
  SignerDetails = 'signer_details',
  /** column name */
  StateId = 'state_id',
  /** column name */
  Status = 'status',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  TotalCommittedBudget = 'total_committed_budget',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Zipcode = 'zipcode'
}

/** input type for updating data in table "investor" */
export type Investor_Set_Input = {
  address_line1?: InputMaybe<Scalars['String']>;
  address_line2?: InputMaybe<Scalars['String']>;
  address_line3?: InputMaybe<Scalars['String']>;
  agreement_file_id?: InputMaybe<Scalars['Int']>;
  agreement_signed_date?: InputMaybe<Scalars['timestamptz']>;
  approved_at?: InputMaybe<Scalars['timestamptz']>;
  approved_by?: InputMaybe<Scalars['Int']>;
  budget_committed_from?: InputMaybe<Scalars['timestamptz']>;
  cin?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  domain?: InputMaybe<Scalars['String']>;
  gstin?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  max_amount_per_company?: InputMaybe<Scalars['Int']>;
  max_amount_per_trade?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  pan?: InputMaybe<Scalars['String']>;
  signer_details?: InputMaybe<Scalars['jsonb']>;
  state_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum>;
  total_committed_budget?: InputMaybe<Scalars['float8']>;
  type?: InputMaybe<Ref_Investor_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  zipcode?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "investor_statement" */
export type Investor_Statement = {
  __typename?: 'investor_statement';
  amount: Scalars['float8'];
  balance: Scalars['float8'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  investor_id: Scalars['Int'];
  metadata?: Maybe<Scalars['jsonb']>;
  timestamp: Scalars['timestamptz'];
  transaction_status: Ref_Transaction_Status_Enum;
  transaction_type: Ref_Transaction_Type_Enum;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "investor_statement" */
export type Investor_StatementMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "investor_statement" */
export type Investor_Statement_Aggregate = {
  __typename?: 'investor_statement_aggregate';
  aggregate?: Maybe<Investor_Statement_Aggregate_Fields>;
  nodes: Array<Investor_Statement>;
};

/** aggregate fields of "investor_statement" */
export type Investor_Statement_Aggregate_Fields = {
  __typename?: 'investor_statement_aggregate_fields';
  avg?: Maybe<Investor_Statement_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Investor_Statement_Max_Fields>;
  min?: Maybe<Investor_Statement_Min_Fields>;
  stddev?: Maybe<Investor_Statement_Stddev_Fields>;
  stddev_pop?: Maybe<Investor_Statement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Investor_Statement_Stddev_Samp_Fields>;
  sum?: Maybe<Investor_Statement_Sum_Fields>;
  var_pop?: Maybe<Investor_Statement_Var_Pop_Fields>;
  var_samp?: Maybe<Investor_Statement_Var_Samp_Fields>;
  variance?: Maybe<Investor_Statement_Variance_Fields>;
};


/** aggregate fields of "investor_statement" */
export type Investor_Statement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Investor_Statement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Investor_Statement_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Investor_Statement_Avg_Fields = {
  __typename?: 'investor_statement_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "investor_statement". All fields are combined with a logical 'AND'. */
export type Investor_Statement_Bool_Exp = {
  _and?: InputMaybe<Array<Investor_Statement_Bool_Exp>>;
  _not?: InputMaybe<Investor_Statement_Bool_Exp>;
  _or?: InputMaybe<Array<Investor_Statement_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  balance?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  investor_id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum_Comparison_Exp>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "investor_statement" */
export enum Investor_Statement_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvestorStatementPkey = 'investor_statement_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Investor_Statement_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Investor_Statement_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Investor_Statement_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "investor_statement" */
export type Investor_Statement_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  balance?: InputMaybe<Scalars['float8']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "investor_statement" */
export type Investor_Statement_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  balance?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Investor_Statement_Max_Fields = {
  __typename?: 'investor_statement_max_fields';
  amount?: Maybe<Scalars['float8']>;
  balance?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  investor_id?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Investor_Statement_Min_Fields = {
  __typename?: 'investor_statement_min_fields';
  amount?: Maybe<Scalars['float8']>;
  balance?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  investor_id?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "investor_statement" */
export type Investor_Statement_Mutation_Response = {
  __typename?: 'investor_statement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Investor_Statement>;
};

/** on_conflict condition type for table "investor_statement" */
export type Investor_Statement_On_Conflict = {
  constraint: Investor_Statement_Constraint;
  update_columns?: Array<Investor_Statement_Update_Column>;
  where?: InputMaybe<Investor_Statement_Bool_Exp>;
};

/** Ordering options when selecting data from "investor_statement". */
export type Investor_Statement_Order_By = {
  amount?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  transaction_status?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: investor_statement */
export type Investor_Statement_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Investor_Statement_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "investor_statement" */
export enum Investor_Statement_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Balance = 'balance',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  InvestorId = 'investor_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TransactionStatus = 'transaction_status',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "investor_statement" */
export type Investor_Statement_Set_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  balance?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Investor_Statement_Stddev_Fields = {
  __typename?: 'investor_statement_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Investor_Statement_Stddev_Pop_Fields = {
  __typename?: 'investor_statement_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Investor_Statement_Stddev_Samp_Fields = {
  __typename?: 'investor_statement_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "investor_statement" */
export type Investor_Statement_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Investor_Statement_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Investor_Statement_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  balance?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Investor_Statement_Sum_Fields = {
  __typename?: 'investor_statement_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  balance?: Maybe<Scalars['float8']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  investor_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "investor_statement" */
export enum Investor_Statement_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Balance = 'balance',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  InvestorId = 'investor_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TransactionStatus = 'transaction_status',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Investor_Statement_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Investor_Statement_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Investor_Statement_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Investor_Statement_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Investor_Statement_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Investor_Statement_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Investor_Statement_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Investor_Statement_Set_Input>;
  /** filter the rows which have to be updated */
  where: Investor_Statement_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Investor_Statement_Var_Pop_Fields = {
  __typename?: 'investor_statement_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Investor_Statement_Var_Samp_Fields = {
  __typename?: 'investor_statement_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Investor_Statement_Variance_Fields = {
  __typename?: 'investor_statement_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Investor_Stddev_Fields = {
  __typename?: 'investor_stddev_fields';
  agreement_file_id?: Maybe<Scalars['Float']>;
  approved_by?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_amount_per_company?: Maybe<Scalars['Float']>;
  max_amount_per_trade?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
  total_committed_budget?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  zipcode?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Investor_Stddev_Pop_Fields = {
  __typename?: 'investor_stddev_pop_fields';
  agreement_file_id?: Maybe<Scalars['Float']>;
  approved_by?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_amount_per_company?: Maybe<Scalars['Float']>;
  max_amount_per_trade?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
  total_committed_budget?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  zipcode?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Investor_Stddev_Samp_Fields = {
  __typename?: 'investor_stddev_samp_fields';
  agreement_file_id?: Maybe<Scalars['Float']>;
  approved_by?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_amount_per_company?: Maybe<Scalars['Float']>;
  max_amount_per_trade?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
  total_committed_budget?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  zipcode?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "investor" */
export type Investor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Investor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Investor_Stream_Cursor_Value_Input = {
  address_line1?: InputMaybe<Scalars['String']>;
  address_line2?: InputMaybe<Scalars['String']>;
  address_line3?: InputMaybe<Scalars['String']>;
  agreement_file_id?: InputMaybe<Scalars['Int']>;
  agreement_signed_date?: InputMaybe<Scalars['timestamptz']>;
  approved_at?: InputMaybe<Scalars['timestamptz']>;
  approved_by?: InputMaybe<Scalars['Int']>;
  budget_committed_from?: InputMaybe<Scalars['timestamptz']>;
  cin?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  domain?: InputMaybe<Scalars['String']>;
  gstin?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  max_amount_per_company?: InputMaybe<Scalars['Int']>;
  max_amount_per_trade?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  pan?: InputMaybe<Scalars['String']>;
  signer_details?: InputMaybe<Scalars['jsonb']>;
  state_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum>;
  total_committed_budget?: InputMaybe<Scalars['float8']>;
  type?: InputMaybe<Ref_Investor_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  zipcode?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Investor_Sum_Fields = {
  __typename?: 'investor_sum_fields';
  agreement_file_id?: Maybe<Scalars['Int']>;
  approved_by?: Maybe<Scalars['Int']>;
  country_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  max_amount_per_company?: Maybe<Scalars['Int']>;
  max_amount_per_trade?: Maybe<Scalars['Int']>;
  state_id?: Maybe<Scalars['Int']>;
  total_committed_budget?: Maybe<Scalars['float8']>;
  updated_by?: Maybe<Scalars['Int']>;
  zipcode?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "investor_transaction" */
export type Investor_Transaction = {
  __typename?: 'investor_transaction';
  account_id: Scalars['Int'];
  amount: Scalars['numeric'];
  bounce_charges?: Maybe<Scalars['numeric']>;
  capital?: Maybe<Scalars['numeric']>;
  counterparty_id: Scalars['Int'];
  id: Scalars['uuid'];
  interest?: Maybe<Scalars['numeric']>;
  interest_charges?: Maybe<Scalars['numeric']>;
  investment_id: Scalars['Int'];
  metadata?: Maybe<Scalars['jsonb']>;
  remarks?: Maybe<Scalars['String']>;
  /** An object relationship */
  scheduled_emi?: Maybe<Scheduled_Emi>;
  scheduled_emi_id?: Maybe<Scalars['Int']>;
  tds?: Maybe<Scalars['numeric']>;
  timestamp: Scalars['timestamptz'];
  /** An object relationship */
  trade: Trade;
  trade_id: Scalars['Int'];
  trade_installment_seq?: Maybe<Scalars['Int']>;
  transaction_method: Scalars['String'];
  transaction_method_id: Scalars['String'];
  transaction_status: Scalars['String'];
  transaction_type: Scalars['String'];
};


/** columns and relationships of "investor_transaction" */
export type Investor_TransactionMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "investor_transaction" */
export type Investor_Transaction_Aggregate = {
  __typename?: 'investor_transaction_aggregate';
  aggregate?: Maybe<Investor_Transaction_Aggregate_Fields>;
  nodes: Array<Investor_Transaction>;
};

/** aggregate fields of "investor_transaction" */
export type Investor_Transaction_Aggregate_Fields = {
  __typename?: 'investor_transaction_aggregate_fields';
  avg?: Maybe<Investor_Transaction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Investor_Transaction_Max_Fields>;
  min?: Maybe<Investor_Transaction_Min_Fields>;
  stddev?: Maybe<Investor_Transaction_Stddev_Fields>;
  stddev_pop?: Maybe<Investor_Transaction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Investor_Transaction_Stddev_Samp_Fields>;
  sum?: Maybe<Investor_Transaction_Sum_Fields>;
  var_pop?: Maybe<Investor_Transaction_Var_Pop_Fields>;
  var_samp?: Maybe<Investor_Transaction_Var_Samp_Fields>;
  variance?: Maybe<Investor_Transaction_Variance_Fields>;
};


/** aggregate fields of "investor_transaction" */
export type Investor_Transaction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Investor_Transaction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Investor_Transaction_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Investor_Transaction_Avg_Fields = {
  __typename?: 'investor_transaction_avg_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  interest_charges?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "investor_transaction". All fields are combined with a logical 'AND'. */
export type Investor_Transaction_Bool_Exp = {
  _and?: InputMaybe<Array<Investor_Transaction_Bool_Exp>>;
  _not?: InputMaybe<Investor_Transaction_Bool_Exp>;
  _or?: InputMaybe<Array<Investor_Transaction_Bool_Exp>>;
  account_id?: InputMaybe<Int_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  bounce_charges?: InputMaybe<Numeric_Comparison_Exp>;
  capital?: InputMaybe<Numeric_Comparison_Exp>;
  counterparty_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interest?: InputMaybe<Numeric_Comparison_Exp>;
  interest_charges?: InputMaybe<Numeric_Comparison_Exp>;
  investment_id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
  scheduled_emi?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  scheduled_emi_id?: InputMaybe<Int_Comparison_Exp>;
  tds?: InputMaybe<Numeric_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  trade?: InputMaybe<Trade_Bool_Exp>;
  trade_id?: InputMaybe<Int_Comparison_Exp>;
  trade_installment_seq?: InputMaybe<Int_Comparison_Exp>;
  transaction_method?: InputMaybe<String_Comparison_Exp>;
  transaction_method_id?: InputMaybe<String_Comparison_Exp>;
  transaction_status?: InputMaybe<String_Comparison_Exp>;
  transaction_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "investor_transaction" */
export enum Investor_Transaction_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvestorTransactionPkey = 'investor_transaction_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Investor_Transaction_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Investor_Transaction_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Investor_Transaction_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "investor_transaction" */
export type Investor_Transaction_Inc_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  bounce_charges?: InputMaybe<Scalars['numeric']>;
  capital?: InputMaybe<Scalars['numeric']>;
  counterparty_id?: InputMaybe<Scalars['Int']>;
  interest?: InputMaybe<Scalars['numeric']>;
  interest_charges?: InputMaybe<Scalars['numeric']>;
  investment_id?: InputMaybe<Scalars['Int']>;
  scheduled_emi_id?: InputMaybe<Scalars['Int']>;
  tds?: InputMaybe<Scalars['numeric']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "investor_transaction" */
export type Investor_Transaction_Insert_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  bounce_charges?: InputMaybe<Scalars['numeric']>;
  capital?: InputMaybe<Scalars['numeric']>;
  counterparty_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  interest?: InputMaybe<Scalars['numeric']>;
  interest_charges?: InputMaybe<Scalars['numeric']>;
  investment_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  remarks?: InputMaybe<Scalars['String']>;
  scheduled_emi?: InputMaybe<Scheduled_Emi_Obj_Rel_Insert_Input>;
  scheduled_emi_id?: InputMaybe<Scalars['Int']>;
  tds?: InputMaybe<Scalars['numeric']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  trade?: InputMaybe<Trade_Obj_Rel_Insert_Input>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
  transaction_method?: InputMaybe<Scalars['String']>;
  transaction_method_id?: InputMaybe<Scalars['String']>;
  transaction_status?: InputMaybe<Scalars['String']>;
  transaction_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Investor_Transaction_Max_Fields = {
  __typename?: 'investor_transaction_max_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  bounce_charges?: Maybe<Scalars['numeric']>;
  capital?: Maybe<Scalars['numeric']>;
  counterparty_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  interest?: Maybe<Scalars['numeric']>;
  interest_charges?: Maybe<Scalars['numeric']>;
  investment_id?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  scheduled_emi_id?: Maybe<Scalars['Int']>;
  tds?: Maybe<Scalars['numeric']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
  transaction_method?: Maybe<Scalars['String']>;
  transaction_method_id?: Maybe<Scalars['String']>;
  transaction_status?: Maybe<Scalars['String']>;
  transaction_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Investor_Transaction_Min_Fields = {
  __typename?: 'investor_transaction_min_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  bounce_charges?: Maybe<Scalars['numeric']>;
  capital?: Maybe<Scalars['numeric']>;
  counterparty_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  interest?: Maybe<Scalars['numeric']>;
  interest_charges?: Maybe<Scalars['numeric']>;
  investment_id?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  scheduled_emi_id?: Maybe<Scalars['Int']>;
  tds?: Maybe<Scalars['numeric']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
  transaction_method?: Maybe<Scalars['String']>;
  transaction_method_id?: Maybe<Scalars['String']>;
  transaction_status?: Maybe<Scalars['String']>;
  transaction_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "investor_transaction" */
export type Investor_Transaction_Mutation_Response = {
  __typename?: 'investor_transaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Investor_Transaction>;
};

/** on_conflict condition type for table "investor_transaction" */
export type Investor_Transaction_On_Conflict = {
  constraint: Investor_Transaction_Constraint;
  update_columns?: Array<Investor_Transaction_Update_Column>;
  where?: InputMaybe<Investor_Transaction_Bool_Exp>;
};

/** Ordering options when selecting data from "investor_transaction". */
export type Investor_Transaction_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  interest_charges?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  scheduled_emi?: InputMaybe<Scheduled_Emi_Order_By>;
  scheduled_emi_id?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  trade?: InputMaybe<Trade_Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  transaction_method?: InputMaybe<Order_By>;
  transaction_method_id?: InputMaybe<Order_By>;
  transaction_status?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: investor_transaction */
export type Investor_Transaction_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Investor_Transaction_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "investor_transaction" */
export enum Investor_Transaction_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  BounceCharges = 'bounce_charges',
  /** column name */
  Capital = 'capital',
  /** column name */
  CounterpartyId = 'counterparty_id',
  /** column name */
  Id = 'id',
  /** column name */
  Interest = 'interest',
  /** column name */
  InterestCharges = 'interest_charges',
  /** column name */
  InvestmentId = 'investment_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  ScheduledEmiId = 'scheduled_emi_id',
  /** column name */
  Tds = 'tds',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  TradeInstallmentSeq = 'trade_installment_seq',
  /** column name */
  TransactionMethod = 'transaction_method',
  /** column name */
  TransactionMethodId = 'transaction_method_id',
  /** column name */
  TransactionStatus = 'transaction_status',
  /** column name */
  TransactionType = 'transaction_type'
}

/** input type for updating data in table "investor_transaction" */
export type Investor_Transaction_Set_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  bounce_charges?: InputMaybe<Scalars['numeric']>;
  capital?: InputMaybe<Scalars['numeric']>;
  counterparty_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  interest?: InputMaybe<Scalars['numeric']>;
  interest_charges?: InputMaybe<Scalars['numeric']>;
  investment_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  remarks?: InputMaybe<Scalars['String']>;
  scheduled_emi_id?: InputMaybe<Scalars['Int']>;
  tds?: InputMaybe<Scalars['numeric']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
  transaction_method?: InputMaybe<Scalars['String']>;
  transaction_method_id?: InputMaybe<Scalars['String']>;
  transaction_status?: InputMaybe<Scalars['String']>;
  transaction_type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Investor_Transaction_Stddev_Fields = {
  __typename?: 'investor_transaction_stddev_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  interest_charges?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Investor_Transaction_Stddev_Pop_Fields = {
  __typename?: 'investor_transaction_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  interest_charges?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Investor_Transaction_Stddev_Samp_Fields = {
  __typename?: 'investor_transaction_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  interest_charges?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "investor_transaction" */
export type Investor_Transaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Investor_Transaction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Investor_Transaction_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  bounce_charges?: InputMaybe<Scalars['numeric']>;
  capital?: InputMaybe<Scalars['numeric']>;
  counterparty_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  interest?: InputMaybe<Scalars['numeric']>;
  interest_charges?: InputMaybe<Scalars['numeric']>;
  investment_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  remarks?: InputMaybe<Scalars['String']>;
  scheduled_emi_id?: InputMaybe<Scalars['Int']>;
  tds?: InputMaybe<Scalars['numeric']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
  transaction_method?: InputMaybe<Scalars['String']>;
  transaction_method_id?: InputMaybe<Scalars['String']>;
  transaction_status?: InputMaybe<Scalars['String']>;
  transaction_type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Investor_Transaction_Sum_Fields = {
  __typename?: 'investor_transaction_sum_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  bounce_charges?: Maybe<Scalars['numeric']>;
  capital?: Maybe<Scalars['numeric']>;
  counterparty_id?: Maybe<Scalars['Int']>;
  interest?: Maybe<Scalars['numeric']>;
  interest_charges?: Maybe<Scalars['numeric']>;
  investment_id?: Maybe<Scalars['Int']>;
  scheduled_emi_id?: Maybe<Scalars['Int']>;
  tds?: Maybe<Scalars['numeric']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
};

/** update columns of table "investor_transaction" */
export enum Investor_Transaction_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  BounceCharges = 'bounce_charges',
  /** column name */
  Capital = 'capital',
  /** column name */
  CounterpartyId = 'counterparty_id',
  /** column name */
  Id = 'id',
  /** column name */
  Interest = 'interest',
  /** column name */
  InterestCharges = 'interest_charges',
  /** column name */
  InvestmentId = 'investment_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  ScheduledEmiId = 'scheduled_emi_id',
  /** column name */
  Tds = 'tds',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  TradeInstallmentSeq = 'trade_installment_seq',
  /** column name */
  TransactionMethod = 'transaction_method',
  /** column name */
  TransactionMethodId = 'transaction_method_id',
  /** column name */
  TransactionStatus = 'transaction_status',
  /** column name */
  TransactionType = 'transaction_type'
}

export type Investor_Transaction_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Investor_Transaction_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Investor_Transaction_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Investor_Transaction_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Investor_Transaction_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Investor_Transaction_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Investor_Transaction_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Investor_Transaction_Set_Input>;
  /** filter the rows which have to be updated */
  where: Investor_Transaction_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Investor_Transaction_Var_Pop_Fields = {
  __typename?: 'investor_transaction_var_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  interest_charges?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Investor_Transaction_Var_Samp_Fields = {
  __typename?: 'investor_transaction_var_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  interest_charges?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Investor_Transaction_Variance_Fields = {
  __typename?: 'investor_transaction_variance_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  interest_charges?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** update columns of table "investor" */
export enum Investor_Update_Column {
  /** column name */
  AddressLine1 = 'address_line1',
  /** column name */
  AddressLine2 = 'address_line2',
  /** column name */
  AddressLine3 = 'address_line3',
  /** column name */
  AgreementFileId = 'agreement_file_id',
  /** column name */
  AgreementSignedDate = 'agreement_signed_date',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  BudgetCommittedFrom = 'budget_committed_from',
  /** column name */
  Cin = 'cin',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Domain = 'domain',
  /** column name */
  Gstin = 'gstin',
  /** column name */
  Id = 'id',
  /** column name */
  MaxAmountPerCompany = 'max_amount_per_company',
  /** column name */
  MaxAmountPerTrade = 'max_amount_per_trade',
  /** column name */
  Name = 'name',
  /** column name */
  Pan = 'pan',
  /** column name */
  SignerDetails = 'signer_details',
  /** column name */
  StateId = 'state_id',
  /** column name */
  Status = 'status',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  TotalCommittedBudget = 'total_committed_budget',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Zipcode = 'zipcode'
}

export type Investor_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Investor_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Investor_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Investor_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Investor_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Investor_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Investor_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Investor_Set_Input>;
  /** filter the rows which have to be updated */
  where: Investor_Bool_Exp;
};

/** columns and relationships of "investor_user" */
export type Investor_User = {
  __typename?: 'investor_user';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  investor: Investor;
  investor_id: Scalars['Int'];
  pan?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
  /** An object relationship */
  user: User;
  user_id: Scalars['Int'];
};

/** aggregated selection of "investor_user" */
export type Investor_User_Aggregate = {
  __typename?: 'investor_user_aggregate';
  aggregate?: Maybe<Investor_User_Aggregate_Fields>;
  nodes: Array<Investor_User>;
};

export type Investor_User_Aggregate_Bool_Exp = {
  count?: InputMaybe<Investor_User_Aggregate_Bool_Exp_Count>;
};

export type Investor_User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Investor_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Investor_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "investor_user" */
export type Investor_User_Aggregate_Fields = {
  __typename?: 'investor_user_aggregate_fields';
  avg?: Maybe<Investor_User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Investor_User_Max_Fields>;
  min?: Maybe<Investor_User_Min_Fields>;
  stddev?: Maybe<Investor_User_Stddev_Fields>;
  stddev_pop?: Maybe<Investor_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Investor_User_Stddev_Samp_Fields>;
  sum?: Maybe<Investor_User_Sum_Fields>;
  var_pop?: Maybe<Investor_User_Var_Pop_Fields>;
  var_samp?: Maybe<Investor_User_Var_Samp_Fields>;
  variance?: Maybe<Investor_User_Variance_Fields>;
};


/** aggregate fields of "investor_user" */
export type Investor_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Investor_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "investor_user" */
export type Investor_User_Aggregate_Order_By = {
  avg?: InputMaybe<Investor_User_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Investor_User_Max_Order_By>;
  min?: InputMaybe<Investor_User_Min_Order_By>;
  stddev?: InputMaybe<Investor_User_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Investor_User_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Investor_User_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Investor_User_Sum_Order_By>;
  var_pop?: InputMaybe<Investor_User_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Investor_User_Var_Samp_Order_By>;
  variance?: InputMaybe<Investor_User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "investor_user" */
export type Investor_User_Arr_Rel_Insert_Input = {
  data: Array<Investor_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Investor_User_On_Conflict>;
};

/** aggregate avg on columns */
export type Investor_User_Avg_Fields = {
  __typename?: 'investor_user_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "investor_user" */
export type Investor_User_Avg_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "investor_user". All fields are combined with a logical 'AND'. */
export type Investor_User_Bool_Exp = {
  _and?: InputMaybe<Array<Investor_User_Bool_Exp>>;
  _not?: InputMaybe<Investor_User_Bool_Exp>;
  _or?: InputMaybe<Array<Investor_User_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  investor?: InputMaybe<Investor_Bool_Exp>;
  investor_id?: InputMaybe<Int_Comparison_Exp>;
  pan?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "investor_user" */
export enum Investor_User_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvestorUserPkey = 'investor_user_pkey',
  /** unique or primary key constraint on columns "user_id" */
  InvestorUserUserIdIdx = 'investor_user_user_id_idx'
}

/** input type for incrementing numeric columns in table "investor_user" */
export type Investor_User_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "investor_user" */
export type Investor_User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  investor?: InputMaybe<Investor_Obj_Rel_Insert_Input>;
  investor_id?: InputMaybe<Scalars['Int']>;
  pan?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Investor_User_Max_Fields = {
  __typename?: 'investor_user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  investor_id?: Maybe<Scalars['Int']>;
  pan?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "investor_user" */
export type Investor_User_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  pan?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Investor_User_Min_Fields = {
  __typename?: 'investor_user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  investor_id?: Maybe<Scalars['Int']>;
  pan?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "investor_user" */
export type Investor_User_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  pan?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "investor_user" */
export type Investor_User_Mutation_Response = {
  __typename?: 'investor_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Investor_User>;
};

/** on_conflict condition type for table "investor_user" */
export type Investor_User_On_Conflict = {
  constraint: Investor_User_Constraint;
  update_columns?: Array<Investor_User_Update_Column>;
  where?: InputMaybe<Investor_User_Bool_Exp>;
};

/** Ordering options when selecting data from "investor_user". */
export type Investor_User_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor?: InputMaybe<Investor_Order_By>;
  investor_id?: InputMaybe<Order_By>;
  pan?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: investor_user */
export type Investor_User_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "investor_user" */
export enum Investor_User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  InvestorId = 'investor_id',
  /** column name */
  Pan = 'pan',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "investor_user" */
export type Investor_User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  pan?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Investor_User_Stddev_Fields = {
  __typename?: 'investor_user_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "investor_user" */
export type Investor_User_Stddev_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Investor_User_Stddev_Pop_Fields = {
  __typename?: 'investor_user_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "investor_user" */
export type Investor_User_Stddev_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Investor_User_Stddev_Samp_Fields = {
  __typename?: 'investor_user_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "investor_user" */
export type Investor_User_Stddev_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "investor_user" */
export type Investor_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Investor_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Investor_User_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  investor_id?: InputMaybe<Scalars['Int']>;
  pan?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Investor_User_Sum_Fields = {
  __typename?: 'investor_user_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  investor_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "investor_user" */
export type Investor_User_Sum_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "investor_user" */
export enum Investor_User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  InvestorId = 'investor_id',
  /** column name */
  Pan = 'pan',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id'
}

export type Investor_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Investor_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Investor_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Investor_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Investor_User_Var_Pop_Fields = {
  __typename?: 'investor_user_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "investor_user" */
export type Investor_User_Var_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Investor_User_Var_Samp_Fields = {
  __typename?: 'investor_user_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "investor_user" */
export type Investor_User_Var_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Investor_User_Variance_Fields = {
  __typename?: 'investor_user_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investor_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "investor_user" */
export type Investor_User_Variance_Order_By = {
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Investor_Var_Pop_Fields = {
  __typename?: 'investor_var_pop_fields';
  agreement_file_id?: Maybe<Scalars['Float']>;
  approved_by?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_amount_per_company?: Maybe<Scalars['Float']>;
  max_amount_per_trade?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
  total_committed_budget?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  zipcode?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Investor_Var_Samp_Fields = {
  __typename?: 'investor_var_samp_fields';
  agreement_file_id?: Maybe<Scalars['Float']>;
  approved_by?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_amount_per_company?: Maybe<Scalars['Float']>;
  max_amount_per_trade?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
  total_committed_budget?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  zipcode?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Investor_Variance_Fields = {
  __typename?: 'investor_variance_fields';
  agreement_file_id?: Maybe<Scalars['Float']>;
  approved_by?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_amount_per_company?: Maybe<Scalars['Float']>;
  max_amount_per_trade?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
  total_committed_budget?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  zipcode?: Maybe<Scalars['Float']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "lifecycle_question_crm_mapper" */
export type Lifecycle_Question_Crm_Mapper = {
  __typename?: 'lifecycle_question_crm_mapper';
  crm_key: Scalars['String'];
  is_active: Scalars['Boolean'];
  serial_number: Scalars['String'];
};

/** aggregated selection of "lifecycle_question_crm_mapper" */
export type Lifecycle_Question_Crm_Mapper_Aggregate = {
  __typename?: 'lifecycle_question_crm_mapper_aggregate';
  aggregate?: Maybe<Lifecycle_Question_Crm_Mapper_Aggregate_Fields>;
  nodes: Array<Lifecycle_Question_Crm_Mapper>;
};

/** aggregate fields of "lifecycle_question_crm_mapper" */
export type Lifecycle_Question_Crm_Mapper_Aggregate_Fields = {
  __typename?: 'lifecycle_question_crm_mapper_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lifecycle_Question_Crm_Mapper_Max_Fields>;
  min?: Maybe<Lifecycle_Question_Crm_Mapper_Min_Fields>;
};


/** aggregate fields of "lifecycle_question_crm_mapper" */
export type Lifecycle_Question_Crm_Mapper_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lifecycle_Question_Crm_Mapper_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lifecycle_question_crm_mapper". All fields are combined with a logical 'AND'. */
export type Lifecycle_Question_Crm_Mapper_Bool_Exp = {
  _and?: InputMaybe<Array<Lifecycle_Question_Crm_Mapper_Bool_Exp>>;
  _not?: InputMaybe<Lifecycle_Question_Crm_Mapper_Bool_Exp>;
  _or?: InputMaybe<Array<Lifecycle_Question_Crm_Mapper_Bool_Exp>>;
  crm_key?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lifecycle_question_crm_mapper" */
export enum Lifecycle_Question_Crm_Mapper_Constraint {
  /** unique or primary key constraint on columns "serial_number" */
  LifecycleQuestionCrmMapperPkey = 'lifecycle_question_crm_mapper_pkey'
}

/** input type for inserting data into table "lifecycle_question_crm_mapper" */
export type Lifecycle_Question_Crm_Mapper_Insert_Input = {
  crm_key?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lifecycle_Question_Crm_Mapper_Max_Fields = {
  __typename?: 'lifecycle_question_crm_mapper_max_fields';
  crm_key?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lifecycle_Question_Crm_Mapper_Min_Fields = {
  __typename?: 'lifecycle_question_crm_mapper_min_fields';
  crm_key?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lifecycle_question_crm_mapper" */
export type Lifecycle_Question_Crm_Mapper_Mutation_Response = {
  __typename?: 'lifecycle_question_crm_mapper_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lifecycle_Question_Crm_Mapper>;
};

/** on_conflict condition type for table "lifecycle_question_crm_mapper" */
export type Lifecycle_Question_Crm_Mapper_On_Conflict = {
  constraint: Lifecycle_Question_Crm_Mapper_Constraint;
  update_columns?: Array<Lifecycle_Question_Crm_Mapper_Update_Column>;
  where?: InputMaybe<Lifecycle_Question_Crm_Mapper_Bool_Exp>;
};

/** Ordering options when selecting data from "lifecycle_question_crm_mapper". */
export type Lifecycle_Question_Crm_Mapper_Order_By = {
  crm_key?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lifecycle_question_crm_mapper */
export type Lifecycle_Question_Crm_Mapper_Pk_Columns_Input = {
  serial_number: Scalars['String'];
};

/** select columns of table "lifecycle_question_crm_mapper" */
export enum Lifecycle_Question_Crm_Mapper_Select_Column {
  /** column name */
  CrmKey = 'crm_key',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  SerialNumber = 'serial_number'
}

/** input type for updating data in table "lifecycle_question_crm_mapper" */
export type Lifecycle_Question_Crm_Mapper_Set_Input = {
  crm_key?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "lifecycle_question_crm_mapper" */
export type Lifecycle_Question_Crm_Mapper_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lifecycle_Question_Crm_Mapper_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lifecycle_Question_Crm_Mapper_Stream_Cursor_Value_Input = {
  crm_key?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** update columns of table "lifecycle_question_crm_mapper" */
export enum Lifecycle_Question_Crm_Mapper_Update_Column {
  /** column name */
  CrmKey = 'crm_key',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  SerialNumber = 'serial_number'
}

export type Lifecycle_Question_Crm_Mapper_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lifecycle_Question_Crm_Mapper_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lifecycle_Question_Crm_Mapper_Bool_Exp;
};

/** columns and relationships of "lifecycle_question_result" */
export type Lifecycle_Question_Result = {
  __typename?: 'lifecycle_question_result';
  answer: Scalars['jsonb'];
  category: Ref_Lifecycle_Question_Category_Enum;
  /** An object relationship */
  company: Company;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "lifecycle_question_result" */
export type Lifecycle_Question_ResultAnswerArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "lifecycle_question_result" */
export type Lifecycle_Question_Result_Aggregate = {
  __typename?: 'lifecycle_question_result_aggregate';
  aggregate?: Maybe<Lifecycle_Question_Result_Aggregate_Fields>;
  nodes: Array<Lifecycle_Question_Result>;
};

/** aggregate fields of "lifecycle_question_result" */
export type Lifecycle_Question_Result_Aggregate_Fields = {
  __typename?: 'lifecycle_question_result_aggregate_fields';
  avg?: Maybe<Lifecycle_Question_Result_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lifecycle_Question_Result_Max_Fields>;
  min?: Maybe<Lifecycle_Question_Result_Min_Fields>;
  stddev?: Maybe<Lifecycle_Question_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Lifecycle_Question_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lifecycle_Question_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Lifecycle_Question_Result_Sum_Fields>;
  var_pop?: Maybe<Lifecycle_Question_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Lifecycle_Question_Result_Var_Samp_Fields>;
  variance?: Maybe<Lifecycle_Question_Result_Variance_Fields>;
};


/** aggregate fields of "lifecycle_question_result" */
export type Lifecycle_Question_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lifecycle_Question_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Lifecycle_Question_Result_Append_Input = {
  answer?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Lifecycle_Question_Result_Avg_Fields = {
  __typename?: 'lifecycle_question_result_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lifecycle_question_result". All fields are combined with a logical 'AND'. */
export type Lifecycle_Question_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Lifecycle_Question_Result_Bool_Exp>>;
  _not?: InputMaybe<Lifecycle_Question_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Lifecycle_Question_Result_Bool_Exp>>;
  answer?: InputMaybe<Jsonb_Comparison_Exp>;
  category?: InputMaybe<Ref_Lifecycle_Question_Category_Enum_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lifecycle_question_result" */
export enum Lifecycle_Question_Result_Constraint {
  /** unique or primary key constraint on columns "id" */
  LifecycleQuestionResultPkey = 'lifecycle_question_result_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Lifecycle_Question_Result_Delete_At_Path_Input = {
  answer?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Lifecycle_Question_Result_Delete_Elem_Input = {
  answer?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Lifecycle_Question_Result_Delete_Key_Input = {
  answer?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "lifecycle_question_result" */
export type Lifecycle_Question_Result_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lifecycle_question_result" */
export type Lifecycle_Question_Result_Insert_Input = {
  answer?: InputMaybe<Scalars['jsonb']>;
  category?: InputMaybe<Ref_Lifecycle_Question_Category_Enum>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Lifecycle_Question_Result_Max_Fields = {
  __typename?: 'lifecycle_question_result_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Lifecycle_Question_Result_Min_Fields = {
  __typename?: 'lifecycle_question_result_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "lifecycle_question_result" */
export type Lifecycle_Question_Result_Mutation_Response = {
  __typename?: 'lifecycle_question_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lifecycle_Question_Result>;
};

/** input type for inserting object relation for remote table "lifecycle_question_result" */
export type Lifecycle_Question_Result_Obj_Rel_Insert_Input = {
  data: Lifecycle_Question_Result_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lifecycle_Question_Result_On_Conflict>;
};

/** on_conflict condition type for table "lifecycle_question_result" */
export type Lifecycle_Question_Result_On_Conflict = {
  constraint: Lifecycle_Question_Result_Constraint;
  update_columns?: Array<Lifecycle_Question_Result_Update_Column>;
  where?: InputMaybe<Lifecycle_Question_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "lifecycle_question_result". */
export type Lifecycle_Question_Result_Order_By = {
  answer?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lifecycle_question_result */
export type Lifecycle_Question_Result_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Lifecycle_Question_Result_Prepend_Input = {
  answer?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "lifecycle_question_result" */
export enum Lifecycle_Question_Result_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  Category = 'category',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "lifecycle_question_result" */
export type Lifecycle_Question_Result_Set_Input = {
  answer?: InputMaybe<Scalars['jsonb']>;
  category?: InputMaybe<Ref_Lifecycle_Question_Category_Enum>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lifecycle_Question_Result_Stddev_Fields = {
  __typename?: 'lifecycle_question_result_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lifecycle_Question_Result_Stddev_Pop_Fields = {
  __typename?: 'lifecycle_question_result_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lifecycle_Question_Result_Stddev_Samp_Fields = {
  __typename?: 'lifecycle_question_result_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "lifecycle_question_result" */
export type Lifecycle_Question_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lifecycle_Question_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lifecycle_Question_Result_Stream_Cursor_Value_Input = {
  answer?: InputMaybe<Scalars['jsonb']>;
  category?: InputMaybe<Ref_Lifecycle_Question_Category_Enum>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Lifecycle_Question_Result_Sum_Fields = {
  __typename?: 'lifecycle_question_result_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "lifecycle_question_result" */
export enum Lifecycle_Question_Result_Update_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  Category = 'category',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Lifecycle_Question_Result_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Lifecycle_Question_Result_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Lifecycle_Question_Result_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Lifecycle_Question_Result_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Lifecycle_Question_Result_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lifecycle_Question_Result_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Lifecycle_Question_Result_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lifecycle_Question_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lifecycle_Question_Result_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lifecycle_Question_Result_Var_Pop_Fields = {
  __typename?: 'lifecycle_question_result_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lifecycle_Question_Result_Var_Samp_Fields = {
  __typename?: 'lifecycle_question_result_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lifecycle_Question_Result_Variance_Fields = {
  __typename?: 'lifecycle_question_result_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "mandate" */
export type Mandate = {
  __typename?: 'mandate';
  /** An object relationship */
  account: Account;
  account_id: Scalars['Int'];
  /** An object relationship */
  company: Company;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  failure_reason?: Maybe<Scalars['jsonb']>;
  frequency: Ref_Mandate_Frequency_Enum;
  id: Scalars['Int'];
  max_presentation_amount: Scalars['numeric'];
  metadata?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  signed_file?: Maybe<File_Registry>;
  signed_file_id?: Maybe<Scalars['Int']>;
  source_id: Scalars['String'];
  start_date: Scalars['timestamptz'];
  status: Ref_Mandate_Status_Enum;
  type: Ref_Mandate_Type_Enum;
  umrn?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "mandate" */
export type MandateFailure_ReasonArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "mandate" */
export type MandateMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "mandate" */
export type Mandate_Aggregate = {
  __typename?: 'mandate_aggregate';
  aggregate?: Maybe<Mandate_Aggregate_Fields>;
  nodes: Array<Mandate>;
};

export type Mandate_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mandate_Aggregate_Bool_Exp_Count>;
};

export type Mandate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mandate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mandate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mandate" */
export type Mandate_Aggregate_Fields = {
  __typename?: 'mandate_aggregate_fields';
  avg?: Maybe<Mandate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mandate_Max_Fields>;
  min?: Maybe<Mandate_Min_Fields>;
  stddev?: Maybe<Mandate_Stddev_Fields>;
  stddev_pop?: Maybe<Mandate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mandate_Stddev_Samp_Fields>;
  sum?: Maybe<Mandate_Sum_Fields>;
  var_pop?: Maybe<Mandate_Var_Pop_Fields>;
  var_samp?: Maybe<Mandate_Var_Samp_Fields>;
  variance?: Maybe<Mandate_Variance_Fields>;
};


/** aggregate fields of "mandate" */
export type Mandate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mandate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mandate" */
export type Mandate_Aggregate_Order_By = {
  avg?: InputMaybe<Mandate_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mandate_Max_Order_By>;
  min?: InputMaybe<Mandate_Min_Order_By>;
  stddev?: InputMaybe<Mandate_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mandate_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mandate_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mandate_Sum_Order_By>;
  var_pop?: InputMaybe<Mandate_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mandate_Var_Samp_Order_By>;
  variance?: InputMaybe<Mandate_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mandate_Append_Input = {
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "mandate" */
export type Mandate_Arr_Rel_Insert_Input = {
  data: Array<Mandate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mandate_On_Conflict>;
};

/** aggregate avg on columns */
export type Mandate_Avg_Fields = {
  __typename?: 'mandate_avg_fields';
  account_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "mandate" */
export type Mandate_Avg_Order_By = {
  account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  signed_file_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mandate". All fields are combined with a logical 'AND'. */
export type Mandate_Bool_Exp = {
  _and?: InputMaybe<Array<Mandate_Bool_Exp>>;
  _not?: InputMaybe<Mandate_Bool_Exp>;
  _or?: InputMaybe<Array<Mandate_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_id?: InputMaybe<Int_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  end_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  failure_reason?: InputMaybe<Jsonb_Comparison_Exp>;
  frequency?: InputMaybe<Ref_Mandate_Frequency_Enum_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  max_presentation_amount?: InputMaybe<Numeric_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  signed_file?: InputMaybe<File_Registry_Bool_Exp>;
  signed_file_id?: InputMaybe<Int_Comparison_Exp>;
  source_id?: InputMaybe<String_Comparison_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<Ref_Mandate_Status_Enum_Comparison_Exp>;
  type?: InputMaybe<Ref_Mandate_Type_Enum_Comparison_Exp>;
  umrn?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "mandate" */
export enum Mandate_Constraint {
  /** unique or primary key constraint on columns "id" */
  MandatePkey = 'mandate_pkey',
  /** unique or primary key constraint on columns "source_id" */
  MandateSourceIdKey = 'mandate_source_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mandate_Delete_At_Path_Input = {
  failure_reason?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mandate_Delete_Elem_Input = {
  failure_reason?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mandate_Delete_Key_Input = {
  failure_reason?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "mandate" */
export type Mandate_Inc_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  max_presentation_amount?: InputMaybe<Scalars['numeric']>;
  signed_file_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "mandate" */
export type Mandate_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['Int']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  frequency?: InputMaybe<Ref_Mandate_Frequency_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  max_presentation_amount?: InputMaybe<Scalars['numeric']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  signed_file?: InputMaybe<File_Registry_Obj_Rel_Insert_Input>;
  signed_file_id?: InputMaybe<Scalars['Int']>;
  source_id?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Ref_Mandate_Status_Enum>;
  type?: InputMaybe<Ref_Mandate_Type_Enum>;
  umrn?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Mandate_Max_Fields = {
  __typename?: 'mandate_max_fields';
  account_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  max_presentation_amount?: Maybe<Scalars['numeric']>;
  signed_file_id?: Maybe<Scalars['Int']>;
  source_id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  umrn?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "mandate" */
export type Mandate_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  signed_file_id?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  umrn?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mandate_Min_Fields = {
  __typename?: 'mandate_min_fields';
  account_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  max_presentation_amount?: Maybe<Scalars['numeric']>;
  signed_file_id?: Maybe<Scalars['Int']>;
  source_id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  umrn?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "mandate" */
export type Mandate_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  signed_file_id?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  umrn?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mandate" */
export type Mandate_Mutation_Response = {
  __typename?: 'mandate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mandate>;
};

/** input type for inserting object relation for remote table "mandate" */
export type Mandate_Obj_Rel_Insert_Input = {
  data: Mandate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mandate_On_Conflict>;
};

/** on_conflict condition type for table "mandate" */
export type Mandate_On_Conflict = {
  constraint: Mandate_Constraint;
  update_columns?: Array<Mandate_Update_Column>;
  where?: InputMaybe<Mandate_Bool_Exp>;
};

/** Ordering options when selecting data from "mandate". */
export type Mandate_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_id?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  failure_reason?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  signed_file?: InputMaybe<File_Registry_Order_By>;
  signed_file_id?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  umrn?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mandate */
export type Mandate_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mandate_Prepend_Input = {
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "mandate" */
export enum Mandate_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  MaxPresentationAmount = 'max_presentation_amount',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  SignedFileId = 'signed_file_id',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  Umrn = 'umrn',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "mandate" */
export type Mandate_Set_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  frequency?: InputMaybe<Ref_Mandate_Frequency_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  max_presentation_amount?: InputMaybe<Scalars['numeric']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  signed_file_id?: InputMaybe<Scalars['Int']>;
  source_id?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Ref_Mandate_Status_Enum>;
  type?: InputMaybe<Ref_Mandate_Type_Enum>;
  umrn?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Mandate_Stddev_Fields = {
  __typename?: 'mandate_stddev_fields';
  account_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "mandate" */
export type Mandate_Stddev_Order_By = {
  account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  signed_file_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mandate_Stddev_Pop_Fields = {
  __typename?: 'mandate_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "mandate" */
export type Mandate_Stddev_Pop_Order_By = {
  account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  signed_file_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mandate_Stddev_Samp_Fields = {
  __typename?: 'mandate_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "mandate" */
export type Mandate_Stddev_Samp_Order_By = {
  account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  signed_file_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mandate" */
export type Mandate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mandate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mandate_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  frequency?: InputMaybe<Ref_Mandate_Frequency_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  max_presentation_amount?: InputMaybe<Scalars['numeric']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  signed_file_id?: InputMaybe<Scalars['Int']>;
  source_id?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Ref_Mandate_Status_Enum>;
  type?: InputMaybe<Ref_Mandate_Type_Enum>;
  umrn?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Mandate_Sum_Fields = {
  __typename?: 'mandate_sum_fields';
  account_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  max_presentation_amount?: Maybe<Scalars['numeric']>;
  signed_file_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "mandate" */
export type Mandate_Sum_Order_By = {
  account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  signed_file_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "mandate" */
export enum Mandate_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  MaxPresentationAmount = 'max_presentation_amount',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  SignedFileId = 'signed_file_id',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  Umrn = 'umrn',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Mandate_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Mandate_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Mandate_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Mandate_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Mandate_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mandate_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Mandate_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mandate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mandate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mandate_Var_Pop_Fields = {
  __typename?: 'mandate_var_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "mandate" */
export type Mandate_Var_Pop_Order_By = {
  account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  signed_file_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mandate_Var_Samp_Fields = {
  __typename?: 'mandate_var_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "mandate" */
export type Mandate_Var_Samp_Order_By = {
  account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  signed_file_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mandate_Variance_Fields = {
  __typename?: 'mandate_variance_fields';
  account_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  signed_file_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "mandate" */
export type Mandate_Variance_Order_By = {
  account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  signed_file_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

export enum MoveToStages {
  Closed = 'CLOSED',
  Cold = 'COLD',
  Investor = 'INVESTOR',
  Rejected = 'REJECTED',
  Sql = 'SQL'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  AddCollaborator?: Maybe<AddCollaboratorOutput>;
  AddDocumentchecklistForCompany?: Maybe<AddDocumentchecklistForCompanyOutput>;
  AddInvestorUser?: Maybe<AddInvestorUserOutput>;
  AddPhysicalAccount?: Maybe<AddPhysicalAccountOutput>;
  AddVirtualAccount?: Maybe<AddVirtualAccountOutput>;
  CheckColdCompanies?: Maybe<CheckColdCompaniesOutput>;
  CompanyAdditionalEmails?: Maybe<CompanyAdditionalEmailsOutput>;
  DeleteCollaborator?: Maybe<DeleteCollaboratorOutput>;
  DeletePhysicalAccount?: Maybe<DeletePhysicalAccountOutput>;
  DownloadAgreement?: Maybe<DownloadAgreementOutput>;
  DownloadAmortizationTable?: Maybe<DownloadAmortizationTableOutput>;
  DownloadFilesZip?: Maybe<DownloadFilesZipOutput>;
  DownloadZip?: Maybe<DownloadZipOutput>;
  EditTrade?: Maybe<EditTradeOutput>;
  ExportCompanyQueues?: Maybe<ExportCompanyQueuesOutput>;
  GenerateAnnexure?: Maybe<GenerateAnnexureOutput>;
  GenerateMISReport?: Maybe<GenerateMisReportOutput>;
  GetVirtualAccountBalance?: Maybe<GetVirtualAccountBalanceOutput>;
  InitiatePayoutRequest?: Maybe<InitiatePayoutRequestOutput>;
  MoveCompanyInQueue?: Maybe<MoveCompanyInQueueOutput>;
  NotifyAndTriggerEscrowReceivables?: Maybe<NotifyAndTriggerEscrowReceivablesOutput>;
  OpsCreateCompany?: Maybe<OpsCreateCompanyOutput>;
  OpsCreateInvestor?: Maybe<OpsCreateInvestorOutput>;
  ResendMailToCollaborator?: Maybe<ResendMailToCollaboratorOutput>;
  ResyncHubspot?: Maybe<ResyncHubspotOutput>;
  SendBankLinkagesStatusNotification?: Maybe<SendBankLinkagesStatusNotificationOutput>;
  SendDocumentchecklistNotification?: Maybe<SendDocumentchecklistNotificationOutput>;
  SubmitPreQualificationQuestionnaire?: Maybe<SubmitPreQualificationQuestionnaireOutput>;
  SyncCompanyEmailLogs?: Maybe<SyncCompanyEmailLogsOutput>;
  ToggleCompanyDocumentChecklist?: Maybe<ToggleCompanyDocumentChecklistOutput>;
  TransferTDSToCompany?: Maybe<TransferTdsToCompanyOutput>;
  TriggerCompanyClientAgreement?: Maybe<TriggerCompanyClientAgreementOutput>;
  TriggerCustomNDA?: Maybe<TriggerCustomNdaOutput>;
  UpdateInvestorUser?: Maybe<UpdateInvestorUserOutput>;
  UploadAgreement?: Maybe<UploadAgreementOutput>;
  UploadSubscriptionData?: Maybe<UploadSubscriptionDataOutput>;
  VerifyAndUpdateAutoReject?: Maybe<VerifyAndUpdateAutoRejectOutput>;
  accountBalanceEmailAlert?: Maybe<AccountBalanceEmailAlertOutput>;
  accountingExcelUpload?: Maybe<AccountingExcelUploadOutput>;
  accountingSync?: Maybe<AccountingSyncOutput>;
  addEMITransaction?: Maybe<AddEmiTransactionOutput>;
  addPayoutTransaction?: Maybe<AddPayoutTransactionOutput>;
  attachContractsToTrade?: Maybe<AttachContractsToTradeOutput>;
  bankStatementsUpload?: Maybe<BankStatementsUploadOutput>;
  closeBidding?: Maybe<CloseBiddingOutput>;
  createDeal?: Maybe<CreateDealOutput>;
  delete?: Maybe<DeleteOutput>;
  /** delete data from the table: "account" */
  delete_account?: Maybe<Account_Mutation_Response>;
  /** delete data from the table: "account_balance" */
  delete_account_balance?: Maybe<Account_Balance_Mutation_Response>;
  /** delete single row from the table: "account_balance" */
  delete_account_balance_by_pk?: Maybe<Account_Balance>;
  /** delete single row from the table: "account" */
  delete_account_by_pk?: Maybe<Account>;
  /** delete data from the table: "agreement_registry" */
  delete_agreement_registry?: Maybe<Agreement_Registry_Mutation_Response>;
  /** delete single row from the table: "agreement_registry" */
  delete_agreement_registry_by_pk?: Maybe<Agreement_Registry>;
  /** delete data from the table: "agreement_tenant" */
  delete_agreement_tenant?: Maybe<Agreement_Tenant_Mutation_Response>;
  /** delete single row from the table: "agreement_tenant" */
  delete_agreement_tenant_by_pk?: Maybe<Agreement_Tenant>;
  /** delete data from the table: "application_lifecycle_stage" */
  delete_application_lifecycle_stage?: Maybe<Application_Lifecycle_Stage_Mutation_Response>;
  /** delete single row from the table: "application_lifecycle_stage" */
  delete_application_lifecycle_stage_by_pk?: Maybe<Application_Lifecycle_Stage>;
  /** delete data from the table: "banking_partner_integration" */
  delete_banking_partner_integration?: Maybe<Banking_Partner_Integration_Mutation_Response>;
  /** delete data from the table: "bid" */
  delete_bid?: Maybe<Bid_Mutation_Response>;
  /** delete single row from the table: "bid" */
  delete_bid_by_pk?: Maybe<Bid>;
  /** delete data from the table: "blacklisted_tokens" */
  delete_blacklisted_tokens?: Maybe<Blacklisted_Tokens_Mutation_Response>;
  /** delete single row from the table: "blacklisted_tokens" */
  delete_blacklisted_tokens_by_pk?: Maybe<Blacklisted_Tokens>;
  /** delete data from the table: "company" */
  delete_company?: Maybe<Company_Mutation_Response>;
  /** delete data from the table: "company_application_crime_details" */
  delete_company_application_crime_details?: Maybe<Company_Application_Crime_Details_Mutation_Response>;
  /** delete single row from the table: "company_application_crime_details" */
  delete_company_application_crime_details_by_pk?: Maybe<Company_Application_Crime_Details>;
  /** delete data from the table: "company_application_epfo" */
  delete_company_application_epfo?: Maybe<Company_Application_Epfo_Mutation_Response>;
  /** delete single row from the table: "company_application_epfo" */
  delete_company_application_epfo_by_pk?: Maybe<Company_Application_Epfo>;
  /** delete data from the table: "company_authorized_signatory" */
  delete_company_authorized_signatory?: Maybe<Company_Authorized_Signatory_Mutation_Response>;
  /** delete single row from the table: "company_authorized_signatory" */
  delete_company_authorized_signatory_by_pk?: Maybe<Company_Authorized_Signatory>;
  /** delete single row from the table: "company" */
  delete_company_by_pk?: Maybe<Company>;
  /** delete data from the table: "company_cin" */
  delete_company_cin?: Maybe<Company_Cin_Mutation_Response>;
  /** delete single row from the table: "company_cin" */
  delete_company_cin_by_pk?: Maybe<Company_Cin>;
  /** delete data from the table: "company_email_log" */
  delete_company_email_log?: Maybe<Company_Email_Log_Mutation_Response>;
  /** delete single row from the table: "company_email_log" */
  delete_company_email_log_by_pk?: Maybe<Company_Email_Log>;
  /** delete data from the table: "company_gst_return" */
  delete_company_gst_return?: Maybe<Company_Gst_Return_Mutation_Response>;
  /** delete single row from the table: "company_gst_return" */
  delete_company_gst_return_by_pk?: Maybe<Company_Gst_Return>;
  /** delete data from the table: "company_integration" */
  delete_company_integration?: Maybe<Company_Integration_Mutation_Response>;
  /** delete single row from the table: "company_integration" */
  delete_company_integration_by_pk?: Maybe<Company_Integration>;
  /** delete data from the table: "company_integration_file" */
  delete_company_integration_file?: Maybe<Company_Integration_File_Mutation_Response>;
  /** delete single row from the table: "company_integration_file" */
  delete_company_integration_file_by_pk?: Maybe<Company_Integration_File>;
  /** delete data from the table: "company_lead_status" */
  delete_company_lead_status?: Maybe<Company_Lead_Status_Mutation_Response>;
  /** delete single row from the table: "company_lead_status" */
  delete_company_lead_status_by_pk?: Maybe<Company_Lead_Status>;
  /** delete data from the table: "company_reports" */
  delete_company_reports?: Maybe<Company_Reports_Mutation_Response>;
  /** delete single row from the table: "company_reports" */
  delete_company_reports_by_pk?: Maybe<Company_Reports>;
  /** delete data from the table: "company_score" */
  delete_company_score?: Maybe<Company_Score_Mutation_Response>;
  /** delete single row from the table: "company_score" */
  delete_company_score_by_pk?: Maybe<Company_Score>;
  /** delete data from the table: "company_score_factor" */
  delete_company_score_factor?: Maybe<Company_Score_Factor_Mutation_Response>;
  /** delete single row from the table: "company_score_factor" */
  delete_company_score_factor_by_pk?: Maybe<Company_Score_Factor>;
  /** delete data from the table: "company_score_time_series" */
  delete_company_score_time_series?: Maybe<Company_Score_Time_Series_Mutation_Response>;
  /** delete single row from the table: "company_score_time_series" */
  delete_company_score_time_series_by_pk?: Maybe<Company_Score_Time_Series>;
  /** delete data from the table: "company_statement" */
  delete_company_statement?: Maybe<Company_Statement_Mutation_Response>;
  /** delete single row from the table: "company_statement" */
  delete_company_statement_by_pk?: Maybe<Company_Statement>;
  /** delete data from the table: "company_tan" */
  delete_company_tan?: Maybe<Company_Tan_Mutation_Response>;
  /** delete single row from the table: "company_tan" */
  delete_company_tan_by_pk?: Maybe<Company_Tan>;
  /** delete data from the table: "company_type_factor_benchmark" */
  delete_company_type_factor_benchmark?: Maybe<Company_Type_Factor_Benchmark_Mutation_Response>;
  /** delete single row from the table: "company_type_factor_benchmark" */
  delete_company_type_factor_benchmark_by_pk?: Maybe<Company_Type_Factor_Benchmark>;
  /** delete data from the table: "company_user" */
  delete_company_user?: Maybe<Company_User_Mutation_Response>;
  /** delete single row from the table: "company_user" */
  delete_company_user_by_pk?: Maybe<Company_User>;
  /** delete data from the table: "deal" */
  delete_deal?: Maybe<Deal_Mutation_Response>;
  /** delete single row from the table: "deal" */
  delete_deal_by_pk?: Maybe<Deal>;
  /** delete data from the table: "document_checklist" */
  delete_document_checklist?: Maybe<Document_Checklist_Mutation_Response>;
  /** delete single row from the table: "document_checklist" */
  delete_document_checklist_by_pk?: Maybe<Document_Checklist>;
  /** delete data from the table: "document_checklist_category" */
  delete_document_checklist_category?: Maybe<Document_Checklist_Category_Mutation_Response>;
  /** delete single row from the table: "document_checklist_category" */
  delete_document_checklist_category_by_pk?: Maybe<Document_Checklist_Category>;
  /** delete data from the table: "email_events" */
  delete_email_events?: Maybe<Email_Events_Mutation_Response>;
  /** delete single row from the table: "email_events" */
  delete_email_events_by_pk?: Maybe<Email_Events>;
  /** delete data from the table: "emi_tds" */
  delete_emi_tds?: Maybe<Emi_Tds_Mutation_Response>;
  /** delete single row from the table: "emi_tds" */
  delete_emi_tds_by_pk?: Maybe<Emi_Tds>;
  /** delete data from the table: "factor_benchmark_range" */
  delete_factor_benchmark_range?: Maybe<Factor_Benchmark_Range_Mutation_Response>;
  /** delete single row from the table: "factor_benchmark_range" */
  delete_factor_benchmark_range_by_pk?: Maybe<Factor_Benchmark_Range>;
  /** delete data from the table: "file_registry" */
  delete_file_registry?: Maybe<File_Registry_Mutation_Response>;
  /** delete single row from the table: "file_registry" */
  delete_file_registry_by_pk?: Maybe<File_Registry>;
  /** delete data from the table: "integration_auth" */
  delete_integration_auth?: Maybe<Integration_Auth_Mutation_Response>;
  /** delete single row from the table: "integration_auth" */
  delete_integration_auth_by_pk?: Maybe<Integration_Auth>;
  /** delete data from the table: "internal_statement" */
  delete_internal_statement?: Maybe<Internal_Statement_Mutation_Response>;
  /** delete single row from the table: "internal_statement" */
  delete_internal_statement_by_pk?: Maybe<Internal_Statement>;
  /** delete data from the table: "investment" */
  delete_investment?: Maybe<Investment_Mutation_Response>;
  /** delete single row from the table: "investment" */
  delete_investment_by_pk?: Maybe<Investment>;
  /** delete data from the table: "investor" */
  delete_investor?: Maybe<Investor_Mutation_Response>;
  /** delete single row from the table: "investor" */
  delete_investor_by_pk?: Maybe<Investor>;
  /** delete data from the table: "investor_preference" */
  delete_investor_preference?: Maybe<Investor_Preference_Mutation_Response>;
  /** delete single row from the table: "investor_preference" */
  delete_investor_preference_by_pk?: Maybe<Investor_Preference>;
  /** delete data from the table: "investor_statement" */
  delete_investor_statement?: Maybe<Investor_Statement_Mutation_Response>;
  /** delete single row from the table: "investor_statement" */
  delete_investor_statement_by_pk?: Maybe<Investor_Statement>;
  /** delete data from the table: "investor_transaction" */
  delete_investor_transaction?: Maybe<Investor_Transaction_Mutation_Response>;
  /** delete single row from the table: "investor_transaction" */
  delete_investor_transaction_by_pk?: Maybe<Investor_Transaction>;
  /** delete data from the table: "investor_user" */
  delete_investor_user?: Maybe<Investor_User_Mutation_Response>;
  /** delete single row from the table: "investor_user" */
  delete_investor_user_by_pk?: Maybe<Investor_User>;
  /** delete data from the table: "lifecycle_question_crm_mapper" */
  delete_lifecycle_question_crm_mapper?: Maybe<Lifecycle_Question_Crm_Mapper_Mutation_Response>;
  /** delete single row from the table: "lifecycle_question_crm_mapper" */
  delete_lifecycle_question_crm_mapper_by_pk?: Maybe<Lifecycle_Question_Crm_Mapper>;
  /** delete data from the table: "lifecycle_question_result" */
  delete_lifecycle_question_result?: Maybe<Lifecycle_Question_Result_Mutation_Response>;
  /** delete single row from the table: "lifecycle_question_result" */
  delete_lifecycle_question_result_by_pk?: Maybe<Lifecycle_Question_Result>;
  /** delete data from the table: "mandate" */
  delete_mandate?: Maybe<Mandate_Mutation_Response>;
  /** delete single row from the table: "mandate" */
  delete_mandate_by_pk?: Maybe<Mandate>;
  /** delete data from the table: "popular_banks" */
  delete_popular_banks?: Maybe<Popular_Banks_Mutation_Response>;
  /** delete single row from the table: "popular_banks" */
  delete_popular_banks_by_pk?: Maybe<Popular_Banks>;
  /** delete data from the table: "ref_account_status" */
  delete_ref_account_status?: Maybe<Ref_Account_Status_Mutation_Response>;
  /** delete single row from the table: "ref_account_status" */
  delete_ref_account_status_by_pk?: Maybe<Ref_Account_Status>;
  /** delete data from the table: "ref_account_type" */
  delete_ref_account_type?: Maybe<Ref_Account_Type_Mutation_Response>;
  /** delete single row from the table: "ref_account_type" */
  delete_ref_account_type_by_pk?: Maybe<Ref_Account_Type>;
  /** delete data from the table: "ref_agreement_category" */
  delete_ref_agreement_category?: Maybe<Ref_Agreement_Category_Mutation_Response>;
  /** delete single row from the table: "ref_agreement_category" */
  delete_ref_agreement_category_by_pk?: Maybe<Ref_Agreement_Category>;
  /** delete data from the table: "ref_agreement_status" */
  delete_ref_agreement_status?: Maybe<Ref_Agreement_Status_Mutation_Response>;
  /** delete single row from the table: "ref_agreement_status" */
  delete_ref_agreement_status_by_pk?: Maybe<Ref_Agreement_Status>;
  /** delete data from the table: "ref_application_allowed_district" */
  delete_ref_application_allowed_district?: Maybe<Ref_Application_Allowed_District_Mutation_Response>;
  /** delete single row from the table: "ref_application_allowed_district" */
  delete_ref_application_allowed_district_by_pk?: Maybe<Ref_Application_Allowed_District>;
  /** delete data from the table: "ref_application_lifecycle_stage" */
  delete_ref_application_lifecycle_stage?: Maybe<Ref_Application_Lifecycle_Stage_Mutation_Response>;
  /** delete single row from the table: "ref_application_lifecycle_stage" */
  delete_ref_application_lifecycle_stage_by_pk?: Maybe<Ref_Application_Lifecycle_Stage>;
  /** delete data from the table: "ref_bank" */
  delete_ref_bank?: Maybe<Ref_Bank_Mutation_Response>;
  /** delete single row from the table: "ref_bank" */
  delete_ref_bank_by_pk?: Maybe<Ref_Bank>;
  /** delete data from the table: "ref_bank_list" */
  delete_ref_bank_list?: Maybe<Ref_Bank_List_Mutation_Response>;
  /** delete single row from the table: "ref_bank_list" */
  delete_ref_bank_list_by_pk?: Maybe<Ref_Bank_List>;
  /** delete data from the table: "ref_bid" */
  delete_ref_bid?: Maybe<Ref_Bid_Mutation_Response>;
  /** delete single row from the table: "ref_bid" */
  delete_ref_bid_by_pk?: Maybe<Ref_Bid>;
  /** delete data from the table: "ref_billing_period" */
  delete_ref_billing_period?: Maybe<Ref_Billing_Period_Mutation_Response>;
  /** delete single row from the table: "ref_billing_period" */
  delete_ref_billing_period_by_pk?: Maybe<Ref_Billing_Period>;
  /** delete data from the table: "ref_category" */
  delete_ref_category?: Maybe<Ref_Category_Mutation_Response>;
  /** delete single row from the table: "ref_category" */
  delete_ref_category_by_pk?: Maybe<Ref_Category>;
  /** delete data from the table: "ref_collection_mode" */
  delete_ref_collection_mode?: Maybe<Ref_Collection_Mode_Mutation_Response>;
  /** delete single row from the table: "ref_collection_mode" */
  delete_ref_collection_mode_by_pk?: Maybe<Ref_Collection_Mode>;
  /** delete data from the table: "ref_company_lead_status" */
  delete_ref_company_lead_status?: Maybe<Ref_Company_Lead_Status_Mutation_Response>;
  /** delete single row from the table: "ref_company_lead_status" */
  delete_ref_company_lead_status_by_pk?: Maybe<Ref_Company_Lead_Status>;
  /** delete data from the table: "ref_company_likeliness" */
  delete_ref_company_likeliness?: Maybe<Ref_Company_Likeliness_Mutation_Response>;
  /** delete single row from the table: "ref_company_likeliness" */
  delete_ref_company_likeliness_by_pk?: Maybe<Ref_Company_Likeliness>;
  /** delete data from the table: "ref_company_status" */
  delete_ref_company_status?: Maybe<Ref_Company_Status_Mutation_Response>;
  /** delete single row from the table: "ref_company_status" */
  delete_ref_company_status_by_pk?: Maybe<Ref_Company_Status>;
  /** delete data from the table: "ref_company_type" */
  delete_ref_company_type?: Maybe<Ref_Company_Type_Mutation_Response>;
  /** delete single row from the table: "ref_company_type" */
  delete_ref_company_type_by_pk?: Maybe<Ref_Company_Type>;
  /** delete data from the table: "ref_country" */
  delete_ref_country?: Maybe<Ref_Country_Mutation_Response>;
  /** delete single row from the table: "ref_country" */
  delete_ref_country_by_pk?: Maybe<Ref_Country>;
  /** delete data from the table: "ref_currency" */
  delete_ref_currency?: Maybe<Ref_Currency_Mutation_Response>;
  /** delete single row from the table: "ref_currency" */
  delete_ref_currency_by_pk?: Maybe<Ref_Currency>;
  /** delete data from the table: "ref_debit_mode" */
  delete_ref_debit_mode?: Maybe<Ref_Debit_Mode_Mutation_Response>;
  /** delete single row from the table: "ref_debit_mode" */
  delete_ref_debit_mode_by_pk?: Maybe<Ref_Debit_Mode>;
  /** delete data from the table: "ref_document_checklist" */
  delete_ref_document_checklist?: Maybe<Ref_Document_Checklist_Mutation_Response>;
  /** delete single row from the table: "ref_document_checklist" */
  delete_ref_document_checklist_by_pk?: Maybe<Ref_Document_Checklist>;
  /** delete data from the table: "ref_document_checklist_category" */
  delete_ref_document_checklist_category?: Maybe<Ref_Document_Checklist_Category_Mutation_Response>;
  /** delete single row from the table: "ref_document_checklist_category" */
  delete_ref_document_checklist_category_by_pk?: Maybe<Ref_Document_Checklist_Category>;
  /** delete data from the table: "ref_document_checklist_integration" */
  delete_ref_document_checklist_integration?: Maybe<Ref_Document_Checklist_Integration_Mutation_Response>;
  /** delete single row from the table: "ref_document_checklist_integration" */
  delete_ref_document_checklist_integration_by_pk?: Maybe<Ref_Document_Checklist_Integration>;
  /** delete data from the table: "ref_document_checklist_status" */
  delete_ref_document_checklist_status?: Maybe<Ref_Document_Checklist_Status_Mutation_Response>;
  /** delete single row from the table: "ref_document_checklist_status" */
  delete_ref_document_checklist_status_by_pk?: Maybe<Ref_Document_Checklist_Status>;
  /** delete data from the table: "ref_email_events_type" */
  delete_ref_email_events_type?: Maybe<Ref_Email_Events_Type_Mutation_Response>;
  /** delete single row from the table: "ref_email_events_type" */
  delete_ref_email_events_type_by_pk?: Maybe<Ref_Email_Events_Type>;
  /** delete data from the table: "ref_file_category" */
  delete_ref_file_category?: Maybe<Ref_File_Category_Mutation_Response>;
  /** delete single row from the table: "ref_file_category" */
  delete_ref_file_category_by_pk?: Maybe<Ref_File_Category>;
  /** delete data from the table: "ref_file_category_mimetype" */
  delete_ref_file_category_mimetype?: Maybe<Ref_File_Category_Mimetype_Mutation_Response>;
  /** delete single row from the table: "ref_file_category_mimetype" */
  delete_ref_file_category_mimetype_by_pk?: Maybe<Ref_File_Category_Mimetype>;
  /** delete data from the table: "ref_industry" */
  delete_ref_industry?: Maybe<Ref_Industry_Mutation_Response>;
  /** delete single row from the table: "ref_industry" */
  delete_ref_industry_by_pk?: Maybe<Ref_Industry>;
  /** delete data from the table: "ref_integration_file_status" */
  delete_ref_integration_file_status?: Maybe<Ref_Integration_File_Status_Mutation_Response>;
  /** delete single row from the table: "ref_integration_file_status" */
  delete_ref_integration_file_status_by_pk?: Maybe<Ref_Integration_File_Status>;
  /** delete data from the table: "ref_integration_status" */
  delete_ref_integration_status?: Maybe<Ref_Integration_Status_Mutation_Response>;
  /** delete single row from the table: "ref_integration_status" */
  delete_ref_integration_status_by_pk?: Maybe<Ref_Integration_Status>;
  /** delete data from the table: "ref_investor_status" */
  delete_ref_investor_status?: Maybe<Ref_Investor_Status_Mutation_Response>;
  /** delete single row from the table: "ref_investor_status" */
  delete_ref_investor_status_by_pk?: Maybe<Ref_Investor_Status>;
  /** delete data from the table: "ref_investor_type" */
  delete_ref_investor_type?: Maybe<Ref_Investor_Type_Mutation_Response>;
  /** delete single row from the table: "ref_investor_type" */
  delete_ref_investor_type_by_pk?: Maybe<Ref_Investor_Type>;
  /** delete data from the table: "ref_lifecycle_question" */
  delete_ref_lifecycle_question?: Maybe<Ref_Lifecycle_Question_Mutation_Response>;
  /** delete single row from the table: "ref_lifecycle_question" */
  delete_ref_lifecycle_question_by_pk?: Maybe<Ref_Lifecycle_Question>;
  /** delete data from the table: "ref_lifecycle_question_category" */
  delete_ref_lifecycle_question_category?: Maybe<Ref_Lifecycle_Question_Category_Mutation_Response>;
  /** delete single row from the table: "ref_lifecycle_question_category" */
  delete_ref_lifecycle_question_category_by_pk?: Maybe<Ref_Lifecycle_Question_Category>;
  /** delete data from the table: "ref_mandate_frequency" */
  delete_ref_mandate_frequency?: Maybe<Ref_Mandate_Frequency_Mutation_Response>;
  /** delete single row from the table: "ref_mandate_frequency" */
  delete_ref_mandate_frequency_by_pk?: Maybe<Ref_Mandate_Frequency>;
  /** delete data from the table: "ref_mandate_status" */
  delete_ref_mandate_status?: Maybe<Ref_Mandate_Status_Mutation_Response>;
  /** delete single row from the table: "ref_mandate_status" */
  delete_ref_mandate_status_by_pk?: Maybe<Ref_Mandate_Status>;
  /** delete data from the table: "ref_mandate_type" */
  delete_ref_mandate_type?: Maybe<Ref_Mandate_Type_Mutation_Response>;
  /** delete single row from the table: "ref_mandate_type" */
  delete_ref_mandate_type_by_pk?: Maybe<Ref_Mandate_Type>;
  /** delete data from the table: "ref_perfios_bank_list" */
  delete_ref_perfios_bank_list?: Maybe<Ref_Perfios_Bank_List_Mutation_Response>;
  /** delete single row from the table: "ref_perfios_bank_list" */
  delete_ref_perfios_bank_list_by_pk?: Maybe<Ref_Perfios_Bank_List>;
  /** delete data from the table: "ref_report_category" */
  delete_ref_report_category?: Maybe<Ref_Report_Category_Mutation_Response>;
  /** delete single row from the table: "ref_report_category" */
  delete_ref_report_category_by_pk?: Maybe<Ref_Report_Category>;
  /** delete data from the table: "ref_role" */
  delete_ref_role?: Maybe<Ref_Role_Mutation_Response>;
  /** delete single row from the table: "ref_role" */
  delete_ref_role_by_pk?: Maybe<Ref_Role>;
  /** delete data from the table: "ref_schedule_checklist_reminder_status" */
  delete_ref_schedule_checklist_reminder_status?: Maybe<Ref_Schedule_Checklist_Reminder_Status_Mutation_Response>;
  /** delete single row from the table: "ref_schedule_checklist_reminder_status" */
  delete_ref_schedule_checklist_reminder_status_by_pk?: Maybe<Ref_Schedule_Checklist_Reminder_Status>;
  /** delete data from the table: "ref_score_factor" */
  delete_ref_score_factor?: Maybe<Ref_Score_Factor_Mutation_Response>;
  /** delete single row from the table: "ref_score_factor" */
  delete_ref_score_factor_by_pk?: Maybe<Ref_Score_Factor>;
  /** delete data from the table: "ref_score_factor_category" */
  delete_ref_score_factor_category?: Maybe<Ref_Score_Factor_Category_Mutation_Response>;
  /** delete single row from the table: "ref_score_factor_category" */
  delete_ref_score_factor_category_by_pk?: Maybe<Ref_Score_Factor_Category>;
  /** delete data from the table: "ref_score_factor_unit" */
  delete_ref_score_factor_unit?: Maybe<Ref_Score_Factor_Unit_Mutation_Response>;
  /** delete single row from the table: "ref_score_factor_unit" */
  delete_ref_score_factor_unit_by_pk?: Maybe<Ref_Score_Factor_Unit>;
  /** delete data from the table: "ref_source" */
  delete_ref_source?: Maybe<Ref_Source_Mutation_Response>;
  /** delete single row from the table: "ref_source" */
  delete_ref_source_by_pk?: Maybe<Ref_Source>;
  /** delete data from the table: "ref_states" */
  delete_ref_states?: Maybe<Ref_States_Mutation_Response>;
  /** delete single row from the table: "ref_states" */
  delete_ref_states_by_pk?: Maybe<Ref_States>;
  /** delete data from the table: "ref_subscription_status" */
  delete_ref_subscription_status?: Maybe<Ref_Subscription_Status_Mutation_Response>;
  /** delete single row from the table: "ref_subscription_status" */
  delete_ref_subscription_status_by_pk?: Maybe<Ref_Subscription_Status>;
  /** delete data from the table: "ref_system_account" */
  delete_ref_system_account?: Maybe<Ref_System_Account_Mutation_Response>;
  /** delete single row from the table: "ref_system_account" */
  delete_ref_system_account_by_pk?: Maybe<Ref_System_Account>;
  /** delete data from the table: "ref_system_account_status" */
  delete_ref_system_account_status?: Maybe<Ref_System_Account_Status_Mutation_Response>;
  /** delete single row from the table: "ref_system_account_status" */
  delete_ref_system_account_status_by_pk?: Maybe<Ref_System_Account_Status>;
  /** delete data from the table: "ref_system_account_type" */
  delete_ref_system_account_type?: Maybe<Ref_System_Account_Type_Mutation_Response>;
  /** delete single row from the table: "ref_system_account_type" */
  delete_ref_system_account_type_by_pk?: Maybe<Ref_System_Account_Type>;
  /** delete data from the table: "ref_tds_status" */
  delete_ref_tds_status?: Maybe<Ref_Tds_Status_Mutation_Response>;
  /** delete single row from the table: "ref_tds_status" */
  delete_ref_tds_status_by_pk?: Maybe<Ref_Tds_Status>;
  /** delete data from the table: "ref_tds_value_unit" */
  delete_ref_tds_value_unit?: Maybe<Ref_Tds_Value_Unit_Mutation_Response>;
  /** delete single row from the table: "ref_tds_value_unit" */
  delete_ref_tds_value_unit_by_pk?: Maybe<Ref_Tds_Value_Unit>;
  /** delete data from the table: "ref_tenant_type" */
  delete_ref_tenant_type?: Maybe<Ref_Tenant_Type_Mutation_Response>;
  /** delete single row from the table: "ref_tenant_type" */
  delete_ref_tenant_type_by_pk?: Maybe<Ref_Tenant_Type>;
  /** delete data from the table: "ref_trade_state" */
  delete_ref_trade_state?: Maybe<Ref_Trade_State_Mutation_Response>;
  /** delete single row from the table: "ref_trade_state" */
  delete_ref_trade_state_by_pk?: Maybe<Ref_Trade_State>;
  /** delete data from the table: "ref_trade_status" */
  delete_ref_trade_status?: Maybe<Ref_Trade_Status_Mutation_Response>;
  /** delete single row from the table: "ref_trade_status" */
  delete_ref_trade_status_by_pk?: Maybe<Ref_Trade_Status>;
  /** delete data from the table: "ref_trade_withdrawal_mode" */
  delete_ref_trade_withdrawal_mode?: Maybe<Ref_Trade_Withdrawal_Mode_Mutation_Response>;
  /** delete single row from the table: "ref_trade_withdrawal_mode" */
  delete_ref_trade_withdrawal_mode_by_pk?: Maybe<Ref_Trade_Withdrawal_Mode>;
  /** delete data from the table: "ref_transaction_method" */
  delete_ref_transaction_method?: Maybe<Ref_Transaction_Method_Mutation_Response>;
  /** delete single row from the table: "ref_transaction_method" */
  delete_ref_transaction_method_by_pk?: Maybe<Ref_Transaction_Method>;
  /** delete data from the table: "ref_transaction_status" */
  delete_ref_transaction_status?: Maybe<Ref_Transaction_Status_Mutation_Response>;
  /** delete single row from the table: "ref_transaction_status" */
  delete_ref_transaction_status_by_pk?: Maybe<Ref_Transaction_Status>;
  /** delete data from the table: "ref_transaction_type" */
  delete_ref_transaction_type?: Maybe<Ref_Transaction_Type_Mutation_Response>;
  /** delete single row from the table: "ref_transaction_type" */
  delete_ref_transaction_type_by_pk?: Maybe<Ref_Transaction_Type>;
  /** delete data from the table: "ref_transfer_status" */
  delete_ref_transfer_status?: Maybe<Ref_Transfer_Status_Mutation_Response>;
  /** delete single row from the table: "ref_transfer_status" */
  delete_ref_transfer_status_by_pk?: Maybe<Ref_Transfer_Status>;
  /** delete data from the table: "ref_transfer_type" */
  delete_ref_transfer_type?: Maybe<Ref_Transfer_Type_Mutation_Response>;
  /** delete single row from the table: "ref_transfer_type" */
  delete_ref_transfer_type_by_pk?: Maybe<Ref_Transfer_Type>;
  /** delete data from the table: "ref_user_status" */
  delete_ref_user_status?: Maybe<Ref_User_Status_Mutation_Response>;
  /** delete single row from the table: "ref_user_status" */
  delete_ref_user_status_by_pk?: Maybe<Ref_User_Status>;
  /** delete data from the table: "ref_user_type" */
  delete_ref_user_type?: Maybe<Ref_User_Type_Mutation_Response>;
  /** delete single row from the table: "ref_user_type" */
  delete_ref_user_type_by_pk?: Maybe<Ref_User_Type>;
  /** delete data from the table: "report_category" */
  delete_report_category?: Maybe<Report_Category_Mutation_Response>;
  /** delete single row from the table: "report_category" */
  delete_report_category_by_pk?: Maybe<Report_Category>;
  /** delete data from the table: "report_event" */
  delete_report_event?: Maybe<Report_Event_Mutation_Response>;
  /** delete single row from the table: "report_event" */
  delete_report_event_by_pk?: Maybe<Report_Event>;
  /** delete data from the table: "schedule_document_checklist_reminder" */
  delete_schedule_document_checklist_reminder?: Maybe<Schedule_Document_Checklist_Reminder_Mutation_Response>;
  /** delete single row from the table: "schedule_document_checklist_reminder" */
  delete_schedule_document_checklist_reminder_by_pk?: Maybe<Schedule_Document_Checklist_Reminder>;
  /** delete data from the table: "scheduled_emi" */
  delete_scheduled_emi?: Maybe<Scheduled_Emi_Mutation_Response>;
  /** delete single row from the table: "scheduled_emi" */
  delete_scheduled_emi_by_pk?: Maybe<Scheduled_Emi>;
  /** delete data from the table: "score_factor" */
  delete_score_factor?: Maybe<Score_Factor_Mutation_Response>;
  /** delete single row from the table: "score_factor" */
  delete_score_factor_by_pk?: Maybe<Score_Factor>;
  /** delete data from the table: "staff_user" */
  delete_staff_user?: Maybe<Staff_User_Mutation_Response>;
  /** delete single row from the table: "staff_user" */
  delete_staff_user_by_pk?: Maybe<Staff_User>;
  /** delete data from the table: "subscription" */
  delete_subscription?: Maybe<Subscription_Mutation_Response>;
  /** delete single row from the table: "subscription" */
  delete_subscription_by_pk?: Maybe<Subscription>;
  /** delete data from the table: "tds" */
  delete_tds?: Maybe<Tds_Mutation_Response>;
  /** delete single row from the table: "tds" */
  delete_tds_by_pk?: Maybe<Tds>;
  /** delete data from the table: "trade" */
  delete_trade?: Maybe<Trade_Mutation_Response>;
  /** delete single row from the table: "trade" */
  delete_trade_by_pk?: Maybe<Trade>;
  /** delete data from the table: "trade_subscription" */
  delete_trade_subscription?: Maybe<Trade_Subscription_Mutation_Response>;
  /** delete single row from the table: "trade_subscription" */
  delete_trade_subscription_by_pk?: Maybe<Trade_Subscription>;
  /** delete data from the table: "transaction" */
  delete_transaction?: Maybe<Transaction_Mutation_Response>;
  /** delete single row from the table: "transaction" */
  delete_transaction_by_pk?: Maybe<Transaction>;
  /** delete data from the table: "transfer" */
  delete_transfer?: Maybe<Transfer_Mutation_Response>;
  /** delete single row from the table: "transfer" */
  delete_transfer_by_pk?: Maybe<Transfer>;
  /** delete data from the table: "updates_log" */
  delete_updates_log?: Maybe<Updates_Log_Mutation_Response>;
  /** delete single row from the table: "updates_log" */
  delete_updates_log_by_pk?: Maybe<Updates_Log>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "verification_code" */
  delete_verification_code?: Maybe<Verification_Code_Mutation_Response>;
  /** delete single row from the table: "verification_code" */
  delete_verification_code_by_pk?: Maybe<Verification_Code>;
  /** delete data from the table: "webhook_events" */
  delete_webhook_events?: Maybe<Webhook_Events_Mutation_Response>;
  /** delete single row from the table: "webhook_events" */
  delete_webhook_events_by_pk?: Maybe<Webhook_Events>;
  downloadCiFile?: Maybe<DownloadCiFileOutput>;
  exchangeSaveToken?: Maybe<ExchangeSaveTokenOutput>;
  fetchCrimeDetails?: Maybe<FetchCrimeDetailsOutput>;
  fetchEpfoDetails?: Maybe<FetchEpfoDetailOutput>;
  fetchGSTReturnFiling?: Maybe<FetchGstReturnFilingOutput>;
  fetchHistoricalAccountingData?: Maybe<FetchHistoricalAccountingDataOutput>;
  fetchMCAProfile?: Maybe<FetchMcaProfileOutput>;
  fetchTANTransaction?: Maybe<FetchTanTransactionOutput>;
  fileRegistryUpdatePasswords?: Maybe<FileRegistryUpdatePasswordsOutput>;
  generateAPA?: Maybe<GenerateApaOutput>;
  generateDailyCustomerReport?: Maybe<GenerateDailyCustomerReportOutput>;
  generateFileRegistryUploadURL?: Maybe<GenerateFileRegistryUploadUrlOutput>;
  generateTradeEMISchedule?: Maybe<GenerateTradeEmiScheduleOutput>;
  initiateTrade?: Maybe<InitiateTradeOutput>;
  /** insert data into the table: "account" */
  insert_account?: Maybe<Account_Mutation_Response>;
  /** insert data into the table: "account_balance" */
  insert_account_balance?: Maybe<Account_Balance_Mutation_Response>;
  /** insert a single row into the table: "account_balance" */
  insert_account_balance_one?: Maybe<Account_Balance>;
  /** insert a single row into the table: "account" */
  insert_account_one?: Maybe<Account>;
  /** insert data into the table: "agreement_registry" */
  insert_agreement_registry?: Maybe<Agreement_Registry_Mutation_Response>;
  /** insert a single row into the table: "agreement_registry" */
  insert_agreement_registry_one?: Maybe<Agreement_Registry>;
  /** insert data into the table: "agreement_tenant" */
  insert_agreement_tenant?: Maybe<Agreement_Tenant_Mutation_Response>;
  /** insert a single row into the table: "agreement_tenant" */
  insert_agreement_tenant_one?: Maybe<Agreement_Tenant>;
  /** insert data into the table: "application_lifecycle_stage" */
  insert_application_lifecycle_stage?: Maybe<Application_Lifecycle_Stage_Mutation_Response>;
  /** insert a single row into the table: "application_lifecycle_stage" */
  insert_application_lifecycle_stage_one?: Maybe<Application_Lifecycle_Stage>;
  /** insert data into the table: "banking_partner_integration" */
  insert_banking_partner_integration?: Maybe<Banking_Partner_Integration_Mutation_Response>;
  /** insert a single row into the table: "banking_partner_integration" */
  insert_banking_partner_integration_one?: Maybe<Banking_Partner_Integration>;
  /** insert data into the table: "bid" */
  insert_bid?: Maybe<Bid_Mutation_Response>;
  /** insert a single row into the table: "bid" */
  insert_bid_one?: Maybe<Bid>;
  /** insert data into the table: "blacklisted_tokens" */
  insert_blacklisted_tokens?: Maybe<Blacklisted_Tokens_Mutation_Response>;
  /** insert a single row into the table: "blacklisted_tokens" */
  insert_blacklisted_tokens_one?: Maybe<Blacklisted_Tokens>;
  /** insert data into the table: "company" */
  insert_company?: Maybe<Company_Mutation_Response>;
  /** insert data into the table: "company_application_crime_details" */
  insert_company_application_crime_details?: Maybe<Company_Application_Crime_Details_Mutation_Response>;
  /** insert a single row into the table: "company_application_crime_details" */
  insert_company_application_crime_details_one?: Maybe<Company_Application_Crime_Details>;
  /** insert data into the table: "company_application_epfo" */
  insert_company_application_epfo?: Maybe<Company_Application_Epfo_Mutation_Response>;
  /** insert a single row into the table: "company_application_epfo" */
  insert_company_application_epfo_one?: Maybe<Company_Application_Epfo>;
  /** insert data into the table: "company_authorized_signatory" */
  insert_company_authorized_signatory?: Maybe<Company_Authorized_Signatory_Mutation_Response>;
  /** insert a single row into the table: "company_authorized_signatory" */
  insert_company_authorized_signatory_one?: Maybe<Company_Authorized_Signatory>;
  /** insert data into the table: "company_cin" */
  insert_company_cin?: Maybe<Company_Cin_Mutation_Response>;
  /** insert a single row into the table: "company_cin" */
  insert_company_cin_one?: Maybe<Company_Cin>;
  /** insert data into the table: "company_email_log" */
  insert_company_email_log?: Maybe<Company_Email_Log_Mutation_Response>;
  /** insert a single row into the table: "company_email_log" */
  insert_company_email_log_one?: Maybe<Company_Email_Log>;
  /** insert data into the table: "company_gst_return" */
  insert_company_gst_return?: Maybe<Company_Gst_Return_Mutation_Response>;
  /** insert a single row into the table: "company_gst_return" */
  insert_company_gst_return_one?: Maybe<Company_Gst_Return>;
  /** insert data into the table: "company_integration" */
  insert_company_integration?: Maybe<Company_Integration_Mutation_Response>;
  /** insert data into the table: "company_integration_file" */
  insert_company_integration_file?: Maybe<Company_Integration_File_Mutation_Response>;
  /** insert a single row into the table: "company_integration_file" */
  insert_company_integration_file_one?: Maybe<Company_Integration_File>;
  /** insert a single row into the table: "company_integration" */
  insert_company_integration_one?: Maybe<Company_Integration>;
  /** insert data into the table: "company_lead_status" */
  insert_company_lead_status?: Maybe<Company_Lead_Status_Mutation_Response>;
  /** insert a single row into the table: "company_lead_status" */
  insert_company_lead_status_one?: Maybe<Company_Lead_Status>;
  /** insert a single row into the table: "company" */
  insert_company_one?: Maybe<Company>;
  /** insert data into the table: "company_reports" */
  insert_company_reports?: Maybe<Company_Reports_Mutation_Response>;
  /** insert a single row into the table: "company_reports" */
  insert_company_reports_one?: Maybe<Company_Reports>;
  /** insert data into the table: "company_score" */
  insert_company_score?: Maybe<Company_Score_Mutation_Response>;
  /** insert data into the table: "company_score_factor" */
  insert_company_score_factor?: Maybe<Company_Score_Factor_Mutation_Response>;
  /** insert a single row into the table: "company_score_factor" */
  insert_company_score_factor_one?: Maybe<Company_Score_Factor>;
  /** insert a single row into the table: "company_score" */
  insert_company_score_one?: Maybe<Company_Score>;
  /** insert data into the table: "company_score_time_series" */
  insert_company_score_time_series?: Maybe<Company_Score_Time_Series_Mutation_Response>;
  /** insert a single row into the table: "company_score_time_series" */
  insert_company_score_time_series_one?: Maybe<Company_Score_Time_Series>;
  /** insert data into the table: "company_statement" */
  insert_company_statement?: Maybe<Company_Statement_Mutation_Response>;
  /** insert a single row into the table: "company_statement" */
  insert_company_statement_one?: Maybe<Company_Statement>;
  /** insert data into the table: "company_tan" */
  insert_company_tan?: Maybe<Company_Tan_Mutation_Response>;
  /** insert a single row into the table: "company_tan" */
  insert_company_tan_one?: Maybe<Company_Tan>;
  /** insert data into the table: "company_type_factor_benchmark" */
  insert_company_type_factor_benchmark?: Maybe<Company_Type_Factor_Benchmark_Mutation_Response>;
  /** insert a single row into the table: "company_type_factor_benchmark" */
  insert_company_type_factor_benchmark_one?: Maybe<Company_Type_Factor_Benchmark>;
  /** insert data into the table: "company_user" */
  insert_company_user?: Maybe<Company_User_Mutation_Response>;
  /** insert a single row into the table: "company_user" */
  insert_company_user_one?: Maybe<Company_User>;
  /** insert data into the table: "deal" */
  insert_deal?: Maybe<Deal_Mutation_Response>;
  /** insert a single row into the table: "deal" */
  insert_deal_one?: Maybe<Deal>;
  /** insert data into the table: "document_checklist" */
  insert_document_checklist?: Maybe<Document_Checklist_Mutation_Response>;
  /** insert data into the table: "document_checklist_category" */
  insert_document_checklist_category?: Maybe<Document_Checklist_Category_Mutation_Response>;
  /** insert a single row into the table: "document_checklist_category" */
  insert_document_checklist_category_one?: Maybe<Document_Checklist_Category>;
  /** insert a single row into the table: "document_checklist" */
  insert_document_checklist_one?: Maybe<Document_Checklist>;
  /** insert data into the table: "email_events" */
  insert_email_events?: Maybe<Email_Events_Mutation_Response>;
  /** insert a single row into the table: "email_events" */
  insert_email_events_one?: Maybe<Email_Events>;
  /** insert data into the table: "emi_tds" */
  insert_emi_tds?: Maybe<Emi_Tds_Mutation_Response>;
  /** insert a single row into the table: "emi_tds" */
  insert_emi_tds_one?: Maybe<Emi_Tds>;
  /** insert data into the table: "factor_benchmark_range" */
  insert_factor_benchmark_range?: Maybe<Factor_Benchmark_Range_Mutation_Response>;
  /** insert a single row into the table: "factor_benchmark_range" */
  insert_factor_benchmark_range_one?: Maybe<Factor_Benchmark_Range>;
  /** insert data into the table: "file_registry" */
  insert_file_registry?: Maybe<File_Registry_Mutation_Response>;
  /** insert a single row into the table: "file_registry" */
  insert_file_registry_one?: Maybe<File_Registry>;
  /** insert data into the table: "integration_auth" */
  insert_integration_auth?: Maybe<Integration_Auth_Mutation_Response>;
  /** insert a single row into the table: "integration_auth" */
  insert_integration_auth_one?: Maybe<Integration_Auth>;
  /** insert data into the table: "internal_statement" */
  insert_internal_statement?: Maybe<Internal_Statement_Mutation_Response>;
  /** insert a single row into the table: "internal_statement" */
  insert_internal_statement_one?: Maybe<Internal_Statement>;
  /** insert data into the table: "investment" */
  insert_investment?: Maybe<Investment_Mutation_Response>;
  /** insert a single row into the table: "investment" */
  insert_investment_one?: Maybe<Investment>;
  /** insert data into the table: "investor" */
  insert_investor?: Maybe<Investor_Mutation_Response>;
  /** insert a single row into the table: "investor" */
  insert_investor_one?: Maybe<Investor>;
  /** insert data into the table: "investor_preference" */
  insert_investor_preference?: Maybe<Investor_Preference_Mutation_Response>;
  /** insert a single row into the table: "investor_preference" */
  insert_investor_preference_one?: Maybe<Investor_Preference>;
  /** insert data into the table: "investor_statement" */
  insert_investor_statement?: Maybe<Investor_Statement_Mutation_Response>;
  /** insert a single row into the table: "investor_statement" */
  insert_investor_statement_one?: Maybe<Investor_Statement>;
  /** insert data into the table: "investor_transaction" */
  insert_investor_transaction?: Maybe<Investor_Transaction_Mutation_Response>;
  /** insert a single row into the table: "investor_transaction" */
  insert_investor_transaction_one?: Maybe<Investor_Transaction>;
  /** insert data into the table: "investor_user" */
  insert_investor_user?: Maybe<Investor_User_Mutation_Response>;
  /** insert a single row into the table: "investor_user" */
  insert_investor_user_one?: Maybe<Investor_User>;
  /** insert data into the table: "lifecycle_question_crm_mapper" */
  insert_lifecycle_question_crm_mapper?: Maybe<Lifecycle_Question_Crm_Mapper_Mutation_Response>;
  /** insert a single row into the table: "lifecycle_question_crm_mapper" */
  insert_lifecycle_question_crm_mapper_one?: Maybe<Lifecycle_Question_Crm_Mapper>;
  /** insert data into the table: "lifecycle_question_result" */
  insert_lifecycle_question_result?: Maybe<Lifecycle_Question_Result_Mutation_Response>;
  /** insert a single row into the table: "lifecycle_question_result" */
  insert_lifecycle_question_result_one?: Maybe<Lifecycle_Question_Result>;
  /** insert data into the table: "mandate" */
  insert_mandate?: Maybe<Mandate_Mutation_Response>;
  /** insert a single row into the table: "mandate" */
  insert_mandate_one?: Maybe<Mandate>;
  /** insert data into the table: "popular_banks" */
  insert_popular_banks?: Maybe<Popular_Banks_Mutation_Response>;
  /** insert a single row into the table: "popular_banks" */
  insert_popular_banks_one?: Maybe<Popular_Banks>;
  /** insert data into the table: "ref_account_status" */
  insert_ref_account_status?: Maybe<Ref_Account_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_account_status" */
  insert_ref_account_status_one?: Maybe<Ref_Account_Status>;
  /** insert data into the table: "ref_account_type" */
  insert_ref_account_type?: Maybe<Ref_Account_Type_Mutation_Response>;
  /** insert a single row into the table: "ref_account_type" */
  insert_ref_account_type_one?: Maybe<Ref_Account_Type>;
  /** insert data into the table: "ref_agreement_category" */
  insert_ref_agreement_category?: Maybe<Ref_Agreement_Category_Mutation_Response>;
  /** insert a single row into the table: "ref_agreement_category" */
  insert_ref_agreement_category_one?: Maybe<Ref_Agreement_Category>;
  /** insert data into the table: "ref_agreement_status" */
  insert_ref_agreement_status?: Maybe<Ref_Agreement_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_agreement_status" */
  insert_ref_agreement_status_one?: Maybe<Ref_Agreement_Status>;
  /** insert data into the table: "ref_application_allowed_district" */
  insert_ref_application_allowed_district?: Maybe<Ref_Application_Allowed_District_Mutation_Response>;
  /** insert a single row into the table: "ref_application_allowed_district" */
  insert_ref_application_allowed_district_one?: Maybe<Ref_Application_Allowed_District>;
  /** insert data into the table: "ref_application_lifecycle_stage" */
  insert_ref_application_lifecycle_stage?: Maybe<Ref_Application_Lifecycle_Stage_Mutation_Response>;
  /** insert a single row into the table: "ref_application_lifecycle_stage" */
  insert_ref_application_lifecycle_stage_one?: Maybe<Ref_Application_Lifecycle_Stage>;
  /** insert data into the table: "ref_bank" */
  insert_ref_bank?: Maybe<Ref_Bank_Mutation_Response>;
  /** insert data into the table: "ref_bank_list" */
  insert_ref_bank_list?: Maybe<Ref_Bank_List_Mutation_Response>;
  /** insert a single row into the table: "ref_bank_list" */
  insert_ref_bank_list_one?: Maybe<Ref_Bank_List>;
  /** insert a single row into the table: "ref_bank" */
  insert_ref_bank_one?: Maybe<Ref_Bank>;
  /** insert data into the table: "ref_bid" */
  insert_ref_bid?: Maybe<Ref_Bid_Mutation_Response>;
  /** insert a single row into the table: "ref_bid" */
  insert_ref_bid_one?: Maybe<Ref_Bid>;
  /** insert data into the table: "ref_billing_period" */
  insert_ref_billing_period?: Maybe<Ref_Billing_Period_Mutation_Response>;
  /** insert a single row into the table: "ref_billing_period" */
  insert_ref_billing_period_one?: Maybe<Ref_Billing_Period>;
  /** insert data into the table: "ref_category" */
  insert_ref_category?: Maybe<Ref_Category_Mutation_Response>;
  /** insert a single row into the table: "ref_category" */
  insert_ref_category_one?: Maybe<Ref_Category>;
  /** insert data into the table: "ref_collection_mode" */
  insert_ref_collection_mode?: Maybe<Ref_Collection_Mode_Mutation_Response>;
  /** insert a single row into the table: "ref_collection_mode" */
  insert_ref_collection_mode_one?: Maybe<Ref_Collection_Mode>;
  /** insert data into the table: "ref_company_lead_status" */
  insert_ref_company_lead_status?: Maybe<Ref_Company_Lead_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_company_lead_status" */
  insert_ref_company_lead_status_one?: Maybe<Ref_Company_Lead_Status>;
  /** insert data into the table: "ref_company_likeliness" */
  insert_ref_company_likeliness?: Maybe<Ref_Company_Likeliness_Mutation_Response>;
  /** insert a single row into the table: "ref_company_likeliness" */
  insert_ref_company_likeliness_one?: Maybe<Ref_Company_Likeliness>;
  /** insert data into the table: "ref_company_status" */
  insert_ref_company_status?: Maybe<Ref_Company_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_company_status" */
  insert_ref_company_status_one?: Maybe<Ref_Company_Status>;
  /** insert data into the table: "ref_company_type" */
  insert_ref_company_type?: Maybe<Ref_Company_Type_Mutation_Response>;
  /** insert a single row into the table: "ref_company_type" */
  insert_ref_company_type_one?: Maybe<Ref_Company_Type>;
  /** insert data into the table: "ref_country" */
  insert_ref_country?: Maybe<Ref_Country_Mutation_Response>;
  /** insert a single row into the table: "ref_country" */
  insert_ref_country_one?: Maybe<Ref_Country>;
  /** insert data into the table: "ref_currency" */
  insert_ref_currency?: Maybe<Ref_Currency_Mutation_Response>;
  /** insert a single row into the table: "ref_currency" */
  insert_ref_currency_one?: Maybe<Ref_Currency>;
  /** insert data into the table: "ref_debit_mode" */
  insert_ref_debit_mode?: Maybe<Ref_Debit_Mode_Mutation_Response>;
  /** insert a single row into the table: "ref_debit_mode" */
  insert_ref_debit_mode_one?: Maybe<Ref_Debit_Mode>;
  /** insert data into the table: "ref_document_checklist" */
  insert_ref_document_checklist?: Maybe<Ref_Document_Checklist_Mutation_Response>;
  /** insert data into the table: "ref_document_checklist_category" */
  insert_ref_document_checklist_category?: Maybe<Ref_Document_Checklist_Category_Mutation_Response>;
  /** insert a single row into the table: "ref_document_checklist_category" */
  insert_ref_document_checklist_category_one?: Maybe<Ref_Document_Checklist_Category>;
  /** insert data into the table: "ref_document_checklist_integration" */
  insert_ref_document_checklist_integration?: Maybe<Ref_Document_Checklist_Integration_Mutation_Response>;
  /** insert a single row into the table: "ref_document_checklist_integration" */
  insert_ref_document_checklist_integration_one?: Maybe<Ref_Document_Checklist_Integration>;
  /** insert a single row into the table: "ref_document_checklist" */
  insert_ref_document_checklist_one?: Maybe<Ref_Document_Checklist>;
  /** insert data into the table: "ref_document_checklist_status" */
  insert_ref_document_checklist_status?: Maybe<Ref_Document_Checklist_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_document_checklist_status" */
  insert_ref_document_checklist_status_one?: Maybe<Ref_Document_Checklist_Status>;
  /** insert data into the table: "ref_email_events_type" */
  insert_ref_email_events_type?: Maybe<Ref_Email_Events_Type_Mutation_Response>;
  /** insert a single row into the table: "ref_email_events_type" */
  insert_ref_email_events_type_one?: Maybe<Ref_Email_Events_Type>;
  /** insert data into the table: "ref_file_category" */
  insert_ref_file_category?: Maybe<Ref_File_Category_Mutation_Response>;
  /** insert data into the table: "ref_file_category_mimetype" */
  insert_ref_file_category_mimetype?: Maybe<Ref_File_Category_Mimetype_Mutation_Response>;
  /** insert a single row into the table: "ref_file_category_mimetype" */
  insert_ref_file_category_mimetype_one?: Maybe<Ref_File_Category_Mimetype>;
  /** insert a single row into the table: "ref_file_category" */
  insert_ref_file_category_one?: Maybe<Ref_File_Category>;
  /** insert data into the table: "ref_industry" */
  insert_ref_industry?: Maybe<Ref_Industry_Mutation_Response>;
  /** insert a single row into the table: "ref_industry" */
  insert_ref_industry_one?: Maybe<Ref_Industry>;
  /** insert data into the table: "ref_integration_file_status" */
  insert_ref_integration_file_status?: Maybe<Ref_Integration_File_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_integration_file_status" */
  insert_ref_integration_file_status_one?: Maybe<Ref_Integration_File_Status>;
  /** insert data into the table: "ref_integration_status" */
  insert_ref_integration_status?: Maybe<Ref_Integration_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_integration_status" */
  insert_ref_integration_status_one?: Maybe<Ref_Integration_Status>;
  /** insert data into the table: "ref_investor_status" */
  insert_ref_investor_status?: Maybe<Ref_Investor_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_investor_status" */
  insert_ref_investor_status_one?: Maybe<Ref_Investor_Status>;
  /** insert data into the table: "ref_investor_type" */
  insert_ref_investor_type?: Maybe<Ref_Investor_Type_Mutation_Response>;
  /** insert a single row into the table: "ref_investor_type" */
  insert_ref_investor_type_one?: Maybe<Ref_Investor_Type>;
  /** insert data into the table: "ref_lifecycle_question" */
  insert_ref_lifecycle_question?: Maybe<Ref_Lifecycle_Question_Mutation_Response>;
  /** insert data into the table: "ref_lifecycle_question_category" */
  insert_ref_lifecycle_question_category?: Maybe<Ref_Lifecycle_Question_Category_Mutation_Response>;
  /** insert a single row into the table: "ref_lifecycle_question_category" */
  insert_ref_lifecycle_question_category_one?: Maybe<Ref_Lifecycle_Question_Category>;
  /** insert a single row into the table: "ref_lifecycle_question" */
  insert_ref_lifecycle_question_one?: Maybe<Ref_Lifecycle_Question>;
  /** insert data into the table: "ref_mandate_frequency" */
  insert_ref_mandate_frequency?: Maybe<Ref_Mandate_Frequency_Mutation_Response>;
  /** insert a single row into the table: "ref_mandate_frequency" */
  insert_ref_mandate_frequency_one?: Maybe<Ref_Mandate_Frequency>;
  /** insert data into the table: "ref_mandate_status" */
  insert_ref_mandate_status?: Maybe<Ref_Mandate_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_mandate_status" */
  insert_ref_mandate_status_one?: Maybe<Ref_Mandate_Status>;
  /** insert data into the table: "ref_mandate_type" */
  insert_ref_mandate_type?: Maybe<Ref_Mandate_Type_Mutation_Response>;
  /** insert a single row into the table: "ref_mandate_type" */
  insert_ref_mandate_type_one?: Maybe<Ref_Mandate_Type>;
  /** insert data into the table: "ref_perfios_bank_list" */
  insert_ref_perfios_bank_list?: Maybe<Ref_Perfios_Bank_List_Mutation_Response>;
  /** insert a single row into the table: "ref_perfios_bank_list" */
  insert_ref_perfios_bank_list_one?: Maybe<Ref_Perfios_Bank_List>;
  /** insert data into the table: "ref_report_category" */
  insert_ref_report_category?: Maybe<Ref_Report_Category_Mutation_Response>;
  /** insert a single row into the table: "ref_report_category" */
  insert_ref_report_category_one?: Maybe<Ref_Report_Category>;
  /** insert data into the table: "ref_role" */
  insert_ref_role?: Maybe<Ref_Role_Mutation_Response>;
  /** insert a single row into the table: "ref_role" */
  insert_ref_role_one?: Maybe<Ref_Role>;
  /** insert data into the table: "ref_schedule_checklist_reminder_status" */
  insert_ref_schedule_checklist_reminder_status?: Maybe<Ref_Schedule_Checklist_Reminder_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_schedule_checklist_reminder_status" */
  insert_ref_schedule_checklist_reminder_status_one?: Maybe<Ref_Schedule_Checklist_Reminder_Status>;
  /** insert data into the table: "ref_score_factor" */
  insert_ref_score_factor?: Maybe<Ref_Score_Factor_Mutation_Response>;
  /** insert data into the table: "ref_score_factor_category" */
  insert_ref_score_factor_category?: Maybe<Ref_Score_Factor_Category_Mutation_Response>;
  /** insert a single row into the table: "ref_score_factor_category" */
  insert_ref_score_factor_category_one?: Maybe<Ref_Score_Factor_Category>;
  /** insert a single row into the table: "ref_score_factor" */
  insert_ref_score_factor_one?: Maybe<Ref_Score_Factor>;
  /** insert data into the table: "ref_score_factor_unit" */
  insert_ref_score_factor_unit?: Maybe<Ref_Score_Factor_Unit_Mutation_Response>;
  /** insert a single row into the table: "ref_score_factor_unit" */
  insert_ref_score_factor_unit_one?: Maybe<Ref_Score_Factor_Unit>;
  /** insert data into the table: "ref_source" */
  insert_ref_source?: Maybe<Ref_Source_Mutation_Response>;
  /** insert a single row into the table: "ref_source" */
  insert_ref_source_one?: Maybe<Ref_Source>;
  /** insert data into the table: "ref_states" */
  insert_ref_states?: Maybe<Ref_States_Mutation_Response>;
  /** insert a single row into the table: "ref_states" */
  insert_ref_states_one?: Maybe<Ref_States>;
  /** insert data into the table: "ref_subscription_status" */
  insert_ref_subscription_status?: Maybe<Ref_Subscription_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_subscription_status" */
  insert_ref_subscription_status_one?: Maybe<Ref_Subscription_Status>;
  /** insert data into the table: "ref_system_account" */
  insert_ref_system_account?: Maybe<Ref_System_Account_Mutation_Response>;
  /** insert a single row into the table: "ref_system_account" */
  insert_ref_system_account_one?: Maybe<Ref_System_Account>;
  /** insert data into the table: "ref_system_account_status" */
  insert_ref_system_account_status?: Maybe<Ref_System_Account_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_system_account_status" */
  insert_ref_system_account_status_one?: Maybe<Ref_System_Account_Status>;
  /** insert data into the table: "ref_system_account_type" */
  insert_ref_system_account_type?: Maybe<Ref_System_Account_Type_Mutation_Response>;
  /** insert a single row into the table: "ref_system_account_type" */
  insert_ref_system_account_type_one?: Maybe<Ref_System_Account_Type>;
  /** insert data into the table: "ref_tds_status" */
  insert_ref_tds_status?: Maybe<Ref_Tds_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_tds_status" */
  insert_ref_tds_status_one?: Maybe<Ref_Tds_Status>;
  /** insert data into the table: "ref_tds_value_unit" */
  insert_ref_tds_value_unit?: Maybe<Ref_Tds_Value_Unit_Mutation_Response>;
  /** insert a single row into the table: "ref_tds_value_unit" */
  insert_ref_tds_value_unit_one?: Maybe<Ref_Tds_Value_Unit>;
  /** insert data into the table: "ref_tenant_type" */
  insert_ref_tenant_type?: Maybe<Ref_Tenant_Type_Mutation_Response>;
  /** insert a single row into the table: "ref_tenant_type" */
  insert_ref_tenant_type_one?: Maybe<Ref_Tenant_Type>;
  /** insert data into the table: "ref_trade_state" */
  insert_ref_trade_state?: Maybe<Ref_Trade_State_Mutation_Response>;
  /** insert a single row into the table: "ref_trade_state" */
  insert_ref_trade_state_one?: Maybe<Ref_Trade_State>;
  /** insert data into the table: "ref_trade_status" */
  insert_ref_trade_status?: Maybe<Ref_Trade_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_trade_status" */
  insert_ref_trade_status_one?: Maybe<Ref_Trade_Status>;
  /** insert data into the table: "ref_trade_withdrawal_mode" */
  insert_ref_trade_withdrawal_mode?: Maybe<Ref_Trade_Withdrawal_Mode_Mutation_Response>;
  /** insert a single row into the table: "ref_trade_withdrawal_mode" */
  insert_ref_trade_withdrawal_mode_one?: Maybe<Ref_Trade_Withdrawal_Mode>;
  /** insert data into the table: "ref_transaction_method" */
  insert_ref_transaction_method?: Maybe<Ref_Transaction_Method_Mutation_Response>;
  /** insert a single row into the table: "ref_transaction_method" */
  insert_ref_transaction_method_one?: Maybe<Ref_Transaction_Method>;
  /** insert data into the table: "ref_transaction_status" */
  insert_ref_transaction_status?: Maybe<Ref_Transaction_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_transaction_status" */
  insert_ref_transaction_status_one?: Maybe<Ref_Transaction_Status>;
  /** insert data into the table: "ref_transaction_type" */
  insert_ref_transaction_type?: Maybe<Ref_Transaction_Type_Mutation_Response>;
  /** insert a single row into the table: "ref_transaction_type" */
  insert_ref_transaction_type_one?: Maybe<Ref_Transaction_Type>;
  /** insert data into the table: "ref_transfer_status" */
  insert_ref_transfer_status?: Maybe<Ref_Transfer_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_transfer_status" */
  insert_ref_transfer_status_one?: Maybe<Ref_Transfer_Status>;
  /** insert data into the table: "ref_transfer_type" */
  insert_ref_transfer_type?: Maybe<Ref_Transfer_Type_Mutation_Response>;
  /** insert a single row into the table: "ref_transfer_type" */
  insert_ref_transfer_type_one?: Maybe<Ref_Transfer_Type>;
  /** insert data into the table: "ref_user_status" */
  insert_ref_user_status?: Maybe<Ref_User_Status_Mutation_Response>;
  /** insert a single row into the table: "ref_user_status" */
  insert_ref_user_status_one?: Maybe<Ref_User_Status>;
  /** insert data into the table: "ref_user_type" */
  insert_ref_user_type?: Maybe<Ref_User_Type_Mutation_Response>;
  /** insert a single row into the table: "ref_user_type" */
  insert_ref_user_type_one?: Maybe<Ref_User_Type>;
  /** insert data into the table: "report_category" */
  insert_report_category?: Maybe<Report_Category_Mutation_Response>;
  /** insert a single row into the table: "report_category" */
  insert_report_category_one?: Maybe<Report_Category>;
  /** insert data into the table: "report_event" */
  insert_report_event?: Maybe<Report_Event_Mutation_Response>;
  /** insert a single row into the table: "report_event" */
  insert_report_event_one?: Maybe<Report_Event>;
  /** insert data into the table: "schedule_document_checklist_reminder" */
  insert_schedule_document_checklist_reminder?: Maybe<Schedule_Document_Checklist_Reminder_Mutation_Response>;
  /** insert a single row into the table: "schedule_document_checklist_reminder" */
  insert_schedule_document_checklist_reminder_one?: Maybe<Schedule_Document_Checklist_Reminder>;
  /** insert data into the table: "scheduled_emi" */
  insert_scheduled_emi?: Maybe<Scheduled_Emi_Mutation_Response>;
  /** insert a single row into the table: "scheduled_emi" */
  insert_scheduled_emi_one?: Maybe<Scheduled_Emi>;
  /** insert data into the table: "score_factor" */
  insert_score_factor?: Maybe<Score_Factor_Mutation_Response>;
  /** insert a single row into the table: "score_factor" */
  insert_score_factor_one?: Maybe<Score_Factor>;
  /** insert data into the table: "staff_user" */
  insert_staff_user?: Maybe<Staff_User_Mutation_Response>;
  /** insert a single row into the table: "staff_user" */
  insert_staff_user_one?: Maybe<Staff_User>;
  /** insert data into the table: "subscription" */
  insert_subscription?: Maybe<Subscription_Mutation_Response>;
  /** insert a single row into the table: "subscription" */
  insert_subscription_one?: Maybe<Subscription>;
  /** insert data into the table: "tds" */
  insert_tds?: Maybe<Tds_Mutation_Response>;
  /** insert a single row into the table: "tds" */
  insert_tds_one?: Maybe<Tds>;
  /** insert data into the table: "trade" */
  insert_trade?: Maybe<Trade_Mutation_Response>;
  /** insert a single row into the table: "trade" */
  insert_trade_one?: Maybe<Trade>;
  /** insert data into the table: "trade_subscription" */
  insert_trade_subscription?: Maybe<Trade_Subscription_Mutation_Response>;
  /** insert a single row into the table: "trade_subscription" */
  insert_trade_subscription_one?: Maybe<Trade_Subscription>;
  /** insert data into the table: "transaction" */
  insert_transaction?: Maybe<Transaction_Mutation_Response>;
  /** insert a single row into the table: "transaction" */
  insert_transaction_one?: Maybe<Transaction>;
  /** insert data into the table: "transfer" */
  insert_transfer?: Maybe<Transfer_Mutation_Response>;
  /** insert a single row into the table: "transfer" */
  insert_transfer_one?: Maybe<Transfer>;
  /** insert data into the table: "updates_log" */
  insert_updates_log?: Maybe<Updates_Log_Mutation_Response>;
  /** insert a single row into the table: "updates_log" */
  insert_updates_log_one?: Maybe<Updates_Log>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "verification_code" */
  insert_verification_code?: Maybe<Verification_Code_Mutation_Response>;
  /** insert a single row into the table: "verification_code" */
  insert_verification_code_one?: Maybe<Verification_Code>;
  /** insert data into the table: "webhook_events" */
  insert_webhook_events?: Maybe<Webhook_Events_Mutation_Response>;
  /** insert a single row into the table: "webhook_events" */
  insert_webhook_events_one?: Maybe<Webhook_Events>;
  integrationsApprove?: Maybe<IntegrationsApproveOutput>;
  integrationsUpload?: Maybe<IntegrationsUploadOutput>;
  netBankingPull?: Maybe<NetBankingPullOutput>;
  notifyCustomersUpcomingEMI?: Maybe<NotifyCustomersUpcomingEmiOutput>;
  notifyIncompleteDocumentChecklist?: Maybe<NotifyIncompleteDocumentChecklistOutput>;
  notifyNewDeals?: Maybe<NotifyNewDealsOutput>;
  notifyOpsUpcomingEMIs?: Maybe<NotifyOpsUpcomingEmIsOutput>;
  opsAccountSetupNotify?: Maybe<OpsAccountSetupNotifyOutput>;
  placeBid?: Maybe<PlaceBidOutput>;
  presentEscrowEmiManual?: Maybe<PresentMandateManualOutput>;
  presentMandateDigio?: Maybe<PresentMandateDigioOutput>;
  presentMandateManual?: Maybe<PresentMandateManualOutput>;
  previewAPA?: Maybe<PreviewApaOutput>;
  reQueueCompanyIntegration?: Maybe<ReQueueCompanyIntegrationOutput>;
  refreshTokens?: Maybe<RefreshTokensOutput>;
  registerNachMandate?: Maybe<RegisterNachMandateOutput>;
  remindInvestors?: Maybe<RemindInvestorsOutput>;
  runScoringEngine?: Maybe<RunScoringEngineOutput>;
  submitSignedNachMandate?: Maybe<SubmitSignedNachMandateOutput>;
  subscriptionCSVUpload?: Maybe<SubscriptionCsvUploadOutput>;
  subscriptionSync?: Maybe<SubscriptionSyncOutput>;
  updateCompanyStatus?: Maybe<UpdateCompanyStatusOutput>;
  updateNetbankingLinkage?: Maybe<UpdateNetbankingLinkageOutput>;
  updateScheduledEmi?: Maybe<UpdateScheduledEmiOutput>;
  updateTradeAmounts?: Maybe<UpdateTradeAmountsOutput>;
  /** update data of the table: "account" */
  update_account?: Maybe<Account_Mutation_Response>;
  /** update data of the table: "account_balance" */
  update_account_balance?: Maybe<Account_Balance_Mutation_Response>;
  /** update single row of the table: "account_balance" */
  update_account_balance_by_pk?: Maybe<Account_Balance>;
  /** update multiples rows of table: "account_balance" */
  update_account_balance_many?: Maybe<Array<Maybe<Account_Balance_Mutation_Response>>>;
  /** update single row of the table: "account" */
  update_account_by_pk?: Maybe<Account>;
  /** update multiples rows of table: "account" */
  update_account_many?: Maybe<Array<Maybe<Account_Mutation_Response>>>;
  /** update data of the table: "agreement_registry" */
  update_agreement_registry?: Maybe<Agreement_Registry_Mutation_Response>;
  /** update single row of the table: "agreement_registry" */
  update_agreement_registry_by_pk?: Maybe<Agreement_Registry>;
  /** update multiples rows of table: "agreement_registry" */
  update_agreement_registry_many?: Maybe<Array<Maybe<Agreement_Registry_Mutation_Response>>>;
  /** update data of the table: "agreement_tenant" */
  update_agreement_tenant?: Maybe<Agreement_Tenant_Mutation_Response>;
  /** update single row of the table: "agreement_tenant" */
  update_agreement_tenant_by_pk?: Maybe<Agreement_Tenant>;
  /** update multiples rows of table: "agreement_tenant" */
  update_agreement_tenant_many?: Maybe<Array<Maybe<Agreement_Tenant_Mutation_Response>>>;
  /** update data of the table: "application_lifecycle_stage" */
  update_application_lifecycle_stage?: Maybe<Application_Lifecycle_Stage_Mutation_Response>;
  /** update single row of the table: "application_lifecycle_stage" */
  update_application_lifecycle_stage_by_pk?: Maybe<Application_Lifecycle_Stage>;
  /** update multiples rows of table: "application_lifecycle_stage" */
  update_application_lifecycle_stage_many?: Maybe<Array<Maybe<Application_Lifecycle_Stage_Mutation_Response>>>;
  /** update data of the table: "banking_partner_integration" */
  update_banking_partner_integration?: Maybe<Banking_Partner_Integration_Mutation_Response>;
  /** update multiples rows of table: "banking_partner_integration" */
  update_banking_partner_integration_many?: Maybe<Array<Maybe<Banking_Partner_Integration_Mutation_Response>>>;
  /** update data of the table: "bid" */
  update_bid?: Maybe<Bid_Mutation_Response>;
  /** update single row of the table: "bid" */
  update_bid_by_pk?: Maybe<Bid>;
  /** update multiples rows of table: "bid" */
  update_bid_many?: Maybe<Array<Maybe<Bid_Mutation_Response>>>;
  /** update data of the table: "blacklisted_tokens" */
  update_blacklisted_tokens?: Maybe<Blacklisted_Tokens_Mutation_Response>;
  /** update single row of the table: "blacklisted_tokens" */
  update_blacklisted_tokens_by_pk?: Maybe<Blacklisted_Tokens>;
  /** update multiples rows of table: "blacklisted_tokens" */
  update_blacklisted_tokens_many?: Maybe<Array<Maybe<Blacklisted_Tokens_Mutation_Response>>>;
  /** update data of the table: "company" */
  update_company?: Maybe<Company_Mutation_Response>;
  /** update data of the table: "company_application_crime_details" */
  update_company_application_crime_details?: Maybe<Company_Application_Crime_Details_Mutation_Response>;
  /** update single row of the table: "company_application_crime_details" */
  update_company_application_crime_details_by_pk?: Maybe<Company_Application_Crime_Details>;
  /** update multiples rows of table: "company_application_crime_details" */
  update_company_application_crime_details_many?: Maybe<Array<Maybe<Company_Application_Crime_Details_Mutation_Response>>>;
  /** update data of the table: "company_application_epfo" */
  update_company_application_epfo?: Maybe<Company_Application_Epfo_Mutation_Response>;
  /** update single row of the table: "company_application_epfo" */
  update_company_application_epfo_by_pk?: Maybe<Company_Application_Epfo>;
  /** update multiples rows of table: "company_application_epfo" */
  update_company_application_epfo_many?: Maybe<Array<Maybe<Company_Application_Epfo_Mutation_Response>>>;
  /** update data of the table: "company_authorized_signatory" */
  update_company_authorized_signatory?: Maybe<Company_Authorized_Signatory_Mutation_Response>;
  /** update single row of the table: "company_authorized_signatory" */
  update_company_authorized_signatory_by_pk?: Maybe<Company_Authorized_Signatory>;
  /** update multiples rows of table: "company_authorized_signatory" */
  update_company_authorized_signatory_many?: Maybe<Array<Maybe<Company_Authorized_Signatory_Mutation_Response>>>;
  /** update single row of the table: "company" */
  update_company_by_pk?: Maybe<Company>;
  /** update data of the table: "company_cin" */
  update_company_cin?: Maybe<Company_Cin_Mutation_Response>;
  /** update single row of the table: "company_cin" */
  update_company_cin_by_pk?: Maybe<Company_Cin>;
  /** update multiples rows of table: "company_cin" */
  update_company_cin_many?: Maybe<Array<Maybe<Company_Cin_Mutation_Response>>>;
  /** update data of the table: "company_email_log" */
  update_company_email_log?: Maybe<Company_Email_Log_Mutation_Response>;
  /** update single row of the table: "company_email_log" */
  update_company_email_log_by_pk?: Maybe<Company_Email_Log>;
  /** update multiples rows of table: "company_email_log" */
  update_company_email_log_many?: Maybe<Array<Maybe<Company_Email_Log_Mutation_Response>>>;
  /** update data of the table: "company_gst_return" */
  update_company_gst_return?: Maybe<Company_Gst_Return_Mutation_Response>;
  /** update single row of the table: "company_gst_return" */
  update_company_gst_return_by_pk?: Maybe<Company_Gst_Return>;
  /** update multiples rows of table: "company_gst_return" */
  update_company_gst_return_many?: Maybe<Array<Maybe<Company_Gst_Return_Mutation_Response>>>;
  /** update data of the table: "company_integration" */
  update_company_integration?: Maybe<Company_Integration_Mutation_Response>;
  /** update single row of the table: "company_integration" */
  update_company_integration_by_pk?: Maybe<Company_Integration>;
  /** update data of the table: "company_integration_file" */
  update_company_integration_file?: Maybe<Company_Integration_File_Mutation_Response>;
  /** update single row of the table: "company_integration_file" */
  update_company_integration_file_by_pk?: Maybe<Company_Integration_File>;
  /** update multiples rows of table: "company_integration_file" */
  update_company_integration_file_many?: Maybe<Array<Maybe<Company_Integration_File_Mutation_Response>>>;
  /** update multiples rows of table: "company_integration" */
  update_company_integration_many?: Maybe<Array<Maybe<Company_Integration_Mutation_Response>>>;
  /** update data of the table: "company_lead_status" */
  update_company_lead_status?: Maybe<Company_Lead_Status_Mutation_Response>;
  /** update single row of the table: "company_lead_status" */
  update_company_lead_status_by_pk?: Maybe<Company_Lead_Status>;
  /** update multiples rows of table: "company_lead_status" */
  update_company_lead_status_many?: Maybe<Array<Maybe<Company_Lead_Status_Mutation_Response>>>;
  /** update multiples rows of table: "company" */
  update_company_many?: Maybe<Array<Maybe<Company_Mutation_Response>>>;
  /** update data of the table: "company_reports" */
  update_company_reports?: Maybe<Company_Reports_Mutation_Response>;
  /** update single row of the table: "company_reports" */
  update_company_reports_by_pk?: Maybe<Company_Reports>;
  /** update multiples rows of table: "company_reports" */
  update_company_reports_many?: Maybe<Array<Maybe<Company_Reports_Mutation_Response>>>;
  /** update data of the table: "company_score" */
  update_company_score?: Maybe<Company_Score_Mutation_Response>;
  /** update single row of the table: "company_score" */
  update_company_score_by_pk?: Maybe<Company_Score>;
  /** update data of the table: "company_score_factor" */
  update_company_score_factor?: Maybe<Company_Score_Factor_Mutation_Response>;
  /** update single row of the table: "company_score_factor" */
  update_company_score_factor_by_pk?: Maybe<Company_Score_Factor>;
  /** update multiples rows of table: "company_score_factor" */
  update_company_score_factor_many?: Maybe<Array<Maybe<Company_Score_Factor_Mutation_Response>>>;
  /** update multiples rows of table: "company_score" */
  update_company_score_many?: Maybe<Array<Maybe<Company_Score_Mutation_Response>>>;
  /** update data of the table: "company_score_time_series" */
  update_company_score_time_series?: Maybe<Company_Score_Time_Series_Mutation_Response>;
  /** update single row of the table: "company_score_time_series" */
  update_company_score_time_series_by_pk?: Maybe<Company_Score_Time_Series>;
  /** update multiples rows of table: "company_score_time_series" */
  update_company_score_time_series_many?: Maybe<Array<Maybe<Company_Score_Time_Series_Mutation_Response>>>;
  /** update data of the table: "company_statement" */
  update_company_statement?: Maybe<Company_Statement_Mutation_Response>;
  /** update single row of the table: "company_statement" */
  update_company_statement_by_pk?: Maybe<Company_Statement>;
  /** update multiples rows of table: "company_statement" */
  update_company_statement_many?: Maybe<Array<Maybe<Company_Statement_Mutation_Response>>>;
  /** update data of the table: "company_tan" */
  update_company_tan?: Maybe<Company_Tan_Mutation_Response>;
  /** update single row of the table: "company_tan" */
  update_company_tan_by_pk?: Maybe<Company_Tan>;
  /** update multiples rows of table: "company_tan" */
  update_company_tan_many?: Maybe<Array<Maybe<Company_Tan_Mutation_Response>>>;
  /** update data of the table: "company_type_factor_benchmark" */
  update_company_type_factor_benchmark?: Maybe<Company_Type_Factor_Benchmark_Mutation_Response>;
  /** update single row of the table: "company_type_factor_benchmark" */
  update_company_type_factor_benchmark_by_pk?: Maybe<Company_Type_Factor_Benchmark>;
  /** update multiples rows of table: "company_type_factor_benchmark" */
  update_company_type_factor_benchmark_many?: Maybe<Array<Maybe<Company_Type_Factor_Benchmark_Mutation_Response>>>;
  /** update data of the table: "company_user" */
  update_company_user?: Maybe<Company_User_Mutation_Response>;
  /** update single row of the table: "company_user" */
  update_company_user_by_pk?: Maybe<Company_User>;
  /** update multiples rows of table: "company_user" */
  update_company_user_many?: Maybe<Array<Maybe<Company_User_Mutation_Response>>>;
  /** update data of the table: "deal" */
  update_deal?: Maybe<Deal_Mutation_Response>;
  /** update single row of the table: "deal" */
  update_deal_by_pk?: Maybe<Deal>;
  /** update multiples rows of table: "deal" */
  update_deal_many?: Maybe<Array<Maybe<Deal_Mutation_Response>>>;
  /** update data of the table: "document_checklist" */
  update_document_checklist?: Maybe<Document_Checklist_Mutation_Response>;
  /** update single row of the table: "document_checklist" */
  update_document_checklist_by_pk?: Maybe<Document_Checklist>;
  /** update data of the table: "document_checklist_category" */
  update_document_checklist_category?: Maybe<Document_Checklist_Category_Mutation_Response>;
  /** update single row of the table: "document_checklist_category" */
  update_document_checklist_category_by_pk?: Maybe<Document_Checklist_Category>;
  /** update multiples rows of table: "document_checklist_category" */
  update_document_checklist_category_many?: Maybe<Array<Maybe<Document_Checklist_Category_Mutation_Response>>>;
  /** update multiples rows of table: "document_checklist" */
  update_document_checklist_many?: Maybe<Array<Maybe<Document_Checklist_Mutation_Response>>>;
  /** update data of the table: "email_events" */
  update_email_events?: Maybe<Email_Events_Mutation_Response>;
  /** update single row of the table: "email_events" */
  update_email_events_by_pk?: Maybe<Email_Events>;
  /** update multiples rows of table: "email_events" */
  update_email_events_many?: Maybe<Array<Maybe<Email_Events_Mutation_Response>>>;
  /** update data of the table: "emi_tds" */
  update_emi_tds?: Maybe<Emi_Tds_Mutation_Response>;
  /** update single row of the table: "emi_tds" */
  update_emi_tds_by_pk?: Maybe<Emi_Tds>;
  /** update multiples rows of table: "emi_tds" */
  update_emi_tds_many?: Maybe<Array<Maybe<Emi_Tds_Mutation_Response>>>;
  /** update data of the table: "factor_benchmark_range" */
  update_factor_benchmark_range?: Maybe<Factor_Benchmark_Range_Mutation_Response>;
  /** update single row of the table: "factor_benchmark_range" */
  update_factor_benchmark_range_by_pk?: Maybe<Factor_Benchmark_Range>;
  /** update multiples rows of table: "factor_benchmark_range" */
  update_factor_benchmark_range_many?: Maybe<Array<Maybe<Factor_Benchmark_Range_Mutation_Response>>>;
  /** update data of the table: "file_registry" */
  update_file_registry?: Maybe<File_Registry_Mutation_Response>;
  /** update single row of the table: "file_registry" */
  update_file_registry_by_pk?: Maybe<File_Registry>;
  /** update multiples rows of table: "file_registry" */
  update_file_registry_many?: Maybe<Array<Maybe<File_Registry_Mutation_Response>>>;
  /** update data of the table: "integration_auth" */
  update_integration_auth?: Maybe<Integration_Auth_Mutation_Response>;
  /** update single row of the table: "integration_auth" */
  update_integration_auth_by_pk?: Maybe<Integration_Auth>;
  /** update multiples rows of table: "integration_auth" */
  update_integration_auth_many?: Maybe<Array<Maybe<Integration_Auth_Mutation_Response>>>;
  /** update data of the table: "internal_statement" */
  update_internal_statement?: Maybe<Internal_Statement_Mutation_Response>;
  /** update single row of the table: "internal_statement" */
  update_internal_statement_by_pk?: Maybe<Internal_Statement>;
  /** update multiples rows of table: "internal_statement" */
  update_internal_statement_many?: Maybe<Array<Maybe<Internal_Statement_Mutation_Response>>>;
  /** update data of the table: "investment" */
  update_investment?: Maybe<Investment_Mutation_Response>;
  /** update single row of the table: "investment" */
  update_investment_by_pk?: Maybe<Investment>;
  /** update multiples rows of table: "investment" */
  update_investment_many?: Maybe<Array<Maybe<Investment_Mutation_Response>>>;
  /** update data of the table: "investor" */
  update_investor?: Maybe<Investor_Mutation_Response>;
  /** update single row of the table: "investor" */
  update_investor_by_pk?: Maybe<Investor>;
  /** update multiples rows of table: "investor" */
  update_investor_many?: Maybe<Array<Maybe<Investor_Mutation_Response>>>;
  /** update data of the table: "investor_preference" */
  update_investor_preference?: Maybe<Investor_Preference_Mutation_Response>;
  /** update single row of the table: "investor_preference" */
  update_investor_preference_by_pk?: Maybe<Investor_Preference>;
  /** update multiples rows of table: "investor_preference" */
  update_investor_preference_many?: Maybe<Array<Maybe<Investor_Preference_Mutation_Response>>>;
  /** update data of the table: "investor_statement" */
  update_investor_statement?: Maybe<Investor_Statement_Mutation_Response>;
  /** update single row of the table: "investor_statement" */
  update_investor_statement_by_pk?: Maybe<Investor_Statement>;
  /** update multiples rows of table: "investor_statement" */
  update_investor_statement_many?: Maybe<Array<Maybe<Investor_Statement_Mutation_Response>>>;
  /** update data of the table: "investor_transaction" */
  update_investor_transaction?: Maybe<Investor_Transaction_Mutation_Response>;
  /** update single row of the table: "investor_transaction" */
  update_investor_transaction_by_pk?: Maybe<Investor_Transaction>;
  /** update multiples rows of table: "investor_transaction" */
  update_investor_transaction_many?: Maybe<Array<Maybe<Investor_Transaction_Mutation_Response>>>;
  /** update data of the table: "investor_user" */
  update_investor_user?: Maybe<Investor_User_Mutation_Response>;
  /** update single row of the table: "investor_user" */
  update_investor_user_by_pk?: Maybe<Investor_User>;
  /** update multiples rows of table: "investor_user" */
  update_investor_user_many?: Maybe<Array<Maybe<Investor_User_Mutation_Response>>>;
  /** update data of the table: "lifecycle_question_crm_mapper" */
  update_lifecycle_question_crm_mapper?: Maybe<Lifecycle_Question_Crm_Mapper_Mutation_Response>;
  /** update single row of the table: "lifecycle_question_crm_mapper" */
  update_lifecycle_question_crm_mapper_by_pk?: Maybe<Lifecycle_Question_Crm_Mapper>;
  /** update multiples rows of table: "lifecycle_question_crm_mapper" */
  update_lifecycle_question_crm_mapper_many?: Maybe<Array<Maybe<Lifecycle_Question_Crm_Mapper_Mutation_Response>>>;
  /** update data of the table: "lifecycle_question_result" */
  update_lifecycle_question_result?: Maybe<Lifecycle_Question_Result_Mutation_Response>;
  /** update single row of the table: "lifecycle_question_result" */
  update_lifecycle_question_result_by_pk?: Maybe<Lifecycle_Question_Result>;
  /** update multiples rows of table: "lifecycle_question_result" */
  update_lifecycle_question_result_many?: Maybe<Array<Maybe<Lifecycle_Question_Result_Mutation_Response>>>;
  /** update data of the table: "mandate" */
  update_mandate?: Maybe<Mandate_Mutation_Response>;
  /** update single row of the table: "mandate" */
  update_mandate_by_pk?: Maybe<Mandate>;
  /** update multiples rows of table: "mandate" */
  update_mandate_many?: Maybe<Array<Maybe<Mandate_Mutation_Response>>>;
  /** update data of the table: "popular_banks" */
  update_popular_banks?: Maybe<Popular_Banks_Mutation_Response>;
  /** update single row of the table: "popular_banks" */
  update_popular_banks_by_pk?: Maybe<Popular_Banks>;
  /** update multiples rows of table: "popular_banks" */
  update_popular_banks_many?: Maybe<Array<Maybe<Popular_Banks_Mutation_Response>>>;
  /** update data of the table: "ref_account_status" */
  update_ref_account_status?: Maybe<Ref_Account_Status_Mutation_Response>;
  /** update single row of the table: "ref_account_status" */
  update_ref_account_status_by_pk?: Maybe<Ref_Account_Status>;
  /** update multiples rows of table: "ref_account_status" */
  update_ref_account_status_many?: Maybe<Array<Maybe<Ref_Account_Status_Mutation_Response>>>;
  /** update data of the table: "ref_account_type" */
  update_ref_account_type?: Maybe<Ref_Account_Type_Mutation_Response>;
  /** update single row of the table: "ref_account_type" */
  update_ref_account_type_by_pk?: Maybe<Ref_Account_Type>;
  /** update multiples rows of table: "ref_account_type" */
  update_ref_account_type_many?: Maybe<Array<Maybe<Ref_Account_Type_Mutation_Response>>>;
  /** update data of the table: "ref_agreement_category" */
  update_ref_agreement_category?: Maybe<Ref_Agreement_Category_Mutation_Response>;
  /** update single row of the table: "ref_agreement_category" */
  update_ref_agreement_category_by_pk?: Maybe<Ref_Agreement_Category>;
  /** update multiples rows of table: "ref_agreement_category" */
  update_ref_agreement_category_many?: Maybe<Array<Maybe<Ref_Agreement_Category_Mutation_Response>>>;
  /** update data of the table: "ref_agreement_status" */
  update_ref_agreement_status?: Maybe<Ref_Agreement_Status_Mutation_Response>;
  /** update single row of the table: "ref_agreement_status" */
  update_ref_agreement_status_by_pk?: Maybe<Ref_Agreement_Status>;
  /** update multiples rows of table: "ref_agreement_status" */
  update_ref_agreement_status_many?: Maybe<Array<Maybe<Ref_Agreement_Status_Mutation_Response>>>;
  /** update data of the table: "ref_application_allowed_district" */
  update_ref_application_allowed_district?: Maybe<Ref_Application_Allowed_District_Mutation_Response>;
  /** update single row of the table: "ref_application_allowed_district" */
  update_ref_application_allowed_district_by_pk?: Maybe<Ref_Application_Allowed_District>;
  /** update multiples rows of table: "ref_application_allowed_district" */
  update_ref_application_allowed_district_many?: Maybe<Array<Maybe<Ref_Application_Allowed_District_Mutation_Response>>>;
  /** update data of the table: "ref_application_lifecycle_stage" */
  update_ref_application_lifecycle_stage?: Maybe<Ref_Application_Lifecycle_Stage_Mutation_Response>;
  /** update single row of the table: "ref_application_lifecycle_stage" */
  update_ref_application_lifecycle_stage_by_pk?: Maybe<Ref_Application_Lifecycle_Stage>;
  /** update multiples rows of table: "ref_application_lifecycle_stage" */
  update_ref_application_lifecycle_stage_many?: Maybe<Array<Maybe<Ref_Application_Lifecycle_Stage_Mutation_Response>>>;
  /** update data of the table: "ref_bank" */
  update_ref_bank?: Maybe<Ref_Bank_Mutation_Response>;
  /** update single row of the table: "ref_bank" */
  update_ref_bank_by_pk?: Maybe<Ref_Bank>;
  /** update data of the table: "ref_bank_list" */
  update_ref_bank_list?: Maybe<Ref_Bank_List_Mutation_Response>;
  /** update single row of the table: "ref_bank_list" */
  update_ref_bank_list_by_pk?: Maybe<Ref_Bank_List>;
  /** update multiples rows of table: "ref_bank_list" */
  update_ref_bank_list_many?: Maybe<Array<Maybe<Ref_Bank_List_Mutation_Response>>>;
  /** update multiples rows of table: "ref_bank" */
  update_ref_bank_many?: Maybe<Array<Maybe<Ref_Bank_Mutation_Response>>>;
  /** update data of the table: "ref_bid" */
  update_ref_bid?: Maybe<Ref_Bid_Mutation_Response>;
  /** update single row of the table: "ref_bid" */
  update_ref_bid_by_pk?: Maybe<Ref_Bid>;
  /** update multiples rows of table: "ref_bid" */
  update_ref_bid_many?: Maybe<Array<Maybe<Ref_Bid_Mutation_Response>>>;
  /** update data of the table: "ref_billing_period" */
  update_ref_billing_period?: Maybe<Ref_Billing_Period_Mutation_Response>;
  /** update single row of the table: "ref_billing_period" */
  update_ref_billing_period_by_pk?: Maybe<Ref_Billing_Period>;
  /** update multiples rows of table: "ref_billing_period" */
  update_ref_billing_period_many?: Maybe<Array<Maybe<Ref_Billing_Period_Mutation_Response>>>;
  /** update data of the table: "ref_category" */
  update_ref_category?: Maybe<Ref_Category_Mutation_Response>;
  /** update single row of the table: "ref_category" */
  update_ref_category_by_pk?: Maybe<Ref_Category>;
  /** update multiples rows of table: "ref_category" */
  update_ref_category_many?: Maybe<Array<Maybe<Ref_Category_Mutation_Response>>>;
  /** update data of the table: "ref_collection_mode" */
  update_ref_collection_mode?: Maybe<Ref_Collection_Mode_Mutation_Response>;
  /** update single row of the table: "ref_collection_mode" */
  update_ref_collection_mode_by_pk?: Maybe<Ref_Collection_Mode>;
  /** update multiples rows of table: "ref_collection_mode" */
  update_ref_collection_mode_many?: Maybe<Array<Maybe<Ref_Collection_Mode_Mutation_Response>>>;
  /** update data of the table: "ref_company_lead_status" */
  update_ref_company_lead_status?: Maybe<Ref_Company_Lead_Status_Mutation_Response>;
  /** update single row of the table: "ref_company_lead_status" */
  update_ref_company_lead_status_by_pk?: Maybe<Ref_Company_Lead_Status>;
  /** update multiples rows of table: "ref_company_lead_status" */
  update_ref_company_lead_status_many?: Maybe<Array<Maybe<Ref_Company_Lead_Status_Mutation_Response>>>;
  /** update data of the table: "ref_company_likeliness" */
  update_ref_company_likeliness?: Maybe<Ref_Company_Likeliness_Mutation_Response>;
  /** update single row of the table: "ref_company_likeliness" */
  update_ref_company_likeliness_by_pk?: Maybe<Ref_Company_Likeliness>;
  /** update multiples rows of table: "ref_company_likeliness" */
  update_ref_company_likeliness_many?: Maybe<Array<Maybe<Ref_Company_Likeliness_Mutation_Response>>>;
  /** update data of the table: "ref_company_status" */
  update_ref_company_status?: Maybe<Ref_Company_Status_Mutation_Response>;
  /** update single row of the table: "ref_company_status" */
  update_ref_company_status_by_pk?: Maybe<Ref_Company_Status>;
  /** update multiples rows of table: "ref_company_status" */
  update_ref_company_status_many?: Maybe<Array<Maybe<Ref_Company_Status_Mutation_Response>>>;
  /** update data of the table: "ref_company_type" */
  update_ref_company_type?: Maybe<Ref_Company_Type_Mutation_Response>;
  /** update single row of the table: "ref_company_type" */
  update_ref_company_type_by_pk?: Maybe<Ref_Company_Type>;
  /** update multiples rows of table: "ref_company_type" */
  update_ref_company_type_many?: Maybe<Array<Maybe<Ref_Company_Type_Mutation_Response>>>;
  /** update data of the table: "ref_country" */
  update_ref_country?: Maybe<Ref_Country_Mutation_Response>;
  /** update single row of the table: "ref_country" */
  update_ref_country_by_pk?: Maybe<Ref_Country>;
  /** update multiples rows of table: "ref_country" */
  update_ref_country_many?: Maybe<Array<Maybe<Ref_Country_Mutation_Response>>>;
  /** update data of the table: "ref_currency" */
  update_ref_currency?: Maybe<Ref_Currency_Mutation_Response>;
  /** update single row of the table: "ref_currency" */
  update_ref_currency_by_pk?: Maybe<Ref_Currency>;
  /** update multiples rows of table: "ref_currency" */
  update_ref_currency_many?: Maybe<Array<Maybe<Ref_Currency_Mutation_Response>>>;
  /** update data of the table: "ref_debit_mode" */
  update_ref_debit_mode?: Maybe<Ref_Debit_Mode_Mutation_Response>;
  /** update single row of the table: "ref_debit_mode" */
  update_ref_debit_mode_by_pk?: Maybe<Ref_Debit_Mode>;
  /** update multiples rows of table: "ref_debit_mode" */
  update_ref_debit_mode_many?: Maybe<Array<Maybe<Ref_Debit_Mode_Mutation_Response>>>;
  /** update data of the table: "ref_document_checklist" */
  update_ref_document_checklist?: Maybe<Ref_Document_Checklist_Mutation_Response>;
  /** update single row of the table: "ref_document_checklist" */
  update_ref_document_checklist_by_pk?: Maybe<Ref_Document_Checklist>;
  /** update data of the table: "ref_document_checklist_category" */
  update_ref_document_checklist_category?: Maybe<Ref_Document_Checklist_Category_Mutation_Response>;
  /** update single row of the table: "ref_document_checklist_category" */
  update_ref_document_checklist_category_by_pk?: Maybe<Ref_Document_Checklist_Category>;
  /** update multiples rows of table: "ref_document_checklist_category" */
  update_ref_document_checklist_category_many?: Maybe<Array<Maybe<Ref_Document_Checklist_Category_Mutation_Response>>>;
  /** update data of the table: "ref_document_checklist_integration" */
  update_ref_document_checklist_integration?: Maybe<Ref_Document_Checklist_Integration_Mutation_Response>;
  /** update single row of the table: "ref_document_checklist_integration" */
  update_ref_document_checklist_integration_by_pk?: Maybe<Ref_Document_Checklist_Integration>;
  /** update multiples rows of table: "ref_document_checklist_integration" */
  update_ref_document_checklist_integration_many?: Maybe<Array<Maybe<Ref_Document_Checklist_Integration_Mutation_Response>>>;
  /** update multiples rows of table: "ref_document_checklist" */
  update_ref_document_checklist_many?: Maybe<Array<Maybe<Ref_Document_Checklist_Mutation_Response>>>;
  /** update data of the table: "ref_document_checklist_status" */
  update_ref_document_checklist_status?: Maybe<Ref_Document_Checklist_Status_Mutation_Response>;
  /** update single row of the table: "ref_document_checklist_status" */
  update_ref_document_checklist_status_by_pk?: Maybe<Ref_Document_Checklist_Status>;
  /** update multiples rows of table: "ref_document_checklist_status" */
  update_ref_document_checklist_status_many?: Maybe<Array<Maybe<Ref_Document_Checklist_Status_Mutation_Response>>>;
  /** update data of the table: "ref_email_events_type" */
  update_ref_email_events_type?: Maybe<Ref_Email_Events_Type_Mutation_Response>;
  /** update single row of the table: "ref_email_events_type" */
  update_ref_email_events_type_by_pk?: Maybe<Ref_Email_Events_Type>;
  /** update multiples rows of table: "ref_email_events_type" */
  update_ref_email_events_type_many?: Maybe<Array<Maybe<Ref_Email_Events_Type_Mutation_Response>>>;
  /** update data of the table: "ref_file_category" */
  update_ref_file_category?: Maybe<Ref_File_Category_Mutation_Response>;
  /** update single row of the table: "ref_file_category" */
  update_ref_file_category_by_pk?: Maybe<Ref_File_Category>;
  /** update multiples rows of table: "ref_file_category" */
  update_ref_file_category_many?: Maybe<Array<Maybe<Ref_File_Category_Mutation_Response>>>;
  /** update data of the table: "ref_file_category_mimetype" */
  update_ref_file_category_mimetype?: Maybe<Ref_File_Category_Mimetype_Mutation_Response>;
  /** update single row of the table: "ref_file_category_mimetype" */
  update_ref_file_category_mimetype_by_pk?: Maybe<Ref_File_Category_Mimetype>;
  /** update multiples rows of table: "ref_file_category_mimetype" */
  update_ref_file_category_mimetype_many?: Maybe<Array<Maybe<Ref_File_Category_Mimetype_Mutation_Response>>>;
  /** update data of the table: "ref_industry" */
  update_ref_industry?: Maybe<Ref_Industry_Mutation_Response>;
  /** update single row of the table: "ref_industry" */
  update_ref_industry_by_pk?: Maybe<Ref_Industry>;
  /** update multiples rows of table: "ref_industry" */
  update_ref_industry_many?: Maybe<Array<Maybe<Ref_Industry_Mutation_Response>>>;
  /** update data of the table: "ref_integration_file_status" */
  update_ref_integration_file_status?: Maybe<Ref_Integration_File_Status_Mutation_Response>;
  /** update single row of the table: "ref_integration_file_status" */
  update_ref_integration_file_status_by_pk?: Maybe<Ref_Integration_File_Status>;
  /** update multiples rows of table: "ref_integration_file_status" */
  update_ref_integration_file_status_many?: Maybe<Array<Maybe<Ref_Integration_File_Status_Mutation_Response>>>;
  /** update data of the table: "ref_integration_status" */
  update_ref_integration_status?: Maybe<Ref_Integration_Status_Mutation_Response>;
  /** update single row of the table: "ref_integration_status" */
  update_ref_integration_status_by_pk?: Maybe<Ref_Integration_Status>;
  /** update multiples rows of table: "ref_integration_status" */
  update_ref_integration_status_many?: Maybe<Array<Maybe<Ref_Integration_Status_Mutation_Response>>>;
  /** update data of the table: "ref_investor_status" */
  update_ref_investor_status?: Maybe<Ref_Investor_Status_Mutation_Response>;
  /** update single row of the table: "ref_investor_status" */
  update_ref_investor_status_by_pk?: Maybe<Ref_Investor_Status>;
  /** update multiples rows of table: "ref_investor_status" */
  update_ref_investor_status_many?: Maybe<Array<Maybe<Ref_Investor_Status_Mutation_Response>>>;
  /** update data of the table: "ref_investor_type" */
  update_ref_investor_type?: Maybe<Ref_Investor_Type_Mutation_Response>;
  /** update single row of the table: "ref_investor_type" */
  update_ref_investor_type_by_pk?: Maybe<Ref_Investor_Type>;
  /** update multiples rows of table: "ref_investor_type" */
  update_ref_investor_type_many?: Maybe<Array<Maybe<Ref_Investor_Type_Mutation_Response>>>;
  /** update data of the table: "ref_lifecycle_question" */
  update_ref_lifecycle_question?: Maybe<Ref_Lifecycle_Question_Mutation_Response>;
  /** update single row of the table: "ref_lifecycle_question" */
  update_ref_lifecycle_question_by_pk?: Maybe<Ref_Lifecycle_Question>;
  /** update data of the table: "ref_lifecycle_question_category" */
  update_ref_lifecycle_question_category?: Maybe<Ref_Lifecycle_Question_Category_Mutation_Response>;
  /** update single row of the table: "ref_lifecycle_question_category" */
  update_ref_lifecycle_question_category_by_pk?: Maybe<Ref_Lifecycle_Question_Category>;
  /** update multiples rows of table: "ref_lifecycle_question_category" */
  update_ref_lifecycle_question_category_many?: Maybe<Array<Maybe<Ref_Lifecycle_Question_Category_Mutation_Response>>>;
  /** update multiples rows of table: "ref_lifecycle_question" */
  update_ref_lifecycle_question_many?: Maybe<Array<Maybe<Ref_Lifecycle_Question_Mutation_Response>>>;
  /** update data of the table: "ref_mandate_frequency" */
  update_ref_mandate_frequency?: Maybe<Ref_Mandate_Frequency_Mutation_Response>;
  /** update single row of the table: "ref_mandate_frequency" */
  update_ref_mandate_frequency_by_pk?: Maybe<Ref_Mandate_Frequency>;
  /** update multiples rows of table: "ref_mandate_frequency" */
  update_ref_mandate_frequency_many?: Maybe<Array<Maybe<Ref_Mandate_Frequency_Mutation_Response>>>;
  /** update data of the table: "ref_mandate_status" */
  update_ref_mandate_status?: Maybe<Ref_Mandate_Status_Mutation_Response>;
  /** update single row of the table: "ref_mandate_status" */
  update_ref_mandate_status_by_pk?: Maybe<Ref_Mandate_Status>;
  /** update multiples rows of table: "ref_mandate_status" */
  update_ref_mandate_status_many?: Maybe<Array<Maybe<Ref_Mandate_Status_Mutation_Response>>>;
  /** update data of the table: "ref_mandate_type" */
  update_ref_mandate_type?: Maybe<Ref_Mandate_Type_Mutation_Response>;
  /** update single row of the table: "ref_mandate_type" */
  update_ref_mandate_type_by_pk?: Maybe<Ref_Mandate_Type>;
  /** update multiples rows of table: "ref_mandate_type" */
  update_ref_mandate_type_many?: Maybe<Array<Maybe<Ref_Mandate_Type_Mutation_Response>>>;
  /** update data of the table: "ref_perfios_bank_list" */
  update_ref_perfios_bank_list?: Maybe<Ref_Perfios_Bank_List_Mutation_Response>;
  /** update single row of the table: "ref_perfios_bank_list" */
  update_ref_perfios_bank_list_by_pk?: Maybe<Ref_Perfios_Bank_List>;
  /** update multiples rows of table: "ref_perfios_bank_list" */
  update_ref_perfios_bank_list_many?: Maybe<Array<Maybe<Ref_Perfios_Bank_List_Mutation_Response>>>;
  /** update data of the table: "ref_report_category" */
  update_ref_report_category?: Maybe<Ref_Report_Category_Mutation_Response>;
  /** update single row of the table: "ref_report_category" */
  update_ref_report_category_by_pk?: Maybe<Ref_Report_Category>;
  /** update multiples rows of table: "ref_report_category" */
  update_ref_report_category_many?: Maybe<Array<Maybe<Ref_Report_Category_Mutation_Response>>>;
  /** update data of the table: "ref_role" */
  update_ref_role?: Maybe<Ref_Role_Mutation_Response>;
  /** update single row of the table: "ref_role" */
  update_ref_role_by_pk?: Maybe<Ref_Role>;
  /** update multiples rows of table: "ref_role" */
  update_ref_role_many?: Maybe<Array<Maybe<Ref_Role_Mutation_Response>>>;
  /** update data of the table: "ref_schedule_checklist_reminder_status" */
  update_ref_schedule_checklist_reminder_status?: Maybe<Ref_Schedule_Checklist_Reminder_Status_Mutation_Response>;
  /** update single row of the table: "ref_schedule_checklist_reminder_status" */
  update_ref_schedule_checklist_reminder_status_by_pk?: Maybe<Ref_Schedule_Checklist_Reminder_Status>;
  /** update multiples rows of table: "ref_schedule_checklist_reminder_status" */
  update_ref_schedule_checklist_reminder_status_many?: Maybe<Array<Maybe<Ref_Schedule_Checklist_Reminder_Status_Mutation_Response>>>;
  /** update data of the table: "ref_score_factor" */
  update_ref_score_factor?: Maybe<Ref_Score_Factor_Mutation_Response>;
  /** update single row of the table: "ref_score_factor" */
  update_ref_score_factor_by_pk?: Maybe<Ref_Score_Factor>;
  /** update data of the table: "ref_score_factor_category" */
  update_ref_score_factor_category?: Maybe<Ref_Score_Factor_Category_Mutation_Response>;
  /** update single row of the table: "ref_score_factor_category" */
  update_ref_score_factor_category_by_pk?: Maybe<Ref_Score_Factor_Category>;
  /** update multiples rows of table: "ref_score_factor_category" */
  update_ref_score_factor_category_many?: Maybe<Array<Maybe<Ref_Score_Factor_Category_Mutation_Response>>>;
  /** update multiples rows of table: "ref_score_factor" */
  update_ref_score_factor_many?: Maybe<Array<Maybe<Ref_Score_Factor_Mutation_Response>>>;
  /** update data of the table: "ref_score_factor_unit" */
  update_ref_score_factor_unit?: Maybe<Ref_Score_Factor_Unit_Mutation_Response>;
  /** update single row of the table: "ref_score_factor_unit" */
  update_ref_score_factor_unit_by_pk?: Maybe<Ref_Score_Factor_Unit>;
  /** update multiples rows of table: "ref_score_factor_unit" */
  update_ref_score_factor_unit_many?: Maybe<Array<Maybe<Ref_Score_Factor_Unit_Mutation_Response>>>;
  /** update data of the table: "ref_source" */
  update_ref_source?: Maybe<Ref_Source_Mutation_Response>;
  /** update single row of the table: "ref_source" */
  update_ref_source_by_pk?: Maybe<Ref_Source>;
  /** update multiples rows of table: "ref_source" */
  update_ref_source_many?: Maybe<Array<Maybe<Ref_Source_Mutation_Response>>>;
  /** update data of the table: "ref_states" */
  update_ref_states?: Maybe<Ref_States_Mutation_Response>;
  /** update single row of the table: "ref_states" */
  update_ref_states_by_pk?: Maybe<Ref_States>;
  /** update multiples rows of table: "ref_states" */
  update_ref_states_many?: Maybe<Array<Maybe<Ref_States_Mutation_Response>>>;
  /** update data of the table: "ref_subscription_status" */
  update_ref_subscription_status?: Maybe<Ref_Subscription_Status_Mutation_Response>;
  /** update single row of the table: "ref_subscription_status" */
  update_ref_subscription_status_by_pk?: Maybe<Ref_Subscription_Status>;
  /** update multiples rows of table: "ref_subscription_status" */
  update_ref_subscription_status_many?: Maybe<Array<Maybe<Ref_Subscription_Status_Mutation_Response>>>;
  /** update data of the table: "ref_system_account" */
  update_ref_system_account?: Maybe<Ref_System_Account_Mutation_Response>;
  /** update single row of the table: "ref_system_account" */
  update_ref_system_account_by_pk?: Maybe<Ref_System_Account>;
  /** update multiples rows of table: "ref_system_account" */
  update_ref_system_account_many?: Maybe<Array<Maybe<Ref_System_Account_Mutation_Response>>>;
  /** update data of the table: "ref_system_account_status" */
  update_ref_system_account_status?: Maybe<Ref_System_Account_Status_Mutation_Response>;
  /** update single row of the table: "ref_system_account_status" */
  update_ref_system_account_status_by_pk?: Maybe<Ref_System_Account_Status>;
  /** update multiples rows of table: "ref_system_account_status" */
  update_ref_system_account_status_many?: Maybe<Array<Maybe<Ref_System_Account_Status_Mutation_Response>>>;
  /** update data of the table: "ref_system_account_type" */
  update_ref_system_account_type?: Maybe<Ref_System_Account_Type_Mutation_Response>;
  /** update single row of the table: "ref_system_account_type" */
  update_ref_system_account_type_by_pk?: Maybe<Ref_System_Account_Type>;
  /** update multiples rows of table: "ref_system_account_type" */
  update_ref_system_account_type_many?: Maybe<Array<Maybe<Ref_System_Account_Type_Mutation_Response>>>;
  /** update data of the table: "ref_tds_status" */
  update_ref_tds_status?: Maybe<Ref_Tds_Status_Mutation_Response>;
  /** update single row of the table: "ref_tds_status" */
  update_ref_tds_status_by_pk?: Maybe<Ref_Tds_Status>;
  /** update multiples rows of table: "ref_tds_status" */
  update_ref_tds_status_many?: Maybe<Array<Maybe<Ref_Tds_Status_Mutation_Response>>>;
  /** update data of the table: "ref_tds_value_unit" */
  update_ref_tds_value_unit?: Maybe<Ref_Tds_Value_Unit_Mutation_Response>;
  /** update single row of the table: "ref_tds_value_unit" */
  update_ref_tds_value_unit_by_pk?: Maybe<Ref_Tds_Value_Unit>;
  /** update multiples rows of table: "ref_tds_value_unit" */
  update_ref_tds_value_unit_many?: Maybe<Array<Maybe<Ref_Tds_Value_Unit_Mutation_Response>>>;
  /** update data of the table: "ref_tenant_type" */
  update_ref_tenant_type?: Maybe<Ref_Tenant_Type_Mutation_Response>;
  /** update single row of the table: "ref_tenant_type" */
  update_ref_tenant_type_by_pk?: Maybe<Ref_Tenant_Type>;
  /** update multiples rows of table: "ref_tenant_type" */
  update_ref_tenant_type_many?: Maybe<Array<Maybe<Ref_Tenant_Type_Mutation_Response>>>;
  /** update data of the table: "ref_trade_state" */
  update_ref_trade_state?: Maybe<Ref_Trade_State_Mutation_Response>;
  /** update single row of the table: "ref_trade_state" */
  update_ref_trade_state_by_pk?: Maybe<Ref_Trade_State>;
  /** update multiples rows of table: "ref_trade_state" */
  update_ref_trade_state_many?: Maybe<Array<Maybe<Ref_Trade_State_Mutation_Response>>>;
  /** update data of the table: "ref_trade_status" */
  update_ref_trade_status?: Maybe<Ref_Trade_Status_Mutation_Response>;
  /** update single row of the table: "ref_trade_status" */
  update_ref_trade_status_by_pk?: Maybe<Ref_Trade_Status>;
  /** update multiples rows of table: "ref_trade_status" */
  update_ref_trade_status_many?: Maybe<Array<Maybe<Ref_Trade_Status_Mutation_Response>>>;
  /** update data of the table: "ref_trade_withdrawal_mode" */
  update_ref_trade_withdrawal_mode?: Maybe<Ref_Trade_Withdrawal_Mode_Mutation_Response>;
  /** update single row of the table: "ref_trade_withdrawal_mode" */
  update_ref_trade_withdrawal_mode_by_pk?: Maybe<Ref_Trade_Withdrawal_Mode>;
  /** update multiples rows of table: "ref_trade_withdrawal_mode" */
  update_ref_trade_withdrawal_mode_many?: Maybe<Array<Maybe<Ref_Trade_Withdrawal_Mode_Mutation_Response>>>;
  /** update data of the table: "ref_transaction_method" */
  update_ref_transaction_method?: Maybe<Ref_Transaction_Method_Mutation_Response>;
  /** update single row of the table: "ref_transaction_method" */
  update_ref_transaction_method_by_pk?: Maybe<Ref_Transaction_Method>;
  /** update multiples rows of table: "ref_transaction_method" */
  update_ref_transaction_method_many?: Maybe<Array<Maybe<Ref_Transaction_Method_Mutation_Response>>>;
  /** update data of the table: "ref_transaction_status" */
  update_ref_transaction_status?: Maybe<Ref_Transaction_Status_Mutation_Response>;
  /** update single row of the table: "ref_transaction_status" */
  update_ref_transaction_status_by_pk?: Maybe<Ref_Transaction_Status>;
  /** update multiples rows of table: "ref_transaction_status" */
  update_ref_transaction_status_many?: Maybe<Array<Maybe<Ref_Transaction_Status_Mutation_Response>>>;
  /** update data of the table: "ref_transaction_type" */
  update_ref_transaction_type?: Maybe<Ref_Transaction_Type_Mutation_Response>;
  /** update single row of the table: "ref_transaction_type" */
  update_ref_transaction_type_by_pk?: Maybe<Ref_Transaction_Type>;
  /** update multiples rows of table: "ref_transaction_type" */
  update_ref_transaction_type_many?: Maybe<Array<Maybe<Ref_Transaction_Type_Mutation_Response>>>;
  /** update data of the table: "ref_transfer_status" */
  update_ref_transfer_status?: Maybe<Ref_Transfer_Status_Mutation_Response>;
  /** update single row of the table: "ref_transfer_status" */
  update_ref_transfer_status_by_pk?: Maybe<Ref_Transfer_Status>;
  /** update multiples rows of table: "ref_transfer_status" */
  update_ref_transfer_status_many?: Maybe<Array<Maybe<Ref_Transfer_Status_Mutation_Response>>>;
  /** update data of the table: "ref_transfer_type" */
  update_ref_transfer_type?: Maybe<Ref_Transfer_Type_Mutation_Response>;
  /** update single row of the table: "ref_transfer_type" */
  update_ref_transfer_type_by_pk?: Maybe<Ref_Transfer_Type>;
  /** update multiples rows of table: "ref_transfer_type" */
  update_ref_transfer_type_many?: Maybe<Array<Maybe<Ref_Transfer_Type_Mutation_Response>>>;
  /** update data of the table: "ref_user_status" */
  update_ref_user_status?: Maybe<Ref_User_Status_Mutation_Response>;
  /** update single row of the table: "ref_user_status" */
  update_ref_user_status_by_pk?: Maybe<Ref_User_Status>;
  /** update multiples rows of table: "ref_user_status" */
  update_ref_user_status_many?: Maybe<Array<Maybe<Ref_User_Status_Mutation_Response>>>;
  /** update data of the table: "ref_user_type" */
  update_ref_user_type?: Maybe<Ref_User_Type_Mutation_Response>;
  /** update single row of the table: "ref_user_type" */
  update_ref_user_type_by_pk?: Maybe<Ref_User_Type>;
  /** update multiples rows of table: "ref_user_type" */
  update_ref_user_type_many?: Maybe<Array<Maybe<Ref_User_Type_Mutation_Response>>>;
  /** update data of the table: "report_category" */
  update_report_category?: Maybe<Report_Category_Mutation_Response>;
  /** update single row of the table: "report_category" */
  update_report_category_by_pk?: Maybe<Report_Category>;
  /** update multiples rows of table: "report_category" */
  update_report_category_many?: Maybe<Array<Maybe<Report_Category_Mutation_Response>>>;
  /** update data of the table: "report_event" */
  update_report_event?: Maybe<Report_Event_Mutation_Response>;
  /** update single row of the table: "report_event" */
  update_report_event_by_pk?: Maybe<Report_Event>;
  /** update multiples rows of table: "report_event" */
  update_report_event_many?: Maybe<Array<Maybe<Report_Event_Mutation_Response>>>;
  /** update data of the table: "schedule_document_checklist_reminder" */
  update_schedule_document_checklist_reminder?: Maybe<Schedule_Document_Checklist_Reminder_Mutation_Response>;
  /** update single row of the table: "schedule_document_checklist_reminder" */
  update_schedule_document_checklist_reminder_by_pk?: Maybe<Schedule_Document_Checklist_Reminder>;
  /** update multiples rows of table: "schedule_document_checklist_reminder" */
  update_schedule_document_checklist_reminder_many?: Maybe<Array<Maybe<Schedule_Document_Checklist_Reminder_Mutation_Response>>>;
  /** update data of the table: "scheduled_emi" */
  update_scheduled_emi?: Maybe<Scheduled_Emi_Mutation_Response>;
  /** update single row of the table: "scheduled_emi" */
  update_scheduled_emi_by_pk?: Maybe<Scheduled_Emi>;
  /** update multiples rows of table: "scheduled_emi" */
  update_scheduled_emi_many?: Maybe<Array<Maybe<Scheduled_Emi_Mutation_Response>>>;
  /** update data of the table: "score_factor" */
  update_score_factor?: Maybe<Score_Factor_Mutation_Response>;
  /** update single row of the table: "score_factor" */
  update_score_factor_by_pk?: Maybe<Score_Factor>;
  /** update multiples rows of table: "score_factor" */
  update_score_factor_many?: Maybe<Array<Maybe<Score_Factor_Mutation_Response>>>;
  /** update data of the table: "staff_user" */
  update_staff_user?: Maybe<Staff_User_Mutation_Response>;
  /** update single row of the table: "staff_user" */
  update_staff_user_by_pk?: Maybe<Staff_User>;
  /** update multiples rows of table: "staff_user" */
  update_staff_user_many?: Maybe<Array<Maybe<Staff_User_Mutation_Response>>>;
  /** update data of the table: "subscription" */
  update_subscription?: Maybe<Subscription_Mutation_Response>;
  /** update single row of the table: "subscription" */
  update_subscription_by_pk?: Maybe<Subscription>;
  /** update multiples rows of table: "subscription" */
  update_subscription_many?: Maybe<Array<Maybe<Subscription_Mutation_Response>>>;
  /** update data of the table: "tds" */
  update_tds?: Maybe<Tds_Mutation_Response>;
  /** update single row of the table: "tds" */
  update_tds_by_pk?: Maybe<Tds>;
  /** update multiples rows of table: "tds" */
  update_tds_many?: Maybe<Array<Maybe<Tds_Mutation_Response>>>;
  /** update data of the table: "trade" */
  update_trade?: Maybe<Trade_Mutation_Response>;
  /** update single row of the table: "trade" */
  update_trade_by_pk?: Maybe<Trade>;
  /** update multiples rows of table: "trade" */
  update_trade_many?: Maybe<Array<Maybe<Trade_Mutation_Response>>>;
  /** update data of the table: "trade_subscription" */
  update_trade_subscription?: Maybe<Trade_Subscription_Mutation_Response>;
  /** update single row of the table: "trade_subscription" */
  update_trade_subscription_by_pk?: Maybe<Trade_Subscription>;
  /** update multiples rows of table: "trade_subscription" */
  update_trade_subscription_many?: Maybe<Array<Maybe<Trade_Subscription_Mutation_Response>>>;
  /** update data of the table: "transaction" */
  update_transaction?: Maybe<Transaction_Mutation_Response>;
  /** update single row of the table: "transaction" */
  update_transaction_by_pk?: Maybe<Transaction>;
  /** update multiples rows of table: "transaction" */
  update_transaction_many?: Maybe<Array<Maybe<Transaction_Mutation_Response>>>;
  /** update data of the table: "transfer" */
  update_transfer?: Maybe<Transfer_Mutation_Response>;
  /** update single row of the table: "transfer" */
  update_transfer_by_pk?: Maybe<Transfer>;
  /** update multiples rows of table: "transfer" */
  update_transfer_many?: Maybe<Array<Maybe<Transfer_Mutation_Response>>>;
  /** update data of the table: "updates_log" */
  update_updates_log?: Maybe<Updates_Log_Mutation_Response>;
  /** update single row of the table: "updates_log" */
  update_updates_log_by_pk?: Maybe<Updates_Log>;
  /** update multiples rows of table: "updates_log" */
  update_updates_log_many?: Maybe<Array<Maybe<Updates_Log_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "verification_code" */
  update_verification_code?: Maybe<Verification_Code_Mutation_Response>;
  /** update single row of the table: "verification_code" */
  update_verification_code_by_pk?: Maybe<Verification_Code>;
  /** update multiples rows of table: "verification_code" */
  update_verification_code_many?: Maybe<Array<Maybe<Verification_Code_Mutation_Response>>>;
  /** update data of the table: "webhook_events" */
  update_webhook_events?: Maybe<Webhook_Events_Mutation_Response>;
  /** update single row of the table: "webhook_events" */
  update_webhook_events_by_pk?: Maybe<Webhook_Events>;
  /** update multiples rows of table: "webhook_events" */
  update_webhook_events_many?: Maybe<Array<Maybe<Webhook_Events_Mutation_Response>>>;
  uploadSignedNachMandate?: Maybe<UploadSignedNachMandateOutput>;
  verifiedSubscriptionCSVUpload?: Maybe<VerifiedSubscriptionCsvUploadOutput>;
};


/** mutation root */
export type Mutation_RootAddCollaboratorArgs = {
  args: AddCollaboratorInput;
};


/** mutation root */
export type Mutation_RootAddDocumentchecklistForCompanyArgs = {
  args: AddDocumentchecklistForCompanyInput;
};


/** mutation root */
export type Mutation_RootAddInvestorUserArgs = {
  args: Array<AddInvestorUserInput>;
};


/** mutation root */
export type Mutation_RootAddPhysicalAccountArgs = {
  args: AddPhysicalAccountInput;
};


/** mutation root */
export type Mutation_RootAddVirtualAccountArgs = {
  args: AddVirtualAccountInput;
};


/** mutation root */
export type Mutation_RootCompanyAdditionalEmailsArgs = {
  args: CompanyAdditionalEmailsInput;
};


/** mutation root */
export type Mutation_RootDeleteCollaboratorArgs = {
  args: DeleteCollaboratorInput;
};


/** mutation root */
export type Mutation_RootDeletePhysicalAccountArgs = {
  args: DeletePhysicalAccountInput;
};


/** mutation root */
export type Mutation_RootDownloadAgreementArgs = {
  args: DownloadAgreementInput;
};


/** mutation root */
export type Mutation_RootDownloadAmortizationTableArgs = {
  args: DownloadAmortizationTableInput;
};


/** mutation root */
export type Mutation_RootDownloadFilesZipArgs = {
  args: DownloadFilesZipInput;
};


/** mutation root */
export type Mutation_RootDownloadZipArgs = {
  args: DownloadZipInput;
};


/** mutation root */
export type Mutation_RootEditTradeArgs = {
  args: EditTradeInput;
};


/** mutation root */
export type Mutation_RootExportCompanyQueuesArgs = {
  args: ExportCompanyQueuesInput;
};


/** mutation root */
export type Mutation_RootGenerateAnnexureArgs = {
  args: GenerateAnnexureInput;
};


/** mutation root */
export type Mutation_RootGenerateMisReportArgs = {
  args: GenerateMisReportInput;
};


/** mutation root */
export type Mutation_RootGetVirtualAccountBalanceArgs = {
  args: GetVirtualAccountBalanceInput;
};


/** mutation root */
export type Mutation_RootInitiatePayoutRequestArgs = {
  args: InitiatePayoutRequestInput;
};


/** mutation root */
export type Mutation_RootMoveCompanyInQueueArgs = {
  args: MoveCompanyInQueueInput;
};


/** mutation root */
export type Mutation_RootOpsCreateCompanyArgs = {
  args: OpsCreateCompanyInput;
};


/** mutation root */
export type Mutation_RootOpsCreateInvestorArgs = {
  args: OpsCreateInvestorInput;
};


/** mutation root */
export type Mutation_RootResendMailToCollaboratorArgs = {
  args: ResendMailToCollaboratorInput;
};


/** mutation root */
export type Mutation_RootSendDocumentchecklistNotificationArgs = {
  args: SendDocumentchecklistNotificationInput;
};


/** mutation root */
export type Mutation_RootSubmitPreQualificationQuestionnaireArgs = {
  args: SubmitPreQualificationQuestionnaireInput;
};


/** mutation root */
export type Mutation_RootToggleCompanyDocumentChecklistArgs = {
  args?: InputMaybe<ToggleCompanyDocumentChecklistInput>;
};


/** mutation root */
export type Mutation_RootTransferTdsToCompanyArgs = {
  args: TransferTdsToCompanyInput;
};


/** mutation root */
export type Mutation_RootTriggerCompanyClientAgreementArgs = {
  args: TriggerCompanyClientAgreementInput;
};


/** mutation root */
export type Mutation_RootTriggerCustomNdaArgs = {
  args: TriggerCustomNdaOutputInput;
};


/** mutation root */
export type Mutation_RootUpdateInvestorUserArgs = {
  args: UpdateInvestorUserInput;
};


/** mutation root */
export type Mutation_RootUploadAgreementArgs = {
  args: UploadAgreementInput;
};


/** mutation root */
export type Mutation_RootUploadSubscriptionDataArgs = {
  args: UploadSubscriptionDataInput;
};


/** mutation root */
export type Mutation_RootVerifyAndUpdateAutoRejectArgs = {
  args: VerifyAndUpdateAutoRejectInput;
};


/** mutation root */
export type Mutation_RootAccountingExcelUploadArgs = {
  args: AccountingExcelUploadInput;
};


/** mutation root */
export type Mutation_RootAccountingSyncArgs = {
  args: AccountingSyncInput;
};


/** mutation root */
export type Mutation_RootAddEmiTransactionArgs = {
  args: AddEmiTransactionInput;
};


/** mutation root */
export type Mutation_RootAddPayoutTransactionArgs = {
  args: AddPayoutTransactionInput;
};


/** mutation root */
export type Mutation_RootAttachContractsToTradeArgs = {
  args: AttachContractsToTradeInput;
};


/** mutation root */
export type Mutation_RootBankStatementsUploadArgs = {
  args: BankStatementsUploadInput;
};


/** mutation root */
export type Mutation_RootCreateDealArgs = {
  args: CreateDealInput;
};


/** mutation root */
export type Mutation_RootDeleteArgs = {
  args: DeleteInput;
};


/** mutation root */
export type Mutation_RootDelete_AccountArgs = {
  where: Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_BalanceArgs = {
  where: Account_Balance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Balance_By_PkArgs = {
  account_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Account_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Agreement_RegistryArgs = {
  where: Agreement_Registry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Agreement_Registry_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Agreement_TenantArgs = {
  where: Agreement_Tenant_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Agreement_Tenant_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Application_Lifecycle_StageArgs = {
  where: Application_Lifecycle_Stage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Application_Lifecycle_Stage_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Banking_Partner_IntegrationArgs = {
  where: Banking_Partner_Integration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BidArgs = {
  where: Bid_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bid_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Blacklisted_TokensArgs = {
  where: Blacklisted_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Blacklisted_Tokens_By_PkArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CompanyArgs = {
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Application_Crime_DetailsArgs = {
  where: Company_Application_Crime_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Application_Crime_Details_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_Application_EpfoArgs = {
  where: Company_Application_Epfo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Application_Epfo_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_Authorized_SignatoryArgs = {
  where: Company_Authorized_Signatory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Authorized_Signatory_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_CinArgs = {
  where: Company_Cin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Cin_By_PkArgs = {
  cin: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Company_Email_LogArgs = {
  where: Company_Email_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Email_Log_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_Gst_ReturnArgs = {
  where: Company_Gst_Return_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Gst_Return_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_IntegrationArgs = {
  where: Company_Integration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Integration_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_Integration_FileArgs = {
  where: Company_Integration_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Integration_File_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_Lead_StatusArgs = {
  where: Company_Lead_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Lead_Status_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_ReportsArgs = {
  where: Company_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Reports_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_ScoreArgs = {
  where: Company_Score_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Score_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_Score_FactorArgs = {
  where: Company_Score_Factor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Score_Factor_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_Score_Time_SeriesArgs = {
  where: Company_Score_Time_Series_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Score_Time_Series_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_StatementArgs = {
  where: Company_Statement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Statement_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_TanArgs = {
  where: Company_Tan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Tan_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_Type_Factor_BenchmarkArgs = {
  where: Company_Type_Factor_Benchmark_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Type_Factor_Benchmark_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Company_UserArgs = {
  where: Company_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_User_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_DealArgs = {
  where: Deal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deal_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Document_ChecklistArgs = {
  where: Document_Checklist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Document_Checklist_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Document_Checklist_CategoryArgs = {
  where: Document_Checklist_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Document_Checklist_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Email_EventsArgs = {
  where: Email_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Events_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Emi_TdsArgs = {
  where: Emi_Tds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Emi_Tds_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Factor_Benchmark_RangeArgs = {
  where: Factor_Benchmark_Range_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Factor_Benchmark_Range_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_File_RegistryArgs = {
  where: File_Registry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_File_Registry_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Integration_AuthArgs = {
  where: Integration_Auth_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Integration_Auth_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Internal_StatementArgs = {
  where: Internal_Statement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Internal_Statement_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_InvestmentArgs = {
  where: Investment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Investment_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_InvestorArgs = {
  where: Investor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Investor_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Investor_PreferenceArgs = {
  where: Investor_Preference_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Investor_Preference_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Investor_StatementArgs = {
  where: Investor_Statement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Investor_Statement_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Investor_TransactionArgs = {
  where: Investor_Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Investor_Transaction_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Investor_UserArgs = {
  where: Investor_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Investor_User_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lifecycle_Question_Crm_MapperArgs = {
  where: Lifecycle_Question_Crm_Mapper_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lifecycle_Question_Crm_Mapper_By_PkArgs = {
  serial_number: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lifecycle_Question_ResultArgs = {
  where: Lifecycle_Question_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lifecycle_Question_Result_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MandateArgs = {
  where: Mandate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mandate_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Popular_BanksArgs = {
  where: Popular_Banks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Popular_Banks_By_PkArgs = {
  bank_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Account_StatusArgs = {
  where: Ref_Account_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Account_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Account_TypeArgs = {
  where: Ref_Account_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Account_Type_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Agreement_CategoryArgs = {
  where: Ref_Agreement_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Agreement_Category_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Agreement_StatusArgs = {
  where: Ref_Agreement_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Agreement_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Application_Allowed_DistrictArgs = {
  where: Ref_Application_Allowed_District_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Application_Allowed_District_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Application_Lifecycle_StageArgs = {
  where: Ref_Application_Lifecycle_Stage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Application_Lifecycle_Stage_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_BankArgs = {
  where: Ref_Bank_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Bank_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Bank_ListArgs = {
  where: Ref_Bank_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Bank_List_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_BidArgs = {
  where: Ref_Bid_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Bid_By_PkArgs = {
  price: Scalars['numeric'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Billing_PeriodArgs = {
  where: Ref_Billing_Period_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Billing_Period_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_CategoryArgs = {
  where: Ref_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Category_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Collection_ModeArgs = {
  where: Ref_Collection_Mode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Collection_Mode_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Company_Lead_StatusArgs = {
  where: Ref_Company_Lead_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Company_Lead_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Company_LikelinessArgs = {
  where: Ref_Company_Likeliness_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Company_Likeliness_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Company_StatusArgs = {
  where: Ref_Company_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Company_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Company_TypeArgs = {
  where: Ref_Company_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Company_Type_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_CountryArgs = {
  where: Ref_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Country_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_CurrencyArgs = {
  where: Ref_Currency_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Currency_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Debit_ModeArgs = {
  where: Ref_Debit_Mode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Debit_Mode_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Document_ChecklistArgs = {
  where: Ref_Document_Checklist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Document_Checklist_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Document_Checklist_CategoryArgs = {
  where: Ref_Document_Checklist_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Document_Checklist_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Document_Checklist_IntegrationArgs = {
  where: Ref_Document_Checklist_Integration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Document_Checklist_Integration_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Document_Checklist_StatusArgs = {
  where: Ref_Document_Checklist_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Document_Checklist_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Email_Events_TypeArgs = {
  where: Ref_Email_Events_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Email_Events_Type_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_File_CategoryArgs = {
  where: Ref_File_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_File_Category_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_File_Category_MimetypeArgs = {
  where: Ref_File_Category_Mimetype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_File_Category_Mimetype_By_PkArgs = {
  id: Ref_File_Category_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Ref_IndustryArgs = {
  where: Ref_Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Industry_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Integration_File_StatusArgs = {
  where: Ref_Integration_File_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Integration_File_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Integration_StatusArgs = {
  where: Ref_Integration_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Integration_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Investor_StatusArgs = {
  where: Ref_Investor_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Investor_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Investor_TypeArgs = {
  where: Ref_Investor_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Investor_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Lifecycle_QuestionArgs = {
  where: Ref_Lifecycle_Question_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Lifecycle_Question_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Lifecycle_Question_CategoryArgs = {
  where: Ref_Lifecycle_Question_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Lifecycle_Question_Category_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Mandate_FrequencyArgs = {
  where: Ref_Mandate_Frequency_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Mandate_Frequency_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Mandate_StatusArgs = {
  where: Ref_Mandate_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Mandate_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Mandate_TypeArgs = {
  where: Ref_Mandate_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Mandate_Type_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Perfios_Bank_ListArgs = {
  where: Ref_Perfios_Bank_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Perfios_Bank_List_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Report_CategoryArgs = {
  where: Ref_Report_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Report_Category_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_RoleArgs = {
  where: Ref_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Role_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Schedule_Checklist_Reminder_StatusArgs = {
  where: Ref_Schedule_Checklist_Reminder_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Schedule_Checklist_Reminder_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Score_FactorArgs = {
  where: Ref_Score_Factor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Score_Factor_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Score_Factor_CategoryArgs = {
  where: Ref_Score_Factor_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Score_Factor_Category_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Score_Factor_UnitArgs = {
  where: Ref_Score_Factor_Unit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Score_Factor_Unit_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_SourceArgs = {
  where: Ref_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Source_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_StatesArgs = {
  where: Ref_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_States_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Subscription_StatusArgs = {
  where: Ref_Subscription_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Subscription_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_System_AccountArgs = {
  where: Ref_System_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_System_Account_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_System_Account_StatusArgs = {
  where: Ref_System_Account_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_System_Account_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_System_Account_TypeArgs = {
  where: Ref_System_Account_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_System_Account_Type_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Tds_StatusArgs = {
  where: Ref_Tds_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Tds_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Tds_Value_UnitArgs = {
  where: Ref_Tds_Value_Unit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Tds_Value_Unit_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Tenant_TypeArgs = {
  where: Ref_Tenant_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Tenant_Type_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Trade_StateArgs = {
  where: Ref_Trade_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Trade_State_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Trade_StatusArgs = {
  where: Ref_Trade_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Trade_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Trade_Withdrawal_ModeArgs = {
  where: Ref_Trade_Withdrawal_Mode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Trade_Withdrawal_Mode_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Transaction_MethodArgs = {
  where: Ref_Transaction_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Transaction_Method_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Transaction_StatusArgs = {
  where: Ref_Transaction_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Transaction_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Transaction_TypeArgs = {
  where: Ref_Transaction_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Transaction_Type_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Transfer_StatusArgs = {
  where: Ref_Transfer_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Transfer_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_Transfer_TypeArgs = {
  where: Ref_Transfer_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_Transfer_Type_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_User_StatusArgs = {
  where: Ref_User_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_User_Status_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ref_User_TypeArgs = {
  where: Ref_User_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ref_User_Type_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Report_CategoryArgs = {
  where: Report_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Report_Category_By_PkArgs = {
  ref_report_category_id: Ref_Report_Category_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Report_EventArgs = {
  where: Report_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Report_Event_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Schedule_Document_Checklist_ReminderArgs = {
  where: Schedule_Document_Checklist_Reminder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Schedule_Document_Checklist_Reminder_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Scheduled_EmiArgs = {
  where: Scheduled_Emi_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Scheduled_Emi_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Score_FactorArgs = {
  where: Score_Factor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Score_Factor_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Staff_UserArgs = {
  where: Staff_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Staff_User_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SubscriptionArgs = {
  where: Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscription_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TdsArgs = {
  where: Tds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tds_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TradeArgs = {
  where: Trade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Trade_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Trade_SubscriptionArgs = {
  where: Trade_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Trade_Subscription_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TransactionArgs = {
  where: Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transaction_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TransferArgs = {
  where: Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transfer_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Updates_LogArgs = {
  where: Updates_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Updates_Log_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Verification_CodeArgs = {
  where: Verification_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Verification_Code_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Webhook_EventsArgs = {
  where: Webhook_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Webhook_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDownloadCiFileArgs = {
  args: DownloadCiFileInput;
};


/** mutation root */
export type Mutation_RootExchangeSaveTokenArgs = {
  args: ExchangeSaveTokenInput;
};


/** mutation root */
export type Mutation_RootFetchCrimeDetailsArgs = {
  args: FetchCrimeDetailsInput;
};


/** mutation root */
export type Mutation_RootFetchEpfoDetailsArgs = {
  args: FetchEpfoDetailInput;
};


/** mutation root */
export type Mutation_RootFetchGstReturnFilingArgs = {
  args: FetchGstReturnFilingInput;
};


/** mutation root */
export type Mutation_RootFetchHistoricalAccountingDataArgs = {
  args: FetchHistoricalAccountingDataInput;
};


/** mutation root */
export type Mutation_RootFetchMcaProfileArgs = {
  args: FetchMcaProfileInput;
};


/** mutation root */
export type Mutation_RootFetchTanTransactionArgs = {
  args: FetchTanTransactionInput;
};


/** mutation root */
export type Mutation_RootFileRegistryUpdatePasswordsArgs = {
  args?: InputMaybe<Array<FileRegistryUpdatePasswordsInput>>;
};


/** mutation root */
export type Mutation_RootGenerateApaArgs = {
  args: GenerateApaInput;
};


/** mutation root */
export type Mutation_RootGenerateFileRegistryUploadUrlArgs = {
  args: GenerateFileRegistryUploadUrlInput;
};


/** mutation root */
export type Mutation_RootGenerateTradeEmiScheduleArgs = {
  args: GenerateTradeEmiScheduleInput;
};


/** mutation root */
export type Mutation_RootInitiateTradeArgs = {
  args: InitiateTradeInput;
};


/** mutation root */
export type Mutation_RootInsert_AccountArgs = {
  objects: Array<Account_Insert_Input>;
  on_conflict?: InputMaybe<Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_BalanceArgs = {
  objects: Array<Account_Balance_Insert_Input>;
  on_conflict?: InputMaybe<Account_Balance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Balance_OneArgs = {
  object: Account_Balance_Insert_Input;
  on_conflict?: InputMaybe<Account_Balance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_OneArgs = {
  object: Account_Insert_Input;
  on_conflict?: InputMaybe<Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agreement_RegistryArgs = {
  objects: Array<Agreement_Registry_Insert_Input>;
  on_conflict?: InputMaybe<Agreement_Registry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agreement_Registry_OneArgs = {
  object: Agreement_Registry_Insert_Input;
  on_conflict?: InputMaybe<Agreement_Registry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agreement_TenantArgs = {
  objects: Array<Agreement_Tenant_Insert_Input>;
  on_conflict?: InputMaybe<Agreement_Tenant_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agreement_Tenant_OneArgs = {
  object: Agreement_Tenant_Insert_Input;
  on_conflict?: InputMaybe<Agreement_Tenant_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Application_Lifecycle_StageArgs = {
  objects: Array<Application_Lifecycle_Stage_Insert_Input>;
  on_conflict?: InputMaybe<Application_Lifecycle_Stage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Application_Lifecycle_Stage_OneArgs = {
  object: Application_Lifecycle_Stage_Insert_Input;
  on_conflict?: InputMaybe<Application_Lifecycle_Stage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Banking_Partner_IntegrationArgs = {
  objects: Array<Banking_Partner_Integration_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Banking_Partner_Integration_OneArgs = {
  object: Banking_Partner_Integration_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BidArgs = {
  objects: Array<Bid_Insert_Input>;
  on_conflict?: InputMaybe<Bid_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bid_OneArgs = {
  object: Bid_Insert_Input;
  on_conflict?: InputMaybe<Bid_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blacklisted_TokensArgs = {
  objects: Array<Blacklisted_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Blacklisted_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blacklisted_Tokens_OneArgs = {
  object: Blacklisted_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Blacklisted_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompanyArgs = {
  objects: Array<Company_Insert_Input>;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Application_Crime_DetailsArgs = {
  objects: Array<Company_Application_Crime_Details_Insert_Input>;
  on_conflict?: InputMaybe<Company_Application_Crime_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Application_Crime_Details_OneArgs = {
  object: Company_Application_Crime_Details_Insert_Input;
  on_conflict?: InputMaybe<Company_Application_Crime_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Application_EpfoArgs = {
  objects: Array<Company_Application_Epfo_Insert_Input>;
  on_conflict?: InputMaybe<Company_Application_Epfo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Application_Epfo_OneArgs = {
  object: Company_Application_Epfo_Insert_Input;
  on_conflict?: InputMaybe<Company_Application_Epfo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Authorized_SignatoryArgs = {
  objects: Array<Company_Authorized_Signatory_Insert_Input>;
  on_conflict?: InputMaybe<Company_Authorized_Signatory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Authorized_Signatory_OneArgs = {
  object: Company_Authorized_Signatory_Insert_Input;
  on_conflict?: InputMaybe<Company_Authorized_Signatory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_CinArgs = {
  objects: Array<Company_Cin_Insert_Input>;
  on_conflict?: InputMaybe<Company_Cin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Cin_OneArgs = {
  object: Company_Cin_Insert_Input;
  on_conflict?: InputMaybe<Company_Cin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Email_LogArgs = {
  objects: Array<Company_Email_Log_Insert_Input>;
  on_conflict?: InputMaybe<Company_Email_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Email_Log_OneArgs = {
  object: Company_Email_Log_Insert_Input;
  on_conflict?: InputMaybe<Company_Email_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Gst_ReturnArgs = {
  objects: Array<Company_Gst_Return_Insert_Input>;
  on_conflict?: InputMaybe<Company_Gst_Return_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Gst_Return_OneArgs = {
  object: Company_Gst_Return_Insert_Input;
  on_conflict?: InputMaybe<Company_Gst_Return_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_IntegrationArgs = {
  objects: Array<Company_Integration_Insert_Input>;
  on_conflict?: InputMaybe<Company_Integration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Integration_FileArgs = {
  objects: Array<Company_Integration_File_Insert_Input>;
  on_conflict?: InputMaybe<Company_Integration_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Integration_File_OneArgs = {
  object: Company_Integration_File_Insert_Input;
  on_conflict?: InputMaybe<Company_Integration_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Integration_OneArgs = {
  object: Company_Integration_Insert_Input;
  on_conflict?: InputMaybe<Company_Integration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Lead_StatusArgs = {
  objects: Array<Company_Lead_Status_Insert_Input>;
  on_conflict?: InputMaybe<Company_Lead_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Lead_Status_OneArgs = {
  object: Company_Lead_Status_Insert_Input;
  on_conflict?: InputMaybe<Company_Lead_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_OneArgs = {
  object: Company_Insert_Input;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_ReportsArgs = {
  objects: Array<Company_Reports_Insert_Input>;
  on_conflict?: InputMaybe<Company_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Reports_OneArgs = {
  object: Company_Reports_Insert_Input;
  on_conflict?: InputMaybe<Company_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_ScoreArgs = {
  objects: Array<Company_Score_Insert_Input>;
  on_conflict?: InputMaybe<Company_Score_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Score_FactorArgs = {
  objects: Array<Company_Score_Factor_Insert_Input>;
  on_conflict?: InputMaybe<Company_Score_Factor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Score_Factor_OneArgs = {
  object: Company_Score_Factor_Insert_Input;
  on_conflict?: InputMaybe<Company_Score_Factor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Score_OneArgs = {
  object: Company_Score_Insert_Input;
  on_conflict?: InputMaybe<Company_Score_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Score_Time_SeriesArgs = {
  objects: Array<Company_Score_Time_Series_Insert_Input>;
  on_conflict?: InputMaybe<Company_Score_Time_Series_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Score_Time_Series_OneArgs = {
  object: Company_Score_Time_Series_Insert_Input;
  on_conflict?: InputMaybe<Company_Score_Time_Series_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_StatementArgs = {
  objects: Array<Company_Statement_Insert_Input>;
  on_conflict?: InputMaybe<Company_Statement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Statement_OneArgs = {
  object: Company_Statement_Insert_Input;
  on_conflict?: InputMaybe<Company_Statement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_TanArgs = {
  objects: Array<Company_Tan_Insert_Input>;
  on_conflict?: InputMaybe<Company_Tan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Tan_OneArgs = {
  object: Company_Tan_Insert_Input;
  on_conflict?: InputMaybe<Company_Tan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Type_Factor_BenchmarkArgs = {
  objects: Array<Company_Type_Factor_Benchmark_Insert_Input>;
  on_conflict?: InputMaybe<Company_Type_Factor_Benchmark_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Type_Factor_Benchmark_OneArgs = {
  object: Company_Type_Factor_Benchmark_Insert_Input;
  on_conflict?: InputMaybe<Company_Type_Factor_Benchmark_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_UserArgs = {
  objects: Array<Company_User_Insert_Input>;
  on_conflict?: InputMaybe<Company_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_User_OneArgs = {
  object: Company_User_Insert_Input;
  on_conflict?: InputMaybe<Company_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DealArgs = {
  objects: Array<Deal_Insert_Input>;
  on_conflict?: InputMaybe<Deal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deal_OneArgs = {
  object: Deal_Insert_Input;
  on_conflict?: InputMaybe<Deal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Document_ChecklistArgs = {
  objects: Array<Document_Checklist_Insert_Input>;
  on_conflict?: InputMaybe<Document_Checklist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Document_Checklist_CategoryArgs = {
  objects: Array<Document_Checklist_Category_Insert_Input>;
  on_conflict?: InputMaybe<Document_Checklist_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Document_Checklist_Category_OneArgs = {
  object: Document_Checklist_Category_Insert_Input;
  on_conflict?: InputMaybe<Document_Checklist_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Document_Checklist_OneArgs = {
  object: Document_Checklist_Insert_Input;
  on_conflict?: InputMaybe<Document_Checklist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_EventsArgs = {
  objects: Array<Email_Events_Insert_Input>;
  on_conflict?: InputMaybe<Email_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Events_OneArgs = {
  object: Email_Events_Insert_Input;
  on_conflict?: InputMaybe<Email_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Emi_TdsArgs = {
  objects: Array<Emi_Tds_Insert_Input>;
  on_conflict?: InputMaybe<Emi_Tds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Emi_Tds_OneArgs = {
  object: Emi_Tds_Insert_Input;
  on_conflict?: InputMaybe<Emi_Tds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Factor_Benchmark_RangeArgs = {
  objects: Array<Factor_Benchmark_Range_Insert_Input>;
  on_conflict?: InputMaybe<Factor_Benchmark_Range_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Factor_Benchmark_Range_OneArgs = {
  object: Factor_Benchmark_Range_Insert_Input;
  on_conflict?: InputMaybe<Factor_Benchmark_Range_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_RegistryArgs = {
  objects: Array<File_Registry_Insert_Input>;
  on_conflict?: InputMaybe<File_Registry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_Registry_OneArgs = {
  object: File_Registry_Insert_Input;
  on_conflict?: InputMaybe<File_Registry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_AuthArgs = {
  objects: Array<Integration_Auth_Insert_Input>;
  on_conflict?: InputMaybe<Integration_Auth_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Auth_OneArgs = {
  object: Integration_Auth_Insert_Input;
  on_conflict?: InputMaybe<Integration_Auth_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Internal_StatementArgs = {
  objects: Array<Internal_Statement_Insert_Input>;
  on_conflict?: InputMaybe<Internal_Statement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Internal_Statement_OneArgs = {
  object: Internal_Statement_Insert_Input;
  on_conflict?: InputMaybe<Internal_Statement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvestmentArgs = {
  objects: Array<Investment_Insert_Input>;
  on_conflict?: InputMaybe<Investment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Investment_OneArgs = {
  object: Investment_Insert_Input;
  on_conflict?: InputMaybe<Investment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvestorArgs = {
  objects: Array<Investor_Insert_Input>;
  on_conflict?: InputMaybe<Investor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Investor_OneArgs = {
  object: Investor_Insert_Input;
  on_conflict?: InputMaybe<Investor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Investor_PreferenceArgs = {
  objects: Array<Investor_Preference_Insert_Input>;
  on_conflict?: InputMaybe<Investor_Preference_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Investor_Preference_OneArgs = {
  object: Investor_Preference_Insert_Input;
  on_conflict?: InputMaybe<Investor_Preference_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Investor_StatementArgs = {
  objects: Array<Investor_Statement_Insert_Input>;
  on_conflict?: InputMaybe<Investor_Statement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Investor_Statement_OneArgs = {
  object: Investor_Statement_Insert_Input;
  on_conflict?: InputMaybe<Investor_Statement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Investor_TransactionArgs = {
  objects: Array<Investor_Transaction_Insert_Input>;
  on_conflict?: InputMaybe<Investor_Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Investor_Transaction_OneArgs = {
  object: Investor_Transaction_Insert_Input;
  on_conflict?: InputMaybe<Investor_Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Investor_UserArgs = {
  objects: Array<Investor_User_Insert_Input>;
  on_conflict?: InputMaybe<Investor_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Investor_User_OneArgs = {
  object: Investor_User_Insert_Input;
  on_conflict?: InputMaybe<Investor_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lifecycle_Question_Crm_MapperArgs = {
  objects: Array<Lifecycle_Question_Crm_Mapper_Insert_Input>;
  on_conflict?: InputMaybe<Lifecycle_Question_Crm_Mapper_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lifecycle_Question_Crm_Mapper_OneArgs = {
  object: Lifecycle_Question_Crm_Mapper_Insert_Input;
  on_conflict?: InputMaybe<Lifecycle_Question_Crm_Mapper_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lifecycle_Question_ResultArgs = {
  objects: Array<Lifecycle_Question_Result_Insert_Input>;
  on_conflict?: InputMaybe<Lifecycle_Question_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lifecycle_Question_Result_OneArgs = {
  object: Lifecycle_Question_Result_Insert_Input;
  on_conflict?: InputMaybe<Lifecycle_Question_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MandateArgs = {
  objects: Array<Mandate_Insert_Input>;
  on_conflict?: InputMaybe<Mandate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mandate_OneArgs = {
  object: Mandate_Insert_Input;
  on_conflict?: InputMaybe<Mandate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Popular_BanksArgs = {
  objects: Array<Popular_Banks_Insert_Input>;
  on_conflict?: InputMaybe<Popular_Banks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Popular_Banks_OneArgs = {
  object: Popular_Banks_Insert_Input;
  on_conflict?: InputMaybe<Popular_Banks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Account_StatusArgs = {
  objects: Array<Ref_Account_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Account_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Account_Status_OneArgs = {
  object: Ref_Account_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_Account_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Account_TypeArgs = {
  objects: Array<Ref_Account_Type_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Account_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Account_Type_OneArgs = {
  object: Ref_Account_Type_Insert_Input;
  on_conflict?: InputMaybe<Ref_Account_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Agreement_CategoryArgs = {
  objects: Array<Ref_Agreement_Category_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Agreement_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Agreement_Category_OneArgs = {
  object: Ref_Agreement_Category_Insert_Input;
  on_conflict?: InputMaybe<Ref_Agreement_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Agreement_StatusArgs = {
  objects: Array<Ref_Agreement_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Agreement_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Agreement_Status_OneArgs = {
  object: Ref_Agreement_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_Agreement_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Application_Allowed_DistrictArgs = {
  objects: Array<Ref_Application_Allowed_District_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Application_Allowed_District_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Application_Allowed_District_OneArgs = {
  object: Ref_Application_Allowed_District_Insert_Input;
  on_conflict?: InputMaybe<Ref_Application_Allowed_District_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Application_Lifecycle_StageArgs = {
  objects: Array<Ref_Application_Lifecycle_Stage_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Application_Lifecycle_Stage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Application_Lifecycle_Stage_OneArgs = {
  object: Ref_Application_Lifecycle_Stage_Insert_Input;
  on_conflict?: InputMaybe<Ref_Application_Lifecycle_Stage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_BankArgs = {
  objects: Array<Ref_Bank_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Bank_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Bank_ListArgs = {
  objects: Array<Ref_Bank_List_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Bank_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Bank_List_OneArgs = {
  object: Ref_Bank_List_Insert_Input;
  on_conflict?: InputMaybe<Ref_Bank_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Bank_OneArgs = {
  object: Ref_Bank_Insert_Input;
  on_conflict?: InputMaybe<Ref_Bank_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_BidArgs = {
  objects: Array<Ref_Bid_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Bid_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Bid_OneArgs = {
  object: Ref_Bid_Insert_Input;
  on_conflict?: InputMaybe<Ref_Bid_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Billing_PeriodArgs = {
  objects: Array<Ref_Billing_Period_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Billing_Period_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Billing_Period_OneArgs = {
  object: Ref_Billing_Period_Insert_Input;
  on_conflict?: InputMaybe<Ref_Billing_Period_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_CategoryArgs = {
  objects: Array<Ref_Category_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Category_OneArgs = {
  object: Ref_Category_Insert_Input;
  on_conflict?: InputMaybe<Ref_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Collection_ModeArgs = {
  objects: Array<Ref_Collection_Mode_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Collection_Mode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Collection_Mode_OneArgs = {
  object: Ref_Collection_Mode_Insert_Input;
  on_conflict?: InputMaybe<Ref_Collection_Mode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Company_Lead_StatusArgs = {
  objects: Array<Ref_Company_Lead_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Company_Lead_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Company_Lead_Status_OneArgs = {
  object: Ref_Company_Lead_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_Company_Lead_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Company_LikelinessArgs = {
  objects: Array<Ref_Company_Likeliness_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Company_Likeliness_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Company_Likeliness_OneArgs = {
  object: Ref_Company_Likeliness_Insert_Input;
  on_conflict?: InputMaybe<Ref_Company_Likeliness_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Company_StatusArgs = {
  objects: Array<Ref_Company_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Company_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Company_Status_OneArgs = {
  object: Ref_Company_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_Company_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Company_TypeArgs = {
  objects: Array<Ref_Company_Type_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Company_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Company_Type_OneArgs = {
  object: Ref_Company_Type_Insert_Input;
  on_conflict?: InputMaybe<Ref_Company_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_CountryArgs = {
  objects: Array<Ref_Country_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Country_OneArgs = {
  object: Ref_Country_Insert_Input;
  on_conflict?: InputMaybe<Ref_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_CurrencyArgs = {
  objects: Array<Ref_Currency_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Currency_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Currency_OneArgs = {
  object: Ref_Currency_Insert_Input;
  on_conflict?: InputMaybe<Ref_Currency_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Debit_ModeArgs = {
  objects: Array<Ref_Debit_Mode_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Debit_Mode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Debit_Mode_OneArgs = {
  object: Ref_Debit_Mode_Insert_Input;
  on_conflict?: InputMaybe<Ref_Debit_Mode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Document_ChecklistArgs = {
  objects: Array<Ref_Document_Checklist_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Document_Checklist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Document_Checklist_CategoryArgs = {
  objects: Array<Ref_Document_Checklist_Category_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Document_Checklist_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Document_Checklist_Category_OneArgs = {
  object: Ref_Document_Checklist_Category_Insert_Input;
  on_conflict?: InputMaybe<Ref_Document_Checklist_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Document_Checklist_IntegrationArgs = {
  objects: Array<Ref_Document_Checklist_Integration_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Document_Checklist_Integration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Document_Checklist_Integration_OneArgs = {
  object: Ref_Document_Checklist_Integration_Insert_Input;
  on_conflict?: InputMaybe<Ref_Document_Checklist_Integration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Document_Checklist_OneArgs = {
  object: Ref_Document_Checklist_Insert_Input;
  on_conflict?: InputMaybe<Ref_Document_Checklist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Document_Checklist_StatusArgs = {
  objects: Array<Ref_Document_Checklist_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Document_Checklist_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Document_Checklist_Status_OneArgs = {
  object: Ref_Document_Checklist_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_Document_Checklist_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Email_Events_TypeArgs = {
  objects: Array<Ref_Email_Events_Type_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Email_Events_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Email_Events_Type_OneArgs = {
  object: Ref_Email_Events_Type_Insert_Input;
  on_conflict?: InputMaybe<Ref_Email_Events_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_File_CategoryArgs = {
  objects: Array<Ref_File_Category_Insert_Input>;
  on_conflict?: InputMaybe<Ref_File_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_File_Category_MimetypeArgs = {
  objects: Array<Ref_File_Category_Mimetype_Insert_Input>;
  on_conflict?: InputMaybe<Ref_File_Category_Mimetype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_File_Category_Mimetype_OneArgs = {
  object: Ref_File_Category_Mimetype_Insert_Input;
  on_conflict?: InputMaybe<Ref_File_Category_Mimetype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_File_Category_OneArgs = {
  object: Ref_File_Category_Insert_Input;
  on_conflict?: InputMaybe<Ref_File_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_IndustryArgs = {
  objects: Array<Ref_Industry_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Industry_OneArgs = {
  object: Ref_Industry_Insert_Input;
  on_conflict?: InputMaybe<Ref_Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Integration_File_StatusArgs = {
  objects: Array<Ref_Integration_File_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Integration_File_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Integration_File_Status_OneArgs = {
  object: Ref_Integration_File_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_Integration_File_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Integration_StatusArgs = {
  objects: Array<Ref_Integration_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Integration_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Integration_Status_OneArgs = {
  object: Ref_Integration_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_Integration_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Investor_StatusArgs = {
  objects: Array<Ref_Investor_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Investor_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Investor_Status_OneArgs = {
  object: Ref_Investor_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_Investor_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Investor_TypeArgs = {
  objects: Array<Ref_Investor_Type_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Investor_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Investor_Type_OneArgs = {
  object: Ref_Investor_Type_Insert_Input;
  on_conflict?: InputMaybe<Ref_Investor_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Lifecycle_QuestionArgs = {
  objects: Array<Ref_Lifecycle_Question_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Lifecycle_Question_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Lifecycle_Question_CategoryArgs = {
  objects: Array<Ref_Lifecycle_Question_Category_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Lifecycle_Question_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Lifecycle_Question_Category_OneArgs = {
  object: Ref_Lifecycle_Question_Category_Insert_Input;
  on_conflict?: InputMaybe<Ref_Lifecycle_Question_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Lifecycle_Question_OneArgs = {
  object: Ref_Lifecycle_Question_Insert_Input;
  on_conflict?: InputMaybe<Ref_Lifecycle_Question_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Mandate_FrequencyArgs = {
  objects: Array<Ref_Mandate_Frequency_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Mandate_Frequency_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Mandate_Frequency_OneArgs = {
  object: Ref_Mandate_Frequency_Insert_Input;
  on_conflict?: InputMaybe<Ref_Mandate_Frequency_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Mandate_StatusArgs = {
  objects: Array<Ref_Mandate_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Mandate_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Mandate_Status_OneArgs = {
  object: Ref_Mandate_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_Mandate_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Mandate_TypeArgs = {
  objects: Array<Ref_Mandate_Type_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Mandate_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Mandate_Type_OneArgs = {
  object: Ref_Mandate_Type_Insert_Input;
  on_conflict?: InputMaybe<Ref_Mandate_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Perfios_Bank_ListArgs = {
  objects: Array<Ref_Perfios_Bank_List_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Perfios_Bank_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Perfios_Bank_List_OneArgs = {
  object: Ref_Perfios_Bank_List_Insert_Input;
  on_conflict?: InputMaybe<Ref_Perfios_Bank_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Report_CategoryArgs = {
  objects: Array<Ref_Report_Category_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Report_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Report_Category_OneArgs = {
  object: Ref_Report_Category_Insert_Input;
  on_conflict?: InputMaybe<Ref_Report_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_RoleArgs = {
  objects: Array<Ref_Role_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Role_OneArgs = {
  object: Ref_Role_Insert_Input;
  on_conflict?: InputMaybe<Ref_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Schedule_Checklist_Reminder_StatusArgs = {
  objects: Array<Ref_Schedule_Checklist_Reminder_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Schedule_Checklist_Reminder_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Schedule_Checklist_Reminder_Status_OneArgs = {
  object: Ref_Schedule_Checklist_Reminder_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_Schedule_Checklist_Reminder_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Score_FactorArgs = {
  objects: Array<Ref_Score_Factor_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Score_Factor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Score_Factor_CategoryArgs = {
  objects: Array<Ref_Score_Factor_Category_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Score_Factor_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Score_Factor_Category_OneArgs = {
  object: Ref_Score_Factor_Category_Insert_Input;
  on_conflict?: InputMaybe<Ref_Score_Factor_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Score_Factor_OneArgs = {
  object: Ref_Score_Factor_Insert_Input;
  on_conflict?: InputMaybe<Ref_Score_Factor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Score_Factor_UnitArgs = {
  objects: Array<Ref_Score_Factor_Unit_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Score_Factor_Unit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Score_Factor_Unit_OneArgs = {
  object: Ref_Score_Factor_Unit_Insert_Input;
  on_conflict?: InputMaybe<Ref_Score_Factor_Unit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_SourceArgs = {
  objects: Array<Ref_Source_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Source_OneArgs = {
  object: Ref_Source_Insert_Input;
  on_conflict?: InputMaybe<Ref_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_StatesArgs = {
  objects: Array<Ref_States_Insert_Input>;
  on_conflict?: InputMaybe<Ref_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_States_OneArgs = {
  object: Ref_States_Insert_Input;
  on_conflict?: InputMaybe<Ref_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Subscription_StatusArgs = {
  objects: Array<Ref_Subscription_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Subscription_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Subscription_Status_OneArgs = {
  object: Ref_Subscription_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_Subscription_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_System_AccountArgs = {
  objects: Array<Ref_System_Account_Insert_Input>;
  on_conflict?: InputMaybe<Ref_System_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_System_Account_OneArgs = {
  object: Ref_System_Account_Insert_Input;
  on_conflict?: InputMaybe<Ref_System_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_System_Account_StatusArgs = {
  objects: Array<Ref_System_Account_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_System_Account_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_System_Account_Status_OneArgs = {
  object: Ref_System_Account_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_System_Account_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_System_Account_TypeArgs = {
  objects: Array<Ref_System_Account_Type_Insert_Input>;
  on_conflict?: InputMaybe<Ref_System_Account_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_System_Account_Type_OneArgs = {
  object: Ref_System_Account_Type_Insert_Input;
  on_conflict?: InputMaybe<Ref_System_Account_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Tds_StatusArgs = {
  objects: Array<Ref_Tds_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Tds_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Tds_Status_OneArgs = {
  object: Ref_Tds_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_Tds_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Tds_Value_UnitArgs = {
  objects: Array<Ref_Tds_Value_Unit_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Tds_Value_Unit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Tds_Value_Unit_OneArgs = {
  object: Ref_Tds_Value_Unit_Insert_Input;
  on_conflict?: InputMaybe<Ref_Tds_Value_Unit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Tenant_TypeArgs = {
  objects: Array<Ref_Tenant_Type_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Tenant_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Tenant_Type_OneArgs = {
  object: Ref_Tenant_Type_Insert_Input;
  on_conflict?: InputMaybe<Ref_Tenant_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Trade_StateArgs = {
  objects: Array<Ref_Trade_State_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Trade_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Trade_State_OneArgs = {
  object: Ref_Trade_State_Insert_Input;
  on_conflict?: InputMaybe<Ref_Trade_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Trade_StatusArgs = {
  objects: Array<Ref_Trade_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Trade_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Trade_Status_OneArgs = {
  object: Ref_Trade_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_Trade_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Trade_Withdrawal_ModeArgs = {
  objects: Array<Ref_Trade_Withdrawal_Mode_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Trade_Withdrawal_Mode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Trade_Withdrawal_Mode_OneArgs = {
  object: Ref_Trade_Withdrawal_Mode_Insert_Input;
  on_conflict?: InputMaybe<Ref_Trade_Withdrawal_Mode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Transaction_MethodArgs = {
  objects: Array<Ref_Transaction_Method_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Transaction_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Transaction_Method_OneArgs = {
  object: Ref_Transaction_Method_Insert_Input;
  on_conflict?: InputMaybe<Ref_Transaction_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Transaction_StatusArgs = {
  objects: Array<Ref_Transaction_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Transaction_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Transaction_Status_OneArgs = {
  object: Ref_Transaction_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_Transaction_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Transaction_TypeArgs = {
  objects: Array<Ref_Transaction_Type_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Transaction_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Transaction_Type_OneArgs = {
  object: Ref_Transaction_Type_Insert_Input;
  on_conflict?: InputMaybe<Ref_Transaction_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Transfer_StatusArgs = {
  objects: Array<Ref_Transfer_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Transfer_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Transfer_Status_OneArgs = {
  object: Ref_Transfer_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_Transfer_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Transfer_TypeArgs = {
  objects: Array<Ref_Transfer_Type_Insert_Input>;
  on_conflict?: InputMaybe<Ref_Transfer_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_Transfer_Type_OneArgs = {
  object: Ref_Transfer_Type_Insert_Input;
  on_conflict?: InputMaybe<Ref_Transfer_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_User_StatusArgs = {
  objects: Array<Ref_User_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ref_User_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_User_Status_OneArgs = {
  object: Ref_User_Status_Insert_Input;
  on_conflict?: InputMaybe<Ref_User_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_User_TypeArgs = {
  objects: Array<Ref_User_Type_Insert_Input>;
  on_conflict?: InputMaybe<Ref_User_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ref_User_Type_OneArgs = {
  object: Ref_User_Type_Insert_Input;
  on_conflict?: InputMaybe<Ref_User_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Report_CategoryArgs = {
  objects: Array<Report_Category_Insert_Input>;
  on_conflict?: InputMaybe<Report_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Report_Category_OneArgs = {
  object: Report_Category_Insert_Input;
  on_conflict?: InputMaybe<Report_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Report_EventArgs = {
  objects: Array<Report_Event_Insert_Input>;
  on_conflict?: InputMaybe<Report_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Report_Event_OneArgs = {
  object: Report_Event_Insert_Input;
  on_conflict?: InputMaybe<Report_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Schedule_Document_Checklist_ReminderArgs = {
  objects: Array<Schedule_Document_Checklist_Reminder_Insert_Input>;
  on_conflict?: InputMaybe<Schedule_Document_Checklist_Reminder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Schedule_Document_Checklist_Reminder_OneArgs = {
  object: Schedule_Document_Checklist_Reminder_Insert_Input;
  on_conflict?: InputMaybe<Schedule_Document_Checklist_Reminder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scheduled_EmiArgs = {
  objects: Array<Scheduled_Emi_Insert_Input>;
  on_conflict?: InputMaybe<Scheduled_Emi_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scheduled_Emi_OneArgs = {
  object: Scheduled_Emi_Insert_Input;
  on_conflict?: InputMaybe<Scheduled_Emi_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Score_FactorArgs = {
  objects: Array<Score_Factor_Insert_Input>;
  on_conflict?: InputMaybe<Score_Factor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Score_Factor_OneArgs = {
  object: Score_Factor_Insert_Input;
  on_conflict?: InputMaybe<Score_Factor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staff_UserArgs = {
  objects: Array<Staff_User_Insert_Input>;
  on_conflict?: InputMaybe<Staff_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staff_User_OneArgs = {
  object: Staff_User_Insert_Input;
  on_conflict?: InputMaybe<Staff_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubscriptionArgs = {
  objects: Array<Subscription_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_OneArgs = {
  object: Subscription_Insert_Input;
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TdsArgs = {
  objects: Array<Tds_Insert_Input>;
  on_conflict?: InputMaybe<Tds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tds_OneArgs = {
  object: Tds_Insert_Input;
  on_conflict?: InputMaybe<Tds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TradeArgs = {
  objects: Array<Trade_Insert_Input>;
  on_conflict?: InputMaybe<Trade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trade_OneArgs = {
  object: Trade_Insert_Input;
  on_conflict?: InputMaybe<Trade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trade_SubscriptionArgs = {
  objects: Array<Trade_Subscription_Insert_Input>;
  on_conflict?: InputMaybe<Trade_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trade_Subscription_OneArgs = {
  object: Trade_Subscription_Insert_Input;
  on_conflict?: InputMaybe<Trade_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransactionArgs = {
  objects: Array<Transaction_Insert_Input>;
  on_conflict?: InputMaybe<Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transaction_OneArgs = {
  object: Transaction_Insert_Input;
  on_conflict?: InputMaybe<Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransferArgs = {
  objects: Array<Transfer_Insert_Input>;
  on_conflict?: InputMaybe<Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transfer_OneArgs = {
  object: Transfer_Insert_Input;
  on_conflict?: InputMaybe<Transfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Updates_LogArgs = {
  objects: Array<Updates_Log_Insert_Input>;
  on_conflict?: InputMaybe<Updates_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Updates_Log_OneArgs = {
  object: Updates_Log_Insert_Input;
  on_conflict?: InputMaybe<Updates_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verification_CodeArgs = {
  objects: Array<Verification_Code_Insert_Input>;
  on_conflict?: InputMaybe<Verification_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verification_Code_OneArgs = {
  object: Verification_Code_Insert_Input;
  on_conflict?: InputMaybe<Verification_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webhook_EventsArgs = {
  objects: Array<Webhook_Events_Insert_Input>;
  on_conflict?: InputMaybe<Webhook_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webhook_Events_OneArgs = {
  object: Webhook_Events_Insert_Input;
  on_conflict?: InputMaybe<Webhook_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootIntegrationsApproveArgs = {
  args: IntegrationsApproveInput;
};


/** mutation root */
export type Mutation_RootIntegrationsUploadArgs = {
  args: IntegrationsUploadInput;
};


/** mutation root */
export type Mutation_RootNetBankingPullArgs = {
  args: NetBankingPullInput;
};


/** mutation root */
export type Mutation_RootOpsAccountSetupNotifyArgs = {
  args: OpsAccountSetupNotifyInput;
};


/** mutation root */
export type Mutation_RootPlaceBidArgs = {
  args: PlaceBidInput;
};


/** mutation root */
export type Mutation_RootPresentEscrowEmiManualArgs = {
  args?: InputMaybe<PresentEscrowEmiManualInput>;
};


/** mutation root */
export type Mutation_RootPresentMandateManualArgs = {
  args?: InputMaybe<PresentMandateManualInput>;
};


/** mutation root */
export type Mutation_RootPreviewApaArgs = {
  args: PreviewApaInput;
};


/** mutation root */
export type Mutation_RootReQueueCompanyIntegrationArgs = {
  arg1: ReQueueCompanyIntegrationInput;
};


/** mutation root */
export type Mutation_RootRefreshTokensArgs = {
  args: RefreshTokensInput;
};


/** mutation root */
export type Mutation_RootRegisterNachMandateArgs = {
  args: RegisterNachMandateInput;
};


/** mutation root */
export type Mutation_RootRunScoringEngineArgs = {
  args: RunScoringEngineInput;
};


/** mutation root */
export type Mutation_RootSubmitSignedNachMandateArgs = {
  args: SubmitSignedNachMandateInput;
};


/** mutation root */
export type Mutation_RootSubscriptionCsvUploadArgs = {
  args: SubscriptionCsvUploadInput;
};


/** mutation root */
export type Mutation_RootSubscriptionSyncArgs = {
  args: SubscriptionSyncInput;
};


/** mutation root */
export type Mutation_RootUpdateCompanyStatusArgs = {
  args: UpdateCompanyStatusInput;
};


/** mutation root */
export type Mutation_RootUpdateNetbankingLinkageArgs = {
  args: UpdateNetbankingLinkageInput;
};


/** mutation root */
export type Mutation_RootUpdateScheduledEmiArgs = {
  arg: UpdateScheduledEmiInput;
};


/** mutation root */
export type Mutation_RootUpdateTradeAmountsArgs = {
  args: UpdateTradeAmountsInput;
};


/** mutation root */
export type Mutation_RootUpdate_AccountArgs = {
  _append?: InputMaybe<Account_Append_Input>;
  _delete_at_path?: InputMaybe<Account_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Account_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Account_Delete_Key_Input>;
  _inc?: InputMaybe<Account_Inc_Input>;
  _prepend?: InputMaybe<Account_Prepend_Input>;
  _set?: InputMaybe<Account_Set_Input>;
  where: Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_BalanceArgs = {
  _inc?: InputMaybe<Account_Balance_Inc_Input>;
  _set?: InputMaybe<Account_Balance_Set_Input>;
  where: Account_Balance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Balance_By_PkArgs = {
  _inc?: InputMaybe<Account_Balance_Inc_Input>;
  _set?: InputMaybe<Account_Balance_Set_Input>;
  pk_columns: Account_Balance_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Balance_ManyArgs = {
  updates: Array<Account_Balance_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Account_By_PkArgs = {
  _append?: InputMaybe<Account_Append_Input>;
  _delete_at_path?: InputMaybe<Account_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Account_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Account_Delete_Key_Input>;
  _inc?: InputMaybe<Account_Inc_Input>;
  _prepend?: InputMaybe<Account_Prepend_Input>;
  _set?: InputMaybe<Account_Set_Input>;
  pk_columns: Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_ManyArgs = {
  updates: Array<Account_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Agreement_RegistryArgs = {
  _append?: InputMaybe<Agreement_Registry_Append_Input>;
  _delete_at_path?: InputMaybe<Agreement_Registry_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Agreement_Registry_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Agreement_Registry_Delete_Key_Input>;
  _inc?: InputMaybe<Agreement_Registry_Inc_Input>;
  _prepend?: InputMaybe<Agreement_Registry_Prepend_Input>;
  _set?: InputMaybe<Agreement_Registry_Set_Input>;
  where: Agreement_Registry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Agreement_Registry_By_PkArgs = {
  _append?: InputMaybe<Agreement_Registry_Append_Input>;
  _delete_at_path?: InputMaybe<Agreement_Registry_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Agreement_Registry_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Agreement_Registry_Delete_Key_Input>;
  _inc?: InputMaybe<Agreement_Registry_Inc_Input>;
  _prepend?: InputMaybe<Agreement_Registry_Prepend_Input>;
  _set?: InputMaybe<Agreement_Registry_Set_Input>;
  pk_columns: Agreement_Registry_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Agreement_Registry_ManyArgs = {
  updates: Array<Agreement_Registry_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Agreement_TenantArgs = {
  _inc?: InputMaybe<Agreement_Tenant_Inc_Input>;
  _set?: InputMaybe<Agreement_Tenant_Set_Input>;
  where: Agreement_Tenant_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Agreement_Tenant_By_PkArgs = {
  _inc?: InputMaybe<Agreement_Tenant_Inc_Input>;
  _set?: InputMaybe<Agreement_Tenant_Set_Input>;
  pk_columns: Agreement_Tenant_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Agreement_Tenant_ManyArgs = {
  updates: Array<Agreement_Tenant_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Application_Lifecycle_StageArgs = {
  _inc?: InputMaybe<Application_Lifecycle_Stage_Inc_Input>;
  _set?: InputMaybe<Application_Lifecycle_Stage_Set_Input>;
  where: Application_Lifecycle_Stage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Application_Lifecycle_Stage_By_PkArgs = {
  _inc?: InputMaybe<Application_Lifecycle_Stage_Inc_Input>;
  _set?: InputMaybe<Application_Lifecycle_Stage_Set_Input>;
  pk_columns: Application_Lifecycle_Stage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Application_Lifecycle_Stage_ManyArgs = {
  updates: Array<Application_Lifecycle_Stage_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Banking_Partner_IntegrationArgs = {
  _inc?: InputMaybe<Banking_Partner_Integration_Inc_Input>;
  _set?: InputMaybe<Banking_Partner_Integration_Set_Input>;
  where: Banking_Partner_Integration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Banking_Partner_Integration_ManyArgs = {
  updates: Array<Banking_Partner_Integration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BidArgs = {
  _inc?: InputMaybe<Bid_Inc_Input>;
  _set?: InputMaybe<Bid_Set_Input>;
  where: Bid_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bid_By_PkArgs = {
  _inc?: InputMaybe<Bid_Inc_Input>;
  _set?: InputMaybe<Bid_Set_Input>;
  pk_columns: Bid_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bid_ManyArgs = {
  updates: Array<Bid_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Blacklisted_TokensArgs = {
  _set?: InputMaybe<Blacklisted_Tokens_Set_Input>;
  where: Blacklisted_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blacklisted_Tokens_By_PkArgs = {
  _set?: InputMaybe<Blacklisted_Tokens_Set_Input>;
  pk_columns: Blacklisted_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Blacklisted_Tokens_ManyArgs = {
  updates: Array<Blacklisted_Tokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyArgs = {
  _append?: InputMaybe<Company_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Inc_Input>;
  _prepend?: InputMaybe<Company_Prepend_Input>;
  _set?: InputMaybe<Company_Set_Input>;
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Application_Crime_DetailsArgs = {
  _append?: InputMaybe<Company_Application_Crime_Details_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Application_Crime_Details_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Application_Crime_Details_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Application_Crime_Details_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Application_Crime_Details_Inc_Input>;
  _prepend?: InputMaybe<Company_Application_Crime_Details_Prepend_Input>;
  _set?: InputMaybe<Company_Application_Crime_Details_Set_Input>;
  where: Company_Application_Crime_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Application_Crime_Details_By_PkArgs = {
  _append?: InputMaybe<Company_Application_Crime_Details_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Application_Crime_Details_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Application_Crime_Details_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Application_Crime_Details_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Application_Crime_Details_Inc_Input>;
  _prepend?: InputMaybe<Company_Application_Crime_Details_Prepend_Input>;
  _set?: InputMaybe<Company_Application_Crime_Details_Set_Input>;
  pk_columns: Company_Application_Crime_Details_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Application_Crime_Details_ManyArgs = {
  updates: Array<Company_Application_Crime_Details_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Application_EpfoArgs = {
  _append?: InputMaybe<Company_Application_Epfo_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Application_Epfo_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Application_Epfo_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Application_Epfo_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Application_Epfo_Inc_Input>;
  _prepend?: InputMaybe<Company_Application_Epfo_Prepend_Input>;
  _set?: InputMaybe<Company_Application_Epfo_Set_Input>;
  where: Company_Application_Epfo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Application_Epfo_By_PkArgs = {
  _append?: InputMaybe<Company_Application_Epfo_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Application_Epfo_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Application_Epfo_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Application_Epfo_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Application_Epfo_Inc_Input>;
  _prepend?: InputMaybe<Company_Application_Epfo_Prepend_Input>;
  _set?: InputMaybe<Company_Application_Epfo_Set_Input>;
  pk_columns: Company_Application_Epfo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Application_Epfo_ManyArgs = {
  updates: Array<Company_Application_Epfo_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Authorized_SignatoryArgs = {
  _inc?: InputMaybe<Company_Authorized_Signatory_Inc_Input>;
  _set?: InputMaybe<Company_Authorized_Signatory_Set_Input>;
  where: Company_Authorized_Signatory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Authorized_Signatory_By_PkArgs = {
  _inc?: InputMaybe<Company_Authorized_Signatory_Inc_Input>;
  _set?: InputMaybe<Company_Authorized_Signatory_Set_Input>;
  pk_columns: Company_Authorized_Signatory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Authorized_Signatory_ManyArgs = {
  updates: Array<Company_Authorized_Signatory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_By_PkArgs = {
  _append?: InputMaybe<Company_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Inc_Input>;
  _prepend?: InputMaybe<Company_Prepend_Input>;
  _set?: InputMaybe<Company_Set_Input>;
  pk_columns: Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_CinArgs = {
  _set?: InputMaybe<Company_Cin_Set_Input>;
  where: Company_Cin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Cin_By_PkArgs = {
  _set?: InputMaybe<Company_Cin_Set_Input>;
  pk_columns: Company_Cin_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Cin_ManyArgs = {
  updates: Array<Company_Cin_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Email_LogArgs = {
  _inc?: InputMaybe<Company_Email_Log_Inc_Input>;
  _set?: InputMaybe<Company_Email_Log_Set_Input>;
  where: Company_Email_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Email_Log_By_PkArgs = {
  _inc?: InputMaybe<Company_Email_Log_Inc_Input>;
  _set?: InputMaybe<Company_Email_Log_Set_Input>;
  pk_columns: Company_Email_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Email_Log_ManyArgs = {
  updates: Array<Company_Email_Log_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Gst_ReturnArgs = {
  _append?: InputMaybe<Company_Gst_Return_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Gst_Return_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Gst_Return_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Gst_Return_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Gst_Return_Inc_Input>;
  _prepend?: InputMaybe<Company_Gst_Return_Prepend_Input>;
  _set?: InputMaybe<Company_Gst_Return_Set_Input>;
  where: Company_Gst_Return_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Gst_Return_By_PkArgs = {
  _append?: InputMaybe<Company_Gst_Return_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Gst_Return_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Gst_Return_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Gst_Return_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Gst_Return_Inc_Input>;
  _prepend?: InputMaybe<Company_Gst_Return_Prepend_Input>;
  _set?: InputMaybe<Company_Gst_Return_Set_Input>;
  pk_columns: Company_Gst_Return_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Gst_Return_ManyArgs = {
  updates: Array<Company_Gst_Return_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_IntegrationArgs = {
  _append?: InputMaybe<Company_Integration_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Integration_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Integration_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Integration_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Integration_Inc_Input>;
  _prepend?: InputMaybe<Company_Integration_Prepend_Input>;
  _set?: InputMaybe<Company_Integration_Set_Input>;
  where: Company_Integration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Integration_By_PkArgs = {
  _append?: InputMaybe<Company_Integration_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Integration_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Integration_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Integration_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Integration_Inc_Input>;
  _prepend?: InputMaybe<Company_Integration_Prepend_Input>;
  _set?: InputMaybe<Company_Integration_Set_Input>;
  pk_columns: Company_Integration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Integration_FileArgs = {
  _append?: InputMaybe<Company_Integration_File_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Integration_File_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Integration_File_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Integration_File_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Integration_File_Inc_Input>;
  _prepend?: InputMaybe<Company_Integration_File_Prepend_Input>;
  _set?: InputMaybe<Company_Integration_File_Set_Input>;
  where: Company_Integration_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Integration_File_By_PkArgs = {
  _append?: InputMaybe<Company_Integration_File_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Integration_File_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Integration_File_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Integration_File_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Integration_File_Inc_Input>;
  _prepend?: InputMaybe<Company_Integration_File_Prepend_Input>;
  _set?: InputMaybe<Company_Integration_File_Set_Input>;
  pk_columns: Company_Integration_File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Integration_File_ManyArgs = {
  updates: Array<Company_Integration_File_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Integration_ManyArgs = {
  updates: Array<Company_Integration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Lead_StatusArgs = {
  _inc?: InputMaybe<Company_Lead_Status_Inc_Input>;
  _set?: InputMaybe<Company_Lead_Status_Set_Input>;
  where: Company_Lead_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Lead_Status_By_PkArgs = {
  _inc?: InputMaybe<Company_Lead_Status_Inc_Input>;
  _set?: InputMaybe<Company_Lead_Status_Set_Input>;
  pk_columns: Company_Lead_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Lead_Status_ManyArgs = {
  updates: Array<Company_Lead_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_ManyArgs = {
  updates: Array<Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_ReportsArgs = {
  _inc?: InputMaybe<Company_Reports_Inc_Input>;
  _set?: InputMaybe<Company_Reports_Set_Input>;
  where: Company_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Reports_By_PkArgs = {
  _inc?: InputMaybe<Company_Reports_Inc_Input>;
  _set?: InputMaybe<Company_Reports_Set_Input>;
  pk_columns: Company_Reports_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Reports_ManyArgs = {
  updates: Array<Company_Reports_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_ScoreArgs = {
  _inc?: InputMaybe<Company_Score_Inc_Input>;
  _set?: InputMaybe<Company_Score_Set_Input>;
  where: Company_Score_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Score_By_PkArgs = {
  _inc?: InputMaybe<Company_Score_Inc_Input>;
  _set?: InputMaybe<Company_Score_Set_Input>;
  pk_columns: Company_Score_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Score_FactorArgs = {
  _inc?: InputMaybe<Company_Score_Factor_Inc_Input>;
  _set?: InputMaybe<Company_Score_Factor_Set_Input>;
  where: Company_Score_Factor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Score_Factor_By_PkArgs = {
  _inc?: InputMaybe<Company_Score_Factor_Inc_Input>;
  _set?: InputMaybe<Company_Score_Factor_Set_Input>;
  pk_columns: Company_Score_Factor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Score_Factor_ManyArgs = {
  updates: Array<Company_Score_Factor_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Score_ManyArgs = {
  updates: Array<Company_Score_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Score_Time_SeriesArgs = {
  _inc?: InputMaybe<Company_Score_Time_Series_Inc_Input>;
  _set?: InputMaybe<Company_Score_Time_Series_Set_Input>;
  where: Company_Score_Time_Series_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Score_Time_Series_By_PkArgs = {
  _inc?: InputMaybe<Company_Score_Time_Series_Inc_Input>;
  _set?: InputMaybe<Company_Score_Time_Series_Set_Input>;
  pk_columns: Company_Score_Time_Series_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Score_Time_Series_ManyArgs = {
  updates: Array<Company_Score_Time_Series_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_StatementArgs = {
  _append?: InputMaybe<Company_Statement_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Statement_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Statement_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Statement_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Statement_Inc_Input>;
  _prepend?: InputMaybe<Company_Statement_Prepend_Input>;
  _set?: InputMaybe<Company_Statement_Set_Input>;
  where: Company_Statement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Statement_By_PkArgs = {
  _append?: InputMaybe<Company_Statement_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Statement_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Statement_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Statement_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Statement_Inc_Input>;
  _prepend?: InputMaybe<Company_Statement_Prepend_Input>;
  _set?: InputMaybe<Company_Statement_Set_Input>;
  pk_columns: Company_Statement_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Statement_ManyArgs = {
  updates: Array<Company_Statement_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_TanArgs = {
  _append?: InputMaybe<Company_Tan_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Tan_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Tan_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Tan_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Tan_Inc_Input>;
  _prepend?: InputMaybe<Company_Tan_Prepend_Input>;
  _set?: InputMaybe<Company_Tan_Set_Input>;
  where: Company_Tan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Tan_By_PkArgs = {
  _append?: InputMaybe<Company_Tan_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Tan_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Tan_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Tan_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Tan_Inc_Input>;
  _prepend?: InputMaybe<Company_Tan_Prepend_Input>;
  _set?: InputMaybe<Company_Tan_Set_Input>;
  pk_columns: Company_Tan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Tan_ManyArgs = {
  updates: Array<Company_Tan_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Type_Factor_BenchmarkArgs = {
  _inc?: InputMaybe<Company_Type_Factor_Benchmark_Inc_Input>;
  _set?: InputMaybe<Company_Type_Factor_Benchmark_Set_Input>;
  where: Company_Type_Factor_Benchmark_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Type_Factor_Benchmark_By_PkArgs = {
  _inc?: InputMaybe<Company_Type_Factor_Benchmark_Inc_Input>;
  _set?: InputMaybe<Company_Type_Factor_Benchmark_Set_Input>;
  pk_columns: Company_Type_Factor_Benchmark_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Type_Factor_Benchmark_ManyArgs = {
  updates: Array<Company_Type_Factor_Benchmark_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_UserArgs = {
  _inc?: InputMaybe<Company_User_Inc_Input>;
  _set?: InputMaybe<Company_User_Set_Input>;
  where: Company_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_User_By_PkArgs = {
  _inc?: InputMaybe<Company_User_Inc_Input>;
  _set?: InputMaybe<Company_User_Set_Input>;
  pk_columns: Company_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_User_ManyArgs = {
  updates: Array<Company_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DealArgs = {
  _inc?: InputMaybe<Deal_Inc_Input>;
  _set?: InputMaybe<Deal_Set_Input>;
  where: Deal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deal_By_PkArgs = {
  _inc?: InputMaybe<Deal_Inc_Input>;
  _set?: InputMaybe<Deal_Set_Input>;
  pk_columns: Deal_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deal_ManyArgs = {
  updates: Array<Deal_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Document_ChecklistArgs = {
  _inc?: InputMaybe<Document_Checklist_Inc_Input>;
  _set?: InputMaybe<Document_Checklist_Set_Input>;
  where: Document_Checklist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Document_Checklist_By_PkArgs = {
  _inc?: InputMaybe<Document_Checklist_Inc_Input>;
  _set?: InputMaybe<Document_Checklist_Set_Input>;
  pk_columns: Document_Checklist_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Document_Checklist_CategoryArgs = {
  _inc?: InputMaybe<Document_Checklist_Category_Inc_Input>;
  _set?: InputMaybe<Document_Checklist_Category_Set_Input>;
  where: Document_Checklist_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Document_Checklist_Category_By_PkArgs = {
  _inc?: InputMaybe<Document_Checklist_Category_Inc_Input>;
  _set?: InputMaybe<Document_Checklist_Category_Set_Input>;
  pk_columns: Document_Checklist_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Document_Checklist_Category_ManyArgs = {
  updates: Array<Document_Checklist_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Document_Checklist_ManyArgs = {
  updates: Array<Document_Checklist_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Email_EventsArgs = {
  _append?: InputMaybe<Email_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Email_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Email_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Email_Events_Delete_Key_Input>;
  _inc?: InputMaybe<Email_Events_Inc_Input>;
  _prepend?: InputMaybe<Email_Events_Prepend_Input>;
  _set?: InputMaybe<Email_Events_Set_Input>;
  where: Email_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Events_By_PkArgs = {
  _append?: InputMaybe<Email_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Email_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Email_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Email_Events_Delete_Key_Input>;
  _inc?: InputMaybe<Email_Events_Inc_Input>;
  _prepend?: InputMaybe<Email_Events_Prepend_Input>;
  _set?: InputMaybe<Email_Events_Set_Input>;
  pk_columns: Email_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Events_ManyArgs = {
  updates: Array<Email_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Emi_TdsArgs = {
  _inc?: InputMaybe<Emi_Tds_Inc_Input>;
  _set?: InputMaybe<Emi_Tds_Set_Input>;
  where: Emi_Tds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Emi_Tds_By_PkArgs = {
  _inc?: InputMaybe<Emi_Tds_Inc_Input>;
  _set?: InputMaybe<Emi_Tds_Set_Input>;
  pk_columns: Emi_Tds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Emi_Tds_ManyArgs = {
  updates: Array<Emi_Tds_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Factor_Benchmark_RangeArgs = {
  _inc?: InputMaybe<Factor_Benchmark_Range_Inc_Input>;
  _set?: InputMaybe<Factor_Benchmark_Range_Set_Input>;
  where: Factor_Benchmark_Range_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Factor_Benchmark_Range_By_PkArgs = {
  _inc?: InputMaybe<Factor_Benchmark_Range_Inc_Input>;
  _set?: InputMaybe<Factor_Benchmark_Range_Set_Input>;
  pk_columns: Factor_Benchmark_Range_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Factor_Benchmark_Range_ManyArgs = {
  updates: Array<Factor_Benchmark_Range_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_File_RegistryArgs = {
  _inc?: InputMaybe<File_Registry_Inc_Input>;
  _set?: InputMaybe<File_Registry_Set_Input>;
  where: File_Registry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_File_Registry_By_PkArgs = {
  _inc?: InputMaybe<File_Registry_Inc_Input>;
  _set?: InputMaybe<File_Registry_Set_Input>;
  pk_columns: File_Registry_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_File_Registry_ManyArgs = {
  updates: Array<File_Registry_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_AuthArgs = {
  _append?: InputMaybe<Integration_Auth_Append_Input>;
  _delete_at_path?: InputMaybe<Integration_Auth_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Integration_Auth_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Integration_Auth_Delete_Key_Input>;
  _inc?: InputMaybe<Integration_Auth_Inc_Input>;
  _prepend?: InputMaybe<Integration_Auth_Prepend_Input>;
  _set?: InputMaybe<Integration_Auth_Set_Input>;
  where: Integration_Auth_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Auth_By_PkArgs = {
  _append?: InputMaybe<Integration_Auth_Append_Input>;
  _delete_at_path?: InputMaybe<Integration_Auth_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Integration_Auth_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Integration_Auth_Delete_Key_Input>;
  _inc?: InputMaybe<Integration_Auth_Inc_Input>;
  _prepend?: InputMaybe<Integration_Auth_Prepend_Input>;
  _set?: InputMaybe<Integration_Auth_Set_Input>;
  pk_columns: Integration_Auth_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Auth_ManyArgs = {
  updates: Array<Integration_Auth_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Internal_StatementArgs = {
  _append?: InputMaybe<Internal_Statement_Append_Input>;
  _delete_at_path?: InputMaybe<Internal_Statement_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Internal_Statement_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Internal_Statement_Delete_Key_Input>;
  _inc?: InputMaybe<Internal_Statement_Inc_Input>;
  _prepend?: InputMaybe<Internal_Statement_Prepend_Input>;
  _set?: InputMaybe<Internal_Statement_Set_Input>;
  where: Internal_Statement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Internal_Statement_By_PkArgs = {
  _append?: InputMaybe<Internal_Statement_Append_Input>;
  _delete_at_path?: InputMaybe<Internal_Statement_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Internal_Statement_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Internal_Statement_Delete_Key_Input>;
  _inc?: InputMaybe<Internal_Statement_Inc_Input>;
  _prepend?: InputMaybe<Internal_Statement_Prepend_Input>;
  _set?: InputMaybe<Internal_Statement_Set_Input>;
  pk_columns: Internal_Statement_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Internal_Statement_ManyArgs = {
  updates: Array<Internal_Statement_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvestmentArgs = {
  _append?: InputMaybe<Investment_Append_Input>;
  _delete_at_path?: InputMaybe<Investment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Investment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Investment_Delete_Key_Input>;
  _inc?: InputMaybe<Investment_Inc_Input>;
  _prepend?: InputMaybe<Investment_Prepend_Input>;
  _set?: InputMaybe<Investment_Set_Input>;
  where: Investment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Investment_By_PkArgs = {
  _append?: InputMaybe<Investment_Append_Input>;
  _delete_at_path?: InputMaybe<Investment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Investment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Investment_Delete_Key_Input>;
  _inc?: InputMaybe<Investment_Inc_Input>;
  _prepend?: InputMaybe<Investment_Prepend_Input>;
  _set?: InputMaybe<Investment_Set_Input>;
  pk_columns: Investment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Investment_ManyArgs = {
  updates: Array<Investment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvestorArgs = {
  _append?: InputMaybe<Investor_Append_Input>;
  _delete_at_path?: InputMaybe<Investor_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Investor_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Investor_Delete_Key_Input>;
  _inc?: InputMaybe<Investor_Inc_Input>;
  _prepend?: InputMaybe<Investor_Prepend_Input>;
  _set?: InputMaybe<Investor_Set_Input>;
  where: Investor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Investor_By_PkArgs = {
  _append?: InputMaybe<Investor_Append_Input>;
  _delete_at_path?: InputMaybe<Investor_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Investor_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Investor_Delete_Key_Input>;
  _inc?: InputMaybe<Investor_Inc_Input>;
  _prepend?: InputMaybe<Investor_Prepend_Input>;
  _set?: InputMaybe<Investor_Set_Input>;
  pk_columns: Investor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Investor_ManyArgs = {
  updates: Array<Investor_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Investor_PreferenceArgs = {
  _append?: InputMaybe<Investor_Preference_Append_Input>;
  _delete_at_path?: InputMaybe<Investor_Preference_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Investor_Preference_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Investor_Preference_Delete_Key_Input>;
  _inc?: InputMaybe<Investor_Preference_Inc_Input>;
  _prepend?: InputMaybe<Investor_Preference_Prepend_Input>;
  _set?: InputMaybe<Investor_Preference_Set_Input>;
  where: Investor_Preference_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Investor_Preference_By_PkArgs = {
  _append?: InputMaybe<Investor_Preference_Append_Input>;
  _delete_at_path?: InputMaybe<Investor_Preference_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Investor_Preference_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Investor_Preference_Delete_Key_Input>;
  _inc?: InputMaybe<Investor_Preference_Inc_Input>;
  _prepend?: InputMaybe<Investor_Preference_Prepend_Input>;
  _set?: InputMaybe<Investor_Preference_Set_Input>;
  pk_columns: Investor_Preference_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Investor_Preference_ManyArgs = {
  updates: Array<Investor_Preference_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Investor_StatementArgs = {
  _append?: InputMaybe<Investor_Statement_Append_Input>;
  _delete_at_path?: InputMaybe<Investor_Statement_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Investor_Statement_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Investor_Statement_Delete_Key_Input>;
  _inc?: InputMaybe<Investor_Statement_Inc_Input>;
  _prepend?: InputMaybe<Investor_Statement_Prepend_Input>;
  _set?: InputMaybe<Investor_Statement_Set_Input>;
  where: Investor_Statement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Investor_Statement_By_PkArgs = {
  _append?: InputMaybe<Investor_Statement_Append_Input>;
  _delete_at_path?: InputMaybe<Investor_Statement_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Investor_Statement_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Investor_Statement_Delete_Key_Input>;
  _inc?: InputMaybe<Investor_Statement_Inc_Input>;
  _prepend?: InputMaybe<Investor_Statement_Prepend_Input>;
  _set?: InputMaybe<Investor_Statement_Set_Input>;
  pk_columns: Investor_Statement_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Investor_Statement_ManyArgs = {
  updates: Array<Investor_Statement_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Investor_TransactionArgs = {
  _append?: InputMaybe<Investor_Transaction_Append_Input>;
  _delete_at_path?: InputMaybe<Investor_Transaction_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Investor_Transaction_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Investor_Transaction_Delete_Key_Input>;
  _inc?: InputMaybe<Investor_Transaction_Inc_Input>;
  _prepend?: InputMaybe<Investor_Transaction_Prepend_Input>;
  _set?: InputMaybe<Investor_Transaction_Set_Input>;
  where: Investor_Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Investor_Transaction_By_PkArgs = {
  _append?: InputMaybe<Investor_Transaction_Append_Input>;
  _delete_at_path?: InputMaybe<Investor_Transaction_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Investor_Transaction_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Investor_Transaction_Delete_Key_Input>;
  _inc?: InputMaybe<Investor_Transaction_Inc_Input>;
  _prepend?: InputMaybe<Investor_Transaction_Prepend_Input>;
  _set?: InputMaybe<Investor_Transaction_Set_Input>;
  pk_columns: Investor_Transaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Investor_Transaction_ManyArgs = {
  updates: Array<Investor_Transaction_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Investor_UserArgs = {
  _inc?: InputMaybe<Investor_User_Inc_Input>;
  _set?: InputMaybe<Investor_User_Set_Input>;
  where: Investor_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Investor_User_By_PkArgs = {
  _inc?: InputMaybe<Investor_User_Inc_Input>;
  _set?: InputMaybe<Investor_User_Set_Input>;
  pk_columns: Investor_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Investor_User_ManyArgs = {
  updates: Array<Investor_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lifecycle_Question_Crm_MapperArgs = {
  _set?: InputMaybe<Lifecycle_Question_Crm_Mapper_Set_Input>;
  where: Lifecycle_Question_Crm_Mapper_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lifecycle_Question_Crm_Mapper_By_PkArgs = {
  _set?: InputMaybe<Lifecycle_Question_Crm_Mapper_Set_Input>;
  pk_columns: Lifecycle_Question_Crm_Mapper_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lifecycle_Question_Crm_Mapper_ManyArgs = {
  updates: Array<Lifecycle_Question_Crm_Mapper_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lifecycle_Question_ResultArgs = {
  _append?: InputMaybe<Lifecycle_Question_Result_Append_Input>;
  _delete_at_path?: InputMaybe<Lifecycle_Question_Result_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Lifecycle_Question_Result_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Lifecycle_Question_Result_Delete_Key_Input>;
  _inc?: InputMaybe<Lifecycle_Question_Result_Inc_Input>;
  _prepend?: InputMaybe<Lifecycle_Question_Result_Prepend_Input>;
  _set?: InputMaybe<Lifecycle_Question_Result_Set_Input>;
  where: Lifecycle_Question_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lifecycle_Question_Result_By_PkArgs = {
  _append?: InputMaybe<Lifecycle_Question_Result_Append_Input>;
  _delete_at_path?: InputMaybe<Lifecycle_Question_Result_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Lifecycle_Question_Result_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Lifecycle_Question_Result_Delete_Key_Input>;
  _inc?: InputMaybe<Lifecycle_Question_Result_Inc_Input>;
  _prepend?: InputMaybe<Lifecycle_Question_Result_Prepend_Input>;
  _set?: InputMaybe<Lifecycle_Question_Result_Set_Input>;
  pk_columns: Lifecycle_Question_Result_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lifecycle_Question_Result_ManyArgs = {
  updates: Array<Lifecycle_Question_Result_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MandateArgs = {
  _append?: InputMaybe<Mandate_Append_Input>;
  _delete_at_path?: InputMaybe<Mandate_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mandate_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mandate_Delete_Key_Input>;
  _inc?: InputMaybe<Mandate_Inc_Input>;
  _prepend?: InputMaybe<Mandate_Prepend_Input>;
  _set?: InputMaybe<Mandate_Set_Input>;
  where: Mandate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mandate_By_PkArgs = {
  _append?: InputMaybe<Mandate_Append_Input>;
  _delete_at_path?: InputMaybe<Mandate_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mandate_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mandate_Delete_Key_Input>;
  _inc?: InputMaybe<Mandate_Inc_Input>;
  _prepend?: InputMaybe<Mandate_Prepend_Input>;
  _set?: InputMaybe<Mandate_Set_Input>;
  pk_columns: Mandate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mandate_ManyArgs = {
  updates: Array<Mandate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Popular_BanksArgs = {
  _inc?: InputMaybe<Popular_Banks_Inc_Input>;
  _set?: InputMaybe<Popular_Banks_Set_Input>;
  where: Popular_Banks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Popular_Banks_By_PkArgs = {
  _inc?: InputMaybe<Popular_Banks_Inc_Input>;
  _set?: InputMaybe<Popular_Banks_Set_Input>;
  pk_columns: Popular_Banks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Popular_Banks_ManyArgs = {
  updates: Array<Popular_Banks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Account_StatusArgs = {
  _set?: InputMaybe<Ref_Account_Status_Set_Input>;
  where: Ref_Account_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Account_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_Account_Status_Set_Input>;
  pk_columns: Ref_Account_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Account_Status_ManyArgs = {
  updates: Array<Ref_Account_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Account_TypeArgs = {
  _set?: InputMaybe<Ref_Account_Type_Set_Input>;
  where: Ref_Account_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Account_Type_By_PkArgs = {
  _set?: InputMaybe<Ref_Account_Type_Set_Input>;
  pk_columns: Ref_Account_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Account_Type_ManyArgs = {
  updates: Array<Ref_Account_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Agreement_CategoryArgs = {
  _set?: InputMaybe<Ref_Agreement_Category_Set_Input>;
  where: Ref_Agreement_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Agreement_Category_By_PkArgs = {
  _set?: InputMaybe<Ref_Agreement_Category_Set_Input>;
  pk_columns: Ref_Agreement_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Agreement_Category_ManyArgs = {
  updates: Array<Ref_Agreement_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Agreement_StatusArgs = {
  _set?: InputMaybe<Ref_Agreement_Status_Set_Input>;
  where: Ref_Agreement_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Agreement_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_Agreement_Status_Set_Input>;
  pk_columns: Ref_Agreement_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Agreement_Status_ManyArgs = {
  updates: Array<Ref_Agreement_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Application_Allowed_DistrictArgs = {
  _set?: InputMaybe<Ref_Application_Allowed_District_Set_Input>;
  where: Ref_Application_Allowed_District_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Application_Allowed_District_By_PkArgs = {
  _set?: InputMaybe<Ref_Application_Allowed_District_Set_Input>;
  pk_columns: Ref_Application_Allowed_District_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Application_Allowed_District_ManyArgs = {
  updates: Array<Ref_Application_Allowed_District_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Application_Lifecycle_StageArgs = {
  _set?: InputMaybe<Ref_Application_Lifecycle_Stage_Set_Input>;
  where: Ref_Application_Lifecycle_Stage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Application_Lifecycle_Stage_By_PkArgs = {
  _set?: InputMaybe<Ref_Application_Lifecycle_Stage_Set_Input>;
  pk_columns: Ref_Application_Lifecycle_Stage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Application_Lifecycle_Stage_ManyArgs = {
  updates: Array<Ref_Application_Lifecycle_Stage_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_BankArgs = {
  _inc?: InputMaybe<Ref_Bank_Inc_Input>;
  _set?: InputMaybe<Ref_Bank_Set_Input>;
  where: Ref_Bank_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Bank_By_PkArgs = {
  _inc?: InputMaybe<Ref_Bank_Inc_Input>;
  _set?: InputMaybe<Ref_Bank_Set_Input>;
  pk_columns: Ref_Bank_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Bank_ListArgs = {
  _inc?: InputMaybe<Ref_Bank_List_Inc_Input>;
  _set?: InputMaybe<Ref_Bank_List_Set_Input>;
  where: Ref_Bank_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Bank_List_By_PkArgs = {
  _inc?: InputMaybe<Ref_Bank_List_Inc_Input>;
  _set?: InputMaybe<Ref_Bank_List_Set_Input>;
  pk_columns: Ref_Bank_List_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Bank_List_ManyArgs = {
  updates: Array<Ref_Bank_List_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Bank_ManyArgs = {
  updates: Array<Ref_Bank_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_BidArgs = {
  _inc?: InputMaybe<Ref_Bid_Inc_Input>;
  _set?: InputMaybe<Ref_Bid_Set_Input>;
  where: Ref_Bid_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Bid_By_PkArgs = {
  _inc?: InputMaybe<Ref_Bid_Inc_Input>;
  _set?: InputMaybe<Ref_Bid_Set_Input>;
  pk_columns: Ref_Bid_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Bid_ManyArgs = {
  updates: Array<Ref_Bid_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Billing_PeriodArgs = {
  _inc?: InputMaybe<Ref_Billing_Period_Inc_Input>;
  _set?: InputMaybe<Ref_Billing_Period_Set_Input>;
  where: Ref_Billing_Period_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Billing_Period_By_PkArgs = {
  _inc?: InputMaybe<Ref_Billing_Period_Inc_Input>;
  _set?: InputMaybe<Ref_Billing_Period_Set_Input>;
  pk_columns: Ref_Billing_Period_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Billing_Period_ManyArgs = {
  updates: Array<Ref_Billing_Period_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_CategoryArgs = {
  _set?: InputMaybe<Ref_Category_Set_Input>;
  where: Ref_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Category_By_PkArgs = {
  _set?: InputMaybe<Ref_Category_Set_Input>;
  pk_columns: Ref_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Category_ManyArgs = {
  updates: Array<Ref_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Collection_ModeArgs = {
  _set?: InputMaybe<Ref_Collection_Mode_Set_Input>;
  where: Ref_Collection_Mode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Collection_Mode_By_PkArgs = {
  _set?: InputMaybe<Ref_Collection_Mode_Set_Input>;
  pk_columns: Ref_Collection_Mode_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Collection_Mode_ManyArgs = {
  updates: Array<Ref_Collection_Mode_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Company_Lead_StatusArgs = {
  _set?: InputMaybe<Ref_Company_Lead_Status_Set_Input>;
  where: Ref_Company_Lead_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Company_Lead_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_Company_Lead_Status_Set_Input>;
  pk_columns: Ref_Company_Lead_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Company_Lead_Status_ManyArgs = {
  updates: Array<Ref_Company_Lead_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Company_LikelinessArgs = {
  _set?: InputMaybe<Ref_Company_Likeliness_Set_Input>;
  where: Ref_Company_Likeliness_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Company_Likeliness_By_PkArgs = {
  _set?: InputMaybe<Ref_Company_Likeliness_Set_Input>;
  pk_columns: Ref_Company_Likeliness_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Company_Likeliness_ManyArgs = {
  updates: Array<Ref_Company_Likeliness_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Company_StatusArgs = {
  _set?: InputMaybe<Ref_Company_Status_Set_Input>;
  where: Ref_Company_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Company_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_Company_Status_Set_Input>;
  pk_columns: Ref_Company_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Company_Status_ManyArgs = {
  updates: Array<Ref_Company_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Company_TypeArgs = {
  _set?: InputMaybe<Ref_Company_Type_Set_Input>;
  where: Ref_Company_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Company_Type_By_PkArgs = {
  _set?: InputMaybe<Ref_Company_Type_Set_Input>;
  pk_columns: Ref_Company_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Company_Type_ManyArgs = {
  updates: Array<Ref_Company_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_CountryArgs = {
  _inc?: InputMaybe<Ref_Country_Inc_Input>;
  _set?: InputMaybe<Ref_Country_Set_Input>;
  where: Ref_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Country_By_PkArgs = {
  _inc?: InputMaybe<Ref_Country_Inc_Input>;
  _set?: InputMaybe<Ref_Country_Set_Input>;
  pk_columns: Ref_Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Country_ManyArgs = {
  updates: Array<Ref_Country_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_CurrencyArgs = {
  _set?: InputMaybe<Ref_Currency_Set_Input>;
  where: Ref_Currency_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Currency_By_PkArgs = {
  _set?: InputMaybe<Ref_Currency_Set_Input>;
  pk_columns: Ref_Currency_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Currency_ManyArgs = {
  updates: Array<Ref_Currency_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Debit_ModeArgs = {
  _set?: InputMaybe<Ref_Debit_Mode_Set_Input>;
  where: Ref_Debit_Mode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Debit_Mode_By_PkArgs = {
  _set?: InputMaybe<Ref_Debit_Mode_Set_Input>;
  pk_columns: Ref_Debit_Mode_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Debit_Mode_ManyArgs = {
  updates: Array<Ref_Debit_Mode_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Document_ChecklistArgs = {
  _inc?: InputMaybe<Ref_Document_Checklist_Inc_Input>;
  _set?: InputMaybe<Ref_Document_Checklist_Set_Input>;
  where: Ref_Document_Checklist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Document_Checklist_By_PkArgs = {
  _inc?: InputMaybe<Ref_Document_Checklist_Inc_Input>;
  _set?: InputMaybe<Ref_Document_Checklist_Set_Input>;
  pk_columns: Ref_Document_Checklist_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Document_Checklist_CategoryArgs = {
  _inc?: InputMaybe<Ref_Document_Checklist_Category_Inc_Input>;
  _set?: InputMaybe<Ref_Document_Checklist_Category_Set_Input>;
  where: Ref_Document_Checklist_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Document_Checklist_Category_By_PkArgs = {
  _inc?: InputMaybe<Ref_Document_Checklist_Category_Inc_Input>;
  _set?: InputMaybe<Ref_Document_Checklist_Category_Set_Input>;
  pk_columns: Ref_Document_Checklist_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Document_Checklist_Category_ManyArgs = {
  updates: Array<Ref_Document_Checklist_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Document_Checklist_IntegrationArgs = {
  _set?: InputMaybe<Ref_Document_Checklist_Integration_Set_Input>;
  where: Ref_Document_Checklist_Integration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Document_Checklist_Integration_By_PkArgs = {
  _set?: InputMaybe<Ref_Document_Checklist_Integration_Set_Input>;
  pk_columns: Ref_Document_Checklist_Integration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Document_Checklist_Integration_ManyArgs = {
  updates: Array<Ref_Document_Checklist_Integration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Document_Checklist_ManyArgs = {
  updates: Array<Ref_Document_Checklist_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Document_Checklist_StatusArgs = {
  _set?: InputMaybe<Ref_Document_Checklist_Status_Set_Input>;
  where: Ref_Document_Checklist_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Document_Checklist_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_Document_Checklist_Status_Set_Input>;
  pk_columns: Ref_Document_Checklist_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Document_Checklist_Status_ManyArgs = {
  updates: Array<Ref_Document_Checklist_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Email_Events_TypeArgs = {
  _set?: InputMaybe<Ref_Email_Events_Type_Set_Input>;
  where: Ref_Email_Events_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Email_Events_Type_By_PkArgs = {
  _set?: InputMaybe<Ref_Email_Events_Type_Set_Input>;
  pk_columns: Ref_Email_Events_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Email_Events_Type_ManyArgs = {
  updates: Array<Ref_Email_Events_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_File_CategoryArgs = {
  _set?: InputMaybe<Ref_File_Category_Set_Input>;
  where: Ref_File_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_File_Category_By_PkArgs = {
  _set?: InputMaybe<Ref_File_Category_Set_Input>;
  pk_columns: Ref_File_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_File_Category_ManyArgs = {
  updates: Array<Ref_File_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_File_Category_MimetypeArgs = {
  _set?: InputMaybe<Ref_File_Category_Mimetype_Set_Input>;
  where: Ref_File_Category_Mimetype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_File_Category_Mimetype_By_PkArgs = {
  _set?: InputMaybe<Ref_File_Category_Mimetype_Set_Input>;
  pk_columns: Ref_File_Category_Mimetype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_File_Category_Mimetype_ManyArgs = {
  updates: Array<Ref_File_Category_Mimetype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_IndustryArgs = {
  _set?: InputMaybe<Ref_Industry_Set_Input>;
  where: Ref_Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Industry_By_PkArgs = {
  _set?: InputMaybe<Ref_Industry_Set_Input>;
  pk_columns: Ref_Industry_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Industry_ManyArgs = {
  updates: Array<Ref_Industry_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Integration_File_StatusArgs = {
  _set?: InputMaybe<Ref_Integration_File_Status_Set_Input>;
  where: Ref_Integration_File_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Integration_File_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_Integration_File_Status_Set_Input>;
  pk_columns: Ref_Integration_File_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Integration_File_Status_ManyArgs = {
  updates: Array<Ref_Integration_File_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Integration_StatusArgs = {
  _set?: InputMaybe<Ref_Integration_Status_Set_Input>;
  where: Ref_Integration_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Integration_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_Integration_Status_Set_Input>;
  pk_columns: Ref_Integration_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Integration_Status_ManyArgs = {
  updates: Array<Ref_Integration_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Investor_StatusArgs = {
  _set?: InputMaybe<Ref_Investor_Status_Set_Input>;
  where: Ref_Investor_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Investor_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_Investor_Status_Set_Input>;
  pk_columns: Ref_Investor_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Investor_Status_ManyArgs = {
  updates: Array<Ref_Investor_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Investor_TypeArgs = {
  _set?: InputMaybe<Ref_Investor_Type_Set_Input>;
  where: Ref_Investor_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Investor_Type_By_PkArgs = {
  _set?: InputMaybe<Ref_Investor_Type_Set_Input>;
  pk_columns: Ref_Investor_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Investor_Type_ManyArgs = {
  updates: Array<Ref_Investor_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Lifecycle_QuestionArgs = {
  _inc?: InputMaybe<Ref_Lifecycle_Question_Inc_Input>;
  _set?: InputMaybe<Ref_Lifecycle_Question_Set_Input>;
  where: Ref_Lifecycle_Question_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Lifecycle_Question_By_PkArgs = {
  _inc?: InputMaybe<Ref_Lifecycle_Question_Inc_Input>;
  _set?: InputMaybe<Ref_Lifecycle_Question_Set_Input>;
  pk_columns: Ref_Lifecycle_Question_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Lifecycle_Question_CategoryArgs = {
  _set?: InputMaybe<Ref_Lifecycle_Question_Category_Set_Input>;
  where: Ref_Lifecycle_Question_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Lifecycle_Question_Category_By_PkArgs = {
  _set?: InputMaybe<Ref_Lifecycle_Question_Category_Set_Input>;
  pk_columns: Ref_Lifecycle_Question_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Lifecycle_Question_Category_ManyArgs = {
  updates: Array<Ref_Lifecycle_Question_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Lifecycle_Question_ManyArgs = {
  updates: Array<Ref_Lifecycle_Question_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Mandate_FrequencyArgs = {
  _set?: InputMaybe<Ref_Mandate_Frequency_Set_Input>;
  where: Ref_Mandate_Frequency_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Mandate_Frequency_By_PkArgs = {
  _set?: InputMaybe<Ref_Mandate_Frequency_Set_Input>;
  pk_columns: Ref_Mandate_Frequency_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Mandate_Frequency_ManyArgs = {
  updates: Array<Ref_Mandate_Frequency_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Mandate_StatusArgs = {
  _set?: InputMaybe<Ref_Mandate_Status_Set_Input>;
  where: Ref_Mandate_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Mandate_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_Mandate_Status_Set_Input>;
  pk_columns: Ref_Mandate_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Mandate_Status_ManyArgs = {
  updates: Array<Ref_Mandate_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Mandate_TypeArgs = {
  _set?: InputMaybe<Ref_Mandate_Type_Set_Input>;
  where: Ref_Mandate_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Mandate_Type_By_PkArgs = {
  _set?: InputMaybe<Ref_Mandate_Type_Set_Input>;
  pk_columns: Ref_Mandate_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Mandate_Type_ManyArgs = {
  updates: Array<Ref_Mandate_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Perfios_Bank_ListArgs = {
  _inc?: InputMaybe<Ref_Perfios_Bank_List_Inc_Input>;
  _set?: InputMaybe<Ref_Perfios_Bank_List_Set_Input>;
  where: Ref_Perfios_Bank_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Perfios_Bank_List_By_PkArgs = {
  _inc?: InputMaybe<Ref_Perfios_Bank_List_Inc_Input>;
  _set?: InputMaybe<Ref_Perfios_Bank_List_Set_Input>;
  pk_columns: Ref_Perfios_Bank_List_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Perfios_Bank_List_ManyArgs = {
  updates: Array<Ref_Perfios_Bank_List_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Report_CategoryArgs = {
  _set?: InputMaybe<Ref_Report_Category_Set_Input>;
  where: Ref_Report_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Report_Category_By_PkArgs = {
  _set?: InputMaybe<Ref_Report_Category_Set_Input>;
  pk_columns: Ref_Report_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Report_Category_ManyArgs = {
  updates: Array<Ref_Report_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_RoleArgs = {
  _set?: InputMaybe<Ref_Role_Set_Input>;
  where: Ref_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Role_By_PkArgs = {
  _set?: InputMaybe<Ref_Role_Set_Input>;
  pk_columns: Ref_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Role_ManyArgs = {
  updates: Array<Ref_Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Schedule_Checklist_Reminder_StatusArgs = {
  _set?: InputMaybe<Ref_Schedule_Checklist_Reminder_Status_Set_Input>;
  where: Ref_Schedule_Checklist_Reminder_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Schedule_Checklist_Reminder_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_Schedule_Checklist_Reminder_Status_Set_Input>;
  pk_columns: Ref_Schedule_Checklist_Reminder_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Schedule_Checklist_Reminder_Status_ManyArgs = {
  updates: Array<Ref_Schedule_Checklist_Reminder_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Score_FactorArgs = {
  _set?: InputMaybe<Ref_Score_Factor_Set_Input>;
  where: Ref_Score_Factor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Score_Factor_By_PkArgs = {
  _set?: InputMaybe<Ref_Score_Factor_Set_Input>;
  pk_columns: Ref_Score_Factor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Score_Factor_CategoryArgs = {
  _set?: InputMaybe<Ref_Score_Factor_Category_Set_Input>;
  where: Ref_Score_Factor_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Score_Factor_Category_By_PkArgs = {
  _set?: InputMaybe<Ref_Score_Factor_Category_Set_Input>;
  pk_columns: Ref_Score_Factor_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Score_Factor_Category_ManyArgs = {
  updates: Array<Ref_Score_Factor_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Score_Factor_ManyArgs = {
  updates: Array<Ref_Score_Factor_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Score_Factor_UnitArgs = {
  _set?: InputMaybe<Ref_Score_Factor_Unit_Set_Input>;
  where: Ref_Score_Factor_Unit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Score_Factor_Unit_By_PkArgs = {
  _set?: InputMaybe<Ref_Score_Factor_Unit_Set_Input>;
  pk_columns: Ref_Score_Factor_Unit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Score_Factor_Unit_ManyArgs = {
  updates: Array<Ref_Score_Factor_Unit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_SourceArgs = {
  _set?: InputMaybe<Ref_Source_Set_Input>;
  where: Ref_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Source_By_PkArgs = {
  _set?: InputMaybe<Ref_Source_Set_Input>;
  pk_columns: Ref_Source_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Source_ManyArgs = {
  updates: Array<Ref_Source_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_StatesArgs = {
  _inc?: InputMaybe<Ref_States_Inc_Input>;
  _set?: InputMaybe<Ref_States_Set_Input>;
  where: Ref_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_States_By_PkArgs = {
  _inc?: InputMaybe<Ref_States_Inc_Input>;
  _set?: InputMaybe<Ref_States_Set_Input>;
  pk_columns: Ref_States_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_States_ManyArgs = {
  updates: Array<Ref_States_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Subscription_StatusArgs = {
  _set?: InputMaybe<Ref_Subscription_Status_Set_Input>;
  where: Ref_Subscription_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Subscription_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_Subscription_Status_Set_Input>;
  pk_columns: Ref_Subscription_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Subscription_Status_ManyArgs = {
  updates: Array<Ref_Subscription_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_System_AccountArgs = {
  _inc?: InputMaybe<Ref_System_Account_Inc_Input>;
  _set?: InputMaybe<Ref_System_Account_Set_Input>;
  where: Ref_System_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_System_Account_By_PkArgs = {
  _inc?: InputMaybe<Ref_System_Account_Inc_Input>;
  _set?: InputMaybe<Ref_System_Account_Set_Input>;
  pk_columns: Ref_System_Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_System_Account_ManyArgs = {
  updates: Array<Ref_System_Account_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_System_Account_StatusArgs = {
  _set?: InputMaybe<Ref_System_Account_Status_Set_Input>;
  where: Ref_System_Account_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_System_Account_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_System_Account_Status_Set_Input>;
  pk_columns: Ref_System_Account_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_System_Account_Status_ManyArgs = {
  updates: Array<Ref_System_Account_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_System_Account_TypeArgs = {
  _set?: InputMaybe<Ref_System_Account_Type_Set_Input>;
  where: Ref_System_Account_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_System_Account_Type_By_PkArgs = {
  _set?: InputMaybe<Ref_System_Account_Type_Set_Input>;
  pk_columns: Ref_System_Account_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_System_Account_Type_ManyArgs = {
  updates: Array<Ref_System_Account_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Tds_StatusArgs = {
  _set?: InputMaybe<Ref_Tds_Status_Set_Input>;
  where: Ref_Tds_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Tds_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_Tds_Status_Set_Input>;
  pk_columns: Ref_Tds_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Tds_Status_ManyArgs = {
  updates: Array<Ref_Tds_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Tds_Value_UnitArgs = {
  _set?: InputMaybe<Ref_Tds_Value_Unit_Set_Input>;
  where: Ref_Tds_Value_Unit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Tds_Value_Unit_By_PkArgs = {
  _set?: InputMaybe<Ref_Tds_Value_Unit_Set_Input>;
  pk_columns: Ref_Tds_Value_Unit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Tds_Value_Unit_ManyArgs = {
  updates: Array<Ref_Tds_Value_Unit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Tenant_TypeArgs = {
  _set?: InputMaybe<Ref_Tenant_Type_Set_Input>;
  where: Ref_Tenant_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Tenant_Type_By_PkArgs = {
  _set?: InputMaybe<Ref_Tenant_Type_Set_Input>;
  pk_columns: Ref_Tenant_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Tenant_Type_ManyArgs = {
  updates: Array<Ref_Tenant_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Trade_StateArgs = {
  _set?: InputMaybe<Ref_Trade_State_Set_Input>;
  where: Ref_Trade_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Trade_State_By_PkArgs = {
  _set?: InputMaybe<Ref_Trade_State_Set_Input>;
  pk_columns: Ref_Trade_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Trade_State_ManyArgs = {
  updates: Array<Ref_Trade_State_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Trade_StatusArgs = {
  _set?: InputMaybe<Ref_Trade_Status_Set_Input>;
  where: Ref_Trade_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Trade_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_Trade_Status_Set_Input>;
  pk_columns: Ref_Trade_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Trade_Status_ManyArgs = {
  updates: Array<Ref_Trade_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Trade_Withdrawal_ModeArgs = {
  _set?: InputMaybe<Ref_Trade_Withdrawal_Mode_Set_Input>;
  where: Ref_Trade_Withdrawal_Mode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Trade_Withdrawal_Mode_By_PkArgs = {
  _set?: InputMaybe<Ref_Trade_Withdrawal_Mode_Set_Input>;
  pk_columns: Ref_Trade_Withdrawal_Mode_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Trade_Withdrawal_Mode_ManyArgs = {
  updates: Array<Ref_Trade_Withdrawal_Mode_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Transaction_MethodArgs = {
  _set?: InputMaybe<Ref_Transaction_Method_Set_Input>;
  where: Ref_Transaction_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Transaction_Method_By_PkArgs = {
  _set?: InputMaybe<Ref_Transaction_Method_Set_Input>;
  pk_columns: Ref_Transaction_Method_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Transaction_Method_ManyArgs = {
  updates: Array<Ref_Transaction_Method_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Transaction_StatusArgs = {
  _set?: InputMaybe<Ref_Transaction_Status_Set_Input>;
  where: Ref_Transaction_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Transaction_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_Transaction_Status_Set_Input>;
  pk_columns: Ref_Transaction_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Transaction_Status_ManyArgs = {
  updates: Array<Ref_Transaction_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Transaction_TypeArgs = {
  _set?: InputMaybe<Ref_Transaction_Type_Set_Input>;
  where: Ref_Transaction_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Transaction_Type_By_PkArgs = {
  _set?: InputMaybe<Ref_Transaction_Type_Set_Input>;
  pk_columns: Ref_Transaction_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Transaction_Type_ManyArgs = {
  updates: Array<Ref_Transaction_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Transfer_StatusArgs = {
  _set?: InputMaybe<Ref_Transfer_Status_Set_Input>;
  where: Ref_Transfer_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Transfer_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_Transfer_Status_Set_Input>;
  pk_columns: Ref_Transfer_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Transfer_Status_ManyArgs = {
  updates: Array<Ref_Transfer_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Transfer_TypeArgs = {
  _set?: InputMaybe<Ref_Transfer_Type_Set_Input>;
  where: Ref_Transfer_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Transfer_Type_By_PkArgs = {
  _set?: InputMaybe<Ref_Transfer_Type_Set_Input>;
  pk_columns: Ref_Transfer_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_Transfer_Type_ManyArgs = {
  updates: Array<Ref_Transfer_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_User_StatusArgs = {
  _set?: InputMaybe<Ref_User_Status_Set_Input>;
  where: Ref_User_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_User_Status_By_PkArgs = {
  _set?: InputMaybe<Ref_User_Status_Set_Input>;
  pk_columns: Ref_User_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_User_Status_ManyArgs = {
  updates: Array<Ref_User_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_User_TypeArgs = {
  _set?: InputMaybe<Ref_User_Type_Set_Input>;
  where: Ref_User_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_User_Type_By_PkArgs = {
  _set?: InputMaybe<Ref_User_Type_Set_Input>;
  pk_columns: Ref_User_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ref_User_Type_ManyArgs = {
  updates: Array<Ref_User_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Report_CategoryArgs = {
  _set?: InputMaybe<Report_Category_Set_Input>;
  where: Report_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Report_Category_By_PkArgs = {
  _set?: InputMaybe<Report_Category_Set_Input>;
  pk_columns: Report_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Report_Category_ManyArgs = {
  updates: Array<Report_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Report_EventArgs = {
  _append?: InputMaybe<Report_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Report_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Report_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Report_Event_Delete_Key_Input>;
  _inc?: InputMaybe<Report_Event_Inc_Input>;
  _prepend?: InputMaybe<Report_Event_Prepend_Input>;
  _set?: InputMaybe<Report_Event_Set_Input>;
  where: Report_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Report_Event_By_PkArgs = {
  _append?: InputMaybe<Report_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Report_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Report_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Report_Event_Delete_Key_Input>;
  _inc?: InputMaybe<Report_Event_Inc_Input>;
  _prepend?: InputMaybe<Report_Event_Prepend_Input>;
  _set?: InputMaybe<Report_Event_Set_Input>;
  pk_columns: Report_Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Report_Event_ManyArgs = {
  updates: Array<Report_Event_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Schedule_Document_Checklist_ReminderArgs = {
  _inc?: InputMaybe<Schedule_Document_Checklist_Reminder_Inc_Input>;
  _set?: InputMaybe<Schedule_Document_Checklist_Reminder_Set_Input>;
  where: Schedule_Document_Checklist_Reminder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Schedule_Document_Checklist_Reminder_By_PkArgs = {
  _inc?: InputMaybe<Schedule_Document_Checklist_Reminder_Inc_Input>;
  _set?: InputMaybe<Schedule_Document_Checklist_Reminder_Set_Input>;
  pk_columns: Schedule_Document_Checklist_Reminder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Schedule_Document_Checklist_Reminder_ManyArgs = {
  updates: Array<Schedule_Document_Checklist_Reminder_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Scheduled_EmiArgs = {
  _append?: InputMaybe<Scheduled_Emi_Append_Input>;
  _delete_at_path?: InputMaybe<Scheduled_Emi_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Scheduled_Emi_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Scheduled_Emi_Delete_Key_Input>;
  _inc?: InputMaybe<Scheduled_Emi_Inc_Input>;
  _prepend?: InputMaybe<Scheduled_Emi_Prepend_Input>;
  _set?: InputMaybe<Scheduled_Emi_Set_Input>;
  where: Scheduled_Emi_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Scheduled_Emi_By_PkArgs = {
  _append?: InputMaybe<Scheduled_Emi_Append_Input>;
  _delete_at_path?: InputMaybe<Scheduled_Emi_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Scheduled_Emi_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Scheduled_Emi_Delete_Key_Input>;
  _inc?: InputMaybe<Scheduled_Emi_Inc_Input>;
  _prepend?: InputMaybe<Scheduled_Emi_Prepend_Input>;
  _set?: InputMaybe<Scheduled_Emi_Set_Input>;
  pk_columns: Scheduled_Emi_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Scheduled_Emi_ManyArgs = {
  updates: Array<Scheduled_Emi_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Score_FactorArgs = {
  _inc?: InputMaybe<Score_Factor_Inc_Input>;
  _set?: InputMaybe<Score_Factor_Set_Input>;
  where: Score_Factor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Score_Factor_By_PkArgs = {
  _inc?: InputMaybe<Score_Factor_Inc_Input>;
  _set?: InputMaybe<Score_Factor_Set_Input>;
  pk_columns: Score_Factor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Score_Factor_ManyArgs = {
  updates: Array<Score_Factor_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Staff_UserArgs = {
  _inc?: InputMaybe<Staff_User_Inc_Input>;
  _set?: InputMaybe<Staff_User_Set_Input>;
  where: Staff_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Staff_User_By_PkArgs = {
  _inc?: InputMaybe<Staff_User_Inc_Input>;
  _set?: InputMaybe<Staff_User_Set_Input>;
  pk_columns: Staff_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Staff_User_ManyArgs = {
  updates: Array<Staff_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SubscriptionArgs = {
  _inc?: InputMaybe<Subscription_Inc_Input>;
  _set?: InputMaybe<Subscription_Set_Input>;
  where: Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_By_PkArgs = {
  _inc?: InputMaybe<Subscription_Inc_Input>;
  _set?: InputMaybe<Subscription_Set_Input>;
  pk_columns: Subscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_ManyArgs = {
  updates: Array<Subscription_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TdsArgs = {
  _append?: InputMaybe<Tds_Append_Input>;
  _delete_at_path?: InputMaybe<Tds_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tds_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tds_Delete_Key_Input>;
  _inc?: InputMaybe<Tds_Inc_Input>;
  _prepend?: InputMaybe<Tds_Prepend_Input>;
  _set?: InputMaybe<Tds_Set_Input>;
  where: Tds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tds_By_PkArgs = {
  _append?: InputMaybe<Tds_Append_Input>;
  _delete_at_path?: InputMaybe<Tds_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tds_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tds_Delete_Key_Input>;
  _inc?: InputMaybe<Tds_Inc_Input>;
  _prepend?: InputMaybe<Tds_Prepend_Input>;
  _set?: InputMaybe<Tds_Set_Input>;
  pk_columns: Tds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tds_ManyArgs = {
  updates: Array<Tds_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TradeArgs = {
  _append?: InputMaybe<Trade_Append_Input>;
  _delete_at_path?: InputMaybe<Trade_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Trade_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Trade_Delete_Key_Input>;
  _inc?: InputMaybe<Trade_Inc_Input>;
  _prepend?: InputMaybe<Trade_Prepend_Input>;
  _set?: InputMaybe<Trade_Set_Input>;
  where: Trade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_By_PkArgs = {
  _append?: InputMaybe<Trade_Append_Input>;
  _delete_at_path?: InputMaybe<Trade_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Trade_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Trade_Delete_Key_Input>;
  _inc?: InputMaybe<Trade_Inc_Input>;
  _prepend?: InputMaybe<Trade_Prepend_Input>;
  _set?: InputMaybe<Trade_Set_Input>;
  pk_columns: Trade_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_ManyArgs = {
  updates: Array<Trade_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_SubscriptionArgs = {
  _inc?: InputMaybe<Trade_Subscription_Inc_Input>;
  _set?: InputMaybe<Trade_Subscription_Set_Input>;
  where: Trade_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_Subscription_By_PkArgs = {
  _inc?: InputMaybe<Trade_Subscription_Inc_Input>;
  _set?: InputMaybe<Trade_Subscription_Set_Input>;
  pk_columns: Trade_Subscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_Subscription_ManyArgs = {
  updates: Array<Trade_Subscription_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TransactionArgs = {
  _append?: InputMaybe<Transaction_Append_Input>;
  _delete_at_path?: InputMaybe<Transaction_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Transaction_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Transaction_Delete_Key_Input>;
  _inc?: InputMaybe<Transaction_Inc_Input>;
  _prepend?: InputMaybe<Transaction_Prepend_Input>;
  _set?: InputMaybe<Transaction_Set_Input>;
  where: Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transaction_By_PkArgs = {
  _append?: InputMaybe<Transaction_Append_Input>;
  _delete_at_path?: InputMaybe<Transaction_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Transaction_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Transaction_Delete_Key_Input>;
  _inc?: InputMaybe<Transaction_Inc_Input>;
  _prepend?: InputMaybe<Transaction_Prepend_Input>;
  _set?: InputMaybe<Transaction_Set_Input>;
  pk_columns: Transaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Transaction_ManyArgs = {
  updates: Array<Transaction_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TransferArgs = {
  _append?: InputMaybe<Transfer_Append_Input>;
  _delete_at_path?: InputMaybe<Transfer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Transfer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Transfer_Delete_Key_Input>;
  _inc?: InputMaybe<Transfer_Inc_Input>;
  _prepend?: InputMaybe<Transfer_Prepend_Input>;
  _set?: InputMaybe<Transfer_Set_Input>;
  where: Transfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transfer_By_PkArgs = {
  _append?: InputMaybe<Transfer_Append_Input>;
  _delete_at_path?: InputMaybe<Transfer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Transfer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Transfer_Delete_Key_Input>;
  _inc?: InputMaybe<Transfer_Inc_Input>;
  _prepend?: InputMaybe<Transfer_Prepend_Input>;
  _set?: InputMaybe<Transfer_Set_Input>;
  pk_columns: Transfer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Transfer_ManyArgs = {
  updates: Array<Transfer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Updates_LogArgs = {
  _append?: InputMaybe<Updates_Log_Append_Input>;
  _delete_at_path?: InputMaybe<Updates_Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Updates_Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Updates_Log_Delete_Key_Input>;
  _inc?: InputMaybe<Updates_Log_Inc_Input>;
  _prepend?: InputMaybe<Updates_Log_Prepend_Input>;
  _set?: InputMaybe<Updates_Log_Set_Input>;
  where: Updates_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Updates_Log_By_PkArgs = {
  _append?: InputMaybe<Updates_Log_Append_Input>;
  _delete_at_path?: InputMaybe<Updates_Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Updates_Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Updates_Log_Delete_Key_Input>;
  _inc?: InputMaybe<Updates_Log_Inc_Input>;
  _prepend?: InputMaybe<Updates_Log_Prepend_Input>;
  _set?: InputMaybe<Updates_Log_Set_Input>;
  pk_columns: Updates_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Updates_Log_ManyArgs = {
  updates: Array<Updates_Log_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _append?: InputMaybe<User_Append_Input>;
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  _inc?: InputMaybe<User_Inc_Input>;
  _prepend?: InputMaybe<User_Prepend_Input>;
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _append?: InputMaybe<User_Append_Input>;
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  _inc?: InputMaybe<User_Inc_Input>;
  _prepend?: InputMaybe<User_Prepend_Input>;
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_CodeArgs = {
  _inc?: InputMaybe<Verification_Code_Inc_Input>;
  _set?: InputMaybe<Verification_Code_Set_Input>;
  where: Verification_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_Code_By_PkArgs = {
  _inc?: InputMaybe<Verification_Code_Inc_Input>;
  _set?: InputMaybe<Verification_Code_Set_Input>;
  pk_columns: Verification_Code_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_Code_ManyArgs = {
  updates: Array<Verification_Code_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Webhook_EventsArgs = {
  _append?: InputMaybe<Webhook_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Webhook_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Webhook_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Webhook_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Webhook_Events_Prepend_Input>;
  _set?: InputMaybe<Webhook_Events_Set_Input>;
  where: Webhook_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Webhook_Events_By_PkArgs = {
  _append?: InputMaybe<Webhook_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Webhook_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Webhook_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Webhook_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Webhook_Events_Prepend_Input>;
  _set?: InputMaybe<Webhook_Events_Set_Input>;
  pk_columns: Webhook_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Webhook_Events_ManyArgs = {
  updates: Array<Webhook_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUploadSignedNachMandateArgs = {
  args: UploadSignedNachMandateInput;
};


/** mutation root */
export type Mutation_RootVerifiedSubscriptionCsvUploadArgs = {
  args: VerifiedSubscriptionCsvUploadInput;
};

export type NotifyOpsUpcomingEmIsOutput = {
  __typename?: 'notifyOpsUpcomingEMIsOutput';
  message: Scalars['String'];
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "popular_banks" */
export type Popular_Banks = {
  __typename?: 'popular_banks';
  bank_id: Scalars['Int'];
  /** An object relationship */
  ref_bank: Ref_Bank;
  sequence: Scalars['Int'];
};

/** aggregated selection of "popular_banks" */
export type Popular_Banks_Aggregate = {
  __typename?: 'popular_banks_aggregate';
  aggregate?: Maybe<Popular_Banks_Aggregate_Fields>;
  nodes: Array<Popular_Banks>;
};

/** aggregate fields of "popular_banks" */
export type Popular_Banks_Aggregate_Fields = {
  __typename?: 'popular_banks_aggregate_fields';
  avg?: Maybe<Popular_Banks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Popular_Banks_Max_Fields>;
  min?: Maybe<Popular_Banks_Min_Fields>;
  stddev?: Maybe<Popular_Banks_Stddev_Fields>;
  stddev_pop?: Maybe<Popular_Banks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Popular_Banks_Stddev_Samp_Fields>;
  sum?: Maybe<Popular_Banks_Sum_Fields>;
  var_pop?: Maybe<Popular_Banks_Var_Pop_Fields>;
  var_samp?: Maybe<Popular_Banks_Var_Samp_Fields>;
  variance?: Maybe<Popular_Banks_Variance_Fields>;
};


/** aggregate fields of "popular_banks" */
export type Popular_Banks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Popular_Banks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Popular_Banks_Avg_Fields = {
  __typename?: 'popular_banks_avg_fields';
  bank_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "popular_banks". All fields are combined with a logical 'AND'. */
export type Popular_Banks_Bool_Exp = {
  _and?: InputMaybe<Array<Popular_Banks_Bool_Exp>>;
  _not?: InputMaybe<Popular_Banks_Bool_Exp>;
  _or?: InputMaybe<Array<Popular_Banks_Bool_Exp>>;
  bank_id?: InputMaybe<Int_Comparison_Exp>;
  ref_bank?: InputMaybe<Ref_Bank_Bool_Exp>;
  sequence?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "popular_banks" */
export enum Popular_Banks_Constraint {
  /** unique or primary key constraint on columns "bank_id" */
  PopularBanksPkey = 'popular_banks_pkey'
}

/** input type for incrementing numeric columns in table "popular_banks" */
export type Popular_Banks_Inc_Input = {
  bank_id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "popular_banks" */
export type Popular_Banks_Insert_Input = {
  bank_id?: InputMaybe<Scalars['Int']>;
  ref_bank?: InputMaybe<Ref_Bank_Obj_Rel_Insert_Input>;
  sequence?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Popular_Banks_Max_Fields = {
  __typename?: 'popular_banks_max_fields';
  bank_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Popular_Banks_Min_Fields = {
  __typename?: 'popular_banks_min_fields';
  bank_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "popular_banks" */
export type Popular_Banks_Mutation_Response = {
  __typename?: 'popular_banks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Popular_Banks>;
};

/** on_conflict condition type for table "popular_banks" */
export type Popular_Banks_On_Conflict = {
  constraint: Popular_Banks_Constraint;
  update_columns?: Array<Popular_Banks_Update_Column>;
  where?: InputMaybe<Popular_Banks_Bool_Exp>;
};

/** Ordering options when selecting data from "popular_banks". */
export type Popular_Banks_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  ref_bank?: InputMaybe<Ref_Bank_Order_By>;
  sequence?: InputMaybe<Order_By>;
};

/** primary key columns input for table: popular_banks */
export type Popular_Banks_Pk_Columns_Input = {
  bank_id: Scalars['Int'];
};

/** select columns of table "popular_banks" */
export enum Popular_Banks_Select_Column {
  /** column name */
  BankId = 'bank_id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "popular_banks" */
export type Popular_Banks_Set_Input = {
  bank_id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Popular_Banks_Stddev_Fields = {
  __typename?: 'popular_banks_stddev_fields';
  bank_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Popular_Banks_Stddev_Pop_Fields = {
  __typename?: 'popular_banks_stddev_pop_fields';
  bank_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Popular_Banks_Stddev_Samp_Fields = {
  __typename?: 'popular_banks_stddev_samp_fields';
  bank_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "popular_banks" */
export type Popular_Banks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Popular_Banks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Popular_Banks_Stream_Cursor_Value_Input = {
  bank_id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Popular_Banks_Sum_Fields = {
  __typename?: 'popular_banks_sum_fields';
  bank_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** update columns of table "popular_banks" */
export enum Popular_Banks_Update_Column {
  /** column name */
  BankId = 'bank_id',
  /** column name */
  Sequence = 'sequence'
}

export type Popular_Banks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Popular_Banks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Popular_Banks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Popular_Banks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Popular_Banks_Var_Pop_Fields = {
  __typename?: 'popular_banks_var_pop_fields';
  bank_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Popular_Banks_Var_Samp_Fields = {
  __typename?: 'popular_banks_var_samp_fields';
  bank_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Popular_Banks_Variance_Fields = {
  __typename?: 'popular_banks_variance_fields';
  bank_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: Account_Aggregate;
  /** fetch data from the table: "account_balance" */
  account_balance: Array<Account_Balance>;
  /** fetch aggregated fields from the table: "account_balance" */
  account_balance_aggregate: Account_Balance_Aggregate;
  /** fetch data from the table: "account_balance" using primary key columns */
  account_balance_by_pk?: Maybe<Account_Balance>;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<Account>;
  /** fetch data from the table: "agreement_registry" */
  agreement_registry: Array<Agreement_Registry>;
  /** fetch aggregated fields from the table: "agreement_registry" */
  agreement_registry_aggregate: Agreement_Registry_Aggregate;
  /** fetch data from the table: "agreement_registry" using primary key columns */
  agreement_registry_by_pk?: Maybe<Agreement_Registry>;
  /** fetch data from the table: "agreement_tenant" */
  agreement_tenant: Array<Agreement_Tenant>;
  /** fetch aggregated fields from the table: "agreement_tenant" */
  agreement_tenant_aggregate: Agreement_Tenant_Aggregate;
  /** fetch data from the table: "agreement_tenant" using primary key columns */
  agreement_tenant_by_pk?: Maybe<Agreement_Tenant>;
  /** fetch data from the table: "application_lifecycle_stage" */
  application_lifecycle_stage: Array<Application_Lifecycle_Stage>;
  /** fetch aggregated fields from the table: "application_lifecycle_stage" */
  application_lifecycle_stage_aggregate: Application_Lifecycle_Stage_Aggregate;
  /** fetch data from the table: "application_lifecycle_stage" using primary key columns */
  application_lifecycle_stage_by_pk?: Maybe<Application_Lifecycle_Stage>;
  /** fetch data from the table: "banking_partner_integration" */
  banking_partner_integration: Array<Banking_Partner_Integration>;
  /** fetch aggregated fields from the table: "banking_partner_integration" */
  banking_partner_integration_aggregate: Banking_Partner_Integration_Aggregate;
  /** fetch data from the table: "bid" */
  bid: Array<Bid>;
  /** fetch aggregated fields from the table: "bid" */
  bid_aggregate: Bid_Aggregate;
  /** fetch data from the table: "bid" using primary key columns */
  bid_by_pk?: Maybe<Bid>;
  /** fetch data from the table: "blacklisted_tokens" */
  blacklisted_tokens: Array<Blacklisted_Tokens>;
  /** fetch aggregated fields from the table: "blacklisted_tokens" */
  blacklisted_tokens_aggregate: Blacklisted_Tokens_Aggregate;
  /** fetch data from the table: "blacklisted_tokens" using primary key columns */
  blacklisted_tokens_by_pk?: Maybe<Blacklisted_Tokens>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company_agreement_logs_view" */
  company_agreement_logs_view: Array<Company_Agreement_Logs_View>;
  /** fetch aggregated fields from the table: "company_agreement_logs_view" */
  company_agreement_logs_view_aggregate: Company_Agreement_Logs_View_Aggregate;
  /** fetch data from the table: "company_application_crime_details" */
  company_application_crime_details: Array<Company_Application_Crime_Details>;
  /** fetch aggregated fields from the table: "company_application_crime_details" */
  company_application_crime_details_aggregate: Company_Application_Crime_Details_Aggregate;
  /** fetch data from the table: "company_application_crime_details" using primary key columns */
  company_application_crime_details_by_pk?: Maybe<Company_Application_Crime_Details>;
  /** fetch data from the table: "company_application_epfo" */
  company_application_epfo: Array<Company_Application_Epfo>;
  /** fetch aggregated fields from the table: "company_application_epfo" */
  company_application_epfo_aggregate: Company_Application_Epfo_Aggregate;
  /** fetch data from the table: "company_application_epfo" using primary key columns */
  company_application_epfo_by_pk?: Maybe<Company_Application_Epfo>;
  /** fetch data from the table: "company_authorized_signatory" */
  company_authorized_signatory: Array<Company_Authorized_Signatory>;
  /** fetch aggregated fields from the table: "company_authorized_signatory" */
  company_authorized_signatory_aggregate: Company_Authorized_Signatory_Aggregate;
  /** fetch data from the table: "company_authorized_signatory" using primary key columns */
  company_authorized_signatory_by_pk?: Maybe<Company_Authorized_Signatory>;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table: "company_cin" */
  company_cin: Array<Company_Cin>;
  /** fetch aggregated fields from the table: "company_cin" */
  company_cin_aggregate: Company_Cin_Aggregate;
  /** fetch data from the table: "company_cin" using primary key columns */
  company_cin_by_pk?: Maybe<Company_Cin>;
  /** fetch data from the table: "company_email_log" */
  company_email_log: Array<Company_Email_Log>;
  /** fetch aggregated fields from the table: "company_email_log" */
  company_email_log_aggregate: Company_Email_Log_Aggregate;
  /** fetch data from the table: "company_email_log" using primary key columns */
  company_email_log_by_pk?: Maybe<Company_Email_Log>;
  /** fetch data from the table: "company_gst_return" */
  company_gst_return: Array<Company_Gst_Return>;
  /** fetch aggregated fields from the table: "company_gst_return" */
  company_gst_return_aggregate: Company_Gst_Return_Aggregate;
  /** fetch data from the table: "company_gst_return" using primary key columns */
  company_gst_return_by_pk?: Maybe<Company_Gst_Return>;
  /** fetch data from the table: "company_integration" */
  company_integration: Array<Company_Integration>;
  /** fetch aggregated fields from the table: "company_integration" */
  company_integration_aggregate: Company_Integration_Aggregate;
  /** fetch data from the table: "company_integration" using primary key columns */
  company_integration_by_pk?: Maybe<Company_Integration>;
  /** fetch data from the table: "company_integration_file" */
  company_integration_file: Array<Company_Integration_File>;
  /** fetch aggregated fields from the table: "company_integration_file" */
  company_integration_file_aggregate: Company_Integration_File_Aggregate;
  /** fetch data from the table: "company_integration_file" using primary key columns */
  company_integration_file_by_pk?: Maybe<Company_Integration_File>;
  /** fetch data from the table: "company_lead_status" */
  company_lead_status: Array<Company_Lead_Status>;
  /** fetch aggregated fields from the table: "company_lead_status" */
  company_lead_status_aggregate: Company_Lead_Status_Aggregate;
  /** fetch data from the table: "company_lead_status" using primary key columns */
  company_lead_status_by_pk?: Maybe<Company_Lead_Status>;
  /** fetch data from the table: "company_reports" */
  company_reports: Array<Company_Reports>;
  /** fetch aggregated fields from the table: "company_reports" */
  company_reports_aggregate: Company_Reports_Aggregate;
  /** fetch data from the table: "company_reports" using primary key columns */
  company_reports_by_pk?: Maybe<Company_Reports>;
  /** fetch data from the table: "company_score" */
  company_score: Array<Company_Score>;
  /** fetch aggregated fields from the table: "company_score" */
  company_score_aggregate: Company_Score_Aggregate;
  /** fetch data from the table: "company_score" using primary key columns */
  company_score_by_pk?: Maybe<Company_Score>;
  /** fetch data from the table: "company_score_factor" */
  company_score_factor: Array<Company_Score_Factor>;
  /** fetch aggregated fields from the table: "company_score_factor" */
  company_score_factor_aggregate: Company_Score_Factor_Aggregate;
  /** fetch data from the table: "company_score_factor" using primary key columns */
  company_score_factor_by_pk?: Maybe<Company_Score_Factor>;
  /** An array relationship */
  company_score_time_series: Array<Company_Score_Time_Series>;
  /** An aggregate relationship */
  company_score_time_series_aggregate: Company_Score_Time_Series_Aggregate;
  /** fetch data from the table: "company_score_time_series" using primary key columns */
  company_score_time_series_by_pk?: Maybe<Company_Score_Time_Series>;
  /** fetch data from the table: "company_statement" */
  company_statement: Array<Company_Statement>;
  /** fetch aggregated fields from the table: "company_statement" */
  company_statement_aggregate: Company_Statement_Aggregate;
  /** fetch data from the table: "company_statement" using primary key columns */
  company_statement_by_pk?: Maybe<Company_Statement>;
  /** fetch data from the table: "company_tan" */
  company_tan: Array<Company_Tan>;
  /** fetch aggregated fields from the table: "company_tan" */
  company_tan_aggregate: Company_Tan_Aggregate;
  /** fetch data from the table: "company_tan" using primary key columns */
  company_tan_by_pk?: Maybe<Company_Tan>;
  /** fetch data from the table: "company_type_factor_benchmark" */
  company_type_factor_benchmark: Array<Company_Type_Factor_Benchmark>;
  /** fetch aggregated fields from the table: "company_type_factor_benchmark" */
  company_type_factor_benchmark_aggregate: Company_Type_Factor_Benchmark_Aggregate;
  /** fetch data from the table: "company_type_factor_benchmark" using primary key columns */
  company_type_factor_benchmark_by_pk?: Maybe<Company_Type_Factor_Benchmark>;
  /** fetch data from the table: "company_user" */
  company_user: Array<Company_User>;
  /** fetch aggregated fields from the table: "company_user" */
  company_user_aggregate: Company_User_Aggregate;
  /** fetch data from the table: "company_user" using primary key columns */
  company_user_by_pk?: Maybe<Company_User>;
  /** fetch data from the table: "deal" */
  deal: Array<Deal>;
  /** fetch aggregated fields from the table: "deal" */
  deal_aggregate: Deal_Aggregate;
  /** fetch data from the table: "deal" using primary key columns */
  deal_by_pk?: Maybe<Deal>;
  /** fetch data from the table: "document_checklist" */
  document_checklist: Array<Document_Checklist>;
  /** fetch aggregated fields from the table: "document_checklist" */
  document_checklist_aggregate: Document_Checklist_Aggregate;
  /** fetch data from the table: "document_checklist" using primary key columns */
  document_checklist_by_pk?: Maybe<Document_Checklist>;
  /** fetch data from the table: "document_checklist_category" */
  document_checklist_category: Array<Document_Checklist_Category>;
  /** fetch aggregated fields from the table: "document_checklist_category" */
  document_checklist_category_aggregate: Document_Checklist_Category_Aggregate;
  /** fetch data from the table: "document_checklist_category" using primary key columns */
  document_checklist_category_by_pk?: Maybe<Document_Checklist_Category>;
  downloadPrefilledNachMandate?: Maybe<DownloadPrefilledNachMandateOutput>;
  /** fetch data from the table: "email_events" */
  email_events: Array<Email_Events>;
  /** fetch aggregated fields from the table: "email_events" */
  email_events_aggregate: Email_Events_Aggregate;
  /** fetch data from the table: "email_events" using primary key columns */
  email_events_by_pk?: Maybe<Email_Events>;
  /** An array relationship */
  emi_tds: Array<Emi_Tds>;
  /** An aggregate relationship */
  emi_tds_aggregate: Emi_Tds_Aggregate;
  /** fetch data from the table: "emi_tds" using primary key columns */
  emi_tds_by_pk?: Maybe<Emi_Tds>;
  /** fetch data from the table: "factor_benchmark_range" */
  factor_benchmark_range: Array<Factor_Benchmark_Range>;
  /** fetch aggregated fields from the table: "factor_benchmark_range" */
  factor_benchmark_range_aggregate: Factor_Benchmark_Range_Aggregate;
  /** fetch data from the table: "factor_benchmark_range" using primary key columns */
  factor_benchmark_range_by_pk?: Maybe<Factor_Benchmark_Range>;
  /** fetch data from the table: "file_registry" */
  file_registry: Array<File_Registry>;
  /** fetch aggregated fields from the table: "file_registry" */
  file_registry_aggregate: File_Registry_Aggregate;
  /** fetch data from the table: "file_registry" using primary key columns */
  file_registry_by_pk?: Maybe<File_Registry>;
  generateFileRegistryDownloadURL?: Maybe<GenerateFileRegistryDownloadUrlOutput>;
  getNachMandateProperties?: Maybe<GetNachMandatePropertiesOutput>;
  /** fetch data from the table: "integration_auth" */
  integration_auth: Array<Integration_Auth>;
  /** fetch aggregated fields from the table: "integration_auth" */
  integration_auth_aggregate: Integration_Auth_Aggregate;
  /** fetch data from the table: "integration_auth" using primary key columns */
  integration_auth_by_pk?: Maybe<Integration_Auth>;
  /** fetch data from the table: "internal_statement" */
  internal_statement: Array<Internal_Statement>;
  /** fetch aggregated fields from the table: "internal_statement" */
  internal_statement_aggregate: Internal_Statement_Aggregate;
  /** fetch data from the table: "internal_statement" using primary key columns */
  internal_statement_by_pk?: Maybe<Internal_Statement>;
  /** fetch data from the table: "investment" */
  investment: Array<Investment>;
  /** fetch aggregated fields from the table: "investment" */
  investment_aggregate: Investment_Aggregate;
  /** An array relationship */
  investment_amortization: Array<Investment_Amortization>;
  /** An aggregate relationship */
  investment_amortization_aggregate: Investment_Amortization_Aggregate;
  /** fetch data from the table: "investment" using primary key columns */
  investment_by_pk?: Maybe<Investment>;
  /** fetch data from the table: "investor" */
  investor: Array<Investor>;
  /** fetch aggregated fields from the table: "investor" */
  investor_aggregate: Investor_Aggregate;
  /** fetch data from the table: "investor" using primary key columns */
  investor_by_pk?: Maybe<Investor>;
  /** fetch data from the table: "investor_preference" */
  investor_preference: Array<Investor_Preference>;
  /** fetch aggregated fields from the table: "investor_preference" */
  investor_preference_aggregate: Investor_Preference_Aggregate;
  /** fetch data from the table: "investor_preference" using primary key columns */
  investor_preference_by_pk?: Maybe<Investor_Preference>;
  /** fetch data from the table: "investor_statement" */
  investor_statement: Array<Investor_Statement>;
  /** fetch aggregated fields from the table: "investor_statement" */
  investor_statement_aggregate: Investor_Statement_Aggregate;
  /** fetch data from the table: "investor_statement" using primary key columns */
  investor_statement_by_pk?: Maybe<Investor_Statement>;
  /** fetch data from the table: "investor_transaction" */
  investor_transaction: Array<Investor_Transaction>;
  /** fetch aggregated fields from the table: "investor_transaction" */
  investor_transaction_aggregate: Investor_Transaction_Aggregate;
  /** fetch data from the table: "investor_transaction" using primary key columns */
  investor_transaction_by_pk?: Maybe<Investor_Transaction>;
  /** execute function "investor_transaction_view" which returns "investor_transaction" */
  investor_transaction_view: Array<Investor_Transaction>;
  /** execute function "investor_transaction_view" and query aggregates on result of table type "investor_transaction" */
  investor_transaction_view_aggregate: Investor_Transaction_Aggregate;
  /** fetch data from the table: "investor_user" */
  investor_user: Array<Investor_User>;
  /** fetch aggregated fields from the table: "investor_user" */
  investor_user_aggregate: Investor_User_Aggregate;
  /** fetch data from the table: "investor_user" using primary key columns */
  investor_user_by_pk?: Maybe<Investor_User>;
  /** fetch data from the table: "lifecycle_question_crm_mapper" */
  lifecycle_question_crm_mapper: Array<Lifecycle_Question_Crm_Mapper>;
  /** fetch aggregated fields from the table: "lifecycle_question_crm_mapper" */
  lifecycle_question_crm_mapper_aggregate: Lifecycle_Question_Crm_Mapper_Aggregate;
  /** fetch data from the table: "lifecycle_question_crm_mapper" using primary key columns */
  lifecycle_question_crm_mapper_by_pk?: Maybe<Lifecycle_Question_Crm_Mapper>;
  /** fetch data from the table: "lifecycle_question_result" */
  lifecycle_question_result: Array<Lifecycle_Question_Result>;
  /** fetch aggregated fields from the table: "lifecycle_question_result" */
  lifecycle_question_result_aggregate: Lifecycle_Question_Result_Aggregate;
  /** fetch data from the table: "lifecycle_question_result" using primary key columns */
  lifecycle_question_result_by_pk?: Maybe<Lifecycle_Question_Result>;
  listIntegrations?: Maybe<Array<Maybe<ListIntegrationsOutput>>>;
  /** fetch data from the table: "mandate" */
  mandate: Array<Mandate>;
  /** fetch aggregated fields from the table: "mandate" */
  mandate_aggregate: Mandate_Aggregate;
  /** fetch data from the table: "mandate" using primary key columns */
  mandate_by_pk?: Maybe<Mandate>;
  /** fetch data from the table: "popular_banks" */
  popular_banks: Array<Popular_Banks>;
  /** fetch aggregated fields from the table: "popular_banks" */
  popular_banks_aggregate: Popular_Banks_Aggregate;
  /** fetch data from the table: "popular_banks" using primary key columns */
  popular_banks_by_pk?: Maybe<Popular_Banks>;
  /** fetch data from the table: "ref_account_status" */
  ref_account_status: Array<Ref_Account_Status>;
  /** fetch aggregated fields from the table: "ref_account_status" */
  ref_account_status_aggregate: Ref_Account_Status_Aggregate;
  /** fetch data from the table: "ref_account_status" using primary key columns */
  ref_account_status_by_pk?: Maybe<Ref_Account_Status>;
  /** fetch data from the table: "ref_account_type" */
  ref_account_type: Array<Ref_Account_Type>;
  /** fetch aggregated fields from the table: "ref_account_type" */
  ref_account_type_aggregate: Ref_Account_Type_Aggregate;
  /** fetch data from the table: "ref_account_type" using primary key columns */
  ref_account_type_by_pk?: Maybe<Ref_Account_Type>;
  /** fetch data from the table: "ref_agreement_category" */
  ref_agreement_category: Array<Ref_Agreement_Category>;
  /** fetch aggregated fields from the table: "ref_agreement_category" */
  ref_agreement_category_aggregate: Ref_Agreement_Category_Aggregate;
  /** fetch data from the table: "ref_agreement_category" using primary key columns */
  ref_agreement_category_by_pk?: Maybe<Ref_Agreement_Category>;
  /** fetch data from the table: "ref_agreement_status" */
  ref_agreement_status: Array<Ref_Agreement_Status>;
  /** fetch aggregated fields from the table: "ref_agreement_status" */
  ref_agreement_status_aggregate: Ref_Agreement_Status_Aggregate;
  /** fetch data from the table: "ref_agreement_status" using primary key columns */
  ref_agreement_status_by_pk?: Maybe<Ref_Agreement_Status>;
  /** fetch data from the table: "ref_application_allowed_district" */
  ref_application_allowed_district: Array<Ref_Application_Allowed_District>;
  /** fetch aggregated fields from the table: "ref_application_allowed_district" */
  ref_application_allowed_district_aggregate: Ref_Application_Allowed_District_Aggregate;
  /** fetch data from the table: "ref_application_allowed_district" using primary key columns */
  ref_application_allowed_district_by_pk?: Maybe<Ref_Application_Allowed_District>;
  /** fetch data from the table: "ref_application_lifecycle_stage" */
  ref_application_lifecycle_stage: Array<Ref_Application_Lifecycle_Stage>;
  /** fetch aggregated fields from the table: "ref_application_lifecycle_stage" */
  ref_application_lifecycle_stage_aggregate: Ref_Application_Lifecycle_Stage_Aggregate;
  /** fetch data from the table: "ref_application_lifecycle_stage" using primary key columns */
  ref_application_lifecycle_stage_by_pk?: Maybe<Ref_Application_Lifecycle_Stage>;
  /** fetch data from the table: "ref_bank" */
  ref_bank: Array<Ref_Bank>;
  /** fetch aggregated fields from the table: "ref_bank" */
  ref_bank_aggregate: Ref_Bank_Aggregate;
  /** fetch data from the table: "ref_bank" using primary key columns */
  ref_bank_by_pk?: Maybe<Ref_Bank>;
  /** fetch data from the table: "ref_bank_list" */
  ref_bank_list: Array<Ref_Bank_List>;
  /** fetch aggregated fields from the table: "ref_bank_list" */
  ref_bank_list_aggregate: Ref_Bank_List_Aggregate;
  /** fetch data from the table: "ref_bank_list" using primary key columns */
  ref_bank_list_by_pk?: Maybe<Ref_Bank_List>;
  /** fetch data from the table: "ref_bid" */
  ref_bid: Array<Ref_Bid>;
  /** fetch aggregated fields from the table: "ref_bid" */
  ref_bid_aggregate: Ref_Bid_Aggregate;
  /** fetch data from the table: "ref_bid" using primary key columns */
  ref_bid_by_pk?: Maybe<Ref_Bid>;
  /** fetch data from the table: "ref_billing_period" */
  ref_billing_period: Array<Ref_Billing_Period>;
  /** fetch aggregated fields from the table: "ref_billing_period" */
  ref_billing_period_aggregate: Ref_Billing_Period_Aggregate;
  /** fetch data from the table: "ref_billing_period" using primary key columns */
  ref_billing_period_by_pk?: Maybe<Ref_Billing_Period>;
  /** fetch data from the table: "ref_category" */
  ref_category: Array<Ref_Category>;
  /** fetch aggregated fields from the table: "ref_category" */
  ref_category_aggregate: Ref_Category_Aggregate;
  /** fetch data from the table: "ref_category" using primary key columns */
  ref_category_by_pk?: Maybe<Ref_Category>;
  /** fetch data from the table: "ref_collection_mode" */
  ref_collection_mode: Array<Ref_Collection_Mode>;
  /** fetch aggregated fields from the table: "ref_collection_mode" */
  ref_collection_mode_aggregate: Ref_Collection_Mode_Aggregate;
  /** fetch data from the table: "ref_collection_mode" using primary key columns */
  ref_collection_mode_by_pk?: Maybe<Ref_Collection_Mode>;
  /** fetch data from the table: "ref_company_lead_status" */
  ref_company_lead_status: Array<Ref_Company_Lead_Status>;
  /** fetch aggregated fields from the table: "ref_company_lead_status" */
  ref_company_lead_status_aggregate: Ref_Company_Lead_Status_Aggregate;
  /** fetch data from the table: "ref_company_lead_status" using primary key columns */
  ref_company_lead_status_by_pk?: Maybe<Ref_Company_Lead_Status>;
  /** fetch data from the table: "ref_company_likeliness" */
  ref_company_likeliness: Array<Ref_Company_Likeliness>;
  /** fetch aggregated fields from the table: "ref_company_likeliness" */
  ref_company_likeliness_aggregate: Ref_Company_Likeliness_Aggregate;
  /** fetch data from the table: "ref_company_likeliness" using primary key columns */
  ref_company_likeliness_by_pk?: Maybe<Ref_Company_Likeliness>;
  /** fetch data from the table: "ref_company_status" */
  ref_company_status: Array<Ref_Company_Status>;
  /** fetch aggregated fields from the table: "ref_company_status" */
  ref_company_status_aggregate: Ref_Company_Status_Aggregate;
  /** fetch data from the table: "ref_company_status" using primary key columns */
  ref_company_status_by_pk?: Maybe<Ref_Company_Status>;
  /** fetch data from the table: "ref_company_type" */
  ref_company_type: Array<Ref_Company_Type>;
  /** fetch aggregated fields from the table: "ref_company_type" */
  ref_company_type_aggregate: Ref_Company_Type_Aggregate;
  /** fetch data from the table: "ref_company_type" using primary key columns */
  ref_company_type_by_pk?: Maybe<Ref_Company_Type>;
  /** fetch data from the table: "ref_country" */
  ref_country: Array<Ref_Country>;
  /** fetch aggregated fields from the table: "ref_country" */
  ref_country_aggregate: Ref_Country_Aggregate;
  /** fetch data from the table: "ref_country" using primary key columns */
  ref_country_by_pk?: Maybe<Ref_Country>;
  /** fetch data from the table: "ref_currency" */
  ref_currency: Array<Ref_Currency>;
  /** fetch aggregated fields from the table: "ref_currency" */
  ref_currency_aggregate: Ref_Currency_Aggregate;
  /** fetch data from the table: "ref_currency" using primary key columns */
  ref_currency_by_pk?: Maybe<Ref_Currency>;
  /** fetch data from the table: "ref_debit_mode" */
  ref_debit_mode: Array<Ref_Debit_Mode>;
  /** fetch aggregated fields from the table: "ref_debit_mode" */
  ref_debit_mode_aggregate: Ref_Debit_Mode_Aggregate;
  /** fetch data from the table: "ref_debit_mode" using primary key columns */
  ref_debit_mode_by_pk?: Maybe<Ref_Debit_Mode>;
  /** fetch data from the table: "ref_document_checklist" */
  ref_document_checklist: Array<Ref_Document_Checklist>;
  /** fetch aggregated fields from the table: "ref_document_checklist" */
  ref_document_checklist_aggregate: Ref_Document_Checklist_Aggregate;
  /** fetch data from the table: "ref_document_checklist" using primary key columns */
  ref_document_checklist_by_pk?: Maybe<Ref_Document_Checklist>;
  /** fetch data from the table: "ref_document_checklist_category" */
  ref_document_checklist_category: Array<Ref_Document_Checklist_Category>;
  /** fetch aggregated fields from the table: "ref_document_checklist_category" */
  ref_document_checklist_category_aggregate: Ref_Document_Checklist_Category_Aggregate;
  /** fetch data from the table: "ref_document_checklist_category" using primary key columns */
  ref_document_checklist_category_by_pk?: Maybe<Ref_Document_Checklist_Category>;
  /** fetch data from the table: "ref_document_checklist_integration" */
  ref_document_checklist_integration: Array<Ref_Document_Checklist_Integration>;
  /** fetch aggregated fields from the table: "ref_document_checklist_integration" */
  ref_document_checklist_integration_aggregate: Ref_Document_Checklist_Integration_Aggregate;
  /** fetch data from the table: "ref_document_checklist_integration" using primary key columns */
  ref_document_checklist_integration_by_pk?: Maybe<Ref_Document_Checklist_Integration>;
  /** fetch data from the table: "ref_document_checklist_status" */
  ref_document_checklist_status: Array<Ref_Document_Checklist_Status>;
  /** fetch aggregated fields from the table: "ref_document_checklist_status" */
  ref_document_checklist_status_aggregate: Ref_Document_Checklist_Status_Aggregate;
  /** fetch data from the table: "ref_document_checklist_status" using primary key columns */
  ref_document_checklist_status_by_pk?: Maybe<Ref_Document_Checklist_Status>;
  /** fetch data from the table: "ref_email_events_type" */
  ref_email_events_type: Array<Ref_Email_Events_Type>;
  /** fetch aggregated fields from the table: "ref_email_events_type" */
  ref_email_events_type_aggregate: Ref_Email_Events_Type_Aggregate;
  /** fetch data from the table: "ref_email_events_type" using primary key columns */
  ref_email_events_type_by_pk?: Maybe<Ref_Email_Events_Type>;
  /** fetch data from the table: "ref_file_category" */
  ref_file_category: Array<Ref_File_Category>;
  /** fetch aggregated fields from the table: "ref_file_category" */
  ref_file_category_aggregate: Ref_File_Category_Aggregate;
  /** fetch data from the table: "ref_file_category" using primary key columns */
  ref_file_category_by_pk?: Maybe<Ref_File_Category>;
  /** fetch data from the table: "ref_file_category_mimetype" */
  ref_file_category_mimetype: Array<Ref_File_Category_Mimetype>;
  /** fetch aggregated fields from the table: "ref_file_category_mimetype" */
  ref_file_category_mimetype_aggregate: Ref_File_Category_Mimetype_Aggregate;
  /** fetch data from the table: "ref_file_category_mimetype" using primary key columns */
  ref_file_category_mimetype_by_pk?: Maybe<Ref_File_Category_Mimetype>;
  /** fetch data from the table: "ref_industry" */
  ref_industry: Array<Ref_Industry>;
  /** fetch aggregated fields from the table: "ref_industry" */
  ref_industry_aggregate: Ref_Industry_Aggregate;
  /** fetch data from the table: "ref_industry" using primary key columns */
  ref_industry_by_pk?: Maybe<Ref_Industry>;
  /** fetch data from the table: "ref_integration_file_status" */
  ref_integration_file_status: Array<Ref_Integration_File_Status>;
  /** fetch aggregated fields from the table: "ref_integration_file_status" */
  ref_integration_file_status_aggregate: Ref_Integration_File_Status_Aggregate;
  /** fetch data from the table: "ref_integration_file_status" using primary key columns */
  ref_integration_file_status_by_pk?: Maybe<Ref_Integration_File_Status>;
  /** fetch data from the table: "ref_integration_status" */
  ref_integration_status: Array<Ref_Integration_Status>;
  /** fetch aggregated fields from the table: "ref_integration_status" */
  ref_integration_status_aggregate: Ref_Integration_Status_Aggregate;
  /** fetch data from the table: "ref_integration_status" using primary key columns */
  ref_integration_status_by_pk?: Maybe<Ref_Integration_Status>;
  /** fetch data from the table: "ref_investor_status" */
  ref_investor_status: Array<Ref_Investor_Status>;
  /** fetch aggregated fields from the table: "ref_investor_status" */
  ref_investor_status_aggregate: Ref_Investor_Status_Aggregate;
  /** fetch data from the table: "ref_investor_status" using primary key columns */
  ref_investor_status_by_pk?: Maybe<Ref_Investor_Status>;
  /** fetch data from the table: "ref_investor_type" */
  ref_investor_type: Array<Ref_Investor_Type>;
  /** fetch aggregated fields from the table: "ref_investor_type" */
  ref_investor_type_aggregate: Ref_Investor_Type_Aggregate;
  /** fetch data from the table: "ref_investor_type" using primary key columns */
  ref_investor_type_by_pk?: Maybe<Ref_Investor_Type>;
  /** fetch data from the table: "ref_lifecycle_question" */
  ref_lifecycle_question: Array<Ref_Lifecycle_Question>;
  /** fetch aggregated fields from the table: "ref_lifecycle_question" */
  ref_lifecycle_question_aggregate: Ref_Lifecycle_Question_Aggregate;
  /** fetch data from the table: "ref_lifecycle_question" using primary key columns */
  ref_lifecycle_question_by_pk?: Maybe<Ref_Lifecycle_Question>;
  /** fetch data from the table: "ref_lifecycle_question_category" */
  ref_lifecycle_question_category: Array<Ref_Lifecycle_Question_Category>;
  /** fetch aggregated fields from the table: "ref_lifecycle_question_category" */
  ref_lifecycle_question_category_aggregate: Ref_Lifecycle_Question_Category_Aggregate;
  /** fetch data from the table: "ref_lifecycle_question_category" using primary key columns */
  ref_lifecycle_question_category_by_pk?: Maybe<Ref_Lifecycle_Question_Category>;
  /** fetch data from the table: "ref_mandate_frequency" */
  ref_mandate_frequency: Array<Ref_Mandate_Frequency>;
  /** fetch aggregated fields from the table: "ref_mandate_frequency" */
  ref_mandate_frequency_aggregate: Ref_Mandate_Frequency_Aggregate;
  /** fetch data from the table: "ref_mandate_frequency" using primary key columns */
  ref_mandate_frequency_by_pk?: Maybe<Ref_Mandate_Frequency>;
  /** fetch data from the table: "ref_mandate_status" */
  ref_mandate_status: Array<Ref_Mandate_Status>;
  /** fetch aggregated fields from the table: "ref_mandate_status" */
  ref_mandate_status_aggregate: Ref_Mandate_Status_Aggregate;
  /** fetch data from the table: "ref_mandate_status" using primary key columns */
  ref_mandate_status_by_pk?: Maybe<Ref_Mandate_Status>;
  /** fetch data from the table: "ref_mandate_type" */
  ref_mandate_type: Array<Ref_Mandate_Type>;
  /** fetch aggregated fields from the table: "ref_mandate_type" */
  ref_mandate_type_aggregate: Ref_Mandate_Type_Aggregate;
  /** fetch data from the table: "ref_mandate_type" using primary key columns */
  ref_mandate_type_by_pk?: Maybe<Ref_Mandate_Type>;
  /** fetch data from the table: "ref_perfios_bank_list" */
  ref_perfios_bank_list: Array<Ref_Perfios_Bank_List>;
  /** fetch aggregated fields from the table: "ref_perfios_bank_list" */
  ref_perfios_bank_list_aggregate: Ref_Perfios_Bank_List_Aggregate;
  /** fetch data from the table: "ref_perfios_bank_list" using primary key columns */
  ref_perfios_bank_list_by_pk?: Maybe<Ref_Perfios_Bank_List>;
  /** fetch data from the table: "ref_report_category" */
  ref_report_category: Array<Ref_Report_Category>;
  /** fetch aggregated fields from the table: "ref_report_category" */
  ref_report_category_aggregate: Ref_Report_Category_Aggregate;
  /** fetch data from the table: "ref_report_category" using primary key columns */
  ref_report_category_by_pk?: Maybe<Ref_Report_Category>;
  /** fetch data from the table: "ref_role" */
  ref_role: Array<Ref_Role>;
  /** fetch aggregated fields from the table: "ref_role" */
  ref_role_aggregate: Ref_Role_Aggregate;
  /** fetch data from the table: "ref_role" using primary key columns */
  ref_role_by_pk?: Maybe<Ref_Role>;
  /** fetch data from the table: "ref_schedule_checklist_reminder_status" */
  ref_schedule_checklist_reminder_status: Array<Ref_Schedule_Checklist_Reminder_Status>;
  /** fetch aggregated fields from the table: "ref_schedule_checklist_reminder_status" */
  ref_schedule_checklist_reminder_status_aggregate: Ref_Schedule_Checklist_Reminder_Status_Aggregate;
  /** fetch data from the table: "ref_schedule_checklist_reminder_status" using primary key columns */
  ref_schedule_checklist_reminder_status_by_pk?: Maybe<Ref_Schedule_Checklist_Reminder_Status>;
  /** fetch data from the table: "ref_score_factor" */
  ref_score_factor: Array<Ref_Score_Factor>;
  /** fetch aggregated fields from the table: "ref_score_factor" */
  ref_score_factor_aggregate: Ref_Score_Factor_Aggregate;
  /** fetch data from the table: "ref_score_factor" using primary key columns */
  ref_score_factor_by_pk?: Maybe<Ref_Score_Factor>;
  /** fetch data from the table: "ref_score_factor_category" */
  ref_score_factor_category: Array<Ref_Score_Factor_Category>;
  /** fetch aggregated fields from the table: "ref_score_factor_category" */
  ref_score_factor_category_aggregate: Ref_Score_Factor_Category_Aggregate;
  /** fetch data from the table: "ref_score_factor_category" using primary key columns */
  ref_score_factor_category_by_pk?: Maybe<Ref_Score_Factor_Category>;
  /** fetch data from the table: "ref_score_factor_unit" */
  ref_score_factor_unit: Array<Ref_Score_Factor_Unit>;
  /** fetch aggregated fields from the table: "ref_score_factor_unit" */
  ref_score_factor_unit_aggregate: Ref_Score_Factor_Unit_Aggregate;
  /** fetch data from the table: "ref_score_factor_unit" using primary key columns */
  ref_score_factor_unit_by_pk?: Maybe<Ref_Score_Factor_Unit>;
  /** fetch data from the table: "ref_source" */
  ref_source: Array<Ref_Source>;
  /** fetch aggregated fields from the table: "ref_source" */
  ref_source_aggregate: Ref_Source_Aggregate;
  /** fetch data from the table: "ref_source" using primary key columns */
  ref_source_by_pk?: Maybe<Ref_Source>;
  /** An array relationship */
  ref_states: Array<Ref_States>;
  /** An aggregate relationship */
  ref_states_aggregate: Ref_States_Aggregate;
  /** fetch data from the table: "ref_states" using primary key columns */
  ref_states_by_pk?: Maybe<Ref_States>;
  /** fetch data from the table: "ref_subscription_status" */
  ref_subscription_status: Array<Ref_Subscription_Status>;
  /** fetch aggregated fields from the table: "ref_subscription_status" */
  ref_subscription_status_aggregate: Ref_Subscription_Status_Aggregate;
  /** fetch data from the table: "ref_subscription_status" using primary key columns */
  ref_subscription_status_by_pk?: Maybe<Ref_Subscription_Status>;
  /** fetch data from the table: "ref_system_account" */
  ref_system_account: Array<Ref_System_Account>;
  /** fetch aggregated fields from the table: "ref_system_account" */
  ref_system_account_aggregate: Ref_System_Account_Aggregate;
  /** fetch data from the table: "ref_system_account" using primary key columns */
  ref_system_account_by_pk?: Maybe<Ref_System_Account>;
  /** fetch data from the table: "ref_system_account_status" */
  ref_system_account_status: Array<Ref_System_Account_Status>;
  /** fetch aggregated fields from the table: "ref_system_account_status" */
  ref_system_account_status_aggregate: Ref_System_Account_Status_Aggregate;
  /** fetch data from the table: "ref_system_account_status" using primary key columns */
  ref_system_account_status_by_pk?: Maybe<Ref_System_Account_Status>;
  /** fetch data from the table: "ref_system_account_type" */
  ref_system_account_type: Array<Ref_System_Account_Type>;
  /** fetch aggregated fields from the table: "ref_system_account_type" */
  ref_system_account_type_aggregate: Ref_System_Account_Type_Aggregate;
  /** fetch data from the table: "ref_system_account_type" using primary key columns */
  ref_system_account_type_by_pk?: Maybe<Ref_System_Account_Type>;
  /** fetch data from the table: "ref_tds_status" */
  ref_tds_status: Array<Ref_Tds_Status>;
  /** fetch aggregated fields from the table: "ref_tds_status" */
  ref_tds_status_aggregate: Ref_Tds_Status_Aggregate;
  /** fetch data from the table: "ref_tds_status" using primary key columns */
  ref_tds_status_by_pk?: Maybe<Ref_Tds_Status>;
  /** fetch data from the table: "ref_tds_value_unit" */
  ref_tds_value_unit: Array<Ref_Tds_Value_Unit>;
  /** fetch aggregated fields from the table: "ref_tds_value_unit" */
  ref_tds_value_unit_aggregate: Ref_Tds_Value_Unit_Aggregate;
  /** fetch data from the table: "ref_tds_value_unit" using primary key columns */
  ref_tds_value_unit_by_pk?: Maybe<Ref_Tds_Value_Unit>;
  /** fetch data from the table: "ref_tenant_type" */
  ref_tenant_type: Array<Ref_Tenant_Type>;
  /** fetch aggregated fields from the table: "ref_tenant_type" */
  ref_tenant_type_aggregate: Ref_Tenant_Type_Aggregate;
  /** fetch data from the table: "ref_tenant_type" using primary key columns */
  ref_tenant_type_by_pk?: Maybe<Ref_Tenant_Type>;
  /** fetch data from the table: "ref_trade_state" */
  ref_trade_state: Array<Ref_Trade_State>;
  /** fetch aggregated fields from the table: "ref_trade_state" */
  ref_trade_state_aggregate: Ref_Trade_State_Aggregate;
  /** fetch data from the table: "ref_trade_state" using primary key columns */
  ref_trade_state_by_pk?: Maybe<Ref_Trade_State>;
  /** fetch data from the table: "ref_trade_status" */
  ref_trade_status: Array<Ref_Trade_Status>;
  /** fetch aggregated fields from the table: "ref_trade_status" */
  ref_trade_status_aggregate: Ref_Trade_Status_Aggregate;
  /** fetch data from the table: "ref_trade_status" using primary key columns */
  ref_trade_status_by_pk?: Maybe<Ref_Trade_Status>;
  /** fetch data from the table: "ref_trade_withdrawal_mode" */
  ref_trade_withdrawal_mode: Array<Ref_Trade_Withdrawal_Mode>;
  /** fetch aggregated fields from the table: "ref_trade_withdrawal_mode" */
  ref_trade_withdrawal_mode_aggregate: Ref_Trade_Withdrawal_Mode_Aggregate;
  /** fetch data from the table: "ref_trade_withdrawal_mode" using primary key columns */
  ref_trade_withdrawal_mode_by_pk?: Maybe<Ref_Trade_Withdrawal_Mode>;
  /** fetch data from the table: "ref_transaction_method" */
  ref_transaction_method: Array<Ref_Transaction_Method>;
  /** fetch aggregated fields from the table: "ref_transaction_method" */
  ref_transaction_method_aggregate: Ref_Transaction_Method_Aggregate;
  /** fetch data from the table: "ref_transaction_method" using primary key columns */
  ref_transaction_method_by_pk?: Maybe<Ref_Transaction_Method>;
  /** fetch data from the table: "ref_transaction_status" */
  ref_transaction_status: Array<Ref_Transaction_Status>;
  /** fetch aggregated fields from the table: "ref_transaction_status" */
  ref_transaction_status_aggregate: Ref_Transaction_Status_Aggregate;
  /** fetch data from the table: "ref_transaction_status" using primary key columns */
  ref_transaction_status_by_pk?: Maybe<Ref_Transaction_Status>;
  /** fetch data from the table: "ref_transaction_type" */
  ref_transaction_type: Array<Ref_Transaction_Type>;
  /** fetch aggregated fields from the table: "ref_transaction_type" */
  ref_transaction_type_aggregate: Ref_Transaction_Type_Aggregate;
  /** fetch data from the table: "ref_transaction_type" using primary key columns */
  ref_transaction_type_by_pk?: Maybe<Ref_Transaction_Type>;
  /** fetch data from the table: "ref_transfer_status" */
  ref_transfer_status: Array<Ref_Transfer_Status>;
  /** fetch aggregated fields from the table: "ref_transfer_status" */
  ref_transfer_status_aggregate: Ref_Transfer_Status_Aggregate;
  /** fetch data from the table: "ref_transfer_status" using primary key columns */
  ref_transfer_status_by_pk?: Maybe<Ref_Transfer_Status>;
  /** fetch data from the table: "ref_transfer_type" */
  ref_transfer_type: Array<Ref_Transfer_Type>;
  /** fetch aggregated fields from the table: "ref_transfer_type" */
  ref_transfer_type_aggregate: Ref_Transfer_Type_Aggregate;
  /** fetch data from the table: "ref_transfer_type" using primary key columns */
  ref_transfer_type_by_pk?: Maybe<Ref_Transfer_Type>;
  /** fetch data from the table: "ref_user_status" */
  ref_user_status: Array<Ref_User_Status>;
  /** fetch aggregated fields from the table: "ref_user_status" */
  ref_user_status_aggregate: Ref_User_Status_Aggregate;
  /** fetch data from the table: "ref_user_status" using primary key columns */
  ref_user_status_by_pk?: Maybe<Ref_User_Status>;
  /** fetch data from the table: "ref_user_type" */
  ref_user_type: Array<Ref_User_Type>;
  /** fetch aggregated fields from the table: "ref_user_type" */
  ref_user_type_aggregate: Ref_User_Type_Aggregate;
  /** fetch data from the table: "ref_user_type" using primary key columns */
  ref_user_type_by_pk?: Maybe<Ref_User_Type>;
  /** fetch data from the table: "report_category" */
  report_category: Array<Report_Category>;
  /** fetch aggregated fields from the table: "report_category" */
  report_category_aggregate: Report_Category_Aggregate;
  /** fetch data from the table: "report_category" using primary key columns */
  report_category_by_pk?: Maybe<Report_Category>;
  /** fetch data from the table: "report_event" */
  report_event: Array<Report_Event>;
  /** fetch aggregated fields from the table: "report_event" */
  report_event_aggregate: Report_Event_Aggregate;
  /** fetch data from the table: "report_event" using primary key columns */
  report_event_by_pk?: Maybe<Report_Event>;
  /** fetch data from the table: "schedule_document_checklist_reminder" */
  schedule_document_checklist_reminder: Array<Schedule_Document_Checklist_Reminder>;
  /** fetch aggregated fields from the table: "schedule_document_checklist_reminder" */
  schedule_document_checklist_reminder_aggregate: Schedule_Document_Checklist_Reminder_Aggregate;
  /** fetch data from the table: "schedule_document_checklist_reminder" using primary key columns */
  schedule_document_checklist_reminder_by_pk?: Maybe<Schedule_Document_Checklist_Reminder>;
  /** fetch data from the table: "scheduled_emi" */
  scheduled_emi: Array<Scheduled_Emi>;
  /** fetch aggregated fields from the table: "scheduled_emi" */
  scheduled_emi_aggregate: Scheduled_Emi_Aggregate;
  /** fetch data from the table: "scheduled_emi" using primary key columns */
  scheduled_emi_by_pk?: Maybe<Scheduled_Emi>;
  /** fetch data from the table: "score_factor" */
  score_factor: Array<Score_Factor>;
  /** fetch aggregated fields from the table: "score_factor" */
  score_factor_aggregate: Score_Factor_Aggregate;
  /** fetch data from the table: "score_factor" using primary key columns */
  score_factor_by_pk?: Maybe<Score_Factor>;
  searchIFSC?: Maybe<SearchIfscOutput>;
  /** fetch data from the table: "staff_user" */
  staff_user: Array<Staff_User>;
  /** fetch aggregated fields from the table: "staff_user" */
  staff_user_aggregate: Staff_User_Aggregate;
  /** fetch data from the table: "staff_user" using primary key columns */
  staff_user_by_pk?: Maybe<Staff_User>;
  /** fetch data from the table: "subscription" */
  subscription: Array<Subscription>;
  /** fetch aggregated fields from the table: "subscription" */
  subscription_aggregate: Subscription_Aggregate;
  /** fetch data from the table: "subscription" using primary key columns */
  subscription_by_pk?: Maybe<Subscription>;
  /** An array relationship */
  tds: Array<Tds>;
  /** An aggregate relationship */
  tds_aggregate: Tds_Aggregate;
  /** fetch data from the table: "tds" using primary key columns */
  tds_by_pk?: Maybe<Tds>;
  /** fetch data from the table: "trade" */
  trade: Array<Trade>;
  /** fetch aggregated fields from the table: "trade" */
  trade_aggregate: Trade_Aggregate;
  /** fetch data from the table: "trade_amortization" */
  trade_amortization: Array<Trade_Amortization>;
  /** fetch aggregated fields from the table: "trade_amortization" */
  trade_amortization_aggregate: Trade_Amortization_Aggregate;
  /** fetch data from the table: "trade" using primary key columns */
  trade_by_pk?: Maybe<Trade>;
  /** fetch data from the table: "trade_subscription" */
  trade_subscription: Array<Trade_Subscription>;
  /** fetch aggregated fields from the table: "trade_subscription" */
  trade_subscription_aggregate: Trade_Subscription_Aggregate;
  /** fetch data from the table: "trade_subscription" using primary key columns */
  trade_subscription_by_pk?: Maybe<Trade_Subscription>;
  /** fetch data from the table: "transaction" */
  transaction: Array<Transaction>;
  /** fetch aggregated fields from the table: "transaction" */
  transaction_aggregate: Transaction_Aggregate;
  /** fetch data from the table: "transaction" using primary key columns */
  transaction_by_pk?: Maybe<Transaction>;
  /** fetch data from the table: "transactions_view" */
  transactions_view: Array<Transactions_View>;
  /** fetch aggregated fields from the table: "transactions_view" */
  transactions_view_aggregate: Transactions_View_Aggregate;
  /** fetch data from the table: "transfer" */
  transfer: Array<Transfer>;
  /** fetch aggregated fields from the table: "transfer" */
  transfer_aggregate: Transfer_Aggregate;
  /** fetch data from the table: "transfer" using primary key columns */
  transfer_by_pk?: Maybe<Transfer>;
  /** fetch data from the table: "updates_log" */
  updates_log: Array<Updates_Log>;
  /** fetch aggregated fields from the table: "updates_log" */
  updates_log_aggregate: Updates_Log_Aggregate;
  /** fetch data from the table: "updates_log" using primary key columns */
  updates_log_by_pk?: Maybe<Updates_Log>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "verification_code" */
  verification_code: Array<Verification_Code>;
  /** fetch aggregated fields from the table: "verification_code" */
  verification_code_aggregate: Verification_Code_Aggregate;
  /** fetch data from the table: "verification_code" using primary key columns */
  verification_code_by_pk?: Maybe<Verification_Code>;
  verifyPan?: Maybe<VerifyPanOutput>;
  /** fetch data from the table: "webhook_events" */
  webhook_events: Array<Webhook_Events>;
  /** fetch aggregated fields from the table: "webhook_events" */
  webhook_events_aggregate: Webhook_Events_Aggregate;
  /** fetch data from the table: "webhook_events" using primary key columns */
  webhook_events_by_pk?: Maybe<Webhook_Events>;
};


export type Query_RootAccountArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


export type Query_RootAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


export type Query_RootAccount_BalanceArgs = {
  distinct_on?: InputMaybe<Array<Account_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Balance_Order_By>>;
  where?: InputMaybe<Account_Balance_Bool_Exp>;
};


export type Query_RootAccount_Balance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Balance_Order_By>>;
  where?: InputMaybe<Account_Balance_Bool_Exp>;
};


export type Query_RootAccount_Balance_By_PkArgs = {
  account_id: Scalars['Int'];
};


export type Query_RootAccount_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAgreement_RegistryArgs = {
  distinct_on?: InputMaybe<Array<Agreement_Registry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Agreement_Registry_Order_By>>;
  where?: InputMaybe<Agreement_Registry_Bool_Exp>;
};


export type Query_RootAgreement_Registry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agreement_Registry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Agreement_Registry_Order_By>>;
  where?: InputMaybe<Agreement_Registry_Bool_Exp>;
};


export type Query_RootAgreement_Registry_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAgreement_TenantArgs = {
  distinct_on?: InputMaybe<Array<Agreement_Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Agreement_Tenant_Order_By>>;
  where?: InputMaybe<Agreement_Tenant_Bool_Exp>;
};


export type Query_RootAgreement_Tenant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agreement_Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Agreement_Tenant_Order_By>>;
  where?: InputMaybe<Agreement_Tenant_Bool_Exp>;
};


export type Query_RootAgreement_Tenant_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootApplication_Lifecycle_StageArgs = {
  distinct_on?: InputMaybe<Array<Application_Lifecycle_Stage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_Lifecycle_Stage_Order_By>>;
  where?: InputMaybe<Application_Lifecycle_Stage_Bool_Exp>;
};


export type Query_RootApplication_Lifecycle_Stage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Application_Lifecycle_Stage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_Lifecycle_Stage_Order_By>>;
  where?: InputMaybe<Application_Lifecycle_Stage_Bool_Exp>;
};


export type Query_RootApplication_Lifecycle_Stage_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBanking_Partner_IntegrationArgs = {
  distinct_on?: InputMaybe<Array<Banking_Partner_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Banking_Partner_Integration_Order_By>>;
  where?: InputMaybe<Banking_Partner_Integration_Bool_Exp>;
};


export type Query_RootBanking_Partner_Integration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Banking_Partner_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Banking_Partner_Integration_Order_By>>;
  where?: InputMaybe<Banking_Partner_Integration_Bool_Exp>;
};


export type Query_RootBidArgs = {
  distinct_on?: InputMaybe<Array<Bid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bid_Order_By>>;
  where?: InputMaybe<Bid_Bool_Exp>;
};


export type Query_RootBid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bid_Order_By>>;
  where?: InputMaybe<Bid_Bool_Exp>;
};


export type Query_RootBid_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBlacklisted_TokensArgs = {
  distinct_on?: InputMaybe<Array<Blacklisted_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blacklisted_Tokens_Order_By>>;
  where?: InputMaybe<Blacklisted_Tokens_Bool_Exp>;
};


export type Query_RootBlacklisted_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blacklisted_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blacklisted_Tokens_Order_By>>;
  where?: InputMaybe<Blacklisted_Tokens_Bool_Exp>;
};


export type Query_RootBlacklisted_Tokens_By_PkArgs = {
  token: Scalars['String'];
};


export type Query_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Query_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Query_RootCompany_Agreement_Logs_ViewArgs = {
  distinct_on?: InputMaybe<Array<Company_Agreement_Logs_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Agreement_Logs_View_Order_By>>;
  where?: InputMaybe<Company_Agreement_Logs_View_Bool_Exp>;
};


export type Query_RootCompany_Agreement_Logs_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Agreement_Logs_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Agreement_Logs_View_Order_By>>;
  where?: InputMaybe<Company_Agreement_Logs_View_Bool_Exp>;
};


export type Query_RootCompany_Application_Crime_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Company_Application_Crime_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Application_Crime_Details_Order_By>>;
  where?: InputMaybe<Company_Application_Crime_Details_Bool_Exp>;
};


export type Query_RootCompany_Application_Crime_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Application_Crime_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Application_Crime_Details_Order_By>>;
  where?: InputMaybe<Company_Application_Crime_Details_Bool_Exp>;
};


export type Query_RootCompany_Application_Crime_Details_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_Application_EpfoArgs = {
  distinct_on?: InputMaybe<Array<Company_Application_Epfo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Application_Epfo_Order_By>>;
  where?: InputMaybe<Company_Application_Epfo_Bool_Exp>;
};


export type Query_RootCompany_Application_Epfo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Application_Epfo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Application_Epfo_Order_By>>;
  where?: InputMaybe<Company_Application_Epfo_Bool_Exp>;
};


export type Query_RootCompany_Application_Epfo_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_Authorized_SignatoryArgs = {
  distinct_on?: InputMaybe<Array<Company_Authorized_Signatory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Authorized_Signatory_Order_By>>;
  where?: InputMaybe<Company_Authorized_Signatory_Bool_Exp>;
};


export type Query_RootCompany_Authorized_Signatory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Authorized_Signatory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Authorized_Signatory_Order_By>>;
  where?: InputMaybe<Company_Authorized_Signatory_Bool_Exp>;
};


export type Query_RootCompany_Authorized_Signatory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_CinArgs = {
  distinct_on?: InputMaybe<Array<Company_Cin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Cin_Order_By>>;
  where?: InputMaybe<Company_Cin_Bool_Exp>;
};


export type Query_RootCompany_Cin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Cin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Cin_Order_By>>;
  where?: InputMaybe<Company_Cin_Bool_Exp>;
};


export type Query_RootCompany_Cin_By_PkArgs = {
  cin: Scalars['String'];
};


export type Query_RootCompany_Email_LogArgs = {
  distinct_on?: InputMaybe<Array<Company_Email_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Email_Log_Order_By>>;
  where?: InputMaybe<Company_Email_Log_Bool_Exp>;
};


export type Query_RootCompany_Email_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Email_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Email_Log_Order_By>>;
  where?: InputMaybe<Company_Email_Log_Bool_Exp>;
};


export type Query_RootCompany_Email_Log_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_Gst_ReturnArgs = {
  distinct_on?: InputMaybe<Array<Company_Gst_Return_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Gst_Return_Order_By>>;
  where?: InputMaybe<Company_Gst_Return_Bool_Exp>;
};


export type Query_RootCompany_Gst_Return_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Gst_Return_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Gst_Return_Order_By>>;
  where?: InputMaybe<Company_Gst_Return_Bool_Exp>;
};


export type Query_RootCompany_Gst_Return_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_IntegrationArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_Order_By>>;
  where?: InputMaybe<Company_Integration_Bool_Exp>;
};


export type Query_RootCompany_Integration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_Order_By>>;
  where?: InputMaybe<Company_Integration_Bool_Exp>;
};


export type Query_RootCompany_Integration_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_Integration_FileArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_File_Order_By>>;
  where?: InputMaybe<Company_Integration_File_Bool_Exp>;
};


export type Query_RootCompany_Integration_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_File_Order_By>>;
  where?: InputMaybe<Company_Integration_File_Bool_Exp>;
};


export type Query_RootCompany_Integration_File_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_Lead_StatusArgs = {
  distinct_on?: InputMaybe<Array<Company_Lead_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Lead_Status_Order_By>>;
  where?: InputMaybe<Company_Lead_Status_Bool_Exp>;
};


export type Query_RootCompany_Lead_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Lead_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Lead_Status_Order_By>>;
  where?: InputMaybe<Company_Lead_Status_Bool_Exp>;
};


export type Query_RootCompany_Lead_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_ReportsArgs = {
  distinct_on?: InputMaybe<Array<Company_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Reports_Order_By>>;
  where?: InputMaybe<Company_Reports_Bool_Exp>;
};


export type Query_RootCompany_Reports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Reports_Order_By>>;
  where?: InputMaybe<Company_Reports_Bool_Exp>;
};


export type Query_RootCompany_Reports_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_ScoreArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Order_By>>;
  where?: InputMaybe<Company_Score_Bool_Exp>;
};


export type Query_RootCompany_Score_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Order_By>>;
  where?: InputMaybe<Company_Score_Bool_Exp>;
};


export type Query_RootCompany_Score_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_Score_FactorArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Factor_Order_By>>;
  where?: InputMaybe<Company_Score_Factor_Bool_Exp>;
};


export type Query_RootCompany_Score_Factor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Factor_Order_By>>;
  where?: InputMaybe<Company_Score_Factor_Bool_Exp>;
};


export type Query_RootCompany_Score_Factor_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_Score_Time_SeriesArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Time_Series_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Time_Series_Order_By>>;
  where?: InputMaybe<Company_Score_Time_Series_Bool_Exp>;
};


export type Query_RootCompany_Score_Time_Series_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Time_Series_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Time_Series_Order_By>>;
  where?: InputMaybe<Company_Score_Time_Series_Bool_Exp>;
};


export type Query_RootCompany_Score_Time_Series_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_StatementArgs = {
  distinct_on?: InputMaybe<Array<Company_Statement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Statement_Order_By>>;
  where?: InputMaybe<Company_Statement_Bool_Exp>;
};


export type Query_RootCompany_Statement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Statement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Statement_Order_By>>;
  where?: InputMaybe<Company_Statement_Bool_Exp>;
};


export type Query_RootCompany_Statement_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_TanArgs = {
  distinct_on?: InputMaybe<Array<Company_Tan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Tan_Order_By>>;
  where?: InputMaybe<Company_Tan_Bool_Exp>;
};


export type Query_RootCompany_Tan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Tan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Tan_Order_By>>;
  where?: InputMaybe<Company_Tan_Bool_Exp>;
};


export type Query_RootCompany_Tan_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_Type_Factor_BenchmarkArgs = {
  distinct_on?: InputMaybe<Array<Company_Type_Factor_Benchmark_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Type_Factor_Benchmark_Order_By>>;
  where?: InputMaybe<Company_Type_Factor_Benchmark_Bool_Exp>;
};


export type Query_RootCompany_Type_Factor_Benchmark_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Type_Factor_Benchmark_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Type_Factor_Benchmark_Order_By>>;
  where?: InputMaybe<Company_Type_Factor_Benchmark_Bool_Exp>;
};


export type Query_RootCompany_Type_Factor_Benchmark_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompany_UserArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};


export type Query_RootCompany_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};


export type Query_RootCompany_User_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDealArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


export type Query_RootDeal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


export type Query_RootDeal_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDocument_ChecklistArgs = {
  distinct_on?: InputMaybe<Array<Document_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Checklist_Order_By>>;
  where?: InputMaybe<Document_Checklist_Bool_Exp>;
};


export type Query_RootDocument_Checklist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Checklist_Order_By>>;
  where?: InputMaybe<Document_Checklist_Bool_Exp>;
};


export type Query_RootDocument_Checklist_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDocument_Checklist_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Document_Checklist_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Checklist_Category_Order_By>>;
  where?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
};


export type Query_RootDocument_Checklist_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Checklist_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Checklist_Category_Order_By>>;
  where?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
};


export type Query_RootDocument_Checklist_Category_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDownloadPrefilledNachMandateArgs = {
  args: DownloadPrefilledNachMandateInput;
};


export type Query_RootEmail_EventsArgs = {
  distinct_on?: InputMaybe<Array<Email_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Events_Order_By>>;
  where?: InputMaybe<Email_Events_Bool_Exp>;
};


export type Query_RootEmail_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Events_Order_By>>;
  where?: InputMaybe<Email_Events_Bool_Exp>;
};


export type Query_RootEmail_Events_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEmi_TdsArgs = {
  distinct_on?: InputMaybe<Array<Emi_Tds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emi_Tds_Order_By>>;
  where?: InputMaybe<Emi_Tds_Bool_Exp>;
};


export type Query_RootEmi_Tds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Emi_Tds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emi_Tds_Order_By>>;
  where?: InputMaybe<Emi_Tds_Bool_Exp>;
};


export type Query_RootEmi_Tds_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFactor_Benchmark_RangeArgs = {
  distinct_on?: InputMaybe<Array<Factor_Benchmark_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Factor_Benchmark_Range_Order_By>>;
  where?: InputMaybe<Factor_Benchmark_Range_Bool_Exp>;
};


export type Query_RootFactor_Benchmark_Range_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Factor_Benchmark_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Factor_Benchmark_Range_Order_By>>;
  where?: InputMaybe<Factor_Benchmark_Range_Bool_Exp>;
};


export type Query_RootFactor_Benchmark_Range_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFile_RegistryArgs = {
  distinct_on?: InputMaybe<Array<File_Registry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Registry_Order_By>>;
  where?: InputMaybe<File_Registry_Bool_Exp>;
};


export type Query_RootFile_Registry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Registry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Registry_Order_By>>;
  where?: InputMaybe<File_Registry_Bool_Exp>;
};


export type Query_RootFile_Registry_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootGenerateFileRegistryDownloadUrlArgs = {
  args: GenerateFileRegistryDownloadUrlInput;
};


export type Query_RootGetNachMandatePropertiesArgs = {
  args: GetNachMandatePropertiesInput;
};


export type Query_RootIntegration_AuthArgs = {
  distinct_on?: InputMaybe<Array<Integration_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Auth_Order_By>>;
  where?: InputMaybe<Integration_Auth_Bool_Exp>;
};


export type Query_RootIntegration_Auth_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Auth_Order_By>>;
  where?: InputMaybe<Integration_Auth_Bool_Exp>;
};


export type Query_RootIntegration_Auth_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInternal_StatementArgs = {
  distinct_on?: InputMaybe<Array<Internal_Statement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Internal_Statement_Order_By>>;
  where?: InputMaybe<Internal_Statement_Bool_Exp>;
};


export type Query_RootInternal_Statement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Internal_Statement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Internal_Statement_Order_By>>;
  where?: InputMaybe<Internal_Statement_Bool_Exp>;
};


export type Query_RootInternal_Statement_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInvestmentArgs = {
  distinct_on?: InputMaybe<Array<Investment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Order_By>>;
  where?: InputMaybe<Investment_Bool_Exp>;
};


export type Query_RootInvestment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Order_By>>;
  where?: InputMaybe<Investment_Bool_Exp>;
};


export type Query_RootInvestment_AmortizationArgs = {
  distinct_on?: InputMaybe<Array<Investment_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Amortization_Order_By>>;
  where?: InputMaybe<Investment_Amortization_Bool_Exp>;
};


export type Query_RootInvestment_Amortization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investment_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Amortization_Order_By>>;
  where?: InputMaybe<Investment_Amortization_Bool_Exp>;
};


export type Query_RootInvestment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInvestorArgs = {
  distinct_on?: InputMaybe<Array<Investor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Order_By>>;
  where?: InputMaybe<Investor_Bool_Exp>;
};


export type Query_RootInvestor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Order_By>>;
  where?: InputMaybe<Investor_Bool_Exp>;
};


export type Query_RootInvestor_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInvestor_PreferenceArgs = {
  distinct_on?: InputMaybe<Array<Investor_Preference_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Preference_Order_By>>;
  where?: InputMaybe<Investor_Preference_Bool_Exp>;
};


export type Query_RootInvestor_Preference_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investor_Preference_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Preference_Order_By>>;
  where?: InputMaybe<Investor_Preference_Bool_Exp>;
};


export type Query_RootInvestor_Preference_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInvestor_StatementArgs = {
  distinct_on?: InputMaybe<Array<Investor_Statement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Statement_Order_By>>;
  where?: InputMaybe<Investor_Statement_Bool_Exp>;
};


export type Query_RootInvestor_Statement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investor_Statement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Statement_Order_By>>;
  where?: InputMaybe<Investor_Statement_Bool_Exp>;
};


export type Query_RootInvestor_Statement_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInvestor_TransactionArgs = {
  distinct_on?: InputMaybe<Array<Investor_Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Transaction_Order_By>>;
  where?: InputMaybe<Investor_Transaction_Bool_Exp>;
};


export type Query_RootInvestor_Transaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investor_Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Transaction_Order_By>>;
  where?: InputMaybe<Investor_Transaction_Bool_Exp>;
};


export type Query_RootInvestor_Transaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInvestor_Transaction_ViewArgs = {
  distinct_on?: InputMaybe<Array<Investor_Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Transaction_Order_By>>;
  where?: InputMaybe<Investor_Transaction_Bool_Exp>;
};


export type Query_RootInvestor_Transaction_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investor_Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Transaction_Order_By>>;
  where?: InputMaybe<Investor_Transaction_Bool_Exp>;
};


export type Query_RootInvestor_UserArgs = {
  distinct_on?: InputMaybe<Array<Investor_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_User_Order_By>>;
  where?: InputMaybe<Investor_User_Bool_Exp>;
};


export type Query_RootInvestor_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investor_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_User_Order_By>>;
  where?: InputMaybe<Investor_User_Bool_Exp>;
};


export type Query_RootInvestor_User_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLifecycle_Question_Crm_MapperArgs = {
  distinct_on?: InputMaybe<Array<Lifecycle_Question_Crm_Mapper_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lifecycle_Question_Crm_Mapper_Order_By>>;
  where?: InputMaybe<Lifecycle_Question_Crm_Mapper_Bool_Exp>;
};


export type Query_RootLifecycle_Question_Crm_Mapper_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lifecycle_Question_Crm_Mapper_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lifecycle_Question_Crm_Mapper_Order_By>>;
  where?: InputMaybe<Lifecycle_Question_Crm_Mapper_Bool_Exp>;
};


export type Query_RootLifecycle_Question_Crm_Mapper_By_PkArgs = {
  serial_number: Scalars['String'];
};


export type Query_RootLifecycle_Question_ResultArgs = {
  distinct_on?: InputMaybe<Array<Lifecycle_Question_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lifecycle_Question_Result_Order_By>>;
  where?: InputMaybe<Lifecycle_Question_Result_Bool_Exp>;
};


export type Query_RootLifecycle_Question_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lifecycle_Question_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lifecycle_Question_Result_Order_By>>;
  where?: InputMaybe<Lifecycle_Question_Result_Bool_Exp>;
};


export type Query_RootLifecycle_Question_Result_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMandateArgs = {
  distinct_on?: InputMaybe<Array<Mandate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mandate_Order_By>>;
  where?: InputMaybe<Mandate_Bool_Exp>;
};


export type Query_RootMandate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mandate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mandate_Order_By>>;
  where?: InputMaybe<Mandate_Bool_Exp>;
};


export type Query_RootMandate_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPopular_BanksArgs = {
  distinct_on?: InputMaybe<Array<Popular_Banks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Popular_Banks_Order_By>>;
  where?: InputMaybe<Popular_Banks_Bool_Exp>;
};


export type Query_RootPopular_Banks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Popular_Banks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Popular_Banks_Order_By>>;
  where?: InputMaybe<Popular_Banks_Bool_Exp>;
};


export type Query_RootPopular_Banks_By_PkArgs = {
  bank_id: Scalars['Int'];
};


export type Query_RootRef_Account_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Account_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Account_Status_Order_By>>;
  where?: InputMaybe<Ref_Account_Status_Bool_Exp>;
};


export type Query_RootRef_Account_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Account_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Account_Status_Order_By>>;
  where?: InputMaybe<Ref_Account_Status_Bool_Exp>;
};


export type Query_RootRef_Account_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Account_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Account_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Account_Type_Order_By>>;
  where?: InputMaybe<Ref_Account_Type_Bool_Exp>;
};


export type Query_RootRef_Account_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Account_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Account_Type_Order_By>>;
  where?: InputMaybe<Ref_Account_Type_Bool_Exp>;
};


export type Query_RootRef_Account_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Agreement_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Agreement_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Agreement_Category_Order_By>>;
  where?: InputMaybe<Ref_Agreement_Category_Bool_Exp>;
};


export type Query_RootRef_Agreement_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Agreement_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Agreement_Category_Order_By>>;
  where?: InputMaybe<Ref_Agreement_Category_Bool_Exp>;
};


export type Query_RootRef_Agreement_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Agreement_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Agreement_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Agreement_Status_Order_By>>;
  where?: InputMaybe<Ref_Agreement_Status_Bool_Exp>;
};


export type Query_RootRef_Agreement_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Agreement_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Agreement_Status_Order_By>>;
  where?: InputMaybe<Ref_Agreement_Status_Bool_Exp>;
};


export type Query_RootRef_Agreement_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Application_Allowed_DistrictArgs = {
  distinct_on?: InputMaybe<Array<Ref_Application_Allowed_District_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Application_Allowed_District_Order_By>>;
  where?: InputMaybe<Ref_Application_Allowed_District_Bool_Exp>;
};


export type Query_RootRef_Application_Allowed_District_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Application_Allowed_District_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Application_Allowed_District_Order_By>>;
  where?: InputMaybe<Ref_Application_Allowed_District_Bool_Exp>;
};


export type Query_RootRef_Application_Allowed_District_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootRef_Application_Lifecycle_StageArgs = {
  distinct_on?: InputMaybe<Array<Ref_Application_Lifecycle_Stage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Application_Lifecycle_Stage_Order_By>>;
  where?: InputMaybe<Ref_Application_Lifecycle_Stage_Bool_Exp>;
};


export type Query_RootRef_Application_Lifecycle_Stage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Application_Lifecycle_Stage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Application_Lifecycle_Stage_Order_By>>;
  where?: InputMaybe<Ref_Application_Lifecycle_Stage_Bool_Exp>;
};


export type Query_RootRef_Application_Lifecycle_Stage_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_BankArgs = {
  distinct_on?: InputMaybe<Array<Ref_Bank_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Bank_Order_By>>;
  where?: InputMaybe<Ref_Bank_Bool_Exp>;
};


export type Query_RootRef_Bank_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Bank_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Bank_Order_By>>;
  where?: InputMaybe<Ref_Bank_Bool_Exp>;
};


export type Query_RootRef_Bank_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRef_Bank_ListArgs = {
  distinct_on?: InputMaybe<Array<Ref_Bank_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Bank_List_Order_By>>;
  where?: InputMaybe<Ref_Bank_List_Bool_Exp>;
};


export type Query_RootRef_Bank_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Bank_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Bank_List_Order_By>>;
  where?: InputMaybe<Ref_Bank_List_Bool_Exp>;
};


export type Query_RootRef_Bank_List_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRef_BidArgs = {
  distinct_on?: InputMaybe<Array<Ref_Bid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Bid_Order_By>>;
  where?: InputMaybe<Ref_Bid_Bool_Exp>;
};


export type Query_RootRef_Bid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Bid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Bid_Order_By>>;
  where?: InputMaybe<Ref_Bid_Bool_Exp>;
};


export type Query_RootRef_Bid_By_PkArgs = {
  price: Scalars['numeric'];
};


export type Query_RootRef_Billing_PeriodArgs = {
  distinct_on?: InputMaybe<Array<Ref_Billing_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Billing_Period_Order_By>>;
  where?: InputMaybe<Ref_Billing_Period_Bool_Exp>;
};


export type Query_RootRef_Billing_Period_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Billing_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Billing_Period_Order_By>>;
  where?: InputMaybe<Ref_Billing_Period_Bool_Exp>;
};


export type Query_RootRef_Billing_Period_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Category_Order_By>>;
  where?: InputMaybe<Ref_Category_Bool_Exp>;
};


export type Query_RootRef_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Category_Order_By>>;
  where?: InputMaybe<Ref_Category_Bool_Exp>;
};


export type Query_RootRef_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Collection_ModeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Collection_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Collection_Mode_Order_By>>;
  where?: InputMaybe<Ref_Collection_Mode_Bool_Exp>;
};


export type Query_RootRef_Collection_Mode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Collection_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Collection_Mode_Order_By>>;
  where?: InputMaybe<Ref_Collection_Mode_Bool_Exp>;
};


export type Query_RootRef_Collection_Mode_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Company_Lead_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Lead_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Lead_Status_Order_By>>;
  where?: InputMaybe<Ref_Company_Lead_Status_Bool_Exp>;
};


export type Query_RootRef_Company_Lead_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Lead_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Lead_Status_Order_By>>;
  where?: InputMaybe<Ref_Company_Lead_Status_Bool_Exp>;
};


export type Query_RootRef_Company_Lead_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Company_LikelinessArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Likeliness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Likeliness_Order_By>>;
  where?: InputMaybe<Ref_Company_Likeliness_Bool_Exp>;
};


export type Query_RootRef_Company_Likeliness_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Likeliness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Likeliness_Order_By>>;
  where?: InputMaybe<Ref_Company_Likeliness_Bool_Exp>;
};


export type Query_RootRef_Company_Likeliness_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Company_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Status_Order_By>>;
  where?: InputMaybe<Ref_Company_Status_Bool_Exp>;
};


export type Query_RootRef_Company_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Status_Order_By>>;
  where?: InputMaybe<Ref_Company_Status_Bool_Exp>;
};


export type Query_RootRef_Company_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Company_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Type_Order_By>>;
  where?: InputMaybe<Ref_Company_Type_Bool_Exp>;
};


export type Query_RootRef_Company_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Type_Order_By>>;
  where?: InputMaybe<Ref_Company_Type_Bool_Exp>;
};


export type Query_RootRef_Company_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_CountryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Country_Order_By>>;
  where?: InputMaybe<Ref_Country_Bool_Exp>;
};


export type Query_RootRef_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Country_Order_By>>;
  where?: InputMaybe<Ref_Country_Bool_Exp>;
};


export type Query_RootRef_Country_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRef_CurrencyArgs = {
  distinct_on?: InputMaybe<Array<Ref_Currency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Currency_Order_By>>;
  where?: InputMaybe<Ref_Currency_Bool_Exp>;
};


export type Query_RootRef_Currency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Currency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Currency_Order_By>>;
  where?: InputMaybe<Ref_Currency_Bool_Exp>;
};


export type Query_RootRef_Currency_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Debit_ModeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Debit_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Debit_Mode_Order_By>>;
  where?: InputMaybe<Ref_Debit_Mode_Bool_Exp>;
};


export type Query_RootRef_Debit_Mode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Debit_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Debit_Mode_Order_By>>;
  where?: InputMaybe<Ref_Debit_Mode_Bool_Exp>;
};


export type Query_RootRef_Debit_Mode_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Document_ChecklistArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Bool_Exp>;
};


export type Query_RootRef_Document_Checklist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Bool_Exp>;
};


export type Query_RootRef_Document_Checklist_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRef_Document_Checklist_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Category_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Category_Bool_Exp>;
};


export type Query_RootRef_Document_Checklist_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Category_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Category_Bool_Exp>;
};


export type Query_RootRef_Document_Checklist_Category_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRef_Document_Checklist_IntegrationArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Integration_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Integration_Bool_Exp>;
};


export type Query_RootRef_Document_Checklist_Integration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Integration_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Integration_Bool_Exp>;
};


export type Query_RootRef_Document_Checklist_Integration_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Document_Checklist_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Status_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Status_Bool_Exp>;
};


export type Query_RootRef_Document_Checklist_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Status_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Status_Bool_Exp>;
};


export type Query_RootRef_Document_Checklist_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Email_Events_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Email_Events_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Email_Events_Type_Order_By>>;
  where?: InputMaybe<Ref_Email_Events_Type_Bool_Exp>;
};


export type Query_RootRef_Email_Events_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Email_Events_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Email_Events_Type_Order_By>>;
  where?: InputMaybe<Ref_Email_Events_Type_Bool_Exp>;
};


export type Query_RootRef_Email_Events_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_File_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Ref_File_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_File_Category_Order_By>>;
  where?: InputMaybe<Ref_File_Category_Bool_Exp>;
};


export type Query_RootRef_File_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_File_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_File_Category_Order_By>>;
  where?: InputMaybe<Ref_File_Category_Bool_Exp>;
};


export type Query_RootRef_File_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_File_Category_MimetypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_File_Category_Mimetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_File_Category_Mimetype_Order_By>>;
  where?: InputMaybe<Ref_File_Category_Mimetype_Bool_Exp>;
};


export type Query_RootRef_File_Category_Mimetype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_File_Category_Mimetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_File_Category_Mimetype_Order_By>>;
  where?: InputMaybe<Ref_File_Category_Mimetype_Bool_Exp>;
};


export type Query_RootRef_File_Category_Mimetype_By_PkArgs = {
  id: Ref_File_Category_Enum;
};


export type Query_RootRef_IndustryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Industry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Industry_Order_By>>;
  where?: InputMaybe<Ref_Industry_Bool_Exp>;
};


export type Query_RootRef_Industry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Industry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Industry_Order_By>>;
  where?: InputMaybe<Ref_Industry_Bool_Exp>;
};


export type Query_RootRef_Industry_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Integration_File_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Integration_File_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Integration_File_Status_Order_By>>;
  where?: InputMaybe<Ref_Integration_File_Status_Bool_Exp>;
};


export type Query_RootRef_Integration_File_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Integration_File_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Integration_File_Status_Order_By>>;
  where?: InputMaybe<Ref_Integration_File_Status_Bool_Exp>;
};


export type Query_RootRef_Integration_File_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Integration_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Integration_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Integration_Status_Order_By>>;
  where?: InputMaybe<Ref_Integration_Status_Bool_Exp>;
};


export type Query_RootRef_Integration_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Integration_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Integration_Status_Order_By>>;
  where?: InputMaybe<Ref_Integration_Status_Bool_Exp>;
};


export type Query_RootRef_Integration_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Investor_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Investor_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Investor_Status_Order_By>>;
  where?: InputMaybe<Ref_Investor_Status_Bool_Exp>;
};


export type Query_RootRef_Investor_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Investor_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Investor_Status_Order_By>>;
  where?: InputMaybe<Ref_Investor_Status_Bool_Exp>;
};


export type Query_RootRef_Investor_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Investor_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Investor_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Investor_Type_Order_By>>;
  where?: InputMaybe<Ref_Investor_Type_Bool_Exp>;
};


export type Query_RootRef_Investor_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Investor_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Investor_Type_Order_By>>;
  where?: InputMaybe<Ref_Investor_Type_Bool_Exp>;
};


export type Query_RootRef_Investor_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootRef_Lifecycle_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Ref_Lifecycle_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Lifecycle_Question_Order_By>>;
  where?: InputMaybe<Ref_Lifecycle_Question_Bool_Exp>;
};


export type Query_RootRef_Lifecycle_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Lifecycle_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Lifecycle_Question_Order_By>>;
  where?: InputMaybe<Ref_Lifecycle_Question_Bool_Exp>;
};


export type Query_RootRef_Lifecycle_Question_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRef_Lifecycle_Question_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Lifecycle_Question_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Lifecycle_Question_Category_Order_By>>;
  where?: InputMaybe<Ref_Lifecycle_Question_Category_Bool_Exp>;
};


export type Query_RootRef_Lifecycle_Question_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Lifecycle_Question_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Lifecycle_Question_Category_Order_By>>;
  where?: InputMaybe<Ref_Lifecycle_Question_Category_Bool_Exp>;
};


export type Query_RootRef_Lifecycle_Question_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Mandate_FrequencyArgs = {
  distinct_on?: InputMaybe<Array<Ref_Mandate_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Mandate_Frequency_Order_By>>;
  where?: InputMaybe<Ref_Mandate_Frequency_Bool_Exp>;
};


export type Query_RootRef_Mandate_Frequency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Mandate_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Mandate_Frequency_Order_By>>;
  where?: InputMaybe<Ref_Mandate_Frequency_Bool_Exp>;
};


export type Query_RootRef_Mandate_Frequency_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Mandate_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Mandate_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Mandate_Status_Order_By>>;
  where?: InputMaybe<Ref_Mandate_Status_Bool_Exp>;
};


export type Query_RootRef_Mandate_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Mandate_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Mandate_Status_Order_By>>;
  where?: InputMaybe<Ref_Mandate_Status_Bool_Exp>;
};


export type Query_RootRef_Mandate_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Mandate_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Mandate_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Mandate_Type_Order_By>>;
  where?: InputMaybe<Ref_Mandate_Type_Bool_Exp>;
};


export type Query_RootRef_Mandate_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Mandate_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Mandate_Type_Order_By>>;
  where?: InputMaybe<Ref_Mandate_Type_Bool_Exp>;
};


export type Query_RootRef_Mandate_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Perfios_Bank_ListArgs = {
  distinct_on?: InputMaybe<Array<Ref_Perfios_Bank_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Perfios_Bank_List_Order_By>>;
  where?: InputMaybe<Ref_Perfios_Bank_List_Bool_Exp>;
};


export type Query_RootRef_Perfios_Bank_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Perfios_Bank_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Perfios_Bank_List_Order_By>>;
  where?: InputMaybe<Ref_Perfios_Bank_List_Bool_Exp>;
};


export type Query_RootRef_Perfios_Bank_List_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRef_Report_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Report_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Report_Category_Order_By>>;
  where?: InputMaybe<Ref_Report_Category_Bool_Exp>;
};


export type Query_RootRef_Report_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Report_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Report_Category_Order_By>>;
  where?: InputMaybe<Ref_Report_Category_Bool_Exp>;
};


export type Query_RootRef_Report_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_RoleArgs = {
  distinct_on?: InputMaybe<Array<Ref_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Role_Order_By>>;
  where?: InputMaybe<Ref_Role_Bool_Exp>;
};


export type Query_RootRef_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Role_Order_By>>;
  where?: InputMaybe<Ref_Role_Bool_Exp>;
};


export type Query_RootRef_Role_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Schedule_Checklist_Reminder_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Schedule_Checklist_Reminder_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Schedule_Checklist_Reminder_Status_Order_By>>;
  where?: InputMaybe<Ref_Schedule_Checklist_Reminder_Status_Bool_Exp>;
};


export type Query_RootRef_Schedule_Checklist_Reminder_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Schedule_Checklist_Reminder_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Schedule_Checklist_Reminder_Status_Order_By>>;
  where?: InputMaybe<Ref_Schedule_Checklist_Reminder_Status_Bool_Exp>;
};


export type Query_RootRef_Schedule_Checklist_Reminder_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Score_FactorArgs = {
  distinct_on?: InputMaybe<Array<Ref_Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Score_Factor_Order_By>>;
  where?: InputMaybe<Ref_Score_Factor_Bool_Exp>;
};


export type Query_RootRef_Score_Factor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Score_Factor_Order_By>>;
  where?: InputMaybe<Ref_Score_Factor_Bool_Exp>;
};


export type Query_RootRef_Score_Factor_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Score_Factor_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Score_Factor_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Score_Factor_Category_Order_By>>;
  where?: InputMaybe<Ref_Score_Factor_Category_Bool_Exp>;
};


export type Query_RootRef_Score_Factor_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Score_Factor_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Score_Factor_Category_Order_By>>;
  where?: InputMaybe<Ref_Score_Factor_Category_Bool_Exp>;
};


export type Query_RootRef_Score_Factor_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Score_Factor_UnitArgs = {
  distinct_on?: InputMaybe<Array<Ref_Score_Factor_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Score_Factor_Unit_Order_By>>;
  where?: InputMaybe<Ref_Score_Factor_Unit_Bool_Exp>;
};


export type Query_RootRef_Score_Factor_Unit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Score_Factor_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Score_Factor_Unit_Order_By>>;
  where?: InputMaybe<Ref_Score_Factor_Unit_Bool_Exp>;
};


export type Query_RootRef_Score_Factor_Unit_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_SourceArgs = {
  distinct_on?: InputMaybe<Array<Ref_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Source_Order_By>>;
  where?: InputMaybe<Ref_Source_Bool_Exp>;
};


export type Query_RootRef_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Source_Order_By>>;
  where?: InputMaybe<Ref_Source_Bool_Exp>;
};


export type Query_RootRef_Source_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_StatesArgs = {
  distinct_on?: InputMaybe<Array<Ref_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_States_Order_By>>;
  where?: InputMaybe<Ref_States_Bool_Exp>;
};


export type Query_RootRef_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_States_Order_By>>;
  where?: InputMaybe<Ref_States_Bool_Exp>;
};


export type Query_RootRef_States_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRef_Subscription_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Subscription_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Subscription_Status_Order_By>>;
  where?: InputMaybe<Ref_Subscription_Status_Bool_Exp>;
};


export type Query_RootRef_Subscription_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Subscription_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Subscription_Status_Order_By>>;
  where?: InputMaybe<Ref_Subscription_Status_Bool_Exp>;
};


export type Query_RootRef_Subscription_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_System_AccountArgs = {
  distinct_on?: InputMaybe<Array<Ref_System_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_System_Account_Order_By>>;
  where?: InputMaybe<Ref_System_Account_Bool_Exp>;
};


export type Query_RootRef_System_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_System_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_System_Account_Order_By>>;
  where?: InputMaybe<Ref_System_Account_Bool_Exp>;
};


export type Query_RootRef_System_Account_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRef_System_Account_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_System_Account_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_System_Account_Status_Order_By>>;
  where?: InputMaybe<Ref_System_Account_Status_Bool_Exp>;
};


export type Query_RootRef_System_Account_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_System_Account_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_System_Account_Status_Order_By>>;
  where?: InputMaybe<Ref_System_Account_Status_Bool_Exp>;
};


export type Query_RootRef_System_Account_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_System_Account_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_System_Account_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_System_Account_Type_Order_By>>;
  where?: InputMaybe<Ref_System_Account_Type_Bool_Exp>;
};


export type Query_RootRef_System_Account_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_System_Account_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_System_Account_Type_Order_By>>;
  where?: InputMaybe<Ref_System_Account_Type_Bool_Exp>;
};


export type Query_RootRef_System_Account_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Tds_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Tds_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Tds_Status_Order_By>>;
  where?: InputMaybe<Ref_Tds_Status_Bool_Exp>;
};


export type Query_RootRef_Tds_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Tds_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Tds_Status_Order_By>>;
  where?: InputMaybe<Ref_Tds_Status_Bool_Exp>;
};


export type Query_RootRef_Tds_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Tds_Value_UnitArgs = {
  distinct_on?: InputMaybe<Array<Ref_Tds_Value_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Tds_Value_Unit_Order_By>>;
  where?: InputMaybe<Ref_Tds_Value_Unit_Bool_Exp>;
};


export type Query_RootRef_Tds_Value_Unit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Tds_Value_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Tds_Value_Unit_Order_By>>;
  where?: InputMaybe<Ref_Tds_Value_Unit_Bool_Exp>;
};


export type Query_RootRef_Tds_Value_Unit_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Tenant_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Tenant_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Tenant_Type_Order_By>>;
  where?: InputMaybe<Ref_Tenant_Type_Bool_Exp>;
};


export type Query_RootRef_Tenant_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Tenant_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Tenant_Type_Order_By>>;
  where?: InputMaybe<Ref_Tenant_Type_Bool_Exp>;
};


export type Query_RootRef_Tenant_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Trade_StateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Trade_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Trade_State_Order_By>>;
  where?: InputMaybe<Ref_Trade_State_Bool_Exp>;
};


export type Query_RootRef_Trade_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Trade_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Trade_State_Order_By>>;
  where?: InputMaybe<Ref_Trade_State_Bool_Exp>;
};


export type Query_RootRef_Trade_State_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Trade_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Trade_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Trade_Status_Order_By>>;
  where?: InputMaybe<Ref_Trade_Status_Bool_Exp>;
};


export type Query_RootRef_Trade_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Trade_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Trade_Status_Order_By>>;
  where?: InputMaybe<Ref_Trade_Status_Bool_Exp>;
};


export type Query_RootRef_Trade_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Trade_Withdrawal_ModeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Trade_Withdrawal_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Trade_Withdrawal_Mode_Order_By>>;
  where?: InputMaybe<Ref_Trade_Withdrawal_Mode_Bool_Exp>;
};


export type Query_RootRef_Trade_Withdrawal_Mode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Trade_Withdrawal_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Trade_Withdrawal_Mode_Order_By>>;
  where?: InputMaybe<Ref_Trade_Withdrawal_Mode_Bool_Exp>;
};


export type Query_RootRef_Trade_Withdrawal_Mode_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Transaction_MethodArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transaction_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transaction_Method_Order_By>>;
  where?: InputMaybe<Ref_Transaction_Method_Bool_Exp>;
};


export type Query_RootRef_Transaction_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transaction_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transaction_Method_Order_By>>;
  where?: InputMaybe<Ref_Transaction_Method_Bool_Exp>;
};


export type Query_RootRef_Transaction_Method_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Transaction_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transaction_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transaction_Status_Order_By>>;
  where?: InputMaybe<Ref_Transaction_Status_Bool_Exp>;
};


export type Query_RootRef_Transaction_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transaction_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transaction_Status_Order_By>>;
  where?: InputMaybe<Ref_Transaction_Status_Bool_Exp>;
};


export type Query_RootRef_Transaction_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Transaction_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transaction_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transaction_Type_Order_By>>;
  where?: InputMaybe<Ref_Transaction_Type_Bool_Exp>;
};


export type Query_RootRef_Transaction_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transaction_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transaction_Type_Order_By>>;
  where?: InputMaybe<Ref_Transaction_Type_Bool_Exp>;
};


export type Query_RootRef_Transaction_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Transfer_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transfer_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transfer_Status_Order_By>>;
  where?: InputMaybe<Ref_Transfer_Status_Bool_Exp>;
};


export type Query_RootRef_Transfer_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transfer_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transfer_Status_Order_By>>;
  where?: InputMaybe<Ref_Transfer_Status_Bool_Exp>;
};


export type Query_RootRef_Transfer_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_Transfer_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transfer_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transfer_Type_Order_By>>;
  where?: InputMaybe<Ref_Transfer_Type_Bool_Exp>;
};


export type Query_RootRef_Transfer_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transfer_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transfer_Type_Order_By>>;
  where?: InputMaybe<Ref_Transfer_Type_Bool_Exp>;
};


export type Query_RootRef_Transfer_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_User_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_User_Status_Order_By>>;
  where?: InputMaybe<Ref_User_Status_Bool_Exp>;
};


export type Query_RootRef_User_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_User_Status_Order_By>>;
  where?: InputMaybe<Ref_User_Status_Bool_Exp>;
};


export type Query_RootRef_User_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRef_User_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_User_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_User_Type_Order_By>>;
  where?: InputMaybe<Ref_User_Type_Bool_Exp>;
};


export type Query_RootRef_User_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_User_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_User_Type_Order_By>>;
  where?: InputMaybe<Ref_User_Type_Bool_Exp>;
};


export type Query_RootRef_User_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootReport_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Report_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Report_Category_Order_By>>;
  where?: InputMaybe<Report_Category_Bool_Exp>;
};


export type Query_RootReport_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Report_Category_Order_By>>;
  where?: InputMaybe<Report_Category_Bool_Exp>;
};


export type Query_RootReport_Category_By_PkArgs = {
  ref_report_category_id: Ref_Report_Category_Enum;
};


export type Query_RootReport_EventArgs = {
  distinct_on?: InputMaybe<Array<Report_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Report_Event_Order_By>>;
  where?: InputMaybe<Report_Event_Bool_Exp>;
};


export type Query_RootReport_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Report_Event_Order_By>>;
  where?: InputMaybe<Report_Event_Bool_Exp>;
};


export type Query_RootReport_Event_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSchedule_Document_Checklist_ReminderArgs = {
  distinct_on?: InputMaybe<Array<Schedule_Document_Checklist_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Schedule_Document_Checklist_Reminder_Order_By>>;
  where?: InputMaybe<Schedule_Document_Checklist_Reminder_Bool_Exp>;
};


export type Query_RootSchedule_Document_Checklist_Reminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Schedule_Document_Checklist_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Schedule_Document_Checklist_Reminder_Order_By>>;
  where?: InputMaybe<Schedule_Document_Checklist_Reminder_Bool_Exp>;
};


export type Query_RootSchedule_Document_Checklist_Reminder_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootScheduled_EmiArgs = {
  distinct_on?: InputMaybe<Array<Scheduled_Emi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scheduled_Emi_Order_By>>;
  where?: InputMaybe<Scheduled_Emi_Bool_Exp>;
};


export type Query_RootScheduled_Emi_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scheduled_Emi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scheduled_Emi_Order_By>>;
  where?: InputMaybe<Scheduled_Emi_Bool_Exp>;
};


export type Query_RootScheduled_Emi_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootScore_FactorArgs = {
  distinct_on?: InputMaybe<Array<Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Score_Factor_Order_By>>;
  where?: InputMaybe<Score_Factor_Bool_Exp>;
};


export type Query_RootScore_Factor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Score_Factor_Order_By>>;
  where?: InputMaybe<Score_Factor_Bool_Exp>;
};


export type Query_RootScore_Factor_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSearchIfscArgs = {
  args: SearchIfscInput;
};


export type Query_RootStaff_UserArgs = {
  distinct_on?: InputMaybe<Array<Staff_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_User_Order_By>>;
  where?: InputMaybe<Staff_User_Bool_Exp>;
};


export type Query_RootStaff_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_User_Order_By>>;
  where?: InputMaybe<Staff_User_Bool_Exp>;
};


export type Query_RootStaff_User_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Query_RootSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Query_RootSubscription_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTdsArgs = {
  distinct_on?: InputMaybe<Array<Tds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tds_Order_By>>;
  where?: InputMaybe<Tds_Bool_Exp>;
};


export type Query_RootTds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tds_Order_By>>;
  where?: InputMaybe<Tds_Bool_Exp>;
};


export type Query_RootTds_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTradeArgs = {
  distinct_on?: InputMaybe<Array<Trade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Order_By>>;
  where?: InputMaybe<Trade_Bool_Exp>;
};


export type Query_RootTrade_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Order_By>>;
  where?: InputMaybe<Trade_Bool_Exp>;
};


export type Query_RootTrade_AmortizationArgs = {
  distinct_on?: InputMaybe<Array<Trade_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Amortization_Order_By>>;
  where?: InputMaybe<Trade_Amortization_Bool_Exp>;
};


export type Query_RootTrade_Amortization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trade_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Amortization_Order_By>>;
  where?: InputMaybe<Trade_Amortization_Bool_Exp>;
};


export type Query_RootTrade_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTrade_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Trade_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Subscription_Order_By>>;
  where?: InputMaybe<Trade_Subscription_Bool_Exp>;
};


export type Query_RootTrade_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trade_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Subscription_Order_By>>;
  where?: InputMaybe<Trade_Subscription_Bool_Exp>;
};


export type Query_RootTrade_Subscription_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTransactionArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};


export type Query_RootTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};


export type Query_RootTransaction_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTransactions_ViewArgs = {
  distinct_on?: InputMaybe<Array<Transactions_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transactions_View_Order_By>>;
  where?: InputMaybe<Transactions_View_Bool_Exp>;
};


export type Query_RootTransactions_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transactions_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transactions_View_Order_By>>;
  where?: InputMaybe<Transactions_View_Bool_Exp>;
};


export type Query_RootTransferArgs = {
  distinct_on?: InputMaybe<Array<Transfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transfer_Order_By>>;
  where?: InputMaybe<Transfer_Bool_Exp>;
};


export type Query_RootTransfer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transfer_Order_By>>;
  where?: InputMaybe<Transfer_Bool_Exp>;
};


export type Query_RootTransfer_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUpdates_LogArgs = {
  distinct_on?: InputMaybe<Array<Updates_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Updates_Log_Order_By>>;
  where?: InputMaybe<Updates_Log_Bool_Exp>;
};


export type Query_RootUpdates_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Updates_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Updates_Log_Order_By>>;
  where?: InputMaybe<Updates_Log_Bool_Exp>;
};


export type Query_RootUpdates_Log_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVerification_CodeArgs = {
  distinct_on?: InputMaybe<Array<Verification_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verification_Code_Order_By>>;
  where?: InputMaybe<Verification_Code_Bool_Exp>;
};


export type Query_RootVerification_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verification_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verification_Code_Order_By>>;
  where?: InputMaybe<Verification_Code_Bool_Exp>;
};


export type Query_RootVerification_Code_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVerifyPanArgs = {
  args: VerifyPanInput;
};


export type Query_RootWebhook_EventsArgs = {
  distinct_on?: InputMaybe<Array<Webhook_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webhook_Events_Order_By>>;
  where?: InputMaybe<Webhook_Events_Bool_Exp>;
};


export type Query_RootWebhook_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webhook_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webhook_Events_Order_By>>;
  where?: InputMaybe<Webhook_Events_Bool_Exp>;
};


export type Query_RootWebhook_Events_By_PkArgs = {
  id: Scalars['uuid'];
};

export type ReQueueCompanyIntegrationInput = {
  id: Scalars['Int'];
};

export type ReQueueCompanyIntegrationOutput = {
  __typename?: 'reQueueCompanyIntegrationOutput';
  id: Scalars['Int'];
};

/** columns and relationships of "ref_account_status" */
export type Ref_Account_Status = {
  __typename?: 'ref_account_status';
  desc: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_account_status" */
export type Ref_Account_Status_Aggregate = {
  __typename?: 'ref_account_status_aggregate';
  aggregate?: Maybe<Ref_Account_Status_Aggregate_Fields>;
  nodes: Array<Ref_Account_Status>;
};

/** aggregate fields of "ref_account_status" */
export type Ref_Account_Status_Aggregate_Fields = {
  __typename?: 'ref_account_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Account_Status_Max_Fields>;
  min?: Maybe<Ref_Account_Status_Min_Fields>;
};


/** aggregate fields of "ref_account_status" */
export type Ref_Account_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Account_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_account_status". All fields are combined with a logical 'AND'. */
export type Ref_Account_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Account_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_Account_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Account_Status_Bool_Exp>>;
  desc?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_account_status" */
export enum Ref_Account_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefAccountStatusPkey = 'ref_account_status_pkey'
}

export enum Ref_Account_Status_Enum {
  /** active account */
  Active = 'ACTIVE',
  /** in active account */
  InActive = 'IN_ACTIVE'
}

/** Boolean expression to compare columns of type "ref_account_status_enum". All fields are combined with logical 'AND'. */
export type Ref_Account_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Account_Status_Enum>;
  _in?: InputMaybe<Array<Ref_Account_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Account_Status_Enum>;
  _nin?: InputMaybe<Array<Ref_Account_Status_Enum>>;
};

/** input type for inserting data into table "ref_account_status" */
export type Ref_Account_Status_Insert_Input = {
  desc?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Account_Status_Max_Fields = {
  __typename?: 'ref_account_status_max_fields';
  desc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Account_Status_Min_Fields = {
  __typename?: 'ref_account_status_min_fields';
  desc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_account_status" */
export type Ref_Account_Status_Mutation_Response = {
  __typename?: 'ref_account_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Account_Status>;
};

/** on_conflict condition type for table "ref_account_status" */
export type Ref_Account_Status_On_Conflict = {
  constraint: Ref_Account_Status_Constraint;
  update_columns?: Array<Ref_Account_Status_Update_Column>;
  where?: InputMaybe<Ref_Account_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_account_status". */
export type Ref_Account_Status_Order_By = {
  desc?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_account_status */
export type Ref_Account_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_account_status" */
export enum Ref_Account_Status_Select_Column {
  /** column name */
  Desc = 'desc',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_account_status" */
export type Ref_Account_Status_Set_Input = {
  desc?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_account_status" */
export type Ref_Account_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Account_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Account_Status_Stream_Cursor_Value_Input = {
  desc?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_account_status" */
export enum Ref_Account_Status_Update_Column {
  /** column name */
  Desc = 'desc',
  /** column name */
  Id = 'id'
}

export type Ref_Account_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Account_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Account_Status_Bool_Exp;
};

/** columns and relationships of "ref_account_type" */
export type Ref_Account_Type = {
  __typename?: 'ref_account_type';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_account_type" */
export type Ref_Account_Type_Aggregate = {
  __typename?: 'ref_account_type_aggregate';
  aggregate?: Maybe<Ref_Account_Type_Aggregate_Fields>;
  nodes: Array<Ref_Account_Type>;
};

/** aggregate fields of "ref_account_type" */
export type Ref_Account_Type_Aggregate_Fields = {
  __typename?: 'ref_account_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Account_Type_Max_Fields>;
  min?: Maybe<Ref_Account_Type_Min_Fields>;
};


/** aggregate fields of "ref_account_type" */
export type Ref_Account_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Account_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_account_type". All fields are combined with a logical 'AND'. */
export type Ref_Account_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Account_Type_Bool_Exp>>;
  _not?: InputMaybe<Ref_Account_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Account_Type_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_account_type" */
export enum Ref_Account_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefAccountTypePkey = 'ref_account_type_pkey'
}

export enum Ref_Account_Type_Enum {
  /** CC */
  CashCredit = 'CASH_CREDIT',
  /** CURRENT */
  Current = 'CURRENT',
  /** EFC */
  Efc = 'EFC',
  /** OD */
  Overdraft = 'OVERDRAFT',
  /** SAVINGS */
  Savings = 'SAVINGS',
  /** VIRTUAL */
  Virtual = 'VIRTUAL'
}

/** Boolean expression to compare columns of type "ref_account_type_enum". All fields are combined with logical 'AND'. */
export type Ref_Account_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Account_Type_Enum>;
  _in?: InputMaybe<Array<Ref_Account_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Account_Type_Enum>;
  _nin?: InputMaybe<Array<Ref_Account_Type_Enum>>;
};

/** input type for inserting data into table "ref_account_type" */
export type Ref_Account_Type_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Account_Type_Max_Fields = {
  __typename?: 'ref_account_type_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Account_Type_Min_Fields = {
  __typename?: 'ref_account_type_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_account_type" */
export type Ref_Account_Type_Mutation_Response = {
  __typename?: 'ref_account_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Account_Type>;
};

/** on_conflict condition type for table "ref_account_type" */
export type Ref_Account_Type_On_Conflict = {
  constraint: Ref_Account_Type_Constraint;
  update_columns?: Array<Ref_Account_Type_Update_Column>;
  where?: InputMaybe<Ref_Account_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_account_type". */
export type Ref_Account_Type_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_account_type */
export type Ref_Account_Type_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_account_type" */
export enum Ref_Account_Type_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_account_type" */
export type Ref_Account_Type_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_account_type" */
export type Ref_Account_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Account_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Account_Type_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_account_type" */
export enum Ref_Account_Type_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Account_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Account_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Account_Type_Bool_Exp;
};

/** columns and relationships of "ref_agreement_category" */
export type Ref_Agreement_Category = {
  __typename?: 'ref_agreement_category';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_agreement_category" */
export type Ref_Agreement_Category_Aggregate = {
  __typename?: 'ref_agreement_category_aggregate';
  aggregate?: Maybe<Ref_Agreement_Category_Aggregate_Fields>;
  nodes: Array<Ref_Agreement_Category>;
};

/** aggregate fields of "ref_agreement_category" */
export type Ref_Agreement_Category_Aggregate_Fields = {
  __typename?: 'ref_agreement_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Agreement_Category_Max_Fields>;
  min?: Maybe<Ref_Agreement_Category_Min_Fields>;
};


/** aggregate fields of "ref_agreement_category" */
export type Ref_Agreement_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Agreement_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_agreement_category". All fields are combined with a logical 'AND'. */
export type Ref_Agreement_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Agreement_Category_Bool_Exp>>;
  _not?: InputMaybe<Ref_Agreement_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Agreement_Category_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_agreement_category" */
export enum Ref_Agreement_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefAgreementCategoryPkey = 'ref_agreement_category_pkey'
}

export enum Ref_Agreement_Category_Enum {
  /** Assets Purchase Agreement */
  Apa = 'APA',
  /** Non Disclosure Agreement via Clickwrap */
  NdaClickwrap = 'NDA_CLICKWRAP',
  /** Manual Non Disclosure Agreement */
  NdaManual = 'NDA_MANUAL',
  /** Online Non Disclosure Agreement */
  NdaOnline = 'NDA_ONLINE',
  /** Company Client Agreement */
  TenantAgreement = 'TENANT_AGREEMENT'
}

/** Boolean expression to compare columns of type "ref_agreement_category_enum". All fields are combined with logical 'AND'. */
export type Ref_Agreement_Category_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Agreement_Category_Enum>;
  _in?: InputMaybe<Array<Ref_Agreement_Category_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Agreement_Category_Enum>;
  _nin?: InputMaybe<Array<Ref_Agreement_Category_Enum>>;
};

/** input type for inserting data into table "ref_agreement_category" */
export type Ref_Agreement_Category_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Agreement_Category_Max_Fields = {
  __typename?: 'ref_agreement_category_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Agreement_Category_Min_Fields = {
  __typename?: 'ref_agreement_category_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_agreement_category" */
export type Ref_Agreement_Category_Mutation_Response = {
  __typename?: 'ref_agreement_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Agreement_Category>;
};

/** input type for inserting object relation for remote table "ref_agreement_category" */
export type Ref_Agreement_Category_Obj_Rel_Insert_Input = {
  data: Ref_Agreement_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Agreement_Category_On_Conflict>;
};

/** on_conflict condition type for table "ref_agreement_category" */
export type Ref_Agreement_Category_On_Conflict = {
  constraint: Ref_Agreement_Category_Constraint;
  update_columns?: Array<Ref_Agreement_Category_Update_Column>;
  where?: InputMaybe<Ref_Agreement_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_agreement_category". */
export type Ref_Agreement_Category_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_agreement_category */
export type Ref_Agreement_Category_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_agreement_category" */
export enum Ref_Agreement_Category_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_agreement_category" */
export type Ref_Agreement_Category_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_agreement_category" */
export type Ref_Agreement_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Agreement_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Agreement_Category_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_agreement_category" */
export enum Ref_Agreement_Category_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Agreement_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Agreement_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Agreement_Category_Bool_Exp;
};

/** columns and relationships of "ref_agreement_status" */
export type Ref_Agreement_Status = {
  __typename?: 'ref_agreement_status';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_agreement_status" */
export type Ref_Agreement_Status_Aggregate = {
  __typename?: 'ref_agreement_status_aggregate';
  aggregate?: Maybe<Ref_Agreement_Status_Aggregate_Fields>;
  nodes: Array<Ref_Agreement_Status>;
};

/** aggregate fields of "ref_agreement_status" */
export type Ref_Agreement_Status_Aggregate_Fields = {
  __typename?: 'ref_agreement_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Agreement_Status_Max_Fields>;
  min?: Maybe<Ref_Agreement_Status_Min_Fields>;
};


/** aggregate fields of "ref_agreement_status" */
export type Ref_Agreement_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Agreement_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_agreement_status". All fields are combined with a logical 'AND'. */
export type Ref_Agreement_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Agreement_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_Agreement_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Agreement_Status_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_agreement_status" */
export enum Ref_Agreement_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefAgreementStatusPkey = 'ref_agreement_status_pkey'
}

export enum Ref_Agreement_Status_Enum {
  /** Agreement is Cancelled */
  Cancelled = 'CANCELLED',
  /** Agreement in Draft Status */
  Draft = 'DRAFT',
  /** Agreement Failed */
  Failed = 'FAILED',
  /** Agreement is Initiated */
  Initiated = 'INITIATED',
  /** Agreement is Partially Signed */
  PartialSigned = 'PARTIAL_SIGNED',
  /** Agreement is Signed */
  Signed = 'SIGNED'
}

/** Boolean expression to compare columns of type "ref_agreement_status_enum". All fields are combined with logical 'AND'. */
export type Ref_Agreement_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Agreement_Status_Enum>;
  _in?: InputMaybe<Array<Ref_Agreement_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Agreement_Status_Enum>;
  _nin?: InputMaybe<Array<Ref_Agreement_Status_Enum>>;
};

/** input type for inserting data into table "ref_agreement_status" */
export type Ref_Agreement_Status_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Agreement_Status_Max_Fields = {
  __typename?: 'ref_agreement_status_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Agreement_Status_Min_Fields = {
  __typename?: 'ref_agreement_status_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_agreement_status" */
export type Ref_Agreement_Status_Mutation_Response = {
  __typename?: 'ref_agreement_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Agreement_Status>;
};

/** input type for inserting object relation for remote table "ref_agreement_status" */
export type Ref_Agreement_Status_Obj_Rel_Insert_Input = {
  data: Ref_Agreement_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Agreement_Status_On_Conflict>;
};

/** on_conflict condition type for table "ref_agreement_status" */
export type Ref_Agreement_Status_On_Conflict = {
  constraint: Ref_Agreement_Status_Constraint;
  update_columns?: Array<Ref_Agreement_Status_Update_Column>;
  where?: InputMaybe<Ref_Agreement_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_agreement_status". */
export type Ref_Agreement_Status_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_agreement_status */
export type Ref_Agreement_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_agreement_status" */
export enum Ref_Agreement_Status_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_agreement_status" */
export type Ref_Agreement_Status_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_agreement_status" */
export type Ref_Agreement_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Agreement_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Agreement_Status_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_agreement_status" */
export enum Ref_Agreement_Status_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Agreement_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Agreement_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Agreement_Status_Bool_Exp;
};

/** columns and relationships of "ref_application_allowed_district" */
export type Ref_Application_Allowed_District = {
  __typename?: 'ref_application_allowed_district';
  name: Scalars['String'];
};

/** aggregated selection of "ref_application_allowed_district" */
export type Ref_Application_Allowed_District_Aggregate = {
  __typename?: 'ref_application_allowed_district_aggregate';
  aggregate?: Maybe<Ref_Application_Allowed_District_Aggregate_Fields>;
  nodes: Array<Ref_Application_Allowed_District>;
};

/** aggregate fields of "ref_application_allowed_district" */
export type Ref_Application_Allowed_District_Aggregate_Fields = {
  __typename?: 'ref_application_allowed_district_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Application_Allowed_District_Max_Fields>;
  min?: Maybe<Ref_Application_Allowed_District_Min_Fields>;
};


/** aggregate fields of "ref_application_allowed_district" */
export type Ref_Application_Allowed_District_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Application_Allowed_District_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_application_allowed_district". All fields are combined with a logical 'AND'. */
export type Ref_Application_Allowed_District_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Application_Allowed_District_Bool_Exp>>;
  _not?: InputMaybe<Ref_Application_Allowed_District_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Application_Allowed_District_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_application_allowed_district" */
export enum Ref_Application_Allowed_District_Constraint {
  /** unique or primary key constraint on columns "name" */
  RefApplicationAllowedDistrictPkey = 'ref_application_allowed_district_pkey'
}

/** input type for inserting data into table "ref_application_allowed_district" */
export type Ref_Application_Allowed_District_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Application_Allowed_District_Max_Fields = {
  __typename?: 'ref_application_allowed_district_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Application_Allowed_District_Min_Fields = {
  __typename?: 'ref_application_allowed_district_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_application_allowed_district" */
export type Ref_Application_Allowed_District_Mutation_Response = {
  __typename?: 'ref_application_allowed_district_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Application_Allowed_District>;
};

/** on_conflict condition type for table "ref_application_allowed_district" */
export type Ref_Application_Allowed_District_On_Conflict = {
  constraint: Ref_Application_Allowed_District_Constraint;
  update_columns?: Array<Ref_Application_Allowed_District_Update_Column>;
  where?: InputMaybe<Ref_Application_Allowed_District_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_application_allowed_district". */
export type Ref_Application_Allowed_District_Order_By = {
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_application_allowed_district */
export type Ref_Application_Allowed_District_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "ref_application_allowed_district" */
export enum Ref_Application_Allowed_District_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_application_allowed_district" */
export type Ref_Application_Allowed_District_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_application_allowed_district" */
export type Ref_Application_Allowed_District_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Application_Allowed_District_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Application_Allowed_District_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_application_allowed_district" */
export enum Ref_Application_Allowed_District_Update_Column {
  /** column name */
  Name = 'name'
}

export type Ref_Application_Allowed_District_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Application_Allowed_District_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Application_Allowed_District_Bool_Exp;
};

/** columns and relationships of "ref_application_lifecycle_stage" */
export type Ref_Application_Lifecycle_Stage = {
  __typename?: 'ref_application_lifecycle_stage';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_application_lifecycle_stage" */
export type Ref_Application_Lifecycle_Stage_Aggregate = {
  __typename?: 'ref_application_lifecycle_stage_aggregate';
  aggregate?: Maybe<Ref_Application_Lifecycle_Stage_Aggregate_Fields>;
  nodes: Array<Ref_Application_Lifecycle_Stage>;
};

/** aggregate fields of "ref_application_lifecycle_stage" */
export type Ref_Application_Lifecycle_Stage_Aggregate_Fields = {
  __typename?: 'ref_application_lifecycle_stage_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Application_Lifecycle_Stage_Max_Fields>;
  min?: Maybe<Ref_Application_Lifecycle_Stage_Min_Fields>;
};


/** aggregate fields of "ref_application_lifecycle_stage" */
export type Ref_Application_Lifecycle_Stage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Application_Lifecycle_Stage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_application_lifecycle_stage". All fields are combined with a logical 'AND'. */
export type Ref_Application_Lifecycle_Stage_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Application_Lifecycle_Stage_Bool_Exp>>;
  _not?: InputMaybe<Ref_Application_Lifecycle_Stage_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Application_Lifecycle_Stage_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_application_lifecycle_stage" */
export enum Ref_Application_Lifecycle_Stage_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyApplicationLifecycleStagePkey = 'company_application_lifecycle_stage_pkey'
}

export enum Ref_Application_Lifecycle_Stage_Enum {
  /** Company Registered */
  A1Registered = 'A1_REGISTERED',
  /** Marketing qualified */
  B2Mql = 'B2_MQL',
  /** Pre qualified */
  C3Pql = 'C3_PQL',
  /** Sales qualified */
  D4Sql = 'D4_SQL',
  /** Investor qualified */
  E5Investor = 'E5_INVESTOR',
  /** Trade created */
  F6Trade = 'F6_TRADE',
  /** Rejected */
  G7Rejected = 'G7_REJECTED'
}

/** Boolean expression to compare columns of type "ref_application_lifecycle_stage_enum". All fields are combined with logical 'AND'. */
export type Ref_Application_Lifecycle_Stage_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Application_Lifecycle_Stage_Enum>;
  _in?: InputMaybe<Array<Ref_Application_Lifecycle_Stage_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Application_Lifecycle_Stage_Enum>;
  _nin?: InputMaybe<Array<Ref_Application_Lifecycle_Stage_Enum>>;
};

/** input type for inserting data into table "ref_application_lifecycle_stage" */
export type Ref_Application_Lifecycle_Stage_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Application_Lifecycle_Stage_Max_Fields = {
  __typename?: 'ref_application_lifecycle_stage_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Application_Lifecycle_Stage_Min_Fields = {
  __typename?: 'ref_application_lifecycle_stage_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_application_lifecycle_stage" */
export type Ref_Application_Lifecycle_Stage_Mutation_Response = {
  __typename?: 'ref_application_lifecycle_stage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Application_Lifecycle_Stage>;
};

/** input type for inserting object relation for remote table "ref_application_lifecycle_stage" */
export type Ref_Application_Lifecycle_Stage_Obj_Rel_Insert_Input = {
  data: Ref_Application_Lifecycle_Stage_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Application_Lifecycle_Stage_On_Conflict>;
};

/** on_conflict condition type for table "ref_application_lifecycle_stage" */
export type Ref_Application_Lifecycle_Stage_On_Conflict = {
  constraint: Ref_Application_Lifecycle_Stage_Constraint;
  update_columns?: Array<Ref_Application_Lifecycle_Stage_Update_Column>;
  where?: InputMaybe<Ref_Application_Lifecycle_Stage_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_application_lifecycle_stage". */
export type Ref_Application_Lifecycle_Stage_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_application_lifecycle_stage */
export type Ref_Application_Lifecycle_Stage_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_application_lifecycle_stage" */
export enum Ref_Application_Lifecycle_Stage_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_application_lifecycle_stage" */
export type Ref_Application_Lifecycle_Stage_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_application_lifecycle_stage" */
export type Ref_Application_Lifecycle_Stage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Application_Lifecycle_Stage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Application_Lifecycle_Stage_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_application_lifecycle_stage" */
export enum Ref_Application_Lifecycle_Stage_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Ref_Application_Lifecycle_Stage_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Application_Lifecycle_Stage_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Application_Lifecycle_Stage_Bool_Exp;
};

/** columns and relationships of "ref_bank" */
export type Ref_Bank = {
  __typename?: 'ref_bank';
  bank_code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  supported_integrations: Array<Ref_Bank_List>;
  /** An aggregate relationship */
  supported_integrations_aggregate: Ref_Bank_List_Aggregate;
};


/** columns and relationships of "ref_bank" */
export type Ref_BankSupported_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Ref_Bank_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Bank_List_Order_By>>;
  where?: InputMaybe<Ref_Bank_List_Bool_Exp>;
};


/** columns and relationships of "ref_bank" */
export type Ref_BankSupported_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Bank_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Bank_List_Order_By>>;
  where?: InputMaybe<Ref_Bank_List_Bool_Exp>;
};

/** aggregated selection of "ref_bank" */
export type Ref_Bank_Aggregate = {
  __typename?: 'ref_bank_aggregate';
  aggregate?: Maybe<Ref_Bank_Aggregate_Fields>;
  nodes: Array<Ref_Bank>;
};

/** aggregate fields of "ref_bank" */
export type Ref_Bank_Aggregate_Fields = {
  __typename?: 'ref_bank_aggregate_fields';
  avg?: Maybe<Ref_Bank_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ref_Bank_Max_Fields>;
  min?: Maybe<Ref_Bank_Min_Fields>;
  stddev?: Maybe<Ref_Bank_Stddev_Fields>;
  stddev_pop?: Maybe<Ref_Bank_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ref_Bank_Stddev_Samp_Fields>;
  sum?: Maybe<Ref_Bank_Sum_Fields>;
  var_pop?: Maybe<Ref_Bank_Var_Pop_Fields>;
  var_samp?: Maybe<Ref_Bank_Var_Samp_Fields>;
  variance?: Maybe<Ref_Bank_Variance_Fields>;
};


/** aggregate fields of "ref_bank" */
export type Ref_Bank_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Bank_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ref_Bank_Avg_Fields = {
  __typename?: 'ref_bank_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ref_bank". All fields are combined with a logical 'AND'. */
export type Ref_Bank_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Bank_Bool_Exp>>;
  _not?: InputMaybe<Ref_Bank_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Bank_Bool_Exp>>;
  bank_code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  supported_integrations?: InputMaybe<Ref_Bank_List_Bool_Exp>;
  supported_integrations_aggregate?: InputMaybe<Ref_Bank_List_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "ref_bank" */
export enum Ref_Bank_Constraint {
  /** unique or primary key constraint on columns "bank_code" */
  RefBankBankCodeKey = 'ref_bank_bank_code_key',
  /** unique or primary key constraint on columns "id" */
  RefBankPkey = 'ref_bank_pkey'
}

/** input type for incrementing numeric columns in table "ref_bank" */
export type Ref_Bank_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ref_bank" */
export type Ref_Bank_Insert_Input = {
  bank_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  supported_integrations?: InputMaybe<Ref_Bank_List_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "ref_bank_list" */
export type Ref_Bank_List = {
  __typename?: 'ref_bank_list';
  bank_id?: Maybe<Scalars['Int']>;
  bank_name: Scalars['String'];
  id: Scalars['Int'];
  netbanking: Scalars['Boolean'];
  /** An object relationship */
  ref_bank?: Maybe<Ref_Bank>;
  source: Scalars['String'];
  source_bank_id: Scalars['Int'];
  statement_upload: Scalars['Boolean'];
  type: Scalars['String'];
  verified: Scalars['Boolean'];
};

/** aggregated selection of "ref_bank_list" */
export type Ref_Bank_List_Aggregate = {
  __typename?: 'ref_bank_list_aggregate';
  aggregate?: Maybe<Ref_Bank_List_Aggregate_Fields>;
  nodes: Array<Ref_Bank_List>;
};

export type Ref_Bank_List_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Ref_Bank_List_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Ref_Bank_List_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Ref_Bank_List_Aggregate_Bool_Exp_Count>;
};

export type Ref_Bank_List_Aggregate_Bool_Exp_Bool_And = {
  arguments: Ref_Bank_List_Select_Column_Ref_Bank_List_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ref_Bank_List_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ref_Bank_List_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Ref_Bank_List_Select_Column_Ref_Bank_List_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ref_Bank_List_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ref_Bank_List_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ref_Bank_List_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ref_Bank_List_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ref_bank_list" */
export type Ref_Bank_List_Aggregate_Fields = {
  __typename?: 'ref_bank_list_aggregate_fields';
  avg?: Maybe<Ref_Bank_List_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ref_Bank_List_Max_Fields>;
  min?: Maybe<Ref_Bank_List_Min_Fields>;
  stddev?: Maybe<Ref_Bank_List_Stddev_Fields>;
  stddev_pop?: Maybe<Ref_Bank_List_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ref_Bank_List_Stddev_Samp_Fields>;
  sum?: Maybe<Ref_Bank_List_Sum_Fields>;
  var_pop?: Maybe<Ref_Bank_List_Var_Pop_Fields>;
  var_samp?: Maybe<Ref_Bank_List_Var_Samp_Fields>;
  variance?: Maybe<Ref_Bank_List_Variance_Fields>;
};


/** aggregate fields of "ref_bank_list" */
export type Ref_Bank_List_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Bank_List_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ref_bank_list" */
export type Ref_Bank_List_Aggregate_Order_By = {
  avg?: InputMaybe<Ref_Bank_List_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ref_Bank_List_Max_Order_By>;
  min?: InputMaybe<Ref_Bank_List_Min_Order_By>;
  stddev?: InputMaybe<Ref_Bank_List_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ref_Bank_List_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ref_Bank_List_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ref_Bank_List_Sum_Order_By>;
  var_pop?: InputMaybe<Ref_Bank_List_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ref_Bank_List_Var_Samp_Order_By>;
  variance?: InputMaybe<Ref_Bank_List_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ref_bank_list" */
export type Ref_Bank_List_Arr_Rel_Insert_Input = {
  data: Array<Ref_Bank_List_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Bank_List_On_Conflict>;
};

/** aggregate avg on columns */
export type Ref_Bank_List_Avg_Fields = {
  __typename?: 'ref_bank_list_avg_fields';
  bank_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_bank_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ref_bank_list" */
export type Ref_Bank_List_Avg_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_bank_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ref_bank_list". All fields are combined with a logical 'AND'. */
export type Ref_Bank_List_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Bank_List_Bool_Exp>>;
  _not?: InputMaybe<Ref_Bank_List_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Bank_List_Bool_Exp>>;
  bank_id?: InputMaybe<Int_Comparison_Exp>;
  bank_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  netbanking?: InputMaybe<Boolean_Comparison_Exp>;
  ref_bank?: InputMaybe<Ref_Bank_Bool_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  source_bank_id?: InputMaybe<Int_Comparison_Exp>;
  statement_upload?: InputMaybe<Boolean_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  verified?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_bank_list" */
export enum Ref_Bank_List_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefBankListPkey = 'ref_bank_list_pkey'
}

/** input type for incrementing numeric columns in table "ref_bank_list" */
export type Ref_Bank_List_Inc_Input = {
  bank_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  source_bank_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ref_bank_list" */
export type Ref_Bank_List_Insert_Input = {
  bank_id?: InputMaybe<Scalars['Int']>;
  bank_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  netbanking?: InputMaybe<Scalars['Boolean']>;
  ref_bank?: InputMaybe<Ref_Bank_Obj_Rel_Insert_Input>;
  source?: InputMaybe<Scalars['String']>;
  source_bank_id?: InputMaybe<Scalars['Int']>;
  statement_upload?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Ref_Bank_List_Max_Fields = {
  __typename?: 'ref_bank_list_max_fields';
  bank_id?: Maybe<Scalars['Int']>;
  bank_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  source_bank_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ref_bank_list" */
export type Ref_Bank_List_Max_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  bank_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  source_bank_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ref_Bank_List_Min_Fields = {
  __typename?: 'ref_bank_list_min_fields';
  bank_id?: Maybe<Scalars['Int']>;
  bank_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  source_bank_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ref_bank_list" */
export type Ref_Bank_List_Min_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  bank_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  source_bank_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ref_bank_list" */
export type Ref_Bank_List_Mutation_Response = {
  __typename?: 'ref_bank_list_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Bank_List>;
};

/** on_conflict condition type for table "ref_bank_list" */
export type Ref_Bank_List_On_Conflict = {
  constraint: Ref_Bank_List_Constraint;
  update_columns?: Array<Ref_Bank_List_Update_Column>;
  where?: InputMaybe<Ref_Bank_List_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_bank_list". */
export type Ref_Bank_List_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  bank_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  netbanking?: InputMaybe<Order_By>;
  ref_bank?: InputMaybe<Ref_Bank_Order_By>;
  source?: InputMaybe<Order_By>;
  source_bank_id?: InputMaybe<Order_By>;
  statement_upload?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  verified?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_bank_list */
export type Ref_Bank_List_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ref_bank_list" */
export enum Ref_Bank_List_Select_Column {
  /** column name */
  BankId = 'bank_id',
  /** column name */
  BankName = 'bank_name',
  /** column name */
  Id = 'id',
  /** column name */
  Netbanking = 'netbanking',
  /** column name */
  Source = 'source',
  /** column name */
  SourceBankId = 'source_bank_id',
  /** column name */
  StatementUpload = 'statement_upload',
  /** column name */
  Type = 'type',
  /** column name */
  Verified = 'verified'
}

/** select "ref_bank_list_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ref_bank_list" */
export enum Ref_Bank_List_Select_Column_Ref_Bank_List_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Netbanking = 'netbanking',
  /** column name */
  StatementUpload = 'statement_upload',
  /** column name */
  Verified = 'verified'
}

/** select "ref_bank_list_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ref_bank_list" */
export enum Ref_Bank_List_Select_Column_Ref_Bank_List_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Netbanking = 'netbanking',
  /** column name */
  StatementUpload = 'statement_upload',
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "ref_bank_list" */
export type Ref_Bank_List_Set_Input = {
  bank_id?: InputMaybe<Scalars['Int']>;
  bank_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  netbanking?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  source_bank_id?: InputMaybe<Scalars['Int']>;
  statement_upload?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Ref_Bank_List_Stddev_Fields = {
  __typename?: 'ref_bank_list_stddev_fields';
  bank_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_bank_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ref_bank_list" */
export type Ref_Bank_List_Stddev_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_bank_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ref_Bank_List_Stddev_Pop_Fields = {
  __typename?: 'ref_bank_list_stddev_pop_fields';
  bank_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_bank_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ref_bank_list" */
export type Ref_Bank_List_Stddev_Pop_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_bank_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ref_Bank_List_Stddev_Samp_Fields = {
  __typename?: 'ref_bank_list_stddev_samp_fields';
  bank_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_bank_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ref_bank_list" */
export type Ref_Bank_List_Stddev_Samp_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_bank_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ref_bank_list" */
export type Ref_Bank_List_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Bank_List_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Bank_List_Stream_Cursor_Value_Input = {
  bank_id?: InputMaybe<Scalars['Int']>;
  bank_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  netbanking?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  source_bank_id?: InputMaybe<Scalars['Int']>;
  statement_upload?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Ref_Bank_List_Sum_Fields = {
  __typename?: 'ref_bank_list_sum_fields';
  bank_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  source_bank_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ref_bank_list" */
export type Ref_Bank_List_Sum_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_bank_id?: InputMaybe<Order_By>;
};

/** update columns of table "ref_bank_list" */
export enum Ref_Bank_List_Update_Column {
  /** column name */
  BankId = 'bank_id',
  /** column name */
  BankName = 'bank_name',
  /** column name */
  Id = 'id',
  /** column name */
  Netbanking = 'netbanking',
  /** column name */
  Source = 'source',
  /** column name */
  SourceBankId = 'source_bank_id',
  /** column name */
  StatementUpload = 'statement_upload',
  /** column name */
  Type = 'type',
  /** column name */
  Verified = 'verified'
}

export type Ref_Bank_List_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ref_Bank_List_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Bank_List_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Bank_List_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ref_Bank_List_Var_Pop_Fields = {
  __typename?: 'ref_bank_list_var_pop_fields';
  bank_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_bank_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ref_bank_list" */
export type Ref_Bank_List_Var_Pop_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_bank_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ref_Bank_List_Var_Samp_Fields = {
  __typename?: 'ref_bank_list_var_samp_fields';
  bank_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_bank_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ref_bank_list" */
export type Ref_Bank_List_Var_Samp_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_bank_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ref_Bank_List_Variance_Fields = {
  __typename?: 'ref_bank_list_variance_fields';
  bank_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_bank_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ref_bank_list" */
export type Ref_Bank_List_Variance_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source_bank_id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Ref_Bank_Max_Fields = {
  __typename?: 'ref_bank_max_fields';
  bank_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Bank_Min_Fields = {
  __typename?: 'ref_bank_min_fields';
  bank_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_bank" */
export type Ref_Bank_Mutation_Response = {
  __typename?: 'ref_bank_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Bank>;
};

/** input type for inserting object relation for remote table "ref_bank" */
export type Ref_Bank_Obj_Rel_Insert_Input = {
  data: Ref_Bank_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Bank_On_Conflict>;
};

/** on_conflict condition type for table "ref_bank" */
export type Ref_Bank_On_Conflict = {
  constraint: Ref_Bank_Constraint;
  update_columns?: Array<Ref_Bank_Update_Column>;
  where?: InputMaybe<Ref_Bank_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_bank". */
export type Ref_Bank_Order_By = {
  bank_code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  supported_integrations_aggregate?: InputMaybe<Ref_Bank_List_Aggregate_Order_By>;
};

/** primary key columns input for table: ref_bank */
export type Ref_Bank_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ref_bank" */
export enum Ref_Bank_Select_Column {
  /** column name */
  BankCode = 'bank_code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_bank" */
export type Ref_Bank_Set_Input = {
  bank_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Ref_Bank_Stddev_Fields = {
  __typename?: 'ref_bank_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ref_Bank_Stddev_Pop_Fields = {
  __typename?: 'ref_bank_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ref_Bank_Stddev_Samp_Fields = {
  __typename?: 'ref_bank_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ref_bank" */
export type Ref_Bank_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Bank_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Bank_Stream_Cursor_Value_Input = {
  bank_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Ref_Bank_Sum_Fields = {
  __typename?: 'ref_bank_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "ref_bank" */
export enum Ref_Bank_Update_Column {
  /** column name */
  BankCode = 'bank_code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Bank_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ref_Bank_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Bank_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Bank_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ref_Bank_Var_Pop_Fields = {
  __typename?: 'ref_bank_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ref_Bank_Var_Samp_Fields = {
  __typename?: 'ref_bank_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ref_Bank_Variance_Fields = {
  __typename?: 'ref_bank_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Possible bids and roi */
export type Ref_Bid = {
  __typename?: 'ref_bid';
  price: Scalars['numeric'];
  roi: Scalars['numeric'];
  round_price: Scalars['numeric'];
};

/** aggregated selection of "ref_bid" */
export type Ref_Bid_Aggregate = {
  __typename?: 'ref_bid_aggregate';
  aggregate?: Maybe<Ref_Bid_Aggregate_Fields>;
  nodes: Array<Ref_Bid>;
};

/** aggregate fields of "ref_bid" */
export type Ref_Bid_Aggregate_Fields = {
  __typename?: 'ref_bid_aggregate_fields';
  avg?: Maybe<Ref_Bid_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ref_Bid_Max_Fields>;
  min?: Maybe<Ref_Bid_Min_Fields>;
  stddev?: Maybe<Ref_Bid_Stddev_Fields>;
  stddev_pop?: Maybe<Ref_Bid_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ref_Bid_Stddev_Samp_Fields>;
  sum?: Maybe<Ref_Bid_Sum_Fields>;
  var_pop?: Maybe<Ref_Bid_Var_Pop_Fields>;
  var_samp?: Maybe<Ref_Bid_Var_Samp_Fields>;
  variance?: Maybe<Ref_Bid_Variance_Fields>;
};


/** aggregate fields of "ref_bid" */
export type Ref_Bid_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Bid_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ref_Bid_Avg_Fields = {
  __typename?: 'ref_bid_avg_fields';
  price?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  round_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ref_bid". All fields are combined with a logical 'AND'. */
export type Ref_Bid_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Bid_Bool_Exp>>;
  _not?: InputMaybe<Ref_Bid_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Bid_Bool_Exp>>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  roi?: InputMaybe<Numeric_Comparison_Exp>;
  round_price?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_bid" */
export enum Ref_Bid_Constraint {
  /** unique or primary key constraint on columns "price" */
  RefBidPkey = 'ref_bid_pkey'
}

/** input type for incrementing numeric columns in table "ref_bid" */
export type Ref_Bid_Inc_Input = {
  price?: InputMaybe<Scalars['numeric']>;
  roi?: InputMaybe<Scalars['numeric']>;
  round_price?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "ref_bid" */
export type Ref_Bid_Insert_Input = {
  price?: InputMaybe<Scalars['numeric']>;
  roi?: InputMaybe<Scalars['numeric']>;
  round_price?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Ref_Bid_Max_Fields = {
  __typename?: 'ref_bid_max_fields';
  price?: Maybe<Scalars['numeric']>;
  roi?: Maybe<Scalars['numeric']>;
  round_price?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Ref_Bid_Min_Fields = {
  __typename?: 'ref_bid_min_fields';
  price?: Maybe<Scalars['numeric']>;
  roi?: Maybe<Scalars['numeric']>;
  round_price?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "ref_bid" */
export type Ref_Bid_Mutation_Response = {
  __typename?: 'ref_bid_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Bid>;
};

/** input type for inserting object relation for remote table "ref_bid" */
export type Ref_Bid_Obj_Rel_Insert_Input = {
  data: Ref_Bid_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Bid_On_Conflict>;
};

/** on_conflict condition type for table "ref_bid" */
export type Ref_Bid_On_Conflict = {
  constraint: Ref_Bid_Constraint;
  update_columns?: Array<Ref_Bid_Update_Column>;
  where?: InputMaybe<Ref_Bid_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_bid". */
export type Ref_Bid_Order_By = {
  price?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  round_price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_bid */
export type Ref_Bid_Pk_Columns_Input = {
  price: Scalars['numeric'];
};

/** select columns of table "ref_bid" */
export enum Ref_Bid_Select_Column {
  /** column name */
  Price = 'price',
  /** column name */
  Roi = 'roi',
  /** column name */
  RoundPrice = 'round_price'
}

/** input type for updating data in table "ref_bid" */
export type Ref_Bid_Set_Input = {
  price?: InputMaybe<Scalars['numeric']>;
  roi?: InputMaybe<Scalars['numeric']>;
  round_price?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Ref_Bid_Stddev_Fields = {
  __typename?: 'ref_bid_stddev_fields';
  price?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  round_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ref_Bid_Stddev_Pop_Fields = {
  __typename?: 'ref_bid_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  round_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ref_Bid_Stddev_Samp_Fields = {
  __typename?: 'ref_bid_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  round_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ref_bid" */
export type Ref_Bid_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Bid_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Bid_Stream_Cursor_Value_Input = {
  price?: InputMaybe<Scalars['numeric']>;
  roi?: InputMaybe<Scalars['numeric']>;
  round_price?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Ref_Bid_Sum_Fields = {
  __typename?: 'ref_bid_sum_fields';
  price?: Maybe<Scalars['numeric']>;
  roi?: Maybe<Scalars['numeric']>;
  round_price?: Maybe<Scalars['numeric']>;
};

/** update columns of table "ref_bid" */
export enum Ref_Bid_Update_Column {
  /** column name */
  Price = 'price',
  /** column name */
  Roi = 'roi',
  /** column name */
  RoundPrice = 'round_price'
}

export type Ref_Bid_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ref_Bid_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Bid_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Bid_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ref_Bid_Var_Pop_Fields = {
  __typename?: 'ref_bid_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  round_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ref_Bid_Var_Samp_Fields = {
  __typename?: 'ref_bid_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  round_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ref_Bid_Variance_Fields = {
  __typename?: 'ref_bid_variance_fields';
  price?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  round_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ref_billing_period" */
export type Ref_Billing_Period = {
  __typename?: 'ref_billing_period';
  id: Scalars['String'];
  name: Scalars['String'];
  yearly_count: Scalars['float8'];
};

/** aggregated selection of "ref_billing_period" */
export type Ref_Billing_Period_Aggregate = {
  __typename?: 'ref_billing_period_aggregate';
  aggregate?: Maybe<Ref_Billing_Period_Aggregate_Fields>;
  nodes: Array<Ref_Billing_Period>;
};

/** aggregate fields of "ref_billing_period" */
export type Ref_Billing_Period_Aggregate_Fields = {
  __typename?: 'ref_billing_period_aggregate_fields';
  avg?: Maybe<Ref_Billing_Period_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ref_Billing_Period_Max_Fields>;
  min?: Maybe<Ref_Billing_Period_Min_Fields>;
  stddev?: Maybe<Ref_Billing_Period_Stddev_Fields>;
  stddev_pop?: Maybe<Ref_Billing_Period_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ref_Billing_Period_Stddev_Samp_Fields>;
  sum?: Maybe<Ref_Billing_Period_Sum_Fields>;
  var_pop?: Maybe<Ref_Billing_Period_Var_Pop_Fields>;
  var_samp?: Maybe<Ref_Billing_Period_Var_Samp_Fields>;
  variance?: Maybe<Ref_Billing_Period_Variance_Fields>;
};


/** aggregate fields of "ref_billing_period" */
export type Ref_Billing_Period_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Billing_Period_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ref_Billing_Period_Avg_Fields = {
  __typename?: 'ref_billing_period_avg_fields';
  yearly_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ref_billing_period". All fields are combined with a logical 'AND'. */
export type Ref_Billing_Period_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Billing_Period_Bool_Exp>>;
  _not?: InputMaybe<Ref_Billing_Period_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Billing_Period_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  yearly_count?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_billing_period" */
export enum Ref_Billing_Period_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefBillingPeriodPkey = 'ref_billing_period_pkey'
}

/** input type for incrementing numeric columns in table "ref_billing_period" */
export type Ref_Billing_Period_Inc_Input = {
  yearly_count?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "ref_billing_period" */
export type Ref_Billing_Period_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  yearly_count?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Ref_Billing_Period_Max_Fields = {
  __typename?: 'ref_billing_period_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  yearly_count?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Ref_Billing_Period_Min_Fields = {
  __typename?: 'ref_billing_period_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  yearly_count?: Maybe<Scalars['float8']>;
};

/** response of any mutation on the table "ref_billing_period" */
export type Ref_Billing_Period_Mutation_Response = {
  __typename?: 'ref_billing_period_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Billing_Period>;
};

/** on_conflict condition type for table "ref_billing_period" */
export type Ref_Billing_Period_On_Conflict = {
  constraint: Ref_Billing_Period_Constraint;
  update_columns?: Array<Ref_Billing_Period_Update_Column>;
  where?: InputMaybe<Ref_Billing_Period_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_billing_period". */
export type Ref_Billing_Period_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  yearly_count?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_billing_period */
export type Ref_Billing_Period_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_billing_period" */
export enum Ref_Billing_Period_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  YearlyCount = 'yearly_count'
}

/** input type for updating data in table "ref_billing_period" */
export type Ref_Billing_Period_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  yearly_count?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Ref_Billing_Period_Stddev_Fields = {
  __typename?: 'ref_billing_period_stddev_fields';
  yearly_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ref_Billing_Period_Stddev_Pop_Fields = {
  __typename?: 'ref_billing_period_stddev_pop_fields';
  yearly_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ref_Billing_Period_Stddev_Samp_Fields = {
  __typename?: 'ref_billing_period_stddev_samp_fields';
  yearly_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ref_billing_period" */
export type Ref_Billing_Period_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Billing_Period_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Billing_Period_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  yearly_count?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Ref_Billing_Period_Sum_Fields = {
  __typename?: 'ref_billing_period_sum_fields';
  yearly_count?: Maybe<Scalars['float8']>;
};

/** update columns of table "ref_billing_period" */
export enum Ref_Billing_Period_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  YearlyCount = 'yearly_count'
}

export type Ref_Billing_Period_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ref_Billing_Period_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Billing_Period_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Billing_Period_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ref_Billing_Period_Var_Pop_Fields = {
  __typename?: 'ref_billing_period_var_pop_fields';
  yearly_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ref_Billing_Period_Var_Samp_Fields = {
  __typename?: 'ref_billing_period_var_samp_fields';
  yearly_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ref_Billing_Period_Variance_Fields = {
  __typename?: 'ref_billing_period_variance_fields';
  yearly_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ref_category" */
export type Ref_Category = {
  __typename?: 'ref_category';
  /** An array relationship */
  company_integrations: Array<Company_Integration>;
  /** An aggregate relationship */
  company_integrations_aggregate: Company_Integration_Aggregate;
  id: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  ref_sources: Array<Ref_Source>;
  /** An aggregate relationship */
  ref_sources_aggregate: Ref_Source_Aggregate;
};


/** columns and relationships of "ref_category" */
export type Ref_CategoryCompany_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_Order_By>>;
  where?: InputMaybe<Company_Integration_Bool_Exp>;
};


/** columns and relationships of "ref_category" */
export type Ref_CategoryCompany_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_Order_By>>;
  where?: InputMaybe<Company_Integration_Bool_Exp>;
};


/** columns and relationships of "ref_category" */
export type Ref_CategoryRef_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Ref_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Source_Order_By>>;
  where?: InputMaybe<Ref_Source_Bool_Exp>;
};


/** columns and relationships of "ref_category" */
export type Ref_CategoryRef_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Source_Order_By>>;
  where?: InputMaybe<Ref_Source_Bool_Exp>;
};

/** aggregated selection of "ref_category" */
export type Ref_Category_Aggregate = {
  __typename?: 'ref_category_aggregate';
  aggregate?: Maybe<Ref_Category_Aggregate_Fields>;
  nodes: Array<Ref_Category>;
};

/** aggregate fields of "ref_category" */
export type Ref_Category_Aggregate_Fields = {
  __typename?: 'ref_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Category_Max_Fields>;
  min?: Maybe<Ref_Category_Min_Fields>;
};


/** aggregate fields of "ref_category" */
export type Ref_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_category". All fields are combined with a logical 'AND'. */
export type Ref_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Category_Bool_Exp>>;
  _not?: InputMaybe<Ref_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Category_Bool_Exp>>;
  company_integrations?: InputMaybe<Company_Integration_Bool_Exp>;
  company_integrations_aggregate?: InputMaybe<Company_Integration_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ref_sources?: InputMaybe<Ref_Source_Bool_Exp>;
  ref_sources_aggregate?: InputMaybe<Ref_Source_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "ref_category" */
export enum Ref_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefCategoryPkey = 'ref_category_pkey'
}

/** input type for inserting data into table "ref_category" */
export type Ref_Category_Insert_Input = {
  company_integrations?: InputMaybe<Company_Integration_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ref_sources?: InputMaybe<Ref_Source_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ref_Category_Max_Fields = {
  __typename?: 'ref_category_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Category_Min_Fields = {
  __typename?: 'ref_category_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_category" */
export type Ref_Category_Mutation_Response = {
  __typename?: 'ref_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Category>;
};

/** on_conflict condition type for table "ref_category" */
export type Ref_Category_On_Conflict = {
  constraint: Ref_Category_Constraint;
  update_columns?: Array<Ref_Category_Update_Column>;
  where?: InputMaybe<Ref_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_category". */
export type Ref_Category_Order_By = {
  company_integrations_aggregate?: InputMaybe<Company_Integration_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ref_sources_aggregate?: InputMaybe<Ref_Source_Aggregate_Order_By>;
};

/** primary key columns input for table: ref_category */
export type Ref_Category_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_category" */
export enum Ref_Category_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_category" */
export type Ref_Category_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_category" */
export type Ref_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Category_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_category" */
export enum Ref_Category_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Category_Bool_Exp;
};

/** columns and relationships of "ref_collection_mode" */
export type Ref_Collection_Mode = {
  __typename?: 'ref_collection_mode';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_collection_mode" */
export type Ref_Collection_Mode_Aggregate = {
  __typename?: 'ref_collection_mode_aggregate';
  aggregate?: Maybe<Ref_Collection_Mode_Aggregate_Fields>;
  nodes: Array<Ref_Collection_Mode>;
};

/** aggregate fields of "ref_collection_mode" */
export type Ref_Collection_Mode_Aggregate_Fields = {
  __typename?: 'ref_collection_mode_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Collection_Mode_Max_Fields>;
  min?: Maybe<Ref_Collection_Mode_Min_Fields>;
};


/** aggregate fields of "ref_collection_mode" */
export type Ref_Collection_Mode_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Collection_Mode_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_collection_mode". All fields are combined with a logical 'AND'. */
export type Ref_Collection_Mode_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Collection_Mode_Bool_Exp>>;
  _not?: InputMaybe<Ref_Collection_Mode_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Collection_Mode_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_collection_mode" */
export enum Ref_Collection_Mode_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefCollectionModePkey = 'ref_collection_mode_pkey'
}

export enum Ref_Collection_Mode_Enum {
  /** escrow as collection */
  Escrow = 'ESCROW',
  /** nach mandate as collection */
  Mandate = 'MANDATE'
}

/** Boolean expression to compare columns of type "ref_collection_mode_enum". All fields are combined with logical 'AND'. */
export type Ref_Collection_Mode_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Collection_Mode_Enum>;
  _in?: InputMaybe<Array<Ref_Collection_Mode_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Collection_Mode_Enum>;
  _nin?: InputMaybe<Array<Ref_Collection_Mode_Enum>>;
};

/** input type for inserting data into table "ref_collection_mode" */
export type Ref_Collection_Mode_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Collection_Mode_Max_Fields = {
  __typename?: 'ref_collection_mode_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Collection_Mode_Min_Fields = {
  __typename?: 'ref_collection_mode_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_collection_mode" */
export type Ref_Collection_Mode_Mutation_Response = {
  __typename?: 'ref_collection_mode_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Collection_Mode>;
};

/** on_conflict condition type for table "ref_collection_mode" */
export type Ref_Collection_Mode_On_Conflict = {
  constraint: Ref_Collection_Mode_Constraint;
  update_columns?: Array<Ref_Collection_Mode_Update_Column>;
  where?: InputMaybe<Ref_Collection_Mode_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_collection_mode". */
export type Ref_Collection_Mode_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_collection_mode */
export type Ref_Collection_Mode_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_collection_mode" */
export enum Ref_Collection_Mode_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_collection_mode" */
export type Ref_Collection_Mode_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_collection_mode" */
export type Ref_Collection_Mode_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Collection_Mode_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Collection_Mode_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_collection_mode" */
export enum Ref_Collection_Mode_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Ref_Collection_Mode_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Collection_Mode_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Collection_Mode_Bool_Exp;
};

/** columns and relationships of "ref_company_lead_status" */
export type Ref_Company_Lead_Status = {
  __typename?: 'ref_company_lead_status';
  Description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_company_lead_status" */
export type Ref_Company_Lead_Status_Aggregate = {
  __typename?: 'ref_company_lead_status_aggregate';
  aggregate?: Maybe<Ref_Company_Lead_Status_Aggregate_Fields>;
  nodes: Array<Ref_Company_Lead_Status>;
};

/** aggregate fields of "ref_company_lead_status" */
export type Ref_Company_Lead_Status_Aggregate_Fields = {
  __typename?: 'ref_company_lead_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Company_Lead_Status_Max_Fields>;
  min?: Maybe<Ref_Company_Lead_Status_Min_Fields>;
};


/** aggregate fields of "ref_company_lead_status" */
export type Ref_Company_Lead_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Company_Lead_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_company_lead_status". All fields are combined with a logical 'AND'. */
export type Ref_Company_Lead_Status_Bool_Exp = {
  Description?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Ref_Company_Lead_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_Company_Lead_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Company_Lead_Status_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_company_lead_status" */
export enum Ref_Company_Lead_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefCompanyLeadStatusPkey = 'ref_company_lead_status_pkey'
}

export enum Ref_Company_Lead_Status_Enum {
  /** Agreement In Progress  */
  AgreementInProgress = 'AGREEMENT_IN_PROGRESS',
  /** Bidding */
  Bidding = 'BIDDING',
  /** Completed */
  Completed = 'COMPLETED',
  /** Credit Accepted */
  CreditAccepted = 'CREDIT_ACCEPTED',
  /** Credit Hold */
  CreditHold = 'CREDIT_HOLD',
  /** Credit Rejected */
  CreditRejected = 'CREDIT_REJECTED',
  /** Credit Wip */
  CreditWip = 'CREDIT_WIP',
  /** Customer Accept  */
  CustomerAccept = 'CUSTOMER_ACCEPT',
  /** Customer Deny */
  CustomerDeny = 'CUSTOMER_DENY',
  /** Document Collection */
  DocumentCollection = 'DOCUMENT_COLLECTION',
  /** Failed */
  Failed = 'FAILED',
  /** Sales Qualified */
  SalesQualified = 'SALES_QUALIFIED'
}

/** Boolean expression to compare columns of type "ref_company_lead_status_enum". All fields are combined with logical 'AND'. */
export type Ref_Company_Lead_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Company_Lead_Status_Enum>;
  _in?: InputMaybe<Array<Ref_Company_Lead_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Company_Lead_Status_Enum>;
  _nin?: InputMaybe<Array<Ref_Company_Lead_Status_Enum>>;
};

/** input type for inserting data into table "ref_company_lead_status" */
export type Ref_Company_Lead_Status_Insert_Input = {
  Description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Company_Lead_Status_Max_Fields = {
  __typename?: 'ref_company_lead_status_max_fields';
  Description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Company_Lead_Status_Min_Fields = {
  __typename?: 'ref_company_lead_status_min_fields';
  Description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_company_lead_status" */
export type Ref_Company_Lead_Status_Mutation_Response = {
  __typename?: 'ref_company_lead_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Company_Lead_Status>;
};

/** on_conflict condition type for table "ref_company_lead_status" */
export type Ref_Company_Lead_Status_On_Conflict = {
  constraint: Ref_Company_Lead_Status_Constraint;
  update_columns?: Array<Ref_Company_Lead_Status_Update_Column>;
  where?: InputMaybe<Ref_Company_Lead_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_company_lead_status". */
export type Ref_Company_Lead_Status_Order_By = {
  Description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_company_lead_status */
export type Ref_Company_Lead_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_company_lead_status" */
export enum Ref_Company_Lead_Status_Select_Column {
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_company_lead_status" */
export type Ref_Company_Lead_Status_Set_Input = {
  Description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_company_lead_status" */
export type Ref_Company_Lead_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Company_Lead_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Company_Lead_Status_Stream_Cursor_Value_Input = {
  Description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_company_lead_status" */
export enum Ref_Company_Lead_Status_Update_Column {
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'id'
}

export type Ref_Company_Lead_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Company_Lead_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Company_Lead_Status_Bool_Exp;
};

/** Enum values for the likeliness of companies in investor queue */
export type Ref_Company_Likeliness = {
  __typename?: 'ref_company_likeliness';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_company_likeliness" */
export type Ref_Company_Likeliness_Aggregate = {
  __typename?: 'ref_company_likeliness_aggregate';
  aggregate?: Maybe<Ref_Company_Likeliness_Aggregate_Fields>;
  nodes: Array<Ref_Company_Likeliness>;
};

/** aggregate fields of "ref_company_likeliness" */
export type Ref_Company_Likeliness_Aggregate_Fields = {
  __typename?: 'ref_company_likeliness_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Company_Likeliness_Max_Fields>;
  min?: Maybe<Ref_Company_Likeliness_Min_Fields>;
};


/** aggregate fields of "ref_company_likeliness" */
export type Ref_Company_Likeliness_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Company_Likeliness_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_company_likeliness". All fields are combined with a logical 'AND'. */
export type Ref_Company_Likeliness_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Company_Likeliness_Bool_Exp>>;
  _not?: InputMaybe<Ref_Company_Likeliness_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Company_Likeliness_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_company_likeliness" */
export enum Ref_Company_Likeliness_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefCompanyLikelinessPkey = 'ref_company_likeliness_pkey'
}

export enum Ref_Company_Likeliness_Enum {
  /** Unlikely */
  Unlikely = 'UNLIKELY',
  /** Unsure */
  Unsure = 'UNSURE',
  /** Very Likely */
  VeryLikely = 'VERY_LIKELY'
}

/** Boolean expression to compare columns of type "ref_company_likeliness_enum". All fields are combined with logical 'AND'. */
export type Ref_Company_Likeliness_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Company_Likeliness_Enum>;
  _in?: InputMaybe<Array<Ref_Company_Likeliness_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Company_Likeliness_Enum>;
  _nin?: InputMaybe<Array<Ref_Company_Likeliness_Enum>>;
};

/** input type for inserting data into table "ref_company_likeliness" */
export type Ref_Company_Likeliness_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Company_Likeliness_Max_Fields = {
  __typename?: 'ref_company_likeliness_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Company_Likeliness_Min_Fields = {
  __typename?: 'ref_company_likeliness_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_company_likeliness" */
export type Ref_Company_Likeliness_Mutation_Response = {
  __typename?: 'ref_company_likeliness_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Company_Likeliness>;
};

/** on_conflict condition type for table "ref_company_likeliness" */
export type Ref_Company_Likeliness_On_Conflict = {
  constraint: Ref_Company_Likeliness_Constraint;
  update_columns?: Array<Ref_Company_Likeliness_Update_Column>;
  where?: InputMaybe<Ref_Company_Likeliness_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_company_likeliness". */
export type Ref_Company_Likeliness_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_company_likeliness */
export type Ref_Company_Likeliness_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_company_likeliness" */
export enum Ref_Company_Likeliness_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_company_likeliness" */
export type Ref_Company_Likeliness_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_company_likeliness" */
export type Ref_Company_Likeliness_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Company_Likeliness_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Company_Likeliness_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_company_likeliness" */
export enum Ref_Company_Likeliness_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Ref_Company_Likeliness_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Company_Likeliness_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Company_Likeliness_Bool_Exp;
};

/** columns and relationships of "ref_company_status" */
export type Ref_Company_Status = {
  __typename?: 'ref_company_status';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_company_status" */
export type Ref_Company_Status_Aggregate = {
  __typename?: 'ref_company_status_aggregate';
  aggregate?: Maybe<Ref_Company_Status_Aggregate_Fields>;
  nodes: Array<Ref_Company_Status>;
};

/** aggregate fields of "ref_company_status" */
export type Ref_Company_Status_Aggregate_Fields = {
  __typename?: 'ref_company_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Company_Status_Max_Fields>;
  min?: Maybe<Ref_Company_Status_Min_Fields>;
};


/** aggregate fields of "ref_company_status" */
export type Ref_Company_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Company_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_company_status". All fields are combined with a logical 'AND'. */
export type Ref_Company_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Company_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_Company_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Company_Status_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_company_status" */
export enum Ref_Company_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefCompanyStatusPkey = 'ref_company_status_pkey'
}

export enum Ref_Company_Status_Enum {
  /** active */
  Active = 'ACTIVE',
  /** Blocked */
  Blocked = 'BLOCKED',
  /** Connected */
  Connected = 'CONNECTED',
  /** Disconnected */
  Disconnected = 'DISCONNECTED',
  /** Ineligible */
  Ineligible = 'INELIGIBLE',
  /** Not Interested */
  NotInterested = 'NOT_INTERESTED',
  /** Paused */
  Paused = 'PAUSED',
  /** Processing */
  Processing = 'PROCESSING',
  /** Registered */
  Registered = 'REGISTERED'
}

/** Boolean expression to compare columns of type "ref_company_status_enum". All fields are combined with logical 'AND'. */
export type Ref_Company_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Company_Status_Enum>;
  _in?: InputMaybe<Array<Ref_Company_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Company_Status_Enum>;
  _nin?: InputMaybe<Array<Ref_Company_Status_Enum>>;
};

/** input type for inserting data into table "ref_company_status" */
export type Ref_Company_Status_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Company_Status_Max_Fields = {
  __typename?: 'ref_company_status_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Company_Status_Min_Fields = {
  __typename?: 'ref_company_status_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_company_status" */
export type Ref_Company_Status_Mutation_Response = {
  __typename?: 'ref_company_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Company_Status>;
};

/** on_conflict condition type for table "ref_company_status" */
export type Ref_Company_Status_On_Conflict = {
  constraint: Ref_Company_Status_Constraint;
  update_columns?: Array<Ref_Company_Status_Update_Column>;
  where?: InputMaybe<Ref_Company_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_company_status". */
export type Ref_Company_Status_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_company_status */
export type Ref_Company_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_company_status" */
export enum Ref_Company_Status_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_company_status" */
export type Ref_Company_Status_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_company_status" */
export type Ref_Company_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Company_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Company_Status_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_company_status" */
export enum Ref_Company_Status_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Company_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Company_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Company_Status_Bool_Exp;
};

/** columns and relationships of "ref_company_type" */
export type Ref_Company_Type = {
  __typename?: 'ref_company_type';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_company_type" */
export type Ref_Company_Type_Aggregate = {
  __typename?: 'ref_company_type_aggregate';
  aggregate?: Maybe<Ref_Company_Type_Aggregate_Fields>;
  nodes: Array<Ref_Company_Type>;
};

/** aggregate fields of "ref_company_type" */
export type Ref_Company_Type_Aggregate_Fields = {
  __typename?: 'ref_company_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Company_Type_Max_Fields>;
  min?: Maybe<Ref_Company_Type_Min_Fields>;
};


/** aggregate fields of "ref_company_type" */
export type Ref_Company_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Company_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_company_type". All fields are combined with a logical 'AND'. */
export type Ref_Company_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Company_Type_Bool_Exp>>;
  _not?: InputMaybe<Ref_Company_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Company_Type_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_company_type" */
export enum Ref_Company_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefCompanyTypePkey = 'ref_company_type_pkey'
}

/** input type for inserting data into table "ref_company_type" */
export type Ref_Company_Type_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Company_Type_Max_Fields = {
  __typename?: 'ref_company_type_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Company_Type_Min_Fields = {
  __typename?: 'ref_company_type_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_company_type" */
export type Ref_Company_Type_Mutation_Response = {
  __typename?: 'ref_company_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Company_Type>;
};

/** input type for inserting object relation for remote table "ref_company_type" */
export type Ref_Company_Type_Obj_Rel_Insert_Input = {
  data: Ref_Company_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Company_Type_On_Conflict>;
};

/** on_conflict condition type for table "ref_company_type" */
export type Ref_Company_Type_On_Conflict = {
  constraint: Ref_Company_Type_Constraint;
  update_columns?: Array<Ref_Company_Type_Update_Column>;
  where?: InputMaybe<Ref_Company_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_company_type". */
export type Ref_Company_Type_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_company_type */
export type Ref_Company_Type_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_company_type" */
export enum Ref_Company_Type_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_company_type" */
export type Ref_Company_Type_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_company_type" */
export type Ref_Company_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Company_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Company_Type_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_company_type" */
export enum Ref_Company_Type_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Company_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Company_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Company_Type_Bool_Exp;
};

/** columns and relationships of "ref_country" */
export type Ref_Country = {
  __typename?: 'ref_country';
  id: Scalars['Int'];
  iso2_code: Scalars['String'];
  name: Scalars['String'];
  phonecode: Scalars['Int'];
  /** An array relationship */
  ref_states: Array<Ref_States>;
  /** An aggregate relationship */
  ref_states_aggregate: Ref_States_Aggregate;
};


/** columns and relationships of "ref_country" */
export type Ref_CountryRef_StatesArgs = {
  distinct_on?: InputMaybe<Array<Ref_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_States_Order_By>>;
  where?: InputMaybe<Ref_States_Bool_Exp>;
};


/** columns and relationships of "ref_country" */
export type Ref_CountryRef_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_States_Order_By>>;
  where?: InputMaybe<Ref_States_Bool_Exp>;
};

/** aggregated selection of "ref_country" */
export type Ref_Country_Aggregate = {
  __typename?: 'ref_country_aggregate';
  aggregate?: Maybe<Ref_Country_Aggregate_Fields>;
  nodes: Array<Ref_Country>;
};

/** aggregate fields of "ref_country" */
export type Ref_Country_Aggregate_Fields = {
  __typename?: 'ref_country_aggregate_fields';
  avg?: Maybe<Ref_Country_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ref_Country_Max_Fields>;
  min?: Maybe<Ref_Country_Min_Fields>;
  stddev?: Maybe<Ref_Country_Stddev_Fields>;
  stddev_pop?: Maybe<Ref_Country_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ref_Country_Stddev_Samp_Fields>;
  sum?: Maybe<Ref_Country_Sum_Fields>;
  var_pop?: Maybe<Ref_Country_Var_Pop_Fields>;
  var_samp?: Maybe<Ref_Country_Var_Samp_Fields>;
  variance?: Maybe<Ref_Country_Variance_Fields>;
};


/** aggregate fields of "ref_country" */
export type Ref_Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ref_Country_Avg_Fields = {
  __typename?: 'ref_country_avg_fields';
  id?: Maybe<Scalars['Float']>;
  phonecode?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ref_country". All fields are combined with a logical 'AND'. */
export type Ref_Country_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Country_Bool_Exp>>;
  _not?: InputMaybe<Ref_Country_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Country_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  iso2_code?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phonecode?: InputMaybe<Int_Comparison_Exp>;
  ref_states?: InputMaybe<Ref_States_Bool_Exp>;
  ref_states_aggregate?: InputMaybe<Ref_States_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "ref_country" */
export enum Ref_Country_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefCountryPkey = 'ref_country_pkey'
}

/** input type for incrementing numeric columns in table "ref_country" */
export type Ref_Country_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  phonecode?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ref_country" */
export type Ref_Country_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  iso2_code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phonecode?: InputMaybe<Scalars['Int']>;
  ref_states?: InputMaybe<Ref_States_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ref_Country_Max_Fields = {
  __typename?: 'ref_country_max_fields';
  id?: Maybe<Scalars['Int']>;
  iso2_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phonecode?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Ref_Country_Min_Fields = {
  __typename?: 'ref_country_min_fields';
  id?: Maybe<Scalars['Int']>;
  iso2_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phonecode?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "ref_country" */
export type Ref_Country_Mutation_Response = {
  __typename?: 'ref_country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Country>;
};

/** input type for inserting object relation for remote table "ref_country" */
export type Ref_Country_Obj_Rel_Insert_Input = {
  data: Ref_Country_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Country_On_Conflict>;
};

/** on_conflict condition type for table "ref_country" */
export type Ref_Country_On_Conflict = {
  constraint: Ref_Country_Constraint;
  update_columns?: Array<Ref_Country_Update_Column>;
  where?: InputMaybe<Ref_Country_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_country". */
export type Ref_Country_Order_By = {
  id?: InputMaybe<Order_By>;
  iso2_code?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phonecode?: InputMaybe<Order_By>;
  ref_states_aggregate?: InputMaybe<Ref_States_Aggregate_Order_By>;
};

/** primary key columns input for table: ref_country */
export type Ref_Country_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ref_country" */
export enum Ref_Country_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Iso2Code = 'iso2_code',
  /** column name */
  Name = 'name',
  /** column name */
  Phonecode = 'phonecode'
}

/** input type for updating data in table "ref_country" */
export type Ref_Country_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  iso2_code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phonecode?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Ref_Country_Stddev_Fields = {
  __typename?: 'ref_country_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  phonecode?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ref_Country_Stddev_Pop_Fields = {
  __typename?: 'ref_country_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  phonecode?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ref_Country_Stddev_Samp_Fields = {
  __typename?: 'ref_country_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  phonecode?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ref_country" */
export type Ref_Country_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Country_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Country_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  iso2_code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phonecode?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Ref_Country_Sum_Fields = {
  __typename?: 'ref_country_sum_fields';
  id?: Maybe<Scalars['Int']>;
  phonecode?: Maybe<Scalars['Int']>;
};

/** update columns of table "ref_country" */
export enum Ref_Country_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Iso2Code = 'iso2_code',
  /** column name */
  Name = 'name',
  /** column name */
  Phonecode = 'phonecode'
}

export type Ref_Country_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ref_Country_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Country_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Country_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ref_Country_Var_Pop_Fields = {
  __typename?: 'ref_country_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  phonecode?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ref_Country_Var_Samp_Fields = {
  __typename?: 'ref_country_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  phonecode?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ref_Country_Variance_Fields = {
  __typename?: 'ref_country_variance_fields';
  id?: Maybe<Scalars['Float']>;
  phonecode?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ref_currency" */
export type Ref_Currency = {
  __typename?: 'ref_currency';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_currency" */
export type Ref_Currency_Aggregate = {
  __typename?: 'ref_currency_aggregate';
  aggregate?: Maybe<Ref_Currency_Aggregate_Fields>;
  nodes: Array<Ref_Currency>;
};

/** aggregate fields of "ref_currency" */
export type Ref_Currency_Aggregate_Fields = {
  __typename?: 'ref_currency_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Currency_Max_Fields>;
  min?: Maybe<Ref_Currency_Min_Fields>;
};


/** aggregate fields of "ref_currency" */
export type Ref_Currency_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Currency_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_currency". All fields are combined with a logical 'AND'. */
export type Ref_Currency_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Currency_Bool_Exp>>;
  _not?: InputMaybe<Ref_Currency_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Currency_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_currency" */
export enum Ref_Currency_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefCurrencyPkey = 'ref_currency_pkey'
}

/** input type for inserting data into table "ref_currency" */
export type Ref_Currency_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Currency_Max_Fields = {
  __typename?: 'ref_currency_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Currency_Min_Fields = {
  __typename?: 'ref_currency_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_currency" */
export type Ref_Currency_Mutation_Response = {
  __typename?: 'ref_currency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Currency>;
};

/** on_conflict condition type for table "ref_currency" */
export type Ref_Currency_On_Conflict = {
  constraint: Ref_Currency_Constraint;
  update_columns?: Array<Ref_Currency_Update_Column>;
  where?: InputMaybe<Ref_Currency_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_currency". */
export type Ref_Currency_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_currency */
export type Ref_Currency_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_currency" */
export enum Ref_Currency_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_currency" */
export type Ref_Currency_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_currency" */
export type Ref_Currency_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Currency_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Currency_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_currency" */
export enum Ref_Currency_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Currency_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Currency_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Currency_Bool_Exp;
};

/** columns and relationships of "ref_debit_mode" */
export type Ref_Debit_Mode = {
  __typename?: 'ref_debit_mode';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_debit_mode" */
export type Ref_Debit_Mode_Aggregate = {
  __typename?: 'ref_debit_mode_aggregate';
  aggregate?: Maybe<Ref_Debit_Mode_Aggregate_Fields>;
  nodes: Array<Ref_Debit_Mode>;
};

/** aggregate fields of "ref_debit_mode" */
export type Ref_Debit_Mode_Aggregate_Fields = {
  __typename?: 'ref_debit_mode_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Debit_Mode_Max_Fields>;
  min?: Maybe<Ref_Debit_Mode_Min_Fields>;
};


/** aggregate fields of "ref_debit_mode" */
export type Ref_Debit_Mode_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Debit_Mode_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_debit_mode". All fields are combined with a logical 'AND'. */
export type Ref_Debit_Mode_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Debit_Mode_Bool_Exp>>;
  _not?: InputMaybe<Ref_Debit_Mode_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Debit_Mode_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_debit_mode" */
export enum Ref_Debit_Mode_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefNachModePkey = 'ref_nach_mode_pkey'
}

export enum Ref_Debit_Mode_Enum {
  /** auto nach debit */
  Auto = 'AUTO',
  /** manual nach debit */
  Manual = 'MANUAL'
}

/** Boolean expression to compare columns of type "ref_debit_mode_enum". All fields are combined with logical 'AND'. */
export type Ref_Debit_Mode_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Debit_Mode_Enum>;
  _in?: InputMaybe<Array<Ref_Debit_Mode_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Debit_Mode_Enum>;
  _nin?: InputMaybe<Array<Ref_Debit_Mode_Enum>>;
};

/** input type for inserting data into table "ref_debit_mode" */
export type Ref_Debit_Mode_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Debit_Mode_Max_Fields = {
  __typename?: 'ref_debit_mode_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Debit_Mode_Min_Fields = {
  __typename?: 'ref_debit_mode_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_debit_mode" */
export type Ref_Debit_Mode_Mutation_Response = {
  __typename?: 'ref_debit_mode_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Debit_Mode>;
};

/** on_conflict condition type for table "ref_debit_mode" */
export type Ref_Debit_Mode_On_Conflict = {
  constraint: Ref_Debit_Mode_Constraint;
  update_columns?: Array<Ref_Debit_Mode_Update_Column>;
  where?: InputMaybe<Ref_Debit_Mode_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_debit_mode". */
export type Ref_Debit_Mode_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_debit_mode */
export type Ref_Debit_Mode_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_debit_mode" */
export enum Ref_Debit_Mode_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_debit_mode" */
export type Ref_Debit_Mode_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_debit_mode" */
export type Ref_Debit_Mode_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Debit_Mode_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Debit_Mode_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_debit_mode" */
export enum Ref_Debit_Mode_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Ref_Debit_Mode_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Debit_Mode_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Debit_Mode_Bool_Exp;
};

/** columns and relationships of "ref_document_checklist" */
export type Ref_Document_Checklist = {
  __typename?: 'ref_document_checklist';
  id: Scalars['Int'];
  internal_tip?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_mandatory: Scalars['Boolean'];
  name: Scalars['String'];
  ref_document_checklist_category_id: Scalars['Int'];
  serial_number: Scalars['String'];
  subtext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ref_document_checklist" */
export type Ref_Document_Checklist_Aggregate = {
  __typename?: 'ref_document_checklist_aggregate';
  aggregate?: Maybe<Ref_Document_Checklist_Aggregate_Fields>;
  nodes: Array<Ref_Document_Checklist>;
};

export type Ref_Document_Checklist_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Ref_Document_Checklist_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Ref_Document_Checklist_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Ref_Document_Checklist_Aggregate_Bool_Exp_Count>;
};

export type Ref_Document_Checklist_Aggregate_Bool_Exp_Bool_And = {
  arguments: Ref_Document_Checklist_Select_Column_Ref_Document_Checklist_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ref_Document_Checklist_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ref_Document_Checklist_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Ref_Document_Checklist_Select_Column_Ref_Document_Checklist_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ref_Document_Checklist_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ref_Document_Checklist_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ref_Document_Checklist_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ref_Document_Checklist_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ref_document_checklist" */
export type Ref_Document_Checklist_Aggregate_Fields = {
  __typename?: 'ref_document_checklist_aggregate_fields';
  avg?: Maybe<Ref_Document_Checklist_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ref_Document_Checklist_Max_Fields>;
  min?: Maybe<Ref_Document_Checklist_Min_Fields>;
  stddev?: Maybe<Ref_Document_Checklist_Stddev_Fields>;
  stddev_pop?: Maybe<Ref_Document_Checklist_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ref_Document_Checklist_Stddev_Samp_Fields>;
  sum?: Maybe<Ref_Document_Checklist_Sum_Fields>;
  var_pop?: Maybe<Ref_Document_Checklist_Var_Pop_Fields>;
  var_samp?: Maybe<Ref_Document_Checklist_Var_Samp_Fields>;
  variance?: Maybe<Ref_Document_Checklist_Variance_Fields>;
};


/** aggregate fields of "ref_document_checklist" */
export type Ref_Document_Checklist_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Document_Checklist_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ref_document_checklist" */
export type Ref_Document_Checklist_Aggregate_Order_By = {
  avg?: InputMaybe<Ref_Document_Checklist_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ref_Document_Checklist_Max_Order_By>;
  min?: InputMaybe<Ref_Document_Checklist_Min_Order_By>;
  stddev?: InputMaybe<Ref_Document_Checklist_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ref_Document_Checklist_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ref_Document_Checklist_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ref_Document_Checklist_Sum_Order_By>;
  var_pop?: InputMaybe<Ref_Document_Checklist_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ref_Document_Checklist_Var_Samp_Order_By>;
  variance?: InputMaybe<Ref_Document_Checklist_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ref_document_checklist" */
export type Ref_Document_Checklist_Arr_Rel_Insert_Input = {
  data: Array<Ref_Document_Checklist_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Document_Checklist_On_Conflict>;
};

/** aggregate avg on columns */
export type Ref_Document_Checklist_Avg_Fields = {
  __typename?: 'ref_document_checklist_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ref_document_checklist" */
export type Ref_Document_Checklist_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ref_document_checklist". All fields are combined with a logical 'AND'. */
export type Ref_Document_Checklist_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Document_Checklist_Bool_Exp>>;
  _not?: InputMaybe<Ref_Document_Checklist_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Document_Checklist_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  internal_tip?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_mandatory?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ref_document_checklist_category_id?: InputMaybe<Int_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  subtext?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "ref_document_checklist_category" */
export type Ref_Document_Checklist_Category = {
  __typename?: 'ref_document_checklist_category';
  id: Scalars['Int'];
  is_applicable: Scalars['Boolean'];
  name: Scalars['String'];
  /** An array relationship */
  ref_document_checklists: Array<Ref_Document_Checklist>;
  /** An aggregate relationship */
  ref_document_checklists_aggregate: Ref_Document_Checklist_Aggregate;
  sequence: Scalars['Int'];
};


/** columns and relationships of "ref_document_checklist_category" */
export type Ref_Document_Checklist_CategoryRef_Document_ChecklistsArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Bool_Exp>;
};


/** columns and relationships of "ref_document_checklist_category" */
export type Ref_Document_Checklist_CategoryRef_Document_Checklists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Bool_Exp>;
};

/** aggregated selection of "ref_document_checklist_category" */
export type Ref_Document_Checklist_Category_Aggregate = {
  __typename?: 'ref_document_checklist_category_aggregate';
  aggregate?: Maybe<Ref_Document_Checklist_Category_Aggregate_Fields>;
  nodes: Array<Ref_Document_Checklist_Category>;
};

/** aggregate fields of "ref_document_checklist_category" */
export type Ref_Document_Checklist_Category_Aggregate_Fields = {
  __typename?: 'ref_document_checklist_category_aggregate_fields';
  avg?: Maybe<Ref_Document_Checklist_Category_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ref_Document_Checklist_Category_Max_Fields>;
  min?: Maybe<Ref_Document_Checklist_Category_Min_Fields>;
  stddev?: Maybe<Ref_Document_Checklist_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Ref_Document_Checklist_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ref_Document_Checklist_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Ref_Document_Checklist_Category_Sum_Fields>;
  var_pop?: Maybe<Ref_Document_Checklist_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Ref_Document_Checklist_Category_Var_Samp_Fields>;
  variance?: Maybe<Ref_Document_Checklist_Category_Variance_Fields>;
};


/** aggregate fields of "ref_document_checklist_category" */
export type Ref_Document_Checklist_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Document_Checklist_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ref_Document_Checklist_Category_Avg_Fields = {
  __typename?: 'ref_document_checklist_category_avg_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ref_document_checklist_category". All fields are combined with a logical 'AND'. */
export type Ref_Document_Checklist_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Document_Checklist_Category_Bool_Exp>>;
  _not?: InputMaybe<Ref_Document_Checklist_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Document_Checklist_Category_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_applicable?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ref_document_checklists?: InputMaybe<Ref_Document_Checklist_Bool_Exp>;
  ref_document_checklists_aggregate?: InputMaybe<Ref_Document_Checklist_Aggregate_Bool_Exp>;
  sequence?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_document_checklist_category" */
export enum Ref_Document_Checklist_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefDocumentChecklistCategoryPkey = 'ref_document_checklist_category_pkey'
}

/** input type for incrementing numeric columns in table "ref_document_checklist_category" */
export type Ref_Document_Checklist_Category_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ref_document_checklist_category" */
export type Ref_Document_Checklist_Category_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_applicable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ref_document_checklists?: InputMaybe<Ref_Document_Checklist_Arr_Rel_Insert_Input>;
  sequence?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Ref_Document_Checklist_Category_Max_Fields = {
  __typename?: 'ref_document_checklist_category_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Ref_Document_Checklist_Category_Min_Fields = {
  __typename?: 'ref_document_checklist_category_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "ref_document_checklist_category" */
export type Ref_Document_Checklist_Category_Mutation_Response = {
  __typename?: 'ref_document_checklist_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Document_Checklist_Category>;
};

/** input type for inserting object relation for remote table "ref_document_checklist_category" */
export type Ref_Document_Checklist_Category_Obj_Rel_Insert_Input = {
  data: Ref_Document_Checklist_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Document_Checklist_Category_On_Conflict>;
};

/** on_conflict condition type for table "ref_document_checklist_category" */
export type Ref_Document_Checklist_Category_On_Conflict = {
  constraint: Ref_Document_Checklist_Category_Constraint;
  update_columns?: Array<Ref_Document_Checklist_Category_Update_Column>;
  where?: InputMaybe<Ref_Document_Checklist_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_document_checklist_category". */
export type Ref_Document_Checklist_Category_Order_By = {
  id?: InputMaybe<Order_By>;
  is_applicable?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ref_document_checklists_aggregate?: InputMaybe<Ref_Document_Checklist_Aggregate_Order_By>;
  sequence?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_document_checklist_category */
export type Ref_Document_Checklist_Category_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ref_document_checklist_category" */
export enum Ref_Document_Checklist_Category_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsApplicable = 'is_applicable',
  /** column name */
  Name = 'name',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "ref_document_checklist_category" */
export type Ref_Document_Checklist_Category_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_applicable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Ref_Document_Checklist_Category_Stddev_Fields = {
  __typename?: 'ref_document_checklist_category_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ref_Document_Checklist_Category_Stddev_Pop_Fields = {
  __typename?: 'ref_document_checklist_category_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ref_Document_Checklist_Category_Stddev_Samp_Fields = {
  __typename?: 'ref_document_checklist_category_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ref_document_checklist_category" */
export type Ref_Document_Checklist_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Document_Checklist_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Document_Checklist_Category_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_applicable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Ref_Document_Checklist_Category_Sum_Fields = {
  __typename?: 'ref_document_checklist_category_sum_fields';
  id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** update columns of table "ref_document_checklist_category" */
export enum Ref_Document_Checklist_Category_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsApplicable = 'is_applicable',
  /** column name */
  Name = 'name',
  /** column name */
  Sequence = 'sequence'
}

export type Ref_Document_Checklist_Category_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ref_Document_Checklist_Category_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Document_Checklist_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Document_Checklist_Category_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ref_Document_Checklist_Category_Var_Pop_Fields = {
  __typename?: 'ref_document_checklist_category_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ref_Document_Checklist_Category_Var_Samp_Fields = {
  __typename?: 'ref_document_checklist_category_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ref_Document_Checklist_Category_Variance_Fields = {
  __typename?: 'ref_document_checklist_category_variance_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "ref_document_checklist" */
export enum Ref_Document_Checklist_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefDocumentChecklistPkey = 'ref_document_checklist_pkey'
}

/** input type for incrementing numeric columns in table "ref_document_checklist" */
export type Ref_Document_Checklist_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  ref_document_checklist_category_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ref_document_checklist" */
export type Ref_Document_Checklist_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  internal_tip?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_mandatory?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ref_document_checklist_category_id?: InputMaybe<Scalars['Int']>;
  serial_number?: InputMaybe<Scalars['String']>;
  subtext?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "ref_document_checklist_integration" */
export type Ref_Document_Checklist_Integration = {
  __typename?: 'ref_document_checklist_integration';
  id: Scalars['String'];
  serial_number: Scalars['String'];
};

/** aggregated selection of "ref_document_checklist_integration" */
export type Ref_Document_Checklist_Integration_Aggregate = {
  __typename?: 'ref_document_checklist_integration_aggregate';
  aggregate?: Maybe<Ref_Document_Checklist_Integration_Aggregate_Fields>;
  nodes: Array<Ref_Document_Checklist_Integration>;
};

/** aggregate fields of "ref_document_checklist_integration" */
export type Ref_Document_Checklist_Integration_Aggregate_Fields = {
  __typename?: 'ref_document_checklist_integration_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Document_Checklist_Integration_Max_Fields>;
  min?: Maybe<Ref_Document_Checklist_Integration_Min_Fields>;
};


/** aggregate fields of "ref_document_checklist_integration" */
export type Ref_Document_Checklist_Integration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Document_Checklist_Integration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_document_checklist_integration". All fields are combined with a logical 'AND'. */
export type Ref_Document_Checklist_Integration_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Document_Checklist_Integration_Bool_Exp>>;
  _not?: InputMaybe<Ref_Document_Checklist_Integration_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Document_Checklist_Integration_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_document_checklist_integration" */
export enum Ref_Document_Checklist_Integration_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefDocumentChecklistIntegrationPkey = 'ref_document_checklist_integration_pkey'
}

/** input type for inserting data into table "ref_document_checklist_integration" */
export type Ref_Document_Checklist_Integration_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Document_Checklist_Integration_Max_Fields = {
  __typename?: 'ref_document_checklist_integration_max_fields';
  id?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Document_Checklist_Integration_Min_Fields = {
  __typename?: 'ref_document_checklist_integration_min_fields';
  id?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_document_checklist_integration" */
export type Ref_Document_Checklist_Integration_Mutation_Response = {
  __typename?: 'ref_document_checklist_integration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Document_Checklist_Integration>;
};

/** input type for inserting object relation for remote table "ref_document_checklist_integration" */
export type Ref_Document_Checklist_Integration_Obj_Rel_Insert_Input = {
  data: Ref_Document_Checklist_Integration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Document_Checklist_Integration_On_Conflict>;
};

/** on_conflict condition type for table "ref_document_checklist_integration" */
export type Ref_Document_Checklist_Integration_On_Conflict = {
  constraint: Ref_Document_Checklist_Integration_Constraint;
  update_columns?: Array<Ref_Document_Checklist_Integration_Update_Column>;
  where?: InputMaybe<Ref_Document_Checklist_Integration_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_document_checklist_integration". */
export type Ref_Document_Checklist_Integration_Order_By = {
  id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_document_checklist_integration */
export type Ref_Document_Checklist_Integration_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_document_checklist_integration" */
export enum Ref_Document_Checklist_Integration_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SerialNumber = 'serial_number'
}

/** input type for updating data in table "ref_document_checklist_integration" */
export type Ref_Document_Checklist_Integration_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_document_checklist_integration" */
export type Ref_Document_Checklist_Integration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Document_Checklist_Integration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Document_Checklist_Integration_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_document_checklist_integration" */
export enum Ref_Document_Checklist_Integration_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SerialNumber = 'serial_number'
}

export type Ref_Document_Checklist_Integration_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Document_Checklist_Integration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Document_Checklist_Integration_Bool_Exp;
};

/** aggregate max on columns */
export type Ref_Document_Checklist_Max_Fields = {
  __typename?: 'ref_document_checklist_max_fields';
  id?: Maybe<Scalars['Int']>;
  internal_tip?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Int']>;
  serial_number?: Maybe<Scalars['String']>;
  subtext?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ref_document_checklist" */
export type Ref_Document_Checklist_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  internal_tip?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  subtext?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ref_Document_Checklist_Min_Fields = {
  __typename?: 'ref_document_checklist_min_fields';
  id?: Maybe<Scalars['Int']>;
  internal_tip?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Int']>;
  serial_number?: Maybe<Scalars['String']>;
  subtext?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ref_document_checklist" */
export type Ref_Document_Checklist_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  internal_tip?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  subtext?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ref_document_checklist" */
export type Ref_Document_Checklist_Mutation_Response = {
  __typename?: 'ref_document_checklist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Document_Checklist>;
};

/** input type for inserting object relation for remote table "ref_document_checklist" */
export type Ref_Document_Checklist_Obj_Rel_Insert_Input = {
  data: Ref_Document_Checklist_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Document_Checklist_On_Conflict>;
};

/** on_conflict condition type for table "ref_document_checklist" */
export type Ref_Document_Checklist_On_Conflict = {
  constraint: Ref_Document_Checklist_Constraint;
  update_columns?: Array<Ref_Document_Checklist_Update_Column>;
  where?: InputMaybe<Ref_Document_Checklist_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_document_checklist". */
export type Ref_Document_Checklist_Order_By = {
  id?: InputMaybe<Order_By>;
  internal_tip?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_mandatory?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  subtext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_document_checklist */
export type Ref_Document_Checklist_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ref_document_checklist" */
export enum Ref_Document_Checklist_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InternalTip = 'internal_tip',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsMandatory = 'is_mandatory',
  /** column name */
  Name = 'name',
  /** column name */
  RefDocumentChecklistCategoryId = 'ref_document_checklist_category_id',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  Subtext = 'subtext'
}

/** select "ref_document_checklist_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ref_document_checklist" */
export enum Ref_Document_Checklist_Select_Column_Ref_Document_Checklist_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsMandatory = 'is_mandatory'
}

/** select "ref_document_checklist_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ref_document_checklist" */
export enum Ref_Document_Checklist_Select_Column_Ref_Document_Checklist_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsMandatory = 'is_mandatory'
}

/** input type for updating data in table "ref_document_checklist" */
export type Ref_Document_Checklist_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  internal_tip?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_mandatory?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ref_document_checklist_category_id?: InputMaybe<Scalars['Int']>;
  serial_number?: InputMaybe<Scalars['String']>;
  subtext?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "ref_document_checklist_status" */
export type Ref_Document_Checklist_Status = {
  __typename?: 'ref_document_checklist_status';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_document_checklist_status" */
export type Ref_Document_Checklist_Status_Aggregate = {
  __typename?: 'ref_document_checklist_status_aggregate';
  aggregate?: Maybe<Ref_Document_Checklist_Status_Aggregate_Fields>;
  nodes: Array<Ref_Document_Checklist_Status>;
};

/** aggregate fields of "ref_document_checklist_status" */
export type Ref_Document_Checklist_Status_Aggregate_Fields = {
  __typename?: 'ref_document_checklist_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Document_Checklist_Status_Max_Fields>;
  min?: Maybe<Ref_Document_Checklist_Status_Min_Fields>;
};


/** aggregate fields of "ref_document_checklist_status" */
export type Ref_Document_Checklist_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Document_Checklist_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_document_checklist_status". All fields are combined with a logical 'AND'. */
export type Ref_Document_Checklist_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Document_Checklist_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_Document_Checklist_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Document_Checklist_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_document_checklist_status" */
export enum Ref_Document_Checklist_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefDocumentChecklistStatusPkey = 'ref_document_checklist_status_pkey'
}

export enum Ref_Document_Checklist_Status_Enum {
  /** Completed */
  Completed = 'COMPLETED',
  /** Incomplete */
  Incomplete = 'INCOMPLETE',
  /** Not Applicable */
  NotApplicable = 'NOT_APPLICABLE',
  /** Not Received */
  NotReceived = 'NOT_RECEIVED'
}

/** Boolean expression to compare columns of type "ref_document_checklist_status_enum". All fields are combined with logical 'AND'. */
export type Ref_Document_Checklist_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Document_Checklist_Status_Enum>;
  _in?: InputMaybe<Array<Ref_Document_Checklist_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Document_Checklist_Status_Enum>;
  _nin?: InputMaybe<Array<Ref_Document_Checklist_Status_Enum>>;
};

/** input type for inserting data into table "ref_document_checklist_status" */
export type Ref_Document_Checklist_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Document_Checklist_Status_Max_Fields = {
  __typename?: 'ref_document_checklist_status_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Document_Checklist_Status_Min_Fields = {
  __typename?: 'ref_document_checklist_status_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_document_checklist_status" */
export type Ref_Document_Checklist_Status_Mutation_Response = {
  __typename?: 'ref_document_checklist_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Document_Checklist_Status>;
};

/** input type for inserting object relation for remote table "ref_document_checklist_status" */
export type Ref_Document_Checklist_Status_Obj_Rel_Insert_Input = {
  data: Ref_Document_Checklist_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Document_Checklist_Status_On_Conflict>;
};

/** on_conflict condition type for table "ref_document_checklist_status" */
export type Ref_Document_Checklist_Status_On_Conflict = {
  constraint: Ref_Document_Checklist_Status_Constraint;
  update_columns?: Array<Ref_Document_Checklist_Status_Update_Column>;
  where?: InputMaybe<Ref_Document_Checklist_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_document_checklist_status". */
export type Ref_Document_Checklist_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_document_checklist_status */
export type Ref_Document_Checklist_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_document_checklist_status" */
export enum Ref_Document_Checklist_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_document_checklist_status" */
export type Ref_Document_Checklist_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_document_checklist_status" */
export type Ref_Document_Checklist_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Document_Checklist_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Document_Checklist_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_document_checklist_status" */
export enum Ref_Document_Checklist_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Ref_Document_Checklist_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Document_Checklist_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Document_Checklist_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Ref_Document_Checklist_Stddev_Fields = {
  __typename?: 'ref_document_checklist_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ref_document_checklist" */
export type Ref_Document_Checklist_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ref_Document_Checklist_Stddev_Pop_Fields = {
  __typename?: 'ref_document_checklist_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ref_document_checklist" */
export type Ref_Document_Checklist_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ref_Document_Checklist_Stddev_Samp_Fields = {
  __typename?: 'ref_document_checklist_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ref_document_checklist" */
export type Ref_Document_Checklist_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ref_document_checklist" */
export type Ref_Document_Checklist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Document_Checklist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Document_Checklist_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  internal_tip?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_mandatory?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ref_document_checklist_category_id?: InputMaybe<Scalars['Int']>;
  serial_number?: InputMaybe<Scalars['String']>;
  subtext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Ref_Document_Checklist_Sum_Fields = {
  __typename?: 'ref_document_checklist_sum_fields';
  id?: Maybe<Scalars['Int']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ref_document_checklist" */
export type Ref_Document_Checklist_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
};

/** update columns of table "ref_document_checklist" */
export enum Ref_Document_Checklist_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InternalTip = 'internal_tip',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsMandatory = 'is_mandatory',
  /** column name */
  Name = 'name',
  /** column name */
  RefDocumentChecklistCategoryId = 'ref_document_checklist_category_id',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  Subtext = 'subtext'
}

export type Ref_Document_Checklist_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ref_Document_Checklist_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Document_Checklist_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Document_Checklist_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ref_Document_Checklist_Var_Pop_Fields = {
  __typename?: 'ref_document_checklist_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ref_document_checklist" */
export type Ref_Document_Checklist_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ref_Document_Checklist_Var_Samp_Fields = {
  __typename?: 'ref_document_checklist_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ref_document_checklist" */
export type Ref_Document_Checklist_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ref_Document_Checklist_Variance_Fields = {
  __typename?: 'ref_document_checklist_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ref_document_checklist_category_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ref_document_checklist" */
export type Ref_Document_Checklist_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  ref_document_checklist_category_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ref_email_events_type" */
export type Ref_Email_Events_Type = {
  __typename?: 'ref_email_events_type';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_email_events_type" */
export type Ref_Email_Events_Type_Aggregate = {
  __typename?: 'ref_email_events_type_aggregate';
  aggregate?: Maybe<Ref_Email_Events_Type_Aggregate_Fields>;
  nodes: Array<Ref_Email_Events_Type>;
};

/** aggregate fields of "ref_email_events_type" */
export type Ref_Email_Events_Type_Aggregate_Fields = {
  __typename?: 'ref_email_events_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Email_Events_Type_Max_Fields>;
  min?: Maybe<Ref_Email_Events_Type_Min_Fields>;
};


/** aggregate fields of "ref_email_events_type" */
export type Ref_Email_Events_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Email_Events_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_email_events_type". All fields are combined with a logical 'AND'. */
export type Ref_Email_Events_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Email_Events_Type_Bool_Exp>>;
  _not?: InputMaybe<Ref_Email_Events_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Email_Events_Type_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_email_events_type" */
export enum Ref_Email_Events_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefEmailEventsTypePkey = 'ref_email_events_type_pkey'
}

export enum Ref_Email_Events_Type_Enum {
  /** Both HTML and TEXT Template Type */
  Both = 'BOTH',
  /** HTML Template Type */
  Html = 'HTML',
  /** Text Template Type */
  Text = 'TEXT'
}

/** Boolean expression to compare columns of type "ref_email_events_type_enum". All fields are combined with logical 'AND'. */
export type Ref_Email_Events_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Email_Events_Type_Enum>;
  _in?: InputMaybe<Array<Ref_Email_Events_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Email_Events_Type_Enum>;
  _nin?: InputMaybe<Array<Ref_Email_Events_Type_Enum>>;
};

/** input type for inserting data into table "ref_email_events_type" */
export type Ref_Email_Events_Type_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Email_Events_Type_Max_Fields = {
  __typename?: 'ref_email_events_type_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Email_Events_Type_Min_Fields = {
  __typename?: 'ref_email_events_type_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_email_events_type" */
export type Ref_Email_Events_Type_Mutation_Response = {
  __typename?: 'ref_email_events_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Email_Events_Type>;
};

/** on_conflict condition type for table "ref_email_events_type" */
export type Ref_Email_Events_Type_On_Conflict = {
  constraint: Ref_Email_Events_Type_Constraint;
  update_columns?: Array<Ref_Email_Events_Type_Update_Column>;
  where?: InputMaybe<Ref_Email_Events_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_email_events_type". */
export type Ref_Email_Events_Type_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_email_events_type */
export type Ref_Email_Events_Type_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_email_events_type" */
export enum Ref_Email_Events_Type_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_email_events_type" */
export type Ref_Email_Events_Type_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_email_events_type" */
export type Ref_Email_Events_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Email_Events_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Email_Events_Type_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_email_events_type" */
export enum Ref_Email_Events_Type_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Email_Events_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Email_Events_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Email_Events_Type_Bool_Exp;
};

/** columns and relationships of "ref_file_category" */
export type Ref_File_Category = {
  __typename?: 'ref_file_category';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_file_category" */
export type Ref_File_Category_Aggregate = {
  __typename?: 'ref_file_category_aggregate';
  aggregate?: Maybe<Ref_File_Category_Aggregate_Fields>;
  nodes: Array<Ref_File_Category>;
};

/** aggregate fields of "ref_file_category" */
export type Ref_File_Category_Aggregate_Fields = {
  __typename?: 'ref_file_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_File_Category_Max_Fields>;
  min?: Maybe<Ref_File_Category_Min_Fields>;
};


/** aggregate fields of "ref_file_category" */
export type Ref_File_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_File_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_file_category". All fields are combined with a logical 'AND'. */
export type Ref_File_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_File_Category_Bool_Exp>>;
  _not?: InputMaybe<Ref_File_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_File_Category_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_file_category" */
export enum Ref_File_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefFileCategoryPkey = 'ref_file_category_pkey'
}

export enum Ref_File_Category_Enum {
  /** Accounting Data */
  Accounting = 'ACCOUNTING',
  /** Company and Investor Amortization Tables */
  Amortization = 'AMORTIZATION',
  /** Banking Data */
  Banking = 'BANKING',
  /** Generated APA */
  GeneratedApa = 'GENERATED_APA',
  /** Nach Signed Mandate */
  NachSignedMandate = 'NACH_SIGNED_MANDATE',
  /** Non Disclosure Agreement */
  Nda = 'NDA',
  /** Bank Statement Reports */
  Reports = 'REPORTS',
  /** report generated by scoring engine */
  ScoringEngineReport = 'SCORING_ENGINE_REPORT',
  /** Signed APA */
  SignedApa = 'SIGNED_APA',
  /** Subscription Data */
  Subscription = 'SUBSCRIPTION',
  /** Tds Certificate */
  Tds = 'TDS',
  /** tenant onboard agreement */
  TenantAgreement = 'TENANT_AGREEMENT'
}

/** Boolean expression to compare columns of type "ref_file_category_enum". All fields are combined with logical 'AND'. */
export type Ref_File_Category_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_File_Category_Enum>;
  _in?: InputMaybe<Array<Ref_File_Category_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_File_Category_Enum>;
  _nin?: InputMaybe<Array<Ref_File_Category_Enum>>;
};

/** input type for inserting data into table "ref_file_category" */
export type Ref_File_Category_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_File_Category_Max_Fields = {
  __typename?: 'ref_file_category_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** columns and relationships of "ref_file_category_mimetype" */
export type Ref_File_Category_Mimetype = {
  __typename?: 'ref_file_category_mimetype';
  id: Ref_File_Category_Enum;
  mimetype: Scalars['String'];
};

/** aggregated selection of "ref_file_category_mimetype" */
export type Ref_File_Category_Mimetype_Aggregate = {
  __typename?: 'ref_file_category_mimetype_aggregate';
  aggregate?: Maybe<Ref_File_Category_Mimetype_Aggregate_Fields>;
  nodes: Array<Ref_File_Category_Mimetype>;
};

/** aggregate fields of "ref_file_category_mimetype" */
export type Ref_File_Category_Mimetype_Aggregate_Fields = {
  __typename?: 'ref_file_category_mimetype_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_File_Category_Mimetype_Max_Fields>;
  min?: Maybe<Ref_File_Category_Mimetype_Min_Fields>;
};


/** aggregate fields of "ref_file_category_mimetype" */
export type Ref_File_Category_Mimetype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_File_Category_Mimetype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_file_category_mimetype". All fields are combined with a logical 'AND'. */
export type Ref_File_Category_Mimetype_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_File_Category_Mimetype_Bool_Exp>>;
  _not?: InputMaybe<Ref_File_Category_Mimetype_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_File_Category_Mimetype_Bool_Exp>>;
  id?: InputMaybe<Ref_File_Category_Enum_Comparison_Exp>;
  mimetype?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_file_category_mimetype" */
export enum Ref_File_Category_Mimetype_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefFileCategoryMimetypePkey = 'ref_file_category_mimetype_pkey'
}

/** input type for inserting data into table "ref_file_category_mimetype" */
export type Ref_File_Category_Mimetype_Insert_Input = {
  id?: InputMaybe<Ref_File_Category_Enum>;
  mimetype?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_File_Category_Mimetype_Max_Fields = {
  __typename?: 'ref_file_category_mimetype_max_fields';
  mimetype?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_File_Category_Mimetype_Min_Fields = {
  __typename?: 'ref_file_category_mimetype_min_fields';
  mimetype?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_file_category_mimetype" */
export type Ref_File_Category_Mimetype_Mutation_Response = {
  __typename?: 'ref_file_category_mimetype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_File_Category_Mimetype>;
};

/** on_conflict condition type for table "ref_file_category_mimetype" */
export type Ref_File_Category_Mimetype_On_Conflict = {
  constraint: Ref_File_Category_Mimetype_Constraint;
  update_columns?: Array<Ref_File_Category_Mimetype_Update_Column>;
  where?: InputMaybe<Ref_File_Category_Mimetype_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_file_category_mimetype". */
export type Ref_File_Category_Mimetype_Order_By = {
  id?: InputMaybe<Order_By>;
  mimetype?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_file_category_mimetype */
export type Ref_File_Category_Mimetype_Pk_Columns_Input = {
  id: Ref_File_Category_Enum;
};

/** select columns of table "ref_file_category_mimetype" */
export enum Ref_File_Category_Mimetype_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Mimetype = 'mimetype'
}

/** input type for updating data in table "ref_file_category_mimetype" */
export type Ref_File_Category_Mimetype_Set_Input = {
  id?: InputMaybe<Ref_File_Category_Enum>;
  mimetype?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_file_category_mimetype" */
export type Ref_File_Category_Mimetype_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_File_Category_Mimetype_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_File_Category_Mimetype_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Ref_File_Category_Enum>;
  mimetype?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_file_category_mimetype" */
export enum Ref_File_Category_Mimetype_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Mimetype = 'mimetype'
}

export type Ref_File_Category_Mimetype_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_File_Category_Mimetype_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_File_Category_Mimetype_Bool_Exp;
};

/** aggregate min on columns */
export type Ref_File_Category_Min_Fields = {
  __typename?: 'ref_file_category_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_file_category" */
export type Ref_File_Category_Mutation_Response = {
  __typename?: 'ref_file_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_File_Category>;
};

/** on_conflict condition type for table "ref_file_category" */
export type Ref_File_Category_On_Conflict = {
  constraint: Ref_File_Category_Constraint;
  update_columns?: Array<Ref_File_Category_Update_Column>;
  where?: InputMaybe<Ref_File_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_file_category". */
export type Ref_File_Category_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_file_category */
export type Ref_File_Category_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_file_category" */
export enum Ref_File_Category_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_file_category" */
export type Ref_File_Category_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_file_category" */
export type Ref_File_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_File_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_File_Category_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_file_category" */
export enum Ref_File_Category_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_File_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_File_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_File_Category_Bool_Exp;
};

/** columns and relationships of "ref_industry" */
export type Ref_Industry = {
  __typename?: 'ref_industry';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_industry" */
export type Ref_Industry_Aggregate = {
  __typename?: 'ref_industry_aggregate';
  aggregate?: Maybe<Ref_Industry_Aggregate_Fields>;
  nodes: Array<Ref_Industry>;
};

/** aggregate fields of "ref_industry" */
export type Ref_Industry_Aggregate_Fields = {
  __typename?: 'ref_industry_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Industry_Max_Fields>;
  min?: Maybe<Ref_Industry_Min_Fields>;
};


/** aggregate fields of "ref_industry" */
export type Ref_Industry_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Industry_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_industry". All fields are combined with a logical 'AND'. */
export type Ref_Industry_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Industry_Bool_Exp>>;
  _not?: InputMaybe<Ref_Industry_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Industry_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_industry" */
export enum Ref_Industry_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefIndustryPkey = 'ref_industry_pkey'
}

/** input type for inserting data into table "ref_industry" */
export type Ref_Industry_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Industry_Max_Fields = {
  __typename?: 'ref_industry_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Industry_Min_Fields = {
  __typename?: 'ref_industry_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_industry" */
export type Ref_Industry_Mutation_Response = {
  __typename?: 'ref_industry_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Industry>;
};

/** input type for inserting object relation for remote table "ref_industry" */
export type Ref_Industry_Obj_Rel_Insert_Input = {
  data: Ref_Industry_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Industry_On_Conflict>;
};

/** on_conflict condition type for table "ref_industry" */
export type Ref_Industry_On_Conflict = {
  constraint: Ref_Industry_Constraint;
  update_columns?: Array<Ref_Industry_Update_Column>;
  where?: InputMaybe<Ref_Industry_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_industry". */
export type Ref_Industry_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_industry */
export type Ref_Industry_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_industry" */
export enum Ref_Industry_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_industry" */
export type Ref_Industry_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_industry" */
export type Ref_Industry_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Industry_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Industry_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_industry" */
export enum Ref_Industry_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Industry_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Industry_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Industry_Bool_Exp;
};

/** columns and relationships of "ref_integration_file_status" */
export type Ref_Integration_File_Status = {
  __typename?: 'ref_integration_file_status';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_integration_file_status" */
export type Ref_Integration_File_Status_Aggregate = {
  __typename?: 'ref_integration_file_status_aggregate';
  aggregate?: Maybe<Ref_Integration_File_Status_Aggregate_Fields>;
  nodes: Array<Ref_Integration_File_Status>;
};

/** aggregate fields of "ref_integration_file_status" */
export type Ref_Integration_File_Status_Aggregate_Fields = {
  __typename?: 'ref_integration_file_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Integration_File_Status_Max_Fields>;
  min?: Maybe<Ref_Integration_File_Status_Min_Fields>;
};


/** aggregate fields of "ref_integration_file_status" */
export type Ref_Integration_File_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Integration_File_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_integration_file_status". All fields are combined with a logical 'AND'. */
export type Ref_Integration_File_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Integration_File_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_Integration_File_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Integration_File_Status_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_integration_file_status" */
export enum Ref_Integration_File_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefIntegrationFileStatusPkey = 'ref_integration_file_status_pkey'
}

/** input type for inserting data into table "ref_integration_file_status" */
export type Ref_Integration_File_Status_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Integration_File_Status_Max_Fields = {
  __typename?: 'ref_integration_file_status_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Integration_File_Status_Min_Fields = {
  __typename?: 'ref_integration_file_status_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_integration_file_status" */
export type Ref_Integration_File_Status_Mutation_Response = {
  __typename?: 'ref_integration_file_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Integration_File_Status>;
};

/** on_conflict condition type for table "ref_integration_file_status" */
export type Ref_Integration_File_Status_On_Conflict = {
  constraint: Ref_Integration_File_Status_Constraint;
  update_columns?: Array<Ref_Integration_File_Status_Update_Column>;
  where?: InputMaybe<Ref_Integration_File_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_integration_file_status". */
export type Ref_Integration_File_Status_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_integration_file_status */
export type Ref_Integration_File_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_integration_file_status" */
export enum Ref_Integration_File_Status_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_integration_file_status" */
export type Ref_Integration_File_Status_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_integration_file_status" */
export type Ref_Integration_File_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Integration_File_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Integration_File_Status_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_integration_file_status" */
export enum Ref_Integration_File_Status_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Integration_File_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Integration_File_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Integration_File_Status_Bool_Exp;
};

/** columns and relationships of "ref_integration_status" */
export type Ref_Integration_Status = {
  __typename?: 'ref_integration_status';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_integration_status" */
export type Ref_Integration_Status_Aggregate = {
  __typename?: 'ref_integration_status_aggregate';
  aggregate?: Maybe<Ref_Integration_Status_Aggregate_Fields>;
  nodes: Array<Ref_Integration_Status>;
};

/** aggregate fields of "ref_integration_status" */
export type Ref_Integration_Status_Aggregate_Fields = {
  __typename?: 'ref_integration_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Integration_Status_Max_Fields>;
  min?: Maybe<Ref_Integration_Status_Min_Fields>;
};


/** aggregate fields of "ref_integration_status" */
export type Ref_Integration_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Integration_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_integration_status". All fields are combined with a logical 'AND'. */
export type Ref_Integration_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Integration_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_Integration_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Integration_Status_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_integration_status" */
export enum Ref_Integration_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefIntegrationStatusPkey = 'ref_integration_status_pkey'
}

/** input type for inserting data into table "ref_integration_status" */
export type Ref_Integration_Status_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Integration_Status_Max_Fields = {
  __typename?: 'ref_integration_status_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Integration_Status_Min_Fields = {
  __typename?: 'ref_integration_status_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_integration_status" */
export type Ref_Integration_Status_Mutation_Response = {
  __typename?: 'ref_integration_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Integration_Status>;
};

/** on_conflict condition type for table "ref_integration_status" */
export type Ref_Integration_Status_On_Conflict = {
  constraint: Ref_Integration_Status_Constraint;
  update_columns?: Array<Ref_Integration_Status_Update_Column>;
  where?: InputMaybe<Ref_Integration_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_integration_status". */
export type Ref_Integration_Status_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_integration_status */
export type Ref_Integration_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_integration_status" */
export enum Ref_Integration_Status_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_integration_status" */
export type Ref_Integration_Status_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_integration_status" */
export type Ref_Integration_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Integration_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Integration_Status_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_integration_status" */
export enum Ref_Integration_Status_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Integration_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Integration_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Integration_Status_Bool_Exp;
};

/** columns and relationships of "ref_investor_status" */
export type Ref_Investor_Status = {
  __typename?: 'ref_investor_status';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_investor_status" */
export type Ref_Investor_Status_Aggregate = {
  __typename?: 'ref_investor_status_aggregate';
  aggregate?: Maybe<Ref_Investor_Status_Aggregate_Fields>;
  nodes: Array<Ref_Investor_Status>;
};

/** aggregate fields of "ref_investor_status" */
export type Ref_Investor_Status_Aggregate_Fields = {
  __typename?: 'ref_investor_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Investor_Status_Max_Fields>;
  min?: Maybe<Ref_Investor_Status_Min_Fields>;
};


/** aggregate fields of "ref_investor_status" */
export type Ref_Investor_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Investor_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_investor_status". All fields are combined with a logical 'AND'. */
export type Ref_Investor_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Investor_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_Investor_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Investor_Status_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_investor_status" */
export enum Ref_Investor_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefInvestorStatusPkey = 'ref_investor_status_pkey'
}

/** input type for inserting data into table "ref_investor_status" */
export type Ref_Investor_Status_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Investor_Status_Max_Fields = {
  __typename?: 'ref_investor_status_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Investor_Status_Min_Fields = {
  __typename?: 'ref_investor_status_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_investor_status" */
export type Ref_Investor_Status_Mutation_Response = {
  __typename?: 'ref_investor_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Investor_Status>;
};

/** on_conflict condition type for table "ref_investor_status" */
export type Ref_Investor_Status_On_Conflict = {
  constraint: Ref_Investor_Status_Constraint;
  update_columns?: Array<Ref_Investor_Status_Update_Column>;
  where?: InputMaybe<Ref_Investor_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_investor_status". */
export type Ref_Investor_Status_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_investor_status */
export type Ref_Investor_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_investor_status" */
export enum Ref_Investor_Status_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_investor_status" */
export type Ref_Investor_Status_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_investor_status" */
export type Ref_Investor_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Investor_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Investor_Status_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_investor_status" */
export enum Ref_Investor_Status_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Investor_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Investor_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Investor_Status_Bool_Exp;
};

/** columns and relationships of "ref_investor_type" */
export type Ref_Investor_Type = {
  __typename?: 'ref_investor_type';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ref_investor_type" */
export type Ref_Investor_Type_Aggregate = {
  __typename?: 'ref_investor_type_aggregate';
  aggregate?: Maybe<Ref_Investor_Type_Aggregate_Fields>;
  nodes: Array<Ref_Investor_Type>;
};

/** aggregate fields of "ref_investor_type" */
export type Ref_Investor_Type_Aggregate_Fields = {
  __typename?: 'ref_investor_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Investor_Type_Max_Fields>;
  min?: Maybe<Ref_Investor_Type_Min_Fields>;
};


/** aggregate fields of "ref_investor_type" */
export type Ref_Investor_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Investor_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_investor_type". All fields are combined with a logical 'AND'. */
export type Ref_Investor_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Investor_Type_Bool_Exp>>;
  _not?: InputMaybe<Ref_Investor_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Investor_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_investor_type" */
export enum Ref_Investor_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  RefInvestorTypePkey = 'ref_investor_type_pkey'
}

export enum Ref_Investor_Type_Enum {
  /** Bank */
  Bank = 'BANK',
  /** corporate */
  Corporate = 'CORPORATE',
  /** Family Office */
  FamilyOffice = 'FAMILY_OFFICE',
  /** Funds */
  Funds = 'FUNDS',
  /** HNI */
  Hni = 'HNI',
  /** NBFC */
  Nbfc = 'NBFC',
  /** Retail Investor */
  RetailInvestor = 'RETAIL_INVESTOR'
}

/** Boolean expression to compare columns of type "ref_investor_type_enum". All fields are combined with logical 'AND'. */
export type Ref_Investor_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Investor_Type_Enum>;
  _in?: InputMaybe<Array<Ref_Investor_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Investor_Type_Enum>;
  _nin?: InputMaybe<Array<Ref_Investor_Type_Enum>>;
};

/** input type for inserting data into table "ref_investor_type" */
export type Ref_Investor_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Investor_Type_Max_Fields = {
  __typename?: 'ref_investor_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Investor_Type_Min_Fields = {
  __typename?: 'ref_investor_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_investor_type" */
export type Ref_Investor_Type_Mutation_Response = {
  __typename?: 'ref_investor_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Investor_Type>;
};

/** input type for inserting object relation for remote table "ref_investor_type" */
export type Ref_Investor_Type_Obj_Rel_Insert_Input = {
  data: Ref_Investor_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Investor_Type_On_Conflict>;
};

/** on_conflict condition type for table "ref_investor_type" */
export type Ref_Investor_Type_On_Conflict = {
  constraint: Ref_Investor_Type_Constraint;
  update_columns?: Array<Ref_Investor_Type_Update_Column>;
  where?: InputMaybe<Ref_Investor_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_investor_type". */
export type Ref_Investor_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_investor_type */
export type Ref_Investor_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ref_investor_type" */
export enum Ref_Investor_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ref_investor_type" */
export type Ref_Investor_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_investor_type" */
export type Ref_Investor_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Investor_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Investor_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_investor_type" */
export enum Ref_Investor_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Ref_Investor_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Investor_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Investor_Type_Bool_Exp;
};

/** columns and relationships of "ref_lifecycle_question" */
export type Ref_Lifecycle_Question = {
  __typename?: 'ref_lifecycle_question';
  category: Ref_Lifecycle_Question_Category_Enum;
  id: Scalars['Int'];
  question: Scalars['String'];
  sequence: Scalars['Int'];
  serial_number: Scalars['String'];
};

/** aggregated selection of "ref_lifecycle_question" */
export type Ref_Lifecycle_Question_Aggregate = {
  __typename?: 'ref_lifecycle_question_aggregate';
  aggregate?: Maybe<Ref_Lifecycle_Question_Aggregate_Fields>;
  nodes: Array<Ref_Lifecycle_Question>;
};

/** aggregate fields of "ref_lifecycle_question" */
export type Ref_Lifecycle_Question_Aggregate_Fields = {
  __typename?: 'ref_lifecycle_question_aggregate_fields';
  avg?: Maybe<Ref_Lifecycle_Question_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ref_Lifecycle_Question_Max_Fields>;
  min?: Maybe<Ref_Lifecycle_Question_Min_Fields>;
  stddev?: Maybe<Ref_Lifecycle_Question_Stddev_Fields>;
  stddev_pop?: Maybe<Ref_Lifecycle_Question_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ref_Lifecycle_Question_Stddev_Samp_Fields>;
  sum?: Maybe<Ref_Lifecycle_Question_Sum_Fields>;
  var_pop?: Maybe<Ref_Lifecycle_Question_Var_Pop_Fields>;
  var_samp?: Maybe<Ref_Lifecycle_Question_Var_Samp_Fields>;
  variance?: Maybe<Ref_Lifecycle_Question_Variance_Fields>;
};


/** aggregate fields of "ref_lifecycle_question" */
export type Ref_Lifecycle_Question_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Lifecycle_Question_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ref_Lifecycle_Question_Avg_Fields = {
  __typename?: 'ref_lifecycle_question_avg_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ref_lifecycle_question". All fields are combined with a logical 'AND'. */
export type Ref_Lifecycle_Question_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Lifecycle_Question_Bool_Exp>>;
  _not?: InputMaybe<Ref_Lifecycle_Question_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Lifecycle_Question_Bool_Exp>>;
  category?: InputMaybe<Ref_Lifecycle_Question_Category_Enum_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  sequence?: InputMaybe<Int_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "ref_lifecycle_question_category" */
export type Ref_Lifecycle_Question_Category = {
  __typename?: 'ref_lifecycle_question_category';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_lifecycle_question_category" */
export type Ref_Lifecycle_Question_Category_Aggregate = {
  __typename?: 'ref_lifecycle_question_category_aggregate';
  aggregate?: Maybe<Ref_Lifecycle_Question_Category_Aggregate_Fields>;
  nodes: Array<Ref_Lifecycle_Question_Category>;
};

/** aggregate fields of "ref_lifecycle_question_category" */
export type Ref_Lifecycle_Question_Category_Aggregate_Fields = {
  __typename?: 'ref_lifecycle_question_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Lifecycle_Question_Category_Max_Fields>;
  min?: Maybe<Ref_Lifecycle_Question_Category_Min_Fields>;
};


/** aggregate fields of "ref_lifecycle_question_category" */
export type Ref_Lifecycle_Question_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Lifecycle_Question_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_lifecycle_question_category". All fields are combined with a logical 'AND'. */
export type Ref_Lifecycle_Question_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Lifecycle_Question_Category_Bool_Exp>>;
  _not?: InputMaybe<Ref_Lifecycle_Question_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Lifecycle_Question_Category_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_lifecycle_question_category" */
export enum Ref_Lifecycle_Question_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefLifecycleQuestionCategoryPkey = 'ref_lifecycle_question_category_pkey'
}

export enum Ref_Lifecycle_Question_Category_Enum {
  /** Sales Qualified  */
  Ops = 'OPS',
  /** Sales Qualified  */
  Sales = 'SALES',
  /** Pre Qualification */
  User = 'USER'
}

/** Boolean expression to compare columns of type "ref_lifecycle_question_category_enum". All fields are combined with logical 'AND'. */
export type Ref_Lifecycle_Question_Category_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Lifecycle_Question_Category_Enum>;
  _in?: InputMaybe<Array<Ref_Lifecycle_Question_Category_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Lifecycle_Question_Category_Enum>;
  _nin?: InputMaybe<Array<Ref_Lifecycle_Question_Category_Enum>>;
};

/** input type for inserting data into table "ref_lifecycle_question_category" */
export type Ref_Lifecycle_Question_Category_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Lifecycle_Question_Category_Max_Fields = {
  __typename?: 'ref_lifecycle_question_category_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Lifecycle_Question_Category_Min_Fields = {
  __typename?: 'ref_lifecycle_question_category_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_lifecycle_question_category" */
export type Ref_Lifecycle_Question_Category_Mutation_Response = {
  __typename?: 'ref_lifecycle_question_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Lifecycle_Question_Category>;
};

/** on_conflict condition type for table "ref_lifecycle_question_category" */
export type Ref_Lifecycle_Question_Category_On_Conflict = {
  constraint: Ref_Lifecycle_Question_Category_Constraint;
  update_columns?: Array<Ref_Lifecycle_Question_Category_Update_Column>;
  where?: InputMaybe<Ref_Lifecycle_Question_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_lifecycle_question_category". */
export type Ref_Lifecycle_Question_Category_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_lifecycle_question_category */
export type Ref_Lifecycle_Question_Category_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_lifecycle_question_category" */
export enum Ref_Lifecycle_Question_Category_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_lifecycle_question_category" */
export type Ref_Lifecycle_Question_Category_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_lifecycle_question_category" */
export type Ref_Lifecycle_Question_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Lifecycle_Question_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Lifecycle_Question_Category_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_lifecycle_question_category" */
export enum Ref_Lifecycle_Question_Category_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Lifecycle_Question_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Lifecycle_Question_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Lifecycle_Question_Category_Bool_Exp;
};

/** unique or primary key constraints on table "ref_lifecycle_question" */
export enum Ref_Lifecycle_Question_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefLifecycleQuestionPkey = 'ref_lifecycle_question_pkey',
  /** unique or primary key constraint on columns "serial_number" */
  RefLifecycleQuestionSerialNumberKey = 'ref_lifecycle_question_serial_number_key'
}

/** input type for incrementing numeric columns in table "ref_lifecycle_question" */
export type Ref_Lifecycle_Question_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ref_lifecycle_question" */
export type Ref_Lifecycle_Question_Insert_Input = {
  category?: InputMaybe<Ref_Lifecycle_Question_Category_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Lifecycle_Question_Max_Fields = {
  __typename?: 'ref_lifecycle_question_max_fields';
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  serial_number?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Lifecycle_Question_Min_Fields = {
  __typename?: 'ref_lifecycle_question_min_fields';
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  serial_number?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_lifecycle_question" */
export type Ref_Lifecycle_Question_Mutation_Response = {
  __typename?: 'ref_lifecycle_question_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Lifecycle_Question>;
};

/** on_conflict condition type for table "ref_lifecycle_question" */
export type Ref_Lifecycle_Question_On_Conflict = {
  constraint: Ref_Lifecycle_Question_Constraint;
  update_columns?: Array<Ref_Lifecycle_Question_Update_Column>;
  where?: InputMaybe<Ref_Lifecycle_Question_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_lifecycle_question". */
export type Ref_Lifecycle_Question_Order_By = {
  category?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_lifecycle_question */
export type Ref_Lifecycle_Question_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ref_lifecycle_question" */
export enum Ref_Lifecycle_Question_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  SerialNumber = 'serial_number'
}

/** input type for updating data in table "ref_lifecycle_question" */
export type Ref_Lifecycle_Question_Set_Input = {
  category?: InputMaybe<Ref_Lifecycle_Question_Category_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Ref_Lifecycle_Question_Stddev_Fields = {
  __typename?: 'ref_lifecycle_question_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ref_Lifecycle_Question_Stddev_Pop_Fields = {
  __typename?: 'ref_lifecycle_question_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ref_Lifecycle_Question_Stddev_Samp_Fields = {
  __typename?: 'ref_lifecycle_question_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ref_lifecycle_question" */
export type Ref_Lifecycle_Question_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Lifecycle_Question_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Lifecycle_Question_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Ref_Lifecycle_Question_Category_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Ref_Lifecycle_Question_Sum_Fields = {
  __typename?: 'ref_lifecycle_question_sum_fields';
  id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** update columns of table "ref_lifecycle_question" */
export enum Ref_Lifecycle_Question_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  SerialNumber = 'serial_number'
}

export type Ref_Lifecycle_Question_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ref_Lifecycle_Question_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Lifecycle_Question_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Lifecycle_Question_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ref_Lifecycle_Question_Var_Pop_Fields = {
  __typename?: 'ref_lifecycle_question_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ref_Lifecycle_Question_Var_Samp_Fields = {
  __typename?: 'ref_lifecycle_question_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ref_Lifecycle_Question_Variance_Fields = {
  __typename?: 'ref_lifecycle_question_variance_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ref_mandate_frequency" */
export type Ref_Mandate_Frequency = {
  __typename?: 'ref_mandate_frequency';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_mandate_frequency" */
export type Ref_Mandate_Frequency_Aggregate = {
  __typename?: 'ref_mandate_frequency_aggregate';
  aggregate?: Maybe<Ref_Mandate_Frequency_Aggregate_Fields>;
  nodes: Array<Ref_Mandate_Frequency>;
};

/** aggregate fields of "ref_mandate_frequency" */
export type Ref_Mandate_Frequency_Aggregate_Fields = {
  __typename?: 'ref_mandate_frequency_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Mandate_Frequency_Max_Fields>;
  min?: Maybe<Ref_Mandate_Frequency_Min_Fields>;
};


/** aggregate fields of "ref_mandate_frequency" */
export type Ref_Mandate_Frequency_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Mandate_Frequency_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_mandate_frequency". All fields are combined with a logical 'AND'. */
export type Ref_Mandate_Frequency_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Mandate_Frequency_Bool_Exp>>;
  _not?: InputMaybe<Ref_Mandate_Frequency_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Mandate_Frequency_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_mandate_frequency" */
export enum Ref_Mandate_Frequency_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefMandateFrequencyPkey = 'ref_mandate_frequency_pkey'
}

export enum Ref_Mandate_Frequency_Enum {
  /** Adhoc */
  Adhoc = 'ADHOC',
  /** BiMonthly */
  Bimonthly = 'BIMONTHLY',
  /** Daily */
  Daily = 'DAILY',
  /** IntraDay */
  IntraDay = 'INTRA_DAY',
  /** Monthly */
  Monthly = 'MONTHLY',
  /** Quarterly */
  Quarterly = 'QUARTERLY',
  /** Semiannually */
  Semiannually = 'SEMIANNUALLY',
  /** Weekly */
  Weekly = 'WEEKLY',
  /** Yearly */
  Yearly = 'YEARLY'
}

/** Boolean expression to compare columns of type "ref_mandate_frequency_enum". All fields are combined with logical 'AND'. */
export type Ref_Mandate_Frequency_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Mandate_Frequency_Enum>;
  _in?: InputMaybe<Array<Ref_Mandate_Frequency_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Mandate_Frequency_Enum>;
  _nin?: InputMaybe<Array<Ref_Mandate_Frequency_Enum>>;
};

/** input type for inserting data into table "ref_mandate_frequency" */
export type Ref_Mandate_Frequency_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Mandate_Frequency_Max_Fields = {
  __typename?: 'ref_mandate_frequency_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Mandate_Frequency_Min_Fields = {
  __typename?: 'ref_mandate_frequency_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_mandate_frequency" */
export type Ref_Mandate_Frequency_Mutation_Response = {
  __typename?: 'ref_mandate_frequency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Mandate_Frequency>;
};

/** input type for inserting object relation for remote table "ref_mandate_frequency" */
export type Ref_Mandate_Frequency_Obj_Rel_Insert_Input = {
  data: Ref_Mandate_Frequency_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Mandate_Frequency_On_Conflict>;
};

/** on_conflict condition type for table "ref_mandate_frequency" */
export type Ref_Mandate_Frequency_On_Conflict = {
  constraint: Ref_Mandate_Frequency_Constraint;
  update_columns?: Array<Ref_Mandate_Frequency_Update_Column>;
  where?: InputMaybe<Ref_Mandate_Frequency_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_mandate_frequency". */
export type Ref_Mandate_Frequency_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_mandate_frequency */
export type Ref_Mandate_Frequency_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_mandate_frequency" */
export enum Ref_Mandate_Frequency_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_mandate_frequency" */
export type Ref_Mandate_Frequency_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_mandate_frequency" */
export type Ref_Mandate_Frequency_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Mandate_Frequency_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Mandate_Frequency_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_mandate_frequency" */
export enum Ref_Mandate_Frequency_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Ref_Mandate_Frequency_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Mandate_Frequency_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Mandate_Frequency_Bool_Exp;
};

/** columns and relationships of "ref_mandate_status" */
export type Ref_Mandate_Status = {
  __typename?: 'ref_mandate_status';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_mandate_status" */
export type Ref_Mandate_Status_Aggregate = {
  __typename?: 'ref_mandate_status_aggregate';
  aggregate?: Maybe<Ref_Mandate_Status_Aggregate_Fields>;
  nodes: Array<Ref_Mandate_Status>;
};

/** aggregate fields of "ref_mandate_status" */
export type Ref_Mandate_Status_Aggregate_Fields = {
  __typename?: 'ref_mandate_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Mandate_Status_Max_Fields>;
  min?: Maybe<Ref_Mandate_Status_Min_Fields>;
};


/** aggregate fields of "ref_mandate_status" */
export type Ref_Mandate_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Mandate_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_mandate_status". All fields are combined with a logical 'AND'. */
export type Ref_Mandate_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Mandate_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_Mandate_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Mandate_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_mandate_status" */
export enum Ref_Mandate_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefMandateStatusPkey = 'ref_mandate_status_pkey'
}

export enum Ref_Mandate_Status_Enum {
  /** active */
  Active = 'ACTIVE',
  /** CANCELLED */
  Cancelled = 'CANCELLED',
  /** draft */
  Draft = 'DRAFT',
  /** failed */
  Failed = 'FAILED'
}

/** Boolean expression to compare columns of type "ref_mandate_status_enum". All fields are combined with logical 'AND'. */
export type Ref_Mandate_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Mandate_Status_Enum>;
  _in?: InputMaybe<Array<Ref_Mandate_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Mandate_Status_Enum>;
  _nin?: InputMaybe<Array<Ref_Mandate_Status_Enum>>;
};

/** input type for inserting data into table "ref_mandate_status" */
export type Ref_Mandate_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Mandate_Status_Max_Fields = {
  __typename?: 'ref_mandate_status_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Mandate_Status_Min_Fields = {
  __typename?: 'ref_mandate_status_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_mandate_status" */
export type Ref_Mandate_Status_Mutation_Response = {
  __typename?: 'ref_mandate_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Mandate_Status>;
};

/** on_conflict condition type for table "ref_mandate_status" */
export type Ref_Mandate_Status_On_Conflict = {
  constraint: Ref_Mandate_Status_Constraint;
  update_columns?: Array<Ref_Mandate_Status_Update_Column>;
  where?: InputMaybe<Ref_Mandate_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_mandate_status". */
export type Ref_Mandate_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_mandate_status */
export type Ref_Mandate_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_mandate_status" */
export enum Ref_Mandate_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_mandate_status" */
export type Ref_Mandate_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_mandate_status" */
export type Ref_Mandate_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Mandate_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Mandate_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_mandate_status" */
export enum Ref_Mandate_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Ref_Mandate_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Mandate_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Mandate_Status_Bool_Exp;
};

/** columns and relationships of "ref_mandate_type" */
export type Ref_Mandate_Type = {
  __typename?: 'ref_mandate_type';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_mandate_type" */
export type Ref_Mandate_Type_Aggregate = {
  __typename?: 'ref_mandate_type_aggregate';
  aggregate?: Maybe<Ref_Mandate_Type_Aggregate_Fields>;
  nodes: Array<Ref_Mandate_Type>;
};

/** aggregate fields of "ref_mandate_type" */
export type Ref_Mandate_Type_Aggregate_Fields = {
  __typename?: 'ref_mandate_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Mandate_Type_Max_Fields>;
  min?: Maybe<Ref_Mandate_Type_Min_Fields>;
};


/** aggregate fields of "ref_mandate_type" */
export type Ref_Mandate_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Mandate_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_mandate_type". All fields are combined with a logical 'AND'. */
export type Ref_Mandate_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Mandate_Type_Bool_Exp>>;
  _not?: InputMaybe<Ref_Mandate_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Mandate_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_mandate_type" */
export enum Ref_Mandate_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefMandateTypePkey = 'ref_mandate_type_pkey'
}

export enum Ref_Mandate_Type_Enum {
  /** e nach */
  Electronic = 'ELECTRONIC',
  /** paper nach */
  Paper = 'PAPER'
}

/** Boolean expression to compare columns of type "ref_mandate_type_enum". All fields are combined with logical 'AND'. */
export type Ref_Mandate_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Mandate_Type_Enum>;
  _in?: InputMaybe<Array<Ref_Mandate_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Mandate_Type_Enum>;
  _nin?: InputMaybe<Array<Ref_Mandate_Type_Enum>>;
};

/** input type for inserting data into table "ref_mandate_type" */
export type Ref_Mandate_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Mandate_Type_Max_Fields = {
  __typename?: 'ref_mandate_type_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Mandate_Type_Min_Fields = {
  __typename?: 'ref_mandate_type_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_mandate_type" */
export type Ref_Mandate_Type_Mutation_Response = {
  __typename?: 'ref_mandate_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Mandate_Type>;
};

/** on_conflict condition type for table "ref_mandate_type" */
export type Ref_Mandate_Type_On_Conflict = {
  constraint: Ref_Mandate_Type_Constraint;
  update_columns?: Array<Ref_Mandate_Type_Update_Column>;
  where?: InputMaybe<Ref_Mandate_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_mandate_type". */
export type Ref_Mandate_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_mandate_type */
export type Ref_Mandate_Type_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_mandate_type" */
export enum Ref_Mandate_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_mandate_type" */
export type Ref_Mandate_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_mandate_type" */
export type Ref_Mandate_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Mandate_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Mandate_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_mandate_type" */
export enum Ref_Mandate_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Ref_Mandate_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Mandate_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Mandate_Type_Bool_Exp;
};

/** columns and relationships of "ref_perfios_bank_list" */
export type Ref_Perfios_Bank_List = {
  __typename?: 'ref_perfios_bank_list';
  bank_id?: Maybe<Scalars['Int']>;
  bank_name: Scalars['String'];
  id: Scalars['Int'];
  netbanking: Scalars['Boolean'];
  perfios_bank_id: Scalars['Int'];
  statement_upload: Scalars['Boolean'];
  type?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};

/** aggregated selection of "ref_perfios_bank_list" */
export type Ref_Perfios_Bank_List_Aggregate = {
  __typename?: 'ref_perfios_bank_list_aggregate';
  aggregate?: Maybe<Ref_Perfios_Bank_List_Aggregate_Fields>;
  nodes: Array<Ref_Perfios_Bank_List>;
};

/** aggregate fields of "ref_perfios_bank_list" */
export type Ref_Perfios_Bank_List_Aggregate_Fields = {
  __typename?: 'ref_perfios_bank_list_aggregate_fields';
  avg?: Maybe<Ref_Perfios_Bank_List_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ref_Perfios_Bank_List_Max_Fields>;
  min?: Maybe<Ref_Perfios_Bank_List_Min_Fields>;
  stddev?: Maybe<Ref_Perfios_Bank_List_Stddev_Fields>;
  stddev_pop?: Maybe<Ref_Perfios_Bank_List_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ref_Perfios_Bank_List_Stddev_Samp_Fields>;
  sum?: Maybe<Ref_Perfios_Bank_List_Sum_Fields>;
  var_pop?: Maybe<Ref_Perfios_Bank_List_Var_Pop_Fields>;
  var_samp?: Maybe<Ref_Perfios_Bank_List_Var_Samp_Fields>;
  variance?: Maybe<Ref_Perfios_Bank_List_Variance_Fields>;
};


/** aggregate fields of "ref_perfios_bank_list" */
export type Ref_Perfios_Bank_List_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Perfios_Bank_List_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ref_Perfios_Bank_List_Avg_Fields = {
  __typename?: 'ref_perfios_bank_list_avg_fields';
  bank_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  perfios_bank_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ref_perfios_bank_list". All fields are combined with a logical 'AND'. */
export type Ref_Perfios_Bank_List_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Perfios_Bank_List_Bool_Exp>>;
  _not?: InputMaybe<Ref_Perfios_Bank_List_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Perfios_Bank_List_Bool_Exp>>;
  bank_id?: InputMaybe<Int_Comparison_Exp>;
  bank_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  netbanking?: InputMaybe<Boolean_Comparison_Exp>;
  perfios_bank_id?: InputMaybe<Int_Comparison_Exp>;
  statement_upload?: InputMaybe<Boolean_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  verified?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_perfios_bank_list" */
export enum Ref_Perfios_Bank_List_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefPerfiosBankListPkey = 'ref_perfios_bank_list_pkey'
}

/** input type for incrementing numeric columns in table "ref_perfios_bank_list" */
export type Ref_Perfios_Bank_List_Inc_Input = {
  bank_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  perfios_bank_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ref_perfios_bank_list" */
export type Ref_Perfios_Bank_List_Insert_Input = {
  bank_id?: InputMaybe<Scalars['Int']>;
  bank_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  netbanking?: InputMaybe<Scalars['Boolean']>;
  perfios_bank_id?: InputMaybe<Scalars['Int']>;
  statement_upload?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Ref_Perfios_Bank_List_Max_Fields = {
  __typename?: 'ref_perfios_bank_list_max_fields';
  bank_id?: Maybe<Scalars['Int']>;
  bank_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  perfios_bank_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Perfios_Bank_List_Min_Fields = {
  __typename?: 'ref_perfios_bank_list_min_fields';
  bank_id?: Maybe<Scalars['Int']>;
  bank_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  perfios_bank_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_perfios_bank_list" */
export type Ref_Perfios_Bank_List_Mutation_Response = {
  __typename?: 'ref_perfios_bank_list_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Perfios_Bank_List>;
};

/** on_conflict condition type for table "ref_perfios_bank_list" */
export type Ref_Perfios_Bank_List_On_Conflict = {
  constraint: Ref_Perfios_Bank_List_Constraint;
  update_columns?: Array<Ref_Perfios_Bank_List_Update_Column>;
  where?: InputMaybe<Ref_Perfios_Bank_List_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_perfios_bank_list". */
export type Ref_Perfios_Bank_List_Order_By = {
  bank_id?: InputMaybe<Order_By>;
  bank_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  netbanking?: InputMaybe<Order_By>;
  perfios_bank_id?: InputMaybe<Order_By>;
  statement_upload?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  verified?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_perfios_bank_list */
export type Ref_Perfios_Bank_List_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ref_perfios_bank_list" */
export enum Ref_Perfios_Bank_List_Select_Column {
  /** column name */
  BankId = 'bank_id',
  /** column name */
  BankName = 'bank_name',
  /** column name */
  Id = 'id',
  /** column name */
  Netbanking = 'netbanking',
  /** column name */
  PerfiosBankId = 'perfios_bank_id',
  /** column name */
  StatementUpload = 'statement_upload',
  /** column name */
  Type = 'type',
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "ref_perfios_bank_list" */
export type Ref_Perfios_Bank_List_Set_Input = {
  bank_id?: InputMaybe<Scalars['Int']>;
  bank_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  netbanking?: InputMaybe<Scalars['Boolean']>;
  perfios_bank_id?: InputMaybe<Scalars['Int']>;
  statement_upload?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Ref_Perfios_Bank_List_Stddev_Fields = {
  __typename?: 'ref_perfios_bank_list_stddev_fields';
  bank_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  perfios_bank_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ref_Perfios_Bank_List_Stddev_Pop_Fields = {
  __typename?: 'ref_perfios_bank_list_stddev_pop_fields';
  bank_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  perfios_bank_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ref_Perfios_Bank_List_Stddev_Samp_Fields = {
  __typename?: 'ref_perfios_bank_list_stddev_samp_fields';
  bank_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  perfios_bank_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ref_perfios_bank_list" */
export type Ref_Perfios_Bank_List_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Perfios_Bank_List_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Perfios_Bank_List_Stream_Cursor_Value_Input = {
  bank_id?: InputMaybe<Scalars['Int']>;
  bank_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  netbanking?: InputMaybe<Scalars['Boolean']>;
  perfios_bank_id?: InputMaybe<Scalars['Int']>;
  statement_upload?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Ref_Perfios_Bank_List_Sum_Fields = {
  __typename?: 'ref_perfios_bank_list_sum_fields';
  bank_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  perfios_bank_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "ref_perfios_bank_list" */
export enum Ref_Perfios_Bank_List_Update_Column {
  /** column name */
  BankId = 'bank_id',
  /** column name */
  BankName = 'bank_name',
  /** column name */
  Id = 'id',
  /** column name */
  Netbanking = 'netbanking',
  /** column name */
  PerfiosBankId = 'perfios_bank_id',
  /** column name */
  StatementUpload = 'statement_upload',
  /** column name */
  Type = 'type',
  /** column name */
  Verified = 'verified'
}

export type Ref_Perfios_Bank_List_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ref_Perfios_Bank_List_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Perfios_Bank_List_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Perfios_Bank_List_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ref_Perfios_Bank_List_Var_Pop_Fields = {
  __typename?: 'ref_perfios_bank_list_var_pop_fields';
  bank_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  perfios_bank_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ref_Perfios_Bank_List_Var_Samp_Fields = {
  __typename?: 'ref_perfios_bank_list_var_samp_fields';
  bank_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  perfios_bank_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ref_Perfios_Bank_List_Variance_Fields = {
  __typename?: 'ref_perfios_bank_list_variance_fields';
  bank_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  perfios_bank_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ref_report_category" */
export type Ref_Report_Category = {
  __typename?: 'ref_report_category';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_report_category" */
export type Ref_Report_Category_Aggregate = {
  __typename?: 'ref_report_category_aggregate';
  aggregate?: Maybe<Ref_Report_Category_Aggregate_Fields>;
  nodes: Array<Ref_Report_Category>;
};

/** aggregate fields of "ref_report_category" */
export type Ref_Report_Category_Aggregate_Fields = {
  __typename?: 'ref_report_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Report_Category_Max_Fields>;
  min?: Maybe<Ref_Report_Category_Min_Fields>;
};


/** aggregate fields of "ref_report_category" */
export type Ref_Report_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Report_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_report_category". All fields are combined with a logical 'AND'. */
export type Ref_Report_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Report_Category_Bool_Exp>>;
  _not?: InputMaybe<Ref_Report_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Report_Category_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_report_category" */
export enum Ref_Report_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefReportCategoryPkey = 'ref_report_category_pkey'
}

export enum Ref_Report_Category_Enum {
  /** Cam Report */
  Cam = 'CAM'
}

/** Boolean expression to compare columns of type "ref_report_category_enum". All fields are combined with logical 'AND'. */
export type Ref_Report_Category_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Report_Category_Enum>;
  _in?: InputMaybe<Array<Ref_Report_Category_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Report_Category_Enum>;
  _nin?: InputMaybe<Array<Ref_Report_Category_Enum>>;
};

/** input type for inserting data into table "ref_report_category" */
export type Ref_Report_Category_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Report_Category_Max_Fields = {
  __typename?: 'ref_report_category_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Report_Category_Min_Fields = {
  __typename?: 'ref_report_category_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_report_category" */
export type Ref_Report_Category_Mutation_Response = {
  __typename?: 'ref_report_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Report_Category>;
};

/** input type for inserting object relation for remote table "ref_report_category" */
export type Ref_Report_Category_Obj_Rel_Insert_Input = {
  data: Ref_Report_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Report_Category_On_Conflict>;
};

/** on_conflict condition type for table "ref_report_category" */
export type Ref_Report_Category_On_Conflict = {
  constraint: Ref_Report_Category_Constraint;
  update_columns?: Array<Ref_Report_Category_Update_Column>;
  where?: InputMaybe<Ref_Report_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_report_category". */
export type Ref_Report_Category_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_report_category */
export type Ref_Report_Category_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_report_category" */
export enum Ref_Report_Category_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_report_category" */
export type Ref_Report_Category_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_report_category" */
export type Ref_Report_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Report_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Report_Category_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_report_category" */
export enum Ref_Report_Category_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Ref_Report_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Report_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Report_Category_Bool_Exp;
};

/** columns and relationships of "ref_role" */
export type Ref_Role = {
  __typename?: 'ref_role';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_role" */
export type Ref_Role_Aggregate = {
  __typename?: 'ref_role_aggregate';
  aggregate?: Maybe<Ref_Role_Aggregate_Fields>;
  nodes: Array<Ref_Role>;
};

/** aggregate fields of "ref_role" */
export type Ref_Role_Aggregate_Fields = {
  __typename?: 'ref_role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Role_Max_Fields>;
  min?: Maybe<Ref_Role_Min_Fields>;
};


/** aggregate fields of "ref_role" */
export type Ref_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_role". All fields are combined with a logical 'AND'. */
export type Ref_Role_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Role_Bool_Exp>>;
  _not?: InputMaybe<Ref_Role_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Role_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_role" */
export enum Ref_Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefRolePkey = 'ref_role_pkey'
}

/** input type for inserting data into table "ref_role" */
export type Ref_Role_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Role_Max_Fields = {
  __typename?: 'ref_role_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Role_Min_Fields = {
  __typename?: 'ref_role_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_role" */
export type Ref_Role_Mutation_Response = {
  __typename?: 'ref_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Role>;
};

/** on_conflict condition type for table "ref_role" */
export type Ref_Role_On_Conflict = {
  constraint: Ref_Role_Constraint;
  update_columns?: Array<Ref_Role_Update_Column>;
  where?: InputMaybe<Ref_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_role". */
export type Ref_Role_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_role */
export type Ref_Role_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_role" */
export enum Ref_Role_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_role" */
export type Ref_Role_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_role" */
export type Ref_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Role_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_role" */
export enum Ref_Role_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Role_Bool_Exp;
};

/** columns and relationships of "ref_schedule_checklist_reminder_status" */
export type Ref_Schedule_Checklist_Reminder_Status = {
  __typename?: 'ref_schedule_checklist_reminder_status';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_schedule_checklist_reminder_status" */
export type Ref_Schedule_Checklist_Reminder_Status_Aggregate = {
  __typename?: 'ref_schedule_checklist_reminder_status_aggregate';
  aggregate?: Maybe<Ref_Schedule_Checklist_Reminder_Status_Aggregate_Fields>;
  nodes: Array<Ref_Schedule_Checklist_Reminder_Status>;
};

/** aggregate fields of "ref_schedule_checklist_reminder_status" */
export type Ref_Schedule_Checklist_Reminder_Status_Aggregate_Fields = {
  __typename?: 'ref_schedule_checklist_reminder_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Schedule_Checklist_Reminder_Status_Max_Fields>;
  min?: Maybe<Ref_Schedule_Checklist_Reminder_Status_Min_Fields>;
};


/** aggregate fields of "ref_schedule_checklist_reminder_status" */
export type Ref_Schedule_Checklist_Reminder_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Schedule_Checklist_Reminder_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_schedule_checklist_reminder_status". All fields are combined with a logical 'AND'. */
export type Ref_Schedule_Checklist_Reminder_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Schedule_Checklist_Reminder_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_Schedule_Checklist_Reminder_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Schedule_Checklist_Reminder_Status_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_schedule_checklist_reminder_status" */
export enum Ref_Schedule_Checklist_Reminder_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefScheduleChecklistReminderStatusPkey = 'ref_schedule_checklist_reminder_status_pkey'
}

/** input type for inserting data into table "ref_schedule_checklist_reminder_status" */
export type Ref_Schedule_Checklist_Reminder_Status_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Schedule_Checklist_Reminder_Status_Max_Fields = {
  __typename?: 'ref_schedule_checklist_reminder_status_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Schedule_Checklist_Reminder_Status_Min_Fields = {
  __typename?: 'ref_schedule_checklist_reminder_status_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_schedule_checklist_reminder_status" */
export type Ref_Schedule_Checklist_Reminder_Status_Mutation_Response = {
  __typename?: 'ref_schedule_checklist_reminder_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Schedule_Checklist_Reminder_Status>;
};

/** on_conflict condition type for table "ref_schedule_checklist_reminder_status" */
export type Ref_Schedule_Checklist_Reminder_Status_On_Conflict = {
  constraint: Ref_Schedule_Checklist_Reminder_Status_Constraint;
  update_columns?: Array<Ref_Schedule_Checklist_Reminder_Status_Update_Column>;
  where?: InputMaybe<Ref_Schedule_Checklist_Reminder_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_schedule_checklist_reminder_status". */
export type Ref_Schedule_Checklist_Reminder_Status_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_schedule_checklist_reminder_status */
export type Ref_Schedule_Checklist_Reminder_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_schedule_checklist_reminder_status" */
export enum Ref_Schedule_Checklist_Reminder_Status_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_schedule_checklist_reminder_status" */
export type Ref_Schedule_Checklist_Reminder_Status_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_schedule_checklist_reminder_status" */
export type Ref_Schedule_Checklist_Reminder_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Schedule_Checklist_Reminder_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Schedule_Checklist_Reminder_Status_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_schedule_checklist_reminder_status" */
export enum Ref_Schedule_Checklist_Reminder_Status_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Schedule_Checklist_Reminder_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Schedule_Checklist_Reminder_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Schedule_Checklist_Reminder_Status_Bool_Exp;
};

/** columns and relationships of "ref_score_factor" */
export type Ref_Score_Factor = {
  __typename?: 'ref_score_factor';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_score_factor" */
export type Ref_Score_Factor_Aggregate = {
  __typename?: 'ref_score_factor_aggregate';
  aggregate?: Maybe<Ref_Score_Factor_Aggregate_Fields>;
  nodes: Array<Ref_Score_Factor>;
};

/** aggregate fields of "ref_score_factor" */
export type Ref_Score_Factor_Aggregate_Fields = {
  __typename?: 'ref_score_factor_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Score_Factor_Max_Fields>;
  min?: Maybe<Ref_Score_Factor_Min_Fields>;
};


/** aggregate fields of "ref_score_factor" */
export type Ref_Score_Factor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Score_Factor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_score_factor". All fields are combined with a logical 'AND'. */
export type Ref_Score_Factor_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Score_Factor_Bool_Exp>>;
  _not?: InputMaybe<Ref_Score_Factor_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Score_Factor_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "ref_score_factor_category" */
export type Ref_Score_Factor_Category = {
  __typename?: 'ref_score_factor_category';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_score_factor_category" */
export type Ref_Score_Factor_Category_Aggregate = {
  __typename?: 'ref_score_factor_category_aggregate';
  aggregate?: Maybe<Ref_Score_Factor_Category_Aggregate_Fields>;
  nodes: Array<Ref_Score_Factor_Category>;
};

/** aggregate fields of "ref_score_factor_category" */
export type Ref_Score_Factor_Category_Aggregate_Fields = {
  __typename?: 'ref_score_factor_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Score_Factor_Category_Max_Fields>;
  min?: Maybe<Ref_Score_Factor_Category_Min_Fields>;
};


/** aggregate fields of "ref_score_factor_category" */
export type Ref_Score_Factor_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Score_Factor_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_score_factor_category". All fields are combined with a logical 'AND'. */
export type Ref_Score_Factor_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Score_Factor_Category_Bool_Exp>>;
  _not?: InputMaybe<Ref_Score_Factor_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Score_Factor_Category_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_score_factor_category" */
export enum Ref_Score_Factor_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefScoreFactorCategoryPkey = 'ref_score_factor_category_pkey'
}

export enum Ref_Score_Factor_Category_Enum {
  /** Financial Metrics */
  Financial = 'FINANCIAL',
  /** Recurring Revenue Metrics */
  RecurringRevenue = 'RECURRING_REVENUE'
}

/** Boolean expression to compare columns of type "ref_score_factor_category_enum". All fields are combined with logical 'AND'. */
export type Ref_Score_Factor_Category_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Score_Factor_Category_Enum>;
  _in?: InputMaybe<Array<Ref_Score_Factor_Category_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Score_Factor_Category_Enum>;
  _nin?: InputMaybe<Array<Ref_Score_Factor_Category_Enum>>;
};

/** input type for inserting data into table "ref_score_factor_category" */
export type Ref_Score_Factor_Category_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Score_Factor_Category_Max_Fields = {
  __typename?: 'ref_score_factor_category_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Score_Factor_Category_Min_Fields = {
  __typename?: 'ref_score_factor_category_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_score_factor_category" */
export type Ref_Score_Factor_Category_Mutation_Response = {
  __typename?: 'ref_score_factor_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Score_Factor_Category>;
};

/** on_conflict condition type for table "ref_score_factor_category" */
export type Ref_Score_Factor_Category_On_Conflict = {
  constraint: Ref_Score_Factor_Category_Constraint;
  update_columns?: Array<Ref_Score_Factor_Category_Update_Column>;
  where?: InputMaybe<Ref_Score_Factor_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_score_factor_category". */
export type Ref_Score_Factor_Category_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_score_factor_category */
export type Ref_Score_Factor_Category_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_score_factor_category" */
export enum Ref_Score_Factor_Category_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_score_factor_category" */
export type Ref_Score_Factor_Category_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_score_factor_category" */
export type Ref_Score_Factor_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Score_Factor_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Score_Factor_Category_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_score_factor_category" */
export enum Ref_Score_Factor_Category_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Score_Factor_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Score_Factor_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Score_Factor_Category_Bool_Exp;
};

/** unique or primary key constraints on table "ref_score_factor" */
export enum Ref_Score_Factor_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefScoreFactorPkey = 'ref_score_factor_pkey'
}

export enum Ref_Score_Factor_Enum {
  /** ARPA */
  Arpa = 'ARPA',
  /** Capital Efficiency */
  CapitalEfficiency = 'CAPITAL_EFFICIENCY',
  /** Churn */
  Churn = 'CHURN',
  /** Collection Efficiency */
  CollectionEfficiency = 'COLLECTION_EFFICIENCY',
  /** Debt to Equity Ratio */
  DebtToEquityRatio = 'DEBT_TO_EQUITY_RATIO',
  /** Gross Margin */
  GrossMargin = 'GROSS_MARGIN',
  /** LTV:CAC */
  LtvToCac = 'LTV_TO_CAC',
  /** MRR Growth */
  MrrGrowth = 'MRR_GROWTH',
  /** Net Cashflow */
  NetCashflow = 'NET_CASHFLOW',
  /** Net Profit */
  NetProfit = 'NET_PROFIT',
  /** Revenue Retention */
  RevenueRetention = 'REVENUE_RETENTION',
  /** Runway */
  Runway = 'RUNWAY'
}

/** Boolean expression to compare columns of type "ref_score_factor_enum". All fields are combined with logical 'AND'. */
export type Ref_Score_Factor_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Score_Factor_Enum>;
  _in?: InputMaybe<Array<Ref_Score_Factor_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Score_Factor_Enum>;
  _nin?: InputMaybe<Array<Ref_Score_Factor_Enum>>;
};

/** input type for inserting data into table "ref_score_factor" */
export type Ref_Score_Factor_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Score_Factor_Max_Fields = {
  __typename?: 'ref_score_factor_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Score_Factor_Min_Fields = {
  __typename?: 'ref_score_factor_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_score_factor" */
export type Ref_Score_Factor_Mutation_Response = {
  __typename?: 'ref_score_factor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Score_Factor>;
};

/** input type for inserting object relation for remote table "ref_score_factor" */
export type Ref_Score_Factor_Obj_Rel_Insert_Input = {
  data: Ref_Score_Factor_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Score_Factor_On_Conflict>;
};

/** on_conflict condition type for table "ref_score_factor" */
export type Ref_Score_Factor_On_Conflict = {
  constraint: Ref_Score_Factor_Constraint;
  update_columns?: Array<Ref_Score_Factor_Update_Column>;
  where?: InputMaybe<Ref_Score_Factor_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_score_factor". */
export type Ref_Score_Factor_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_score_factor */
export type Ref_Score_Factor_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_score_factor" */
export enum Ref_Score_Factor_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_score_factor" */
export type Ref_Score_Factor_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_score_factor" */
export type Ref_Score_Factor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Score_Factor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Score_Factor_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "ref_score_factor_unit" */
export type Ref_Score_Factor_Unit = {
  __typename?: 'ref_score_factor_unit';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_score_factor_unit" */
export type Ref_Score_Factor_Unit_Aggregate = {
  __typename?: 'ref_score_factor_unit_aggregate';
  aggregate?: Maybe<Ref_Score_Factor_Unit_Aggregate_Fields>;
  nodes: Array<Ref_Score_Factor_Unit>;
};

/** aggregate fields of "ref_score_factor_unit" */
export type Ref_Score_Factor_Unit_Aggregate_Fields = {
  __typename?: 'ref_score_factor_unit_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Score_Factor_Unit_Max_Fields>;
  min?: Maybe<Ref_Score_Factor_Unit_Min_Fields>;
};


/** aggregate fields of "ref_score_factor_unit" */
export type Ref_Score_Factor_Unit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Score_Factor_Unit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_score_factor_unit". All fields are combined with a logical 'AND'. */
export type Ref_Score_Factor_Unit_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Score_Factor_Unit_Bool_Exp>>;
  _not?: InputMaybe<Ref_Score_Factor_Unit_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Score_Factor_Unit_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_score_factor_unit" */
export enum Ref_Score_Factor_Unit_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefScoreFactorUnitPkey = 'ref_score_factor_unit_pkey'
}

export enum Ref_Score_Factor_Unit_Enum {
  /** amount */
  Amount = 'AMOUNT',
  /** datetime */
  Datetime = 'DATETIME',
  /** percentage */
  Percentage = 'PERCENTAGE',
  /** ratio */
  Ratio = 'RATIO',
  /** times */
  Times = 'TIMES'
}

/** Boolean expression to compare columns of type "ref_score_factor_unit_enum". All fields are combined with logical 'AND'. */
export type Ref_Score_Factor_Unit_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Score_Factor_Unit_Enum>;
  _in?: InputMaybe<Array<Ref_Score_Factor_Unit_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Score_Factor_Unit_Enum>;
  _nin?: InputMaybe<Array<Ref_Score_Factor_Unit_Enum>>;
};

/** input type for inserting data into table "ref_score_factor_unit" */
export type Ref_Score_Factor_Unit_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Score_Factor_Unit_Max_Fields = {
  __typename?: 'ref_score_factor_unit_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Score_Factor_Unit_Min_Fields = {
  __typename?: 'ref_score_factor_unit_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_score_factor_unit" */
export type Ref_Score_Factor_Unit_Mutation_Response = {
  __typename?: 'ref_score_factor_unit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Score_Factor_Unit>;
};

/** on_conflict condition type for table "ref_score_factor_unit" */
export type Ref_Score_Factor_Unit_On_Conflict = {
  constraint: Ref_Score_Factor_Unit_Constraint;
  update_columns?: Array<Ref_Score_Factor_Unit_Update_Column>;
  where?: InputMaybe<Ref_Score_Factor_Unit_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_score_factor_unit". */
export type Ref_Score_Factor_Unit_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_score_factor_unit */
export type Ref_Score_Factor_Unit_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_score_factor_unit" */
export enum Ref_Score_Factor_Unit_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_score_factor_unit" */
export type Ref_Score_Factor_Unit_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_score_factor_unit" */
export type Ref_Score_Factor_Unit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Score_Factor_Unit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Score_Factor_Unit_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_score_factor_unit" */
export enum Ref_Score_Factor_Unit_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Score_Factor_Unit_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Score_Factor_Unit_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Score_Factor_Unit_Bool_Exp;
};

/** update columns of table "ref_score_factor" */
export enum Ref_Score_Factor_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Score_Factor_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Score_Factor_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Score_Factor_Bool_Exp;
};

/** columns and relationships of "ref_source" */
export type Ref_Source = {
  __typename?: 'ref_source';
  category_id: Scalars['String'];
  id: Scalars['String'];
  /** An array relationship */
  integrations: Array<Integration_Auth>;
  /** An aggregate relationship */
  integrations_aggregate: Integration_Auth_Aggregate;
  name: Scalars['String'];
};


/** columns and relationships of "ref_source" */
export type Ref_SourceIntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Integration_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Auth_Order_By>>;
  where?: InputMaybe<Integration_Auth_Bool_Exp>;
};


/** columns and relationships of "ref_source" */
export type Ref_SourceIntegrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Auth_Order_By>>;
  where?: InputMaybe<Integration_Auth_Bool_Exp>;
};

/** aggregated selection of "ref_source" */
export type Ref_Source_Aggregate = {
  __typename?: 'ref_source_aggregate';
  aggregate?: Maybe<Ref_Source_Aggregate_Fields>;
  nodes: Array<Ref_Source>;
};

export type Ref_Source_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ref_Source_Aggregate_Bool_Exp_Count>;
};

export type Ref_Source_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ref_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ref_Source_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ref_source" */
export type Ref_Source_Aggregate_Fields = {
  __typename?: 'ref_source_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Source_Max_Fields>;
  min?: Maybe<Ref_Source_Min_Fields>;
};


/** aggregate fields of "ref_source" */
export type Ref_Source_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ref_source" */
export type Ref_Source_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ref_Source_Max_Order_By>;
  min?: InputMaybe<Ref_Source_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ref_source" */
export type Ref_Source_Arr_Rel_Insert_Input = {
  data: Array<Ref_Source_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Source_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ref_source". All fields are combined with a logical 'AND'. */
export type Ref_Source_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Source_Bool_Exp>>;
  _not?: InputMaybe<Ref_Source_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Source_Bool_Exp>>;
  category_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  integrations?: InputMaybe<Integration_Auth_Bool_Exp>;
  integrations_aggregate?: InputMaybe<Integration_Auth_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_source" */
export enum Ref_Source_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefSourcePkey = 'ref_source_pkey'
}

/** input type for inserting data into table "ref_source" */
export type Ref_Source_Insert_Input = {
  category_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  integrations?: InputMaybe<Integration_Auth_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Source_Max_Fields = {
  __typename?: 'ref_source_max_fields';
  category_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ref_source" */
export type Ref_Source_Max_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ref_Source_Min_Fields = {
  __typename?: 'ref_source_min_fields';
  category_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ref_source" */
export type Ref_Source_Min_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ref_source" */
export type Ref_Source_Mutation_Response = {
  __typename?: 'ref_source_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Source>;
};

/** on_conflict condition type for table "ref_source" */
export type Ref_Source_On_Conflict = {
  constraint: Ref_Source_Constraint;
  update_columns?: Array<Ref_Source_Update_Column>;
  where?: InputMaybe<Ref_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_source". */
export type Ref_Source_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integrations_aggregate?: InputMaybe<Integration_Auth_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_source */
export type Ref_Source_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_source" */
export enum Ref_Source_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_source" */
export type Ref_Source_Set_Input = {
  category_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_source" */
export type Ref_Source_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Source_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Source_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_source" */
export enum Ref_Source_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Source_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Source_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Source_Bool_Exp;
};

/** columns and relationships of "ref_states" */
export type Ref_States = {
  __typename?: 'ref_states';
  country_id: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  ref_country: Ref_Country;
};

/** aggregated selection of "ref_states" */
export type Ref_States_Aggregate = {
  __typename?: 'ref_states_aggregate';
  aggregate?: Maybe<Ref_States_Aggregate_Fields>;
  nodes: Array<Ref_States>;
};

export type Ref_States_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ref_States_Aggregate_Bool_Exp_Count>;
};

export type Ref_States_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ref_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ref_States_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ref_states" */
export type Ref_States_Aggregate_Fields = {
  __typename?: 'ref_states_aggregate_fields';
  avg?: Maybe<Ref_States_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ref_States_Max_Fields>;
  min?: Maybe<Ref_States_Min_Fields>;
  stddev?: Maybe<Ref_States_Stddev_Fields>;
  stddev_pop?: Maybe<Ref_States_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ref_States_Stddev_Samp_Fields>;
  sum?: Maybe<Ref_States_Sum_Fields>;
  var_pop?: Maybe<Ref_States_Var_Pop_Fields>;
  var_samp?: Maybe<Ref_States_Var_Samp_Fields>;
  variance?: Maybe<Ref_States_Variance_Fields>;
};


/** aggregate fields of "ref_states" */
export type Ref_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ref_states" */
export type Ref_States_Aggregate_Order_By = {
  avg?: InputMaybe<Ref_States_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ref_States_Max_Order_By>;
  min?: InputMaybe<Ref_States_Min_Order_By>;
  stddev?: InputMaybe<Ref_States_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ref_States_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ref_States_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ref_States_Sum_Order_By>;
  var_pop?: InputMaybe<Ref_States_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ref_States_Var_Samp_Order_By>;
  variance?: InputMaybe<Ref_States_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ref_states" */
export type Ref_States_Arr_Rel_Insert_Input = {
  data: Array<Ref_States_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_States_On_Conflict>;
};

/** aggregate avg on columns */
export type Ref_States_Avg_Fields = {
  __typename?: 'ref_states_avg_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ref_states" */
export type Ref_States_Avg_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ref_states". All fields are combined with a logical 'AND'. */
export type Ref_States_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_States_Bool_Exp>>;
  _not?: InputMaybe<Ref_States_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_States_Bool_Exp>>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ref_country?: InputMaybe<Ref_Country_Bool_Exp>;
};

/** unique or primary key constraints on table "ref_states" */
export enum Ref_States_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefStatesPkey = 'ref_states_pkey'
}

/** input type for incrementing numeric columns in table "ref_states" */
export type Ref_States_Inc_Input = {
  country_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ref_states" */
export type Ref_States_Insert_Input = {
  country_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  ref_country?: InputMaybe<Ref_Country_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ref_States_Max_Fields = {
  __typename?: 'ref_states_max_fields';
  country_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ref_states" */
export type Ref_States_Max_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ref_States_Min_Fields = {
  __typename?: 'ref_states_min_fields';
  country_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ref_states" */
export type Ref_States_Min_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ref_states" */
export type Ref_States_Mutation_Response = {
  __typename?: 'ref_states_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_States>;
};

/** input type for inserting object relation for remote table "ref_states" */
export type Ref_States_Obj_Rel_Insert_Input = {
  data: Ref_States_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_States_On_Conflict>;
};

/** on_conflict condition type for table "ref_states" */
export type Ref_States_On_Conflict = {
  constraint: Ref_States_Constraint;
  update_columns?: Array<Ref_States_Update_Column>;
  where?: InputMaybe<Ref_States_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_states". */
export type Ref_States_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ref_country?: InputMaybe<Ref_Country_Order_By>;
};

/** primary key columns input for table: ref_states */
export type Ref_States_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ref_states" */
export enum Ref_States_Select_Column {
  /** column name */
  CountryId = 'country_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_states" */
export type Ref_States_Set_Input = {
  country_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Ref_States_Stddev_Fields = {
  __typename?: 'ref_states_stddev_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ref_states" */
export type Ref_States_Stddev_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ref_States_Stddev_Pop_Fields = {
  __typename?: 'ref_states_stddev_pop_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ref_states" */
export type Ref_States_Stddev_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ref_States_Stddev_Samp_Fields = {
  __typename?: 'ref_states_stddev_samp_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ref_states" */
export type Ref_States_Stddev_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ref_states" */
export type Ref_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_States_Stream_Cursor_Value_Input = {
  country_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Ref_States_Sum_Fields = {
  __typename?: 'ref_states_sum_fields';
  country_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ref_states" */
export type Ref_States_Sum_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "ref_states" */
export enum Ref_States_Update_Column {
  /** column name */
  CountryId = 'country_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_States_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ref_States_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_States_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_States_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ref_States_Var_Pop_Fields = {
  __typename?: 'ref_states_var_pop_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ref_states" */
export type Ref_States_Var_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ref_States_Var_Samp_Fields = {
  __typename?: 'ref_states_var_samp_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ref_states" */
export type Ref_States_Var_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ref_States_Variance_Fields = {
  __typename?: 'ref_states_variance_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ref_states" */
export type Ref_States_Variance_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ref_subscription_status" */
export type Ref_Subscription_Status = {
  __typename?: 'ref_subscription_status';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_subscription_status" */
export type Ref_Subscription_Status_Aggregate = {
  __typename?: 'ref_subscription_status_aggregate';
  aggregate?: Maybe<Ref_Subscription_Status_Aggregate_Fields>;
  nodes: Array<Ref_Subscription_Status>;
};

/** aggregate fields of "ref_subscription_status" */
export type Ref_Subscription_Status_Aggregate_Fields = {
  __typename?: 'ref_subscription_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Subscription_Status_Max_Fields>;
  min?: Maybe<Ref_Subscription_Status_Min_Fields>;
};


/** aggregate fields of "ref_subscription_status" */
export type Ref_Subscription_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Subscription_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_subscription_status". All fields are combined with a logical 'AND'. */
export type Ref_Subscription_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Subscription_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_Subscription_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Subscription_Status_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_subscription_status" */
export enum Ref_Subscription_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefSubscriptionStatusPkey = 'ref_subscription_status_pkey'
}

export enum Ref_Subscription_Status_Enum {
  /** Active */
  Active = 'ACTIVE',
  /** Churned */
  Churned = 'CHURNED',
  /** Delinquent */
  Delinquent = 'DELINQUENT',
  /** Ineligible */
  Ineligible = 'INELIGIBLE'
}

/** Boolean expression to compare columns of type "ref_subscription_status_enum". All fields are combined with logical 'AND'. */
export type Ref_Subscription_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Subscription_Status_Enum>;
  _in?: InputMaybe<Array<Ref_Subscription_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Subscription_Status_Enum>;
  _nin?: InputMaybe<Array<Ref_Subscription_Status_Enum>>;
};

/** input type for inserting data into table "ref_subscription_status" */
export type Ref_Subscription_Status_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Subscription_Status_Max_Fields = {
  __typename?: 'ref_subscription_status_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Subscription_Status_Min_Fields = {
  __typename?: 'ref_subscription_status_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_subscription_status" */
export type Ref_Subscription_Status_Mutation_Response = {
  __typename?: 'ref_subscription_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Subscription_Status>;
};

/** on_conflict condition type for table "ref_subscription_status" */
export type Ref_Subscription_Status_On_Conflict = {
  constraint: Ref_Subscription_Status_Constraint;
  update_columns?: Array<Ref_Subscription_Status_Update_Column>;
  where?: InputMaybe<Ref_Subscription_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_subscription_status". */
export type Ref_Subscription_Status_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_subscription_status */
export type Ref_Subscription_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_subscription_status" */
export enum Ref_Subscription_Status_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_subscription_status" */
export type Ref_Subscription_Status_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_subscription_status" */
export type Ref_Subscription_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Subscription_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Subscription_Status_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_subscription_status" */
export enum Ref_Subscription_Status_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Subscription_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Subscription_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Subscription_Status_Bool_Exp;
};

/** columns and relationships of "ref_system_account" */
export type Ref_System_Account = {
  __typename?: 'ref_system_account';
  /** An object relationship */
  account: Account;
  account_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  status: Ref_System_Account_Status_Enum;
  type: Ref_System_Account_Type_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "ref_system_account" */
export type Ref_System_Account_Aggregate = {
  __typename?: 'ref_system_account_aggregate';
  aggregate?: Maybe<Ref_System_Account_Aggregate_Fields>;
  nodes: Array<Ref_System_Account>;
};

/** aggregate fields of "ref_system_account" */
export type Ref_System_Account_Aggregate_Fields = {
  __typename?: 'ref_system_account_aggregate_fields';
  avg?: Maybe<Ref_System_Account_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ref_System_Account_Max_Fields>;
  min?: Maybe<Ref_System_Account_Min_Fields>;
  stddev?: Maybe<Ref_System_Account_Stddev_Fields>;
  stddev_pop?: Maybe<Ref_System_Account_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ref_System_Account_Stddev_Samp_Fields>;
  sum?: Maybe<Ref_System_Account_Sum_Fields>;
  var_pop?: Maybe<Ref_System_Account_Var_Pop_Fields>;
  var_samp?: Maybe<Ref_System_Account_Var_Samp_Fields>;
  variance?: Maybe<Ref_System_Account_Variance_Fields>;
};


/** aggregate fields of "ref_system_account" */
export type Ref_System_Account_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_System_Account_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ref_System_Account_Avg_Fields = {
  __typename?: 'ref_system_account_avg_fields';
  account_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ref_system_account". All fields are combined with a logical 'AND'. */
export type Ref_System_Account_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_System_Account_Bool_Exp>>;
  _not?: InputMaybe<Ref_System_Account_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_System_Account_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Ref_System_Account_Status_Enum_Comparison_Exp>;
  type?: InputMaybe<Ref_System_Account_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_system_account" */
export enum Ref_System_Account_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefSystemAccountPkey = 'ref_system_account_pkey'
}

/** input type for incrementing numeric columns in table "ref_system_account" */
export type Ref_System_Account_Inc_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ref_system_account" */
export type Ref_System_Account_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Ref_System_Account_Status_Enum>;
  type?: InputMaybe<Ref_System_Account_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Ref_System_Account_Max_Fields = {
  __typename?: 'ref_system_account_max_fields';
  account_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Ref_System_Account_Min_Fields = {
  __typename?: 'ref_system_account_min_fields';
  account_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "ref_system_account" */
export type Ref_System_Account_Mutation_Response = {
  __typename?: 'ref_system_account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_System_Account>;
};

/** on_conflict condition type for table "ref_system_account" */
export type Ref_System_Account_On_Conflict = {
  constraint: Ref_System_Account_Constraint;
  update_columns?: Array<Ref_System_Account_Update_Column>;
  where?: InputMaybe<Ref_System_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_system_account". */
export type Ref_System_Account_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_system_account */
export type Ref_System_Account_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ref_system_account" */
export enum Ref_System_Account_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "ref_system_account" */
export type Ref_System_Account_Set_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Ref_System_Account_Status_Enum>;
  type?: InputMaybe<Ref_System_Account_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "ref_system_account_status" */
export type Ref_System_Account_Status = {
  __typename?: 'ref_system_account_status';
  desc: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_system_account_status" */
export type Ref_System_Account_Status_Aggregate = {
  __typename?: 'ref_system_account_status_aggregate';
  aggregate?: Maybe<Ref_System_Account_Status_Aggregate_Fields>;
  nodes: Array<Ref_System_Account_Status>;
};

/** aggregate fields of "ref_system_account_status" */
export type Ref_System_Account_Status_Aggregate_Fields = {
  __typename?: 'ref_system_account_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_System_Account_Status_Max_Fields>;
  min?: Maybe<Ref_System_Account_Status_Min_Fields>;
};


/** aggregate fields of "ref_system_account_status" */
export type Ref_System_Account_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_System_Account_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_system_account_status". All fields are combined with a logical 'AND'. */
export type Ref_System_Account_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_System_Account_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_System_Account_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_System_Account_Status_Bool_Exp>>;
  desc?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_system_account_status" */
export enum Ref_System_Account_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefSystemAccountStatusPkey = 'ref_system_account_status_pkey'
}

export enum Ref_System_Account_Status_Enum {
  /** active account */
  Active = 'ACTIVE',
  /** inactive account */
  InActive = 'IN_ACTIVE'
}

/** Boolean expression to compare columns of type "ref_system_account_status_enum". All fields are combined with logical 'AND'. */
export type Ref_System_Account_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_System_Account_Status_Enum>;
  _in?: InputMaybe<Array<Ref_System_Account_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_System_Account_Status_Enum>;
  _nin?: InputMaybe<Array<Ref_System_Account_Status_Enum>>;
};

/** input type for inserting data into table "ref_system_account_status" */
export type Ref_System_Account_Status_Insert_Input = {
  desc?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_System_Account_Status_Max_Fields = {
  __typename?: 'ref_system_account_status_max_fields';
  desc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_System_Account_Status_Min_Fields = {
  __typename?: 'ref_system_account_status_min_fields';
  desc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_system_account_status" */
export type Ref_System_Account_Status_Mutation_Response = {
  __typename?: 'ref_system_account_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_System_Account_Status>;
};

/** on_conflict condition type for table "ref_system_account_status" */
export type Ref_System_Account_Status_On_Conflict = {
  constraint: Ref_System_Account_Status_Constraint;
  update_columns?: Array<Ref_System_Account_Status_Update_Column>;
  where?: InputMaybe<Ref_System_Account_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_system_account_status". */
export type Ref_System_Account_Status_Order_By = {
  desc?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_system_account_status */
export type Ref_System_Account_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_system_account_status" */
export enum Ref_System_Account_Status_Select_Column {
  /** column name */
  Desc = 'desc',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_system_account_status" */
export type Ref_System_Account_Status_Set_Input = {
  desc?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_system_account_status" */
export type Ref_System_Account_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_System_Account_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_System_Account_Status_Stream_Cursor_Value_Input = {
  desc?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_system_account_status" */
export enum Ref_System_Account_Status_Update_Column {
  /** column name */
  Desc = 'desc',
  /** column name */
  Id = 'id'
}

export type Ref_System_Account_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_System_Account_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_System_Account_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Ref_System_Account_Stddev_Fields = {
  __typename?: 'ref_system_account_stddev_fields';
  account_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ref_System_Account_Stddev_Pop_Fields = {
  __typename?: 'ref_system_account_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ref_System_Account_Stddev_Samp_Fields = {
  __typename?: 'ref_system_account_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ref_system_account" */
export type Ref_System_Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_System_Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_System_Account_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Ref_System_Account_Status_Enum>;
  type?: InputMaybe<Ref_System_Account_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Ref_System_Account_Sum_Fields = {
  __typename?: 'ref_system_account_sum_fields';
  account_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "ref_system_account_type" */
export type Ref_System_Account_Type = {
  __typename?: 'ref_system_account_type';
  desc: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_system_account_type" */
export type Ref_System_Account_Type_Aggregate = {
  __typename?: 'ref_system_account_type_aggregate';
  aggregate?: Maybe<Ref_System_Account_Type_Aggregate_Fields>;
  nodes: Array<Ref_System_Account_Type>;
};

/** aggregate fields of "ref_system_account_type" */
export type Ref_System_Account_Type_Aggregate_Fields = {
  __typename?: 'ref_system_account_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_System_Account_Type_Max_Fields>;
  min?: Maybe<Ref_System_Account_Type_Min_Fields>;
};


/** aggregate fields of "ref_system_account_type" */
export type Ref_System_Account_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_System_Account_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_system_account_type". All fields are combined with a logical 'AND'. */
export type Ref_System_Account_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_System_Account_Type_Bool_Exp>>;
  _not?: InputMaybe<Ref_System_Account_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_System_Account_Type_Bool_Exp>>;
  desc?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_system_account_type" */
export enum Ref_System_Account_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefSystemAccountTypePkey = 'ref_system_account_type_pkey'
}

export enum Ref_System_Account_Type_Enum {
  /** main account */
  Main = 'MAIN',
  /** revenue account */
  Revenue = 'REVENUE'
}

/** Boolean expression to compare columns of type "ref_system_account_type_enum". All fields are combined with logical 'AND'. */
export type Ref_System_Account_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_System_Account_Type_Enum>;
  _in?: InputMaybe<Array<Ref_System_Account_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_System_Account_Type_Enum>;
  _nin?: InputMaybe<Array<Ref_System_Account_Type_Enum>>;
};

/** input type for inserting data into table "ref_system_account_type" */
export type Ref_System_Account_Type_Insert_Input = {
  desc?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_System_Account_Type_Max_Fields = {
  __typename?: 'ref_system_account_type_max_fields';
  desc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_System_Account_Type_Min_Fields = {
  __typename?: 'ref_system_account_type_min_fields';
  desc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_system_account_type" */
export type Ref_System_Account_Type_Mutation_Response = {
  __typename?: 'ref_system_account_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_System_Account_Type>;
};

/** on_conflict condition type for table "ref_system_account_type" */
export type Ref_System_Account_Type_On_Conflict = {
  constraint: Ref_System_Account_Type_Constraint;
  update_columns?: Array<Ref_System_Account_Type_Update_Column>;
  where?: InputMaybe<Ref_System_Account_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_system_account_type". */
export type Ref_System_Account_Type_Order_By = {
  desc?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_system_account_type */
export type Ref_System_Account_Type_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_system_account_type" */
export enum Ref_System_Account_Type_Select_Column {
  /** column name */
  Desc = 'desc',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_system_account_type" */
export type Ref_System_Account_Type_Set_Input = {
  desc?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_system_account_type" */
export type Ref_System_Account_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_System_Account_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_System_Account_Type_Stream_Cursor_Value_Input = {
  desc?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_system_account_type" */
export enum Ref_System_Account_Type_Update_Column {
  /** column name */
  Desc = 'desc',
  /** column name */
  Id = 'id'
}

export type Ref_System_Account_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_System_Account_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_System_Account_Type_Bool_Exp;
};

/** update columns of table "ref_system_account" */
export enum Ref_System_Account_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Ref_System_Account_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ref_System_Account_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_System_Account_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_System_Account_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ref_System_Account_Var_Pop_Fields = {
  __typename?: 'ref_system_account_var_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ref_System_Account_Var_Samp_Fields = {
  __typename?: 'ref_system_account_var_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ref_System_Account_Variance_Fields = {
  __typename?: 'ref_system_account_variance_fields';
  account_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ref_tds_status" */
export type Ref_Tds_Status = {
  __typename?: 'ref_tds_status';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_tds_status" */
export type Ref_Tds_Status_Aggregate = {
  __typename?: 'ref_tds_status_aggregate';
  aggregate?: Maybe<Ref_Tds_Status_Aggregate_Fields>;
  nodes: Array<Ref_Tds_Status>;
};

/** aggregate fields of "ref_tds_status" */
export type Ref_Tds_Status_Aggregate_Fields = {
  __typename?: 'ref_tds_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Tds_Status_Max_Fields>;
  min?: Maybe<Ref_Tds_Status_Min_Fields>;
};


/** aggregate fields of "ref_tds_status" */
export type Ref_Tds_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Tds_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_tds_status". All fields are combined with a logical 'AND'. */
export type Ref_Tds_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Tds_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_Tds_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Tds_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_tds_status" */
export enum Ref_Tds_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefTdsStatusPkey = 'ref_tds_status_pkey'
}

export enum Ref_Tds_Status_Enum {
  /** completed */
  Completed = 'COMPLETED',
  /** failed */
  Failed = 'FAILED',
  /** initiated */
  Initiated = 'INITIATED'
}

/** Boolean expression to compare columns of type "ref_tds_status_enum". All fields are combined with logical 'AND'. */
export type Ref_Tds_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Tds_Status_Enum>;
  _in?: InputMaybe<Array<Ref_Tds_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Tds_Status_Enum>;
  _nin?: InputMaybe<Array<Ref_Tds_Status_Enum>>;
};

/** input type for inserting data into table "ref_tds_status" */
export type Ref_Tds_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Tds_Status_Max_Fields = {
  __typename?: 'ref_tds_status_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Tds_Status_Min_Fields = {
  __typename?: 'ref_tds_status_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_tds_status" */
export type Ref_Tds_Status_Mutation_Response = {
  __typename?: 'ref_tds_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Tds_Status>;
};

/** input type for inserting object relation for remote table "ref_tds_status" */
export type Ref_Tds_Status_Obj_Rel_Insert_Input = {
  data: Ref_Tds_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Tds_Status_On_Conflict>;
};

/** on_conflict condition type for table "ref_tds_status" */
export type Ref_Tds_Status_On_Conflict = {
  constraint: Ref_Tds_Status_Constraint;
  update_columns?: Array<Ref_Tds_Status_Update_Column>;
  where?: InputMaybe<Ref_Tds_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_tds_status". */
export type Ref_Tds_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_tds_status */
export type Ref_Tds_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_tds_status" */
export enum Ref_Tds_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_tds_status" */
export type Ref_Tds_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_tds_status" */
export type Ref_Tds_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Tds_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Tds_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_tds_status" */
export enum Ref_Tds_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Ref_Tds_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Tds_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Tds_Status_Bool_Exp;
};

/** columns and relationships of "ref_tds_value_unit" */
export type Ref_Tds_Value_Unit = {
  __typename?: 'ref_tds_value_unit';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_tds_value_unit" */
export type Ref_Tds_Value_Unit_Aggregate = {
  __typename?: 'ref_tds_value_unit_aggregate';
  aggregate?: Maybe<Ref_Tds_Value_Unit_Aggregate_Fields>;
  nodes: Array<Ref_Tds_Value_Unit>;
};

/** aggregate fields of "ref_tds_value_unit" */
export type Ref_Tds_Value_Unit_Aggregate_Fields = {
  __typename?: 'ref_tds_value_unit_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Tds_Value_Unit_Max_Fields>;
  min?: Maybe<Ref_Tds_Value_Unit_Min_Fields>;
};


/** aggregate fields of "ref_tds_value_unit" */
export type Ref_Tds_Value_Unit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Tds_Value_Unit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_tds_value_unit". All fields are combined with a logical 'AND'. */
export type Ref_Tds_Value_Unit_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Tds_Value_Unit_Bool_Exp>>;
  _not?: InputMaybe<Ref_Tds_Value_Unit_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Tds_Value_Unit_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_tds_value_unit" */
export enum Ref_Tds_Value_Unit_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefTdsValueUnitPkey = 'ref_tds_value_unit_pkey'
}

export enum Ref_Tds_Value_Unit_Enum {
  /** Half yearly */
  HalfYearly = 'HALF_YEARLY',
  /** monthly */
  Monthly = 'MONTHLY',
  /** quarterly */
  Quarterly = 'QUARTERLY',
  /** Yearly */
  Yearly = 'YEARLY'
}

/** Boolean expression to compare columns of type "ref_tds_value_unit_enum". All fields are combined with logical 'AND'. */
export type Ref_Tds_Value_Unit_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Tds_Value_Unit_Enum>;
  _in?: InputMaybe<Array<Ref_Tds_Value_Unit_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Tds_Value_Unit_Enum>;
  _nin?: InputMaybe<Array<Ref_Tds_Value_Unit_Enum>>;
};

/** input type for inserting data into table "ref_tds_value_unit" */
export type Ref_Tds_Value_Unit_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Tds_Value_Unit_Max_Fields = {
  __typename?: 'ref_tds_value_unit_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Tds_Value_Unit_Min_Fields = {
  __typename?: 'ref_tds_value_unit_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_tds_value_unit" */
export type Ref_Tds_Value_Unit_Mutation_Response = {
  __typename?: 'ref_tds_value_unit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Tds_Value_Unit>;
};

/** input type for inserting object relation for remote table "ref_tds_value_unit" */
export type Ref_Tds_Value_Unit_Obj_Rel_Insert_Input = {
  data: Ref_Tds_Value_Unit_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Tds_Value_Unit_On_Conflict>;
};

/** on_conflict condition type for table "ref_tds_value_unit" */
export type Ref_Tds_Value_Unit_On_Conflict = {
  constraint: Ref_Tds_Value_Unit_Constraint;
  update_columns?: Array<Ref_Tds_Value_Unit_Update_Column>;
  where?: InputMaybe<Ref_Tds_Value_Unit_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_tds_value_unit". */
export type Ref_Tds_Value_Unit_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_tds_value_unit */
export type Ref_Tds_Value_Unit_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_tds_value_unit" */
export enum Ref_Tds_Value_Unit_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_tds_value_unit" */
export type Ref_Tds_Value_Unit_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_tds_value_unit" */
export type Ref_Tds_Value_Unit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Tds_Value_Unit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Tds_Value_Unit_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_tds_value_unit" */
export enum Ref_Tds_Value_Unit_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Ref_Tds_Value_Unit_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Tds_Value_Unit_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Tds_Value_Unit_Bool_Exp;
};

/** columns and relationships of "ref_tenant_type" */
export type Ref_Tenant_Type = {
  __typename?: 'ref_tenant_type';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_tenant_type" */
export type Ref_Tenant_Type_Aggregate = {
  __typename?: 'ref_tenant_type_aggregate';
  aggregate?: Maybe<Ref_Tenant_Type_Aggregate_Fields>;
  nodes: Array<Ref_Tenant_Type>;
};

/** aggregate fields of "ref_tenant_type" */
export type Ref_Tenant_Type_Aggregate_Fields = {
  __typename?: 'ref_tenant_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Tenant_Type_Max_Fields>;
  min?: Maybe<Ref_Tenant_Type_Min_Fields>;
};


/** aggregate fields of "ref_tenant_type" */
export type Ref_Tenant_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Tenant_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_tenant_type". All fields are combined with a logical 'AND'. */
export type Ref_Tenant_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Tenant_Type_Bool_Exp>>;
  _not?: InputMaybe<Ref_Tenant_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Tenant_Type_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_tenant_type" */
export enum Ref_Tenant_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefTenantTypePkey = 'ref_tenant_type_pkey'
}

export enum Ref_Tenant_Type_Enum {
  /** Company */
  Company = 'COMPANY',
  /** Investor */
  Investor = 'INVESTOR',
  /** Staff */
  Staff = 'STAFF'
}

/** Boolean expression to compare columns of type "ref_tenant_type_enum". All fields are combined with logical 'AND'. */
export type Ref_Tenant_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Tenant_Type_Enum>;
  _in?: InputMaybe<Array<Ref_Tenant_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Tenant_Type_Enum>;
  _nin?: InputMaybe<Array<Ref_Tenant_Type_Enum>>;
};

/** input type for inserting data into table "ref_tenant_type" */
export type Ref_Tenant_Type_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Tenant_Type_Max_Fields = {
  __typename?: 'ref_tenant_type_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Tenant_Type_Min_Fields = {
  __typename?: 'ref_tenant_type_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_tenant_type" */
export type Ref_Tenant_Type_Mutation_Response = {
  __typename?: 'ref_tenant_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Tenant_Type>;
};

/** on_conflict condition type for table "ref_tenant_type" */
export type Ref_Tenant_Type_On_Conflict = {
  constraint: Ref_Tenant_Type_Constraint;
  update_columns?: Array<Ref_Tenant_Type_Update_Column>;
  where?: InputMaybe<Ref_Tenant_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_tenant_type". */
export type Ref_Tenant_Type_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_tenant_type */
export type Ref_Tenant_Type_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_tenant_type" */
export enum Ref_Tenant_Type_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_tenant_type" */
export type Ref_Tenant_Type_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_tenant_type" */
export type Ref_Tenant_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Tenant_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Tenant_Type_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_tenant_type" */
export enum Ref_Tenant_Type_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Tenant_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Tenant_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Tenant_Type_Bool_Exp;
};

/** columns and relationships of "ref_trade_state" */
export type Ref_Trade_State = {
  __typename?: 'ref_trade_state';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_trade_state" */
export type Ref_Trade_State_Aggregate = {
  __typename?: 'ref_trade_state_aggregate';
  aggregate?: Maybe<Ref_Trade_State_Aggregate_Fields>;
  nodes: Array<Ref_Trade_State>;
};

/** aggregate fields of "ref_trade_state" */
export type Ref_Trade_State_Aggregate_Fields = {
  __typename?: 'ref_trade_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Trade_State_Max_Fields>;
  min?: Maybe<Ref_Trade_State_Min_Fields>;
};


/** aggregate fields of "ref_trade_state" */
export type Ref_Trade_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Trade_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_trade_state". All fields are combined with a logical 'AND'. */
export type Ref_Trade_State_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Trade_State_Bool_Exp>>;
  _not?: InputMaybe<Ref_Trade_State_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Trade_State_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_trade_state" */
export enum Ref_Trade_State_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefTradeStatePkey = 'ref_trade_state_pkey'
}

export enum Ref_Trade_State_Enum {
  /** Active */
  Active = 'ACTIVE',
  /** Completed */
  Completed = 'COMPLETED',
  /** Default */
  Default = 'DEFAULT',
  /** Failed */
  Failed = 'FAILED',
  /** Initiated */
  Initiated = 'INITIATED',
  /** OFFLINE */
  Offline = 'OFFLINE'
}

/** Boolean expression to compare columns of type "ref_trade_state_enum". All fields are combined with logical 'AND'. */
export type Ref_Trade_State_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Trade_State_Enum>;
  _in?: InputMaybe<Array<Ref_Trade_State_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Trade_State_Enum>;
  _nin?: InputMaybe<Array<Ref_Trade_State_Enum>>;
};

/** input type for inserting data into table "ref_trade_state" */
export type Ref_Trade_State_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Trade_State_Max_Fields = {
  __typename?: 'ref_trade_state_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Trade_State_Min_Fields = {
  __typename?: 'ref_trade_state_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_trade_state" */
export type Ref_Trade_State_Mutation_Response = {
  __typename?: 'ref_trade_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Trade_State>;
};

/** on_conflict condition type for table "ref_trade_state" */
export type Ref_Trade_State_On_Conflict = {
  constraint: Ref_Trade_State_Constraint;
  update_columns?: Array<Ref_Trade_State_Update_Column>;
  where?: InputMaybe<Ref_Trade_State_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_trade_state". */
export type Ref_Trade_State_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_trade_state */
export type Ref_Trade_State_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_trade_state" */
export enum Ref_Trade_State_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_trade_state" */
export type Ref_Trade_State_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_trade_state" */
export type Ref_Trade_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Trade_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Trade_State_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_trade_state" */
export enum Ref_Trade_State_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Trade_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Trade_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Trade_State_Bool_Exp;
};

/** columns and relationships of "ref_trade_status" */
export type Ref_Trade_Status = {
  __typename?: 'ref_trade_status';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_trade_status" */
export type Ref_Trade_Status_Aggregate = {
  __typename?: 'ref_trade_status_aggregate';
  aggregate?: Maybe<Ref_Trade_Status_Aggregate_Fields>;
  nodes: Array<Ref_Trade_Status>;
};

/** aggregate fields of "ref_trade_status" */
export type Ref_Trade_Status_Aggregate_Fields = {
  __typename?: 'ref_trade_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Trade_Status_Max_Fields>;
  min?: Maybe<Ref_Trade_Status_Min_Fields>;
};


/** aggregate fields of "ref_trade_status" */
export type Ref_Trade_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Trade_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_trade_status". All fields are combined with a logical 'AND'. */
export type Ref_Trade_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Trade_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_Trade_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Trade_Status_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_trade_status" */
export enum Ref_Trade_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefTradeStatusPkey = 'ref_trade_status_pkey'
}

export enum Ref_Trade_Status_Enum {
  /** Active */
  Active = 'ACTIVE',
  /** APA Company Signed */
  ApaCompanySigned = 'APA_COMPANY_SIGNED',
  /** APA Generated */
  ApaGenerated = 'APA_GENERATED',
  /** APA Investor Signed */
  ApaInvestorSigned = 'APA_INVESTOR_SIGNED',
  /** apa company signed webhook call is pending */
  ApaInReview = 'APA_IN_REVIEW',
  /** Completed */
  Completed = 'COMPLETED',
  /** Contracts Selected */
  ContractSelected = 'CONTRACT_SELECTED',
  /** Default */
  Default = 'DEFAULT',
  /** Failed */
  Failed = 'FAILED',
  /** Initiated */
  Initiated = 'INITIATED',
  /** Mandate Failed */
  MandateFailed = 'MANDATE_FAILED',
  /** mandate signed webhook call is pending */
  MandateInReview = 'MANDATE_IN_REVIEW',
  /** Mandate Registered */
  MandateRegistered = 'MANDATE_REGISTERED',
  /** Mandate Signed */
  MandateSigned = 'MANDATE_SIGNED',
  /** Mandate Success */
  MandateSuccess = 'MANDATE_SUCCESS',
  /** OFFLINE */
  Offline = 'OFFLINE',
  /** payout debited */
  PayoutDebited = 'PAYOUT_DEBITED',
  /** Payout Disbursed */
  PayoutDisbursed = 'PAYOUT_DISBURSED',
  /** payout initiated */
  PayoutInitiated = 'PAYOUT_INITIATED'
}

/** Boolean expression to compare columns of type "ref_trade_status_enum". All fields are combined with logical 'AND'. */
export type Ref_Trade_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Trade_Status_Enum>;
  _in?: InputMaybe<Array<Ref_Trade_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Trade_Status_Enum>;
  _nin?: InputMaybe<Array<Ref_Trade_Status_Enum>>;
};

/** input type for inserting data into table "ref_trade_status" */
export type Ref_Trade_Status_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Trade_Status_Max_Fields = {
  __typename?: 'ref_trade_status_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Trade_Status_Min_Fields = {
  __typename?: 'ref_trade_status_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_trade_status" */
export type Ref_Trade_Status_Mutation_Response = {
  __typename?: 'ref_trade_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Trade_Status>;
};

/** on_conflict condition type for table "ref_trade_status" */
export type Ref_Trade_Status_On_Conflict = {
  constraint: Ref_Trade_Status_Constraint;
  update_columns?: Array<Ref_Trade_Status_Update_Column>;
  where?: InputMaybe<Ref_Trade_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_trade_status". */
export type Ref_Trade_Status_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_trade_status */
export type Ref_Trade_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_trade_status" */
export enum Ref_Trade_Status_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_trade_status" */
export type Ref_Trade_Status_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_trade_status" */
export type Ref_Trade_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Trade_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Trade_Status_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_trade_status" */
export enum Ref_Trade_Status_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Trade_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Trade_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Trade_Status_Bool_Exp;
};

/** columns and relationships of "ref_trade_withdrawal_mode" */
export type Ref_Trade_Withdrawal_Mode = {
  __typename?: 'ref_trade_withdrawal_mode';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_trade_withdrawal_mode" */
export type Ref_Trade_Withdrawal_Mode_Aggregate = {
  __typename?: 'ref_trade_withdrawal_mode_aggregate';
  aggregate?: Maybe<Ref_Trade_Withdrawal_Mode_Aggregate_Fields>;
  nodes: Array<Ref_Trade_Withdrawal_Mode>;
};

/** aggregate fields of "ref_trade_withdrawal_mode" */
export type Ref_Trade_Withdrawal_Mode_Aggregate_Fields = {
  __typename?: 'ref_trade_withdrawal_mode_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Trade_Withdrawal_Mode_Max_Fields>;
  min?: Maybe<Ref_Trade_Withdrawal_Mode_Min_Fields>;
};


/** aggregate fields of "ref_trade_withdrawal_mode" */
export type Ref_Trade_Withdrawal_Mode_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Trade_Withdrawal_Mode_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_trade_withdrawal_mode". All fields are combined with a logical 'AND'. */
export type Ref_Trade_Withdrawal_Mode_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Trade_Withdrawal_Mode_Bool_Exp>>;
  _not?: InputMaybe<Ref_Trade_Withdrawal_Mode_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Trade_Withdrawal_Mode_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_trade_withdrawal_mode" */
export enum Ref_Trade_Withdrawal_Mode_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefTradeWithdrawalModePkey = 'ref_trade_withdrawal_mode_pkey'
}

export enum Ref_Trade_Withdrawal_Mode_Enum {
  /** Auto Withdrawal */
  Auto = 'AUTO',
  /** Manual Withdrawal */
  Manual = 'MANUAL'
}

/** Boolean expression to compare columns of type "ref_trade_withdrawal_mode_enum". All fields are combined with logical 'AND'. */
export type Ref_Trade_Withdrawal_Mode_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Trade_Withdrawal_Mode_Enum>;
  _in?: InputMaybe<Array<Ref_Trade_Withdrawal_Mode_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Trade_Withdrawal_Mode_Enum>;
  _nin?: InputMaybe<Array<Ref_Trade_Withdrawal_Mode_Enum>>;
};

/** input type for inserting data into table "ref_trade_withdrawal_mode" */
export type Ref_Trade_Withdrawal_Mode_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Trade_Withdrawal_Mode_Max_Fields = {
  __typename?: 'ref_trade_withdrawal_mode_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Trade_Withdrawal_Mode_Min_Fields = {
  __typename?: 'ref_trade_withdrawal_mode_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_trade_withdrawal_mode" */
export type Ref_Trade_Withdrawal_Mode_Mutation_Response = {
  __typename?: 'ref_trade_withdrawal_mode_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Trade_Withdrawal_Mode>;
};

/** input type for inserting object relation for remote table "ref_trade_withdrawal_mode" */
export type Ref_Trade_Withdrawal_Mode_Obj_Rel_Insert_Input = {
  data: Ref_Trade_Withdrawal_Mode_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_Trade_Withdrawal_Mode_On_Conflict>;
};

/** on_conflict condition type for table "ref_trade_withdrawal_mode" */
export type Ref_Trade_Withdrawal_Mode_On_Conflict = {
  constraint: Ref_Trade_Withdrawal_Mode_Constraint;
  update_columns?: Array<Ref_Trade_Withdrawal_Mode_Update_Column>;
  where?: InputMaybe<Ref_Trade_Withdrawal_Mode_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_trade_withdrawal_mode". */
export type Ref_Trade_Withdrawal_Mode_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_trade_withdrawal_mode */
export type Ref_Trade_Withdrawal_Mode_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_trade_withdrawal_mode" */
export enum Ref_Trade_Withdrawal_Mode_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_trade_withdrawal_mode" */
export type Ref_Trade_Withdrawal_Mode_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_trade_withdrawal_mode" */
export type Ref_Trade_Withdrawal_Mode_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Trade_Withdrawal_Mode_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Trade_Withdrawal_Mode_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_trade_withdrawal_mode" */
export enum Ref_Trade_Withdrawal_Mode_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Ref_Trade_Withdrawal_Mode_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Trade_Withdrawal_Mode_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Trade_Withdrawal_Mode_Bool_Exp;
};

/** columns and relationships of "ref_transaction_method" */
export type Ref_Transaction_Method = {
  __typename?: 'ref_transaction_method';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_transaction_method" */
export type Ref_Transaction_Method_Aggregate = {
  __typename?: 'ref_transaction_method_aggregate';
  aggregate?: Maybe<Ref_Transaction_Method_Aggregate_Fields>;
  nodes: Array<Ref_Transaction_Method>;
};

/** aggregate fields of "ref_transaction_method" */
export type Ref_Transaction_Method_Aggregate_Fields = {
  __typename?: 'ref_transaction_method_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Transaction_Method_Max_Fields>;
  min?: Maybe<Ref_Transaction_Method_Min_Fields>;
};


/** aggregate fields of "ref_transaction_method" */
export type Ref_Transaction_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Transaction_Method_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_transaction_method". All fields are combined with a logical 'AND'. */
export type Ref_Transaction_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Transaction_Method_Bool_Exp>>;
  _not?: InputMaybe<Ref_Transaction_Method_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Transaction_Method_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_transaction_method" */
export enum Ref_Transaction_Method_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefTransactionMethodPkey = 'ref_transaction_method_pkey'
}

export enum Ref_Transaction_Method_Enum {
  /** CHEQUE */
  Cheque = 'CHEQUE',
  /** ENACH */
  Enach = 'ENACH',
  /** ESCROW */
  Escrow = 'ESCROW',
  /** NEFT */
  Neft = 'NEFT',
  /** RTGS */
  Rtgs = 'RTGS'
}

/** Boolean expression to compare columns of type "ref_transaction_method_enum". All fields are combined with logical 'AND'. */
export type Ref_Transaction_Method_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Transaction_Method_Enum>;
  _in?: InputMaybe<Array<Ref_Transaction_Method_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Transaction_Method_Enum>;
  _nin?: InputMaybe<Array<Ref_Transaction_Method_Enum>>;
};

/** input type for inserting data into table "ref_transaction_method" */
export type Ref_Transaction_Method_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Transaction_Method_Max_Fields = {
  __typename?: 'ref_transaction_method_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Transaction_Method_Min_Fields = {
  __typename?: 'ref_transaction_method_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_transaction_method" */
export type Ref_Transaction_Method_Mutation_Response = {
  __typename?: 'ref_transaction_method_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Transaction_Method>;
};

/** on_conflict condition type for table "ref_transaction_method" */
export type Ref_Transaction_Method_On_Conflict = {
  constraint: Ref_Transaction_Method_Constraint;
  update_columns?: Array<Ref_Transaction_Method_Update_Column>;
  where?: InputMaybe<Ref_Transaction_Method_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_transaction_method". */
export type Ref_Transaction_Method_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_transaction_method */
export type Ref_Transaction_Method_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_transaction_method" */
export enum Ref_Transaction_Method_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_transaction_method" */
export type Ref_Transaction_Method_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_transaction_method" */
export type Ref_Transaction_Method_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Transaction_Method_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Transaction_Method_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_transaction_method" */
export enum Ref_Transaction_Method_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Transaction_Method_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Transaction_Method_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Transaction_Method_Bool_Exp;
};

/** columns and relationships of "ref_transaction_status" */
export type Ref_Transaction_Status = {
  __typename?: 'ref_transaction_status';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_transaction_status" */
export type Ref_Transaction_Status_Aggregate = {
  __typename?: 'ref_transaction_status_aggregate';
  aggregate?: Maybe<Ref_Transaction_Status_Aggregate_Fields>;
  nodes: Array<Ref_Transaction_Status>;
};

/** aggregate fields of "ref_transaction_status" */
export type Ref_Transaction_Status_Aggregate_Fields = {
  __typename?: 'ref_transaction_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Transaction_Status_Max_Fields>;
  min?: Maybe<Ref_Transaction_Status_Min_Fields>;
};


/** aggregate fields of "ref_transaction_status" */
export type Ref_Transaction_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Transaction_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_transaction_status". All fields are combined with a logical 'AND'. */
export type Ref_Transaction_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Transaction_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_Transaction_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Transaction_Status_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_transaction_status" */
export enum Ref_Transaction_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefTransactionStatusPkey = 'ref_transaction_status_pkey'
}

export enum Ref_Transaction_Status_Enum {
  /** CANCELLED */
  Cancelled = 'CANCELLED',
  /** COMPLETED */
  Completed = 'COMPLETED',
  /** CREDIT_INITIATED */
  CreditInitiated = 'CREDIT_INITIATED',
  /** credit success */
  CreditSuccess = 'CREDIT_SUCCESS',
  /** DEBIT_INITIATED */
  DebitInitiated = 'DEBIT_INITIATED',
  /** debit success */
  DebitSuccess = 'DEBIT_SUCCESS',
  /** DRAFT */
  Draft = 'DRAFT',
  /** FAILED_CREDIT */
  FailedCredit = 'FAILED_CREDIT',
  /** FAILED_DEBIT */
  FailedDebit = 'FAILED_DEBIT',
  /** INITIATED */
  Initiated = 'INITIATED',
  /** REATTEMPT */
  Reattempt = 'REATTEMPT',
  /** SCHEDULED */
  Scheduled = 'SCHEDULED'
}

/** Boolean expression to compare columns of type "ref_transaction_status_enum". All fields are combined with logical 'AND'. */
export type Ref_Transaction_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Transaction_Status_Enum>;
  _in?: InputMaybe<Array<Ref_Transaction_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Transaction_Status_Enum>;
  _nin?: InputMaybe<Array<Ref_Transaction_Status_Enum>>;
};

/** input type for inserting data into table "ref_transaction_status" */
export type Ref_Transaction_Status_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Transaction_Status_Max_Fields = {
  __typename?: 'ref_transaction_status_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Transaction_Status_Min_Fields = {
  __typename?: 'ref_transaction_status_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_transaction_status" */
export type Ref_Transaction_Status_Mutation_Response = {
  __typename?: 'ref_transaction_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Transaction_Status>;
};

/** on_conflict condition type for table "ref_transaction_status" */
export type Ref_Transaction_Status_On_Conflict = {
  constraint: Ref_Transaction_Status_Constraint;
  update_columns?: Array<Ref_Transaction_Status_Update_Column>;
  where?: InputMaybe<Ref_Transaction_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_transaction_status". */
export type Ref_Transaction_Status_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_transaction_status */
export type Ref_Transaction_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_transaction_status" */
export enum Ref_Transaction_Status_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_transaction_status" */
export type Ref_Transaction_Status_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_transaction_status" */
export type Ref_Transaction_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Transaction_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Transaction_Status_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_transaction_status" */
export enum Ref_Transaction_Status_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Transaction_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Transaction_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Transaction_Status_Bool_Exp;
};

/** columns and relationships of "ref_transaction_type" */
export type Ref_Transaction_Type = {
  __typename?: 'ref_transaction_type';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_transaction_type" */
export type Ref_Transaction_Type_Aggregate = {
  __typename?: 'ref_transaction_type_aggregate';
  aggregate?: Maybe<Ref_Transaction_Type_Aggregate_Fields>;
  nodes: Array<Ref_Transaction_Type>;
};

/** aggregate fields of "ref_transaction_type" */
export type Ref_Transaction_Type_Aggregate_Fields = {
  __typename?: 'ref_transaction_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Transaction_Type_Max_Fields>;
  min?: Maybe<Ref_Transaction_Type_Min_Fields>;
};


/** aggregate fields of "ref_transaction_type" */
export type Ref_Transaction_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Transaction_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_transaction_type". All fields are combined with a logical 'AND'. */
export type Ref_Transaction_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Transaction_Type_Bool_Exp>>;
  _not?: InputMaybe<Ref_Transaction_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Transaction_Type_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_transaction_type" */
export enum Ref_Transaction_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefTransactionTypePkey = 'ref_transaction_type_pkey'
}

export enum Ref_Transaction_Type_Enum {
  /** EMI */
  Emi = 'EMI',
  /** EMI_REATTEMPT */
  EmiReattempt = 'EMI_REATTEMPT',
  /** FEES_CHARGES */
  FeesCharges = 'FEES_CHARGES',
  /** PAYOUT */
  Payout = 'PAYOUT',
  /** TDS */
  Tds = 'TDS'
}

/** Boolean expression to compare columns of type "ref_transaction_type_enum". All fields are combined with logical 'AND'. */
export type Ref_Transaction_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Transaction_Type_Enum>;
  _in?: InputMaybe<Array<Ref_Transaction_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Transaction_Type_Enum>;
  _nin?: InputMaybe<Array<Ref_Transaction_Type_Enum>>;
};

/** input type for inserting data into table "ref_transaction_type" */
export type Ref_Transaction_Type_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Transaction_Type_Max_Fields = {
  __typename?: 'ref_transaction_type_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Transaction_Type_Min_Fields = {
  __typename?: 'ref_transaction_type_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_transaction_type" */
export type Ref_Transaction_Type_Mutation_Response = {
  __typename?: 'ref_transaction_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Transaction_Type>;
};

/** on_conflict condition type for table "ref_transaction_type" */
export type Ref_Transaction_Type_On_Conflict = {
  constraint: Ref_Transaction_Type_Constraint;
  update_columns?: Array<Ref_Transaction_Type_Update_Column>;
  where?: InputMaybe<Ref_Transaction_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_transaction_type". */
export type Ref_Transaction_Type_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_transaction_type */
export type Ref_Transaction_Type_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_transaction_type" */
export enum Ref_Transaction_Type_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_transaction_type" */
export type Ref_Transaction_Type_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_transaction_type" */
export type Ref_Transaction_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Transaction_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Transaction_Type_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_transaction_type" */
export enum Ref_Transaction_Type_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_Transaction_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Transaction_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Transaction_Type_Bool_Exp;
};

/** columns and relationships of "ref_transfer_status" */
export type Ref_Transfer_Status = {
  __typename?: 'ref_transfer_status';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_transfer_status" */
export type Ref_Transfer_Status_Aggregate = {
  __typename?: 'ref_transfer_status_aggregate';
  aggregate?: Maybe<Ref_Transfer_Status_Aggregate_Fields>;
  nodes: Array<Ref_Transfer_Status>;
};

/** aggregate fields of "ref_transfer_status" */
export type Ref_Transfer_Status_Aggregate_Fields = {
  __typename?: 'ref_transfer_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Transfer_Status_Max_Fields>;
  min?: Maybe<Ref_Transfer_Status_Min_Fields>;
};


/** aggregate fields of "ref_transfer_status" */
export type Ref_Transfer_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Transfer_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_transfer_status". All fields are combined with a logical 'AND'. */
export type Ref_Transfer_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Transfer_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_Transfer_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Transfer_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_transfer_status" */
export enum Ref_Transfer_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefTransferStatusPkey = 'ref_transfer_status_pkey'
}

export enum Ref_Transfer_Status_Enum {
  /** completed */
  Completed = 'COMPLETED',
  /** created */
  Created = 'CREATED',
  /** failed */
  Failed = 'FAILED',
  /** initiated */
  Initiated = 'INITIATED'
}

/** Boolean expression to compare columns of type "ref_transfer_status_enum". All fields are combined with logical 'AND'. */
export type Ref_Transfer_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Transfer_Status_Enum>;
  _in?: InputMaybe<Array<Ref_Transfer_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Transfer_Status_Enum>;
  _nin?: InputMaybe<Array<Ref_Transfer_Status_Enum>>;
};

/** input type for inserting data into table "ref_transfer_status" */
export type Ref_Transfer_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Transfer_Status_Max_Fields = {
  __typename?: 'ref_transfer_status_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Transfer_Status_Min_Fields = {
  __typename?: 'ref_transfer_status_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_transfer_status" */
export type Ref_Transfer_Status_Mutation_Response = {
  __typename?: 'ref_transfer_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Transfer_Status>;
};

/** on_conflict condition type for table "ref_transfer_status" */
export type Ref_Transfer_Status_On_Conflict = {
  constraint: Ref_Transfer_Status_Constraint;
  update_columns?: Array<Ref_Transfer_Status_Update_Column>;
  where?: InputMaybe<Ref_Transfer_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_transfer_status". */
export type Ref_Transfer_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_transfer_status */
export type Ref_Transfer_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_transfer_status" */
export enum Ref_Transfer_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_transfer_status" */
export type Ref_Transfer_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_transfer_status" */
export type Ref_Transfer_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Transfer_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Transfer_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_transfer_status" */
export enum Ref_Transfer_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Ref_Transfer_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Transfer_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Transfer_Status_Bool_Exp;
};

/** columns and relationships of "ref_transfer_type" */
export type Ref_Transfer_Type = {
  __typename?: 'ref_transfer_type';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_transfer_type" */
export type Ref_Transfer_Type_Aggregate = {
  __typename?: 'ref_transfer_type_aggregate';
  aggregate?: Maybe<Ref_Transfer_Type_Aggregate_Fields>;
  nodes: Array<Ref_Transfer_Type>;
};

/** aggregate fields of "ref_transfer_type" */
export type Ref_Transfer_Type_Aggregate_Fields = {
  __typename?: 'ref_transfer_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_Transfer_Type_Max_Fields>;
  min?: Maybe<Ref_Transfer_Type_Min_Fields>;
};


/** aggregate fields of "ref_transfer_type" */
export type Ref_Transfer_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_Transfer_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_transfer_type". All fields are combined with a logical 'AND'. */
export type Ref_Transfer_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_Transfer_Type_Bool_Exp>>;
  _not?: InputMaybe<Ref_Transfer_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_Transfer_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_transfer_type" */
export enum Ref_Transfer_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefTransferTypePkey = 'ref_transfer_type_pkey'
}

export enum Ref_Transfer_Type_Enum {
  /** fund transfer from trade VA to bridgeup revenue VA */
  FeesCharges = 'FEES_CHARGES',
  /** fund transfer from trade VA to investor VA */
  PayinCredit = 'PAYIN_CREDIT',
  /** fund transfer from bridgeup main VA to trade VA */
  PayinDebit = 'PAYIN_DEBIT',
  /** fund transfer from trade VA to company real account */
  PayoutCredit = 'PAYOUT_CREDIT',
  /** fund transfer from investor to trade VA */
  PayoutDebit = 'PAYOUT_DEBIT',
  /** Tds amount transfer from BridgeUp Withhold VA to Company Real Account */
  TdsCredit = 'TDS_CREDIT',
  /** Tds amount transfer from IVA to BridgeUp Withhold VA */
  TdsDebit = 'TDS_DEBIT',
  /** fund transfer from investor VA to investor real account */
  Withdraw = 'WITHDRAW'
}

/** Boolean expression to compare columns of type "ref_transfer_type_enum". All fields are combined with logical 'AND'. */
export type Ref_Transfer_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_Transfer_Type_Enum>;
  _in?: InputMaybe<Array<Ref_Transfer_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_Transfer_Type_Enum>;
  _nin?: InputMaybe<Array<Ref_Transfer_Type_Enum>>;
};

/** input type for inserting data into table "ref_transfer_type" */
export type Ref_Transfer_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_Transfer_Type_Max_Fields = {
  __typename?: 'ref_transfer_type_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_Transfer_Type_Min_Fields = {
  __typename?: 'ref_transfer_type_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_transfer_type" */
export type Ref_Transfer_Type_Mutation_Response = {
  __typename?: 'ref_transfer_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_Transfer_Type>;
};

/** on_conflict condition type for table "ref_transfer_type" */
export type Ref_Transfer_Type_On_Conflict = {
  constraint: Ref_Transfer_Type_Constraint;
  update_columns?: Array<Ref_Transfer_Type_Update_Column>;
  where?: InputMaybe<Ref_Transfer_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_transfer_type". */
export type Ref_Transfer_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_transfer_type */
export type Ref_Transfer_Type_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_transfer_type" */
export enum Ref_Transfer_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_transfer_type" */
export type Ref_Transfer_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_transfer_type" */
export type Ref_Transfer_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_Transfer_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_Transfer_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_transfer_type" */
export enum Ref_Transfer_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Ref_Transfer_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_Transfer_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_Transfer_Type_Bool_Exp;
};

/** columns and relationships of "ref_user_status" */
export type Ref_User_Status = {
  __typename?: 'ref_user_status';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ref_user_status" */
export type Ref_User_Status_Aggregate = {
  __typename?: 'ref_user_status_aggregate';
  aggregate?: Maybe<Ref_User_Status_Aggregate_Fields>;
  nodes: Array<Ref_User_Status>;
};

/** aggregate fields of "ref_user_status" */
export type Ref_User_Status_Aggregate_Fields = {
  __typename?: 'ref_user_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_User_Status_Max_Fields>;
  min?: Maybe<Ref_User_Status_Min_Fields>;
};


/** aggregate fields of "ref_user_status" */
export type Ref_User_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_User_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_user_status". All fields are combined with a logical 'AND'. */
export type Ref_User_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_User_Status_Bool_Exp>>;
  _not?: InputMaybe<Ref_User_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_User_Status_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_user_status" */
export enum Ref_User_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefUserStatusPkey = 'ref_user_status_pkey'
}

/** input type for inserting data into table "ref_user_status" */
export type Ref_User_Status_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_User_Status_Max_Fields = {
  __typename?: 'ref_user_status_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_User_Status_Min_Fields = {
  __typename?: 'ref_user_status_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_user_status" */
export type Ref_User_Status_Mutation_Response = {
  __typename?: 'ref_user_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_User_Status>;
};

/** on_conflict condition type for table "ref_user_status" */
export type Ref_User_Status_On_Conflict = {
  constraint: Ref_User_Status_Constraint;
  update_columns?: Array<Ref_User_Status_Update_Column>;
  where?: InputMaybe<Ref_User_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_user_status". */
export type Ref_User_Status_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_user_status */
export type Ref_User_Status_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_user_status" */
export enum Ref_User_Status_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ref_user_status" */
export type Ref_User_Status_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_user_status" */
export type Ref_User_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_User_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_User_Status_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_user_status" */
export enum Ref_User_Status_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ref_User_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_User_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_User_Status_Bool_Exp;
};

/** columns and relationships of "ref_user_type" */
export type Ref_User_Type = {
  __typename?: 'ref_user_type';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ref_user_type" */
export type Ref_User_Type_Aggregate = {
  __typename?: 'ref_user_type_aggregate';
  aggregate?: Maybe<Ref_User_Type_Aggregate_Fields>;
  nodes: Array<Ref_User_Type>;
};

/** aggregate fields of "ref_user_type" */
export type Ref_User_Type_Aggregate_Fields = {
  __typename?: 'ref_user_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ref_User_Type_Max_Fields>;
  min?: Maybe<Ref_User_Type_Min_Fields>;
};


/** aggregate fields of "ref_user_type" */
export type Ref_User_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ref_User_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ref_user_type". All fields are combined with a logical 'AND'. */
export type Ref_User_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Ref_User_Type_Bool_Exp>>;
  _not?: InputMaybe<Ref_User_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Ref_User_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ref_user_type" */
export enum Ref_User_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefUserTypePkey = 'ref_user_type_pkey'
}

export enum Ref_User_Type_Enum {
  /** Admin */
  Admin = 'ADMIN',
  /** Director */
  Director = 'DIRECTOR',
  /** Member */
  Member = 'MEMBER',
  /** Partner */
  Partner = 'PARTNER'
}

/** Boolean expression to compare columns of type "ref_user_type_enum". All fields are combined with logical 'AND'. */
export type Ref_User_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ref_User_Type_Enum>;
  _in?: InputMaybe<Array<Ref_User_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ref_User_Type_Enum>;
  _nin?: InputMaybe<Array<Ref_User_Type_Enum>>;
};

/** input type for inserting data into table "ref_user_type" */
export type Ref_User_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ref_User_Type_Max_Fields = {
  __typename?: 'ref_user_type_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ref_User_Type_Min_Fields = {
  __typename?: 'ref_user_type_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ref_user_type" */
export type Ref_User_Type_Mutation_Response = {
  __typename?: 'ref_user_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ref_User_Type>;
};

/** input type for inserting object relation for remote table "ref_user_type" */
export type Ref_User_Type_Obj_Rel_Insert_Input = {
  data: Ref_User_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ref_User_Type_On_Conflict>;
};

/** on_conflict condition type for table "ref_user_type" */
export type Ref_User_Type_On_Conflict = {
  constraint: Ref_User_Type_Constraint;
  update_columns?: Array<Ref_User_Type_Update_Column>;
  where?: InputMaybe<Ref_User_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ref_user_type". */
export type Ref_User_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ref_user_type */
export type Ref_User_Type_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ref_user_type" */
export enum Ref_User_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ref_user_type" */
export type Ref_User_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ref_user_type" */
export type Ref_User_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ref_User_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ref_User_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ref_user_type" */
export enum Ref_User_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Ref_User_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ref_User_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ref_User_Type_Bool_Exp;
};

/** columns and relationships of "report_category" */
export type Report_Category = {
  __typename?: 'report_category';
  category_type: Scalars['String'];
  /** An object relationship */
  ref_report_category: Ref_Report_Category;
  ref_report_category_id: Ref_Report_Category_Enum;
};

/** aggregated selection of "report_category" */
export type Report_Category_Aggregate = {
  __typename?: 'report_category_aggregate';
  aggregate?: Maybe<Report_Category_Aggregate_Fields>;
  nodes: Array<Report_Category>;
};

/** aggregate fields of "report_category" */
export type Report_Category_Aggregate_Fields = {
  __typename?: 'report_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Report_Category_Max_Fields>;
  min?: Maybe<Report_Category_Min_Fields>;
};


/** aggregate fields of "report_category" */
export type Report_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Report_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "report_category". All fields are combined with a logical 'AND'. */
export type Report_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Report_Category_Bool_Exp>>;
  _not?: InputMaybe<Report_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Report_Category_Bool_Exp>>;
  category_type?: InputMaybe<String_Comparison_Exp>;
  ref_report_category?: InputMaybe<Ref_Report_Category_Bool_Exp>;
  ref_report_category_id?: InputMaybe<Ref_Report_Category_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "report_category" */
export enum Report_Category_Constraint {
  /** unique or primary key constraint on columns "ref_report_category_id" */
  ReportCategoryPkey = 'report_category_pkey'
}

/** input type for inserting data into table "report_category" */
export type Report_Category_Insert_Input = {
  category_type?: InputMaybe<Scalars['String']>;
  ref_report_category?: InputMaybe<Ref_Report_Category_Obj_Rel_Insert_Input>;
  ref_report_category_id?: InputMaybe<Ref_Report_Category_Enum>;
};

/** aggregate max on columns */
export type Report_Category_Max_Fields = {
  __typename?: 'report_category_max_fields';
  category_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Report_Category_Min_Fields = {
  __typename?: 'report_category_min_fields';
  category_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "report_category" */
export type Report_Category_Mutation_Response = {
  __typename?: 'report_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Report_Category>;
};

/** on_conflict condition type for table "report_category" */
export type Report_Category_On_Conflict = {
  constraint: Report_Category_Constraint;
  update_columns?: Array<Report_Category_Update_Column>;
  where?: InputMaybe<Report_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "report_category". */
export type Report_Category_Order_By = {
  category_type?: InputMaybe<Order_By>;
  ref_report_category?: InputMaybe<Ref_Report_Category_Order_By>;
  ref_report_category_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: report_category */
export type Report_Category_Pk_Columns_Input = {
  ref_report_category_id: Ref_Report_Category_Enum;
};

/** select columns of table "report_category" */
export enum Report_Category_Select_Column {
  /** column name */
  CategoryType = 'category_type',
  /** column name */
  RefReportCategoryId = 'ref_report_category_id'
}

/** input type for updating data in table "report_category" */
export type Report_Category_Set_Input = {
  category_type?: InputMaybe<Scalars['String']>;
  ref_report_category_id?: InputMaybe<Ref_Report_Category_Enum>;
};

/** Streaming cursor of the table "report_category" */
export type Report_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Report_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Report_Category_Stream_Cursor_Value_Input = {
  category_type?: InputMaybe<Scalars['String']>;
  ref_report_category_id?: InputMaybe<Ref_Report_Category_Enum>;
};

/** update columns of table "report_category" */
export enum Report_Category_Update_Column {
  /** column name */
  CategoryType = 'category_type',
  /** column name */
  RefReportCategoryId = 'ref_report_category_id'
}

export type Report_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Report_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Report_Category_Bool_Exp;
};

/** columns and relationships of "report_event" */
export type Report_Event = {
  __typename?: 'report_event';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  failure_reason?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  file_registry?: Maybe<File_Registry>;
  file_registry_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  metadata?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  ref_report_category: Ref_Report_Category;
  report_category_id: Ref_Report_Category_Enum;
  start_time?: Maybe<Scalars['timestamptz']>;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "report_event" */
export type Report_EventFailure_ReasonArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "report_event" */
export type Report_EventMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "report_event" */
export type Report_Event_Aggregate = {
  __typename?: 'report_event_aggregate';
  aggregate?: Maybe<Report_Event_Aggregate_Fields>;
  nodes: Array<Report_Event>;
};

/** aggregate fields of "report_event" */
export type Report_Event_Aggregate_Fields = {
  __typename?: 'report_event_aggregate_fields';
  avg?: Maybe<Report_Event_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Report_Event_Max_Fields>;
  min?: Maybe<Report_Event_Min_Fields>;
  stddev?: Maybe<Report_Event_Stddev_Fields>;
  stddev_pop?: Maybe<Report_Event_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Report_Event_Stddev_Samp_Fields>;
  sum?: Maybe<Report_Event_Sum_Fields>;
  var_pop?: Maybe<Report_Event_Var_Pop_Fields>;
  var_samp?: Maybe<Report_Event_Var_Samp_Fields>;
  variance?: Maybe<Report_Event_Variance_Fields>;
};


/** aggregate fields of "report_event" */
export type Report_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Report_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Report_Event_Append_Input = {
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Report_Event_Avg_Fields = {
  __typename?: 'report_event_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  file_registry_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "report_event". All fields are combined with a logical 'AND'. */
export type Report_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Report_Event_Bool_Exp>>;
  _not?: InputMaybe<Report_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Report_Event_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  end_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  failure_reason?: InputMaybe<Jsonb_Comparison_Exp>;
  file_registry?: InputMaybe<File_Registry_Bool_Exp>;
  file_registry_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  ref_report_category?: InputMaybe<Ref_Report_Category_Bool_Exp>;
  report_category_id?: InputMaybe<Ref_Report_Category_Enum_Comparison_Exp>;
  start_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "report_event" */
export enum Report_Event_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReportEventPkey = 'report_event_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Report_Event_Delete_At_Path_Input = {
  failure_reason?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Report_Event_Delete_Elem_Input = {
  failure_reason?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Report_Event_Delete_Key_Input = {
  failure_reason?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "report_event" */
export type Report_Event_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  file_registry_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "report_event" */
export type Report_Event_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  file_registry?: InputMaybe<File_Registry_Obj_Rel_Insert_Input>;
  file_registry_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  ref_report_category?: InputMaybe<Ref_Report_Category_Obj_Rel_Insert_Input>;
  report_category_id?: InputMaybe<Ref_Report_Category_Enum>;
  start_time?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Report_Event_Max_Fields = {
  __typename?: 'report_event_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  file_registry_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Report_Event_Min_Fields = {
  __typename?: 'report_event_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  file_registry_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "report_event" */
export type Report_Event_Mutation_Response = {
  __typename?: 'report_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Report_Event>;
};

/** on_conflict condition type for table "report_event" */
export type Report_Event_On_Conflict = {
  constraint: Report_Event_Constraint;
  update_columns?: Array<Report_Event_Update_Column>;
  where?: InputMaybe<Report_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "report_event". */
export type Report_Event_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  failure_reason?: InputMaybe<Order_By>;
  file_registry?: InputMaybe<File_Registry_Order_By>;
  file_registry_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  ref_report_category?: InputMaybe<Ref_Report_Category_Order_By>;
  report_category_id?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: report_event */
export type Report_Event_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Report_Event_Prepend_Input = {
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "report_event" */
export enum Report_Event_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  FileRegistryId = 'file_registry_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ReportCategoryId = 'report_category_id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "report_event" */
export type Report_Event_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  file_registry_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  report_category_id?: InputMaybe<Ref_Report_Category_Enum>;
  start_time?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Report_Event_Stddev_Fields = {
  __typename?: 'report_event_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  file_registry_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Report_Event_Stddev_Pop_Fields = {
  __typename?: 'report_event_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  file_registry_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Report_Event_Stddev_Samp_Fields = {
  __typename?: 'report_event_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  file_registry_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "report_event" */
export type Report_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Report_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Report_Event_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  failure_reason?: InputMaybe<Scalars['jsonb']>;
  file_registry_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  report_category_id?: InputMaybe<Ref_Report_Category_Enum>;
  start_time?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Report_Event_Sum_Fields = {
  __typename?: 'report_event_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  file_registry_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "report_event" */
export enum Report_Event_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  FileRegistryId = 'file_registry_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ReportCategoryId = 'report_category_id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Report_Event_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Report_Event_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Report_Event_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Report_Event_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Report_Event_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Report_Event_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Report_Event_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Report_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Report_Event_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Report_Event_Var_Pop_Fields = {
  __typename?: 'report_event_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  file_registry_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Report_Event_Var_Samp_Fields = {
  __typename?: 'report_event_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  file_registry_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Report_Event_Variance_Fields = {
  __typename?: 'report_event_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  file_registry_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "schedule_document_checklist_reminder" */
export type Schedule_Document_Checklist_Reminder = {
  __typename?: 'schedule_document_checklist_reminder';
  /** An object relationship */
  company: Company;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  /** fetch data from the table: "document_checklist_category" */
  document_checklist_category: Array<Document_Checklist_Category>;
  /** fetch aggregated fields from the table: "document_checklist_category" */
  document_checklist_category_aggregate: Document_Checklist_Category_Aggregate;
  id: Scalars['Int'];
  sales_user_email?: Maybe<Scalars['String']>;
  scheduled_at: Scalars['timestamptz'];
  status: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "schedule_document_checklist_reminder" */
export type Schedule_Document_Checklist_ReminderDocument_Checklist_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Document_Checklist_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Checklist_Category_Order_By>>;
  where?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
};


/** columns and relationships of "schedule_document_checklist_reminder" */
export type Schedule_Document_Checklist_ReminderDocument_Checklist_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Checklist_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Checklist_Category_Order_By>>;
  where?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
};

/** aggregated selection of "schedule_document_checklist_reminder" */
export type Schedule_Document_Checklist_Reminder_Aggregate = {
  __typename?: 'schedule_document_checklist_reminder_aggregate';
  aggregate?: Maybe<Schedule_Document_Checklist_Reminder_Aggregate_Fields>;
  nodes: Array<Schedule_Document_Checklist_Reminder>;
};

/** aggregate fields of "schedule_document_checklist_reminder" */
export type Schedule_Document_Checklist_Reminder_Aggregate_Fields = {
  __typename?: 'schedule_document_checklist_reminder_aggregate_fields';
  avg?: Maybe<Schedule_Document_Checklist_Reminder_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Schedule_Document_Checklist_Reminder_Max_Fields>;
  min?: Maybe<Schedule_Document_Checklist_Reminder_Min_Fields>;
  stddev?: Maybe<Schedule_Document_Checklist_Reminder_Stddev_Fields>;
  stddev_pop?: Maybe<Schedule_Document_Checklist_Reminder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Schedule_Document_Checklist_Reminder_Stddev_Samp_Fields>;
  sum?: Maybe<Schedule_Document_Checklist_Reminder_Sum_Fields>;
  var_pop?: Maybe<Schedule_Document_Checklist_Reminder_Var_Pop_Fields>;
  var_samp?: Maybe<Schedule_Document_Checklist_Reminder_Var_Samp_Fields>;
  variance?: Maybe<Schedule_Document_Checklist_Reminder_Variance_Fields>;
};


/** aggregate fields of "schedule_document_checklist_reminder" */
export type Schedule_Document_Checklist_Reminder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Schedule_Document_Checklist_Reminder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Schedule_Document_Checklist_Reminder_Avg_Fields = {
  __typename?: 'schedule_document_checklist_reminder_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "schedule_document_checklist_reminder". All fields are combined with a logical 'AND'. */
export type Schedule_Document_Checklist_Reminder_Bool_Exp = {
  _and?: InputMaybe<Array<Schedule_Document_Checklist_Reminder_Bool_Exp>>;
  _not?: InputMaybe<Schedule_Document_Checklist_Reminder_Bool_Exp>;
  _or?: InputMaybe<Array<Schedule_Document_Checklist_Reminder_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  document_checklist_category?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
  document_checklist_category_aggregate?: InputMaybe<Document_Checklist_Category_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  sales_user_email?: InputMaybe<String_Comparison_Exp>;
  scheduled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "schedule_document_checklist_reminder" */
export enum Schedule_Document_Checklist_Reminder_Constraint {
  /** unique or primary key constraint on columns "id" */
  ScheduleChecklistReminderPkey = 'schedule_checklist_reminder_pkey'
}

/** input type for incrementing numeric columns in table "schedule_document_checklist_reminder" */
export type Schedule_Document_Checklist_Reminder_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "schedule_document_checklist_reminder" */
export type Schedule_Document_Checklist_Reminder_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  document_checklist_category?: InputMaybe<Document_Checklist_Category_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  sales_user_email?: InputMaybe<Scalars['String']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Schedule_Document_Checklist_Reminder_Max_Fields = {
  __typename?: 'schedule_document_checklist_reminder_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  sales_user_email?: Maybe<Scalars['String']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Schedule_Document_Checklist_Reminder_Min_Fields = {
  __typename?: 'schedule_document_checklist_reminder_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  sales_user_email?: Maybe<Scalars['String']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "schedule_document_checklist_reminder" */
export type Schedule_Document_Checklist_Reminder_Mutation_Response = {
  __typename?: 'schedule_document_checklist_reminder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Schedule_Document_Checklist_Reminder>;
};

/** on_conflict condition type for table "schedule_document_checklist_reminder" */
export type Schedule_Document_Checklist_Reminder_On_Conflict = {
  constraint: Schedule_Document_Checklist_Reminder_Constraint;
  update_columns?: Array<Schedule_Document_Checklist_Reminder_Update_Column>;
  where?: InputMaybe<Schedule_Document_Checklist_Reminder_Bool_Exp>;
};

/** Ordering options when selecting data from "schedule_document_checklist_reminder". */
export type Schedule_Document_Checklist_Reminder_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  document_checklist_category_aggregate?: InputMaybe<Document_Checklist_Category_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  sales_user_email?: InputMaybe<Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: schedule_document_checklist_reminder */
export type Schedule_Document_Checklist_Reminder_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "schedule_document_checklist_reminder" */
export enum Schedule_Document_Checklist_Reminder_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  SalesUserEmail = 'sales_user_email',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "schedule_document_checklist_reminder" */
export type Schedule_Document_Checklist_Reminder_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  sales_user_email?: InputMaybe<Scalars['String']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Schedule_Document_Checklist_Reminder_Stddev_Fields = {
  __typename?: 'schedule_document_checklist_reminder_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Schedule_Document_Checklist_Reminder_Stddev_Pop_Fields = {
  __typename?: 'schedule_document_checklist_reminder_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Schedule_Document_Checklist_Reminder_Stddev_Samp_Fields = {
  __typename?: 'schedule_document_checklist_reminder_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "schedule_document_checklist_reminder" */
export type Schedule_Document_Checklist_Reminder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Schedule_Document_Checklist_Reminder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Schedule_Document_Checklist_Reminder_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  sales_user_email?: InputMaybe<Scalars['String']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Schedule_Document_Checklist_Reminder_Sum_Fields = {
  __typename?: 'schedule_document_checklist_reminder_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "schedule_document_checklist_reminder" */
export enum Schedule_Document_Checklist_Reminder_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  SalesUserEmail = 'sales_user_email',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Schedule_Document_Checklist_Reminder_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Schedule_Document_Checklist_Reminder_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Schedule_Document_Checklist_Reminder_Set_Input>;
  /** filter the rows which have to be updated */
  where: Schedule_Document_Checklist_Reminder_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Schedule_Document_Checklist_Reminder_Var_Pop_Fields = {
  __typename?: 'schedule_document_checklist_reminder_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Schedule_Document_Checklist_Reminder_Var_Samp_Fields = {
  __typename?: 'schedule_document_checklist_reminder_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Schedule_Document_Checklist_Reminder_Variance_Fields = {
  __typename?: 'schedule_document_checklist_reminder_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "scheduled_emi" */
export type Scheduled_Emi = {
  __typename?: 'scheduled_emi';
  /** An object relationship */
  account: Account;
  account_id: Scalars['Int'];
  amount: Scalars['numeric'];
  bounce_charges: Scalars['numeric'];
  capital?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  counterparty: Account;
  counterparty_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  /** An array relationship */
  emi_tds: Array<Emi_Tds>;
  /** An aggregate relationship */
  emi_tds_aggregate: Emi_Tds_Aggregate;
  id: Scalars['Int'];
  interest?: Maybe<Scalars['numeric']>;
  mandate_id?: Maybe<Scalars['String']>;
  max_presentation_amount: Scalars['numeric'];
  metadata?: Maybe<Scalars['jsonb']>;
  penal_interest: Scalars['numeric'];
  /** Amount presented to Digio */
  presentation_amount?: Maybe<Scalars['numeric']>;
  remarks?: Maybe<Scalars['String']>;
  scheduled_at: Scalars['timestamptz'];
  source_id?: Maybe<Scalars['String']>;
  status: Ref_Transaction_Status_Enum;
  tds?: Maybe<Scalars['numeric']>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: Maybe<Scalars['float8']>;
  /** An object relationship */
  trade: Trade;
  trade_id: Scalars['Int'];
  trade_installment_seq?: Maybe<Scalars['Int']>;
  transaction_method: Ref_Transaction_Method_Enum;
  transaction_type: Ref_Transaction_Type_Enum;
  /** An array relationship */
  transactions: Array<Transaction>;
  /** An aggregate relationship */
  transactions_aggregate: Transaction_Aggregate;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "scheduled_emi" */
export type Scheduled_EmiEmi_TdsArgs = {
  distinct_on?: InputMaybe<Array<Emi_Tds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emi_Tds_Order_By>>;
  where?: InputMaybe<Emi_Tds_Bool_Exp>;
};


/** columns and relationships of "scheduled_emi" */
export type Scheduled_EmiEmi_Tds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Emi_Tds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emi_Tds_Order_By>>;
  where?: InputMaybe<Emi_Tds_Bool_Exp>;
};


/** columns and relationships of "scheduled_emi" */
export type Scheduled_EmiMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "scheduled_emi" */
export type Scheduled_EmiTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};


/** columns and relationships of "scheduled_emi" */
export type Scheduled_EmiTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};

/** aggregated selection of "scheduled_emi" */
export type Scheduled_Emi_Aggregate = {
  __typename?: 'scheduled_emi_aggregate';
  aggregate?: Maybe<Scheduled_Emi_Aggregate_Fields>;
  nodes: Array<Scheduled_Emi>;
};

export type Scheduled_Emi_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Scheduled_Emi_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Scheduled_Emi_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Scheduled_Emi_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Scheduled_Emi_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Scheduled_Emi_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Scheduled_Emi_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Scheduled_Emi_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Scheduled_Emi_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Scheduled_Emi_Aggregate_Bool_Exp_Var_Samp>;
};

export type Scheduled_Emi_Aggregate_Bool_Exp_Avg = {
  arguments: Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Scheduled_Emi_Aggregate_Bool_Exp_Corr = {
  arguments: Scheduled_Emi_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Scheduled_Emi_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Scheduled_Emi_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Scheduled_Emi_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Scheduled_Emi_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Scheduled_Emi_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Scheduled_Emi_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Scheduled_Emi_Aggregate_Bool_Exp_Max = {
  arguments: Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Scheduled_Emi_Aggregate_Bool_Exp_Min = {
  arguments: Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Scheduled_Emi_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Scheduled_Emi_Aggregate_Bool_Exp_Sum = {
  arguments: Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Scheduled_Emi_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "scheduled_emi" */
export type Scheduled_Emi_Aggregate_Fields = {
  __typename?: 'scheduled_emi_aggregate_fields';
  avg?: Maybe<Scheduled_Emi_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Scheduled_Emi_Max_Fields>;
  min?: Maybe<Scheduled_Emi_Min_Fields>;
  stddev?: Maybe<Scheduled_Emi_Stddev_Fields>;
  stddev_pop?: Maybe<Scheduled_Emi_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Scheduled_Emi_Stddev_Samp_Fields>;
  sum?: Maybe<Scheduled_Emi_Sum_Fields>;
  var_pop?: Maybe<Scheduled_Emi_Var_Pop_Fields>;
  var_samp?: Maybe<Scheduled_Emi_Var_Samp_Fields>;
  variance?: Maybe<Scheduled_Emi_Variance_Fields>;
};


/** aggregate fields of "scheduled_emi" */
export type Scheduled_Emi_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Scheduled_Emi_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "scheduled_emi" */
export type Scheduled_Emi_Aggregate_Order_By = {
  avg?: InputMaybe<Scheduled_Emi_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Scheduled_Emi_Max_Order_By>;
  min?: InputMaybe<Scheduled_Emi_Min_Order_By>;
  stddev?: InputMaybe<Scheduled_Emi_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Scheduled_Emi_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Scheduled_Emi_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Scheduled_Emi_Sum_Order_By>;
  var_pop?: InputMaybe<Scheduled_Emi_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Scheduled_Emi_Var_Samp_Order_By>;
  variance?: InputMaybe<Scheduled_Emi_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Scheduled_Emi_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "scheduled_emi" */
export type Scheduled_Emi_Arr_Rel_Insert_Input = {
  data: Array<Scheduled_Emi_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Scheduled_Emi_On_Conflict>;
};

/** aggregate avg on columns */
export type Scheduled_Emi_Avg_Fields = {
  __typename?: 'scheduled_emi_avg_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  penal_interest?: Maybe<Scalars['Float']>;
  /** Amount presented to Digio */
  presentation_amount?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "scheduled_emi" */
export type Scheduled_Emi_Avg_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  /** Amount presented to Digio */
  presentation_amount?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "scheduled_emi". All fields are combined with a logical 'AND'. */
export type Scheduled_Emi_Bool_Exp = {
  _and?: InputMaybe<Array<Scheduled_Emi_Bool_Exp>>;
  _not?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  _or?: InputMaybe<Array<Scheduled_Emi_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_id?: InputMaybe<Int_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  bounce_charges?: InputMaybe<Numeric_Comparison_Exp>;
  capital?: InputMaybe<Numeric_Comparison_Exp>;
  counterparty?: InputMaybe<Account_Bool_Exp>;
  counterparty_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  emi_tds?: InputMaybe<Emi_Tds_Bool_Exp>;
  emi_tds_aggregate?: InputMaybe<Emi_Tds_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interest?: InputMaybe<Numeric_Comparison_Exp>;
  mandate_id?: InputMaybe<String_Comparison_Exp>;
  max_presentation_amount?: InputMaybe<Numeric_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  penal_interest?: InputMaybe<Numeric_Comparison_Exp>;
  presentation_amount?: InputMaybe<Numeric_Comparison_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
  scheduled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  source_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Ref_Transaction_Status_Enum_Comparison_Exp>;
  tds?: InputMaybe<Numeric_Comparison_Exp>;
  total_amount?: InputMaybe<Float8_Comparison_Exp>;
  trade?: InputMaybe<Trade_Bool_Exp>;
  trade_id?: InputMaybe<Int_Comparison_Exp>;
  trade_installment_seq?: InputMaybe<Int_Comparison_Exp>;
  transaction_method?: InputMaybe<Ref_Transaction_Method_Enum_Comparison_Exp>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum_Comparison_Exp>;
  transactions?: InputMaybe<Transaction_Bool_Exp>;
  transactions_aggregate?: InputMaybe<Transaction_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "scheduled_emi" */
export enum Scheduled_Emi_Constraint {
  /** unique or primary key constraint on columns "id" */
  ScheduledPayoutPkey = 'scheduled_payout_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Scheduled_Emi_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Scheduled_Emi_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Scheduled_Emi_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "scheduled_emi" */
export type Scheduled_Emi_Inc_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  bounce_charges?: InputMaybe<Scalars['numeric']>;
  capital?: InputMaybe<Scalars['numeric']>;
  counterparty_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interest?: InputMaybe<Scalars['numeric']>;
  max_presentation_amount?: InputMaybe<Scalars['numeric']>;
  penal_interest?: InputMaybe<Scalars['numeric']>;
  /** Amount presented to Digio */
  presentation_amount?: InputMaybe<Scalars['numeric']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "scheduled_emi" */
export type Scheduled_Emi_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  bounce_charges?: InputMaybe<Scalars['numeric']>;
  capital?: InputMaybe<Scalars['numeric']>;
  counterparty?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  counterparty_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  emi_tds?: InputMaybe<Emi_Tds_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  interest?: InputMaybe<Scalars['numeric']>;
  mandate_id?: InputMaybe<Scalars['String']>;
  max_presentation_amount?: InputMaybe<Scalars['numeric']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  penal_interest?: InputMaybe<Scalars['numeric']>;
  /** Amount presented to Digio */
  presentation_amount?: InputMaybe<Scalars['numeric']>;
  remarks?: InputMaybe<Scalars['String']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  source_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ref_Transaction_Status_Enum>;
  trade?: InputMaybe<Trade_Obj_Rel_Insert_Input>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
  transaction_method?: InputMaybe<Ref_Transaction_Method_Enum>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum>;
  transactions?: InputMaybe<Transaction_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Scheduled_Emi_Max_Fields = {
  __typename?: 'scheduled_emi_max_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  bounce_charges?: Maybe<Scalars['numeric']>;
  capital?: Maybe<Scalars['numeric']>;
  counterparty_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  interest?: Maybe<Scalars['numeric']>;
  mandate_id?: Maybe<Scalars['String']>;
  max_presentation_amount?: Maybe<Scalars['numeric']>;
  penal_interest?: Maybe<Scalars['numeric']>;
  /** Amount presented to Digio */
  presentation_amount?: Maybe<Scalars['numeric']>;
  remarks?: Maybe<Scalars['String']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  source_id?: Maybe<Scalars['String']>;
  tds?: Maybe<Scalars['numeric']>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: Maybe<Scalars['float8']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "scheduled_emi" */
export type Scheduled_Emi_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  mandate_id?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  /** Amount presented to Digio */
  presentation_amount?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Scheduled_Emi_Min_Fields = {
  __typename?: 'scheduled_emi_min_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  bounce_charges?: Maybe<Scalars['numeric']>;
  capital?: Maybe<Scalars['numeric']>;
  counterparty_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  interest?: Maybe<Scalars['numeric']>;
  mandate_id?: Maybe<Scalars['String']>;
  max_presentation_amount?: Maybe<Scalars['numeric']>;
  penal_interest?: Maybe<Scalars['numeric']>;
  /** Amount presented to Digio */
  presentation_amount?: Maybe<Scalars['numeric']>;
  remarks?: Maybe<Scalars['String']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  source_id?: Maybe<Scalars['String']>;
  tds?: Maybe<Scalars['numeric']>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: Maybe<Scalars['float8']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "scheduled_emi" */
export type Scheduled_Emi_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  mandate_id?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  /** Amount presented to Digio */
  presentation_amount?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "scheduled_emi" */
export type Scheduled_Emi_Mutation_Response = {
  __typename?: 'scheduled_emi_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Scheduled_Emi>;
};

/** input type for inserting object relation for remote table "scheduled_emi" */
export type Scheduled_Emi_Obj_Rel_Insert_Input = {
  data: Scheduled_Emi_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Scheduled_Emi_On_Conflict>;
};

/** on_conflict condition type for table "scheduled_emi" */
export type Scheduled_Emi_On_Conflict = {
  constraint: Scheduled_Emi_Constraint;
  update_columns?: Array<Scheduled_Emi_Update_Column>;
  where?: InputMaybe<Scheduled_Emi_Bool_Exp>;
};

/** Ordering options when selecting data from "scheduled_emi". */
export type Scheduled_Emi_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  counterparty?: InputMaybe<Account_Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_tds_aggregate?: InputMaybe<Emi_Tds_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  mandate_id?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  presentation_amount?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
  trade?: InputMaybe<Trade_Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  transaction_method?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
  transactions_aggregate?: InputMaybe<Transaction_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: scheduled_emi */
export type Scheduled_Emi_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Scheduled_Emi_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "scheduled_emi" */
export enum Scheduled_Emi_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  BounceCharges = 'bounce_charges',
  /** column name */
  Capital = 'capital',
  /** column name */
  CounterpartyId = 'counterparty_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Interest = 'interest',
  /** column name */
  MandateId = 'mandate_id',
  /** column name */
  MaxPresentationAmount = 'max_presentation_amount',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PenalInterest = 'penal_interest',
  /** column name */
  PresentationAmount = 'presentation_amount',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Status = 'status',
  /** column name */
  Tds = 'tds',
  /** column name */
  TotalAmount = 'total_amount',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  TradeInstallmentSeq = 'trade_installment_seq',
  /** column name */
  TransactionMethod = 'transaction_method',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "scheduled_emi_aggregate_bool_exp_avg_arguments_columns" columns of table "scheduled_emi" */
export enum Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  TotalAmount = 'total_amount'
}

/** select "scheduled_emi_aggregate_bool_exp_corr_arguments_columns" columns of table "scheduled_emi" */
export enum Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  TotalAmount = 'total_amount'
}

/** select "scheduled_emi_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "scheduled_emi" */
export enum Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  TotalAmount = 'total_amount'
}

/** select "scheduled_emi_aggregate_bool_exp_max_arguments_columns" columns of table "scheduled_emi" */
export enum Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  TotalAmount = 'total_amount'
}

/** select "scheduled_emi_aggregate_bool_exp_min_arguments_columns" columns of table "scheduled_emi" */
export enum Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  TotalAmount = 'total_amount'
}

/** select "scheduled_emi_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "scheduled_emi" */
export enum Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  TotalAmount = 'total_amount'
}

/** select "scheduled_emi_aggregate_bool_exp_sum_arguments_columns" columns of table "scheduled_emi" */
export enum Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  TotalAmount = 'total_amount'
}

/** select "scheduled_emi_aggregate_bool_exp_var_samp_arguments_columns" columns of table "scheduled_emi" */
export enum Scheduled_Emi_Select_Column_Scheduled_Emi_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  TotalAmount = 'total_amount'
}

/** input type for updating data in table "scheduled_emi" */
export type Scheduled_Emi_Set_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  bounce_charges?: InputMaybe<Scalars['numeric']>;
  capital?: InputMaybe<Scalars['numeric']>;
  counterparty_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interest?: InputMaybe<Scalars['numeric']>;
  mandate_id?: InputMaybe<Scalars['String']>;
  max_presentation_amount?: InputMaybe<Scalars['numeric']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  penal_interest?: InputMaybe<Scalars['numeric']>;
  /** Amount presented to Digio */
  presentation_amount?: InputMaybe<Scalars['numeric']>;
  remarks?: InputMaybe<Scalars['String']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  source_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ref_Transaction_Status_Enum>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
  transaction_method?: InputMaybe<Ref_Transaction_Method_Enum>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Scheduled_Emi_Stddev_Fields = {
  __typename?: 'scheduled_emi_stddev_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  penal_interest?: Maybe<Scalars['Float']>;
  /** Amount presented to Digio */
  presentation_amount?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "scheduled_emi" */
export type Scheduled_Emi_Stddev_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  /** Amount presented to Digio */
  presentation_amount?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Scheduled_Emi_Stddev_Pop_Fields = {
  __typename?: 'scheduled_emi_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  penal_interest?: Maybe<Scalars['Float']>;
  /** Amount presented to Digio */
  presentation_amount?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "scheduled_emi" */
export type Scheduled_Emi_Stddev_Pop_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  /** Amount presented to Digio */
  presentation_amount?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Scheduled_Emi_Stddev_Samp_Fields = {
  __typename?: 'scheduled_emi_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  penal_interest?: Maybe<Scalars['Float']>;
  /** Amount presented to Digio */
  presentation_amount?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "scheduled_emi" */
export type Scheduled_Emi_Stddev_Samp_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  /** Amount presented to Digio */
  presentation_amount?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "scheduled_emi" */
export type Scheduled_Emi_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Scheduled_Emi_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Scheduled_Emi_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  bounce_charges?: InputMaybe<Scalars['numeric']>;
  capital?: InputMaybe<Scalars['numeric']>;
  counterparty_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interest?: InputMaybe<Scalars['numeric']>;
  mandate_id?: InputMaybe<Scalars['String']>;
  max_presentation_amount?: InputMaybe<Scalars['numeric']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  penal_interest?: InputMaybe<Scalars['numeric']>;
  /** Amount presented to Digio */
  presentation_amount?: InputMaybe<Scalars['numeric']>;
  remarks?: InputMaybe<Scalars['String']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  source_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ref_Transaction_Status_Enum>;
  tds?: InputMaybe<Scalars['numeric']>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: InputMaybe<Scalars['float8']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
  transaction_method?: InputMaybe<Ref_Transaction_Method_Enum>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Scheduled_Emi_Sum_Fields = {
  __typename?: 'scheduled_emi_sum_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  bounce_charges?: Maybe<Scalars['numeric']>;
  capital?: Maybe<Scalars['numeric']>;
  counterparty_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  interest?: Maybe<Scalars['numeric']>;
  max_presentation_amount?: Maybe<Scalars['numeric']>;
  penal_interest?: Maybe<Scalars['numeric']>;
  /** Amount presented to Digio */
  presentation_amount?: Maybe<Scalars['numeric']>;
  tds?: Maybe<Scalars['numeric']>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: Maybe<Scalars['float8']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "scheduled_emi" */
export type Scheduled_Emi_Sum_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  /** Amount presented to Digio */
  presentation_amount?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "scheduled_emi" */
export enum Scheduled_Emi_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  BounceCharges = 'bounce_charges',
  /** column name */
  Capital = 'capital',
  /** column name */
  CounterpartyId = 'counterparty_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Interest = 'interest',
  /** column name */
  MandateId = 'mandate_id',
  /** column name */
  MaxPresentationAmount = 'max_presentation_amount',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PenalInterest = 'penal_interest',
  /** column name */
  PresentationAmount = 'presentation_amount',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Status = 'status',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  TradeInstallmentSeq = 'trade_installment_seq',
  /** column name */
  TransactionMethod = 'transaction_method',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Scheduled_Emi_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Scheduled_Emi_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Scheduled_Emi_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Scheduled_Emi_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Scheduled_Emi_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Scheduled_Emi_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Scheduled_Emi_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Scheduled_Emi_Set_Input>;
  /** filter the rows which have to be updated */
  where: Scheduled_Emi_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Scheduled_Emi_Var_Pop_Fields = {
  __typename?: 'scheduled_emi_var_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  penal_interest?: Maybe<Scalars['Float']>;
  /** Amount presented to Digio */
  presentation_amount?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "scheduled_emi" */
export type Scheduled_Emi_Var_Pop_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  /** Amount presented to Digio */
  presentation_amount?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Scheduled_Emi_Var_Samp_Fields = {
  __typename?: 'scheduled_emi_var_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  penal_interest?: Maybe<Scalars['Float']>;
  /** Amount presented to Digio */
  presentation_amount?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "scheduled_emi" */
export type Scheduled_Emi_Var_Samp_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  /** Amount presented to Digio */
  presentation_amount?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Scheduled_Emi_Variance_Fields = {
  __typename?: 'scheduled_emi_variance_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  penal_interest?: Maybe<Scalars['Float']>;
  /** Amount presented to Digio */
  presentation_amount?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "scheduled_emi" */
export type Scheduled_Emi_Variance_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  /** Amount presented to Digio */
  presentation_amount?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  /** Total amount including interest charges, operational expenses, etc. */
  total_amount?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "score_factor" */
export type Score_Factor = {
  __typename?: 'score_factor';
  benchmark_desc: Scalars['String'];
  category: Ref_Score_Factor_Category_Enum;
  description: Scalars['String'];
  factor: Ref_Score_Factor_Enum;
  id: Scalars['Int'];
  /** An object relationship */
  ref_score_factor: Ref_Score_Factor;
  unit: Ref_Score_Factor_Unit_Enum;
};

/** aggregated selection of "score_factor" */
export type Score_Factor_Aggregate = {
  __typename?: 'score_factor_aggregate';
  aggregate?: Maybe<Score_Factor_Aggregate_Fields>;
  nodes: Array<Score_Factor>;
};

/** aggregate fields of "score_factor" */
export type Score_Factor_Aggregate_Fields = {
  __typename?: 'score_factor_aggregate_fields';
  avg?: Maybe<Score_Factor_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Score_Factor_Max_Fields>;
  min?: Maybe<Score_Factor_Min_Fields>;
  stddev?: Maybe<Score_Factor_Stddev_Fields>;
  stddev_pop?: Maybe<Score_Factor_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Score_Factor_Stddev_Samp_Fields>;
  sum?: Maybe<Score_Factor_Sum_Fields>;
  var_pop?: Maybe<Score_Factor_Var_Pop_Fields>;
  var_samp?: Maybe<Score_Factor_Var_Samp_Fields>;
  variance?: Maybe<Score_Factor_Variance_Fields>;
};


/** aggregate fields of "score_factor" */
export type Score_Factor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Score_Factor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Score_Factor_Avg_Fields = {
  __typename?: 'score_factor_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "score_factor". All fields are combined with a logical 'AND'. */
export type Score_Factor_Bool_Exp = {
  _and?: InputMaybe<Array<Score_Factor_Bool_Exp>>;
  _not?: InputMaybe<Score_Factor_Bool_Exp>;
  _or?: InputMaybe<Array<Score_Factor_Bool_Exp>>;
  benchmark_desc?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<Ref_Score_Factor_Category_Enum_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  factor?: InputMaybe<Ref_Score_Factor_Enum_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ref_score_factor?: InputMaybe<Ref_Score_Factor_Bool_Exp>;
  unit?: InputMaybe<Ref_Score_Factor_Unit_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "score_factor" */
export enum Score_Factor_Constraint {
  /** unique or primary key constraint on columns "category", "factor" */
  ScoreFactorFactorCategoryKey = 'score_factor_factor_category_key',
  /** unique or primary key constraint on columns "id" */
  ScoreFactorPkey = 'score_factor_pkey'
}

/** input type for incrementing numeric columns in table "score_factor" */
export type Score_Factor_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "score_factor" */
export type Score_Factor_Insert_Input = {
  benchmark_desc?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Ref_Score_Factor_Category_Enum>;
  description?: InputMaybe<Scalars['String']>;
  factor?: InputMaybe<Ref_Score_Factor_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  ref_score_factor?: InputMaybe<Ref_Score_Factor_Obj_Rel_Insert_Input>;
  unit?: InputMaybe<Ref_Score_Factor_Unit_Enum>;
};

/** aggregate max on columns */
export type Score_Factor_Max_Fields = {
  __typename?: 'score_factor_max_fields';
  benchmark_desc?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Score_Factor_Min_Fields = {
  __typename?: 'score_factor_min_fields';
  benchmark_desc?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "score_factor" */
export type Score_Factor_Mutation_Response = {
  __typename?: 'score_factor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Score_Factor>;
};

/** input type for inserting object relation for remote table "score_factor" */
export type Score_Factor_Obj_Rel_Insert_Input = {
  data: Score_Factor_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Score_Factor_On_Conflict>;
};

/** on_conflict condition type for table "score_factor" */
export type Score_Factor_On_Conflict = {
  constraint: Score_Factor_Constraint;
  update_columns?: Array<Score_Factor_Update_Column>;
  where?: InputMaybe<Score_Factor_Bool_Exp>;
};

/** Ordering options when selecting data from "score_factor". */
export type Score_Factor_Order_By = {
  benchmark_desc?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  factor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_score_factor?: InputMaybe<Ref_Score_Factor_Order_By>;
  unit?: InputMaybe<Order_By>;
};

/** primary key columns input for table: score_factor */
export type Score_Factor_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "score_factor" */
export enum Score_Factor_Select_Column {
  /** column name */
  BenchmarkDesc = 'benchmark_desc',
  /** column name */
  Category = 'category',
  /** column name */
  Description = 'description',
  /** column name */
  Factor = 'factor',
  /** column name */
  Id = 'id',
  /** column name */
  Unit = 'unit'
}

/** input type for updating data in table "score_factor" */
export type Score_Factor_Set_Input = {
  benchmark_desc?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Ref_Score_Factor_Category_Enum>;
  description?: InputMaybe<Scalars['String']>;
  factor?: InputMaybe<Ref_Score_Factor_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  unit?: InputMaybe<Ref_Score_Factor_Unit_Enum>;
};

/** aggregate stddev on columns */
export type Score_Factor_Stddev_Fields = {
  __typename?: 'score_factor_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Score_Factor_Stddev_Pop_Fields = {
  __typename?: 'score_factor_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Score_Factor_Stddev_Samp_Fields = {
  __typename?: 'score_factor_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "score_factor" */
export type Score_Factor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Score_Factor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Score_Factor_Stream_Cursor_Value_Input = {
  benchmark_desc?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Ref_Score_Factor_Category_Enum>;
  description?: InputMaybe<Scalars['String']>;
  factor?: InputMaybe<Ref_Score_Factor_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  unit?: InputMaybe<Ref_Score_Factor_Unit_Enum>;
};

/** aggregate sum on columns */
export type Score_Factor_Sum_Fields = {
  __typename?: 'score_factor_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "score_factor" */
export enum Score_Factor_Update_Column {
  /** column name */
  BenchmarkDesc = 'benchmark_desc',
  /** column name */
  Category = 'category',
  /** column name */
  Description = 'description',
  /** column name */
  Factor = 'factor',
  /** column name */
  Id = 'id',
  /** column name */
  Unit = 'unit'
}

export type Score_Factor_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Score_Factor_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Score_Factor_Set_Input>;
  /** filter the rows which have to be updated */
  where: Score_Factor_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Score_Factor_Var_Pop_Fields = {
  __typename?: 'score_factor_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Score_Factor_Var_Samp_Fields = {
  __typename?: 'score_factor_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Score_Factor_Variance_Fields = {
  __typename?: 'score_factor_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type SearchIfscInput = {
  ifsc: Scalars['String'];
};

export type SearchIfscOutput = {
  __typename?: 'searchIFSCOutput';
  bank?: Maybe<Ref_Bank>;
  bank_code: Scalars['String'];
  ifsc: Scalars['String'];
  micr?: Maybe<Scalars['String']>;
};

/** columns and relationships of "staff_user" */
export type Staff_User = {
  __typename?: 'staff_user';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  role: Scalars['String'];
  tenant_type?: Maybe<Ref_Tenant_Type_Enum>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
  user_id: Scalars['Int'];
};

/** aggregated selection of "staff_user" */
export type Staff_User_Aggregate = {
  __typename?: 'staff_user_aggregate';
  aggregate?: Maybe<Staff_User_Aggregate_Fields>;
  nodes: Array<Staff_User>;
};

/** aggregate fields of "staff_user" */
export type Staff_User_Aggregate_Fields = {
  __typename?: 'staff_user_aggregate_fields';
  avg?: Maybe<Staff_User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Staff_User_Max_Fields>;
  min?: Maybe<Staff_User_Min_Fields>;
  stddev?: Maybe<Staff_User_Stddev_Fields>;
  stddev_pop?: Maybe<Staff_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Staff_User_Stddev_Samp_Fields>;
  sum?: Maybe<Staff_User_Sum_Fields>;
  var_pop?: Maybe<Staff_User_Var_Pop_Fields>;
  var_samp?: Maybe<Staff_User_Var_Samp_Fields>;
  variance?: Maybe<Staff_User_Variance_Fields>;
};


/** aggregate fields of "staff_user" */
export type Staff_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Staff_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Staff_User_Avg_Fields = {
  __typename?: 'staff_user_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "staff_user". All fields are combined with a logical 'AND'. */
export type Staff_User_Bool_Exp = {
  _and?: InputMaybe<Array<Staff_User_Bool_Exp>>;
  _not?: InputMaybe<Staff_User_Bool_Exp>;
  _or?: InputMaybe<Array<Staff_User_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "staff_user" */
export enum Staff_User_Constraint {
  /** unique or primary key constraint on columns "id" */
  StaffUserPkey = 'staff_user_pkey',
  /** unique or primary key constraint on columns "user_id" */
  StaffUserUserIdIdx = 'staff_user_user_id_idx'
}

/** input type for incrementing numeric columns in table "staff_user" */
export type Staff_User_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "staff_user" */
export type Staff_User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['String']>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Staff_User_Max_Fields = {
  __typename?: 'staff_user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Staff_User_Min_Fields = {
  __typename?: 'staff_user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "staff_user" */
export type Staff_User_Mutation_Response = {
  __typename?: 'staff_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Staff_User>;
};

/** on_conflict condition type for table "staff_user" */
export type Staff_User_On_Conflict = {
  constraint: Staff_User_Constraint;
  update_columns?: Array<Staff_User_Update_Column>;
  where?: InputMaybe<Staff_User_Bool_Exp>;
};

/** Ordering options when selecting data from "staff_user". */
export type Staff_User_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  tenant_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: staff_user */
export type Staff_User_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "staff_user" */
export enum Staff_User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "staff_user" */
export type Staff_User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['String']>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Staff_User_Stddev_Fields = {
  __typename?: 'staff_user_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Staff_User_Stddev_Pop_Fields = {
  __typename?: 'staff_user_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Staff_User_Stddev_Samp_Fields = {
  __typename?: 'staff_user_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "staff_user" */
export type Staff_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Staff_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staff_User_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['String']>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Staff_User_Sum_Fields = {
  __typename?: 'staff_user_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "staff_user" */
export enum Staff_User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id'
}

export type Staff_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Staff_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Staff_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Staff_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Staff_User_Var_Pop_Fields = {
  __typename?: 'staff_user_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Staff_User_Var_Samp_Fields = {
  __typename?: 'staff_user_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Staff_User_Variance_Fields = {
  __typename?: 'staff_user_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "subscription" */
export type Subscription = {
  __typename?: 'subscription';
  amount_per_month: Scalars['float8'];
  amount_per_period: Scalars['float8'];
  /** A computed field, executes function "calculate_bid_amount" */
  bid_amount?: Maybe<Scalars['float8']>;
  billing_period: Scalars['String'];
  billing_period_interval: Scalars['Int'];
  company_id: Scalars['Int'];
  company_integration_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  currency: Scalars['String'];
  customer_created: Scalars['timestamptz'];
  customer_email: Scalars['String'];
  customer_identifier: Scalars['String'];
  customer_name?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  eligibility_score?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  is_latest: Scalars['Boolean'];
  status: Ref_Subscription_Status_Enum;
  subscription_id: Scalars['String'];
  tax_inclusive: Scalars['Boolean'];
  tax_rate?: Maybe<Scalars['float8']>;
  term_end?: Maybe<Scalars['timestamptz']>;
  term_start: Scalars['timestamptz'];
  /** An array relationship */
  trade_subscriptions: Array<Trade_Subscription>;
  /** An aggregate relationship */
  trade_subscriptions_aggregate: Trade_Subscription_Aggregate;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
  verified?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "subscription" */
export type SubscriptionBid_AmountArgs = {
  args: Bid_Amount_Subscription_Args;
};


/** columns and relationships of "subscription" */
export type SubscriptionTrade_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Trade_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Subscription_Order_By>>;
  where?: InputMaybe<Trade_Subscription_Bool_Exp>;
};


/** columns and relationships of "subscription" */
export type SubscriptionTrade_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trade_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Subscription_Order_By>>;
  where?: InputMaybe<Trade_Subscription_Bool_Exp>;
};

/** aggregated selection of "subscription" */
export type Subscription_Aggregate = {
  __typename?: 'subscription_aggregate';
  aggregate?: Maybe<Subscription_Aggregate_Fields>;
  nodes: Array<Subscription>;
};

/** aggregate fields of "subscription" */
export type Subscription_Aggregate_Fields = {
  __typename?: 'subscription_aggregate_fields';
  avg?: Maybe<Subscription_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subscription_Max_Fields>;
  min?: Maybe<Subscription_Min_Fields>;
  stddev?: Maybe<Subscription_Stddev_Fields>;
  stddev_pop?: Maybe<Subscription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscription_Stddev_Samp_Fields>;
  sum?: Maybe<Subscription_Sum_Fields>;
  var_pop?: Maybe<Subscription_Var_Pop_Fields>;
  var_samp?: Maybe<Subscription_Var_Samp_Fields>;
  variance?: Maybe<Subscription_Variance_Fields>;
};


/** aggregate fields of "subscription" */
export type Subscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subscription_Avg_Fields = {
  __typename?: 'subscription_avg_fields';
  amount_per_month?: Maybe<Scalars['Float']>;
  amount_per_period?: Maybe<Scalars['Float']>;
  billing_period_interval?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  company_integration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  eligibility_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tax_rate?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "subscription". All fields are combined with a logical 'AND'. */
export type Subscription_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Bool_Exp>>;
  amount_per_month?: InputMaybe<Float8_Comparison_Exp>;
  amount_per_period?: InputMaybe<Float8_Comparison_Exp>;
  billing_period?: InputMaybe<String_Comparison_Exp>;
  billing_period_interval?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  company_integration_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  customer_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_email?: InputMaybe<String_Comparison_Exp>;
  customer_identifier?: InputMaybe<String_Comparison_Exp>;
  customer_name?: InputMaybe<String_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  eligibility_score?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_latest?: InputMaybe<Boolean_Comparison_Exp>;
  status?: InputMaybe<Ref_Subscription_Status_Enum_Comparison_Exp>;
  subscription_id?: InputMaybe<String_Comparison_Exp>;
  tax_inclusive?: InputMaybe<Boolean_Comparison_Exp>;
  tax_rate?: InputMaybe<Float8_Comparison_Exp>;
  term_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  term_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  trade_subscriptions?: InputMaybe<Trade_Subscription_Bool_Exp>;
  trade_subscriptions_aggregate?: InputMaybe<Trade_Subscription_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  verified?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription" */
export enum Subscription_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionPkey = 'subscription_pkey'
}

/** input type for incrementing numeric columns in table "subscription" */
export type Subscription_Inc_Input = {
  amount_per_month?: InputMaybe<Scalars['float8']>;
  amount_per_period?: InputMaybe<Scalars['float8']>;
  billing_period_interval?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_integration_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  eligibility_score?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  tax_rate?: InputMaybe<Scalars['float8']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "subscription" */
export type Subscription_Insert_Input = {
  amount_per_month?: InputMaybe<Scalars['float8']>;
  amount_per_period?: InputMaybe<Scalars['float8']>;
  billing_period?: InputMaybe<Scalars['String']>;
  billing_period_interval?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_integration_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  customer_created?: InputMaybe<Scalars['timestamptz']>;
  customer_email?: InputMaybe<Scalars['String']>;
  customer_identifier?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  eligibility_score?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_latest?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Ref_Subscription_Status_Enum>;
  subscription_id?: InputMaybe<Scalars['String']>;
  tax_inclusive?: InputMaybe<Scalars['Boolean']>;
  tax_rate?: InputMaybe<Scalars['float8']>;
  term_end?: InputMaybe<Scalars['timestamptz']>;
  term_start?: InputMaybe<Scalars['timestamptz']>;
  trade_subscriptions?: InputMaybe<Trade_Subscription_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Subscription_Max_Fields = {
  __typename?: 'subscription_max_fields';
  amount_per_month?: Maybe<Scalars['float8']>;
  amount_per_period?: Maybe<Scalars['float8']>;
  billing_period?: Maybe<Scalars['String']>;
  billing_period_interval?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  company_integration_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  customer_created?: Maybe<Scalars['timestamptz']>;
  customer_email?: Maybe<Scalars['String']>;
  customer_identifier?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  eligibility_score?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['String']>;
  tax_rate?: Maybe<Scalars['float8']>;
  term_end?: Maybe<Scalars['timestamptz']>;
  term_start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Subscription_Min_Fields = {
  __typename?: 'subscription_min_fields';
  amount_per_month?: Maybe<Scalars['float8']>;
  amount_per_period?: Maybe<Scalars['float8']>;
  billing_period?: Maybe<Scalars['String']>;
  billing_period_interval?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  company_integration_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  customer_created?: Maybe<Scalars['timestamptz']>;
  customer_email?: Maybe<Scalars['String']>;
  customer_identifier?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  eligibility_score?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['String']>;
  tax_rate?: Maybe<Scalars['float8']>;
  term_end?: Maybe<Scalars['timestamptz']>;
  term_start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "subscription" */
export type Subscription_Mutation_Response = {
  __typename?: 'subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription>;
};

/** input type for inserting object relation for remote table "subscription" */
export type Subscription_Obj_Rel_Insert_Input = {
  data: Subscription_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};

/** on_conflict condition type for table "subscription" */
export type Subscription_On_Conflict = {
  constraint: Subscription_Constraint;
  update_columns?: Array<Subscription_Update_Column>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription". */
export type Subscription_Order_By = {
  amount_per_month?: InputMaybe<Order_By>;
  amount_per_period?: InputMaybe<Order_By>;
  billing_period?: InputMaybe<Order_By>;
  billing_period_interval?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_integration_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  customer_created?: InputMaybe<Order_By>;
  customer_email?: InputMaybe<Order_By>;
  customer_identifier?: InputMaybe<Order_By>;
  customer_name?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  eligibility_score?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_latest?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  tax_inclusive?: InputMaybe<Order_By>;
  tax_rate?: InputMaybe<Order_By>;
  term_end?: InputMaybe<Order_By>;
  term_start?: InputMaybe<Order_By>;
  trade_subscriptions_aggregate?: InputMaybe<Trade_Subscription_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  verified?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription */
export type Subscription_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: Account_Aggregate;
  /** fetch data from the table: "account_balance" */
  account_balance: Array<Account_Balance>;
  /** fetch aggregated fields from the table: "account_balance" */
  account_balance_aggregate: Account_Balance_Aggregate;
  /** fetch data from the table: "account_balance" using primary key columns */
  account_balance_by_pk?: Maybe<Account_Balance>;
  /** fetch data from the table in a streaming manner: "account_balance" */
  account_balance_stream: Array<Account_Balance>;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<Account>;
  /** fetch data from the table in a streaming manner: "account" */
  account_stream: Array<Account>;
  /** fetch data from the table: "agreement_registry" */
  agreement_registry: Array<Agreement_Registry>;
  /** fetch aggregated fields from the table: "agreement_registry" */
  agreement_registry_aggregate: Agreement_Registry_Aggregate;
  /** fetch data from the table: "agreement_registry" using primary key columns */
  agreement_registry_by_pk?: Maybe<Agreement_Registry>;
  /** fetch data from the table in a streaming manner: "agreement_registry" */
  agreement_registry_stream: Array<Agreement_Registry>;
  /** fetch data from the table: "agreement_tenant" */
  agreement_tenant: Array<Agreement_Tenant>;
  /** fetch aggregated fields from the table: "agreement_tenant" */
  agreement_tenant_aggregate: Agreement_Tenant_Aggregate;
  /** fetch data from the table: "agreement_tenant" using primary key columns */
  agreement_tenant_by_pk?: Maybe<Agreement_Tenant>;
  /** fetch data from the table in a streaming manner: "agreement_tenant" */
  agreement_tenant_stream: Array<Agreement_Tenant>;
  /** fetch data from the table: "application_lifecycle_stage" */
  application_lifecycle_stage: Array<Application_Lifecycle_Stage>;
  /** fetch aggregated fields from the table: "application_lifecycle_stage" */
  application_lifecycle_stage_aggregate: Application_Lifecycle_Stage_Aggregate;
  /** fetch data from the table: "application_lifecycle_stage" using primary key columns */
  application_lifecycle_stage_by_pk?: Maybe<Application_Lifecycle_Stage>;
  /** fetch data from the table in a streaming manner: "application_lifecycle_stage" */
  application_lifecycle_stage_stream: Array<Application_Lifecycle_Stage>;
  /** fetch data from the table: "banking_partner_integration" */
  banking_partner_integration: Array<Banking_Partner_Integration>;
  /** fetch aggregated fields from the table: "banking_partner_integration" */
  banking_partner_integration_aggregate: Banking_Partner_Integration_Aggregate;
  /** fetch data from the table in a streaming manner: "banking_partner_integration" */
  banking_partner_integration_stream: Array<Banking_Partner_Integration>;
  /** fetch data from the table: "bid" */
  bid: Array<Bid>;
  /** fetch aggregated fields from the table: "bid" */
  bid_aggregate: Bid_Aggregate;
  /** fetch data from the table: "bid" using primary key columns */
  bid_by_pk?: Maybe<Bid>;
  /** fetch data from the table in a streaming manner: "bid" */
  bid_stream: Array<Bid>;
  /** fetch data from the table: "blacklisted_tokens" */
  blacklisted_tokens: Array<Blacklisted_Tokens>;
  /** fetch aggregated fields from the table: "blacklisted_tokens" */
  blacklisted_tokens_aggregate: Blacklisted_Tokens_Aggregate;
  /** fetch data from the table: "blacklisted_tokens" using primary key columns */
  blacklisted_tokens_by_pk?: Maybe<Blacklisted_Tokens>;
  /** fetch data from the table in a streaming manner: "blacklisted_tokens" */
  blacklisted_tokens_stream: Array<Blacklisted_Tokens>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company_agreement_logs_view" */
  company_agreement_logs_view: Array<Company_Agreement_Logs_View>;
  /** fetch aggregated fields from the table: "company_agreement_logs_view" */
  company_agreement_logs_view_aggregate: Company_Agreement_Logs_View_Aggregate;
  /** fetch data from the table in a streaming manner: "company_agreement_logs_view" */
  company_agreement_logs_view_stream: Array<Company_Agreement_Logs_View>;
  /** fetch data from the table: "company_application_crime_details" */
  company_application_crime_details: Array<Company_Application_Crime_Details>;
  /** fetch aggregated fields from the table: "company_application_crime_details" */
  company_application_crime_details_aggregate: Company_Application_Crime_Details_Aggregate;
  /** fetch data from the table: "company_application_crime_details" using primary key columns */
  company_application_crime_details_by_pk?: Maybe<Company_Application_Crime_Details>;
  /** fetch data from the table in a streaming manner: "company_application_crime_details" */
  company_application_crime_details_stream: Array<Company_Application_Crime_Details>;
  /** fetch data from the table: "company_application_epfo" */
  company_application_epfo: Array<Company_Application_Epfo>;
  /** fetch aggregated fields from the table: "company_application_epfo" */
  company_application_epfo_aggregate: Company_Application_Epfo_Aggregate;
  /** fetch data from the table: "company_application_epfo" using primary key columns */
  company_application_epfo_by_pk?: Maybe<Company_Application_Epfo>;
  /** fetch data from the table in a streaming manner: "company_application_epfo" */
  company_application_epfo_stream: Array<Company_Application_Epfo>;
  /** fetch data from the table: "company_authorized_signatory" */
  company_authorized_signatory: Array<Company_Authorized_Signatory>;
  /** fetch aggregated fields from the table: "company_authorized_signatory" */
  company_authorized_signatory_aggregate: Company_Authorized_Signatory_Aggregate;
  /** fetch data from the table: "company_authorized_signatory" using primary key columns */
  company_authorized_signatory_by_pk?: Maybe<Company_Authorized_Signatory>;
  /** fetch data from the table in a streaming manner: "company_authorized_signatory" */
  company_authorized_signatory_stream: Array<Company_Authorized_Signatory>;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table: "company_cin" */
  company_cin: Array<Company_Cin>;
  /** fetch aggregated fields from the table: "company_cin" */
  company_cin_aggregate: Company_Cin_Aggregate;
  /** fetch data from the table: "company_cin" using primary key columns */
  company_cin_by_pk?: Maybe<Company_Cin>;
  /** fetch data from the table in a streaming manner: "company_cin" */
  company_cin_stream: Array<Company_Cin>;
  /** fetch data from the table: "company_email_log" */
  company_email_log: Array<Company_Email_Log>;
  /** fetch aggregated fields from the table: "company_email_log" */
  company_email_log_aggregate: Company_Email_Log_Aggregate;
  /** fetch data from the table: "company_email_log" using primary key columns */
  company_email_log_by_pk?: Maybe<Company_Email_Log>;
  /** fetch data from the table in a streaming manner: "company_email_log" */
  company_email_log_stream: Array<Company_Email_Log>;
  /** fetch data from the table: "company_gst_return" */
  company_gst_return: Array<Company_Gst_Return>;
  /** fetch aggregated fields from the table: "company_gst_return" */
  company_gst_return_aggregate: Company_Gst_Return_Aggregate;
  /** fetch data from the table: "company_gst_return" using primary key columns */
  company_gst_return_by_pk?: Maybe<Company_Gst_Return>;
  /** fetch data from the table in a streaming manner: "company_gst_return" */
  company_gst_return_stream: Array<Company_Gst_Return>;
  /** fetch data from the table: "company_integration" */
  company_integration: Array<Company_Integration>;
  /** fetch aggregated fields from the table: "company_integration" */
  company_integration_aggregate: Company_Integration_Aggregate;
  /** fetch data from the table: "company_integration" using primary key columns */
  company_integration_by_pk?: Maybe<Company_Integration>;
  /** fetch data from the table: "company_integration_file" */
  company_integration_file: Array<Company_Integration_File>;
  /** fetch aggregated fields from the table: "company_integration_file" */
  company_integration_file_aggregate: Company_Integration_File_Aggregate;
  /** fetch data from the table: "company_integration_file" using primary key columns */
  company_integration_file_by_pk?: Maybe<Company_Integration_File>;
  /** fetch data from the table in a streaming manner: "company_integration_file" */
  company_integration_file_stream: Array<Company_Integration_File>;
  /** fetch data from the table in a streaming manner: "company_integration" */
  company_integration_stream: Array<Company_Integration>;
  /** fetch data from the table: "company_lead_status" */
  company_lead_status: Array<Company_Lead_Status>;
  /** fetch aggregated fields from the table: "company_lead_status" */
  company_lead_status_aggregate: Company_Lead_Status_Aggregate;
  /** fetch data from the table: "company_lead_status" using primary key columns */
  company_lead_status_by_pk?: Maybe<Company_Lead_Status>;
  /** fetch data from the table in a streaming manner: "company_lead_status" */
  company_lead_status_stream: Array<Company_Lead_Status>;
  /** fetch data from the table: "company_reports" */
  company_reports: Array<Company_Reports>;
  /** fetch aggregated fields from the table: "company_reports" */
  company_reports_aggregate: Company_Reports_Aggregate;
  /** fetch data from the table: "company_reports" using primary key columns */
  company_reports_by_pk?: Maybe<Company_Reports>;
  /** fetch data from the table in a streaming manner: "company_reports" */
  company_reports_stream: Array<Company_Reports>;
  /** fetch data from the table: "company_score" */
  company_score: Array<Company_Score>;
  /** fetch aggregated fields from the table: "company_score" */
  company_score_aggregate: Company_Score_Aggregate;
  /** fetch data from the table: "company_score" using primary key columns */
  company_score_by_pk?: Maybe<Company_Score>;
  /** fetch data from the table: "company_score_factor" */
  company_score_factor: Array<Company_Score_Factor>;
  /** fetch aggregated fields from the table: "company_score_factor" */
  company_score_factor_aggregate: Company_Score_Factor_Aggregate;
  /** fetch data from the table: "company_score_factor" using primary key columns */
  company_score_factor_by_pk?: Maybe<Company_Score_Factor>;
  /** fetch data from the table in a streaming manner: "company_score_factor" */
  company_score_factor_stream: Array<Company_Score_Factor>;
  /** fetch data from the table in a streaming manner: "company_score" */
  company_score_stream: Array<Company_Score>;
  /** An array relationship */
  company_score_time_series: Array<Company_Score_Time_Series>;
  /** An aggregate relationship */
  company_score_time_series_aggregate: Company_Score_Time_Series_Aggregate;
  /** fetch data from the table: "company_score_time_series" using primary key columns */
  company_score_time_series_by_pk?: Maybe<Company_Score_Time_Series>;
  /** fetch data from the table in a streaming manner: "company_score_time_series" */
  company_score_time_series_stream: Array<Company_Score_Time_Series>;
  /** fetch data from the table: "company_statement" */
  company_statement: Array<Company_Statement>;
  /** fetch aggregated fields from the table: "company_statement" */
  company_statement_aggregate: Company_Statement_Aggregate;
  /** fetch data from the table: "company_statement" using primary key columns */
  company_statement_by_pk?: Maybe<Company_Statement>;
  /** fetch data from the table in a streaming manner: "company_statement" */
  company_statement_stream: Array<Company_Statement>;
  /** fetch data from the table in a streaming manner: "company" */
  company_stream: Array<Company>;
  /** fetch data from the table: "company_tan" */
  company_tan: Array<Company_Tan>;
  /** fetch aggregated fields from the table: "company_tan" */
  company_tan_aggregate: Company_Tan_Aggregate;
  /** fetch data from the table: "company_tan" using primary key columns */
  company_tan_by_pk?: Maybe<Company_Tan>;
  /** fetch data from the table in a streaming manner: "company_tan" */
  company_tan_stream: Array<Company_Tan>;
  /** fetch data from the table: "company_type_factor_benchmark" */
  company_type_factor_benchmark: Array<Company_Type_Factor_Benchmark>;
  /** fetch aggregated fields from the table: "company_type_factor_benchmark" */
  company_type_factor_benchmark_aggregate: Company_Type_Factor_Benchmark_Aggregate;
  /** fetch data from the table: "company_type_factor_benchmark" using primary key columns */
  company_type_factor_benchmark_by_pk?: Maybe<Company_Type_Factor_Benchmark>;
  /** fetch data from the table in a streaming manner: "company_type_factor_benchmark" */
  company_type_factor_benchmark_stream: Array<Company_Type_Factor_Benchmark>;
  /** fetch data from the table: "company_user" */
  company_user: Array<Company_User>;
  /** fetch aggregated fields from the table: "company_user" */
  company_user_aggregate: Company_User_Aggregate;
  /** fetch data from the table: "company_user" using primary key columns */
  company_user_by_pk?: Maybe<Company_User>;
  /** fetch data from the table in a streaming manner: "company_user" */
  company_user_stream: Array<Company_User>;
  /** fetch data from the table: "deal" */
  deal: Array<Deal>;
  /** fetch aggregated fields from the table: "deal" */
  deal_aggregate: Deal_Aggregate;
  /** fetch data from the table: "deal" using primary key columns */
  deal_by_pk?: Maybe<Deal>;
  /** fetch data from the table in a streaming manner: "deal" */
  deal_stream: Array<Deal>;
  /** fetch data from the table: "document_checklist" */
  document_checklist: Array<Document_Checklist>;
  /** fetch aggregated fields from the table: "document_checklist" */
  document_checklist_aggregate: Document_Checklist_Aggregate;
  /** fetch data from the table: "document_checklist" using primary key columns */
  document_checklist_by_pk?: Maybe<Document_Checklist>;
  /** fetch data from the table: "document_checklist_category" */
  document_checklist_category: Array<Document_Checklist_Category>;
  /** fetch aggregated fields from the table: "document_checklist_category" */
  document_checklist_category_aggregate: Document_Checklist_Category_Aggregate;
  /** fetch data from the table: "document_checklist_category" using primary key columns */
  document_checklist_category_by_pk?: Maybe<Document_Checklist_Category>;
  /** fetch data from the table in a streaming manner: "document_checklist_category" */
  document_checklist_category_stream: Array<Document_Checklist_Category>;
  /** fetch data from the table in a streaming manner: "document_checklist" */
  document_checklist_stream: Array<Document_Checklist>;
  /** fetch data from the table: "email_events" */
  email_events: Array<Email_Events>;
  /** fetch aggregated fields from the table: "email_events" */
  email_events_aggregate: Email_Events_Aggregate;
  /** fetch data from the table: "email_events" using primary key columns */
  email_events_by_pk?: Maybe<Email_Events>;
  /** fetch data from the table in a streaming manner: "email_events" */
  email_events_stream: Array<Email_Events>;
  /** An array relationship */
  emi_tds: Array<Emi_Tds>;
  /** An aggregate relationship */
  emi_tds_aggregate: Emi_Tds_Aggregate;
  /** fetch data from the table: "emi_tds" using primary key columns */
  emi_tds_by_pk?: Maybe<Emi_Tds>;
  /** fetch data from the table in a streaming manner: "emi_tds" */
  emi_tds_stream: Array<Emi_Tds>;
  /** fetch data from the table: "factor_benchmark_range" */
  factor_benchmark_range: Array<Factor_Benchmark_Range>;
  /** fetch aggregated fields from the table: "factor_benchmark_range" */
  factor_benchmark_range_aggregate: Factor_Benchmark_Range_Aggregate;
  /** fetch data from the table: "factor_benchmark_range" using primary key columns */
  factor_benchmark_range_by_pk?: Maybe<Factor_Benchmark_Range>;
  /** fetch data from the table in a streaming manner: "factor_benchmark_range" */
  factor_benchmark_range_stream: Array<Factor_Benchmark_Range>;
  /** fetch data from the table: "file_registry" */
  file_registry: Array<File_Registry>;
  /** fetch aggregated fields from the table: "file_registry" */
  file_registry_aggregate: File_Registry_Aggregate;
  /** fetch data from the table: "file_registry" using primary key columns */
  file_registry_by_pk?: Maybe<File_Registry>;
  /** fetch data from the table in a streaming manner: "file_registry" */
  file_registry_stream: Array<File_Registry>;
  /** fetch data from the table: "integration_auth" */
  integration_auth: Array<Integration_Auth>;
  /** fetch aggregated fields from the table: "integration_auth" */
  integration_auth_aggregate: Integration_Auth_Aggregate;
  /** fetch data from the table: "integration_auth" using primary key columns */
  integration_auth_by_pk?: Maybe<Integration_Auth>;
  /** fetch data from the table in a streaming manner: "integration_auth" */
  integration_auth_stream: Array<Integration_Auth>;
  /** fetch data from the table: "internal_statement" */
  internal_statement: Array<Internal_Statement>;
  /** fetch aggregated fields from the table: "internal_statement" */
  internal_statement_aggregate: Internal_Statement_Aggregate;
  /** fetch data from the table: "internal_statement" using primary key columns */
  internal_statement_by_pk?: Maybe<Internal_Statement>;
  /** fetch data from the table in a streaming manner: "internal_statement" */
  internal_statement_stream: Array<Internal_Statement>;
  /** fetch data from the table: "investment" */
  investment: Array<Investment>;
  /** fetch aggregated fields from the table: "investment" */
  investment_aggregate: Investment_Aggregate;
  /** An array relationship */
  investment_amortization: Array<Investment_Amortization>;
  /** An aggregate relationship */
  investment_amortization_aggregate: Investment_Amortization_Aggregate;
  /** fetch data from the table in a streaming manner: "investment_amortization" */
  investment_amortization_stream: Array<Investment_Amortization>;
  /** fetch data from the table: "investment" using primary key columns */
  investment_by_pk?: Maybe<Investment>;
  /** fetch data from the table in a streaming manner: "investment" */
  investment_stream: Array<Investment>;
  /** fetch data from the table: "investor" */
  investor: Array<Investor>;
  /** fetch aggregated fields from the table: "investor" */
  investor_aggregate: Investor_Aggregate;
  /** fetch data from the table: "investor" using primary key columns */
  investor_by_pk?: Maybe<Investor>;
  /** fetch data from the table: "investor_preference" */
  investor_preference: Array<Investor_Preference>;
  /** fetch aggregated fields from the table: "investor_preference" */
  investor_preference_aggregate: Investor_Preference_Aggregate;
  /** fetch data from the table: "investor_preference" using primary key columns */
  investor_preference_by_pk?: Maybe<Investor_Preference>;
  /** fetch data from the table in a streaming manner: "investor_preference" */
  investor_preference_stream: Array<Investor_Preference>;
  /** fetch data from the table: "investor_statement" */
  investor_statement: Array<Investor_Statement>;
  /** fetch aggregated fields from the table: "investor_statement" */
  investor_statement_aggregate: Investor_Statement_Aggregate;
  /** fetch data from the table: "investor_statement" using primary key columns */
  investor_statement_by_pk?: Maybe<Investor_Statement>;
  /** fetch data from the table in a streaming manner: "investor_statement" */
  investor_statement_stream: Array<Investor_Statement>;
  /** fetch data from the table in a streaming manner: "investor" */
  investor_stream: Array<Investor>;
  /** fetch data from the table: "investor_transaction" */
  investor_transaction: Array<Investor_Transaction>;
  /** fetch aggregated fields from the table: "investor_transaction" */
  investor_transaction_aggregate: Investor_Transaction_Aggregate;
  /** fetch data from the table: "investor_transaction" using primary key columns */
  investor_transaction_by_pk?: Maybe<Investor_Transaction>;
  /** fetch data from the table in a streaming manner: "investor_transaction" */
  investor_transaction_stream: Array<Investor_Transaction>;
  /** execute function "investor_transaction_view" which returns "investor_transaction" */
  investor_transaction_view: Array<Investor_Transaction>;
  /** execute function "investor_transaction_view" and query aggregates on result of table type "investor_transaction" */
  investor_transaction_view_aggregate: Investor_Transaction_Aggregate;
  /** fetch data from the table: "investor_user" */
  investor_user: Array<Investor_User>;
  /** fetch aggregated fields from the table: "investor_user" */
  investor_user_aggregate: Investor_User_Aggregate;
  /** fetch data from the table: "investor_user" using primary key columns */
  investor_user_by_pk?: Maybe<Investor_User>;
  /** fetch data from the table in a streaming manner: "investor_user" */
  investor_user_stream: Array<Investor_User>;
  /** fetch data from the table: "lifecycle_question_crm_mapper" */
  lifecycle_question_crm_mapper: Array<Lifecycle_Question_Crm_Mapper>;
  /** fetch aggregated fields from the table: "lifecycle_question_crm_mapper" */
  lifecycle_question_crm_mapper_aggregate: Lifecycle_Question_Crm_Mapper_Aggregate;
  /** fetch data from the table: "lifecycle_question_crm_mapper" using primary key columns */
  lifecycle_question_crm_mapper_by_pk?: Maybe<Lifecycle_Question_Crm_Mapper>;
  /** fetch data from the table in a streaming manner: "lifecycle_question_crm_mapper" */
  lifecycle_question_crm_mapper_stream: Array<Lifecycle_Question_Crm_Mapper>;
  /** fetch data from the table: "lifecycle_question_result" */
  lifecycle_question_result: Array<Lifecycle_Question_Result>;
  /** fetch aggregated fields from the table: "lifecycle_question_result" */
  lifecycle_question_result_aggregate: Lifecycle_Question_Result_Aggregate;
  /** fetch data from the table: "lifecycle_question_result" using primary key columns */
  lifecycle_question_result_by_pk?: Maybe<Lifecycle_Question_Result>;
  /** fetch data from the table in a streaming manner: "lifecycle_question_result" */
  lifecycle_question_result_stream: Array<Lifecycle_Question_Result>;
  /** fetch data from the table: "mandate" */
  mandate: Array<Mandate>;
  /** fetch aggregated fields from the table: "mandate" */
  mandate_aggregate: Mandate_Aggregate;
  /** fetch data from the table: "mandate" using primary key columns */
  mandate_by_pk?: Maybe<Mandate>;
  /** fetch data from the table in a streaming manner: "mandate" */
  mandate_stream: Array<Mandate>;
  /** fetch data from the table: "popular_banks" */
  popular_banks: Array<Popular_Banks>;
  /** fetch aggregated fields from the table: "popular_banks" */
  popular_banks_aggregate: Popular_Banks_Aggregate;
  /** fetch data from the table: "popular_banks" using primary key columns */
  popular_banks_by_pk?: Maybe<Popular_Banks>;
  /** fetch data from the table in a streaming manner: "popular_banks" */
  popular_banks_stream: Array<Popular_Banks>;
  /** fetch data from the table: "ref_account_status" */
  ref_account_status: Array<Ref_Account_Status>;
  /** fetch aggregated fields from the table: "ref_account_status" */
  ref_account_status_aggregate: Ref_Account_Status_Aggregate;
  /** fetch data from the table: "ref_account_status" using primary key columns */
  ref_account_status_by_pk?: Maybe<Ref_Account_Status>;
  /** fetch data from the table in a streaming manner: "ref_account_status" */
  ref_account_status_stream: Array<Ref_Account_Status>;
  /** fetch data from the table: "ref_account_type" */
  ref_account_type: Array<Ref_Account_Type>;
  /** fetch aggregated fields from the table: "ref_account_type" */
  ref_account_type_aggregate: Ref_Account_Type_Aggregate;
  /** fetch data from the table: "ref_account_type" using primary key columns */
  ref_account_type_by_pk?: Maybe<Ref_Account_Type>;
  /** fetch data from the table in a streaming manner: "ref_account_type" */
  ref_account_type_stream: Array<Ref_Account_Type>;
  /** fetch data from the table: "ref_agreement_category" */
  ref_agreement_category: Array<Ref_Agreement_Category>;
  /** fetch aggregated fields from the table: "ref_agreement_category" */
  ref_agreement_category_aggregate: Ref_Agreement_Category_Aggregate;
  /** fetch data from the table: "ref_agreement_category" using primary key columns */
  ref_agreement_category_by_pk?: Maybe<Ref_Agreement_Category>;
  /** fetch data from the table in a streaming manner: "ref_agreement_category" */
  ref_agreement_category_stream: Array<Ref_Agreement_Category>;
  /** fetch data from the table: "ref_agreement_status" */
  ref_agreement_status: Array<Ref_Agreement_Status>;
  /** fetch aggregated fields from the table: "ref_agreement_status" */
  ref_agreement_status_aggregate: Ref_Agreement_Status_Aggregate;
  /** fetch data from the table: "ref_agreement_status" using primary key columns */
  ref_agreement_status_by_pk?: Maybe<Ref_Agreement_Status>;
  /** fetch data from the table in a streaming manner: "ref_agreement_status" */
  ref_agreement_status_stream: Array<Ref_Agreement_Status>;
  /** fetch data from the table: "ref_application_allowed_district" */
  ref_application_allowed_district: Array<Ref_Application_Allowed_District>;
  /** fetch aggregated fields from the table: "ref_application_allowed_district" */
  ref_application_allowed_district_aggregate: Ref_Application_Allowed_District_Aggregate;
  /** fetch data from the table: "ref_application_allowed_district" using primary key columns */
  ref_application_allowed_district_by_pk?: Maybe<Ref_Application_Allowed_District>;
  /** fetch data from the table in a streaming manner: "ref_application_allowed_district" */
  ref_application_allowed_district_stream: Array<Ref_Application_Allowed_District>;
  /** fetch data from the table: "ref_application_lifecycle_stage" */
  ref_application_lifecycle_stage: Array<Ref_Application_Lifecycle_Stage>;
  /** fetch aggregated fields from the table: "ref_application_lifecycle_stage" */
  ref_application_lifecycle_stage_aggregate: Ref_Application_Lifecycle_Stage_Aggregate;
  /** fetch data from the table: "ref_application_lifecycle_stage" using primary key columns */
  ref_application_lifecycle_stage_by_pk?: Maybe<Ref_Application_Lifecycle_Stage>;
  /** fetch data from the table in a streaming manner: "ref_application_lifecycle_stage" */
  ref_application_lifecycle_stage_stream: Array<Ref_Application_Lifecycle_Stage>;
  /** fetch data from the table: "ref_bank" */
  ref_bank: Array<Ref_Bank>;
  /** fetch aggregated fields from the table: "ref_bank" */
  ref_bank_aggregate: Ref_Bank_Aggregate;
  /** fetch data from the table: "ref_bank" using primary key columns */
  ref_bank_by_pk?: Maybe<Ref_Bank>;
  /** fetch data from the table: "ref_bank_list" */
  ref_bank_list: Array<Ref_Bank_List>;
  /** fetch aggregated fields from the table: "ref_bank_list" */
  ref_bank_list_aggregate: Ref_Bank_List_Aggregate;
  /** fetch data from the table: "ref_bank_list" using primary key columns */
  ref_bank_list_by_pk?: Maybe<Ref_Bank_List>;
  /** fetch data from the table in a streaming manner: "ref_bank_list" */
  ref_bank_list_stream: Array<Ref_Bank_List>;
  /** fetch data from the table in a streaming manner: "ref_bank" */
  ref_bank_stream: Array<Ref_Bank>;
  /** fetch data from the table: "ref_bid" */
  ref_bid: Array<Ref_Bid>;
  /** fetch aggregated fields from the table: "ref_bid" */
  ref_bid_aggregate: Ref_Bid_Aggregate;
  /** fetch data from the table: "ref_bid" using primary key columns */
  ref_bid_by_pk?: Maybe<Ref_Bid>;
  /** fetch data from the table in a streaming manner: "ref_bid" */
  ref_bid_stream: Array<Ref_Bid>;
  /** fetch data from the table: "ref_billing_period" */
  ref_billing_period: Array<Ref_Billing_Period>;
  /** fetch aggregated fields from the table: "ref_billing_period" */
  ref_billing_period_aggregate: Ref_Billing_Period_Aggregate;
  /** fetch data from the table: "ref_billing_period" using primary key columns */
  ref_billing_period_by_pk?: Maybe<Ref_Billing_Period>;
  /** fetch data from the table in a streaming manner: "ref_billing_period" */
  ref_billing_period_stream: Array<Ref_Billing_Period>;
  /** fetch data from the table: "ref_category" */
  ref_category: Array<Ref_Category>;
  /** fetch aggregated fields from the table: "ref_category" */
  ref_category_aggregate: Ref_Category_Aggregate;
  /** fetch data from the table: "ref_category" using primary key columns */
  ref_category_by_pk?: Maybe<Ref_Category>;
  /** fetch data from the table in a streaming manner: "ref_category" */
  ref_category_stream: Array<Ref_Category>;
  /** fetch data from the table: "ref_collection_mode" */
  ref_collection_mode: Array<Ref_Collection_Mode>;
  /** fetch aggregated fields from the table: "ref_collection_mode" */
  ref_collection_mode_aggregate: Ref_Collection_Mode_Aggregate;
  /** fetch data from the table: "ref_collection_mode" using primary key columns */
  ref_collection_mode_by_pk?: Maybe<Ref_Collection_Mode>;
  /** fetch data from the table in a streaming manner: "ref_collection_mode" */
  ref_collection_mode_stream: Array<Ref_Collection_Mode>;
  /** fetch data from the table: "ref_company_lead_status" */
  ref_company_lead_status: Array<Ref_Company_Lead_Status>;
  /** fetch aggregated fields from the table: "ref_company_lead_status" */
  ref_company_lead_status_aggregate: Ref_Company_Lead_Status_Aggregate;
  /** fetch data from the table: "ref_company_lead_status" using primary key columns */
  ref_company_lead_status_by_pk?: Maybe<Ref_Company_Lead_Status>;
  /** fetch data from the table in a streaming manner: "ref_company_lead_status" */
  ref_company_lead_status_stream: Array<Ref_Company_Lead_Status>;
  /** fetch data from the table: "ref_company_likeliness" */
  ref_company_likeliness: Array<Ref_Company_Likeliness>;
  /** fetch aggregated fields from the table: "ref_company_likeliness" */
  ref_company_likeliness_aggregate: Ref_Company_Likeliness_Aggregate;
  /** fetch data from the table: "ref_company_likeliness" using primary key columns */
  ref_company_likeliness_by_pk?: Maybe<Ref_Company_Likeliness>;
  /** fetch data from the table in a streaming manner: "ref_company_likeliness" */
  ref_company_likeliness_stream: Array<Ref_Company_Likeliness>;
  /** fetch data from the table: "ref_company_status" */
  ref_company_status: Array<Ref_Company_Status>;
  /** fetch aggregated fields from the table: "ref_company_status" */
  ref_company_status_aggregate: Ref_Company_Status_Aggregate;
  /** fetch data from the table: "ref_company_status" using primary key columns */
  ref_company_status_by_pk?: Maybe<Ref_Company_Status>;
  /** fetch data from the table in a streaming manner: "ref_company_status" */
  ref_company_status_stream: Array<Ref_Company_Status>;
  /** fetch data from the table: "ref_company_type" */
  ref_company_type: Array<Ref_Company_Type>;
  /** fetch aggregated fields from the table: "ref_company_type" */
  ref_company_type_aggregate: Ref_Company_Type_Aggregate;
  /** fetch data from the table: "ref_company_type" using primary key columns */
  ref_company_type_by_pk?: Maybe<Ref_Company_Type>;
  /** fetch data from the table in a streaming manner: "ref_company_type" */
  ref_company_type_stream: Array<Ref_Company_Type>;
  /** fetch data from the table: "ref_country" */
  ref_country: Array<Ref_Country>;
  /** fetch aggregated fields from the table: "ref_country" */
  ref_country_aggregate: Ref_Country_Aggregate;
  /** fetch data from the table: "ref_country" using primary key columns */
  ref_country_by_pk?: Maybe<Ref_Country>;
  /** fetch data from the table in a streaming manner: "ref_country" */
  ref_country_stream: Array<Ref_Country>;
  /** fetch data from the table: "ref_currency" */
  ref_currency: Array<Ref_Currency>;
  /** fetch aggregated fields from the table: "ref_currency" */
  ref_currency_aggregate: Ref_Currency_Aggregate;
  /** fetch data from the table: "ref_currency" using primary key columns */
  ref_currency_by_pk?: Maybe<Ref_Currency>;
  /** fetch data from the table in a streaming manner: "ref_currency" */
  ref_currency_stream: Array<Ref_Currency>;
  /** fetch data from the table: "ref_debit_mode" */
  ref_debit_mode: Array<Ref_Debit_Mode>;
  /** fetch aggregated fields from the table: "ref_debit_mode" */
  ref_debit_mode_aggregate: Ref_Debit_Mode_Aggregate;
  /** fetch data from the table: "ref_debit_mode" using primary key columns */
  ref_debit_mode_by_pk?: Maybe<Ref_Debit_Mode>;
  /** fetch data from the table in a streaming manner: "ref_debit_mode" */
  ref_debit_mode_stream: Array<Ref_Debit_Mode>;
  /** fetch data from the table: "ref_document_checklist" */
  ref_document_checklist: Array<Ref_Document_Checklist>;
  /** fetch aggregated fields from the table: "ref_document_checklist" */
  ref_document_checklist_aggregate: Ref_Document_Checklist_Aggregate;
  /** fetch data from the table: "ref_document_checklist" using primary key columns */
  ref_document_checklist_by_pk?: Maybe<Ref_Document_Checklist>;
  /** fetch data from the table: "ref_document_checklist_category" */
  ref_document_checklist_category: Array<Ref_Document_Checklist_Category>;
  /** fetch aggregated fields from the table: "ref_document_checklist_category" */
  ref_document_checklist_category_aggregate: Ref_Document_Checklist_Category_Aggregate;
  /** fetch data from the table: "ref_document_checklist_category" using primary key columns */
  ref_document_checklist_category_by_pk?: Maybe<Ref_Document_Checklist_Category>;
  /** fetch data from the table in a streaming manner: "ref_document_checklist_category" */
  ref_document_checklist_category_stream: Array<Ref_Document_Checklist_Category>;
  /** fetch data from the table: "ref_document_checklist_integration" */
  ref_document_checklist_integration: Array<Ref_Document_Checklist_Integration>;
  /** fetch aggregated fields from the table: "ref_document_checklist_integration" */
  ref_document_checklist_integration_aggregate: Ref_Document_Checklist_Integration_Aggregate;
  /** fetch data from the table: "ref_document_checklist_integration" using primary key columns */
  ref_document_checklist_integration_by_pk?: Maybe<Ref_Document_Checklist_Integration>;
  /** fetch data from the table in a streaming manner: "ref_document_checklist_integration" */
  ref_document_checklist_integration_stream: Array<Ref_Document_Checklist_Integration>;
  /** fetch data from the table: "ref_document_checklist_status" */
  ref_document_checklist_status: Array<Ref_Document_Checklist_Status>;
  /** fetch aggregated fields from the table: "ref_document_checklist_status" */
  ref_document_checklist_status_aggregate: Ref_Document_Checklist_Status_Aggregate;
  /** fetch data from the table: "ref_document_checklist_status" using primary key columns */
  ref_document_checklist_status_by_pk?: Maybe<Ref_Document_Checklist_Status>;
  /** fetch data from the table in a streaming manner: "ref_document_checklist_status" */
  ref_document_checklist_status_stream: Array<Ref_Document_Checklist_Status>;
  /** fetch data from the table in a streaming manner: "ref_document_checklist" */
  ref_document_checklist_stream: Array<Ref_Document_Checklist>;
  /** fetch data from the table: "ref_email_events_type" */
  ref_email_events_type: Array<Ref_Email_Events_Type>;
  /** fetch aggregated fields from the table: "ref_email_events_type" */
  ref_email_events_type_aggregate: Ref_Email_Events_Type_Aggregate;
  /** fetch data from the table: "ref_email_events_type" using primary key columns */
  ref_email_events_type_by_pk?: Maybe<Ref_Email_Events_Type>;
  /** fetch data from the table in a streaming manner: "ref_email_events_type" */
  ref_email_events_type_stream: Array<Ref_Email_Events_Type>;
  /** fetch data from the table: "ref_file_category" */
  ref_file_category: Array<Ref_File_Category>;
  /** fetch aggregated fields from the table: "ref_file_category" */
  ref_file_category_aggregate: Ref_File_Category_Aggregate;
  /** fetch data from the table: "ref_file_category" using primary key columns */
  ref_file_category_by_pk?: Maybe<Ref_File_Category>;
  /** fetch data from the table: "ref_file_category_mimetype" */
  ref_file_category_mimetype: Array<Ref_File_Category_Mimetype>;
  /** fetch aggregated fields from the table: "ref_file_category_mimetype" */
  ref_file_category_mimetype_aggregate: Ref_File_Category_Mimetype_Aggregate;
  /** fetch data from the table: "ref_file_category_mimetype" using primary key columns */
  ref_file_category_mimetype_by_pk?: Maybe<Ref_File_Category_Mimetype>;
  /** fetch data from the table in a streaming manner: "ref_file_category_mimetype" */
  ref_file_category_mimetype_stream: Array<Ref_File_Category_Mimetype>;
  /** fetch data from the table in a streaming manner: "ref_file_category" */
  ref_file_category_stream: Array<Ref_File_Category>;
  /** fetch data from the table: "ref_industry" */
  ref_industry: Array<Ref_Industry>;
  /** fetch aggregated fields from the table: "ref_industry" */
  ref_industry_aggregate: Ref_Industry_Aggregate;
  /** fetch data from the table: "ref_industry" using primary key columns */
  ref_industry_by_pk?: Maybe<Ref_Industry>;
  /** fetch data from the table in a streaming manner: "ref_industry" */
  ref_industry_stream: Array<Ref_Industry>;
  /** fetch data from the table: "ref_integration_file_status" */
  ref_integration_file_status: Array<Ref_Integration_File_Status>;
  /** fetch aggregated fields from the table: "ref_integration_file_status" */
  ref_integration_file_status_aggregate: Ref_Integration_File_Status_Aggregate;
  /** fetch data from the table: "ref_integration_file_status" using primary key columns */
  ref_integration_file_status_by_pk?: Maybe<Ref_Integration_File_Status>;
  /** fetch data from the table in a streaming manner: "ref_integration_file_status" */
  ref_integration_file_status_stream: Array<Ref_Integration_File_Status>;
  /** fetch data from the table: "ref_integration_status" */
  ref_integration_status: Array<Ref_Integration_Status>;
  /** fetch aggregated fields from the table: "ref_integration_status" */
  ref_integration_status_aggregate: Ref_Integration_Status_Aggregate;
  /** fetch data from the table: "ref_integration_status" using primary key columns */
  ref_integration_status_by_pk?: Maybe<Ref_Integration_Status>;
  /** fetch data from the table in a streaming manner: "ref_integration_status" */
  ref_integration_status_stream: Array<Ref_Integration_Status>;
  /** fetch data from the table: "ref_investor_status" */
  ref_investor_status: Array<Ref_Investor_Status>;
  /** fetch aggregated fields from the table: "ref_investor_status" */
  ref_investor_status_aggregate: Ref_Investor_Status_Aggregate;
  /** fetch data from the table: "ref_investor_status" using primary key columns */
  ref_investor_status_by_pk?: Maybe<Ref_Investor_Status>;
  /** fetch data from the table in a streaming manner: "ref_investor_status" */
  ref_investor_status_stream: Array<Ref_Investor_Status>;
  /** fetch data from the table: "ref_investor_type" */
  ref_investor_type: Array<Ref_Investor_Type>;
  /** fetch aggregated fields from the table: "ref_investor_type" */
  ref_investor_type_aggregate: Ref_Investor_Type_Aggregate;
  /** fetch data from the table: "ref_investor_type" using primary key columns */
  ref_investor_type_by_pk?: Maybe<Ref_Investor_Type>;
  /** fetch data from the table in a streaming manner: "ref_investor_type" */
  ref_investor_type_stream: Array<Ref_Investor_Type>;
  /** fetch data from the table: "ref_lifecycle_question" */
  ref_lifecycle_question: Array<Ref_Lifecycle_Question>;
  /** fetch aggregated fields from the table: "ref_lifecycle_question" */
  ref_lifecycle_question_aggregate: Ref_Lifecycle_Question_Aggregate;
  /** fetch data from the table: "ref_lifecycle_question" using primary key columns */
  ref_lifecycle_question_by_pk?: Maybe<Ref_Lifecycle_Question>;
  /** fetch data from the table: "ref_lifecycle_question_category" */
  ref_lifecycle_question_category: Array<Ref_Lifecycle_Question_Category>;
  /** fetch aggregated fields from the table: "ref_lifecycle_question_category" */
  ref_lifecycle_question_category_aggregate: Ref_Lifecycle_Question_Category_Aggregate;
  /** fetch data from the table: "ref_lifecycle_question_category" using primary key columns */
  ref_lifecycle_question_category_by_pk?: Maybe<Ref_Lifecycle_Question_Category>;
  /** fetch data from the table in a streaming manner: "ref_lifecycle_question_category" */
  ref_lifecycle_question_category_stream: Array<Ref_Lifecycle_Question_Category>;
  /** fetch data from the table in a streaming manner: "ref_lifecycle_question" */
  ref_lifecycle_question_stream: Array<Ref_Lifecycle_Question>;
  /** fetch data from the table: "ref_mandate_frequency" */
  ref_mandate_frequency: Array<Ref_Mandate_Frequency>;
  /** fetch aggregated fields from the table: "ref_mandate_frequency" */
  ref_mandate_frequency_aggregate: Ref_Mandate_Frequency_Aggregate;
  /** fetch data from the table: "ref_mandate_frequency" using primary key columns */
  ref_mandate_frequency_by_pk?: Maybe<Ref_Mandate_Frequency>;
  /** fetch data from the table in a streaming manner: "ref_mandate_frequency" */
  ref_mandate_frequency_stream: Array<Ref_Mandate_Frequency>;
  /** fetch data from the table: "ref_mandate_status" */
  ref_mandate_status: Array<Ref_Mandate_Status>;
  /** fetch aggregated fields from the table: "ref_mandate_status" */
  ref_mandate_status_aggregate: Ref_Mandate_Status_Aggregate;
  /** fetch data from the table: "ref_mandate_status" using primary key columns */
  ref_mandate_status_by_pk?: Maybe<Ref_Mandate_Status>;
  /** fetch data from the table in a streaming manner: "ref_mandate_status" */
  ref_mandate_status_stream: Array<Ref_Mandate_Status>;
  /** fetch data from the table: "ref_mandate_type" */
  ref_mandate_type: Array<Ref_Mandate_Type>;
  /** fetch aggregated fields from the table: "ref_mandate_type" */
  ref_mandate_type_aggregate: Ref_Mandate_Type_Aggregate;
  /** fetch data from the table: "ref_mandate_type" using primary key columns */
  ref_mandate_type_by_pk?: Maybe<Ref_Mandate_Type>;
  /** fetch data from the table in a streaming manner: "ref_mandate_type" */
  ref_mandate_type_stream: Array<Ref_Mandate_Type>;
  /** fetch data from the table: "ref_perfios_bank_list" */
  ref_perfios_bank_list: Array<Ref_Perfios_Bank_List>;
  /** fetch aggregated fields from the table: "ref_perfios_bank_list" */
  ref_perfios_bank_list_aggregate: Ref_Perfios_Bank_List_Aggregate;
  /** fetch data from the table: "ref_perfios_bank_list" using primary key columns */
  ref_perfios_bank_list_by_pk?: Maybe<Ref_Perfios_Bank_List>;
  /** fetch data from the table in a streaming manner: "ref_perfios_bank_list" */
  ref_perfios_bank_list_stream: Array<Ref_Perfios_Bank_List>;
  /** fetch data from the table: "ref_report_category" */
  ref_report_category: Array<Ref_Report_Category>;
  /** fetch aggregated fields from the table: "ref_report_category" */
  ref_report_category_aggregate: Ref_Report_Category_Aggregate;
  /** fetch data from the table: "ref_report_category" using primary key columns */
  ref_report_category_by_pk?: Maybe<Ref_Report_Category>;
  /** fetch data from the table in a streaming manner: "ref_report_category" */
  ref_report_category_stream: Array<Ref_Report_Category>;
  /** fetch data from the table: "ref_role" */
  ref_role: Array<Ref_Role>;
  /** fetch aggregated fields from the table: "ref_role" */
  ref_role_aggregate: Ref_Role_Aggregate;
  /** fetch data from the table: "ref_role" using primary key columns */
  ref_role_by_pk?: Maybe<Ref_Role>;
  /** fetch data from the table in a streaming manner: "ref_role" */
  ref_role_stream: Array<Ref_Role>;
  /** fetch data from the table: "ref_schedule_checklist_reminder_status" */
  ref_schedule_checklist_reminder_status: Array<Ref_Schedule_Checklist_Reminder_Status>;
  /** fetch aggregated fields from the table: "ref_schedule_checklist_reminder_status" */
  ref_schedule_checklist_reminder_status_aggregate: Ref_Schedule_Checklist_Reminder_Status_Aggregate;
  /** fetch data from the table: "ref_schedule_checklist_reminder_status" using primary key columns */
  ref_schedule_checklist_reminder_status_by_pk?: Maybe<Ref_Schedule_Checklist_Reminder_Status>;
  /** fetch data from the table in a streaming manner: "ref_schedule_checklist_reminder_status" */
  ref_schedule_checklist_reminder_status_stream: Array<Ref_Schedule_Checklist_Reminder_Status>;
  /** fetch data from the table: "ref_score_factor" */
  ref_score_factor: Array<Ref_Score_Factor>;
  /** fetch aggregated fields from the table: "ref_score_factor" */
  ref_score_factor_aggregate: Ref_Score_Factor_Aggregate;
  /** fetch data from the table: "ref_score_factor" using primary key columns */
  ref_score_factor_by_pk?: Maybe<Ref_Score_Factor>;
  /** fetch data from the table: "ref_score_factor_category" */
  ref_score_factor_category: Array<Ref_Score_Factor_Category>;
  /** fetch aggregated fields from the table: "ref_score_factor_category" */
  ref_score_factor_category_aggregate: Ref_Score_Factor_Category_Aggregate;
  /** fetch data from the table: "ref_score_factor_category" using primary key columns */
  ref_score_factor_category_by_pk?: Maybe<Ref_Score_Factor_Category>;
  /** fetch data from the table in a streaming manner: "ref_score_factor_category" */
  ref_score_factor_category_stream: Array<Ref_Score_Factor_Category>;
  /** fetch data from the table in a streaming manner: "ref_score_factor" */
  ref_score_factor_stream: Array<Ref_Score_Factor>;
  /** fetch data from the table: "ref_score_factor_unit" */
  ref_score_factor_unit: Array<Ref_Score_Factor_Unit>;
  /** fetch aggregated fields from the table: "ref_score_factor_unit" */
  ref_score_factor_unit_aggregate: Ref_Score_Factor_Unit_Aggregate;
  /** fetch data from the table: "ref_score_factor_unit" using primary key columns */
  ref_score_factor_unit_by_pk?: Maybe<Ref_Score_Factor_Unit>;
  /** fetch data from the table in a streaming manner: "ref_score_factor_unit" */
  ref_score_factor_unit_stream: Array<Ref_Score_Factor_Unit>;
  /** fetch data from the table: "ref_source" */
  ref_source: Array<Ref_Source>;
  /** fetch aggregated fields from the table: "ref_source" */
  ref_source_aggregate: Ref_Source_Aggregate;
  /** fetch data from the table: "ref_source" using primary key columns */
  ref_source_by_pk?: Maybe<Ref_Source>;
  /** fetch data from the table in a streaming manner: "ref_source" */
  ref_source_stream: Array<Ref_Source>;
  /** An array relationship */
  ref_states: Array<Ref_States>;
  /** An aggregate relationship */
  ref_states_aggregate: Ref_States_Aggregate;
  /** fetch data from the table: "ref_states" using primary key columns */
  ref_states_by_pk?: Maybe<Ref_States>;
  /** fetch data from the table in a streaming manner: "ref_states" */
  ref_states_stream: Array<Ref_States>;
  /** fetch data from the table: "ref_subscription_status" */
  ref_subscription_status: Array<Ref_Subscription_Status>;
  /** fetch aggregated fields from the table: "ref_subscription_status" */
  ref_subscription_status_aggregate: Ref_Subscription_Status_Aggregate;
  /** fetch data from the table: "ref_subscription_status" using primary key columns */
  ref_subscription_status_by_pk?: Maybe<Ref_Subscription_Status>;
  /** fetch data from the table in a streaming manner: "ref_subscription_status" */
  ref_subscription_status_stream: Array<Ref_Subscription_Status>;
  /** fetch data from the table: "ref_system_account" */
  ref_system_account: Array<Ref_System_Account>;
  /** fetch aggregated fields from the table: "ref_system_account" */
  ref_system_account_aggregate: Ref_System_Account_Aggregate;
  /** fetch data from the table: "ref_system_account" using primary key columns */
  ref_system_account_by_pk?: Maybe<Ref_System_Account>;
  /** fetch data from the table: "ref_system_account_status" */
  ref_system_account_status: Array<Ref_System_Account_Status>;
  /** fetch aggregated fields from the table: "ref_system_account_status" */
  ref_system_account_status_aggregate: Ref_System_Account_Status_Aggregate;
  /** fetch data from the table: "ref_system_account_status" using primary key columns */
  ref_system_account_status_by_pk?: Maybe<Ref_System_Account_Status>;
  /** fetch data from the table in a streaming manner: "ref_system_account_status" */
  ref_system_account_status_stream: Array<Ref_System_Account_Status>;
  /** fetch data from the table in a streaming manner: "ref_system_account" */
  ref_system_account_stream: Array<Ref_System_Account>;
  /** fetch data from the table: "ref_system_account_type" */
  ref_system_account_type: Array<Ref_System_Account_Type>;
  /** fetch aggregated fields from the table: "ref_system_account_type" */
  ref_system_account_type_aggregate: Ref_System_Account_Type_Aggregate;
  /** fetch data from the table: "ref_system_account_type" using primary key columns */
  ref_system_account_type_by_pk?: Maybe<Ref_System_Account_Type>;
  /** fetch data from the table in a streaming manner: "ref_system_account_type" */
  ref_system_account_type_stream: Array<Ref_System_Account_Type>;
  /** fetch data from the table: "ref_tds_status" */
  ref_tds_status: Array<Ref_Tds_Status>;
  /** fetch aggregated fields from the table: "ref_tds_status" */
  ref_tds_status_aggregate: Ref_Tds_Status_Aggregate;
  /** fetch data from the table: "ref_tds_status" using primary key columns */
  ref_tds_status_by_pk?: Maybe<Ref_Tds_Status>;
  /** fetch data from the table in a streaming manner: "ref_tds_status" */
  ref_tds_status_stream: Array<Ref_Tds_Status>;
  /** fetch data from the table: "ref_tds_value_unit" */
  ref_tds_value_unit: Array<Ref_Tds_Value_Unit>;
  /** fetch aggregated fields from the table: "ref_tds_value_unit" */
  ref_tds_value_unit_aggregate: Ref_Tds_Value_Unit_Aggregate;
  /** fetch data from the table: "ref_tds_value_unit" using primary key columns */
  ref_tds_value_unit_by_pk?: Maybe<Ref_Tds_Value_Unit>;
  /** fetch data from the table in a streaming manner: "ref_tds_value_unit" */
  ref_tds_value_unit_stream: Array<Ref_Tds_Value_Unit>;
  /** fetch data from the table: "ref_tenant_type" */
  ref_tenant_type: Array<Ref_Tenant_Type>;
  /** fetch aggregated fields from the table: "ref_tenant_type" */
  ref_tenant_type_aggregate: Ref_Tenant_Type_Aggregate;
  /** fetch data from the table: "ref_tenant_type" using primary key columns */
  ref_tenant_type_by_pk?: Maybe<Ref_Tenant_Type>;
  /** fetch data from the table in a streaming manner: "ref_tenant_type" */
  ref_tenant_type_stream: Array<Ref_Tenant_Type>;
  /** fetch data from the table: "ref_trade_state" */
  ref_trade_state: Array<Ref_Trade_State>;
  /** fetch aggregated fields from the table: "ref_trade_state" */
  ref_trade_state_aggregate: Ref_Trade_State_Aggregate;
  /** fetch data from the table: "ref_trade_state" using primary key columns */
  ref_trade_state_by_pk?: Maybe<Ref_Trade_State>;
  /** fetch data from the table in a streaming manner: "ref_trade_state" */
  ref_trade_state_stream: Array<Ref_Trade_State>;
  /** fetch data from the table: "ref_trade_status" */
  ref_trade_status: Array<Ref_Trade_Status>;
  /** fetch aggregated fields from the table: "ref_trade_status" */
  ref_trade_status_aggregate: Ref_Trade_Status_Aggregate;
  /** fetch data from the table: "ref_trade_status" using primary key columns */
  ref_trade_status_by_pk?: Maybe<Ref_Trade_Status>;
  /** fetch data from the table in a streaming manner: "ref_trade_status" */
  ref_trade_status_stream: Array<Ref_Trade_Status>;
  /** fetch data from the table: "ref_trade_withdrawal_mode" */
  ref_trade_withdrawal_mode: Array<Ref_Trade_Withdrawal_Mode>;
  /** fetch aggregated fields from the table: "ref_trade_withdrawal_mode" */
  ref_trade_withdrawal_mode_aggregate: Ref_Trade_Withdrawal_Mode_Aggregate;
  /** fetch data from the table: "ref_trade_withdrawal_mode" using primary key columns */
  ref_trade_withdrawal_mode_by_pk?: Maybe<Ref_Trade_Withdrawal_Mode>;
  /** fetch data from the table in a streaming manner: "ref_trade_withdrawal_mode" */
  ref_trade_withdrawal_mode_stream: Array<Ref_Trade_Withdrawal_Mode>;
  /** fetch data from the table: "ref_transaction_method" */
  ref_transaction_method: Array<Ref_Transaction_Method>;
  /** fetch aggregated fields from the table: "ref_transaction_method" */
  ref_transaction_method_aggregate: Ref_Transaction_Method_Aggregate;
  /** fetch data from the table: "ref_transaction_method" using primary key columns */
  ref_transaction_method_by_pk?: Maybe<Ref_Transaction_Method>;
  /** fetch data from the table in a streaming manner: "ref_transaction_method" */
  ref_transaction_method_stream: Array<Ref_Transaction_Method>;
  /** fetch data from the table: "ref_transaction_status" */
  ref_transaction_status: Array<Ref_Transaction_Status>;
  /** fetch aggregated fields from the table: "ref_transaction_status" */
  ref_transaction_status_aggregate: Ref_Transaction_Status_Aggregate;
  /** fetch data from the table: "ref_transaction_status" using primary key columns */
  ref_transaction_status_by_pk?: Maybe<Ref_Transaction_Status>;
  /** fetch data from the table in a streaming manner: "ref_transaction_status" */
  ref_transaction_status_stream: Array<Ref_Transaction_Status>;
  /** fetch data from the table: "ref_transaction_type" */
  ref_transaction_type: Array<Ref_Transaction_Type>;
  /** fetch aggregated fields from the table: "ref_transaction_type" */
  ref_transaction_type_aggregate: Ref_Transaction_Type_Aggregate;
  /** fetch data from the table: "ref_transaction_type" using primary key columns */
  ref_transaction_type_by_pk?: Maybe<Ref_Transaction_Type>;
  /** fetch data from the table in a streaming manner: "ref_transaction_type" */
  ref_transaction_type_stream: Array<Ref_Transaction_Type>;
  /** fetch data from the table: "ref_transfer_status" */
  ref_transfer_status: Array<Ref_Transfer_Status>;
  /** fetch aggregated fields from the table: "ref_transfer_status" */
  ref_transfer_status_aggregate: Ref_Transfer_Status_Aggregate;
  /** fetch data from the table: "ref_transfer_status" using primary key columns */
  ref_transfer_status_by_pk?: Maybe<Ref_Transfer_Status>;
  /** fetch data from the table in a streaming manner: "ref_transfer_status" */
  ref_transfer_status_stream: Array<Ref_Transfer_Status>;
  /** fetch data from the table: "ref_transfer_type" */
  ref_transfer_type: Array<Ref_Transfer_Type>;
  /** fetch aggregated fields from the table: "ref_transfer_type" */
  ref_transfer_type_aggregate: Ref_Transfer_Type_Aggregate;
  /** fetch data from the table: "ref_transfer_type" using primary key columns */
  ref_transfer_type_by_pk?: Maybe<Ref_Transfer_Type>;
  /** fetch data from the table in a streaming manner: "ref_transfer_type" */
  ref_transfer_type_stream: Array<Ref_Transfer_Type>;
  /** fetch data from the table: "ref_user_status" */
  ref_user_status: Array<Ref_User_Status>;
  /** fetch aggregated fields from the table: "ref_user_status" */
  ref_user_status_aggregate: Ref_User_Status_Aggregate;
  /** fetch data from the table: "ref_user_status" using primary key columns */
  ref_user_status_by_pk?: Maybe<Ref_User_Status>;
  /** fetch data from the table in a streaming manner: "ref_user_status" */
  ref_user_status_stream: Array<Ref_User_Status>;
  /** fetch data from the table: "ref_user_type" */
  ref_user_type: Array<Ref_User_Type>;
  /** fetch aggregated fields from the table: "ref_user_type" */
  ref_user_type_aggregate: Ref_User_Type_Aggregate;
  /** fetch data from the table: "ref_user_type" using primary key columns */
  ref_user_type_by_pk?: Maybe<Ref_User_Type>;
  /** fetch data from the table in a streaming manner: "ref_user_type" */
  ref_user_type_stream: Array<Ref_User_Type>;
  /** fetch data from the table: "report_category" */
  report_category: Array<Report_Category>;
  /** fetch aggregated fields from the table: "report_category" */
  report_category_aggregate: Report_Category_Aggregate;
  /** fetch data from the table: "report_category" using primary key columns */
  report_category_by_pk?: Maybe<Report_Category>;
  /** fetch data from the table in a streaming manner: "report_category" */
  report_category_stream: Array<Report_Category>;
  /** fetch data from the table: "report_event" */
  report_event: Array<Report_Event>;
  /** fetch aggregated fields from the table: "report_event" */
  report_event_aggregate: Report_Event_Aggregate;
  /** fetch data from the table: "report_event" using primary key columns */
  report_event_by_pk?: Maybe<Report_Event>;
  /** fetch data from the table in a streaming manner: "report_event" */
  report_event_stream: Array<Report_Event>;
  /** fetch data from the table: "schedule_document_checklist_reminder" */
  schedule_document_checklist_reminder: Array<Schedule_Document_Checklist_Reminder>;
  /** fetch aggregated fields from the table: "schedule_document_checklist_reminder" */
  schedule_document_checklist_reminder_aggregate: Schedule_Document_Checklist_Reminder_Aggregate;
  /** fetch data from the table: "schedule_document_checklist_reminder" using primary key columns */
  schedule_document_checklist_reminder_by_pk?: Maybe<Schedule_Document_Checklist_Reminder>;
  /** fetch data from the table in a streaming manner: "schedule_document_checklist_reminder" */
  schedule_document_checklist_reminder_stream: Array<Schedule_Document_Checklist_Reminder>;
  /** fetch data from the table: "scheduled_emi" */
  scheduled_emi: Array<Scheduled_Emi>;
  /** fetch aggregated fields from the table: "scheduled_emi" */
  scheduled_emi_aggregate: Scheduled_Emi_Aggregate;
  /** fetch data from the table: "scheduled_emi" using primary key columns */
  scheduled_emi_by_pk?: Maybe<Scheduled_Emi>;
  /** fetch data from the table in a streaming manner: "scheduled_emi" */
  scheduled_emi_stream: Array<Scheduled_Emi>;
  /** fetch data from the table: "score_factor" */
  score_factor: Array<Score_Factor>;
  /** fetch aggregated fields from the table: "score_factor" */
  score_factor_aggregate: Score_Factor_Aggregate;
  /** fetch data from the table: "score_factor" using primary key columns */
  score_factor_by_pk?: Maybe<Score_Factor>;
  /** fetch data from the table in a streaming manner: "score_factor" */
  score_factor_stream: Array<Score_Factor>;
  /** fetch data from the table: "staff_user" */
  staff_user: Array<Staff_User>;
  /** fetch aggregated fields from the table: "staff_user" */
  staff_user_aggregate: Staff_User_Aggregate;
  /** fetch data from the table: "staff_user" using primary key columns */
  staff_user_by_pk?: Maybe<Staff_User>;
  /** fetch data from the table in a streaming manner: "staff_user" */
  staff_user_stream: Array<Staff_User>;
  /** fetch data from the table: "subscription" */
  subscription: Array<Subscription>;
  /** fetch aggregated fields from the table: "subscription" */
  subscription_aggregate: Subscription_Aggregate;
  /** fetch data from the table: "subscription" using primary key columns */
  subscription_by_pk?: Maybe<Subscription>;
  /** fetch data from the table in a streaming manner: "subscription" */
  subscription_stream: Array<Subscription>;
  /** An array relationship */
  tds: Array<Tds>;
  /** An aggregate relationship */
  tds_aggregate: Tds_Aggregate;
  /** fetch data from the table: "tds" using primary key columns */
  tds_by_pk?: Maybe<Tds>;
  /** fetch data from the table in a streaming manner: "tds" */
  tds_stream: Array<Tds>;
  /** fetch data from the table: "trade" */
  trade: Array<Trade>;
  /** fetch aggregated fields from the table: "trade" */
  trade_aggregate: Trade_Aggregate;
  /** fetch data from the table: "trade_amortization" */
  trade_amortization: Array<Trade_Amortization>;
  /** fetch aggregated fields from the table: "trade_amortization" */
  trade_amortization_aggregate: Trade_Amortization_Aggregate;
  /** fetch data from the table in a streaming manner: "trade_amortization" */
  trade_amortization_stream: Array<Trade_Amortization>;
  /** fetch data from the table: "trade" using primary key columns */
  trade_by_pk?: Maybe<Trade>;
  /** fetch data from the table in a streaming manner: "trade" */
  trade_stream: Array<Trade>;
  /** fetch data from the table: "trade_subscription" */
  trade_subscription: Array<Trade_Subscription>;
  /** fetch aggregated fields from the table: "trade_subscription" */
  trade_subscription_aggregate: Trade_Subscription_Aggregate;
  /** fetch data from the table: "trade_subscription" using primary key columns */
  trade_subscription_by_pk?: Maybe<Trade_Subscription>;
  /** fetch data from the table in a streaming manner: "trade_subscription" */
  trade_subscription_stream: Array<Trade_Subscription>;
  /** fetch data from the table: "transaction" */
  transaction: Array<Transaction>;
  /** fetch aggregated fields from the table: "transaction" */
  transaction_aggregate: Transaction_Aggregate;
  /** fetch data from the table: "transaction" using primary key columns */
  transaction_by_pk?: Maybe<Transaction>;
  /** fetch data from the table in a streaming manner: "transaction" */
  transaction_stream: Array<Transaction>;
  /** fetch data from the table: "transactions_view" */
  transactions_view: Array<Transactions_View>;
  /** fetch aggregated fields from the table: "transactions_view" */
  transactions_view_aggregate: Transactions_View_Aggregate;
  /** fetch data from the table in a streaming manner: "transactions_view" */
  transactions_view_stream: Array<Transactions_View>;
  /** fetch data from the table: "transfer" */
  transfer: Array<Transfer>;
  /** fetch aggregated fields from the table: "transfer" */
  transfer_aggregate: Transfer_Aggregate;
  /** fetch data from the table: "transfer" using primary key columns */
  transfer_by_pk?: Maybe<Transfer>;
  /** fetch data from the table in a streaming manner: "transfer" */
  transfer_stream: Array<Transfer>;
  /** fetch data from the table: "updates_log" */
  updates_log: Array<Updates_Log>;
  /** fetch aggregated fields from the table: "updates_log" */
  updates_log_aggregate: Updates_Log_Aggregate;
  /** fetch data from the table: "updates_log" using primary key columns */
  updates_log_by_pk?: Maybe<Updates_Log>;
  /** fetch data from the table in a streaming manner: "updates_log" */
  updates_log_stream: Array<Updates_Log>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "verification_code" */
  verification_code: Array<Verification_Code>;
  /** fetch aggregated fields from the table: "verification_code" */
  verification_code_aggregate: Verification_Code_Aggregate;
  /** fetch data from the table: "verification_code" using primary key columns */
  verification_code_by_pk?: Maybe<Verification_Code>;
  /** fetch data from the table in a streaming manner: "verification_code" */
  verification_code_stream: Array<Verification_Code>;
  /** fetch data from the table: "webhook_events" */
  webhook_events: Array<Webhook_Events>;
  /** fetch aggregated fields from the table: "webhook_events" */
  webhook_events_aggregate: Webhook_Events_Aggregate;
  /** fetch data from the table: "webhook_events" using primary key columns */
  webhook_events_by_pk?: Maybe<Webhook_Events>;
  /** fetch data from the table in a streaming manner: "webhook_events" */
  webhook_events_stream: Array<Webhook_Events>;
};


export type Subscription_RootAccountArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


export type Subscription_RootAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


export type Subscription_RootAccount_BalanceArgs = {
  distinct_on?: InputMaybe<Array<Account_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Balance_Order_By>>;
  where?: InputMaybe<Account_Balance_Bool_Exp>;
};


export type Subscription_RootAccount_Balance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Balance_Order_By>>;
  where?: InputMaybe<Account_Balance_Bool_Exp>;
};


export type Subscription_RootAccount_Balance_By_PkArgs = {
  account_id: Scalars['Int'];
};


export type Subscription_RootAccount_Balance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Account_Balance_Stream_Cursor_Input>>;
  where?: InputMaybe<Account_Balance_Bool_Exp>;
};


export type Subscription_RootAccount_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAccount_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Account_Stream_Cursor_Input>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


export type Subscription_RootAgreement_RegistryArgs = {
  distinct_on?: InputMaybe<Array<Agreement_Registry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Agreement_Registry_Order_By>>;
  where?: InputMaybe<Agreement_Registry_Bool_Exp>;
};


export type Subscription_RootAgreement_Registry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agreement_Registry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Agreement_Registry_Order_By>>;
  where?: InputMaybe<Agreement_Registry_Bool_Exp>;
};


export type Subscription_RootAgreement_Registry_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAgreement_Registry_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Agreement_Registry_Stream_Cursor_Input>>;
  where?: InputMaybe<Agreement_Registry_Bool_Exp>;
};


export type Subscription_RootAgreement_TenantArgs = {
  distinct_on?: InputMaybe<Array<Agreement_Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Agreement_Tenant_Order_By>>;
  where?: InputMaybe<Agreement_Tenant_Bool_Exp>;
};


export type Subscription_RootAgreement_Tenant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agreement_Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Agreement_Tenant_Order_By>>;
  where?: InputMaybe<Agreement_Tenant_Bool_Exp>;
};


export type Subscription_RootAgreement_Tenant_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAgreement_Tenant_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Agreement_Tenant_Stream_Cursor_Input>>;
  where?: InputMaybe<Agreement_Tenant_Bool_Exp>;
};


export type Subscription_RootApplication_Lifecycle_StageArgs = {
  distinct_on?: InputMaybe<Array<Application_Lifecycle_Stage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_Lifecycle_Stage_Order_By>>;
  where?: InputMaybe<Application_Lifecycle_Stage_Bool_Exp>;
};


export type Subscription_RootApplication_Lifecycle_Stage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Application_Lifecycle_Stage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_Lifecycle_Stage_Order_By>>;
  where?: InputMaybe<Application_Lifecycle_Stage_Bool_Exp>;
};


export type Subscription_RootApplication_Lifecycle_Stage_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootApplication_Lifecycle_Stage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Application_Lifecycle_Stage_Stream_Cursor_Input>>;
  where?: InputMaybe<Application_Lifecycle_Stage_Bool_Exp>;
};


export type Subscription_RootBanking_Partner_IntegrationArgs = {
  distinct_on?: InputMaybe<Array<Banking_Partner_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Banking_Partner_Integration_Order_By>>;
  where?: InputMaybe<Banking_Partner_Integration_Bool_Exp>;
};


export type Subscription_RootBanking_Partner_Integration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Banking_Partner_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Banking_Partner_Integration_Order_By>>;
  where?: InputMaybe<Banking_Partner_Integration_Bool_Exp>;
};


export type Subscription_RootBanking_Partner_Integration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Banking_Partner_Integration_Stream_Cursor_Input>>;
  where?: InputMaybe<Banking_Partner_Integration_Bool_Exp>;
};


export type Subscription_RootBidArgs = {
  distinct_on?: InputMaybe<Array<Bid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bid_Order_By>>;
  where?: InputMaybe<Bid_Bool_Exp>;
};


export type Subscription_RootBid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bid_Order_By>>;
  where?: InputMaybe<Bid_Bool_Exp>;
};


export type Subscription_RootBid_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBid_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bid_Stream_Cursor_Input>>;
  where?: InputMaybe<Bid_Bool_Exp>;
};


export type Subscription_RootBlacklisted_TokensArgs = {
  distinct_on?: InputMaybe<Array<Blacklisted_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blacklisted_Tokens_Order_By>>;
  where?: InputMaybe<Blacklisted_Tokens_Bool_Exp>;
};


export type Subscription_RootBlacklisted_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blacklisted_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blacklisted_Tokens_Order_By>>;
  where?: InputMaybe<Blacklisted_Tokens_Bool_Exp>;
};


export type Subscription_RootBlacklisted_Tokens_By_PkArgs = {
  token: Scalars['String'];
};


export type Subscription_RootBlacklisted_Tokens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Blacklisted_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<Blacklisted_Tokens_Bool_Exp>;
};


export type Subscription_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Subscription_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Subscription_RootCompany_Agreement_Logs_ViewArgs = {
  distinct_on?: InputMaybe<Array<Company_Agreement_Logs_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Agreement_Logs_View_Order_By>>;
  where?: InputMaybe<Company_Agreement_Logs_View_Bool_Exp>;
};


export type Subscription_RootCompany_Agreement_Logs_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Agreement_Logs_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Agreement_Logs_View_Order_By>>;
  where?: InputMaybe<Company_Agreement_Logs_View_Bool_Exp>;
};


export type Subscription_RootCompany_Agreement_Logs_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Agreement_Logs_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Agreement_Logs_View_Bool_Exp>;
};


export type Subscription_RootCompany_Application_Crime_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Company_Application_Crime_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Application_Crime_Details_Order_By>>;
  where?: InputMaybe<Company_Application_Crime_Details_Bool_Exp>;
};


export type Subscription_RootCompany_Application_Crime_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Application_Crime_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Application_Crime_Details_Order_By>>;
  where?: InputMaybe<Company_Application_Crime_Details_Bool_Exp>;
};


export type Subscription_RootCompany_Application_Crime_Details_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_Application_Crime_Details_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Application_Crime_Details_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Application_Crime_Details_Bool_Exp>;
};


export type Subscription_RootCompany_Application_EpfoArgs = {
  distinct_on?: InputMaybe<Array<Company_Application_Epfo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Application_Epfo_Order_By>>;
  where?: InputMaybe<Company_Application_Epfo_Bool_Exp>;
};


export type Subscription_RootCompany_Application_Epfo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Application_Epfo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Application_Epfo_Order_By>>;
  where?: InputMaybe<Company_Application_Epfo_Bool_Exp>;
};


export type Subscription_RootCompany_Application_Epfo_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_Application_Epfo_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Application_Epfo_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Application_Epfo_Bool_Exp>;
};


export type Subscription_RootCompany_Authorized_SignatoryArgs = {
  distinct_on?: InputMaybe<Array<Company_Authorized_Signatory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Authorized_Signatory_Order_By>>;
  where?: InputMaybe<Company_Authorized_Signatory_Bool_Exp>;
};


export type Subscription_RootCompany_Authorized_Signatory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Authorized_Signatory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Authorized_Signatory_Order_By>>;
  where?: InputMaybe<Company_Authorized_Signatory_Bool_Exp>;
};


export type Subscription_RootCompany_Authorized_Signatory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_Authorized_Signatory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Authorized_Signatory_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Authorized_Signatory_Bool_Exp>;
};


export type Subscription_RootCompany_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_CinArgs = {
  distinct_on?: InputMaybe<Array<Company_Cin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Cin_Order_By>>;
  where?: InputMaybe<Company_Cin_Bool_Exp>;
};


export type Subscription_RootCompany_Cin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Cin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Cin_Order_By>>;
  where?: InputMaybe<Company_Cin_Bool_Exp>;
};


export type Subscription_RootCompany_Cin_By_PkArgs = {
  cin: Scalars['String'];
};


export type Subscription_RootCompany_Cin_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Cin_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Cin_Bool_Exp>;
};


export type Subscription_RootCompany_Email_LogArgs = {
  distinct_on?: InputMaybe<Array<Company_Email_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Email_Log_Order_By>>;
  where?: InputMaybe<Company_Email_Log_Bool_Exp>;
};


export type Subscription_RootCompany_Email_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Email_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Email_Log_Order_By>>;
  where?: InputMaybe<Company_Email_Log_Bool_Exp>;
};


export type Subscription_RootCompany_Email_Log_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_Email_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Email_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Email_Log_Bool_Exp>;
};


export type Subscription_RootCompany_Gst_ReturnArgs = {
  distinct_on?: InputMaybe<Array<Company_Gst_Return_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Gst_Return_Order_By>>;
  where?: InputMaybe<Company_Gst_Return_Bool_Exp>;
};


export type Subscription_RootCompany_Gst_Return_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Gst_Return_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Gst_Return_Order_By>>;
  where?: InputMaybe<Company_Gst_Return_Bool_Exp>;
};


export type Subscription_RootCompany_Gst_Return_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_Gst_Return_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Gst_Return_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Gst_Return_Bool_Exp>;
};


export type Subscription_RootCompany_IntegrationArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_Order_By>>;
  where?: InputMaybe<Company_Integration_Bool_Exp>;
};


export type Subscription_RootCompany_Integration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_Order_By>>;
  where?: InputMaybe<Company_Integration_Bool_Exp>;
};


export type Subscription_RootCompany_Integration_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_Integration_FileArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_File_Order_By>>;
  where?: InputMaybe<Company_Integration_File_Bool_Exp>;
};


export type Subscription_RootCompany_Integration_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Integration_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Integration_File_Order_By>>;
  where?: InputMaybe<Company_Integration_File_Bool_Exp>;
};


export type Subscription_RootCompany_Integration_File_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_Integration_File_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Integration_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Integration_File_Bool_Exp>;
};


export type Subscription_RootCompany_Integration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Integration_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Integration_Bool_Exp>;
};


export type Subscription_RootCompany_Lead_StatusArgs = {
  distinct_on?: InputMaybe<Array<Company_Lead_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Lead_Status_Order_By>>;
  where?: InputMaybe<Company_Lead_Status_Bool_Exp>;
};


export type Subscription_RootCompany_Lead_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Lead_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Lead_Status_Order_By>>;
  where?: InputMaybe<Company_Lead_Status_Bool_Exp>;
};


export type Subscription_RootCompany_Lead_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_Lead_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Lead_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Lead_Status_Bool_Exp>;
};


export type Subscription_RootCompany_ReportsArgs = {
  distinct_on?: InputMaybe<Array<Company_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Reports_Order_By>>;
  where?: InputMaybe<Company_Reports_Bool_Exp>;
};


export type Subscription_RootCompany_Reports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Reports_Order_By>>;
  where?: InputMaybe<Company_Reports_Bool_Exp>;
};


export type Subscription_RootCompany_Reports_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_Reports_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Reports_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Reports_Bool_Exp>;
};


export type Subscription_RootCompany_ScoreArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Order_By>>;
  where?: InputMaybe<Company_Score_Bool_Exp>;
};


export type Subscription_RootCompany_Score_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Order_By>>;
  where?: InputMaybe<Company_Score_Bool_Exp>;
};


export type Subscription_RootCompany_Score_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_Score_FactorArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Factor_Order_By>>;
  where?: InputMaybe<Company_Score_Factor_Bool_Exp>;
};


export type Subscription_RootCompany_Score_Factor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Factor_Order_By>>;
  where?: InputMaybe<Company_Score_Factor_Bool_Exp>;
};


export type Subscription_RootCompany_Score_Factor_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_Score_Factor_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Score_Factor_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Score_Factor_Bool_Exp>;
};


export type Subscription_RootCompany_Score_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Score_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Score_Bool_Exp>;
};


export type Subscription_RootCompany_Score_Time_SeriesArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Time_Series_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Time_Series_Order_By>>;
  where?: InputMaybe<Company_Score_Time_Series_Bool_Exp>;
};


export type Subscription_RootCompany_Score_Time_Series_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Score_Time_Series_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Score_Time_Series_Order_By>>;
  where?: InputMaybe<Company_Score_Time_Series_Bool_Exp>;
};


export type Subscription_RootCompany_Score_Time_Series_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_Score_Time_Series_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Score_Time_Series_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Score_Time_Series_Bool_Exp>;
};


export type Subscription_RootCompany_StatementArgs = {
  distinct_on?: InputMaybe<Array<Company_Statement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Statement_Order_By>>;
  where?: InputMaybe<Company_Statement_Bool_Exp>;
};


export type Subscription_RootCompany_Statement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Statement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Statement_Order_By>>;
  where?: InputMaybe<Company_Statement_Bool_Exp>;
};


export type Subscription_RootCompany_Statement_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_Statement_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Statement_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Statement_Bool_Exp>;
};


export type Subscription_RootCompany_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Subscription_RootCompany_TanArgs = {
  distinct_on?: InputMaybe<Array<Company_Tan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Tan_Order_By>>;
  where?: InputMaybe<Company_Tan_Bool_Exp>;
};


export type Subscription_RootCompany_Tan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Tan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Tan_Order_By>>;
  where?: InputMaybe<Company_Tan_Bool_Exp>;
};


export type Subscription_RootCompany_Tan_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_Tan_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Tan_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Tan_Bool_Exp>;
};


export type Subscription_RootCompany_Type_Factor_BenchmarkArgs = {
  distinct_on?: InputMaybe<Array<Company_Type_Factor_Benchmark_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Type_Factor_Benchmark_Order_By>>;
  where?: InputMaybe<Company_Type_Factor_Benchmark_Bool_Exp>;
};


export type Subscription_RootCompany_Type_Factor_Benchmark_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Type_Factor_Benchmark_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Type_Factor_Benchmark_Order_By>>;
  where?: InputMaybe<Company_Type_Factor_Benchmark_Bool_Exp>;
};


export type Subscription_RootCompany_Type_Factor_Benchmark_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_Type_Factor_Benchmark_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Type_Factor_Benchmark_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Type_Factor_Benchmark_Bool_Exp>;
};


export type Subscription_RootCompany_UserArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};


export type Subscription_RootCompany_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};


export type Subscription_RootCompany_User_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};


export type Subscription_RootDealArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


export type Subscription_RootDeal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


export type Subscription_RootDeal_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDeal_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Deal_Stream_Cursor_Input>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


export type Subscription_RootDocument_ChecklistArgs = {
  distinct_on?: InputMaybe<Array<Document_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Checklist_Order_By>>;
  where?: InputMaybe<Document_Checklist_Bool_Exp>;
};


export type Subscription_RootDocument_Checklist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Checklist_Order_By>>;
  where?: InputMaybe<Document_Checklist_Bool_Exp>;
};


export type Subscription_RootDocument_Checklist_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDocument_Checklist_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Document_Checklist_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Checklist_Category_Order_By>>;
  where?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
};


export type Subscription_RootDocument_Checklist_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Checklist_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Checklist_Category_Order_By>>;
  where?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
};


export type Subscription_RootDocument_Checklist_Category_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDocument_Checklist_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Document_Checklist_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Checklist_Category_Bool_Exp>;
};


export type Subscription_RootDocument_Checklist_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Document_Checklist_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Checklist_Bool_Exp>;
};


export type Subscription_RootEmail_EventsArgs = {
  distinct_on?: InputMaybe<Array<Email_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Events_Order_By>>;
  where?: InputMaybe<Email_Events_Bool_Exp>;
};


export type Subscription_RootEmail_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Events_Order_By>>;
  where?: InputMaybe<Email_Events_Bool_Exp>;
};


export type Subscription_RootEmail_Events_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEmail_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Email_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Email_Events_Bool_Exp>;
};


export type Subscription_RootEmi_TdsArgs = {
  distinct_on?: InputMaybe<Array<Emi_Tds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emi_Tds_Order_By>>;
  where?: InputMaybe<Emi_Tds_Bool_Exp>;
};


export type Subscription_RootEmi_Tds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Emi_Tds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emi_Tds_Order_By>>;
  where?: InputMaybe<Emi_Tds_Bool_Exp>;
};


export type Subscription_RootEmi_Tds_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEmi_Tds_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Emi_Tds_Stream_Cursor_Input>>;
  where?: InputMaybe<Emi_Tds_Bool_Exp>;
};


export type Subscription_RootFactor_Benchmark_RangeArgs = {
  distinct_on?: InputMaybe<Array<Factor_Benchmark_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Factor_Benchmark_Range_Order_By>>;
  where?: InputMaybe<Factor_Benchmark_Range_Bool_Exp>;
};


export type Subscription_RootFactor_Benchmark_Range_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Factor_Benchmark_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Factor_Benchmark_Range_Order_By>>;
  where?: InputMaybe<Factor_Benchmark_Range_Bool_Exp>;
};


export type Subscription_RootFactor_Benchmark_Range_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFactor_Benchmark_Range_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Factor_Benchmark_Range_Stream_Cursor_Input>>;
  where?: InputMaybe<Factor_Benchmark_Range_Bool_Exp>;
};


export type Subscription_RootFile_RegistryArgs = {
  distinct_on?: InputMaybe<Array<File_Registry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Registry_Order_By>>;
  where?: InputMaybe<File_Registry_Bool_Exp>;
};


export type Subscription_RootFile_Registry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Registry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Registry_Order_By>>;
  where?: InputMaybe<File_Registry_Bool_Exp>;
};


export type Subscription_RootFile_Registry_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFile_Registry_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<File_Registry_Stream_Cursor_Input>>;
  where?: InputMaybe<File_Registry_Bool_Exp>;
};


export type Subscription_RootIntegration_AuthArgs = {
  distinct_on?: InputMaybe<Array<Integration_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Auth_Order_By>>;
  where?: InputMaybe<Integration_Auth_Bool_Exp>;
};


export type Subscription_RootIntegration_Auth_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Auth_Order_By>>;
  where?: InputMaybe<Integration_Auth_Bool_Exp>;
};


export type Subscription_RootIntegration_Auth_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootIntegration_Auth_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Integration_Auth_Stream_Cursor_Input>>;
  where?: InputMaybe<Integration_Auth_Bool_Exp>;
};


export type Subscription_RootInternal_StatementArgs = {
  distinct_on?: InputMaybe<Array<Internal_Statement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Internal_Statement_Order_By>>;
  where?: InputMaybe<Internal_Statement_Bool_Exp>;
};


export type Subscription_RootInternal_Statement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Internal_Statement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Internal_Statement_Order_By>>;
  where?: InputMaybe<Internal_Statement_Bool_Exp>;
};


export type Subscription_RootInternal_Statement_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInternal_Statement_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Internal_Statement_Stream_Cursor_Input>>;
  where?: InputMaybe<Internal_Statement_Bool_Exp>;
};


export type Subscription_RootInvestmentArgs = {
  distinct_on?: InputMaybe<Array<Investment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Order_By>>;
  where?: InputMaybe<Investment_Bool_Exp>;
};


export type Subscription_RootInvestment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Order_By>>;
  where?: InputMaybe<Investment_Bool_Exp>;
};


export type Subscription_RootInvestment_AmortizationArgs = {
  distinct_on?: InputMaybe<Array<Investment_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Amortization_Order_By>>;
  where?: InputMaybe<Investment_Amortization_Bool_Exp>;
};


export type Subscription_RootInvestment_Amortization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investment_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Amortization_Order_By>>;
  where?: InputMaybe<Investment_Amortization_Bool_Exp>;
};


export type Subscription_RootInvestment_Amortization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Investment_Amortization_Stream_Cursor_Input>>;
  where?: InputMaybe<Investment_Amortization_Bool_Exp>;
};


export type Subscription_RootInvestment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInvestment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Investment_Stream_Cursor_Input>>;
  where?: InputMaybe<Investment_Bool_Exp>;
};


export type Subscription_RootInvestorArgs = {
  distinct_on?: InputMaybe<Array<Investor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Order_By>>;
  where?: InputMaybe<Investor_Bool_Exp>;
};


export type Subscription_RootInvestor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Order_By>>;
  where?: InputMaybe<Investor_Bool_Exp>;
};


export type Subscription_RootInvestor_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInvestor_PreferenceArgs = {
  distinct_on?: InputMaybe<Array<Investor_Preference_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Preference_Order_By>>;
  where?: InputMaybe<Investor_Preference_Bool_Exp>;
};


export type Subscription_RootInvestor_Preference_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investor_Preference_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Preference_Order_By>>;
  where?: InputMaybe<Investor_Preference_Bool_Exp>;
};


export type Subscription_RootInvestor_Preference_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInvestor_Preference_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Investor_Preference_Stream_Cursor_Input>>;
  where?: InputMaybe<Investor_Preference_Bool_Exp>;
};


export type Subscription_RootInvestor_StatementArgs = {
  distinct_on?: InputMaybe<Array<Investor_Statement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Statement_Order_By>>;
  where?: InputMaybe<Investor_Statement_Bool_Exp>;
};


export type Subscription_RootInvestor_Statement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investor_Statement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Statement_Order_By>>;
  where?: InputMaybe<Investor_Statement_Bool_Exp>;
};


export type Subscription_RootInvestor_Statement_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInvestor_Statement_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Investor_Statement_Stream_Cursor_Input>>;
  where?: InputMaybe<Investor_Statement_Bool_Exp>;
};


export type Subscription_RootInvestor_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Investor_Stream_Cursor_Input>>;
  where?: InputMaybe<Investor_Bool_Exp>;
};


export type Subscription_RootInvestor_TransactionArgs = {
  distinct_on?: InputMaybe<Array<Investor_Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Transaction_Order_By>>;
  where?: InputMaybe<Investor_Transaction_Bool_Exp>;
};


export type Subscription_RootInvestor_Transaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investor_Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Transaction_Order_By>>;
  where?: InputMaybe<Investor_Transaction_Bool_Exp>;
};


export type Subscription_RootInvestor_Transaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInvestor_Transaction_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Investor_Transaction_Stream_Cursor_Input>>;
  where?: InputMaybe<Investor_Transaction_Bool_Exp>;
};


export type Subscription_RootInvestor_Transaction_ViewArgs = {
  distinct_on?: InputMaybe<Array<Investor_Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Transaction_Order_By>>;
  where?: InputMaybe<Investor_Transaction_Bool_Exp>;
};


export type Subscription_RootInvestor_Transaction_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investor_Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Transaction_Order_By>>;
  where?: InputMaybe<Investor_Transaction_Bool_Exp>;
};


export type Subscription_RootInvestor_UserArgs = {
  distinct_on?: InputMaybe<Array<Investor_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_User_Order_By>>;
  where?: InputMaybe<Investor_User_Bool_Exp>;
};


export type Subscription_RootInvestor_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investor_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_User_Order_By>>;
  where?: InputMaybe<Investor_User_Bool_Exp>;
};


export type Subscription_RootInvestor_User_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInvestor_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Investor_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Investor_User_Bool_Exp>;
};


export type Subscription_RootLifecycle_Question_Crm_MapperArgs = {
  distinct_on?: InputMaybe<Array<Lifecycle_Question_Crm_Mapper_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lifecycle_Question_Crm_Mapper_Order_By>>;
  where?: InputMaybe<Lifecycle_Question_Crm_Mapper_Bool_Exp>;
};


export type Subscription_RootLifecycle_Question_Crm_Mapper_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lifecycle_Question_Crm_Mapper_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lifecycle_Question_Crm_Mapper_Order_By>>;
  where?: InputMaybe<Lifecycle_Question_Crm_Mapper_Bool_Exp>;
};


export type Subscription_RootLifecycle_Question_Crm_Mapper_By_PkArgs = {
  serial_number: Scalars['String'];
};


export type Subscription_RootLifecycle_Question_Crm_Mapper_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lifecycle_Question_Crm_Mapper_Stream_Cursor_Input>>;
  where?: InputMaybe<Lifecycle_Question_Crm_Mapper_Bool_Exp>;
};


export type Subscription_RootLifecycle_Question_ResultArgs = {
  distinct_on?: InputMaybe<Array<Lifecycle_Question_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lifecycle_Question_Result_Order_By>>;
  where?: InputMaybe<Lifecycle_Question_Result_Bool_Exp>;
};


export type Subscription_RootLifecycle_Question_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lifecycle_Question_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lifecycle_Question_Result_Order_By>>;
  where?: InputMaybe<Lifecycle_Question_Result_Bool_Exp>;
};


export type Subscription_RootLifecycle_Question_Result_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLifecycle_Question_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lifecycle_Question_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Lifecycle_Question_Result_Bool_Exp>;
};


export type Subscription_RootMandateArgs = {
  distinct_on?: InputMaybe<Array<Mandate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mandate_Order_By>>;
  where?: InputMaybe<Mandate_Bool_Exp>;
};


export type Subscription_RootMandate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mandate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mandate_Order_By>>;
  where?: InputMaybe<Mandate_Bool_Exp>;
};


export type Subscription_RootMandate_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMandate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mandate_Stream_Cursor_Input>>;
  where?: InputMaybe<Mandate_Bool_Exp>;
};


export type Subscription_RootPopular_BanksArgs = {
  distinct_on?: InputMaybe<Array<Popular_Banks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Popular_Banks_Order_By>>;
  where?: InputMaybe<Popular_Banks_Bool_Exp>;
};


export type Subscription_RootPopular_Banks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Popular_Banks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Popular_Banks_Order_By>>;
  where?: InputMaybe<Popular_Banks_Bool_Exp>;
};


export type Subscription_RootPopular_Banks_By_PkArgs = {
  bank_id: Scalars['Int'];
};


export type Subscription_RootPopular_Banks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Popular_Banks_Stream_Cursor_Input>>;
  where?: InputMaybe<Popular_Banks_Bool_Exp>;
};


export type Subscription_RootRef_Account_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Account_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Account_Status_Order_By>>;
  where?: InputMaybe<Ref_Account_Status_Bool_Exp>;
};


export type Subscription_RootRef_Account_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Account_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Account_Status_Order_By>>;
  where?: InputMaybe<Ref_Account_Status_Bool_Exp>;
};


export type Subscription_RootRef_Account_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Account_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Account_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Account_Status_Bool_Exp>;
};


export type Subscription_RootRef_Account_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Account_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Account_Type_Order_By>>;
  where?: InputMaybe<Ref_Account_Type_Bool_Exp>;
};


export type Subscription_RootRef_Account_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Account_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Account_Type_Order_By>>;
  where?: InputMaybe<Ref_Account_Type_Bool_Exp>;
};


export type Subscription_RootRef_Account_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Account_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Account_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Account_Type_Bool_Exp>;
};


export type Subscription_RootRef_Agreement_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Agreement_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Agreement_Category_Order_By>>;
  where?: InputMaybe<Ref_Agreement_Category_Bool_Exp>;
};


export type Subscription_RootRef_Agreement_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Agreement_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Agreement_Category_Order_By>>;
  where?: InputMaybe<Ref_Agreement_Category_Bool_Exp>;
};


export type Subscription_RootRef_Agreement_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Agreement_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Agreement_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Agreement_Category_Bool_Exp>;
};


export type Subscription_RootRef_Agreement_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Agreement_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Agreement_Status_Order_By>>;
  where?: InputMaybe<Ref_Agreement_Status_Bool_Exp>;
};


export type Subscription_RootRef_Agreement_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Agreement_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Agreement_Status_Order_By>>;
  where?: InputMaybe<Ref_Agreement_Status_Bool_Exp>;
};


export type Subscription_RootRef_Agreement_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Agreement_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Agreement_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Agreement_Status_Bool_Exp>;
};


export type Subscription_RootRef_Application_Allowed_DistrictArgs = {
  distinct_on?: InputMaybe<Array<Ref_Application_Allowed_District_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Application_Allowed_District_Order_By>>;
  where?: InputMaybe<Ref_Application_Allowed_District_Bool_Exp>;
};


export type Subscription_RootRef_Application_Allowed_District_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Application_Allowed_District_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Application_Allowed_District_Order_By>>;
  where?: InputMaybe<Ref_Application_Allowed_District_Bool_Exp>;
};


export type Subscription_RootRef_Application_Allowed_District_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootRef_Application_Allowed_District_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Application_Allowed_District_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Application_Allowed_District_Bool_Exp>;
};


export type Subscription_RootRef_Application_Lifecycle_StageArgs = {
  distinct_on?: InputMaybe<Array<Ref_Application_Lifecycle_Stage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Application_Lifecycle_Stage_Order_By>>;
  where?: InputMaybe<Ref_Application_Lifecycle_Stage_Bool_Exp>;
};


export type Subscription_RootRef_Application_Lifecycle_Stage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Application_Lifecycle_Stage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Application_Lifecycle_Stage_Order_By>>;
  where?: InputMaybe<Ref_Application_Lifecycle_Stage_Bool_Exp>;
};


export type Subscription_RootRef_Application_Lifecycle_Stage_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Application_Lifecycle_Stage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Application_Lifecycle_Stage_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Application_Lifecycle_Stage_Bool_Exp>;
};


export type Subscription_RootRef_BankArgs = {
  distinct_on?: InputMaybe<Array<Ref_Bank_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Bank_Order_By>>;
  where?: InputMaybe<Ref_Bank_Bool_Exp>;
};


export type Subscription_RootRef_Bank_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Bank_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Bank_Order_By>>;
  where?: InputMaybe<Ref_Bank_Bool_Exp>;
};


export type Subscription_RootRef_Bank_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRef_Bank_ListArgs = {
  distinct_on?: InputMaybe<Array<Ref_Bank_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Bank_List_Order_By>>;
  where?: InputMaybe<Ref_Bank_List_Bool_Exp>;
};


export type Subscription_RootRef_Bank_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Bank_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Bank_List_Order_By>>;
  where?: InputMaybe<Ref_Bank_List_Bool_Exp>;
};


export type Subscription_RootRef_Bank_List_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRef_Bank_List_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Bank_List_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Bank_List_Bool_Exp>;
};


export type Subscription_RootRef_Bank_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Bank_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Bank_Bool_Exp>;
};


export type Subscription_RootRef_BidArgs = {
  distinct_on?: InputMaybe<Array<Ref_Bid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Bid_Order_By>>;
  where?: InputMaybe<Ref_Bid_Bool_Exp>;
};


export type Subscription_RootRef_Bid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Bid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Bid_Order_By>>;
  where?: InputMaybe<Ref_Bid_Bool_Exp>;
};


export type Subscription_RootRef_Bid_By_PkArgs = {
  price: Scalars['numeric'];
};


export type Subscription_RootRef_Bid_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Bid_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Bid_Bool_Exp>;
};


export type Subscription_RootRef_Billing_PeriodArgs = {
  distinct_on?: InputMaybe<Array<Ref_Billing_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Billing_Period_Order_By>>;
  where?: InputMaybe<Ref_Billing_Period_Bool_Exp>;
};


export type Subscription_RootRef_Billing_Period_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Billing_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Billing_Period_Order_By>>;
  where?: InputMaybe<Ref_Billing_Period_Bool_Exp>;
};


export type Subscription_RootRef_Billing_Period_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Billing_Period_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Billing_Period_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Billing_Period_Bool_Exp>;
};


export type Subscription_RootRef_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Category_Order_By>>;
  where?: InputMaybe<Ref_Category_Bool_Exp>;
};


export type Subscription_RootRef_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Category_Order_By>>;
  where?: InputMaybe<Ref_Category_Bool_Exp>;
};


export type Subscription_RootRef_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Category_Bool_Exp>;
};


export type Subscription_RootRef_Collection_ModeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Collection_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Collection_Mode_Order_By>>;
  where?: InputMaybe<Ref_Collection_Mode_Bool_Exp>;
};


export type Subscription_RootRef_Collection_Mode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Collection_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Collection_Mode_Order_By>>;
  where?: InputMaybe<Ref_Collection_Mode_Bool_Exp>;
};


export type Subscription_RootRef_Collection_Mode_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Collection_Mode_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Collection_Mode_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Collection_Mode_Bool_Exp>;
};


export type Subscription_RootRef_Company_Lead_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Lead_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Lead_Status_Order_By>>;
  where?: InputMaybe<Ref_Company_Lead_Status_Bool_Exp>;
};


export type Subscription_RootRef_Company_Lead_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Lead_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Lead_Status_Order_By>>;
  where?: InputMaybe<Ref_Company_Lead_Status_Bool_Exp>;
};


export type Subscription_RootRef_Company_Lead_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Company_Lead_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Company_Lead_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Company_Lead_Status_Bool_Exp>;
};


export type Subscription_RootRef_Company_LikelinessArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Likeliness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Likeliness_Order_By>>;
  where?: InputMaybe<Ref_Company_Likeliness_Bool_Exp>;
};


export type Subscription_RootRef_Company_Likeliness_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Likeliness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Likeliness_Order_By>>;
  where?: InputMaybe<Ref_Company_Likeliness_Bool_Exp>;
};


export type Subscription_RootRef_Company_Likeliness_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Company_Likeliness_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Company_Likeliness_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Company_Likeliness_Bool_Exp>;
};


export type Subscription_RootRef_Company_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Status_Order_By>>;
  where?: InputMaybe<Ref_Company_Status_Bool_Exp>;
};


export type Subscription_RootRef_Company_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Status_Order_By>>;
  where?: InputMaybe<Ref_Company_Status_Bool_Exp>;
};


export type Subscription_RootRef_Company_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Company_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Company_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Company_Status_Bool_Exp>;
};


export type Subscription_RootRef_Company_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Type_Order_By>>;
  where?: InputMaybe<Ref_Company_Type_Bool_Exp>;
};


export type Subscription_RootRef_Company_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Company_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Company_Type_Order_By>>;
  where?: InputMaybe<Ref_Company_Type_Bool_Exp>;
};


export type Subscription_RootRef_Company_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Company_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Company_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Company_Type_Bool_Exp>;
};


export type Subscription_RootRef_CountryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Country_Order_By>>;
  where?: InputMaybe<Ref_Country_Bool_Exp>;
};


export type Subscription_RootRef_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Country_Order_By>>;
  where?: InputMaybe<Ref_Country_Bool_Exp>;
};


export type Subscription_RootRef_Country_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRef_Country_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Country_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Country_Bool_Exp>;
};


export type Subscription_RootRef_CurrencyArgs = {
  distinct_on?: InputMaybe<Array<Ref_Currency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Currency_Order_By>>;
  where?: InputMaybe<Ref_Currency_Bool_Exp>;
};


export type Subscription_RootRef_Currency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Currency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Currency_Order_By>>;
  where?: InputMaybe<Ref_Currency_Bool_Exp>;
};


export type Subscription_RootRef_Currency_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Currency_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Currency_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Currency_Bool_Exp>;
};


export type Subscription_RootRef_Debit_ModeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Debit_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Debit_Mode_Order_By>>;
  where?: InputMaybe<Ref_Debit_Mode_Bool_Exp>;
};


export type Subscription_RootRef_Debit_Mode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Debit_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Debit_Mode_Order_By>>;
  where?: InputMaybe<Ref_Debit_Mode_Bool_Exp>;
};


export type Subscription_RootRef_Debit_Mode_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Debit_Mode_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Debit_Mode_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Debit_Mode_Bool_Exp>;
};


export type Subscription_RootRef_Document_ChecklistArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Bool_Exp>;
};


export type Subscription_RootRef_Document_Checklist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Bool_Exp>;
};


export type Subscription_RootRef_Document_Checklist_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRef_Document_Checklist_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Category_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Category_Bool_Exp>;
};


export type Subscription_RootRef_Document_Checklist_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Category_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Category_Bool_Exp>;
};


export type Subscription_RootRef_Document_Checklist_Category_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRef_Document_Checklist_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Document_Checklist_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Document_Checklist_Category_Bool_Exp>;
};


export type Subscription_RootRef_Document_Checklist_IntegrationArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Integration_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Integration_Bool_Exp>;
};


export type Subscription_RootRef_Document_Checklist_Integration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Integration_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Integration_Bool_Exp>;
};


export type Subscription_RootRef_Document_Checklist_Integration_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Document_Checklist_Integration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Document_Checklist_Integration_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Document_Checklist_Integration_Bool_Exp>;
};


export type Subscription_RootRef_Document_Checklist_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Status_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Status_Bool_Exp>;
};


export type Subscription_RootRef_Document_Checklist_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Document_Checklist_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Document_Checklist_Status_Order_By>>;
  where?: InputMaybe<Ref_Document_Checklist_Status_Bool_Exp>;
};


export type Subscription_RootRef_Document_Checklist_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Document_Checklist_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Document_Checklist_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Document_Checklist_Status_Bool_Exp>;
};


export type Subscription_RootRef_Document_Checklist_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Document_Checklist_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Document_Checklist_Bool_Exp>;
};


export type Subscription_RootRef_Email_Events_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Email_Events_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Email_Events_Type_Order_By>>;
  where?: InputMaybe<Ref_Email_Events_Type_Bool_Exp>;
};


export type Subscription_RootRef_Email_Events_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Email_Events_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Email_Events_Type_Order_By>>;
  where?: InputMaybe<Ref_Email_Events_Type_Bool_Exp>;
};


export type Subscription_RootRef_Email_Events_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Email_Events_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Email_Events_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Email_Events_Type_Bool_Exp>;
};


export type Subscription_RootRef_File_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Ref_File_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_File_Category_Order_By>>;
  where?: InputMaybe<Ref_File_Category_Bool_Exp>;
};


export type Subscription_RootRef_File_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_File_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_File_Category_Order_By>>;
  where?: InputMaybe<Ref_File_Category_Bool_Exp>;
};


export type Subscription_RootRef_File_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_File_Category_MimetypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_File_Category_Mimetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_File_Category_Mimetype_Order_By>>;
  where?: InputMaybe<Ref_File_Category_Mimetype_Bool_Exp>;
};


export type Subscription_RootRef_File_Category_Mimetype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_File_Category_Mimetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_File_Category_Mimetype_Order_By>>;
  where?: InputMaybe<Ref_File_Category_Mimetype_Bool_Exp>;
};


export type Subscription_RootRef_File_Category_Mimetype_By_PkArgs = {
  id: Ref_File_Category_Enum;
};


export type Subscription_RootRef_File_Category_Mimetype_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_File_Category_Mimetype_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_File_Category_Mimetype_Bool_Exp>;
};


export type Subscription_RootRef_File_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_File_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_File_Category_Bool_Exp>;
};


export type Subscription_RootRef_IndustryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Industry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Industry_Order_By>>;
  where?: InputMaybe<Ref_Industry_Bool_Exp>;
};


export type Subscription_RootRef_Industry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Industry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Industry_Order_By>>;
  where?: InputMaybe<Ref_Industry_Bool_Exp>;
};


export type Subscription_RootRef_Industry_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Industry_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Industry_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Industry_Bool_Exp>;
};


export type Subscription_RootRef_Integration_File_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Integration_File_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Integration_File_Status_Order_By>>;
  where?: InputMaybe<Ref_Integration_File_Status_Bool_Exp>;
};


export type Subscription_RootRef_Integration_File_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Integration_File_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Integration_File_Status_Order_By>>;
  where?: InputMaybe<Ref_Integration_File_Status_Bool_Exp>;
};


export type Subscription_RootRef_Integration_File_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Integration_File_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Integration_File_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Integration_File_Status_Bool_Exp>;
};


export type Subscription_RootRef_Integration_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Integration_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Integration_Status_Order_By>>;
  where?: InputMaybe<Ref_Integration_Status_Bool_Exp>;
};


export type Subscription_RootRef_Integration_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Integration_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Integration_Status_Order_By>>;
  where?: InputMaybe<Ref_Integration_Status_Bool_Exp>;
};


export type Subscription_RootRef_Integration_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Integration_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Integration_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Integration_Status_Bool_Exp>;
};


export type Subscription_RootRef_Investor_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Investor_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Investor_Status_Order_By>>;
  where?: InputMaybe<Ref_Investor_Status_Bool_Exp>;
};


export type Subscription_RootRef_Investor_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Investor_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Investor_Status_Order_By>>;
  where?: InputMaybe<Ref_Investor_Status_Bool_Exp>;
};


export type Subscription_RootRef_Investor_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Investor_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Investor_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Investor_Status_Bool_Exp>;
};


export type Subscription_RootRef_Investor_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Investor_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Investor_Type_Order_By>>;
  where?: InputMaybe<Ref_Investor_Type_Bool_Exp>;
};


export type Subscription_RootRef_Investor_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Investor_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Investor_Type_Order_By>>;
  where?: InputMaybe<Ref_Investor_Type_Bool_Exp>;
};


export type Subscription_RootRef_Investor_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootRef_Investor_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Investor_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Investor_Type_Bool_Exp>;
};


export type Subscription_RootRef_Lifecycle_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Ref_Lifecycle_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Lifecycle_Question_Order_By>>;
  where?: InputMaybe<Ref_Lifecycle_Question_Bool_Exp>;
};


export type Subscription_RootRef_Lifecycle_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Lifecycle_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Lifecycle_Question_Order_By>>;
  where?: InputMaybe<Ref_Lifecycle_Question_Bool_Exp>;
};


export type Subscription_RootRef_Lifecycle_Question_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRef_Lifecycle_Question_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Lifecycle_Question_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Lifecycle_Question_Category_Order_By>>;
  where?: InputMaybe<Ref_Lifecycle_Question_Category_Bool_Exp>;
};


export type Subscription_RootRef_Lifecycle_Question_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Lifecycle_Question_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Lifecycle_Question_Category_Order_By>>;
  where?: InputMaybe<Ref_Lifecycle_Question_Category_Bool_Exp>;
};


export type Subscription_RootRef_Lifecycle_Question_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Lifecycle_Question_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Lifecycle_Question_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Lifecycle_Question_Category_Bool_Exp>;
};


export type Subscription_RootRef_Lifecycle_Question_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Lifecycle_Question_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Lifecycle_Question_Bool_Exp>;
};


export type Subscription_RootRef_Mandate_FrequencyArgs = {
  distinct_on?: InputMaybe<Array<Ref_Mandate_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Mandate_Frequency_Order_By>>;
  where?: InputMaybe<Ref_Mandate_Frequency_Bool_Exp>;
};


export type Subscription_RootRef_Mandate_Frequency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Mandate_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Mandate_Frequency_Order_By>>;
  where?: InputMaybe<Ref_Mandate_Frequency_Bool_Exp>;
};


export type Subscription_RootRef_Mandate_Frequency_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Mandate_Frequency_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Mandate_Frequency_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Mandate_Frequency_Bool_Exp>;
};


export type Subscription_RootRef_Mandate_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Mandate_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Mandate_Status_Order_By>>;
  where?: InputMaybe<Ref_Mandate_Status_Bool_Exp>;
};


export type Subscription_RootRef_Mandate_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Mandate_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Mandate_Status_Order_By>>;
  where?: InputMaybe<Ref_Mandate_Status_Bool_Exp>;
};


export type Subscription_RootRef_Mandate_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Mandate_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Mandate_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Mandate_Status_Bool_Exp>;
};


export type Subscription_RootRef_Mandate_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Mandate_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Mandate_Type_Order_By>>;
  where?: InputMaybe<Ref_Mandate_Type_Bool_Exp>;
};


export type Subscription_RootRef_Mandate_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Mandate_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Mandate_Type_Order_By>>;
  where?: InputMaybe<Ref_Mandate_Type_Bool_Exp>;
};


export type Subscription_RootRef_Mandate_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Mandate_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Mandate_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Mandate_Type_Bool_Exp>;
};


export type Subscription_RootRef_Perfios_Bank_ListArgs = {
  distinct_on?: InputMaybe<Array<Ref_Perfios_Bank_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Perfios_Bank_List_Order_By>>;
  where?: InputMaybe<Ref_Perfios_Bank_List_Bool_Exp>;
};


export type Subscription_RootRef_Perfios_Bank_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Perfios_Bank_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Perfios_Bank_List_Order_By>>;
  where?: InputMaybe<Ref_Perfios_Bank_List_Bool_Exp>;
};


export type Subscription_RootRef_Perfios_Bank_List_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRef_Perfios_Bank_List_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Perfios_Bank_List_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Perfios_Bank_List_Bool_Exp>;
};


export type Subscription_RootRef_Report_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Report_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Report_Category_Order_By>>;
  where?: InputMaybe<Ref_Report_Category_Bool_Exp>;
};


export type Subscription_RootRef_Report_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Report_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Report_Category_Order_By>>;
  where?: InputMaybe<Ref_Report_Category_Bool_Exp>;
};


export type Subscription_RootRef_Report_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Report_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Report_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Report_Category_Bool_Exp>;
};


export type Subscription_RootRef_RoleArgs = {
  distinct_on?: InputMaybe<Array<Ref_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Role_Order_By>>;
  where?: InputMaybe<Ref_Role_Bool_Exp>;
};


export type Subscription_RootRef_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Role_Order_By>>;
  where?: InputMaybe<Ref_Role_Bool_Exp>;
};


export type Subscription_RootRef_Role_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Role_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Role_Bool_Exp>;
};


export type Subscription_RootRef_Schedule_Checklist_Reminder_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Schedule_Checklist_Reminder_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Schedule_Checklist_Reminder_Status_Order_By>>;
  where?: InputMaybe<Ref_Schedule_Checklist_Reminder_Status_Bool_Exp>;
};


export type Subscription_RootRef_Schedule_Checklist_Reminder_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Schedule_Checklist_Reminder_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Schedule_Checklist_Reminder_Status_Order_By>>;
  where?: InputMaybe<Ref_Schedule_Checklist_Reminder_Status_Bool_Exp>;
};


export type Subscription_RootRef_Schedule_Checklist_Reminder_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Schedule_Checklist_Reminder_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Schedule_Checklist_Reminder_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Schedule_Checklist_Reminder_Status_Bool_Exp>;
};


export type Subscription_RootRef_Score_FactorArgs = {
  distinct_on?: InputMaybe<Array<Ref_Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Score_Factor_Order_By>>;
  where?: InputMaybe<Ref_Score_Factor_Bool_Exp>;
};


export type Subscription_RootRef_Score_Factor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Score_Factor_Order_By>>;
  where?: InputMaybe<Ref_Score_Factor_Bool_Exp>;
};


export type Subscription_RootRef_Score_Factor_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Score_Factor_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Ref_Score_Factor_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Score_Factor_Category_Order_By>>;
  where?: InputMaybe<Ref_Score_Factor_Category_Bool_Exp>;
};


export type Subscription_RootRef_Score_Factor_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Score_Factor_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Score_Factor_Category_Order_By>>;
  where?: InputMaybe<Ref_Score_Factor_Category_Bool_Exp>;
};


export type Subscription_RootRef_Score_Factor_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Score_Factor_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Score_Factor_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Score_Factor_Category_Bool_Exp>;
};


export type Subscription_RootRef_Score_Factor_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Score_Factor_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Score_Factor_Bool_Exp>;
};


export type Subscription_RootRef_Score_Factor_UnitArgs = {
  distinct_on?: InputMaybe<Array<Ref_Score_Factor_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Score_Factor_Unit_Order_By>>;
  where?: InputMaybe<Ref_Score_Factor_Unit_Bool_Exp>;
};


export type Subscription_RootRef_Score_Factor_Unit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Score_Factor_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Score_Factor_Unit_Order_By>>;
  where?: InputMaybe<Ref_Score_Factor_Unit_Bool_Exp>;
};


export type Subscription_RootRef_Score_Factor_Unit_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Score_Factor_Unit_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Score_Factor_Unit_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Score_Factor_Unit_Bool_Exp>;
};


export type Subscription_RootRef_SourceArgs = {
  distinct_on?: InputMaybe<Array<Ref_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Source_Order_By>>;
  where?: InputMaybe<Ref_Source_Bool_Exp>;
};


export type Subscription_RootRef_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Source_Order_By>>;
  where?: InputMaybe<Ref_Source_Bool_Exp>;
};


export type Subscription_RootRef_Source_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Source_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Source_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Source_Bool_Exp>;
};


export type Subscription_RootRef_StatesArgs = {
  distinct_on?: InputMaybe<Array<Ref_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_States_Order_By>>;
  where?: InputMaybe<Ref_States_Bool_Exp>;
};


export type Subscription_RootRef_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_States_Order_By>>;
  where?: InputMaybe<Ref_States_Bool_Exp>;
};


export type Subscription_RootRef_States_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRef_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_States_Bool_Exp>;
};


export type Subscription_RootRef_Subscription_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Subscription_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Subscription_Status_Order_By>>;
  where?: InputMaybe<Ref_Subscription_Status_Bool_Exp>;
};


export type Subscription_RootRef_Subscription_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Subscription_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Subscription_Status_Order_By>>;
  where?: InputMaybe<Ref_Subscription_Status_Bool_Exp>;
};


export type Subscription_RootRef_Subscription_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Subscription_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Subscription_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Subscription_Status_Bool_Exp>;
};


export type Subscription_RootRef_System_AccountArgs = {
  distinct_on?: InputMaybe<Array<Ref_System_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_System_Account_Order_By>>;
  where?: InputMaybe<Ref_System_Account_Bool_Exp>;
};


export type Subscription_RootRef_System_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_System_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_System_Account_Order_By>>;
  where?: InputMaybe<Ref_System_Account_Bool_Exp>;
};


export type Subscription_RootRef_System_Account_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRef_System_Account_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_System_Account_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_System_Account_Status_Order_By>>;
  where?: InputMaybe<Ref_System_Account_Status_Bool_Exp>;
};


export type Subscription_RootRef_System_Account_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_System_Account_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_System_Account_Status_Order_By>>;
  where?: InputMaybe<Ref_System_Account_Status_Bool_Exp>;
};


export type Subscription_RootRef_System_Account_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_System_Account_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_System_Account_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_System_Account_Status_Bool_Exp>;
};


export type Subscription_RootRef_System_Account_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_System_Account_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_System_Account_Bool_Exp>;
};


export type Subscription_RootRef_System_Account_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_System_Account_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_System_Account_Type_Order_By>>;
  where?: InputMaybe<Ref_System_Account_Type_Bool_Exp>;
};


export type Subscription_RootRef_System_Account_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_System_Account_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_System_Account_Type_Order_By>>;
  where?: InputMaybe<Ref_System_Account_Type_Bool_Exp>;
};


export type Subscription_RootRef_System_Account_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_System_Account_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_System_Account_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_System_Account_Type_Bool_Exp>;
};


export type Subscription_RootRef_Tds_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Tds_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Tds_Status_Order_By>>;
  where?: InputMaybe<Ref_Tds_Status_Bool_Exp>;
};


export type Subscription_RootRef_Tds_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Tds_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Tds_Status_Order_By>>;
  where?: InputMaybe<Ref_Tds_Status_Bool_Exp>;
};


export type Subscription_RootRef_Tds_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Tds_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Tds_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Tds_Status_Bool_Exp>;
};


export type Subscription_RootRef_Tds_Value_UnitArgs = {
  distinct_on?: InputMaybe<Array<Ref_Tds_Value_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Tds_Value_Unit_Order_By>>;
  where?: InputMaybe<Ref_Tds_Value_Unit_Bool_Exp>;
};


export type Subscription_RootRef_Tds_Value_Unit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Tds_Value_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Tds_Value_Unit_Order_By>>;
  where?: InputMaybe<Ref_Tds_Value_Unit_Bool_Exp>;
};


export type Subscription_RootRef_Tds_Value_Unit_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Tds_Value_Unit_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Tds_Value_Unit_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Tds_Value_Unit_Bool_Exp>;
};


export type Subscription_RootRef_Tenant_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Tenant_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Tenant_Type_Order_By>>;
  where?: InputMaybe<Ref_Tenant_Type_Bool_Exp>;
};


export type Subscription_RootRef_Tenant_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Tenant_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Tenant_Type_Order_By>>;
  where?: InputMaybe<Ref_Tenant_Type_Bool_Exp>;
};


export type Subscription_RootRef_Tenant_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Tenant_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Tenant_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Tenant_Type_Bool_Exp>;
};


export type Subscription_RootRef_Trade_StateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Trade_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Trade_State_Order_By>>;
  where?: InputMaybe<Ref_Trade_State_Bool_Exp>;
};


export type Subscription_RootRef_Trade_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Trade_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Trade_State_Order_By>>;
  where?: InputMaybe<Ref_Trade_State_Bool_Exp>;
};


export type Subscription_RootRef_Trade_State_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Trade_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Trade_State_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Trade_State_Bool_Exp>;
};


export type Subscription_RootRef_Trade_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Trade_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Trade_Status_Order_By>>;
  where?: InputMaybe<Ref_Trade_Status_Bool_Exp>;
};


export type Subscription_RootRef_Trade_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Trade_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Trade_Status_Order_By>>;
  where?: InputMaybe<Ref_Trade_Status_Bool_Exp>;
};


export type Subscription_RootRef_Trade_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Trade_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Trade_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Trade_Status_Bool_Exp>;
};


export type Subscription_RootRef_Trade_Withdrawal_ModeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Trade_Withdrawal_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Trade_Withdrawal_Mode_Order_By>>;
  where?: InputMaybe<Ref_Trade_Withdrawal_Mode_Bool_Exp>;
};


export type Subscription_RootRef_Trade_Withdrawal_Mode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Trade_Withdrawal_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Trade_Withdrawal_Mode_Order_By>>;
  where?: InputMaybe<Ref_Trade_Withdrawal_Mode_Bool_Exp>;
};


export type Subscription_RootRef_Trade_Withdrawal_Mode_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Trade_Withdrawal_Mode_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Trade_Withdrawal_Mode_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Trade_Withdrawal_Mode_Bool_Exp>;
};


export type Subscription_RootRef_Transaction_MethodArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transaction_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transaction_Method_Order_By>>;
  where?: InputMaybe<Ref_Transaction_Method_Bool_Exp>;
};


export type Subscription_RootRef_Transaction_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transaction_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transaction_Method_Order_By>>;
  where?: InputMaybe<Ref_Transaction_Method_Bool_Exp>;
};


export type Subscription_RootRef_Transaction_Method_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Transaction_Method_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Transaction_Method_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Transaction_Method_Bool_Exp>;
};


export type Subscription_RootRef_Transaction_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transaction_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transaction_Status_Order_By>>;
  where?: InputMaybe<Ref_Transaction_Status_Bool_Exp>;
};


export type Subscription_RootRef_Transaction_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transaction_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transaction_Status_Order_By>>;
  where?: InputMaybe<Ref_Transaction_Status_Bool_Exp>;
};


export type Subscription_RootRef_Transaction_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Transaction_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Transaction_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Transaction_Status_Bool_Exp>;
};


export type Subscription_RootRef_Transaction_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transaction_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transaction_Type_Order_By>>;
  where?: InputMaybe<Ref_Transaction_Type_Bool_Exp>;
};


export type Subscription_RootRef_Transaction_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transaction_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transaction_Type_Order_By>>;
  where?: InputMaybe<Ref_Transaction_Type_Bool_Exp>;
};


export type Subscription_RootRef_Transaction_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Transaction_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Transaction_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Transaction_Type_Bool_Exp>;
};


export type Subscription_RootRef_Transfer_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transfer_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transfer_Status_Order_By>>;
  where?: InputMaybe<Ref_Transfer_Status_Bool_Exp>;
};


export type Subscription_RootRef_Transfer_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transfer_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transfer_Status_Order_By>>;
  where?: InputMaybe<Ref_Transfer_Status_Bool_Exp>;
};


export type Subscription_RootRef_Transfer_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Transfer_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Transfer_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Transfer_Status_Bool_Exp>;
};


export type Subscription_RootRef_Transfer_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transfer_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transfer_Type_Order_By>>;
  where?: InputMaybe<Ref_Transfer_Type_Bool_Exp>;
};


export type Subscription_RootRef_Transfer_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_Transfer_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_Transfer_Type_Order_By>>;
  where?: InputMaybe<Ref_Transfer_Type_Bool_Exp>;
};


export type Subscription_RootRef_Transfer_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_Transfer_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_Transfer_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_Transfer_Type_Bool_Exp>;
};


export type Subscription_RootRef_User_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ref_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_User_Status_Order_By>>;
  where?: InputMaybe<Ref_User_Status_Bool_Exp>;
};


export type Subscription_RootRef_User_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_User_Status_Order_By>>;
  where?: InputMaybe<Ref_User_Status_Bool_Exp>;
};


export type Subscription_RootRef_User_Status_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_User_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_User_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_User_Status_Bool_Exp>;
};


export type Subscription_RootRef_User_TypeArgs = {
  distinct_on?: InputMaybe<Array<Ref_User_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_User_Type_Order_By>>;
  where?: InputMaybe<Ref_User_Type_Bool_Exp>;
};


export type Subscription_RootRef_User_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ref_User_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ref_User_Type_Order_By>>;
  where?: InputMaybe<Ref_User_Type_Bool_Exp>;
};


export type Subscription_RootRef_User_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRef_User_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ref_User_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Ref_User_Type_Bool_Exp>;
};


export type Subscription_RootReport_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Report_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Report_Category_Order_By>>;
  where?: InputMaybe<Report_Category_Bool_Exp>;
};


export type Subscription_RootReport_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Report_Category_Order_By>>;
  where?: InputMaybe<Report_Category_Bool_Exp>;
};


export type Subscription_RootReport_Category_By_PkArgs = {
  ref_report_category_id: Ref_Report_Category_Enum;
};


export type Subscription_RootReport_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Report_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Report_Category_Bool_Exp>;
};


export type Subscription_RootReport_EventArgs = {
  distinct_on?: InputMaybe<Array<Report_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Report_Event_Order_By>>;
  where?: InputMaybe<Report_Event_Bool_Exp>;
};


export type Subscription_RootReport_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Report_Event_Order_By>>;
  where?: InputMaybe<Report_Event_Bool_Exp>;
};


export type Subscription_RootReport_Event_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootReport_Event_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Report_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Report_Event_Bool_Exp>;
};


export type Subscription_RootSchedule_Document_Checklist_ReminderArgs = {
  distinct_on?: InputMaybe<Array<Schedule_Document_Checklist_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Schedule_Document_Checklist_Reminder_Order_By>>;
  where?: InputMaybe<Schedule_Document_Checklist_Reminder_Bool_Exp>;
};


export type Subscription_RootSchedule_Document_Checklist_Reminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Schedule_Document_Checklist_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Schedule_Document_Checklist_Reminder_Order_By>>;
  where?: InputMaybe<Schedule_Document_Checklist_Reminder_Bool_Exp>;
};


export type Subscription_RootSchedule_Document_Checklist_Reminder_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSchedule_Document_Checklist_Reminder_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Schedule_Document_Checklist_Reminder_Stream_Cursor_Input>>;
  where?: InputMaybe<Schedule_Document_Checklist_Reminder_Bool_Exp>;
};


export type Subscription_RootScheduled_EmiArgs = {
  distinct_on?: InputMaybe<Array<Scheduled_Emi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scheduled_Emi_Order_By>>;
  where?: InputMaybe<Scheduled_Emi_Bool_Exp>;
};


export type Subscription_RootScheduled_Emi_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scheduled_Emi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scheduled_Emi_Order_By>>;
  where?: InputMaybe<Scheduled_Emi_Bool_Exp>;
};


export type Subscription_RootScheduled_Emi_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootScheduled_Emi_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Scheduled_Emi_Stream_Cursor_Input>>;
  where?: InputMaybe<Scheduled_Emi_Bool_Exp>;
};


export type Subscription_RootScore_FactorArgs = {
  distinct_on?: InputMaybe<Array<Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Score_Factor_Order_By>>;
  where?: InputMaybe<Score_Factor_Bool_Exp>;
};


export type Subscription_RootScore_Factor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Score_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Score_Factor_Order_By>>;
  where?: InputMaybe<Score_Factor_Bool_Exp>;
};


export type Subscription_RootScore_Factor_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootScore_Factor_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Score_Factor_Stream_Cursor_Input>>;
  where?: InputMaybe<Score_Factor_Bool_Exp>;
};


export type Subscription_RootStaff_UserArgs = {
  distinct_on?: InputMaybe<Array<Staff_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_User_Order_By>>;
  where?: InputMaybe<Staff_User_Bool_Exp>;
};


export type Subscription_RootStaff_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_User_Order_By>>;
  where?: InputMaybe<Staff_User_Bool_Exp>;
};


export type Subscription_RootStaff_User_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStaff_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Staff_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Staff_User_Bool_Exp>;
};


export type Subscription_RootSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Subscription_RootSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Subscription_RootSubscription_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSubscription_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Subscription_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Subscription_RootTdsArgs = {
  distinct_on?: InputMaybe<Array<Tds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tds_Order_By>>;
  where?: InputMaybe<Tds_Bool_Exp>;
};


export type Subscription_RootTds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tds_Order_By>>;
  where?: InputMaybe<Tds_Bool_Exp>;
};


export type Subscription_RootTds_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTds_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tds_Stream_Cursor_Input>>;
  where?: InputMaybe<Tds_Bool_Exp>;
};


export type Subscription_RootTradeArgs = {
  distinct_on?: InputMaybe<Array<Trade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Order_By>>;
  where?: InputMaybe<Trade_Bool_Exp>;
};


export type Subscription_RootTrade_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Order_By>>;
  where?: InputMaybe<Trade_Bool_Exp>;
};


export type Subscription_RootTrade_AmortizationArgs = {
  distinct_on?: InputMaybe<Array<Trade_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Amortization_Order_By>>;
  where?: InputMaybe<Trade_Amortization_Bool_Exp>;
};


export type Subscription_RootTrade_Amortization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trade_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Amortization_Order_By>>;
  where?: InputMaybe<Trade_Amortization_Bool_Exp>;
};


export type Subscription_RootTrade_Amortization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Trade_Amortization_Stream_Cursor_Input>>;
  where?: InputMaybe<Trade_Amortization_Bool_Exp>;
};


export type Subscription_RootTrade_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTrade_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Trade_Stream_Cursor_Input>>;
  where?: InputMaybe<Trade_Bool_Exp>;
};


export type Subscription_RootTrade_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Trade_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Subscription_Order_By>>;
  where?: InputMaybe<Trade_Subscription_Bool_Exp>;
};


export type Subscription_RootTrade_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trade_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Subscription_Order_By>>;
  where?: InputMaybe<Trade_Subscription_Bool_Exp>;
};


export type Subscription_RootTrade_Subscription_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTrade_Subscription_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Trade_Subscription_Stream_Cursor_Input>>;
  where?: InputMaybe<Trade_Subscription_Bool_Exp>;
};


export type Subscription_RootTransactionArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};


export type Subscription_RootTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};


export type Subscription_RootTransaction_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTransaction_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Transaction_Stream_Cursor_Input>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};


export type Subscription_RootTransactions_ViewArgs = {
  distinct_on?: InputMaybe<Array<Transactions_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transactions_View_Order_By>>;
  where?: InputMaybe<Transactions_View_Bool_Exp>;
};


export type Subscription_RootTransactions_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transactions_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transactions_View_Order_By>>;
  where?: InputMaybe<Transactions_View_Bool_Exp>;
};


export type Subscription_RootTransactions_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Transactions_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Transactions_View_Bool_Exp>;
};


export type Subscription_RootTransferArgs = {
  distinct_on?: InputMaybe<Array<Transfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transfer_Order_By>>;
  where?: InputMaybe<Transfer_Bool_Exp>;
};


export type Subscription_RootTransfer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transfer_Order_By>>;
  where?: InputMaybe<Transfer_Bool_Exp>;
};


export type Subscription_RootTransfer_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTransfer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Transfer_Stream_Cursor_Input>>;
  where?: InputMaybe<Transfer_Bool_Exp>;
};


export type Subscription_RootUpdates_LogArgs = {
  distinct_on?: InputMaybe<Array<Updates_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Updates_Log_Order_By>>;
  where?: InputMaybe<Updates_Log_Bool_Exp>;
};


export type Subscription_RootUpdates_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Updates_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Updates_Log_Order_By>>;
  where?: InputMaybe<Updates_Log_Bool_Exp>;
};


export type Subscription_RootUpdates_Log_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUpdates_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Updates_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Updates_Log_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootVerification_CodeArgs = {
  distinct_on?: InputMaybe<Array<Verification_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verification_Code_Order_By>>;
  where?: InputMaybe<Verification_Code_Bool_Exp>;
};


export type Subscription_RootVerification_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verification_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verification_Code_Order_By>>;
  where?: InputMaybe<Verification_Code_Bool_Exp>;
};


export type Subscription_RootVerification_Code_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVerification_Code_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Verification_Code_Stream_Cursor_Input>>;
  where?: InputMaybe<Verification_Code_Bool_Exp>;
};


export type Subscription_RootWebhook_EventsArgs = {
  distinct_on?: InputMaybe<Array<Webhook_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webhook_Events_Order_By>>;
  where?: InputMaybe<Webhook_Events_Bool_Exp>;
};


export type Subscription_RootWebhook_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webhook_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webhook_Events_Order_By>>;
  where?: InputMaybe<Webhook_Events_Bool_Exp>;
};


export type Subscription_RootWebhook_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWebhook_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Webhook_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Webhook_Events_Bool_Exp>;
};

/** select columns of table "subscription" */
export enum Subscription_Select_Column {
  /** column name */
  AmountPerMonth = 'amount_per_month',
  /** column name */
  AmountPerPeriod = 'amount_per_period',
  /** column name */
  BillingPeriod = 'billing_period',
  /** column name */
  BillingPeriodInterval = 'billing_period_interval',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyIntegrationId = 'company_integration_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerCreated = 'customer_created',
  /** column name */
  CustomerEmail = 'customer_email',
  /** column name */
  CustomerIdentifier = 'customer_identifier',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EligibilityScore = 'eligibility_score',
  /** column name */
  Id = 'id',
  /** column name */
  IsLatest = 'is_latest',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  TaxInclusive = 'tax_inclusive',
  /** column name */
  TaxRate = 'tax_rate',
  /** column name */
  TermEnd = 'term_end',
  /** column name */
  TermStart = 'term_start',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "subscription" */
export type Subscription_Set_Input = {
  amount_per_month?: InputMaybe<Scalars['float8']>;
  amount_per_period?: InputMaybe<Scalars['float8']>;
  billing_period?: InputMaybe<Scalars['String']>;
  billing_period_interval?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_integration_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  customer_created?: InputMaybe<Scalars['timestamptz']>;
  customer_email?: InputMaybe<Scalars['String']>;
  customer_identifier?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  eligibility_score?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_latest?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Ref_Subscription_Status_Enum>;
  subscription_id?: InputMaybe<Scalars['String']>;
  tax_inclusive?: InputMaybe<Scalars['Boolean']>;
  tax_rate?: InputMaybe<Scalars['float8']>;
  term_end?: InputMaybe<Scalars['timestamptz']>;
  term_start?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Subscription_Stddev_Fields = {
  __typename?: 'subscription_stddev_fields';
  amount_per_month?: Maybe<Scalars['Float']>;
  amount_per_period?: Maybe<Scalars['Float']>;
  billing_period_interval?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  company_integration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  eligibility_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tax_rate?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subscription_Stddev_Pop_Fields = {
  __typename?: 'subscription_stddev_pop_fields';
  amount_per_month?: Maybe<Scalars['Float']>;
  amount_per_period?: Maybe<Scalars['Float']>;
  billing_period_interval?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  company_integration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  eligibility_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tax_rate?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subscription_Stddev_Samp_Fields = {
  __typename?: 'subscription_stddev_samp_fields';
  amount_per_month?: Maybe<Scalars['Float']>;
  amount_per_period?: Maybe<Scalars['Float']>;
  billing_period_interval?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  company_integration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  eligibility_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tax_rate?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "subscription" */
export type Subscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Stream_Cursor_Value_Input = {
  amount_per_month?: InputMaybe<Scalars['float8']>;
  amount_per_period?: InputMaybe<Scalars['float8']>;
  billing_period?: InputMaybe<Scalars['String']>;
  billing_period_interval?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_integration_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  customer_created?: InputMaybe<Scalars['timestamptz']>;
  customer_email?: InputMaybe<Scalars['String']>;
  customer_identifier?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  eligibility_score?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_latest?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Ref_Subscription_Status_Enum>;
  subscription_id?: InputMaybe<Scalars['String']>;
  tax_inclusive?: InputMaybe<Scalars['Boolean']>;
  tax_rate?: InputMaybe<Scalars['float8']>;
  term_end?: InputMaybe<Scalars['timestamptz']>;
  term_start?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Subscription_Sum_Fields = {
  __typename?: 'subscription_sum_fields';
  amount_per_month?: Maybe<Scalars['float8']>;
  amount_per_period?: Maybe<Scalars['float8']>;
  billing_period_interval?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  company_integration_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  eligibility_score?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tax_rate?: Maybe<Scalars['float8']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "subscription" */
export enum Subscription_Update_Column {
  /** column name */
  AmountPerMonth = 'amount_per_month',
  /** column name */
  AmountPerPeriod = 'amount_per_period',
  /** column name */
  BillingPeriod = 'billing_period',
  /** column name */
  BillingPeriodInterval = 'billing_period_interval',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyIntegrationId = 'company_integration_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerCreated = 'customer_created',
  /** column name */
  CustomerEmail = 'customer_email',
  /** column name */
  CustomerIdentifier = 'customer_identifier',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EligibilityScore = 'eligibility_score',
  /** column name */
  Id = 'id',
  /** column name */
  IsLatest = 'is_latest',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  TaxInclusive = 'tax_inclusive',
  /** column name */
  TaxRate = 'tax_rate',
  /** column name */
  TermEnd = 'term_end',
  /** column name */
  TermStart = 'term_start',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Verified = 'verified'
}

export type Subscription_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Subscription_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Subscription_Var_Pop_Fields = {
  __typename?: 'subscription_var_pop_fields';
  amount_per_month?: Maybe<Scalars['Float']>;
  amount_per_period?: Maybe<Scalars['Float']>;
  billing_period_interval?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  company_integration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  eligibility_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tax_rate?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subscription_Var_Samp_Fields = {
  __typename?: 'subscription_var_samp_fields';
  amount_per_month?: Maybe<Scalars['Float']>;
  amount_per_period?: Maybe<Scalars['Float']>;
  billing_period_interval?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  company_integration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  eligibility_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tax_rate?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subscription_Variance_Fields = {
  __typename?: 'subscription_variance_fields';
  amount_per_month?: Maybe<Scalars['Float']>;
  amount_per_period?: Maybe<Scalars['Float']>;
  billing_period_interval?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  company_integration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  eligibility_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tax_rate?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "tds" */
export type Tds = {
  __typename?: 'tds';
  amount: Scalars['float8'];
  /** An object relationship */
  company: Company;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  /** An array relationship */
  emi_tds: Array<Emi_Tds>;
  /** An aggregate relationship */
  emi_tds_aggregate: Emi_Tds_Aggregate;
  id: Scalars['Int'];
  /** An object relationship */
  investment: Investment;
  investment_id: Scalars['Int'];
  /** An object relationship */
  ref_tds_status: Ref_Tds_Status;
  /** An object relationship */
  ref_tds_value_unit?: Maybe<Ref_Tds_Value_Unit>;
  remarks?: Maybe<Scalars['String']>;
  status: Ref_Tds_Status_Enum;
  tds_files: Scalars['jsonb'];
  /** An object relationship */
  trade: Trade;
  trade_id: Scalars['Int'];
  /** An object relationship */
  transaction: Transaction;
  transaction_id: Scalars['Int'];
  unit?: Maybe<Ref_Tds_Value_Unit_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "tds" */
export type TdsEmi_TdsArgs = {
  distinct_on?: InputMaybe<Array<Emi_Tds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emi_Tds_Order_By>>;
  where?: InputMaybe<Emi_Tds_Bool_Exp>;
};


/** columns and relationships of "tds" */
export type TdsEmi_Tds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Emi_Tds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emi_Tds_Order_By>>;
  where?: InputMaybe<Emi_Tds_Bool_Exp>;
};


/** columns and relationships of "tds" */
export type TdsTds_FilesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "tds" */
export type Tds_Aggregate = {
  __typename?: 'tds_aggregate';
  aggregate?: Maybe<Tds_Aggregate_Fields>;
  nodes: Array<Tds>;
};

export type Tds_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Tds_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Tds_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Tds_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Tds_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Tds_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Tds_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Tds_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Tds_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Tds_Aggregate_Bool_Exp_Var_Samp>;
};

export type Tds_Aggregate_Bool_Exp_Avg = {
  arguments: Tds_Select_Column_Tds_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tds_Aggregate_Bool_Exp_Corr = {
  arguments: Tds_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tds_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Tds_Select_Column_Tds_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Tds_Select_Column_Tds_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Tds_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tds_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Tds_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Tds_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tds_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Tds_Select_Column_Tds_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Tds_Select_Column_Tds_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Tds_Aggregate_Bool_Exp_Max = {
  arguments: Tds_Select_Column_Tds_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tds_Aggregate_Bool_Exp_Min = {
  arguments: Tds_Select_Column_Tds_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tds_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Tds_Select_Column_Tds_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tds_Aggregate_Bool_Exp_Sum = {
  arguments: Tds_Select_Column_Tds_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tds_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Tds_Select_Column_Tds_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "tds" */
export type Tds_Aggregate_Fields = {
  __typename?: 'tds_aggregate_fields';
  avg?: Maybe<Tds_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tds_Max_Fields>;
  min?: Maybe<Tds_Min_Fields>;
  stddev?: Maybe<Tds_Stddev_Fields>;
  stddev_pop?: Maybe<Tds_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tds_Stddev_Samp_Fields>;
  sum?: Maybe<Tds_Sum_Fields>;
  var_pop?: Maybe<Tds_Var_Pop_Fields>;
  var_samp?: Maybe<Tds_Var_Samp_Fields>;
  variance?: Maybe<Tds_Variance_Fields>;
};


/** aggregate fields of "tds" */
export type Tds_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tds" */
export type Tds_Aggregate_Order_By = {
  avg?: InputMaybe<Tds_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tds_Max_Order_By>;
  min?: InputMaybe<Tds_Min_Order_By>;
  stddev?: InputMaybe<Tds_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tds_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tds_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tds_Sum_Order_By>;
  var_pop?: InputMaybe<Tds_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tds_Var_Samp_Order_By>;
  variance?: InputMaybe<Tds_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tds_Append_Input = {
  tds_files?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "tds" */
export type Tds_Arr_Rel_Insert_Input = {
  data: Array<Tds_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tds_On_Conflict>;
};

/** aggregate avg on columns */
export type Tds_Avg_Fields = {
  __typename?: 'tds_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tds" */
export type Tds_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tds". All fields are combined with a logical 'AND'. */
export type Tds_Bool_Exp = {
  _and?: InputMaybe<Array<Tds_Bool_Exp>>;
  _not?: InputMaybe<Tds_Bool_Exp>;
  _or?: InputMaybe<Array<Tds_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  emi_tds?: InputMaybe<Emi_Tds_Bool_Exp>;
  emi_tds_aggregate?: InputMaybe<Emi_Tds_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  investment?: InputMaybe<Investment_Bool_Exp>;
  investment_id?: InputMaybe<Int_Comparison_Exp>;
  ref_tds_status?: InputMaybe<Ref_Tds_Status_Bool_Exp>;
  ref_tds_value_unit?: InputMaybe<Ref_Tds_Value_Unit_Bool_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Ref_Tds_Status_Enum_Comparison_Exp>;
  tds_files?: InputMaybe<Jsonb_Comparison_Exp>;
  trade?: InputMaybe<Trade_Bool_Exp>;
  trade_id?: InputMaybe<Int_Comparison_Exp>;
  transaction?: InputMaybe<Transaction_Bool_Exp>;
  transaction_id?: InputMaybe<Int_Comparison_Exp>;
  unit?: InputMaybe<Ref_Tds_Value_Unit_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "tds" */
export enum Tds_Constraint {
  /** unique or primary key constraint on columns "id" */
  TdsPkey = 'tds_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tds_Delete_At_Path_Input = {
  tds_files?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tds_Delete_Elem_Input = {
  tds_files?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tds_Delete_Key_Input = {
  tds_files?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "tds" */
export type Tds_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  investment_id?: InputMaybe<Scalars['Int']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  transaction_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tds" */
export type Tds_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  emi_tds?: InputMaybe<Emi_Tds_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  investment?: InputMaybe<Investment_Obj_Rel_Insert_Input>;
  investment_id?: InputMaybe<Scalars['Int']>;
  ref_tds_status?: InputMaybe<Ref_Tds_Status_Obj_Rel_Insert_Input>;
  ref_tds_value_unit?: InputMaybe<Ref_Tds_Value_Unit_Obj_Rel_Insert_Input>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ref_Tds_Status_Enum>;
  tds_files?: InputMaybe<Scalars['jsonb']>;
  trade?: InputMaybe<Trade_Obj_Rel_Insert_Input>;
  trade_id?: InputMaybe<Scalars['Int']>;
  transaction?: InputMaybe<Transaction_Obj_Rel_Insert_Input>;
  transaction_id?: InputMaybe<Scalars['Int']>;
  unit?: InputMaybe<Ref_Tds_Value_Unit_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Tds_Max_Fields = {
  __typename?: 'tds_max_fields';
  amount?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  investment_id?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  trade_id?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "tds" */
export type Tds_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tds_Min_Fields = {
  __typename?: 'tds_min_fields';
  amount?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  investment_id?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  trade_id?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "tds" */
export type Tds_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tds" */
export type Tds_Mutation_Response = {
  __typename?: 'tds_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tds>;
};

/** input type for inserting object relation for remote table "tds" */
export type Tds_Obj_Rel_Insert_Input = {
  data: Tds_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tds_On_Conflict>;
};

/** on_conflict condition type for table "tds" */
export type Tds_On_Conflict = {
  constraint: Tds_Constraint;
  update_columns?: Array<Tds_Update_Column>;
  where?: InputMaybe<Tds_Bool_Exp>;
};

/** Ordering options when selecting data from "tds". */
export type Tds_Order_By = {
  amount?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_tds_aggregate?: InputMaybe<Emi_Tds_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  investment?: InputMaybe<Investment_Order_By>;
  investment_id?: InputMaybe<Order_By>;
  ref_tds_status?: InputMaybe<Ref_Tds_Status_Order_By>;
  ref_tds_value_unit?: InputMaybe<Ref_Tds_Value_Unit_Order_By>;
  remarks?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tds_files?: InputMaybe<Order_By>;
  trade?: InputMaybe<Trade_Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction?: InputMaybe<Transaction_Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tds */
export type Tds_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tds_Prepend_Input = {
  tds_files?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "tds" */
export enum Tds_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  InvestmentId = 'investment_id',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  Status = 'status',
  /** column name */
  TdsFiles = 'tds_files',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  Unit = 'unit',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "tds_aggregate_bool_exp_avg_arguments_columns" columns of table "tds" */
export enum Tds_Select_Column_Tds_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "tds_aggregate_bool_exp_corr_arguments_columns" columns of table "tds" */
export enum Tds_Select_Column_Tds_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "tds_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "tds" */
export enum Tds_Select_Column_Tds_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "tds_aggregate_bool_exp_max_arguments_columns" columns of table "tds" */
export enum Tds_Select_Column_Tds_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "tds_aggregate_bool_exp_min_arguments_columns" columns of table "tds" */
export enum Tds_Select_Column_Tds_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "tds_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "tds" */
export enum Tds_Select_Column_Tds_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "tds_aggregate_bool_exp_sum_arguments_columns" columns of table "tds" */
export enum Tds_Select_Column_Tds_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "tds_aggregate_bool_exp_var_samp_arguments_columns" columns of table "tds" */
export enum Tds_Select_Column_Tds_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** input type for updating data in table "tds" */
export type Tds_Set_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  investment_id?: InputMaybe<Scalars['Int']>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ref_Tds_Status_Enum>;
  tds_files?: InputMaybe<Scalars['jsonb']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  transaction_id?: InputMaybe<Scalars['Int']>;
  unit?: InputMaybe<Ref_Tds_Value_Unit_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Tds_Stddev_Fields = {
  __typename?: 'tds_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tds" */
export type Tds_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tds_Stddev_Pop_Fields = {
  __typename?: 'tds_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tds" */
export type Tds_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tds_Stddev_Samp_Fields = {
  __typename?: 'tds_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tds" */
export type Tds_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tds" */
export type Tds_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tds_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tds_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  investment_id?: InputMaybe<Scalars['Int']>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ref_Tds_Status_Enum>;
  tds_files?: InputMaybe<Scalars['jsonb']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  transaction_id?: InputMaybe<Scalars['Int']>;
  unit?: InputMaybe<Ref_Tds_Value_Unit_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Tds_Sum_Fields = {
  __typename?: 'tds_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  investment_id?: Maybe<Scalars['Int']>;
  trade_id?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tds" */
export type Tds_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "tds" */
export enum Tds_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  InvestmentId = 'investment_id',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  Status = 'status',
  /** column name */
  TdsFiles = 'tds_files',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  Unit = 'unit',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type Tds_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tds_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tds_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tds_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tds_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tds_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tds_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tds_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tds_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tds_Var_Pop_Fields = {
  __typename?: 'tds_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tds" */
export type Tds_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tds_Var_Samp_Fields = {
  __typename?: 'tds_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tds" */
export type Tds_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tds_Variance_Fields = {
  __typename?: 'tds_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  investment_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tds" */
export type Tds_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investment_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "trade" */
export type Trade = {
  __typename?: 'trade';
  /** An array relationship */
  amortization: Array<Trade_Amortization>;
  /** An aggregate relationship */
  amortization_aggregate: Trade_Amortization_Aggregate;
  /** An object relationship */
  apa?: Maybe<Agreement_Registry>;
  apa_agreement_id?: Maybe<Scalars['Int']>;
  available_investment_amount?: Maybe<Scalars['numeric']>;
  bid_price: Scalars['numeric'];
  calculated_customers_value?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "generate_trade_code" */
  code?: Maybe<Scalars['String']>;
  collection_mode: Ref_Collection_Mode_Enum;
  /** An object relationship */
  company: Company;
  company_amortization_table?: Maybe<Scalars['Int']>;
  /** An object relationship */
  company_amortization_table_file?: Maybe<File_Registry>;
  /** An object relationship */
  company_escrow_account?: Maybe<Account>;
  company_escrow_account_id?: Maybe<Scalars['Int']>;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  /** An object relationship */
  deal: Deal;
  deal_id: Scalars['Int'];
  debit_mode: Ref_Debit_Mode_Enum;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  emi_amount?: Maybe<Scalars['numeric']>;
  emi_start_date?: Maybe<Scalars['timestamptz']>;
  fees?: Maybe<Scalars['float8']>;
  fees_tds: Scalars['numeric'];
  fees_without_gst: Scalars['numeric'];
  id: Scalars['Int'];
  /** An array relationship */
  investment_amortization: Array<Investment_Amortization>;
  /** An aggregate relationship */
  investment_amortization_aggregate: Investment_Amortization_Aggregate;
  /** An array relationship */
  investments: Array<Investment>;
  /** An aggregate relationship */
  investments_aggregate: Investment_Aggregate;
  /** An object relationship */
  mandate?: Maybe<Mandate>;
  /** An object relationship */
  mandate_account?: Maybe<Account>;
  mandate_account_id?: Maybe<Scalars['Int']>;
  mandate_frequency?: Maybe<Ref_Mandate_Frequency_Enum>;
  mandate_id?: Maybe<Scalars['Int']>;
  mandate_no_of_months?: Maybe<Scalars['Int']>;
  mandate_type?: Maybe<Ref_Mandate_Type_Enum>;
  max_presentation_amount?: Maybe<Scalars['numeric']>;
  metadata?: Maybe<Scalars['jsonb']>;
  payout_on?: Maybe<Scalars['timestamptz']>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: Maybe<Scalars['float8']>;
  /** An object relationship */
  ref_bid?: Maybe<Ref_Bid>;
  /** An object relationship */
  ref_mandate_frequency?: Maybe<Ref_Mandate_Frequency>;
  remarks?: Maybe<Scalars['String']>;
  roi?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  scheduled_emis: Array<Scheduled_Emi>;
  /** An aggregate relationship */
  scheduled_emis_aggregate: Scheduled_Emi_Aggregate;
  selected_customers_value?: Maybe<Scalars['float8']>;
  /** An object relationship */
  settlement_account?: Maybe<Account>;
  settlement_account_id?: Maybe<Scalars['Int']>;
  signing_inputs?: Maybe<Scalars['jsonb']>;
  state: Ref_Trade_State_Enum;
  status: Ref_Trade_Status_Enum;
  /** An object relationship */
  trade_escrow_account?: Maybe<Account>;
  trade_escrow_account_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  trade_subscriptions: Array<Trade_Subscription>;
  /** An aggregate relationship */
  trade_subscriptions_aggregate: Trade_Subscription_Aggregate;
  /** An array relationship */
  transactions: Array<Transaction>;
  /** An aggregate relationship */
  transactions_aggregate: Transaction_Aggregate;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
  validity: Scalars['Int'];
};


/** columns and relationships of "trade" */
export type TradeAmortizationArgs = {
  distinct_on?: InputMaybe<Array<Trade_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Amortization_Order_By>>;
  where?: InputMaybe<Trade_Amortization_Bool_Exp>;
};


/** columns and relationships of "trade" */
export type TradeAmortization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trade_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Amortization_Order_By>>;
  where?: InputMaybe<Trade_Amortization_Bool_Exp>;
};


/** columns and relationships of "trade" */
export type TradeInvestment_AmortizationArgs = {
  distinct_on?: InputMaybe<Array<Investment_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Amortization_Order_By>>;
  where?: InputMaybe<Investment_Amortization_Bool_Exp>;
};


/** columns and relationships of "trade" */
export type TradeInvestment_Amortization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investment_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Amortization_Order_By>>;
  where?: InputMaybe<Investment_Amortization_Bool_Exp>;
};


/** columns and relationships of "trade" */
export type TradeInvestmentsArgs = {
  distinct_on?: InputMaybe<Array<Investment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Order_By>>;
  where?: InputMaybe<Investment_Bool_Exp>;
};


/** columns and relationships of "trade" */
export type TradeInvestments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Order_By>>;
  where?: InputMaybe<Investment_Bool_Exp>;
};


/** columns and relationships of "trade" */
export type TradeMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "trade" */
export type TradeScheduled_EmisArgs = {
  distinct_on?: InputMaybe<Array<Scheduled_Emi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scheduled_Emi_Order_By>>;
  where?: InputMaybe<Scheduled_Emi_Bool_Exp>;
};


/** columns and relationships of "trade" */
export type TradeScheduled_Emis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scheduled_Emi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scheduled_Emi_Order_By>>;
  where?: InputMaybe<Scheduled_Emi_Bool_Exp>;
};


/** columns and relationships of "trade" */
export type TradeSigning_InputsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "trade" */
export type TradeTrade_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Trade_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Subscription_Order_By>>;
  where?: InputMaybe<Trade_Subscription_Bool_Exp>;
};


/** columns and relationships of "trade" */
export type TradeTrade_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trade_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trade_Subscription_Order_By>>;
  where?: InputMaybe<Trade_Subscription_Bool_Exp>;
};


/** columns and relationships of "trade" */
export type TradeTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};


/** columns and relationships of "trade" */
export type TradeTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};

/** aggregated selection of "trade" */
export type Trade_Aggregate = {
  __typename?: 'trade_aggregate';
  aggregate?: Maybe<Trade_Aggregate_Fields>;
  nodes: Array<Trade>;
};

export type Trade_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Trade_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Trade_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Trade_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Trade_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Trade_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Trade_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Trade_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Trade_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Trade_Aggregate_Bool_Exp_Var_Samp>;
};

export type Trade_Aggregate_Bool_Exp_Avg = {
  arguments: Trade_Select_Column_Trade_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Trade_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Trade_Aggregate_Bool_Exp_Corr = {
  arguments: Trade_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Trade_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Trade_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Trade_Select_Column_Trade_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Trade_Select_Column_Trade_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Trade_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Trade_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Trade_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Trade_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Trade_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Trade_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Trade_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Trade_Select_Column_Trade_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Trade_Select_Column_Trade_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Trade_Aggregate_Bool_Exp_Max = {
  arguments: Trade_Select_Column_Trade_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Trade_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Trade_Aggregate_Bool_Exp_Min = {
  arguments: Trade_Select_Column_Trade_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Trade_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Trade_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Trade_Select_Column_Trade_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Trade_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Trade_Aggregate_Bool_Exp_Sum = {
  arguments: Trade_Select_Column_Trade_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Trade_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Trade_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Trade_Select_Column_Trade_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Trade_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "trade" */
export type Trade_Aggregate_Fields = {
  __typename?: 'trade_aggregate_fields';
  avg?: Maybe<Trade_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Trade_Max_Fields>;
  min?: Maybe<Trade_Min_Fields>;
  stddev?: Maybe<Trade_Stddev_Fields>;
  stddev_pop?: Maybe<Trade_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Trade_Stddev_Samp_Fields>;
  sum?: Maybe<Trade_Sum_Fields>;
  var_pop?: Maybe<Trade_Var_Pop_Fields>;
  var_samp?: Maybe<Trade_Var_Samp_Fields>;
  variance?: Maybe<Trade_Variance_Fields>;
};


/** aggregate fields of "trade" */
export type Trade_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trade_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "trade" */
export type Trade_Aggregate_Order_By = {
  avg?: InputMaybe<Trade_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Trade_Max_Order_By>;
  min?: InputMaybe<Trade_Min_Order_By>;
  stddev?: InputMaybe<Trade_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Trade_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Trade_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Trade_Sum_Order_By>;
  var_pop?: InputMaybe<Trade_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Trade_Var_Samp_Order_By>;
  variance?: InputMaybe<Trade_Variance_Order_By>;
};

/** columns and relationships of "trade_amortization" */
export type Trade_Amortization = {
  __typename?: 'trade_amortization';
  amount?: Maybe<Scalars['numeric']>;
  capital?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  interest?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  investment_amortization: Array<Investment_Amortization>;
  /** An aggregate relationship */
  investment_amortization_aggregate: Investment_Amortization_Aggregate;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  scheduled_emi?: Maybe<Scheduled_Emi>;
  status?: Maybe<Scalars['String']>;
  tds?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  trade?: Maybe<Trade>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "trade_amortization" */
export type Trade_AmortizationInvestment_AmortizationArgs = {
  distinct_on?: InputMaybe<Array<Investment_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Amortization_Order_By>>;
  where?: InputMaybe<Investment_Amortization_Bool_Exp>;
};


/** columns and relationships of "trade_amortization" */
export type Trade_AmortizationInvestment_Amortization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investment_Amortization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investment_Amortization_Order_By>>;
  where?: InputMaybe<Investment_Amortization_Bool_Exp>;
};

/** aggregated selection of "trade_amortization" */
export type Trade_Amortization_Aggregate = {
  __typename?: 'trade_amortization_aggregate';
  aggregate?: Maybe<Trade_Amortization_Aggregate_Fields>;
  nodes: Array<Trade_Amortization>;
};

export type Trade_Amortization_Aggregate_Bool_Exp = {
  count?: InputMaybe<Trade_Amortization_Aggregate_Bool_Exp_Count>;
};

export type Trade_Amortization_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Trade_Amortization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Trade_Amortization_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "trade_amortization" */
export type Trade_Amortization_Aggregate_Fields = {
  __typename?: 'trade_amortization_aggregate_fields';
  avg?: Maybe<Trade_Amortization_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Trade_Amortization_Max_Fields>;
  min?: Maybe<Trade_Amortization_Min_Fields>;
  stddev?: Maybe<Trade_Amortization_Stddev_Fields>;
  stddev_pop?: Maybe<Trade_Amortization_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Trade_Amortization_Stddev_Samp_Fields>;
  sum?: Maybe<Trade_Amortization_Sum_Fields>;
  var_pop?: Maybe<Trade_Amortization_Var_Pop_Fields>;
  var_samp?: Maybe<Trade_Amortization_Var_Samp_Fields>;
  variance?: Maybe<Trade_Amortization_Variance_Fields>;
};


/** aggregate fields of "trade_amortization" */
export type Trade_Amortization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trade_Amortization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "trade_amortization" */
export type Trade_Amortization_Aggregate_Order_By = {
  avg?: InputMaybe<Trade_Amortization_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Trade_Amortization_Max_Order_By>;
  min?: InputMaybe<Trade_Amortization_Min_Order_By>;
  stddev?: InputMaybe<Trade_Amortization_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Trade_Amortization_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Trade_Amortization_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Trade_Amortization_Sum_Order_By>;
  var_pop?: InputMaybe<Trade_Amortization_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Trade_Amortization_Var_Samp_Order_By>;
  variance?: InputMaybe<Trade_Amortization_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "trade_amortization" */
export type Trade_Amortization_Arr_Rel_Insert_Input = {
  data: Array<Trade_Amortization_Insert_Input>;
};

/** aggregate avg on columns */
export type Trade_Amortization_Avg_Fields = {
  __typename?: 'trade_amortization_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "trade_amortization" */
export type Trade_Amortization_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "trade_amortization". All fields are combined with a logical 'AND'. */
export type Trade_Amortization_Bool_Exp = {
  _and?: InputMaybe<Array<Trade_Amortization_Bool_Exp>>;
  _not?: InputMaybe<Trade_Amortization_Bool_Exp>;
  _or?: InputMaybe<Array<Trade_Amortization_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  capital?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interest?: InputMaybe<Numeric_Comparison_Exp>;
  investment_amortization?: InputMaybe<Investment_Amortization_Bool_Exp>;
  investment_amortization_aggregate?: InputMaybe<Investment_Amortization_Aggregate_Bool_Exp>;
  scheduled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  scheduled_emi?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tds?: InputMaybe<Numeric_Comparison_Exp>;
  trade?: InputMaybe<Trade_Bool_Exp>;
  trade_id?: InputMaybe<Int_Comparison_Exp>;
  trade_installment_seq?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "trade_amortization" */
export type Trade_Amortization_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  capital?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  interest?: InputMaybe<Scalars['numeric']>;
  investment_amortization?: InputMaybe<Investment_Amortization_Arr_Rel_Insert_Input>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  scheduled_emi?: InputMaybe<Scheduled_Emi_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  tds?: InputMaybe<Scalars['numeric']>;
  trade?: InputMaybe<Trade_Obj_Rel_Insert_Input>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Trade_Amortization_Max_Fields = {
  __typename?: 'trade_amortization_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  capital?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  interest?: Maybe<Scalars['numeric']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  tds?: Maybe<Scalars['numeric']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "trade_amortization" */
export type Trade_Amortization_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Trade_Amortization_Min_Fields = {
  __typename?: 'trade_amortization_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  capital?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  interest?: Maybe<Scalars['numeric']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  tds?: Maybe<Scalars['numeric']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "trade_amortization" */
export type Trade_Amortization_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "trade_amortization". */
export type Trade_Amortization_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  investment_amortization_aggregate?: InputMaybe<Investment_Amortization_Aggregate_Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  scheduled_emi?: InputMaybe<Scheduled_Emi_Order_By>;
  status?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade?: InputMaybe<Trade_Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** select columns of table "trade_amortization" */
export enum Trade_Amortization_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Capital = 'capital',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Interest = 'interest',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  Status = 'status',
  /** column name */
  Tds = 'tds',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  TradeInstallmentSeq = 'trade_installment_seq'
}

/** aggregate stddev on columns */
export type Trade_Amortization_Stddev_Fields = {
  __typename?: 'trade_amortization_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "trade_amortization" */
export type Trade_Amortization_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Trade_Amortization_Stddev_Pop_Fields = {
  __typename?: 'trade_amortization_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "trade_amortization" */
export type Trade_Amortization_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Trade_Amortization_Stddev_Samp_Fields = {
  __typename?: 'trade_amortization_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "trade_amortization" */
export type Trade_Amortization_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "trade_amortization" */
export type Trade_Amortization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trade_Amortization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trade_Amortization_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  capital?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  interest?: InputMaybe<Scalars['numeric']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  tds?: InputMaybe<Scalars['numeric']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Trade_Amortization_Sum_Fields = {
  __typename?: 'trade_amortization_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  capital?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  interest?: Maybe<Scalars['numeric']>;
  tds?: Maybe<Scalars['numeric']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "trade_amortization" */
export type Trade_Amortization_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Trade_Amortization_Var_Pop_Fields = {
  __typename?: 'trade_amortization_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "trade_amortization" */
export type Trade_Amortization_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Trade_Amortization_Var_Samp_Fields = {
  __typename?: 'trade_amortization_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "trade_amortization" */
export type Trade_Amortization_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Trade_Amortization_Variance_Fields = {
  __typename?: 'trade_amortization_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  tds?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "trade_amortization" */
export type Trade_Amortization_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  capital?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest?: InputMaybe<Order_By>;
  tds?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Trade_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
  signing_inputs?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "trade" */
export type Trade_Arr_Rel_Insert_Input = {
  data: Array<Trade_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Trade_On_Conflict>;
};

/** aggregate avg on columns */
export type Trade_Avg_Fields = {
  __typename?: 'trade_avg_fields';
  apa_agreement_id?: Maybe<Scalars['Float']>;
  available_investment_amount?: Maybe<Scalars['Float']>;
  bid_price?: Maybe<Scalars['Float']>;
  calculated_customers_value?: Maybe<Scalars['Float']>;
  company_amortization_table?: Maybe<Scalars['Float']>;
  company_escrow_account_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_amount?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  fees_tds?: Maybe<Scalars['Float']>;
  fees_without_gst?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mandate_account_id?: Maybe<Scalars['Float']>;
  mandate_id?: Maybe<Scalars['Float']>;
  mandate_no_of_months?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  selected_customers_value?: Maybe<Scalars['Float']>;
  settlement_account_id?: Maybe<Scalars['Float']>;
  trade_escrow_account_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  validity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "trade" */
export type Trade_Avg_Order_By = {
  apa_agreement_id?: InputMaybe<Order_By>;
  available_investment_amount?: InputMaybe<Order_By>;
  bid_price?: InputMaybe<Order_By>;
  calculated_customers_value?: InputMaybe<Order_By>;
  company_amortization_table?: InputMaybe<Order_By>;
  company_escrow_account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_amount?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  fees_tds?: InputMaybe<Order_By>;
  fees_without_gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mandate_account_id?: InputMaybe<Order_By>;
  mandate_id?: InputMaybe<Order_By>;
  mandate_no_of_months?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  selected_customers_value?: InputMaybe<Order_By>;
  settlement_account_id?: InputMaybe<Order_By>;
  trade_escrow_account_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "trade". All fields are combined with a logical 'AND'. */
export type Trade_Bool_Exp = {
  _and?: InputMaybe<Array<Trade_Bool_Exp>>;
  _not?: InputMaybe<Trade_Bool_Exp>;
  _or?: InputMaybe<Array<Trade_Bool_Exp>>;
  amortization?: InputMaybe<Trade_Amortization_Bool_Exp>;
  amortization_aggregate?: InputMaybe<Trade_Amortization_Aggregate_Bool_Exp>;
  apa?: InputMaybe<Agreement_Registry_Bool_Exp>;
  apa_agreement_id?: InputMaybe<Int_Comparison_Exp>;
  available_investment_amount?: InputMaybe<Numeric_Comparison_Exp>;
  bid_price?: InputMaybe<Numeric_Comparison_Exp>;
  calculated_customers_value?: InputMaybe<Numeric_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  collection_mode?: InputMaybe<Ref_Collection_Mode_Enum_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_amortization_table?: InputMaybe<Int_Comparison_Exp>;
  company_amortization_table_file?: InputMaybe<File_Registry_Bool_Exp>;
  company_escrow_account?: InputMaybe<Account_Bool_Exp>;
  company_escrow_account_id?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deal?: InputMaybe<Deal_Bool_Exp>;
  deal_id?: InputMaybe<Int_Comparison_Exp>;
  debit_mode?: InputMaybe<Ref_Debit_Mode_Enum_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  emi_amount?: InputMaybe<Numeric_Comparison_Exp>;
  emi_start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  fees?: InputMaybe<Float8_Comparison_Exp>;
  fees_tds?: InputMaybe<Numeric_Comparison_Exp>;
  fees_without_gst?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  investment_amortization?: InputMaybe<Investment_Amortization_Bool_Exp>;
  investment_amortization_aggregate?: InputMaybe<Investment_Amortization_Aggregate_Bool_Exp>;
  investments?: InputMaybe<Investment_Bool_Exp>;
  investments_aggregate?: InputMaybe<Investment_Aggregate_Bool_Exp>;
  mandate?: InputMaybe<Mandate_Bool_Exp>;
  mandate_account?: InputMaybe<Account_Bool_Exp>;
  mandate_account_id?: InputMaybe<Int_Comparison_Exp>;
  mandate_frequency?: InputMaybe<Ref_Mandate_Frequency_Enum_Comparison_Exp>;
  mandate_id?: InputMaybe<Int_Comparison_Exp>;
  mandate_no_of_months?: InputMaybe<Int_Comparison_Exp>;
  mandate_type?: InputMaybe<Ref_Mandate_Type_Enum_Comparison_Exp>;
  max_presentation_amount?: InputMaybe<Numeric_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  payout_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  payout_value?: InputMaybe<Float8_Comparison_Exp>;
  ref_bid?: InputMaybe<Ref_Bid_Bool_Exp>;
  ref_mandate_frequency?: InputMaybe<Ref_Mandate_Frequency_Bool_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
  roi?: InputMaybe<Numeric_Comparison_Exp>;
  scheduled_emis?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  scheduled_emis_aggregate?: InputMaybe<Scheduled_Emi_Aggregate_Bool_Exp>;
  selected_customers_value?: InputMaybe<Float8_Comparison_Exp>;
  settlement_account?: InputMaybe<Account_Bool_Exp>;
  settlement_account_id?: InputMaybe<Int_Comparison_Exp>;
  signing_inputs?: InputMaybe<Jsonb_Comparison_Exp>;
  state?: InputMaybe<Ref_Trade_State_Enum_Comparison_Exp>;
  status?: InputMaybe<Ref_Trade_Status_Enum_Comparison_Exp>;
  trade_escrow_account?: InputMaybe<Account_Bool_Exp>;
  trade_escrow_account_id?: InputMaybe<Int_Comparison_Exp>;
  trade_subscriptions?: InputMaybe<Trade_Subscription_Bool_Exp>;
  trade_subscriptions_aggregate?: InputMaybe<Trade_Subscription_Aggregate_Bool_Exp>;
  transactions?: InputMaybe<Transaction_Bool_Exp>;
  transactions_aggregate?: InputMaybe<Transaction_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  validity?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "trade" */
export enum Trade_Constraint {
  /** unique or primary key constraint on columns "id" */
  TradePkey = 'trade_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Trade_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
  signing_inputs?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Trade_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
  signing_inputs?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Trade_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
  signing_inputs?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "trade" */
export type Trade_Inc_Input = {
  apa_agreement_id?: InputMaybe<Scalars['Int']>;
  available_investment_amount?: InputMaybe<Scalars['numeric']>;
  bid_price?: InputMaybe<Scalars['numeric']>;
  calculated_customers_value?: InputMaybe<Scalars['numeric']>;
  company_amortization_table?: InputMaybe<Scalars['Int']>;
  company_escrow_account_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deal_id?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  emi_amount?: InputMaybe<Scalars['numeric']>;
  fees?: InputMaybe<Scalars['float8']>;
  fees_tds?: InputMaybe<Scalars['numeric']>;
  fees_without_gst?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  mandate_account_id?: InputMaybe<Scalars['Int']>;
  mandate_id?: InputMaybe<Scalars['Int']>;
  mandate_no_of_months?: InputMaybe<Scalars['Int']>;
  max_presentation_amount?: InputMaybe<Scalars['numeric']>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: InputMaybe<Scalars['float8']>;
  roi?: InputMaybe<Scalars['numeric']>;
  selected_customers_value?: InputMaybe<Scalars['float8']>;
  settlement_account_id?: InputMaybe<Scalars['Int']>;
  trade_escrow_account_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  validity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "trade" */
export type Trade_Insert_Input = {
  amortization?: InputMaybe<Trade_Amortization_Arr_Rel_Insert_Input>;
  apa?: InputMaybe<Agreement_Registry_Obj_Rel_Insert_Input>;
  apa_agreement_id?: InputMaybe<Scalars['Int']>;
  available_investment_amount?: InputMaybe<Scalars['numeric']>;
  bid_price?: InputMaybe<Scalars['numeric']>;
  calculated_customers_value?: InputMaybe<Scalars['numeric']>;
  collection_mode?: InputMaybe<Ref_Collection_Mode_Enum>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_amortization_table?: InputMaybe<Scalars['Int']>;
  company_amortization_table_file?: InputMaybe<File_Registry_Obj_Rel_Insert_Input>;
  company_escrow_account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  company_escrow_account_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deal?: InputMaybe<Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['Int']>;
  debit_mode?: InputMaybe<Ref_Debit_Mode_Enum>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  emi_amount?: InputMaybe<Scalars['numeric']>;
  emi_start_date?: InputMaybe<Scalars['timestamptz']>;
  fees?: InputMaybe<Scalars['float8']>;
  fees_tds?: InputMaybe<Scalars['numeric']>;
  fees_without_gst?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  investment_amortization?: InputMaybe<Investment_Amortization_Arr_Rel_Insert_Input>;
  investments?: InputMaybe<Investment_Arr_Rel_Insert_Input>;
  mandate?: InputMaybe<Mandate_Obj_Rel_Insert_Input>;
  mandate_account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  mandate_account_id?: InputMaybe<Scalars['Int']>;
  mandate_frequency?: InputMaybe<Ref_Mandate_Frequency_Enum>;
  mandate_id?: InputMaybe<Scalars['Int']>;
  mandate_no_of_months?: InputMaybe<Scalars['Int']>;
  mandate_type?: InputMaybe<Ref_Mandate_Type_Enum>;
  max_presentation_amount?: InputMaybe<Scalars['numeric']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  payout_on?: InputMaybe<Scalars['timestamptz']>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: InputMaybe<Scalars['float8']>;
  ref_bid?: InputMaybe<Ref_Bid_Obj_Rel_Insert_Input>;
  ref_mandate_frequency?: InputMaybe<Ref_Mandate_Frequency_Obj_Rel_Insert_Input>;
  remarks?: InputMaybe<Scalars['String']>;
  roi?: InputMaybe<Scalars['numeric']>;
  scheduled_emis?: InputMaybe<Scheduled_Emi_Arr_Rel_Insert_Input>;
  selected_customers_value?: InputMaybe<Scalars['float8']>;
  settlement_account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  settlement_account_id?: InputMaybe<Scalars['Int']>;
  signing_inputs?: InputMaybe<Scalars['jsonb']>;
  state?: InputMaybe<Ref_Trade_State_Enum>;
  status?: InputMaybe<Ref_Trade_Status_Enum>;
  trade_escrow_account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  trade_escrow_account_id?: InputMaybe<Scalars['Int']>;
  trade_subscriptions?: InputMaybe<Trade_Subscription_Arr_Rel_Insert_Input>;
  transactions?: InputMaybe<Transaction_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  validity?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Trade_Max_Fields = {
  __typename?: 'trade_max_fields';
  apa_agreement_id?: Maybe<Scalars['Int']>;
  available_investment_amount?: Maybe<Scalars['numeric']>;
  bid_price?: Maybe<Scalars['numeric']>;
  calculated_customers_value?: Maybe<Scalars['numeric']>;
  company_amortization_table?: Maybe<Scalars['Int']>;
  company_escrow_account_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  emi_amount?: Maybe<Scalars['numeric']>;
  emi_start_date?: Maybe<Scalars['timestamptz']>;
  fees?: Maybe<Scalars['float8']>;
  fees_tds?: Maybe<Scalars['numeric']>;
  fees_without_gst?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  mandate_account_id?: Maybe<Scalars['Int']>;
  mandate_id?: Maybe<Scalars['Int']>;
  mandate_no_of_months?: Maybe<Scalars['Int']>;
  max_presentation_amount?: Maybe<Scalars['numeric']>;
  payout_on?: Maybe<Scalars['timestamptz']>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: Maybe<Scalars['float8']>;
  remarks?: Maybe<Scalars['String']>;
  roi?: Maybe<Scalars['numeric']>;
  selected_customers_value?: Maybe<Scalars['float8']>;
  settlement_account_id?: Maybe<Scalars['Int']>;
  trade_escrow_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  validity?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "trade" */
export type Trade_Max_Order_By = {
  apa_agreement_id?: InputMaybe<Order_By>;
  available_investment_amount?: InputMaybe<Order_By>;
  bid_price?: InputMaybe<Order_By>;
  calculated_customers_value?: InputMaybe<Order_By>;
  company_amortization_table?: InputMaybe<Order_By>;
  company_escrow_account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_amount?: InputMaybe<Order_By>;
  emi_start_date?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  fees_tds?: InputMaybe<Order_By>;
  fees_without_gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mandate_account_id?: InputMaybe<Order_By>;
  mandate_id?: InputMaybe<Order_By>;
  mandate_no_of_months?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  payout_on?: InputMaybe<Order_By>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  selected_customers_value?: InputMaybe<Order_By>;
  settlement_account_id?: InputMaybe<Order_By>;
  trade_escrow_account_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Trade_Min_Fields = {
  __typename?: 'trade_min_fields';
  apa_agreement_id?: Maybe<Scalars['Int']>;
  available_investment_amount?: Maybe<Scalars['numeric']>;
  bid_price?: Maybe<Scalars['numeric']>;
  calculated_customers_value?: Maybe<Scalars['numeric']>;
  company_amortization_table?: Maybe<Scalars['Int']>;
  company_escrow_account_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  emi_amount?: Maybe<Scalars['numeric']>;
  emi_start_date?: Maybe<Scalars['timestamptz']>;
  fees?: Maybe<Scalars['float8']>;
  fees_tds?: Maybe<Scalars['numeric']>;
  fees_without_gst?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  mandate_account_id?: Maybe<Scalars['Int']>;
  mandate_id?: Maybe<Scalars['Int']>;
  mandate_no_of_months?: Maybe<Scalars['Int']>;
  max_presentation_amount?: Maybe<Scalars['numeric']>;
  payout_on?: Maybe<Scalars['timestamptz']>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: Maybe<Scalars['float8']>;
  remarks?: Maybe<Scalars['String']>;
  roi?: Maybe<Scalars['numeric']>;
  selected_customers_value?: Maybe<Scalars['float8']>;
  settlement_account_id?: Maybe<Scalars['Int']>;
  trade_escrow_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  validity?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "trade" */
export type Trade_Min_Order_By = {
  apa_agreement_id?: InputMaybe<Order_By>;
  available_investment_amount?: InputMaybe<Order_By>;
  bid_price?: InputMaybe<Order_By>;
  calculated_customers_value?: InputMaybe<Order_By>;
  company_amortization_table?: InputMaybe<Order_By>;
  company_escrow_account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_amount?: InputMaybe<Order_By>;
  emi_start_date?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  fees_tds?: InputMaybe<Order_By>;
  fees_without_gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mandate_account_id?: InputMaybe<Order_By>;
  mandate_id?: InputMaybe<Order_By>;
  mandate_no_of_months?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  payout_on?: InputMaybe<Order_By>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  selected_customers_value?: InputMaybe<Order_By>;
  settlement_account_id?: InputMaybe<Order_By>;
  trade_escrow_account_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "trade" */
export type Trade_Mutation_Response = {
  __typename?: 'trade_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Trade>;
};

/** input type for inserting object relation for remote table "trade" */
export type Trade_Obj_Rel_Insert_Input = {
  data: Trade_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Trade_On_Conflict>;
};

/** on_conflict condition type for table "trade" */
export type Trade_On_Conflict = {
  constraint: Trade_Constraint;
  update_columns?: Array<Trade_Update_Column>;
  where?: InputMaybe<Trade_Bool_Exp>;
};

/** Ordering options when selecting data from "trade". */
export type Trade_Order_By = {
  amortization_aggregate?: InputMaybe<Trade_Amortization_Aggregate_Order_By>;
  apa?: InputMaybe<Agreement_Registry_Order_By>;
  apa_agreement_id?: InputMaybe<Order_By>;
  available_investment_amount?: InputMaybe<Order_By>;
  bid_price?: InputMaybe<Order_By>;
  calculated_customers_value?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  collection_mode?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_amortization_table?: InputMaybe<Order_By>;
  company_amortization_table_file?: InputMaybe<File_Registry_Order_By>;
  company_escrow_account?: InputMaybe<Account_Order_By>;
  company_escrow_account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal?: InputMaybe<Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  debit_mode?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_amount?: InputMaybe<Order_By>;
  emi_start_date?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  fees_tds?: InputMaybe<Order_By>;
  fees_without_gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investment_amortization_aggregate?: InputMaybe<Investment_Amortization_Aggregate_Order_By>;
  investments_aggregate?: InputMaybe<Investment_Aggregate_Order_By>;
  mandate?: InputMaybe<Mandate_Order_By>;
  mandate_account?: InputMaybe<Account_Order_By>;
  mandate_account_id?: InputMaybe<Order_By>;
  mandate_frequency?: InputMaybe<Order_By>;
  mandate_id?: InputMaybe<Order_By>;
  mandate_no_of_months?: InputMaybe<Order_By>;
  mandate_type?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  payout_on?: InputMaybe<Order_By>;
  payout_value?: InputMaybe<Order_By>;
  ref_bid?: InputMaybe<Ref_Bid_Order_By>;
  ref_mandate_frequency?: InputMaybe<Ref_Mandate_Frequency_Order_By>;
  remarks?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  scheduled_emis_aggregate?: InputMaybe<Scheduled_Emi_Aggregate_Order_By>;
  selected_customers_value?: InputMaybe<Order_By>;
  settlement_account?: InputMaybe<Account_Order_By>;
  settlement_account_id?: InputMaybe<Order_By>;
  signing_inputs?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  trade_escrow_account?: InputMaybe<Account_Order_By>;
  trade_escrow_account_id?: InputMaybe<Order_By>;
  trade_subscriptions_aggregate?: InputMaybe<Trade_Subscription_Aggregate_Order_By>;
  transactions_aggregate?: InputMaybe<Transaction_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trade */
export type Trade_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Trade_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
  signing_inputs?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "trade" */
export enum Trade_Select_Column {
  /** column name */
  ApaAgreementId = 'apa_agreement_id',
  /** column name */
  AvailableInvestmentAmount = 'available_investment_amount',
  /** column name */
  BidPrice = 'bid_price',
  /** column name */
  CalculatedCustomersValue = 'calculated_customers_value',
  /** column name */
  CollectionMode = 'collection_mode',
  /** column name */
  CompanyAmortizationTable = 'company_amortization_table',
  /** column name */
  CompanyEscrowAccountId = 'company_escrow_account_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  DebitMode = 'debit_mode',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EmiAmount = 'emi_amount',
  /** column name */
  EmiStartDate = 'emi_start_date',
  /** column name */
  Fees = 'fees',
  /** column name */
  FeesTds = 'fees_tds',
  /** column name */
  FeesWithoutGst = 'fees_without_gst',
  /** column name */
  Id = 'id',
  /** column name */
  MandateAccountId = 'mandate_account_id',
  /** column name */
  MandateFrequency = 'mandate_frequency',
  /** column name */
  MandateId = 'mandate_id',
  /** column name */
  MandateNoOfMonths = 'mandate_no_of_months',
  /** column name */
  MandateType = 'mandate_type',
  /** column name */
  MaxPresentationAmount = 'max_presentation_amount',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PayoutOn = 'payout_on',
  /** column name */
  PayoutValue = 'payout_value',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  Roi = 'roi',
  /** column name */
  SelectedCustomersValue = 'selected_customers_value',
  /** column name */
  SettlementAccountId = 'settlement_account_id',
  /** column name */
  SigningInputs = 'signing_inputs',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  TradeEscrowAccountId = 'trade_escrow_account_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Validity = 'validity'
}

/** select "trade_aggregate_bool_exp_avg_arguments_columns" columns of table "trade" */
export enum Trade_Select_Column_Trade_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Fees = 'fees',
  /** column name */
  PayoutValue = 'payout_value',
  /** column name */
  SelectedCustomersValue = 'selected_customers_value'
}

/** select "trade_aggregate_bool_exp_corr_arguments_columns" columns of table "trade" */
export enum Trade_Select_Column_Trade_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Fees = 'fees',
  /** column name */
  PayoutValue = 'payout_value',
  /** column name */
  SelectedCustomersValue = 'selected_customers_value'
}

/** select "trade_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "trade" */
export enum Trade_Select_Column_Trade_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Fees = 'fees',
  /** column name */
  PayoutValue = 'payout_value',
  /** column name */
  SelectedCustomersValue = 'selected_customers_value'
}

/** select "trade_aggregate_bool_exp_max_arguments_columns" columns of table "trade" */
export enum Trade_Select_Column_Trade_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Fees = 'fees',
  /** column name */
  PayoutValue = 'payout_value',
  /** column name */
  SelectedCustomersValue = 'selected_customers_value'
}

/** select "trade_aggregate_bool_exp_min_arguments_columns" columns of table "trade" */
export enum Trade_Select_Column_Trade_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Fees = 'fees',
  /** column name */
  PayoutValue = 'payout_value',
  /** column name */
  SelectedCustomersValue = 'selected_customers_value'
}

/** select "trade_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "trade" */
export enum Trade_Select_Column_Trade_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Fees = 'fees',
  /** column name */
  PayoutValue = 'payout_value',
  /** column name */
  SelectedCustomersValue = 'selected_customers_value'
}

/** select "trade_aggregate_bool_exp_sum_arguments_columns" columns of table "trade" */
export enum Trade_Select_Column_Trade_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Fees = 'fees',
  /** column name */
  PayoutValue = 'payout_value',
  /** column name */
  SelectedCustomersValue = 'selected_customers_value'
}

/** select "trade_aggregate_bool_exp_var_samp_arguments_columns" columns of table "trade" */
export enum Trade_Select_Column_Trade_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Fees = 'fees',
  /** column name */
  PayoutValue = 'payout_value',
  /** column name */
  SelectedCustomersValue = 'selected_customers_value'
}

/** input type for updating data in table "trade" */
export type Trade_Set_Input = {
  apa_agreement_id?: InputMaybe<Scalars['Int']>;
  available_investment_amount?: InputMaybe<Scalars['numeric']>;
  bid_price?: InputMaybe<Scalars['numeric']>;
  calculated_customers_value?: InputMaybe<Scalars['numeric']>;
  collection_mode?: InputMaybe<Ref_Collection_Mode_Enum>;
  company_amortization_table?: InputMaybe<Scalars['Int']>;
  company_escrow_account_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deal_id?: InputMaybe<Scalars['Int']>;
  debit_mode?: InputMaybe<Ref_Debit_Mode_Enum>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  emi_amount?: InputMaybe<Scalars['numeric']>;
  emi_start_date?: InputMaybe<Scalars['timestamptz']>;
  fees?: InputMaybe<Scalars['float8']>;
  fees_tds?: InputMaybe<Scalars['numeric']>;
  fees_without_gst?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  mandate_account_id?: InputMaybe<Scalars['Int']>;
  mandate_frequency?: InputMaybe<Ref_Mandate_Frequency_Enum>;
  mandate_id?: InputMaybe<Scalars['Int']>;
  mandate_no_of_months?: InputMaybe<Scalars['Int']>;
  mandate_type?: InputMaybe<Ref_Mandate_Type_Enum>;
  max_presentation_amount?: InputMaybe<Scalars['numeric']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  payout_on?: InputMaybe<Scalars['timestamptz']>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: InputMaybe<Scalars['float8']>;
  remarks?: InputMaybe<Scalars['String']>;
  roi?: InputMaybe<Scalars['numeric']>;
  selected_customers_value?: InputMaybe<Scalars['float8']>;
  settlement_account_id?: InputMaybe<Scalars['Int']>;
  signing_inputs?: InputMaybe<Scalars['jsonb']>;
  state?: InputMaybe<Ref_Trade_State_Enum>;
  status?: InputMaybe<Ref_Trade_Status_Enum>;
  trade_escrow_account_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  validity?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Trade_Stddev_Fields = {
  __typename?: 'trade_stddev_fields';
  apa_agreement_id?: Maybe<Scalars['Float']>;
  available_investment_amount?: Maybe<Scalars['Float']>;
  bid_price?: Maybe<Scalars['Float']>;
  calculated_customers_value?: Maybe<Scalars['Float']>;
  company_amortization_table?: Maybe<Scalars['Float']>;
  company_escrow_account_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_amount?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  fees_tds?: Maybe<Scalars['Float']>;
  fees_without_gst?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mandate_account_id?: Maybe<Scalars['Float']>;
  mandate_id?: Maybe<Scalars['Float']>;
  mandate_no_of_months?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  selected_customers_value?: Maybe<Scalars['Float']>;
  settlement_account_id?: Maybe<Scalars['Float']>;
  trade_escrow_account_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  validity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "trade" */
export type Trade_Stddev_Order_By = {
  apa_agreement_id?: InputMaybe<Order_By>;
  available_investment_amount?: InputMaybe<Order_By>;
  bid_price?: InputMaybe<Order_By>;
  calculated_customers_value?: InputMaybe<Order_By>;
  company_amortization_table?: InputMaybe<Order_By>;
  company_escrow_account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_amount?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  fees_tds?: InputMaybe<Order_By>;
  fees_without_gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mandate_account_id?: InputMaybe<Order_By>;
  mandate_id?: InputMaybe<Order_By>;
  mandate_no_of_months?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  selected_customers_value?: InputMaybe<Order_By>;
  settlement_account_id?: InputMaybe<Order_By>;
  trade_escrow_account_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Trade_Stddev_Pop_Fields = {
  __typename?: 'trade_stddev_pop_fields';
  apa_agreement_id?: Maybe<Scalars['Float']>;
  available_investment_amount?: Maybe<Scalars['Float']>;
  bid_price?: Maybe<Scalars['Float']>;
  calculated_customers_value?: Maybe<Scalars['Float']>;
  company_amortization_table?: Maybe<Scalars['Float']>;
  company_escrow_account_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_amount?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  fees_tds?: Maybe<Scalars['Float']>;
  fees_without_gst?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mandate_account_id?: Maybe<Scalars['Float']>;
  mandate_id?: Maybe<Scalars['Float']>;
  mandate_no_of_months?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  selected_customers_value?: Maybe<Scalars['Float']>;
  settlement_account_id?: Maybe<Scalars['Float']>;
  trade_escrow_account_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  validity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "trade" */
export type Trade_Stddev_Pop_Order_By = {
  apa_agreement_id?: InputMaybe<Order_By>;
  available_investment_amount?: InputMaybe<Order_By>;
  bid_price?: InputMaybe<Order_By>;
  calculated_customers_value?: InputMaybe<Order_By>;
  company_amortization_table?: InputMaybe<Order_By>;
  company_escrow_account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_amount?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  fees_tds?: InputMaybe<Order_By>;
  fees_without_gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mandate_account_id?: InputMaybe<Order_By>;
  mandate_id?: InputMaybe<Order_By>;
  mandate_no_of_months?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  selected_customers_value?: InputMaybe<Order_By>;
  settlement_account_id?: InputMaybe<Order_By>;
  trade_escrow_account_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Trade_Stddev_Samp_Fields = {
  __typename?: 'trade_stddev_samp_fields';
  apa_agreement_id?: Maybe<Scalars['Float']>;
  available_investment_amount?: Maybe<Scalars['Float']>;
  bid_price?: Maybe<Scalars['Float']>;
  calculated_customers_value?: Maybe<Scalars['Float']>;
  company_amortization_table?: Maybe<Scalars['Float']>;
  company_escrow_account_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_amount?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  fees_tds?: Maybe<Scalars['Float']>;
  fees_without_gst?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mandate_account_id?: Maybe<Scalars['Float']>;
  mandate_id?: Maybe<Scalars['Float']>;
  mandate_no_of_months?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  selected_customers_value?: Maybe<Scalars['Float']>;
  settlement_account_id?: Maybe<Scalars['Float']>;
  trade_escrow_account_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  validity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "trade" */
export type Trade_Stddev_Samp_Order_By = {
  apa_agreement_id?: InputMaybe<Order_By>;
  available_investment_amount?: InputMaybe<Order_By>;
  bid_price?: InputMaybe<Order_By>;
  calculated_customers_value?: InputMaybe<Order_By>;
  company_amortization_table?: InputMaybe<Order_By>;
  company_escrow_account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_amount?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  fees_tds?: InputMaybe<Order_By>;
  fees_without_gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mandate_account_id?: InputMaybe<Order_By>;
  mandate_id?: InputMaybe<Order_By>;
  mandate_no_of_months?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  selected_customers_value?: InputMaybe<Order_By>;
  settlement_account_id?: InputMaybe<Order_By>;
  trade_escrow_account_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "trade" */
export type Trade_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trade_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trade_Stream_Cursor_Value_Input = {
  apa_agreement_id?: InputMaybe<Scalars['Int']>;
  available_investment_amount?: InputMaybe<Scalars['numeric']>;
  bid_price?: InputMaybe<Scalars['numeric']>;
  calculated_customers_value?: InputMaybe<Scalars['numeric']>;
  collection_mode?: InputMaybe<Ref_Collection_Mode_Enum>;
  company_amortization_table?: InputMaybe<Scalars['Int']>;
  company_escrow_account_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deal_id?: InputMaybe<Scalars['Int']>;
  debit_mode?: InputMaybe<Ref_Debit_Mode_Enum>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  emi_amount?: InputMaybe<Scalars['numeric']>;
  emi_start_date?: InputMaybe<Scalars['timestamptz']>;
  fees?: InputMaybe<Scalars['float8']>;
  fees_tds?: InputMaybe<Scalars['numeric']>;
  fees_without_gst?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  mandate_account_id?: InputMaybe<Scalars['Int']>;
  mandate_frequency?: InputMaybe<Ref_Mandate_Frequency_Enum>;
  mandate_id?: InputMaybe<Scalars['Int']>;
  mandate_no_of_months?: InputMaybe<Scalars['Int']>;
  mandate_type?: InputMaybe<Ref_Mandate_Type_Enum>;
  max_presentation_amount?: InputMaybe<Scalars['numeric']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  payout_on?: InputMaybe<Scalars['timestamptz']>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: InputMaybe<Scalars['float8']>;
  remarks?: InputMaybe<Scalars['String']>;
  roi?: InputMaybe<Scalars['numeric']>;
  selected_customers_value?: InputMaybe<Scalars['float8']>;
  settlement_account_id?: InputMaybe<Scalars['Int']>;
  signing_inputs?: InputMaybe<Scalars['jsonb']>;
  state?: InputMaybe<Ref_Trade_State_Enum>;
  status?: InputMaybe<Ref_Trade_Status_Enum>;
  trade_escrow_account_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  validity?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "trade_subscription" */
export type Trade_Subscription = {
  __typename?: 'trade_subscription';
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month: Scalars['numeric'];
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  currency: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** A computed field, executes function "subscription_offer_amount" */
  offer_amount?: Maybe<Scalars['float8']>;
  parent_id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "calculate_revenue" */
  revenue?: Maybe<Scalars['numeric']>;
  status: Ref_Subscription_Status_Enum;
  /** An object relationship */
  subscription: Subscription;
  subscription_id: Scalars['Int'];
  /** An object relationship */
  trade: Trade;
  trade_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};

/** aggregated selection of "trade_subscription" */
export type Trade_Subscription_Aggregate = {
  __typename?: 'trade_subscription_aggregate';
  aggregate?: Maybe<Trade_Subscription_Aggregate_Fields>;
  nodes: Array<Trade_Subscription>;
};

export type Trade_Subscription_Aggregate_Bool_Exp = {
  count?: InputMaybe<Trade_Subscription_Aggregate_Bool_Exp_Count>;
};

export type Trade_Subscription_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Trade_Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Trade_Subscription_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "trade_subscription" */
export type Trade_Subscription_Aggregate_Fields = {
  __typename?: 'trade_subscription_aggregate_fields';
  avg?: Maybe<Trade_Subscription_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Trade_Subscription_Max_Fields>;
  min?: Maybe<Trade_Subscription_Min_Fields>;
  stddev?: Maybe<Trade_Subscription_Stddev_Fields>;
  stddev_pop?: Maybe<Trade_Subscription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Trade_Subscription_Stddev_Samp_Fields>;
  sum?: Maybe<Trade_Subscription_Sum_Fields>;
  var_pop?: Maybe<Trade_Subscription_Var_Pop_Fields>;
  var_samp?: Maybe<Trade_Subscription_Var_Samp_Fields>;
  variance?: Maybe<Trade_Subscription_Variance_Fields>;
};


/** aggregate fields of "trade_subscription" */
export type Trade_Subscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trade_Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "trade_subscription" */
export type Trade_Subscription_Aggregate_Order_By = {
  avg?: InputMaybe<Trade_Subscription_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Trade_Subscription_Max_Order_By>;
  min?: InputMaybe<Trade_Subscription_Min_Order_By>;
  stddev?: InputMaybe<Trade_Subscription_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Trade_Subscription_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Trade_Subscription_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Trade_Subscription_Sum_Order_By>;
  var_pop?: InputMaybe<Trade_Subscription_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Trade_Subscription_Var_Samp_Order_By>;
  variance?: InputMaybe<Trade_Subscription_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "trade_subscription" */
export type Trade_Subscription_Arr_Rel_Insert_Input = {
  data: Array<Trade_Subscription_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Trade_Subscription_On_Conflict>;
};

/** aggregate avg on columns */
export type Trade_Subscription_Avg_Fields = {
  __typename?: 'trade_subscription_avg_fields';
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "trade_subscription" */
export type Trade_Subscription_Avg_Order_By = {
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "trade_subscription". All fields are combined with a logical 'AND'. */
export type Trade_Subscription_Bool_Exp = {
  _and?: InputMaybe<Array<Trade_Subscription_Bool_Exp>>;
  _not?: InputMaybe<Trade_Subscription_Bool_Exp>;
  _or?: InputMaybe<Array<Trade_Subscription_Bool_Exp>>;
  amount_per_month?: InputMaybe<Numeric_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  offer_amount?: InputMaybe<Float8_Comparison_Exp>;
  parent_id?: InputMaybe<Int_Comparison_Exp>;
  revenue?: InputMaybe<Numeric_Comparison_Exp>;
  status?: InputMaybe<Ref_Subscription_Status_Enum_Comparison_Exp>;
  subscription?: InputMaybe<Subscription_Bool_Exp>;
  subscription_id?: InputMaybe<Int_Comparison_Exp>;
  trade?: InputMaybe<Trade_Bool_Exp>;
  trade_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "trade_subscription" */
export enum Trade_Subscription_Constraint {
  /** unique or primary key constraint on columns "id" */
  TradeSubscriptionPkey = 'trade_subscription_pkey',
  /** unique or primary key constraint on columns "subscription_id", "trade_id" */
  TradeSubscriptionTradeIdSubscriptionIdIdx = 'trade_subscription_trade_id_subscription_id_idx'
}

/** input type for incrementing numeric columns in table "trade_subscription" */
export type Trade_Subscription_Inc_Input = {
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: InputMaybe<Scalars['numeric']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  parent_id?: InputMaybe<Scalars['Int']>;
  subscription_id?: InputMaybe<Scalars['Int']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "trade_subscription" */
export type Trade_Subscription_Insert_Input = {
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: InputMaybe<Scalars['numeric']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  parent_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Ref_Subscription_Status_Enum>;
  subscription?: InputMaybe<Subscription_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars['Int']>;
  trade?: InputMaybe<Trade_Obj_Rel_Insert_Input>;
  trade_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Trade_Subscription_Max_Fields = {
  __typename?: 'trade_subscription_max_fields';
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['Int']>;
  trade_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "trade_subscription" */
export type Trade_Subscription_Max_Order_By = {
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Trade_Subscription_Min_Fields = {
  __typename?: 'trade_subscription_min_fields';
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['Int']>;
  trade_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "trade_subscription" */
export type Trade_Subscription_Min_Order_By = {
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "trade_subscription" */
export type Trade_Subscription_Mutation_Response = {
  __typename?: 'trade_subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Trade_Subscription>;
};

/** on_conflict condition type for table "trade_subscription" */
export type Trade_Subscription_On_Conflict = {
  constraint: Trade_Subscription_Constraint;
  update_columns?: Array<Trade_Subscription_Update_Column>;
  where?: InputMaybe<Trade_Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "trade_subscription". */
export type Trade_Subscription_Order_By = {
  amount_per_month?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  offer_amount?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  revenue?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscription_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  trade?: InputMaybe<Trade_Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trade_subscription */
export type Trade_Subscription_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "trade_subscription" */
export enum Trade_Subscription_Select_Column {
  /** column name */
  AmountPerMonth = 'amount_per_month',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Currency = 'currency',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "trade_subscription" */
export type Trade_Subscription_Set_Input = {
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: InputMaybe<Scalars['numeric']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  parent_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Ref_Subscription_Status_Enum>;
  subscription_id?: InputMaybe<Scalars['Int']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Trade_Subscription_Stddev_Fields = {
  __typename?: 'trade_subscription_stddev_fields';
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "trade_subscription" */
export type Trade_Subscription_Stddev_Order_By = {
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Trade_Subscription_Stddev_Pop_Fields = {
  __typename?: 'trade_subscription_stddev_pop_fields';
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "trade_subscription" */
export type Trade_Subscription_Stddev_Pop_Order_By = {
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Trade_Subscription_Stddev_Samp_Fields = {
  __typename?: 'trade_subscription_stddev_samp_fields';
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "trade_subscription" */
export type Trade_Subscription_Stddev_Samp_Order_By = {
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "trade_subscription" */
export type Trade_Subscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trade_Subscription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trade_Subscription_Stream_Cursor_Value_Input = {
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: InputMaybe<Scalars['numeric']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  parent_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Ref_Subscription_Status_Enum>;
  subscription_id?: InputMaybe<Scalars['Int']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Trade_Subscription_Sum_Fields = {
  __typename?: 'trade_subscription_sum_fields';
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['Int']>;
  trade_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "trade_subscription" */
export type Trade_Subscription_Sum_Order_By = {
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "trade_subscription" */
export enum Trade_Subscription_Update_Column {
  /** column name */
  AmountPerMonth = 'amount_per_month',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Currency = 'currency',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Trade_Subscription_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Trade_Subscription_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trade_Subscription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trade_Subscription_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Trade_Subscription_Var_Pop_Fields = {
  __typename?: 'trade_subscription_var_pop_fields';
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "trade_subscription" */
export type Trade_Subscription_Var_Pop_Order_By = {
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Trade_Subscription_Var_Samp_Fields = {
  __typename?: 'trade_subscription_var_samp_fields';
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "trade_subscription" */
export type Trade_Subscription_Var_Samp_Order_By = {
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Trade_Subscription_Variance_Fields = {
  __typename?: 'trade_subscription_variance_fields';
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "trade_subscription" */
export type Trade_Subscription_Variance_Order_By = {
  /** Amount per month in INR when contracts were selected for a trade */
  amount_per_month?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Trade_Sum_Fields = {
  __typename?: 'trade_sum_fields';
  apa_agreement_id?: Maybe<Scalars['Int']>;
  available_investment_amount?: Maybe<Scalars['numeric']>;
  bid_price?: Maybe<Scalars['numeric']>;
  calculated_customers_value?: Maybe<Scalars['numeric']>;
  company_amortization_table?: Maybe<Scalars['Int']>;
  company_escrow_account_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deal_id?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  emi_amount?: Maybe<Scalars['numeric']>;
  fees?: Maybe<Scalars['float8']>;
  fees_tds?: Maybe<Scalars['numeric']>;
  fees_without_gst?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  mandate_account_id?: Maybe<Scalars['Int']>;
  mandate_id?: Maybe<Scalars['Int']>;
  mandate_no_of_months?: Maybe<Scalars['Int']>;
  max_presentation_amount?: Maybe<Scalars['numeric']>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: Maybe<Scalars['float8']>;
  roi?: Maybe<Scalars['numeric']>;
  selected_customers_value?: Maybe<Scalars['float8']>;
  settlement_account_id?: Maybe<Scalars['Int']>;
  trade_escrow_account_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
  validity?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "trade" */
export type Trade_Sum_Order_By = {
  apa_agreement_id?: InputMaybe<Order_By>;
  available_investment_amount?: InputMaybe<Order_By>;
  bid_price?: InputMaybe<Order_By>;
  calculated_customers_value?: InputMaybe<Order_By>;
  company_amortization_table?: InputMaybe<Order_By>;
  company_escrow_account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_amount?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  fees_tds?: InputMaybe<Order_By>;
  fees_without_gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mandate_account_id?: InputMaybe<Order_By>;
  mandate_id?: InputMaybe<Order_By>;
  mandate_no_of_months?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  selected_customers_value?: InputMaybe<Order_By>;
  settlement_account_id?: InputMaybe<Order_By>;
  trade_escrow_account_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** update columns of table "trade" */
export enum Trade_Update_Column {
  /** column name */
  ApaAgreementId = 'apa_agreement_id',
  /** column name */
  AvailableInvestmentAmount = 'available_investment_amount',
  /** column name */
  BidPrice = 'bid_price',
  /** column name */
  CalculatedCustomersValue = 'calculated_customers_value',
  /** column name */
  CollectionMode = 'collection_mode',
  /** column name */
  CompanyAmortizationTable = 'company_amortization_table',
  /** column name */
  CompanyEscrowAccountId = 'company_escrow_account_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  DebitMode = 'debit_mode',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  EmiAmount = 'emi_amount',
  /** column name */
  EmiStartDate = 'emi_start_date',
  /** column name */
  Fees = 'fees',
  /** column name */
  FeesTds = 'fees_tds',
  /** column name */
  FeesWithoutGst = 'fees_without_gst',
  /** column name */
  Id = 'id',
  /** column name */
  MandateAccountId = 'mandate_account_id',
  /** column name */
  MandateFrequency = 'mandate_frequency',
  /** column name */
  MandateId = 'mandate_id',
  /** column name */
  MandateNoOfMonths = 'mandate_no_of_months',
  /** column name */
  MandateType = 'mandate_type',
  /** column name */
  MaxPresentationAmount = 'max_presentation_amount',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PayoutOn = 'payout_on',
  /** column name */
  PayoutValue = 'payout_value',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  Roi = 'roi',
  /** column name */
  SelectedCustomersValue = 'selected_customers_value',
  /** column name */
  SettlementAccountId = 'settlement_account_id',
  /** column name */
  SigningInputs = 'signing_inputs',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  TradeEscrowAccountId = 'trade_escrow_account_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Validity = 'validity'
}

export type Trade_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Trade_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Trade_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Trade_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Trade_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Trade_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Trade_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trade_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trade_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Trade_Var_Pop_Fields = {
  __typename?: 'trade_var_pop_fields';
  apa_agreement_id?: Maybe<Scalars['Float']>;
  available_investment_amount?: Maybe<Scalars['Float']>;
  bid_price?: Maybe<Scalars['Float']>;
  calculated_customers_value?: Maybe<Scalars['Float']>;
  company_amortization_table?: Maybe<Scalars['Float']>;
  company_escrow_account_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_amount?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  fees_tds?: Maybe<Scalars['Float']>;
  fees_without_gst?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mandate_account_id?: Maybe<Scalars['Float']>;
  mandate_id?: Maybe<Scalars['Float']>;
  mandate_no_of_months?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  selected_customers_value?: Maybe<Scalars['Float']>;
  settlement_account_id?: Maybe<Scalars['Float']>;
  trade_escrow_account_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  validity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "trade" */
export type Trade_Var_Pop_Order_By = {
  apa_agreement_id?: InputMaybe<Order_By>;
  available_investment_amount?: InputMaybe<Order_By>;
  bid_price?: InputMaybe<Order_By>;
  calculated_customers_value?: InputMaybe<Order_By>;
  company_amortization_table?: InputMaybe<Order_By>;
  company_escrow_account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_amount?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  fees_tds?: InputMaybe<Order_By>;
  fees_without_gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mandate_account_id?: InputMaybe<Order_By>;
  mandate_id?: InputMaybe<Order_By>;
  mandate_no_of_months?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  selected_customers_value?: InputMaybe<Order_By>;
  settlement_account_id?: InputMaybe<Order_By>;
  trade_escrow_account_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Trade_Var_Samp_Fields = {
  __typename?: 'trade_var_samp_fields';
  apa_agreement_id?: Maybe<Scalars['Float']>;
  available_investment_amount?: Maybe<Scalars['Float']>;
  bid_price?: Maybe<Scalars['Float']>;
  calculated_customers_value?: Maybe<Scalars['Float']>;
  company_amortization_table?: Maybe<Scalars['Float']>;
  company_escrow_account_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_amount?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  fees_tds?: Maybe<Scalars['Float']>;
  fees_without_gst?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mandate_account_id?: Maybe<Scalars['Float']>;
  mandate_id?: Maybe<Scalars['Float']>;
  mandate_no_of_months?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  selected_customers_value?: Maybe<Scalars['Float']>;
  settlement_account_id?: Maybe<Scalars['Float']>;
  trade_escrow_account_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  validity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "trade" */
export type Trade_Var_Samp_Order_By = {
  apa_agreement_id?: InputMaybe<Order_By>;
  available_investment_amount?: InputMaybe<Order_By>;
  bid_price?: InputMaybe<Order_By>;
  calculated_customers_value?: InputMaybe<Order_By>;
  company_amortization_table?: InputMaybe<Order_By>;
  company_escrow_account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_amount?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  fees_tds?: InputMaybe<Order_By>;
  fees_without_gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mandate_account_id?: InputMaybe<Order_By>;
  mandate_id?: InputMaybe<Order_By>;
  mandate_no_of_months?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  selected_customers_value?: InputMaybe<Order_By>;
  settlement_account_id?: InputMaybe<Order_By>;
  trade_escrow_account_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Trade_Variance_Fields = {
  __typename?: 'trade_variance_fields';
  apa_agreement_id?: Maybe<Scalars['Float']>;
  available_investment_amount?: Maybe<Scalars['Float']>;
  bid_price?: Maybe<Scalars['Float']>;
  calculated_customers_value?: Maybe<Scalars['Float']>;
  company_amortization_table?: Maybe<Scalars['Float']>;
  company_escrow_account_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  emi_amount?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  fees_tds?: Maybe<Scalars['Float']>;
  fees_without_gst?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mandate_account_id?: Maybe<Scalars['Float']>;
  mandate_id?: Maybe<Scalars['Float']>;
  mandate_no_of_months?: Maybe<Scalars['Float']>;
  max_presentation_amount?: Maybe<Scalars['Float']>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: Maybe<Scalars['Float']>;
  roi?: Maybe<Scalars['Float']>;
  selected_customers_value?: Maybe<Scalars['Float']>;
  settlement_account_id?: Maybe<Scalars['Float']>;
  trade_escrow_account_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  validity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "trade" */
export type Trade_Variance_Order_By = {
  apa_agreement_id?: InputMaybe<Order_By>;
  available_investment_amount?: InputMaybe<Order_By>;
  bid_price?: InputMaybe<Order_By>;
  calculated_customers_value?: InputMaybe<Order_By>;
  company_amortization_table?: InputMaybe<Order_By>;
  company_escrow_account_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  emi_amount?: InputMaybe<Order_By>;
  fees?: InputMaybe<Order_By>;
  fees_tds?: InputMaybe<Order_By>;
  fees_without_gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mandate_account_id?: InputMaybe<Order_By>;
  mandate_id?: InputMaybe<Order_By>;
  mandate_no_of_months?: InputMaybe<Order_By>;
  max_presentation_amount?: InputMaybe<Order_By>;
  /** Total offered value, selected_customers_value * bid_price */
  payout_value?: InputMaybe<Order_By>;
  roi?: InputMaybe<Order_By>;
  selected_customers_value?: InputMaybe<Order_By>;
  settlement_account_id?: InputMaybe<Order_By>;
  trade_escrow_account_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** columns and relationships of "transaction" */
export type Transaction = {
  __typename?: 'transaction';
  /** An object relationship */
  account: Account;
  account_id: Scalars['Int'];
  amount: Scalars['numeric'];
  bounce_charges?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  counterparty_account: Account;
  counterparty_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  failure_reason?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  metadata?: Maybe<Scalars['jsonb']>;
  penal_interest?: Maybe<Scalars['numeric']>;
  remarks?: Maybe<Scalars['String']>;
  /** An object relationship */
  scheduled_emi?: Maybe<Scheduled_Emi>;
  scheduled_emi_id?: Maybe<Scalars['Int']>;
  source_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  tds: Array<Tds>;
  /** An aggregate relationship */
  tds_aggregate: Tds_Aggregate;
  timestamp: Scalars['timestamptz'];
  /** An object relationship */
  trade: Trade;
  trade_id: Scalars['Int'];
  trade_installment_seq?: Maybe<Scalars['Int']>;
  transaction_method: Ref_Transaction_Method_Enum;
  transaction_method_id: Scalars['String'];
  transaction_status: Ref_Transaction_Status_Enum;
  transaction_type: Ref_Transaction_Type_Enum;
  /** An array relationship */
  transfers: Array<Transfer>;
  /** An aggregate relationship */
  transfers_aggregate: Transfer_Aggregate;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "transaction" */
export type TransactionMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "transaction" */
export type TransactionTdsArgs = {
  distinct_on?: InputMaybe<Array<Tds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tds_Order_By>>;
  where?: InputMaybe<Tds_Bool_Exp>;
};


/** columns and relationships of "transaction" */
export type TransactionTds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tds_Order_By>>;
  where?: InputMaybe<Tds_Bool_Exp>;
};


/** columns and relationships of "transaction" */
export type TransactionTransfersArgs = {
  distinct_on?: InputMaybe<Array<Transfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transfer_Order_By>>;
  where?: InputMaybe<Transfer_Bool_Exp>;
};


/** columns and relationships of "transaction" */
export type TransactionTransfers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transfer_Order_By>>;
  where?: InputMaybe<Transfer_Bool_Exp>;
};

/** aggregated selection of "transaction" */
export type Transaction_Aggregate = {
  __typename?: 'transaction_aggregate';
  aggregate?: Maybe<Transaction_Aggregate_Fields>;
  nodes: Array<Transaction>;
};

export type Transaction_Aggregate_Bool_Exp = {
  count?: InputMaybe<Transaction_Aggregate_Bool_Exp_Count>;
};

export type Transaction_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Transaction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transaction_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "transaction" */
export type Transaction_Aggregate_Fields = {
  __typename?: 'transaction_aggregate_fields';
  avg?: Maybe<Transaction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Transaction_Max_Fields>;
  min?: Maybe<Transaction_Min_Fields>;
  stddev?: Maybe<Transaction_Stddev_Fields>;
  stddev_pop?: Maybe<Transaction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Transaction_Stddev_Samp_Fields>;
  sum?: Maybe<Transaction_Sum_Fields>;
  var_pop?: Maybe<Transaction_Var_Pop_Fields>;
  var_samp?: Maybe<Transaction_Var_Samp_Fields>;
  variance?: Maybe<Transaction_Variance_Fields>;
};


/** aggregate fields of "transaction" */
export type Transaction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transaction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "transaction" */
export type Transaction_Aggregate_Order_By = {
  avg?: InputMaybe<Transaction_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Transaction_Max_Order_By>;
  min?: InputMaybe<Transaction_Min_Order_By>;
  stddev?: InputMaybe<Transaction_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Transaction_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Transaction_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Transaction_Sum_Order_By>;
  var_pop?: InputMaybe<Transaction_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Transaction_Var_Samp_Order_By>;
  variance?: InputMaybe<Transaction_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Transaction_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "transaction" */
export type Transaction_Arr_Rel_Insert_Input = {
  data: Array<Transaction_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Transaction_On_Conflict>;
};

/** aggregate avg on columns */
export type Transaction_Avg_Fields = {
  __typename?: 'transaction_avg_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  penal_interest?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "transaction" */
export type Transaction_Avg_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  scheduled_emi_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transaction". All fields are combined with a logical 'AND'. */
export type Transaction_Bool_Exp = {
  _and?: InputMaybe<Array<Transaction_Bool_Exp>>;
  _not?: InputMaybe<Transaction_Bool_Exp>;
  _or?: InputMaybe<Array<Transaction_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_id?: InputMaybe<Int_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  bounce_charges?: InputMaybe<Numeric_Comparison_Exp>;
  counterparty_account?: InputMaybe<Account_Bool_Exp>;
  counterparty_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  failure_reason?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  penal_interest?: InputMaybe<Numeric_Comparison_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
  scheduled_emi?: InputMaybe<Scheduled_Emi_Bool_Exp>;
  scheduled_emi_id?: InputMaybe<Int_Comparison_Exp>;
  source_id?: InputMaybe<String_Comparison_Exp>;
  tds?: InputMaybe<Tds_Bool_Exp>;
  tds_aggregate?: InputMaybe<Tds_Aggregate_Bool_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  trade?: InputMaybe<Trade_Bool_Exp>;
  trade_id?: InputMaybe<Int_Comparison_Exp>;
  trade_installment_seq?: InputMaybe<Int_Comparison_Exp>;
  transaction_method?: InputMaybe<Ref_Transaction_Method_Enum_Comparison_Exp>;
  transaction_method_id?: InputMaybe<String_Comparison_Exp>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum_Comparison_Exp>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum_Comparison_Exp>;
  transfers?: InputMaybe<Transfer_Bool_Exp>;
  transfers_aggregate?: InputMaybe<Transfer_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "transaction" */
export enum Transaction_Constraint {
  /** unique or primary key constraint on columns "id" */
  TransactionPkey = 'transaction_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Transaction_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Transaction_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Transaction_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "transaction" */
export type Transaction_Inc_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  bounce_charges?: InputMaybe<Scalars['numeric']>;
  counterparty_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  penal_interest?: InputMaybe<Scalars['numeric']>;
  scheduled_emi_id?: InputMaybe<Scalars['Int']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "transaction" */
export type Transaction_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  bounce_charges?: InputMaybe<Scalars['numeric']>;
  counterparty_account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  counterparty_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  failure_reason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  penal_interest?: InputMaybe<Scalars['numeric']>;
  remarks?: InputMaybe<Scalars['String']>;
  scheduled_emi?: InputMaybe<Scheduled_Emi_Obj_Rel_Insert_Input>;
  scheduled_emi_id?: InputMaybe<Scalars['Int']>;
  source_id?: InputMaybe<Scalars['String']>;
  tds?: InputMaybe<Tds_Arr_Rel_Insert_Input>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  trade?: InputMaybe<Trade_Obj_Rel_Insert_Input>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
  transaction_method?: InputMaybe<Ref_Transaction_Method_Enum>;
  transaction_method_id?: InputMaybe<Scalars['String']>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum>;
  transfers?: InputMaybe<Transfer_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Transaction_Max_Fields = {
  __typename?: 'transaction_max_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  bounce_charges?: Maybe<Scalars['numeric']>;
  counterparty_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  failure_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  penal_interest?: Maybe<Scalars['numeric']>;
  remarks?: Maybe<Scalars['String']>;
  scheduled_emi_id?: Maybe<Scalars['Int']>;
  source_id?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
  transaction_method_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "transaction" */
export type Transaction_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  failure_reason?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  scheduled_emi_id?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  transaction_method_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Transaction_Min_Fields = {
  __typename?: 'transaction_min_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  bounce_charges?: Maybe<Scalars['numeric']>;
  counterparty_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  failure_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  penal_interest?: Maybe<Scalars['numeric']>;
  remarks?: Maybe<Scalars['String']>;
  scheduled_emi_id?: Maybe<Scalars['Int']>;
  source_id?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
  transaction_method_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "transaction" */
export type Transaction_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  failure_reason?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  scheduled_emi_id?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  transaction_method_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "transaction" */
export type Transaction_Mutation_Response = {
  __typename?: 'transaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transaction>;
};

/** input type for inserting object relation for remote table "transaction" */
export type Transaction_Obj_Rel_Insert_Input = {
  data: Transaction_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Transaction_On_Conflict>;
};

/** on_conflict condition type for table "transaction" */
export type Transaction_On_Conflict = {
  constraint: Transaction_Constraint;
  update_columns?: Array<Transaction_Update_Column>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};

/** Ordering options when selecting data from "transaction". */
export type Transaction_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  counterparty_account?: InputMaybe<Account_Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  failure_reason?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  scheduled_emi?: InputMaybe<Scheduled_Emi_Order_By>;
  scheduled_emi_id?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  tds_aggregate?: InputMaybe<Tds_Aggregate_Order_By>;
  timestamp?: InputMaybe<Order_By>;
  trade?: InputMaybe<Trade_Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  transaction_method?: InputMaybe<Order_By>;
  transaction_method_id?: InputMaybe<Order_By>;
  transaction_status?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
  transfers_aggregate?: InputMaybe<Transfer_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transaction */
export type Transaction_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Transaction_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "transaction" */
export enum Transaction_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  BounceCharges = 'bounce_charges',
  /** column name */
  CounterpartyId = 'counterparty_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PenalInterest = 'penal_interest',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  ScheduledEmiId = 'scheduled_emi_id',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  TradeInstallmentSeq = 'trade_installment_seq',
  /** column name */
  TransactionMethod = 'transaction_method',
  /** column name */
  TransactionMethodId = 'transaction_method_id',
  /** column name */
  TransactionStatus = 'transaction_status',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "transaction" */
export type Transaction_Set_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  bounce_charges?: InputMaybe<Scalars['numeric']>;
  counterparty_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  failure_reason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  penal_interest?: InputMaybe<Scalars['numeric']>;
  remarks?: InputMaybe<Scalars['String']>;
  scheduled_emi_id?: InputMaybe<Scalars['Int']>;
  source_id?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
  transaction_method?: InputMaybe<Ref_Transaction_Method_Enum>;
  transaction_method_id?: InputMaybe<Scalars['String']>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Transaction_Stddev_Fields = {
  __typename?: 'transaction_stddev_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  penal_interest?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "transaction" */
export type Transaction_Stddev_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  scheduled_emi_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Transaction_Stddev_Pop_Fields = {
  __typename?: 'transaction_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  penal_interest?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "transaction" */
export type Transaction_Stddev_Pop_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  scheduled_emi_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Transaction_Stddev_Samp_Fields = {
  __typename?: 'transaction_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  penal_interest?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "transaction" */
export type Transaction_Stddev_Samp_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  scheduled_emi_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "transaction" */
export type Transaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transaction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transaction_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  bounce_charges?: InputMaybe<Scalars['numeric']>;
  counterparty_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  failure_reason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  penal_interest?: InputMaybe<Scalars['numeric']>;
  remarks?: InputMaybe<Scalars['String']>;
  scheduled_emi_id?: InputMaybe<Scalars['Int']>;
  source_id?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
  transaction_method?: InputMaybe<Ref_Transaction_Method_Enum>;
  transaction_method_id?: InputMaybe<Scalars['String']>;
  transaction_status?: InputMaybe<Ref_Transaction_Status_Enum>;
  transaction_type?: InputMaybe<Ref_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Transaction_Sum_Fields = {
  __typename?: 'transaction_sum_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  bounce_charges?: Maybe<Scalars['numeric']>;
  counterparty_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  penal_interest?: Maybe<Scalars['numeric']>;
  scheduled_emi_id?: Maybe<Scalars['Int']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "transaction" */
export type Transaction_Sum_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  scheduled_emi_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "transaction" */
export enum Transaction_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  BounceCharges = 'bounce_charges',
  /** column name */
  CounterpartyId = 'counterparty_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PenalInterest = 'penal_interest',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  ScheduledEmiId = 'scheduled_emi_id',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  TradeInstallmentSeq = 'trade_installment_seq',
  /** column name */
  TransactionMethod = 'transaction_method',
  /** column name */
  TransactionMethodId = 'transaction_method_id',
  /** column name */
  TransactionStatus = 'transaction_status',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Transaction_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Transaction_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Transaction_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Transaction_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Transaction_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Transaction_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Transaction_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transaction_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transaction_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Transaction_Var_Pop_Fields = {
  __typename?: 'transaction_var_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  penal_interest?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "transaction" */
export type Transaction_Var_Pop_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  scheduled_emi_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Transaction_Var_Samp_Fields = {
  __typename?: 'transaction_var_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  penal_interest?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "transaction" */
export type Transaction_Var_Samp_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  scheduled_emi_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Transaction_Variance_Fields = {
  __typename?: 'transaction_variance_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  penal_interest?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "transaction" */
export type Transaction_Variance_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  penal_interest?: InputMaybe<Order_By>;
  scheduled_emi_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "transactions_view" */
export type Transactions_View = {
  __typename?: 'transactions_view';
  /** An object relationship */
  account?: Maybe<Account>;
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  bounce_charges?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  counterparty_account?: Maybe<Account>;
  counterparty_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  interest_charges?: Maybe<Scalars['numeric']>;
  metadata?: Maybe<Scalars['jsonb']>;
  remarks?: Maybe<Scalars['String']>;
  scheduled_emi_id?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  total_amount?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  trade?: Maybe<Trade>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
  transaction_method?: Maybe<Scalars['String']>;
  transaction_method_id?: Maybe<Scalars['String']>;
  transaction_status?: Maybe<Scalars['String']>;
  transaction_type?: Maybe<Scalars['String']>;
};


/** columns and relationships of "transactions_view" */
export type Transactions_ViewMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "transactions_view" */
export type Transactions_View_Aggregate = {
  __typename?: 'transactions_view_aggregate';
  aggregate?: Maybe<Transactions_View_Aggregate_Fields>;
  nodes: Array<Transactions_View>;
};

/** aggregate fields of "transactions_view" */
export type Transactions_View_Aggregate_Fields = {
  __typename?: 'transactions_view_aggregate_fields';
  avg?: Maybe<Transactions_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Transactions_View_Max_Fields>;
  min?: Maybe<Transactions_View_Min_Fields>;
  stddev?: Maybe<Transactions_View_Stddev_Fields>;
  stddev_pop?: Maybe<Transactions_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Transactions_View_Stddev_Samp_Fields>;
  sum?: Maybe<Transactions_View_Sum_Fields>;
  var_pop?: Maybe<Transactions_View_Var_Pop_Fields>;
  var_samp?: Maybe<Transactions_View_Var_Samp_Fields>;
  variance?: Maybe<Transactions_View_Variance_Fields>;
};


/** aggregate fields of "transactions_view" */
export type Transactions_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transactions_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Transactions_View_Avg_Fields = {
  __typename?: 'transactions_view_avg_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  interest_charges?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "transactions_view". All fields are combined with a logical 'AND'. */
export type Transactions_View_Bool_Exp = {
  _and?: InputMaybe<Array<Transactions_View_Bool_Exp>>;
  _not?: InputMaybe<Transactions_View_Bool_Exp>;
  _or?: InputMaybe<Array<Transactions_View_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_id?: InputMaybe<Int_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  bounce_charges?: InputMaybe<Numeric_Comparison_Exp>;
  counterparty_account?: InputMaybe<Account_Bool_Exp>;
  counterparty_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interest_charges?: InputMaybe<Numeric_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
  scheduled_emi_id?: InputMaybe<Int_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  total_amount?: InputMaybe<Numeric_Comparison_Exp>;
  trade?: InputMaybe<Trade_Bool_Exp>;
  trade_id?: InputMaybe<Int_Comparison_Exp>;
  trade_installment_seq?: InputMaybe<Int_Comparison_Exp>;
  transaction_method?: InputMaybe<String_Comparison_Exp>;
  transaction_method_id?: InputMaybe<String_Comparison_Exp>;
  transaction_status?: InputMaybe<String_Comparison_Exp>;
  transaction_type?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Transactions_View_Max_Fields = {
  __typename?: 'transactions_view_max_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  bounce_charges?: Maybe<Scalars['numeric']>;
  counterparty_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  interest_charges?: Maybe<Scalars['numeric']>;
  remarks?: Maybe<Scalars['String']>;
  scheduled_emi_id?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  total_amount?: Maybe<Scalars['numeric']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
  transaction_method?: Maybe<Scalars['String']>;
  transaction_method_id?: Maybe<Scalars['String']>;
  transaction_status?: Maybe<Scalars['String']>;
  transaction_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Transactions_View_Min_Fields = {
  __typename?: 'transactions_view_min_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  bounce_charges?: Maybe<Scalars['numeric']>;
  counterparty_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  interest_charges?: Maybe<Scalars['numeric']>;
  remarks?: Maybe<Scalars['String']>;
  scheduled_emi_id?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  total_amount?: Maybe<Scalars['numeric']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
  transaction_method?: Maybe<Scalars['String']>;
  transaction_method_id?: Maybe<Scalars['String']>;
  transaction_status?: Maybe<Scalars['String']>;
  transaction_type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "transactions_view". */
export type Transactions_View_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  bounce_charges?: InputMaybe<Order_By>;
  counterparty_account?: InputMaybe<Account_Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest_charges?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  scheduled_emi_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
  trade?: InputMaybe<Trade_Order_By>;
  trade_id?: InputMaybe<Order_By>;
  trade_installment_seq?: InputMaybe<Order_By>;
  transaction_method?: InputMaybe<Order_By>;
  transaction_method_id?: InputMaybe<Order_By>;
  transaction_status?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
};

/** select columns of table "transactions_view" */
export enum Transactions_View_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  BounceCharges = 'bounce_charges',
  /** column name */
  CounterpartyId = 'counterparty_id',
  /** column name */
  Id = 'id',
  /** column name */
  InterestCharges = 'interest_charges',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  ScheduledEmiId = 'scheduled_emi_id',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TotalAmount = 'total_amount',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  TradeInstallmentSeq = 'trade_installment_seq',
  /** column name */
  TransactionMethod = 'transaction_method',
  /** column name */
  TransactionMethodId = 'transaction_method_id',
  /** column name */
  TransactionStatus = 'transaction_status',
  /** column name */
  TransactionType = 'transaction_type'
}

/** aggregate stddev on columns */
export type Transactions_View_Stddev_Fields = {
  __typename?: 'transactions_view_stddev_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  interest_charges?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Transactions_View_Stddev_Pop_Fields = {
  __typename?: 'transactions_view_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  interest_charges?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Transactions_View_Stddev_Samp_Fields = {
  __typename?: 'transactions_view_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  interest_charges?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "transactions_view" */
export type Transactions_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transactions_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transactions_View_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  bounce_charges?: InputMaybe<Scalars['numeric']>;
  counterparty_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  interest_charges?: InputMaybe<Scalars['numeric']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  remarks?: InputMaybe<Scalars['String']>;
  scheduled_emi_id?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  total_amount?: InputMaybe<Scalars['numeric']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  trade_installment_seq?: InputMaybe<Scalars['Int']>;
  transaction_method?: InputMaybe<Scalars['String']>;
  transaction_method_id?: InputMaybe<Scalars['String']>;
  transaction_status?: InputMaybe<Scalars['String']>;
  transaction_type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Transactions_View_Sum_Fields = {
  __typename?: 'transactions_view_sum_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  bounce_charges?: Maybe<Scalars['numeric']>;
  counterparty_id?: Maybe<Scalars['Int']>;
  interest_charges?: Maybe<Scalars['numeric']>;
  scheduled_emi_id?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['numeric']>;
  trade_id?: Maybe<Scalars['Int']>;
  trade_installment_seq?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Transactions_View_Var_Pop_Fields = {
  __typename?: 'transactions_view_var_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  interest_charges?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Transactions_View_Var_Samp_Fields = {
  __typename?: 'transactions_view_var_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  interest_charges?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Transactions_View_Variance_Fields = {
  __typename?: 'transactions_view_variance_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  bounce_charges?: Maybe<Scalars['Float']>;
  counterparty_id?: Maybe<Scalars['Float']>;
  interest_charges?: Maybe<Scalars['Float']>;
  scheduled_emi_id?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  trade_installment_seq?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "transfer" */
export type Transfer = {
  __typename?: 'transfer';
  /** An object relationship */
  account: Account;
  account_id: Scalars['Int'];
  amount: Scalars['numeric'];
  batch_request?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  beneficiary_account?: Maybe<Account>;
  beneficiary_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  idempotent_key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  purpose?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  status: Ref_Transfer_Status_Enum;
  /** An object relationship */
  trade: Trade;
  trade_id: Scalars['Int'];
  /** An object relationship */
  transaction?: Maybe<Transaction>;
  transaction_id?: Maybe<Scalars['Int']>;
  type: Ref_Transfer_Type_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  utr?: Maybe<Scalars['String']>;
};


/** columns and relationships of "transfer" */
export type TransferBatch_RequestArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "transfer" */
export type TransferMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "transfer" */
export type Transfer_Aggregate = {
  __typename?: 'transfer_aggregate';
  aggregate?: Maybe<Transfer_Aggregate_Fields>;
  nodes: Array<Transfer>;
};

export type Transfer_Aggregate_Bool_Exp = {
  count?: InputMaybe<Transfer_Aggregate_Bool_Exp_Count>;
};

export type Transfer_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Transfer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transfer_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "transfer" */
export type Transfer_Aggregate_Fields = {
  __typename?: 'transfer_aggregate_fields';
  avg?: Maybe<Transfer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Transfer_Max_Fields>;
  min?: Maybe<Transfer_Min_Fields>;
  stddev?: Maybe<Transfer_Stddev_Fields>;
  stddev_pop?: Maybe<Transfer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Transfer_Stddev_Samp_Fields>;
  sum?: Maybe<Transfer_Sum_Fields>;
  var_pop?: Maybe<Transfer_Var_Pop_Fields>;
  var_samp?: Maybe<Transfer_Var_Samp_Fields>;
  variance?: Maybe<Transfer_Variance_Fields>;
};


/** aggregate fields of "transfer" */
export type Transfer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transfer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "transfer" */
export type Transfer_Aggregate_Order_By = {
  avg?: InputMaybe<Transfer_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Transfer_Max_Order_By>;
  min?: InputMaybe<Transfer_Min_Order_By>;
  stddev?: InputMaybe<Transfer_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Transfer_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Transfer_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Transfer_Sum_Order_By>;
  var_pop?: InputMaybe<Transfer_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Transfer_Var_Samp_Order_By>;
  variance?: InputMaybe<Transfer_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Transfer_Append_Input = {
  batch_request?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "transfer" */
export type Transfer_Arr_Rel_Insert_Input = {
  data: Array<Transfer_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Transfer_On_Conflict>;
};

/** aggregate avg on columns */
export type Transfer_Avg_Fields = {
  __typename?: 'transfer_avg_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  beneficiary_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "transfer" */
export type Transfer_Avg_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  beneficiary_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transfer". All fields are combined with a logical 'AND'. */
export type Transfer_Bool_Exp = {
  _and?: InputMaybe<Array<Transfer_Bool_Exp>>;
  _not?: InputMaybe<Transfer_Bool_Exp>;
  _or?: InputMaybe<Array<Transfer_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_id?: InputMaybe<Int_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  batch_request?: InputMaybe<Jsonb_Comparison_Exp>;
  beneficiary_account?: InputMaybe<Account_Bool_Exp>;
  beneficiary_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  idempotent_key?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  purpose?: InputMaybe<String_Comparison_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
  source_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Ref_Transfer_Status_Enum_Comparison_Exp>;
  trade?: InputMaybe<Trade_Bool_Exp>;
  trade_id?: InputMaybe<Int_Comparison_Exp>;
  transaction?: InputMaybe<Transaction_Bool_Exp>;
  transaction_id?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<Ref_Transfer_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  utr?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "transfer" */
export enum Transfer_Constraint {
  /** unique or primary key constraint on columns "idempotent_key" */
  TransferIdempotentKeyKey = 'transfer_idempotent_key_key',
  /** unique or primary key constraint on columns "id" */
  TransferPkey = 'transfer_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Transfer_Delete_At_Path_Input = {
  batch_request?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Transfer_Delete_Elem_Input = {
  batch_request?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Transfer_Delete_Key_Input = {
  batch_request?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "transfer" */
export type Transfer_Inc_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  beneficiary_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  trade_id?: InputMaybe<Scalars['Int']>;
  transaction_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "transfer" */
export type Transfer_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  batch_request?: InputMaybe<Scalars['jsonb']>;
  beneficiary_account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  beneficiary_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  idempotent_key?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  purpose?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  source_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ref_Transfer_Status_Enum>;
  trade?: InputMaybe<Trade_Obj_Rel_Insert_Input>;
  trade_id?: InputMaybe<Scalars['Int']>;
  transaction?: InputMaybe<Transaction_Obj_Rel_Insert_Input>;
  transaction_id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Ref_Transfer_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  utr?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Transfer_Max_Fields = {
  __typename?: 'transfer_max_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  beneficiary_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  idempotent_key?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  trade_id?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  utr?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "transfer" */
export type Transfer_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  beneficiary_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idempotent_key?: InputMaybe<Order_By>;
  purpose?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  utr?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Transfer_Min_Fields = {
  __typename?: 'transfer_min_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  beneficiary_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  idempotent_key?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  trade_id?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  utr?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "transfer" */
export type Transfer_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  beneficiary_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idempotent_key?: InputMaybe<Order_By>;
  purpose?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  utr?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "transfer" */
export type Transfer_Mutation_Response = {
  __typename?: 'transfer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transfer>;
};

/** on_conflict condition type for table "transfer" */
export type Transfer_On_Conflict = {
  constraint: Transfer_Constraint;
  update_columns?: Array<Transfer_Update_Column>;
  where?: InputMaybe<Transfer_Bool_Exp>;
};

/** Ordering options when selecting data from "transfer". */
export type Transfer_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  batch_request?: InputMaybe<Order_By>;
  beneficiary_account?: InputMaybe<Account_Order_By>;
  beneficiary_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idempotent_key?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  purpose?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  trade?: InputMaybe<Trade_Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction?: InputMaybe<Transaction_Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  utr?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transfer */
export type Transfer_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Transfer_Prepend_Input = {
  batch_request?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "transfer" */
export enum Transfer_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  BatchRequest = 'batch_request',
  /** column name */
  BeneficiaryId = 'beneficiary_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  IdempotentKey = 'idempotent_key',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Purpose = 'purpose',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Status = 'status',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Utr = 'utr'
}

/** input type for updating data in table "transfer" */
export type Transfer_Set_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  batch_request?: InputMaybe<Scalars['jsonb']>;
  beneficiary_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  idempotent_key?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  purpose?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  source_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ref_Transfer_Status_Enum>;
  trade_id?: InputMaybe<Scalars['Int']>;
  transaction_id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Ref_Transfer_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  utr?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Transfer_Stddev_Fields = {
  __typename?: 'transfer_stddev_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  beneficiary_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "transfer" */
export type Transfer_Stddev_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  beneficiary_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Transfer_Stddev_Pop_Fields = {
  __typename?: 'transfer_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  beneficiary_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "transfer" */
export type Transfer_Stddev_Pop_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  beneficiary_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Transfer_Stddev_Samp_Fields = {
  __typename?: 'transfer_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  beneficiary_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "transfer" */
export type Transfer_Stddev_Samp_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  beneficiary_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "transfer" */
export type Transfer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transfer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transfer_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['numeric']>;
  batch_request?: InputMaybe<Scalars['jsonb']>;
  beneficiary_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  idempotent_key?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  purpose?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  source_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ref_Transfer_Status_Enum>;
  trade_id?: InputMaybe<Scalars['Int']>;
  transaction_id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Ref_Transfer_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  utr?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Transfer_Sum_Fields = {
  __typename?: 'transfer_sum_fields';
  account_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  beneficiary_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  trade_id?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "transfer" */
export type Transfer_Sum_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  beneficiary_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "transfer" */
export enum Transfer_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  BatchRequest = 'batch_request',
  /** column name */
  BeneficiaryId = 'beneficiary_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  IdempotentKey = 'idempotent_key',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Purpose = 'purpose',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Status = 'status',
  /** column name */
  TradeId = 'trade_id',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Utr = 'utr'
}

export type Transfer_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Transfer_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Transfer_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Transfer_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Transfer_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Transfer_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Transfer_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transfer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transfer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Transfer_Var_Pop_Fields = {
  __typename?: 'transfer_var_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  beneficiary_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "transfer" */
export type Transfer_Var_Pop_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  beneficiary_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Transfer_Var_Samp_Fields = {
  __typename?: 'transfer_var_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  beneficiary_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "transfer" */
export type Transfer_Var_Samp_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  beneficiary_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Transfer_Variance_Fields = {
  __typename?: 'transfer_variance_fields';
  account_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  beneficiary_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  deleted_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trade_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "transfer" */
export type Transfer_Variance_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  beneficiary_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trade_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

export type UpdateScheduledEmiInput = {
  bounce_charges?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  interest_charges?: InputMaybe<Scalars['Float']>;
  new_date: Scalars['String'];
  remarks: Scalars['String'];
  total_amount: Scalars['Float'];
};

export type UpdateScheduledEmiOutput = {
  __typename?: 'updateScheduledEmiOutput';
  message: Scalars['String'];
};

/** columns and relationships of "updates_log" */
export type Updates_Log = {
  __typename?: 'updates_log';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  id: Scalars['uuid'];
  model_id: Scalars['Int'];
  model_name: Scalars['String'];
  prev_record: Scalars['jsonb'];
};


/** columns and relationships of "updates_log" */
export type Updates_LogPrev_RecordArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "updates_log" */
export type Updates_Log_Aggregate = {
  __typename?: 'updates_log_aggregate';
  aggregate?: Maybe<Updates_Log_Aggregate_Fields>;
  nodes: Array<Updates_Log>;
};

/** aggregate fields of "updates_log" */
export type Updates_Log_Aggregate_Fields = {
  __typename?: 'updates_log_aggregate_fields';
  avg?: Maybe<Updates_Log_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Updates_Log_Max_Fields>;
  min?: Maybe<Updates_Log_Min_Fields>;
  stddev?: Maybe<Updates_Log_Stddev_Fields>;
  stddev_pop?: Maybe<Updates_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Updates_Log_Stddev_Samp_Fields>;
  sum?: Maybe<Updates_Log_Sum_Fields>;
  var_pop?: Maybe<Updates_Log_Var_Pop_Fields>;
  var_samp?: Maybe<Updates_Log_Var_Samp_Fields>;
  variance?: Maybe<Updates_Log_Variance_Fields>;
};


/** aggregate fields of "updates_log" */
export type Updates_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Updates_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Updates_Log_Append_Input = {
  prev_record?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Updates_Log_Avg_Fields = {
  __typename?: 'updates_log_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "updates_log". All fields are combined with a logical 'AND'. */
export type Updates_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Updates_Log_Bool_Exp>>;
  _not?: InputMaybe<Updates_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Updates_Log_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  model_id?: InputMaybe<Int_Comparison_Exp>;
  model_name?: InputMaybe<String_Comparison_Exp>;
  prev_record?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "updates_log" */
export enum Updates_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  UpdatesLogPkey = 'updates_log_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Updates_Log_Delete_At_Path_Input = {
  prev_record?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Updates_Log_Delete_Elem_Input = {
  prev_record?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Updates_Log_Delete_Key_Input = {
  prev_record?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "updates_log" */
export type Updates_Log_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  model_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "updates_log" */
export type Updates_Log_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  model_id?: InputMaybe<Scalars['Int']>;
  model_name?: InputMaybe<Scalars['String']>;
  prev_record?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Updates_Log_Max_Fields = {
  __typename?: 'updates_log_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  model_id?: Maybe<Scalars['Int']>;
  model_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Updates_Log_Min_Fields = {
  __typename?: 'updates_log_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  model_id?: Maybe<Scalars['Int']>;
  model_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "updates_log" */
export type Updates_Log_Mutation_Response = {
  __typename?: 'updates_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Updates_Log>;
};

/** on_conflict condition type for table "updates_log" */
export type Updates_Log_On_Conflict = {
  constraint: Updates_Log_Constraint;
  update_columns?: Array<Updates_Log_Update_Column>;
  where?: InputMaybe<Updates_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "updates_log". */
export type Updates_Log_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
  model_name?: InputMaybe<Order_By>;
  prev_record?: InputMaybe<Order_By>;
};

/** primary key columns input for table: updates_log */
export type Updates_Log_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Updates_Log_Prepend_Input = {
  prev_record?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "updates_log" */
export enum Updates_Log_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  ModelName = 'model_name',
  /** column name */
  PrevRecord = 'prev_record'
}

/** input type for updating data in table "updates_log" */
export type Updates_Log_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  model_id?: InputMaybe<Scalars['Int']>;
  model_name?: InputMaybe<Scalars['String']>;
  prev_record?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Updates_Log_Stddev_Fields = {
  __typename?: 'updates_log_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Updates_Log_Stddev_Pop_Fields = {
  __typename?: 'updates_log_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Updates_Log_Stddev_Samp_Fields = {
  __typename?: 'updates_log_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "updates_log" */
export type Updates_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Updates_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Updates_Log_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  model_id?: InputMaybe<Scalars['Int']>;
  model_name?: InputMaybe<Scalars['String']>;
  prev_record?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type Updates_Log_Sum_Fields = {
  __typename?: 'updates_log_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  model_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "updates_log" */
export enum Updates_Log_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  ModelName = 'model_name',
  /** column name */
  PrevRecord = 'prev_record'
}

export type Updates_Log_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Updates_Log_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Updates_Log_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Updates_Log_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Updates_Log_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Updates_Log_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Updates_Log_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Updates_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Updates_Log_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Updates_Log_Var_Pop_Fields = {
  __typename?: 'updates_log_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Updates_Log_Var_Samp_Fields = {
  __typename?: 'updates_log_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Updates_Log_Variance_Fields = {
  __typename?: 'updates_log_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  agree_nda: Scalars['Boolean'];
  agree_privacy_policy: Scalars['Boolean'];
  agree_terms_conditions?: Maybe<Scalars['Boolean']>;
  auth_provider_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  company_users: Array<Company_User>;
  /** An aggregate relationship */
  company_users_aggregate: Company_User_Aggregate;
  country_code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  designation?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  email_verified: Scalars['Boolean'];
  first_name: Scalars['String'];
  id: Scalars['Int'];
  last_changed_password?: Maybe<Scalars['timestamptz']>;
  last_name: Scalars['String'];
  metadata?: Maybe<Scalars['jsonb']>;
  mobile_number: Scalars['String'];
  mobile_verified: Scalars['Boolean'];
  source: Scalars['String'];
  status: Scalars['String'];
  tenant_type: Ref_Tenant_Type_Enum;
  updated_at: Scalars['timestamptz'];
  user_agent?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "user" */
export type UserCompany_UsersArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserCompany_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "user" */
export type UserUser_AgentArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
  user_agent?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'user_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  agree_nda?: InputMaybe<Boolean_Comparison_Exp>;
  agree_privacy_policy?: InputMaybe<Boolean_Comparison_Exp>;
  agree_terms_conditions?: InputMaybe<Boolean_Comparison_Exp>;
  auth_provider_id?: InputMaybe<String_Comparison_Exp>;
  company_users?: InputMaybe<Company_User_Bool_Exp>;
  company_users_aggregate?: InputMaybe<Company_User_Aggregate_Bool_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  designation?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  email_verified?: InputMaybe<Boolean_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_changed_password?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  mobile_number?: InputMaybe<String_Comparison_Exp>;
  mobile_verified?: InputMaybe<Boolean_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_agent?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "auth_provider_id" */
  UserAuthProviderIdIdx = 'user_auth_provider_id_idx',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey',
  /** unique or primary key constraint on columns "email", "tenant_type" */
  UserTenantTypeEmailIdx = 'user_tenant_type_email_idx'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
  user_agent?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
  user_agent?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
  user_agent?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "user" */
export type User_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  agree_nda?: InputMaybe<Scalars['Boolean']>;
  agree_privacy_policy?: InputMaybe<Scalars['Boolean']>;
  agree_terms_conditions?: InputMaybe<Scalars['Boolean']>;
  auth_provider_id?: InputMaybe<Scalars['String']>;
  company_users?: InputMaybe<Company_User_Arr_Rel_Insert_Input>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  designation?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_changed_password?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  mobile_verified?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_agent?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  auth_provider_id?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  designation?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_changed_password?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  auth_provider_id?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  designation?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_changed_password?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  agree_nda?: InputMaybe<Order_By>;
  agree_privacy_policy?: InputMaybe<Order_By>;
  agree_terms_conditions?: InputMaybe<Order_By>;
  auth_provider_id?: InputMaybe<Order_By>;
  company_users_aggregate?: InputMaybe<Company_User_Aggregate_Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  designation?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_verified?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_changed_password?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  mobile_number?: InputMaybe<Order_By>;
  mobile_verified?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tenant_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_agent?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
  user_agent?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  AgreeNda = 'agree_nda',
  /** column name */
  AgreePrivacyPolicy = 'agree_privacy_policy',
  /** column name */
  AgreeTermsConditions = 'agree_terms_conditions',
  /** column name */
  AuthProviderId = 'auth_provider_id',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Designation = 'designation',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastChangedPassword = 'last_changed_password',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  MobileVerified = 'mobile_verified',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserAgent = 'user_agent'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  agree_nda?: InputMaybe<Scalars['Boolean']>;
  agree_privacy_policy?: InputMaybe<Scalars['Boolean']>;
  agree_terms_conditions?: InputMaybe<Scalars['Boolean']>;
  auth_provider_id?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  designation?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_changed_password?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  mobile_verified?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_agent?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  agree_nda?: InputMaybe<Scalars['Boolean']>;
  agree_privacy_policy?: InputMaybe<Scalars['Boolean']>;
  agree_terms_conditions?: InputMaybe<Scalars['Boolean']>;
  auth_provider_id?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  designation?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_changed_password?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  mobile_verified?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tenant_type?: InputMaybe<Ref_Tenant_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_agent?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'user_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  AgreeNda = 'agree_nda',
  /** column name */
  AgreePrivacyPolicy = 'agree_privacy_policy',
  /** column name */
  AgreeTermsConditions = 'agree_terms_conditions',
  /** column name */
  AuthProviderId = 'auth_provider_id',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Designation = 'designation',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastChangedPassword = 'last_changed_password',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  MobileVerified = 'mobile_verified',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  TenantType = 'tenant_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserAgent = 'user_agent'
}

export type User_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'user_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "verification_code" */
export type Verification_Code = {
  __typename?: 'verification_code';
  auth_provider_id: Scalars['String'];
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  expiry: Scalars['Int'];
  /** A computed field, executes function "link_expiry_date" */
  expiry_date?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
};

/** aggregated selection of "verification_code" */
export type Verification_Code_Aggregate = {
  __typename?: 'verification_code_aggregate';
  aggregate?: Maybe<Verification_Code_Aggregate_Fields>;
  nodes: Array<Verification_Code>;
};

/** aggregate fields of "verification_code" */
export type Verification_Code_Aggregate_Fields = {
  __typename?: 'verification_code_aggregate_fields';
  avg?: Maybe<Verification_Code_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Verification_Code_Max_Fields>;
  min?: Maybe<Verification_Code_Min_Fields>;
  stddev?: Maybe<Verification_Code_Stddev_Fields>;
  stddev_pop?: Maybe<Verification_Code_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Verification_Code_Stddev_Samp_Fields>;
  sum?: Maybe<Verification_Code_Sum_Fields>;
  var_pop?: Maybe<Verification_Code_Var_Pop_Fields>;
  var_samp?: Maybe<Verification_Code_Var_Samp_Fields>;
  variance?: Maybe<Verification_Code_Variance_Fields>;
};


/** aggregate fields of "verification_code" */
export type Verification_Code_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Verification_Code_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Verification_Code_Avg_Fields = {
  __typename?: 'verification_code_avg_fields';
  expiry?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "verification_code". All fields are combined with a logical 'AND'. */
export type Verification_Code_Bool_Exp = {
  _and?: InputMaybe<Array<Verification_Code_Bool_Exp>>;
  _not?: InputMaybe<Verification_Code_Bool_Exp>;
  _or?: InputMaybe<Array<Verification_Code_Bool_Exp>>;
  auth_provider_id?: InputMaybe<String_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiry?: InputMaybe<Int_Comparison_Exp>;
  expiry_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "verification_code" */
export enum Verification_Code_Constraint {
  /** unique or primary key constraint on columns "id" */
  VerificationCodePkey = 'verification_code_pkey'
}

/** input type for incrementing numeric columns in table "verification_code" */
export type Verification_Code_Inc_Input = {
  expiry?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "verification_code" */
export type Verification_Code_Insert_Input = {
  auth_provider_id?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiry?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Verification_Code_Max_Fields = {
  __typename?: 'verification_code_max_fields';
  auth_provider_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expiry?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Verification_Code_Min_Fields = {
  __typename?: 'verification_code_min_fields';
  auth_provider_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expiry?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "verification_code" */
export type Verification_Code_Mutation_Response = {
  __typename?: 'verification_code_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Verification_Code>;
};

/** on_conflict condition type for table "verification_code" */
export type Verification_Code_On_Conflict = {
  constraint: Verification_Code_Constraint;
  update_columns?: Array<Verification_Code_Update_Column>;
  where?: InputMaybe<Verification_Code_Bool_Exp>;
};

/** Ordering options when selecting data from "verification_code". */
export type Verification_Code_Order_By = {
  auth_provider_id?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expiry?: InputMaybe<Order_By>;
  expiry_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: verification_code */
export type Verification_Code_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "verification_code" */
export enum Verification_Code_Select_Column {
  /** column name */
  AuthProviderId = 'auth_provider_id',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Expiry = 'expiry',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "verification_code" */
export type Verification_Code_Set_Input = {
  auth_provider_id?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiry?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Verification_Code_Stddev_Fields = {
  __typename?: 'verification_code_stddev_fields';
  expiry?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Verification_Code_Stddev_Pop_Fields = {
  __typename?: 'verification_code_stddev_pop_fields';
  expiry?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Verification_Code_Stddev_Samp_Fields = {
  __typename?: 'verification_code_stddev_samp_fields';
  expiry?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "verification_code" */
export type Verification_Code_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Verification_Code_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Verification_Code_Stream_Cursor_Value_Input = {
  auth_provider_id?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiry?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Verification_Code_Sum_Fields = {
  __typename?: 'verification_code_sum_fields';
  expiry?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "verification_code" */
export enum Verification_Code_Update_Column {
  /** column name */
  AuthProviderId = 'auth_provider_id',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Expiry = 'expiry',
  /** column name */
  Id = 'id'
}

export type Verification_Code_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Verification_Code_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Verification_Code_Set_Input>;
  /** filter the rows which have to be updated */
  where: Verification_Code_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Verification_Code_Var_Pop_Fields = {
  __typename?: 'verification_code_var_pop_fields';
  expiry?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Verification_Code_Var_Samp_Fields = {
  __typename?: 'verification_code_var_samp_fields';
  expiry?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Verification_Code_Variance_Fields = {
  __typename?: 'verification_code_variance_fields';
  expiry?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "webhook_events" */
export type Webhook_Events = {
  __typename?: 'webhook_events';
  event_name: Scalars['String'];
  id: Scalars['uuid'];
  payload: Scalars['jsonb'];
  received_timestamp: Scalars['timestamptz'];
  source: Scalars['String'];
  status: Scalars['String'];
};


/** columns and relationships of "webhook_events" */
export type Webhook_EventsPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "webhook_events" */
export type Webhook_Events_Aggregate = {
  __typename?: 'webhook_events_aggregate';
  aggregate?: Maybe<Webhook_Events_Aggregate_Fields>;
  nodes: Array<Webhook_Events>;
};

/** aggregate fields of "webhook_events" */
export type Webhook_Events_Aggregate_Fields = {
  __typename?: 'webhook_events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Webhook_Events_Max_Fields>;
  min?: Maybe<Webhook_Events_Min_Fields>;
};


/** aggregate fields of "webhook_events" */
export type Webhook_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Webhook_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Webhook_Events_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "webhook_events". All fields are combined with a logical 'AND'. */
export type Webhook_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Webhook_Events_Bool_Exp>>;
  _not?: InputMaybe<Webhook_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Webhook_Events_Bool_Exp>>;
  event_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  received_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "webhook_events" */
export enum Webhook_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  WebhookEventsPkey = 'webhook_events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Webhook_Events_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Webhook_Events_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Webhook_Events_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "webhook_events" */
export type Webhook_Events_Insert_Input = {
  event_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  received_timestamp?: InputMaybe<Scalars['timestamptz']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Webhook_Events_Max_Fields = {
  __typename?: 'webhook_events_max_fields';
  event_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  received_timestamp?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Webhook_Events_Min_Fields = {
  __typename?: 'webhook_events_min_fields';
  event_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  received_timestamp?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "webhook_events" */
export type Webhook_Events_Mutation_Response = {
  __typename?: 'webhook_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Webhook_Events>;
};

/** on_conflict condition type for table "webhook_events" */
export type Webhook_Events_On_Conflict = {
  constraint: Webhook_Events_Constraint;
  update_columns?: Array<Webhook_Events_Update_Column>;
  where?: InputMaybe<Webhook_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "webhook_events". */
export type Webhook_Events_Order_By = {
  event_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  received_timestamp?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: webhook_events */
export type Webhook_Events_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Webhook_Events_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "webhook_events" */
export enum Webhook_Events_Select_Column {
  /** column name */
  EventName = 'event_name',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  ReceivedTimestamp = 'received_timestamp',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "webhook_events" */
export type Webhook_Events_Set_Input = {
  event_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  received_timestamp?: InputMaybe<Scalars['timestamptz']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "webhook_events" */
export type Webhook_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Webhook_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Webhook_Events_Stream_Cursor_Value_Input = {
  event_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  received_timestamp?: InputMaybe<Scalars['timestamptz']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "webhook_events" */
export enum Webhook_Events_Update_Column {
  /** column name */
  EventName = 'event_name',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  ReceivedTimestamp = 'received_timestamp',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status'
}

export type Webhook_Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Webhook_Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Webhook_Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Webhook_Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Webhook_Events_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Webhook_Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Webhook_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Webhook_Events_Bool_Exp;
};
