import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as InformationCircleIcon } from "../../../assets/Icon/Outline/information-circle-icon.svg";

interface ConfirmationModalPropsInterface {
  onCancelClick?: () => void;
  onProceedClick?: () => void;
  loading: any;
}
const BoxGrid = styled(Grid)`
  background: white;
  padding: 32px;
  width: 510px;
`;
const ConfirmationModal = (props: ConfirmationModalPropsInterface) => {
  const { onCancelClick, onProceedClick, loading } = props;
  return (
    <BoxGrid item style={{ background: "white" }}>
      <Grid item>
        <Typography
          display={"flex"}
          alignItems={"center"}
          fontWeight={700}
          gap={2}
          fontSize={"16px"}
        >
          <InformationCircleIcon color="#FF4D4F" width="20px" height="20px" />
          Are you sure you want to proceed?
        </Typography>
      </Grid>
      <br />
      <br />
      <Grid
        item
        width={"100%"}
        justifyContent={"flex-end"}
        display={"flex"}
        gap={"8px"}
      >
        <Button onClick={onCancelClick} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={onProceedClick}
          disabled={loading}
          variant="contained"
          color="primary"
        >
          {loading ? <CircularProgress size={"24px"} /> : "Proceed"}
        </Button>
      </Grid>
    </BoxGrid>
  );
};

export default ConfirmationModal;
