/* eslint-disable array-callback-return */
import {
  Button,
  Grid,
  Typography,
  Divider,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputAdornment,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import SimpleInput from "../../../components/input/SimpleInput";
import { useGetScoreFactorQuery } from "../../../data-access/hooks/company/score.hooks";
import {
  useUpdateCompanyTimeSeriesSequenceMutation,
  useUpdateCompanyTradeFactorsMutation,
} from "../../../data-access/hooks/company/deals-trade.hooks";
import { CompanyContext } from "../../../contexts/CompanyContext";
import SideModal from "../../../components/Modals/SideModal";

interface Props {
  open: boolean;
  setStepNumber: any;
  handleDialogClose: () => void;
  scoreFactorRefetch: () => void;
  stepNumber: number;
  scoreData: any;
  setCreatedScoreId: any;
}

const EditTradeFactor = (props: Props) => {
  const { open, handleDialogClose, scoreFactorRefetch, scoreData } = props;

  const toEditData = scoreData?.score_factors;

  const { setShowAlert } = useContext(CompanyContext);

  const [scoringFactors, setScoringFactors] = useState<any>();

  /*************************************************************************************************************/
  const [handleUpdateTimeseriesSequence, { loading: timeSeriesLoading }] =
    useUpdateCompanyTimeSeriesSequenceMutation({
      onCompleted: (item) => {
        handleDialogClose();
        scoreFactorRefetch();
      },
      onError: (item) => {
        setShowAlert({
          show: true,
          message: item?.message,
          type: "error",
        });
      },
    });

  /*************************************************************************************************************/
  const [handleUpdateCompanyTradeFactors, { loading: updateLoading }] =
    useUpdateCompanyTradeFactorsMutation({
      onCompleted: (item) => {
        handleDialogClose();
        scoreFactorRefetch();
      },
      onError: (item) => {
        setShowAlert({
          show: true,
          message: item?.message,
          type: "error",
        });
      },
    });
  /*************************************************************************************************************/
  const { loading: getTradeFactorLoading } = useGetScoreFactorQuery({
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const x = data.score_factor.map((item) => {
        return {
          id: item.id,
          label: item.ref_score_factor.name,
          name: item.ref_score_factor.id,
          unit: item.unit,
        };
      });
      setScoringFactors(x);
    },
    onError: (item) => {
      setShowAlert({
        show: true,
        message: item?.message,
        type: "error",
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      time_series: scoreData?.time_series || "",
      sequence: scoreData?.sequence || "",
      1:
        toEditData?.find((item: any) => item.score_factor_id === 1)?.value ||
        "",
      2:
        toEditData?.find((item: any) => item.score_factor_id === 2)?.value ||
        "",
      3:
        toEditData?.find((item: any) => item.score_factor_id === 3)?.value ||
        "",
      4:
        toEditData?.find((item: any) => item.score_factor_id === 4)?.value ||
        "",
      5:
        toEditData?.find((item: any) => item.score_factor_id === 5)?.value ||
        "",
      6:
        toEditData?.find((item: any) => item.score_factor_id === 6)?.value ||
        "",
      7:
        toEditData?.find((item: any) => item.score_factor_id === 7)?.value ||
        "",
      8:
        toEditData?.find((item: any) => item.score_factor_id === 8)?.value ||
        "",
      9:
        toEditData?.find((item: any) => item.score_factor_id === 9)?.value ||
        "",
      10:
        toEditData?.find((item: any) => item.score_factor_id === 10)?.value ||
        Date.now(),
      11:
        toEditData?.find((item: any) => item.score_factor_id === 11)?.value ||
        "",
      12:
        toEditData?.find((item: any) => item.score_factor_id === 12)?.value ||
        "",
    },
    onSubmit: (values) => {
      const x = toEditData?.map((item: any, index: number) => {
        return {
          where: {
            id: {
              _eq: item.id,
            },
          },
          _set: {
            value: Object.entries(values).map(([key, value]) => {
              if (Number(key) === item.score_factor_id) {
                return Number(value);
              }
            })[index],
          },
        };
      });
      handleUpdateTimeseriesSequence({
        variables: {
          id: scoreData?.score_factor_id,
          sequence: scoreData?.sequence,
          time_series: scoreData?.time_series,
        },
      });
      handleUpdateCompanyTradeFactors({
        variables: {
          updates: x,
        },
      });
    },
  });

  return (
    <SideModal
      open={open}
      onClose={() => handleDialogClose()}
      modalTitle={"Edit Trade Factors"}
      footer={
        <Button
          disabled={updateLoading || timeSeriesLoading}
          variant="primarySmall"
          type="submit"
          fullWidth
          form="edit-trade-factor-form"
        >
          {updateLoading || timeSeriesLoading ? (
            <CircularProgress size={20} />
          ) : (
            "Update"
          )}
        </Button>
      }
    >
      <form onSubmit={formik.handleSubmit} id="edit-trade-factor-form">
        <Accordion expanded={true}>
          <AccordionSummary>
            <Box display={"flex"} flex={1} justifyContent="space-between">
              <Typography fontWeight={600} variant="body2">
                Time Series:{formik.values.time_series}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {getTradeFactorLoading ? (
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Skeleton />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="standard">
                    <SimpleInput
                      fullWidth
                      requiredlabel
                      id="time_series"
                      name="time_series"
                      placeholder="Example: FY 2022"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputlabel="Time Series"
                      value={formik.values.time_series}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="standard">
                    <SimpleInput
                      id="sequence"
                      name="sequence"
                      fullWidth
                      placeholder="Enter The Sequence"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputlabel="Sequence"
                      value={formik.values.sequence}
                      type={"number"}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    variant="fullWidth"
                    orientation="horizontal"
                  ></Divider>
                </Grid>
                {scoringFactors?.map(
                  (item: {
                    id: number;
                    label: string;
                    unit: string;
                    name: string;
                  }) => (
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth variant="standard">
                        <SimpleInput
                          id={item.id}
                          fullWidth
                          onChange={formik.handleChange}
                          name={`${item.id}`}
                          onBlur={formik.handleBlur}
                          inputlabel={item.label}
                          //@ts-ignore
                          value={formik.values[item.id]}
                          InputProps={{
                            endAdornment:
                              (item.unit === "PERCENTAGE" && (
                                <InputAdornment position="end">
                                  <Typography
                                    color={"#000"}
                                    fontSize="14px"
                                    fontWeight={500}
                                  >
                                    %
                                  </Typography>
                                </InputAdornment>
                              )) ||
                              ((item?.name === "CAPITAL_EFFICIENCY" ||
                                item?.name === "LTV_TO_CAC" ||
                                item?.name === "DEBT_TO_EQUITY_RATIO") && (
                                <InputAdornment position="end">
                                  <Typography fontSize={30}>÷</Typography>
                                </InputAdornment>
                              )) ||
                              (item.unit === "AMOUNT" && (
                                <InputAdornment position="end">
                                  <Typography
                                    color={"#000"}
                                    fontSize="14px"
                                    fontWeight={500}
                                  >
                                    ₹
                                  </Typography>
                                </InputAdornment>
                              )),
                          }}
                          type={"number"}
                        />
                      </FormControl>
                    </Grid>
                  )
                )}
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </form>
    </SideModal>
  );
};

export default EditTradeFactor;
