const colors = {
  primary: "#1E88E5",
  primaryHover: "#1A6FBA",
  white: "#fff",
  disableBackground: "#E0E0E0",
  disableText: "#7D87A6",
  primaryLight: "#EEF5FC",
};

const components = {
  MuiButton: {
    variants: [
      {
        props: { variant: "linkButton" },
        style: {
          borderRadius: "4px",
          height: "36px",
          padding: "0",
          background: "transparent",
          color: colors.primary,
          fontWeight: "500",
          fontSize: "14px",
          "&:hover": {
            background: "transparent",
            textDecoration: "underline",
          },
          "&:disabled": {
            background: colors.disableBackground,
            color: colors.disableText,
          },
          "&:focus": {
            border: `none`,
          },
        },
      },
      {
        props: { variant: "tertiarySmall" },
        style: {
          borderRadius: "4px",
          height: "36px",
          background: "transparent",
          color: colors.primary,
          fontWeight: "500",
          fontSize: "14px",
          "&:hover": {
            background: colors.primaryLight,
          },
          "&:disabled": {
            background: colors.disableBackground,
            color: colors.disableText,
          },
          "&:focus": {
            border: `solid 2px #000`,
          },
        },
      },
      {
        props: { variant: "tertiaryMedium" },
        style: {
          borderRadius: "4px",
          height: "40px",
          background: "transparent",
          color: colors.primary,
          fontWeight: "500",
          fontSize: "14px",
          "&:hover": {
            background: colors.primaryLight,
          },
          "&:disabled": {
            background: colors.disableBackground,
            border: `none`,
            color: colors.disableText,
          },
          "&:focus": {
            border: `solid 2px #000`,
          },
        },
      },
      {
        props: { variant: "tertiaryLarge" },
        style: {
          borderRadius: "4px",
          height: "50px",
          background: "transparent",
          color: colors.primary,
          fontWeight: "500",
          fontSize: "14px",
          "&:hover": {
            background: colors.primaryLight,
          },
          "&:disabled": {
            background: colors.disableBackground,
            border: `none`,
            color: colors.disableText,
          },
          "&:focus": {
            border: `solid 2px #000`,
          },
        },
      },
      {
        props: { variant: "secondarySmall" },
        style: {
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          height: "36px",
          background: colors.white,
          color: colors.primary,
          border: "solid 1px",
          borderColor: colors.primary,
          fontWeight: "500",
          fontSize: "14px",
          "&:hover": {
            background: colors.primaryLight,
          },
          "&:disabled": {
            background: colors.disableBackground,
            color: colors.disableText,
            border: `none`,
          },
          "&:focus": {
            border: `solid 2px #000`,
          },
        },
      },
      {
        props: { variant: "secondaryMedium" },
        style: {
          borderRadius: "4px",
          height: "40px",
          background: colors.white,
          color: colors.primary,
          border: "solid 1px",
          borderColor: colors.primary,
          fontWeight: "500",
          fontSize: "14px",
          "&:hover": {
            background: colors.primaryLight,
          },
          "&:disabled": {
            background: colors.disableBackground,
            border: `none`,
            color: colors.disableText,
          },
          "&:focus": {
            border: `solid 2px #000`,
          },
        },
      },
      {
        props: { variant: "secondaryLarge" },
        style: {
          borderRadius: "4px",
          height: "50px",
          background: colors.white,
          color: colors.primary,
          border: "solid 1px",
          borderColor: colors.primary,
          fontWeight: "500",
          fontSize: "14px",
          "&:hover": {
            background: colors.primaryLight,
          },
          "&:disabled": {
            background: colors.disableBackground,
            border: `none`,
            color: colors.disableText,
          },
          "&:focus": {
            border: `solid 2px #000`,
          },
        },
      },
      {
        props: { variant: "dropdownButton" },
        style: {
          borderRadius: "4px",
          height: "40px",
          background: colors.white,
          color: "#000000",
          border: "solid 1px",
          borderColor: "rgba(0, 0, 0, 0.23)",
          fontWeight: "500",
          fontSize: "14px",
          "&:hover": {
            background: colors.primaryLight,
          },
          "&:disabled": {
            background: colors.disableBackground,
            border: `none`,
            color: colors.disableText,
          },
          "&:focus": {
            border: `solid 2px #000`,
          },
        },
      },
      {
        props: { variant: "primarySmall" },
        style: {
          borderRadius: "4px",
          height: "36px",
          background: colors.primary,
          color: colors.white,
          fontWeight: "500",
          fontSize: "14px",
          "&:hover": {
            background: colors.primaryHover,
          },
          "&:disabled": {
            background: colors.disableBackground,
            color: colors.disableText,
          },
          "&:focus": {
            border: `solid 2px #000`,
          },
        },
      },
      {
        props: { variant: "primaryMedium" },
        style: {
          borderRadius: "4px",
          height: "40px",
          background: colors.primary,
          color: colors.white,
          fontWeight: "500",
          fontSize: "14px",
          "&:hover": {
            background: colors.primaryHover,
          },
          "&:disabled": {
            background: colors.disableBackground,
            color: colors.disableText,
          },
          "&:focus": {
            border: `solid 2px #000`,
          },
        },
      },
      {
        props: { variant: "primaryLarge" },
        style: {
          borderRadius: "4px",
          height: "50px",
          background: colors.primary,
          color: colors.white,
          fontWeight: "500",
          fontSize: "14px",
          "&:hover": {
            background: colors.primaryHover,
          },
          "&:disabled": {
            background: colors.disableBackground,
            color: colors.disableText,
          },
          "&:focus": {
            border: `solid 2px #000`,
          },
        },
      },
    ],
  },
  MuiIconButton: {
    variants: [
      {
        props: { color: "primary" },
        style: {
          border: "1px solid rgba(30, 136, 229, 0.2)",
        },
      },
    ],
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "h6",
      },
    },
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
        boxShadow:
          "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
        backgroundImage: "none",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
        "&::-webkit-scrollbar": {
          height: "5px",
          width: "2px",
        },
        "&::-webkit-scrollbar-track": {
          background: " #1e88e555",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#1e88e5",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: "300",
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: "64px",
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`,
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px",
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300,
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        tr: {
          background: "#ecf5ff",
        },
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        tr: {
          backgroundColor: "#ffffff",
          "&:hover": {
            backgroundColor: "#fbf9f9",
          },
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        color: "#000000",
        fontWeight: 600,
        borderBottomWidth: ".125rem",
        paddingTop: "0.75rem",
        paddingBottom: "0.75rem",
        background: "transparent",
        whiteSpace: "nowrap",
        fontSize: "14px",
      },
      body: {
        color: "#000000",
        fontWeight: 400,
        fontSize: "14px",
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      hover: {
        backgroundColor: "#a3aed0",
        "&:hover": {
          backgroundColor: "#a3aed0",
        },
      },
    },
  },
  MuiModal: {
    styleOverrides: {
      root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: {
        "& .MuiPaper-root": {
          boxShadow: "0px 0px 3px 0px #ebebeb",
        },
        "& .MuiMenu-list": {
          padding: 10,
        },

        "& .MuiMenuItem-root:hover": {
          background: "#1E88E524",
          color: "#1E88E5",
        },
        "& .MuiMenuItem-root": {
          borderBottom: "solid 1px #E0E0E078",
          borderRadius: "4px",
        },
        "& .MuiMenuItem-root:last-child": {
          borderBottom: "none",
        },
      },
    },
  },
  MuiAlert: {
    variants: [
      {
        props: { severity: "success" },
        style: {
          background: "#E9F6EB",
          color: "#57B867",
          fontWeight: "600",
          border: "solid 1px #57B867",
          "& .MuiSvgIcon-root": {
            color: "#57B867",
          },
        },
      },
      {
        props: { severity: "info" },
        style: {
          background: "#E2F0FC",
          color: "#1E88E5",
          fontWeight: "600",
          border: "solid 1px #1E88E5",
          "& .MuiSvgIcon-root": {
            color: "#1E88E5",
          },
        },
      },
      {
        props: { severity: "error" },
        style: {
          background: "#FFE6EB",
          color: "#DD3838",
          fontWeight: "600",
          border: "solid 1px #DD3838",
          "& .MuiSvgIcon-root": {
            color: "#DD3838",
          },
        },
      },
      {
        props: { severity: "warning" },
        style: {
          background: "#FDECDE",
          color: "#ED6C02",
          fontWeight: "600",
          border: "solid 1px #ED6C02",
          "& .MuiSvgIcon-root": {
            color: "#ED6C02",
          },
        },
      },
    ],
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: 35,
        height: 19,
        padding: 0,
        borderRadius: 50,
        display: "flex",
        "&:active": {
          "& .MuiSwitch-thumb": {
            width: 15,
          },
          "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
          },
        },
        "& .MuiSwitch-switchBase": {
          padding: 2,
          "&.Mui-checked": {
            transform: "translateX(15px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
              opacity: 1,
              backgroundColor: "#1E88E5",
            },
          },
        },
        "& .MuiSwitch-thumb": {
          boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
          width: 15,
          height: 15,
          color: "#fff",
          borderRadius: 10,
        },
        "& .MuiSwitch-track": {
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor: "#D3D4D6",
          boxSizing: "border-box",
        },
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        "& .MuiTablePagination-toolbar .MuiTablePagination-spacer": {
          display: "none",
        },
        "& .MuiTablePagination-toolbar .MuiInputBase-colorPrimary": {
          border: "solid 1px #00000021",
          borderRadius: "6px",
        },
        "& .MuiTablePagination-toolbar": {
          padding: 5,
        },
        "& .MuiPaginationItem-outlined": {
          borderRadius: "6px",
          fontSize: "14px",
          fontWeight: "400",
          minWidth: 28,
          height: 30,
        },
      },
    },
  },
};

export default components;
