import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  CircularProgress,
  FormControl,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import SimpleInput from "../input/SimpleInput";

const Alert = styled(MuiAlert)(spacing);

const MuiButton = styled(Button)`
  &:disabled {
    background: #dae9fa;
  }
`;

function SignIn() {
  const navigate = useNavigate();

  const { signIn } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        rememberme: false,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          localStorage.setItem("rememberme", String(values.rememberme));
          await signIn(values.email, values.password);
          navigate("/company-queues");
        } catch (error: any) {
          const message = error.response.data.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Alert mt={3} mb={3} severity="info">
            Use <strong>your_name@brigeup.com</strong> and{" "}
            <strong>your_password</strong> to sign in
          </Alert>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <FormControl fullWidth>
            <SimpleInput
              requiredlabel
              type="email"
              name="email"
              inputlabel="Email Address"
              value={values.email}
              error={touched.email && Boolean(errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
              autoFocus
              InputProps={{
                style: {
                  padding: 0,
                },
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <SimpleInput
              requiredlabel
              type={showPassword ? "text" : "password"}
              name="password"
              inputlabel="Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
              InputProps={{
                style: {
                  padding: 0,
                },
                endAdornment: values.password !== "" && (
                  <Button
                    variant="linkButton"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? "Show" : "Hide"}
                  </Button>
                ),
              }}
            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                value={values.rememberme}
                name="rememberme"
                onChange={handleChange}
                color="primary"
              />
            }
            label="Remember me"
          />
          <MuiButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={25} /> : "  Sign in"}
          </MuiButton>
          <Button
            component={Link}
            to="/auth/reset-password"
            fullWidth
            color="primary"
          >
            Forgot password
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
