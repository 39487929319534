import {
  Grid,
  Typography,
  Button,
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import SimpleInput from "../../components/input/SimpleInput";
import {
  useCreateOfferMutation,
  useGetAllInvestorQuery,
} from "../../data-access/hooks/company/deals-trade.hooks";
import { useContext, useState } from "react";
import { ReactComponent as ChevronDownIcon } from "../../assets/Icon/Outline/chevron-down-icon.svg";
import { createStyles, makeStyles } from "@mui/styles";
import { IdPrefixFormat } from "../../data-access/formatter";
import { CompanyContext } from "../../contexts/CompanyContext";
import SideModal from "../../components/Modals/SideModal";

interface InvestorInterface {
  id: Number;
  name: String;
}

const ITEM_HEIGHT = 60;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5,
      padding: "10px",
      boxShadow: "#00000044 1px 1px 10px 3px",
    },
  },
};
const useStyles = makeStyles(() =>
  createStyles({
    customSelect: {
      padding: "0 40px 0 10px",
      height: "40px !important",
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
  })
);

const AddOfferModal = ({ onClose, dealId, offerRefetch, open }: any) => {
  const [allInvestors, setAllInvestors] = useState<InvestorInterface[]>();

  const { setShowAlert } = useContext(CompanyContext);

  const { loading: getInvestorLoading } = useGetAllInvestorQuery({
    onCompleted: (item) => setAllInvestors(item.investor),
  });
  /**---------------------------------------------------------------------------------------- */
  const [addNewOffer, { loading: addOfferLoading }] = useCreateOfferMutation({
    onCompleted: (item) => {
      offerRefetch();
      setShowAlert({
        show: true,
        message: `Offer with ID:${item.placeBid?.bid_id} is successfully created`,
        type: "success",
      });
      onClose(false);
    },
    onError: (item) => {
      offerRefetch();
      setShowAlert({
        show: true,
        message: item.message,
        type: "error",
      });
    },
  });

  /**---------------------------------------------------------------------------------------- */

  const formik = useFormik({
    initialValues: {
      investors: "",
      interest_rate: "",
      amount: "",
      remark: "",
    },
    onSubmit: (data) => {
      addNewOffer({
        variables: {
          args: {
            deal_id: dealId,
            roi: Number(data.interest_rate),
            max_investment_amount: Number(data.amount),
            remarks: data.remark,
          },
        },
        context: {
          headers: {
            "X-hasura-proxy-tenant-type": "INVESTOR",
            "X-hasura-proxy-tenant-id": data.investors,
            "X-hasura-tenant-id": data.investors,
          },
        },
      });
    },
  });

  const classes = useStyles();

  return (
    <SideModal
      open={open}
      onClose={() => onClose(false)}
      modalTitle="  Add New Offer"
      footer={
        <Button
          disabled={addOfferLoading}
          type="submit"
          fullWidth
          variant="primaryMedium"
          form="add-bid-form"
        >
          {addOfferLoading ? <CircularProgress size={20} /> : "Submit"}
        </Button>
      }
    >
      {!getInvestorLoading && (
        <form
          id="add-bid-form"
          onSubmit={formik.handleSubmit}
          style={{ width: "100%" }}
        >
          <Grid item width="100%">
            <FormControl fullWidth margin="dense">
              <FormLabel style={{ fontSize: "14px", fontWeight: "500" }}>
                Investor
              </FormLabel>
              <Select
                IconComponent={() => (
                  <span style={{ marginRight: "12px", display: "flex" }}>
                    <ChevronDownIcon width={20} height={20} color={"#1E88E5"} />
                  </span>
                )}
                name="investors"
                id="investors"
                value={formik.values.investors}
                onChange={formik.handleChange}
                classes={{
                  select: classes.customSelect,
                }}
                displayEmpty
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                renderValue={() => {
                  if (formik.values.investors === "") {
                    return (
                      <span style={{ color: "#66666669" }}>
                        Please select an Investor
                      </span>
                    );
                  } else {
                    return allInvestors?.filter(
                      (item) => item.id === Number(formik.values.investors)
                    )[0]?.name;
                  }
                }}
              >
                {allInvestors?.map((item: any) => (
                  <MenuItem value={item.id}>
                    {IdPrefixFormat(item?.id, "I")} - {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <SimpleInput
                inputlabel="Interest rate"
                requiredlabel
                name="interest_rate"
                onChange={formik.handleChange}
                value={formik.values.interest_rate}
                type="number"
                placeholder="Enter interest rate"
                InputProps={{
                  endAdornment: <Typography margin={"0 6px"}>%</Typography>,
                }}
              />
            </FormControl>
            <FormControl fullWidth margin="dense">
              <SimpleInput
                inputlabel="Max Invested Amount"
                requiredlabel
                placeholder="Enter Max Invested Amount"
                name="amount"
                onChange={formik.handleChange}
                value={formik?.values?.amount}
                type="number"
              />
            </FormControl>
            <FormControl fullWidth margin="dense">
              <SimpleInput
                inputlabel="Remark"
                placeholder="Enter Remark"
                name="remark"
                onChange={formik.handleChange}
                value={formik?.values?.remark}
                type="text"
              />
            </FormControl>
          </Grid>
        </form>
      )}
    </SideModal>
  );
};

export default AddOfferModal;
