import { Skeleton, Table, TableHead } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableWrapper } from "../theme/customComponents";

interface LoaderProps {
  loadingCount?: any[];
}

const CommonLoader = ({ loadingCount }: LoaderProps) => {
  const loadingData = loadingCount
    ? loadingCount
    : [1, 2, 3, 4, 5, 5, 4, 7, 8, 7];

  return (
    <TableWrapper
      style={{
        width: "100%",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Skeleton
                variant="text"
                sx={{ fontSize: "11px", margin: "0px" }}
              />
            </TableCell>
            <TableCell>
              <Skeleton
                variant="text"
                sx={{ fontSize: "11px", margin: "0px" }}
              />
            </TableCell>
            <TableCell>
              <Skeleton
                variant="text"
                sx={{ fontSize: "11px", margin: "0px" }}
              />
            </TableCell>
            <TableCell>
              <Skeleton
                variant="text"
                sx={{ fontSize: "11px", margin: "0px" }}
              />
            </TableCell>
            <TableCell>
              <Skeleton
                variant="text"
                sx={{ fontSize: "11px", margin: "0px" }}
              />
            </TableCell>
            <TableCell>
              <Skeleton
                variant="text"
                sx={{ fontSize: "11px", margin: "0px" }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loadingData?.map((row: any, index: number) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "11px", margin: "0px" }}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "11px", margin: "0px" }}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "11px", margin: "0px" }}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "11px", margin: "0px" }}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "11px", margin: "0px" }}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "11px", margin: "0px" }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

export default CommonLoader;
