import {
  useDashboardLazyQuery,
  useDashboardQuery,
} from "./../hooks/staff/dashboard-total-data.hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllDashboardCompaniesQuery } from "../hooks/company/company.hooks";
import { useGetScheduledEmiQuery } from "../hooks/staff/schedule-emi.hooks";
import { endOfMonth, format, startOfMonth, sub } from "date-fns";
import { Order_By } from "../types";

const useDashboardData = () => {
  const navigate = useNavigate();
  const [allCompanyListData, setAllCompanyData] = useState([]);
  const [allTotalData, setAllTotalData] = useState<any>();
  const [allEMIData, setAllEMIData] = useState([]);
  const [age, setAge] = useState(0);

  //?----------------------------------CURRENT MONTH FUNCTION------------------------------------------------ */
  const today = new Date();
  const thirtyDaysBefore = new Date(new Date().setDate(today.getDate() - 30));
  const sevenDaysBefore = new Date(new Date().setDate(today.getDate() - 7));
  const thirtyDayFromSeven = new Date(new Date().setDate(today.getDate() + 30));
  const endDateOfMonth = endOfMonth(thirtyDaysBefore);
  const pervThreeMonths = sub(endDateOfMonth, {
    months: 3,
  });

  const dashboardDropdownData = [
    {
      text: "Current month",
      value: 0,
    },
    {
      text: "Last 30 days",
      value: 1,
    },
    {
      text: "90 days",
      value: 2,
    },
    {
      text: "6 months",
      value: 3,
    },
    {
      text: "One Year",
      value: 4,
    },
  ];

  //?----------------------------------CURRENT MONTH FUNCTION------------------------------------------------ */
  const allMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date();
  const currentMonth = allMonths[d.getMonth()];

  //?----------------------------------ALL EMI's LIST-------------------------------------------------------- */
  const { loading: EMIListLoading } = useGetScheduledEmiQuery({
    variables: {
      limit: 20,
      offset: 0,
      order_by: [{ created_at: Order_By.Desc }],
      where: {
        scheduled_at: {
          _gte: format(sevenDaysBefore, "yyyy-MM-dd"),
          _lte: format(thirtyDayFromSeven, "yyyy-MM-dd"),
        },
      },
    },
    onCompleted: (item: any) => setAllEMIData(item.scheduled_emi),
  });

  //?----------------------------------ALL COMPANIES LIST---------------------------------------------------- */
  const { loading: CompanyListLoaing } = useGetAllDashboardCompaniesQuery({
    variables: {
      where: {
        stage: {
          _eq: "D4_SQL" as any,
        },
        end_date: {
          _is_null: true,
        },
      },
    },
    fetchPolicy: "no-cache",
    onCompleted: (item: any) =>
      setAllCompanyData(item?.application_lifecycle_stage),
  });
  //?------------------------------------------------------------------------------------------------------- */
  const [getDataBySelectValue, { loading: totalDataGetLoading }] =
    useDashboardLazyQuery({
      onCompleted: (item) => setAllTotalData(item),
    });

  const handleChange = (event: any) => {
    setAge(event.target.value);
    if (event.target.value === 0) {
      getDataBySelectValue({
        variables: {
          endDate: format(new Date(), "yyyy-MM-dd"),
          startDate: format(startOfMonth(new Date()), "yyyy-MM-dd"),
        },
      });
    } else if (event.target.value === 1) {
      getDataBySelectValue({
        variables: {
          endDate: format(new Date(), "yyyy-MM-dd"),
          startDate: format(
            sub(new Date(), {
              months: 1,
            }),
            "yyyy-MM-dd"
          ),
        },
      });
    } else if (event.target.value === 2) {
      getDataBySelectValue({
        variables: {
          endDate: format(pervThreeMonths, "yyyy/MM/dd"),
          startDate: format(endDateOfMonth, "yyyy/MM/dd"),
        },
      });
    } else if (event.target.value === 3) {
      getDataBySelectValue({
        variables: {
          endDate: format(endDateOfMonth, "yyyy/MM/dd"),
          startDate: format(
            sub(endDateOfMonth, {
              months: 6,
            }),
            "yyyy/MM/dd"
          ),
        },
      });
    } else if (event.target.value === 4) {
      getDataBySelectValue({
        variables: {
          endDate: format(endDateOfMonth, "yyyy/MM/dd"),
          startDate: format(
            sub(endDateOfMonth, {
              years: 1,
            }),
            "yyyy/MM/dd"
          ),
        },
      });
    }
  };

  //?------------------------------------------------------------------------------------------------------- */

  const { loading: getAllTotalDataLoading } = useDashboardQuery({
    variables: {
      startDate: "2022-08-01",
      endDate: "2022-08-23",
    },
    onCompleted: (item) => setAllTotalData(item),
  });
  //?------------------------------------------------------------------------------------------------------- */
  const ARR = [
    {
      key: "Pre-revenue",
      value: 0,
    },
    {
      key: "< ₹ 1.5 Cr",
      value: 1.5,
    },
    {
      key: "₹ 1.5 - 10 Cr",
      value: 10,
    },
    {
      key: "₹ 10 - 100 Cr",
      value: 100,
    },
    {
      key: "₹ 100 - 500 Cr",
      value: 500,
    },
    {
      key: "₹ 500 Cr+",
      value: 501,
    },
  ];

  const handleArr = (incommingArr: number) => {
    // eslint-disable-next-line array-callback-return
    const result = ARR.find((item: any) => {
      if (item?.value === incommingArr) {
        return item?.key;
      }
    });
    return result;
  };
  const statusData = (status: any) => {
    if (status.toLowerCase() === "scheduled") {
      return "#1E88E5";
    } else if (status.toLowerCase() === "completed") {
      return "#57B867";
    } else if (status.toLowerCase() === "escrow receivables") {
      return "#A157FF";
    } else if (status.toLowerCase() === "failed") {
      return "#E90000";
    } else if (status.toLowerCase() === "In progress") {
      return "#EAB224";
    }
  };

  return {
    totalDataGetLoading,
    allTotalData,
    dashboardDropdownData,
    allCompanyListData,
    allEMIData,
    age,
    currentMonth,
    EMIListLoading,
    CompanyListLoaing,
    getAllTotalDataLoading,
    navigate,
    handleChange,
    statusData,
    handleArr,
  };
};

export default useDashboardData;
