import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Skeleton,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import SimpleInput from "../../components/input/SimpleInput";
import {
  useAddEmiTransactionMutation,
  useGetAllTransactionMethodsQuery,
} from "../../data-access/hooks/company/emi-calendar.hooks";
import SimpleDatePicker from "../../components/input/SimpleDatePicker";
import SimpleSelect from "../../components/input/SimpleSelect";
import SideModal from "../../components/Modals/SideModal";

interface SelectedDataInterface {
  id: string;
  amount: number;
  trade_id: number;
  transaction_status: string;
  transaction_type: string;
  transaction_method: string;
  timestamp: string;
  scheduled_emi_id: number;
  company_id: number;
  trade_installment_seq: number;
}
interface ManualPaymentInterface {
  data: SelectedDataInterface;
  setOpen: Function;
  refetch: Function;
  open: any;
}

interface TransactionMethodsVariables {
  name: String;
  id: any;
}

const ManualPayment = ({
  data,
  setOpen,
  refetch,
  open,
}: ManualPaymentInterface) => {
  /**===================================================================================================== */
  const [allTransactionMethods, setAllTransactionMethods] =
    useState<TransactionMethodsVariables[]>();

  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  /**===================================================================================================== */
  const { loading: allTransactionLoading } = useGetAllTransactionMethodsQuery({
    onCompleted: (item) => {
      const data = item.ref_transaction_method.map((dataItem) => {
        return {
          name: dataItem.name,
          id: dataItem.id,
        };
      });
      setAllTransactionMethods(data);
    },
  });

  /**===================================================================================================== */
  const [addManualEMIPayment, { loading: addLoading }] =
    useAddEmiTransactionMutation({
      onCompleted: (item) => {
        setOpen(false);
        refetch();
      },
      onError: (error) => {
        setShowAlert({
          show: true,
          message: error.message,
          type: "error",
        });
      },
    });

  /**===================================================================================================== */
  const formik = useFormik({
    initialValues: {
      trade_id: data.trade_id,
      amount: data.amount,
      timestamp: new Date(),
      transaction_method: "",
      interest_charges: "",
      bounce_charges: "",
      transaction_method_id: "",
      remarks: "",
      trade_installment_seq: data?.trade_installment_seq,
      scheduled_emi_id: data?.scheduled_emi_id,
    },
    onSubmit: (fieldValue) => {
      addManualEMIPayment({
        variables: {
          args: {
            trade_id: Number(fieldValue?.trade_id),
            transaction_method: fieldValue?.transaction_method,
            transaction_method_id: fieldValue?.transaction_method_id,
            remarks: fieldValue?.remarks,
            timestamp: fieldValue?.timestamp,
            amount: Number(fieldValue?.amount),
            bounce_charges: Number(fieldValue?.bounce_charges),
            interest_charges: Number(fieldValue?.interest_charges),
            trade_installment_seq: fieldValue?.trade_installment_seq,
            scheduled_emi_id: fieldValue?.scheduled_emi_id,
          },
        },
      });
    },
  });

  return (
    <SideModal
      open={open.show}
      onClose={() =>
        setOpen({
          ...open,
          show: false,
        })
      }
      modalTitle="Manual Payment"
      footer={
        <Button
          form="manual-payment-form"
          fullWidth
          disabled={addLoading}
          type="submit"
          variant="primarySmall"
        >
          {addLoading ? <CircularProgress size={20} /> : "Submit"}
        </Button>
      }
    >
      <form id="manual-payment-form" onSubmit={formik.handleSubmit}>
        {allTransactionLoading ? (
          <Grid container columnSpacing={5} rowSpacing={2}>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            columnSpacing={5}
            rowSpacing={2}
            style={{
              height: "calc(100% - 140px)",
              overflow: "auto",
            }}
          >
            {showAlert.show && (
              <Grid item xs={12} my={2}>
                <Alert severity={showAlert.type as any}>
                  {showAlert.message}
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <SimpleInput
                  name="trade_id"
                  inputlabel="Trade ID"
                  placeholder="Enter Trade ID"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.trade_id}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <SimpleInput
                  name="scheduled_emi_id"
                  inputlabel="EMI #"
                  placeholder="Enter EMI #"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.scheduled_emi_id}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <SimpleInput
                  name="amount"
                  inputlabel="EMI Amount"
                  placeholder="Enter EMI Amount"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                  value={formik.values.amount}
                  InputProps={{
                    startAdornment: (
                      <Typography paddingLeft={2} align="center">
                        ₹
                      </Typography>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <SimpleDatePicker
                  name="timestamp"
                  inputlabel="Payment Date"
                  placeholder="Enter Payment Date"
                  onChange={(value: any) => {
                    formik.setFieldValue("timestamp", value);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.timestamp}
                  renderInput={(
                    params: JSX.IntrinsicAttributes & TextFieldProps
                  ) => <TextField {...params} fullWidth variant="outlined" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <SimpleSelect
                  name="transaction_method"
                  formlabel="Payment Mode"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.transaction_method}
                  displayEmpty
                  renderValue={() => {
                    if (formik.values.transaction_method === "") {
                      return "Select Payment Mode";
                    } else {
                      return formik.values.transaction_method;
                    }
                  }}
                >
                  {allTransactionMethods?.map((dataItem, index) => (
                    <MenuItem value={dataItem.id} key={index}>
                      {dataItem.name}
                    </MenuItem>
                  ))}
                </SimpleSelect>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <SimpleInput
                  name="interest_charges"
                  inputlabel="Penal Interest"
                  placeholder="Enter Penal Interest"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.interest_charges}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <Typography paddingLeft={2} align="center">
                        ₹
                      </Typography>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <SimpleInput
                  name="bounce_charges"
                  inputlabel="Bounce Fees"
                  placeholder="Enter Bounce Fees"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bounce_charges}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <Typography paddingLeft={2} align="center">
                        ₹
                      </Typography>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <SimpleInput
                  name="transaction_method_id"
                  inputlabel="Reference #"
                  placeholder="Enter UTR / Check No."
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.transaction_method_id}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <SimpleInput
                  name="remarks"
                  inputlabel="Remarks"
                  placeholder="Enter any comments / remarks."
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.remarks}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
      </form>
    </SideModal>
  );
};

export default ManualPayment;
