import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Grid,
  Typography,
  Button,
  Modal,
  CircularProgress,
} from "@mui/material";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as TrashIcon } from "../../../assets/Icon/Outline/trash-icon.svg";
import CommonLoader from "../../../components/CommonLoader";
import TableStatus from "../../../components/TableStatus";
import { CompanyContext } from "../../../contexts/CompanyContext";
import {
  useDeleteCollaboratorMailMutation,
  useGetAllAlternateEmailQuery,
  useGetAllCollaboratorsByIdQuery,
  useResendCollaboratorMailMutation,
} from "../../../data-access/hooks/company/team-collaboration.hooks";
import { TableWrapper } from "../../../theme/customComponents";
import AdditionalsEmail from "./AdditionalsEmail";
import InviteTeamModal from "./InviteTeamModal";

const CompanyTeam = () => {
  const { id: company_id } = useParams();

  const [openInviteModal, setOpenInviteModal] = useState(false);

  const [openAdditionEmailsModal, setOpenAdditionEmailsModal] = useState(false);

  const [selectedId, setSelectedId] = useState(0);

  const [teamList, setTeamList] = useState<any[]>([]);

  const { setShowAlert } = useContext(CompanyContext);

  const { data: allAlternateEmails, refetch: AdditionalEmailRefetch } =
    useGetAllAlternateEmailQuery({
      variables: {
        where: {
          id: {
            _eq: Number(company_id),
          },
        },
      },
    });

  const { loading, refetch } = useGetAllCollaboratorsByIdQuery({
    variables: {
      id: Number(company_id),
    },
    onCompleted: (item) => {
      const data = item?.company_by_pk?.company_users?.map((item) => {
        return {
          first_name: item?.user?.first_name,
          last_name: item?.user?.last_name,
          email: item?.user?.email,
          mobile_number: item?.user?.mobile_number,
          user_type: item?.user_type,
          status: item?.user?.status,
          id: item?.user?.id,
          company_user_id: item?.id,
        };
      });
      setTeamList(data || []);
    },
    onError: (error) => {
      setShowAlert({
        type: "error",
        message: error.message,
        show: true,
      });
    },
  });

  const [handleDeleteMember, { loading: deleteLoading }] =
    useDeleteCollaboratorMailMutation({
      onError: (error) => {
        setShowAlert({
          type: "error",
          message: error.message,
          show: true,
        });
      },
      onCompleted: (item) => {
        setShowAlert({
          type: "success",
          message: "Member successfully deleted.",
          show: true,
        });
        refetch();
      },
    });

  const [handleResendMail, { loading: resendLoading }] =
    useResendCollaboratorMailMutation({
      onError: (error) => {
        setShowAlert({
          type: "error",
          message: error.message,
          show: true,
        });
      },
      onCompleted: (item) => {
        setShowAlert({
          type: "success",
          message: "Email successfully sent.",
          show: true,
        });
        refetch();
      },
    });

  return (
    <>
      <Paper>
        <Grid padding={"20px"} justifyContent="space-between" container>
          <Grid item display={"flex"} alignItems="center">
            <Typography fontWeight={600} variant="h4">
              Invited Team Members
            </Typography>
          </Grid>
          <Grid item display={"flex"} alignItems="center" gap="10px">
            <Button
              onClick={() => {
                setOpenAdditionEmailsModal(true);
              }}
              variant="secondarySmall"
            >
              Additional Email IDs{" "}
            </Button>
            <Button
              onClick={() => {
                setOpenInviteModal(true);
              }}
              variant="primarySmall"
            >
              Invite Team
            </Button>
          </Grid>
        </Grid>
        {loading ? (
          <CommonLoader />
        ) : (
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>Association Type</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {teamList
                  ?.filter((item) => item?.user_type !== "ADMIN")
                  ?.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>{data?.first_name}</TableCell>
                      <TableCell>{data?.last_name}</TableCell>
                      <TableCell>{data?.email}</TableCell>
                      <TableCell>{data?.mobile_number}</TableCell>
                      <TableCell>{data?.user_type}</TableCell>
                      <TableCell>
                        {TableStatus({
                          stautsText: `${data?.status}`,
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {data?.status === "UNVERIFIED" && (
                          <Button
                            variant="linkButton"
                            disabled={resendLoading}
                            onClick={() => {
                              setSelectedId(data?.id);
                              handleResendMail({
                                variables: {
                                  user_id: Number(data?.id),
                                },
                              });
                            }}
                          >
                            {resendLoading && selectedId === data?.id ? (
                              <CircularProgress size={20} />
                            ) : (
                              "Resend Email"
                            )}
                          </Button>
                        )}
                        &nbsp; &nbsp; &nbsp;
                        <IconButton
                          color="primary"
                          disabled={deleteLoading}
                          onClick={() =>
                            handleDeleteMember({
                              variables: {
                                id: data?.id,
                              },
                            })
                          }
                        >
                          {deleteLoading && selectedId === data?.id ? (
                            <CircularProgress size={20} />
                          ) : (
                            <TrashIcon
                              width="18px"
                              height="18px"
                              color="#DD3838"
                            />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableWrapper>
        )}
      </Paper>

      {openInviteModal && (
        <InviteTeamModal
          open={openInviteModal}
          setOpen={setOpenInviteModal}
          refetch={refetch}
        />
      )}

      <Modal open={openAdditionEmailsModal}>
        <Grid
          container
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <AdditionalsEmail
            setOpen={setOpenAdditionEmailsModal}
            refetch={AdditionalEmailRefetch}
            company_id={company_id}
            allAlternateEmails={String(
              allAlternateEmails?.company[0]?.alternate_emails ?? ""
            )}
          />
        </Grid>
      </Modal>
    </>
  );
};

export default CompanyTeam;
