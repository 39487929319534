import styled from "styled-components/macro";
import { CircularProgress, FormLabel as MuiFormLabel } from "@mui/material";

const FormLabel = styled(MuiFormLabel)`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  margin-bottom: 4px;
`;
const FileInput = styled.input`
  opacity: 0;
  margin-top: 4px;
  height: 40px;
  width: 100%;
`;

const FileButton = styled.span`
  margin-top: 4px;
  height: 40px;
  width: 100%;
  position: absolute;
  border: solid 1px #dfdfdf;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  z-index: 0;
  align-items: center;
  color: #1e88e5;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

const SimpleFileInput = (props: any) => {
  return (
    <>
      {props.inputlabel && (
        <FormLabel>
          {props.inputlabel}
          {props.required && <span style={{ color: "#E90000" }}>*</span>}
        </FormLabel>
      )}
      <div
        style={{
          position: "relative",
        }}
      >
        <FileButton>
          {props.loading ? <CircularProgress size={20} /> : "Browse File"}
        </FileButton>
        <FileInput {...props} />
      </div>
    </>
  );
};

export default SimpleFileInput;
