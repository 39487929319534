import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllInvestmentsByInvestorQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Investment_Bool_Exp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetAllInvestmentsByInvestorQuery = { __typename?: 'query_root', investment: Array<{ __typename?: 'investment', trade_id: number, invested_amount?: any | null, withdrawal_mode?: Types.Ref_Trade_Withdrawal_Mode_Enum | null, trade: { __typename?: 'trade', status: Types.Ref_Trade_Status_Enum, company: { __typename?: 'company', name?: string | null, id: number } } }>, investment_aggregate: { __typename?: 'investment_aggregate', aggregate?: { __typename?: 'investment_aggregate_fields', count: number } | null } };


export const GetAllInvestmentsByInvestorDocument = gql`
    query getAllInvestmentsByInvestor($where: investment_bool_exp, $limit: Int, $offset: Int) {
  investment(where: $where, limit: $limit, offset: $offset) {
    trade_id
    invested_amount
    withdrawal_mode
    trade {
      status
      company {
        name
        id
      }
    }
  }
  investment_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllInvestmentsByInvestorQuery__
 *
 * To run a query within a React component, call `useGetAllInvestmentsByInvestorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInvestmentsByInvestorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInvestmentsByInvestorQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAllInvestmentsByInvestorQuery(baseOptions?: Apollo.QueryHookOptions<GetAllInvestmentsByInvestorQuery, GetAllInvestmentsByInvestorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInvestmentsByInvestorQuery, GetAllInvestmentsByInvestorQueryVariables>(GetAllInvestmentsByInvestorDocument, options);
      }
export function useGetAllInvestmentsByInvestorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInvestmentsByInvestorQuery, GetAllInvestmentsByInvestorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInvestmentsByInvestorQuery, GetAllInvestmentsByInvestorQueryVariables>(GetAllInvestmentsByInvestorDocument, options);
        }
export type GetAllInvestmentsByInvestorQueryHookResult = ReturnType<typeof useGetAllInvestmentsByInvestorQuery>;
export type GetAllInvestmentsByInvestorLazyQueryHookResult = ReturnType<typeof useGetAllInvestmentsByInvestorLazyQuery>;
export type GetAllInvestmentsByInvestorQueryResult = Apollo.QueryResult<GetAllInvestmentsByInvestorQuery, GetAllInvestmentsByInvestorQueryVariables>;
export const namedOperations = {
  Query: {
    getAllInvestmentsByInvestor: 'getAllInvestmentsByInvestor'
  }
}