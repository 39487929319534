import Calendar from "./FullCalendar";

const EMICalendar = () => {
  return (
    <div>
      <Calendar />
    </div>
  );
};

export default EMICalendar;
