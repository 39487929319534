import React, { useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  IconButton,
} from "@mui/material";
import { TableWrapper } from "../../../theme/customComponents";
import SignatoryModal from "./SignatoryModal";
import { ReactComponent as PencilIcon } from "../../../assets/Icon/Outline/pencil-icon.svg";
import CommonLoader from "../../../components/CommonLoader";

interface AuthorizedSignatoryDetailsInterface {
  open?: any;
  setOpen?: any;
  loading?: boolean;
  signatoryFetch?: any;
  companyId?: any;
  data: any[];
}

const AuthorizedSignatoryDetails = (
  props: AuthorizedSignatoryDetailsInterface
) => {
  const { open, setOpen, data, companyId, signatoryFetch, loading } = props;

  const [updateData, setUpdateData] = useState({});

  return (
    <>
      <Paper>
        <Grid padding={"20px"} justifyContent="space-between" container>
          <Grid item display={"flex"} alignItems="center">
            <Typography fontWeight={600} variant="h4">
              Agreement Signatory Details
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={() => setOpen(true)} variant="secondarySmall">
              Add Signatory
            </Button>
          </Grid>
        </Grid>
        {loading ? (
          <CommonLoader />
        ) : (
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Designation</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item?.full_name}</TableCell>
                    <TableCell>{item?.email}</TableCell>
                    <TableCell>{item?.mobile_number}</TableCell>
                    <TableCell>{item?.designation}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setUpdateData(item);
                          setOpen(true);
                        }}
                      >
                        <PencilIcon
                          width="16px"
                          height="16px"
                          color="#3188E5"
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        )}
      </Paper>

      {open && (
        <SignatoryModal
          setOpen={setOpen}
          open={open}
          updateData={updateData}
          companyId={companyId}
          signatoryFetch={signatoryFetch}
          setUpdateData={setUpdateData}
        />
      )}
    </>
  );
};

export default AuthorizedSignatoryDetails;
