/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components/macro";
import {
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  CircularProgress,
  Button,
  Modal,
  LinearProgress,
  MenuItem,
  TextField,
  Switch,
  TextFieldProps,
  Menu,
} from "@mui/material";
import { Paper as MuiPaper, Divider as MuiDivider } from "@mui/material";
import React from "react";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { TableWrapper } from "../../../theme/customComponents";
import CommonBreadCrumb from "../../../components/CommonBreadCrumb";
import UploadAPA from "./UploadAPA";
import EmiSummary from "./EmiSummary";
import DetailsContainer from "./DetailsContainer";
import SimpleDatePicker from "../../../components/input/SimpleDatePicker";
import useCompanyTrade from "../../../hooks/useCompanyTrade";
import { ReactComponent as ChevronDownIcon } from "../../../assets/Icon/Outline/chevron-down-icon.svg";
import { Annexure_Type } from "../../../data-access/types";
import { useNavigate } from "react-router";
import MandateConfiguration from "./MandateConfiguration";
import EscorwAccount from "./EscorwAccount";
import TDSTransaction from "./TDSTransaction";
import { GetAllTdsByTradeQuery } from "../../../data-access/hooks/company/emi-calendar.hooks";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 35,
  height: 19.5,
  padding: 0,
  borderRadius: 50,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(15px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#fff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 15,
    height: 15,
    color: "#1E88E5",
    borderRadius: 10,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(255,255,255)",
    border: " solid 2px black",
    boxSizing: "border-box",
  },
}));

const Paper = styled(MuiPaper)(spacing);

const Divider = styled(MuiDivider)(spacing);

const CompanyTrade: React.FC = () => {
  const {
    emiStaus,
    openAPAModal,
    setOpenAPAModal,
    allTradeData,
    getAllDataRefetch,
    bankAccountsLoading,
    mandateLoading,
    formik,
    tradeDataLoading,
    isEditing,
    setIsEditing,
    handleInitiatePayoutRequest,
    initiatePayoutLoading,
    updateLoading,
    downloadLoading,
    downloadAPALoading,
    handleDownloadTable,
    downloadUploadFile,
    investorsDetails,
    handleInvestorChange,
    mandateList,
    mandateBankList,
    handleWithdrawalChange,
    anchorEl,
    setAnchorEl,
    open,
    handleAnnexure,
    APALoading,
    mandateFrequency,
    company_id,
    trade_id,
    availableBalance,
    allTdsData,
    getAllLoading,
    GetTDSRefetch,
  } = useCompanyTrade();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (formik?.values.trade_configuration.collection_mode === "ESCROW") {
      formik.setFieldValue("trade_configuration.debit_mode", "MANUAL");
    }
  }, [formik?.values.trade_configuration.collection_mode]);

  return (
    <React.Fragment>
      <Helmet title="Company Trade" />
      {bankAccountsLoading || mandateLoading ? (
        <LinearProgress />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Grid
            item
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {/*---------------------------*COMMON BREAD CRUMBS--------------------------- */}
            <Grid item>
              <CommonBreadCrumb
                typographyText="Trade Overview"
                linksText={[{ link: `Trades`, to: "/trades" }]}
                showCompany
              />
            </Grid>
          </Grid>
          {tradeDataLoading || (APALoading && <LinearProgress />)}

          <Divider mt="16px" mb="28px" />
          <Grid item justifyContent={"flex-end"} display="flex" gap="10px">
            {!isEditing && (
              <>
                <Button
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                  variant="secondarySmall"
                >
                  Generate Annexure
                  <ChevronDownIcon width={18} height={18} color="#1E88E5 " />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem
                    onClick={() => {
                      handleAnnexure({
                        variables: {
                          trade_id: allTradeData?.id,
                          annexure_type: "APA" as Annexure_Type,
                        },
                      });
                    }}
                  >
                    Generate APA Annexure
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleAnnexure({
                        variables: {
                          trade_id: allTradeData?.id,
                          annexure_type: "RTA" as Annexure_Type,
                        },
                      });
                    }}
                  >
                    Generate RTA Annexure
                  </MenuItem>
                </Menu>
              </>
            )}
            {!isEditing && (
              <Button
                onClick={() => setOpenAPAModal(true)}
                variant="secondarySmall"
              >
                Upload APA/RTA
              </Button>
            )}
            {!isEditing && (
              <Button
                onClick={() => setIsEditing(true)}
                disabled={
                  ![
                    "INITIATED",
                    "ACTIVE",
                    "CONTRACT_SELECTED",
                    "MANDATE_FAILED",
                    "OFFLINE",
                  ].includes(allTradeData?.status)
                }
                variant="secondarySmall"
              >
                Edit
              </Button>
            )}
            {!isEditing && (
              <Button
                disabled={[
                  "MANDATE_REGISTERED",
                  "MANDATE_SIGNED",
                  "MANDATE_SUCCESS",
                ].includes(allTradeData?.status)}
                onClick={() =>
                  navigate(
                    `/company/${company_id}/trade/${trade_id}/contract-selection`
                  )
                }
                variant="secondarySmall"
              >
                Edit Contract
              </Button>
            )}
            {!isEditing && (
              <Button
                onClick={() =>
                  handleInitiatePayoutRequest({
                    variables: {
                      args: {
                        trade_id: allTradeData?.id,
                      },
                    },
                  })
                }
                disabled={
                  allTradeData?.apa === null ||
                  initiatePayoutLoading ||
                  ["MANDATE_SIGNED"].includes(allTradeData?.status)
                }
                variant="primarySmall"
              >
                {initiatePayoutLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  "Initiate Payout"
                )}
              </Button>
            )}
            {isEditing && (
              <Button
                onClick={() => setIsEditing(false)}
                variant="secondarySmall"
              >
                Cancle
              </Button>
            )}
            {isEditing && (
              <Button
                type="submit"
                onClick={() => setIsEditing(true)}
                variant="primarySmall"
                disabled={updateLoading}
              >
                {updateLoading ? <CircularProgress size={20} /> : "Save"}
              </Button>
            )}
          </Grid>
          <br />
          <br />
          {/**TRADE DETAILS-------------------------------------------------------------- */}
          <DetailsContainer
            allTradeData={allTradeData}
            downloadLoading={downloadLoading}
            downloadAPALoading={downloadAPALoading}
            handleDownloadTable={handleDownloadTable}
            downloadUploadFile={downloadUploadFile}
            isEditing={isEditing}
            formik={formik}
          />
          {/**TRADE CONFIG-------------------------------------------------------------- */}
          {allTradeData?.status !== "OFFLINE" && isEditing && (
            <Grid item>
              <Paper>
                <Grid
                  padding={"20px"}
                  justifyContent="space-between"
                  gap={1}
                  container
                >
                  <Grid item>
                    <Typography fontWeight={600} variant="h4">
                      Trade Configurations{" "}
                    </Typography>
                  </Grid>
                </Grid>
                <TableWrapper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Offline Trade</TableCell>
                        {`${formik.values.trade_configuration.is_offline}` ===
                          "true" && <TableCell>Date of Disbusal</TableCell>}
                        <TableCell>EMI Start Date</TableCell>
                        <TableCell>Tenure</TableCell>
                        <TableCell>Interest rate</TableCell>
                        <TableCell>Loan Amount</TableCell>
                        <TableCell>Fees without GST</TableCell>
                        <TableCell>Mandate Trigger</TableCell>
                        <TableCell>Escrow Receivables</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>No</Grid>

                            <Grid item>
                              <AntSwitch
                                disabled={allTradeData?.status === "ACTIVE"}
                                name="trade_configuration.is_offline"
                                value={
                                  formik.values.trade_configuration.is_offline
                                }
                                checked={
                                  formik.values.trade_configuration.is_offline
                                    ? true
                                    : false
                                }
                                onChange={(value: any) =>
                                  formik.setFieldValue(
                                    "trade_configuration.is_offline",
                                    value?.target?.checked
                                  )
                                }
                              />
                            </Grid>
                            <Grid item>Yes</Grid>
                          </Grid>
                        </TableCell>
                        {`${formik.values.trade_configuration.is_offline}` ===
                          "true" && (
                          <TableCell>
                            <SimpleDatePicker
                              disabled={allTradeData?.status === "ACTIVE"}
                              name="trade_configuration.payout_on"
                              disablePast
                              onChange={(value: any) =>
                                formik.setFieldValue(
                                  "trade_configuration.payout_on",
                                  value
                                )
                              }
                              value={
                                formik.values.trade_configuration.payout_on
                              }
                              renderInput={(
                                params: JSX.IntrinsicAttributes & TextFieldProps
                              ) => (
                                <TextField
                                  {...params}
                                  placeholder="Select Date of Disbusal"
                                  fullWidth
                                  variant="outlined"
                                />
                              )}
                            />
                          </TableCell>
                        )}
                        <TableCell>
                          <SimpleDatePicker
                            disabled={allTradeData?.status === "ACTIVE"}
                            name="trade_configuration.emi_start_date"
                            disablePast
                            onChange={(value: any) =>
                              formik.setFieldValue(
                                "trade_configuration.emi_start_date",
                                value
                              )
                            }
                            value={
                              formik.values.trade_configuration.emi_start_date
                            }
                            renderInput={(
                              params: JSX.IntrinsicAttributes & TextFieldProps
                            ) => (
                              <TextField
                                {...params}
                                placeholder="Select Scored on date"
                                fullWidth
                                variant="outlined"
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            disabled={allTradeData?.status === "ACTIVE"}
                            id="trade_configuration.validity"
                            name="trade_configuration.validity"
                            onChange={formik.handleChange}
                            value={formik.values.trade_configuration.validity}
                            placeholder="Enter Tenure"
                            variant="outlined"
                            type={"number"}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            disabled={allTradeData?.status === "ACTIVE"}
                            name="trade_configuration.roi"
                            id="trade_configuration.roi"
                            placeholder="Enter interest rate"
                            variant="outlined"
                            type={"number"}
                            value={formik.values.trade_configuration.roi}
                            onChange={formik.handleChange}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            disabled={allTradeData?.status === "ACTIVE"}
                            name="trade_configuration.payout_value"
                            id="trade_configuration.payout_value"
                            placeholder="Enter Fees"
                            type={"number"}
                            variant="outlined"
                            value={
                              formik.values.trade_configuration.payout_value
                            }
                            onChange={formik.handleChange}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            disabled={allTradeData?.status === "ACTIVE"}
                            name="trade_configuration.fees_without_gst"
                            id="trade_configuration.fees_without_gst"
                            placeholder="Enter Fees"
                            type={"number"}
                            variant="outlined"
                            value={
                              formik.values.trade_configuration.fees_without_gst
                            }
                            onChange={formik.handleChange}
                          />
                        </TableCell>
                        <TableCell>
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>Auto</Grid>
                            <Grid item>
                              <AntSwitch
                                disabled={allTradeData?.status === "ACTIVE"}
                                name="trade_configuration.debit_mode"
                                value={
                                  formik.values.trade_configuration.debit_mode
                                }
                                checked={
                                  formik.values.trade_configuration
                                    .debit_mode === "AUTO"
                                    ? false
                                    : true
                                }
                                onChange={(value: any) =>
                                  formik.setFieldValue(
                                    "trade_configuration.debit_mode",
                                    value?.target?.checked === true
                                      ? "MANUAL"
                                      : "AUTO"
                                  )
                                }
                              />
                            </Grid>
                            <Grid item>Manual</Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>No</Grid>
                            <Grid item>
                              <AntSwitch
                                disabled={allTradeData?.status === "ACTIVE"}
                                value={
                                  formik.values.trade_configuration
                                    .collection_mode
                                }
                                checked={
                                  formik.values.trade_configuration
                                    .collection_mode === "MANDATE"
                                    ? false
                                    : true
                                }
                                onChange={(value: any) =>
                                  formik.setFieldValue(
                                    "trade_configuration.collection_mode",
                                    value?.target?.checked === true
                                      ? "ESCROW"
                                      : "MANDATE"
                                  )
                                }
                                name="trade_configuration.collection_mode"
                              />
                            </Grid>
                            <Grid item>Yes</Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableWrapper>
              </Paper>
            </Grid>
          )}
          <br />
          <br />
          {/**INVESTOR DETAILS-------------------------------------------------------------- */}
          <Grid item>
            <TableWrapper>
              <Paper>
                <Typography fontSize={18} fontWeight={600} p={3}>
                  Investors Details
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Offer Amount</TableCell>
                      <TableCell>Invested Amount</TableCell>
                      <TableCell>Split Ratio</TableCell>
                      <TableCell>Investor Fees/EMI</TableCell>
                      <TableCell>Investor Repayment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allTradeData?.investments.map((item: any, i: number) => (
                      <TableRow>
                        <TableCell>{item?.investor?.name}</TableCell>
                        <TableCell>{item?.investor?.id}</TableCell>
                        <TableCell>
                          {allTradeData?.status !== "OFFLINE" && isEditing ? (
                            <TextField
                              disabled={allTradeData?.status === "ACTIVE"}
                              name="offered_amount"
                              id="offered_amount"
                              placeholder="Enter amount"
                              value={
                                investorsDetails &&
                                investorsDetails[i]?.offered_amount
                              }
                              onChange={(event) =>
                                handleInvestorChange(event, i)
                              }
                              variant="outlined"
                              type="number"
                            />
                          ) : (
                            `₹ ${item?.offered_amount}`
                          )}
                        </TableCell>
                        <TableCell>
                          {allTradeData?.status !== "OFFLINE" && isEditing ? (
                            <TextField
                              disabled={allTradeData?.status === "ACTIVE"}
                              name="invested_amount"
                              id="invested_amount"
                              placeholder="Enter amount"
                              value={
                                investorsDetails &&
                                investorsDetails[i]?.invested_amount
                              }
                              onChange={(event) =>
                                handleInvestorChange(event, i)
                              }
                              type="number"
                              variant="outlined"
                            />
                          ) : (
                            `₹ ${item?.invested_amount}`
                          )}
                        </TableCell>
                        <TableCell>
                          {allTradeData?.status !== "OFFLINE" && isEditing ? (
                            <TextField
                              disabled={allTradeData?.status === "ACTIVE"}
                              name="emi_split_ratio"
                              id="emi_split_ratio"
                              value={
                                investorsDetails &&
                                investorsDetails[i]?.emi_split_ratio
                              }
                              placeholder="Enter amount"
                              onChange={(event) =>
                                handleInvestorChange(event, i)
                              }
                              variant="outlined"
                            />
                          ) : (
                            item?.emi_split_ratio
                          )}
                        </TableCell>
                        <TableCell>
                          {allTradeData?.status !== "OFFLINE" && isEditing ? (
                            <TextField
                              disabled={allTradeData?.status === "ACTIVE"}
                              name="fees"
                              id="fees"
                              value={
                                investorsDetails && investorsDetails[i]?.fees
                              }
                              placeholder="Enter amount"
                              onChange={(event) =>
                                handleInvestorChange(event, i)
                              }
                              type="number"
                              variant="outlined"
                            />
                          ) : (
                            `₹ ${item?.fees}`
                          )}
                        </TableCell>
                        <TableCell>
                          {allTradeData?.status !== "OFFLINE" && isEditing ? (
                            <Grid
                              component="label"
                              container
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>Auto</Grid>
                              <Grid item>
                                <AntSwitch
                                  disabled={allTradeData?.status !== "ACTIVE"}
                                  name="withdrawal_mode"
                                  checked={
                                    investorsDetails &&
                                    investorsDetails[i]?.withdrawal_mode ===
                                      "MANUAL"
                                      ? true
                                      : false
                                  }
                                  onChange={(event) =>
                                    handleWithdrawalChange(event, i)
                                  }
                                />
                              </Grid>
                              <Grid item>Manual</Grid>
                            </Grid>
                          ) : (
                            item?.withdrawal_mode
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </TableWrapper>
          </Grid>
          <br />
          <br />
          {/**MANDATE DETAILS-------------------------------------------------------------- */}

          {`${formik.values.trade_configuration.is_offline}` !== "true" && (
            <MandateConfiguration
              formik={formik}
              isEditing={isEditing}
              mandateList={mandateList}
              mandateBankList={mandateBankList}
              mandateFrequency={mandateFrequency}
              mandateData={allTradeData}
            />
          )}
          <br />
          <br />
          {/**EMI SUMMARY-------------------------------------------------------------- */}
          {allTradeData?.status !== "OFFLINE" && (
            <>
              <Grid item>
                <EmiSummary
                  emiStaus={emiStaus}
                  allTradeData={allTradeData}
                  availableBalance={availableBalance}
                  GetTDSRefetch={GetTDSRefetch}
                />
              </Grid>
              <br />
              <br />
            </>
          )}
          {/**ESCROW -------------------------------------------------------------- */}
          {allTradeData?.status !== "OFFLINE" && (
            <>
              {" "}
              <Grid item>
                <EscorwAccount
                  allTradeData={allTradeData}
                  availableBalance={availableBalance}
                />
              </Grid>
              <br />
              <br />
            </>
          )}
          {/**TDS DETAILS -------------------------------------------------------------- */}
          {allTradeData?.status !== "OFFLINE" && (
            <Grid item>
              <TDSTransaction
                allTdsData={allTdsData as GetAllTdsByTradeQuery}
                getAllLoading={getAllLoading}
              />
            </Grid>
          )}
        </form>
      )}
      <Modal open={openAPAModal} onClose={() => setOpenAPAModal(false)}>
        <Grid
          container
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <UploadAPA
            onClose={() => setOpenAPAModal(false)}
            trade_id={allTradeData?.id}
            getAllDataRefetch={getAllDataRefetch}
          />
        </Grid>
      </Modal>
    </React.Fragment>
  );
};

export default CompanyTrade;
