import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetScoreFactorQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetScoreFactorQuery = { __typename?: 'query_root', score_factor: Array<{ __typename?: 'score_factor', id: number, unit: Types.Ref_Score_Factor_Unit_Enum, ref_score_factor: { __typename?: 'ref_score_factor', id: string, name: string } }> };

export type AddTradingFactorsMutationVariables = Types.Exact<{
  objects: Array<Types.Company_Score_Time_Series_Insert_Input> | Types.Company_Score_Time_Series_Insert_Input;
}>;


export type AddTradingFactorsMutation = { __typename?: 'mutation_root', insert_company_score_time_series?: { __typename?: 'company_score_time_series_mutation_response', returning: Array<{ __typename?: 'company_score_time_series', id: number }> } | null };

export type GetScoreAndFactorQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int'];
}>;


export type GetScoreAndFactorQuery = { __typename?: 'query_root', company_score: Array<{ __typename?: 'company_score', id: number, company_id: number, min_bid_price?: any | null, rating?: any | null, facility_limit?: any | null, arr?: any | null, financials_score?: any | null, business_growth_score?: any | null, capital_efficiency_score?: any | null, customer_value_score?: any | null, management_score?: any | null, scored_on: any, gross_margin?: any | null, mrr_growth?: any | null, capital_efficiency?: any | null, net_profit?: any | null, net_cash_flow?: any | null, ltv_cac?: any | null, revenue_retention?: any | null, estimated_runway?: number | null, churn?: any | null, arpa?: any | null, min_recommended_bid?: any | null, max_recommended_bid?: any | null, report_file_id?: number | null, company_score_time_series: Array<{ __typename?: 'company_score_time_series', id: number, company_score_id: number, sequence: number, time_series: string, company_score_factors: Array<{ __typename?: 'company_score_factor', score_factor_id: number, id: number, value: any }> }> }>, score_factor: Array<{ __typename?: 'score_factor', id: number, factor: Types.Ref_Score_Factor_Enum }> };

export type AddNewCompanyScoreMutationVariables = Types.Exact<{
  object: Types.Company_Score_Insert_Input;
}>;


export type AddNewCompanyScoreMutation = { __typename?: 'mutation_root', insert_company_score_one?: { __typename?: 'company_score', id: number, company_id: number, min_bid_price?: any | null, rating?: any | null, facility_limit?: any | null, arr?: any | null, financials_score?: any | null, business_growth_score?: any | null, capital_efficiency_score?: any | null, customer_value_score?: any | null, management_score?: any | null, scored_on: any, gross_margin?: any | null, mrr_growth?: any | null, capital_efficiency?: any | null, net_profit?: any | null, net_cash_flow?: any | null, ltv_cac?: any | null, revenue_retention?: any | null, estimated_runway?: number | null, churn?: any | null, arpa?: any | null, min_recommended_bid?: any | null, max_recommended_bid?: any | null, report_file_id?: number | null } | null };

export type UpdateCompanyScoreMutationVariables = Types.Exact<{
  score_id: Types.Scalars['Int'];
  set?: Types.InputMaybe<Types.Company_Score_Set_Input>;
}>;


export type UpdateCompanyScoreMutation = { __typename?: 'mutation_root', update_company_score_by_pk?: { __typename?: 'company_score', id: number, company_id: number, min_bid_price?: any | null, rating?: any | null, facility_limit?: any | null, arr?: any | null, financials_score?: any | null, business_growth_score?: any | null, capital_efficiency_score?: any | null, customer_value_score?: any | null, management_score?: any | null, scored_on: any, gross_margin?: any | null, mrr_growth?: any | null, capital_efficiency?: any | null, net_profit?: any | null, net_cash_flow?: any | null, ltv_cac?: any | null, revenue_retention?: any | null, estimated_runway?: number | null, churn?: any | null, arpa?: any | null, min_recommended_bid?: any | null, max_recommended_bid?: any | null, report_file_id?: number | null } | null };

export type AddNewCompanyScoreTimeSeriesMutationVariables = Types.Exact<{
  objects: Array<Types.Company_Score_Time_Series_Insert_Input> | Types.Company_Score_Time_Series_Insert_Input;
}>;


export type AddNewCompanyScoreTimeSeriesMutation = { __typename?: 'mutation_root', insert_company_score_time_series?: { __typename?: 'company_score_time_series_mutation_response', returning: Array<{ __typename?: 'company_score_time_series', id: number }> } | null };

export type DownloadScoreReportQueryVariables = Types.Exact<{
  file_registry_id: Types.Scalars['Int'];
}>;


export type DownloadScoreReportQuery = { __typename?: 'query_root', generateFileRegistryDownloadURL?: { __typename?: 'GenerateFileRegistryDownloadURLOutput', download_url: string } | null };


export const GetScoreFactorDocument = gql`
    query getScoreFactor {
  score_factor(order_by: [{id: asc}]) {
    id
    ref_score_factor {
      id
      name
    }
    unit
  }
}
    `;

/**
 * __useGetScoreFactorQuery__
 *
 * To run a query within a React component, call `useGetScoreFactorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScoreFactorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScoreFactorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetScoreFactorQuery(baseOptions?: Apollo.QueryHookOptions<GetScoreFactorQuery, GetScoreFactorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScoreFactorQuery, GetScoreFactorQueryVariables>(GetScoreFactorDocument, options);
      }
export function useGetScoreFactorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScoreFactorQuery, GetScoreFactorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScoreFactorQuery, GetScoreFactorQueryVariables>(GetScoreFactorDocument, options);
        }
export type GetScoreFactorQueryHookResult = ReturnType<typeof useGetScoreFactorQuery>;
export type GetScoreFactorLazyQueryHookResult = ReturnType<typeof useGetScoreFactorLazyQuery>;
export type GetScoreFactorQueryResult = Apollo.QueryResult<GetScoreFactorQuery, GetScoreFactorQueryVariables>;
export const AddTradingFactorsDocument = gql`
    mutation addTradingFactors($objects: [company_score_time_series_insert_input!]!) {
  insert_company_score_time_series(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type AddTradingFactorsMutationFn = Apollo.MutationFunction<AddTradingFactorsMutation, AddTradingFactorsMutationVariables>;

/**
 * __useAddTradingFactorsMutation__
 *
 * To run a mutation, you first call `useAddTradingFactorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTradingFactorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTradingFactorsMutation, { data, loading, error }] = useAddTradingFactorsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddTradingFactorsMutation(baseOptions?: Apollo.MutationHookOptions<AddTradingFactorsMutation, AddTradingFactorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTradingFactorsMutation, AddTradingFactorsMutationVariables>(AddTradingFactorsDocument, options);
      }
export type AddTradingFactorsMutationHookResult = ReturnType<typeof useAddTradingFactorsMutation>;
export type AddTradingFactorsMutationResult = Apollo.MutationResult<AddTradingFactorsMutation>;
export type AddTradingFactorsMutationOptions = Apollo.BaseMutationOptions<AddTradingFactorsMutation, AddTradingFactorsMutationVariables>;
export const GetScoreAndFactorDocument = gql`
    query getScoreAndFactor($companyId: Int!) {
  company_score(where: {company_id: {_eq: $companyId}}, order_by: [{id: desc}]) {
    id
    company_id
    min_bid_price
    rating
    facility_limit
    arr
    financials_score
    business_growth_score
    capital_efficiency_score
    customer_value_score
    management_score
    scored_on
    gross_margin
    mrr_growth
    capital_efficiency
    net_profit
    net_cash_flow
    ltv_cac
    revenue_retention
    estimated_runway
    churn
    arpa
    min_recommended_bid
    max_recommended_bid
    report_file_id
    company_score_time_series {
      id
      company_score_id
      sequence
      time_series
      company_score_factors(order_by: [{score_factor_id: asc}]) {
        score_factor_id
        id
        value
      }
    }
  }
  score_factor(order_by: [{id: asc}]) {
    id
    factor
  }
}
    `;

/**
 * __useGetScoreAndFactorQuery__
 *
 * To run a query within a React component, call `useGetScoreAndFactorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScoreAndFactorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScoreAndFactorQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetScoreAndFactorQuery(baseOptions: Apollo.QueryHookOptions<GetScoreAndFactorQuery, GetScoreAndFactorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScoreAndFactorQuery, GetScoreAndFactorQueryVariables>(GetScoreAndFactorDocument, options);
      }
export function useGetScoreAndFactorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScoreAndFactorQuery, GetScoreAndFactorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScoreAndFactorQuery, GetScoreAndFactorQueryVariables>(GetScoreAndFactorDocument, options);
        }
export type GetScoreAndFactorQueryHookResult = ReturnType<typeof useGetScoreAndFactorQuery>;
export type GetScoreAndFactorLazyQueryHookResult = ReturnType<typeof useGetScoreAndFactorLazyQuery>;
export type GetScoreAndFactorQueryResult = Apollo.QueryResult<GetScoreAndFactorQuery, GetScoreAndFactorQueryVariables>;
export const AddNewCompanyScoreDocument = gql`
    mutation addNewCompanyScore($object: company_score_insert_input!) {
  insert_company_score_one(object: $object) {
    id
    company_id
    min_bid_price
    rating
    facility_limit
    arr
    financials_score
    business_growth_score
    capital_efficiency_score
    customer_value_score
    management_score
    scored_on
    gross_margin
    mrr_growth
    capital_efficiency
    net_profit
    net_cash_flow
    ltv_cac
    revenue_retention
    estimated_runway
    churn
    arpa
    min_recommended_bid
    max_recommended_bid
    report_file_id
  }
}
    `;
export type AddNewCompanyScoreMutationFn = Apollo.MutationFunction<AddNewCompanyScoreMutation, AddNewCompanyScoreMutationVariables>;

/**
 * __useAddNewCompanyScoreMutation__
 *
 * To run a mutation, you first call `useAddNewCompanyScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewCompanyScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewCompanyScoreMutation, { data, loading, error }] = useAddNewCompanyScoreMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddNewCompanyScoreMutation(baseOptions?: Apollo.MutationHookOptions<AddNewCompanyScoreMutation, AddNewCompanyScoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewCompanyScoreMutation, AddNewCompanyScoreMutationVariables>(AddNewCompanyScoreDocument, options);
      }
export type AddNewCompanyScoreMutationHookResult = ReturnType<typeof useAddNewCompanyScoreMutation>;
export type AddNewCompanyScoreMutationResult = Apollo.MutationResult<AddNewCompanyScoreMutation>;
export type AddNewCompanyScoreMutationOptions = Apollo.BaseMutationOptions<AddNewCompanyScoreMutation, AddNewCompanyScoreMutationVariables>;
export const UpdateCompanyScoreDocument = gql`
    mutation updateCompanyScore($score_id: Int!, $set: company_score_set_input) {
  update_company_score_by_pk(pk_columns: {id: $score_id}, _set: $set) {
    id
    company_id
    min_bid_price
    rating
    facility_limit
    arr
    financials_score
    business_growth_score
    capital_efficiency_score
    customer_value_score
    management_score
    scored_on
    gross_margin
    mrr_growth
    capital_efficiency
    net_profit
    net_cash_flow
    ltv_cac
    revenue_retention
    estimated_runway
    churn
    arpa
    min_recommended_bid
    max_recommended_bid
    report_file_id
  }
}
    `;
export type UpdateCompanyScoreMutationFn = Apollo.MutationFunction<UpdateCompanyScoreMutation, UpdateCompanyScoreMutationVariables>;

/**
 * __useUpdateCompanyScoreMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyScoreMutation, { data, loading, error }] = useUpdateCompanyScoreMutation({
 *   variables: {
 *      score_id: // value for 'score_id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateCompanyScoreMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyScoreMutation, UpdateCompanyScoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyScoreMutation, UpdateCompanyScoreMutationVariables>(UpdateCompanyScoreDocument, options);
      }
export type UpdateCompanyScoreMutationHookResult = ReturnType<typeof useUpdateCompanyScoreMutation>;
export type UpdateCompanyScoreMutationResult = Apollo.MutationResult<UpdateCompanyScoreMutation>;
export type UpdateCompanyScoreMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyScoreMutation, UpdateCompanyScoreMutationVariables>;
export const AddNewCompanyScoreTimeSeriesDocument = gql`
    mutation addNewCompanyScoreTimeSeries($objects: [company_score_time_series_insert_input!]!) {
  insert_company_score_time_series(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type AddNewCompanyScoreTimeSeriesMutationFn = Apollo.MutationFunction<AddNewCompanyScoreTimeSeriesMutation, AddNewCompanyScoreTimeSeriesMutationVariables>;

/**
 * __useAddNewCompanyScoreTimeSeriesMutation__
 *
 * To run a mutation, you first call `useAddNewCompanyScoreTimeSeriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewCompanyScoreTimeSeriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewCompanyScoreTimeSeriesMutation, { data, loading, error }] = useAddNewCompanyScoreTimeSeriesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddNewCompanyScoreTimeSeriesMutation(baseOptions?: Apollo.MutationHookOptions<AddNewCompanyScoreTimeSeriesMutation, AddNewCompanyScoreTimeSeriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewCompanyScoreTimeSeriesMutation, AddNewCompanyScoreTimeSeriesMutationVariables>(AddNewCompanyScoreTimeSeriesDocument, options);
      }
export type AddNewCompanyScoreTimeSeriesMutationHookResult = ReturnType<typeof useAddNewCompanyScoreTimeSeriesMutation>;
export type AddNewCompanyScoreTimeSeriesMutationResult = Apollo.MutationResult<AddNewCompanyScoreTimeSeriesMutation>;
export type AddNewCompanyScoreTimeSeriesMutationOptions = Apollo.BaseMutationOptions<AddNewCompanyScoreTimeSeriesMutation, AddNewCompanyScoreTimeSeriesMutationVariables>;
export const DownloadScoreReportDocument = gql`
    query downloadScoreReport($file_registry_id: Int!) {
  generateFileRegistryDownloadURL(args: {file_registry_id: $file_registry_id}) {
    download_url
  }
}
    `;

/**
 * __useDownloadScoreReportQuery__
 *
 * To run a query within a React component, call `useDownloadScoreReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadScoreReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadScoreReportQuery({
 *   variables: {
 *      file_registry_id: // value for 'file_registry_id'
 *   },
 * });
 */
export function useDownloadScoreReportQuery(baseOptions: Apollo.QueryHookOptions<DownloadScoreReportQuery, DownloadScoreReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadScoreReportQuery, DownloadScoreReportQueryVariables>(DownloadScoreReportDocument, options);
      }
export function useDownloadScoreReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadScoreReportQuery, DownloadScoreReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadScoreReportQuery, DownloadScoreReportQueryVariables>(DownloadScoreReportDocument, options);
        }
export type DownloadScoreReportQueryHookResult = ReturnType<typeof useDownloadScoreReportQuery>;
export type DownloadScoreReportLazyQueryHookResult = ReturnType<typeof useDownloadScoreReportLazyQuery>;
export type DownloadScoreReportQueryResult = Apollo.QueryResult<DownloadScoreReportQuery, DownloadScoreReportQueryVariables>;
export const namedOperations = {
  Query: {
    getScoreFactor: 'getScoreFactor',
    getScoreAndFactor: 'getScoreAndFactor',
    downloadScoreReport: 'downloadScoreReport'
  },
  Mutation: {
    addTradingFactors: 'addTradingFactors',
    addNewCompanyScore: 'addNewCompanyScore',
    updateCompanyScore: 'updateCompanyScore',
    addNewCompanyScoreTimeSeries: 'addNewCompanyScoreTimeSeries'
  }
}