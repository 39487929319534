import styled from "styled-components/macro";
import { Outlet, useParams } from "react-router-dom";
import { CssBaseline, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import GlobalStyle from "../components/GlobalStyle";
import { SidebarItemsType } from "../types/sidebar";
import Sidebar from "../components/sidebar/Sidebar";
import { ReactComponent as GlobeAltIcon } from "../assets/Icon/Outline/globe-alt-icon.svg";
import { ReactComponent as GlobeIcon } from "../assets/Icon/Outline/globe-icon.svg";
import { ReactComponent as LibraryIcon } from "../assets/Icon/Outline/library-icon.svg";
import { ReactComponent as MailIcon } from "../assets/Icon/Outline/mail-icon.svg";
import { ReactComponent as NewsPaperIcon } from "../assets/Icon/Outline/newspaper-icon.svg";
import { ReactComponent as PencilAltIcon } from "../assets/Icon/Outline/pencil-alt-icon.svg";
import { ReactComponent as UserIcon } from "../assets/Icon/Outline/user-icon.svg";
import { ReactComponent as ViewListIcon } from "../assets/Icon/Outline/view-list-icon.svg";
import { ReactComponent as ViewBoardsIcon } from "../assets/Icon/Solid/view-boards-solid-icon.svg";
import { ReactComponent as AdjustmentsIcon } from "../assets/Icon/Outline/adjustments-icon.svg";
import { ReactComponent as ShareIcon } from "../assets/Icon/Outline/share-icon.svg";

const drawerWidth = 258;
const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;
const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;
const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;
const Paper = styled(MuiPaper)(spacing);
const MainContent = styled(Paper)`
  flex: 1;
  padding: 0;
  margin-left: 258px;
  background: ${(props) => props.theme.palette.background.default};
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

//?---------------------------------------------------------------------------------------------------------------- */

const ModuleLayout = ({ children, path }: any) => {
  const { id: companyId } = useParams();

  const pagesSection = [
    {
      icon: GlobeIcon,
      title: "Company Overview",
      href: `/company/${companyId}/overview`,
    },
    {
      icon: GlobeAltIcon,
      title: "Company Details",
      href: `/company/${companyId}/details`,
    },
    {
      icon: GlobeAltIcon,
      title: "User Details",
      href: `/company/${companyId}/user-details`,
    },
    {
      icon: LibraryIcon,
      title: "Banking Data",
      href: `/company/${companyId}/banking-data`,
    },
    {
      icon: PencilAltIcon,
      title: "Document Check List",
      href: `/company/${companyId}/document-checklist`,
    },
    {
      icon: MailIcon,
      title: "Email Logs",
      href: `/company/${companyId}/checklist-logs`,
    },
    {
      icon: UserIcon,
      title: "Deals & Trades",
      href: `/company/${companyId}/deals-trades`,
    },
    {
      icon: NewsPaperIcon,
      title: "Scoring engine",
      href: `/company/${companyId}/scoring-engine`,
    },
    {
      icon: ViewListIcon,
      title: "Payment Partner",
      href: `/company/${companyId}/payment-partner`,
    },
    {
      icon: ViewBoardsIcon,
      title: "Accounting Data",
      href: `/company/${companyId}/account-data`,
    },
    {
      icon: AdjustmentsIcon,
      title: "Company GST",
      href: `/company/${companyId}/gst`,
    },
    {
      icon: ShareIcon,
      title: "Company Transactions",
      href: `/company/${companyId}/transactions`,
    },
  ] as SidebarItemsType[];

  const navItems = [
    {
      title: " ",
      pages: pagesSection,
    },
  ];

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <MainContent>
          <Drawer>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth, marginTop: "60px" } }}
              items={navItems}
            />
          </Drawer>
          {children}
          <Outlet />
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default ModuleLayout;
