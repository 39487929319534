import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import SimpleInput from "../../../components/input/SimpleInput";
import { useFormik } from "formik";
import {
  useAddInvestorSignatoryMutation,
  useGetAllStateQuery,
} from "../../../data-access/hooks/company/investors.hooks";
import * as Yup from "yup";
import SimpleSelect from "../../../components/input/SimpleSelect";
import SideModal from "../../../components/Modals/SideModal";

interface APASignatoryInterface {
  setOpen: Function;
  investor_id: Number;
  open: boolean;
}

const APASignatoryModal = ({
  setOpen,
  investor_id,
  open,
}: APASignatoryInterface) => {
  const [handleAddAPASignatory, { loading: addLoading }] =
    useAddInvestorSignatoryMutation({
      onCompleted: () => {
        setOpen(false);
      },
    });

  const [allStates, getAllStates] = React.useState<any[]>([]);

  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  const validationSchema = Yup.object().shape({
    i_signer_name: Yup.string().required("Please enter Signer Name"),
    i_signer_designation: Yup.string().required(
      "Please Enter Signer Designation"
    ),
    i_auth_id: Yup.string()
      .required("Please Enter Signer Email Id")
      .email("Enter a valid Email Id"),
  });

  const formik = useFormik({
    initialValues: {
      i_signer_name: "",
      i_signer_designation: "",
      i_auth_id: "",
      i_auth_addr: "",
      jurisdiction: 0,
      invest_ph: "",
      estamp_tag: "",
      estamp_quantity: "",
    },
    validationSchema: validationSchema,
    onSubmit: (item) => {
      handleAddAPASignatory({
        variables: {
          id: Number(investor_id),
          set: {
            signer_details: {
              i_signer_name: item?.i_signer_name,
              i_signer_designation: item?.i_signer_designation,
              i_auth_id: item?.i_auth_id,
              i_auth_addr: item?.i_auth_addr,
              jurisdiction: item?.jurisdiction,
              invest_ph: item?.invest_ph,
              estamp_tag: item?.estamp_tag,
              estamp_quantity: item?.estamp_quantity,
            },
          },
        },
      });
    },
  });

  /***-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
  const { loading: stateLoading } = useGetAllStateQuery({
    onCompleted: (item) => {
      getAllStates(item?.ref_states);
    },
    variables: {
      where: {
        country_id: {
          _eq: 101,
        },
      },
    },
  });

  return (
    <SideModal
      open={open}
      onClose={() => setOpen(false)}
      modalTitle={"Add Signer Details"}
      footer={
        <Button
          disabled={addLoading}
          fullWidth
          type={"submit"}
          variant="primarySmall"
          form="apa-signatory-form"
        >
          {addLoading ? <CircularProgress size={20} /> : "Submit"}
        </Button>
      }
    >
      <form
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
        id="apa-signatory-form"
        onSubmit={formik.handleSubmit}
      >
        {stateLoading && <LinearProgress />}
        <Grid item container columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <SimpleInput
                name="i_signer_name"
                inputlabel="Signer Name"
                placeholder="Enter Signer Name"
                requiredlabel
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.i_signer_name}
                error={
                  formik.touched.i_signer_name &&
                  Boolean(formik.errors.i_signer_name)
                }
                helperText={
                  formik.touched.i_signer_name && formik.errors.i_signer_name
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <SimpleInput
                name="i_signer_designation"
                inputlabel="Signer Designation"
                placeholder="Enter Signer Designation"
                requiredlabel
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.i_signer_designation}
                error={
                  formik.touched.i_signer_designation &&
                  Boolean(formik.errors.i_signer_designation)
                }
                helperText={
                  formik.touched.i_signer_designation &&
                  formik.errors.i_signer_designation
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <SimpleInput
                name="i_auth_id"
                inputlabel="Signer Email"
                placeholder="Enter Signer Email"
                requiredlabel
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.i_auth_id}
                error={
                  formik.touched.i_auth_id && Boolean(formik.errors.i_auth_id)
                }
                helperText={formik.touched.i_auth_id && formik.errors.i_auth_id}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <SimpleInput
                name="invest_ph"
                inputlabel="Signer Phone No"
                placeholder="Enter Signer Phone No"
                requiredlabel
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.invest_ph}
                error={
                  formik.touched.invest_ph && Boolean(formik.errors.invest_ph)
                }
                helperText={formik.touched.invest_ph && formik.errors.invest_ph}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <SimpleSelect
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.jurisdiction}
                name="jurisdiction"
                error={
                  formik.touched.jurisdiction &&
                  Boolean(formik.errors.jurisdiction)
                }
                helperText={
                  formik.touched.jurisdiction && formik.errors.jurisdiction
                }
                displayEmpty
                requiredlabel
                id="jurisdiction"
                placeholder="Please enter"
                formlabel="State"
                renderValue={(values: any) => {
                  if (formik.values.jurisdiction === 0) {
                    return <Typography>Select</Typography>;
                  } else {
                    return allStates?.find((item) => item?.id === values)?.name;
                  }
                }}
              >
                {allStates?.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </SimpleSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <SimpleInput
                name="estamp_tag"
                inputlabel="Estamp Tag"
                placeholder="Enter Estamp Tag"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.estamp_tag}
                error={
                  formik.touched.estamp_tag && Boolean(formik.errors.estamp_tag)
                }
                helperText={
                  formik.touched.estamp_tag && formik.errors.estamp_tag
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <SimpleInput
                name="i_auth_addr"
                inputlabel="Signer Address"
                placeholder="Enter Signer Address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.i_auth_addr}
                helperText={
                  formik.touched.i_auth_addr && formik.errors.i_auth_addr
                }
                error={
                  formik.touched.i_auth_addr &&
                  Boolean(formik.errors.i_auth_addr)
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} mt={6} mb={3}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <SimpleInput
                name="estamp_quantity"
                inputlabel="Estamp Quantity"
                placeholder="Enter Estamp Quantity"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.estamp_quantity}
                error={
                  formik.touched.estamp_quantity &&
                  Boolean(formik.errors.estamp_quantity)
                }
                helperText={
                  formik.touched.estamp_quantity &&
                  formik.errors.estamp_quantity
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </SideModal>
  );
};

export default APASignatoryModal;
