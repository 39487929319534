import {
  Grid,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { format } from "date-fns";
import { ReactComponent as ChevronDownIcon } from "../../../assets/Icon/Outline/chevron-down-icon.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/Icon/Outline/download-icon.svg";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import CommonBreadCrumb from "../../../components/CommonBreadCrumb";
import EmptyData from "../../../components/EmptyData";
import useDealsTrade from "../../../data-access/api/deals-trade";
import { TableWrapper } from "../../../theme/customComponents";
import CommonLoader from "../../../components/CommonLoader";
import TableStatus from "../../../components/TableStatus";

const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root::before {
    background-color: white;
  }
  & .MuiAccordionSummary-gutters {
    padding: 0 24px;
  }
  & .MuiAccordionDetails-root {
    padding: 0;
  }
`;

const Deals = () => {
  const {
    alldealsData,
    allTradeData,
    handleDownloadTable,
    TableType,
    downloadLoading,
    loading,
  } = useDealsTrade();

  return (
    <>
      <Helmet title="Company Detail" />
      <Grid
        item
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {/*---------------------------*COMMON BREAD CRUMBS--------------------------- */}
        <Grid item>
          <CommonBreadCrumb
            typographyText="Deals & Trades"
            linksText={[{ link: "  Company Queues", to: "/company-queues" }]}
            showCompany
          />
        </Grid>
      </Grid>
      <br />
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ChevronDownIcon width={14} height={14} color="black" />}
          id="deal-over-view"
        >
          <Typography fontSize={"18px"} fontWeight={600}>
            Deal Over View
          </Typography>
        </AccordionSummary>
        {loading ? (
          <CommonLoader />
        ) : (
          <AccordionDetails>
            {alldealsData?.deal?.length === 0 ? (
              <EmptyData />
            ) : (
              <TableWrapper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Score ID</TableCell>
                      <TableCell>Deal ID</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Deal Expiry</TableCell>
                      <TableCell>Offer Closure</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {alldealsData?.deal?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ verticalAlign: "top" }}>
                          {item?.company_score_id}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                          {item?.id}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                          {TableStatus({
                            stautsText: `${item?.status}`,
                          })}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                          {format(Date.parse(item?.created_at), "dd LLL, yyyy")}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                          {format(
                            Date.parse(item?.deal_expiry),
                            "dd LLL, yyyy"
                          )}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                          {format(
                            Date.parse(item?.bid_closure),
                            "dd LLL, yyyy"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableWrapper>
            )}
          </AccordionDetails>
        )}
      </Accordion>
      <br />
      <Accordion defaultExpanded={true} expanded={true}>
        <AccordionSummary style={{ pointerEvents: "none" }}>
          <Typography fontSize={"18px"} fontWeight={600}>
            Trade Overview{" "}
          </Typography>
        </AccordionSummary>
        {loading ? (
          <CommonLoader />
        ) : (
          <AccordionDetails>
            {alldealsData?.deal?.length === 0 ? (
              <EmptyData />
            ) : (
              <TableWrapper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Trade ID</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Tenure</TableCell>
                      <TableCell>Trade Amount</TableCell>
                      <TableCell>Investors</TableCell>
                      <TableCell>Mandate ID</TableCell>
                      <TableCell>EMI Start Date</TableCell>
                      <TableCell>EMI Count</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Amortisation Table</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allTradeData?.trade?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ verticalAlign: "top" }}>
                          {item?.id}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                          {item?.company?.name}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                          {item?.validity}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                          ₹ {parseFloat(item?.emi_amount).toFixed(1)}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                          {item?.investments?.map((item, index) => {
                            return (
                              <Typography
                                key={index}
                                style={{
                                  color: "black",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {item?.investor?.name}
                              </Typography>
                            );
                          })}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                          {item?.mandate_id || "-"}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                          {item?.emi_start_date
                            ? format(
                                new Date(item?.emi_start_date),
                                "dd LLL, yyyy"
                              )
                            : "-"}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                          {item?.transactions_aggregate?.aggregate?.count ||
                            "-"}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                          {TableStatus({
                            stautsText: `${item?.status}`,
                          })}
                        </TableCell>
                        <TableCell
                          style={{
                            verticalAlign: "top",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          {downloadLoading ? (
                            <CircularProgress size={18} />
                          ) : (
                            <>
                              <Button
                                variant="linkButton"
                                disabled={downloadLoading}
                                onClick={() =>
                                  handleDownloadTable({
                                    type: TableType.Investor,
                                    trade_id: item?.id,
                                  })
                                }
                              >
                                <DownloadIcon width={18} height={18} />
                                &nbsp; Investors
                              </Button>
                              <Button
                                variant="linkButton"
                                disabled={downloadLoading}
                                onClick={() =>
                                  handleDownloadTable({
                                    type: TableType.Company,
                                    trade_id: item?.id,
                                  })
                                }
                              >
                                <DownloadIcon width={18} height={18} />
                                &nbsp; Company{" "}
                              </Button>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableWrapper>
            )}
          </AccordionDetails>
        )}
      </Accordion>
    </>
  );
};

export default Deals;
