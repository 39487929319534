import { createGlobalStyle } from "styled-components/macro";
import { GlobalStyleProps } from "../types/styles";

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    font-family: Manrope;
    height: 100%;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #1E88E555; 
    }
    ::-webkit-scrollbar-thumb {
      background: #1E88E5; 
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
  }
  body {
    background: ${(props) => props.theme.palette.background.default};
    margin: 0;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

export default GlobalStyle;
