export const currencyFormat = (
  amount: number,
  decimal = 4,
  currency = "INR",
  locale = "en-IN"
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    maximumFractionDigits: decimal,
    minimumFractionDigits: decimal,
  });
  return formatter.format(amount);
};
