import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  Pagination,
  TableSortLabel,
  Paper,
} from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import CommonBreadCrumb from "../../../components/CommonBreadCrumb";
import SimpleInput from "../../../components/input/SimpleInput";
import { useGetAllCompanyLogsByIdQuery } from "../../../data-access/hooks/company/document-checklist.hooks";
import { TableWrapper } from "../../../theme/customComponents";
import { ReactComponent as SearchIcon } from "../../../assets/Icon/Outline/search-icon.svg";
import { useParams } from "react-router-dom";
import { Order_By } from "../../../data-access/types";
import EmptyData from "../../../components/EmptyData";
import CommonLoader from "../../../components/CommonLoader";
import TableStatus from "../../../components/TableStatus";

interface EmailLogsInterface {
  subject?: string | null | undefined;
  recipient?: any | null | undefined;
  sender?: any | null | undefined;
  cc?: any | null | undefined;
  bcc?: any | null | undefined;
  status?: string | null | undefined;
  sent_at?: string | null | undefined;
}

const CompanyEmailLogs = () => {
  const { id } = useParams();

  const [allLogs, setAllLogs] = useState<EmailLogsInterface[]>([]);

  const [searchText, setSearchText] = useState<string>("");

  const [tableOrder, setTableOrder] = useState("desc");

  const mailCheck = {
    to: {
      email: searchText,
    },
  };

  const [paginationData, setPaginationData] = useState({
    offset: 0,
    limit: 50,
    totalcount: 0,
    currentPage: 1,
  });

  const { loading } = useGetAllCompanyLogsByIdQuery({
    onCompleted: (item) => {
      const data = item.company_email_log?.map((data) => {
        return {
          subject: data?.email_event?.subject,
          recipient: data?.email_event?.to,
          sender: data?.email_event?.from,
          cc: data?.email_event?.cc,
          bcc: data?.email_event?.bcc,
          status: data?.email_event?.status,
          sent_at: data?.created_at,
        };
      });
      setAllLogs(data);
      setPaginationData({
        ...paginationData,
        totalcount: item.company_email_log_aggregate.aggregate?.count ?? 0,
      });
    },
    fetchPolicy: "no-cache",
    variables: {
      limit: paginationData?.limit,
      offset: paginationData.offset,
      company_id: Number(id),
      order_by: [
        {
          created_at: tableOrder as Order_By,
        },
      ],
      where: {
        company_id: {
          _eq: Number(id),
        },
        _or: [
          {
            email_event: {
              subject: {
                _ilike: `%${searchText}%`,
              },
            },
          },
          {
            email_event: {
              to: {
                _contains: mailCheck,
              },
            },
          },
        ],
      },
    },
  });

  const pageCount = (number: number) => {
    return Math.ceil(number / 10);
  };

  const ReciptientConverter = (data: any) => {
    if (data?.length) {
      return data;
    } else if (typeof data === "object") {
      return [data];
    } else if (typeof data === "string") {
      return [
        {
          email: data,
        },
      ];
    }
  };

  return (
    <Grid container display={"flex"} flexDirection="column">
      <Helmet title="Company Detail" />
      <Grid
        item
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {/*---------------------------*COMMON BREAD CRUMBS--------------------------- */}
        <Grid item>
          <CommonBreadCrumb
            typographyText="Email logs"
            linksText={[{ link: "Company Queues", to: "/company-queues" }]}
            showCompany
          />
        </Grid>
      </Grid>
      <Divider
        variant="fullWidth"
        orientation="horizontal"
        style={{
          margin: "16px 0 28px 0",
        }}
      />
      <Grid item width={"30%"} mb={6}>
        <SimpleInput
          fullWidth
          InputProps={{
            startAdornment: (
              <SearchIcon
                width={16}
                height={16}
                color="#666666"
                style={{ marginLeft: "6px" }}
              />
            ),
          }}
          placeholder="Search with subject or recipient"
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            setSearchText(event?.currentTarget?.value)
          }
        />
      </Grid>
      <Grid
        item
        p={3}
        style={{
          background: "#fff",
        }}
      >
        <Typography fontWeight={600} fontSize={"16px"}>
          Email Logs
        </Typography>
      </Grid>
      <Grid item>
        {loading ? (
          <CommonLoader />
        ) : (
          <TableWrapper>
            {allLogs?.length === 0 ? (
              <Paper>
                <EmptyData />
              </Paper>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Subject</TableCell>
                    <TableCell>Recipient (To)</TableCell>
                    <TableCell>Sender (From)</TableCell>
                    <TableCell>CC and BCC</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={true}
                        direction={tableOrder as any}
                        onClick={() =>
                          setTableOrder(tableOrder === "desc" ? "asc" : "desc")
                        }
                      >
                        Sent Date and Time
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allLogs?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell width={"20%"}>
                        {item?.subject || "-"}
                      </TableCell>
                      <TableCell>
                        {item?.recipient
                          ? ReciptientConverter(item?.recipient)?.map(
                              (data: any, index: number) => (
                                <Typography key={index} whiteSpace={"nowrap"}>
                                  {data?.email}
                                </Typography>
                              )
                            )
                          : item?.recipient?.email}
                      </TableCell>
                      <TableCell>{item?.sender?.email}</TableCell>
                      <TableCell>
                        <Typography whiteSpace={"nowrap"}>
                          {item.bcc?.email ? `${item.bcc?.email} (BCC)` : "-"}
                        </Typography>

                        <Typography whiteSpace={"nowrap"}>
                          {item.cc?.email ? `${item.cc?.email} (CC)` : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {TableStatus({
                          stautsText: `${item?.status}`,
                        })}
                      </TableCell>
                      <TableCell>
                        {format(new Date(`${item?.sent_at}`), "dd LLL yyyy")} &
                        {format(new Date(`${item?.sent_at}`), " h:m a")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableWrapper>
        )}
        {allLogs?.length !== 0 && (
          <Pagination
            count={pageCount(paginationData?.totalcount)}
            variant={"outlined"}
            page={paginationData?.currentPage}
            onChange={(event: any, value: any) => {
              const newOffset = 10 * value - 10;
              setPaginationData({
                offset: newOffset,
                limit: paginationData?.limit,
                totalcount: paginationData?.totalcount,
                currentPage: value,
              });
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CompanyEmailLogs;
