import { Grid, Typography } from "@mui/material";
import { ReactComponent as ExclamationIcon } from "../../../assets/Icon/Outline/exclamation-icon.svg";

const ConfirmCustomNDA = ({ data }: any) => {
  return (
    <>
      <Grid container style={{ background: "white" }} width="100%" gap="22px">
        <Grid
          item
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          width="100%"
        >
          <Typography
            fontSize={"16px"}
            fontWeight={700}
            display="flex"
            gap={"10px"}
            alignItems="center"
          >
            <ExclamationIcon color="#E90000" /> Are you sure you want to
            continue?
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontSize={"16px"} fontWeight={600}>
            An NDA for{" "}
            <span
              style={{
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              {data?.company_name}
            </span>{" "}
            will be sent to{" "}
            <span
              style={{
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              {data?.email}
            </span>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfirmCustomNDA;
