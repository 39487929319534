import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
import {
  useCompanyDetailByIdQuery,
  useGetSignatoryByCompanyIdQuery,
} from "../data-access/hooks/company/company-details.hooks";

const useUserDetails = () => {
  const [companyDetailsData, setCompanyDetailsData] = useState<any>();

  const [openUploadModal, setOpenUploadModal] = useState(false);

  const { setShowAlert } = useContext(CompanyContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { id }: any = useParams();

  const [openAddSignatoryModal, setOpenAddSignatoryModal] =
    useState<boolean>(false);

  const [selectSignatoryModal, setSelectSignatoryModal] =
    useState<boolean>(false);

  const [signatoryList, setSignatoryList] = useState<any[]>([]);

  /********************************************************************************************************************* */
  const { loading: getSignatoryLoading, refetch: signatoryFetch } =
    useGetSignatoryByCompanyIdQuery({
      variables: {
        where: {
          company_id: {
            _eq: Number(id),
          },
        },
      },
      onCompleted: (item) => {
        setSignatoryList(item.company_authorized_signatory);
      },
      fetchPolicy: "no-cache",
    });

  /*************************************************************************************************************/
  const handleAgreementClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /*************************************************************************************************************/
  const { loading: getDataLoading } = useCompanyDetailByIdQuery({
    variables: {
      id: id,
    },
    onCompleted: (item) => {
      setCompanyDetailsData(item.company_by_pk);
    },
    onError: (error) => {
      setShowAlert({
        show: true,
        message: error.message,
        type: "error",
      });
    },
  });

  /*************************************************************************************************************/
  const loading = getDataLoading;

  /*************************************************************************************************************/

  return {
    id,
    open,
    anchorEl,
    loading,
    companyDetailsData,
    setAnchorEl,
    handleAgreementClick,
    setOpenUploadModal,
    openAddSignatoryModal,
    setOpenAddSignatoryModal,
    signatoryList,
    getSignatoryLoading,
    signatoryFetch,
    setSelectSignatoryModal,
    openUploadModal,
    selectSignatoryModal,
  };
};

export default useUserDetails;
