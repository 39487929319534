import { Button, Grid, Typography } from "@mui/material";
import { ReactComponent as ExclamationIcon } from "../../assets/Icon/Outline/exclamation-icon.svg";

interface ErrorModalInterface {
  type: string;
  onClose: () => void;
}

const ErrorModal = ({ type, onClose }: ErrorModalInterface) => {
  return (
    <Grid
      container
      display={"flex"}
      flexDirection="column"
      style={{ background: "#fff" }}
      justifyContent="center"
      width={"30%"}
      gap={4}
      p={8}
    >
      <Grid item display={"flex"} width={"100%"} alignItems="center">
        <ExclamationIcon color="#1E88E5" />
        &nbsp; &nbsp;
        <Typography fontSize={16} fontWeight={700}>
          {type === "investor"
            ? "Multiple offers, Same investor!"
            : "Different Interest Rates selected!"}
        </Typography>
      </Grid>
      <Grid item display={"flex"} width={"100%"} alignItems="center">
        <Typography fontSize={16} fontWeight={400}>
          {type === "investor"
            ? "You have selected multiple offers with the same investor. Please change your selection"
            : "You have selected offers with different interest rates. Please change your selection"}
        </Typography>
      </Grid>
      <Grid
        item
        display={"flex"}
        width={"100%"}
        alignItems="center"
        justifyContent={"flex-end"}
      >
        <Button onClick={onClose} variant="primarySmall">
          Close
        </Button>
      </Grid>
    </Grid>
  );
};

export default ErrorModal;
