import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableWrapper } from "../../../theme/customComponents";
import {
  CircularProgress,
  IconButton,
  Typography,
  Pagination,
} from "@mui/material";
import { ReactComponent as DownloadIcon } from "../../../assets/Icon/Outline/download-icon.svg";
import { format } from "date-fns";
import { useDownloadFilesZipMutation } from "../../../data-access/hooks/company/download-zip-files.hooks";
import { TargetFilesType } from "../../../data-access/types";
import React from "react";
import TableStatus from "../../../components/TableStatus";

interface AccountDataInterface {
  category: string;
  company_id: number;
  company_integration_files: IntegrationDataInterface[];
  source: string;
  status: string;
  updated_at: string;
}

interface IntegrationDataInterface {
  company_integration_id: number;
  file_registry: any;
}

const AccountingDataTable = ({
  allAccountData,
  paginationData,
  setPaginationData,
}: any) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const pageCount = (number: number) => {
    return Math.ceil(number / 10);
  };

  const [handleFileDownloadZip, { loading: downloadZipLoading }] =
    useDownloadFilesZipMutation({
      onCompleted: (item) => {
        const downloadUrl = item.DownloadFilesZip?.downloadUrl || "";
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      },
    });

  return (
    <>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Account Software</TableCell>
              <TableCell>Files</TableCell>
              <TableCell>Last Sync On</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allAccountData.map((item: AccountDataInterface, index: number) => (
              <TableRow key={index}>
                <TableCell>{item.source}</TableCell>
                <TableCell>
                  {item.company_integration_files?.length
                    ? item.company_integration_files.map((integrationData) => (
                        <Typography>
                          {integrationData.file_registry?.name}
                        </Typography>
                      ))
                    : "-"}
                </TableCell>
                <TableCell>
                  {item.updated_at
                    ? format(new Date(item.updated_at), "dd LLL yyyy")
                    : "-"}
                </TableCell>
                <TableCell>
                  {TableStatus({
                    stautsText: item?.status,
                  })}
                </TableCell>
                <TableCell>
                  <IconButton
                    style={{ border: "solid 1px #1E88E533" }}
                    onClick={() => {
                      setCurrentIndex(index);
                      handleFileDownloadZip({
                        variables: {
                          args: {
                            company_integration_id: 2384,
                            fileType: TargetFilesType.UploadedFiles,
                          },
                        },
                      });
                    }}
                  >
                    {currentIndex === index && downloadZipLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <DownloadIcon color="#1E88E5" width={16} height={16} />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
      <Pagination
        count={pageCount(paginationData?.totalcount ?? 0)}
        variant={"outlined"}
        page={paginationData?.currentPage}
        onChange={(event: any, value: any) => {
          const newOffset = 10 * value - 10;
          setPaginationData({
            offset: newOffset,
            limit: 50,
            totalcount: paginationData?.totalcount,
            currentPage: value,
          });
        }}
      />
    </>
  );
};

export default AccountingDataTable;
