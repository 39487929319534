import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Select as MuiSelect,
  MenuItem,
  Tooltip as MuiTooltip,
  Fade,
  Modal,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Paper as MuiPaper, Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import useCompanyDocumentChecklist from "../../../data-access/api/company-document-checklist";
import { createStyles, makeStyles } from "@mui/styles";
import ConfirmationModal from "./ConfirmationModal";
import RemarksCell from "./RemarksCell";
import { TableWrapper } from "../../../theme/customComponents";
import CommonBreadCrumb from "../../../components/CommonBreadCrumb";
import EmptyData from "../../../components/EmptyData";
import { ReactComponent as CheckIcon } from "../../../assets/Icon/Outline/check-icon.svg";
import { ReactComponent as InformationCircleIcon } from "../../../assets/Icon/Outline/information-circle-icon.svg";
import CommonLoader from "../../../components/CommonLoader";

//*-----------------------------------------------------------------------------------------------
const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      background: "#fff",
      boxShadow: "0px 4px 12px rgba(192, 191, 191)",
      color: "#000",
      padding: "18px 24px",
      borderRadius: "7px",
      width: "400px",
    },
    arrow: {
      fontSize: 16,
      width: 17,
      "&::before": {
        border: "1px solid #FFF",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 12px rgba(192, 191, 191)",
      },
    },
    menu: {
      boxShadow: "0px 4px 12px rgba(192, 191, 191)",
    },
    normalTooltip: {
      background: "#000",
    },
    normalArrow: {
      fontSize: 16,
      width: 17,
      "&::before": {
        border: "1px solid #000",
        backgroundColor: "#000",
      },
    },
  })
);

const Paper = styled(MuiPaper)(spacing);

const EditButton = styled(Button)`
  display: flex;
  justifycontent: center;
  alignitems: center;
  gap: 5px;
  height: 40px;
  width: 140px;
  background: #1e88e5;
`;
const Divider = styled(MuiDivider)(spacing);
const Select = styled(MuiSelect)`
  width: 143px;
  height: 40px;
`;

//*-----------------------------------------------------------------------------------------------

const CompanyDocumentChecklist = () => {
  const classes = useStyles();
  const {
    setShowTooltip,
    onHandleSelectChange,
    handleSectionApplicable,
    setShowConfirmationModal,
    setOnTextareaValueChange,
    handleSectionRemarkChange,
    handleSaveDocumentChecklist,
    checkListRef,
    allSectionData,
    statusDropdownData,
    showConfirmationModal,
    onTextareaValueChange,
    categoryLoading,
    loading,
    notifyIncomepleteDocumentChecklist,
    NotifyUserLoading,
    setAllSectionData,
    setUpdateDocumentCheckListData,
    initialSectionData,
    params,

    handleAddDocumentChecklist,
    addLoading,
    handlePauseDocumentChecklist,
    documentChecklistStatus,
    statusUpdateLoading,
  } = useCompanyDocumentChecklist();

  return (
    <div ref={checkListRef}>
      <Helmet title="Company Detail" />
      <Grid
        item
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {/*---------------------------*COMMON BREAD CRUMBS--------------------------- */}
        <Grid item>
          <CommonBreadCrumb
            typographyText="Document Check List"
            linksText={[{ link: "  Company Queues", to: "/company-queues" }]}
            showCompany
          />
        </Grid>
        {/*--------------------------------------------------------------------------- */}
      </Grid>
      <Divider mt="16px" mb="28px" />
      {loading ? (
        <CommonLoader />
      ) : (
        <>
          {allSectionData?.length === 0 ? (
            <Grid container height={"60vh"}>
              <EmptyData
                emptyMessage={"The document checklist has not been generated."}
              >
                <Button
                  onClick={() => {
                    handleAddDocumentChecklist({
                      variables: {
                        company_id: Number(params?.id),
                        sales_email: "sales@bridgeup.com",
                      },
                    });
                  }}
                >
                  {" "}
                  {addLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    "Generate Document Checklist"
                  )}
                </Button>
              </EmptyData>
            </Grid>
          ) : (
            <Grid justifyContent="space-between" container spacing={6}>
              <Grid item>
                <Typography fontSize={"36px"} fontWeight={700} gutterBottom>
                  Document Check List
                </Typography>
              </Grid>
              <Grid item xs />
              <Grid item display={"flex"} gap={"12px"} alignItems={"center"}>
                <FormControlLabel
                  style={{
                    margin: "0 10px",
                  }}
                  value="end"
                  control={
                    statusUpdateLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Switch
                        color="primary"
                        onChange={() => {
                          handlePauseDocumentChecklist({
                            variables: {
                              args: {
                                company_id: Number(params.id),
                              },
                            },
                          });
                        }}
                        checked={
                          !documentChecklistStatus?.company_by_pk
                            ?.document_checklist_enabled
                        }
                      />
                    )
                  }
                  label={
                    <Typography mx={1}>Pause Document Checklist</Typography>
                  }
                  labelPlacement="start"
                />
                <Button
                  onClick={() => {
                    setShowTooltip(false);
                    setShowConfirmationModal(true);
                  }}
                  variant="outlined"
                  style={{
                    height: "40px",
                  }}
                >
                  Send A Reminder
                </Button>
                <EditButton
                  onClick={handleSaveDocumentChecklist}
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={
                    JSON.stringify(
                      initialSectionData?.document_checklist_category
                    ) === JSON.stringify(allSectionData) || categoryLoading
                  }
                >
                  {categoryLoading ? (
                    <CircularProgress size={"15px"} />
                  ) : (
                    "Save"
                  )}
                </EditButton>
              </Grid>
            </Grid>
          )}
          <br />
          {/**----------------------------------------------SECTIONS------------------------------------------------- */}
          {allSectionData?.map((parent: any, index: number) => (
            <Grid item key={index}>
              <Paper>
                <Grid padding={"20px"} justifyContent="space-between" container>
                  <Grid
                    item
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <Typography fontWeight={600} variant="h4">
                      {parent.ref_document_checklist_category.name}
                    </Typography>
                    {parent.id !== 1 && (
                      <Select
                        id="is_applicable"
                        name="is_applicable"
                        value={`${parent?.is_applicable}`}
                        onChange={(item) => {
                          handleSectionApplicable(parent.id, item.target.value);
                        }}
                        defaultValue={`${parent?.is_applicable}`}
                        MenuProps={{ classes: { paper: classes.menu } }}
                      >
                        <MenuItem value={"false"}>Not Applicable</MenuItem>
                        <MenuItem value={"true"}>Applicable</MenuItem>
                      </Select>
                    )}
                  </Grid>
                </Grid>
                <TableWrapper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sr. No.</TableCell>
                        <TableCell>Document Name</TableCell>
                        <TableCell>Submitted By Customer</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Remark</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {parent?.document_checklists?.map(
                        (row: any, index: any) => {
                          return (
                            <TableRow
                              style={{
                                background: parent?.is_applicable
                                  ? "white"
                                  : "#F3F3F3",
                                transition: "all .3s",
                              }}
                              key={index}
                            >
                              <TableCell width={50}>
                                {row.ref_document_checklist.serial_number}
                              </TableCell>
                              <TableCell width={350}>
                                <Grid display={"flex"} gap={1}>
                                  <Typography gap={1}>
                                    {row.ref_document_checklist.name}
                                    <span style={{ color: "red" }}>
                                      {row.documentType === "mandatory" && "*"}
                                    </span>
                                  </Typography>
                                  {row.ref_document_checklist.internal_tip &&
                                    row.ref_document_checklist.internal_tip !==
                                      "-" && (
                                      <MuiTooltip
                                        style={{
                                          alignItems: "center",
                                          display: "flex",
                                          pointerEvents: !parent?.is_applicable
                                            ? "none"
                                            : "unset",
                                        }}
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        classes={{
                                          tooltip: classes.normalTooltip,
                                          arrow: classes.normalArrow,
                                        }}
                                        title={
                                          row.ref_document_checklist
                                            .internal_tip
                                        }
                                        arrow
                                        placement="top"
                                      >
                                        <span>
                                          <InformationCircleIcon
                                            width="16px"
                                            height="16px"
                                          />
                                        </span>
                                      </MuiTooltip>
                                    )}
                                </Grid>
                                <Typography
                                  fontSize={"11px"}
                                  color={"#00000073"}
                                  fontFamily={"Manrope"}
                                >
                                  {row.ref_document_checklist.subtext}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  display={"flex"}
                                  gap={1}
                                  alignItems={"center"}
                                >
                                  {row.shared_by_company && (
                                    <CheckIcon width="20px" height="20px" />
                                  )}
                                  {row.shared_by_company ? "Uploaded" : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Select
                                  disabled={
                                    !parent?.is_applicable && parent.id !== 1
                                  }
                                  name={`is-${index}-applicable`}
                                  id={`is-${index}-applicable`}
                                  ref={checkListRef}
                                  value={row?.status}
                                  onChange={(item) =>
                                    onHandleSelectChange({
                                      e: item.target.value,
                                      id: row?.id,
                                    })
                                  }
                                  MenuProps={{
                                    classes: { paper: classes.menu },
                                  }}
                                >
                                  {statusDropdownData.map((item, index) => (
                                    <MenuItem
                                      key={index}
                                      ref={checkListRef}
                                      value={item.value}
                                    >
                                      {item.text}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {row?.status === "INCOMPLETE" &&
                                  !row?.remarks && (
                                    <Typography color={"red"}>
                                      Please add a remark
                                    </Typography>
                                  )}
                              </TableCell>
                              <TableCell>
                                <RemarksCell
                                  row={row}
                                  setShowTooltip={setShowTooltip}
                                  setAllSectionData={setAllSectionData}
                                  onTextareaValueChange={onTextareaValueChange}
                                  handleSectionRemarkChange={
                                    handleSectionRemarkChange
                                  }
                                  setOnTextareaValueChange={
                                    setOnTextareaValueChange
                                  }
                                  allSectionData={allSectionData}
                                  setUpdateDocumentCheckListData={
                                    setUpdateDocumentCheckListData
                                  }
                                  parent={parent}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableWrapper>
              </Paper>
            </Grid>
          ))}
          <br />
          <br />
        </>
      )}

      <Modal
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
      >
        <Grid
          container
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <ConfirmationModal
            onCancelClick={() => setShowConfirmationModal(false)}
            onProceedClick={() =>
              notifyIncomepleteDocumentChecklist({
                variables: {
                  args: {
                    company_id: Number(params?.id),
                  },
                },
              })
            }
            loading={NotifyUserLoading}
          />
        </Grid>
      </Modal>
    </div>
  );
};

export default CompanyDocumentChecklist;
