import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useDownloadUploadedFileLazyQuery } from "../../../data-access/hooks/company/payment-partner.hooks";
import { ReactComponent as XIcon } from "../../../assets/Icon/Outline/x-icon.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/Icon/Outline/download-icon.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/Icon/Outline/document-icon.svg";
import { ReactComponent as TrashIcon } from "../../../assets/Icon/Outline/trash-icon.svg";
import { useUploadAgreementMutation } from "../../../data-access/hooks/company/nda-logs.hooks";
import { Agreement_Category } from "../../../data-access/types";
import SimpleDatePicker from "../../../components/input/SimpleDatePicker";
import { CompanyContext } from "../../../contexts/CompanyContext";

const UploadAPA = ({ trade_id, onClose, getAllDataRefetch }: any) => {
  const { id } = useParams();

  const [uploadFileDate, setUploadFileDate] = useState(new Date());

  const [secureResponse, setSecureResponse] = useState({
    response: "",
    selectedFile: {} as any,
  });

  const [uploadedNewFile, setUploadNewFile] = useState([]);

  const onDrop = useCallback((acceptedFiles: any) => {
    setUploadLoading(true);
    setUploadNewFile(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      PDF: [".pdf"],
    },
    multiple: false,
    onDrop,
    maxFiles: 1,
  });

  const [uploadLoading, setUploadLoading] = useState(false);

  const { setShowAlert } = useContext(CompanyContext);

  async function uploadFile(selectedFile: File) {
    const formData = new FormData();
    formData.append("file", selectedFile, selectedFile.name);

    formData.append("category_id", "SIGNED_APA");

    formData.append("password", "");

    const token = localStorage.getItem("accessToken");

    const config: any = {
      method: "post",
      url: `${process.env.REACT_APP_ACTIONS_BASE_URL}secure-upload`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        "X-hasura-tenant-id": id,
      },
    };
    await axios(config)
      .then((response) => {
        setShowAlert({
          show: true,
          message: "The file is successfully uploaded",
          type: "success",
        });
        setSecureResponse({
          response: response?.data?.file_registry_id,
          selectedFile,
        });
        setUploadLoading(false);
      })
      .catch((error) => {
        setShowAlert({
          show: true,
          message: error.response.data.message,
          type: "error",
        });
        setUploadLoading(false);
      });
  }

  useEffect(() => {
    if (uploadedNewFile.length !== 0) {
      uploadFile(uploadedNewFile[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedNewFile]);

  const [handleUploadAPA, { loading }] = useUploadAgreementMutation({
    onCompleted: () => {
      getAllDataRefetch();
      onClose();
    },
    onError: (item) => {
      setShowAlert({
        message: item.message,
        show: true,
        type: "error",
      });
    },
  });

  const [downloadUploadFile, { loading: downloadLoading }] =
    useDownloadUploadedFileLazyQuery({
      onCompleted: (item: any) => {
        const downloadUrl = item?.generateFileRegistryDownloadURL?.download_url;
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      },
    });

  return (
    <>
      <Grid
        container
        item
        display={"flex"}
        flexDirection={"column"}
        width={"30%"}
        gap={"25px"}
        style={{ background: "white" }}
        p={5}
      >
        <Grid
          item
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography fontWeight={600} fontSize={"18px"}>
            Upoad APA File
          </Typography>
          <IconButton onClick={() => onClose()}>
            <XIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          gap={"25px"}
          alignItems={"flex-start"}
        >
          <Grid
            item
            width="100%"
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            xs={12}
          >
            {uploadLoading ? (
              <CircularProgress />
            ) : (
              <Grid
                container
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                xs={12}
                gap="10px"
              >
                <Grid item xs={12} width={"100%"}>
                  <div
                    style={{
                      background: "#1E88E50A",
                      width: "100%",
                      border: "dashed 1px #1E88E5",
                      borderRadius: "11px",
                      height: "120px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div>
                      <div>
                        <Typography fontWeight={600} fontSize={"16px"}>
                          Drag and drop or
                          <span
                            style={{
                              color: "#1E88E5",
                            }}
                          >
                            &nbsp; Browse
                          </span>
                        </Typography>
                        <Typography
                          fontWeight={400}
                          fontSize={"14px"}
                          color={"#7D87A6"}
                        >
                          {"Supported file type PDF"}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} width={"100%"}>
                  <SimpleDatePicker
                    onChange={(value: any) => {
                      setUploadFileDate(value);
                    }}
                    value={uploadFileDate}
                    inputlabel="Signed On"
                    disableFuture
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => (
                      <TextField
                        {...params}
                        placeholder="Select Scored on date"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            {secureResponse.response !== "" && (
              <Grid container display={"flex"} flexDirection={"column"}>
                <Typography
                  fontWeight={600}
                  fontSize={"14px"}
                  margin={"10px 0"}
                >
                  Your File
                </Typography>
                <Grid
                  item
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid item display={"flex"} alignItems={"center"}>
                    <DocumentIcon width={16} height={16} color="#1E88E5" />
                    <Typography
                      fontWeight={400}
                      fontSize={"14px"}
                      margin={"0 5px"}
                    >
                      {secureResponse?.selectedFile?.name}
                    </Typography>
                  </Grid>
                  <Grid item display={"flex"} alignItems={"center"}>
                    {downloadLoading ? (
                      <CircularProgress size={16} />
                    ) : (
                      <IconButton
                        color="primary"
                        onClick={() =>
                          downloadUploadFile({
                            variables: {
                              //@ts-ignore
                              file_registry_id: secureResponse?.response,
                            },
                          })
                        }
                      >
                        <DownloadIcon width={16} height={16} color="#1E88E5" />
                      </IconButton>
                    )}
                    <IconButton
                      color="primary"
                      onClick={() =>
                        setSecureResponse({
                          response: "",
                          selectedFile: {},
                        })
                      }
                    >
                      <TrashIcon width={16} height={16} color="#FF6363" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item width="100%">
            <Button
              onClick={() =>
                handleUploadAPA({
                  variables: {
                    args: {
                      file_id: parseInt(secureResponse?.response),
                      category_id: Agreement_Category.Apa,
                      signed_at: `${uploadFileDate}`,
                      metadata: {
                        source_id: trade_id,
                      },
                    },
                  },
                })
              }
              disabled={secureResponse?.response === "" || loading}
              fullWidth
              variant="primarySmall"
            >
              {loading ? <CircularProgress size={18} /> : "Submit"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadAPA;
