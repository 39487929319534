import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import SimpleInput from "../../../components/input/SimpleInput";
import SimpleSelect from "../../../components/input/SimpleSelect";
import * as Yup from "yup";
import { useAddNewCollaboratorMutation } from "../../../data-access/hooks/company/team-collaboration.hooks";
import { Alert } from "@mui/lab";
import SideModal from "../../../components/Modals/SideModal";

const InviteTeamModal = ({ setOpen, refetch, open }: any) => {
  /************************************************************************************************************************************* */
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required."),
    last_name: Yup.string().required("Last Name is required."),
    email: Yup.string()
      .required("Email is required.")
      .email("Enter a valid emal.")
      .matches(
        /^[a-zA-Z0-9_.+-]+@(?!(gmail)).*\.[a-zA-Z]{2,6}$/,
        "Please enter your company email ID"
      ),
    phone: Yup.string()
      .test("len", "Phone number must be 10 digit", (val) => val?.length === 10)
      .required("Phone number is requried"),
    association_type: Yup.string().required(" Association Type is required."),
  });

  /************************************************************************************************************************************* */
  const [handleAddMember, { loading: addLoading, error: addErrors }] =
    useAddNewCollaboratorMutation({
      onCompleted: () => {
        setOpen(false);
        refetch();
      },
    });

  /************************************************************************************************************************************* */
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      association_type: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleAddMember({
        variables: {
          args: {
            mobile_number: `${values.phone}`,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            user_type: values.association_type as any,
          },
        },
      });
    },
  });

  return (
    <SideModal
      open={open}
      onClose={() => setOpen(false)}
      modalTitle="Add Team"
      footer={
        <Button
          form="invite-form"
          disabled={addLoading}
          type="submit"
          fullWidth
          variant="primaryMedium"
        >
          {addLoading ? <CircularProgress size={20} /> : "Send Invite"}
        </Button>
      }
    >
      <Grid
        container
        width={"100%"}
        style={{ background: "white" }}
        justifyContent="center"
        alignItems={"center"}
        display="flex"
        flexDirection={"column"}
      >
        {Boolean(addErrors?.message) && (
          <Grid item mb={2}>
            <Alert severity="error">{addErrors?.message}</Alert>
          </Grid>
        )}
        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
          id="invite-form"
        >
          <FormControl fullWidth>
            <SimpleInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.first_name}
              inputlabel="First Name"
              placeholder="Enter First Name"
              name="first_name"
              helperText={formik.touched.first_name && formik.errors.first_name}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
            />
          </FormControl>
          <FormControl fullWidth>
            <SimpleInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.last_name}
              inputlabel="Last Name"
              placeholder="Enter Last Name"
              name="last_name"
              helperText={formik.touched.last_name && formik.errors.last_name}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
            />
          </FormControl>
          <FormControl fullWidth>
            <SimpleInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              inputlabel="Email-ID"
              placeholder="Enter Email-ID"
              helperText={formik.touched.email && formik.errors.email}
              name="email"
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
          </FormControl>
          <FormControl fullWidth>
            <SimpleInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              inputlabel="Phone"
              placeholder="Enter Phone"
              helperText={formik.touched.phone && formik.errors.phone}
              name="phone"
              type="number"
              error={formik.touched.phone && Boolean(formik.errors.phone)}
            />
          </FormControl>
          <FormControl fullWidth>
            <SimpleSelect
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.association_type}
              formlabel="Association Type"
              placeholder="Enter Association Type"
              name="association_type"
              displayEmpty
              helperText={
                formik.touched.association_type &&
                formik.errors.association_type
              }
              error={
                formik.touched.association_type &&
                Boolean(formik.errors.association_type)
              }
              renderValue={() => {
                if (formik.values.association_type === "") {
                  return "Select your association type";
                } else {
                  return formik.values.association_type === "MEMBER"
                    ? "Team Member"
                    : "Director";
                }
              }}
            >
              <MenuItem value={"DIRECTOR"}>Director</MenuItem>
              <MenuItem value={"MEMBER"}>Team Member</MenuItem>
            </SimpleSelect>
          </FormControl>
        </form>
      </Grid>
    </SideModal>
  );
};

export default InviteTeamModal;
