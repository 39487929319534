import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import axios from "axios";
import { ReactComponent as TrashXIcon } from "../../../assets/Icon/Outline/trash-icon.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/Icon/Outline/document-icon.svg";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import { ReactComponent as XIcon } from "../../../assets/Icon/Outline/x-icon.svg";
import SimpleDatePicker from "../../../components/input/SimpleDatePicker";
import { useUploadAgreementMutation } from "../../../data-access/hooks/company/nda-logs.hooks";
import { Agreement_Category } from "../../../data-access/types";
import { CompanyContext } from "../../../contexts/CompanyContext";

const UploadAgreement = ({ onClose }: any) => {
  const { id } = useParams();

  const [uploadedNewFile, setUploadNewFile] = useState([]);

  const [uploadFileDate, setUploadFileDate] = useState<any>();

  const { setShowAlert } = useContext(CompanyContext);

  const [uploadLoading, setUploadLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles: any) => {
    setUploadLoading(true);
    setUploadNewFile(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    multiple: false,
    onDrop,
    maxFiles: 1,
  });

  const [secureResponse, setSecureResponse] = useState({
    response: "",
    selectedFile: {} as any,
  });

  /*************************************************************************************************************/
  const uploadFile = async (selectedFile: File) => {
    const formData = new FormData();

    formData.append("file", selectedFile, selectedFile.name);

    formData.append("category_id", "TENANT_AGREEMENT");

    formData.append("password", "");

    const token = localStorage.getItem("accessToken");

    const config: any = {
      method: "post",
      url: `${process.env.REACT_APP_ACTIONS_BASE_URL}secure-upload`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        "X-hasura-tenant-id": id,
      },
    };
    await axios(config)
      .then((response) => {
        setShowAlert({
          show: true,
          message: "The file is successfully uploaded",
          type: "success",
        });
        setSecureResponse({
          response: response?.data?.file_registry_id,
          selectedFile,
        });
        setUploadLoading(false);
      })
      .catch((error) => {
        setShowAlert({
          show: true,
          message: error.response.data.message,
          type: "error",
        });
        setUploadLoading(false);
      });
  };

  /*************************************************************************************************************/
  useEffect(() => {
    if (uploadedNewFile.length !== 0) {
      uploadFile(uploadedNewFile[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedNewFile]);

  /*************************************************************************************************************/
  const [handleUploadAgreement, { loading: uploadButtonLoading }] =
    useUploadAgreementMutation({
      onCompleted: () => onClose(false),
      onError: (item) =>
        setShowAlert({
          show: true,
          message: item.message,
          type: "error",
        }),
    });

  return (
    <>
      <Grid
        container
        style={{ background: "white" }}
        width="30%"
        p={8}
        gap="22px"
      >
        <Grid
          item
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          width="100%"
        >
          <Typography fontSize={"22px"} fontWeight={600}>
            Upload BU Agreement
          </Typography>
          <IconButton style={{ padding: "0" }} onClick={() => onClose(false)}>
            <XIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          display="flex"
          width="100%"
          justifyContent={"center"}
          flexDirection="column"
          gap={"10px"}
        >
          {uploadLoading ? (
            <CircularProgress />
          ) : (
            <div
              style={{
                background: "#1E88E50A",
                width: "100%",
                border: "dashed 1px #1E88E5",
                borderRadius: "11px",
                height: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div>
                <div>
                  <Typography fontWeight={600} fontSize={"16px"}>
                    Drag and drop or
                    <span
                      style={{
                        color: "#1E88E5",
                      }}
                    >
                      &nbsp; Browse
                    </span>
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={"14px"}
                    color={"#7D87A6"}
                  >
                    {"Supported file type PDF"}
                  </Typography>
                </div>
              </div>
            </div>
          )}
          <FormControl fullWidth>
            <SimpleDatePicker
              onChange={(value: any) => {
                setUploadFileDate(value);
              }}
              value={uploadFileDate}
              inputlabel="Signed On"
              disableFuture
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps
              ) => (
                <TextField
                  {...params}
                  placeholder="Select Scored on date"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item width={"100%"}>
          {secureResponse.response !== "" && (
            <Grid container display={"flex"} flexDirection={"column"}>
              <Typography fontWeight={600} fontSize={"14px"} margin={"10px 0"}>
                Your File
              </Typography>
              <Grid
                item
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item display={"flex"} alignItems={"center"}>
                  <DocumentIcon width={16} height={16} color="#1E88E5" />
                  <Typography
                    fontWeight={400}
                    fontSize={"14px"}
                    margin={"0 5px"}
                  >
                    {secureResponse?.selectedFile?.name}
                  </Typography>
                </Grid>
                <Grid item display={"flex"} alignItems={"center"}>
                  <IconButton
                    onClick={() =>
                      setSecureResponse({
                        response: "",
                        selectedFile: {},
                      })
                    }
                  >
                    <TrashXIcon width={16} height={16} color="#FF6363" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          display="flex"
          width="100%"
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            disabled={uploadButtonLoading}
            onClick={() =>
              handleUploadAgreement({
                variables: {
                  args: {
                    file_id: Number(secureResponse?.response),
                    signed_at: `${uploadFileDate}`,
                    category_id: Agreement_Category.TenantAgreement,
                  },
                },
              })
            }
            variant="primaryMedium"
          >
            {uploadButtonLoading ? <CircularProgress size={20} /> : "Upload"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadAgreement;
