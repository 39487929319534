import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetScheduledEmiQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Scheduled_Emi_Bool_Exp>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  order_by?: Types.InputMaybe<Array<Types.Scheduled_Emi_Order_By> | Types.Scheduled_Emi_Order_By>;
}>;


export type GetScheduledEmiQuery = { __typename?: 'query_root', scheduled_emi: Array<{ __typename?: 'scheduled_emi', id: number, trade_installment_seq?: number | null, amount: any, bounce_charges: any, status: Types.Ref_Transaction_Status_Enum, scheduled_at: any, trade: { __typename?: 'trade', company: { __typename?: 'company', name?: string | null } }, transactions: Array<{ __typename?: 'transaction', id: number }> }> };


export const GetScheduledEmiDocument = gql`
    query getScheduledEmi($where: scheduled_emi_bool_exp, $offset: Int, $limit: Int, $order_by: [scheduled_emi_order_by!]) {
  scheduled_emi(
    where: $where
    offset: $offset
    limit: $limit
    order_by: $order_by
  ) {
    id
    trade_installment_seq
    trade {
      company {
        name
      }
    }
    amount
    bounce_charges
    status
    scheduled_at
    transactions(order_by: {id: desc}) {
      id
    }
  }
}
    `;

/**
 * __useGetScheduledEmiQuery__
 *
 * To run a query within a React component, call `useGetScheduledEmiQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledEmiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledEmiQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetScheduledEmiQuery(baseOptions?: Apollo.QueryHookOptions<GetScheduledEmiQuery, GetScheduledEmiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduledEmiQuery, GetScheduledEmiQueryVariables>(GetScheduledEmiDocument, options);
      }
export function useGetScheduledEmiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduledEmiQuery, GetScheduledEmiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduledEmiQuery, GetScheduledEmiQueryVariables>(GetScheduledEmiDocument, options);
        }
export type GetScheduledEmiQueryHookResult = ReturnType<typeof useGetScheduledEmiQuery>;
export type GetScheduledEmiLazyQueryHookResult = ReturnType<typeof useGetScheduledEmiLazyQuery>;
export type GetScheduledEmiQueryResult = Apollo.QueryResult<GetScheduledEmiQuery, GetScheduledEmiQueryVariables>;
export const namedOperations = {
  Query: {
    getScheduledEmi: 'getScheduledEmi'
  }
}