import { Divider as MuiDivider } from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

export const FullCalendarWrapper = styled.div`
  background: #fff;
  padding: 30px 30px 30px 30px;
  border-right: solid 1px #ededed;
  font-family: Manrope;
  & .fc-button-group {
    gap: 5px;
  }
  & .fc .fc-button {
    border-radius: 4px !important;
    &:focus {
      box-shadow: none !important;
    }
  }
  & .fc .fc-prev-button,
  .fc .fc-next-button {
    background: #d9d9d9;
    color: black;
    border: none;
    &:hover {
      background: #1e88e5;
    }
  }
  & .fc-today-button {
    background: white !important;
    color: #1e88e5 !important;
    border: solid #1e88e5 1px !important;
    text-transform: capitalize !important;
  }
  & .fc-dayGridMonth-button,
  .fc-dayGridDay-button,
  .fc-dayGridWeek-button {
    background: white !important;
    color: #1e88e5 !important;
    border: solid #1e88e5 1px !important;
    text-transform: capitalize !important;
    width: 65px;
  }
  & .fc-dayGridMonth-button.fc-button-active,
  .fc-dayGridDay-button.fc-button-active,
  .fc-dayGridWeek-button.fc-button-active {
    background: #1e88e5 !important;
    border: none !important;
    color: white !important;
    text-transform: capitalize !important;
  }

  & .fc .fc-daygrid-day {
    border-color: white;
  }

  & .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame {
    border: solid 1px #1e88e5 !important;
  }
  & .fc .fc-daygrid-day .fc-daygrid-day-frame {
    background: transparent;
    border: solid 1px #ededed !important;
    border-top: none !important;
    border-right: none !important;
    min-height: 140px !important;
  }
  & .fc-daygrid-day-top {
    flex-direction: row !important;
    font-size: 20px;
    font-weight: 500;
  }

  & .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 30px;
  }

  & .fc-daygrid-more-link {
    color: #1565c0;
    font-size: 14px;
    font-weight: 400;
  }

  & .fc .fc-daygrid-event-harness {
    z-index: -1 !important;
  }

  & .fc-col-header-cell.fc-day {
    border-right: none;
    border-left: none;
    padding: 8px;
    font-size: 18px;
    font-weight: 600;
    color: #666666de;
  }

  & .fc-daygrid-day-number {
    padding: 8px !important ;
  }

  & .fc-col-header {
    width: 100% !important;
  }

  & .fc-scrollgrid-sync-table {
    width: 100% !important;
  }

  & .fc-daygrid-body {
    width: 100% !important;
  }

  & .fc-scroller {
    overflow-x: auto;
    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      background: #1e88e555;
    }
    ::-webkit-scrollbar-thumb {
      background: #1e88e5;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export const Divider = styled(MuiDivider)(spacing);
