import * as Types from '../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllGstReturnQueryVariables = Types.Exact<{
  company_id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetAllGstReturnQuery = { __typename?: 'query_root', company_gst_return: Array<{ __typename?: 'company_gst_return', company_id: number, gstin: string, financial_year: string, dof: any, is_delay: boolean, status: string, tax_period: string, valid: string, rtntype: string, ret_prd: number, mof: string, is_active?: boolean | null, id: number, arn: string }>, company: Array<{ __typename?: 'company', billing_gst?: string | null, active_gstin?: string | null, billing_address?: string | null }> };


export const GetAllGstReturnDocument = gql`
    query getAllGstReturn($company_id: Int) {
  company_gst_return(where: {company_id: {_eq: $company_id}}) {
    company_id
    gstin
    financial_year
    dof
    is_delay
    status
    tax_period
    valid
    rtntype
    ret_prd
    mof
    is_active
    id
    arn
  }
  company(where: {id: {_eq: $company_id}}) {
    billing_gst
    active_gstin
    billing_address
  }
}
    `;

/**
 * __useGetAllGstReturnQuery__
 *
 * To run a query within a React component, call `useGetAllGstReturnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGstReturnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGstReturnQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetAllGstReturnQuery(baseOptions?: Apollo.QueryHookOptions<GetAllGstReturnQuery, GetAllGstReturnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllGstReturnQuery, GetAllGstReturnQueryVariables>(GetAllGstReturnDocument, options);
      }
export function useGetAllGstReturnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllGstReturnQuery, GetAllGstReturnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllGstReturnQuery, GetAllGstReturnQueryVariables>(GetAllGstReturnDocument, options);
        }
export type GetAllGstReturnQueryHookResult = ReturnType<typeof useGetAllGstReturnQuery>;
export type GetAllGstReturnLazyQueryHookResult = ReturnType<typeof useGetAllGstReturnLazyQuery>;
export type GetAllGstReturnQueryResult = Apollo.QueryResult<GetAllGstReturnQuery, GetAllGstReturnQueryVariables>;
export const namedOperations = {
  Query: {
    getAllGstReturn: 'getAllGstReturn'
  }
}